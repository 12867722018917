export const styles = theme => ({

	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		width: '100%',
		height: 60,
		border: '2px solid #00B67F',
		borderRadius: 5,
		boxSizing: 'border-box',
		padding: 4,
		marginBottom: 4,
		backgroundColor: '#F7F7F7',
		cursor: 'pointer',
	},

	attentionRequired: {
		border: '2px solid #FD2E25',
	},

	greyIcon: {
		fill: 'rgba(0, 0, 0, 0.54)',
	},

	greenIcon: {
		fill: '#00B67F',
	},

	redIcon: {
		fill: '#FD2E25',
	},

	isAgent: {
		borderRadius: '15px',
		fontSize: '10px',
		border: '1px solid #a1a1a2',
		width: '41px',
		height: '16px',
		textAlign: 'center',
		paddingTop: '2px',
		color: '#a1a1a2',
		margin: '2px 6px',
	},

	textField: {
		// padding: '0px 6px',
		fontSize: '10px',
		maxWidth: 100,
		overflow: 'auto',
		scrollbarWidth: 'thin',
	},

	textFieldSmall: {
		// padding: '0px 6px',
		fontSize: '9px',
	},

	stateTypeDivision: {
		height: '100%',
		width: 30,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		alignItems: 'center',
	},

	assetDivison: {
		height: '100%',
		flexBasis: '30%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		alignItems: 'center',
	},
  
	tripDivison: {
		height: '100%',
		flexBasis: '50%',
		borderLeft: '2px solid rgba(0, 0, 0, 0.30)',
		borderRight: '2px solid rgba(0, 0, 0, 0.30)',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		alignItems: 'center',
	},

	iconDivison: {
		height: '100%',
		flexBasis: '20%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},

	iconDivisonVertical: {
		flexDirection: 'column',
	},

	warningIcons: {
		padding: '2px 4px',
		width: 24,
		height: 24,
	},

	verticalFlex: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
	},
  
	horizontalFlex: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
	},
  
	horizontalSpreadFlex: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
	},

	selected: {
		backgroundColor: '#E5E5E5',
	},

});