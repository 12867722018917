import changePassword from "./changePassword";
import getAttachments from "./getAttachments";
import getBonusList from "./getBonusList";
import getCarclubUserDetails from "./getCarclubUserDetails";

import getCustomerAccountDetails from "./getCustomerAccountDetails";

import getLegalDocuments from "./getLegalDocuments";
import getPricingPlans from "./getPricingPlans";
import getUser from "./getUser";
import listCustomers from "./listCustomers";
import searchCustomers from "./searchCustomers";
import toggleCustomer from "./toggleCustomer";
import updateCustomer from "./updateCustomer";

const customerService = {
  changePassword,
  getAttachments,
  getBonusList,
  getCarclubUserDetails,
  getCustomerAccountDetails,
  getLegalDocuments,
  getPricingPlans,
  getUser,
  listCustomers,
  searchCustomers,
  toggleCustomer,
  updateCustomer,
};

export default customerService;
