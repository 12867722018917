import React from 'react';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import styles from './ErrorScreen.styles';

const ErrorScreen = ({
	classes,
}) => {

	return (
		<div className={classes.root}>
			<div className={classes.smallWindowWidthText}>
				<Typography color='inherit'>
					This plataform does not support your current resolution.
                	</Typography>
				<Typography color='inherit'>
					Please extend your window size to a minimum of 1024px wide resolution.
                	</Typography>
			</div>
		</div>
	);
};

export default withStyles(styles)(ErrorScreen);