import React, { useState, useEffect } from 'react';
import CreateLocations from './CreateLocations';

const CreateLocationsWrapper = props => {

	const {
		location,
	} = props;

	const [error, setError] = useState();
	const [placeholder, setPlaceholder] = useState(
		(!!location && { ...location }) || {});

	const {
		latitude,
		longitude,
	} = location || {};

	useEffect(() => {
		if (!!latitude) {
			setPlaceholder(prev => ({ ...prev, latitude }));
		}
	}, [latitude]);

	useEffect(() => {
		if (!!longitude) {
			setPlaceholder(prev => ({ ...prev, longitude }));
		}
	}, [longitude]);

	const onChange = field => setPlaceholder(prev => ({ ...prev, ...field }));

	return (
		<CreateLocations
			{...props}
			error={error}
			placeholder={placeholder}
			setError={setError}
			setPlaceholder={onChange}
		/>
	);
};

export default CreateLocationsWrapper;