import React from "react";
import { TableHead, TableRow, TableCell } from "@material-ui/core";

import CustomDataGridHeaderItem from "./CustomDataGridHeaderItem";

export default function CustomDataGridHeader({ classes, columns, actionsComponent, filter, onSort }) {
  return (
    <TableHead>
      <TableRow>
        {columns.map((col) => {
          return !col.hidden ? (
            <CustomDataGridHeaderItem
              className={classes.headerCell}
              key={"thead_" + col.field}
              align={col.headerAlign}
              label={col.headerName}
              width={col.width}
              headerComponent={col.headerComponent}
              onSort={() => !col.disableSort && onSort(col.field)}
              sort={filter?.sortField === col.field ? filter?.sortType : undefined}
            />
          ) : null;
        })}
        {actionsComponent && <TableCell className={classes.headerCell} key={"thead_actions"}></TableCell>}
      </TableRow>
    </TableHead>
  );
}
