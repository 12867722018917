import createModel from '../createModel';

class Feature {

	constructor({
		blocker,
		description,
		id,
		subtype,
		type,
	} = {}) {
		createModel.call(this, {
			blocker,
			description,
			id,
			subtype,
			type,
		});
	};
};

export default Feature;