export const styles = () => ({
  root: {
    "& .CdgRow": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#eee",
      },
    },
    "& .CdgRowSelected": {
      backgroundColor: "#ddd",
    },
  },
  datagrid: {
    height: "calc(100vh - 245px)",
    "& table": {
      minWidth: "auto !important",
    },
  },
});
