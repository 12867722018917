import React, { useState, useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import withPermission from "../../hoc/withPermission";
import TripApp from "./TripApp";
import { tripReducer, initialState, actions, actionTypes, DISPLAY_MODES } from "./reducer";
import log from "../../utils/logger";
import {
  selectIsLoadingTrips,
  selectSelectedTrip,
  setSelectedTrip,
} from "../../features/reservation/reservationsSlice";
import { useDispatch } from "react-redux";

const logger = log("TripAppWrapper");

const TripAppWrapper = () => {
  const dispatch = useDispatch();
  const selectedTrip = useSelector(selectSelectedTrip);
  const loading = useSelector(selectIsLoadingTrips);

  const [state, dispatch_old] = useReducer(tripReducer, initialState);
  const [shouldFilterWarnings, setShouldFilterWarnings] = useState(false);
  const [onCardMode, setOnCardMode] = useState(false);

  const [currentFilter, setCurrentFilter] = useState({ subType: "NO_CUSTOMER" });
  const [modal, setModal] = useState();
  const [scheduledTripToUpdate, setScheduleTripToUpdate] = useState();

  const displayMode = state.get("displayMode");
  const tripType = state.get("tripType");


  useEffect(() => {
    setOnCardMode(displayMode === DISPLAY_MODES.PRE_FILTERED_MULTI_LIST);
  }, [displayMode]);

  const selectTripHandler = async (trip) => {
    console.log("selectTripHandler --- trip: %o", trip);
    if (typeof trip === "undefined") {
      return;
    }
    try {
      dispatch(setSelectedTrip(trip));
    } catch (error) {
      logger.info(error);
      logger.info(error.message);
    }
  };

  const closeModalHandler = () => {
    setModal(false);
    dispatch_old(actions.setTripTypeCurrent);
    setCurrentFilter((prev) => ({ subType: prev.subType }));
  };

  const closeUpdateModalHandler = () => {
    setScheduleTripToUpdate(null);
    dispatch_old(actions.setTripTypeCurrent);
    setCurrentFilter((prev) => ({ subType: prev.subType }));
  };

  return (
    <TripApp
      currentFilter={currentFilter}
      state={state}
      setDisplayModeList={() => {
        dispatch_old(actions.setDisplayModeList);
      }}
      setDisplayModeCards={() => {
        setShouldFilterWarnings(false);
        dispatch_old(actions.setDisplayModeCards);
      }}
      setTripTypeCurrent={() => {
        if (loading) {
          return;
        }
        selectTripHandler({});
        dispatch_old(actions.setTripTypeCurrent);
        setCurrentFilter((prev) => ({ subType: prev.subType }));
      }}
      setTripTypeHistory={() => {
        if (loading) {
          return;
        }
        selectTripHandler({});
        dispatch_old(actions.setTripTypeHistory);
        setCurrentFilter((prev) => ({ subType: prev.subType }));
      }}
      updateTrip={(trip) => {
        console.log("update trip: %o", trip);
        dispatch_old({
          type: actionTypes.UPDATE_TRIP,
          trip: trip,
        });
        //selectTripHandler(tripsToDisplay[trip.code]);
      }}
      updateHistoryTrip={(trip) => {
        dispatch_old({
          type: actionTypes.UPDATE_HISTORY_TRIP,
          trip: trip,
        });
        // selectTripHandler(tripsToDisplay[trip.code]);
      }}
      removeTrip={(trip) => {
        dispatch_old({
          type: actionTypes.DELETE_TRIP,
          trip,
        });
        selectTripHandler();
      }}
      displayMode={displayMode}
      modal={modal}
      onCardMode={onCardMode}
      selectedTrip={selectedTrip || {}}
      setModal={setModal}
      setSelectedTrip={selectTripHandler}
      setShouldFilterWarnings={setShouldFilterWarnings}
      shouldFilterWarnings={shouldFilterWarnings}
      tripType={tripType}
      closeModalHandler={closeModalHandler}
      setScheduleTripToUpdate={setScheduleTripToUpdate}
      scheduledTripToUpdate={scheduledTripToUpdate}
      closeUpdateModalHandler={closeUpdateModalHandler}
    />
  );
};

export default withPermission([{ resource: ["reservations", "immediate", "read"] }])(TripAppWrapper);
