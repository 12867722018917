import React from "react";
import { useDispatch } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";
import Button from "../../../components/UI/Buttons/CommonButtons";

import { styles } from "./DeviceTeltonikaForm.styles";
import { formSchema, formFields } from "./DeviceTeltonikaForm.schema";
import { FormFields, Form } from "../../../common/forms";

import { deviceCreateTeltonika, deviceUpdateTeltonika } from "../deviceSlice";

function DeviceTeltonikaForm({ classes, onCancel, onSave, device, isEdit }) {
  const dispatch = useDispatch();

  const onSubmit = (deviceValues) => {
    console.log("deviceValues: %o", deviceValues);
    if (isEdit) {
      dispatch(deviceUpdateTeltonika(deviceValues));
    } else {
      dispatch(deviceCreateTeltonika(deviceValues));
    }
    onSave && onSave();
  };

  return (
    <div className={classes.root}>
      <Form
        schema={formSchema()}
        initialValues={device || { protocol: "generic", model: "TFT100", debugMode: false }}
        onSubmit={onSubmit}
        render={({ submitting, form }) => {
          return (
            <>
              <Typography variant="h5" align="left" component="h1">
                {isEdit ? `Update device ${device?.imei}` : "Add new device"}
              </Typography>
              <br />
              <div className={classes.form}>
                <FormFields fields={formFields(isEdit)} />
              </div>
              <Grid container justifyContent="space-between" className={classes.formButtons}>
                <Button buttonStyleName="void" label="Cancel" onClick={onCancel} />
                <Button
                  buttonStyleName="filled"
                  type="submit"
                  disabled={submitting}
                  label={isEdit ? "Update" : "Create"}
                />
              </Grid>
            </>
          );
        }}
      />
    </div>
  );
}

export default withStyles(styles)(DeviceTeltonikaForm);
