const styles = (theme) => ({

	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		flexWrap: 'nowrap',
	},

	actionDiv: {
		height: 40,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},

	workspace: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'row',
		alingItems: 'center',
		flexWrap: 'nowrap',
		position: 'relative',
	},

	title: {
		width: 220,
		fontSize: '22px',
		color: '#47525E',
		marginRight: 20,
	},

	createIcon: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		width: 26,
		height: 26,
	},

	searchbar: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		marginTop: 10,
	},

	titleAndSearch: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},

	actions: {
		display: 'flex',
		alignItems: 'center',
	},

	list: {
		width: '100%',
		height: '100%',
		paddingLeft: 21,
		paddingRight: 21,
		display: 'flex',
		flexDirection: 'column',
	},

	details: {
		minWidth: '284px',
		maxWidth: '284px',
		paddingRight: 21,
		overflowY: 'auto',
	},

	icon: {
		verticalAlign: 'middle',
	},

	textField: {
		width: '100%',
		borderBottom: '1px solid rgba(224, 224, 224, 1)',
	},

	inputRoot: {
		color: 'inherit',
		width: 388,
		height: 42,
		marginLeft: theme.spacing(2),
	},

	inputInput: {
		paddingTop: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		transition: theme.transitions.create('width'),
		width: '92%',
		borderBottom: '1px solid rgba(224, 224, 224, 1)',
	},

	searchIcon: {
		'&:hover': {
			cursor: 'pointer',
		},
	},

	requestModal: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		margin: 'auto',
		width: 400,
		height: 100,
		backgroundColor: '#fff',
		padding: '10px',
		boxSizing: 'border-box',
		boxShadow: theme.shadows[5],
		display: 'flex',
		borderRadius: 5,
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		outline: 'none',
		border: 'none !important',
	},

	requestText: {
		fontSize: '14px',
		wordBreak: 'break-word',
	},
});

export default styles;