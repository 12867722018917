import React, { useState } from 'react';
import Modal from './Modal';

const ModalWrapper = ({
	selected,
	...props
}) => {
	const [nickname, setNickname] = useState(selected?.nickname || selected?.phone);

	return (
		<Modal
			{...props}
			nickname={nickname}
			setNickname={setNickname}
		/>
	);
};

export default ModalWrapper;