import React, { useState, useCallback } from 'react';
import TripsFilters from './TripsFilters';

const TripsFiltersWrapper = props => {

	const {
		filter,
		setFilters,
	} = props;

	const [customerCode, setCustomerCode] = useState(filter || '');

	const onSearch = useCallback(() =>
		setFilters(prev => ({...prev, customerCode, page: 1})), 
	[customerCode, setFilters]);

	const tripsFiltersWrapper = (
		<TripsFilters
			{...props}
			filter={customerCode}
			onSearch={onSearch}
			setFilter={setCustomerCode}
		/>
	);

	return tripsFiltersWrapper;
};

export default TripsFiltersWrapper;