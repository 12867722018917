import {
	PARK_CLEAR_ELEMENTS,
	PARK_SET_ELEMENTS,
	PARK_SET_DETAILS,
} from '../../actions/parks/actionTypes';
import * as mutate from './mutators';
import { pipe } from '../utils';
import createReducer from '../../utils/createReducer';
import { Map } from 'immutable';

const initialState = Map({
	elements: Map(),
});

const parksClearElements = (state) => {
	return state.update('elements', map => map.clear());
};

const parksSetElements = (state, action) => {
	return pipe([
		mutate.setElements(action.elements, 'code'),
	], state);
};

const parksSetDetails = (state, action) => {
	return pipe([
		mutate.setElements([action.park], 'code'),
	], state);
};

const parksReducer = createReducer(initialState, {
	[PARK_CLEAR_ELEMENTS]: parksClearElements,
	[PARK_SET_ELEMENTS]: parksSetElements,
	[PARK_SET_DETAILS]: parksSetDetails,
});

export default parksReducer;