
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import mapper from './mappers/parkMapper';
import { publishAndAwait } from '../../../app/coreSlice';

const listPark = async filter => {

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'PARK',
			subType: 'LIST',
			message: {
				filter,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	};

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	};

	const parks = processMessage.slice();

	const parksModel = Array.isArray(parks)
		&& parks.map( park => mapper.fromDTO({
			...park,
			geoInformation: `[${park.geoLocation}]`,
		}));

	return parksModel;
};

export default listPark;