import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { KeyboardDateTimePicker } from "@material-ui/pickers";

import { styles } from "./_base.styles";

function DateTimeInput({ classes, id, className, label, value, onChange, disabled, error, required, placeholder, minDate, maxDate }) {
  return (
    <div className={classes.root + " " + className}>
      <KeyboardDateTimePicker
        id={id}
        fullWidth
        ampm={false}
        className={disabled ? classes.disabledInput : ""}
        format="DD/MM/YYYY HH:mm"
        clearable
        label={label}
        onChange={(value) => onChange(value)}
        required={required}
        value={value || null}
        disabled={disabled}
        size="small"
        error={error}
        placeholder={placeholder}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
}

export default withStyles(styles)(DateTimeInput);
