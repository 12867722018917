import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const carclubInsertLegalDocumentCall = async (
  { carclubActiveLicenseLocation, carclubSearchLegalDocuments, setFormSuccess },
  dispatch,
  state,
  { url, mandatory, type, name }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  const filter = state?.getIn(["carclub2"])?.legalDocuments?.filter;
  let rh = requestHelper(dispatch, "CARCLUB/INSERT_LEGAL_DOCUMENT");
  let result;
  try {
    result = await wsCall({
      type: "CARCLUB",
      subType: "INSERT_LEGAL_DOCUMENT",
      locationId: selectedLocation?.id,
      message: {
        url,
        mandatory,
        type,
        name,
      },
    });

    if (!result.error) {
      dispatch(setSuccess("Document saved."));
      dispatch(carclubActiveLicenseLocation());
      dispatch(carclubSearchLegalDocuments(filter, true));
      dispatch(setFormSuccess(true));
      setTimeout(() => {
        dispatch(setFormSuccess(false));
      }, 200);
    } else {
      rh.error(result?.description || "Error inserting new document.");
    }
  } catch (_ex) {
    rh.error("Error inserting new document.");
  }
  rh.close();
  return result;
};

export default carclubInsertLegalDocumentCall;
