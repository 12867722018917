import React from 'react';
import NotificationList from './NotificationList/NotificationListWrapper';
import NotificationFilter from './NotificationFilter/NotificationFilterWrapper';
import CreateOrEditNotificationWrapper from './CreateOrEditNotification/CreateOrEditNotificationWrapper';
import NotificationButtons from './NotificationButtons/NotificationButtons';
import Configurations from './Configurations/ConfigurationsWrapper';
import {
	Drawer,
	withStyles,
	Tooltip,
} from '@material-ui/core';
import {
	SettingsOutlined as ConfigurationsIcon,
} from '@material-ui/icons';
import withPermission from '../../../../hoc/withPermission';
import { styles } from './NotificationsSMS.styles';
import classnames from 'classnames';

const NotificationsSMS = ({
	classes,
	className,
	currentScreenSize,
	notifications,
	removeNotification,
	type,
	setType,
	setSubType,
	subType,
	onCreateOrEdit,
	openConfigurations,
	openNotification,
	enterCreateOrEditMode,
	exitCreateOrEditMode,
	updateNotification,
	getNotifications,
}) => {

	const list = (
		<NotificationList
			currentScreenSize={currentScreenSize}
			removeNotification={removeNotification}
			notifications={notifications}
			filters={{ type, subType }}
			editNotification={enterCreateOrEditMode}
		/>
	);

	const header = (
		<div
			className={classes.row}
		>
			<NotificationFilter
				className={classes.header}
				type={type}
				subType={subType}
				notifications={notifications}
				selectType={setType}
				selectSubType={setSubType}
			/>
		</div>

	);

	const buttons = (
		<div
			className={classes.buttons}
		>
			<ConfigurationsButton
				className={classes.configurationsButton}
				onClick={openConfigurations.setOn}
			/>
			<NotificationButtonWithPermission
				className={classes.header}
				onCreate={() => enterCreateOrEditMode()}
			/>
		</div>
	);

	const createOrEditNotification = (
		<Drawer
			anchor='right'
			onClose={() => exitCreateOrEditMode()}
			open={onCreateOrEdit}
		>
			<CreateOrEditNotificationWrapper
				openNotification={openNotification}
				exitCreateOrEditMode={exitCreateOrEditMode}
				updateNotification={updateNotification}
				getNotifications={getNotifications}
				notifications={notifications}
			/>
		</Drawer>

	);

	const configurations = openConfigurations.state && (
		<Configurations
			header='Configurations'
			onCancel={openConfigurations.setOff}
			open={openConfigurations.state}
		/>
	);

	return (
		<div
			className={classnames(classes.root)}
		>
			{buttons}
			{createOrEditNotification}
			{configurations}
			<div
				className={classes.list}
			>
				{header}
				{list}
			</div>
		</div>
	);
};

export default withStyles(styles)(NotificationsSMS);

const ConfigurationsButton = withPermission(
	[{ resource: ['carclub', 'notifications', 'configuration'] }]
)((
	{ className, onClick }
) => (
	<Tooltip title='Configurations' placement='left'>
		<ConfigurationsIcon
			className={className}
			onClick={onClick}
		/>
	</Tooltip>
));

const NotificationButtonWithPermission = withPermission(
	[{ resource: ['carclub', 'notifications', 'create'] }]
)(
	props => <NotificationButtons {...props} />
);