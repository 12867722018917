import { wsCall, requestHelper } from "../../../app/coreSlice";

const reservationOngoingListCall = async (
  { setOngoingReservationsFilter, clearOngoingReservations, setOngoingReservations },
  dispatch,
  state,
  { filter, clear }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  if (!filter) {
    filter = state.getIn(["reservations"])?.ongoingReservations.filter;
  }
  const { fromDateUtc, toDateUtc, page, states, filterType, search } = filter;
  let subType = "RESERVATION_ONGOING_LIST"
  let rh = requestHelper(dispatch, "RESERVATION/" + subType);
  try {
    dispatch(setOngoingReservationsFilter(filter));
    if (clear) {
      dispatch(clearOngoingReservations());
    }
    const searchTrimmed = search?.trim() || undefined;
    const searchFilter =
      filterType === "CUSTOMER"
        ? {
            customerCode: searchTrimmed,
            customerEmail: searchTrimmed,
            customerName: searchTrimmed,
            taxNumber: searchTrimmed,
            phone: searchTrimmed,
          }
        : { reservationCode: searchTrimmed, make: searchTrimmed, model: searchTrimmed, licensePlate: searchTrimmed };
    const result = await wsCall({
      type: "RESERVATION",
      subType,
      locationId: selectedLocation?.id,
      message: {
        fromDate: fromDateUtc,
        toDate: toDateUtc,
        page,
        states,
        ...searchFilter,
      },
    });
    if (clear) {
      dispatch(clearOngoingReservations());
    }
    dispatch(setOngoingReservations(result));
  } catch (_ex) {
    rh.error("Error getting reservations.");
  }
  rh.close();
};

export default reservationOngoingListCall;
