export function listColumns() {
  return [
    {
      field: "serialNumber",
      headerName: "serialNumber",
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "imei",
      headerName: "imei",
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "ip",
      headerName: "ip",
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "model",
      headerName: "model",
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "protocol",
      headerName: "protocol",
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "location",
      headerName: "Location",
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "licensePlate",
      headerName: "License Plate",
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    // {
    //   field: "debugMode",
    //   headerName: "debugMode",
    //   align: "left",
    //   headerAlign: "left",
    //   disableSort: true,
    // },
  ];
}

// private String serialNumber;
// private String imei;
// private String ip;
// private Date date;
// private String model;
// private String carclubCode;
// private String protocol;
// private boolean debugMode;
