import createModel from '../createModel';

/* 
	Backend Definition:
	PricingPlan = {
		id: UUID,
		name: String,
		description: String,
		carclubId: UUID,
		startDate: date,
		endDate: date,
		startingFee: double,
		category: intm
		specific: boolean,
		pricingDetails: JSON (see below),
	};

	==> pricingDetails: JSON (with 2 Arrays) 
	    
		=> [Array 1]. Each element in the
		sequence represents one cell (rate) for 1 hour for a specific
		day of week. Starting with Monday, the first 24 element
		will be the 24 hours of Monday and so on.

		=> [Array 2]. Array of maxPrices (objects)

		EXAMPLE: {
			rates: [...],
			maxPrices: [
				{
					name: 'John Connor',
					maxTime: 5,
					maxPrice: 25.3,
				},
			],
		}
	    

*/

class PricingPlan {

	constructor({
		id,
		name,
		description,
		carclubId,
		startDate,
		endDate,
		startingFee,
		category,
		specific,
		pricingDetails,
		startZoneFees,
		endZoneFees,
	} = {}) {
		createModel.call(this, {
			id,
			name,
			description,
			carclubId,
			startDate,
			endDate,
			startingFee,
			category,
			specific,
			pricingDetails,
			startZoneFees,
			endZoneFees,
		});
	};

};

export default PricingPlan;