import { createSlice } from "@reduxjs/toolkit";
import customerFlowMapper from "../../services/customer/ws/mappers/customerFlowMapper";
import customerChangeLocationCall from "./_sliceCalls/customerChangeLocationCall";
import customerCreateCall from "./_sliceCalls/customerCreate";
import customerNewListCall from "./_sliceCalls/customerNewListCall";
import customerNewPasswordOperatorCall from "./_sliceCalls/customerNewPasswordOperator";
import customerPrincingPlansCall from "./_sliceCalls/customerPrincingPlans";
import customerUpdateCall from "./_sliceCalls/customerUpdate";
import customerUpdate_liteCall from "./_sliceCalls/customerUpdate_lite";
import paymentGetPaymentInfoCall from "./_sliceCalls/paymentGetPaymentInfo";

export const customerSlice = createSlice({
  name: "customer2",
  initialState: {
    customerUpdated: undefined,
    selectedCustomer: undefined,
    selectedCustomerPrincingPlans: undefined,
    selectedCustomerPaymentInfo: undefined,
    customers: {
      list: [],
      lastPage: false,
      filter: {
        page: 1,
        limit: 30,
      },
    },
    ui: {
      showMoveDriverModal: false,
    },
    errorMovingDriver: null,
  },
  reducers: {
    setCustomerUpdated: (state, action) => {
      state.customerUpdated = action.payload;
      // update selected customer
      state.selectedCustomer = { ...state.selectedCustomer, ...action.payload };
      // update customer in list
      if (action.payload) {
        let nList = [];
        (state.customers?.list || []).forEach((customer) => {
          if (customer?.code === action.payload?.code) {
            nList.push({ ...customer, ...action.payload });
          } else {
            nList.push(customer);
          }
        });
        state.customers.list = nList;
      }
    },
    clearCustomerUpdated: (state) => {
      state.customerUpdated = undefined;
    },
    setSelectedCustomer: (state, action) => {
      state.selectedCustomer = action.payload;
    },
    setSelectedCustomerByCode: (state, action) => {
      state.selectedCustomer = (state.customers.list || []).find((customer) => customer.code === action.payload);
    },
    setCustomerPrincingPlans: (state, action) => {
      state.selectedCustomerPrincingPlans = action.payload;
    },
    setCustomerPaymentInfo: (state, action) => {
      console.log("setCustomerPaymentInfo: %o", action.payload);
      state.selectedCustomerPaymentInfo = action.payload;
    },
    setCustomers: (state, action) => {
      let nCustomers = action.payload.customers.map((customer) => customerFlowMapper.fromDTO(customer));
      let customersList = [...state.customers.list, ...nCustomers];
      customersList.sort((a, b) => b.registrationDate - a.registrationDate);
      state.customers.list = customersList;
      state.customers.lastPage = action.payload.lastPage;
    },
    clearCustomers: (state) => {
      state.customers.list = [];
      state.customers.lastPage = false;
      state.customers.filter.page = 1;
    },
    removeCustomerFromList: (state, action) => {
      let nList = (state.customers.list || []).filter((item) => item.code !== action.payload);
      state.customers.list = nList;
    },
    setCustomersFilter: (state, action) => {
      state.customers.filter = action.payload;
    },
    setShowMoveDriverModal: (state, action) => {
      let nValue = action.payload === undefined ? !state.ui.showMoveDriverModal : action.payload;
      Object.keys(state.ui || {}).forEach((key) => {
        state.ui[key] = false;
      });
      state.ui.showMoveDriverModal = nValue;
    },
    setErrorMovingDriver: (state, action) => {
      state.errorMovingDriver = action.payload;
    },
  },
});

export default customerSlice.reducer;

export const {
  setCustomerUpdated,
  clearCustomerUpdated,
  setSelectedCustomerByCode,
  setShowMoveDriverModal,
  setErrorMovingDriver,
} = customerSlice.actions;

// SELECTS
export const selectIsLoading = (state) => state.getIn(["core"])?.loader?.length > 0;
export const selectCustomerUpdated = (state) => state.getIn(["customer2"])?.customerUpdated;
export const selectSelectedCustomer = (state) => state.getIn(["customer2"])?.selectedCustomer;
export const selectSelectedCustomerPrincingPlans = (state) => state.getIn(["customer2"])?.selectedCustomerPrincingPlans;
export const selectSelectedCustomerPaymentInfo = (state) => state.getIn(["customer2"])?.selectedCustomerPaymentInfo;
export const selectCustomers = (state) => state.getIn(["customer2"])?.customers;
export const selectUI = (state) => state.getIn(["customer2"])?.ui;
export const selectErrorMovingDriver = (state) => state.getIn(["customer2"])?.errorMovingDriver;

// CALLS
export const customerUpdateLite = (customer) => async (dispatch, getState) => {
  customerUpdate_liteCall({ customerNewList }, dispatch, getState(), customer);
};

export const customerUpdate = (customer) => async (dispatch, getState) => {
  customerUpdateCall({ ...customerSlice.actions, customerNewList }, dispatch, getState(), customer);
};

export const customerCreate = (customer) => async (dispatch, getState) => {
  customerCreateCall({ customerNewList }, dispatch, getState(), customer);
};

export const customerNewPasswordOperator =
  ({ customerCode }) =>
  async (dispatch, getState) => {
    customerNewPasswordOperatorCall(dispatch, getState(), { customerCode });
  };

export const customerPrincingPlans =
  ({ customerCode }) =>
  async (dispatch, getState) => {
    customerPrincingPlansCall(customerSlice.actions, dispatch, getState(), { customerCode });
  };

export const customerNewList =
  ({ reset, filter }) =>
  async (dispatch, getState) => {
    customerNewListCall(customerSlice.actions, dispatch, getState(), {
      reset,
      filter: filter || getState().getIn(["customer2"])?.customers?.filter,
    });
  };

export const paymentGetPaymentInfo = (customerCode) => async (dispatch, getState) => {
  paymentGetPaymentInfoCall(customerSlice.actions, dispatch, getState(), {
    customerCode,
  });
};

export const customerChangeLocation =
  ({ customerCode, locationId }) =>
  async (dispatch, getState) => {
    customerChangeLocationCall(customerSlice.actions, dispatch, getState(), { customerCode, locationId });
  };
