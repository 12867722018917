import React from "react";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { getPublicLinks } from '../../../../../services/S3';

function ActiveLegalDocumentsDataGridActions({ row, classes }) {
  const getPublicLinkAndOpenFile = async (privateUrl) => {
    const result = await getPublicLinks([privateUrl]);
    window.open(result[0], "_blank");
  };

  return (
    <Grid container justifyContent="flex-end">
      <Grid item>
        <OpenInNewIcon onClick={() => getPublicLinkAndOpenFile(row?.url)} className={classes.icon} />
      </Grid>
    </Grid>
  );
}

export default ActiveLegalDocumentsDataGridActions;
