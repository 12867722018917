import React from 'react';
import DetailsDiv from '../../../UI/DetailsDiv/DetailsDiv';
import DetailsRow from '../../../UI/DetailsDiv/DetailsRow/DetailsRow';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import styles from './Details.styles';

const Detail = ({
	classes,
	info,
}) => {
	const code = (
		<DetailsRow
			label='Id'
			detail={
				<Typography className={classes.typography}>
					{info?.id}
				</Typography>
			}
		/>
	);

	const creator = (
		<DetailsRow
			label='Created By'
			detail={
				<Typography className={classes.typography}>
					{info?.userAddThis}
				</Typography>
			}
		/>
	);

	const driver = (
		<DetailsRow
			label='Driver'
			detail={
				<Typography className={classes.typography}>
					{info?.customerCode}
				</Typography>
			}
		/>
	);

	const creation = (
		<DetailsRow
			label='Create Date'
			detail={
				<Typography className={classes.typography}>
					{info?.createDate}
				</Typography>
			}
		/>
	);

	const reason = (
		<DetailsRow
			label='Reason'
			detail={
				<Typography className={classes.typography}>
					{info?.reason}
				</Typography>
			}
			column
		/>
	);

	const generatedCode = info?.generatedCode && (
		<DetailsRow
			label='Generated Bonus Id'
			detail={
				<Typography className={classes.typography}>
					{info.generatedCode}
				</Typography>
			}
			column
		/>
	);

	return (
		<DetailsDiv title='Detail'>
			{code}
			{creator}
			{driver}
			{creation}
			{reason}
			{generatedCode}
		</DetailsDiv>
	);
};

export default withStyles(styles)(Detail);