export const styles = (theme) => ({
  root: {
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: theme.shadows[5],
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    height: "auto",
    left: "50%",
    outline: 0,
    padding: "20px 15px",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
  },
});
