import React from 'react';
import {
	FormControl,
	InputBase,
	InputLabel,
} from '@material-ui/core';
import { dateFormatter } from '../../../../services/formatter';
import classnames from 'classnames';

const updateFieldMaker = ({ field, label, disabled, date }) =>
	({ placeholder, classes, onChange, error }) =>
		(
			<FormControl key={field}>
				{!!label && (
					<InputLabel>
						{label}
					</InputLabel>
				)}
				<InputBase
					classes={{
						root: classes.inputRoot,
						input: classnames(classes.inputInput,
							!!error && !placeholder[field] && classes.inputError),
					}}
					disabled={!!disabled}
					value={(date ? dateFormatter.format(placeholder[field], 'dateTime') : placeholder[field]) || ''}
					onChange={event => onChange({ [field]: event.target.value })}
				/>
			</FormControl>
		);

const code = updateFieldMaker({
	field: 'name',
	label: 'Name',
});

const designation = updateFieldMaker({
	field: 'description',
	label: 'Designation',
});

const accessLevel = updateFieldMaker({
	field: 'accessLevel',
	label: 'Access Level',
});

const createDate = updateFieldMaker({
	field: 'createDate',
	label: 'Create Date',
	disabled: true,
	date: true,
});

const createBy = updateFieldMaker({
	field: 'createBy',
	label: 'Created By',
	disabled: true,
});

const modifiedBy = updateFieldMaker({
	field: 'modifiedBy',
	label: 'Modified By',
	disabled: true,
});

const modifiedDate = updateFieldMaker({
	field: 'modifiedDate',
	label: 'Modified Date',
	disabled: true,
	date: true,
});

const fields = params => [
	code(params),
	designation(params),
	accessLevel(params),
	createDate(params),
	createBy(params),
	modifiedDate(params),
	modifiedBy(params),
];

export default fields;