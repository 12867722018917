
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_REQUEST_IS_MALFORMED,
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import { publishAndAwait } from '../../../app/coreSlice';

const deleteBanner = async ({id, locationId, carclubId}) => {
	if ( typeof id === 'undefined'
		|| typeof locationId === 'undefined'
		|| typeof carclubId === 'undefined' ) {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	};
	
	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'BANNER',
			subType: 'DELETE_LOCATION',
			message: {
				id,
				locationId,
				carclubId,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	};

	if (error) {

		const {
			code,
			description,
			message,
			tag,
		} = processMessage;

		throw new NGError({
			message: description || message || tag,
			code,
			tag,
		});
	};

	return processMessage;
};

export default deleteBanner;