import React from 'react';
import {
	TextField,
	Typography,
	withStyles,
} from '@material-ui/core';
import { styles } from './ChangePassword.styles';
import classnames from 'classnames';

const ChangePassword = ({
	classes,
	className,
	onConfirmPassChange,
	onCurrentPassChange,
	onNewPassChange,
	passwordsAreDifferent,
}) => {

	return (
		<div
			className={classnames(classes.root, className)}
		>

			<Typography className={classes.title}>Security</Typography>
			<div className={classes.content}>
				<TextField
					className={classes.field}
					label="Current Password"
					onChange={event => onCurrentPassChange(event.target.value)}
					required
					type="password"
				/>

				<TextField
					className={classes.field}
					label="New Password"
					onChange={event => onNewPassChange(event.target.value)}
					required
					type="password"
				/>

				<TextField
					className={classes.field}
					label="Confirm Password"
					error={passwordsAreDifferent}
					onChange={event => onConfirmPassChange(event.target.value)}
					required
					type="password"
				/>

				<Typography className={classes.helperText}>'New' and 'Confirm' passwords must match</Typography>

			</div>

		</div>
	);
};

export default withStyles(styles)(ChangePassword);
