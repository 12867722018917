import React from 'react';
import {
	useNavigate,
} from 'react-router-dom';
import {
	CssBaseline,
	Paper,
	Typography,
	withStyles,
} from '@material-ui/core';
import styles from './Home.styles';
import classnames from 'classnames';

const Home = ({
	className,
	classes,
}) => {

	document.body.style.background = '#0197c6';

	const navigate = useNavigate();

	return (
		<main className={classnames(classes.root, className)}>
			<CssBaseline />
			<Paper className={classes.paper}>
			<img className={classes.Logo} alt="logo" src={process.env.REACT_APP_LOGIN_LOGO}/>
				<Typography component="h1" variant="h5" align="center">
					Welcome!
				</Typography>
				<Typography align="center">
					Please select your carclub by providing it in the URL.
				</Typography>
				<div className={classes.link}>
					<Typography display='inline'>
						Example:
					</Typography>
					&nbsp; {/*blank space*/}
					<Typography
						className={classes.hyperlink}
						display='inline'
						onClick={() => navigate('/'+ process.env.REACT_APP_DEFAULT_CARCLUB)}
					>
						{new URL('/' + process.env.REACT_APP_DEFAULT_CARCLUB, window.location.href).href}
					</Typography>
				</div>
			</Paper>
		</main>
	);
};

export default withStyles(styles)(Home);