const styles = (theme) => ({
	root: {
	},
	
	filledBtn: {
		color: 'white',
		backgroundColor: '#009BCC',
		width: 'auto',
		height: '25px',
		padding: '0 10px',
		fontSize: '9px',
		minWidth: '90px',
		minHeight: 0,
		borderRadius: '18px',
		whiteSpace: 'nowrap',
		'&:hover': {
			color: '#000000',
			backgroundColor: '#C5C5C5',
		},
	},

	voidBtn: {
		color: '#009BCC',
		backgroundColor: 'white',
		width: 'auto',
		height: '25px',
		padding: '0 10px',
		fontSize: '9px',
		minWidth: '90px',
		minHeight: 0,
		borderRadius: '18px',
		boxShadow: 'none',
		border: '1px solid #009BCC',
		whiteSpace: 'nowrap',
		'&:hover': {
			color: '#000000',
			backgroundColor: '#C5C5C5',
		},
	},

	minWidthFilled: {
		minWidth: '138px !important',
		color: 'white',
		backgroundColor: '#009BCC',
		width: 'auto',
		height: '25px',
		padding: '0 24px',
		fontSize: '9px',
		minHeight: 0,
		borderRadius: '18px',
		whiteSpace: 'nowrap',
	},

	minWidthVoid: {
		minWidth: '138px !important',
		color: '#009BCC',
		backgroundColor: 'white',
		width: 'auto',
		height: '25px',
		padding: '0 24px',
		fontSize: '9px',
		minHeight: 0,
		borderRadius: '18px',
		boxShadow: 'none',
		border: 'none',
		whiteSpace: 'nowrap',
	},

	buttonProgress: {
		color: 'green',
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},

	modal:{
		backgroundColor: 'white',
		borderRadius: '10px',
		bottom: 0,
		boxShadow: theme.shadows[5],
		boxSizing: 'border-box',
		height: '200px',
		left: 0,
		outline: 0,
		padding: '20px',
		position: 'absolute',
		right: 0,
		top: 0,
		width: '500px',
		margin: 'auto',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		textAlign: 'center',
	},

	modalTitle: {
		fontSize: '18px',
		marginBottom: '20px',
	},

	modalText: {
		fontSize: '14px',
		marginBottom: '15px',
	},

	modalButtons:{
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},

});

export default styles;