export const styles = () => ({

	root: {
		position: 'absolute',
		left: '50%',
		top: '50%',
		transform: 'translateX(-50%) translateY(-50%)',
		width: '30px',
		height: '30px',
		zIndex: 10000,

		'&:before': {
			content: '""',
			position: 'relative',
			display: 'block',
			width: '300%',
			height: '300%',
			boxSizing: 'border-box',
			marginLeft: '-100%',
			marginTop: '-100%',
			borderRadius: '45px',
			backgroundColor: '#01a4e9',
			animation: '$pulseRing 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite',
			zIndex: 10000,

		},

		'&:after': {
			content: '""',
			position: 'absolute',
			left: 0,
			top: 0,
			display: 'block',
			width: '100%',
			height: '100%',
			backgroundColor: 'rgb(0, 155, 204)',
			borderRadius: '15px',
			boxShadow: ' 0 0 8px rgba(0,110,110,.3)',
			animation: '$pulseDot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite',
			zIndex: 10000,
		},
	},

	'@keyframes pulseRing': {
		'0%': {
			transform: 'scale(.33)',
		},
		'80%, 100%': {
			opacity: 0,
		},
	},

	'@keyframes pulseDot': {
		'0%': {
			transform: 'scale(.8)',
		},
		'50%': {
			transform: 'scale(1)',
		},
		'100%': {
			transform: 'scale(.8)',
		},
	},

});

export default styles;