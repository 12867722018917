import React, { useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import {
  Typography,
  ClickAwayListener,
  Popper,
  MenuList,
  MenuItem,
  ListItemIcon,
  CircularProgress,
} from "@material-ui/core";
import InUseIcon from "@material-ui/icons/PlayCircleOutline";
import UnavailableIcon from "@material-ui/icons/PauseCircleOutline";
import AvailableIcon from "@material-ui/icons/CheckCircleOutline";
import DeactivatedIcon from "@material-ui/icons/CancelOutlined";
import { ReactComponent as UndefinedIcon } from "../../../../assets/AssetApp/icons/choose.svg";

import { styles } from "./AssetStateFilter.styles";
import MapButton from "../../common/MapButton/MapButton";

function AssetStateFilter({ classes, filter, isLoading, onSelectState }) {
  const [anchorEl, setAnchorEl] = useState(null);

  let selectedIcon;
  if (isLoading) {
    selectedIcon = <CircularProgress size={20} />;
  } else {
    switch (filter?.state) {
      case "AVAILABLE":
        selectedIcon = <AvailableIcon style={{ color: "green" }} />;
        break;
      case "UNAVAILABLE":
        selectedIcon = <UnavailableIcon style={{ color: "red" }} />;
        break;
      case "ON_TRIP":
        selectedIcon = <InUseIcon style={{ color: "blue" }} />;
        break;
      case "DEACTIVATED":
        selectedIcon = <DeactivatedIcon style={{ color: "black" }} />;
        break;
      default:
        selectedIcon = <UndefinedIcon style={{ color: "black" }} />;
        break;
    }
  }

  const onMenuItemClickedHandler = (option) => {
    onSelectState && onSelectState(option);
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <MapButton tooltip="Filter by State" onClick={(e) => setAnchorEl(e.currentTarget)}>
        {selectedIcon}
      </MapButton>
      <Popper className={classes.menu} open={Boolean(anchorEl)} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <MenuList>
            {/* AVAILABLE */}
            <MenuItem onClick={() => onMenuItemClickedHandler("AVAILABLE")}>
              <ListItemIcon className={classes.icon}>
                <AvailableIcon style={{ color: "green" }} />
              </ListItemIcon>
              <Typography>Available</Typography>
            </MenuItem>
            {/* UNAVAILABLE */}
            <MenuItem onClick={() => onMenuItemClickedHandler("UNAVAILABLE")}>
              <ListItemIcon className={classes.icon}>
                <UnavailableIcon style={{ color: "red" }} />
              </ListItemIcon>
              <Typography>Unavailable</Typography>
            </MenuItem>
            {/* ON_TRIP */}
            <MenuItem onClick={() => onMenuItemClickedHandler("ON_TRIP")}>
              <ListItemIcon className={classes.icon}>
                <InUseIcon style={{ color: "blue" }} />
              </ListItemIcon>
              <Typography>On Trip</Typography>
            </MenuItem>
            {/* DEACTIVATED */}
            <MenuItem onClick={() => onMenuItemClickedHandler("DEACTIVATED")}>
              <ListItemIcon className={classes.icon}>
                <DeactivatedIcon style={{ color: "black" }} />
              </ListItemIcon>
              <Typography>Deactivated</Typography>
            </MenuItem>
            {/* ALL_ACTIVE */}
            <MenuItem onClick={() => onMenuItemClickedHandler("ALL_ACTIVE")}>
              <ListItemIcon className={classes.icon}>
                <UndefinedIcon style={{ color: "black" }} />
              </ListItemIcon>
              <Typography>All Active</Typography>
            </MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}

export default withStyles(styles)(AssetStateFilter);
