export const styles = () => ({
  root: {
    minWidth: 300,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold"
  },
  description: {
    fontSize: 16,
  },
});