import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Grid, Tooltip, Button } from "@material-ui/core";

import { styles } from "./SeatBattery.styles";

function SeatBattery({ classes, onClick, disabled }) {
  return (
    <Tooltip title={"unlock"}>
      <Grid container className={classes.root} direction="row" alignItems="center">
        <Grid item>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            size="small"
            disabled={disabled}
            onClick={onClick}
          >
            Seat + Battery
          </Button>
        </Grid>
      </Grid>
    </Tooltip>
  );
}

export default withStyles(styles)(SeatBattery);
