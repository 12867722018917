import { wsCall, requestHelper } from "../../../app/coreSlice";

const operatorSetDefaultLocCall = async ({ operatorGetDefaultLoc }, dispatch, state, locationId) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "OPERATOR/SET_DEFAULT_LOC");
  let result;
  try {
    result = await wsCall({
      type: "OPERATOR",
      subType: "SET_DEFAULT_LOC",
      locationId: selectedLocation?.id,
      message: {
        locationId,
      },
    });

    if (!result.error) {
      dispatch(operatorGetDefaultLoc());
    } else {
      rh.error(result?.description || "Error setting user default location.");
    }
  } catch (_ex) {
    rh.error("Error setting user default location.");
  }
  rh.close();
  return result;
};

export default operatorSetDefaultLocCall;
