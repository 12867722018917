const styles = theme => ({
	root: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		height: '100%',
		padding: '5px',
		position: 'relative',
		width: '100%',
	},

	title: {
		fontSize: '16px',
		gridColumn: '1 / span 4',
		gridRow: '1',
		margin: '5px 0',
	},

	section: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
	},

	sectionGrid: {
		display: 'grid',
		gridTemplateColumns: '23% 23% 23% 23%',
		flex: 1,
		gap: '2%',
		alignContent: 'flex-start',
	},

	thumbnail:{
		width: '100%',
		height: 'auto',
	},

	text: {
		fontSize: '14px',
		margin: '10px 0',
	},
});

export default styles;