import React from 'react';
import Detail from './Detail';
import DetailsDiv from '../../../UI/DetailsDiv/DetailsDiv';
import DetailsRow from '../../../UI/DetailsDiv/DetailsRow/DetailsRow';
import {
	withStyles,
} from '@material-ui/core';
import {
	Check as CheckIcon,
} from '@material-ui/icons';
import { dateFormatter } from '../../../../services/formatter';
import styles from './Details.styles';

const Others = ({
	classes,
	info,
}) => {

	const others = (
		<DetailsDiv title='Others'>
			{!!info.registrationDate && <DetailsRow
				label='Registration Date'
				detail={
					<Detail detail={dateFormatter.format(info.registrationDate, 'date')} />
				}
			/>}
			<DetailsRow
				label='Activation date'
				detail={
					<Detail detail={!!info.activationDate 
						? dateFormatter.format(info.activationDate, 'date')
						: '-'
					} />
				}
			/>
			<DetailsRow
				label='Enabled'
				detail={
					info.available ? <CheckIcon className={classes.checkIcon} /> : ' '
				}
			/>
			<DetailsRow
				label='Onboarded'
				detail={
					info.onboarded ? <CheckIcon className={classes.checkIcon} /> : ' '
				}
			/>
			<DetailsRow
				label='Agent'
				detail={
					info.isAgent ? <CheckIcon className={classes.checkIcon} /> : ' '
				}
			/>
			<DetailsRow
				label='Terms and conditions'
				detail={
					!!info.termsVersion ? <CheckIcon className={classes.checkIcon} /> : ' '
				}
			/>
		</DetailsDiv>
	);

	return others;
};

export default withStyles(styles)(Others);
