import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import carclubService from '../../../services/carclub';
import customerService from '../../../services/customer/ws';
import { useCancellablePromise } from
	'../../../hooks/useCancellablePromise/useCancellablePromise.jsx';
import log from '../../../utils/logger';
import Users from './Users';
import withPermission from '../../../hoc/withPermission';

const logger = log('UsersWrapper');

const UsersWrapper = props => {
	//states
	const isMounted = useRef(true);
	const buttonTimeout = useRef(null);
	const [filter, setFilter] = useState('');
	const [filterHandler, setFilterHandler] = useState({ string: '', reset: false, page: 1 });
	const [isEditing, setIsEditing] = useState();
	const [list, setList] = useState({ users: {}, lastPage: true });
	const [resetPasswordModal, setResetPasswordModal] = useState();
	const [roles, setRoles] = useState();
	const [selected, setSelected] = useState();
	const [disableButtons, setDisableButtons] = useState();

	const onSelect = useCallback(async userCode => {
		if (selected === userCode){
			return;
		};
		if (typeof userCode === 'undefined') {
			setSelected();
			return;
		}

		try {
			const user = await customerService.getCarclubUserDetails(userCode);

			setList(prev => ({lastPage: prev.lastPage, users: Object.values(prev?.users || {})
				.reduce((obj, prevUser) => prevUser.code === userCode
					? {...obj, [prevUser.code]: {...prevUser, ...user}} 
					: {...obj, [prevUser.code]: {...prevUser}}, {})}));
			setSelected(userCode);
		} catch (error) {
			logger.warn('Unable to retrieve user details.');
		}
	}, [selected]);

	/**
	 * Getting roles on mount @roles
	 */
	useEffect(() => {
		const getRoles = async () => {
			try {
				const [roles] = await carclubService.getRoles("");
				setRoles(Object.values(roles));
			}
			catch (error) {
				logger.warn('Could not get roles');
			};
		};
		getRoles();
	}, []);

	/** onMount gets users list
	** search will happen whenever the filterHandler is changed
	** it will only be changed on @onSearch or @onReset
	**/
	useEffect(() => {
		const getList = async () => {
			try {
				const response = await carclubService.getUsers(filterHandler);

				setList(prev => filterHandler.reset
					? { ...response }
					: {
						...response,
						users: { ...prev.users, ...response.users },
					});
			}
			catch (error) {
				logger.warn('Could not get userslist');
			}
		};

		getList();
	}, [filterHandler]);

	const onSearch = useCallback(async reset => {
		setFilterHandler(prev => ({
			reset: !!reset,
			string: filter,
			page: reset ? 1 : prev.page + 1,
		}));
	}, [filter]);

	//clickHandlers
	const { makeCancellablePromise } = useCancellablePromise();

	useEffect(() => {

		const cleanup = () => {
			clearTimeout(buttonTimeout.current);
			isMounted.current = false;
		};
		return cleanup;
	}, [buttonTimeout]);

	const onSingleClick = () => {

		if (!buttonTimeout.current) {

			const timerId = setTimeout(() => {
				buttonTimeout.current = null;
				makeCancellablePromise(onSearch(true))
					.catch(error => logger.debug(error));
			}, process.env.REACT_APP_SINGLECLICK_TIMEOUT);

			buttonTimeout.current = timerId;
		};
	};

	//this is required for the doubleClick cancellablePromise
	const onReset = useCallback(() => {
		setFilterHandler({ reset: true, string: '' });
		setFilter('');
	}, []);

	const onDoubleClick = () => {

		if (!!buttonTimeout.current) {
			clearTimeout(buttonTimeout.current);
			buttonTimeout.current = null;
		};

		makeCancellablePromise(onReset())
			.catch(error => logger.debug(error));
	};

	//on list Update, update @user
	const onUpdate = useCallback(user => {
		if (!user.code) {
			return;
		};

		setList(prev =>
			({ ...prev, users: { ...prev.users, [user.code]: { ...user } } }));
	}, []);

	//on list Remove, remove @user
	const onRemove = useCallback(code => {
		if (!code) {
			return;
		};

		const users = { ...list.users };
		delete users[code];
		setList(prev => ({ ...prev, users }));
	}, [list]);

	const onResetPassword = useCallback(async () => {
		setDisableButtons(true);
		try {
			await carclubService.resetUserPassword(selected);
			setResetPasswordModal();
		}
		catch{
			logger.warn(`Could not reset ${selected} password`);
			setResetPasswordModal(`Could not reset password. Try Again?`);
		} finally {
			setDisableButtons();
		};
	}, [selected]);

	return (
		<Users
			{...props}
			disableButtons={disableButtons}
			filter={filter}
			isEditing={isEditing}
			list={list}
			onDoubleClick={onDoubleClick}
			onRemove={onRemove}
			onResetPassword={onResetPassword}
			onSearch={onSearch}
			onSingleClick={onSingleClick}
			onUpdate={onUpdate}
			resetPasswordModal={resetPasswordModal}
			roles={roles}
			selected={selected}
			setFilter={setFilter}
			setIsEditing={setIsEditing}
			setResetPasswordModal={setResetPasswordModal}
			setSelected={onSelect}
		/>
	);
};

const permissions = [{ resource: ['carclub', 'users', 'read'] }];

export default withPermission(permissions)(UsersWrapper);