const styles = theme => ({

	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		overflowY: 'hidden',
	},

	workspace: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		flexWrap: 'no-wrap',
		position: 'relative',
	},

});

export default styles;