import createModel from '../createModel';

class Task {
	constructor({
		code,
		comments,
		description,
		driver,
		driverName,
		endDate,
		endLatitude,
		endLongitude,
		endTime,
		pictures,
		startDate,
		startLatitude,
		startLongitude,
		startTime,
		status,
		type,
	} = {}) {
		createModel.call(this, {
			code,
			comments,
			description,
			driver,
			driverName,
			endDate,
			endLatitude,
			endLongitude,
			endTime,
			pictures,
			startDate,
			startLatitude,
			startLongitude,
			status,
			type,
			startTime,
		});
	};
};

export default Task;