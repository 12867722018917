const styles = (theme) => ({

	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		flexBasis: '66%',
		flexWrap: 'nowrap',
		position: 'relative',
	},

	list: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		overflow: 'auto',
		scrollbarWidth: 'thin',
		//CHROME
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},

	details: {
		minWidth: '284px',
		maxWidth: '284px',
		paddingRight: 21,
		overflowY: 'auto',
	},

	header: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingBottom: theme.spacing(1),
	},

	titleAndSearch: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	selectedRateDiv: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		borderBottom: '2px solid rgba(0, 0, 0, 0.20)',
		marginBottom: '8px',
		paddingBottom: '8px',
	},

	title: {
		fontSize: '22px',
		marginRight: 16,
	},

	searchBar: {
		width: '200px',
		display: 'inline-flex',
		paddingBottom: 4,
	},

		
	createIcon: {
		padding: '8px 0 8px 0',
		cursor: 'pointer',
		width: 26,
		height: 26,
	},

	createButton: {
		display: 'flex',
		flexDirection: 'row-reverse',
		width: 'auto',
		alignItems: 'center',
	},

	searchIcon: {
		margin: '10px 0',
	},

	inputRoot: {
		color: 'inherit',
		width: '100%',
	},

	inputInput: {
		paddingTop: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		transition: theme.transitions.create('width'),
		width: '100%',
		borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
		color: '#000',
	},
	
	crossIcon: {
		cursor: 'pointer',
	},

	rows: {
		overflowY: 'visible',
	},
});

export default styles;