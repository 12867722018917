
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import extraMapper from './mappers/extraMapper';
import { publishAndAwait } from '../../../app/coreSlice';

export const listAdditionalFees = async ({ filter, page }) => {


	const nameValue = typeof filter === 'string' &&
		filter.split('').length > 0
		? filter : null;

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'BILLING',
			subType: 'LIST_ADDITIONAL_FEES',
			message: {
				name: nameValue,
				page,
				limit: 25,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (error) {
		return;
	}

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}

	const {
		lastPage,
		additionalFeesList,
	} = processMessage || {};

	const modelArray = Array.isArray(additionalFeesList)
		&& additionalFeesList.map(rateDTO => extraMapper.fromDTO(rateDTO));

	return {
		list: modelArray,
		lastPage,
	};
};

export default listAdditionalFees;