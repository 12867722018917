const styles = (theme) => ({

	root: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		[theme.breakpoints.up(400 + theme.spacing(6))]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},

	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
	},
  
	Logo: {
		width: '100%',
		margin: "30px auto",
	},

	link: {
		display: 'flex',
	},

	hyperlink: {
		cursor: 'pointer',
		textDecoration: 'underline',
		'&:hover':{
			color: '#1AC',
		},
	},
});

export default styles;