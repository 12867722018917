import addTask from './addTask';
import closeWorkorder from './closeWorkorder';
import createTask from './createTask';
import getPriorities from './getPriorities';
import getServicePoint from './getServicePoint';
import getTasks from './getTasks';
import getTaskTypes from './getTaskTypes';
import getTypes from './getTypes';
import listServicePoints from './listServicePoints';
import listWorkorders from './listWorkOrders';
import removeTask from './removeTask';
import removeWorkorder from './removeWorkorder';
import updateWorkorder from './updateWorkOrder';
import upsertTemplate from './upsertTemplate';
import deleteTemplate from './deleteTemplate';
import deleteTaskType from './deleteTaskType';
import listAgents from './listAgents';

const workOrderService = {
	addTask,
	closeWorkorder,
	createTask,
	getPriorities,
	getServicePoint,
	getTasks,
	getTaskTypes,
	getTypes,
	listAgents,
	listServicePoints,
	listWorkorders,
	removeTask,
	removeWorkorder,
	updateWorkorder,
	upsertTemplate,
	deleteTemplate,
	deleteTaskType,
};

export default workOrderService;