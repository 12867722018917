import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const billingSettleCall = async (
  { setSelectedTripPaymentDetailsBillingPaid, updateSelectedTrip, updateSingleClosedReservation },
  dispatch,
  state,
  { reservationCode }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;

  let rh = requestHelper(dispatch, "BILLING/SETTLE");
  try {
    const result = await wsCall({
      type: "BILLING",
      subType: "SETTLE",
      locationId: selectedLocation?.id,
      message: {
        reservationCode
      },
    });
    if (result) {
      if (result.paid) {
        dispatch(setSuccess("Trip billing settled successfully!"));
        dispatch(setSelectedTripPaymentDetailsBillingPaid());
        dispatch(updateSelectedTrip({ paid: result.paid }));
        dispatch(updateSingleClosedReservation({ paid: result.paid, code: result.reservationCode }));
      }
    }
  } catch (_ex) {
    rh.error("Error settling billing.");
  }
  rh.close();
};

export default billingSettleCall;
