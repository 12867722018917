export const styles = () => ({
  root: {
    height: 400,
    padding: 0,
    overflowY: "auto",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "21px",
      boxShadow: "inset 0 0 21px rgba(0,0,0,.3)",
    },
    "& .MuiListItem-root": {
      padding: 0,
      paddingLeft: 5,
      paddingRight: 10,
    },
  },
  loader: {
    padding: "10px !important",
    display: "block",
    textAlign: "center",
  },
});
