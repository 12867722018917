export const styles =theme => ({

	root: {

	},

	selected: {
		animation: '$blob 0.5s',
	},

	'@keyframes blob': {
		'0%': {
			width: 30,
			height: 30,
		},
		'49%': {
			width: 40,
			height: 40,
		},
		'100%': {
			width: 30,
			height: 30,
		},
	},

});