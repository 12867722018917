import React from 'react';
import List from '../../../UI/List/List';
import TripsItem from './TripsItem/TripsItem';
import tripsListConfig from './tripsListConfig';
import {
	withStyles,
} from '@material-ui/core';
import styles from './TripsList.styles';

const TripsList = ({
	classes,
	currentScreenSize,
	getMoreRows,
	onSort,
	trips,
}) => {

	const list = !!trips && trips.map((trip, index) => 
		<TripsItem
			trip={trip}
			key={'trip_' + index}
		/>
	);

	const root = (
		<List
			className={classes.root}
			headerFields={tripsListConfig(currentScreenSize, onSort)}
			getMoreRows={getMoreRows}
		>
			{list}
		</List>
	);

	return root;
};

export default withStyles(styles)(TripsList);