const styles = theme => ({

	title: {
		display: 'flex',
		width: '100%',
		alignItems: 'baseline',
	},

	mainTitle: {
		fontSize: '20px',
		marginRight: '10px',
	},

	icons: {
		display: 'flex',
		justifyContent: 'flex-end',
		flexGrow: 1,
	},

	colorRed: {
		color: 'red',
	},

	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: '100%',
	},

	selectEmpty: {
		marginBottom: theme.spacing(1),
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(2),
		minWidth: '90px',
	},

});

export default styles;