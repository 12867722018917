import { wsCall, requestHelper } from "../../../app/coreSlice";

const crParkGetAssetsCall = async ({ setCrAssets }, dispatch, state, { parkId, filter, startDate, endDate }) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "PARK/GET_ASSETS");
  try {
    const result = await wsCall({
      type: "PARK",
      subType: "GET_ASSETS",
      locationId: selectedLocation?.id,
      message: {
        parkId,
        filter,
        start: startDate,
        end: endDate,
        available: true,
      },
    });
    if (result) {
      dispatch(setCrAssets(result));
    }
  } catch (_ex) {
    rh.error("Error getting assets.");
  }
  rh.close();
};

export default crParkGetAssetsCall;
