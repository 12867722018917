import React from 'react';
import MembersItem from './MembersItem/MembersItem';
import List from '../../../UI/List/List';
import membersListConfig from './membersListConfig';
import {
	withStyles,
} from '@material-ui/core';
import styles from './MembersList.styles';
import classnames from 'classnames';

const MembersList = ({
	addMember,
	classes,
	className,
	currentScreenSize,
	drivers,
	members,
	onSelect,
	removeMember,
	selected,
	showCustomerTrips,
}) => {

	const details = !!members && members
		.map(member => (
			<MembersItem
				addMember={addMember}
				isMember={true}
				key={member.code}
				member={member}
				onSelect={onSelect}
				removeMember={removeMember}
				selected={!!selected && selected.code === member.code}
				showCustomerTrips={showCustomerTrips}
			/>
		));

	const driverDetails = !!drivers && drivers
		.map(driver => (
			<MembersItem
				addMember={addMember}
				isMember={false}
				key={driver.code}
				member={driver}
				onSelect={onSelect}
				removeMember={removeMember}
				selected={!!selected && selected.code === driver.code}
			/>
		));

	const list = (
		<List
			className={classnames(classes.root, className)}
			headerFields={membersListConfig(currentScreenSize)}
		>
			{details}
			{driverDetails}
		</List>
	);

	return list;
};

export default withStyles(styles)(MembersList);