import React from 'react';
import Detail from './Detail';
import DetailsDiv from '../../../UI/DetailsDiv/DetailsDiv';
import DetailsRow from '../../../UI/DetailsDiv/DetailsRow/DetailsRow';
import withStyles  from '@material-ui/styles/withStyles';
import styles from './Details.styles';

const Consumption = ({
	classes,
	info,
}) => {

	const {
		totalConsumption,
		totalPaid,
		dueAmount,
		dueDate,
		average,
		totalTrips,
	} = info || {};

	const totalConsumptionField = !!totalConsumption && (
		<DetailsRow
			label='Total Consumption'
			detail={
				<Detail detail={totalConsumption} />
			}
		/>
	);

	const totalPaidField = !!totalPaid && (
		<DetailsRow
			label='Total Paid'
			detail={
				<Detail detail={totalPaid} />
			}
		/>
	);

	const dueAmountField = !!dueAmount && (
		<DetailsRow
			label='Due Amount'
			detail={
				<Detail detail={dueAmount} />
			}
		/>
	);

	const dueDateField = !!dueDate && (
		<DetailsRow
			label='Due Date'
			detail={
				<Detail detail={dueDate} />
			}
		/>
	);

	const averageField = !!average && (
		<DetailsRow
			label='Average'
			detail={
				<Detail detail={average} />
			}
		/>
	);

	const totalTripsField = !!totalTrips && (
		<DetailsRow
			label='Total Trips'
			detail={
				<Detail detail={totalTrips} />
			}
		/>
	);

	const consumption = (
		<DetailsDiv title='Consumption'>
			{totalConsumptionField}
			{totalPaidField}
			{dueAmountField}
			{dueDateField}
			{averageField}
			{totalTripsField}
		</DetailsDiv>
	);

	return consumption;
};

export default withStyles(styles)(Consumption);