import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const billingInvoiceSingleTripCall = async (
  { setSelectedTripPaymentDetailsInvoice },
  dispatch,
  state,
  { reservationCode, customerCode }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;

  let rh = requestHelper(dispatch, "BILLING/INVOICE_SINGLE_TRIP");
  try {
    const result = await wsCall({
      type: "BILLING",
      subType: "INVOICE_SINGLE_TRIP",
      locationId: selectedLocation?.id,
      message: {
        reservationCode,
        customerCode,
      },
    });
    if (result) {
      dispatch(setSuccess("Invoice created successfully!"));
      dispatch(setSelectedTripPaymentDetailsInvoice(result));
    }
  } catch (_ex) {
    rh.error("Error creating invoice.");
  }
  rh.close();
};

export default billingInvoiceSingleTripCall;
