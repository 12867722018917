import React, { useState, useEffect } from "react";
import Header from "./Header/Header";
import TripInfo from "./TripInfo/TripInfoWrapper";
import TimelineInfo from "./TimelineInfo/TimelineWrapper";
import RatingInfo from "./RatingInfo/RatingInfoWrapper";
import { Tabs, Tab, withStyles } from "@material-ui/core";
import { styles } from "./TripDetails.styles";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { selectDefaultLocation } from "../../../features/carclub/carclubSlice";
import PaymentTab from "../../../features/reservation/ReservationDetails/PaymentTab/PaymentTab";
import { isSharing, isSubscription } from "../../../common/utils/OTypes";

const TRIP_TAB = "TRIP_TAB";
const PAYMENT_TAB = "PAYMENT_TAB";
const TIMELINE_TAB = "TIMELINE_TAB";
const RATINGS_TAB = "RATINGS_TAB";

const TripDetails = ({
  classes,
  className,
  removeTrip,
  trip,
  tripType,
  updateTrip,
  updateHistoryTrip,
  setModalUpdate,
}) => {
  const selectedLocation = useSelector(selectDefaultLocation);

  const [tab, setTab] = useState(TRIP_TAB);

  useEffect(() => {
    setTab(TRIP_TAB);
  }, [trip.code]);

  const tripInfo = (
    <TripInfo
      removeTrip={removeTrip}
      trip={trip}
      tripType={tripType}
      updateTrip={updateTrip}
      setModalUpdate={setModalUpdate}
    />
  );

  const paymentInfo = !trip?.isAgent && <PaymentTab />;

  const timelineInfo = <TimelineInfo trip={trip} tripType={tripType} />;

  const ratingInfo = <RatingInfo trip={trip} tripType={tripType} />;

  const tabMap = {
    TRIP_TAB: tripInfo,
    PAYMENT_TAB: paymentInfo,
    TIMELINE_TAB: timelineInfo,
    RATINGS_TAB: ratingInfo,
  };
  const currentTab = tabMap[tab];

  const hasRating = trip && "CLOSED" === trip.state;

  const tabBar = (
    <div className={classes.tabContainer}>
      <Tabs
        className={classes.horizontalMenu}
        value={false}
        onChange={(e, value) => (value === PAYMENT_TAB && trip?.isAgent ? undefined : setTab(value))}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab
          classes={{
            root: classnames(classes.horizontalMenuOptions, tab === TRIP_TAB && classes.selectedTab),
          }}
          value={TRIP_TAB}
          label={"Trip Details"}
          selected={tab === TRIP_TAB}
        />
        {!trip?.isAgent && (isSharing(selectedLocation.operationType) || isSubscription(selectedLocation.operationType)) &&(
          <Tab
            classes={{
              root: classnames(classes.horizontalMenuOptions, tab === PAYMENT_TAB && classes.selectedTab),
            }}
            value={PAYMENT_TAB}
            label={"Payments"}
            selected={tab === PAYMENT_TAB}
          />
        )}
        <Tab
          classes={{
            root: classnames(classes.horizontalMenuOptions, tab === TIMELINE_TAB && classes.selectedTab),
          }}
          value={TIMELINE_TAB}
          label={"Timeline"}
          selected={tab === TIMELINE_TAB}
        />
        {hasRating && isSharing(selectedLocation.operationType) && (
          <Tab
            classes={{
              root: classnames(classes.horizontalMenuOptions, tab === RATINGS_TAB && classes.selectedTab),
            }}
            value={RATINGS_TAB}
            label={"Ratings"}
            selected={tab === RATINGS_TAB}
          />
        )}
      </Tabs>
    </div>
  );

  // console.log("trip: %o", trip);

  return (
    <div className={classnames(classes.root, className)}>
      <Header trip={trip} />
      {tabBar}
      <div className={classes.tabContent}>{currentTab}</div>
    </div>
  );
};

export default withStyles(styles)(TripDetails);
