import React from 'react';
import ListItem from '../../../../UI/List/ListItem/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/styles/withStyles';
import TrueIcon from '@material-ui/icons/Check';
import UpdateIcon from '@material-ui/icons/Create';
import EnableIcon from '@material-ui/icons/RemoveCircleOutline';
import moment from 'moment';
import { dateFormatter } from '../../../../../services/formatter';
import withPermission from '../../../../../hoc/withPermission';
import styles from './Row.styles';
import classnames from 'classnames';

const Row = ({
	banner,
	classes,
	disableButtons,
	fields,
	onDelete,
	onEdit,
	onEnable,
	onSelect,
	selected,
}) => {
	const isEnabled = banner?.endDate ? moment(banner.endDate).isBefore(moment.now()) : true;
	// const enableIcon = (
	// 	<Tooltip title={isEnabled ? 'Disable' : 'Enable'} placement='left'>
	// 		<TrueIcon
	// 			onClick={() => onEnable({ ...banner, enabled: isEnabled })}
	// 			className={classnames(classes.editIcon,
	// 				(disableButtons || !isEnabled) && classes.disabledButton)}
	// 		/>
	// 	</Tooltip>
	// );

	const deleteIcon = (
		<Tooltip title='Remove' placement='left'>
			<EnableIcon
				onClick={() => !disableButtons && onDelete(banner)}
				className={classnames(classes.editIcon, disableButtons && classes.disabledButton)}
			/>
		</Tooltip>
	);

	const customerUpdate = (
		<Tooltip title='Update' placement='left'>
			<UpdateIcon
				onClick={() => !disableButtons && onEdit(banner.id)}
				className={classnames(classes.editIcon, disableButtons && classes.disabledButton)}
			/>
		</Tooltip>
	);

	const icons = (
		<ActionButtons>
			{deleteIcon}
			{/* {enableIcon} */}
			{customerUpdate}
		</ActionButtons>
	);

	const enabled = isEnabled && <TrueIcon className={classes.checkIcon} />;

	const createDate = !!banner.createDate
		&& dateFormatter.format(banner.createDate, 'dateTime');

	const startDate = !!banner.startDate
		&& dateFormatter.format(banner.startDate, 'dateTime');

	const endDate = !!banner.endDate
		&& dateFormatter.format(banner.endDate, 'dateTime');

	const item = (
		<ListItem
			selected={selected}
			fields={fields}
			onSelect={() => onSelect(banner.id)}
			element={{
				...banner,
				createDate,
				enabled,
				endDate,
				icons,
				startDate,
			}}
		/>
	);

	return item;
};

const ActionButtons = withPermission(
	[{ resource: ['carclub', 'banners', 'edit'] }]
)(({
	children,
}) =>
	(
		<>
			{children}
		</>
	));

export default withStyles(styles)(Row);