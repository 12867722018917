import React from 'react';
import {
	Tooltip,
	Typography,
	withStyles,
} from '@material-ui/core';
import styles from './DetailsRow.styles';
import classnames from 'classnames';

const DetailsRow = ({
	classes,
	className,
	column,
	detail,
	label,
	tooltip,
}) => {

	const title = (
		<Typography
			className={classnames(classes.title, className && className.title)}
		>
			{label}
		</Typography>
	);

	const row = (
		<Tooltip title={tooltip || ''} placement='top'>
			<div className={classnames(classes.root, !!column && classes.rootColumn)}>
				<div className={classes.label}>
					{title}
				</div>
				<div className={classnames(classes.detail, !!column && classes.detailColumn)}>
					{detail}
				</div>
			</div>
		</Tooltip>
	);

	return row;
};

export default withStyles(styles)(DetailsRow);
