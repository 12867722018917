import React from "react";
import { Grid, Card, CardContent } from "@material-ui/core";

export default function InvoiceConfigPreview({
	classes,
	invoiceConfigurations,
}) {
	return (
		<Card className={classes.preview}>
			<CardContent>
				<Grid container justifyContent="space-between" alignItems="flex-start">
					<Grid item xs={6}>
						<Grid container direction="column" alignItems="flex-start">
							<img
								src={invoiceConfigurations?.carclubInvoiceLogoUrl}
								alt="logo"
								className={classes.previewPic}
							/>
							<Grid
								className={classes.previewTopLeftInfo}
								container
								direction="column"
								alignItems="flex-start"
							>
								<b>Company Name</b>
								<br />
								<div>Company Address</div>
								<br />
								<div>{invoiceConfigurations?.extraInfo}</div>
								<div>{invoiceConfigurations?.vatTitle}: 000000000</div>
								<br />
								<div>{invoiceConfigurations?.toTitle}:</div>
								<b>Customer Name</b>
								<br />
								<div>Customer Address</div>
								<div>{invoiceConfigurations?.vatTitle}: 000000000</div>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={6}>
						<Grid container direction="column" alignItems="flex-end">
							<div className={classes.previewHeader}>
								{invoiceConfigurations?.header}
							</div>
							<div>{invoiceConfigurations?.invoiceNumberTitle} 00001</div>
						</Grid>
						<br />
						<br />
						<br />
						<Grid container direction="column" alignItems="stretch">
							<Grid item xs={12}>
								<Grid
									container
									direction="row"
									justifyContent="flex-end"
									alignItems="center"
								>
									<Grid item xs={6}>
										<div className={classes.previewDateTitle}>
											{invoiceConfigurations?.dateTitle}
										</div>
									</Grid>
									<Grid item xs={6}>
										<div className={classes.previewDateTitleValue}>
											00-00-0000
										</div>
									</Grid>
								</Grid>
								<Grid
									container
									direction="row"
									justifyContent="flex-end"
									alignItems="center"
								>
									<Grid item xs={6}>
										<div className={classes.previewDateTitle}>
											{invoiceConfigurations?.paymentTermsTitle}
										</div>
									</Grid>
									<Grid item xs={6}>
										<div className={classes.previewDateTitleValue}>
											{invoiceConfigurations?.paymentTermsPaid} /{" "}
											{invoiceConfigurations?.paymentTermsIssued}
										</div>
									</Grid>
								</Grid>
								<Grid
									container
									direction="row"
									justifyContent="flex-end"
									alignItems="center"
								>
									<Grid item xs={6}>
										<div className={classes.previewDateTitle}>
											{invoiceConfigurations?.dueDateTitle}
										</div>
									</Grid>
									<Grid item xs={6}>
										<div className={classes.previewDateTitleValue}>
											00-00-0000
										</div>
									</Grid>
								</Grid>
								<Grid
									container
									direction="row"
									justifyContent="flex-end"
									alignItems="center"
								>
									<Grid item xs={6}>
										<div className={classes.previewBalanceTitle}>
											{invoiceConfigurations?.balanceTitle}
										</div>
									</Grid>
									<Grid item xs={6}>
										<div className={classes.previewBalanceTitleValue}>
											{invoiceConfigurations?.currency} 0.00
										</div>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<br />
				<br />
				<br />
				<Grid container direction="row" className={classes.previewTableHeader}>
					<Grid item xs={6}>
						<div>{invoiceConfigurations?.itemHeader}</div>
					</Grid>
					<Grid item xs={2}>
						<div className={classes.alignRight}>
							{invoiceConfigurations?.quantityHeader}
						</div>
					</Grid>
					<Grid item xs={2}>
						<div className={classes.alignRight}>
							{invoiceConfigurations?.unitCostHeader}
						</div>
					</Grid>
					<Grid item xs={2}>
						<div className={classes.alignRight}>
							{invoiceConfigurations?.amountHeader}
						</div>
					</Grid>
				</Grid>
				<Grid container direction="row" className={classes.previewTableItem}>
					<Grid item xs={6}>
						<b>{invoiceConfigurations?.reservationTitle} 000000000</b>
					</Grid>
					<Grid item xs={2}>
						<div className={classes.alignRight}>0</div>
					</Grid>
					<Grid item xs={2}>
						<div className={classes.alignRight}>{invoiceConfigurations?.currency} 0.00</div>
					</Grid>
					<Grid item xs={2}>
						<div className={classes.alignRight}>{invoiceConfigurations?.currency} 0.00</div>
					</Grid>
				</Grid>
				<Grid container direction="column" className={classes.previewTableItem}>
					<div className={classes.grey}>Asset designation</div>
					<div className={classes.grey}>
						{invoiceConfigurations?.startDateTitle}: 0000-00-00 00:00 |{" "}
						{invoiceConfigurations?.endDateTitle}: 0000-00-00 00:00
					</div>
					<div className={classes.grey}>
						{invoiceConfigurations?.timeInUseTitle}: 0 m |{" "}
						{invoiceConfigurations?.timeStandByTitle}: 0 m |{" "}
						{invoiceConfigurations?.distanceTitle}: 0{" "}
					</div>
					<div className={classes.grey}>
						{invoiceConfigurations?.totalCostTitle}: 0.00{" "}
						{invoiceConfigurations?.currency} -{" "}
						{invoiceConfigurations?.includedVatTitle}{" "}
						{invoiceConfigurations?.vatPercent}% - 0.00{" "}
						{invoiceConfigurations?.currency}
					</div>
				</Grid>
				<br />
				<br />
				<br />
				<Grid container direction="row" className={classes.previewTableItem}>
					<Grid item xs={6}></Grid>
					<Grid item xs={3}>
						<div className={classes.alignRightGrey}>
							{invoiceConfigurations?.totalTitle}
						</div>
					</Grid>
					<Grid item xs={3}>
						<div className={classes.alignRight}>{invoiceConfigurations?.currency} 0.00</div>
					</Grid>
				</Grid>
				<Grid container direction="row" className={classes.previewTableItem}>
					<Grid item xs={6}></Grid>
					<Grid item xs={3}>
						<div className={classes.alignRightGrey}>
							{invoiceConfigurations?.amountPaidTitle}
						</div>
					</Grid>
					<Grid item xs={3}>
						<div className={classes.alignRight}>{invoiceConfigurations?.currency} 0.00</div>
					</Grid>
				</Grid>
				<br />
				<br />
				<Grid container direction="column" className={classes.previewTableItem}>
					<div className={classes.grey}>
						{invoiceConfigurations?.notesTitle}:
					</div>
					<br />
					<br />
					<div>
						{invoiceConfigurations?.chargedTitle} 0.00{" "}
						{invoiceConfigurations?.currency} At 0000-00-00 00:00:00
					</div>
				</Grid>
				<br />
				<br />
				<br />
				<br />
				<br />
			</CardContent>
		</Card>
	);
}
