import { createSlice } from "@reduxjs/toolkit";
import assetAddParksAndZonesCall from "./_sliceCalls/assetAddParksAndZones";
import assetAssetReservationCountsCall from "./_sliceCalls/assetAssetReservationCounts";
import assetAssociateActivateDeviceCall from "./_sliceCalls/assetAssociateActivateDevice";
import assetChangeLocationCall from "./_sliceCalls/assetChangeLocationCall";
import assetDetailsCall from "./_sliceCalls/assetDetails";
import assetListCall from "./_sliceCalls/assetList";
import assetList_singleCall from "./_sliceCalls/assetList_single";
import assetMakeAvailableCall from "./_sliceCalls/assetMakeAvailable";
import assetMakeUnavailableCall from "./_sliceCalls/assetMakeUnavailable";
import carclubZonesCall from "./_sliceCalls/carclubZones";
import carInfoCall from "./_sliceCalls/carInfo";
import doorsCloseCall from "./_sliceCalls/doorsClose";
import doorsOpenCall from "./_sliceCalls/doorsOpen";
import imoOffCall from "./_sliceCalls/imoOff";
import imoOnCall from "./_sliceCalls/imoOn";
import parkListCall from "./_sliceCalls/parkList";

export const assetSlice = createSlice({
  name: "assets",
  initialState: {
    selectedAssetToken: null,
    assetDetails: {},
    deviceAssociatedSuccessTrigger: null,
    zonesAndParksSuccessTrigger: null,
    carInfo: null,
    zones: [],
    parks: [],
    assets: {
      list: [],
      filter: undefined,
    },
    mapCenter: null, // probably good idea to create a feature only for map management
    assetsCounters: null,
    ui: {
      showActivityLog: false,
      showDamageReport: false,
      showAssetDetails: false,
      showAssetCommands: false,
      showAssetEdit: false,
      showMoveAssetModal: false,
    },
    showList: false,
    errorMovingAsset: null,
  },
  reducers: {
    setSelectedAssetToken: (state, action) => {
      // closes possible open previous selected Asset UI components
      Object.keys(state.ui || {}).forEach((key) => {
        state.ui[key] = false;
      });
      state.selectedAssetToken = action.payload;
    },
    setDeviceAssociatedSuccessTrigger: (state, action) => {
      state.deviceAssociatedSuccessTrigger = action.payload;
    },
    setZonesAndParksSuccessTrigger: (state, action) => {
      state.zonesAndParksSuccessTrigger = action.payload;
    },
    setCarInfo: (state, action) => {
      state.carInfo = action.payload;
    },
    setZones: (state, action) => {
      state.zones = action.payload;
    },
    setParks: (state, action) => {
      state.parks = action.payload;
    },
    setAssets: (state, action) => {
      state.assets.list = action.payload.list;
      state.assets.filter = action.payload.filter;
    },
    updateAssetOnList: (state, action) => {
      let nList = [];
      state.assets.list.forEach((asset) => {
        if (asset.assetToken === action.payload.assetToken) {
          nList.push({ ...asset, ...action.payload });
        } else {
          nList.push(asset);
        }
      });
      state.assets.list = nList;
    },
    changeAssetLocation: (state, action) => {
      let nList = state.assets.list.filter((asset) => asset.assetToken !== action.payload.assetToken);
      let nAsset = state.assets.list.find((asset) => asset.assetToken === action.payload.assetToken);
      nAsset.locationId = action.payload.locationId;
      state.assets.list = [...nList, nAsset];
    },
    setMapCenter: (state, action) => {
      if (action.payload?.lat && action.payload?.lng) {
        let mc = {
          lat: parseFloat(action.payload.lat.toFixed(6)),
          lng: parseFloat(action.payload.lng.toFixed(6)),
        };
        state.mapCenter = mc;
      }
    },
    setAssetsCounters: (state, action) => {
      state.assetsCounters = action.payload;
    },
    setShowActivityLog: (state, action) => {
      Object.keys(state.ui || {}).forEach((key) => {
        state.ui[key] = false;
      });
      state.ui.showActivityLog = action.payload;
    },
    setShowDamageReport: (state, action) => {
      Object.keys(state.ui || {}).forEach((key) => {
        state.ui[key] = false;
      });
      state.ui.showDamageReport = action.payload;
    },
    setShowAssetDetails: (state, action) => {
      Object.keys(state.ui || {}).forEach((key) => {
        state.ui[key] = false;
      });
      state.ui.showAssetDetails = action.payload;
    },
    setShowAssetCommands: (state, action) => {
      let nValue = action.payload === undefined ? !state.ui.showAssetCommands : action.payload;
      Object.keys(state.ui || {}).forEach((key) => {
        state.ui[key] = false;
      });
      state.ui.showAssetCommands = nValue;
    },
    setShowAssetEdit: (state, action) => {
      let nValue = action.payload === undefined ? !state.ui.showAssetEdit : action.payload;
      Object.keys(state.ui || {}).forEach((key) => {
        state.ui[key] = false;
      });
      state.ui.showAssetEdit = nValue;
    },
    setShowMoveAssetModal: (state, action) => {
      let nValue = action.payload === undefined ? !state.ui.showMoveAssetModal : action.payload;
      Object.keys(state.ui || {}).forEach((key) => {
        state.ui[key] = false;
      });
      state.ui.showMoveAssetModal = nValue;
    },
    setAssetDetails: (state, action) => {
      state.assetDetails = action.payload;
    },
    setShowList: (state, action) => {
      state.showList = action.payload;
    },
    setErrorMovingAsset: (state, action) => {
      state.errorMovingAsset = action.payload;
    },
  },
});

export default assetSlice.reducer;

export const {
  setSelectedAssetToken,
  setDeviceAssociatedSuccessTrigger,
  setZonesAndParksSuccessTrigger,
  setCarInfo,
  setZones,
  setParks,
  setAssets,
  updateAssetOnList,
  setMapCenter,
  setShowActivityLog,
  setShowDamageReport,
  setShowAssetDetails,
  setShowAssetCommands,
  setShowAssetEdit,
  setAssetDetails,
  setShowList,
  setShowMoveAssetModal,
  setErrorMovingAsset
} = assetSlice.actions;

// SELECTS
export const selectLoader = (state) => state.getIn(["core"])?.loader?.length > 0;
export const selectAssetListIsLoading = (state) => state.getIn(["core"])?.loader?.indexOf("ASSET/LIST") > -1;
export const selectSelectedAssetToken = (state) => state.getIn(["assets"])?.selectedAssetToken;
export const selectSelectedAsset = (state) => {
  let assets = state.getIn(["assets"])?.assets?.list || [];
  return assets.find((item) => item.assetToken === state.getIn(["assets"])?.selectedAssetToken);
};
export const selectDeviceAssociatedSuccessTrigger = (state) => state.getIn(["assets"])?.deviceAssociatedSuccessTrigger;
export const selectZonesAndParksSuccessTrigger = (state) => state.getIn(["assets"])?.zonesAndParksSuccessTrigger;
export const selectCarInfo = (state) => state.getIn(["assets"])?.carInfo;
export const selectZones = (state) => state.getIn(["assets"])?.zones;
export const selectWantedZones = (state) =>
  (state.getIn(["assets"])?.zones || []).filter((item) => item?.type === "NORMAL");
export const selectUnwantedZones = (state) =>
  (state.getIn(["assets"])?.zones || []).filter((item) => item?.type === "UNWANTED");
export const selectParks = (state) => state.getIn(["assets"])?.parks;
export const selectMapCenter = (state) => state.getIn(["assets"])?.mapCenter;
export const selectAssets = (state) => state.getIn(["assets"])?.assets?.list || [];
export const selectAssetsFilter = (state) => state.getIn(["assets"])?.assets?.filter || {};
export const selectAssetsCounters = (state) => state.getIn(["assets"])?.assetsCounters;
export const selectUI = (state) => state.getIn(["assets"])?.ui;
export const selectAssetDetails = (state) => state.getIn(["assets"])?.assetDetails;
export const selectShowList = (state) => Boolean(state.getIn(["assets"])?.showList);
export const selectErrorMovingAsset = (state) => state.getIn(["assets"])?.errorMovingAsset;

// CALLS
export const getCarInfo = () => async (dispatch, getState) => {
  carInfoCall(assetSlice.actions, dispatch, getState());
};

export const getZones = () => async (dispatch, getState) => {
  carclubZonesCall(assetSlice.actions, dispatch, getState());
};

export const getParks = () => async (dispatch, getState) => {
  parkListCall(assetSlice.actions, dispatch, getState());
};

export const assetAddParksAndZones =
  ({ carToken, deviceToken, licensePlate, unwanted, wanted, parkIds }) =>
  async (dispatch, getState) => {
    assetAddParksAndZonesCall(assetSlice.actions, dispatch, getState(), {
      carToken,
      deviceToken,
      licensePlate,
      unwanted,
      wanted,
      parkIds,
    });
  };

export const assetAssociateActivateDevice =
  ({ carToken, licensePlate, provider_token, serial_number, externalId }) =>
  async (dispatch, getState) => {
    assetAssociateActivateDeviceCall(assetSlice.actions, dispatch, getState(), {
      carToken,
      licensePlate,
      provider_token,
      serial_number,
      externalId,
    });
  };

export const assetList =
  ({ deviceLicensePlate, fuelLevel, assetState, assetType }) =>
  async (dispatch, getState) => {
    assetListCall(assetSlice.actions, dispatch, getState(), {
      deviceLicensePlate,
      fuelLevel,
      assetState,
      assetType,
    });
  };

export const assetListUpdateAsset =
  ({ deviceLicensePlate }) =>
  async (dispatch, getState) => {
    assetList_singleCall(assetSlice.actions, dispatch, getState(), { deviceLicensePlate });
  };

export const assetMakeUnavailable =
  ({ carToken, maintenance }) =>
  async (dispatch, getState) => {
    assetMakeUnavailableCall(assetSlice.actions, dispatch, getState(), { carToken, maintenance });
  };

export const assetMakeAvailable =
  ({ carToken }) =>
  async (dispatch, getState) => {
    assetMakeAvailableCall(assetSlice.actions, dispatch, getState(), { carToken });
  };

export const assetAssetReservationCounts = () => async (dispatch, getState) => {
  assetAssetReservationCountsCall(assetSlice.actions, dispatch, getState());
};

export const doorsClose =
  ({ carToken }) =>
  async (dispatch, getState) => {
    doorsCloseCall(assetSlice.actions, dispatch, getState(), { carToken });
  };

export const doorsOpen =
  ({ carToken }) =>
  async (dispatch, getState) => {
    doorsOpenCall(assetSlice.actions, dispatch, getState(), { carToken });
  };

export const imoOn =
  ({ carToken }) =>
  async (dispatch, getState) => {
    imoOnCall(assetSlice.actions, dispatch, getState(), { carToken });
  };

export const imoOff =
  ({ carToken }) =>
  async (dispatch, getState) => {
    imoOffCall(assetSlice.actions, dispatch, getState(), { carToken });
  };

export const assetDetails =
  ({ carToken }) =>
  async (dispatch, getState) => {
    assetDetailsCall(assetSlice.actions, dispatch, getState(), { carToken });
  };

export const assetChangeLocation =
  ({ carToken, locationId }) =>
  async (dispatch, getState) => {
    assetChangeLocationCall(assetSlice.actions, dispatch, getState(), { carToken, locationId });
  };
