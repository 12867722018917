const styles = theme => ({
	header: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		minHeight: 0,
	},

	text: {
		fontSize: '18px',
		color: '#009AC9',
	},

	disabled: {
		color: 'black',
	},

	membersIcon: {
		marginLeft: 'auto',
		cursor: 'pointer',
		color: '#009AC9',
	},

	dotIcon: {
		cursor: 'pointer',
		color: '#009AC9',
	},

	noMembers: {
		marginLeft: 'auto',
	},
});

export default styles;