import React, { useState, useCallback } from 'react';
import CreateModal from './CreateModal';

const CreateModalWrapper = ({
	onClose,
	onCreate,
	...props
}) => {

	const [placeholder, setPlaceholder] = useState({});
	const [hasRequest, setHasRequest] = useState(false);

	const onChange = useCallback(field =>
		setPlaceholder(prev => ({ ...prev, ...field })), []);

	const onSave = async () => {
		setHasRequest(true);
		await onCreate(placeholder);
		onClose();
		setPlaceholder({});
		setHasRequest(false);
	};

	return (
		<CreateModal
			{...props}
			hasRequest={hasRequest}
			onChange={onChange}
			onClose={onClose}
			onSave={onSave}
			placeholder={placeholder}
		/>
	);
};

export default CreateModalWrapper;