const styles = () => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
	},

	rootColumn: {
		flexDirection: 'column',
	},

	label: {
		textAlign: 'left',
		textTransform: 'capitalize',
	},

	title: {
		fontWeight: 'bold',
		lineHeight: '20px',
		fontSize: '10px',
	},

	detail: {
		textAlign: 'right',
		overflowWrap: 'break-word',
		maxWidth: '100%',
	},

	detailColumn: {
		textAlign: 'left',
		overflowWrap: 'break-word',
		maxWidth: '100%',
	},

});

export default styles;
