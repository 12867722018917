import {
	PARK_CLEAR_ELEMENTS,
	PARK_SET_ELEMENTS,
	PARK_SET_DETAILS,
} from './actionTypes';
import * as methods from '../../../utils/makeActionCreator';
import makeActionCreator from '../../../utils/makeActionCreator';
import parkService from '../../../services/park';
import log from '../../../utils/logger';

const logger = log('parks#actions');

export const parksSetElements = makeActionCreator(PARK_SET_ELEMENTS, 'elements');
export const parksSetDetails = makeActionCreator(PARK_SET_DETAILS, 'park');
export const parksClearElements = () => ({ type: PARK_CLEAR_ELEMENTS, method: methods.REPLACE });


export const requestParks = () => {

	return async (dispatch) => {

		dispatch(parksClearElements());

		try {

			const parksModelArray = await parkService.listPark();
			
			dispatch(parksSetElements(parksModelArray));
		} catch (error) {
			logger.info(error.message);
		}

	};
};

export const upsertPark = (data) => {

	return async (dispatch) => {

		try {

			const updatedPark = await parkService.upsertPark(data);
			
			dispatch(parksSetElements([updatedPark]));
		} catch (error) {
			logger.info(error.message);
		}

	};
};

export const deletePark = (code, setSelectedPark) => {
	return async (dispatch) => {
		try {
			await parkService.deletePark(code);

			setSelectedPark && setSelectedPark();
		} catch (error) {
			logger.info(error.message);
		};
	};
};