import React from 'react';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import styles from './Details.styles';

const Detail = ({
	classes,
	detail,
}) => {

	return (
		<Typography className={classes.root}>
			{detail}
		</Typography>
	);
};

export default withStyles(styles)(Detail);
