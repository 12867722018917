const styles = theme => ({
	root: {
		width: '100%',
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		minHeight: 0,
	},

	list: {
		display: 'flex',
		flexDirection: 'column',
		boxSizing: 'border-box',
		height: '100%',
		margin: '9px 0',
		overflowY: 'auto',
		scrollbarWidth: 'thin', //FIREFOX
		//CHROME
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},

	text: {
		fontSize: '14px',
		padding: '5px',
	},

	nickname: {
		padding: '0 5px',
		fontSize: '10px',
	},

	textColor: {
		color: '#fff',
	},

	message: {
		borderRadius: '10px',
		boxSizing: 'border-box',
		width: 'auto',
		height: 'auto',
		wordBreak: 'break-all',
		margin: '5px 25% 5px 0',
		backgroundColor: '#eee8',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},

	ownMessage: {
		margin: '5px 5px 5px 25%',
		backgroundColor: '#009AC9',
	},
});

export default styles;