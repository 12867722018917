const styles = () => ({
	root: {
	},

	editIcon: {
		color: '#009AC9',
		cursor: 'pointer',
		width: 18,
		height: 18,
	},

	disabled: {
		color: '#e5e5e5',
		cursor: 'default',
	},
});

export default styles;