import React, {
	useState,
} from 'react';
import withPermission from '../../../hoc/withPermission';
import Notifications from './Notifications';

const NotificationsWrapper = props => {

	const [currentScreen, setCurrentScreen] = useState(screens.EMAIL);
	const [editMode, setEditMode] = useState(false);

	return (
		<Notifications
			{...props}
			screens={screens}
			currentScreen={currentScreen}
			setCurrentScreen={setCurrentScreen}
			editMode={editMode}
			setEditMode={setEditMode}
		/>
	);
};

const permissions = [{ resource: ['carclub', 'notifications', 'read'] }];

export default withPermission(permissions)(NotificationsWrapper);

export const screens = {
	EMAIL: 'Email',
	SMS: 'SMS',
};