import Template from '../../../../model/WorkOrder/Template';

const fromDTO = ({
	carclubId,
	id,
	description,
	locationId,
	designation,
	priority,
	tasks,
	createDate,
	createUser,
	updateDate,
	updateUser,
}) => new Template({
	carclubCode: carclubId,
	code: id,
	description,
	locationCode: locationId,
	type: designation,
	priority,
	tasks,
	createDate,
	createUser,
	updateDate,
	updateUser,
});

const toDTO = ({
	...props
}) => ({
	...props,
});

const mapper = { fromDTO, toDTO };

export default mapper;