
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_REQUEST_IS_MALFORMED,
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import { publishAndAwait } from '../../../app/coreSlice';

const getAttachments = async customerCode => {

	if (typeof customerCode === 'undefined') {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	}

	/* if(customerCode){
		return [{
			type: 'DriversLicense',
			number: 666,
			expirationDate: 'TODO',
			front: 'https://jpn.up.pt/wp-content/uploads/2014/12/luisismael_dr.jpg',
			back: 'https://i.ytimg.com/vi/-CH7RezOzVQ/maxresdefault.jpg',
		}];
	}; */

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'CUSTOMER',
			subType: 'GET_ZERO_DOCUMENTS',
			message: {
				customerCode,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}
  
	return processMessage;
};

export default getAttachments;