const styles = theme => ({
	
	icon: {
		color: '#009AC9',
		cursor: 'pointer',
		width: 18,
		height: 18,
	},

	iconUnchecked: {
		color: '#707070',
	},

});

export default styles;