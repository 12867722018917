import React from 'react';
import ListItem from '../../../UI/List/ListItem/ListItem';
import {
	Tooltip,
	withStyles,
} from '@material-ui/core';
import withPermission from '../../../../hoc/withPermission';
import {
	Check as TrueIcon,
	Create as EditIcon,
	RemoveCircleOutline as RemoveIcon,
	Person as CustomerIcon,
	Map as DashboardIcon,
} from '@material-ui/icons';
import { currencyFormatter } from '../../../../services/formatter';
import styles from './PricingPlanItem.styles';

export const modals = {
	CUSTOMER_EDIT_MODAL: 'CUSTOMER_EDIT_MODAL',
	ZONE_EDIT_MODAL: 'ZONE_EDIT_MODAL',
};

const PricingPlanItem = ({
	classes,
	fields,
	onEdit,
	onRemove,
	onSelect,
	onUpdate,
	onCustomersOrZonesEdit,
	placeholderSetters,
	pricingPlan,
	selected,
}) => {

	const removeIcon = (
		<Tooltip title='Remove' placement='left'>
			<RemoveIcon
				onClick={event => {
					event.stopPropagation();
					onRemove(pricingPlan.id);
				}}
				className={classes.editIcon}
			/>
		</Tooltip>
	);

	const cumulative = pricingPlan.cumulative && <TrueIcon className={classes.checkIcon} />;
	const specific = pricingPlan.specific && <TrueIcon className={classes.checkIcon} />;

	const editIcon = (
		<Tooltip title='Edit' placement='left'>
			<EditIcon
				onClick={event => {
					event.stopPropagation();
					placeholderSetters.setCode(pricingPlan.id);
					placeholderSetters.setName(pricingPlan.name);
					placeholderSetters.setDescription(pricingPlan.description);
					placeholderSetters.setStartingFee(pricingPlan.startFee);
					placeholderSetters.setCumulative(pricingPlan.cumulative);
					placeholderSetters.setSpecific(pricingPlan.specific);
					placeholderSetters.setCategory(pricingPlan.category);
					placeholderSetters.setDetails((!!pricingPlan.details && [...pricingPlan.details]) || []);
					onEdit.setOn();
					onUpdate(true);
				}}
				className={classes.editIcon}
			/>
		</Tooltip>
	);

	const editCustomersIcon = !!specific && (
		<Tooltip title='Manage Customers' placement='left'>
			<CustomerIcon
				onClick={(e) => {
					onCustomersOrZonesEdit(e, pricingPlan,
						modals.CUSTOMER_EDIT_MODAL);
				}}
				className={classes.editIcon}
			/>
		</Tooltip>
	);

	const editZonesIcon = !!specific && (
		<Tooltip title='Manage Zones' placement='left'>
			<DashboardIcon
				onClick={(e) => {
					onCustomersOrZonesEdit(e, pricingPlan,
						modals.ZONE_EDIT_MODAL);
				}}
				className={classes.editZonesIcon}
			/>
		</Tooltip>
	);


	const editIcons = (
		<div className={classes.icons}>
			{editCustomersIcon}
			{editZonesIcon}
			<ButtonsWithPermission>
				{editIcon}
				{removeIcon}
			</ButtonsWithPermission>
		</div>
	);

	const startFee = !!pricingPlan.startFee
		&& currencyFormatter.format(pricingPlan.startFee) + currencyFormatter.symbol();

	const item = (
		<ListItem
			selected={selected}
			fields={fields}
			onSelect={() => {
				placeholderSetters.setCode(pricingPlan.id);
				placeholderSetters.setName(pricingPlan.name);
				placeholderSetters.setDescription(pricingPlan.description);
				placeholderSetters.setStartingFee(pricingPlan.startFee);
				placeholderSetters.setCumulative(pricingPlan.cumulative);
				placeholderSetters.setSpecific(pricingPlan.specific);
				placeholderSetters.setCategory(pricingPlan.category);
				placeholderSetters.setDetails((!!pricingPlan.details && [...pricingPlan.details]) || []);
				onSelect(pricingPlan.id);
			}}
			element={{
				...pricingPlan,
				editIcons,
				startFee,
				cumulative,
				specific,
			}}
		/>
	);

	return item;
};

export default withStyles(styles)(PricingPlanItem);

const ButtonsWithPermission = withPermission(
	[{ resource: ['pricingPlans', 'edit'] }],
)(({ children }) => <> {children} </>);