const styles = () => ({
	
	root: {
		borderRadius: '10px',
		backgroundColor: 'red',
	},
	
	text: {
		color: 'white',
		padding: '0px 7px',
	},

	
});

export default styles;