import {
  ViewList as ListIcon,
  Layers as ZonesIcon,
  LocationCity as LocationsListIcon,
  LocalParking as ParkIcon,
} from "@material-ui/icons";

const button = (icon, text, tag, style = { color: "gray" }, right = false) => {
  return {
    icon,
    right,
    style,
    permissions: [{ resource: ["fleet", tag, "read"] }],
    tag,
    text,
  };
};

const menuButtons = () => {
  const array = []
    .concat(button(ListIcon, "List Assets", "assets"))
    .concat(button(ZonesIcon, "List Zones", "zones", {}))
    .concat(button(ParkIcon, "List Parks", "parks", {}))
    .concat(button(LocationsListIcon, "List Locations", "locations"));
  return array;
};

export default menuButtons;
