import remoteConfigsApi from "../Api/remote-configs";

export const getConfigs = (host_url, host_key, app_name, app_version, carclub_code, dispatch) => {
	dispatch({
		type: "RC_GETCONFIGS",
	});
	remoteConfigsApi.getRemoteConfigs(
		host_url, host_key,
		app_name, app_version, carclub_code,
		(response, error) => {
			if (!error) {
				dispatch({
					type: "RC_GETCONFIGS_SUCCESS",
					payload: response.data,
				});
			}
			else {
				dispatch({
					type: "RC_GETCONFIGS_FAILURE",
					payload: error,
				});
			}
		}
	);
};

export const addRemoteConfig = (host_url, host_key, app_name, app_version, carclub_code, app_config, dispatch) => {
	dispatch({
		type: "RC_CREATECONFIG",
	});
	remoteConfigsApi.addRemoteConfig(
		host_url, host_key,
		app_name, app_version, carclub_code, app_config,
		(response, error) => {
			if (!error) {
				dispatch({
					type: "RC_CREATECONFIG_SUCCESS",
					payload: response.data,
				});
				getConfigs(app_name, app_version, dispatch);
			}
			else {
				dispatch({
					type: "RC_CREATECONFIG_FAILURE",
					payload: error,
				});
			}
		}
	);
};