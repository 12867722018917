import React, { useCallback, useState } from 'react';
import { PROMOTIONS, INDIVIDUALBONUS } from '../../model/Promotion/screens';
import PromotionApp from './PromotionApp';

const PromotionAppWrapper = props => {
	const [currentScreen, setCurrentScreen] = useState(PROMOTIONS);
	const [idToSearch, setIdToSearch] = useState();

	const searchFromBonus = useCallback(id => {
		setIdToSearch(id);
		setCurrentScreen(INDIVIDUALBONUS);
	}, []);

	return (
		<PromotionApp
			{...props}
			idToSearch={idToSearch}
			currentScreen={currentScreen}
			searchFromBonus={searchFromBonus}
			setCurrentScreen={setCurrentScreen}
		/>
	);
};

export default PromotionAppWrapper;
