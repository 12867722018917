import React from 'react';
import MenuButton from './MenuButton';
import withPermission from '../../../hoc/withPermission';

const MenuButtonWrapper = props => {

	const { button } = props;

	const MenuButtonWithPermission = withPermission(button.permissions)(MenuButton);

	return <MenuButtonWithPermission { ...props } />;
};

export default MenuButtonWrapper;