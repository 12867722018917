import React from "react";
import { withStyles } from "@material-ui/core";
import AddButton from "./addButton";
import List from "./List/ListWrapper";
import Modal from "./Modal/ModalWrapper";
import SearchBar from "../../../../../components/UI/Searchbar/Searchbar";
import styles from "./Contacts.styles";

const Contacts = ({
  anchorEl,
  classes,
  contacts,
  filter,
  hasRequest,
  modal,
  newMessages,
  onAdd,
  onCreate,
  onGroupAdd,
  onRemove,
  onSearch,
  selected,
  setAnchorEl,
  setFilter,
  setModal,
  setSelected,
}) => {
  const addButton = <AddButton setAnchorEl={setAnchorEl} classes={classes} anchorEl={anchorEl} onCreate={onCreate} />;

  const searchText = (
    <SearchBar
      className={classes.searchText}
      filter={filter}
      label="Search"
      noline
      onSearch={onSearch}
      setFilter={setFilter}
    />
  );

  const searchBar = (
    <div className={classes.searchBar}>
      {addButton}
      {searchText}
    </div>
  );

  const groups = (
    <List
      array={contacts.filter((contact) => !!contact?.groupId)}
      newMessages={newMessages}
      onAdd={onGroupAdd}
      selected={selected?.groupId || selected?.target}
      setSelected={setSelected}
      title="Groups"
    />
  );

  const people = (
    <List
      array={contacts.filter(({ groupId, pending, sent }) => !groupId && !pending && !sent)}
      newMessages={newMessages}
      onRemove={onRemove}
      selected={selected?.target || selected?.groupId}
      setSelected={setSelected}
      title="People"
    />
  );

  const pending = (
    <List
      array={contacts.filter((contact) => !!contact?.pending && !contact?.groupId)}
      onAdd={setModal}
      selected={selected?.target || selected?.groupId}
      setSelected={setSelected}
      title="Pending"
    />
  );

  const list = (
    <div className={classes.list}>
      {groups}
      {people}
      {pending}
    </div>
  );

  const modalScreen = !!modal && (
    <Modal hasRequest={hasRequest} modal={modal} onSave={onAdd} selected={selected} setModal={setModal} />
  );

  return (
    <div className={classes.root}>
      {modalScreen}
      {searchBar}
      {list}
    </div>
  );
};

export default withStyles(styles)(Contacts);
