import React, { Component } from "react";
import VerticalMenuBar from "../../components/VerticalMenuBar/VerticalMenuBar";
import menuButtons from "./menuButtons";
import MapCore from "../../components/MainMap/MapCore";
import AssetWorkspace from "../../components/Assets/AssetWorkspace/AssetWorkspaceWrapper";
import ParksWorkspace from "../../components/Parks/ParksWorkspace/ParksWorkspaceWrapper";
import ZoneWorkspace from "../../components/Zones/ZoneWorkspace/ZoneWorkspaceWrapper";
import LocationsWorkspace from "../../components/Locations/LocationsWorkspace/LocationsWorkspaceWrapper";
import LocationModal from "../../components/UI/LocationModal/LocationModalWrapper";
import { withStyles } from "@material-ui/core";
import styles from "./AssetApp.styles";

class AssetApp extends Component {
  state = {
    locationAnchor: null,
    zoom: undefined,
  };

  selectAssetHandler = (assetToken) => {
    // console.log("selectAssetHandler -- assetToken: %o", assetToken);
    const { selectedAssetToken, setSelectedAssetToken, setMapCenter } = this.props;

    if (typeof assetToken === "undefined" || selectedAssetToken === assetToken) {
      setSelectedAssetToken(null);

      this.setState({
        zoom: undefined,
      });

      return;
    }

    const { assets, getAssetZones: selectAssetTokenAndGetZones } = this.props;

    const asset = assets.find((asset) => asset.assetToken === assetToken);

    if (!asset) {
      return;
    }

    selectAssetTokenAndGetZones(asset);

    setMapCenter({
      lat: asset.lat || asset.latitude,
      lng: asset.lng || asset.longitude,
    });
    this.setState(
      {
        zoom: 16,
      },
      this.focusOnAsset
    );
  };

  // TODO revise this function
  showListHandler = (showList) => {
    const { setShowList } = this.props;

    const newShowList = typeof showList === "undefined" ? !this.props.showList : showList;

    setShowList(newShowList);
  };

  locationMenuHandler = (target) => {
    this.setState({
      locationAnchor: target ? target : null,
    });
  };

  centerSelectedPosHandler = (lat, lng, name, id) => {
    this.props.setSelectedAssetToken(null);

    this.props.changeCurrentLocation({ name, id });

    this.props.setMapCenter({ lat, lng });
    this.setState({
      zoom: 14,
    });
  };

  focusOnAsset = () => {
    const { showList, setShowList } = this.props;

    if (!showList) {
      setShowList(true);
    }
    // TODO check if focusing works well
    if (!this.selectedAssetRef) {
      return;
    }

    this.selectedAssetRef.focus();
  };

  centerMapHandler = (lat, lng, zoom) => {
    // console.log("centerMapHandler - lat: %o, lng: %o, zoom: %o", lat, lng, zoom);
    if (lat && lng) {
      this.props.setMapCenter({ lat, lng });
    }
    this.setState({ zoom });
  };

  UNSAFE_componentWillMount() {
    const { location } = this.props;

    if (!location) {
      return;
    }

    this.setState({
      zoom: 14,
    });
  }

  componentWillUnmount() {
    this.props.setSelectedAssetToken(null);
  }

  render() {
    const {
      assets,
      assetType,
      classes,
      clearAssetZones,
      clearMapOfPolygons,
      createPolygon,
      deletePark,
      getAsset,
      getAssetZones,
      getDamageReport,
      getParks,
      hidePolygon,
      hidePolygons,
      location,
      onGoogleAPILoaded,
      parksList,
      polygonPainter,
      polygonPainterIsDrawing,
      polygonRedrawer,
      polygons,
      selectedAssetToken,
      setAssetType,
      setParks,
      setSelectedAssetToken,
      setShowList,
      setWorkspace,
      showList,
      updateAsset,
      upsertPark,
      workspace,
      workspaces,
      mapCenter,
      selectedLocation,
    } = this.props;

    const menuApps = ["assets", "zones", "parks", "locations"];

    const { locationAnchor, zoom } = this.state;

    const selectedAsset = assets.length > 0 && assets.find((asset) => asset.assetToken === selectedAssetToken);

    // Menu bar

    const listHandler = (targetWorkspace) => {
      setWorkspace(targetWorkspace);
      if (workspace?.id === targetWorkspace?.id || !showList) {
        this.showListHandler();
      }
    };

    const menuButtonOnClick = {
      assets: () => {
        clearMapOfPolygons();
        listHandler(workspaces.ASSET);
      },
      Locations: (event) =>
        locationAnchor !== null ? this.locationMenuHandler() : this.locationMenuHandler(event.currentTarget),
      zones: () => {
        clearMapOfPolygons();
        listHandler(workspaces.ZONE);
      },
      parks: () => {
        clearMapOfPolygons();
        hidePolygons();
        listHandler(workspaces.PARK);
      },
      locations: () => {
        clearMapOfPolygons();
        listHandler(workspaces.LOCATIONS);
      },
    };

    const menuBtns = menuButtons(menuApps).map((button) => {
      const { label } = workspace || {};

      button["onClick"] = menuButtonOnClick[button.tag];
      button.style = button.text === label ? { color: "#009BCC" } : {};
      return button;
    });

    // Map
    const map = location?.id && (
      <MapCore
        centerMapHandler={(lat, lng, zoom) => this.centerMapHandler(lat, lng, zoom)}
        markers={
          workspace?.id === workspaces.ASSET?.id
            ? assets.map((asset) => ({ ...asset, lat: asset.latitude, lng: asset.longitude }))
            : []
        } //assetMarkers
        onAPIloaded={onGoogleAPILoaded}
        pos={mapCenter}
        selectedAsset={selectedAsset}
        selectAsset={this.selectAssetHandler}
        styles={{
          mapTypeControl: true,
          mapTypeControlOptions: {
            position: 9,
            style: 0,
            mapTypeIds: ["satellite", "roadmap"],
          },
        }}
        hidePolygons={hidePolygons}
        zoom={zoom}
        zonePainter={polygonPainter}
      />
    );

    // Workspaces

    const assetWorkspace = workspace?.id === workspaces.ASSET?.id && (
      <AssetWorkspace
        centerMap={this.centerMapHandler}
        selectAsset={this.selectAssetHandler}
        {...{
          assets,
          assetType,
          clearAssetZones,
          getAsset,
          getAssetZones,
          getDamageReport,
          selectedAsset,
          selectedAssetToken,
          setAssetType,
          setSelectedAssetToken,
          setShowList,
          showList, // TODO shouldn't this be AssetAppWrapper?
          updateAsset,
        }}
      />
    );

    const parksWorkspace = workspace?.id === workspaces.PARK?.id && (
      <ParksWorkspace
        parks={parksList}
        isDrawing={polygonPainterIsDrawing}
        getParks={getParks}
        hidePark={hidePolygon}
        hideParks={hidePolygons}
        polygons={polygons}
        setShowList={setShowList}
        setParks={setParks}
        showList={showList}
        parkCreator={createPolygon}
        parkPainter={polygonPainter}
        parkRedrawer={polygonRedrawer}
        upsertPark={upsertPark}
        deletePark={deletePark}
      />
    );

    const zoneWorkspace = workspace?.id === workspaces.ZONE?.id && (
      <ZoneWorkspace
        clearMapOfPolygons={clearMapOfPolygons}
        isDrawing={polygonPainterIsDrawing}
        hideZone={hidePolygon}
        hideZones={hidePolygons}
        polygons={polygons}
        setShowList={setShowList}
        showList={showList}
        zoneCreator={createPolygon}
        zonePainter={polygonPainter}
        zoneRedrawer={polygonRedrawer}
      />
    );

    const locationsWorkspace = workspace?.id === workspaces.LOCATIONS?.id && (
      <LocationsWorkspace
        clearMapOfPolygons={clearMapOfPolygons}
        centerMap={this.centerSelectedPosHandler}
        currentLocation={location}
        isDrawing={polygonPainterIsDrawing}
        {...{
          hideLocation: hidePolygon,
          hideLocations: hidePolygons,
          locationCreator: createPolygon,
          locationPainter: polygonPainter,
          locationRedrawer: polygonRedrawer,
          polygons,
          setShowList,
          showList,
        }}
      />
    );

    const menuBar = <VerticalMenuBar className={classes.menu} buttons={menuBtns} />;

    const modal = !selectedLocation?.id && <LocationModal open={true} />;
    // Actual render

    return (
      <div className={classes.root}>
        <div className={classes.workspace}>
          {modal}
          {menuBar}
          <div className={classes.mapArea}>
            {map}
            {assetWorkspace}
            {parksWorkspace}
            {zoneWorkspace}
            {locationsWorkspace}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AssetApp);
