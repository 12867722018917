import React from 'react';
import Detail from './Detail';
import DetailsDiv from '../../../UI/DetailsDiv/DetailsDiv';
import DetailsRow from '../../../UI/DetailsDiv/DetailsRow/DetailsRow';
import {
	withStyles,
} from '@material-ui/core';
import styles from './Details.styles';

const Billing = ({
	classes,
	info,
}) => {

	const billing = (
		<DetailsDiv title=''>
			{!!info.taxNumber && <DetailsRow
				label='Tax'
				detail={
					<Detail detail={info.taxNumber} />
				}
			/>}
			{!!info.address && <DetailsRow
				label='Address'
				detail={
					<Detail detail={info.address} />
				}
			/>}
			{!!info.city && <DetailsRow
				label='Address City'
				detail={
					<Detail detail={info.city} />
				}
			/>}
			{!!info.zipCode && <DetailsRow
				label='Address Zip Code'
				detail={
					<Detail detail={info.zipCode} />
				}
			/>}
			{!!info.country && <DetailsRow
				label='Address Country'
				detail={
					<Detail detail={info.country} />
				}
			/>}
		</DetailsDiv>
	);

	return billing;
};

export default withStyles(styles)(Billing);