import React from 'react';
import List from '../../UI/List/List';
import AccountItem from './AccountItem/AccountItem';
import withStyles from '@material-ui/styles/withStyles';
import accountFields from './accountFields';
import styles from './AccountList.styles';
import classnames from 'classnames';

const AccountList = ({
	accounts,
	classes,
	className,
	currentScreenSize,
	getMoreRows,
	onSort,
	onSelect,
	selected,
	setModal,
	getAccount,
	enableAccount,
	removeAccount,
}) => {
	const accountItems = Object.values(accounts || {})
		.map(account => (
			<AccountItem
				account={account}
				disable={removeAccount}
				enable={enableAccount}
				getAccount={getAccount}
				key={account.code}
				onSelect={onSelect}
				selected={selected === account.code}
				setModal={setModal}
			/>
		));

	const list = (
		<List
			className={classnames(classes.root, className)}
			getMoreRows={getMoreRows}
			headerFields={accountFields(currentScreenSize, onSort)}
		>
			{accountItems}
		</List>
	);

	return list;
};

export default withStyles(styles)(AccountList);
