export const styles = (theme) => ({
  root: {
    width: "175px",
    height: "auto",
    backgroundColor: "#fffb",
    alignSelf: "flex-end",
    margin: "10px 8px",
    boxShadow: theme?.shadows[5],
    borderRadius: "5px",
    padding: 10,
  },
  totalGrid: {
    paddingLeft: 30,
  },
  total: {
    fontSize: "20px",
    color: "#888",
  },
});
