export const BATTERY_ISSUE = 'BATTERY_ISSUE';
export const DISCONNECTED = 'DISCONNECTED';
export const ENGINE_RUNNING = 'ENGINE_RUNNING';
export const OFFLINE = 'OFFLINE';
export const RESERVATION = 'RESERVATION';
export const LOW_FUEL = 'LOW_FUEL';

export const getFleetStatus = (asset = {}, thresholdOptions) => {

	const {
		batteryIssueLevel = 15,
		lowFuelLevel = 10,
	} = thresholdOptions || {};

	const {
		battery,
		disconnected,
		engineRunning,
		fuelLevel,
		offline,
		reservationCode,
	} = asset || {};

	if (battery < batteryIssueLevel) {
		return BATTERY_ISSUE;
	}

	if (disconnected) {
		return DISCONNECTED;
	}

	if (engineRunning) {
		return ENGINE_RUNNING;
	}
	
	if (offline) {
		return OFFLINE;
	}

	if (reservationCode) {
		return RESERVATION;
	}
	
	if (fuelLevel < lowFuelLevel) {
		return LOW_FUEL;
	}
	
	return null;
};