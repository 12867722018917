import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default function AttributesSelector({
	classes,
	allAtributes,
	attributes,
	setAttributes,
	disabled,
}) {
	const handleChange = (id, checked) => {
		const tempAttributes = attributes || [];
		if (checked) {
			setAttributes([...tempAttributes, id]);
		} else {
			let array = [...tempAttributes];
			let index = array.indexOf(id);
			if (index !== -1) {
				array.splice(index, 1);
				setAttributes(array);
			}
		}
	};

	return (
		<FormGroup>
			{allAtributes.map((item) => {
				return (
					<FormControlLabel
						key={item.id}
						style={{ marginLeft: 0 }}
						control={
							<Checkbox
								className={classes.checkbox}
								disabled={disabled}
								checked={Boolean(
									attributes && attributes.indexOf(item.id) !== -1
								)}
								onChange={(ev) => handleChange(item.id, ev.target.checked)}
								color="primary"
							/>
						}
						label={<div className={classes.checkboxAttLabel}>{item.name}</div>}
					/>
				);
			})}
		</FormGroup>
	);
}
