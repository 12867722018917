import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../UI/Buttons/CommonButtons";
import { IconButton, ListSubheader, ListItem, Tooltip, Typography, withStyles } from "@material-ui/core";
import { Visibility as ShowZonesIcon, VisibilityOff as HideZonesIcon } from "@material-ui/icons";
import { ReactComponent as CreateIcon } from "../../../assets/PromotionApp/icons/add.svg";
import ZonesItem from "./ZonesItem/ZonesItemWrapper";
import ZoneTypes from "../../../model/Zones/ZoneTypes";
import withPermission from "../../../hoc/withPermission";
import styles from "./ZonesList.styles";
import zonesCreateCall from "../../../features/zones/_sliceCalls/zonesCreateCall";

const ZonesList = ({
  areAllZonesPainted,
  cancelDrawing,
  classes,
  confirmDrawing,
  createZone,
  editZone,
  isCreating,
  isDrawing,
  isImporting,
  paintAllZones,
  redrawZone,
  selectedZone,
  selectZone,
  setSelectedZoneCode,
  setXML,
  setZones,
  zones,
  polygon,
  stopDrawing,
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const onStopRedrawing = async () => {
    const { description, type, code } = selectedZone;
    let r = await zonesCreateCall(dispatch, state, { code, description, type, polygon });
    setZones(r);
    stopDrawing();
  };

  const list = zones
    .filter((zone) => zone.type === ZoneTypes.NORMAL || zone.type === ZoneTypes.UNWANTED)
    .sort(zoneCompare)
    .map((zone) => (
      <ListItem className={classes.listItem} key={zone.code} id={"zone_item_" + zone?.code}>
        <ZonesItem
          editZone={() => editZone(zone)}
          isSelected={zone.code === selectedZone?.code}
          isDrawing={isDrawing}
          name={zone.description}
          redrawZone={() => redrawZone(zone)}
          selectZone={selectZone}
          setSelectedZoneCode={setSelectedZoneCode}
          setZones={setZones}
          stopRedrawing={onStopRedrawing}
          type={zone.type}
          zone={zone}
        />
      </ListItem>
    ));

  return (
    <div className={classes.root}>
      <ListSubheader component="div" disableGutters>
        <div className={classes.header}>
          <div className={classes.type}>Type</div>
          <div>Description</div>
          <div className={classes.buttons}>
            {
              <Tooltip title="Show all zones">
                <IconButton aria-label="show zones" color="primary" component="div" onClick={paintAllZones}>
                  {!areAllZonesPainted ? (
                    <HideZonesIcon className={classes.eye} />
                  ) : (
                    <ShowZonesIcon className={classes.eye} />
                  )}
                </IconButton>
              </Tooltip>
            }
            {!isCreating.state && (
              <ButtonWithPermission>
                <Tooltip title="Add zones">
                  <IconButton aria-label="add zones" color="primary" component="div" onClick={createZone}>
                    <CreateIcon />
                  </IconButton>
                </Tooltip>
              </ButtonWithPermission>
            )}
            {isCreating.state && isImporting && (
              <Button label="Save" onClick={confirmDrawing} buttonClassName={classes.saveImport} />
            )}
            {!isCreating.state && (
              <ButtonWithPermission>
                <input
                  hidden
                  id="xml_import"
                  onChange={(event) => setXML(event.target.files[0])}
                  type="file"
                  accept="text/xml"
                />
                <label htmlFor="xml_import">
                  <Button label="Import" component="span" tooltip="XML or KML files" />
                </label>
              </ButtonWithPermission>
            )}
            {isCreating.state && <Button buttonStyleName="void" label="Cancel" onClick={cancelDrawing} />}
          </div>
        </div>
      </ListSubheader>
      {!zones || zones.length === 0 ? (
        <Typography align="center"> No matching results... </Typography>
      ) : (
        <div className={classes.list}>{list}</div>
      )}
    </div>
  );
};

const zoneCompare = (a, b) => {
  if (a.type === b.type) {
    return a.description && b.description ? a.description.localeCompare(b.description) : 0;
  }

  if (a.type === ZoneTypes.NORMAL) {
    return -1;
  }

  if (b.type === ZoneTypes.NORMAL) {
    return 1;
  }

  return 0;
};

const ButtonWithPermission = withPermission([{ resource: ["fleet", "zones", "create"] }])(({ children }) => (
  <>{children}</>
));

export default withStyles(styles)(ZonesList);
