import React from 'react';
import ListItem from '../../../../UI/List/ListItem/ListItem';
import {
	withStyles,
} from '@material-ui/core';
import styles from './AccountDetailsListItem.styles';
import { currencyFormatter, dateFormatter } from '../../../../../services/formatter';
import {
	CheckBoxOutlineBlank as UncheckIcon,
	CheckBox as CheckedIcon,
} from '@material-ui/icons';

const AccountDetailsListItem = ({
	classes,
	detail,
	fields,
	onSelect,
	selected,
}) => {

	const amount = detail.amount
		&& `${currencyFormatter.format(detail.amount)} ${currencyFormatter.symbol()}`;

	const amountUsed = detail.amountUsed
		&& `${currencyFormatter.format(detail.amountUsed)} ${currencyFormatter.symbol()}`;

	const date = detail.date
		&& dateFormatter.format(detail.date, 'dateTime');

	const createDate = detail.createDate
		&& dateFormatter.format(detail.createDate, 'dateTime');

	const expirationDate = detail.expirationDate
		&& dateFormatter.format(detail.expirationDate, 'dateTime');

	const check = selected
		? <CheckedIcon className={classes.icon} />
		: <UncheckIcon className={classes.iconUnchecked} />;

	const item = (
		<ListItem
			fields={fields}
			onSelect={() => onSelect && onSelect(detail.code)}
			selected={selected}
			element={{
				...detail,
				amount,
				amountUsed,
				check,
				createDate,
				date,
				expirationDate,
			}}
		/>
	);

	return item;
};

export default withStyles(styles)(AccountDetailsListItem);