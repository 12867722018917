import React, { useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { TextField, InputAdornment } from "@material-ui/core";

import { styles } from "./_base.styles";
import { ClearIcon, SearchIcon } from "../icons";

function SearchBox({ classes, onEnter, fast, variant, className, disabled }) {
  const [query, setQuery] = useState("");

  const onChangeHandler = (e) => {
    setQuery(e.target.value);
    if (fast) {
      onEnter && onEnter(e.target.value);
    }
  };

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13 && ((query && query.length > 2) || query === "")) {
      onEnter && onEnter(query);
    }
  };

  const onClearHandler = () => {
    setQuery("");
    onEnter && onEnter(null);
  };

  return (
    <div className={className ? classes.root + " " + className : classes.root}>
      <TextField
        className={classes.input}
        margin="none"
        variant={variant}
        size="small"
        value={query}
        onChange={onChangeHandler}
        onKeyDown={onKeyDownHandler}
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon disabled={disabled} onClick={() => onEnter(query)} />
            </InputAdornment>
          ),
          endAdornment: query && (
            <InputAdornment position="end">
              <ClearIcon onClick={onClearHandler} className={classes.clear} disabled={disabled} />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

export default withStyles(styles)(SearchBox);
