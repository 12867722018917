import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const customerChangeLocationCall = async (
  { removeCustomerFromList, setErrorMovingDriver, setShowMoveDriverModal, setSelectedCustomer },
  dispatch,
  state,
  { locationId, customerCode }
) => {
  dispatch(setErrorMovingDriver(null));
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "CUSTOMER/CHANGE_LOCATION");
  try {
    const result = await wsCall({
      type: "CUSTOMER",
      subType: "CHANGE_LOCATION",
      locationId: selectedLocation?.id,
      message: {
        locationId,
        customerCode,
      },
    });
    if (!result.error) {
      dispatch(setShowMoveDriverModal(false));
      dispatch(setSuccess("Driver moved successfully."));
      dispatch(removeCustomerFromList(customerCode));
      dispatch(setSelectedCustomer());
    } else {
      if (result?.tag === "ASSET_IN_RESERVATION") {
        dispatch(setErrorMovingDriver("The driver has an active trip. Close the trip to move to another location."));
      } else {
        rh.error(result?.description || "Error moving driver to another location.");
      }
    }
  } catch (_ex) {
    rh.error("Error moving driver to another location.");
  }
  rh.close();
};

export default customerChangeLocationCall;
