import React from 'react';
import LocationsItem from './LocationsItem/LocationsItem';
import Button from '../../UI/Buttons/CommonButtons';
import {
	IconButton,
	ListSubheader,
	ListItem,
	Tooltip,
	Typography,
	withStyles,
} from '@material-ui/core';
import { ReactComponent as CreateIcon } from '../../../assets/PromotionApp/icons/add.svg';
import {
	Visibility as ShowLocationsIcon,
	VisibilityOff as HideLocationsIcon,
} from '@material-ui/icons';
import styles from './LocationsList.styles';
import withPermission from '../../../hoc/withPermission';

const LocationsList = ({
	areAllLocationsPainted,
	cancelDrawing,
	centerMap,
	classes,
	createLocation,
	confirmDrawing,
	currentLocation,
	editLocation,
	isCreating,
	isDrawing,
	isImporting,
	paintAllLocations,
	redrawLocation,
	removeLocation,
	selectedLocationCode,
	selectLocation,
	setXML,
	stopRedrawing,
	locations,
}) => {

	const showAllLocationsBtn = (
		<Tooltip title="Show all locations">
			<IconButton
				aria-label="show locations"
				color="primary"
				component="div"
				onClick={paintAllLocations}
			>
				{!areAllLocationsPainted
					? <HideLocationsIcon className={classes.eye} />
					: <ShowLocationsIcon className={classes.eye} />}
			</IconButton>
		</Tooltip>
	);

	const addButton = !isCreating.state && (
		<ButtonWithPermission>
			<Tooltip title="Add locations">
				<IconButton
					aria-label="add locations"
					color="primary"
					component="div"
					onClick={createLocation}
				>
					<CreateIcon />
				</IconButton>
			</Tooltip>
		</ButtonWithPermission>
	);

	const importButton = !isCreating.state && (
		<ButtonWithPermission>
			<input
				hidden
				id="xml_import"
				onChange={event => setXML(event.target.files[0])}
				type="file"
				accept='text/xml'
			/>
			<label htmlFor="xml_import">
				<Button
					label='Import'
					component='span'
					tooltip='XML or KML files'
				/>
			</label>
		</ButtonWithPermission>
	);

	const saveImportButton = isCreating.state && isImporting && (
		<Button
			label='Save'
			onClick={confirmDrawing}
			buttonClassName={classes.saveImport}
		/>
	);

	const cancelButton = isCreating.state && (
		<Button
			buttonStyleName='void'
			label='Cancel'
			onClick={cancelDrawing}
		/>
	);

	const header = (
		<ListSubheader component='div'>
			<div className={classes.header}>
				<div className={classes.type}>
				</div>
				<div>
					Locations
				</div>
				<div className={classes.buttons}>
					{showAllLocationsBtn}
					{addButton}
					{saveImportButton}
					{importButton}
					{cancelButton}
				</div>
			</div>
		</ListSubheader>
	);

	if (!locations || locations.length === 0) {
		return (
			<div className={classes.root}>
				{header}
				<Typography align="center"> No matching results... </Typography>
			</div>
		);
	};

	const locationMapper = locationItemMapper(
		centerMap,
		classes,
		currentLocation,
		editLocation,
		isDrawing,
		redrawLocation,
		removeLocation,
		selectedLocationCode,
		selectLocation,
		stopRedrawing,
		cancelDrawing,
	);

	const list = locations
		.sort(locationCompare)
		.map(locationMapper);

	const zonesList = (
		<div className={classes.list}>
			{list}
		</div>
	);

	return (
		<div className={classes.root}>
			{header}
			{zonesList}
		</div>
	);
};

const locationItemMapper = (
	centerMap,
	classes,
	currentLocation,
	editLocation,
	isDrawing,
	redrawLocation,
	removeLocation,
	selectedLocationCode,
	selectLocation,
	stopRedrawing,
	cancelDrawing,
) => location => (
	<ListItem className={classes.listItem} key={location.code} id={'location_item_' + location.code}>
		<LocationsItem
			editLocation={editLocation && (() => editLocation(location))}
			isSelected={location.code === selectedLocationCode}
			isDrawing={isDrawing}
			centerMap={centerMap}
			currentLocation={currentLocation}
			redrawLocation={redrawLocation && (() => redrawLocation(location))}
			onSelect={selectLocation}
			removeLocation={removeLocation}
			stopRedrawing={stopRedrawing}
			location={location}
			cancelDrawing={() => {
				cancelDrawing();
				selectLocation(location);
			}}
		/>
	</ListItem>
);

const locationCompare = (a, b) => {
	return a.name && b.name
		? a.name.localeCompare(b.name)
		: 0;
};

const ButtonWithPermission = withPermission(
	[{ resource: ['fleet', 'locations', 'create'] }]
)(({
	children,
}) =>
	(
		<>
			{children}
		</>
	)
);

export default withStyles(styles)(LocationsList);