import * as listItemTypes from '../../UI/List/ListItem/ListItemTypes';
import * as screenSize from '../../../model/ScreenSize/ScreenSize';

const pricingPlanFields = currentScreenSize => {

	const width = {
		[screenSize.DESKTOP]: 111,
		[screenSize.TABLET]: 78,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	const maxWidth = {
		[screenSize.DESKTOP]: 166,
		[screenSize.TABLET]: 136,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	const height = {
		[screenSize.DESKTOP]: 35,
		[screenSize.TABLET]: 45,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];
	
	return [
		{
			key: 'editIcons',
			label: 'Actions',
			order: 99,
			pinned: true,
			width,
			height,
			type: listItemTypes.COMPONENT,
			flexDirection: 'row-reverse',
		},
		{
			key: 'name',
			label: 'Name',
			order: 1,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'description',
			label: 'Description',
			order: 2,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'startingFee',
			label: 'Starting Fee',
			order: 3,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
			flexDirection: 'row-reverse',
		},
		{
			key: 'category',
			label: 'Category',
			order: 5,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
			flexDirection: 'row-reverse',
		},
		{
			key: 'specific',
			label: 'Private',
			order: 6,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.COMPONENT,
			flexDirection: 'row-reverse',
		},
	];
};

export default pricingPlanFields;