const styles = theme => ({
	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},

	pickers: {
		maxHeight: '50px',
		maxWidth: '170px',
		margin: 'auto 5px',
	},

	input: {
		padding: '3px !important',
		color: '#89898B',
		maxWidth: '120px',
		minWidth: '120px',
	},

	inputIcon: {
		padding: '2px',
	},

	
	title: {
		fontSize: '18px',
		marginBottom: '20px',
	},

	text: {
		fontSize: '14px',
		marginBottom: '15px',
	},

	titleDiv: {
		display: 'flex',
		flexDirection: 'column',
	},

	save:{
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},

	modal:{
		backgroundColor: 'white',
		borderRadius: '10px',
		bottom: 0,
		boxShadow: theme.shadows[5],
		boxSizing: 'border-box',
		height: '200px',
		left: 0,
		outline: 0,
		padding: '20px',
		position: 'absolute',
		right: 0,
		top: 0,
		width: '500px',
		margin: 'auto',
	},

	pickerInput: {
		padding: 8,
	},

	outlined: {
		transform: 'translate(14px, 10px) scale(1)',
	},
});

export default styles;