import React from "react";
import { Checkbox, Chip, TextField, Tooltip } from "@material-ui/core";

class ConfigInput extends React.Component {
  constructor(props) {
    super(props);

    let defaultValue = this.props.field.type === "Boolean" ? false : "";
    let value = props.value || defaultValue;
    if (this.props.field.type === "Array" && value && value.length > 0) {
      value = value.join(",");
    }
    this.state = {
      value: value,
    };
  }

  handleChange(e) {
    let value = e.target.value;
    if (this.props.field.type === "Boolean") {
      value = !this.state.value;
    }
    if (this.props.field.type === "Array") {
      value = value.split(",").map((el) => {
        return el.trim();
      });
    }

    this.setState({
      value: value,
    });
    this.props.onChange(this.props.field.key, value);
  }

  renderKeyAndTooltip() {
    return (
      <td>
        <Tooltip title={this.props.field.description} placement="left">
          <div>
            <span className="key" size="sm">
              {this.props.field.key}
            </span>
            {this.props.field.new ? (
              <Chip
                label="New"
                size="small"
                color="primary"
                style={{ padding: "2px 0", height: "auto", marginLeft: 5 }}
              />
            ) : null}
          </div>
        </Tooltip>
      </td>
    );
  }

  renderStringInput() {
    return (
      <tr id={"tooltip_" + this.props.field.key} className="config-input config-input-string">
        {this.renderKeyAndTooltip()}
        <td>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            inputProps={{ style: { padding: "5px 10px" } }}
            value={this.state.value}
            onChange={this.handleChange.bind(this)}
          />
          {this.renderNote()}
        </td>
      </tr>
    );
  }

  renderBooleanInput() {
    return (
      <tr id={"tooltip_" + this.props.field.key} className="config-input config-input-boolean">
        {this.renderKeyAndTooltip()}
        <td>
          <Checkbox size="small" color="primary" checked={this.state.value} onChange={this.handleChange.bind(this)} />
        </td>
      </tr>
    );
  }

  renderNote() {
    if (this.props.field.note) {
      return <span className="note">{this.props.field.note}</span>;
    }
  }

  render() {
    if (this.props.field) {
      switch (this.props.field.type) {
        case "Boolean":
          return this.renderBooleanInput();
        default:
          return this.renderStringInput();
      }
    }
  }
}

export default ConfigInput;
