import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, List, ListItem } from "@material-ui/core";
import { CheckBoxOutlineBlankOutlined as UncheckedIcon, CheckBoxOutlined as CheckedIcon } from "@material-ui/icons";
import PeopleIcon from "@material-ui/icons/People";

import { styles } from "./AccountsList.styles";

function AccountsList({ classes, driverAccounts, accountId, onSelected, disabled }) {
  return (
    <List disablePadding style={disabled ? { opactity: 0.6 } : {}}>
      {driverAccounts?.map((account, i) => {
        return (
          <ListItem classes={{ root: classes.listItemRoot }} key={account.code + "_" + i} disableGutters={true}>
            <div className={classes.accountSubListItem}>
              <div className={classes.accountFieldContainer}>
                <PeopleIcon className={classes.accountIcon} />
                <Typography className={classes.subListItemTypo}>{account?.name}</Typography>
              </div>
              <div
                onClick={() => {
                  !disabled && onSelected && onSelected(account);
                }}
              >
                {accountId === account.id ? (
                  <CheckedIcon className={classes.checkButtons} />
                ) : (
                  <UncheckedIcon className={classes.checkButtons} />
                )}
              </div>
            </div>
          </ListItem>
        );
      })}
    </List>
  );
}

export default withStyles(styles)(AccountsList);
