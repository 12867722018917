const mapper = ({
	amount,
	createDatetime,
	id,
	paymentMethod,
	reservationCode,
	status,
	type,
}) => ({
	amount,
	code: id,
	date: createDatetime,
	origin: reservationCode,
	source: paymentMethod,
	status,
	type,
});

export default mapper;