import React from 'react';
import { ReactComponent as Icon } from '../../../../assets/icons/maintenance.svg';
import { withStyles, Tooltip } from '@material-ui/core';
import styles from './Point.styles';
import classNames from 'classnames';

const ServicePoint = ({
	$hover,
	classes,
	onSelect,
	tooltip,
}) => {

	return (
		<div
			className={
				classNames(
					/* classes.pin, */
					classes.root,
					classes.centered,
					$hover && classes.hover,
				)
			}
			onClick={onSelect}
		>
			<Tooltip title={tooltip || ''} placement='top'>
				<Icon className={classes.icon} />
			</Tooltip>
		</div>
	);
};

export default withStyles(styles)(ServicePoint);
