import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const billingToBillCall = async (
  { updateSelectedTripBillingItem, updateSingleClosedReservation, reservationPaymentDetails },
  dispatch,
  state,
  { reservationCode }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;

  let rh = requestHelper(dispatch, "BILLING/TO_BILL");
  try {
    const result = await wsCall({
      type: "BILLING",
      subType: "TO_BILL",
      locationId: selectedLocation?.id,
      message: {
        reservationCode,
      },
    });
    if (result) {
      if (result?.billing) {
        dispatch(setSuccess("Trip data confirmed!"));
        dispatch(updateSelectedTripBillingItem(result.billing));
        dispatch(updateSingleClosedReservation({ onHold: result?.billing.onHold, code: result.reservationCode }));
        setTimeout(() => {
          dispatch(reservationPaymentDetails({ reservationCode }));
        }, 200);
      }
    }
  } catch (_ex) {
    rh.error("Error on revise.");
  }
  rh.close();
};

export default billingToBillCall;
