import makeActionCreator from '../../utils/makeActionCreator';
import createReducer from '../../hooks/createCustomReducer';

const types = {
	RESET_PLACEHOLDER: 'RESET_PLACEHOLDER',
	SET_ID: 'SET_ID',
	SET_NAME: 'SET_NAME',
	SET_MAX_TIME: 'SET_MAX_TIME',
	SET_MAX_PRICE: 'SET_MAX_PRICE',
	ADD_RATE: 'ADD_RATE',
	REMOVE_RATE: 'REMOVE_RATE',
};

export const resetPlaceholder = makeActionCreator(types.RESET_PLACEHOLDER);
export const setId = makeActionCreator(types.SET_ID, 'id');
export const setName = makeActionCreator(types.SET_NAME, 'name');
export const setMaxTime = makeActionCreator(types.SET_MAX_TIME, 'maxTime');
export const setMaxPrice = makeActionCreator(types.SET_MAX_PRICE, 'maxPrice');
export const addRate = makeActionCreator(types.ADD_RATE, 'rate');
export const removeRate = makeActionCreator(types.REMOVE_RATE, 'rate');

const actions = dispatch => ({
	resetPlaceholder: () => dispatch(resetPlaceholder()),
	setId: (data) => dispatch(setId(data)),
	setName: (data) => dispatch(setName(data)),
	setMaxTime: (data) => dispatch(setMaxTime(data)),
	setMaxPrice: (data) => dispatch(setMaxPrice(data)),
	addRate: (data) => dispatch(addRate(data)),
	removeRate: (data) => dispatch(removeRate(data)),
});

export const defaultState = {
	id: null,
	name: undefined,
	maxTime: undefined,
	maxPrice: undefined,
	addedRates: [],
};

const setIdReducer = (state, action) => ({
	...state,
	id: action.id,
});

const setNameReducer = (state, action) => ({
	...state,
	name: action.name,
});

const setMaxTimeReducer = (state, action) => ({
	...state,
	maxTime: action.maxTime,
});

const setMaxPriceReducer = (state, action) => ({
	...state,
	maxPrice: action.maxPrice,
});

const addRateReducer = (state, action) => {
	const updatedRates = [...state.addedRates];
	updatedRates.push(action.rate);
	return {
		...state,
		addedRates: updatedRates,
	};};

const removeRateReducer = (state, action) => {
	const updatedRates = [...state.addedRates];
	const result = updatedRates.find( (rate) => {
		return rate.name === action.rate.name;
	});
	const indexToRemove = updatedRates.indexOf(result);

	updatedRates.splice(indexToRemove, 1);
	return {
		...state,
		addedRates: updatedRates,
	};};

const resetPlaceholderReducer = () => defaultState;

export const reducer = (state, action) => ({
	[types.RESET_PLACEHOLDER]: resetPlaceholderReducer,
	[types.SET_ID]: setIdReducer,
	[types.SET_NAME]: setNameReducer,
	[types.SET_MAX_TIME]: setMaxTimeReducer,
	[types.SET_MAX_PRICE]: setMaxPriceReducer,
	[types.ADD_RATE]: addRateReducer,
	[types.REMOVE_RATE]: removeRateReducer,

}[action.type](state, action));

export default createReducer({ reducer, actions, defaultState });