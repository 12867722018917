import { wsCall, requestHelper } from "../../../app/coreSlice";

const assetAssetReservationCountsCall = async ({ setAssetsCounters }, dispatch, state) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  if (!selectedLocation) {
    return;
  }
  let rh = requestHelper(dispatch, "ASSET/ASSET_RESERVATION_COUNTS");

  try {
    const result = await wsCall({
      type: "ASSET",
      subType: "ASSET_RESERVATION_COUNTS",
      locationId: selectedLocation?.id,
    });
    if (!result.error) {
      let total =
        (result.assetsNotInReservation || 0) +
        (result.assetsInIdleState || 0) +
        (result.assetsInReadyState || 0) +
        (result.assetsUnavailable || 0) +
        (result.notOperationalAssets || 0);
      dispatch(setAssetsCounters({ ...result, total }));
    }
  } catch (_ex) {
    rh.error("Error getting assets counts.");
  }
  rh.close();
};

export default assetAssetReservationCountsCall;
