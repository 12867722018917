import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClickAwayListener, MenuItem, MenuList, Paper, Popper, withStyles, Tooltip } from "@material-ui/core";
import { ChevronLeft as ExpandLeftIcon, ChevronRight as ExpandRightIcon } from "@material-ui/icons";
import { styles } from "./UserMenu.styles";
import StarIcon from "@material-ui/icons/Star";
import { selectUserLocations, userGetLocations } from "../../../../../../features/user/userSlice";

const UserMenu = ({
  anchorEl,
  anchorElDefaultLoc,
  classes,
  closeLocationsModal,
  defaultLocation,
  handleClose,
  id,
  onLogout,
  onSetDefaultLocation,
  onSettings,
  openLocationsModal,
  username,
}) => {
  const dispatch = useDispatch();
  const locations = useSelector(selectUserLocations);

  const onOpenHandler = (event) => {
    dispatch(userGetLocations());
    openLocationsModal && openLocationsModal(event);
  };

  const menuItems = (locations || []).map((location) => {
    return (
      <MenuItem
        key={location?.key || location?.id}
        onClick={() => onSetDefaultLocation(location)}
        className={classes.menuItem}
      >
        {location?.name}
        {location?.defaultLoc && (
          <Tooltip title="Default" placement="right">
            <StarIcon fontSize="small" style={{ marginLeft: 10 }} />
          </Tooltip>
        )}
      </MenuItem>
    );
  });

  return (
    <Popper className={classes.root} id={id} open={anchorEl !== null} anchorEl={anchorEl} placement="bottom-end">
      <Paper>
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={() => {
            handleClose();
            closeLocationsModal();
          }}
        >
          <MenuList className={classes.list}>
            <MenuItem disabled className={classes.menuItem}>
              {username}
            </MenuItem>
            <div className={classes.locMenuItem}>
              {anchorElDefaultLoc ? (
                <ExpandRightIcon className={classes.expandIcon} />
              ) : (
                <ExpandLeftIcon className={classes.expandIcon} />
              )}
              <MenuItem onClick={onOpenHandler} className={classes.menuItem}>
                {defaultLocation?.name || "No default location"}
              </MenuItem>
            </div>
            <Popper
              className={classes.locationRoot}
              id={id}
              open={!!anchorElDefaultLoc}
              anchorEl={anchorElDefaultLoc}
              placement="left"
            >
              <Paper>
                <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={closeLocationsModal}>
                  <MenuList className={classes.locationList}>{menuItems}</MenuList>
                </ClickAwayListener>
              </Paper>
            </Popper>
            <MenuItem onClick={onSettings} className={classes.menuItem}>
              Settings
            </MenuItem>
            <MenuItem onClick={onLogout} className={classes.menuItem}>
              Logout
            </MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </Popper>
  );
};

export default withStyles(styles)(UserMenu);
