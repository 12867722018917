import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Messages from './Messages';
import * as chatActions from '../../../../../store/actions/chat/action';
import chatService from '../../../../../services/chat';
import log from '../../../../../utils/logger';

const logger = log('MessagesWrapper');

const MessagesWrapper = ({
	contact,
	newMessages,
	...props
}) => {
	const [conversation, setConversation] = useState([]);
	const [message, setMessage] = useState('');
	const [error, setError] = useState(false);
	const [scroll, setScroll] = useState(false);

	const messagesRef = useRef();
	const errorTimeout = useRef();

	const dispatch = useDispatch();
	const getHistory = useCallback(async scroll => {
		if (!contact?.target && !contact?.groupId) return setConversation([]);

		try {
			const chat = await chatService.getChatHistory(contact);

			setConversation(chat);

			setScroll(scroll);
		} catch (e) {
			setConversation([]);
			logger.warn(e.description || e.message || 'Could not get chat history');
		};
	}, [contact]);

	useEffect(() => {
		//onMount
		getHistory(true);
	}, [getHistory]);

	useEffect(() => {
		//scroll to last message
		if (scroll) {
			messagesRef?.current?.lastElementChild
				&& messagesRef.current.lastElementChild.scrollIntoView();

			setScroll(false);
		};
	}, [scroll]);

	useEffect(() => {
		//onNewMessage
		if (newMessages?.has(contact?.target) || newMessages?.has(contact?.groupId)) {
			const newMessage = newMessages.toJS()[contact.groupId || contact.target];

			setConversation(state => [...state,
				{ ...newMessage, message: newMessage.message, sender: newMessage.customerCode }]);

			setScroll(messagesRef?.current
				&& messagesRef.current.scrollHeight - messagesRef.current.scrollTop < 500);

			dispatch(chatActions.chatUpdateMessages(contact?.groupId || contact?.target));
		};
	}, [newMessages, contact, dispatch]);

	//Clear error
	useEffect(() => {
		if (error) {
			errorTimeout.current = setTimeout(() => setError(), 3000);
		}

		return () => clearTimeout(errorTimeout.current);
	}, [error]);

	const onSend = useCallback(async () => {
		if (!message || !message.trim()) {
			return setError('Please writte a message before sending');
		};

		try {
			await chatService
				.sendMessage({ customerCode: contact?.target, message, groupId: contact?.groupId });

			!contact.groupId && setConversation(state => [...state, { sender: contact?.customer, message }]);

			setScroll(true);
			setMessage('');
		} catch (e) {
			logger.warn(e.description || e.message || 'Could not send message');
			setError(e.description || e.message || 'Could not send message');
		};
	}, [message, contact]);

	return (
		<Messages
			{...props}
			contact={contact}
			conversation={conversation}
			error={error}
			message={message}
			messagesRef={messagesRef}
			onSend={onSend}
			ownCode={contact?.customer} // TODO: this feels bad?!?
			setMessage={setMessage}
		/>
	);
};

export default MessagesWrapper;