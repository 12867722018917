import React from "react";
import withStyles from "@material-ui/styles/withStyles";

import { styles } from "./TripAnalysisDataGrid.styles";
import { listColumns } from "./TripAnalysisDataGrid.columns";
import { useDateTime } from "../../../common/hooks";
import { CustomDataGrid } from "../../../common/displays";

function TripAnalysisDataGrid({ classes, data, onLoadMore, onRowSelected, selectedRowId, listKey }) {
  const dt = useDateTime();
  const columns = listColumns(classes, dt, listKey);

  const selectedRowIndex = data.findIndex((item) => item?.localId === selectedRowId);
  return (
    <div className={classes.root}>
      {data && (
        <CustomDataGrid
          className={classes.datagrid}
          columns={columns}
          rows={data}
          onLoadMore={onLoadMore}
          onRowSelected={onRowSelected}
          total={10000}
          selectedIndex={selectedRowIndex}
        />
      )}
    </div>
  );
}

export default withStyles(styles)(TripAnalysisDataGrid);
