import React from 'react';
import {
	withStyles,
} from '@material-ui/core';
import { styles } from '../CommonStyles.styles';

const Aws = ({
	classes,
	editMode,
	placeholder,
	onChangeHandler,
	inputBuilders,
	isDefaultConfigs,
}) => {

	const {
		getLabelAndSelect,
		getLabelAndInput,
	} = inputBuilders || {};

	const providerOptions = ['aws', 'do'];

	const providerInput = getLabelAndSelect({
		editMode: editMode && !!isDefaultConfigs,
		options: providerOptions, fieldLabel: 'Provider', fieldString: 'awsS3_provider',
		onChangeHandler, placeholder, classes,
	});

	const awsS3Key = getLabelAndInput({
		editMode: editMode && !!isDefaultConfigs, fieldLabel: 'API key', fieldString: 'awsS3_key',
		onChangeHandler, placeholder, classes, rowsNumberString: '1',
	});

	const awsS3Secret = getLabelAndInput({
		editMode: editMode && !!isDefaultConfigs, fieldLabel: 'Secret key', fieldString: 'awsS3_secret', onChangeHandler,
		placeholder, classes, rowsNumberString: '1',
	});

	const awsS3Region = getLabelAndInput({
		editMode: editMode && !!isDefaultConfigs, fieldLabel: 'Region', fieldString: 'awsS3_region', onChangeHandler,
		placeholder, classes, rowsNumberString: '1',
	});

	const awsS3PrivateBucket = getLabelAndInput({
		editMode: editMode && !!isDefaultConfigs, fieldLabel: 'Private Bucket', fieldString: 'awsS3_private_bucket', onChangeHandler,
		placeholder, classes, rowsNumberString: '1',
	});

	const awsS3PublicBucket = getLabelAndInput({
		editMode: editMode && !!isDefaultConfigs, fieldLabel: 'Public Bucket', fieldString: 'awsS3_public_bucket', onChangeHandler,
		placeholder, classes, rowsNumberString: '1',
	});

	const awsS3InvoiceBucket = getLabelAndInput({
		editMode: editMode && !!isDefaultConfigs, fieldLabel: 'Invoice Bucket', fieldString: 'awsS3_invoice_bucket', onChangeHandler,
		placeholder, classes, rowsNumberString: '1',
	});

	const awsS3EndtripBucket = getLabelAndInput({
		editMode: editMode && !!isDefaultConfigs, fieldLabel: 'Endtrip Bucket', fieldString: 'awsS3_endtrip_bucket', onChangeHandler,
		placeholder, classes, rowsNumberString: '1',
	});

	const awsS3AppBucket = getLabelAndInput({
		editMode: editMode && !!isDefaultConfigs, fieldLabel: 'App Bucket', fieldString: 'awsS3_app_bucket', onChangeHandler,
		placeholder, classes, rowsNumberString: '1',
	});

	return (
		<div className={classes.root}>
			<div className={classes.verticalBlockTrips}>
				{providerInput}
				{awsS3Key}
				{awsS3Secret}
				{awsS3Region}
				{awsS3PrivateBucket}
				{awsS3PublicBucket}
				{awsS3InvoiceBucket}
				{awsS3EndtripBucket}
				{awsS3AppBucket}
			</div>
		</div>
	);
};


export default withStyles(styles)(Aws);


