const styles = () => ({

	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
	},
	
});

export default styles;