import React from 'react';
import ListItem from '../../../../../UI/List/ListItem/ListItem';
import {
	Tooltip,
	withStyles,
} from '@material-ui/core';
import {
	Create as EditIcon,
	InfoOutlined as InfoIcon,
	RemoveCircleOutline as OptionsIcon, // FIXME put correct icon
} from '@material-ui/icons';
import { dateFormatter } from '../../../../../../services/formatter';
import withPermission from '../../../../../../hoc/withPermission';
import { styles } from './NotificationItem.styles';
import classnames from 'classnames';

const NotificationItem = ({
	classes,
	className,
	notification = {},
	fields,
	onEdit,
	onRemove,
}) => {

	const {
		description,
		createDate: createDateOld,
		lastModified: lastModifiedOld,
		lastSent: lastSentOld,
	} = notification || {};

	const infoIcon = (
		<Tooltip
			title={description || ''}
			placement='left'
		>
			<InfoIcon
				className={classnames(classes.iconEnabled)}
			/>
		</Tooltip>
	);

	const editIcon = (
		<ButtonWithPermission
			title='Edit'
			Component={EditIcon}
			onClick={onEdit}
			className={classnames(classes.iconEnabled)}
		/>
	);

	const optionsIcon = (
		<ButtonWithPermission
			title='Delete'
			Component={OptionsIcon}
			onClick={onRemove}
			className={classnames(classes.iconEnabled)}
		/>
	);

	const editIcons = (
		<div className={classes.icons}>
			{infoIcon}
			{editIcon}
			{optionsIcon}
		</div>
	);

	const createDate = !!createDateOld
		&& dateFormatter.format(createDateOld, 'dateTime');

	const lastModified = !!lastModifiedOld
		&& dateFormatter.format(lastModifiedOld, 'dateTime');

	const lastSent = !!lastSentOld
		&& dateFormatter.format(lastSentOld, 'dateTime');

	const item = (
		<ListItem
			className={classnames(classes.root, className)}
			disableGutters={true}
			fields={fields}
			element={{
				...notification,
				createDate,
				lastModified,
				lastSent,
				editIcons,
			}}
		/>
	);

	return item;
};

export default withStyles(styles)(NotificationItem);

const ButtonWithPermission = withPermission(
	[{ resource: ['carclub', 'notifications', 'edit'] }]
)((
	{className, onClick, title, Component}
) => (
	<Tooltip
		title={title}
		placement='left'
	>
		<Component
			onClick={onClick}
			className={className}
		/>
	</Tooltip>
));