import React from 'react';
import AccountDetailsList from './AccountDetailsList/AccountDetailsList';
import { currencyFormatter } from '../../../services/formatter';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import styles from './CustomerAccountDetails.styles';
import classnames from 'classnames';

const CustomerAccountDetails = ({
	balance,
	bonus,
	classes,
	className,
	currentScreenSize,
	customer,
	getMoreRows,
	mode,
	onSelect,
	onSelectAll,
	onUnselect,
	payments,
	selected,
	setMode,
}) => {

	const selectedAmount = selected.reduce((sum, code) =>
		payments[code] ? sum + payments[code].amount : sum, 0);

	const title = (
		<div className={classes.title}>
			<div className={classes.selectTitle}>
				<Typography
					className={classnames(classes.selectText,
						'DETAILS' === mode && classes.selectedText)}
					onClick={() => 'DETAILS' !== mode && setMode('DETAILS')}
				>
					Account Details
				</Typography>
				<Typography
					className={classnames(classes.selectText,
						'BONUS' === mode && classes.selectedText)}
					onClick={() => 'BONUS' !== mode && setMode('BONUS')}
				>
					Bonus
				</Typography>
			</div>
			<Typography className={classes.subTitle}>
				{customer.name}
			</Typography>
			<Typography className={classes.subTitle}>
				{customer.code}
			</Typography>
		</div>
	);

	const icons = (
		<div className={classes.icons}>
		</div>
	);

	const total = 'DETAILS' === mode
		? `Balance: ${balance === 0 ? 0 : balance || '-'} ${currencyFormatter.symbol()}`
		: `Bonus: ${bonus?.total === 0 ? 0 : bonus?.total || '-'} ${currencyFormatter.symbol()}`;

	const amounts = (
		<div className={classes.balance}>
			<Typography className={classes.balanceValue}>
				{total}
			</Typography>
		</div>
	);

	const header = (
		<div className={classes.header}>
			{title}
			{icons}
			{amounts}
		</div>
	);

	const list = payments && (
		<AccountDetailsList
			bonusList={bonus?.bonusList && Object.values(bonus?.bonusList)}
			className={classes.list}
			currentScreenSize={currentScreenSize}
			getMoreRows={getMoreRows}
			mode={mode}
			onSelect={onSelect}
			onSelectAll={onSelectAll}
			onUnselect={onUnselect}
			paymentsArray={Object.values(payments)
				.sort((a, b) => b.date - a.date)}
			selected={selected}
		/>
	);

	const totalAmount = 'DETAILS' === mode && (
		<Typography className={classes.totalAmount}>
			{`Selected amount: ${currencyFormatter.format(selectedAmount)} ${currencyFormatter.symbol()}`}
		</Typography>
	);

	const customerAccountDetails = (
		<div className={classnames(classes.root, className)}>
			{header}
			{list}
			{totalAmount}
		</div>
	);

	return customerAccountDetails;
};

export default withStyles(styles)(CustomerAccountDetails);