import Incident from '../../../../model/Incident/Incident';

export const fromDTO = ({
	administrativeCost,
	attachment,
	carToken,
	customerCode,
	description,
	incidentCost,
	incidentType,
	liabilityPercentage,
	licensePlate,
	reservationCode,
}) => new Incident({
	administrativeCost,
	attachment,
	carToken,
	customerCode,
	description,
	incidentCost,
	incidentType,
	liabilityPercentage,
	licensePlate,
	reservationCode,
});

export const toDTO = () => alert('Mapper Undefined');

const mapper = {
	fromDTO,
	toDTO,
};

export default mapper;