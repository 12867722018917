export const TEXT = 'TEXT';
export const COMPONENT = 'COMPONENT';
export const SPECIAL_TEXT = 'SPECIAL_TEXT';

const types = {
	TEXT,
	COMPONENT,
	SPECIAL_TEXT,
};

export default types;