import React, { useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import {
  Typography,
  ClickAwayListener,
  Popper,
  MenuList,
  MenuItem,
  Tooltip,
  IconButton,
  Badge,
} from "@material-ui/core";
import FilterListIcon from '@material-ui/icons/FilterList';

import { styles } from "./CustomerStateFilter.styles";

function CustomerStateFilter({ classes, onSelectStatesChange, states }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const onMenuItemClickedHandler = (option) => {
    if (option === "ALL") {
      onSelectStatesChange && onSelectStatesChange([]);
    } else {
      let exists = states.find((item) => item === option);
      let nOptions;
      if (exists) {
        nOptions = states.filter((item) => item !== option);
      } else {
        nOptions = [...states, option];
      }
      onSelectStatesChange && onSelectStatesChange(nOptions);
    }
  };

  return (
    <div className={classes.root}>
      <Tooltip title="Drivers Filter">
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
          <Badge color="primary" overlap="rectangular" invisible={!states || states.length === 0} variant="dot">
            <FilterListIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popper className={classes.menu} open={Boolean(anchorEl)} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <MenuList>
            {/* ALL */}
            <MenuItem
              onClick={() => onMenuItemClickedHandler("ALL")}
              className={states.find((item) => item === "ALL") && classes.selected}
            >
              <Typography>All Customers</Typography>
            </MenuItem>
            {/* ONBOARDING */}
            <MenuItem
              onClick={() => onMenuItemClickedHandler("ONBOARDING")}
              className={states.find((item) => item === "ONBOARDING") && classes.selected}
            >
              <Typography>Onboarding</Typography>
            </MenuItem>
            {/* AGENT */}
            <MenuItem
              onClick={() => onMenuItemClickedHandler("AGENT")}
              className={states.find((item) => item === "AGENT") && classes.selected}
            >
              <Typography>Agent</Typography>
            </MenuItem>
            {/* DISABLED */}
            <MenuItem
              onClick={() => onMenuItemClickedHandler("DISABLED")}
              className={states.find((item) => item === "DISABLED") && classes.selected}
            >
              <Typography>Disabled</Typography>
            </MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}

export default withStyles(styles)(CustomerStateFilter);
