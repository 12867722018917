import React, { useEffect } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Grid } from "@material-ui/core";
import Button from "../../../components/UI/Buttons/CommonButtons";

import { styles } from "./ZonesParksForm.styles";

import {
  selectWantedZones,
  selectUnwantedZones,
  selectParks,
  assetAddParksAndZones,
  selectZonesAndParksSuccessTrigger,
  selectLoader,
} from "../assetSlice";
import { useSelector, useDispatch } from "react-redux";
import { Form, FormFields } from "../../../common/forms";
import { formSchema, formFields } from "./ZonesParksForm.schema";

function ZonesParksForm({ classes, carToken, deviceToken, licensePlate, wanted, unwanted, parkIds, onAdded }) {
  const wantedZones = useSelector(selectWantedZones);
  const unwantedZones = useSelector(selectUnwantedZones);
  const parks = useSelector(selectParks);
  const addedSuccessTrigger = useSelector(selectZonesAndParksSuccessTrigger);
  const loading = useSelector(selectLoader);
  console.log("loading: %o", loading);

  useEffect(() => {
    if (addedSuccessTrigger) {
      onAdded && onAdded(addedSuccessTrigger);
    }
    // eslint-disable-next-line
  }, [addedSuccessTrigger]);

  const dispatch = useDispatch();
  const onSubmit = (values) => {
    // console.log("values: %o", values);
    dispatch(assetAddParksAndZones(values));
  };

  return (
    <div className={classes.root}>
      <Form
        schema={formSchema()}
        initialValues={{ carToken, deviceToken, licensePlate, wanted, unwanted, parkIds }}
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        render={({ submitting, form }) => {
          return (
            <>
              <FormFields
                fields={formFields(classes, submitting || loading, wantedZones, unwantedZones, parks, form)}
              />
              <Grid container justifyContent="center" className={classes.formButtons}>
                <Button
                  buttonStyleName="filled"
                  type="submit"
                  disabled={submitting || loading}
                  label="Update zones and parks"
                />
              </Grid>
            </>
          );
        }}
      />
    </div>
  );
}

export default withStyles(styles)(ZonesParksForm);
