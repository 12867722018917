
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_REQUEST_IS_MALFORMED,
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import rateMapper from './mappers/rateMapper';
import { publishAndAwait } from '../../../app/coreSlice';

export const createRate = async (rate) => {

	const {
		goldPinInUse,
		goldPinInStandby,
		name,
		priceInStandby,
		priceInUse,
		priceKm,
		rateNumber,
	} = rate || {};

	if (typeof rate === 'undefined'
		|| typeof goldPinInUse === 'undefined'
		|| typeof goldPinInStandby === 'undefined'
		|| typeof name === 'undefined'
		|| typeof priceInStandby === 'undefined'
		|| typeof priceInUse === 'undefined'
		|| typeof priceKm === 'undefined'
		|| typeof rateNumber === 'undefined') {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	};



	const dto = rateMapper.toDTO(rate);

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'RATE',
			subType: 'CREATE',
			message: {
				...dto,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	return processMessage || null;
};

export default createRate;