import React, { useRef } from "react";
import {
	Typography,
	FormControl,
	Grid,
	Input,
	InputAdornment,
	IconButton,
} from "@material-ui/core";

import Publish from "@material-ui/icons/Publish";

export default function InvoiceConfigImageUpload({
	classes,
	editMode,
	fieldLabel,
	field,
	value,
	onChangeHandler,
	onImageUploadHandler,
}) {
	const inputFileRef = useRef(null);

	const labelField = (
		<Typography key={"ic_label_" + field} className={classes.label}>
			{fieldLabel}
		</Typography>
	);

	const handleUploadClick = () => {
		inputFileRef.current.click();
	};

	const handleUploadMouseDown = (event) => {
		event.preventDefault();
	};

	const imageHandler = async (event) => {
		event.target.files &&
			event.target.files[0] &&
			onImageUploadHandler(event.target.files[0]);
	};

	const inputField = (
		<FormControl className={classes.fieldImageUpload}>
			<Input
				key={"ic_input_" + field}
				className={classes.inputProps}
				type="text"
				value={value}
				disabled={!editMode}
				onChange={(e) => onChangeHandler(field, e.target.value)}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							aria-label="upload"
							disabled={!editMode}
							onClick={handleUploadClick}
							onMouseDown={handleUploadMouseDown}
						>
							<Publish />
						</IconButton>
					</InputAdornment>
				}
			/>
		</FormControl>
	);

	return (
		<Grid container direction="row" alignItems="flex-end">
			<Grid item md={4}>
				{labelField}
			</Grid>
			<Grid item md={8}>
				{inputField}
				<input
					ref={inputFileRef}
					type="file"
					className={classes.hidden}
					accept="image/*"
					onChange={imageHandler}
					disabled={!editMode}
				/>
			</Grid>
		</Grid>
	);
}
