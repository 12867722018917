import React from 'react';
import ListItem from '../../../../UI/List/ListItem/ListItem';
import {
	Tooltip,
	withStyles,
} from '@material-ui/core';
import {
	Check as TrueIcon,
	Create as UpdateIcon,
	RemoveCircleOutline as EnableIcon,
} from '@material-ui/icons';
import { dateFormatter, currencyFormatter } from '../../../../../services/formatter';
import withPermission from '../../../../../hoc/withPermission';
import styles from './RolesItem.styles';
import classnames from 'classnames';

const RolesItem = ({
	classes,
	fields,
	onEdit,
	onEnable,
	onSelect,
	permissions,
	role,
	selected,
}) => {

	const rolesUpdate = (
		<Tooltip title='Update' placement='left'>
			<UpdateIcon
				onClick={() => {
					onSelect(role.id);
					onEdit(true);
				}}
				className={classnames(classes.editIcon)}
			/>
		</Tooltip>
	);

	//TODO: undestand if this makes sense and ask backend for flow?
	const rolesEnable = false && (
		<Tooltip title='Update' placement='left'>
			<EnableIcon
				onClick={() => {
					onEnable(role.id);
				}}
				className={classnames(classes.editIcon)}
			/>
		</Tooltip>
	);

	const icons = (
		<ActionButtons>
			{rolesUpdate}
			{rolesEnable}
		</ActionButtons>
	);

	const enabled = role.enabled 
		? <TrueIcon className={classes.checkIcon} />
		: undefined;

	const createDate = !!role.createDate
		&& dateFormatter.format(role.createDate, 'dateTime');

	const permission = permissions && (permissions.find(
		permission => permission.id === role.permissionId) || {}).name;

	const maxAmount = `${role.maxAmount || 0} ${currencyFormatter.symbol()}`;

	const item = (
		<ListItem
			selected={selected}
			fields={fields}
			onSelect={() => onSelect(role.id)}
			element={{
				...role,
				createDate,
				enabled,
				icons,
				maxAmount,
				permission,
			}}
		/>
	);

	return item;
};

const ActionButtons = withPermission(
	[{ resource: ['carclub', 'roles', 'edit'] }]
)(({
	children,
}) => (
	<>
		{children}
	</>
));

export default withStyles(styles)(RolesItem);