
import CustomerDTO from "./CustomerDTO";

const _getFullName = (fullname, name, surname) => {
  if (!!fullname) {
    return fullname;
  }

  if (!!name && !!surname) {
    return name + " " + surname;
  }

  return name || surname;
};

export const mapperCustomerFlows = (input) => {
  const {
    aceptTermsDate,
    activationDate,
    address,
    addressCity,
    addressCountry,
    addressState,
    addressZipCode,
    available,
    billingAddress,
    billingAddressCity,
    billingAddressCountry,
    billingAddressState,
    billingAddressZipCode,
    birthDate,
    carclubId,
    code,
    createDate,
    customerCode,
    deactivated,
    email,
    emailValidation,
    fatherName,
    fullname,
    grandFatherName,
    id,
    imageUrl,
    isAgent,
    mobileNumber,
    mobile,
    mobileValidation,
    name,
    nationality,
    nif,
    phoneNumber,
    policyPrivate,
    socialName,
    surname,
    taxNumber,
    affiliateId,
    ...rest
  } = input;
  return {
    acceptedTerms: aceptTermsDate,
    activationDate: activationDate || undefined,
    address: address || "",
    available: available !== null ? available : undefined,
    billingAddress: billingAddress || "",
    billingCity: billingAddressCity || "",
    billingCountry: billingAddressCountry || "",
    billingState: billingAddressState || "",
    billingZipCode: billingAddressZipCode || undefined,
    birthdate: birthDate || undefined,
    carclubId: carclubId || undefined,
    city: addressCity || "",
    code: code || customerCode || "",
    country: addressCountry || "",
    createDate: createDate || undefined,
    customerCode: code || customerCode || "",
    enabled: !deactivated,
    email: email || "",
    emailVerified: emailValidation !== null ? emailValidation : undefined,
    fullName: fullname || _getFullName(fullname, name, surname) || "",
    grandParentName: grandFatherName || "",
    id: id || undefined,
    imageUrl: imageUrl || undefined,
    isAgent: isAgent !== null ? isAgent : undefined,
    mobileVerified: mobileValidation !== null ? mobileValidation : undefined,
    mobile: mobile || phoneNumber,
    name: name || "",
    nationality: nationality || "",
    nif: nif || undefined,
    parentName: fatherName || "",
    phone: phoneNumber || undefined,
    phoneNumber: mobileNumber || phoneNumber || undefined,
    privacyPolicy: policyPrivate !== null ? policyPrivate : undefined,
    registrationDate: createDate || undefined,
    socialName: socialName || "",
    state: addressState || "",
    surname: surname || "",
    taxNumber: taxNumber || undefined,
    zipCode: addressZipCode || "",
    affiliateId: affiliateId || "",
    ...rest,
  };
};

export const mapperForGetCustomerPaymentInfo = ({ cardNumber, cardType, code, customerCode, paymentType }) =>
  ({
    cardNumber: cardNumber,
    cardType: cardType,
    code: code || customerCode,
    customerCode: customerCode,
    paymentType: paymentType,
  });

export const toDTO = ({
  acceptedTerms,
  activationDate,
  address,
  agent,
  available,
  billingAddress,
  billingCity,
  billingCountry,
  billingState,
  billingZipCode,
  birthdate,
  carclubId,
  city,
  code,
  country,
  createDate,
  customerCode,
  email,
  emailVerified,
  fullName,
  id,
  imageUrl,
  mobileVerified,
  mobile,
  name,
  nationality,
  nif,
  onboarded,
  phoneNumber,
  privacyPolicy,
  registrationDate,
  socialName,
  state,
  surname,
  taxNumber,
  termsVersion,
  zipCode,
  rfidToken,
}) =>
  new CustomerDTO({
    aceptTermsDate: acceptedTerms !== null ? acceptedTerms : undefined,
    activationDate: activationDate || undefined,
    address: address || "",
    addressCity: city || "",
    addressCountry: country || "",
    addressState: state || "",
    addressZipCode: zipCode || "",
    isAgent: agent !== null ? agent : undefined,
    available: available !== null ? available : undefined,
    billingAddress: billingAddress || "",
    billingAddressCity: billingCity || "",
    billingAddressCountry: billingCountry || "",
    billingAddressState: billingState || "",
    billingAddressZipCode: billingZipCode || undefined,
    birthDate: birthdate || undefined,
    carclubId: carclubId || undefined,
    code: code || customerCode || "",
    createDate: createDate || registrationDate || undefined,
    customerCode: customerCode || "",
    email: email || "",
    emailValidation: emailVerified !== null ? emailVerified : undefined,
    fullName: fullName || _getFullName(null, name, surname) || "",
    fullname: fullName || _getFullName(null, name, surname) || "",
    id: id || undefined,
    imageUrl: imageUrl || undefined,
    mobileNumber: phoneNumber || "",
    mobileValidation: mobileVerified !== null ? mobileVerified : undefined,
    name: name || "",
    nationality: nationality || "",
    nif: nif || "",
    onboarded,
    policyPrivate: privacyPolicy !== null ? privacyPolicy : undefined,
    socialName: socialName || "",
    surname: surname || "",
    taxNumber: taxNumber || undefined,
    termsVersion,
    rfidToken,
  });

const customerFlowMapper = {
  fromDTO: (customer) => mapperCustomerFlows({ ...customer }),
  toDTO: (customer) => toDTO({ ...customer }),
  fromPaymentInfoDTO: (customer) => mapperForGetCustomerPaymentInfo(...customer),
};

export default customerFlowMapper;
