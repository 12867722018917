export const styles = (theme) => ({
  root: {},
  listItem: {
    borderTop: "1px solid",
    borderColor: theme.palette.grey["300"],
    paddingBottom: 0,
    paddingTop: 0,
  },
  accordion: {
    "& .MuiAccordionDetails-root": {
      padding: 0,
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "auto",
    },
    "& > .MuiAccordionSummary-root": {
      backgroundColor: "#f5f7f9",
      "& .MuiAccordionSummary-content": {
        margin: "20px 0",
      },
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "20px 0",
    },
    "&.Mui-expanded": {
      margin: "0 1px",
    },
    backgroundColor: "#ffffff",
  },
  noAssets: {
    margin: 8,
    color: "#999",
  },
});
