import React, { useState, useEffect, useCallback } from 'react';
import promotionService from '../../../services/promotions';
import IndividualBonus from './IndividualBonus';

const IndividualBonusWrapper = ({idToSearch, ...props}) => {
	const [drawer, setDrawer] = useState(false);
	const [bonuses, setBonuses] = useState([]);
	const [selectedIndividualBonus, setSelectedIndividualBonus] = useState();
	const [lastPage, setLastPage] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [filter, setFilter] = useState(idToSearch || '');
	const [hasNotification, setHasNotification] = useState({});

	//ERROR HANDLING
	const handleNotification = useCallback((message, success) => {
		const notificationTimeout = 2500;
		setHasNotification({message, success});
		setTimeout(() => {
			setHasNotification({});
		}, notificationTimeout);
	}, []);

	//onMount
	useEffect(() => {
		const onMount = async () => {
			try {
				const {
					lastPage: lPage,
					bonuses: bonusList,
				} = await promotionService.listIndividualBonus({filter: idToSearch});

				setLastPage(lPage);
				setBonuses(bonusList);
			} catch (e) {
				handleNotification(e?.message || e?.description || 'Could not get list');
			}
		};
		onMount();
	}, [handleNotification, idToSearch]);

	const addBonus = useCallback(bonus => setBonuses(prev => {
		const keys = new Set(bonus.map(obj => obj.id));
		
		return [...bonus, ...prev.filter(obj => !keys.has(obj.id))];
	}), []);

	const getMoreRows = useCallback(async () => {
		try {
			const {
				lastPage: lPage,
				bonuses: bonusList,
			} = await promotionService.listIndividualBonus({ page: currentPage + 1, filter });

			setLastPage(lPage);
			setCurrentPage(currentPage + 1);
			setBonuses(prev => [...prev, ...bonusList]);
		} catch (e) {
			handleNotification(e?.message || e?.description || 'Could not get more rows');
		}
	}, [currentPage, filter, handleNotification]);

	const onSearch = useCallback(async () => {
		try {
			const {
				lastPage: lPage,
				bonuses: bonusList,
			} = await promotionService.listIndividualBonus({ filter });

			setLastPage(lPage);
			setCurrentPage(1);
			setBonuses([...bonusList]);
		} catch (e) {
			handleNotification(e?.message || e?.description || 'Could not return search');
		}
	}, [filter, handleNotification]);

	return (
		<IndividualBonus
			{...props}
			addBonus={addBonus}
			bonuses={bonuses}
			drawer={drawer}
			filter={filter}
			getMoreRows={getMoreRows}
			handleNotification={handleNotification}
			hasNotification={hasNotification}
			lastPage={lastPage}
			onSearch={onSearch}
			selectedIndividualBonus={selectedIndividualBonus}
			setDrawer={setDrawer}
			setFilter={setFilter}
			setSelectedIndividualBonus={setSelectedIndividualBonus}
		/>
	);
};

export default IndividualBonusWrapper;