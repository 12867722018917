import React from "react";
import { Typography, Divider, ListItem, List, withStyles } from "@material-ui/core";
import {
  CheckBoxOutlineBlankOutlined as DefaultAddIcon,
  CheckBoxOutlined as DefaultRemoveIcon,
} from "@material-ui/icons";
import SearchBar from "../Searchbar/Searchbar";
import styles from "./SearchList.styles";
import classnames from "classnames";

const SearchList = ({
  addIcon,
  classes,
  className,
  currentFields,
  disabled,
  disableScrollToTop = false,
  error,
  extraFilter,
  extraIcon,
  fields,
  header,
  listRef,
  name,
  onAdd,
  onExtra,
  onRemove,
  onSearch,
  onSelect,
  overrideTitleDiv = false,
  removeIcon,
  searchLabel,
  searchText,
  setSearchText,
  style,
  subList,
}) => {
  const {
    classNameTitle,
    classNameHeader,
    classNameHeaderItem,
    classNameSearch,
    classNameFiltersRoot,
    classNameListItem,
    classNameExtraButton,
  } = style || {};

  const Add = addIcon || DefaultAddIcon;
  const Remove = removeIcon || DefaultRemoveIcon;
  const Extra = extraIcon || null;

  const title =
    name && "string" === typeof name ? (
      <Typography
        className={classnames(classes.title, classNameTitle, error && classes.error, disabled && classes.disabled)}
      >
        {name}
      </Typography>
    ) : (
      name
    );

  const searchBar = (
    <SearchBar
      className={classNameFiltersRoot}
      disabled={disabled}
      error={error}
      filter={searchText}
      label={searchLabel}
      onSearch={() => {
        onSearch();
      }}
      setFilter={setSearchText}
    />
  );

  const titleDiv = !overrideTitleDiv ? (
    <div className={classnames(classes.titleDiv, error && classes.error)}>
      {title}
      {searchBar}
      {extraFilter}
    </div>
  ) : (
    <div className={classnames(classes.titleDivOverride, error && classes.error)}>
      <div className={classnames(classes.titleDiv, error && classes.error)}>{title}</div>
      <div className={classnames(classes.titleDiv, error && classes.error, classNameSearch)}>
        {searchBar}
        {extraFilter}
      </div>
    </div>
  );

  const listHeader = Array.isArray(header) ? (
    <div className={classnames(classes.header, classNameHeader)}>
      {header.map((header) => (
        <Typography
          className={classnames(
            classes.listHeader,
            disabled && classes.disabled,
            error && classes.error,
            classNameHeaderItem
          )}
          key={header}
        >
          {header}
        </Typography>
      ))}
      <div className={classnames(classes.buttons, classNameHeaderItem)}></div>
    </div>
  ) : (
    <Typography
      className={classnames(classes.listHeader, error && classes.error, disabled && classes.disabled, classNameHeader)}
    >
      {header}
    </Typography>
  );

  const current = Array.isArray(currentFields) ? currentFields : getCurrent(currentFields);

  const selected = current.map((selected) => selected?.key);

  const results = Array.isArray(fields) ? fields.filter((field) => !selected.includes(field.key)) : [];

  const items = [...current, ...results].map((field, i) => {
    const { key, label, associated, ...rest } = field;

    const hasField = (selected && selected.includes(key)) || associated;

    const restArray = Object.values(rest);

    const addButton = (
      <Add
        className={classnames(classes.button, hasField && classes.disabled)}
        onClick={() => {
          if (onAdd && !disabled && !hasField) {
            onAdd(key, label, ...restArray);
            const div = document.getElementById("search_list_" + name);
            if (!disableScrollToTop && !!div) {
              div.scrollTop = 0;
            }
          }
        }}
      />
    );

    const removeButton = (
      <Remove
        className={classnames(classes.button, !hasField && classes.disabled)}
        onClick={() => !disabled && hasField && onRemove && onRemove(key)}
      />
    );

    const extraButton = !!extraIcon && (
      <Extra className={classnames(classes.button, classNameExtraButton)} onClick={() => onExtra(key)} />
    );

    let button = hasField ? removeButton : addButton;

    const mainLabel = (
      <Typography className={classnames(classes.label, disabled && classes.disabled)}>{label}</Typography>
    );

    const extraLabels =
      Array.isArray(header) &&
      restArray.map(
        (label, index) =>
          header.length - 1 > index && (
            <Typography key={`label_${index}`} className={classes.label}>
              {label}
            </Typography>
          )
      );

    return (
      <div key={"list_wrapper_" + i}>
        <ListItem
          key={key + i}
          className={classnames(classes.listItem, classNameListItem)}
          disableGutters={true}
          onClick={() => onSelect && onSelect(field)}
        >
          <div className={classnames(classes.item)}>
            {mainLabel}
            {extraLabels}
            <div className={classes.buttons}>
              {extraButton}
              {button}
            </div>
          </div>
        </ListItem>
        {subList?.key === key && subList}
      </div>
    );
  });

  const list = (
    <List id={"search_list_" + name} className={classes.list} ref={listRef}>
      {items}
    </List>
  );

  return (
    <div className={classnames(classes.root, className)}>
      {titleDiv}
      {listHeader}
      <Divider />
      {list}
    </div>
  );
};

export default withStyles(styles)(SearchList);

const getCurrent = (current) => (current?.key ? [current] : []);
