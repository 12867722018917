import React from 'react';
import FeaturesList from './FeaturesList/FeaturesList';
import {
	FormControl,
	InputBase,
	InputLabel,
	TextField,
	Tooltip,
	Typography,
	withStyles,
} from '@material-ui/core';
import {
	AddCircle as AddIcon,
	RemoveCircle as RemoveIcon,
	ExpandMore as ArrowUpIcon,
	ExpandLess as ArrowDownIcon,
} from '@material-ui/icons';
import withPermission from '../../../../hoc/withPermission';
import styles from './Create.styles';
import classnames from 'classnames';

const Create = ({
	classes,
	className,
	error,
	features,
	onAdd,
	onCancel,
	onChange,
	onReOrder,
	onSelect,
	onUpdate,
	placeholder,
	selected,
}) => {

	const createField = ({ field, label, text, disabled }) => (
		<FormControl>
			{!!label && (
				<InputLabel>
					{label}
				</InputLabel>
			)}
			<InputBase
				placeholder={text || undefined}
				classes={{
					root: classes.inputRoot,
					input: classnames(classes.inputInput,
						!!error && !placeholder[field] && classes.inputError),
				}}
				disabled={!!disabled}
				value={placeholder[field]}
				onChange={event => onChange(event.target.value, field)}
			/>
		</FormControl>
	);

	const isUpdating = placeholder.id;

	const titleText = isUpdating ? 'Editing carflow' : 'Creating carflow';

	const saveUpdateText = isUpdating ? 'Update' : 'Save';

	const TextButton = TextButtonWithPermission(isUpdating);

	const title = (
		<Typography className={classes.title}>
			{titleText}
		</Typography>
	);

	const saveUpdate = (
		<TextButton
			className={classes.save}
			align='right'
			onClick={onUpdate}
			text={saveUpdateText}
		/>
	);

	const cancel = (
		<TextButton
			className={classes.cancel}
			align='right'
			onClick={onCancel}
			text='Cancel'
		/>
	);

	const description = (
		<TextField
			className={classes.description}
			fullWidth
			multiline
			onChange={event => onChange(event.target.value, 'description')}
			placeholder='Description'
			minRows='7'
			value={placeholder.description}
			variant='filled'
		/>
	);

	const type = createField({ field: 'type', text: 'Type' });

	const subtype = createField({ field: 'subtype', text: 'Subype' });

	const createDate = createField({
		field: 'createDate',
		label: 'Create Date',
		disabled: true,
	});

	const createdBy = createField({
		field: 'createdBy',
		label: 'Created By',
		disabled: true,
	});

	const Button = ButtonWithPermission(isUpdating);

	const addButton = (
		<Button
			Component={AddIcon}
			title='Add Feature'
			className={classes.button}
			onClick={onAdd}
		/>
	);

	const removeButton = (
		<Button
			Component={RemoveIcon}
			title='Remove Feature'
			className={classes.button}
			onClick={() => onReOrder()}
		/>
	);

	const orderMoreButton = (
		<Button
			Component={ArrowUpIcon}
			title='Move Feature Down'
			className={classes.button}
			onClick={() => onReOrder(1)}
		/>
	);

	const orderLessButton = (
		<Button
			Component={ArrowDownIcon}
			title='Move Feature Up'
			className={classes.button}
			onClick={() => onReOrder(-1)}
		/>
	);

	const featuresManage = (
		<div className={classes.manage}>
			<div className={classes.higherSpace} />
			<Typography>
				Type
			</Typography>
			<div className={classes.higherSpace} />
			<Typography>
				Subtype
			</Typography>
			<div className={classes.space} />
			{orderLessButton}
			{orderMoreButton}
			<div className={classes.space} />
			{removeButton}
			{addButton}
		</div>
	);

	const filters = (
		<div className={classes.filters}>
			<div className={classes.subFilters}>
				{type}
				{subtype}
			</div>
			<div className={classes.subFilters}>
				{createDate}
				{createdBy}
			</div>
			{description}
			{featuresManage}
		</div>
	);

	const list = (
		<FeaturesList
			array={placeholder.features}
			features={features}
			onSelect={onSelect}
			selected={selected}
		/>
	);

	const featuresError = !!error && placeholder.features.length <= 0 && (
		<Typography className={classes.error} align='center'>
			You must select at least one feature
		</Typography>
	);

	const create = (
		<div className={classnames(classes.root, className)}>
			<div className={classes.div}>
				{title}
				{cancel}
				{saveUpdate}
			</div>
			<div className={classes.div}>
				{filters}
			</div>
			{featuresError}
			{list}
		</div>
	);

	return create;
};

export default withStyles(styles)(Create);

const TextButtonWithPermission = isUpdating => withPermission(
	[{ resource: ['carclub', 'carflows', isUpdating ? 'edit' : 'create'] }]
)((
	{ className, onClick, text }
) => (
	<Typography
		className={className}
		align='right'
		onClick={onClick}
	>
		{text}
	</Typography>
));

const ButtonWithPermission = isUpdating => withPermission(
	[{ resource: ['carclub', 'carflows', isUpdating ? 'edit' : 'create'] }]
)((
	{ className, onClick, title, Component }
) => (
	<Tooltip
		title={title}
		placement='left'
	>
		<Component
			onClick={onClick}
			className={className}
		/>
	</Tooltip>
));