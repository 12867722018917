import React from "react";
import GoogleMap from "google-map-react";
import withStyles from "@material-ui/styles/withStyles";
import styles from "./MainMap.styles";
import { useSelector } from "react-redux";
import { selectCarclubInfo } from "../../features/carclub/carclubSlice";

const MainMap = ({
  pos,
  markers,
  onAPIloaded,
  onMapChange,
  zoom,
  styles = {},
  onChildClick,
  onChildMouseEnter,
  onChildMouseLeave,
}) => {
  const carclubInfo = useSelector(selectCarclubInfo);

  const mapOptions = (maps) => {
    return {
      disableDefaultUI: true,
      ...styles,
    };
  };

  const center =
    pos?.lat || pos?.latitude
      ? {
          lat: Number(pos.latitude || pos.lat) || null,
          lng: Number(pos.longitude || pos.lng) || null,
        }
      : null;
  return carclubInfo ? (
    <GoogleMap
      center={center}
      defaultCenter={{ lat: 0, lng: 0 }}
      bootstrapURLKeys={{ key: carclubInfo?.secrets?.googleMapsAPIKey, libraries: ["drawing", "geometry"] }}
      zoom={zoom}
      defaultZoom={13}
      options={mapOptions}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={(google) => onAPIloaded(google)}
      onChange={onMapChange ? onMapChange : () => null}
      onChildClick={onChildClick}
      onChildMouseEnter={onChildMouseEnter}
      onChildMouseLeave={onChildMouseLeave}
    >
      {markers}
    </GoogleMap>
  ) : null;
};

MainMap.defaultProps = {
  onAPIloaded: () => undefined,
};

export default withStyles(styles)(MainMap);
