import makeActionCreator from '../../utils/makeActionCreator';
import createReducer from '../../hooks/createCustomReducer';

//TODO: Refactor to use other hooks
const types = {
	ADD_DETAIL: 'ADD_DETAIL',
	EDIT_DETAIL: 'EDIT_DETAIL',
	REMOVE_DETAIL: 'REMOVE_DETAIL',
	RESET_PLACEHOLDER: 'RESET_PLACEHOLDER',
	SET_CATEGORY: 'SET_CATEGORY',
	SET_CODE: 'SET_CODE',
	SET_CUMULATIVE: 'SET_CUMULATIVE',
	SET_DESCRIPTION: 'SET_DESCRIPTION',
	SET_DETAILS: 'SET_DETAILS',
	SET_NAME: 'SET_SELECTED_PRICING_PLAN',
	SET_STARTING_FEE: 'SET_STARTING_FEE',
	SET_SPECIFIC: 'SET_SPECIFIC',
};

export const addDetail = makeActionCreator(types.ADD_DETAIL, 'detail');
export const editDetail = makeActionCreator(types.EDIT_DETAIL, 'detail', 'index');
export const removeDetail = makeActionCreator(types.REMOVE_DETAIL, 'index');
export const resetPlaceholder = makeActionCreator(types.RESET_PLACEHOLDER);
export const setCategory = makeActionCreator(types.SET_CATEGORY, 'category');
export const setCode = makeActionCreator(types.SET_CODE, 'id');
export const setCumulative = makeActionCreator(types.SET_CUMULATIVE, 'cumulative');
export const setDescription = makeActionCreator(types.SET_DESCRIPTION, 'description');
export const setDetails = makeActionCreator(types.SET_DETAILS, 'details');
export const setName = makeActionCreator(types.SET_NAME, 'name');
export const setStartingFee = makeActionCreator(types.SET_STARTING_FEE, 'startFee');
export const setSpecific = makeActionCreator(types.SET_SPECIFIC, 'specific');

const actions = dispatch => ({
	addDetail: data => dispatch(addDetail(data)),
	editDetail: (detail, index) => dispatch(editDetail(detail, index)),
	removeDetail: index => dispatch(removeDetail(index)),
	resetPlaceholder: () => dispatch(resetPlaceholder()),
	setCategory: data => dispatch(setCategory(data)),
	setCode: data => dispatch(setCode(data)),
	setCumulative: data => dispatch(setCumulative(data)),
	setDescription: data => dispatch(setDescription(data)),
	setDetails: details => dispatch(setDetails(details)),
	setName: data => dispatch(setName(data)),
	setStartingFee: data => dispatch(setStartingFee(data)),
	setSpecific: data => dispatch(setSpecific(data)),
});

export const defaultState = {
	id: undefined,
	name: '',
	description: '',
	startFee: 0,
	cumulative: false,
	specific: false,
	category: 0,
	details: [],
};

const setNameReducer = (state, action) => ({
	...state,
	name: action.name,
});

const setCodeReducer = (state, action) => ({
	...state,
	id: action.id,
});

const setDescriptionReducer = (state, action) => ({
	...state,
	description: action.description,
});

const setStartingFeeReducer = (state, action) => {
	if (isNaN(Number(action.startFee))) {
		return state;
	};

	return {
		...state,
		startFee: action.startFee,
	};
};

const setCategoryReducer = (state, action) => {
	if (isNaN(Number(action.category))) {
		return state;
	};

	return {
		...state,
		category: action.category,
	};
};

const setCumulativeReducer = (state, action) => ({
	...state,
	cumulative: action.cumulative,
});

const setSpecificReducer = (state, action) => ({
	...state,
	specific: action.specific,
});

const addDetailReducer = (state, action) => {

	const details = (
		!!state.details
		&& Array.isArray(state.details)
		&& state.details.map(detail => ({...detail}))
	) || [];
		
	const currentDetails = [...details, action.detail];

	return {
		...state,
		details: currentDetails,
	};
};

const editDetailReducer = (state, action) => {

	const {
		index,
		detail,
	} = action;

	const details = (
		!!state.details
		&& Array.isArray(state.details)
		&& state.details.map(detail => ({...detail}))
	) || [];

	const currentDetails = [
		...details.slice(0, index),
		detail,
		...details.slice(index + 1, details.length),
	];

	return {
		...state,
		details: currentDetails,
	};
};

const removeDetailReducer = (state, action) => {

	const { index } = action;
	const { details } = state;
	
	const currentDetails = [...details.slice(0, index),
		...details.slice(index + 1, details.length)];

	return {
		...state,
		details: currentDetails,
	};
};

const setDetailsReducer = (state, action) => {

	const details = (
		!!action.details
		&& Array.isArray(action.details)
		&& action.details.map(detail => ({...detail}))
	) || [];

	return {
		...state,
		details: details,
	};
};

const resetPlaceholderReducer = () => defaultState;

export const reducer = (state, action) => ({
	[types.ADD_DETAIL]: addDetailReducer,
	[types.EDIT_DETAIL]: editDetailReducer,
	[types.REMOVE_DETAIL]: removeDetailReducer,
	[types.RESET_PLACEHOLDER]: resetPlaceholderReducer,
	[types.SET_CATEGORY]: setCategoryReducer,
	[types.SET_CODE]: setCodeReducer,
	[types.SET_CUMULATIVE]: setCumulativeReducer,
	[types.SET_DESCRIPTION]: setDescriptionReducer,
	[types.SET_DETAILS]: setDetailsReducer,
	[types.SET_NAME]: setNameReducer,
	[types.SET_STARTING_FEE]: setStartingFeeReducer,
	[types.SET_SPECIFIC]: setSpecificReducer,
}[action.type](state, action));

export default createReducer({ reducer, actions, defaultState });