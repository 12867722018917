import React, {useCallback, useEffect, useState} from 'react';
import accountService from '../../../services/account/ws';
import TripsModal from './TripsModal';
import log from '../../../utils/logger';

const logger = log('TripsModalWrapper');

const filterTrips = filter => trip =>
	(!filter.customerCode || trip.customerCode.includes(filter.customerCode))
	&& (!filter.state || trip.state.includes(filter.state))
	&& (!filter.startDate || new Date(trip.startDate) > filter.startDate)
	&& (!filter.endDate || new Date(trip.endDate) < filter.endDate);

const sortTrips = filter => (a, b) =>
	filter.orderStart
		? new Date(a.startDate) - new Date(b.startDate)
		: new Date(b.startDate) - new Date(a.startDate); 

const TripsModalWrapper = props => {

	const {
		accountCode,
		customerCode,
		setCustomerCode,
	} = props;

	const [trips, setTrips] = useState([]);
	const [filters, setFilters] = useState(
		{orderStart: true, page: 1, customerCode});
	const [lastPage, setLastPage] = useState(false);

	const getTrips = useCallback(async () => {
		try{
			const {
				lastPage,
				list,
			} = await accountService.getAccountReservations(
				{accountCode, ...filters});

			setTrips(prev => filters.page === 1 ? list : [...prev, ...list]);
			setLastPage(lastPage);
		}
		catch(error){
			logger.warn(`Could not get the trip details for ${accountCode}`);
		};
	}, [accountCode, filters]);

	useEffect(() => {
		getTrips();
	}, [getTrips]);
	
	useEffect(() => {
		return () => setCustomerCode();
	}, [setCustomerCode]);

	const tripsModal = (
		<TripsModal
			{...{
				...props,
				filters,
				lastPage,
				setFilters,
				trips: trips && trips.filter(filterTrips(filters)).sort(sortTrips(filters)),
			}}
		/>
	);

	return tripsModal;
};

export default TripsModalWrapper;