import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Grid } from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";

import { styles } from "./AssetFields.styles";
import AssetProperty from "./AssetProperty/AssetProperty";
import Attributes from "./Attributes/Attributes";

function AssetFields({ classes, asset }) {
  return (
    <Grid className={classes.root} container spacing={2}>
      <Grid item xs={4}>
        <AssetProperty label="Asset Name" value={asset?.assetName} />
        <AssetProperty label="License Plate" value={asset?.licensePlate} />
        <AssetProperty label="Make" value={asset?.make} />
        <AssetProperty label="Model" value={asset?.model} />
        <AssetProperty label="VIN Number" value={asset?.vinNumber} />
      </Grid>
      <Grid item xs={4}>
        {asset?.imageUrl ? (
          <img src={asset?.imageUrl} alt="Asset" className={classes.image} />
        ) : (
          <div className={classes.noimage}>
            <ImageIcon fontSize="large" style={{ fill: "#fff" }} />
          </div>
        )}
        <br />
        <AssetProperty label="Class" value={asset?.carClass} />
        <AssetProperty label="Fuel Type" value={asset?.fuel} />
        <AssetProperty label="Year" value={asset?.year} />
        <AssetProperty label="Transmission" value={asset?.transmission} />
      </Grid>
      <Grid item xs={4}>
        <Attributes asset={asset} />
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(AssetFields);
