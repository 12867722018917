import { wsCall, requestHelper } from "../../../app/coreSlice";

const carclubGetConfigsCall = async ({ setCarclubConfigs }, dispatch, state) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "CARCLUB/GET_CONFIGS");
  let result;
  try {
    result = await wsCall({
      type: "CARCLUB",
      subType: "GET_CONFIGS",
      locationId: selectedLocation?.id,
    });

    if (!result.error) {
      dispatch(setCarclubConfigs(result));
      // refresh reservations
    } else {
      rh.error(result?.description || "Error loading carclub configs.");
    }
  } catch (_ex) {
    rh.error("Error loading carclub configs.");
  }
  rh.close();
  return result;
};

export default carclubGetConfigsCall;
