import { Map, fromJS } from 'immutable';
import Trip from '../../model/Trip/Trip';


export const TRIP_TYPES = {
	CURRENT: 'CURRENT',
	HISTORY: 'HISTORY',
};
const {
	CURRENT,
	HISTORY,
} = TRIP_TYPES;

export const TRIP_STATE = {
	ONGOING: 'ONGOING',
	STARTED: 'STARTED',
	READY: 'READY',
	IDLE: 'IDLE',
	SCHEDULED: 'SCHEDULED',
	CANCELLED: 'CANCELLED',
	CLOSED: 'CLOSED',
	ABSENT: 'ABSENT',
};

const {
	ONGOING,
	READY,
	STARTED,
	IDLE,
	SCHEDULED,
	CANCELLED,
	CLOSED,
	ABSENT,
} = TRIP_STATE;

export const TRIP_TYPE = {
	ADVANCED: 'ADVANCED',
	IMMEDIATE: 'IMMEDIATE',
};

export const DISPLAY_MODES = {
	SINGLE_LIST_WITH_FILTERS: 'SINGLE_LIST_WITH_FILTERS',
	PRE_FILTERED_MULTI_LIST: 'PRE_FILTERED_MULTI_LIST',
};

const TRIP_TYPES_BY_MODE = {
	CURRENT: [
		ONGOING,
		READY,
		SCHEDULED,
		STARTED,
		IDLE,
	],
	HISTORY: [
		CANCELLED,
		CLOSED,
		ABSENT,
	],
};

const filterTrips = (tripsArray = [], tripType) => {
	return tripsArray.filter(trip => {
		return TRIP_TYPES_BY_MODE[tripType].includes(trip.state);
	});
};

const indexToMapByCode = (tripsArray = [], keyString = 'code') => {
	let indexedMap = Map();
	tripsArray.forEach(trip => {
		indexedMap = indexedMap.set(trip[keyString], trip);
	});
	return indexedMap;
};

export const initialState = Map({
	displayMode: DISPLAY_MODES.SINGLE_LIST_WITH_FILTERS,
	tripType: TRIP_TYPES.CURRENT,
	currentTrips: Map(),
	historyTrips: Map(),
	currentTripsMap: Map(),
	selectedTrip: {},
	elementsPerPage: 50,
	modalContent: null,
	focusRow: undefined,
});

export const actionTypes = {
	ADD_CURRENT_TRIPS: 'ADD_CURRENT_TRIPS',
	ADD_HISTORY_TRIPS: 'ADD_HISTORY_TRIPS',
	ADD_TRIPS: 'ADD_TRIPS',
	CLEAR_CURRENT_TRIPS: 'CLEAR_CURRENT_TRIPS',
	CLEAR_HISTORY_TRIPS: 'CLEAR_HISTORY_TRIPS',
	DELETE_TRIP: 'DELETE_TRIP',
	GET_TRIPS: 'GET_TRIPS',
	SET_CURRENT_TRIPS: 'SET_CURRENT_TRIPS',
	SET_DISPLAY_MODE: 'SET_DISPLAY_MODE',
	SET_HISTORY_TRIPS: 'SET_HISTORY_TRIPS',
	SET_TRIP_TYPE: 'SET_TRIP_TYPE',
	UPDATE_HISTORY_TRIP: 'UPDATE_HISTORY_TRIP',
	UPDATE_TRIP: 'UPDATE_TRIP',
};

const {
	SET_DISPLAY_MODE,
	SET_TRIP_TYPE,
} = actionTypes;

export const actions = {
	setDisplayModeList: {
		type: SET_DISPLAY_MODE,
		displayMode: DISPLAY_MODES.SINGLE_LIST_WITH_FILTERS,
	},
	setDisplayModeCards: {
		type: SET_DISPLAY_MODE,
		displayMode: DISPLAY_MODES.PRE_FILTERED_MULTI_LIST,
	},
	setTripTypeCurrent: {
		type: SET_TRIP_TYPE,
		tripType: TRIP_TYPES.CURRENT,
	},
	setTripTypeHistory: {
		type: SET_TRIP_TYPE,
		tripType: TRIP_TYPES.HISTORY,
	},

};

export const tripReducer = (state, action) => {

	switch (action.type) {
		case actionTypes.SET_DISPLAY_MODE:
			return state.set('displayMode', action.displayMode);

		case actionTypes.SET_TRIP_TYPE:
			return state.set('tripType', action.tripType);

		case actionTypes.ADD_CURRENT_TRIPS:
			return state.update('currentTrips', map => {
				const filteredArray = filterTrips(action.trips, CURRENT);
				const newMap = indexToMapByCode(fromJS(filteredArray));
				return map.merge(newMap);
			});

		case actionTypes.ADD_HISTORY_TRIPS:
			return state.update('historyTrips', map => {
				const filteredArray = filterTrips(action.trips, HISTORY);
				const newMap = indexToMapByCode(fromJS(filteredArray));
				return map.merge(newMap);
			});

		case actionTypes.CLEAR_CURRENT_TRIPS:
			return state.update('currentTrips', map => map.clear());

		case actionTypes.CLEAR_HISTORY_TRIPS:
			return state.update('historyTrips', map => map.clear());

		case actionTypes.DELETE_TRIP:
			return  state.deleteIn(['currentTrips', action.trip.code], {});

		case actionTypes.UPDATE_TRIP:
			const currentTrip = state.getIn(['currentTrips', action.trip.code]);
			const updatedTrip = new Trip({...currentTrip, ...action.trip});
			return state.update('currentTrips', map => map.set(updatedTrip.code, updatedTrip));

		case actionTypes.UPDATE_HISTORY_TRIP:
			const currentHistoryTrip = state.getIn(['historyTrips', action.trip.code]);
			const updatedHistoryTrip = new Trip({...currentHistoryTrip, ...action.trip});
			return state.update('historyTrips', map => map.set(updatedHistoryTrip.code, updatedHistoryTrip));

		default:
			return state;
	}
};