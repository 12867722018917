import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { TextField, Typography } from "@material-ui/core";

import { styles } from "./_base.styles";

function TextInput({
  classes,
  id,
  className,
  label,
  value,
  onChange,
  disabled,
  type = "text",
  error,
  required,
  variant,
  multiline,
  rows,
  placeholder,
}) {
  return (
    <div className={classes.root + " " + className}>
      {variant === "outlined" && (
        <Typography
          component="span"
          variant="caption"
          htmlFor={id}
          color="textPrimary"
          className={disabled ? classes.disabledLabel : ""}
        >
          {label}
          {required && <span> *</span>}
        </Typography>
      )}
      <TextField
        id={id}
        fullWidth
        type={type}
        className={disabled ? classes.disabledInput : ""}
        error={error}
        label={label ? (variant === "outlined" ? undefined : label + (required ? " *" : "")) : undefined}
        onChange={(e) => onChange && onChange(e.target.value)}
        value={value}
        disabled={disabled}
        variant={variant}
        size="small"
        multiline={multiline}
        minRows={rows}
        maxRows={rows}
        placeholder={placeholder}
      />
    </div>
  );
}

export default withStyles(styles)(TextInput);
