import Trip from "../../../../model/Trip/Trip";
import { getReservationStatus } from "../../../../model/Reservation/ReservationStateType";

export const fromDTO = ({
  advanced,
  agent,
  assetName,
  authorizeStatus,
  blocked,
  carToken,
  code,
  createDate,
  customerCode,
  deviceToken,
  distance,
  endDate,
  groupId,
  imgAsset,
  isAgent,
  lateDelivery,
  licensePlate,
  locationId,
  longTrip,
  operator,
  paid,
  parkEnd,
  parkOn,
  parkStart,
  paymentIssue,
  realEnd,
  realStart,
  reservationCode,
  startDate,
  state,
  toMigrate,
  type,
  underReview,
  wantedEndDate,
  wantedStartDate,
  rfidTrip,
  apiTrip,
  goldPin,
  operationType
}) =>
  new Trip({
    advanced,
    agent,
    assetName,
    authorizeStatus,
    blocked,
    carToken,
    code: reservationCode || code,
    createDate,
    customerCode,
    deviceToken,
    distance,
    endDate,
    groupId,
    imgUrl: imgAsset,
    isAgent,
    lateDelivery,
    licensePlate,
    locationId,
    longTrip,
    operator,
    paid,
    parkEnd,
    parkOn,
    parkStart,
    paymentIssue,
    realEnd,
    realStart,
    startDate,
    state: getReservationStatus({ state }),
    toMigrate,
    type,
    underReview,
    wantedEndDate,
    wantedStartDate,
    rfidTrip,
    apiTrip,
    goldPin,
    operationType
  });

export const toDTO = ({
  agent,
  assetName,
  authorizeStatus,
  blocked,
  carToken,
  code,
  distance,
  endDate,
  lateDelivery,
  licensePlate,
  locationId,
  longTrip,
  operator,
  paid,
  parkEnd,
  parkStart,
  paymentIssue,
  realEnd,
  realStart,
  startDate,
  state,
  toMigrate,
  type,
  underReview,
}) => ({
  agent,
  assetName,
  authorizeStatus,
  blocked,
  carToken,
  distance,
  endDate,
  lateDelivery,
  licensePlate,
  locationId,
  longTrip,
  operator,
  paid,
  parkEnd,
  parkStart,
  paymentIssue,
  realEnd,
  realStart,
  reservationCode: code,
  startDate,
  state,
  toMigrate,
  type,
  underReview,
});

const mapper = { fromDTO, toDTO };

export default mapper;
