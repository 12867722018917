import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid, Button } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { styles } from "./MoveDriverForm.styles";
import { SelectInput } from "../../../../common/inputs";

import { selectUserLocations } from "../../../user/userSlice";
import {
  customerChangeLocation,
  selectErrorMovingDriver,
  selectSelectedCustomer,
  setShowMoveDriverModal,
} from "../../customerSlice";

function MoveDriverForm({ classes }) {
  const dispatch = useDispatch();
  const selectedCustomer = useSelector(selectSelectedCustomer);
  const locations = useSelector(selectUserLocations) || [];
  const [newLocation, setNewLocation] = useState();
  const errorMovingCustomer = useSelector(selectErrorMovingDriver);

  console.log("selectedCustomer: %o", selectedCustomer);

  const onUpdateHandler = () => {
    dispatch(customerChangeLocation({ customerCode: selectedCustomer?.code, locationId: newLocation }));
  };

  return (
    <Grid container className={classes.root} direction="column" spacing={2} justifyContent="space-between">
      <Grid item>
        <Typography className={classes.title}>
          {`Move Driver to another location: ${selectedCustomer?.code}`}
        </Typography>
      </Grid>
      <Grid item>
        <SelectInput
          label="Location"
          value={newLocation || selectedCustomer?.locationId}
          options={locations.map((item) => ({
            text: item.name,
            value: item.id,
          }))}
          onChange={(locationId) => setNewLocation(locationId)}
        />
      </Grid>
      <Grid item>
        {errorMovingCustomer && (
          <Grid container direction="row" spacing={1} alignItems="center" wrap="nowrap">
            <Grid item>
              <InfoOutlinedIcon fontSize="small" color="error" style={{ display: "block" }} />
            </Grid>
            <Grid item>
              <Typography color="error">{errorMovingCustomer}</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item>
        <Grid container direction="row" justifyContent="center" spacing={4}>
          <Grid item>
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => {
                dispatch(setShowMoveDriverModal(false));
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              size="small"
              disabled={Boolean(!newLocation || newLocation === selectedCustomer?.locationId || errorMovingCustomer)}
              onClick={onUpdateHandler}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(MoveDriverForm);
