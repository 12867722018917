import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid, Button } from "@material-ui/core";

import { styles } from "./ExternalApi.styles";
import { formFieldsNotification, formSchema } from "./ExternalApi.schema";
import { Form, FormFields } from "../../../../common/forms";

import { selectIsLoading, selectCarclubConfigs, carclubGetConfigs, carclubUpdateConfigs } from "../../carclubSlice";

function ExternalApi({ classes, disabled, submitRef }) {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const loading = useSelector(selectIsLoading);
  const configs = useSelector(selectCarclubConfigs);

  useEffect(() => {
    if (!loading && !configs && !loaded) {
      dispatch(carclubGetConfigs());
      setLoaded(true);
    }
    // eslint-disable-next-line
  }, [loading]);

  const onSubmit = (values) => {
    let nConfigs = { ...configs, EXTERNAL_APIS: values };
    console.log("nConfigs: %o", nConfigs);
    dispatch(carclubUpdateConfigs(nConfigs));
  };

  let initialValues = configs?.EXTERNAL_APIS;
  if (!initialValues?.notification) {
    initialValues = { notification: { active: false } };
  }

  return (
    <div className={classes.root}>
      <Form
        schema={formSchema()}
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ submitting }) => {
          return (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography className={classes.title}>Notification</Typography>
                <FormFields fields={formFieldsNotification(disabled)} />
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={12}>
                <Button
                  style={{ display: "none" }}
                  ref={submitRef}
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          );
        }}
      />
    </div>
  );
}

export default withStyles(styles)(ExternalApi);
