import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Paper, Typography, Grid } from "@material-ui/core";

import { styles } from "./LoginForm.styles";
import { formSchema, formFields } from "./LoginForm.schema";
import { Form, FormFields } from "../../../common/forms";
import Button from "../../../components/UI/Buttons/CommonButtons";

import { hash } from "../../../common/utils";
import NetworkSpinner from "../../../components/UI/Spinner/NetworkSpinner/NetworkSpinner";
import { useNavigate, useParams } from "react-router-dom";
import {
  initWS,
  selectConnected,
  selectConnectError,
  selectConnecting,
  selectCredentials,
} from "../../../app/websocket/websocketSlice";

function LoginForm({ classes }) {
  const connected = useSelector(selectConnected);
  const connecting = useSelector(selectConnecting);
  const credentials = useSelector(selectCredentials);
  const error = useSelector(selectConnectError);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { carclubCode } = useParams();

  useEffect(() => {
    if (!connecting) {
      if (connected) {
        navigate(carclubCode ? `/${carclubCode}` : "/");
      } else {
        let account;
        try {
          account = JSON.parse(localStorage.getItem("account-credentials"));
        } catch (_ex) {}
        console.log("account: %o", account);
        if (account?.username && account?.carclub === carclubCode.toUpperCase()) {
          dispatch(initWS(account));
        }
      }
    }
    // eslint-disable-next-line
  }, [connected, connecting]);

  const onSubmit = ({ email, password }) => {
    let credentials = {
      username: email.toLowerCase(),
      password: hash(password),
      carclub: carclubCode.toUpperCase(),
    };
    dispatch(initWS(credentials));
  };
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Paper className={classes.paper}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <img className={classes.logo} alt="logo" src={process.env.REACT_APP_LOGIN_LOGO} />
            </Grid>
            <Grid item>
              <Typography component="h1" variant="h5" align="center">
                Sign in
              </Typography>
            </Grid>
            <Grid item>
              <Form
                onSubmit={onSubmit}
                initialValues={{ email: credentials?.username }}
                keepDirtyOnReinitialize
                schema={formSchema()}
                render={({ submitting }) => (
                  <Grid container direction="column" spacing={2}>
                    {connecting && (
                      <Grid item>
                        <div style={{ width: 58, margin: "0 auto" }}>
                          <NetworkSpinner />
                        </div>
                      </Grid>
                    )}
                    <Grid item>
                      <FormFields fields={formFields({ disabled: connecting })} />
                    </Grid>
                    {error?.tag && (
                      <Grid item>
                        <Typography component="p" variant="body1" color="error" align="center">
                          Invalid username or password
                        </Typography>
                      </Grid>
                    )}
                    <Grid item>
                      <Button
                        className={classes.button}
                        buttonStyleName="filled"
                        type="submit"
                        disabled={submitting || connecting}
                        label="Login"
                      />
                    </Grid>
                  </Grid>
                )}
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
}

export default withStyles(styles)(LoginForm);
