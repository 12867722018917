import { wsCall, requestHelper } from "../../../app/coreSlice";

export const bigdataListCommsCall = async (
  { clearActivityLogs, appendActivityLogs, setActivityLogsIsLastPage },
  dispatch,
  state,
  { clear, start, end, deviceToken, page, commType, limit }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "BIGDATA/LIST_COMMS");
  try {
    if (clear) {
      dispatch(clearActivityLogs());
    }

    const result = await wsCall({
      type: "BIGDATA",
      subType: "LIST_COMMS",
      locationId: selectedLocation?.id,
      message: {
        start,
        end,
        deviceToken,
        page,
        commType,
        limit,
      },
    });
    // console.log("------------------ result: %o", result);

    if (result) {
      const { lastPage, commsList } = result;
      dispatch(appendActivityLogs(commsList));
      dispatch(setActivityLogsIsLastPage(lastPage));
    }
  } catch (_ex) {
    rh.error("Error getting device comms.");
  }
  rh.close();
};

export default bigdataListCommsCall;
