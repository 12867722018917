import React from "react";
import { TableFooter, TableRow, TableCell } from "@material-ui/core";

export default function CustomDataGridFooter({ colspan, children }) {
  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={colspan}>{children}</TableCell>
      </TableRow>
    </TableFooter>
  );
}
