import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Modal from "@material-ui/core/Modal";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import MoreIcon from "@material-ui/icons/AddBox";
import MinusIcon from "@material-ui/icons/IndeterminateCheckBox";
import ChargeIcon from "@material-ui/icons/MonetizationOn";
import InvoiceIcon from "@material-ui/icons/Receipt";
import RemoveIcon from "@material-ui/icons/RemoveCircle";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import DownloadInvoiceIcon from "@material-ui/icons/PictureAsPdf";
import MailIcon from "@material-ui/icons/MailOutline";
import classnames from "classnames";
import styles from "./Item.styles";
import { dateFormatter } from "../../../../../services/formatter";
import Button from "../../../../UI/Buttons/CommonButtons";
import useCurrency from "../../../../../common/hooks/useCurrency";

const infoFields = [
  { field: "code", style: "bold" },
  { field: "amount", currency: true },
  { field: "numberOfTrips" },
  { field: "startDate", date: true },
  { field: "endDate", date: true },
  { field: "issueDate", date: true },
  { field: "dueDate", date: true },
  { field: "paymentDate", date: true },
];

const subInfoHeader = [
  { value: "Reservation No.", style: "bold" },
  { value: "Amount", style: "bold" },
  { value: "Customer No.", style: "bold" },
  { value: "Customer Name", style: "bold" },
];

const subInfoFields = [
  { field: "reservationCode" },
  { field: "preAmount", currency: true },
  { field: "customerCode" },
  { field: "customerName", style: "larger" },
];

const Item = ({
  billingItem,
  classes,
  hasModal,
  isExpanded,
  isSendingMail,
  onInvoice,
  onOpenTransactions,
  onRemove,
  onSendMail,
  openChargingModal,
  setIsExpanded,
  setModal,
  sublist = [],
}) => {
  const { formatCurrency } = useCurrency();
  const flexItem = flexItemCreate(classes, formatCurrency);

  const info = infoFields.map(flexItem(billingItem));

  const expansionIcon = isExpanded ? (
    <MinusIcon className={classes.expansionButton} onClick={() => setIsExpanded(false)} />
  ) : (
    <MoreIcon className={classes.expansionButton} onClick={() => setIsExpanded(true)} />
  );

  const actions = (
    <div className={classes.actions}>
      <Tooltip title="Transactions" placement="left">
        <InfoIcon className={classnames(classes.button)} onClick={onOpenTransactions} />
      </Tooltip>
      <Tooltip title="Generate invoice" placement="left">
        <InvoiceIcon
          className={classnames(classes.button, billingItem?.invoiced && classes.disabled)}
          onClick={() => !billingItem?.invoiced && onInvoice(billingItem.code)}
        />
      </Tooltip>
      <Tooltip title="Download invoice" placement="left">
        <DownloadInvoiceIcon
          className={classnames(classes.button, !billingItem?.pdfUrl && classes.disabled)}
          onClick={() => billingItem?.pdfUrl && window.open(billingItem.pdfUrl, "_blank")}
        />
      </Tooltip>
      <Tooltip title="Send invoice pdf" placement="left">
        <MailIcon
          className={classnames(
            classes.button,
            billingItem?.sendIntent && classes.greenIcon,
            !billingItem?.pdfUrl && classes.disabled
          )}
          onClick={() => billingItem?.pdfUrl && !isSendingMail && onSendMail(billingItem.code)}
        />
      </Tooltip>
      <Tooltip title="Settle" placement="left">
        <ChargeIcon
          className={classnames(classes.button, billingItem?.paid && classes.disabled)}
          onClick={() => !billingItem?.paid && openChargingModal(billingItem.code)}
        />
      </Tooltip>
      <Tooltip title="Delete" placement="left">
        <RemoveIcon
          className={classnames(classes.button, (billingItem?.paid || billingItem?.invoiced) && classes.disabled)}
          onClick={() => !billingItem?.paid && !billingItem?.invoiced && onRemove(billingItem.code)}
        />
      </Tooltip>
    </div>
  );

  const row = (
    <div className={classes.item}>
      {expansionIcon}
      {info}
      {actions}
    </div>
  );

  const subListDetails =
    isExpanded &&
    sublist.map((billingItem, index) => (
      <div className={classes.item} key={index}>
        <div className={classes.iconSpace} />
        {subInfoFields.map(flexItem(billingItem))}
      </div>
    ));

  const details = isExpanded && (
    <div className={classnames(classes.root)}>
      <div className={classes.subHeader}>{subInfoHeader.map(flexItem({}))}</div>
      {subListDetails}
    </div>
  );

  const modal = hasModal && (
    <Modal open={hasModal} onClose={() => setModal(false)}>
      <div className={classes.modal}>
        <Typography className={classes.text}>Your e-mail has been sent</Typography>
        <Button key="close_button" label="Close" onClick={() => setModal(false)} />
      </div>
    </Modal>
  );

  return (
    <ListItem className={classes.listItem} disableGutters>
      {modal}
      <div className={classes.root}>
        {row}
        {details}
      </div>
    </ListItem>
  );
};

export default withStyles(styles)(Item);

const flexItemCreate =
  (classes, formatCurrency) =>
  (billingItem) =>
  ({ field, date, value, style, currency }) =>
    (
      <Typography
        className={classnames(classes.flexItem, classes[style])}
        key={`${field}_${billingItem?.[field]}_${value}`}
      >
        {value ||
          (date && dateFormatter.format(billingItem?.[field], "date")) ||
          (currency && formatCurrency(billingItem?.[field])) ||
          billingItem?.[field]}
      </Typography>
    );
