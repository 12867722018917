import { SvgIcon } from "@material-ui/core";

export function PicturePlaceholder(props) {
  return (
    <SvgIcon {...props} viewBox="100 100 300 300">
      <rect
        fill="none"
        height="190.3"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        width="199.5"
        x="156.3"
        y="160.9"
      />
      <polyline
        fill="none"
        points="   156.3,315.7 219.7,256 275.7,315.7 304.7,285.8 355.7,351.1  "
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
      />
      <circle
        cx="303.9"
        cy="222.6"
        fill="none"
        r="26.2"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
      />
    </SvgIcon>
  );
}
