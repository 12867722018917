
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_REQUEST_IS_MALFORMED,
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import customerFlowMapper from './mappers/customerFlowMapper';
import { publishAndAwait } from '../../../app/coreSlice';

const getCarclubUserDetails = async (customerCode) => {

	if (typeof customerCode === 'undefined') {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	}

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'CUSTOMER',
			subType: 'NEW_CUSTOMER_DETAILS',
			message: {
				customerCode,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}
  
	const customer = customerFlowMapper.fromDTO(processMessage);
  
	return customer || null;

};

export default getCarclubUserDetails;