import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const billingRefundAdvancedCall = async (
  {
    reservationPaymentDetails,
  },
  dispatch,
  state,
  { reservationCode, carToken, refundAmount }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;

  let rh = requestHelper(dispatch, "BILLING/REFUND_ADVANCED");
  try {
    const result = await wsCall({
      type: "BILLING",
      subType: "REFUND_ADVANCED",
      locationId: selectedLocation?.id,
      message: {
        reservationCode,
        carToken,
        refundAmount,
      },
    });
    if (result) {
      console.log("result: %o", result);
      dispatch(setSuccess("Trip deposit refunded successfully!"));
      dispatch(reservationPaymentDetails({ reservationCode }));
    }
  } catch (_ex) {
    rh.error("Error creating invoice.");
  }
  rh.close();
};

export default billingRefundAdvancedCall;
