import React, { useState, useEffect } from 'react';
import Flows from './Flows';

const compare = (a, b) => ('' + a.type).localeCompare(b.type);
const listFilter = (element, filter) => 
	element.type.includes(filter.type) 
	&& element.subtype.includes(filter.subtype);

const FlowsWrapper = ({
	flows,
	carclub,
	carclubs,
	getFlows,
	setCarclub,
	...props
}) => {

	const [filter, setFilter] = useState({type: '', subtype: ''});
	const [list, setList] = useState([]);

	useEffect(() => {
		setList((flows && Object
			.values(flows)
			.sort(compare)
			.filter(element => listFilter(element, filter))
		) || []);
	}, [flows, filter]);

	const changeCarclubGetFlows = carclubId => {
		
		setCarclub(carclubId);
		getFlows(carclubId);
	};

	const changeFilter = (field, value) => {
		if (typeof value !== 'string'){
			return;
		};
		
		let string = value.trim();
		string = !!string ? string.toUpperCase() : '';

		setFilter({...filter, [field]: string});
	};

	return (
		<Flows
			{...props}
			flows={list}
			filter={filter}
			setFilter={changeFilter}
			carclub={carclub}
			setCarclub={changeCarclubGetFlows}
		/>
	);
};

export default FlowsWrapper;