import { wsCall, requestHelper } from "../../../app/coreSlice";

const bigdataDeviceHeartbeatsCall = async (
  { clearHeartbeats, setHeartbeats },
  dispatch,
  state,
  { clear, start, end, deviceToken, offset, limit }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "BIGDATA/DEVICE_HEARTBEATS");
  try {
    if (clear) {
      dispatch(clearHeartbeats());
    }

    const result = await wsCall({
      type: "BIGDATA",
      subType: "DEVICE_HEARTBEATS",
      locationId: selectedLocation?.id,
      message: {
        start,
        end,
        deviceToken,
        offset,
        limit,
      },
    });
    // console.log("------------------ result: %o", result);

    if (result) {
      const { rows, heartbeatsList } = result;
      dispatch(setHeartbeats({ list: heartbeatsList, totalItems: rows || 0 }));
    }
  } catch (_ex) {
    console.log("ex: %o", _ex);
    rh.error("Error getting device heartbeats.");
  }
  rh.close();
};

export default bigdataDeviceHeartbeatsCall;
