import React from "react";
import UpsertParkWrapper from "../UpsertPark/UpsertParkWrapper";
import importParks from "../../../services/ImportXML/CoordinatesXML";
import ParksList from "../ParksList/ParksList";
import log from "../../../utils/logger";

const logger = log("ParksWorkspace");

const stopDrawingSetup = (setIsDrawing, setModal, setIsCreating) => () => {
  setIsDrawing();
  setIsCreating && setIsCreating();
  setModal();
};

const stopRedrawing = async (park, polygon, stopDrawing, upsertPark) => {
  const { code, name, description, latitude, longitude, maxSpots } = park;

  try {
    let geo_information = [];

    const path = polygon.getPath();
    path.push(polygon.getPath().getAt(0));
    const points = path.getLength();

    for (let i = 0; i < points; i++) {
      geo_information = [...geo_information, [path.getAt(i).lat(), path.getAt(i).lng()]];
    }

    geo_information = geo_information.join(" ").replace(/\s/g, "],[");
    geo_information = `[${geo_information}]`;

    if (geo_information.length === 0) {
      geo_information = park.geoLocation;
    }

    const data = {
      code,
      name,
      description,
      latitude,
      longitude,
      maxSpots,
      geoLocation: geo_information,
    };

    await upsertPark(data);
    stopDrawing();
  } catch (error) {
    logger.warn("Unable to create/update park.");
  }
};

const ParksWorkspace = ({
  associatedAssets,
  classes,
  defaultLocationFromUser,
  deletePark,
  getAssociatedAssets,
  hasModal,
  hidePark,
  hideParks,
  isCreating,
  isDrawing,
  isImporting,
  parkCreator,
  parkPainter,
  parkRedrawer,
  parks,
  polygon,
  selectedPark,
  selectedParkCode,
  setIsImporting,
  setParks,
  setSelectedPark,
  setSelectedParkCode,
  setAssociatedAssets,
  showParks,
  upsertPark,
}) => {
  const selectPark = (park, editable = false) => {
    hideParks();

    const { code } = park || {};

    setSelectedParkCode(code);

    if (editable) {
      parkRedrawer(park, true);
      return;
    }

    parkPainter(park, true);
  };

  const cancelDrawing = () => {
    setIsImporting();
    hideParks();
    stopDrawing();
  };

  const setXML = (xml) => {
    hideParks();
    setIsImporting(true);
    setSelectedParkCode();
    isCreating.setOn();
    showParks.setOn();
    isDrawing.setOn();

    importParks(
      xml,
      (park) => {
        setSelectedPark(park);
        parkRedrawer(park, true);
      },
      cancelDrawing
    );
  };

  const createPark = () => {
    if (selectedPark) {
      setSelectedParkCode();
    }

    hideParks();

    isCreating.setOn();
    isDrawing.setOn();

    parkCreator();
  };

  const stopDrawing = stopDrawingSetup(isDrawing.setOff, hasModal.setOff, isCreating.setOff);

  const editPark = (park) => {
    setSelectedParkCode(park.code);
    isCreating.setOn();
    hasModal.setOn();
  };

  const redrawPark = (park) => {
    showParks.setOn();
    selectPark(park, true);
    isDrawing.setOn();
  };

  const upsertParkModal = hasModal.state && (
    <UpsertParkWrapper
      park={selectedPark}
      polygon={polygon}
      setParks={setParks}
      stopDrawing={() => {
        stopDrawing();
        hidePark("NEW");
      }}
      upsertPark={upsertPark}
      defaultLocationFromUser={defaultLocationFromUser}
    />
  );

  return (
    <>
      {showParks.state && (
        <ParksList
          cancelDrawing={cancelDrawing}
          confirmDrawing={() => {
            setIsImporting();
          }}
          createPark={createPark}
          deletePark={deletePark}
          editPark={editPark}
          isCreating={isCreating}
          isDrawing={isDrawing}
          isImporting={isImporting}
          getAssociatedAssets={getAssociatedAssets}
          associatedAssets={associatedAssets}
          parks={parks}
          redrawPark={redrawPark}
          selectedPark={selectedParkCode}
          setSelectedParkCode={setSelectedParkCode}
          selectPark={(park) => {
            selectPark(park);
            setIsImporting();
            isCreating.setOff();
            setAssociatedAssets([]);
          }}
          setParks={(data) => {
            hideParks();
            setParks(data);
          }}
          setXML={setXML}
          stopRedrawing={() => stopRedrawing(selectedPark, polygon, stopDrawing, upsertPark)}
        />
      )}
      {upsertParkModal}
    </>
  );
};

export default ParksWorkspace;
