export const styles = theme => ({

	root: {
		flexBasis: '60%',
	},

	workspace: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
	},

	list: {
		paddingTop: 6,
		flexBasis: '50%',
		maxHeight: '100px',
		overflowX: 'hidden',
		overflowY: 'auto',
		scrollbarWidth: 'thin',
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},

	parkList: {
		flexBasis: '100%',
	},


	listOnEdit: {
		flexBasis: '100%',
		maxHeight: '100%',
		width: '91%',
		alignSelf: 'flex-end',
	},

	onEditList: {
		paddingTop: 10,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},

	header: {
		height: 38,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderBottom: '2px solid #009ac9',
		alignItems: 'center',
	},

	title: {
		fontSize: 16,
		textAlign: 'left',
		color: '#a1a1a1',
	},

	subtitle: {
		width: '80%',
		fontSize: 14,
		textAlign: 'center',
		color: '#a1a1a1',
		borderBottom: '1px solid #a1a1a1',
	},

	listItem: {
		alignItems: 'center',
		padding: '1px',
		height: 'auto',
		// maxHeight: '25px',
		overflowY: 'hidden',
		overflowX: 'auto',
		scrollbarWidth: 'thin',
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},

	listItemIcon: {
		alignItems: 'center',
		padding: '1px',
		minWidth: '32px',
	},

	listItemIconOnEdit: {
		alignItems: 'center',
		padding: '1px',
		minWidth: '54px',
	},


});