export const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  title: {
    fontSize: "18px",
    padding: "8px 0",
  },
  icon: {
    color: "#009AC9",
    cursor: "pointer",
  },
  typeInput: {
    minWidth: 200,
  },
});
