import { createSlice } from "@reduxjs/toolkit";
import damageReportGetResolvedCall from "./_sliceCalls/damageReportGetResolved";
import damageReportListCall from "./_sliceCalls/damageReportList";
import damageReportResolveCall from "./_sliceCalls/damageReportResolve";

export const damagereportSlice = createSlice({
  name: "damagereport",
  initialState: {
    damageReports: {
      list: [],
      listSolved: [],
      assetToken: undefined,
      selectedDamage: undefined,
    },
  },
  reducers: {
    setDamageReports: (state, action) => {
      state.damageReports.list = action.payload;
    },
    clearDamageReports: (state) => {
      state.damageReports.list = [];
    },
    setDamageReportsSolved: (state, action) => {
      state.damageReports.listSolved = action.payload;
    },
    clearDamageReportsSolved: (state) => {
      state.damageReports.list = [];
    },
    setAssetToken: (state, action) => {
      state.damageReports.assetToken = action.payload;
    },
    setSelectedDamage: (state, action) => {
      state.damageReports.selectedDamage = action.payload;
    },
  },
});

export default damagereportSlice.reducer;

export const {
  setDamageReports,
  setDamageReportsSolved,
  setAssetToken,
  setSelectedDamage,
  clearDamageReports,
  clearDamageReportsSolved,
} = damagereportSlice.actions;

// SELECTS
export const selectIsLoading = (state) => Boolean(state.getIn(["core"])?.loader?.length > 0);
export const selectDamageReports = (state) => state.getIn(["damagereport"])?.damageReports;
export const selectSelectedDamage = (state) => {
  let reports = state.getIn(["damagereport"])?.damageReports;

  let selectedReport = (reports?.list || []).find(
    (item) => item.damageToken && item.damageToken === reports?.selectedDamage
  );
  if (!selectedReport) {
    selectedReport = (reports?.listSolved || []).find((item) => item.id && item.id === reports?.selectedDamage);
  }
  return selectedReport;
};

// CALLS

export const damageReportList =
  ({ assetToken }) =>
  async (dispatch, getState) => {
    damageReportListCall(damagereportSlice.actions, dispatch, getState(), { assetToken });
  };
export const damageReportGetResolved =
  ({ assetToken }) =>
  async (dispatch, getState) => {
    damageReportGetResolvedCall(damagereportSlice.actions, dispatch, getState(), { assetToken });
  };

export const damageReportResolve =
  ({ assetToken, damageToken }) =>
  async (dispatch, getState) => {
    damageReportResolveCall({ damageReportList, damageReportGetResolved }, dispatch, getState(), {
      assetToken,
      damageToken,
    });
  };
