import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Navigation from "./Navigation/Navigation";
import { Routes, Route } from "react-router-dom";

import Layout from "../../../containers/Layout/Layout";
import {
  carclubGetInfo,
  selectCarclubDetails,
  selectCarclubInfo,
  carclubDetails as carclubDetailsRequest,
} from "../../../features/carclub/carclubSlice";
import apps from "../../../containers/Layout/apps";
import s3Service from "../../../services/S3";
import { selectConnected } from "../../websocket/websocketSlice";
import { carclubGetDetails } from "../../../store/actions/carclub/action";
import { userGetLocations } from "../../../features/user/userSlice";

function MainRouting() {
  const connected = useSelector(selectConnected);
  const dispatch = useDispatch();
  const [firstLoad, setFirstLoad] = useState(true);
  const carclubInfo = useSelector(selectCarclubInfo);
  const carclubDetails = useSelector(selectCarclubDetails);

  useEffect(() => {
    if (connected && firstLoad) {
      console.log("FIRST LOAD");
      setFirstLoad(false);
      dispatch(carclubGetInfo());
      dispatch(carclubDetailsRequest());
      dispatch(userGetLocations());
    }
    // eslint-disable-next-line
  }, [connected, firstLoad]);

  useEffect(() => {
    if (carclubInfo && carclubDetails) {
      console.log("CARCLUB LOADED");
      s3Service.init({ ...carclubInfo.awsS3, code: carclubDetails.code });
      dispatch(carclubGetDetails(carclubDetails));
    }
    // eslint-disable-next-line
  }, [carclubInfo, carclubDetails]);

  // TEMP CODE - temporary support for old stuff that need to be refactored
  // ----------------------------------------------------------------------
  // console.log("carclubDetails: %o", carclubDetails);
  const appsWithPermissions = Object.keys(carclubDetails?.apps || {});
  const filteredApps = apps().filter((app) => appsWithPermissions.includes(app.tag));

  const [selectedApp, setSelectedApp] = useState("fleet");
  const [appProps, setAppProps] = useState();

  const selectAppHandler = (
    appName,
    appProps = {
      noAutoSearch: false,
      reservationCode: undefined,
    }
  ) => {
    // console.log("selectAppHandler: %o", appName);
    setSelectedApp(appName);
    setAppProps(appProps);
  };
  // ----------------------------------------------------------------------

  return (
    <Navigation
      filteredApps={filteredApps}
      selectedApp={selectedApp}
      appProps={appProps}
      selectAppHandler={selectAppHandler}
    >
      <Routes>
        <Route path="fleet/*" element={<b>ASSETS</b>} />
        <Route
          path="*"
          element={
            <Layout
              filteredApps={filteredApps}
              selectedApp={selectedApp}
              appProps={appProps}
              selectAppHandler={selectAppHandler}
            />
          }
        />
      </Routes>
    </Navigation>
  );
}

export default MainRouting;
