export const styles = () => ({
  root: {},
  title: {
    fontSize: 20,
    color: "#4095c2",
  },
  tabs: {
    marginRight: 15,
    "& .MuiTabs-indicator ": {
      backgroundColor: "#4095c2",
    },
    "& .MuiTab-root": {
      padding: 0,
      margin: 0,
      minWidth: "auto",
      flexGrow: 1,
    },
  },
  list: {
    height: "calc(90vh - 115px)",
    paddingTop: 2,
    "& li": {
      paddingLeft: "0 !important",
    },
  },
  grid: {
    height: "calc(90vh - 60px)",
    overflowX: "hidden",
    overflowY: "auto",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "5px",
      boxShadow: "inset 0 0 15px #00000060",
    },
  },
});
