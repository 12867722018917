export const getAssetStatus = ({ operational, inUse, assetUnused, deviceToken, assetToken }) => {
  if (!deviceToken && assetToken) {
    return "DEACTIVATED";
  }

  if (inUse) {
    return "IN_USE";
  }

  if (operational && assetUnused) {
    return "UNUSED";
  }

  if (operational) {
    return "AVAILABLE";
  }

  if (operational !== undefined) {
    return "UNAVAILABLE";
  }

  return "UNDEFINED";
};
