const styles = theme => ({

	root: {
		alingItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		height: '100%',
		position: 'relative',
		width: '100%',
	},

	list: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		paddingLeft: 21,
		paddingRight: 21,
		width: '100%',
	},

	details: {
		background: '#FAFAFA 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 6px #00000029',
		maxWidth: '284px',
		minWidth: '284px',
		overflowY: 'auto',
		padding: '0 21px',
		scrollbarWidth: 'thin',
		'&::-webkit-scrollbar': {
			width: '1px',
		},

		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)', 
		},
	},
	
	drawer: {
		height: '100%',
		maxWidth: '284px',
		minWidth: '284px',
		paddingRight: 21,
	},

	modal: {
		backgroundColor: 'white',
		borderRadius: '10px',
		boxShadow: theme.shadows[5],
		boxSizing: 'border-box',
		height: '90%',
		left: '10%',
		outline: 0,
		padding: '10px 20px',
		position: 'absolute',
		top: '5%',
		width: '80%',
	},
});

export default styles;