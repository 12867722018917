import React, { useEffect, useCallback, useState, useRef } from "react";
import { useDispatch } from "react-redux";

import carclubService from "../../../services/carclub";
import LocationModal from "./LocationModal";
import { operatorSetDefaultLoc } from "../../../features/carclub/carclubSlice";

const LocationModalWrapper = ({ onClose, ...props }) => {
  const [error, setError] = useState();
  const [hasRequest, setHasRequest] = useState(false);
  const [locations, setLocations] = useState([]);
  const [placeholder, setPlaceholder] = useState();

  const errorTimeout = useRef();

  const dispatch = useDispatch();

  const onMount = useCallback(async () => {
    try {
      setHasRequest(true);

      const locations = await carclubService.getUserLocations();

      setLocations(locations);
    } catch (e) {
      setError(e.description || e.message || "Could not get locations");
    } finally {
      setHasRequest(false);
    }
  }, []);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const onSave = useCallback(async () => {
    try {
      setHasRequest(true);
      dispatch(operatorSetDefaultLoc(placeholder));

      setHasRequest(false);
      onClose(true);
    } catch (e) {
      setError(e.description || e.message || "Could not save default location");
      setHasRequest(false);
    }
    // eslint-disable-next-line
  }, [placeholder, onClose]);

  useEffect(() => {
    if (error) {
      errorTimeout.current = setTimeout(() => setError(), 3000);
    }

    return () => clearTimeout(errorTimeout.current);
  }, [error]);

  return (
    <LocationModal
      {...props}
      error={error}
      hasRequest={hasRequest}
      locations={locations}
      onChange={setPlaceholder}
      onSave={onSave}
      placeholder={placeholder}
    />
  );
};

export default LocationModalWrapper;
