import React from "react";
import IncidentsDetails from "../../components/Incidents/IncidentsDetails/IncidentsDetailsWrapper";
import IncidentsFilter from "../../components/Incidents/IncidentsFilter/IncidentsFilterWrapper";
import IncidentsList from "../../components/Incidents/IncidentsList/IncidentsListWrapper";
import IncidentsUpdate from "../../components/Incidents/newIncidentsUpdate/IncidentsUpdateWrapper";
import { Drawer, withStyles } from "@material-ui/core";
import styles from "./IncidentsApp.styles";

const IncidentsApp = ({
  classes,
  closeDrawer,
  closeIncident,
  currentDrawer,
  currentScreenSize,
  filter,
  incidents,
  incidentsSetElements,
  incidentTypes,
  invoiceIncident,
  lastPage,
  onReset,
  onSearch,
  onSort,
  selectedIncident,
  setSelectedIncident,
  settleIncident,
  startCreate,
  startEdit,
  onCharge,
  error,
  setError,
}) => {
  const searchMenu = (
    <div className={classes.searchBar}>
      <IncidentsFilter
        filter={filter}
        incidentTypes={incidentTypes}
        onReset={onReset}
        onSearch={onSearch}
        startCreate={startCreate}
      />
    </div>
  );

  const list = (
    <IncidentsList
      currentScreenSize={currentScreenSize}
      incidentsArray={incidents}
      lastPage={lastPage}
      onEdit={startEdit}
      onSearch={onSearch}
      onSelect={setSelectedIncident}
      onSort={onSort}
      selected={selectedIncident}
    />
  );

  const details = !!selectedIncident && (
    <IncidentsDetails
      incident={selectedIncident}
      onCharge={onCharge}
      onClose={closeIncident}
      onSettle={settleIncident}
      onInvoice={invoiceIncident}
    />
  );

  const drawers = {
    create: (
      <IncidentsUpdate
        incidentsSetElements={incidentsSetElements}
        incidentTypes={incidentTypes}
        onCancel={closeDrawer}
        setSelectedIncident={setSelectedIncident}
      />
    ),

    update: (
      <IncidentsUpdate
        incident={selectedIncident}
        incidentsSetElements={incidentsSetElements}
        incidentTypes={incidentTypes}
        onCancel={closeDrawer}
        setSelectedIncident={setSelectedIncident}
      />
    ),
  };

  const drawer = (
    <Drawer anchor="right" onClose={closeDrawer} open={!!currentDrawer}>
      {drawers[currentDrawer]}
    </Drawer>
  );

  const errorDiv = error && (
    <div className={classes.errorBox}>
      <div className={classes.error} onClick={() => setError()}>
        {error}
      </div>
    </div>
  );

  const incidentsApp = (
    <div className={classes.root}>
      <div className={classes.workspace}>
        {drawer}
        <div className={classes.list}>
          {errorDiv}
          {searchMenu}
          {list}
        </div>
        <div className={classes.details}>{details}</div>
      </div>
    </div>
  );

  return incidentsApp;
};

export default withStyles(styles)(IncidentsApp);
