import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ListSubheader, List, ListItem, withStyles } from "@material-ui/core";

import AddAssetButton from "./AddAssetButton";
import styles from "./AssetList.styles";
import AssetItem from "../../../features/asset/AssetList/AssetItem/AssetItem";
import { selectUI, setSelectedAssetToken, selectSelectedAsset } from "../../../features/asset/assetSlice";
import AssetListAccordion from "../../../features/asset/AssetList/AssetListAccordion/AssetListAccordion";

const AssetList = ({ createButtonRef, onCreateAsset, classes, selectAsset }) => {
  const uiState = useSelector(selectUI);
  const dispatch = useDispatch();
  const selectedAsset = useSelector(selectSelectedAsset);

  const onCreateAssetHandler = () => {
    onCreateAsset && onCreateAsset();
    dispatch(setSelectedAssetToken());
    selectAsset();
  };

  return (
    <div className={classes.root}>
      <ListSubheader component="div">
        <div className={classes.header}>
          <div>Status</div>
          <div>License Plate</div>
          <div></div>
          <div></div>
          <div>State</div>
          <div></div>
        </div>
        <AddAssetButton classes={classes} onCreateAsset={onCreateAssetHandler} createButtonRef={createButtonRef} />
      </ListSubheader>
      {selectedAsset && uiState.showActivityLog ? (
        <List>
          <ListItem disableGutters className={classes.listItem}>
            <AssetItem
              asset={selectedAsset}
              onClick={() => {
                dispatch(setSelectedAssetToken());
                selectAsset();
              }}
            />
          </ListItem>
        </List>
      ) : (
        <AssetListAccordion
          onAssetClick={({ isSelected, assetToken }) => {
            if (isSelected) {
              dispatch(setSelectedAssetToken());
              selectAsset();
            } else {
              dispatch(setSelectedAssetToken(assetToken));
              selectAsset(assetToken);
            }
          }}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(AssetList);
