import { wsCall, requestHelper } from "../../../app/coreSlice";

const deviceListTeltonikaCall = async (
  { clearTeltonikaDevices, setTeltonikaDevices },
  dispatch,
  state,
  { clear, offset = 0, limit = 10000 }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "DEVICE/LIST_TELTONIKA");
  try {
    if (clear) {
      dispatch(clearTeltonikaDevices());
    }
    const result = await wsCall({
      type: "DEVICE",
      subType: "LIST_TELTONIKA",
      locationId: selectedLocation?.id,
      message: {
        offset,
        limit,
      },
    });

    if (result) {
      const { rows, list } = result;
      dispatch(setTeltonikaDevices({ list, totalItems: rows || 0 }));
    }
  } catch (_ex) {
    rh.error("Error loading devices.");
  }
  rh.close();
};

export default deviceListTeltonikaCall;
