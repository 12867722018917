export const styles = () => ({
  root: {
    width: 55,
    position: "relative",
    height: 25,
  },
  info: {
    position: "absolute",
    padding: 3,
  },
  icon: {
    height: 19,
    maxWidth: 18,
    fill: "#47525E"
  },
  progress: {
    position: "absolute",
    borderRadius: 5,
    height: 25,
    width: "100%",
    top: 0,
    left: 0,
    backgroundColor: "#d3d3d3",
  },
  full: {
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#00B67F",
    },
  },
  half: {
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#FFD103",
    },
  },
  empty: {
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#F17618",
    },
  },
});
