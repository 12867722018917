
export const styles = theme => ({

	root: {
		height: '100%',
		width: '25%',
		minWidth: '420px',
		background: '#FFFFFF',
		borderRadius: '2px',
		opacity: 1,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		overflow: 'hidden',
		border: '1px solid black',
		boxSizing: 'border-box',
	},

	contentContainer: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		padding: '16px',
		boxSizing: 'border-box',
	},

	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		width: '100%',
	},

	typeAndSubtypeContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
	},

	typeAndSubtypeLabel: {
		fontSize: 14,
		paddingRight: 6,
	},

	title: {
		fontSize: '16px',
		padding: '16px 0',
	},

	subtitle14px: {
		fontSize: '14px',
		padding: '16px 0',
	},

	labelProps: {
		color: '#47525E',
		zIndex: 999,
	},

	inputPropsMultiline: {
		fontSize: 12,
	},

	multiline: {
		padding: 0,
		margin: '0px 0px 8px 0px',
	},

	fieldMultiline: {
		width: '100%',
		height: 125,
		fontSize: '11px',
		display: 'flex',
		alignSelf: 'center',
	},

	messageLength: {
		width: '100%',
		textAlign: 'end',
	},

	messageTooBig: {
		color: '#FD3025',
	},

	textFieldRoot: {
		marginRight: 8,
	},

	textFieldSelect: {
		padding: 5,
	},

	//ERROR HANDLING

	rootBar: {
		position: 'absolute',
		top: 0,
		left: 'calc(50% - 190px)',
		textAlign: 'center',
		lineHeight: 'inherit',
		marginTop: 16,
		height: 18,
		minWidth: '380px',
		width: 'auto',
		fontSize: '14px',
		borderRadius: 10,
		color: 'white',
		boxSizing: 'border-box',
	},

	showBar: {
		opacity: 1,
		transition: 'all 1000ms linear',
	},

	hideBar: {
		opacity: 0,
		transition: 'all 2500ms linear',
	},

	success: {
		backgroundColor: 'rgb(0, 182, 127)',
	},

	error: {
		backgroundColor: 'rgb(253, 46, 37)',
	},

});

export default styles;