const styles = (theme) => ({

	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		flexWrap: 'nowrap',
	},

	workspace: {
		width: '100%',
		height: 'calc(100vh - 60px)',
		display: 'flex',
		flexDirection: 'row',
		alingItems: 'center',
		flexWrap: 'nowrap',
		position: 'relative',
	},

	list: {
		width: '100%',
		height: '100%',
		paddingLeft: 21,
		paddingRight: 21,
		display: 'flex',
		flexDirection: 'column',
	},

	details: {
		maxWidth: '284px',
		minWidth: '284px',
		paddingRight: 21,
		overflowY: 'auto',
	},
	
	modal: {
		backgroundColor: 'white',
		borderRadius: '10px',
		boxShadow: theme.shadows[5],
		boxSizing: 'border-box',
		height: '90%',
		left: '10%',
		outline: 0,
		padding: '10px 20px',
		position: 'absolute',
		top: '5%',
		width: '80%',
	},
});

export default styles;