import React from "react";
import { Drawer, withStyles } from "@material-ui/core";
import styles from "./PricingPlanListScreen.styles";
import PricingPlanList from "../PricingPlanList/PricingPlanList";
import PricingPlanEditor from "../PricingPlanEditor/PricingPlanEditor";
import ConfirmationModal from "../../UI/ConfirmationModal/ConfirmationModal";
import SearchListExecute from "../../SearchListExecute/SearchListExecuteWrapper";
import withPermisssion from "../../../hoc/withPermission";
import { ReactComponent as CreateIcon } from "../../../assets/PromotionApp/icons/add.svg";

const PricingPlanListScreen = ({
  classes,
  currentScreenSize,
  disableButtons,
  editMode,
  errorMessage,
  getZones,
  hasError,
  isRemoving,
  location,
  modal,
  onCustomersOrZonesEdit,
  onSave,
  placeholder,
  placeholderSetters,
  pricingPlanArray,
  pricingPlanToChange,
  removeHandler,
  removePricingPlan,
  selectedPricingPlanId,
  setErrorFalse,
  setModal,
  setPricingPlanToChange,
  setSelectedPricingPlan,
  setShowPPCalendar,
  updateHandler,
  zones,
}) => {
  const ppName =
    !!pricingPlanArray &&
    !!selectedPricingPlanId &&
    Array.isArray(pricingPlanArray) &&
    pricingPlanArray.find((e) => e.id === selectedPricingPlanId).name;

  const editCustomersOrZonesFromPP = (
    <Drawer anchor="right" onClose={() => setModal()} open={!!modal}>
      <div className={classes.drawer}>
        <SearchListExecute
          onCancel={() => setModal()}
          modal={modal}
          pricingPlanId={selectedPricingPlanId}
          pricingPlanName={ppName}
          getZones={getZones}
          zones={zones}
        />
      </div>
    </Drawer>
  );

  const onCancel = () => {
    placeholderSetters.resetPlaceholder();
    editMode.setOff();
    setErrorFalse();
    updateHandler(false);
  };

  const addButton = (
    <div className={classes.createButton}>
      <CreateButton
        onClick={() => {
          editMode.setOn();
        }}
        className={classes.createIcon}
      />
    </div>
  );

  const pricingPlanList = (
    <PricingPlanList
      currentScreenSize={currentScreenSize}
      pricingPlanArray={pricingPlanArray}
      placeholderSetters={placeholderSetters}
      onSelect={setSelectedPricingPlan}
      onEdit={editMode}
      onChange={setPricingPlanToChange}
      onRemove={(id) => {
        setPricingPlanToChange(id);
        removeHandler(true);
      }}
      onUpdate={updateHandler}
      onCustomersOrZonesEdit={onCustomersOrZonesEdit}
    />
  );

  const editScreen = (
    <Drawer anchor="right" onClose={() => !disableButtons && onCancel()} open={editMode.state}>
      <PricingPlanEditor
        disableButtons={disableButtons}
        errorMessage={errorMessage}
        hasError={hasError}
        location={location}
        onCancel={onCancel}
        onSave={onSave}
        placeholder={placeholder}
        placeholderSetters={placeholderSetters}
      />
    </Drawer>
  );

  const removePricingPlanConfirmModal = !!pricingPlanToChange && isRemoving && (
    <ConfirmationModal
      confirmHandler={() => {
        removePricingPlan(pricingPlanToChange);
        setPricingPlanToChange(undefined);
        removeHandler(false);
      }}
      confirmCustomLabel="Confirm"
      cancelHandler={() => setPricingPlanToChange(undefined)}
      cancelCustomLabel="Cancel"
      title="Remove pricing plan"
      text="Are you sure you want to remove this pricing plan?"
    />
  );

  const pricingPlanListScreen = (
    <div className={classes.root}>
      {editCustomersOrZonesFromPP}
      {removePricingPlanConfirmModal}
      {editScreen}
      {addButton}
      <div className={classes.list}>{pricingPlanList}</div>
    </div>
  );

  return pricingPlanListScreen;
};

export default withStyles(styles)(PricingPlanListScreen);

const CreateButton = withPermisssion([{ resource: ["pricingPlans", "create"] }])((props) => <CreateIcon {...props} />);
