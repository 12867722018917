import { wsCall, requestHelper } from "../../../app/coreSlice";

const assetListCall = async (
  { setAssets },
  dispatch,
  state,
  { deviceLicensePlate, fuelLevel, assetState, assetType }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  if (!selectedLocation) {
    return;
  }
  let rh = requestHelper(dispatch, "ASSET/LIST");
  let fuelHighLevel, fuelLowLevel, available, activated, inUse;
  switch (fuelLevel) {
    case "HIGH":
      fuelHighLevel = undefined;
      fuelLowLevel = 50;
      break;
    case "MEDIUM":
      fuelHighLevel = 49;
      fuelLowLevel = 25;
      break;
    case "LOW":
      fuelHighLevel = 24;
      fuelLowLevel = 10;
      break;
    case "CRITICAL":
      fuelHighLevel = 9;
      fuelLowLevel = undefined;
      break;
    default:
      fuelHighLevel = undefined;
      fuelLowLevel = undefined;
      break;
  }
  switch (assetState) {
    case "AVAILABLE":
      available = true;
      activated = true;
      inUse = false;
      break;
    case "UNAVAILABLE":
      available = false;
      activated = true;
      inUse = undefined;
      break;
    case "ON_TRIP":
      available = true;
      activated = true;
      inUse = true;
      break;
    case "DEACTIVATED":
      available = undefined;
      activated = false;
      inUse = undefined;
      break;
    default:
      available = undefined;
      activated = true;
      inUse = undefined;
      break;
  }

  try {
    const result = await wsCall({
      type: "ASSET",
      subType: "LIST",
      locationId: selectedLocation?.id,
      message: {
        deviceLicensePlate,
        fuelHighLevel,
        fuelLowLevel,
        activated,
        available,
        assetType,
        inUse,
      },
    });
    if (!result.error) {
      dispatch(
        setAssets({
          list: result,
          filter: {
            deviceLicensePlate,
            fuelLevel,
            assetType,
            assetState,
          },
        })
      );
    }
  } catch (_ex) {
    rh.error("Error getting assets.");
  }
  rh.close();
};

export default assetListCall;
