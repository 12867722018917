import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const deviceUpdateTeltonikaCall = async (
  { deviceListTeltonika },
  dispatch,
  state,
  { serialNumber, imei, model, protocol, debugMode, date, ip, carclubCode }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "DEVICE/UPDATE_TELTONIKA");
  try {
    const result = await wsCall({
      type: "DEVICE",
      subType: "UPDATE_TELTONIKA",
      locationId: selectedLocation?.id,
      message: {
        serialNumber,
        imei,
        model,
        protocol,
        debugMode,
        date,
        ip,
        carclubCode,
      },
    });
    if (result && !result.error) {
      dispatch(setSuccess("Teltonika Device Updated successfully"));
      dispatch(deviceListTeltonika({ clear: true }));
    } else {
      rh.error(result?.description || "Error updating device!");
    }
  } catch (_ex) {
    rh.error("Error updating device!");
  }
  rh.close();
};

export default deviceUpdateTeltonikaCall;
