import React from "react";
import { withStyles } from "@material-ui/core";
import { styles } from "./Buttons.styles";
import Button from "../../../../UI/Buttons/CommonButtons";
import { ASSET_CREATION_STEPS, CONFIRMATION_SCREENS } from "../utils";
import { screens } from "../UpsertAsset/UpsertAsset";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { setShowAssetEdit } from "../../../../../features/asset/assetSlice";

const Buttons = ({ classes, className, generalInfo, handlers, hasRequest, placeholder }) => {
  const dispatch = useDispatch();
  const {
    closeHandlerCreate,
    confirmHandler,
    editHandler,
    getAssetDetails,
    removeDevice,
    setConfirmationScreen,
    setCurrentStep,
    setCurrentTab,
  } = handlers || {};

  const { onEdit, onCreate, currentStep, confirmationScreen } = generalInfo || {};

  const { CREATE_ASSET, ADD_ZONES_OR_PARKS, CREATE_DEVICE } = ASSET_CREATION_STEPS;

  const { ACTIVATE_DEVICE_OR_CREATE_ONLY, CONFIRM_EXIT, REMOVE_DEVICE } = CONFIRMATION_SCREENS;

  // console.log('Buttons - currentStep: %o, confirmationScreen: %o', currentStep, confirmationScreen);
  const buttonGroupOnCreateStepCreateAsset = !onEdit &&
    onCreate &&
    currentStep === CREATE_ASSET &&
    !confirmationScreen && (
      <>
        <Button
          buttonStyleName="void"
          className={classes.button}
          disabled={hasRequest}
          onClick={closeHandlerCreate}
          label="Cancel"
        />

        <Button
          buttonStyleName="filled"
          className={classes.button}
          disabled={hasRequest}
          onClick={confirmHandler}
          label="Next"
        />
      </>
    );

  const buttonGroupOnCreateStepZonesAndParks = !onEdit &&
    onCreate &&
    currentStep === ADD_ZONES_OR_PARKS &&
    !confirmationScreen && (
      <>
        <Button
          buttonStyleName="void"
          className={classes.button}
          disabled={hasRequest}
          onClick={closeHandlerCreate}
          label="Close"
        />

        <Button
          buttonStyleName="filled"
          className={classes.button}
          disabled={hasRequest}
          onClick={confirmHandler}
          label="Next"
        />
      </>
    );

  const buttonGroupOnCreateStepCreateDevice = !onEdit &&
    onCreate &&
    currentStep === CREATE_DEVICE &&
    !confirmationScreen && (
      <>
        <Button
          buttonStyleName="void"
          className={classes.button}
          disabled={hasRequest}
          onClick={() => {
            setCurrentStep(ADD_ZONES_OR_PARKS);
            setCurrentTab(screens.ZONES_AND_PARKS);
          }}
          label="Back"
        />

        <Button
          buttonStyleName="filled"
          className={classes.button}
          disabled={hasRequest}
          onClick={() => {
            setConfirmationScreen(ACTIVATE_DEVICE_OR_CREATE_ONLY);
          }}
          label="Finish"
        />
      </>
    );

  const buttonGroupOnConfirmActivateOrCreateDeviceOnly = !!onCreate &&
    !onEdit &&
    confirmationScreen === ACTIVATE_DEVICE_OR_CREATE_ONLY && (
      <>
        <Button
          buttonStyleName="void"
          className={classes.button}
          disabled={hasRequest}
          onClick={closeHandlerCreate}
          label="Close"
        />

        <Button
          buttonStyleName="filled"
          className={classes.button}
          disabled={hasRequest}
          onClick={() => {
            confirmHandler();
          }}
          label="Confirm Activation"
        />
      </>
    );

  const buttonGroupOnRemoveDevice = (onCreate || onEdit) && confirmationScreen === REMOVE_DEVICE && (
    <>
      <Button
        buttonStyleName="void"
        className={classes.button}
        disabled={hasRequest}
        onClick={() => setConfirmationScreen()}
        label="Cancel"
      />

      <Button
        buttonStyleName="filled"
        className={classes.button}
        disabled={hasRequest}
        onClick={() => {
          removeDevice();
          setConfirmationScreen();
        }}
        label="Remove"
      />
    </>
  );

  const buttonGroupOnEdit = !!onEdit && !confirmationScreen && (
    <>
      <Button
        buttonStyleName="void"
        className={classes.button}
        disabled={hasRequest}
        onClick={() => setConfirmationScreen(CONFIRM_EXIT)}
        label="Cancel"
      />

      <Button
        buttonStyleName="filled"
        className={classes.button}
        disabled={hasRequest}
        onClick={confirmHandler}
        label="Confirm"
      />
    </>
  );

  const buttonGroupOnConfirmEditExit = !!onEdit && confirmationScreen === CONFIRM_EXIT && (
    <>
      <Button
        buttonStyleName="void"
        className={classes.button}
        disabled={hasRequest}
        onClick={() => setConfirmationScreen()}
        label="Cancel"
      />

      <Button
        buttonStyleName="filled"
        className={classes.button}
        disabled={hasRequest}
        onClick={() => {
          getAssetDetails();
          editHandler();
          setConfirmationScreen();
          dispatch(setShowAssetEdit(false));
        }}
        label="Confirm"
      />
    </>
  );

  const buttonGroupNotOnEdit = !onEdit && !onCreate && (
    <>
      <Button
        buttonStyleName="void"
        className={classes.button}
        disabled={hasRequest}
        onClick={closeHandlerCreate}
        label="Close"
      />
      <Button
        buttonStyleName="filled"
        className={classes.button}
        disabled={typeof placeholder?.operational === "undefined" || !!placeholder?.operational || hasRequest}
        onClick={() => !placeholder?.operational && editHandler()}
        label="Edit"
      />
    </>
  );
  const horizontalDivider = (
    <hr
      style={{
        width: "100%",
        borderTop: "1px solid #E0E0E0",
        borderBottom: "1px solid #E0E0E0",
      }}
    />
  );

  return (
    <div className={classnames(classes.root, className)}>
      {horizontalDivider}
      <div className={classes.buttons}>
        {buttonGroupOnEdit}
        {buttonGroupNotOnEdit}
        {buttonGroupOnCreateStepCreateAsset}
        {buttonGroupOnCreateStepZonesAndParks}
        {buttonGroupOnCreateStepCreateDevice}
        {buttonGroupOnConfirmActivateOrCreateDeviceOnly}
        {buttonGroupOnRemoveDevice}
        {buttonGroupOnConfirmEditExit}
      </div>
    </div>
  );
};

export default withStyles(styles)(Buttons);
