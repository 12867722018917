import React from "react";
import Button from "../../UI/Buttons/CommonButtons";
import { IconButton, ListSubheader, ListItem, Tooltip, Typography, withStyles } from "@material-ui/core";
import { ReactComponent as CreateIcon } from "../../../assets/PromotionApp/icons/add.svg";
import ParksItem from "./ParksItem/ParksItem";
import withPermissions from "../../../hoc/withPermission";
import styles from "./ParksList.styles";

const ParksList = ({
  associatedAssets,
  cancelDrawing,
  classes,
  confirmDrawing,
  createPark,
  deletePark,
  editPark,
  getAssociatedAssets,
  isCreating,
  isDrawing,
  isImporting,
  parks,
  redrawPark,
  selectedPark,
  selectPark,
  setParks,
  setSelectedParkCode,
  setXML,
  stopRedrawing,
}) => {
  const addButton = !isCreating.state && (
    <ButtonWithPermission>
      <Tooltip title="Add park">
        <IconButton aria-label="add park" color="primary" component="div" onClick={createPark}>
          <CreateIcon />
        </IconButton>
      </Tooltip>
    </ButtonWithPermission>
  );

  const importButton = !isCreating.state && (
    <ButtonWithPermission>
      <input hidden id="xml_import" onChange={(event) => setXML(event.target.files[0])} type="file" accept="text/xml" />
      <label htmlFor="xml_import">
        <Button label="Import" component="span" tooltip="XML or KML files" />
      </label>
    </ButtonWithPermission>
  );

  const saveImportButton = isCreating.state && isImporting && (
    <Button label="Save" onClick={confirmDrawing} buttonClassName={classes.saveImport} />
  );

  const cancelButton = isCreating.state && <Button buttonStyleName="void" label="Cancel" onClick={cancelDrawing} />;

  const header = (
    <ListSubheader component="div" disableGutters>
      <div className={classes.header}>
        <div className={classes.type}></div>
        <div>Name</div>
        <div className={classes.buttons}>
          {addButton}
          {saveImportButton}
          {importButton}
          {cancelButton}
        </div>
      </div>
    </ListSubheader>
  );

  if (!parks || parks.length === 0) {
    return (
      <div className={classes.root}>
        {header}
        <Typography align="center"> No matching results... </Typography>
      </div>
    );
  }

  const parkMapper = parkMapperSetup(
    classes,
    editPark,
    redrawPark,
    selectPark,
    selectedPark,
    setParks,
    isDrawing,
    stopRedrawing,
    deletePark,
    setSelectedParkCode,
    getAssociatedAssets,
    associatedAssets
  );

  return (
    <div className={classes.root}>
      {header}
      {<div className={classes.list}>{parks.sort(parkCompare).map(parkMapper)}</div>}
    </div>
  );
};

const parkMapperSetup =
  (
    classes,
    editPark,
    redrawPark,
    selectPark,
    selectedPark,
    setParks,
    isDrawing,
    stopRedrawing,
    deletePark,
    setSelectedParkCode,
    getAssociatedAssets,
    associatedAssets
  ) =>
  (park) =>
    (
      <ListItem className={classes.listItem} key={park.code}>
        <ParksItem
          associatedAssets={associatedAssets}
          deletePark={deletePark}
          editPark={() => editPark(park)}
          getAssociatedAssets={getAssociatedAssets}
          isDrawing={isDrawing}
          isSelected={park.code === selectedPark}
          name={park.name}
          park={park}
          redrawPark={() => redrawPark(park)}
          selectPark={selectPark}
          setParks={setParks}
          setSelectedParkCode={setSelectedParkCode}
          stopRedrawing={stopRedrawing}
          type={park.type}
        />
      </ListItem>
    );

const parkCompare = (a, b) => {
  if (a.name === b.name) {
    return a.name && b.name ? a.name.localeCompare(b.name) : 0;
  }

  return 0;
};

const permissions = [{ resource: ["fleet", "parks", "create"] }];
const ButtonWithPermission = withPermissions(permissions)(({ children }) => <>{children}</>);

export default withStyles(styles)(ParksList);
