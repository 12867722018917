import createModel from "../../../../model/createModel";

class CustomerDTO {
  constructor({
    aceptTermsDate,
    activationDate,
    address,
    addressCity,
    addressCountry,
    addressState,
    addressZipCode,
    agent,
    available,
    billingAddress,
    billingAddressCity,
    billingAddressCountry,
    billingAddressState,
    billingAddressZipCode,
    birthDate,
    carclubId,
    code,
    createDate,
    customerCode,
    email,
    emailValidation,
    fullName,
    id,
    imageUrl,
    mobileNumber,
    mobileValidation,
    name,
    nationality,
    nif,
    policyPrivate,
    socialName,
    surname,
    taxNumber,
    rfidToken,
    affiliateId,
    ...rest
  } = {}) {
    createModel.call(this, {
      acceptedTerms: aceptTermsDate,
      activationDate,
      address,
      addressCity,
      addressCountry,
      addressState,
      addressZipCode,
      isAgent: agent,
      available,
      billingAddress,
      billingAddressCity,
      billingAddressCountry,
      billingAddressState,
      billingAddressZipCode,
      birthDate,
      carclubId,
      code,
      createDate,
      customerCode,
      email,
      emailValidation,
      fullName,
      fullname: fullName,
      id,
      imageUrl,
      mobileNumber,
      mobileValidation,
      name,
      nationality,
      nif,
      policyPrivate,
      socialName,
      surname,
      taxNumber,
      rfidToken,
      affiliateId,
      ...rest,
    });
  }
}

export default CustomerDTO;
