import React from 'react';
import {
	Modal,
	Typography,
	withStyles,
} from '@material-ui/core';
import Button from '../../../UI/Buttons/CommonButtons';
import styles from './ResetPasswordModal.styles';

const ResetPasswordModal = ({
	classes,
	disableButtons,
	isOpen,
	onCancel,
	onConfirm,
	username,
}) => {

	const isConfirming = 'string' !== typeof isOpen;

	const message = isConfirming
		? `You are about to reset ${username} password. Confirm?`
		: isOpen;

	const text = (
		<Typography className={classes.text}>
			{message}
		</Typography>
	);

	const cancelButton = (
		<Button
			buttonStyleName='void'
			disabled={disableButtons}
			key='CancelButton'
			label='Cancel'
			onClick={() => onCancel()}
		/>
	);

	const confirmButton = (
		<Button
			buttonStyleName='filled'
			disabled={disableButtons}
			key={'confirmButton'}
			label={isConfirming ? 'Confirm' : 'Try Again'}
			onClick={onConfirm}
		/>
	);

	const buttons = (
		<div className={classes.buttons}>
			{confirmButton}
			{cancelButton}
		</div>
	);

	return (
		<Modal
			open={!!isOpen}
		>
			<div className={classes.root}>
				{text}
				{buttons}
			</div>
		</Modal>
	);
};

export default withStyles(styles)(ResetPasswordModal);