const styles = () => ({
	root: {
		height: '475px',
		width: '300px',
		outline: 'none',
	},

	errorDiv: {
		position: 'absolute',
		top: '10px',
		left: '10%',
		width: '80%',
		border: "2px solid red",
	},

	errorText: {
		fontSize: '9px',
	},

	content: {
		display: 'flex',
		width: '100%',
		justifyContent: 'flex-start',
		flexDirection: 'column',
	},

	buttons: {
		marginTop: '25px',
		textAlign: 'center',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
	},
});

export default styles;