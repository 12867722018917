import getCustomersFromPP from './getCustomersFromPP';
import getZonesFromPP from './getZonesFromPP';
import addOrRemoveCustomerToPP from './addOrRemoveCustomerToPP';
import addOrRemoveZoneToPP from './addOrRemoveZoneToPP';

const pricingPlanService = {
	getCustomersFromPP,
	addOrRemoveCustomerToPP,
	getZonesFromPP,
	addOrRemoveZoneToPP,
};

export default pricingPlanService;