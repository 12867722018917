import React from 'react';
import {
	Divider,
	InputAdornment,
	InputBase,
	Typography,
} from '@material-ui/core';
import {
	RemoveCircle as RemoveIcon,
} from '@material-ui/icons';
import classnames from 'classnames';
import CommonButton from '../../UI/Buttons/CommonButtons';

export const createConfirmationField = ({ classes, placeholder, onChange, hasRequest }) => ({ label, field, fieldName }) => (
	<div className={classes.confirmationField} key={fieldName}>
		<Typography align='center' className={classes.confirmationTitle}>
			{label}
		</Typography>
		<Typography align='center' className={classes.confirmationLabel}>
			{placeholder?.[fieldName]}
			{placeholder?.[fieldName] && onChange &&
				<RemoveIcon
					className={classes.removeIcon}
					onClick={() => !hasRequest && onChange({ [field]: undefined, [fieldName]: undefined })}
				/>
			}
		</Typography>
	</div>
);

export const divider = <Divider style={{ margin: '10px 0' }} />;

export const typeHeader = {
	Reservation: ['Code', 'Customer', 'License plate'],
	Customer: ['Code', 'Name', 'Mobile'],
	Vehicle: ['License plate', 'Name'],
};

export const currentFields = placeholder => ({
	Reservation: {
		key: placeholder.reservationCode,
		label: placeholder.reservationCode,
		customer: placeholder.customerCode,
		licensePlate: placeholder.licensePlate,
	},
	Customer: {
		key: placeholder.customerCode,
		label: placeholder.customerCode,
		name: placeholder.customerName,
		mobile: placeholder.mobile,
	},
	Vehicle: {
		key: placeholder.carToken,
		label: placeholder.licensePlate,
		name: placeholder.carName,
	},
});

export const typeOnAdd = onChange => ({
	Reservation: (reservationCode, label, customerCode, licensePlate, carToken) =>
		onChange({ reservationCode, licensePlate, customerCode, carToken }),
	Customer: (customerCode, label, customerName, mobile, reservationCode) =>
		onChange({ customerCode, customerName, mobile, reservationCode }),
	Vehicle: (carToken, licensePlate, carName, reservationCode) =>
		onChange({ carToken, licensePlate, carName, reservationCode }),
});

export const Button = ({ disabled, label, onClick, buttonStyleName }) => (
	<CommonButton
		buttonStyleName={buttonStyleName}
		disabled={disabled}
		key={label}
		label={label}
		onClick={onClick}
	/>
);

export const Input = classes =>
	({ title, onChange, error, value, disabled, startAdornment, endAdornment }) => (
		<div className={classes.inputDiv}>
			<Typography>
				{title}
			</Typography>
			{startAdornment}
			<InputBase
				disabled={!!disabled}
				classes={{
					root: classes.inputRootform,
					input: classnames(classes.inputInput, error && classes.inputError),
				}}
				endAdornment={
					<InputAdornment position="end">
						{endAdornment}
					</InputAdornment>
				}
				onChange={onChange}
				value={value || ''}
			/>
		</div>
	);

export const fitsPercentage = value => {
	if (!value) {
		return true;
	};

	const number = Number(value);
	const fits = 100 >= number && number >= 0;
	return (!!number || number === 0) && Number.isInteger(number) && fits;
};