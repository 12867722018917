const styles = () => ({
	root: {
		
	},

	list: {
		height: '100%',
		width: '100%',
	},
});

export default styles;