import React from 'react';
import {
	List as MUIList,
	ListItem,
	Typography,
	withStyles,
} from '@material-ui/core';
import classnames from 'classnames';
import styles from './List.styles';

const List = ({
	classes,
	conversation,
	groupId,
	messagesRef,
	ownCode,
}) => {
	const listItems = Array.isArray(conversation) && conversation.map((field, index) => (
		<ListItem
			className={classnames(classes.message,
				ownCode === field.sender && classes.ownMessage)}
			disableGutters
			key={`key_message_${index}_${field.sender}`}
		>
			{!!groupId && (field?.nickname || field?.sender) &&
				<Typography
					align='left'
					className={classnames(classes.nickname, ownCode === field.sender && classes.textColor)}
				>
					{field?.senderNickname || field?.sender}
				</Typography>
			}
			<Typography
				align='left'
				className={classnames(classes.text, ownCode === field.sender && classes.textColor)}
			>
				{field.message}
			</Typography>
		</ListItem>
	));

	const list = (
		<MUIList className={classes.list} disablePadding ref={messagesRef}>
			{listItems}
		</MUIList>
	);

	return (
		<div className={classes.root} >
			{list}
		</div>
	);
};

export default withStyles(styles)(List);