import makeActionCreator from '../../utils/makeActionCreator';
import createCustomReducer from "../createCustomReducer";

const actionTypes = {
	setField: 'SET_FIELD',
};

export const setFieldAction = makeActionCreator(actionTypes.setField, 'data');

const actions = dispatch => ({
	set: (data) => dispatch(setFieldAction(data)),
});

export const defaultState = {
	value: undefined,
	error: false,
	lastValid: undefined,
};

const useValidator = (initialState = undefined, validator) => {

	if (!validator || typeof validator !== 'function') {
		throw Error("Validator must be a function");
	}

	const initialStateToUse = typeof initialState !== 'undefined'
		? initialState  //TODO Initialize with set action
		: defaultState;

	// REDUCERS
	const setFieldReducer = (state, action) => {

		const hasError = validator(action.data);

		return hasError
			? {
				...state,
				value: action.data,
				error: hasError,
			}
			: {
				value: action.data,
				error: hasError,
				lastValid: action.data,
			};
	};

	const reducer = (state, action) => ({
		[actionTypes.setField]: setFieldReducer,
	}[action.type](state, action));

	return createCustomReducer({ reducer, actions, defaultState })(initialStateToUse);
};

export default useValidator;