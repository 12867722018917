import React, { useState, useReducer, useEffect, useRef } from 'react';
import PricingPlanBuilder from './PricingPlanBuilder';
import pricingPlanServiceV2 from '../../../services/pricingPlanV2';
import {
	calendarReducer,
	initialState,
	actionTypes,
} from './reducer';
import log from '../../../utils/logger';

const logger = log('PricingPlanBuilderWrapper');

const PricingPlanBuilderWrapper = (props) => {

	const {
		selectedRateToApply,
		selectedPP,
		placeholderSetters,
		upsertPPHandler,
		ratesList,
		setRatesList,
	} = props;

	//CALENDAR STATE
	const [state, dispatch] = useReducer(calendarReducer, initialState);
	const calendarState = state.toJS();

	//RATES && MAX FLAT RATES
	const [rates, setRates] = useState([]);
	const [maxRates, setMaxRates] = useState([]);

	//PAITNING CALENDAR
	const [draggedValue, setDraggedValue] = useState();
	const [draggedOverCells, setDraggedOverCells] = useState({});
	const [blockSelection, setBlockSelection] = useState(true);

	//SELECTED
	const pricingPlanId = !!selectedPP && selectedPP.id;

	//MOUNTED
	const isMounted = useRef(true);

	const asyncGetCalendar = async () => {

		try {
			let processMessage;
			let pricingPlan;
			let ratesResult;
			let maxRatesArray;
			let ratesArray;

			if (!!pricingPlanId) {
				processMessage = await pricingPlanServiceV2.getPricingPlan(pricingPlanId);
				pricingPlan = processMessage.pricing;
			};

			ratesResult = await pricingPlanServiceV2.listRates({});

			maxRatesArray = !!pricingPlan
                && pricingPlan.pricingDetails.maxPrices;
			ratesArray = !!pricingPlan
                && pricingPlan.pricingDetails
                && pricingPlan.pricingDetails.rates;

			if (isMounted.current) {
				setMaxRates(maxRatesArray);
			}


			const ratesMap = {};
			let parsedRatesArray = [];

			if (!ratesArray || ratesArray.length < 168) {
				ratesArray = placeholderRates;
			};

			if (!Array.isArray(ratesArray)) {
				return;
			};

			ratesResult.forEach(element => {
				ratesMap[element.id] = element.rateNumber;
			});

			ratesArray.forEach(e => {
				const value = e === null ? null : ratesMap[e];
				parsedRatesArray.push(value);
			});

			if (isMounted.current) {
				dispatch({
					type: actionTypes.SET_CALENDAR,
					calendar: parsedRatesArray,
				});
			}
		} catch (error) {
			logger.info(error.message || error);
		}
	};

	const asyncGetRates = async () => {

		try {
			const rates = await pricingPlanServiceV2.listRates({});

			if (isMounted.current) {
				setRatesList(rates);
			}
		} catch (error) {
			logger.info(error.message || error);

		}

	};

	useEffect(() => {

		const cleanup = () => {
			isMounted.current = false;
		};

		asyncGetRates();
		asyncGetCalendar();

		return cleanup;
		//eslint-disable-next-line
    }, []);


	const dragStartHandler = (e, value) => {
		if (!blockSelection) {
			return;
		};

		setDraggedValue(value);
	};

	const dragOverHandler = (e, hourIndex, field) => {

		e.preventDefault();

		const key = hourIndex + '_' + field.key;
		if (typeof draggedOverCells[key] !== 'undefined') {
			return;
		};

		dispatch({
			type: actionTypes.SET_CELL,
			index: hourIndex,
			dayName: field.key,
			value: draggedValue,
		});

		draggedOverCells[key] = {
			hourIndex,
			fieldName: field.key,
		};


	};

	const dragDropHandler = (e) => {
		if (!blockSelection) return;
		setDraggedOverCells({});
	};

	const calendarAddHandler = (hourIndex, day) => {
		const dayName = day.key;
		if (typeof selectedRateToApply === 'undefined'
            || (!selectedRateToApply.rateNumber && selectedRateToApply.rateNumber < 0)) {
			return;
		};
		dispatch({
			type: actionTypes.SET_CELL,
			index: hourIndex,
			dayName,
			value: selectedRateToApply.rateNumber,
		});
	};

	const calendarSubtractHandler = (hourIndex, day) => {
		const dayName = day.key;
		dispatch({
			type: actionTypes.SET_CELL,
			index: hourIndex,
			dayName,
			value: 1,
		});
	};

	const saveHandler = () => {

		try {

			const ratesMap = {};
			ratesList.forEach(element => {
				ratesMap[element.rateNumber] = element.id;
			});

			const updatedRates = [];
			const ratesArrayOfObjects = Object.values(calendarState);

			for (let indexWeek = 0; indexWeek < 7; indexWeek++) {
				for (let indexHour = 0; indexHour < 24; indexHour++) {
					const dayName = daysOfWeek[indexWeek];
					const hour = ratesArrayOfObjects[indexHour][dayName];
					const value = ratesMap[hour] || null;
					updatedRates.push(value);
				}
			}

			placeholderSetters.setRates(updatedRates);
			upsertPPHandler(updatedRates);
        
		} catch (error) {
			logger.info(error);
		}
	};

	return (
		<PricingPlanBuilder
			{...props}
			calendarState={calendarState}
			calendarAddHandler={calendarAddHandler}
			calendarSubtractHandler={calendarSubtractHandler}
			dragStartHandler={dragStartHandler}
			dragOverHandler={dragOverHandler}
			dragDropHandler={dragDropHandler}
			blockSelection={blockSelection}
			setBlockSelection={setBlockSelection}
			selectedName={selectedPP && selectedPP.name}
			saveHandler={saveHandler}
			rates={rates}
			setRates={setRates}
			asyncGetRates={asyncGetRates}
			maxRatesArray={maxRates}
		/>
	);
};

const placeholderRates = [
	null, null, null, null, null, null, null,
	null, null, null, null, null, null, null,
	null, null, null, null, null, null, null,
	null, null, null, null, null, null, null,
	null, null, null, null, null, null, null,
	null, null, null, null, null, null, null,
	null, null, null, null, null, null, null,
	null, null, null, null, null, null, null,
	null, null, null, null, null, null, null,
	null, null, null, null, null, null, null,
	null, null, null, null, null, null, null,
	null, null, null, null, null, null, null,
	null, null, null, null, null, null, null,
	null, null, null, null, null, null, null,
	null, null, null, null, null, null, null,
	null, null, null, null, null, null, null,
	null, null, null, null, null, null, null,
	null, null, null, null, null, null, null,
	null, null, null, null, null, null, null,
	null, null, null, null, null, null, null,
	null, null, null, null, null, null, null,
	null, null, null, null, null, null, null,
	null, null, null, null, null, null, null,
	null, null, null, null, null, null, null,
];

const daysOfWeek = {
	0: 'monday',
	1: 'tuesday',
	2: 'wednesday',
	3: 'thursday',
	4: 'friday',
	5: 'saturday',
	6: 'sunday',
};

export default PricingPlanBuilderWrapper;