import createModel from '../createModel';

class User {

	constructor({
		code,
		createBy,
		createDate,
		email,
		enabled,
		login,
		mobile,
		modifiedBy,
		modifiedDate,
		name,
		role,
	} = {}) {
		createModel.call(this, {
			code,
			createBy,
			createDate,
			email,
			enabled,
			login,
			mobile,
			modifiedBy,
			modifiedDate,
			name,
			role,
		});
	};
};

export default User;