import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import orderType from "../../model/Account/orderType";
import modals from "../../model/Account/modals";
import AccountApp from "./AccountApp";
import { selectDefaultLocation } from "../../features/carclub/carclubSlice";

const defaultFilter = {
  type: orderType.DISABLED,
  ascending: true,
  string: "",
  page: 1,
};

const AccountAppWrapper = (props) => {
  const { listAccounts, lastPage } = props;

  //DEFAULT LOCATION
  const selectedLocation = useSelector(selectDefaultLocation);

  const [selected, onSelect] = useState();
  const [filter, setFilter] = useState(defaultFilter);
  const [customerCode, setCustomerCode] = useState();

  // const refreshAccountOnDefaultLocation = useCallback(async () => {
  //   await listAccounts(defaultFilter);
  //   setFilter(defaultFilter);
  // }, [listAccounts]);

  useEffect(() => {
    setFilter(defaultFilter);
    listAccounts(filter);
    // eslint-disable-next-line
  }, [selectedLocation]);

  useEffect(() => {
    listAccounts(filter);
    // eslint-disable-next-line
  }, [filter]);

  const [modal, setModal] = useState();

  const onSort = (type) => {
    setFilter((prev) => ({
      ...prev,
      type,
      ascending: type === prev.type ? !prev.ascending : true,
      page: 1,
    }));
  };

  const getMoreRows = useCallback(() => {
    if (!lastPage) {
      setFilter((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  }, [lastPage, setFilter]);

  return (
    <AccountApp
      {...props}
      customerCode={customerCode}
      modal={modal}
      modals={modals}
      onSelect={onSelect}
      selected={selected}
      setCustomerCode={setCustomerCode}
      setFilter={setFilter}
      setModal={setModal}
      closeModal={() => setModal()}
      filter={{ ...filter, default: () => setFilter(defaultFilter) }}
      onSort={onSort}
      getMoreRows={getMoreRows}
    />
  );
};

export default AccountAppWrapper;
