import PricingPlan from '../../../../model/PricingPlanNew/PricingPlan';

const pricingPlanMapper = {

	fromDTO: ({
		carclubId,
		category,
		description,
		endDate,
		endZoneFees,
		id,
		name,
		pricingDetails,
		specific,
		startDate,
		startingFee,
		startZoneFees,
	}) => new PricingPlan({
		carclubId,
		category,
		description,
		endDate,
		endZoneFees,
		id,
		name,
		pricingDetails,
		specific,
		startDate,
		startingFee,
		startZoneFees,
	}),


	toDTO: ({
		carclubId,
		category,
		description,
		endDate,
		endZoneFees,
		id,
		maxPrices,
		name,
		rates,
		specific,
		startDate,
		startingFee,
		startZoneFees,
	}) => new PricingPlan({
		carclubId,
		category,
		description,
		endDate,
		endZoneFees,
		id,
		name,
		pricingDetails: { rates, maxPrices },
		specific,
		startDate,
		startingFee,
		startZoneFees,
	}),
};

export default pricingPlanMapper;

