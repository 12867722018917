const styles = () => ({

	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: 'auto',
		justifyContent: 'space-between',
		padding: '25px',
		boxSizing: 'border-box',
	},

	buttons: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
	},

	title: {
		fontSize: '22px',
	},

	calendarTitle: {
		fontSize: '14px',
		marginBottom: '10px',
	},

	error: {
		borderRadius: '21px',
		backgroundColor: 'red',
		color: 'white',
		margin: '10px',
	},

	datePickersContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
	},

	pickers: {
		margin: 'auto',
		maxWidth: '115px',
		maxHeight: '50px',
	},

	pickerLabel: {
		color: '#4C4C4E',
		// FIXME some of these should be given by the Mui.theme
		fontSize: '12px',
		fontWeight: '400',
		lineHeight: '34px',
		width: '32px',
		textAlign: 'left',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},

	input: {
		marginTop: '0 !important',
		padding: '3px !important',
		// FIXME some of these should be given by the Mui.theme
		color: '#89898B',
		fontSize: '12px',
		fontWeight: '400',
		lineHeight: '14px',
		textAlign: 'left',
	},

	inputIcon: {
		padding: '2px',
	},

	pickerInput: {
		padding: 8,
	},

	outlined: {
		transform: 'translate(14px, 10px) scale(1)',
	},

	feeAndEqual: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
	},

	zonesTitle: {
		fontSize: '16px',
		minWidth: 'max-content',
	},

	textField: {
		maxWidth: '50px',
		margin: '0 10px',
	},

	addButton: {
		color: '#009BCC',
		cursor: 'pointer',
		alignSelf: 'auto',
		width: '100%',
	},

	header: {
		width: '100%',
		display: 'grid',
		gridGap: '9px',
		gridTemplateColumns: 'max-content auto 21px',
		alignItems: 'center',
	},

	listHeader: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		marginTop: '10px',
	},

	headerLabel: {
		flex: 1,
	},

	buttonLabel: {
		maxWidth: '35px',
	},

	listItems: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		overflowY: 'auto',
		scrollbarWidth: 'thin', //FIREFOX
		//CHROME
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},

	item: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		wordBreak: 'break-word',
	},

	parameter: {
		flex: 1,
	},

	equalParam: {
		color: '#00CC9A',
		flex: '1',
		maxWidth: '35px',
	},

	equalHeader: {
		maxWidth: '35px',
	},
	
	fee: {
		width: '75px',
		maxWidth: '75px',
	},

	listItem: {
		width: '100%',
		paddingTop: '3px',
		paddingBottom: '3px',
		cursor: 'pointer',
	},

	removeButton: {
		color: '#888',
		width: '35px',
		maxWidth: '35px',
	},

	zonesRoot: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		minHeight: '160px',
		maxHeight: '160px',
		width: '100%',
		margin: '15px 0',
	},
});

export default styles;