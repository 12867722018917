import createMutator from '../../utils/createMutator';

export const setElements = createMutator('set', 'elements');

export const setSelectedAssetToken = createMutator('set', 'selectedAssetToken');

export const setRequestTime = createMutator('set', 'requestTime');

export const setShowList = createMutator('set', 'showList');

export const setCurrentLocation = createMutator('set', 'currentLocation');