import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DisplayAsset from "./DisplayAsset";
import UpsertAsset, { screens } from "./UpsertAsset/UpsertAsset";
import {
  ASSET_CREATION_STEPS,
  fieldsToCheck,
  fieldsToCheckLabel,
  mapAndFilterZonesAndParks,
  ZONE_TYPES_MAP,
} from "./utils";
import assetService from "../../../../services/asset";
import s3Service from "../../../../services/S3/index";
import { validate } from "../../../../services/validator/index";
import log from "../../../../utils/logger";
import { setNotification } from "../../../../store/actions/notification/action";
import {
  selectCarInfo,
  getCarInfo,
  getZones,
  getParks,
  selectParks,
  selectZones,
  selectUI,
} from "../../../../features/asset/assetSlice";
import { selectDefaultLocation } from "../../../../features/carclub/carclubSlice";
import assetDetailsCall from "../../../../features/asset/_sliceCalls/assetDetails";
import store from "../../../../store";
import { isSharing } from "../../../../common/utils/OTypes";

const logger = log("DisplayAssetWrapper");

const DisplayAssetWrapper = (props) => {
  const selectedLocation = useSelector(selectDefaultLocation);
  const zones = useSelector(selectZones);
  const parks = useSelector(selectParks);
  const uiState = useSelector(selectUI);

  const { selectedAsset, assetType, onCreate, exitToShow, refreshList } = props;

  const [placeholder, setPlaceholder] = useState(initialState(assetType));

  const [hasChangedZonesOrDevice, setHasChangedZonesOrDevice] = useState(false);

  const [currentStep, setCurrentStep] = useState();
  const [editMode, setEditMode] = useState(false);
  const [currentTab, setCurrentTab] = useState(screens.ASSET_INFO);
  const [confirmationScreen, setConfirmationScreen] = useState();

  const [hasNotification, setHasNotification] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [hasRequest, setHasRequest] = useState(false);

  const dispatch = useDispatch();
  const carInfo = useSelector(selectCarInfo);
  useEffect(() => {
    if (!carInfo) {
      dispatch(getCarInfo());
    }
    dispatch(getZones());
    dispatch(getParks());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (uiState.showAssetEdit) {
      setEditMode(true);
    }
  }, [uiState]);

  //ERROR HANDLING
  const handleNotification = useCallback((errorMsg) => {
    const notificationTimeout = 2500;
    setErrorMessage(errorMsg);
    setHasNotification(true);
    setTimeout(() => {
      setHasNotification(false);
    }, notificationTimeout);
    setTimeout(() => {
      setIsSuccess(false);
    }, notificationTimeout * 2);
  }, []);

  const handleSuccessfulNotification = useCallback(
    (message) => {
      setIsSuccess(true);
      handleNotification(message);
    },
    [handleNotification]
  );

  const resetErrorHandler = useCallback(() => {
    setErrorMessage();
    setHasNotification(false);
    setIsSuccess(false);
  }, []);

  // INFO GETTERS

  const getAssetDetails = useCallback(
    async (assetToken) => {
      try {
        if (!(selectedAsset?.assetToken || assetToken)) {
          return;
        }

        const { asset, assetZones, device, parks } = await assetDetailsCall({}, store.dispatch, store.state, {
          carToken: selectedAsset?.assetToken || assetToken,
        });

        const assetObject = {
          ...asset,
          class: asset?.carClass,
          device,
          deviceNumber: device?.serial_number,
          fuelType: asset?.fuel,
          provider: asset?.providerName,
          zones: mapAndFilterZonesAndParks({ zones: assetZones }),
        };

        if (!!parks) {
          assetObject.zones.park = parks;
        }
        setPlaceholder(assetObject);
      } catch (error) {
        logger.warn("Unable to get asset details", error);
      }
    },
    [selectedAsset]
  );

  //USE EFFECTS
  useEffect(() => {
    if (!!onCreate && typeof currentStep === "undefined") {
      setCurrentStep(CREATE_ASSET);
      setCurrentTab(screens.ASSET_INFO);
    }
  }, [onCreate, currentStep]);

  useEffect(() => {
    getAssetDetails();
    // eslint-disable-next-line
  }, []);

  //CHANGE HANDLERS
  const onChangeHandler = (key, value) => {
    if (key === "deviceNumber") {
      setHasChangedZonesOrDevice(true);
      setPlaceholder((prev) => ({
        ...prev,
        device: {
          ...prev?.device,
          serialNumber: value,
          serial_number: value,
        },
      }));
    }
    if (key === "externalId") {
      setHasChangedZonesOrDevice(true);
      setPlaceholder((prev) => ({
        ...prev,
        device: {
          ...prev?.device,
          externalId: value,
        },
      }));
    }

    if ("make" === key) {
      setPlaceholder((prev) => ({ ...prev, [key]: value, model: undefined }));
    }

    setPlaceholder((prev) => ({ ...prev, [key]: value }));
  };

  const editHandler = useCallback(() => {
    setEditMode((prev) => {
      if (prev === true) {
        setHasChangedZonesOrDevice(false);
      }
      return !prev;
    });
  }, []);

  const closeHandler = useCallback(() => {
    console.log("closeHandler");
    setEditMode(false);
  }, []);

  const onChangeHandlerZones = (zone, type) => {
    if (!hasChangedZonesOrDevice) {
      setHasChangedZonesOrDevice(true);
    }
    const currentArray = placeholder?.zones[type];
    const newArray = [...currentArray];
    const hasIndex = newArray.findIndex((el) => el?.id === zone?.id);
    hasIndex < 0 ? newArray.push(zone) : newArray.splice(hasIndex, 1);

    if (type === ZONE_TYPES_MAP.NORMAL) {
      setPlaceholder((prev) => ({
        ...prev,
        parkId: undefined,
        zones: {
          wanted: newArray,
          unwanted: prev?.zones?.unwanted,
          park: [],
        },
      }));
    }

    if (type === ZONE_TYPES_MAP.UNWANTED) {
      setPlaceholder((prev) => ({
        ...prev,
        parkId: undefined,
        zones: {
          wanted: prev?.zones?.wanted,
          unwanted: newArray,
          park: [],
        },
      }));
    }

    if (type === ZONE_TYPES_MAP.PARKING) {
      setPlaceholder((prev) => ({
        ...prev,
        parkId: zone?.id === prev?.parkId ? undefined : zone?.id,
        zones: {
          wanted: [],
          unwanted: [],
          park: newArray, //zone?.id === prev?.parkId ? [] : [zone],
        },
      }));
    }
  };

  const imageHandler = useCallback(
    async (event) => {
      try {
        const imgFile = event.target.files[0];
        const uploadedImage = await uploadImage(imgFile);

        if (uploadedImage?.error) {
          logger.want("Unable to upload image.");
          handleNotification("Unable to upload image.");
          return;
        }

        const { keyname } = uploadedImage;

        const imageRequest = await saveImage(keyname);

        onChangeHandler("imageUrl", imageRequest);
      } catch (error) {
        handleNotification("Unable to upload image.");
        logger.warn("Unable to upload image.");
      }
    },
    [handleNotification]
  );

  //AUX FUNCS
  const getZonesAndParksIdArrays = useCallback(() => {
    console.log("........... getZonesAndParksIdArrays - placeholder?.zones: %o", placeholder?.zones);
    const wanted = (placeholder?.zones?.wanted || []).map((el) => el?.id);
    const unwanted = (placeholder?.zones?.unwanted || []).map((el) => el?.id);
    const park = (placeholder?.zones?.park || []).map((el) => el?.id);

    return {
      wanted,
      unwanted,
      park,
    };
  }, [placeholder]);

  const getProvider = useCallback(() => {
    return (carInfo?.providers || []).find((provider) => provider?.name === placeholder?.provider);
  }, [carInfo, placeholder]);

  const noZonesAndNoParks = useCallback(({ wanted, unwanted, park }) => {
    if (wanted?.length <= 0 && unwanted?.length <= 0 && park?.length <= 0) {
      return true;
    }
    return false;
  }, []);

  //SERVICE REQUESTS
  const createAsset = useCallback(async () => {
    try {
      setHasRequest(true);

      const result = await assetService.createAsset({
        ...placeholder,
        assetType: carInfo?.types.find((type) => type?.name === assetType)?.id,
      });

      setPlaceholder((prev) => ({
        ...prev,
        ...result,
      }));
      return { hasError: false };
    } catch (error) {
      handleNotification("Failed to create asset, " + error?.message || error?.description);
      logger.warn("Failed to create new asset.");
      return { hasError: true };
    } finally {
      setHasRequest(false);
    }
  }, [placeholder, assetType, carInfo, handleNotification]);

  const removeDevice = useCallback(async () => {
    try {
      setHasRequest(true);

      const message = {
        carToken: placeholder?.carToken,
        deviceToken: placeholder?.deviceToken,
        licensePlate: placeholder?.licensePlate,
      };

      //Remove Device and Deactivate Asset
      const result = await assetService.activateOrDeactivateAsset(message);

      console.log("result: %o", result);

      setPlaceholder((prev) => ({
        ...prev,
        ...result,
        device: {},
        deviceNumber: undefined,
        deviceToken: undefined,
        provider: undefined,
        providerName: undefined,
        providerToken: undefined,
        zones: {
          wanted: [],
          unwanted: [],
          park: [],
        },
      }));
      editHandler();
      refreshList();
      setHasChangedZonesOrDevice(false);
    } catch (error) {
      console.log("ERROR: %o", error);
      handleNotification(`Could not deactivate asset. ` + error?.description || error?.message);
    } finally {
      setHasRequest(false);
    }
  }, [placeholder, handleNotification, refreshList, editHandler]);

  const createDevice = useCallback(async () => {
    console.log("createDevice 1");
    const provider = getProvider();
    const { wanted, unwanted, park } = getZonesAndParksIdArrays();

    setHasRequest(true);
    try {
      const result = await assetService.createAndActivateDevice({
        ...placeholder,
        parkId: (Array.isArray(park) && park[0]) || undefined,
        provider_token: provider?.provider_token,
        serial_number: placeholder?.deviceNumber,
        unwanted,
        wanted,
        park,
      });

      const { asset, device, assetZones } = result || {};

      const assetObject = {
        ...asset,
        zones: mapAndFilterZonesAndParks({ zones: assetZones, parks: park && [park] }),
        providerToken: device?.provider_token,
        deviceNumber: device?.serial_number,
        provider: asset?.providerName,
        device: {
          ...device,
          providerToken: device?.provider_token,
          deviceToken: device?.device_token,
          serialNumber: device?.serial_number,
        },
        class: asset?.carClass,
        fuelType: asset?.fuel,
      };
      setPlaceholder({ ...assetObject });

      return { hasError: false, assetObject, message: "" };
    } catch (e) {
      setNotification("Could not create device " + e.description || e.message);
      return { hasError: true, message: e.description || e.message };
    } finally {
      setHasRequest(true);
    }
  }, [getProvider, getZonesAndParksIdArrays, placeholder]);

  const isFormValid = useCallback(
    (fields) => {
      for (const field of fields || fieldsToCheck[currentStep]) {
        if (typeof placeholder[field] === "undefined" || (placeholder[field] + "").trim().length === 0) {
          handleNotification(
            "imageUrl" === field
              ? "Please select an image by clicking the placeholder"
              : `The field ${fieldsToCheckLabel[field]} is required`
          );
          return false;
        }
      }

      return true;
    },
    [placeholder, currentStep, handleNotification]
  );

  const updateAsset = useCallback(async () => {
    if (!isFormValid(hasChangedZonesOrDevice ? fieldsToCheck?.update : fieldsToCheck?.CREATE_ASSET)) return;

    try {
      setHasRequest(true);
      let result;
      result = await assetService.updateAssetInfoOnly({ ...placeholder });

      setHasChangedZonesOrDevice(false);
      setPlaceholder((prev) => ({
        ...prev,
        ...result,
      }));
      refreshList();
      handleSuccessfulNotification("Asset updated successfully");
      setEditMode(false);
    } catch (error) {
      handleNotification("Could not update asset. " + error?.message || error?.description);
      logger.warn("Error while updating asset." + error?.message || error?.description);
    } finally {
      getAssetDetails(placeholder?.assetToken);
      setHasRequest(false);
    }
  }, [
    getAssetDetails,
    handleNotification,
    handleSuccessfulNotification,
    hasChangedZonesOrDevice,
    isFormValid,
    placeholder,
    refreshList,
  ]);

  const assetVin = placeholder?.vinNumber;

  //MAIN HANDLER
  const confirmHandler = useCallback(async () => {
    console.log("confirmHandler - currentStep: %s", currentStep);
    try {
      resetErrorHandler();
      console.log("editMode: %o", editMode);
      if (editMode) {
        updateAsset();
        return;
      }

      if (!isFormValid()) {
        return;
      }

      if (currentStep === CREATE_ASSET) {
        if (!validate.VIN(assetVin)) {
          handleNotification("The VIN must be a combination of exactly 17 letters and/or numbers.");
          return;
        }
        const { hasError } = await createAsset().catch((e) => {
          handleNotification("Unable to create asset: " + e?.message || e?.description || e);
          return;
        });
        if (!!hasError) {
          return;
        }

        handleSuccessfulNotification("Asset created successfully");
        // setCurrentStep(ADD_ZONES_OR_PARKS);
        // setCurrentTab(screens.ZONES_AND_PARKS);
        setCurrentStep(CREATE_DEVICE);
        setCurrentTab(screens.OBS_DEVICE);
        return;
      }

      if (currentStep === ADD_ZONES_OR_PARKS) {
        if (isSharing(selectedLocation.operationType) && noZonesAndNoParks(placeholder.zones)) {
          handleNotification("You must select at least one park or zone.");
          return { hasError: true };
        }
        // setCurrentStep(CREATE_DEVICE);
        // setCurrentTab(screens.OBS_DEVICE);
        setCurrentStep(ADD_ZONES_OR_PARKS);
        setCurrentTab(screens.ZONES_AND_PARKS);
        return;
      }

      if (currentStep === CREATE_DEVICE) {
        await createDevice().catch((e) => {
          handleNotification("Unable to create and activate device: " + e?.message || e?.description);
          return;
        });
        setConfirmationScreen();
        handleSuccessfulNotification("Device created successfully");
        refreshList();
        exitToShow(placeholder);
        return;
      }
    } catch (error) {
      setConfirmationScreen();
      handleNotification("Could not update asset " + error?.message || error?.description);
      logger.warn("Error while updating asset.", +error?.message || error?.description || error);
    }
  }, [
    assetVin,
    createAsset,
    createDevice,
    currentStep,
    editMode,
    exitToShow,
    handleNotification,
    handleSuccessfulNotification,
    isFormValid,
    noZonesAndNoParks,
    placeholder,
    refreshList,
    resetErrorHandler,
    updateAsset,
    selectedLocation.operationType,
  ]);

  const onDeviceAssociateHandler = (asset) => {
    console.log("onDeviceAssociateHandler - asset: %o", asset);
    getAssetDetails(asset?.carToken || asset?.asset?.carToken);
    refreshList();
    setCurrentTab(screens.ZONES_AND_PARKS);
  };

  const onZonesAddedHandler = (carToken) => {
    console.log("onZonesAddedHandler - carToken: %o", carToken);
    getAssetDetails(carToken);
    refreshList();
  };

  const activate = useCallback(async () => {
    setHasRequest(true);

    try {
      await assetService.activateOrDeactivateAsset({
        carToken: placeholder?.carToken,
        deviceToken: placeholder?.deviceToken,
        licensePlate: placeholder?.licensePlate,
        activate: true,
      });

      setPlaceholder((prev) => ({ ...prev, active: true }));
      handleSuccessfulNotification("Device activated successfully");
    } catch (e) {
      handleNotification(`Could not activate asset: ${e.message || e.description || e.tag}`);
    } finally {
      setHasRequest(false);
    }
  }, [placeholder, handleNotification, handleSuccessfulNotification]);

  const handlers = {
    activate,
    closeHandler,
    closeHandlerCreate: props.closeHandler,
    confirmHandler,
    editHandler,
    getAssetDetails,
    imageHandler,
    onChangeHandler,
    onChangeHandlerZones,
    removeDevice,
    setConfirmationScreen,
    setCurrentStep,
    setCurrentTab,
  };

  const errorHandling = {
    errorMessage,
    isSuccess,
    hasNotification,
  };

  const generalInfo = {
    confirmationScreen,
    currentStep,
    currentTab,
    onCreate,
    onEdit: editMode === true,
    generalAssetInformation: carInfo,
    zonesAndParks: mapAndFilterZonesAndParks({ zones, parks }),
  };

  return editMode || onCreate ? (
    <UpsertAsset
      errorHandling={errorHandling}
      generalInfo={generalInfo}
      handlers={handlers}
      hasRequest={hasRequest}
      placeholder={placeholder}
      assetType={assetType}
      onDeviceAssociate={onDeviceAssociateHandler}
      onZonesAdded={onZonesAddedHandler}
    />
  ) : (
    <DisplayAsset
      handlers={handlers}
      errorHandling={errorHandling}
      generalInfo={generalInfo}
      placeholder={placeholder}
      {...props}
      hasRequest={hasRequest}
    />
  );
};

const { uploadImage, saveImage } = s3Service;

const initialState = (assetTypeName) => ({
  assetTypeName,
  zones: {
    wanted: [],
    unwanted: [],
    park: [],
  },
});

const { CREATE_ASSET, ADD_ZONES_OR_PARKS, CREATE_DEVICE } = ASSET_CREATION_STEPS;

export default DisplayAssetWrapper;
