export const styles = theme => ({
  root: {
    position: "fixed",
    bottom: 0,
    right: 10,
    zIndex: 1500,
  },
  alert: {
    marginBottom: 10
  }
});