import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import parkService from "../../../services/park";
import useSwitch from "../../../hooks/useSwitch";
import ParksWorkspace from "./ParksWorkspace";
import { selectDefaultLocation } from "../../../features/carclub/carclubSlice";

const ParksWorkspaceWrapper = ({ polygons, setShowList, showList, upsertPark, ...props }) => {
  const { getParks, parkPainter, parks, hideParks } = props;

  const defaultLocationFromUser = useSelector(selectDefaultLocation);

  const [selectedParkCode, setSelectedParkCode] = useState();
  const [selectedPark, setSelectedPark] = useState();
  const [isImporting, setIsImporting] = useState();
  const [associatedAssets, setAssets] = useState([]);

  const getAssociatedAssets = useCallback(async (parkId) => {
    try {
      const associatedAssets = await parkService.getAssociatedAssets(parkId);

      setAssets(associatedAssets);
    } catch (e) {
      setAssets([]);
      //TODO
    }
  }, []);

  const hasModal = useSwitch(false);
  const isCreating = useSwitch(false);

  useEffect(() => {
    const cleanup = () => hideParks();
    return cleanup;
    //eslint-disable-next-line
  }, []);

  //onMount
  useEffect(() => {
    !showList && getParks();
    // eslint-disable-next-line
  }, [getParks]);

  useEffect(() => {
    if (!showList) {
      return;
    }

    getParks();
  }, [getParks, showList]);

  useEffect(() => {
    setSelectedPark(parks.find((park) => park.code === selectedParkCode));
  }, [selectedParkCode, setSelectedPark, parks]);

  useEffect(() => {
    !isImporting && selectedPark && parkPainter(selectedPark);
    // FIXME Make so we do not need to disable it
    // eslint-disable-next-line
  }, [selectedPark]);

  useEffect(() => {
    if (isCreating.state && polygons["NEW"] && !isImporting) {
      hasModal.setOn();
    }
  }, [polygons, hasModal, isCreating.state, isImporting]);

  return (
    <ParksWorkspace
      hasModal={hasModal}
      isCreating={isCreating}
      isImporting={isImporting}
      polygon={polygons[selectedParkCode || "NEW"]}
      selectedPark={selectedPark}
      selectedParkCode={selectedParkCode}
      setAssociatedAssets={setAssets}
      setIsImporting={setIsImporting}
      setSelectedPark={setSelectedPark}
      setSelectedParkCode={setSelectedParkCode}
      showParks={{
        state: showList,
        setOn: () => setShowList(true),
        setOff: () => setShowList(false),
        toggle: () => setShowList(!showList),
      }}
      upsertPark={upsertPark}
      defaultLocationFromUser={defaultLocationFromUser}
      {...props}
      getAssociatedAssets={getAssociatedAssets}
      associatedAssets={associatedAssets}
    />
  );
};

export default ParksWorkspaceWrapper;
