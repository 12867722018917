import React, { useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Paper, ClickAwayListener, List, ListItem } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { styles } from "./ActionsDropDown.styles";

function ActionsDropDown({ classes, className, children, listClassName, itemClickClose }) {
  const [open, setOpen] = useState(false);

  const iconClickHandler = (e) => {
    e.stopPropagation();
    setOpen((prev) => !prev);
  };

  const itemClickHandler = (e) => {
    e.stopPropagation();
    if (itemClickClose) {
      setOpen(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={classes.root + (className ? " " + className : "")}>
        <MoreVertIcon className={classes.icon} onClick={iconClickHandler} />
        {open ? (
          <Paper className={classes.list + (listClassName ? " " + listClassName : "")}>
            <List>
              {(children || []).map((item, index) => (
                <ListItem key={"listItem_" + index} onClick={itemClickHandler}>
                  {item}
                </ListItem>
              ))}
            </List>
          </Paper>
        ) : null}
      </div>
    </ClickAwayListener>
  );
}

export default withStyles(styles)(ActionsDropDown);
