import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const princingPlanCreateCall = async ({ appendPrincingPlan }, dispatch, state, data) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "PRICING_PLAN/CREATE");

  try {
    const result = await wsCall({
      type: "PRICING_PLAN",
      subType: "CREATE",
      locationId: selectedLocation?.id,
      message: {
        ...data, // set fields to give visibility
      },
    });
    if (result && !result.error) {
      dispatch(setSuccess("Pricing plan created successfully"));
      dispatch(appendPrincingPlan(result));
    }
  } catch (_ex) {
    console.error(_ex);
    rh.error("Error creating princing plan");
  }
  rh.close();
};

export default princingPlanCreateCall;
