const styles = () => ({
  
	root: {
        
	},

	icon: {
		width: 10,
		height: 10,
	},

	selected: {
		width: 30,
		height: 30,
		borderRadius: '50%',
		// transform: 'translate(0, -50%)',
		boxShadow: 'inset 0 0 0 3px rgba(0, 155, 204, 0.3), inset 0 0 0 20px rgba(0, 174, 230, 0.3)',
		zIndex: 1000,
	},

	translate: {
		transform: 'translate(0, 100%)',
	},

});

export default styles;