import React from 'react';
import Map from './Map';

const MapWrapper = ({
	placeholderMap,
	mapPlaceholderOnChangeHandler,
	...props
}) => {

	return (
		<Map
			{...props}
			placeholderMap={placeholderMap}
			mapPlaceholderOnChangeHandler={mapPlaceholderOnChangeHandler}
		/>
	);
};

export default MapWrapper;