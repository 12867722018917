export const styles = () => ({
  root: {
    width: 32,
    height: 32,
    borderRadius: 16,
    background: "#fd2e25",
    "& svg": {
      margin: 2,
      height: 28,
      width: 28,
      fill: "#FFF",
    },
  },
  warning: {
    background: "#daa520",
  },
  resolved: {
    background: "#4C4C4E",
  },
  ongoing: {
    background: "#0092E9",
  },
});
