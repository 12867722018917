
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_PROCESS_MESSAGE_IS_MALFORMED,
	WS_REQUEST_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import { fromDTO as mapper } from './mappers/membersMapper.js';
import { publishAndAwait } from '../../../app/coreSlice';

const removeAccountMember = async (accountCode, customerCode) => {

	if (typeof accountCode === 'undefined' || typeof customerCode === 'undefined') {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	};

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'ACCOUNTS',
			subType: 'DELETE_CUSTOMER_ASSOCIATION',
			message: {
				accountCode,
				customerCode,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage || !processMessage.customerList) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	};

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	};

	const members = processMessage.customerList
		.reduce((acc, member) => ({...acc, [member.customerCode]: mapper(member)}), {});

	return members;
};

export default removeAccountMember;