import React from "react";
import { useSelector, useDispatch } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Alert } from "@material-ui/lab";

import { styles } from "./Snacks.styles";
import { selectSnacks, removeSnack } from "../../coreSlice";

function Snacks({ classes }) {
  const dispatch = useDispatch();
  const snacks = useSelector(selectSnacks);
  return (
    <div className={classes.root}>
      {snacks.map((item, i) => (
        <Alert
          className={classes.alert}
          variant="filled"
          key={"snack_" + i}
          severity={item.type}
          onClick={() => dispatch(removeSnack(item.id))}
        >
          {item.msg}
        </Alert>
      ))}
    </div>
  );
}

export default withStyles(styles)(Snacks);
