import * as Yup from "yup";
import { TextField, CheckboxField, SelectField, NumberField } from "../../../../common/forms";
import { getBillingEnginesOptions, getBillingGatewaysOptions, getInvoiceGatewaysOptions } from "./Payments.options";

export function formSchema() {
  return Yup.object().shape({
    CONFIGURATION: Yup.object().shape({
      simulator: Yup.string(),
    }),
  });
}

export function formFields({ disabled, values, billingEngines, isDefaultConfigs }) {
  return [
    {
      size: 12,
      field: (
        <SelectField
          id="CONFIGURATION.simulator"
          label="Pricing Engine"
          options={getBillingEnginesOptions(billingEngines)}
          disabled={disabled}
        />
      ),
    },
    {
      size: 12,
      field: (
        <SelectField
          id="BILLING.gateway"
          label="Payment GW"
          options={getBillingGatewaysOptions(values)}
          disabled={disabled}
        />
      ),
      hidden: !isDefaultConfigs,
    },
    // STRIPE
    {
      size: 1,
      hidden: values?.BILLING?.gateway !== "STRIPE" || !isDefaultConfigs,
    },
    {
      size: 11,
      field: <TextField id="STRIPE.secretKey" type="text" label="Secret Key" disabled={disabled} />,
      hidden: values?.BILLING?.gateway !== "STRIPE" || !isDefaultConfigs,
    },
    {
      size: 1,
      hidden: values?.BILLING?.gateway !== "STRIPE" || !isDefaultConfigs,
    },
    {
      size: 11,
      field: <TextField id="STRIPE.publicKey" type="text" label="Public Key" disabled={disabled} />,
      hidden: values?.BILLING?.gateway !== "STRIPE" || !isDefaultConfigs,
    },
    // BRAINTREE
    {
      size: 1,
      hidden: values?.BILLING?.gateway !== "BRAINTREE" || !isDefaultConfigs,
    },
    {
      size: 11,
      field: <TextField id="BRAINTREE.btEnvironment" type="text" label="Bt Environment" disabled={disabled} />,
      hidden: values?.BILLING?.gateway !== "BRAINTREE" || !isDefaultConfigs,
    },
    {
      size: 1,
      hidden: values?.BILLING?.gateway !== "BRAINTREE" || !isDefaultConfigs,
    },
    {
      size: 11,
      field: <TextField id="BRAINTREE.merchantAccount" type="text" label="Merchant Account" disabled={disabled} />,
      hidden: values?.BILLING?.gateway !== "BRAINTREE" || !isDefaultConfigs,
    },
    {
      size: 1,
      hidden: values?.BILLING?.gateway !== "BRAINTREE" || !isDefaultConfigs,
    },
    {
      size: 11,
      field: <TextField id="BRAINTREE.merchantId" type="text" label="Merchant id" disabled={disabled} />,
      hidden: values?.BILLING?.gateway !== "BRAINTREE" || !isDefaultConfigs,
    },
    {
      size: 1,
      hidden: values?.BILLING?.gateway !== "BRAINTREE" || !isDefaultConfigs,
    },
    {
      size: 11,
      field: <TextField id="BRAINTREE.privateKey" type="text" label="Private Key" disabled={disabled} />,
      hidden: values?.BILLING?.gateway !== "BRAINTREE" || !isDefaultConfigs,
    },
    {
      size: 1,
      hidden: values?.BILLING?.gateway !== "BRAINTREE" || !isDefaultConfigs,
    },
    {
      size: 11,
      field: <TextField id="BRAINTREE.publicKey" type="text" label="Public Key" disabled={disabled} />,
      hidden: values?.BILLING?.gateway !== "BRAINTREE" || !isDefaultConfigs,
    },

    {
      size: 12,
      field: (
        <SelectField
          id="BILLING.invoiceGateway"
          label="Invoice GW"
          options={getInvoiceGatewaysOptions()}
          disabled={disabled}
        />
      ),
    },
    {
      size: 1,
      hidden: values?.BILLING?.invoiceGateway !== "INVOICE_EXPRESS",
    },
    {
      size: 11,
      field: <TextField id="INVOICE_EXPRESS.country" type="text" label="Country" disabled={disabled} />,
      hidden: values?.BILLING?.invoiceGateway !== "INVOICE_EXPRESS",
    },
    {
      size: 1,
      hidden: values?.BILLING?.invoiceGateway !== "INVOICE_EXPRESS",
    },
    {
      size: 11,
      field: <TextField id="INVOICE_EXPRESS.apiKey" type="text" label="API Key" disabled={disabled} />,
      hidden: values?.BILLING?.invoiceGateway !== "INVOICE_EXPRESS",
    },
    {
      size: 1,
      hidden: values?.BILLING?.invoiceGateway !== "INVOICE_EXPRESS",
    },
    {
      size: 11,
      field: <TextField id="INVOICE_EXPRESS.apiCode" type="text" label="API Code" disabled={disabled} />,
      hidden: values?.BILLING?.invoiceGateway !== "INVOICE_EXPRESS",
    },
    {
      size: 1,
      hidden: values?.BILLING?.invoiceGateway !== "INVOICE_EXPRESS",
    },
    {
      size: 11,
      field: <TextField id="INVOICE_EXPRESS.taxCode" type="text" label="Tax Code" disabled={disabled} />,
      hidden: values?.BILLING?.invoiceGateway !== "INVOICE_EXPRESS",
    },
    {
      size: 1,
      hidden: values?.BILLING?.invoiceGateway !== "INVOICE_EXPRESS",
    },
    {
      size: 11,
      field: <NumberField id="INVOICE_EXPRESS.value" type="text" label="Value" decimalScale={0} disabled={disabled} />,
      hidden: values?.BILLING?.invoiceGateway !== "INVOICE_EXPRESS",
    },
    {
      size: 12,
      field: <TextField id="STRIPE.accountId" type="text" label="Stripe account ID" disabled={disabled} />,
      hidden: values?.BILLING?.gateway !== "STRIPE" || isDefaultConfigs,
    },
  ];
}

export function formBillingFields({ disabled, currencySymbol, values }) {
  return [
    {
      size: 12,
      field: <CheckboxField id="BILLING.reviseOnClose" label="Revise on close by backoffice" disabled={disabled} />,
    },
    {
      size: 12,
      field: <CheckboxField id="BILLING.withCapture" label="Enable Capture" disabled={disabled} />,
    },
    {
      size: 12,
      field: (
        <NumberField
          id="BILLING.captivationPeriod"
          label="Captivation Period (credit)"
          decimalScale={0}
          endAdornment="min"
          disabled={disabled}
        />
      ),
      hidden: !values?.BILLING?.withCapture
    },
    {
      size: 12,
      field: (
        <NumberField
          id="BILLING.minimumCaptivationAmount"
          label="Minimum captivation amount (credit)"
          decimalScale={2}
          endAdornment={currencySymbol}
          disabled={disabled}
        />
      ),
      hidden: !values?.BILLING?.withCapture
    },
    // {
    //   size: 12,
    //   field: (
    //     <NumberField
    //       id="BILLING.reservationBond"
    //       label="Minimum amount to start reservation (debit)"
    //       decimalScale={2}
    //       endAdornment={currencySymbol}
    //       disabled={disabled}
    //     />
    //   ),
    // },
    // {
    //   size: 12,
    //   field: (
    //     <NumberField
    //       id="BILLING.reservationBondAlert"
    //       label="Minimun amount to request top-up (debit)"
    //       decimalScale={2}
    //       endAdornment={currencySymbol}
    //       disabled={disabled}
    //     />
    //   ),
    // },
    // {
    //   size: 12,
    //   field: (
    //     <NumberField
    //       id="BILLING.refundFee"
    //       label="Refund fee"
    //       decimalScale={2}
    //       endAdornment={currencySymbol}
    //       disabled={disabled}
    //     />
    //   ),
    // },
    {
      size: 12,
      field: <CheckboxField id="BILLING.enableDeposit" label="Enable Deposit" disabled={disabled} />,
    },
    {
      size: 12,
      field: (
        <NumberField
          id="BILLING.deposit"
          label="Deposit"
          decimalScale={2}
          endAdornment={currencySymbol}
          disabled={disabled}
        />
      ),
      hidden: !values?.BILLING?.enableDeposit
    },
  ];
}
