import React from 'react';
import {
	withStyles,
} from '@material-ui/core';
import Promotion from '../../components/Promotion/Promotion/PromotionWrapper';
import PromoCodeGen from '../../components/Promotion/PromoCodeGen/PromoCodeGenWrapper';
import IndividualBonus from '../../components/Promotion/IndividualBonus/IndividualBonusWrapper';
import * as screens from '../../model/Promotion/screens';
import verticalMenuButtons from './verticalMenuButtons';
import VerticalMenuBar from '../../components/VerticalMenuBar/VerticalMenuBar';
import styles from './PromotionApp.styles';

const PromotionApp = ({
	classes,
	currentScreen,
	currentScreenSize,
	idToSearch,
	searchFromBonus,
	setCurrentScreen,
}) => {
	const menuButtonOnClick = {
		[screens.PROMOTIONS]: () =>
			setCurrentScreen(screens.PROMOTIONS),
		[screens.INDIVIDUALBONUS]: () =>
			searchFromBonus(),
		[screens.CODEGENERATOR]: () =>
			setCurrentScreen(screens.CODEGENERATOR),
	};

	const menuButtons = verticalMenuButtons
		.map((button) => {
			button['onClick'] = menuButtonOnClick[button.text];
			button.fill = currentScreen === button.text ? 'rgb(64, 149, 194)' : '#818181';
			return button;
		});

	const menu = <VerticalMenuBar buttons={menuButtons} />;

	const screen = {
		[screens.PROMOTIONS]: <Promotion currentScreenSize={currentScreenSize} />,
		[screens.INDIVIDUALBONUS]: (
			<IndividualBonus
				currentScreenSize={currentScreenSize}
				idToSearch={idToSearch}
			/>
		),
		[screens.CODEGENERATOR]: (
			<PromoCodeGen
				currentScreenSize={currentScreenSize}
				searchFromBonus={searchFromBonus}
			/>
		),
	}[currentScreen];

	return (
		<div className={classes.root}>
			{menu}
			{screen}
		</div>
	);
};

export default withStyles(styles)(PromotionApp);