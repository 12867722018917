import React, {
	useEffect,
} from 'react';
import CreatePricingPlanDetail from './CreatePricingPlanDetail';
import {
	currencyFormatter,
} from '../../../../services/formatter';
import useValidator from '../../../../hooks/useValidator/useValidator';

const setValue = (value, setter) => {
	if(typeof value !== 'undefined' && value !== null){
		return setter.value.set(String(value));
	};

	return false;
};

const decimalPlaces = 4;

const format = value => currencyFormatter.format(value, {decimalPlaces});

const isNatural = n => {

	const p = parseInt(n, 10);
	return p >= 0 && p.toString() === n;
};

const countDecimals = value => {

	if (!value && value !== 0){
		return 0;
	}

	if (Math.floor(value) === Number(value)) return 0;

	const decimals = value.toString().split(".")[1];

	return (decimals && decimals.length) || 0;
};

const CreatePricingPlanDetailWrapper = props => {

	const {
		closeHandler,
		isEditing,
		placeholderSetters,
		selectedPricingPlanDetail,
		setHasUnsavedChanges,
	} = props;

	const {
		addDetail,
		editDetail,
	} = placeholderSetters || {};

	// useValidator wiring
	const descriptionInput = {
		label: 'Description',
		value: useValidator({
			value: undefined,
			error: false,
		},
		value => {
			if (typeof value === 'undefined') {
				return false;
			}
			const errorMessage = 'Description must be under 176 characters';
			return value.length > 176 ? errorMessage : false;
		},
		),
		required: true,
	};

	const timeStartMinInput = {
		label: 'Time Start Minutes',
		value: useValidator({
			value: undefined,
			error: false,
		},
		value => {
			if (typeof value === "undefined" || value === 0) return false;

			return isNatural(value) ? false : 'Time start must be a natural number.';
		},
		),
		required: false,
	};

	const timeEndInput = {
		label: 'Time End Minutes',
		value: useValidator({
			value: undefined,
			error: false,
		},
		value => {
			if (typeof value === "undefined") return false;

			return isNatural(value) ? false : 'Time end must be a natural number.';
		},
		),
		required: true,
	};

	const distancePriceInput = {
		label: 'Distance Price',
		value: useValidator({
			value: undefined,
			error: false,
		},
		value => {
			if (typeof value === "undefined") return false;

			if (value < 0 || countDecimals(value) > decimalPlaces) {
				return 'Distance price must be a positive number, up to ' + decimalPlaces + ' decimal places.';
			}
			return false;
		},
		),
		required: true,
	};

	const timePriceInput = {
		label: 'Time Price',
		value: useValidator({
			value: undefined,
			error: false,
		},
		value => {
			if (typeof value === "undefined") return false;

			if (value < 0 || countDecimals(value) > decimalPlaces) {
				return 'Time price must be a positive number, up to ' + decimalPlaces + ' decimal places.';
			}
			return false;
		},
		),
		required: true,
	};

	const maxTimePriceInput = {
		label: 'Max. Time Price',
		value: useValidator({
			value: undefined,
			error: false,
		},
		value => {
			if (typeof value === "undefined") return false;

			if (value < 0 || countDecimals(value) > decimalPlaces) {
				return ' Maximum time price must be a positive number, up to ' + decimalPlaces + ' decimal places.';
			}
			return false;
		},
		),
		required: false,
	};

	const distanceOfferInput = {
		label: 'Distance Offer',
		value: useValidator({
			value: undefined,
			error: false,
		},
		value => {
			if (typeof value === "undefined" || value === 0) return false;

			return isNatural(value) ? false : 'Distance offer must be a natural number.';
		},
		),
		required: false,
	};

	const timeOfferInput = {
		label: 'Time Offer',
		value: useValidator({
			value: undefined,
			error: false,
		},
		value => {
			if (typeof value === "undefined" || value === 0) return false;

			return isNatural(value) ? false : 'Time offer must be a natural number.';
		},
		),
		required: false,
	};

	const isDefaultInput = {
		label: 'Default',
		value: useValidator({
			value: false,
			error: false,
		},
		() => false,
		),
		required: false,
	};

	const validatedInputs = {
		description: descriptionInput,
		timeStartMin: timeStartMinInput,
		timeEnd: timeEndInput,
		distancePrice: distancePriceInput,
		timePrice: timePriceInput,
		maxTimePrice: maxTimePriceInput,
		distanceOffer: distanceOfferInput,
		timeOffer: timeOfferInput,
		isDefault: isDefaultInput,
	};

	const errors = {
		descriptionError: descriptionInput.value.state.error,
		timeStartMinError: timeStartMinInput.value.state.error,
		timeEndError: timeEndInput.value.state.error,
		distancePriceError: distancePriceInput.value.state.error,
		timePriceError: timePriceInput.value.state.error,
		maxTimePriceError: maxTimePriceInput.value.state.error,
		distanceOfferError: distanceOfferInput.value.state.error,
		timeOfferError: timeOfferInput.value.state.error,
		isDefaultError: isDefaultInput.value.state.error,
	};

	// END //

	useEffect(() => {

		if (isEditing && !!selectedPricingPlanDetail) {
			setValue(selectedPricingPlanDetail.description, descriptionInput);
			setValue(selectedPricingPlanDetail.timeStartMin, timeStartMinInput);
			setValue(selectedPricingPlanDetail.timeEnd, timeEndInput);
			setValue(format(selectedPricingPlanDetail.distancePrice), distancePriceInput);
			setValue(format(selectedPricingPlanDetail.timePrice), timePriceInput);
			setValue(format(selectedPricingPlanDetail.maxTimePrice), maxTimePriceInput);
			setValue(selectedPricingPlanDetail.distanceOffer, distanceOfferInput);
			setValue(selectedPricingPlanDetail.timeOffer, timeOfferInput);
			setValue(selectedPricingPlanDetail.maxTimePrice, maxTimePriceInput);
			
			if (typeof selectedPricingPlanDetail.isDefault !== 'undefined') {
				isDefaultInput.value.set(selectedPricingPlanDetail.isDefault);
			}

			props.setHasUnsavedChanges(true);
		}
		// eslint-disable-next-line
	}, []);

	const createPricingPlanDetailHandler = async (event) => {

		event.preventDefault();

		const pricingPlanDetail = {
			description: descriptionInput.value.state.value,
			timeStartMin: timeStartMinInput.value.state.value,
			timeEnd: timeEndInput.value.state.value,
			distancePrice: distancePriceInput.value.state.value,
			timePrice: timePriceInput.value.state.value,
			maxTimePrice: maxTimePriceInput.value.state.value,
			distanceOffer: distanceOfferInput.value.state.value,
			timeOffer: timeOfferInput.value.state.value,
			isDefault: isDefaultInput.value.state.value,
		};

		!!selectedPricingPlanDetail
			? editDetail({...pricingPlanDetail}, selectedPricingPlanDetail.index)
			: addDetail({...pricingPlanDetail});

		setHasUnsavedChanges(true);
		closeHandler();
	};

	return (
		<CreatePricingPlanDetail
			{...props}
			// FIXME: Use useSwitch hook
			createPricingPlanDetailHandler={createPricingPlanDetailHandler}
			error={Object.values(errors).find(Boolean)}
			validatedInputs={validatedInputs}
		/>
	);
};

export default CreatePricingPlanDetailWrapper;