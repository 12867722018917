import React from "react";
import { Input, Tooltip, Badge } from "reactstrap";

class I18nElement extends React.Component {
	constructor(props) {
		super(props);
		let value = {};
		if (props.languages) {
			props.languages.forEach(lang => {
				value[lang] = props.value[lang] || "";
			});
		}
		this.state = {
			tooltipOpen: false,
			value: value,
		};
		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		this.setState({
			tooltipOpen: !this.state.tooltipOpen,
		});
	}

	handleChange(lang, e) {
		let value = e.target.value;
		let v = this.state.value;
		v[lang] = value;
		this.setState({
			value: v,
		});
		this.props.onChange(this.props.field.key, v);
	}

	fixKeyAttr(key) {
		return key.replace(/\./g, "-");
	}

	renderKeyAndTooltip() {
		return (
			<td>
				<span size="sm">{this.props.field.key}  </span>
				{this.props.field.new ?  <Badge color="warning">New</Badge> : null}
				<Tooltip placement="left" isOpen={this.state.tooltipOpen} target={'tooltip_' + this.fixKeyAttr(this.props.field.key)} toggle={this.toggle}>
					{this.props.field.description}
				</Tooltip>
			</td>
		);
	}

	renderInputs() {
		if (this.props.languages) {
			return this.props.languages.map(lang => {
				if (this.props.field.key === 'form.persinfo.terms' || this.props.field.key === 'terms') {
					return (
						<td key={'input_' + lang + '_' + this.fixKeyAttr(this.props.field.key)}>
							<Input type="textarea" rows="30" bsSize="sm" value={this.state.value[lang]} onChange={this.handleChange.bind(this, lang)} />
						</td>
					);
				}
        
				return (
					<td key={'input_' + lang + '_' + this.fixKeyAttr(this.props.field.key)}>
						<Input type="text" bsSize="sm" value={this.state.value[lang]} onChange={this.handleChange.bind(this, lang)} />
					</td>
				);
        
			});
		}
	}

	render() {
		if (this.props.field) {
			return (
				<tr id={'tooltip_' + this.fixKeyAttr(this.props.field.key)} className="config-input config-input-string">
					{this.renderKeyAndTooltip()}
					{this.renderInputs()}
				</tr>
			);
		}
	}
}

export default I18nElement;