import { wsCall, requestHelper } from "../../../app/coreSlice";
import { alertList, setAlertsFilterLocation } from "../../alerts/alertsSlice";
import { assetList, setMapCenter } from "../../asset/assetSlice";

const operatorGetDefaultLocCall = async ({ setDefaultLocation }, dispatch, state) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let selectedAssetToken = state.getIn(["assets"])?.selectedAssetToken;
  let rh = requestHelper(dispatch, "OPERATOR/GET_DEFAULT_LOC");
  let result;
  try {
    result = await wsCall({
      type: "OPERATOR",
      subType: "GET_DEFAULT_LOC",
      locationId: selectedLocation?.id,
    });

    if (result && !result.error) {
      dispatch(setDefaultLocation(result));
      dispatch(setAlertsFilterLocation(result?.id));
      dispatch(alertList({ clear: true }));
      dispatch(assetList({}));
      if (!selectedAssetToken) {
        dispatch(
          setMapCenter({
            lat: result.latitude,
            lng: result.longitude,
          })
        );
      }
    }
  } catch (_ex) {
    rh.error("Error loading user default location.");
  }
  rh.close();
  return result;
};

export default operatorGetDefaultLocCall;
