import React from 'react';
import {
	Error as ErrorIcon,
	Warning as WarningIcon,
	Info as InfoIcon,
	CheckCircleOutline as SuccessIcon,
	HighlightOff as CloseIcon,
} from '@material-ui/icons';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import { styles } from './Alert.styles';
import classnames from 'classnames';

const Alert = ({
	classes,
	className,
	type,
	titleMessage,
	errorMessage,
	clearErrorMessage,
}) => {

	if (typeof errorMessage === 'undefined'
        || typeof clearErrorMessage !== 'function') {
		return null;
	};

	const iconAndStyles = getIconAndStyles(type, classes);
	const Icon = iconAndStyles.Icon;
	const {
		root,
		icon,
		title,
		text,
	} = iconAndStyles;

	const content = (
		<>
			<Icon className={icon} />
			<div className={classes.errorTextContainer} >
				<Typography className={title}>{titleMessage || 'Something went wrong'}</Typography>
				<Typography className={text}>{errorMessage}</Typography>
			</div>
			<CloseIcon className={classes.closeIcon} onClick={clearErrorMessage} />
		</>
	);

	return (
		<div className={classnames(classes.root, root, errorMessage === false && classes.fadeOut)} >
			{content}
		</div>
	);
};

const getIconAndStyles = (type, classes) => {
	switch (type) {
		case 'error':
			return {
				Icon: ErrorIcon,
				root: classes.errorRoot,
				icon: classes.errorIcon,
				title: classes.errorTitle,
				text: classes.errorText,
			};
		case 'warning':
			return {
				Icon: WarningIcon,
				root: classes.warningRoot,
				icon: classes.warningIcon,
				title: classes.warningTitle,
				text: classes.warningText,
			};
		case 'info':
			return {
				Icon: InfoIcon,
				root: classes.infoRoot,
				icon: classes.infoIcon,
				title: classes.infoTitle,
				text: classes.infoText,
			};
		case 'success':
			return {
				Icon: SuccessIcon,
				root: classes.successRoot,
				icon: classes.successIcon,
				title: classes.successTitle,
				text: classes.successText,
			};
		default:
			return {
				Icon: InfoIcon,
				root: classes.infoRoot,
				icon: classes.infoIcon,
				title: classes.infoTitle,
				text: classes.infoText,
			};
	};
};

export default withStyles(styles)(Alert);