import React from 'react';
import {
	Modal,
	Typography,
	withStyles,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import classnames from 'classnames';
import moment from 'moment';
import Button from '../../../UI/Buttons/CommonButtons';
import styles from './CreateModal.styles';

const CreateModal = ({
	classes,
	className,
	hasRequest,
	onChange,
	onClose,
	onSave,
	open,
	placeholder,
}) => {
	const title = (
		<div className={classes.titleDiv}>
			<Typography className={classes.title}>
				Create account billing item
			</Typography>
			<Typography className={classes.text}>
				Select the desired date range and press Ok to create a new account billing item
			</Typography>
		</div>
	);

	const datePicker = datePickerCreate(classes, placeholder, onChange);
	const startDate = datePicker({ label: 'Start Date', field: 'startDate' });
	const endDate = datePicker({ label: 'End Date', field: 'endDate' });

	const dateFilters = (
		<>
			{startDate}
			{endDate}
		</>
	);

	const saveButton = (
		<Button
			disabled={hasRequest}
			key='create_button'
			label='Ok'
			onClick={onSave}
		/>
	);
	return (
		<Modal
			onClose={!hasRequest ? onClose : undefined}
			open={open}
		>
			<div className={classes.modal}>
				<div className={classnames(classes.root, className)}>
					{title}
					<div className={classes.save}>
						{dateFilters}
						{saveButton}
					</div>
				</div>

			</div>
		</Modal>
	);
};

export default withStyles(styles)(CreateModal);

const datePickerCreate = (classes, placeholder, onChange) => ({ field, label }) => (
	<DatePicker
		ampm={false}
		className={classes.pickers}
		clearable
		format="DD/MM/YYYY"
		inputVariant="outlined"
		key={label}
		label={label}
		onChange={date => onChange({ [field]: date && moment(date).format('YYYY-MM-DD') })}
		value={placeholder?.[field] || null}
		InputProps={{ classes: {input: classes.pickerInput}}}
		InputLabelProps={{classes: {outlined: classes.outlined}}}
	/>
);