import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const imoOnCall = async ({ updateAssetOnList }, dispatch, state, { carToken }) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "IMO/ON");
  try {
    const result = await wsCall({
      type: "IMO",
      subType: "ON",
      locationId: selectedLocation?.id,
      message: {
        carToken,
      },
    });
    if (result && !result.error) {
      dispatch(setSuccess("Enable immobiliser command sent successfully"));
      dispatch(updateAssetOnList(result));
    } else {
      rh.error(result?.description || "Error enabling immobiliser!");
    }
  } catch (_ex) {
    rh.error("Error enabling immobiliser!");
  }
  rh.close();
};

export default imoOnCall;
