const styles = theme => ({
	root: {
		minWidth: 283,
		height: '100%',
		fontFamily: theme.typography.fontFamily,
	},

	noSelected: {
		paddingTop: '20px',
		color: '#009BCC',
		fontSize: '16px',
	},

	buttons: {
		alignItems: 'flex-end',
		display: 'flex',
		flexGrow: '1',
		justifyContent: 'space-around',
		padding: '5% 0',
	},
});

export default styles;