const styles = () => ({

	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		position: 'relative',
	},

	calendar: {
		width: '50%',
		height: '90%',
		display: 'flex',
		flexDirection: 'column',
		color: 'white',
		backgroundColor: 'rgb(220, 220, 220)',
		zIndex: 10,
	},

	list: {
		width: 'auto',
		height: 'auto',
		display: 'flex',
		flexDirection: 'column',
		padding: '0 21px',
		minHeight: '0px',
	},

	createButton: {
		padding: '0px 21px',
		display: 'flex',
		flexDirection: 'row-reverse',
		width: 'auto',
		height: 40,
		alignItems: 'center',
	},

	createIcon: {
		cursor: 'pointer',
	},

	drawer: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		maxWidth: '625px',
		minWidth: '625px',
		padding: '15px',
	},
});

export default styles;