import React from "react";
import { InputAdornment, InputBase, withStyles } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import styles from "./Searchbar.styles";
import classnames from "classnames";

const SearchBar = ({
  classes,
  className,
  error,
  filter,
  label,
  disabled,
  onClick,
  onDoubleClick,
  onSearch,
  setFilter,
  noline,
}) => {
  const searchIcon = (
    <SearchIcon
      className={classnames(classes.searchIcon, disabled && classes.disabled)}
      onClick={disabled ? undefined : onClick || onSearch}
      onDoubleClick={() => onDoubleClick && onDoubleClick()}
      size="small"
    />
  );

  return (
    <InputBase
      className={classnames(classes.root, disabled && classes.disabled, className)}
      placeholder={label}
      value={filter || ""}
      classes={{
        root: classes.inputRoot,
        input: classnames(classes.inputInput, error && classes.error, noline && classes.noBorder),
      }}
      onChange={(event) => setFilter(event.target.value)}
      disabled={!!disabled}
      onKeyDown={(event) => {
        if (event.keyCode === 13) {
          !disabled && onSearch();
        }
      }}
      endAdornment={<InputAdornment position="end">{searchIcon}</InputAdornment>}
    />
  );
};

export default withStyles(styles)(SearchBar);
