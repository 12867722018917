import React from "react";
import { grid } from "./grid";
import { withStyles } from "@material-ui/core";
import classNames from "classnames";
import styles from "./DamagesGrid.styles";

const DamagesGrid = ({ classes, damageReports, selected, asset, onSelect }) => {
  let damages = {};

  damages =
    damageReports &&
    Object.values(damageReports)
      .filter((element) => !element.resolvedDate || (typeof selected !== "undefined" && element === selected))
      .reduce((accumulator, element) => _fillScheme(accumulator, element), {});

  if (typeof selected !== "undefined" && selected.resolvedDate) {
    _fillScheme(damages, selected);
  }

  return (
    <div className={classNames(classes.root, asset?.assetTypeName === "SCOOTER" ? classes.scooter : classes.car)}>
      {Object.keys(grid).map((column) => {
        return grid[column].map((row) => {
          let damageToken = damages[column] && damages[column][row] && damages[column][row][0];
          return (
            <div className={classes.cell} key={`${column}${row}`}>
              {damageToken && (
                <div
                  id={damages[column][row]}
                  className={classNames(
                    classes.damage,
                    selected &&
                      (selected.damageToken === damageToken || selected.id === damageToken) &&
                      classes.selectedDamage
                  )}
                  onClick={() => onSelect && onSelect(damageToken)}
                />
              )}
            </div>
          );
        });
      })}
    </div>
  );
};

export default withStyles(styles)(DamagesGrid);

const _fillScheme = (obj, { column, row, damageToken, id }) => {
  if (!obj[column]) {
    obj[column] = {};
  }

  if (!obj[column][row]) {
    obj[column][row] = [];
  }

  obj[column][row].push(damageToken || id);

  return obj;
};
