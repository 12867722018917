import React from "react";
import Button from "../../../UI/Buttons/CommonButtons";
import { Typography, withStyles, Tooltip } from "@material-ui/core";
import { LocationCity as Icon } from "@material-ui/icons";
import { dateFormatter } from "../../../../services/formatter";
import withPermission from "../../../../hoc/withPermission";
import classnames from "classnames";
import styles from "./LocationsItem.styles";

import StarIcon from "@material-ui/icons/Star";

const LocationsItem = ({
  centerMap,
  classes,
  className,
  currentLocation,
  editLocation,
  isDrawing,
  isSelected,
  location,
  onSelect,
  redrawLocation,
  removeLocation,
  stopRedrawing,
  cancelDrawing,
}) => {
  const { code, createDate, latitude, longitude, name, updateDate, updateUser, defaultLoc } = location || {};

  const editDate = updateDate || createDate;

  const lastEditDate = editDate ? dateFormatter.format(editDate, "dateTime") : "N/A";

  return (
    <div
      className={classnames(classes.root, className, isSelected && classes.selected)}
      onClick={() => {
        onSelect(isSelected ? null : location);
        !isSelected && centerMap(latitude, longitude, name, code);
      }}
    >
      <div className={classes.header}>
        <Icon className={classnames(classes.icon, currentLocation?.id === location?.id && classes.blueIcon)} />
        <Typography>{name}</Typography>
        {defaultLoc && (
          <Tooltip title="Default" placement="right">
            <StarIcon fontSize="small" style={{ marginLeft: 10 }} />
          </Tooltip>
        )}
      </div>
      {isSelected && !isDrawing.state && (
        <div className={classes.buttons}>
          <ButtonWithPermission buttonStyleName="void" label="Edit" onClick={stopPropagation(editLocation)} />
          <ButtonWithPermission buttonStyleName="void" label="Redraw" onClick={stopPropagation(redrawLocation)} />
          <ButtonWithPermission label="Delete" onClick={() => removeLocation(code)} />
        </div>
      )}
      {isSelected && isDrawing.state && (
        <div className={classes.buttons}>
          <Button buttonStyleName="void" label="Cancel" onClick={stopPropagation(cancelDrawing)} />
          <Button label="Confirm" onClick={stopPropagation(stopRedrawing)} />
        </div>
      )}
      {isSelected && (
        <div className={classes.timestamp}>
          <Typography>{`Last edited at ${lastEditDate}`}</Typography>
          <Typography>{` by user ${updateUser || "N/A"}`}</Typography>
        </div>
      )}
    </div>
  );
};

const ButtonWithPermission = withPermission([{ resource: ["fleet", "locations", "edit"] }])((props) => (
  <Button {...props} />
));

export default withStyles(styles)(LocationsItem);

const stopPropagation = (func) => (e) => {
  e.stopPropagation();
  !!func && func();
};
