import {
	SET_FILTER,
	SET_HASSEARCH,
	SET_BUTTONTIMEOUT,
} from './actionTypes';
import makeActionCreator from '../../../utils/makeActionCreator';

export const setFilter = makeActionCreator(SET_FILTER, 'filter');

export const setHasSearch = makeActionCreator(SET_HASSEARCH, 'hasSearch');

export const setButtonTimeout = makeActionCreator(SET_BUTTONTIMEOUT, 'buttonTimeout');
