export const styles = theme => ({

	root: {
		position: 'absolute',
		top: '5vh',
		marginLeft: 'auto',
		marginRight: 'auto',
		left: '10vw',
		width: '60vw',
		textColot: 'white',
		padding: theme.spacing(1),
		borderRadius: 3,
		height: 40,
		maxHeight: 40,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		animation: '$bigshow 1.5s forwards',
		visibility: 'hidden',
	},

	fadeOut: {
		animation: '$bigshow2 1.5s forwards',
		visibility: 'visible',
	},

	'@keyframes bigshow': {
		'0%': {
			visibility: 'visible',
			display: 'none',
			opacity: 0,
		},
		'1%': {
			display: 'flex',
			opacity: 0,
		},
		'25%': {
			opacity: 0.8,
		},
		'100%': {
			display: 'flex',
			visibility: 'visible',
			opacity: 1,
		},
	},

	'@keyframes bigshow2': {
		'0%': {
			display: 'flex',
			opacity: 1,
		},
		'1%': {
			opacity: 0.85,
		},
		'50%': {
			opacity: 0,
		},
		'100%': {
			opacity: 0,
			display: 'none',
		},
	},

	errorTextContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'left',

	},

	closeIcon: {
		position: 'absolute',
		right: 0,
		top: 0,
		margin: 2,
		fill: 'rgb(40, 40, 40)',
		cursor: 'pointer',
	},

	errorRoot: {
		backgroundColor: 'rgb(250, 179, 174)',
		border: '2px solid rgb(244, 67, 54)',
	},
	errorIcon: {
		fill: 'rgb(244, 67, 54)',
		marginRight: 8,
	},
	errorTitle: {
		color: 'white',
	},
	errorText: {
		color: 'white',
	},
	warningRoot: {
		backgroundColor: 'rgb(255, 213, 153)',
		border: '2px solid rgb(255, 152, 0)',

	},
	warningIcon: {
		fill: 'rgb(255, 152, 0)',
		marginRight: 8,
	},
	warningTitle: {
		// color: 'rgb(255, 152, 0)',
		color: 'white',
	},
	warningText: {
		// color: 'rgb(255, 152, 0)',
		color: 'white',
	},
	infoRoot: {
		backgroundColor: 'rgb(166, 213, 250)',
		border: '2px solid rgb(33, 150, 243)',
	},
	infoIcon: {
		fill: 'rgb(33, 150, 243)',
		marginRight: 8,
	},
	infoTitle: {
		// color: 'rgb(33, 150, 243)',
		color: 'white',
	},
	infoText: {
		// color: 'rgb(33, 150, 243)',
		color: 'white',
	},
	successRoot: {
		backgroundColor: 'rgb(183, 223, 185)',
		border: '2px solid rgb(76, 175, 80)',

	},
	successIcon: {
		fill: 'rgb(76, 175, 80)',
		marginRight: 8,
	},
	successTitle: {
		color: 'white',
	},
	successText: {
		color: 'white',
	},

});