import Incident from '../../../../model/Incident/Incident';

export const fromDTO = ({
	administrativeCost,
	attachment,
	description,
	incidentCode,
	incidentCost,
	incidentType,
	liabilityPercentage,
}) => new Incident({
	administrativeCost,
	attachment,
	description,
	incidentCode,
	incidentCost,
	incidentType,
	liabilityPercentage,
});

export const toDTO = () => alert('Mapper Undefined');

const mapper = {
	fromDTO,
	toDTO,
};

export default mapper;