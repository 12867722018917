import makeActionCreator from '../../utils/makeActionCreator';
import createReducer from '../../hooks/createCustomReducer';

const types = {
	RESET_PLACEHOLDER: 'RESET_PLACEHOLDER',
	SET_CARCLUB_ID: 'SET_CARCLUB_ID',
	SET_COLOR: 'SET_COLOR',
	SET_GP_STANDBY: 'SET_GP_STANDBY',
	SET_GP_USE: 'SET_GP_USE',
	SET_ID: 'SET_ID',
	SET_NAME: 'SET_NAME',
	SET_PRICE_IN_STANDBY: 'SET_PRICE_IN_STANDBY',
	SET_PRICE_IN_USE: 'SET_PRICE_IN_USE',
	SET_PRICE_KM: 'SET_PRICE_KM',
	SET_RATE_NUMBER: 'SET_RATE_NUMBER',
};

export const resetPlaceholder = makeActionCreator(types.RESET_PLACEHOLDER);
export const setCarclubId = makeActionCreator(types.SET_CARCLUB_ID, 'carclubId');
export const setColor = makeActionCreator(types.SET_COLOR, 'color');
export const setGoldPinInUse = makeActionCreator(types.SET_GP_USE, 'goldPinInUse');
export const setGoldPinInStandby = makeActionCreator(types.SET_GP_STANDBY, 'goldPinInStandby');
export const setId = makeActionCreator(types.SET_ID, 'id');
export const setName = makeActionCreator(types.SET_NAME, 'name');
export const setPriceInKm = makeActionCreator(types.SET_PRICE_KM, 'priceKm');
export const setPriceInStandby = makeActionCreator(types.SET_PRICE_IN_STANDBY, 'priceInStandby');
export const setPriceInUse = makeActionCreator(types.SET_PRICE_IN_USE, 'priceInUse');
export const setRateNumber = makeActionCreator(types.SET_RATE_NUMBER, 'rateNumber');

const actions = dispatch => ({
	resetPlaceholder: () => dispatch(resetPlaceholder()),
	setCarclubId: (data) => dispatch(setCarclubId(data)),
	setColor: (data) => dispatch(setColor(data)),
	setGoldPinInUse: data => dispatch(setGoldPinInUse(data)),
	setGoldPinInStandby: data => dispatch(setGoldPinInStandby(data)),
	setId: (data) => dispatch(setId(data)),
	setName: (data) => dispatch(setName(data)),
	setPriceInKm: (data) => dispatch(setPriceInKm(data)),
	setPriceInStandby: (data) => dispatch(setPriceInStandby(data)),
	setPriceInUse: (data) => dispatch(setPriceInUse(data)),
	setRateNumber: (data) => dispatch(setRateNumber(data)),
});

export const defaultState = {
	carclubId: undefined,
	color: undefined,
	goldPinInUse: 0,
	goldPinInStandby: 0,
	id: undefined,
	name: undefined,
	priceInStandby: 0,
	priceInUse: 0,
	priceKm: 0,
	rateNumber: 0,
};

const setIdReducer = (state, action) => ({
	...state,
	id: action.id,
});

const setNameReducer = (state, action) => ({
	...state,
	name: action.name,
});

const setRateNumberReducer = (state, action) => {
	return validate('rateNumber', action.rateNumber, state);
};

const setPriceInUseReducer = (state, action) => {
	return validate('priceInUse', action.priceInUse, state);
};

const setPriceInStandbyReducer = (state, action) => {
	return validate('priceInStandby', action.priceInStandby, state);
};

const setPriceKmReducer = (state, action) => {
	return validate('priceKm', action.priceKm, state);
};

const setGoldPinInUseReducer = (state, action) => {
	return validate('goldPinInUse', action.goldPinInUse, state);
};

const setGoldPinInStandbyReducer = (state, action) => {
	return validate('goldPinInStandby', action.goldPinInStandby, state);
};

const setColorReducer = (state, action) => ({
	...state,
	color: action.color,
});

const setCarclubIdReducer = (state, action) => ({
	...state,
	carclubId: action.carclubId,
});


const resetPlaceholderReducer = () => defaultState;

export const reducer = (state, action) => ({
	[types.RESET_PLACEHOLDER]: resetPlaceholderReducer,
	[types.SET_CARCLUB_ID]: setCarclubIdReducer,
	[types.SET_COLOR]: setColorReducer,
	[types.SET_ID]: setIdReducer,
	[types.SET_NAME]: setNameReducer,
	[types.SET_GP_STANDBY]: setGoldPinInStandbyReducer,
	[types.SET_PRICE_IN_STANDBY]: setPriceInStandbyReducer,
	[types.SET_GP_USE]: setGoldPinInUseReducer,
	[types.SET_PRICE_IN_USE]: setPriceInUseReducer,
	[types.SET_PRICE_KM]: setPriceKmReducer,
	[types.SET_RATE_NUMBER]: setRateNumberReducer,

}[action.type](state, action));

export default createReducer({ reducer, actions, defaultState });

const validate = (key, value, state) => {
	const regex = validators[key];
	if (!regex) {
		return {
			...state,
			[key]: value,
		};
	}
	const passedTest = regex.test(value);
	if (!!passedTest) {
		if (value === '.') {
			return {
				...state,
				[key]: 0.0,
			};
		}
		return {
			...state,
			[key]: value,
		};
	}
	return state;
};

const numericFloatRegex = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/;
const numericIntegerRegex = /^[0-9]*$/;

const validators = {
	rateNumber: numericIntegerRegex,
	priceInUse: numericFloatRegex,
	priceInStandby: numericFloatRegex,
	priceKm: numericFloatRegex,
	goldPinInUse: numericFloatRegex,
	goldPinInStandby: numericFloatRegex,
};