import React, { useCallback } from 'react';
import carclubService from '../../../../services/carclub';
import RolesList from './RolesList';
import log from '../../../../utils/logger';

const logger = log('[Carclub]RolesListWrapper');

const RolesListWrapper = ({
	lastPage,
	onRemove,
	onSearch,
	onUpdate,
	...props }) => {

	const getMoreRows = !lastPage && (() => onSearch());

	const onEnable = useCallback(async role => {
		if (!role) {
			return;
		};

		try {
			const updatedRole = await carclubService.enableRole(role);

			onUpdate(updatedRole);
		}
		catch (error) {
			logger.warn(`Could not ${role.enabled ? 'disable' : 'enable'} ${role.id}`);
		};
	}, [onUpdate]);

	const onDelete = useCallback(async code => {
		try {
			const request = await carclubService.removeRole(code);

			!request.error && onRemove(code);
		}
		catch (error) {
			logger.warn(`Could not remove ${code}`);
		};
	}, [onRemove]);

	return (
		<RolesList
			{...props}
			{...{
				getMoreRows,
				onDelete,
				roleHandler: onEnable,
			}}
		/>
	);
};

export default RolesListWrapper;