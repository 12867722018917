import { wsCall, requestHelper, s3GetPublic } from "../../../app/coreSlice";

const damageReportGetResolvedCall = async (
  { clearDamageReportsSolved, setAssetToken, setDamageReportsSolved },
  dispatch, state,
  { assetToken }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "DAMAGE_REPORT/GET_RESOLVED");
  try {
    dispatch(clearDamageReportsSolved());
    dispatch(setAssetToken(assetToken));
    const result = await wsCall({
      type: "DAMAGE_REPORT",
      subType: "GET_RESOLVED",
      locationId: selectedLocation?.id,
      message: {
        carToken: assetToken,
      },
    });
    if (!result.error) {
      let s3Link = (result || []).map((item) => item.urlImage).filter((item) => Boolean(item));
      let publicLinks = s3Link && s3Link?.length > 0 ? await s3GetPublic({ s3Link }) : [];
      let resultWithImagesLinks =
        publicLinks && publicLinks?.length > 0
          ? (result || []).map((item) => {
              return {
                ...item,
                urlImagePublic: publicLinks.find((img) => img.key === item.urlImage)?.value || undefined,
              };
            })
          : result;
      dispatch(setDamageReportsSolved(resultWithImagesLinks));
    }
  } catch (_ex) {
    rh.error("Error getting damage reports.");
  }
  rh.close();
};

export default damageReportGetResolvedCall;