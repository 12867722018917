import React from 'react';
import {
	withStyles,
} from '@material-ui/core';
import { styles } from './Driver.styles';
import classnames from 'classnames';
import SimpleInputMapper from '../Utils/SimpleInputMapper';

const Driver = ({
	classes,
	driver,
}) => {

	const {
		name,
		email,
		phone,
		accountName,
		groupId,
	} = driver || {};

	const driverMapperConfig =  {
		'Name:': name || '-',
		'Email:': email || '-',
		'Phone:': phone || '-',
	};

	if(groupId){
		driverMapperConfig.Account = accountName || '-';
	};

	return (
		<div
			className={classnames(classes.root)}
		>
			<SimpleInputMapper
				title='Driver'
				inputmapperconfig={driverMapperConfig}
				// inputmapper={textFieldMapper}
			/>
		</div>
	);
};

export default withStyles(styles)(Driver);