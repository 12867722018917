export const styles = theme => ({

	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		width: 750,
		height: 480,
		position: 'absolute',
		top: 'calc(50vh - 220px)',
		left: 'calc(50vw - 375px)',
		backgroundColor: '#fff',
		padding: '8px 26px',
		borderRadius: 8,
		boxSizing: 'border-box',
	},

	workspace: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
	},

	content: {
		width: '100%',
		height: 350,
		display: 'flex',

	},

	tabs: {
		display: 'flex',
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between',
		boxSizing: 'border-box',
	},

	tab: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.30)',
		flexBasis: '30%',
		cursor: 'pointer',
		minWidth: '115px',
		textAlign: 'center',
		fontSize: 16,
		color: '#a1a1a1',
	},

	selectedTab: {
		borderBottom: '2px solid rgba(1, 151, 198, 0.75)',
		fontWeight: 'bold',
	},

	disabled: {
		cursor: 'default',
	},

	assetInformation: {
		flexBasis: '100%',

	},

	zonesAndParks: {
		flexBasis: '100%',
	},

	obsDevice: {
		alignSelf: 'center',
	},

	rootBar: {
		position: 'absolute',
		top: 0,
		left: 'calc(50% - 200px)',
		textAlign: 'center',
		lineHeight: 'inherit',
		marginTop: 10,
		height: 18,
		width: '400px',
		fontSize: '14px',
		borderRadius: 10,
		color: 'white',
	},

	showBar: {
		opacity: 1,
		transition: 'all 1000ms linear',
	},

	hideBar: {
		opacity: 0,
		transition: 'all 2500ms linear',
	},

	success: {
		backgroundColor: 'rgb(0, 182, 127)',
	},

	error: {
		backgroundColor: 'rgb(253, 46, 37)',
	},

	confirmationModal: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		/*alignItems: 'center',*/
	},

});
