export const styles = theme => ({
	
	root: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: 'calc(100vh - 50px)',
		maxHeight: 'calc(100vh - 50px)',
		width: '100%',
	},
	
	titleBar: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'flex-start',
		width: '100%',
	},

	title: {
		fontSize: '18px',
		padding: '8px',
		color: '#47525E',
	},

	tabs: {
		display: 'flex',
		padding: '0 8px 0 3px',
		width: '100%',
		boxSizing: 'border-box',
	},

	tab: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.30)',
		cursor: 'pointer',
		fontSize: '16px',
		margin: '0 5px 5px 5px',
		minWidth: '96px',
		textAlign: 'left',
	},

	selectedTab: {
		borderBottom: '2px solid rgba(1, 151, 198, 0.75)',
		fontWeight: 'bold',
	},
	
});