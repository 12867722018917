import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";
import CarIcon from "@material-ui/icons/DirectionsCar";

import { styles } from "./AssetCounter.styles";

function AssetCounter({ classes, color, label, counter }) {
  return (
    <Grid container direction="row" className={classes.root} justifyContent="space-between" alignItems="center">
      <Grid item>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <CarIcon className={classes.icon} style={{ backgroundColor: color }} />
          </Grid>
          <Grid item>
            <Typography className={classes.text}>{label}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography className={classes.text}>{counter}</Typography>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(AssetCounter);
