import { connect } from 'react-redux';
import toJS from '../../hoc/toJS';
import * as accountActions from '../../store/actions/account/action';
import AccountApp from './AccountAppWrapper';

const mapStateToProps = (state) => {

	return {
		currentScreenSize: state.getIn(['app', 'windowWidth']),
		accounts: state.getIn(['account', 'elements']),
		lastPage: state.getIn(['account', 'lastPage']),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		listAccounts: filters => dispatch(accountActions.listAccounts(filters)),
		getAccount: filters => dispatch(accountActions.getAccount(filters)),
		createAccount: account => dispatch(accountActions.createAccount(account)),
		updateAccount: account => dispatch(accountActions.updateAccount(account)),
		removeAccount: code => dispatch(accountActions.removeAccount(code)),
		getAccountDetails: code => dispatch(accountActions.getAccountDetails(code)),
		enableAccount: code => dispatch(accountActions.enableAccount(code)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(AccountApp));
