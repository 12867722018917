import { ReactComponent as PricingPlanIcon } from '../../assets/PricingPlanApp/new/pricing_plan.svg';
import { ReactComponent as RatesIcon } from '../../assets/PricingPlanApp/new/rates.svg';
import { ReactComponent as ExtrasIcon } from '../../assets/PricingPlanApp/new/extras.svg';

const button = (icon, text, tag, style={ color: "gray" }, right=false) => {
	return {
		icon,
		right,
		style,
		tag,
		text,
		permissions: tag && [{ resource: ['pricingPlansNew', tag, 'read']}] ,
	};
};

const menuButtons = []
	.concat(button(PricingPlanIcon, 'Pricing Plans', 'pricingPlans'))
	.concat(button(RatesIcon, 'Rates', 'rates'))
	.concat(button(ExtrasIcon, 'Additional Fees', 'extras'))
;

export default menuButtons;