import { assetAssetReservationCounts, updateAssetOnList } from "../../../features/asset/assetSlice";
import {
  dashboardFeedReservation,
  removeSingleOngoingReservation,
  updateSingleOngoingReservation,
} from "../../../features/reservation/reservationsSlice";
import defaultHandlers from "./defaultHandlers";

export const alertSubscriptionHandlers = (body, dispatch, state) => {
  const selectedLocation = state.getIn(["carclub2"])?.defaultLocation;
  console.log("selectedLocation: %o", selectedLocation);
  switch (body.type + "/" + body.subType) {
    case "DASHBOARD/FEED_RESERVATION":
      let msg = body?.processMessage;
      console.log("DASHBOARD/FEED_RESERVATION - msg: %o", msg);
      if (msg.locationId !== selectedLocation?.id) {
        // ignore reservation feed from other locations
        return;
      }
      if (msg.state === "IDLE" || msg.state === "STARTED") {
        dispatch(
          updateSingleOngoingReservation({
            code: msg.reservationCode,
            ...msg,
          })
        );
      }
      if (msg.state === "SCHEDULED" && msg.advanced) {
        dispatch(
          updateAssetOnList({
            assetToken: msg.carToken,
            inUse: true,
          })
        );
        dispatch(
          updateSingleOngoingReservation({
            code: msg.reservationCode,
            ...msg,
          })
        );
      }
      if (msg.state === "CANCELLED" || msg.state === "CLOSED" || msg.state === "OVERLAPPED") {
        dispatch(
          updateAssetOnList({
            assetToken: msg.carToken,
            inUse: false,
          })
        );
        dispatch(
          removeSingleOngoingReservation({
            code: msg.reservationCode,
          })
        );
      }
      dispatch(assetAssetReservationCounts());
      dispatch(dashboardFeedReservation(msg));
      break;
    default:
      break;
  }

  let handler = defaultHandlers[body.type]?.[body.subType];
  handler && handler(body.processMessage);
};
