
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import { publishAndAwait } from '../../../app/coreSlice';

const listAvailableCoordinates = async () => {

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'ASSET',
			subType: 'LIST',
			message: {
				locationId: null,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
			message,
		} = processMessage;

		throw new NGError({
			message: description || message,
			code,
			tag,
		});
	}

	const validAssets = processMessage.map( e => {
		if (!(e.lat || e.latitude) || !(e.lng || e.longitude)) return null;
		return {
			lat: e.lat || e.latitude,
			lng: e.lng || e.longitude,
			licensePlate: e.licensePlate,
			assetToken: e.assetToken,
			maintenance: e?.maintenance,
			criticalFuel: e?.fuelLevel < 10 ? true : false,
		};
	}).filter(e => !!e);

	return validAssets;
};

export default listAvailableCoordinates;
