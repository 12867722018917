import React from 'react';
import {
	Tooltip,
	IconButton,
} from '@material-ui/core';
import { ReactComponent as CreateIcon } from '../../../assets/PromotionApp/icons/add.svg';
import withPermission from '../../../hoc/withPermission';

const AddButton = ({
	classes,
	onCreateAsset,
	createButtonRef,
}) => (
	<Tooltip title="Add asset">
		<IconButton
			aria-label="add asset"
			className={classes.addButton}
			color="primary"
			component="div"
			onClick={onCreateAsset}
			ref={createButtonRef}
		>
			<CreateIcon />
		</IconButton>
	</Tooltip>
);

const permissions = [{ resource: ['fleet', 'assets', 'create'] }];

export default withPermission(permissions)(AddButton);