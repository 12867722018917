import React from 'react';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import { styles } from './SimpleInputMapper.styles';
import classnames from 'classnames';

/*  <What_Is_This_?/>
        This is a SimpleInputMapper, his job is to create a simple, standard,
        block of details in the form prop_name: prop_value ( Job: Pirate );
    <What_Do_I_Need_To_Work_?/>
        To do this it uses 3 things, at his most basic form:
            > title => (STRING) Title to display above the mapped <name, value> details.,
            > inputmapperconfig => (OBJECT) An object containing the <k, v> entries,
            > inputmapper => (FUNCTION) Returns a React.Fragment ( same as => <></> ),
                containg 2 text fields <name, value>.
    <Logic/>
        An array obtained by extracting the keys from the inputMapperConfigs
        (namesOfFields) is iterated over by the map function, which takes the
        inputmapper function that return the mapped and styled fields. These
        are inserted in the root div with the styled title above.
    
*/
const SimpleInputMapper = ({
	classes,
	className,
	titleClassName,
	labelClassName,
	valueClassName,
	title,
	noTitle = false,
	noPadding = false,
	inputmapperconfig = {},
	inputmapper = textFieldMapper,
}) => {

	const namesOfFields = Object.keys(inputmapperconfig);

	const titleDisplayValue = typeof title !== 'undefined'
		? (<Typography
			key={'Title' + title}
			className={classnames(classes.textFieldTitle, titleClassName)}
		>
			{title}
		</Typography>)
		: 'Details (missing title)';


	const mappedInputs = (
		<>
			{namesOfFields.map(inputmapper({
				classes,
				title,
				inputmapperconfig,
				labelClassName,
				valueClassName,
			}))}
		</>
	);

	const detailsBlock = !!inputmapperconfig && (
		<>
			{!noTitle && titleDisplayValue}
			{mappedInputs}
		</>
	);

	// RENDER

	return (
		<div className={classnames(classes.root, className,
			!!noPadding && classes.noPadding)}>
			{detailsBlock}
		</div>
	);
};

const textFieldMapper = ({
	classes,
	title,
	inputmapperconfig,
	labelClassName,
	valueClassName,
}) => (fieldName) => {

	if (typeof fieldName === 'undefined'
		|| typeof inputmapperconfig === 'undefined'
		|| !inputmapperconfig[fieldName]) {
		return null;
	}
	return (
		<div
			key={title + 'container' + fieldName}
			className={classnames(classes.fieldContainer)}
		>
			<Typography
				key={title + title + fieldName}
				className={classnames(classes.textField, !!labelClassName && labelClassName)} display={'inline'}
			>
				{fieldName}
			</Typography>
			<Typography
				key={title + title + inputmapperconfig[fieldName]}
				className={classnames(classes.textField, !!valueClassName && valueClassName)} display={'inline'}
			>
				{inputmapperconfig[fieldName]}
			</Typography>
		</div>
	);
};

export default withStyles(styles)(SimpleInputMapper);