import {
	CHAT_ADD_WORKORDER,
	CHAT_NEW_GROUP,
	CHAT_NEW_MESSAGE,
	CHAT_NEW_PENDING,
	CHAT_REMOVE_GROUP_INVITE,
	CHAT_REMOVE_PENDING,
	CHAT_REMOVE_WORKORDER,
	CHAT_UPDATE_MESSAGES,
} from '../../actions/chat/actionTypes';
import * as mutate from './mutators';
import { pipe } from '../utils';
import createReducer from '../../utils/createReducer';
import { Map } from 'immutable';

const initialState = Map({
	newMessages: Map({}),
	pendingContacts: Map({}),
	groupInvites: Map({}),
	workorder: undefined,
});

const addNewMessage = (state, action) => {
	return pipe([
		mutate.addNewMessage([action.contact], 'code'),
	], state);
};

const removeMessageAlert = (state, action) => {
	return pipe([
		mutate.removeMessageAlert(action.contact),
	], state);
};

const newPendingContact = (state, action) => {
	return pipe([
		mutate.addNewPendingContact([action.contact], 'target'),
	], state);
};

const removePendingContact = (state, action) => {
	return pipe([
		mutate.removePendingContact(action.contact),
	], state);
};

const newGroupInvite = (state, action) => {
	return pipe([
		mutate.addNewGroup([action.group], 'groupId'),
	], state);
};

const removeGroupInvite = (state, action) => {
	return pipe([
		mutate.removeGroupInvite(action.group),
	], state);
};

const addWorkorder = (state, action) => {
	return pipe([
		mutate.setWorkorder(action.workorder),
	], state);
};

const removeWorkorder = state => {
	return pipe([
		mutate.setWorkorder(null),
	], state);
};

const chatReducer = createReducer(initialState, {
	[CHAT_ADD_WORKORDER]: addWorkorder,
	[CHAT_NEW_GROUP]: newGroupInvite,
	[CHAT_NEW_MESSAGE]: addNewMessage,
	[CHAT_NEW_PENDING]: newPendingContact,
	[CHAT_REMOVE_GROUP_INVITE]: removeGroupInvite,
	[CHAT_REMOVE_PENDING]: removePendingContact,
	[CHAT_REMOVE_WORKORDER]: removeWorkorder,
	[CHAT_UPDATE_MESSAGES]: removeMessageAlert,
});

export default chatReducer;