// FIXME These should not be exported but this will break the app
// Refactor to use the new definition
// (these strings can be used directly in const types)
export const CAR = 'CAR';
export const KICKSCOOTER = 'SCOOTER';
export const MOBICS = 'MOBICS';

const batteryIssue = {
	color: '#F00000',
	radius: '30px',
};

const disconnected = {
	color: '#000000',
	radius: '30px',
};

const engineRunning = {
	color: '#54A226',
	radius: '14px',
};

const offline = {
	color: '#F00000',
	radius: '12px',
};

const reservation = {
	color: '#0082FF',
	radius: '10px',
};

const lowFuel = {
	color: '#0082FF',
	radius: '10px',
};

const undefinedStatus = {
	color: '#BDBDBD',
	radius: '10px',
};

export const getIconDetails = assetStatus => ({
	BATTERY_ISSUE: batteryIssue,
	DISCONNECTED: disconnected,
	ENGINE_RUNNING: engineRunning,
	OFFLINE: offline,
	RESERVATION: reservation,
	LOW_FUEL: lowFuel,
}[assetStatus] || undefinedStatus);