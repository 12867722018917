import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { FormControlLabel, Checkbox } from "@material-ui/core";

import { styles } from "./_base.styles";

function CheckboxInput({ classes, className, label, value, onChange, disabled, size }) {
  return (
    <div className={classes.root + (className ? " " + className : "")}>
      <FormControlLabel
        className={classes.switch}
        control={
          <Checkbox
            color="primary"
            size={size}
            checked={value}
            onChange={(e) => onChange && onChange(e.target.checked)}
            disabled={disabled}
          />
        }
        label={label}
        labelPlacement="end"
        disabled={disabled}
      />
    </div>
  );
}

export default withStyles(styles)(CheckboxInput);
