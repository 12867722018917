import React from "react";
import { InputBase, Typography, withStyles } from "@material-ui/core";
import { Search as SearchIcon, AddCircle as CreateIcon } from "@material-ui/icons";
import classnames from "classnames";
import statusFilter from "./statusFilter";
import workOrderStatus from "../../../../model/WorkOrder/workOrderStatus";
import DatePicker from "../../../Reservations/ReservationDatePicker/ReservationDatePicker";
import MultiFilterButton from "../../../FilterButton/MultiFilterButton";
import withPermission from "../../../../hoc/withPermission";
import styles from "./Filters.styles";

const { ALL, OPEN, INPROCESS, COMPLETED } = workOrderStatus;

//TODO: dateFormatter
const dateChange = (onChange, type) => (date) => onChange({ [type]: date ? new Date(date) : undefined });

const Filters = ({
  anchorEl,
  classes,
  closeSelectFilter,
  doubleClick,
  filter = {},
  onChange,
  onSearch,
  openFilter,
  openSelectFilter,
  priorityButton,
  selectedStatus,
  setStatus,
  singleClick,
  startCreate,
  typeButton,
  workOrdersBackupArray,
}) => {
  const searchBox = (
    <InputBase
      placeholder="Search by assigned agent or license plate"
      value={filter.filter || ""}
      classes={{
        root: classnames(classes.inputRoot, classes.string),
        input: classes.inputInput,
      }}
      onChange={(event) => onChange({ filter: event.target.value })}
      onKeyDown={(event) => {
        if (event.keyCode === 13) {
          onSearch(true);
        }
      }}
    />
  );

  const datePickers = (
    <DatePicker
      className={classes.datePickers}
      endDate={filter.scheduledEndDate || null}
      endDateHandler={dateChange(onChange, "scheduledEndDate")}
      endDateLabel="Scheduled End"
      hasFuture={true}
      startDate={filter.scheduledStartDate || null}
      startDateHandler={dateChange(onChange, "scheduledStartDate")}
      startDateLabel="Scheduled Start"
    />
  );

  const priorityFilter = (
    <MultiFilterButton
      key={priorityButton.text}
      button={priorityButton}
      open={openFilter === priorityButton.text}
      anchorEl={anchorEl}
      handleOpen={(event, filter) => openSelectFilter(event, filter)}
      handleClose={closeSelectFilter || (() => undefined)}
      selected={filter.priority}
      onSelect={(selectedFilter) => onChange({ priority: selectedFilter.filter })}
    />
  );

  const typeFilter = (
    <MultiFilterButton
      key={typeButton.text}
      button={typeButton}
      open={openFilter === typeButton.text}
      anchorEl={anchorEl}
      handleOpen={(event, filter) => openSelectFilter(event, filter)}
      handleClose={closeSelectFilter || (() => undefined)}
      selected={filter.designation}
      onSelect={(selectedFilter) => onChange({ designation: selectedFilter.filter })}
    />
  );

  const filterBar = (
    <div className={classes.filterBar}>
      <Typography className={classes.title}>Work Orders</Typography>
      <div className={classes.filters}>
        {searchBox}
        {datePickers}
        {typeFilter}
        {priorityFilter}
        <SearchIcon onClick={singleClick} onDoubleClick={doubleClick} className={classnames(classes.icon)} />
      </div>
      <div className={classes.createButtonDiv}>
        <CreateButton onClick={startCreate} className={classes.createIcon} />
      </div>
    </div>
  );

  const statusFiltersMapper = statusFilter(classes, setStatus, selectedStatus);

  const statusFilters = [ALL, OPEN, INPROCESS, COMPLETED].map((status) => {
    const arrayToCountWith = workOrdersBackupArray;
    const length = arrayToCountWith.filter((workOrder) => !status.key || workOrder.status === status.key).length;
    return statusFiltersMapper({ ...status, hideLength: status === COMPLETED, length });
  });

  const typeFiltersDiv = (
    <div className={classes.typeFilters}>
      <Typography className={classes.subtitle} display="inline">
        Orders overview
      </Typography>
      {statusFilters}
    </div>
  );

  return (
    <div className={classes.root}>
      {filterBar}
      {typeFiltersDiv}
    </div>
  );
};

export default withStyles(styles)(Filters);

const CreateButton = withPermission([{ resource: ["workOrders", "create"] }])(({ onClick, className }) => (
  <CreateIcon onClick={onClick} className={className} />
));
