const styles = theme => ({

	root:{
		width: '100%',
		height: '100%',
		display: 'flex',
		boxSizing: 'border-box',
	},

	create: {
		minWidth: '100px',
		width: '100%',
		boxSizing: 'border-box',
	},
});

export default styles;