import React from 'react';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import {
	Check,
} from '@material-ui/icons';
import { styles } from './PricingPlanDetails.styles';
import classnames from 'classnames';
import SimpleInputMapper from '../../TripApp/TripDetails/Utils/SimpleInputMapper';
import MaxFlatRatesList from '../PricingPlanRatesList/MaxFlatRatesList/MaxFlatRatesListWrapper';
import { currencyFormatter } from '../../../services/formatter';
import ZonesList from '../PricingPlanEditor/ZonesList';


const PricingPlanDetails = ({
	classes,
	pricingPlan,
	moreDetails,
	placeholderMaxFlatRate,
}) => {
	const {
		name,
		description,
		startDate,
		endDate,
		startingFee,
		category,
		specific,
		customerCount,
		zoneCount,
		groupCount,
	} = pricingPlan || {};

	const startingFeeValue = !!startingFee
		? currencyFormatter.format(startingFee) + ' ' + currencyFormatter.symbol()
		: null;

	const ppDetailsMapperConfig = {
		'Name:': name || '-',
		'Description:': description || '-',
		'Start date:': startDate || '-',
		'End date:': endDate || '-',
		'Starting fee:': startingFeeValue || '-',
		'Category:': category || '-',
		'Private:': specific ? <Check /> : '-',
		'Drivers:': customerCount || '-',
		'Zones:': zoneCount || '-',
		'Groups:': groupCount || '-',
	};

	const ppDetailsStyled = (
		<SimpleInputMapper
			title='Pricing Plan Details'
			titleClassName={classes.textFieldTitle}
			inputmapperconfig={ppDetailsMapperConfig}
		/>
	);

	const maxFlatRatesList = !!pricingPlan && !!pricingPlan.pricingDetails
		&& pricingPlan.pricingDetails.maxPrices
		&& (
			<MaxFlatRatesList
				className={classes.maxRatesList}
				titleClassName={classes.textFieldTitle}
				placeholderMaxFlatRate={placeholderMaxFlatRate}
				maxRatesArray={pricingPlan.pricingDetails.maxPrices}
				viewOnly={true}
				smallList={true}
			/>
		);

	const startZones = (
		<ZonesList
			title='Start Zone'
			zonesList={pricingPlan?.startZoneFees}
			viewOnly
		/>
	);

	const endZones = (
		<ZonesList
			title='End Zone'
			zonesList={pricingPlan?.endZoneFees}
			hasEqual
			viewOnly
		/>
	);

	return (
		<div
			className={classnames(classes.root)}
		>
			<div className={classes.detailsBlock}>
				{ppDetailsStyled}
			</div>
			<div className={classes.maxRatesBlock}>
				{maxFlatRatesList}
			</div>
			<div className={classes.zonesBlock}>
				<Typography className={classes.title}>
					Zones
				</Typography>
				{startZones}
				{endZones}
			</div>
		</div>
	);
};

export default withStyles(styles)(PricingPlanDetails);