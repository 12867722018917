import { useReducer } from 'react';

const createCustomReducer = ({ reducer, actions, defaultState = null }) => {

	if (!reducer) {
		throw new Error('A reducer must be provided');
	}

	if (!actions) {
		throw new Error('Actions must be provided');
	}

	return (initialState) => {

		const initState = initialState !== undefined
			? initialState
			: defaultState;

		const [state, dispatch] = useReducer(reducer, initState);

		const action = actions(dispatch);

		return { state, ...action };
	};
};

export default createCustomReducer;