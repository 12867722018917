import React  from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Person } from "@material-ui/icons";
import PeopleIcon from "@material-ui/icons/People";

import { styles } from "./DriverIcon.styles";

function DriverIcon({ classes, empty, account }) {
  return (
    <div className={classes.root}>
      <div className={classes.icon + (empty ? " " + classes.empty : "")}>{account ? <PeopleIcon /> : <Person />}</div>
    </div>
  );
}

export default withStyles(styles)(DriverIcon);
