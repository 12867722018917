import React, { useEffect } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid, Button, CircularProgress } from "@material-ui/core";

import { styles } from "./AssetDetails.styles";
import VehicleIcon, { getTooltip } from "../common/VehicleIcon/VehicleIcon";
import { useSelector } from "react-redux";
import {
  selectAssetDetails,
  selectLoader,
  selectSelectedAsset,
  assetDetails as assetDetailsReq,
  setAssetDetails,
  getCarInfo,
  selectCarInfo,
  setShowAssetEdit,
  setShowMoveAssetModal,
} from "../assetSlice";
import { getAssetStatus } from "../asset.utils";
import AssetFields from "./AssetFields/AssetFields";
import { useDispatch } from "react-redux";
import DeviceInfo from "./DeviceInfo/DeviceInfo";
import AssetZonesAndParks from "./AssetZonesAndParks/AssetZonesAndParks";
import Permission from "../../../app/components/Permission";

function AssetDetails({ classes, onEdit }) {
  const dispatch = useDispatch();
  const selectedAsset = useSelector(selectSelectedAsset);
  const status = getAssetStatus(selectedAsset || {});
  const assetDetails = useSelector(selectAssetDetails);
  const carInfo = useSelector(selectCarInfo);
  const loading = useSelector(selectLoader);

  const asset = assetDetails?.carToken ? assetDetails : selectedAsset;

  useEffect(() => {
    if (selectedAsset?.assetToken) {
      dispatch(assetDetailsReq({ carToken: selectedAsset?.assetToken }));
    }
    if (!carInfo) {
      dispatch(getCarInfo());
    }
    return () => {
      dispatch(setAssetDetails({}));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Grid
      className={classes.root}
      container
      spacing={1}
      justifyContent="space-between"
      direction="column"
      wrap="nowrap"
    >
      <Grid item>
        <Grid container justifyContent="space-between" className={classes.header}>
          <Grid item>
            <Typography className={classes.title}>Asset Information</Typography>
          </Grid>
          {loading && (
            <Grid item>
              <CircularProgress size={20} />
            </Grid>
          )}
          <Grid item>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <VehicleIcon asset={asset} />
              </Grid>
              <Grid item>
                <Typography className={classes.title}>{getTooltip(status)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AssetFields asset={asset} />
          </Grid>
          <Grid item xs={8}>
            <AssetZonesAndParks asset={asset} />
          </Grid>
          <Grid item xs={4}>
            <DeviceInfo
              provider_token={asset?.device?.provider_token}
              serial_number={asset?.device?.serial_number}
              externalId={asset?.device?.externalId}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container className={classes.footer} justifyContent="center" spacing={2}>
          <Permission code="fleet.assets.edit">
            <Grid item>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                size="small"
                disabled={asset?.operational}
                onClick={() => dispatch(setShowAssetEdit(true))}
              >
                Edit
              </Button>
            </Grid>
          </Permission>
          <Permission code="fleet.assets.create">
            <Grid item>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                size="small"
                // disabled={asset?.operational}
                onClick={() => dispatch(setShowMoveAssetModal(true))}
              >
                Move Asset
              </Button>
            </Grid>
          </Permission>
        </Grid>
      </Grid>
      <br />
    </Grid>
  );
}

export default withStyles(styles)(AssetDetails);
