import {
	INVOICE_CLEAR_ELEMENTS,
	INVOICE_SET_ELEMENTS,
	INVOICE_SET_DETAILS,
} from '../../actions/invoice/actionTypes';
import * as mutate from './mutators';
import { pipe } from '../utils';
import createReducer from '../../utils/createReducer';
import { Map } from 'immutable';

const initialState = Map({
	elements: Map(),
});

const invoiceSetElements = (state, action) => {

	return pipe([
		mutate.setElements(action.elements, 'invoiceId'),
	], state);
};

const invoiceClearElements = state => {
	return state.update('elements', map => map.clear());
};

const invoiceSetDetails = (state, action) => {

	return pipe([
		mutate.setElements([action.invoice], 'invoiceId'),
	], state);
};

const invoiceReducer = createReducer(initialState, {
	[INVOICE_SET_ELEMENTS] : invoiceSetElements,
	[INVOICE_CLEAR_ELEMENTS] : invoiceClearElements,
	[INVOICE_SET_DETAILS] : invoiceSetDetails,
});

export default invoiceReducer;