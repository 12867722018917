import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const customerNewPasswordOperatorCall = async (dispatch, state, { customerCode }) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "CUSTOMER/NEW_PASSWORD_OPERATOR");
  try {

    let result = await wsCall({
      type: "CUSTOMER",
      subType: "NEW_PASSWORD_OPERATOR",
      locationId: selectedLocation?.id,
      message: {
        customerCode,
        locationId: selectedLocation?.id,
      },
    });
    if (!result.error) {
      dispatch(setSuccess("Customer password reseted."));
    } else {
      rh.error(result?.description || "Error reseting customer password.");
    }
  } catch (_ex) {
    rh.error("Error reseting customer password.");
  }
  rh.close();
};

export default customerNewPasswordOperatorCall;
