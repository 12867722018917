import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Grid, Link, Divider } from "@material-ui/core";
import { PictureAsPdf as PdfIcon } from "@material-ui/icons";

import { styles } from "./InvoiceDetails.styles";
import { useSelector } from "react-redux";
import LabelValueInfo from "../../../../../common/displays/LabelValueInfo/LabelValueInfo";
import { useCurrency, useDateTime } from "../../../../../common/hooks";
import { selectSelectedTrip, selectSelectedTripPaymentDetails } from "../../../reservationsSlice";
import moment from "moment";

function InvoiceDetails({ classes, sortedPayments }) {
  const paymentDetails = useSelector(selectSelectedTripPaymentDetails);
  const reservation = useSelector(selectSelectedTrip);
  const { formatCurrency } = useCurrency();
  const { toTimeZone } = useDateTime();

  const { billingDetails, billingItemCosts } = paymentDetails;
  let { billingItem, invoices } = billingDetails || {};
  let { chargedAmount, feeAmount, paid, promotions, preAmount, pricingPlan, depositAmount, depositRefundAmount } = billingItem || {};
  let { standByCost, inUseCost, kmCost, inUseTime, standByTime, distance } = billingItemCosts || {};

  const [lastPayment] = (sortedPayments || []).slice(-1);

  const parsedDistance = distance ? " " + distance + " km" : "";
  const chargedAmountValue = reservation.state === "CLOSED" && chargedAmount ? chargedAmount : null;
  const dueAmountValue = reservation.state === "CLOSED" && preAmount && !paid ? preAmount - (chargedAmount || 0) + depositAmount - depositRefundAmount : null;

  return (
    <Grid className={classes.root} container direction="column" spacing={1}>
      {/* STANDBY */}
      <Grid item>
        <LabelValueInfo
          label={standByTime > 0 ? `Standby (${minuteRoundUpFormat(standByTime)}):` : "Standby:"}
          value={formatCurrency(standByCost)}
        />
      </Grid>
      {/* DRIVING */}
      <Grid item>
        <LabelValueInfo
          label={inUseTime > 0 ? `Driving (${minuteRoundUpFormat(inUseTime)}):` : "Driving:"}
          value={formatCurrency(inUseCost)}
        />
      </Grid>
      {/* KM */}
      <Grid item>
        <LabelValueInfo label="Km:" value={formatCurrency(kmCost) + (kmCost ? parsedDistance : "")} />
      </Grid>
      {/* FEES */}
      <Grid item>
        <LabelValueInfo label="Fees Cost:" value={formatCurrency(feeAmount)} />
      </Grid>
      <Divider />
      {/* TRIP COST */}
      <Grid item>
        <LabelValueInfo label="Trip Cost:" value={formatCurrency(preAmount)} />
      </Grid>
      {/* DEPOSIT */}
      <Grid item>
        <LabelValueInfo label="Deposit:" value={formatCurrency(depositAmount)} />
      </Grid>
      {/* DEPOSIT REFUND */}
      <Grid item>
        <LabelValueInfo label="Deposit Refund:" value={formatCurrency(depositRefundAmount)} />
      </Grid>
      {/* CHARGED */}
      <Grid item>
        <LabelValueInfo label="Total Charged:" value={formatCurrency(chargedAmountValue)} />
      </Grid>
      {/* DUE */}
      <Grid item>
        <LabelValueInfo label="Due Amount:" value={formatCurrency(dueAmountValue)} />
      </Grid>
      <Divider />
      {/* PRINCING PLAN */}
      {pricingPlan && (
        <Grid item>
          <LabelValueInfo label="Pricing Plan Applied:" value={pricingPlan} />
        </Grid>
      )}
      {/* PROMOTIONS */}
      {promotions && promotions[0] && (
        <Grid item>
          <LabelValueInfo
            label="Promotion Applied:"
            value={`${promotions[0].promotionDescription} (${formatCurrency(promotions[0].discountAmount)})`}
          />
        </Grid>
      )}
      {/* PAYMENT FAILED MESSAGE */}
      {lastPayment?.failMessage && (
        <Grid item>
          <div className={classes.errorContainer}>{`Failed Message: ${lastPayment?.failMessage}`}</div>
        </Grid>
      )}
      {/* INVOICES */}
      {invoices && invoices?.length > 0 && (
        <Grid item>
          {invoices.map((invoice, index) => (
            <Grid key={"invoice_" + index} className={classes.root} container direction="column" spacing={1}>
              <Grid item>
                <LabelValueInfo
                  label="Invoice Number:"
                  value={invoice.invoiceNumber || invoice.carclubInvoiceNumber || "-"}
                />
              </Grid>
              <Grid item>
                <LabelValueInfo
                  label="Invoice Issue:"
                  value={
                    invoice.createDate || invoice.dateCreated
                      ? toTimeZone(invoice.createDate || invoice.dateCreated, "date")
                      : "-"
                  }
                />
              </Grid>
              <Grid item>
                <LabelValueInfo
                  label="Invoice PDF:"
                  value={
                    invoice.pdfUrl ? (
                      <Link href={invoice.pdfUrl} target="_blank">
                        <PdfIcon />
                      </Link>
                    ) : (
                      "-"
                    )
                  }
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
}

export default withStyles(styles)(InvoiceDetails);

const minuteRoundUpFormat = (millisecondsTime) => {
  if (!millisecondsTime) {
    return null;
  }
  let mTime = moment("2000-01-01 00:00:00").add(millisecondsTime, "milliseconds");
  mTime = mTime.second() || mTime.millisecond() ? mTime.add(1, "minute").startOf("minute") : mTime.startOf("minute");
  return mTime.format("H[h] m[m]").replace(/0h /, "");
};
