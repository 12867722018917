import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppBar, Avatar, Button, Toolbar, Typography, makeStyles, withStyles } from "@material-ui/core";
import classnames from "classnames";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ScrollLeftIcon from "@material-ui/icons/ChevronLeft";
import ScrollRightIcon from "@material-ui/icons/ChevronRight";

import styles from "./PrimaryBar.styles";
import UserMenu from "./UserMenu/UserMenuWrapper";
import Timer from "./Timer/Timer";
import AppButton from "./AppButton/AppButton";

import { ReactComponent as Logo } from "../../../../../assets/icons/logo.svg";
import AlertsMain from "../../../../../features/alerts/AlertsMain/AlertsMain";
import { selectCarclubInfo, selectDefaultLocation } from "../../../../../features/carclub/carclubSlice";
import useSwitch from "../../../../../hooks/useSwitch";
import UserSettingsModal from "../../../../../components/UserSettingsModal/UserSettingsModal";
import { selectUserDetails } from "../../../../../features/user/userSlice";
import { logout } from "../../../../websocket/websocketSlice";

const toolbarStyle = { minHeight: 50, paddingRight: 10, backgroundColor: "inherit" };

const useStyles = makeStyles({
  root: {
    backgroundColor: "inherit",
    minWidth: 90,
    fontSize: 10,
    color: "white",
    minHeight: "45px",
    padding: 0,
    cursor: "pointer",
  },
});

const PrimaryBar = ({ apps, selectedApp, selectApp, onLogout, classes, className, style }) => {
  const dispatch = useDispatch();
  const [leftScrollTimeout, setLeftScrollTimeout] = useState();
  const [rightScrollTimeout, setRightScrollTimeout] = useState();
  const dockerRef = useRef();
  const defaultLocation = useSelector(selectDefaultLocation);
  const menuButtonClasses = useStyles(style);
  const openUserSettings = useSwitch(false);
  const carclubInfo = useSelector(selectCarclubInfo);
  const userName = useSelector(selectUserDetails)?.userName;

  const onSettings = openUserSettings.setOn;
  const settingsModal = openUserSettings.state && (
    <UserSettingsModal open={openUserSettings.state} onClose={openUserSettings.setOff} />
  );

  const [userMenuAnchor, setUserMenuAnchor] = useState(null);

  const hasArrows = dockerRef?.current?.offsetWidth < dockerRef?.current?.scrollWidth;

  const windowWidth = window.innerWidth;

  const useLabel = apps.length >= 8 && windowWidth <= 1180 ? false : true;

  useEffect(() => {
    const dock = document.getElementById("appDock");
    if (dock) {
      dock.scrollLeft = 0;
    }
    // eslint-disable-next-line
  }, []);

  const onLogoutHandler = () => {
    dispatch(logout());
  };

  const spacer = <div className={classes.grow} />;

  const scrollLeftBtn = hasArrows && (
    <ScrollLeftIcon
      className={classes.arrow}
      onMouseEnter={(e) => {
        const dock = document.getElementById("appDock");
        if (dock && !leftScrollTimeout) {
          const id = setInterval(() => {
            dock.scrollLeft -= 5;
          }, 10);
          setLeftScrollTimeout(id);
        }
      }}
      onMouseLeave={(e) => {
        if (!!leftScrollTimeout) {
          clearInterval(leftScrollTimeout);
          setLeftScrollTimeout();
        }
      }}
    />
  );
  const scrollRightBtn = hasArrows && (
    <ScrollRightIcon
      className={classes.arrow}
      onMouseEnter={(e) => {
        const dock = document.getElementById("appDock");
        if (dock && !rightScrollTimeout) {
          const id = setInterval(() => {
            dock.scrollLeft += 5;
          }, 10);
          setRightScrollTimeout(id);
        }
      }}
      onMouseLeave={(e) => {
        if (!!rightScrollTimeout) {
          clearInterval(rightScrollTimeout);
          setRightScrollTimeout();
        }
      }}
    />
  );

  return (
    <AppBar
      className={classnames(classes.root, className)}
      style={{ backgroundColor: carclubInfo?.customize?.colorHeader }}
      position="fixed"
    >
      {settingsModal}
      <Toolbar style={toolbarStyle}>
        {" "}
        {/* FIXME this value should not be hardcoded! */}
        {carclubInfo?.customize?.logoFile ? (
          <img className={classes.logo} src={carclubInfo?.customize?.logoFile} alt="logo" />
        ) : (
          <Logo className={classes.logo} />
        )}
        {scrollLeftBtn}
        <div id="appDock" className={classes.dock} ref={dockerRef}>
          {apps &&
            apps.map((app) => (
              <AppButton
                app={app}
                key={app.tag}
                selectApp={selectApp}
                selectedApp={selectedApp}
                useLabel={useLabel}
                menuButtonClasses={menuButtonClasses}
              />
            ))}
        </div>
        {scrollRightBtn}
        {spacer}
        <div className={classes.location}>
          {defaultLocation?.name && <Typography className={classes.locationText}>{defaultLocation.name}</Typography>}
          <Timer />
        </div>
        <AlertsMain selectApp={selectApp} />
        <Button onClick={(e) => setUserMenuAnchor(e.currentTarget)} disabled={null !== userMenuAnchor} color="inherit">
          <Avatar className={classes.avatar}>
            <AccountCircleIcon fontSize="large" />
          </Avatar>
        </Button>
        <UserMenu
          anchorEl={userMenuAnchor}
          defaultLocation={defaultLocation}
          handleClose={() => setUserMenuAnchor(null)}
          onLogout={onLogoutHandler}
          onSettings={() => {
            onSettings();
            setUserMenuAnchor(null);
          }}
          username={userName}
        />
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(PrimaryBar);
