import React from 'react';
import ListItem from '../../../UI/List/ListItem/ListItem';
import {
	Tooltip,
	withStyles,
} from '@material-ui/core';
import withPermission from '../../../../hoc/withPermission';
import {
	Stop as SquareIcon,
	Create as EditIcon,
	RemoveCircleOutline as RemoveIcon,
} from '@material-ui/icons';
//eslint-disable-next-line
import { currencyFormatter } from '../../../../services/formatter';
import classnames from 'classnames';
import styles from './RateItem.styles';

export const modals = {
	CUSTOMER_EDIT_MODAL: 'CUSTOMER_EDIT_MODAL',
	ZONE_EDIT_MODAL: 'ZONE_EDIT_MODAL',
};

const RateItem = ({
	classes,
	fields,
	selected,
	rate,
	placeholder,
	placeholderSetters,
	editMode,
	cantEdit,
	onSelectRateToApply,
	onRemove,
	fullSizeList,
}) => {

	const {
		color: colorCode,
	} = rate || {};

	const squareStyle = { fill: colorCode };
	const color = 
		<SquareIcon style={squareStyle} />
	;

	const removeIcon = !!onRemove && !!placeholderSetters && (
		<Tooltip title='Remove' placement='left'>
			<RemoveIcon
				className={classes.icon}
				onClick={(e) => {
					e.stopPropagation();
					onRemove(rate.id);
					placeholderSetters.resetPlaceholderRate();
				}}
			/>
		</Tooltip>

	);

	const editIcon = !!placeholderSetters && (
		<Tooltip title='Edit' placement='left'>
			<EditIcon
				onClick={event => {
					event.stopPropagation();
					placeholderSetters.setId(rate.id);
					placeholderSetters.setRateNumber(rate.rateNumber);
					placeholderSetters.setNameRate(rate.name);
					placeholderSetters.setPriceInUse(rate.priceInUse);
					placeholderSetters.setPriceInStandby(rate.priceInStandby);
					placeholderSetters.setPriceInKm(rate.priceKm);
					placeholderSetters.setColor(rate.color);
					placeholderSetters.setCarclubId(rate.carclubId);
					placeholderSetters.setGoldPinInUse(rate.goldPinInUse);
					placeholderSetters.setGoldPinInStandby(rate.goldPinInStandby);
					onSelectRateToApply(rate);
					editMode.toggle();
				}}
				className={classes.icon}
			/>
		</Tooltip>
	);

	const editIcons = (
		<div className={classes.icons}>
			<ButtonsWithPermission>
				{editIcon}
				{removeIcon}
			</ButtonsWithPermission>
		</div>
	);

	const itemOnSelect = !!placeholderSetters

		? (event) => {
			event.stopPropagation();
			placeholderSetters.setId(rate.id);
			placeholderSetters.setRateNumber(rate.rateNumber);
			placeholderSetters.setNameRate(rate.name);
			placeholderSetters.setPriceInUse(rate.priceInUse);
			placeholderSetters.setPriceInStandby(rate.priceInStandby);
			placeholderSetters.setPriceInKm(rate.priceKm);
			placeholderSetters.setColor(rate.color);
			placeholderSetters.setCarclubId(rate.carclubId);
			placeholderSetters.setGoldPinInUse(rate.goldPinInUse);
			placeholderSetters.setGoldPinInStandby(rate.goldPinInStandby);
			onSelectRateToApply(rate);
		}
		: () => {
			onSelectRateToApply(rate);
		};

	const priceInUse = (!!rate.priceInUse || Number(rate.priceInUse) === 0)
		&& currencyFormatter.format(rate.priceInUse) + ' ' + currencyFormatter.symbol();

	const priceInStandby = (!!rate.priceInStandby || Number(rate.priceInStandby) === 0)
		&& currencyFormatter.format(rate.priceInStandby) + ' ' + currencyFormatter.symbol();

	const priceKm = (!!rate.priceKm || Number(rate.priceKm) === 0)
		&& currencyFormatter.format(rate.priceKm) + ' ' + currencyFormatter.symbol();

	const goldPinInStandby = (!!rate.goldPinInStandby || Number(rate.goldPinInStandby) === 0)
		&& currencyFormatter.format(rate.goldPinInStandby) + ' ' + currencyFormatter.symbol();

	const goldPinInUse = (!!rate.goldPinInUse || Number(rate.goldPinInUse) === 0)
		&& currencyFormatter.format(rate.goldPinInUse) + ' ' + currencyFormatter.symbol();

	const item = (
		<ListItem
			className={classnames(classes.root, !fullSizeList && classes.disableGutters)}
			selected={selected}
			fields={fields}
			onSelect={itemOnSelect}
			element={{
				...rate,
				color,
				editIcons,
				goldPinInStandby,
				goldPinInUse,
				priceInStandby,
				priceInUse,
				priceKm,
			}}
		/>
	);

	return item;
};

export default withStyles(styles)(RateItem);

const ButtonsWithPermission = withPermission(
	[{ resource: ['pricingPlansNew', 'rates', 'edit'] }],
)(({ children }) => <> {children} </>);