export const details = (workOrder) => [
  { label: "Order ID:", detail: workOrder.externalId || "-" },
  { label: "Vehicle:", detail: workOrder.licensePlate || "-" },
  { label: "Type:", detail: workOrder.type || "-" },
  { label: "Priority:", detail: workOrder.priority || "-" },
  { label: "Scheduled start:", detail: workOrder.scheduledStartDate || "-" },
  { label: "Scheduled end:", detail: workOrder.scheduledEndDate || "-" },
  { label: "Assigned to:", detail: workOrder.assignAgentName || "-" },
  { label: "Status:", detail: workOrder.status || "-" },
  {},
  { label: "Issued by:", detail: workOrder.createUser || "-" },
  { label: "Issue date:", detail: workOrder.createDate || "-" },
  { label: "Start date:", detail: workOrder.startDate || "-" },
  { label: "Completion date:", detail: workOrder.endDate || "-" },
  { label: "Start Location:", detail: workOrder.startLocation || "-" },
  { label: "End Location:", detail: workOrder.endLocation || "-" },
  { label: "Service Point:", detail: workOrder.servicePoint || "-" },
  { label: "Description:", detail: workOrder.description || "-", column: true },
];

export const task = (tasks) => {
  let comments = 0;
  let pictures = 0;

  tasks.forEach((task) => {
    comments += (task.comments && 1) || 0;
    pictures += (task.pictures && task.pictures.length) || 0;
  });

  return [{}, { label: "Comments", detail: comments }, { label: "Pictures", detail: pictures }];
};
