import geofenceTypes from "../../../../model/Geofence/geofenceTypes";

const fromDTO = (location) => {
  return {
    ...location,
    geoInformation: `[${location.geoLocation}]`,
    code: location.id,
    type: geofenceTypes.LOCATION,
  };
};

const toDTO = (location) => ({
  ...location,
  id: location.code,
  geoLocation: location.geoLocation || location.geoInformation,
});

const mapper = {
  fromDTO,
  toDTO,
};

export default mapper;
