export const BANNERS = 'Banners';
export const CARFLOWS = 'Carflows';
export const DEFAULT_CONFIGURATIONS = 'Default Configurations';
export const LOCAL_CONFIGURATIONS = 'Local Configurations';
export const NOTIFICATIONS = 'Notifications';
export const PERMISSIONS = 'Permissions';
export const ROLES = 'Roles';
export const USERS = 'Users';
export const APP_CONFIGURATIONS = 'Mobile App Configurations';
export const INVOICE = 'Invoice Configurations';
export const TELTONIKA = 'IoT Devices';