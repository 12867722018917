import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import * as Yup from "yup";

import { styles } from "./DashboardMetricsFilter.styles";
import { useDateTime } from "../../../../common/hooks";
import { DateTimeField, Form } from "../../../../common/forms";
import { Search as SearchIcon } from "@material-ui/icons";
import { customerDashboardStats, dashboardReservationsBilling, reservationDashboardGet } from "../../dashboardSlice";
import { selectDefaultLocation } from "../../../carclub/carclubSlice";

function DashboardMetricsFilter({ classes }) {
  const dispatch = useDispatch();
  const selectedLocation = useSelector(selectDefaultLocation);
  const { toUtc, now } = useDateTime();
  const resetBtn = useRef();

  useEffect(() => {
    console.log("reset");
    resetBtn.current.click();
  }, [selectedLocation]);

  const formSchema = () => {
    return Yup.object().shape({
      startDate: Yup.string(),
      endDate: Yup.string(),
    });
  };

  const onSubmit = (values) => {
    console.log("values: %o", values);
    const { startDate, endDate } = values;
    const startDateUtc = startDate ? toUtc(startDate).format("YYYY-MM-DD HH:mm:ss") : undefined;
    const endDateUtc = endDate ? toUtc(endDate).format("YYYY-MM-DD HH:mm:ss") : undefined;
    dispatch(customerDashboardStats({ startDate: startDateUtc, endDate: endDateUtc }));
    dispatch(reservationDashboardGet({ startDate: startDateUtc, endDate: endDateUtc }));
    dispatch(dashboardReservationsBilling({ startDate: startDateUtc, endDate: endDateUtc }));
  };

  return (
    <div className={classes.root}>
      <Form
        schema={formSchema()}
        initialValues={{ startDate: now().add(-7, "d"), endDate: now() }}
        keepDirtyOnReinitialize
        onSubmit={onSubmit}
        render={({ submitting, form, values }) => {
          return (
            <Grid
              container
              spacing={2}
              direction="row"
              wrap="nowrap"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Typography align="left" className={classes.title}>
                  Overview
                </Typography>
              </Grid>
              <Grid item>
                <DateTimeField
                  id="startDate"
                  className={classes.date}
                  placeholder="Start date"
                  maxDate={now().format("YYYY-MM-DD HH:mm:00")}
                />
              </Grid>
              <Grid item>
                <DateTimeField
                  id="endDate"
                  className={classes.date}
                  placeholder="End date"
                  maxDate={now().format("YYYY-MM-DD HH:mm:00")}
                  minDate={values.startDate}
                />
              </Grid>
              <Grid item>
                <IconButton type="submit">
                  <SearchIcon />
                </IconButton>
              </Grid>
              <Button
                ref={resetBtn}
                style={{ display: "none" }}
                onClick={() =>
                  form.batch(() => {
                    form.change("startDate", now().add(-7, "d"));
                    form.change("endDate", now());
                  })
                }
              >
                RESET
              </Button>
            </Grid>
          );
        }}
      />
    </div>
  );
}

export default withStyles(styles)(DashboardMetricsFilter);
