import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Field } from "react-final-form";
import MetaError from "../MetaError";
import { styles } from "./_base.styles";
import { DecimalInput } from "../../inputs";

function NumberField({ classes, id, decimalScale, label, disabled, required, endAdornment }) {
  const onChangeHandler = (v, input) => {
    input.onChange && input.onChange(v);
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => (
          <>
            <DecimalInput
              id={id}
              label={label}
              value={input.value}
              onChange={(value) => onChangeHandler(value, input)}
              disabled={disabled}
              required={required}
              decimalScale={decimalScale}
              error={Boolean(meta.error && meta.touched)}
              endAdornment={endAdornment}
              // variant={"outlined"}
            />
            <div className={"FormFieldError " + classes.error}>
              <MetaError meta={meta} />
            </div>
          </>
        )}
      </Field>
    </div>
  );
}

export default withStyles(styles)(NumberField);
