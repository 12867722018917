import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Tooltip } from "@material-ui/core";
import MaintenanceIcon from "@material-ui/icons/BuildRounded";
import UndefinedIcon from "@material-ui/icons/HelpOutline";
import { ReactComponent as BabyCarIcon } from "./icons/babyicon.svg";
import { ReactComponent as CarIcon } from "./icons/caricon.svg";
import { ReactComponent as KickScooterIcon } from "./icons/kickscootericon.svg";
import { ReactComponent as ScooterIcon } from "./icons/scootericon.svg";

import { styles } from "./VehicleIcon.styles";
import { getAssetStatus } from "../../asset.utils";

const RichTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    borderRadius: 3,
    color: "#47525E",
    border: "1px solid #dadde9",
    boxShadow: theme?.shadows[5],
  },
}))(Tooltip);

function VehicleIcon({ classes, className, asset, tooltip, mapPin, richTooltip }) {
  let icon = getIcon(asset);
  let status = getAssetStatus(asset || {});
  let specificClass = classes["icon_" + status];

  let content = (
    <div className={classes.root + (mapPin ? " " + classes.mapMarker : "")}>
      {mapPin && <div className={classes.mapPin + (specificClass ? " " + specificClass : "")}></div>}
      <div className={classes.icon + (specificClass ? " " + specificClass : "")}>{icon}</div>
    </div>
  );

  if (richTooltip) {
    return (
      <RichTooltip className={className} title={tooltip || getTooltip(status)} placement="right">
        {content}
      </RichTooltip>
    );
  } else {
    return (
      <Tooltip className={className} title={tooltip || getTooltip(status)} placement="right">
        {content}
      </Tooltip>
    );
  }
}

export default withStyles(styles)(VehicleIcon);

/////////////////////////////////////////////////////
/// AUXILIARS

const getIcon = (asset) => {
  if (asset?.maintenance) {
    return <MaintenanceIcon />;
  }
  if (asset?.babySeat) {
    return <BabyCarIcon />;
  }
  switch (asset?.assetTypeName) {
    case "CAR":
      return <CarIcon />;
    case "KICKSCOOTER":
      return <KickScooterIcon />;
    case "SCOOTER":
      return <ScooterIcon />;
    default:
      break;
  }
  return <UndefinedIcon />;
};

export const getTooltip = (status) => {
  switch (status) {
    case "DEACTIVATED":
      return "Deactivated";
    case "IN_USE":
      return "On trip";
    case "UNUSED":
      return "Unused";
    case "AVAILABLE":
      return "Available";
    case "UNAVAILABLE":
      return "Unavailable";
    default:
      return "Undefined";
  }
};
