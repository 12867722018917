export const styles = () => ({
  root: {},
  infoIcon: {
		color: '#009AC9',
		width: 17,
		height: 17,
	},
  feesDetails: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'left',
	},
  errorContainer: {
		color: '#47525E',
	},
});
