import React from 'react';
import {
	Tooltip,
	withStyles,
} from '@material-ui/core';
import {
	ExpandLess as MoveUpIcon,
	ExpandMore as MoveDownIcon,
	RemoveCircleOutline as RemoveIcon,
} from '@material-ui/icons';
import withPermission from '../../../../../../hoc/withPermission';
import ListItem from '../../../../../UI/List/ListItem/ListItem';
import styles from './Item.styles';
import classnames from 'classnames';

const Item = ({
	classes,
	fields,
	onRemove,
	onMoveTask,
	task = {},
	editMode,
}) => {

	const moveUpButton = (
		<ButtonWithPermission>
			<MoveUpIcon
				className={classnames(classes.moveIcon, !editMode && classes.disabledIcon)}
				onClick={() => onMoveTask(task, -1)}
			/>
		</ButtonWithPermission>
	);

	const moveDownButton = (
		<ButtonWithPermission>
			<MoveDownIcon
				className={classnames(classes.moveIcon, !editMode && classes.disabledIcon)}
				onClick={() => onMoveTask(task, 1)}
			/>
		</ButtonWithPermission>
	);

	const removeButton = (
		<ButtonWithPermission>
			<Tooltip title='Remove workorder' placement='left'>
				<RemoveIcon
					className={classnames(classes.removeIcon, !editMode && classes.disabledIcon)}
					onClick={() => onRemove(task)}
				/>
			</Tooltip>
		</ButtonWithPermission>
	);

	const actions = (
		<>
			{removeButton}
			{moveDownButton}
			{moveUpButton}
		</>
	);

	return (
		<ListItem
			fields={fields}
			className={classes.row}
			element={{
				...task,
				actions,
			}}
		/>
	);
};

export default withStyles(styles)(Item);

const ButtonWithPermission = withPermission(
	[{ resource: ['workOrders', 'edit'] }]
)(({
	children,
}) =>
	(
		<>
			{children}
		</>
	)
);