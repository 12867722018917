import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const carclubInsertLicenseCall = async (
  { carclubActiveLicenseLocation },
  dispatch,
  state,
  { idList }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "CARCLUB/INSERT_LICENSE");
  let result;
  try {
    result = await wsCall({
      type: "CARCLUB",
      subType: "INSERT_LICENSE",
      locationId: selectedLocation?.id,
      message: {
        idList
      },
    });

    if (!result.error) {
      dispatch(setSuccess("New license saved."));
      dispatch(carclubActiveLicenseLocation());
    } else {
      rh.error(result?.description || "Error updating new license.");
    }
  } catch (_ex) {
    rh.error("Error updating new license.");
  }
  rh.close();
  return result;
};

export default carclubInsertLicenseCall;
