import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AssetApp from "./AssetApp";
import useGoogleMap from "../../hooks/useGoogleMap";
import geofenceTypes from "../../model/Geofence/geofenceTypes";

import logger from "../../utils/logger";
import { clearActivityLogs, clearHeartbeats } from "../../features/bigdata/bigdataSlice";
import {
  selectAssets,
  selectMapCenter,
  selectSelectedAssetToken,
  selectShowList,
  setShowList,
  setMapCenter as setMapCenterSlice,
  selectSelectedAsset,
} from "../../features/asset/assetSlice";
import { selectSelectedAlert } from "../../features/alerts/alertsSlice";
import { selectDefaultLocation } from "../../features/carclub/carclubSlice";
import * as parksActions from "../../store/actions/parks/action";

const AssetAppWrapper = (props) => {
  const assets = useSelector(selectAssets);
  const parksList = useSelector((state) => state.getIn(["parks", "elements"]).toList());
  const showList = useSelector(selectShowList);

  const selectedAssetToken = useSelector(selectSelectedAssetToken);
  const selectedAlert = useSelector(selectSelectedAlert);

  const dispatch = useDispatch();

  // Labels strings must match the subtab buttons text
  const workspaces = {
    ASSET: { id: "ASSET", label: "List Assets" },
    PARK: { id: "PARK", label: "List Parks" },
    ZONE: { id: "ZONE", label: "List Zones" },
    LOCATIONS: { id: "LOCATIONS", label: "List Locations" },
  };
  const [workspace, setWorkspace] = useState(workspaces.ASSET);
  // const [selectedAsset, setSelectedAsset] = useState({});
  const selectedAsset = useSelector(selectSelectedAsset);
  //const [mapCenter, setMapCenter] = useState();
  const mapCenter = useSelector(selectMapCenter);
  const setMapCenter = (value) => {
    dispatch(setMapCenterSlice(value));
  };

  const googleMap = useGoogleMap();

  const polygonRedrawerHandler = (polygon, fitBounds = false) =>
    polygonRedrawer(polygon, googleMap.showPolygon, fitBounds);

  const location = useSelector(selectDefaultLocation);

  useEffect(() => {
    if (selectedAlert) {
      console.log("selectedAlert: %o", selectedAlert);
      let cat = (selectedAlert?.category || "").toUpperCase();

      if (cat === "DEVICE" || cat === "RESERVATION") {
        if (selectedAlert?.payload?.latitude && selectedAlert?.payload?.longitude) {
          setMapCenter({
            lat: selectedAlert?.payload?.latitude,
            lng: selectedAlert?.payload?.longitude,
          });
        } else {
          let asset = assets.find((item) => item.assetToken === selectedAssetToken);
          console.log("asset: %o", asset);
          if (asset) {
            setMapCenter({
              lat: asset.lat || asset.latitude,
              lng: asset.lng || asset.longitude,
            });
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [selectedAlert]);

  useEffect(() => {
    dispatch(parksActions.requestParks());

    if (!location) {
      return;
    }

    setMapCenter({
      lat: location.latitude,
      lng: location.longitude,
    });
    //eslint-disable-next-line
  }, []);

  const polygonPainter = useCallback(
    (polygon, fitBounds = false, polygonOnClick, keepDrawing) => {
      const { code, geoInformation, type } = polygon || {};

      googleMap.state.isDrawing && !keepDrawing && googleMap.setIsDrawing(false);
      googleMap.showPolygon(code, geoInformation, polygonColors(type), fitBounds, polygonOnClick);
    },
    [googleMap]
  );

  const clearMapOfPolygons = () => {
    try {
      googleMap.clearPolygonsFromMap();
    } catch (error) {
      logger.warn("Failed to clear map of polygons", error);
    }
  };

  useEffect(() => {
    if (selectedAssetToken === selectedAsset?.assetToken) {
      const asset = assets.find((asset) => {
        return asset?.assetToken === selectedAssetToken;
      });
      if (asset && asset.refreshPos) {
        // setSelectedAsset(asset);

        if (!mapCenter || mapCenter.lat !== asset.latitude || mapCenter.lng !== asset.longitude) {
          setMapCenter({
            lat: asset.latitude,
            lng: asset.longitude,
          });
        }
      }
    }
    if (
      selectedAssetToken === selectedAsset?.assetToken ||
      (!selectedAssetToken && typeof selectedAsset?.assetToken === "undefined")
    ) {
      return;
    }
    try {
      const result = assets.find((asset) => {
        return asset?.assetToken === selectedAssetToken;
      });
      // setSelectedAsset(result || {});
      if (result?.parkId) {
        const park = parksList.find((el) => el?.id === result?.parkId);
        if (park) {
          polygonPainter(park);
        }
      }
    } catch (error) {
      logger.warn("Failure to draw asset's park.");
    }
    //eslint-disable-next-line
  }, [selectedAssetToken, assets, parksList]);

  useEffect(() => {
    dispatch(clearHeartbeats());
    dispatch(clearActivityLogs());
    // eslint-disable-next-line
  }, [selectedAssetToken]);

  useEffect(() => {
    googleMap.removePolygons();
    googleMap.state.isDrawing && googleMap.setIsDrawing(false);
    /**
     * FIXME:
     *  this is used to remove polygons from map, on workspace change.
     * custom reducers will always re-render
     * @googleMap should not be a simple custom reducer,
     * it either needs a useRef or useCallback, etc.. */
    //eslint-disable-next-line
  }, [workspace]);

  return (
    <AssetApp
      mapCenter={mapCenter}
      setMapCenter={(pos) => setMapCenter(pos)}
      clearMapOfPolygons={clearMapOfPolygons}
      createPolygon={googleMap.drawPolygon}
      hidePolygon={googleMap.removePolygon}
      hidePolygons={googleMap.removePolygons}
      location={location}
      onGoogleAPILoaded={(google) => {
        googleMap.setupGoogle(google);
      }}
      polygonPainter={polygonPainter}
      polygons={googleMap.state.polygons}
      setWorkspace={(workspace) => workspaces[workspace.id] && setWorkspace(workspaces[workspace.id])}
      workspace={workspace}
      workspaces={workspaces}
      polygonPainterIsDrawing={{
        state: googleMap.state.isDrawing,
        setOn: () => googleMap.setIsDrawing(true),
        setOff: () => googleMap.setIsDrawing(false),
      }}
      polygonRedrawer={polygonRedrawerHandler}
      selectedAssetToken={selectedAssetToken}
      parksList={parksList}
      assets={assets}
      showList={showList}
      setShowList={(value) => dispatch(setShowList(value))}
      selectedLocation={location}
      {...props}
    />
  );
};

export default AssetAppWrapper;

const polygonColors = (category) =>
  ({
    [geofenceTypes.NORMAL]: {
      fillColor: "#00EE00",
      fillOpacity: 0.2,
      strokeColor: "#00EE00",
      strokeOpacity: 0.8,
    },
    [geofenceTypes.UNWANTED]: {
      fillColor: "#EEEE00",
      fillOpacity: 0.2,
      strokeColor: "#EEEE00",
      strokeOpacity: 0.8,
    },
    [geofenceTypes.LOCATION]: {
      fillColor: "#1AC",
      fillOpacity: 0.2,
      strokeColor: "#1AC",
      strokeOpacity: 0.8,
    },
    [undefined]: {},
  }[category]);

const polygonRedrawer = (polygon, painter, fitBounds = false) => {
  const { code, geoInformation, type } = polygon || {};

  painter(code, geoInformation, { ...polygonColors(type), editable: true }, fitBounds);
};

export let GoogleMapContext;
