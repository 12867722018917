import React from "react";
import { Tooltip } from "@material-ui/core";
import { useDateTime } from "../../hooks";

function EventMarker({ event }) {
  const { toTimeZone } = useDateTime();

  // console.log('event: %o', event);

  const tooltip = parseEventDesignation(event) + " - " + toTimeZone(event?.comms?.create_date).format("YYYY-MM-DD HH:mm:ss");
  return (
    <Tooltip title={tooltip}>
      <div
        style={{
          backgroundColor: "#444444",
          height: 10,
          width: 10,
          borderRadius: 5,
          marginLeft: -5,
          marginTop: -5,
          cursor: "pointer",
        }}
      ></div>
    </Tooltip>
  );
}

export default EventMarker;

const parseEventDesignation = (event) => {
  if (!event || !event.comms) {
    return "";
  }

  if (event.comms?.commType === "ALARM") {
    switch (event.comms?.alarmDescription) {
      case "DEVICE DISCONNECTED":
        return "Disconnected";
      case "STATUS BATTERY":
        return "Battery <12V";
      default:
        return event.comms?.alarmDescription;
    }
  }

  const type = event.comms?.last_command_type;
  const subType = event.comms?.last_command_sub_type;

  switch (type) {
    case "ACTIVATE_DEVICE":
      return `Device ${subType === "ACTIVATE" ? "Activate" : "Deactivate"}`;
    case "ENGINE":
      return subType === "START" ? "Engine Starts" : "Engine Stops";
    case "PARK":
      return subType === "ON" ? "Start Parking" : "Stop Parking";
    case "IMMOBILIZER":
      return subType === "ON" ? "Immobilizer ON" : "Immobilizer OFF";
    case "DOORS":
      return subType === "OPEN" ? "Doors Unlocked" : "Doors Locked";
    case "LOCKER":
      return subType === "UNLOCK" ? "Doors Unlocked" : "Doors Locked";
    case "STATUS" && subType === "BATTERY":
      return "Battery <12V";
    default:
      return `${type} ${subType}`;
  }
};
