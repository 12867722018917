import createRole from './createRole';
import createUser from './createUser';
import enableUser from './enableUser';
import getCarclubConfigs from './getCarclubConfigs';
import getFleetConfigurations from './getFleetConfigurations';

import getDetails from './getDetails';
import getRoles from './getRoles';
import getUserLocations from './getUserLocations';
import getUsers from './getUsers';
import removeRole from './removeRole';
import removeUser from './removeUser';
import resetUserPassword from './resetUserPassword';
import setFleetConfigurations from './setFleetConfigurations';

import updateCarclubConfigs from './updateCarclubConfigs';

import updateRole from './updateRole';
import updateUser from './updateUser';

const carclubService = {
	createRole,
	createUser,
	enableUser,
	getCarclubConfigs,
	getFleetConfigurations,

	getRoles,
	getUserLocations,
	getUsers,
	getDetails,
	removeRole,
	removeUser,
	resetUserPassword,
	setFleetConfigurations,
	updateCarclubConfigs,

	updateRole,
	updateUser,
};

export default carclubService;