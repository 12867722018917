import React, { useEffect } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";

import { styles } from "./AssetStateCounter.styles";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { assetAssetReservationCounts, selectAssetsCounters } from "../assetSlice";
import { selectDefaultLocation } from "../../carclub/carclubSlice";
import AssetCounter from "./AssetCounter/AssetCounter";

function AssetStateCounter({ classes }) {
  const dispatch = useDispatch();
  const selectedLocation = useSelector(selectDefaultLocation);
  const counters = useSelector(selectAssetsCounters);

  useEffect(() => {
    dispatch(assetAssetReservationCounts());
    // eslint-disable-next-line
  }, [selectedLocation]);

  return (
    <Grid container direction="column" className={classes.root} spacing={1}>
      <Grid item>
        <AssetCounter label="Available" counter={counters?.assetsNotInReservation} color="#0a6" />
      </Grid>
      <Grid item>
        <AssetCounter label="Booked" counter={counters?.assetsInIdleState} color="#ee0" />
      </Grid>
      <Grid item>
        <AssetCounter label="On Trip" counter={counters?.assetsInReadyState} color="#08c" />
      </Grid>
      <Grid item>
        <AssetCounter label="Unavailable" counter={counters?.assetsUnavailable} color="#f43" />
      </Grid>
      <Grid item>
        <AssetCounter label="Deactivated" counter={counters?.notOperationalAssets} color="#000" />
      </Grid>
      <Grid item>
        <Grid
          className={classes.totalGrid}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography className={classes.total}>Total</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.total}>{counters?.total}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(AssetStateCounter);
