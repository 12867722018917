import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Modal as MuiModal } from "@material-ui/core";

import { styles } from "./ImageModal.styles";

function ImageModal({ classes, url, open, setOpen }) {
  return (
    <MuiModal open={open} onClose={() => setOpen(false)}>
      <div className={classes.root} onClick={() => setOpen(false)}>
        <div className={classes.frame}>
          <img src={url} alt="damage" className={classes.img} />
        </div>
      </div>
    </MuiModal>
  );
}

export default withStyles(styles)(ImageModal);
