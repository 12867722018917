import CarclubConfigs from "../../../../model/Carclub/CarclubConfigs";
import CarclubConfigsDTO from "../../../../model/Carclub/CarclubConfigsDTO";

//TODO finish this mapper
const carclubConfigsMapper = {
  fromDTO: ({
    BILLING,
    BRAINTREE,
    CARCLUB_INFO,
    CONFIGURATION,
    DEFAULT_LOCATION,
    INVOICE_EXPRESS,
    IYZICO,
    // LOCATIONS,
    PAYFORT,
    REGISTRATION,
    SECRETS,
    STRIPE,
    TRIP,
    NONE,
  }) => {
    const paymentGWs = {
      BRAINTREE,
      IYZICO,
      PAYFORT,
      STRIPE,
      NONE,
    };

    if (typeof STRIPE === "undefined") {
      delete paymentGWs.STRIPE;
    }
    if (typeof IYZICO === "undefined") {
      delete paymentGWs.IYZICO;
    }
    if (typeof PAYFORT === "undefined") {
      delete paymentGWs.PAYFORT;
    }
    if (typeof BRAINTREE === "undefined") {
      delete paymentGWs.BRAINTREE;
    }
    if (typeof NONE === "undefined") {
      delete paymentGWs.NONE;
    }

    const invoiceGWs = {
      INVOICE_EXPRESS,
      NONE,
    };

    if (typeof INVOICE_EXPRESS === "undefined") {
      delete invoiceGWs.INVOICE_EXPRESS;
    }

    if (typeof INVOICE_EXPRESS === "undefined") {
      delete invoiceGWs.NONE;
    }

    return new CarclubConfigs({
      ...CARCLUB_INFO,
      BILLING,
      CONFIGURATION,
      DEFAULT_LOCATION,
      invoiceGWs,
      paymentGWs,
      SECRETS,
      REGISTRATION,
      originalConfigs: {
        BILLING,
        BRAINTREE,
        CARCLUB_INFO,
        REGISTRATION,
        CONFIGURATION,
        DEFAULT_LOCATION,
        INVOICE_EXPRESS,
        // LOCATIONS,
        SECRETS,
        STRIPE,
        IYZICO,
      },
      TRIP,
    });
  },

  toDTO: ({
    activateGoldpinAfter,
    address,
    blockNewTripForSameCar,
    allowDifferentEndPark,
    chargeGracePeriod,
    timeoutAutomaticTripClosure,
    sendNotificationsToClient,
    bonusCondition,
    bonusConditionWithoutReferral,
    BRAINTREE_btEnvironment,
    BRAINTREE_merchantAccount,
    BRAINTREE_merchantId,
    BRAINTREE_privateKey,
    BRAINTREE_publicKey,
    captivationPeriod,
    colorButtons,
    colorHeader,
    comercialDesignation,
    country,
    countryCodeOfficialPhone,
    countryCodePhone,
    defaultLocation,
    email,
    expireUnusedTripAfter,
    expireUnusedPlannedTripAfter,
    percFleetPlannedTrip,
    maxFutureDaysPlannedTrip,
    thresholdAssignPlannedTrip,
    warningBattery,
    criticalBattery,
    warningUnused,
    criticalUnused,
    warningRental,
    criticalRental,
    warningGeofence,
    gateway,
    GDPDR,
    googleMapsAPIKey,
    INVOICE_EXPRESS_apiCode,
    INVOICE_EXPRESS_apiKey,
    INVOICE_EXPRESS_country,
    INVOICE_EXPRESS_taxCode,
    INVOICE_EXPRESS_value,
    invoiceGWs,
    IYZICO_apiKey,
    IYZICO_baseUrl,
    IYZICO_secretKey,
    lateDelivery,
    logoFileLink,
    logoFile,
    longTripTime,
    minimumCaptivationAmount,
    officialPhone,
    originalConfigs,
    paymentGWs,
    personalEmail,
    personalNumber,
    phoneNumber,
    pricingEngine,
    referrerBonusCondition,
    refundFee,
    reservationBond,
    reservationBondAlert,
    reset,
    reviseOnClose,
    smsNotification,
    operationType,
    damageReportStatus,
    periodicRefreshInterval,
    S3AccessKey,
    S3AccessSecretKey,
    region_config,
    bucket,
    STRIPE_publicKey,
    STRIPE_secretKey,
    taxNumber,
    termsAndConditions,
    website,
    withoutCapture,
    withoutReferralBonusConfig,
    withoutReferralBonusExpire,
    withoutReferralFeeConfig,
    withReferralBonusConfig,
    withReferralBonusConfigForReferrer,
    withReferralBonusExpire,
    withReferralBonusExpireForReferrer,
    withReferralFeeConfig,
    invoiceTemplateUrl,
    appName,
    appVersions,
    hostUrl,
    hostKey,
    awsS3_provider,
    awsS3_key,
    awsS3_secret,
    awsS3_region,
    awsS3_private_bucket,
    awsS3_public_bucket,
    awsS3_invoice_bucket,
    awsS3_endtrip_bucket,
    awsS3_app_bucket,
    assetUnavailableWithCriticalBattery,
    holdAmount,
    warningSpeed,
    invoiceGateway,
  }) =>
    new CarclubConfigsDTO({
      activateGoldpinAfter,
      address,
      blockNewTripForSameCar,
      allowDifferentEndPark,
      chargeGracePeriod,
      timeoutAutomaticTripClosure,
      sendNotificationsToClient,
      bonusCondition,
      bonusConditionWithoutReferral,
      BRAINTREE_btEnvironment,
      BRAINTREE_merchantAccount,
      BRAINTREE_merchantId,
      BRAINTREE_privateKey,
      BRAINTREE_publicKey,
      captivationPeriod,
      colorButtons,
      colorHeader,
      comercialDesignation,
      country,
      countryCodeOfficialPhone,
      countryCodePhone,
      defaultLocation,
      email,
      expireUnusedTripAfter,
      expireUnusedPlannedTripAfter,
      percFleetPlannedTrip,
      maxFutureDaysPlannedTrip,
      thresholdAssignPlannedTrip,
      warningBattery,
      criticalBattery,
      warningUnused,
      criticalUnused,
      warningRental,
      criticalRental,
      warningGeofence,
      gateway,
      GDPDR,
      googleMapsAPIKey,
      INVOICE_EXPRESS_apiCode,
      INVOICE_EXPRESS_apiKey,
      INVOICE_EXPRESS_country,
      INVOICE_EXPRESS_taxCode,
      INVOICE_EXPRESS_value,
      invoiceGWs,
      IYZICO_apiKey,
      IYZICO_baseUrl,
      IYZICO_secretKey,
      lateDelivery,
      logoFileLink: logoFileLink ? logoFileLink : logoFile,
      longTripTime,
      minimumCaptivationAmount,
      officialPhone,
      originalConfigs,
      paymentGWs,
      personalEmail,
      personalNumber,
      phoneNumber,
      pricingEngine,
      referrerBonusCondition,
      refundFee,
      reservationBond,
      reservationBondAlert,
      reset,
      reviseOnClose,
      smsNotification,
      operationType,
      damageReportStatus,
      periodicRefreshInterval,
      S3AccessKey,
      S3AccessSecretKey,
      region_config,
      bucket,
      STRIPE_publicKey,
      STRIPE_secretKey,
      taxNumber,
      termsAndConditions,
      website,
      withoutCapture,
      withoutReferralBonusConfig,
      withoutReferralBonusExpire,
      withoutReferralFeeConfig,
      withReferralBonusConfig,
      withReferralBonusConfigForReferrer,
      withReferralBonusExpire,
      withReferralBonusExpireForReferrer,
      withReferralFeeConfig,
      invoiceTemplateUrl,
      appName,
      appVersions,
      hostUrl,
      hostKey,
      awsS3_provider,
      awsS3_key,
      awsS3_secret,
      awsS3_region,
      awsS3_private_bucket,
      awsS3_public_bucket,
      awsS3_invoice_bucket,
      awsS3_endtrip_bucket,
      awsS3_app_bucket,
      assetUnavailableWithCriticalBattery,
      holdAmount,
      warningSpeed,
      invoiceGateway,
    }),
};

export default carclubConfigsMapper;
