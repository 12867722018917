import React, { Fragment } from 'react';
import {
	KeyboardArrowUp as Chevron,
} from '@material-ui/icons';
import classnames from 'classnames';
import Detail from './Detail';
import DetailsDiv from '../../../UI/DetailsDiv/DetailsDiv';
import DetailsRow from '../../../UI/DetailsDiv/DetailsRow/DetailsRow';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import styles from './Details.styles';
import { dateFormatter } from '../../../../services/formatter';

const Documents = ({
	classes,
	info,
	openLink,
	setOpen,
	showFiles,
}) => {
	const details = info.map(({ type, number, expirationDate, front, back, documentUrl, emissionDate }, index) => {
		const link = front || documentUrl;
		const detail = (
			<DetailsRow
				label={type}
				detail={
					<Detail detail={number} />
				}
			/>
		);

		const expireDate = expirationDate && (
			<DetailsRow
				label='Expiration date'
				detail={
					<Detail detail={dateFormatter.format(expirationDate, 'date')} />
				}
			/>
		);

		const issueDate = emissionDate && (
			<DetailsRow
				label='Emission date'
				detail={
					<Detail detail={dateFormatter.format(emissionDate, 'date')} />
				}
			/>
		);

		const documentFront = !!link && (
			<DetailsRow
				label='Front'
				detail={
					<Typography
						className={classes.document}
						onClick={() => openLink(link)}
					>
						download
					</Typography>
				}
			/>
		);

		const documentBack = !!back && (
			<DetailsRow
				label='Back'
				detail={
					<Typography
						className={classes.document}
						onClick={() => openLink(back)}
					>
						download
					</Typography>
				}
			/>
		);

		const attachmentLabel = (back || link) && (
			<Typography className={classes.attachments} onClick={() => setOpen(type)}>
				Attachments
				<Chevron
					className={classnames(classes.chevron, showFiles.includes(type)
						&& classes.open)}
				/>
			</Typography>
		);

		const files = (back || link) && (
			<div
				className={classnames(classes.files, !showFiles.includes(type) && classes.noDisplay)}
				style={{ '--height': (!!back + !!link) * 20 + 'px' }}
			>
				{documentFront}
				{documentBack}
			</div>
		);

		return (
			<Fragment key={`documents_${index}`}>
				{detail}
				{issueDate}
				{expireDate}
				{attachmentLabel}
				{files}
			</Fragment>
		);
	});

	const documents = (
		<DetailsDiv title='Documents'>
			{details}
		</DetailsDiv>
	);

	return documents;
};

export default withStyles(styles)(Documents);