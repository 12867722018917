import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const deviceRemoveTeltonikaCall = async ({ deviceListTeltonika }, dispatch, state, { serialNumber, imei }) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "DEVICE/REMOVE_TELTONIKA");
  try {
    const result = await wsCall({
      type: "DEVICE",
      subType: "REMOVE_TELTONIKA",
      locationId: selectedLocation?.id,
      message: {
        serialNumber,
        imei,
      },
    });
    if (result && !result.error) {
      dispatch(setSuccess("Teltonika Device Removed successfully"));
      dispatch(deviceListTeltonika({ clear: true }));
    } else {
      rh.error(result?.description || "Error removing device!");
    }
  } catch (_ex) {
    rh.error("Error removing device!");
  }
  rh.close();
};

export default deviceRemoveTeltonikaCall;
