import React from "react";
import Button from "../../../../UI/Buttons/CommonButtons";
import ErrorDiv from "../../../../ErrorDiv/ErrorDiv";
import { Drawer, Typography, withStyles } from "@material-ui/core";
import styles from "./Configurations.styles";
import classnames from "classnames";
import { TextInput, SwitchInput } from "../../../../../common/inputs";

const Configurations = ({
  classes,
  className,
  errorMessage,
  header,
  hasRequest,
  onCancel,
  onChange,
  open,
  onSave,
  placeholder,
}) => {
  const configurations = (
    <Drawer anchor="right" onClose={hasRequest ? () => {} : onCancel} open={open}>
      <div className={classnames(classes.root, className)}>
        <Typography className={classes.title}>{header}</Typography>
        <div className={classes.form}>
          <SwitchInput
            label="Send emails"
            value={Boolean(placeholder?.active)}
            onChange={(value) => onChange({ name: "active", value })}
          />
          <TextInput
            label="Email"
            value={placeholder?.email || ""}
            onChange={(value) => onChange({ name: "email", value })}
          />
          <TextInput
            label="Host"
            value={placeholder?.host || ""}
            onChange={(value) => onChange({ name: "host", value })}
          />
          <TextInput
            label="Port"
            value={placeholder?.portal || ""}
            onChange={(value) => onChange({ name: "portal", value })}
          />
          <TextInput
            label="User"
            value={placeholder?.username || ""}
            onChange={(value) => onChange({ name: "username", value })}
          />
          <TextInput
            label="Password"
            type="password"
            value={placeholder?.password || ""}
            onChange={(value) => onChange({ name: "password", value })}
          />
        </div>
        {!!errorMessage && <ErrorDiv message={errorMessage} />}
        <div className={classes.buttons}>
          <Button buttonStyleName="void" disabled={Boolean(hasRequest)} label="Cancel" onClick={onCancel} />
          <Button buttonStyleName="filled" disabled={Boolean(hasRequest)} label="Save" onClick={onSave} />
        </div>
      </div>
    </Drawer>
  );

  return configurations;
};

export default withStyles(styles)(Configurations);
