export const styles = () => ({
  root: {},
  button: {
    fontSize: 9,
    minWidth: 90,
    minHeight: 25,
  },
  amount: {
    fontSize: 14,
    fontWeight: "bold",
  },
});
