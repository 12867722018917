import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid, Button } from "@material-ui/core";

import { styles } from "./ReservationActions.styles";
import { useSelector } from "react-redux";
import { selectSelectedTrip, selectActionIsLoading } from "../../reservationsSlice";
import ActivateButton from "./ActivateButton/ActivateButton";
import CloseButton from "./CloseButton/CloseButton";
import ParkOnButton from "./ParkOnButton/ParkOnButton";
import ParkOffButton from "./ParkOffButton/ParkOffButton";
import CancelButton from "./CancelButton/CancelButton";
import ExtendButton from "./ExtendButton/ExtendButton";
import { isSubscription } from "../../../../common/utils/OTypes";
import { selectDefaultLocation } from "../../../carclub/carclubSlice";

function ReservationActions({ classes, onUpdateClick, actions }) {
  const reservation = useSelector(selectSelectedTrip);
  const selectedLocation = useSelector(selectDefaultLocation);

  let { state, parkOn, advanced, operationType } = reservation;

  // normalization of state, to remove in the future
  if (state === "ONGOING") {
    state = "STARTED";
  }
  if (state === "READY") {
    state = "IDLE";
  }

  const isLoading = useSelector(selectActionIsLoading);

  const onUpdateAdvanceHandler = () => {
    onUpdateClick && onUpdateClick();
  };

  console.log("ReservationActions - reservation: %o", reservation);

  return (
    <Grid container className={classes.root} direction="column" spacing={1}>
      <Grid item>
        <Typography className={classes.title}>Actions</Typography>
      </Grid>
      <Grid item>
        <Grid container direction="row" justifyContent="space-evenly">
          {/* UPDATE */}
          {(state === "SCHEDULED" || (isSubscription(selectedLocation.operationType) && ["IDLE", "STARTED"].some(a => a === state))) && (
            <Grid item>
              <Button variant="contained" color="primary" onClick={onUpdateAdvanceHandler} disabled={isLoading}>
                Update
              </Button>
            </Grid>
          )}
          {/* CANCEL ADVANCED */}
          {state === "SCHEDULED" && (
            <Grid item>
              <CancelButton reservation={reservation} actions={actions} />
            </Grid>
          )}

          {/* CANCEL */}
          {state === "IDLE" && (
            <Grid item>
              <CancelButton reservation={reservation} actions={actions} />
            </Grid>
          )}

          {/* ACTIVATE */}
          {(state === "IDLE" || (state === "SCHEDULED" && isSubscription(operationType))) && (
            <Grid item>
              <ActivateButton reservation={reservation} actions={actions} />
            </Grid>
          )}

          {/* END_TRIP */}
          {state === "STARTED" && (
            <Grid item>
              <CloseButton reservation={reservation} actions={actions} />
            </Grid>
          )}

          {/* PARK ON */}
          {state === "STARTED" && !parkOn && (
            <Grid item>
              <ParkOnButton reservation={reservation} actions={actions} />
            </Grid>
          )}

          {/* PARK OFF */}
          {state === "STARTED" && parkOn && (
            <Grid item>
              <ParkOffButton reservation={reservation} actions={actions} />
            </Grid>
          )}

          {/* EXTEND */}
          {advanced && state === "STARTED" && !isSubscription(selectedLocation.operationType) && (
            <Grid item>
              <ExtendButton reservation={reservation} actions={actions} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(ReservationActions);
