import React from "react";
import { NavItem, NavLink, Tooltip } from "reactstrap";
import classnames from 'classnames';

class TabLink extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			tooltipOpen: false,
		};
	}

	toggle() {
		this.setState({
			tooltipOpen: !this.state.tooltipOpen,
		});
	}

	render() {
		return (
			<NavItem>
				<NavLink className={classnames({ active: this.props.active })}
					onClick={() => { this.props.onSelected(); }} >
					<span id={'tooltip_' + this.props.title}>{this.props.title}</span>
					<Tooltip placement="top" isOpen={this.state.tooltipOpen} target={'tooltip_' + this.props.title} toggle={this.toggle}>
						{this.props.tooltip}
					</Tooltip>
				</NavLink>
			</NavItem>
		);
	}
}

export default TabLink;