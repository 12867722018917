import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, List, ListItem, Checkbox } from "@material-ui/core";

import { styles } from "./_base.styles";

function CheckListInput({ classes, label, options, value, onChange, disabled }) {
  const onClickHandler = (val) => {
    let nValues;
    if ((value || []).find((v) => v === val)) {
      nValues = (value || []).filter((v) => v !== val);
    } else {
      nValues = [...value, val];
    }
    onChange && onChange(nValues);
  };
  return (
    <div className={classes.root}>
      {typeof label === "string" ? <Typography>{label}</Typography> : label}
      <List>
        {(options || []).map((item) => (
          <ListItem button key={`list_item_${item.value}`} onClick={() => onClickHandler(item.value)} disableGutters>
            <Checkbox className={classes.checkbox} disabled={disabled} checked={Boolean((value || []).find((v) => v === item.value))} disableRipple color="primary" />
            {item.component}
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default withStyles(styles)(CheckListInput);
