import React from "react";
import Modal from "../../UI/Modal/Modal";
import ErrorDiv from "../../ErrorDiv/ErrorDiv";
import CommonButtons from "../../UI/Buttons/CommonButtons";
import Select from "../../UI/Select/Select";
import categories from "../../../model/Zones/ZoneTypes";
import { Typography, TextField, withStyles } from "@material-ui/core";
import withPermission from "../../../hoc/withPermission";
import styles from "./CreateZone.styles";
import classnames from "classnames";
import zonesCreateCall from "../../../features/zones/_sliceCalls/zonesCreateCall";
import { useDispatch, useSelector } from "react-redux";

const CreateZone = ({ classes, createZone, polygon, setZones, stopDrawing, defaultLocationFromUser, setXML }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { code, description, error, zoneType } = createZone.state;

  const confirmUpdateHandler = async () => {
    if (!zoneType || !description || description.trim().getLength === 0) {
      setError("Please make sure you have a description, category and type.");
      return;
    }
    let r = await zonesCreateCall(dispatch, state, { code, description, type: zoneType, polygon });
    setZones(r);
    stopDrawing();
  };

  console.log("createZone.state: %o", createZone.state);

  const { setDescription, setError, setZoneType } = createZone;

  const defaultLocationField = (
    <TextField defaultValue={defaultLocationFromUser?.name} id="defLocEditor" label="Location" disabled={true} />
  );

  const descriptionField = (
    <TextField
      key="descriptionField"
      label="Description"
      value={description}
      onChange={(event) => setDescription(event.target.value)}
      margin="normal"
    />
  );

  const selectType = (
    <Select name="Type" value={zoneType} array={Object.values(categories)} onChange={(value) => setZoneType(value)} />
  );

  const errorDiv = !!error && (
    <ErrorDiv className={{ div: classes.errorDiv, text: classes.errorText }} message={error} />
  );

  const importButton = !!code && (
    <ButtonWithPermission>
      <div className={classes.importContainer}>
        <label htmlFor="xml_import" accept=".xml, .kml" className={classes.importButton}>
          <Typography className={classes.attachmentInput}>Upload KML</Typography>
        </label>
        <input id="xml_import" onChange={(event) => setXML(event.target.files[0])} type="file" accept=".xml, .kml" />
      </div>
    </ButtonWithPermission>
  );

  const cancelButton = (
    <CommonButtons
      key="Cancel"
      buttonStyleName="void"
      onClick={() => {
        stopDrawing();
      }}
      label="Cancel"
    />
  );

  const confirmButton = (
    <CommonButtons
      key="Submit"
      buttonStyleName="filled"
      onClick={confirmUpdateHandler}
      label={code ? "Update" : "Create"}
    />
  );

  const content = (
    <div className={classes.content}>
      {errorDiv}
      {defaultLocationField}
      {selectType}
      {descriptionField}
      {importButton}
      <div className={classes.buttons}>
        {cancelButton}
        {confirmButton}
      </div>
    </div>
  );

  const modal = <Modal content={content} className={classnames(classes.root, !code && classes.reducedRootSize)} />;

  return modal;
};

const ButtonWithPermission = withPermission([{ resource: ["fleet", "zones", "create"] }])(({ children }) => (
  <>{children}</>
));

export default withStyles(styles)(CreateZone);
