import { ReactComponent as CurrentTripsIcon } from '../../assets/TripApp/current/trip_ongoing.svg';
import { ReactComponent as HistoryTripsIcon } from '../../assets/TripApp/history/trip_closed.svg';

const button = (icon, text, tag, style={ color: "gray" }, right=false) => {
	return {
		icon,
		right,
		style,
		tag,
		text,
	};
};

const menuButtons = []
	.concat(button(CurrentTripsIcon, 'Current Trips', 'CURRENT'))
	.concat(button(HistoryTripsIcon, 'History Trips', 'HISTORY'))
;

export default menuButtons;