
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import { publishAndAwait } from '../../../app/coreSlice';

export const listGeneratedCodes = async ({page, filter}) => {
	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'BILLING',
			subType: 'LIST_GENERATED_BONUSES',
			message: {
				page,
				...filter,
				id: filter?.id !== '' ? filter?.id : undefined,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}

	return processMessage;
};

export default listGeneratedCodes;