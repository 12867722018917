import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as chatActions from "../../../../store/actions/chat/action";
import chatService from "../../../../services/chat";
import Chat from "./Chat";
import log from "../../../../utils/logger";

const logger = log("ChatWrapper");

const isEmpty = (obj) => {
  for (let key in obj) {
    return !Object.prototype.hasOwnProperty.call(obj, key);
  }
};

const ChatWrapper = ({ ...props }) => {
  const { isOpen } = props;

  const [selected, setSelected] = useState({});
  const [contacts, setContacts] = useState([]);
  const [createType, setCreateType] = useState();

  const dispatch = useDispatch();

  const newMessages = useSelector((state) => state.getIn(["chat", "newMessages"]));
  const newPendingContacts = useSelector((state) => state.getIn(["chat", "pendingContacts"]));
  const newGroupInvites = useSelector((state) => state.getIn(["chat", "groupInvites"]));
  const selectedWorkorder = useSelector((state) => state.getIn(["chat", "workorder"]));

  // useEffect(() => {
  //   setCreateType();
  // }, [selected]);

  useEffect(() => {
    if (!selected || isEmpty(selected)) {
      return;
    }

    newPendingContacts?.has(selected.target) && dispatch(chatActions.removePendingContact(selected.target));

    newGroupInvites?.has(selected.groupId) && dispatch(chatActions.removeGroupInvite(selected.groupId));
    // eslint-disable-next-line
  }, [selected, newPendingContacts, newGroupInvites]);

  useEffect(() => {
    if (newPendingContacts?.size <= 0) return;

    Object.values(newPendingContacts.toJS()).forEach((contact) => {
      setContacts((state) => {
        const hasPending = state.findIndex((currContact) => currContact?.target === contact?.target);

        return hasPending < 0
          ? [...state, { ...contact, pending: true }]
          : [...state.filter((currContact) => currContact?.target !== contact?.target), { ...contact, pending: true }];
      });
    });
    // eslint-disable-next-line
  }, [newPendingContacts]);

  useEffect(() => {
    if (newGroupInvites?.size <= 0) return;

    Object.values(newGroupInvites.toJS()).forEach((group) => {
      setContacts((state) => {
        const hasInvite = state.findIndex((currContact) => currContact?.groupId === group?.groupId);
        return hasInvite < 0
          ? [...state, { ...group, name: group?.groupName, pending: true }]
          : [
              ...state.filter((currContact) => currContact?.groupId !== group?.groupId),
              { ...group, name: group?.groupName, pending: true },
            ];
      });
    });
    // eslint-disable-next-line
  }, [newGroupInvites]);

  const onMount = useCallback(async () => {
    let getContacts = [];
    try {
      const contactsResponse = await chatService.getContacts();

      getContacts = [...getContacts, ...contactsResponse];
    } catch (e) {
      logger.warn(e.message || e.description || "Could not get contacts");
    }
    try {
      const groupsResponse = await chatService.getGroups();

      getContacts = [...getContacts, ...groupsResponse];
    } catch (e) {
      logger.warn(e.message || e.description || "Could not get groups");
    }
    try {
      const pendingContactsResponse = await chatService.getPending();

      getContacts = [...getContacts, ...pendingContactsResponse];
    } catch (e) {
      logger.warn(e.message || e.description || "Could not get pending contacts");
    }

    setContacts(getContacts);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      // timeout to avoid getting info to soon, workaraound until refactoring methods to use slice instead of actions
      onMount();
    }, 1000);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isOpen || !selectedWorkorder) {
      !!selectedWorkorder && dispatch(chatActions.removeSelectedWorkorder());
      return;
    }

    const newSelect = contacts.find((contact) => contact.target === selectedWorkorder?.assignAgent);

    if (!newSelect) {
      setCreateType("CREATE");
      return;
    }

    setSelected(newSelect);
    setCreateType();
    dispatch(chatActions.removeSelectedWorkorder());
    // eslint-disable-next-line
  }, [isOpen, contacts, selectedWorkorder]);

  const onCreate = useCallback((type) => setCreateType(type), []);

  const onCreateClose = useCallback(
    (type) => {
      if (!type || !getNewContact[type]) {
        return setCreateType();
      }

      onMount();
      setCreateType();
      setSelected({});
    },
    [onMount]
  );

  return (
    <Chat
      {...props}
      contactsList={contacts}
      createType={createType}
      newMessages={newMessages}
      onCreate={onCreate}
      onCreateClose={onCreateClose}
      selected={selected}
      setContacts={setContacts}
      setSelected={setSelected}
    />
  );
};

export default ChatWrapper;

const getNewContact = {
  CREATE: true,
  CREATE_GROUP: true,
  GROUP_UPDATE: false,
  INVITE_GROUP: false,
};
