
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_REQUEST_IS_MALFORMED,
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import customerFlowMapper from '../../customer/ws/mappers/customerFlowMapper';
import { publishAndAwait } from '../../../app/coreSlice';

export const getCustomersFromPP = async id => {

	if (typeof id === 'undefined') {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	};

	if (!id) {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	};

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'PRICING_PLAN',
			subType: 'GET_CUSTOMERS',
			message: {
				pricingPlanId: id,
			},
		},
	});
	const { processMessage, error } = request.response;

	if (error) {
		return;
	}

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}

	const {
		customers,
		lastPage,
	} = processMessage || {};

	const mappedCustomers = customers && Array.isArray(customers)
		&& customers.map(customer => {
			const customerModel = customerFlowMapper.fromDTO(customer);
			return customerModel;
		});

	return { resultArray: mappedCustomers, lastPage } || null;
};

export default getCustomersFromPP;