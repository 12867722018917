import React from 'react';
import {
	InputAdornment,
	InputBase,
	withStyles,
} from '@material-ui/core';
import {
	Search as SearchIcon,
} from '@material-ui/icons';
import styles from './TypeSubtypeFilter.styles';
import classnames from 'classnames';

const filterTypes = {
	TYPE: 'type',
	SUBTYPE: 'subtype',
};

const TypeSubtypeFilter = ({
	classes,
	className,
	onSearch,
	filter,
	setFilter,
}) => {

	const searchIcon = (
		<SearchIcon
			className={classes.searchIcon}
			size="small"
		/>
	);
	
	const searchFilter = (type, label) => (
		<InputBase
			className={classes[type]}
			placeholder={label}
			value={filter[type]}
			classes={{
				root: classes.inputRoot,
				input: classes.inputInput,
			}}
			onChange={event => setFilter(type, event.target.value)}
			onKeyDown={event => {
				if (event.keyCode === 13){
				};
			}}
			endAdornment={
				<InputAdornment position="end">
					{searchIcon}
				</InputAdornment>
			}
		/>
	);

	const typeFilter = searchFilter(filterTypes.TYPE, 'Type');

	const subTypeFilter = searchFilter(filterTypes.SUBTYPE, 'Subtype');

	const filters = (
		<div className={classnames(classes.root, className)}>
			{typeFilter}
			{subTypeFilter}
		</div>
	);

	return filters;
};

export default withStyles(styles)(TypeSubtypeFilter);