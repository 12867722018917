import React from "react";
import List from "../../UI/List/List";
import TripCard from "../TripCard/TripCard";
import { Typography, withStyles } from "@material-ui/core";
import { styles } from "./TripList.styles";
import classnames from "classnames";

const TripList = ({
  classes,
  className,
  currentFilter,
  filterWarnings,
  getMoreRows,
  selectedTrip,
  separateTrips,
  setSelectedTrip,
  shouldFilterWarnings,
  trips,
}) => {

  const tripsWithWarning = [];
  let tripsArray = trips;

  if (separateTrips) {
    tripsArray &&
      tripsArray.filter((trip) => {
        if (trip.seeIfAttentionIsRequired()) {
          tripsWithWarning.push(trip);
          return false;
        }
        return true;
      });
  }

  let styledTripsAll = [];

  if (shouldFilterWarnings) {
    tripsArray = tripsArray.filter((trip) => {
      return !!trip.seeIfAttentionIsRequired();
    });
  }

  styledTripsAll =
    Array.isArray(tripsArray) &&
    tripsArray
      // .sort((b, a) => (a || {}).startDate - (b || {}).startDate || 0)
      .map((trip) => {
        return (
          <TripCard
            key={trip.code + trip.licensePlate}
            trip={trip}
            needsAttention={trip.seeIfAttentionIsRequired()}
            onClick={() => {
              console.log("SET");
              setSelectedTrip({ ...trip });
            }}
            selectedTrip={selectedTrip}
          />
        );
      });

  const styledTripsWithWarnings =
    Array.isArray(tripsArray) &&
    tripsArray
      .filter((trip) => trip.seeIfAttentionIsRequired())
      .sort((b, a) => (a || {}).startDate - (b || {}).startDate || 0)
      .map((trip) => {
        return (
          <TripCard
            className={classes.separatedTrip}
            key={trip.code + trip.licensePlate}
            trip={trip}
            needsAttention={trip.seeIfAttentionIsRequired()}
            cardMode={separateTrips}
            onClick={() => {
              console.log("SET");
              setSelectedTrip({ ...trip });
            }}
            selectedTrip={selectedTrip}
          />
        );
      });

  const styledTripsWithoutWarnings =
    Array.isArray(tripsArray) &&
    tripsArray
      .filter((trip) => !trip.seeIfAttentionIsRequired())
      .sort((b, a) => (a || {}).startDate - (b || {}).startDate || 0)
      .map((trip) => {
        return (
          <TripCard
            className={classes.separatedTrip}
            key={trip.code + trip.licensePlate}
            trip={trip}
            needsAttention={trip.seeIfAttentionIsRequired()}
            cardMode={separateTrips}
            onClick={() => {
              console.log("SET");
              setSelectedTrip({ ...trip });
            }}
            selectedTrip={selectedTrip}
          />
        );
      });

  const noTripsMessage = styledTripsWithWarnings.length === 0 &&
    styledTripsWithoutWarnings.length === 0 &&
    styledTripsAll.length === 0 && (
      <Typography className={classes.noTripsMessage}>No trips were found for this search.</Typography>
    );

  return (
    <>
      {separateTrips ? (
        <div className={classnames(classes.root, classes.sepatatedLists)}>
          {noTripsMessage}
          <List
            className={classnames(classes.root, className, classes.healthyList)}
            overridePadding={true}
            noHeader={true}
            getMoreRows={getMoreRows}
          >
            {styledTripsWithWarnings}
          </List>
          <List
            className={classnames(classes.root, className, classes.warningsList)}
            overridePadding={true}
            noHeader={true}
            getMoreRows={getMoreRows}
          >
            {styledTripsWithoutWarnings}
          </List>
        </div>
      ) : (
        <>
          {noTripsMessage}
          <List
            className={classnames(classes.root, className)}
            overridePadding={true}
            noHeader={true}
            getMoreRows={getMoreRows}
          >
            {styledTripsAll}
          </List>
        </>
      )}
    </>
  );
};

export default withStyles(styles)(TripList);
