
import listAsset from './listAsset';
import listAssociatedZones from './listAssociatedZones';
import listAvailableAsset from './listAvailableAsset';
import listAvailableCoordinates from './listAvailableCoordinates';

import updateAssetInfoOnly from './updateAssetInfoOnly';
import createAsset from './createAsset';

import createAndActivateDevice from './createAndActivateDevice';
import activateOrDeactivateAsset from './activateOrDeactivateAsset';

const assetService = {
	listAsset,
	listAssociatedZones,
	listAvailableAsset,
	listAvailableCoordinates,
	updateAssetInfoOnly,
	createAsset,
	createAndActivateDevice,
	activateOrDeactivateAsset,
};

export default assetService;

