import * as Yup from "yup";
import { NumberField, SwitchField } from "../../../../common/forms";

export function formSchema() {
  return Yup.object().shape({
    CONFIGURATION: Yup.object().shape({
      maxTime: Yup.string(),
    }),
  });
}

export function genericFormFields({ classes, disabled }) {
  return [
    {
      size: 12,
      field: (
        <NumberField
          id="CONFIGURATION.maxTime"
          decimalScale={0}
          label="Long Trip Time"
          disabled={disabled}
          endAdornment="min"
        />
      ),
    },
    {
      size: 12,
      field: (
        <NumberField
          id="TRIP.lateDelivery"
          decimalScale={0}
          label="Late Delivery"
          disabled={disabled}
          endAdornment="min"
        />
      ),
    },
    {
      size: 12,
      field: (
        <NumberField
          id="TRIP.expireUnusedTripAfter"
          decimalScale={0}
          label="Expire Unused Trip After"
          disabled={disabled}
          endAdornment="min"
        />
      ),
    },
    {
      size: 12,
      field: (
        <NumberField
          id="TRIP.blockNewTripForSameCar"
          decimalScale={0}
          label="Block New Trip For Same Car"
          disabled={disabled}
          endAdornment="min"
        />
      ),
    },
    {
      size: 12,
      field: (
        <NumberField
          id="TRIP.activateGoldpinAfter"
          decimalScale={0}
          label="Activate Gold Pin After"
          disabled={disabled}
          endAdornment="min"
        />
      ),
    },
    {
      size: 12,
      field: <SwitchField id="TRIP.allowDifferentEndPark" label="Allow Different End Park" disabled={disabled} />,
    },
    {
      size: 12,
      field: <SwitchField id="TRIP.chargeGracePeriod" label="Charge Grace Period" disabled={disabled} />,
    },
    {
      size: 12,
      field: (
        <NumberField
          id="TRIP.timeoutAutomaticTripClosure"
          decimalScale={0}
          label="Timeout value for automatic trip closure"
          disabled={disabled}
          endAdornment="min"
        />
      ),
    },
    {
      size: 12,
      field: (
        <SwitchField id="TRIP.sendNotificationsToClient" label="Send Notifications To Client" disabled={disabled} />
      ),
    },
    {
      size: 12,
      field: (
        <SwitchField
          id="TRIP.assetUnavailableWithCriticalBattery"
          label="Asset unavailable with critical battery"
          disabled={disabled}
        />
      ),
    },
  ];
}

export function plannedTripsFormFields({ classes, disabled }) {
  return [
    {
      size: 12,
      field: (
        <NumberField
          id="TRIP.expireUnusedPlannedTripAfter"
          decimalScale={0}
          label="Expire Unused Planned Trip After"
          disabled={disabled}
          endAdornment="min"
        />
      ),
    },
    {
      size: 12,
      field: (
        <NumberField
          id="TRIP.percFleetPlannedTrip"
          decimalScale={0}
          label="Percentage of fleet reservation"
          disabled={disabled}
          endAdornment="%"
        />
      ),
    },
    {
      size: 12,
      field: (
        <NumberField
          id="TRIP.maxFutureDaysPlannedTrip"
          decimalScale={0}
          label="Advanced reservation timming"
          disabled={disabled}
          endAdornment="days"
        />
      ),
    },
    {
      size: 12,
      field: (
        <NumberField
          id="TRIP.thresholdAssignPlannedTrip"
          decimalScale={0}
          label="Threshold to assign car"
          disabled={disabled}
          endAdornment="hours"
        />
      ),
    },
  ];
}

export function warningsFormFields({ classes, disabled }) {
  return [
    {
      size: 12,
      field: (
        <NumberField
          id="TRIP.warningBattery"
          decimalScale={0}
          label="Warning Battery"
          disabled={disabled}
          endAdornment="%"
        />
      ),
    },
    {
      size: 12,
      field: (
        <NumberField
          id="TRIP.criticalBattery"
          decimalScale={0}
          label="Critical Battery"
          disabled={disabled}
          endAdornment="%"
        />
      ),
    },
    {
      size: 12,
      field: (
        <NumberField
          id="TRIP.warningUnused"
          decimalScale={0}
          label="Warning Unused"
          disabled={disabled}
          endAdornment="min"
        />
      ),
    },
    {
      size: 12,
      field: (
        <NumberField
          id="TRIP.criticalUnused"
          decimalScale={0}
          label="Critical Unused"
          disabled={disabled}
          endAdornment="min"
        />
      ),
    },
    {
      size: 12,
      field: (
        <NumberField
          id="TRIP.warningRental"
          decimalScale={0}
          label="Warning Rental"
          disabled={disabled}
          endAdornment="min"
        />
      ),
    },
    {
      size: 12,
      field: (
        <NumberField
          id="TRIP.criticalRental"
          decimalScale={0}
          label="Critical Rental"
          disabled={disabled}
          endAdornment="min"
        />
      ),
    },
    {
      size: 12,
      field: (
        <NumberField
          id="TRIP.warningSpeed"
          decimalScale={0}
          label="Warning Speed"
          disabled={disabled}
          endAdornment="km/h"
        />
      ),
    },
    {
      size: 12,
      field: (
        <NumberField
          id="TRIP.warningGeofence"
          decimalScale={0}
          label="Warning Geofence"
          disabled={disabled}
          endAdornment="meters"
        />
      ),
    },
  ];
}
