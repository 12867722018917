const styles = (theme) => ({
	root: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: '100%',
		padding: '15px 0',
	},

	titleText: {
		fontSize: '20px',
		padding: '0 15px',
	},

	selectByReservationCustomer: {
		display: 'flex',
		width: '100%',
		padding: '0 15px',
		boxSizing: 'border-box',
	},

	icon: {
		cursor: 'pointer',
		marginBottom: theme.spacing(1),
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(3),
	},

	inputRoot: {
		color: 'inherit',
		width: '100%',
	},

	inputInput: {
		borderBottom: '1px solid rgba(224, 224, 224, 1)',
		marginBottom: theme.spacing(1),
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(2),
		transition: theme.transitions.create('width'),
		width: '100%',
	},

	selectEmpty: {
		marginBottom: theme.spacing(1),
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(2),
		minWidth: '90px',
	},

	warning: {
		fontSize: '14px',
		color: '#e50',
		marginTop: '10px',
	},
});

export default styles;