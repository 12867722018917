export const getCompanyCountries = () => {
  return ["France", "Germany", "Italy", "Israel", "Portugal", "Spain", "UK", "USA"].map((item) => {
    return {
      value: item,
      text: item,
    };
  });
};

export const getOperationTypeOptions = () => {
  return [
    {
      value: "S",
      text: "Shared Mobility",
    },
    {
      value: "C",
      text: "Commercial",
    },
    {
      value: "P",
      text: "Private Subscription"
    }
  ]
}
