import React from 'react';
import { hasPath, changePermissions, addAll } from './editTree';
import {
	Checkbox,
	Tooltip,
	Typography,
	withStyles,
} from '@material-ui/core';
import {
	AddBox as MoreIcon,
	IndeterminateCheckBox as MinusIcon,
	LibraryAddCheck as SelectAllIcon,
} from '@material-ui/icons';
import styles from './Tree.styles';
import classnames from 'classnames';

const textStyle = (level = 0) => ({
	textTransform: 'capitalize',
	fontSize: Math.max(16 - level * 2, 10) + 'px',
	display: 'flex',
	alignItems: 'center',
	marginLeft: '5px',
});

const Tree = ({
	allOpen,
	allPermissions,
	classes,
	className,
	onToggle,
	closed,
	permissions,
	setPermissions,
}) => {

	const listMaker = (obj, path = [], level = 0) => Object.keys(obj)
		.sort((a, b) => (a + '').localeCompare(b))
		.map(
			(key, index, array) => {
				const currentPath = [...path, key];

				if (typeof obj[key] === 'object') {

					const isOpen = !closed.includes(key);

					const iconClassName = classnames(classes.icon,
						!allOpen && classes.iconCursor);

					const iconProps = {
						className: iconClassName,
						onClick: () => onToggle(key),
					};

					const icon = isOpen
						? <MinusIcon {...iconProps} />
						: <MoreIcon {...iconProps} />;

					const allIcon = (
						<SelectAllIcon
							className={iconClassName}
							onClick={() => !!setPermissions
								&& setPermissions(addAll(currentPath, obj[key]))}
						/>
					);

					const addAllIcon = (
						<Tooltip
							title={`Select all ${key.replace(/([A-Z])/g, " $1")}`}
							placement='top'
						>
							{allIcon}
						</Tooltip>
					);

					return (
						<li key={key} className={classes.listPath}>
							<div className={classes.listTitle}>
								<Typography
									style={textStyle(level)}
								>
									{icon} {key.replace(/([A-Z])/g, " $1")}
								</Typography>
								{!allOpen && addAllIcon}
							</div>
							<ul className={classnames(classes.list,
								index !== array.length - 1 && classes.listLine)}>
								{isOpen
									&& listMaker(obj[key], currentPath, level + 1)}
							</ul>
						</li>
					);
				};

				const isChecked = !!currentPath.reduce(
					hasPath, permissions);

				const checkIcon = (
					<Checkbox
						className={classes.checkbox}
						checked={isChecked}
						color='primary'
						onChange={() =>
							!!setPermissions && setPermissions(
								changePermissions(currentPath))}
						disableRipple={true}
						disableTouchRipple={true}
						disabled={!!allOpen}
					/>
				);

				return (
					<li key={key}>
						<Typography style={{
							...textStyle(3),
							justifyContent: 'space-between',
						}}>
							{key.replace(/([A-Z])/g, " $1")} {checkIcon}
						</Typography>
					</li>
				);
			}
		);

	const list = listMaker(allPermissions);

	const all = !allOpen && (
		<Typography
			align='right'
			className={classes.all}
			onClick={() => !!setPermissions
				&& setPermissions(addAll(undefined, allPermissions))}
		>
			All <SelectAllIcon className={classes.allIcon} />
		</Typography>
	);

	return (
		<div className={classnames(classes.root, className)}>
			{all}
			<ul className={classes.mainList}>
				{list}
			</ul>
		</div>
	);
};

export default withStyles(styles)(Tree);