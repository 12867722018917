import { Typography } from "@material-ui/core";
import * as Yup from "yup";
import { TextField, CheckboxField, SelectField, NumberField, SwitchField } from "../../../../common/forms";
import { getCompanyCountries, getOperationTypeOptions } from "./General.options";

export function formSchema() {
  return Yup.object().shape({
    CARCLUB_INFO: Yup.object().shape({
      infoCompany: Yup.object().shape({
        simulator: Yup.string(),
      }),
    }),
  });
}

export function compayFormFields({ classes, disabled }) {
  return [
    {
      size: 12,
      field: (
        <TextField
          id="CARCLUB_INFO.infoCompany.comercialDesignation"
          type="text"
          label="Commercial Designation"
          disabled={disabled}
        />
      ),
    },
    {
      size: 12,
      field: (
        <SelectField
          id="CARCLUB_INFO.infoCompany.country"
          options={getCompanyCountries()}
          label="Country"
          disabled={disabled}
        />
      ),
    },
    {
      size: 12,
      field: <TextField id="CARCLUB_INFO.infoCompany.taxNumber" type="text" label="Tax Number" disabled={disabled} />,
    },
    {
      size: 12,
      field: (
        <TextField
          id="CARCLUB_INFO.infoCompany.address"
          type="text"
          label="Address"
          multiline
          rows={3}
          disabled={disabled}
        />
      ),
    },
    {
      size: 12,
      field: <Typography className={classes.subtitle}>Official Phone</Typography>,
    },
    {
      size: 4,
      field: (
        <TextField
          id="CARCLUB_INFO.infoCompany.countryCodeOfficialPhone"
          type="text"
          label="Country Code"
          disabled={disabled}
        />
      ),
    },
    {
      size: 8,
      field: (
        <NumberField id="CARCLUB_INFO.infoCompany.officialPhone" decimalScale={0} label="Number" disabled={disabled} />
      ),
    },
    {
      size: 12,
      field: <TextField id="CARCLUB_INFO.infoCompany.email" type="text" label="Email" disabled={disabled} />,
    },
    {
      size: 12,
      field: <TextField id="CARCLUB_INFO.infoCompany.website" type="text" label="Website" disabled={disabled} />,
    },
    {
      size: 12,
      field: (
        <TextField id="CARCLUB_INFO.infoUser.personalEmail" type="text" label="Contact (email)" disabled={disabled} />
      ),
    },
    {
      size: 12,
      field: <Typography className={classes.subtitle}>Contact Phone</Typography>,
    },
    {
      size: 4,
      field: (
        <TextField id="CARCLUB_INFO.infoUser.countryCodePhone" type="text" label="Country Code" disabled={disabled} />
      ),
    },
    {
      size: 8,
      field: <NumberField id="CARCLUB_INFO.infoUser.phoneNumber" decimalScale={0} label="Number" disabled={disabled} />,
    },
  ];
}

export function generalFormFields({ disabled, isDefaultConfigs }) {
  return [
    {
      size: 12,
      field: (
        <SelectField
          id="CONFIGURATION.operationType"
          options={getOperationTypeOptions()}
          label="Operation Type"
          disabled={disabled}
        />
      ),
    },
    {
      size: 12,
      field: <SwitchField id="CONFIGURATION.damageReportStatus" label="Damage Report Status" disabled={disabled} />,
    },
    {
      size: 12,
      field: <TextField id="CARCLUB_INFO.secrets.googleMapsAPIKey" type="text" label="Maps API Key" disabled={disabled} />,
      hidden: !isDefaultConfigs,
    },
    {
      size: 12,
      field: <TextField id="CARCLUB_INFO.linkApp.url" type="text" label="App link" disabled={disabled} />,
      hidden: !isDefaultConfigs,
    },
    {
      size: 12,
      field: <CheckboxField id="CARCLUB_INFO.secrets.GDPDR" label="GDPR" disabled={disabled} />,
    },
    {
      size: 12,
      field: <CheckboxField id="CONFIGURATION.smsNotification" label="SMS Notifications" disabled={disabled} />,
    },
    {
      size: 12,
      field: (
        <NumberField
          id="CONFIGURATION.periodicRefreshInterval"
          decimalScale={0}
          label="Assets periodic refresh interval"
          disabled={disabled}
          endAdornment="min"
        />
      ),
      hidden: !isDefaultConfigs,
    },
  ];
}

export function customizationFormFields({ classes, disabled }) {
  return [
    {
      size: 12,
      field: <TextField id="CARCLUB_INFO.customize.colorHeader" type="text" label="Header Color" disabled={disabled} />,
    },
  ];
}
