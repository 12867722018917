import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid, Drawer, Button } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";

import { styles } from "./LegalDocuments.styles";
import {
  carclubActiveLicenseLocation,
  carclubInsertLicense,
  carclubSearchLegalDocuments,
  selectDefaultLocation,
  selectFormSuccess,
  selectIsLoading,
  selectLegalDocumentsFilter,
} from "../../carclubSlice";
import ActiveLegalDocumentsDataGrid from "./ActiveLegalDocumentsDataGrid/ActiveLegalDocumentsDataGrid";
import DocumentForm from "./DocumentForm/DocumentForm";
import LegalDocumentsDataGrid from "./LegalDocumentsDataGrid/LegalDocumentsDataGrid";
import { SelectInput } from "../../../../common/inputs";
import { getLegalDocumentTypeOptions } from "./DocumentForm/DocumentForm.options";

function LegalDocuments({ classes }) {
  const dispatch = useDispatch();
  const loading = useSelector(selectIsLoading);
  const selectedLocation = useSelector(selectDefaultLocation);
  const filter = useSelector(selectLegalDocumentsFilter);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [configsLoaded, setConfigsLoaded] = useState(false);
  const [formDrawerOpen, setFormDrawerOpen] = useState(false);
  const [enableApply, setEnableApply] = useState(false);
  const formSuccess = useSelector(selectFormSuccess);

  useEffect(() => {
    if (!loading && configsLoaded !== selectedLocation) {
      dispatch(carclubActiveLicenseLocation());
      dispatch(carclubSearchLegalDocuments(filter, true));
      setConfigsLoaded(selectedLocation);
    }
    // eslint-disable-next-line
  }, [loading, selectedLocation]);

  useEffect(() => {
    if (formSuccess) {
      setFormDrawerOpen(false);
    }
    // eslint-disable-next-line
  }, [formSuccess]);

  const onFilterChangeHandler = (value) => {
    dispatch(carclubSearchLegalDocuments({ ...filter, type: value === "ALL" ? undefined : value }, true));
  };

  const onInsertLicenseHandler = () => {
    dispatch(carclubInsertLicense({ idList: selectedDocuments.map((item) => item.id) }));
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Active Documents</Typography>
      <ActiveLegalDocumentsDataGrid />
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <Typography className={classes.title}>All Documents</Typography>
            </Grid>
            <Grid item>
              <SelectInput
                label="Select Type"
                className={classes.typeInput}
                value={filter?.type || "ALL"}
                onChange={onFilterChangeHandler}
                options={getLegalDocumentTypeOptions(true)}
              />
            </Grid>

            {enableApply && (
              <Grid item>
                <Button color="primary" variant="contained" onClick={onInsertLicenseHandler}>
                  Apply New License
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <AddCircle onClick={() => setFormDrawerOpen(true)} className={classes.icon} elevation={2} />
        </Grid>
      </Grid>
      <LegalDocumentsDataGrid
        setEnableApply={setEnableApply}
        selectedDocuments={selectedDocuments}
        setSelectedDocuments={setSelectedDocuments}
      />
      <Drawer anchor="right" onClose={() => setFormDrawerOpen(false)} open={formDrawerOpen}>
        <DocumentForm />
      </Drawer>
    </div>
  );
}

export default withStyles(styles)(LegalDocuments);
