export const styles = theme => ({
	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
	},

	workspace: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
	},

	flexVertically: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		boxSizing: 'border-box',
	},

	title: {
		fontSize: '22px',
	},

	ratesSideDiv: {
		width: '100%',
		height: '100%',
		display: 'flex',
		alingItems: 'center',
		flexWrap: 'nowrap',
		position: 'relative',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		backgroundColor: '#F3F3F3',
		boxShadow: '-2px 0px 2px 0px rgba(0,0,0,.3)',
		padding: 8,
		boxSizing: 'border-box',
		overflowX: 'auto',
		scrollbarWidth: 'thin', //FIREFOX
		//CHROME
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},

	ratesList: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		maxHeight: '500px',
		height: 'min-content',
	},

	maxRatesList: {
	},

	errorText: {
		fontSize: '9px',
	},
});