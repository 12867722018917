import { wsCall, requestHelper } from "../../../app/coreSlice";

const alertAssignCall = async ({ updateAlertInList }, dispatch, state, { alertToken, status }) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "ALERT/ASSIGN");
  try {
    const result = await wsCall({
      type: "ALERT",
      subType: "ASSIGN",
      locationId: selectedLocation?.id,
      message: {
        alertToken,
        status,
      },
    });
    dispatch(updateAlertInList(result));
  } catch (_ex) {
    rh.error("Error assigning alert.");
  }
  rh.close();
};

export default alertAssignCall;
