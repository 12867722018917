import React from 'react';
import {
	InputBase,
	Typography,
	withStyles,
} from '@material-ui/core';
import List from '../../../UI/List/List';
import MaxFlatRateItem from './MaxFlatRateItem/MaxFlatRateItem';
import maxFlatRatesFields from './maxFlatRatesFields';
import styles from './MaxFlatRatesList.styles';
import CommonButtons from '../../../UI/Buttons/CommonButtons';
import classnames from 'classnames';

const MaxFlatRatesList = ({
	classes,
	className,
	currentScreenSize,
	maxFlatRates,
	addRateHandler,
	placeholder,
	placeholderSetters,
	removeRateHandler,
	titleClassName,
	viewOnly,
	smallList,
}) => {

	const {
		name,
		maxTime,
		maxPrice,
	} = placeholder || {};

	const {
		setName,
		setMaxPrice,
		setMaxTime,
	} = placeholderSetters || {};

	const title = (
		<Typography className={classnames(classes.title, titleClassName)}>
			Flat Rate Max Prices
		</Typography>
	);

	const inputsBar = !viewOnly && (
		<div className={classes.searchBar}>
			<InputBase
				placeholder={'Name'}
				value={name || ''}
				classes={{
					root: classes.inputRoot,
					input: classes.inputInput,
				}}
				onChange={event => setName(event.target.value)}
			/>
			<InputBase
				placeholder={'Max Time'}
				value={maxTime || ''}
				classes={{
					root: classes.inputRoot,
					input: classes.inputInput,
				}}
				onChange={event => setMaxTime(event.target.value)}
				type='number'
			/>
			<InputBase
				placeholder={'Max Price'}
				value={maxPrice || ''}
				classes={{
					root: classes.inputRoot,
					input: classes.inputInput,
				}}
				onChange={event => setMaxPrice(event.target.value)}
				type='number'
			/>
			<div className={classes.btnContainer}>
				<CommonButtons
					key='addMaxFlatRate'
					buttonClassName={classes.btn}
					buttonStyleName='filled'
					onClick={addRateHandler}
					disabled={false}
					label={'Add'}
				/>
			</div>
		</div>
	);

	const titleAndSearch = (
		<div className={classes.titleAndInputs}>
			{title}
			{inputsBar}
		</div>
	);

	const maxFlatRateRows = !!maxFlatRates && maxFlatRates.map(rate => (
		<MaxFlatRateItem
			key={rate.name + rate.maxTime + rate.maxPrice + Math.round(Math.random() * 10)}
			rate={rate}
			removeRate={removeRateHandler}
			viewOnly={viewOnly}
		/>
	));

	const list = (
		<List
			className={classes.list}
			headerFields={maxFlatRatesFields(currentScreenSize, smallList)}
			disableGutters={true}
		>
			{maxFlatRateRows}
		</List>
	);

	return (
		<div className={classnames(classes.root, className)}>
			{titleAndSearch}
			{list}
		</div>
	);
};

export default withStyles(styles)(MaxFlatRatesList);