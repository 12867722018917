import MaintenanceIcon from '@material-ui/icons/BuildRounded';
import UndefinedIcon from '@material-ui/icons/HelpOutline';
import { ReactComponent as BabyCarIcon } from '../../../assets/icons/babyicon.svg';
import { ReactComponent as CarIcon } from '../../../assets/icons/caricon.svg';
import { ReactComponent as KickScooterIcon }
	from '../../../assets/icons/kickscootericon.svg';
import { ReactComponent as ScooterIcon }
	from '../../../assets/icons/scootericon.svg';
import {
	BABY,
	CAR,
	KICKSCOOTER,
	SCOOTER,
	MAINTENANCE,
	CRITICALFUEL,
} from '../../../model/Asset/assetTypes';
import {
	DEACTIVATED,
	UNAVAILABLE,
	AVAILABLE,
	UNUSED,
	IN_USE,
} from '../../../model/Asset/AssetStatusType';
import CriticalIcon from '@material-ui/icons/Warning';

const inUse = {
	title: "On trip",
	color: '#0092E9',
};

const available = {
	title: "Available",
	color: '#00B67F',
};

const unused = {
	title: "Unused",
	color: '#DAA520',
};

const unavailable = {
	title: "Unavailable",
	color: '#FD2E25',
};

const deactivated = {
	title: "Deactivated",
	color: '#000000',
};

const undefinedStatus = {
	title: "Undefined",
	color: '#89898B',
};

export const getIconDetails = assetStatus => ({
	[DEACTIVATED]: deactivated,
	[UNAVAILABLE]: unavailable,
	[AVAILABLE]: available,
	[IN_USE]: inUse,
	[UNUSED]: unused,
}[assetStatus] || undefinedStatus);

export const getIcon = assetType => ({
	[BABY]: BabyCarIcon,
	[CAR]: CarIcon,
	[KICKSCOOTER]: KickScooterIcon,
	[SCOOTER]: ScooterIcon,
	[MAINTENANCE]: MaintenanceIcon,
	[CRITICALFUEL]: CriticalIcon,
}[assetType] || UndefinedIcon);
