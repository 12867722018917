import React from 'react';
import PromotionList from './PromoCodeList/PromoCodeList';
import PromoCodeDetails from './PromoCodeDetails/PromoCodeDetails';
import CreatePromoCode from './CreatePromoCode/CreatePromoCode';
import {
	Button,
	InputAdornment,
	InputBase,
	Drawer,
	withStyles,
	Typography,
} from '@material-ui/core';
import {
	Search as SearchIcon,
	Close as CrossIcon,
} from '@material-ui/icons';
import { ReactComponent as CreateIcon } from '../../../assets/PromotionApp/icons/add.svg';
import withPermission from '../../../hoc/withPermission';
import styles from './PromoCodeGen.styles';
import moment from 'moment';
import classnames from 'classnames';

const PromoCodeGen = ({
	classes,
	currentScreenSize,
	errorMessage,
	filter,
	filterOnChangeHandler,
	generatedCodeList,
	getMoreRows,
	hasNotification,
	isSuccess,
	modal,
	onChangeHandler,
	onDisableHandler,
	onDownloadCSVHandler,
	onSearchHandler,
	onSelectHandler,
	placeholder,
	promoCodeBatchOnSaveHandler,
	searchFromBonus,
	selectedItem,
	setFilter,
	setModal,
	setPlaceholder,
}) => {

	const errorBar = !modal && (
		<Typography align='center' className={classnames(
			classes.rootBar,
			hasNotification ? classes.showBar : classes.hideBar,
			!!isSuccess ? classes.success : classes.error)}>
			{errorMessage}
		</Typography>
	);

	const title = (
		<Typography className={classes.title}>
			Generated Bonus Codes
		</Typography>
	);

	const icon = (
		<CrossIcon
			onClick={(event) => setFilter('')}
			className={classes.crossIcon}
			size="small"
		/>
	);

	const searchBar = (
		<div className={classes.searchBar}>
			<InputBase
				placeholder={'Search by name or ID'}
				value={filter}
				classes={{
					root: classes.inputRoot,
					input: classes.inputInput,
				}}
				onChange={filterOnChangeHandler}
				onKeyDown={event => {
					if (filter.isSearching) {
						return;
					};
					if (event.keyCode === 13) {
						onSearchHandler();
					};
				}}
				endAdornment={
					<>
						{!!filter &&
							<InputAdornment position="end">
								{icon}
							</InputAdornment>}
					</>
				}
			/>
		</div>
	);

	const searchButton = (
		<Button
			aria-label="Search"
			onClick={onSearchHandler}
		>
			<SearchIcon className={classes.searchIcon} />
		</Button>
	);

	const createButton = (
		<CreateButton
			onClick={() => {
				setPlaceholder({ expirationDate: moment() });
				setModal('createPromoCodeBatch');
			}}
			className={classes.createIcon}
		/>
	);

	const titleAndSearch = (
		<div className={classes.header}>
			<div className={classes.titleAndSearch}>
				{title}
				{searchBar}
				{searchButton}
				{errorBar}
			</div>
			{createButton}
		</div>
	);

	const list = !!generatedCodeList && (
		<PromotionList
			currentScreenSize={currentScreenSize}
			generatedCodeList={generatedCodeList}
			getMoreRows={getMoreRows}
			onDisableHandler={onDisableHandler}
			onDownloadCSVHandler={onDownloadCSVHandler}
			onSelectHandler={onSelectHandler}
			selectedItem={selectedItem}
		/>
	);

	const details = !!selectedItem && (
		<PromoCodeDetails
			searchFromBonus={searchFromBonus}
			selectedItem={selectedItem}
		/>
	);

	const createPromoModal = (
		<Drawer
			anchor='right'
			onClose={() => setModal()}
			open={modal === 'createPromoCodeBatch'}
		>
			<CreatePromoCode
				closeHandler={() => setModal()}
				onChange={onChangeHandler}
				onSaveHandler={promoCodeBatchOnSaveHandler}
				placeholder={placeholder}
				errorMessage={errorMessage}
			/>
		</Drawer>
	);

	return (
		<div className={classes.root}>
			{createPromoModal}
			<div className={classes.workspace}>
				<div className={classes.list}>
					{titleAndSearch}
					{list}
				</div>
				<div className={classes.details}>
					{details}
				</div>
			</div>
		</div>
	);
};

export default withStyles(styles)(PromoCodeGen);

const CreateButton = withPermission(
	[{ resource: ['promotions', 'bonusGenerator', 'create'] }],
)(
	props => <CreateIcon {...props} />
);