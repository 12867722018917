import * as listItemTypes from '../../../../UI/List/ListItem/ListItemTypes';
import * as screenSize from '../../../../../model/ScreenSize/ScreenSize';

const notificationFields = currentScreenSize => {

	const width = {
		[screenSize.DESKTOP]: 151,
		[screenSize.TABLET]: 118,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	const maxWidth = {
		[screenSize.DESKTOP]: 206,
		[screenSize.TABLET]: 176,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	const height = {
		[screenSize.DESKTOP]: 35,
		[screenSize.TABLET]: 45,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];
	
	return [
		{
			key: 'editIcons',
			label: ' ',
			order: 99,
			pinned: true,
			width,
			height,
			type: listItemTypes.COMPONENT,
			flexDirection: 'row-reverse',
		},
		{
			key: 'type',
			label: 'Type',
			order: 1,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'subType',
			label: 'Subtype',
			order: 2,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'createDate',
			label: 'Create Date',
			order: 3,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'createdBy',
			label: 'Created by',
			order: 4,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'lastModified',
			label: 'Last Modified',
			order: 5,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'modifiedBy',
			label: 'Modified by',
			order: 6,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		/* {
			key: 'lastSent',
			label: 'Last sent',
			order: 7,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'totalSent',
			label: 'Total sent',
			order: 8,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		}, */
	];
};

export default notificationFields;
