import createModel from '../createModel';

class Carflow {

	constructor({
		createdBy,
		createDate,
		description,
		features,
		id,
		report,
		subtype,
		type,
	} = {}) {
		createModel.call(this, {
			createdBy,
			createDate,
			description,
			features,
			id,
			report,
			subtype,
			type,
		});
	};
};

export default Carflow;