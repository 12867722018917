import { connect } from 'react-redux';
import toJS from '../../hoc/toJS';
import PromotionApp from './PromotionAppWrapper';

const mapStateToProps = (state) => {
	return {
		currentScreenSize: state.getIn(['app', 'windowWidth']),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(PromotionApp));