import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";

import { styles } from "./DeviceInfo.styles";
import { useSelector } from "react-redux";
import { selectCarInfo } from "../../assetSlice";

function DeviceInfo({ classes, provider_token, serial_number, externalId }) {
  const carInfo = useSelector(selectCarInfo);
  const provider_name = (carInfo?.providers || []).find((item) => item.provider_token === provider_token)?.name;

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.title}>OBS Device</Typography>
      </div>
      <Grid container direction="column" spacing={1}>
        {provider_name && (
          <Grid item>
            <Typography className={classes.label}>Provider</Typography>
            <Typography className={classes.field}>{provider_name}</Typography>
          </Grid>
        )}
        {serial_number && (
          <Grid item>
            <Typography className={classes.label}>
              {provider_name === "PRIMECLOUD" ? "Device Name" : "Device Serial Number"}
            </Typography>
            <Typography className={classes.field}>{serial_number}</Typography>
          </Grid>
        )}
        {externalId && (
          <Grid item>
            <Typography className={classes.label}>Device IMEI</Typography>
            <Typography className={classes.field}>{externalId}</Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default withStyles(styles)(DeviceInfo);
