import createMutator from '../../utils/createMutator';

export const setAlertEndPoint = createMutator('set', 'alertEndPoint');
export const setApps = createMutator('set', 'apps');
export const setCarclubs = createMutator('set', 'carclubs');
export const setCode = createMutator('set', 'code');
export const setColor = createMutator('set', 'color');
export const setDashboardEndPoint = createMutator('set', 'dashboardEndPoint');
export const setDefaultLocation = createMutator('set', 'defaultLocation');
export const setFeatures = createMutator('set', 'features');
export const setFlows = createMutator('set', 'flows');
export const setLocale = createMutator('set', 'locale');
export const setLocations = createMutator('set', 'locations');

export const setNotifications = createMutator('set', 'notifications');
export const setPatterns = createMutator('set', 'patterns');
export const setRefundFees = createMutator('set', 'refundFees');
export const setTawtheeq = createMutator('set', 'tawtheeq');
export const setTheme = createMutator('set', 'theme');
export const setTimezone = createMutator('set', 'timezone');
export const setWasl = createMutator('set', 'wasl');