export const styles = () => ({
  root: {
    padding: 20,
    width: "calc(100vw - 105px)",
  },
  icon: {
    color: "#009AC9",
    cursor: "pointer",
  },
  title: {
    fontSize: 20,
  },
});
