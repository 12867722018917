export const styles = theme => ({
  root: {
    width: 350,
    padding: 15,
  },
  form: {
    boxSizing: "border-box",
    height: "calc(100vh - 120px)",
    overflow: "auto",
    padding: "0 10px",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "2px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "21px",
      boxShadow: "inset 0 0 21px rgba(0,0,0,.3)",
    },
  },
  formButtons: {
    padding: "10px",
  },
  subTitle: {
    fontSize: "16px",
  },
});