
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_REQUEST_IS_MALFORMED,
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import { publishAndAwait } from '../../../app/coreSlice';

const enableUser = async user => {

	if ( !user 
		|| typeof user.code === 'undefined' 
		|| typeof user.enabled === 'undefined') {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	};
	
	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'USER',
			subType: 'CHANGE_STATE',
			message: {
				customerCode: user.code,
				deactivated: user.enabled,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	} 

	return {...user, enabled: !processMessage.deactivated};
};

export default enableUser;
