export const styles = theme => ({
	root: {
		height: 'auto',
		// height: '285px',
		width: 'auto',
		// width: '221px',
		outline: 'none',
		paddingTop: '30px',
	},

	content: {
		
	},

});