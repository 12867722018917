import React from 'react';
import { useSelector } from 'react-redux';
import List from './List';

const ListWrapper = ({
	lastPage,
	onSearch,
	...props
}) => {
	const currentScreenSize = useSelector(state => state.getIn(['app', 'windowWidth']));

	return (
		<List
			{...props}
			getMoreRows={!lastPage && onSearch}
			currentScreenSize={currentScreenSize}
		/>
	);
};

export default ListWrapper;
