import NGError from './ngError';
import wsError, * as wsErrorType from './websocket/errorTypes';

const errorFactory = type => {
	
	const errorDetails = {
		[wsErrorType.WS_REQUEST_IS_MALFORMED]: wsError(type),
		[wsErrorType.WS_PROCESS_MESSAGE_IS_MALFORMED]: wsError(type),
		[wsErrorType.WS_CONNECTION_ERROR]: wsError(type),
		[wsErrorType.WS_AUTHENTICATION_ERROR]: wsError(type),
	}[type];

	return new NGError(errorDetails);
};

export default errorFactory;
 