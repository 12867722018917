const styles = theme => ({
	root: {
		display: 'flex',
	},

	icon: {
		color: '#009AC9',
		cursor: 'pointer',
	},

	removeIcon: {
		color: '#f50057',
		cursor: 'pointer',
	},

	disabledIcon: {
		color: '#C5C5C5',
		cursor: 'default',
	},

	redIcon: {
		color: '#510000',
	},
	
	row: {
		padding: '0',
	},

	assignButton: {
		boxShadow: 'none',
		height: '21px',
		minWidth: '65px',
	},
});

export default styles;