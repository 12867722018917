import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { useDispatch, useSelector } from "react-redux";

import { styles } from "./TripActivityMap.styles";

import {
  bigdataListComms,
  selectActivityLogs,
  bigdataHeartbeats,
  selectIsLoading,
  selectHeartbeats,
} from "../bigdataSlice";
import { EventMarker, HeartbeatMarker, MapLite } from "../../../common/map";
import { useDateTime } from "../../../common/hooks";
import moment from "moment";
import { filterHeartBeats, getCenter } from "../bigdataUtils";
import { selectDefaultLocation } from "../../carclub/carclubSlice";

let lines;
let googleAPI;

function TripActivityMap({ classes, startDate, endDate, deviceToken }) {
  const selectedLocation = useSelector(selectDefaultLocation);
  const dispatch = useDispatch();
  const loading = useSelector(selectIsLoading);
  const events = useSelector(selectActivityLogs);
  const heartbeats = useSelector(selectHeartbeats);
  const { toTimeZone } = useDateTime();
  const [filteredHeartBeats, setFilteredHeartbeats] = useState([]);

  const onGoogleApiLoadedHandler = (google) => {
    googleAPI = google;
  };

  useEffect(() => {
    setFilteredHeartbeats(filterHeartBeats(heartbeats));
    //eslint-disable-next-line
  }, [heartbeats]);

  useEffect(() => {
    //console.log("startDate: %o, endDate: %o, deviceToken: %o, loading: %o", startDate, endDate, deviceToken, loading);
    if (startDate && deviceToken && !loading) {
      let endDateAux = endDate ? moment.utc(endDate) : moment.utc();
      dispatch(
        bigdataListComms({
          clear: true,
          start: moment.utc(startDate).format("YYYY-MM-DD HH:mm:ss"),
          end: endDateAux.format("YYYY-MM-DD HH:mm:ss"),
          deviceToken,
          page: 1,
          limit: 10000,
        })
      );
      dispatch(
        bigdataHeartbeats({
          clear: true,
          start: moment.utc(startDate).format("YYYY-MM-DD HH:mm:ss"),
          end: endDateAux.format("YYYY-MM-DD HH:mm:ss"),
          deviceToken,
          offset: 0,
          limit: 10000,
        })
      );
    }
    // eslint-disable-next-line
  }, [startDate, endDate, deviceToken]);

  useEffect(() => {
    if (events && filteredHeartBeats) {
      let points = [...filteredHeartBeats];
      points = points.sort((a, b) => (a.date < b.date ? -1 : 1));
      updateRoutes(points);
    }
  }, [events, filteredHeartBeats]);

  if ((!events || events.length === 0) && !heartbeats) {
    return null;
  }

  const eventMarkers = (events || [])
    .filter(
      (item) =>
        item.comms?.latitude && item.comms?.longitude && item.comms?.latitude !== 0 && item.comms?.longitude !== 0
    )
    .map((item, index) => (
      <EventMarker key={"log_" + index} lat={item?.comms?.latitude} lng={item?.comms?.longitude} event={item} />
    ));

  const center =
    events && events.length > 0
      ? getCenter([
          ...events
            .filter(
              (item) =>
                item.comms?.latitude &&
                item.comms?.longitude &&
                item.comms?.latitude !== 0 &&
                item.comms?.longitude !== 0
            )
            .map((item) => {
              return {
                lat: item.comms?.latitude,
                lng: item.comms?.longitude,
              };
            }),
          ...filteredHeartBeats,
        ])
      : { lat: selectedLocation.latitude, lng: selectedLocation.longitude };

  const heartbeatMarkers = (filteredHeartBeats || []).map((item, index) => (
    <HeartbeatMarker
      key={"hb_" + index}
      lat={item.lat}
      lng={item.lng}
      tooltip={toTimeZone(item.date).format("YYYY-MM-DD HH:mm:ss")}
    />
  ));

  return (
    <div className={classes.root}>
      <MapLite
        markers={[...heartbeatMarkers, ...eventMarkers]}
        center={center}
        zoom={14}
        onGoogleApiLoaded={onGoogleApiLoadedHandler}
      />
    </div>
  );
}

const updateRoutes = (points) => {
  if (points && points.length > 1 && googleAPI) {
    lines = new googleAPI.maps.Polyline({
      path: points,
      geodesic: true,
      strokeColor: "#60bdda",
      strokeWeight: 2,
    });
    lines.setMap(googleAPI.map);
  } else {
    if (lines) {
      lines.setMap(null);
    }
  }
};

export default withStyles(styles)(TripActivityMap);
