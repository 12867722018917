import AssetApp from '../AssetApp/AssetAppContainer';
import TripApp from '../TripApp/TripAppWrapper';
import CustomerApp from '../NewCustomerApp/CustomerAppContainer';
import PromotionApp from '../PromotionApp/PromotionAppContainer';
import PricingPlanApp from '../PricingPlanApp/PricingPlanAppContainer';
import PricingPlanAppNew from '../PricingPlanAppNew/PricingPlanAppWrapper';
import CarclubApp from '../CarclubApp/CarclubAppContainer';
import IncidentsApp from '../IncidentApp/IncidentsAppContainer';
import AccountApp from '../AccountApp/AccountAppContainer';
import DashboardApp from '../DashboardApp/DashboardApp';
import WorkOrderApp from '../WorkOrderApp/WorkOrderApp';
import { ReactComponent as PromotionIcon } from '../../assets/PromotionApp/icons/promotions.svg';
import { ReactComponent as PricingPlanIcon } from '../../assets/PricingPlanApp/icons/pricing_plan.svg';
import { IncidentsAppIcon as IncidentsIcon } from '../../assets/IncidentsApp/all-icons';
import { ReactComponent as WorkOrderIcon } from '../../assets/WorkOrderApp/work_orders.svg';
import {
	Commute as DeviceIcon,
	NearMe as TripIcon,
	Group as AccountIcon,
	Person as CustomerIcon,
	Settings as CarclubIcon,
	Dashboard as DashboardIcon,
} from '@material-ui/icons';
import TableChartIcon from '@material-ui/icons/TableChart';
import ReportsApp from '../../features/reports/ReportsApp/ReportsApp';

const app = (
	name,
	url,
	icon,
	component,
	tag,
) => {

	return {
		name,
		url,
		icon,
		component,
		tag,
	};
};


const apps = () => {
	const array = []
		.concat(app('Fleet', '/devices', DeviceIcon, AssetApp, "fleet"))
		.concat(app('Trips', '/trips', TripIcon, TripApp, "reservations"))
		.concat(app('Drivers', '/customers', CustomerIcon, CustomerApp, "customers"))
		.concat(app('Work Orders', '/workOrders', WorkOrderIcon, WorkOrderApp, 'workOrders'))
		.concat(app('Incidents', '/incidents', IncidentsIcon, IncidentsApp, 'incidents'))
		.concat(app('Accounts', '/accounts', AccountIcon, AccountApp, 'accounts'))
		.concat(app('Promotions', '/promotions', PromotionIcon, PromotionApp, "promotions"))
		.concat(app('Pricing Plans', '/pricingPlans', PricingPlanIcon, PricingPlanApp, "pricingPlans"))
		.concat(app('Rate P.Plans', '/pricingPlansNew', PricingPlanIcon, PricingPlanAppNew, "pricingPlansNew"))
		.concat(app('Dashboard', '/dashboard', DashboardIcon, DashboardApp, 'dashboard'))
		.concat(app('Reports', '/reports', TableChartIcon, ReportsApp, 'reports')) 
		.concat(app('Carclub', '/carclub', CarclubIcon, CarclubApp, 'carclub'));
	return array;
};


export default apps;
