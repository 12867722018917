import React, { useState } from 'react';
import DetailsDiv from '../../../../UI/DetailsDiv/DetailsDiv';
import DetailsRow from '../../../../UI/DetailsDiv/DetailsRow/DetailsRow';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	withStyles,
} from '@material-ui/core';
import {
	ExpandMore,
} from '@material-ui/icons';
import styles from './Details.styles';

const MoreInfo = ({
	classes,
	info,
}) => {

	const [selected, setSelected] = useState(false);

	const summary = (
		<AccordionSummary
			className={classes.expansionSummary}
			classes={{
				root: classes.expansionRoot,
				expanded: classes.expanded,
				content: classes.expanded,
			}}
			expandIcon={<ExpandMore />}
		>
			<DetailsDiv title='More Info' />
		</AccordionSummary>
	);

	const details = info.details && (
		<DetailsRow
			label='Details'
			column={true}
			detail={
				<Typography className={classes.typography}>
					{info.details}
				</Typography>
			}
		/>
	);

	const expansionDetails = (
		<AccordionDetails className={classes.details} onClick={(event) => event.stopPropagation()} >
			{details}
		</AccordionDetails>
	);


	return (
		<Accordion
			className={classes.expansionPanel}
			expanded={!!selected}
			onClick={() => setSelected(!selected)}
		>
			{summary}
			{expansionDetails}
		</Accordion>
	);
};

export default withStyles(styles)(MoreInfo);