export const styles = theme => ({

	root: {
		backgroundColor: 'inherit',
		height: 'inherit',
		minWidth: 'inherit',
		maxWidth: 'inherit',
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
	},

});