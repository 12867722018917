import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/styles/withStyles";
import CreateIcon from "@material-ui/icons/AddCircle";
import withPermission from "../../../hoc/withPermission";
import styles from "./AccountButtons.styles";
import classnames from "classnames";

const AccountButtons = ({ classes, className, onCreate }) => {
  const createButton = <CreateButton classes={classes} onCreate={onCreate} />;

  return <div className={classnames(classes.root, className)}>{createButton}</div>;
};

const createPermission = { resource: ["accounts", "create"] };

const CreateButton = withPermission([createPermission])(({ classes, onCreate }) => (
  <Tooltip title="Create" placement="left">
    <CreateIcon onClick={() => onCreate && onCreate()} className={classnames(classes.icon)} />
  </Tooltip>
));

export default withStyles(styles)(AccountButtons);
