// eslint-disable-next-line
const tiny = Number(process.env.REACT_APP_TINY);

function CurrencyFormatter(
	{
		decimalPlaces,
		decimalSymbol,
		thousandSymbol,
		symbol,
	} = {
		decimalPlaces: 2,
		decimalSymbol: ',',
		thousandSymbol: ' ',
		symbol: '€',
	}
) {

	this.patterns = {
		decimalPlaces,
		decimalSymbol,
		thousandSymbol,
		symbol,
	};

	this.regex = new RegExp(
		'\\d(?=(\\d{3})+' + (this.patterns.decimalPlaces > 0 ? '\\D' : '$') + ')',
		'g',
	);
}

// FIXME Tests needed (untested)
CurrencyFormatter.prototype.format = function (number, options) {

	const {
		ceil,
		floor,
		round,
		decimalPlaces,
		decimalSymbol,
		thousandSymbol,
	} = options || {};

	const isDev = process.env.NODE_ENV === 'development'
		? true
		: false;

	if (typeof number === 'undefined'
		|| (!Number(number) && Number(number) !== 0)) {
		return null;
	}

	let newNumber = number;

	if ((ceil && floor)
		|| (floor && round)
		|| (round && ceil)) {
		if (isDev) {
			throw new Error('Choose only one rounding startegy.');
		}
		return null;
	}

	if (ceil) {
		const decimals = Math.pow(10, decimalPlaces || this.patterns.decimalPlaces);
		// TODO decide if we should use epsilon or tiny (0.00001)
		newNumber = Math.round((newNumber + Number.EPSILON) * decimals) / decimals;

	}

	if ((floor || round)
		&& isDev) {
		throw new Error('Rounding strategy not implemented.');
	}

	const num = Number(newNumber).toFixed(
		Math.max(0, Math.floor(decimalPlaces || this.patterns.decimalPlaces))
	);

	// TODO This returns NaN if newNumber === 0, what to do, what to do...?
	return num.replace('.', decimalSymbol || this.patterns.decimalSymbol)
		.replace(
			this.regex,
			'$&' + (thousandSymbol || this.patterns.thousandSymbol || ',')
		);
};

CurrencyFormatter.prototype.symbol = function () {
	return this.patterns.symbol;
};

export default CurrencyFormatter;

