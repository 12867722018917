import React, { useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Button, Grid, Typography } from "@material-ui/core";

import { styles } from "./ChargeButton.styles";
import Modal from "../../../../../../common/displays/Modal/Modal";
import { useDispatch } from "react-redux";
import { billingChargeAdvanced } from "../../../../reservationsSlice";
import { useCurrency } from "../../../../../../common/hooks";

function ChargeButton({ classes, loading, reservationCode, carToken, amount, creditCardLastDigits }) {
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const { formatCurrency } = useCurrency();

  const settleHandler = () => {
    dispatch(billingChargeAdvanced({ reservationCode, carToken }));
    setModal(false);
  };

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        size="small"
        disabled={loading}
        onClick={() => setModal(true)}
      >
        Charge
      </Button>
      <Modal open={modal} setOpen={setModal} sizeClass="small">
        <div style={{ height: "100%" }}>
          <Grid
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Grid item>
              <Typography align="center" variant="h5">
                Charge trip
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="center">Please confirm the following billing details to charge:</Typography>
            </Grid>
            {creditCardLastDigits && (
              <Grid item>
                <Typography align="center">{`xxxx-xxxx-xxxx-${creditCardLastDigits}`}</Typography>
              </Grid>
            )}
            <Grid item>
              <Typography className={classes.amount}>{formatCurrency(amount)}</Typography>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                size="small"
                disabled={loading}
                onClick={settleHandler}
              >
                Charge
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}

export default withStyles(styles)(ChargeButton);
