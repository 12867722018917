import {ReactComponent as OngoingIcon } from '../../icons/ongoing.svg';
import {ReactComponent as ReadyIcon } from '../../icons/ready.svg';
import {ReactComponent as ClosedIcon } from '../../icons/closed.svg';
import {ReactComponent as CancelledIcon } from '../../icons/cancel.svg';
import {ReactComponent as ScheduledIcon } from '../../TripApp/current/trip_scheduled.svg';
import AllIcon from '@material-ui/icons/InfoOutlined';

const reservationStatusIcon = {
	'READY' : ReadyIcon,
	'IDLE' : ReadyIcon,
	'CLOSED' : ClosedIcon,
	'ONGOING' : OngoingIcon,
	'STARTED' : OngoingIcon,
	'CANCELLED': CancelledIcon,
	'ABSENT': CancelledIcon,
	'SCHEDULED': ScheduledIcon,
	'ALL': AllIcon,
};

export const reservationStatusColor = {
	'READY' : { color: '#FFD103' },
	'IDLE' : { color: '#FFD103' },
	'CLOSED' : { color: '#4c4c4e' },
	'ONGOING' : { color: '#0092e9' },
	'STARTED' : { color: '#0092e9' },
	'CANCELLED' : { color: '#89898b' },
	'ABSENT' : { color: '#89898b' },
	'SCHEDULED': { color: '#333'},
	'ALL' : { color: 'black'},
};

export default reservationStatusIcon;