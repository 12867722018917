import { wsCall, requestHelper } from "../../../app/coreSlice";

const customerNewListCall = async (
  { setCustomers, clearCustomers, setCustomersFilter },
  dispatch,
  state,
  { reset, filter }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  const { agent, available, customerCode, email, limit, mobileNumber, name, nif, onboarded, page: oPage } = filter;
  let page = oPage;
  if (reset) {
    page = 1;
  }

  let rh = requestHelper(dispatch, "CUSTOMER/NEW_LIST");
  dispatch(setCustomersFilter({ ...filter, page }));
  try {
    if (reset) {
      dispatch(clearCustomers());
    }
    const result = await wsCall({
      type: "CUSTOMER",
      subType: "NEW_LIST",
      locationId: selectedLocation?.id,
      message: {
        agent,
        available,
        customerCode,
        email,
        limit,
        mobileNumber,
        name,
        nif,
        onboarded,
        page,
      },
    });
    if (result) {
      dispatch(setCustomers(result));
    }
  } catch (_ex) {
    rh.error("Error getting drivers.");
  }
  rh.close();
};

export default customerNewListCall;
