import React from 'react';
import Detail from './Details/Detail';
import Application from './Details/Application';
import MoreInfo from './Details/MoreInfo';
import {
	Divider,
	withStyles,
} from '@material-ui/core';
import styles from './PromotionDetails.styles';
import classnames from 'classnames';

const PromotionDetails = ({
	classes,
	className,
	details,
}) => {

	const detail = <Detail info={details} />;

	const application = <Application info={details} />;

	const moreInfo = details.details !== null
		? (
			<>
				<Divider />
				<MoreInfo info={details} />
	 		</>
		)
		: null;

	//Not for MVP
	// const metrics = <Metrics info={details} />;

	const promotionDetails = (
		<div className={classnames(classes.root, className)}>
			{detail}
			<Divider />
			{application}
			{moreInfo}
		</div>
	);

	return promotionDetails;
};

export default withStyles(styles)(PromotionDetails);