import React from "react";
import Button from "../../../../UI/Buttons/CommonButtons";
import ErrorDiv from "../../../../ErrorDiv/ErrorDiv";
import configs from "./configs";
import { Drawer, FormControl, InputBase, InputLabel, Typography, withStyles } from "@material-ui/core";
import styles from "./Configurations.styles";
import classnames from "classnames";

const Configurations = ({
  classes,
  className,
  errorMessage,
  header,
  hasRequest,
  onCancel,
  onChange,
  open,
  onSave,
  placeholder,
}) => {
  const title = <Typography className={classes.title}>{header}</Typography>;

  const body = !!configs && configs.map(createInput(classes, onChange, placeholder, hasRequest));

  const save = createButton("filled", hasRequest, "Save", onSave);

  const cancel = createButton("void", hasRequest, "Cancel", onCancel);

  const buttons = (
    <div className={classes.buttons}>
      {cancel}
      {save}
    </div>
  );

  const error = !!errorMessage && <ErrorDiv message={errorMessage} />;

  const configurations = (
    <Drawer anchor="right" onClose={hasRequest && onCancel} open={open}>
      <div className={classnames(classes.root, className)}>
        {title}
        {body}
        {error}
        {buttons}
      </div>
    </Drawer>
  );

  return configurations;
};

export default withStyles(styles)(Configurations);

const createInput = (classes, onChange, configs, disabled) => (field) =>
  (
    <FormControl className={classes.fields} disabled={disabled} key={field.key}>
      <InputLabel className={classes.inputLabel}>{field.label}</InputLabel>
      <InputBase
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        value={configs[field.key] || ""}
        onChange={(e) => onChange({ name: field.key, value: e.target.value })}
      />
    </FormControl>
  );

const createButton = (buttonStyle, isButtonDisabled, label, onClick) => (
  <Button buttonStyleName={buttonStyle} disabled={!!isButtonDisabled} key={label} label={label} onClick={onClick} />
);
