import {
	FilterList as TypeIcon,
	InfoOutlined as PriorityIcon,
} from "@material-ui/icons";

const button = (icon, text, options, right = false) => {
	return {
		icon,
		text,
		right,
		options,
	};
};

const buttonOption = (text, filter, right = false) => {

	return {
		text,
		right,
		filter,
	};
};

export const priorityButtons = priorities => {
	const buttons = [
		buttonOption(
			'All priorities',
			undefined,
		),
		...(priorities || [])
			.map(priority =>
				buttonOption(
					typeof priority === 'string' ? uncaps(priority) : priority,
					priority,
				)),
	];

	return button(PriorityIcon, 'All priorities', buttons);
};

export const typeButtons = types => {
	const buttons = [
		buttonOption(
			'All types',
			undefined,
		),
		...(types || [])
			.map(type =>
				buttonOption(
					type,
					type,
				)),
	];

	return button(TypeIcon, 'All types', buttons);
};

const filterbuttons = { typeButtons, priorityButtons };
export default filterbuttons;

const uncaps = string => string[0].toUpperCase() + string.slice(1).toLowerCase();