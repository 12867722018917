import React from 'react';
import {
	Modal as MaterialModal,
	IconButton,
	Paper,
	withStyles,
} from '@material-ui/core';
import {
	Close as CloseIcon,
} from '@material-ui/icons';
import styles from './Modal.styles';
import classnames from 'classnames';

const Modal = ({
	classes,
	closeHandler,
	content,
	className,
	overrideRetardedEscape = false,
	disableCloseIcon = false,
}) => {

	const closeButton = !!closeHandler && !disableCloseIcon
		&& (
			<div className={classes.closeButton}>
				<IconButton
					onClick={closeHandler}
					style={{ color: '#009BCC', backgroundColor: 'transparent' }}
					disableRipple
				>
					<CloseIcon style={{ fontSize: '15px' }} />
				</IconButton>
			</div>
		);

	return (
		<MaterialModal
			open={true}
			disableEscapeKeyDown={!overrideRetardedEscape}
			onClose={closeHandler}
		>
			<div className={classes.root}>
				<Paper className={classnames(classes.paper, className)}>
					{closeButton}
					{content}
				</Paper>
			</div>
		</MaterialModal>
	);
};

export default withStyles(styles)(Modal);	