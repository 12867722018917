import React from "react";
import { useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";

import { styles } from "./ActiveLegalDocumentsDataGrid.styles";
import { listColumns } from "./ActiveLegalDocumentsDataGrid.columns";
import ActiveLegalDocumentsDataGridActions from "./ActiveLegalDocumentsDataGrid.actions";

import { CustomDataGrid } from "../../../../../common/displays";
import { selectActiveLegalDocuments } from "../../../carclubSlice";

function ActiveLegalDocumentsDataGrid({ classes }) {
  const columns = listColumns();
  const data = useSelector(selectActiveLegalDocuments);

  return (
    <div className={classes.root}>
      {data && (
        <CustomDataGrid
          className={classes.datagrid}
          columns={columns}
          rows={data}
          total={data.length}
          actionsComponent={<ActiveLegalDocumentsDataGridActions classes={classes} />}
        />
      )}
    </div>
  );
}

export default withStyles(styles)(ActiveLegalDocumentsDataGrid);
