import * as listItemTypes from '../../../UI/List/ListItem/ListItemTypes';
import * as screenSize from '../../../../model/ScreenSize/ScreenSize';

const maxFlatRatesFields = (currentScreenSize, smallList) => {

	const widthSmall = !!smallList
		? 25
		: 30;
	const widthBigger = !!smallList
		? 100
		: 140;

	const height = {
		[screenSize.DESKTOP]: 15,
		[screenSize.TABLET]: 15,
		[screenSize.ERROR]: 0,
	}[currentScreenSize] || 15;

	return [
		{
			key: 'editIcons',
			label: ' ',
			order: 99,
			pinned: true,
			width: widthSmall,
			height,
			type: listItemTypes.COMPONENT,
			flexDirection: 'row-reverse',
		},
		{
			key: 'name',
			label: 'Name',
			order: 1,
			pinned: true,
			width: widthBigger,
			minWidth: widthSmall,
			maxWidth: widthBigger,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'maxTime',
			label: 'Max Time',
			order: 2,
			pinned: true,
			width: widthBigger,
			minWidth: widthSmall,
			maxWidth: widthBigger,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'maxPrice',
			label: 'Max Price',
			order: 3,
			pinned: true,
			width: widthBigger,
			minWidth: widthSmall,
			maxWidth: widthBigger,
			height,
			type: listItemTypes.TEXT,
		},
	];
};

export default maxFlatRatesFields;