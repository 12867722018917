import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";
import { assetAssetReservationCounts, assetList } from "../../asset/assetSlice";
import { reservationOngoingList } from "../reservationsSlice";

const reservationNewReservationUpdateCall = async (
  dispatch,
  state,
  { reservationCode, carToken, parkId, startDate, endDate }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "RESERVATION/NEW_RESERVATION_UPDATE");
  try {
    const result = await wsCall({
      type: "RESERVATION",
      subType: "NEW_RESERVATION_UPDATE",
      locationId: selectedLocation?.id,
      message: {
        reservationCode,
        carToken,
        parkId,
        startDate,
        endDate,
        wantedStartDate: startDate,
        wantedEndDate: endDate,
      },
    });

    if (!result.error) {
      dispatch(setSuccess("Reservation updated successfully!"));
      setTimeout(() => {
        dispatch(assetAssetReservationCounts());
        dispatch(assetList({}));
        dispatch(reservationOngoingList(null, true));
      }, 300);
      // refresh reservations
    } else {
      rh.error(result?.description || "Error updating reservation.");
    }
  } catch (_ex) {
    rh.error("Error updating reservation.");
  }
  rh.close();
};

export default reservationNewReservationUpdateCall;
