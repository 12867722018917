export const styles = theme => ({

	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
	},

});

export default styles;