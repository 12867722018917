import React, { useEffect, useState } from 'react';
import NotificationFilter from './NotificationFilter';

const NotificationsFilterWrapper = props => {

	const {
		notifications = [],
		type,
		selectSubType,
		selectType,
		subType,
	} = props;

	const [types, setTypes] = useState(['All types', ...new Set(notifications.map(n => n.type))]);
	const [subTypes, setSubTypes] = useState(['All subtypes', ...new Set(
		notifications.filter(n => n.type === type)
			.map(n => n.subType)
	)]);

	useEffect(() => {
		setTypes(['All types', ...new Set(notifications.map(n => n.type))]);
		setSubTypes(['All subtypes', ...new Set(
			notifications.filter(n => n.type === type)
				.map(n => n.subType)
		)]);
	}, [notifications, type]);

	useEffect(() => {

		if (!type) {
			selectType(types[0]);
		}

		if (!subType) {
			selectSubType(subTypes[0]);
		}

	}, [type, selectSubType, selectType, subType, types, subTypes]);

	return (
		<NotificationFilter
			{...props}
			types={types}
			subTypes={subTypes}
			selectType={newType => {
				selectType(newType);
				selectSubType(subTypes[0]);
			}}
		/>
	);
};

export default NotificationsFilterWrapper;
