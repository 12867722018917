import { wsCall, requestHelper } from "../../../app/coreSlice";

const dashboardReservationsBillingCall = async ({ setPaymentMetrics }, dispatch, state, { startDate, endDate }) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "DASHBOARD/RESERVATIONS_BILLING");
  try {
    const result = await wsCall({
      type: "DASHBOARD",
      subType: "RESERVATIONS_BILLING",
      locationId: selectedLocation?.id,
      message: {
        startDate,
        endDate,
      },
    });

    const { listGraph: list, totalCharged: charged, totalCost: total } = result;

    dispatch(
      setPaymentMetrics({
        list,
        total: total.toFixed(2),
        charged: charged.toFixed(2),
        due: (total - charged).toFixed(2),
      })
    );
  } catch (_ex) {
    rh.error("Error getting payments metrics.");
  }
  rh.close();
};

export default dashboardReservationsBillingCall;
