import getImage from './ws/getImage';
import uploadImage from './ws/uploadImage';
import saveImage from './ws/saveImage';
import S3Service from './ws';

export const getPublicLinks = getImage;

const s3Service = new S3Service({
	uploadImage,
	saveImage,
});

export default s3Service;