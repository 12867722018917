import createModel from "../createModel";

const valuesMap = {
  "Sign-Up": "ON_REGISTRATION",
  Trip: "ON_TRIP",
};

const getBoolValue = (value, originalValue) => {
  if (typeof value === "undefined") {
    if (typeof originalValue === "undefined") {
      return false;
    } else {
      return originalValue;
    }
  }
  return value;
};

const getSelectValue = (value, originalValue, defaultValue) => {
  if (typeof value === "undefined") {
    if (typeof originalValue === "undefined") {
      return defaultValue;
    } else {
      return originalValue;
    }
  }
  return value;
};
class CarclubConfigsDTO {
  constructor({
    // GATEWAYS
    activateGoldpinAfter,
    address,
    blockNewTripForSameCar,
    allowDifferentEndPark,
    chargeGracePeriod,
    timeoutAutomaticTripClosure,
    sendNotificationsToClient,
    bonusCondition,
    bonusConditionWithoutReferral,
    BRAINTREE_btEnvironment,
    BRAINTREE_merchantAccount,
    BRAINTREE_merchantId,
    BRAINTREE_privateKey,
    BRAINTREE_publicKey,
    captivationPeriod,
    colorButtons,
    colorHeader,
    comercialDesignation,
    country,
    countryCodeOfficialPhone,
    countryCodePhone,
    defaultLocation,
    email,
    expireUnusedTripAfter,
    expireUnusedPlannedTripAfter,
    percFleetPlannedTrip,
    maxFutureDaysPlannedTrip,
    thresholdAssignPlannedTrip,
    warningBattery,
    criticalBattery,
    warningUnused,
    criticalUnused,
    warningRental,
    criticalRental,
    warningGeofence,
    gateway,
    GDPDR,
    googleMapsAPIKey,
    INVOICE_EXPRESS_apiCode,
    INVOICE_EXPRESS_apiKey,
    INVOICE_EXPRESS_country,
    INVOICE_EXPRESS_taxCode,
    INVOICE_EXPRESS_value,
    invoiceGWs,
    IYZICO_apiKey,
    IYZICO_baseUrl,
    IYZICO_secretKey,
    lateDelivery,
    logoFileLink,
    longTripTime,
    minimumCaptivationAmount,
    officialPhone,
    paymentGWs,
    personalEmail,
    personalNumber,
    phoneNumber,
    pricingEngine,
    referrerBonusCondition,
    refundFee,
    reservationBond,
    reservationBondAlert,
    reviseOnClose,
    smsNotification,
    operationType,
    damageReportStatus,
    periodicRefreshInterval,
    S3AccessKey,
    S3AccessSecretKey,
    region_config,
    bucket,
    STRIPE_publicKey,
    STRIPE_secretKey,
    taxNumber,
    termsAndConditions,
    website,
    withoutCapture,
    withoutReferralBonusConfig,
    withoutReferralBonusExpire,
    withoutReferralFeeConfig,
    withReferralBonusConfig,
    withReferralBonusConfigForReferrer,
    withReferralBonusExpire,
    withReferralBonusExpireForReferrer,
    withReferralFeeConfig,
    invoiceTemplateUrl,
    appName,
    appVersions,
    hostUrl,
    hostKey,
    awsS3_provider,
    awsS3_key,
    awsS3_secret,
    awsS3_region,
    awsS3_private_bucket,
    awsS3_public_bucket,
    awsS3_invoice_bucket,
    awsS3_endtrip_bucket,
    awsS3_app_bucket,
    assetUnavailableWithCriticalBattery,
    holdAmount,
    warningSpeed,
    invoiceGateway,
    // ORIGINAL
    originalConfigs,
  } = {}) {
    createModel.call(this, {
      BILLING: {
        captivationPeriod: captivationPeriod || originalConfigs?.BILLING?.captivationPeriod,
        gateway: gateway || originalConfigs?.BILLING?.gateway,
        minimumCaptivationAmount: minimumCaptivationAmount || originalConfigs?.BILLING?.minimumCaptivationAmount,
        refundFee: refundFee || originalConfigs?.BILLING?.refundFee,
        reservationBond: reservationBond || originalConfigs?.BILLING?.reservationBond,
        reservationBondAlert: reservationBondAlert || originalConfigs?.BILLING?.reservationBondAlert,
        withoutCapture: withoutCapture,
        reviseOnClose: reviseOnClose,
        invoiceTemplateUrl,
        invoiceGateway: invoiceGateway || originalConfigs?.BILLING?.invoiceGateway,
      },
      CARCLUB_INFO: {
        customize: {
          colorButtons,
          colorHeader,
          logoFile: logoFileLink,
          termsAndConditions,
        },
        infoCompany: {
          address,
          comercialDesignation,
          countryCodeOfficialPhone,
          officialPhone,
          website,
          country,
          email,
          taxNumber,
        },
        infoUser: {
          personalEmail,
          personalNumber,
          countryCodePhone,
          phoneNumber,
        },
        secrets: {
          S3AccessKey,
          S3AccessSecretKey,
          googleMapsAPIKey,
          bucket: bucket || originalConfigs?.CARCLUB_INFO?.secrets?.bucket,
          region_config: region_config || originalConfigs?.CARCLUB_INFO?.secrets?.region_config,
          GDPDR,
        },
        infoApp: {
          appName: appName || originalConfigs?.CARCLUB_INFO?.infoApp?.appName,
          appVersions: appVersions || originalConfigs?.CARCLUB_INFO?.infoApp?.appVersions,
          hostUrl: hostUrl || originalConfigs?.CARCLUB_INFO?.infoApp?.hostUrl,
          hostKey: hostKey || originalConfigs?.CARCLUB_INFO?.infoApp?.hostKey,
        },
        awsS3: {
          provider: awsS3_provider || originalConfigs?.CARCLUB_INFO?.awsS3?.provider,
          key: awsS3_key || originalConfigs?.CARCLUB_INFO?.awsS3?.key,
          secret: awsS3_secret || originalConfigs?.CARCLUB_INFO?.awsS3?.secret,
          region: awsS3_region || originalConfigs?.CARCLUB_INFO?.awsS3?.region,
          private_bucket: awsS3_private_bucket || originalConfigs?.CARCLUB_INFO?.awsS3?.private_bucket,
          public_bucket: awsS3_public_bucket || originalConfigs?.CARCLUB_INFO?.awsS3?.public_bucket,
          invoice_bucket: awsS3_invoice_bucket || originalConfigs?.CARCLUB_INFO?.awsS3?.invoice_bucket,
          endtrip_bucket: awsS3_endtrip_bucket || originalConfigs?.CARCLUB_INFO?.awsS3?.endtrip_bucket,
          app_bucket: awsS3_app_bucket || originalConfigs?.CARCLUB_INFO?.awsS3?.app_bucket,
        },
      },
      CONFIGURATION: {
        carClubUrl: originalConfigs?.CONFIGURATION?.carClubUrl,
        maxTime: longTripTime,
        refundFees: originalConfigs?.CONFIGURATION?.refundFees,
        simulator: pricingEngine,
        smsNotification,
        tawtheeq: originalConfigs?.CONFIGURATION?.tawtheeq,
        taxValue: originalConfigs?.CONFIGURATION?.taxValue,
        wasl: originalConfigs?.CONFIGURATION?.wasl,
        operationType: getSelectValue(operationType, originalConfigs?.CONFIGURATION?.operationType, "S"),
        damageReportStatus: getBoolValue(damageReportStatus, originalConfigs?.CONFIGURATION?.damageReportStatus),
        periodicRefreshInterval: periodicRefreshInterval || originalConfigs?.CONFIGURATION?.periodicRefreshInterval,
      },
      DEFAULT_LOCATION: defaultLocation,

      INVOICE_EXPRESS: {
        apiCode: INVOICE_EXPRESS_apiCode || invoiceGWs?.INVOICE_EXPRESS?.apiCode,
        apiKey: INVOICE_EXPRESS_apiKey || invoiceGWs?.INVOICE_EXPRESS?.apiKey,
        country: INVOICE_EXPRESS_country || invoiceGWs?.INVOICE_EXPRESS?.country,
        taxCode: INVOICE_EXPRESS_taxCode || invoiceGWs?.INVOICE_EXPRESS?.taxCode,
        value: INVOICE_EXPRESS_value || invoiceGWs?.INVOICE_EXPRESS?.value,
      },

      TRIP: {
        lateDelivery: lateDelivery || originalConfigs?.TRIP?.lateDelivery,
        expireUnusedTripAfter: expireUnusedTripAfter || originalConfigs?.TRIP?.expireUnusedTripAfter,
        expireUnusedPlannedTripAfter:
          expireUnusedPlannedTripAfter || originalConfigs?.TRIP?.expireUnusedPlannedTripAfter,
        percFleetPlannedTrip: percFleetPlannedTrip || originalConfigs?.TRIP?.percFleetPlannedTrip,
        maxFutureDaysPlannedTrip: maxFutureDaysPlannedTrip || originalConfigs?.TRIP?.maxFutureDaysPlannedTrip,
        thresholdAssignPlannedTrip: thresholdAssignPlannedTrip || originalConfigs?.TRIP?.thresholdAssignPlannedTrip,
        blockNewTripForSameCar: blockNewTripForSameCar || originalConfigs?.TRIP?.blockNewTripForSameCar,
        activateGoldpinAfter: activateGoldpinAfter || originalConfigs?.TRIP?.activateGoldpinAfter,
        allowDifferentEndPark: getBoolValue(allowDifferentEndPark, originalConfigs?.TRIP?.allowDifferentEndPark),
        chargeGracePeriod: getBoolValue(chargeGracePeriod, originalConfigs?.TRIP?.chargeGracePeriod),
        timeoutAutomaticTripClosure: timeoutAutomaticTripClosure || originalConfigs?.TRIP?.timeoutAutomaticTripClosure,
        sendNotificationsToClient: getBoolValue(
          sendNotificationsToClient,
          originalConfigs?.TRIP?.sendNotificationsToClient
        ),
        warningBattery: warningBattery || originalConfigs?.TRIP?.warningBattery,
        criticalBattery: criticalBattery || originalConfigs?.TRIP?.criticalBattery,
        warningUnused: warningUnused || originalConfigs?.TRIP?.warningUnused,
        criticalUnused: criticalUnused || originalConfigs?.TRIP?.criticalUnused,
        warningRental: warningRental || originalConfigs?.TRIP?.warningRental,
        criticalRental: criticalRental || originalConfigs?.TRIP?.criticalRental,
        warningGeofence: warningGeofence || originalConfigs?.TRIP?.warningGeofence,
        assetUnavailableWithCriticalBattery: getBoolValue(
          assetUnavailableWithCriticalBattery,
          originalConfigs?.TRIP?.assetUnavailableWithCriticalBattery
        ),
        holdAmount: holdAmount || originalConfigs?.TRIP?.holdAmount,
        warningSpeed: warningSpeed || originalConfigs?.TRIP?.warningSpeed,
      },

      REGISTRATION: {
        referral: {
          feeConfig: withReferralFeeConfig,
          bonusConfig: withReferralBonusConfig,
          bonusExpireDays: withReferralBonusExpire,
          referrerBonusConfig: withReferralBonusConfigForReferrer,
          referrerBonusExpireDays: withReferralBonusExpireForReferrer,
          bonusCondition: valuesMap[bonusCondition],
          referrerBonusCondition: valuesMap[referrerBonusCondition],
        },
        normal: {
          bonusCondition: valuesMap[bonusConditionWithoutReferral],
          feeConfig: withoutReferralFeeConfig,
          bonusConfig: withoutReferralBonusConfig,
          bonusExpireDays: withoutReferralBonusExpire,
        },
      },

      SECRETS: {
        S3AccessKey,
        S3AccessSecretKey,
        googleMapsAPIKey,
        bucket,
        region_config,
      },
      STRIPE: {
        publicKey: STRIPE_publicKey || paymentGWs?.STRIPE?.publicKey,
        secretKey: STRIPE_secretKey || paymentGWs?.STRIPE?.secretKey,
      },

      BRAINTREE: {
        btEnvironment: BRAINTREE_btEnvironment || originalConfigs?.BRAINTREE?.btEnvironment,
        merchantId: BRAINTREE_merchantId || originalConfigs?.BRAINTREE?.merchantId,
        privateKey: BRAINTREE_privateKey || originalConfigs?.BRAINTREE?.privateKey,
        publicKey: BRAINTREE_publicKey || originalConfigs?.BRAINTREE?.publicKey,
        merchantAccount: BRAINTREE_merchantAccount || originalConfigs?.BRAINTREE?.merchantAccount,
      },

      IYZICO: {
        apiKey: IYZICO_apiKey || originalConfigs?.IYZICO?.apiKey,
        baseUrl: IYZICO_baseUrl || originalConfigs?.IYZICO?.baseUrl,
        secretKey: IYZICO_secretKey || originalConfigs?.IYZICO?.secretKey,
      },
    });
  }
}

export default CarclubConfigsDTO;
