import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";

import { styles } from "./CustomerList.styles";
import SearchList from "../../../../components/UI/SearchList/SearchListWrapper";
import {
  crCustomerList,
  crAccountsRetrieveAccountsUser,
  selectCrCustomers,
  selectCrCustomerAccounts,
} from "../../reservationsSlice";
import AccountsList from "./AccountsList/AccountsList";

function CustomerList({
  classes,
  customerCode,
  customerName,
  customerPhone,
  accountId,
  disabled,
  onSelected,
  onAccountSelected,
}) {
  const dispatch = useDispatch();
  const customers = useSelector(selectCrCustomers);
  const accounts = useSelector(selectCrCustomerAccounts);

  console.log("customers: %o", customers);

  useEffect(() => {
    dispatch(crCustomerList());
    // eslint-disable-next-line
  }, []);
  return (
    <SearchList
      className={classes.root}
      currentFields={{
        key: customerCode,
        label: customerName,
        phone: customerPhone,
      }}
      disabled={disabled}
      header={["Name", "Mobile"]}
      name="Driver"
      data={customers.map((customer) => ({
        key: customer.code,
        label: customer.fullname,
        phone: customer.mobileNumber,
        object: customer,
      }))}
      onSearch={(search) => {
        dispatch(crCustomerList(search));
      }}
      onSelect={({ object }) => {
        if (!disabled) {
          onSelected && onSelected(object);
          dispatch(crAccountsRetrieveAccountsUser({ customerCode: object.code }));
        }
      }}
      searchLabel="Search Driver"
      subList={
        accounts?.length > 0 && (
          <AccountsList
            key={customerCode}
            driverAccounts={accounts}
            accountId={accountId}
            onSelected={onAccountSelected}
            disabled={disabled}
          />
        )
      }
    />
  );
}

export default withStyles(styles)(CustomerList);
