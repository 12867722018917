export const styles = (theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  title: {
    fontSize: 24,
    lineHeight: 1,
    color: "#47525E"
  },
  content: {
    minHeight: "calc(100vh - 200px)"
  },
  tabs: {
    minWidth: 260,
    "& .MuiTab-root": {
      fontSize: 18,
      textTransform: "none",
      "& .MuiTab-wrapper": {
        alignItems: "flex-start"
      }
    },
    "& .Mui-selected": {
      backgroundColor: "#0095D90A"
    },
    "& .MuiTabs-indicator": {
      display: "none"
    }
  }
});