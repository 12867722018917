const styles = (theme) => ({

	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		flexWrap: 'nowrap',
	},

	workspace: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'row',
		alingItems: 'center',
		flexWrap: 'nowrap',
		position: 'relative',
	},

	header: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	list: {
		width: '100%',
		height: '100%',
		paddingLeft: 21,
		paddingRight: 21,
		display: 'flex',
		flexDirection: 'column',
	},

	details: {
		minWidth: '284px',
		maxWidth: '284px',
		paddingRight: 21,
		overflowY: 'auto',
	},

	icon: {
		verticalAlign: 'middle',
	},

	textField: {
		width: '100%',
		borderBottom: '1px solid rgba(224, 224, 224, 1)',
	},

	//HEADER

	titleAndSearch: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	title: {
		fontSize: '22px',
		color: '#47525E',
		marginRight: 16,
	},

	searchBar: {
		width: '333px',
		display: 'inline-flex',
		paddingBottom: 4,
	},

	searchIcon: {
		margin: '10px 0',
	},

	createIcon: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		width: 26,
		height: 26,
	},

	crossIcon: {
		cursor: 'pointer',
	},

	inputRoot: {
		color: 'inherit',
		width: '100%',
	},

	inputInput: {
		paddingTop: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		transition: theme.transitions.create('width'),
		width: '100%',
		borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
	},

	//ERROR
	rootBar: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		margin: 'auto',
		textAlign: 'center',
		lineHeight: 'inherit',
		marginTop: 10,
		minHeight: 18,
		width: '300px',
		maxWidth: '300px',
		fontSize: '14px',
		borderRadius: 10,
		color: 'white',
		wordBreak: 'break-word',
	},

	showBar: {
		opacity: 1,
		transition: 'opacity 1000ms linear',
	},

	hideBar: {
		opacity: 0,
		transition: 'opacity 2500ms linear',
	},

	success: {
		backgroundColor: 'rgb(0, 182, 127)',
	},

	error: {
		backgroundColor: 'rgb(253, 46, 37)',
	},

});

export default styles;