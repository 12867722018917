import makeActionCreator from "../../utils/makeActionCreator";
import createReducer from "../../hooks/createCustomReducer";
import log from "../../utils/logger";

const logger = log("useGoogleMap");

let google;
let drawingManager;
let trafficLayer;

const types = {
  ADD_POLYGON: "ADD_POLYGON",
  SHOW_POLYGON: "SHOW_POLYGON",
  REMOVE_POLYGON: "REMOVE_POLYGON",
  REMOVE_POLYGONS: "REMOVE_POLYGONS",
  SET_GOOGLE: "SET_GOOGLE",
  SET_IS_DRAWING: "SET_IS_DRAWING",
  CLEAR_POLYGONS: "CLEAR_POLYGONS",
};

export const showPolygon = makeActionCreator(types.SHOW_POLYGON, "polygon", "options");
export const addPolygon = makeActionCreator(types.ADD_POLYGON, "id", "polygon");
export const removePolygon = makeActionCreator(types.REMOVE_POLYGON, "id");
export const removePolygons = makeActionCreator(types.REMOVE_POLYGONS);
export const clearPolygons = makeActionCreator(types.CLEAR_POLYGONS);

export const setGoogle = makeActionCreator(types.SET_GOOGLE, "google", "onPolygonComplete");
export const setIsDrawing = makeActionCreator(types.SET_IS_DRAWING, "isDrawing");

const actions = (dispatch) => ({
  drawPolygon: () => {
    if (!drawingManager) {
      return;
    }

    drawingManager.setOptions({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      polygonOptions: {
        editable: true,
      },
    });

    drawingManager.setMap(google.map);

    google.maps.event.addListener(drawingManager, "polygoncomplete", (polygon) => {
      dispatch(addPolygon("NEW", polygon));
    });

    dispatch(setIsDrawing(true));
  },

  removePolygon: (id) => dispatch(removePolygon(id)),

  removePolygons: () => dispatch(removePolygons()),

  setIsDrawing: (isDrawing) => {
    if (!isDrawing) {
      drawingManager.setMap(null);
      drawingManager.setOptions({
        drawingMode: null,
      });
    }
    dispatch(setIsDrawing(isDrawing));
  },

  setupGoogle: (google, onPolygonComplete) => dispatch(setGoogle(google, onPolygonComplete)),

  showPolygon: (id, coords, polygonOptions, fitBounds = false, polygonOnClick) => {
    if (!coords) {
      return;
    }

    const code = id || "NEW";

    const bounds = new google.maps.LatLngBounds();

    const mapCoords =
      coords instanceof google.maps.MVCArray
        ? coords
        : JSON.parse(coords).map((coord) => {
            const newCoord = { lat: coord[0], lng: coord[1] };
            bounds.extend(newCoord);
            return newCoord;
          });

    const { editable, fillColor, fillOpacity, strokeColor, strokeOpacity } = polygonOptions || {};

    const polygon = new google.maps.Polygon({
      map: google.map,
      paths: mapCoords,
      editable,
      fillColor,
      fillOpacity,
      strokeColor,
      strokeOpacity,
    });

    google.maps.event.addListener(polygon, "click", function (e) {
      typeof polygonOnClick === "function" && polygonOnClick();
      polygon.setOptions({
        map: google.map,
        paths: mapCoords,
        fillColor: "#000000",
        fillOpacity: "0.4",
        strokeColor: "#e5e5e5",
        strokeOpacity,
        zIndex: 9999,
      });
    });

    google.maps.event.addListener(polygon, "mouseout", function (e) {
      polygon.setOptions({
        fillColor,
        fillOpacity,
        strokeColor,
        strokeOpacity,
      });
    });

    fitBounds && google.map.fitBounds(bounds);
    dispatch(addPolygon(code, polygon));

    return code;
  },
  clearPolygonsFromMap: () => {
    dispatch(clearPolygons());
  },
});

export const initialState = {
  hasMap: false,
  isDrawing: false,
  canDraw: false,
  polygons: {},
};

const clearPolygonsReducer = (state, action) => {
  if (!google?.maps || !google?.map) {
    return {
      ...state,
    };
  }
  Object.values(state?.polygons || []).forEach((p) => p.setMap(null));
  google.maps.event.clearListeners(google.map, "bounds_changed");
  return {
    ...state,
  };
};

const addPolygonReducer = (state, action) => {
  const polygon = state.polygons[action.id];
  if ("NEW" !== action.id && polygon) {
    polygon.setMap(null);
  }

  return {
    ...state,
    polygons: {
      ...state.polygons,
      [action.id]: action.polygon,
    },
  };
};

const setIsDrawingReducer = (state, action) => ({
  ...state,
  isDrawing: action.isDrawing,
});

const removePolygonReducer = (state, action) => {
  const polygons = state.polygons;
  const polygon = polygons[action.id];

  if (!polygon) {
    return state;
  }

  polygon.setMap(null);
  delete polygons[action.id];

  return {
    ...state,
    polygons,
  };
};

const removePolygonsReducer = (state, action) => {
  const polygons = state.polygons;
  for (let index in polygons) {
    polygons[index].setMap(null);
  }

  const k = {
    ...state,
    polygons: {},
  };
  return k;
};

const setGoogleReducer = (state, action) => {
  google = action.google;
  const { map, maps } = google;

  let canDraw = false;

  try {
    if (maps) {
      drawingManager = new google.maps.drawing.DrawingManager({
        drawingControl: false,
      });

      canDraw = true;

      trafficLayer = new google.maps.TrafficLayer();

      let trafficControlDiv = document.createElement("div");
      new TrafficControl(trafficControlDiv, map, trafficLayer);

      trafficControlDiv.index = 1;
      map.controls[9].push(trafficControlDiv);
    }
  } catch {
    logger.info("Error loading Google Maps libraries");
  }

  return {
    ...state,
    hasMap: !!map,
    canDraw,
  };
};

export const reducer = (state, action) =>
  ({
    [types.ADD_POLYGON]: addPolygonReducer,
    [types.CLEAR_POLYGONS]: clearPolygonsReducer,
    [types.REMOVE_POLYGON]: removePolygonReducer,
    [types.REMOVE_POLYGONS]: removePolygonsReducer,
    [types.SET_GOOGLE]: setGoogleReducer,
    [types.SET_IS_DRAWING]: setIsDrawingReducer,
    undefined: () => state,
  }[action.type](state, action));

export default createReducer({ reducer, actions, defaultState: initialState });

function TrafficControl(controlDiv, map, trafficLayer) {
  // Set CSS for the control border.
  let controlUI = document.createElement("div");
  controlUI.style.backgroundColor = "#fff";
  controlUI.style.border = "2px solid #fff";
  controlUI.style.borderRadius = "3px";
  controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
  controlUI.style.cursor = "pointer";
  controlUI.style.marginRight = "10px";
  controlUI.style.textAlign = "center";
  controlUI.title = "Click to show/hide traffic";
  controlUI.style.height = "30px";
  controlUI.style.width = "65px";
  controlUI.style.display = "flex";
  controlUI.style.justifyContent = "center";
  controlUI.style.alignItems = "center";
  controlDiv.appendChild(controlUI);

  // Set CSS for the control interior.
  let controlText = document.createElement("div");
  controlText.style.color = "rgb(25,25,25)";
  controlText.style.fontFamily = "Roboto,Arial,sans-serif";
  controlText.style.fontWeight = "normal";
  controlText.style.fontSize = "16px";
  controlText.innerHTML = "Traffic";
  controlText.id = "traffic_control_button";
  controlUI.appendChild(controlText);

  // Setup the click event listeners: simply set the map to Chicago.
  controlUI.addEventListener("click", function () {
    const currentMap = trafficLayer.getMap();
    if (null === currentMap || undefined === currentMap) {
      trafficLayer.setMap(map);
      document.getElementById("traffic_control_button").style.fontWeight = "500";
      return;
    }

    trafficLayer.setMap(null);
    document.getElementById("traffic_control_button").style.fontWeight = "normal";
  });
}
