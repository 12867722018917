import { wsCall, requestHelper } from "../../../app/coreSlice";

const assetList_singleCall = async (
  { setMapCenter, updateAssetOnList },
  dispatch, state,
  { deviceLicensePlate }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "ASSET/LIST_SINGLE");
  try {
    const result = await wsCall({
      type: "ASSET",
      subType: "LIST",
      locationId: selectedLocation?.id,
      message: {
        deviceLicensePlate,
      },
    });
    if (!result.error && result?.length > 0) {
      let asset = result[0];
      if (asset.latitude && asset.longitude) {
        dispatch(
          setMapCenter({
            lat: asset.latitude,
            lng: asset.longitude,
          })
        );
      }
      dispatch(updateAssetOnList(asset));
    }
  } catch (_ex) {
    rh.error("Error getting asset.");
  }
  rh.close();
};

export default assetList_singleCall;
