const styles = theme => ({
	root: {
		backgroundColor: 'white',
		borderRadius: '10px',
		bottom: 0,
		boxShadow: theme.shadows[5],
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: '150px',
		left: 0,
		margin: 'auto',
		minHeight: '0',
		outline: 0,
		padding: '20px',
		position: 'absolute',
		right: 0,
		top: 0,
		width: '250px',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	text: {
		width: '100%',
		minHeight: '10px',
		alignItems: 'center',
		display: 'flex',
		padding: '0 10px',
		boxSizing: 'border-box',
	},

	title: {
		fontSize: '18px',
	},
});

export default styles;