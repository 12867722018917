import React from "react";
import { Typography, Grid } from "@material-ui/core";

function MetaError({ className, meta }) {
  if (meta.error && meta.touched) {
    let parsedError = "";
    if (Array.isArray(meta.error)) {
      meta.error.forEach((error) => {
        parsedError += error + " ";
      });
    } else if (typeof meta.error === "object") {
      Object.keys(meta.error).forEach((k) => {
        if (Array.isArray(meta.error[k])) {
          meta.error[k].forEach((error) => {
            parsedError += error + " ";
          });
        }
      });
    } else {
      parsedError = meta.error;
    }

    return (
      <Grid item xs={12} className={className}>
        <Typography variant="caption" color="error" style={{ fontWeight: 300 }}>
          {parsedError}
        </Typography>
      </Grid>
    );
  }
  return null;
}

export default MetaError;
