import { wsCall, requestHelper } from "../../../app/coreSlice";

const customerPrincingPlansCall = async ({ setCustomerPrincingPlans }, dispatch, state, {customerCode}) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "CUSTOMER/PRICING_PLANS");

  try {
    const result = await wsCall({
      type: "CUSTOMER",
      subType: "PRICING_PLANS",
      locationId: selectedLocation?.id,
      message: {customerCode},
    });
    if (result && !result.error) {
      dispatch(setCustomerPrincingPlans(result?.pricingPlans));
    }
  } catch (_ex) {
    console.error(_ex);
    rh.error("Error loading princing plans!");
  }
  rh.close();
};

export default customerPrincingPlansCall;
