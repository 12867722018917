const styles = theme => ({

	root: {
		width: '100%',
	},

	titleSuffix: {
		color: '#757575',
	},

	workspace: {
		width: 'auto',
		height: 'auto',
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		position: 'relative',
		padding: '0px 21px',
	},

	list: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},

	backOrSaveBtn: {
		padding: '5px 16px 5px 10px',
		margin: '0px 12px 0px 0px',
	},

	backOrSaveIcon: {
		paddingRight: '6px',
	},

	errorText: {
		margin: '0 20px',
		flexGrow: '1',
	},

	actionDiv: {
		height: 40,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingLeft: '12px',
	},

	leftSideActions: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignSelf: 'first baseline',
		width: '100%',
		alignItems: 'center',
	},

	rightSideActions: {
		height: 40,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	
	createIcon: {
		display: 'flex',
		flexDirection: 'row-reverse',
		width: '100%',
		'&:hover': {
			cursor: 'pointer',
		},
	},

});

export default styles;
