import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Grid } from "@material-ui/core";
import Button from "../../../components/UI/Buttons/CommonButtons";

import { styles } from "./DeviceForm.styles";
import { formSchema, formFields } from "./DeviceForm.schema";
import { FormFields, Form } from "../../../common/forms";

import {
  assetAssociateActivateDevice,
  selectDeviceAssociatedSuccessTrigger,
  selectCarInfo,
  getCarInfo,
} from "../assetSlice";

/// MSL ::: this props should come from directly from the redux, when the all asset form is refactored

function DeviceForm({
  classes,
  disabled,
  carToken,
  licensePlate,
  provider_token,
  serial_number,
  externalId,
  onRemove,
  onAssociate,
}) {
  const dispatch = useDispatch();
  const associatedSuccessTrigger = useSelector(selectDeviceAssociatedSuccessTrigger);
  const carInfo = useSelector(selectCarInfo);

  useEffect(() => {
    if (associatedSuccessTrigger) {
      onAssociate && onAssociate(associatedSuccessTrigger);
    }
    // eslint-disable-next-line
  }, [associatedSuccessTrigger]);

  useEffect(() => {
    if (!carInfo) {
      dispatch(getCarInfo());
    }
    // eslint-disable-next-line
  }, [carInfo]);

  const onSubmit = (device) => {
    console.log("device: %o", device);
    dispatch(assetAssociateActivateDevice(device));
  };

  return (
    <div className={classes.root}>
      <Form
        schema={formSchema()}
        initialValues={{ carToken, licensePlate, provider_token, serial_number, externalId }}
        onSubmit={onSubmit}
        render={({ submitting, values }) => {
          return (
            <>
              <FormFields fields={formFields(disabled, carInfo?.providers, values)} />
              <Grid container justifyContent="center" className={classes.formButtons}>
                {provider_token ? (
                  <Button
                    buttonStyleName="filled"
                    buttonClassName={classes.redButton}
                    disabled={submitting}
                    label="Remove device"
                    onClick={onRemove}
                  />
                ) : (
                  <Button buttonStyleName="filled" type="submit" disabled={submitting} label="Add device" />
                )}
              </Grid>
            </>
          );
        }}
      />
    </div>
  );
}

export default withStyles(styles)(DeviceForm);
