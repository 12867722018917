import React from 'react';
import {
	Menu,
	MenuItem,
	MenuList,
	withStyles,
} from '@material-ui/core';
import classnames from 'classnames';
import { styles } from './GenericDropdownMenu.styles.js';

const GenericDropdownMenu = ({
	anchorEl,
	classes,
	className,
	options,
	optionHandler,
	closeHandler,
}) => {

	const handleClickAway = (event) => {
		event.stopPropagation();
		closeHandler();
	};

	const menuOptions = options && options.map(element => (
		<MenuItem
			key={element.label}
			className={classes.options}
			onClick={(event) => {
				event.stopPropagation();
				optionHandler(element.onClickParam);
			}}>
			{element.label}
		</MenuItem>
	));

	return (
		<Menu
			className={classnames(classes.root, className)}
			onClose={handleClickAway}
			anchorEl={anchorEl}
			open={Boolean(anchorEl)}
		>
			<MenuList>
				{menuOptions}
			</MenuList>
		</Menu>
	);
};

export default withStyles(styles)(GenericDropdownMenu);