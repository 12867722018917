import React from 'react';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import { styles } from './PricingPlanCalendar.styles';
import classnames from 'classnames';
import List from '../../UI/List/List';
import calendarFields from './calendarFields';
import CalendarCell from './CalendarCell/CalendarCell';
import CommonButtons from '../../UI/Buttons/CommonButtons';
import ErrorDiv from '../../ErrorDiv/ErrorDiv';

const PricingPlanCalendar = ({
	classes,
	className,
	currentScreenSize,
	calendarState,
	calendarAddHandler,
	calendarSubtractHandler,
	dragStartHandler,
	dragOverHandler,
	dragDropHandler,
	closeHandler,
	saveHandler,
	blockSelection,
	setBlockSelection,
	rates,
	error,
	selectedPP,
}) => {

	const {
		name: ppName,
	} = selectedPP || {};

	const calendarRatesInfo = {};
	!!rates && rates.forEach(e => {
		calendarRatesInfo[e.rateNumber] = e.color;
	});

	const title = (
		<Typography className={classes.title}>
			{`Pricing Builder - ${ppName || 'New'}`}
		</Typography>
	);

	const keysArray = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'];

	const hourRowsArray = Array.isArray(calendarState)
		&& calendarState.map((detail, i) => {
			return (<CalendarCell
				key={keysArray[i] + '-' + i}
				info={detail}
				hourPeriod={keysArray[i]}
				hourIndex={i}
				calendarAddHandler={calendarAddHandler}
				calendarSubtractHandler={calendarSubtractHandler}
				dragStartHandler={dragStartHandler}
				dragOverHandler={dragOverHandler}
				dragDropHandler={dragDropHandler}
				calendarRatesInfo={calendarRatesInfo}
			/>);
		});

	const calendar = (
		<List
			className={classnames(classes.subList, className)}
			headerFields={calendarFields(currentScreenSize)}
			overridePadding={true}
			headerClassName={classes.listHeader}
			disableGutters={true}
			isCalendar={true}
		>
			{hourRowsArray}
		</List>
	);

	const selectionDiv = (
		<div
			id='selectionDivCal'
			className={classes.selectionDiv}
		/* styles={calendarStyle} */
		>

		</div>
	);

	const closeButton = (
		<CommonButtons
			className={classes.btn}
			key='closeCalendar'
			buttonStyleName='void'
			onClick={closeHandler}
			label='Close'
		/>
	);

	const saveButton = (
		<CommonButtons
			className={classes.btn}
			key='saveCalendar'
			buttonStyleName='filled'
			onClick={() => {
				saveHandler();
			}}
			label='Save'
		/>
	);

	const openPricingTableButton = false && (
		<CommonButtons
			className={classes.btn}
			key='openPricingTable'
			id='toggleSelectFeatureCalendar'
			buttonStyleName={blockSelection ? 'filled' : 'void'}
			onClick={(e) => {
				e.stopPropagation();
				blockSelection ? setBlockSelection(false)
					: setBlockSelection(true);
			}}
			label={`Toggle Selection Feat  ${blockSelection ? 'ON' : 'OFF'}`}
		/>
	);

	const errorDiv = error && (
		<ErrorDiv
			className={classes.errorText}
			message={error}
		/>
	);

	return (
		<div
			className={classnames(classes.root, className)}
			id='calendarDiv'
		>
			<div className={classes.header}>
				{title}
				<div className={classes.buttonsContainer}>
					{closeButton}
					{openPricingTableButton}
					{saveButton}
				</div>

			</div>
			{selectionDiv}
			{calendar}
			{errorDiv}
		</div>
	);
};

export default withStyles(styles)(PricingPlanCalendar);