import { useRef, useCallback, useMemo } from "react";

const useClickPreventionOnDoubleClick = (onClick, onDoubleClick) => {
	const api = useCancellablePromises();

	const handleClick = useCallback(async () => {
		api.clearPendingPromises();
		const waitForClick = cancellablePromise(delay(300));
		api.appendPendingPromise(waitForClick);

		return waitForClick.promise
			.then(() => {
				api.removePendingPromise(waitForClick);
				onClick();
			})
			.catch(errorInfo => {
				api.removePendingPromise(waitForClick);
				if (!errorInfo.isCanceled) {
					throw errorInfo.error;
				}
			});
	}, [api, onClick]);

	const handleDoubleClick = useCallback(() => {
		api.clearPendingPromises();
		onDoubleClick();
	}, [api, onDoubleClick]);

	return [handleClick, handleDoubleClick];
};

export default useClickPreventionOnDoubleClick;

const useCancellablePromises = () => {
	const pendingPromises = useRef([]);

	const appendPendingPromise = useCallback(promise =>
		pendingPromises.current = [...pendingPromises.current, promise], []);

	const removePendingPromise = useCallback(promise =>
		pendingPromises.current = pendingPromises.current.filter(p => p !== promise), []);

	const clearPendingPromises = useCallback(() => pendingPromises.current.map(p => p.cancel()), []);

	const api = useMemo(() => ({
		appendPendingPromise,
		removePendingPromise,
		clearPendingPromises,
	}), [appendPendingPromise, removePendingPromise, clearPendingPromises]);

	return api;
};

export const cancellablePromise = promise => {
	let isCanceled = false;

	const wrappedPromise = new Promise((resolve, reject) => {
		promise.then(
			value => isCanceled ? reject({ isCanceled, value }) : resolve(value),
			error => reject({ isCanceled, error }),
		);
	});

	return {
		promise: wrappedPromise,
		cancel: () => isCanceled = true,
	};
};

export const delay = n => new Promise(resolve => setTimeout(resolve, n));