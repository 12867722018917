import React from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Grid, Divider } from "@material-ui/core";

import { styles } from "./AssetCommands.styles";
import CentralLock from "./CentralLock/CentralLock";
import SeatBattery from "./SeatBattery/SeatBattery";

import { assetListUpdateAsset, doorsClose, doorsOpen, imoOff, imoOn } from "../../../assetSlice";
import { selectIsCallsLoading } from "../../../../../app/coreSlice";
import { deviceSeatBattery } from "../../../../device/deviceSlice";
import Immobilizer from "./Immobilizer/Immobilizer";
import ScooterStartStop from "./ScooterStartStop/ScooterStartStop";
import CommonButtons from "../../../../../components/UI/Buttons/CommonButtons";

function AssetCommands({ classes, asset }) {
  const dispatch = useDispatch();
  const doorsLoading = useSelector(selectIsCallsLoading(["DOORS/CLOSE", "DOORS/OPEN"]));
  const seatBatteryLoading = useSelector(selectIsCallsLoading(["DEVICE/SEAT_BATTERY"]));
  const immobilizerLoading = useSelector(selectIsCallsLoading(["IMO/ON", "IMO/OFF"]));

  // console.log("doorsLoading: %o", doorsLoading);
  // console.log("asset: %o", asset);

  const seatBatteryHandler = () => {
    dispatch(deviceSeatBattery({ carToken: asset.assetToken }));
  };

  const centralLockHandler = () => {
    asset.doors
      ? dispatch(doorsOpen({ carToken: asset.assetToken }))
      : dispatch(doorsClose({ carToken: asset.assetToken }));
  };

  const immobilizerHandler = () => {
    asset.immobilizer
      ? dispatch(imoOff({ carToken: asset.assetToken }))
      : dispatch(imoOn({ carToken: asset.assetToken }));
  };

  const assetRefreshHandler = () => {
    dispatch(assetListUpdateAsset({ deviceLicensePlate: asset.licensePlate }));
  };

  if (!asset) {
    return null;
  }

  return (
    <Grid container className={classes.root} direction="column" spacing={1} wrap="nowrap">
      <Grid item>
        {asset.assetTypeName === "SCOOTER" ? (
          <Grid container display="row" alignItems="center" justifyContent="center">
            {/* SCOOTER */}
            <Grid item xs={6}>
              <SeatBattery disabled={seatBatteryLoading} onClick={seatBatteryHandler} />
            </Grid>
            <Grid item xs={6}>
              <ScooterStartStop
                disabled={immobilizerLoading}
                checked={!asset.immobilizer}
                tooltip={asset.immobilizer ? "Start" : "Stop"}
                onChange={immobilizerHandler}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container display="row" alignItems="center" justifyContent="center">
            {/* CAR */}
            <Grid item xs={6}>
              <CentralLock
                disabled={doorsLoading}
                checked={asset.doors}
                tooltip={asset.doors ? "Unlock" : "Lock"}
                onChange={centralLockHandler}
              />
            </Grid>
            <Grid item xs={6}>
              <Immobilizer
                disabled={immobilizerLoading}
                checked={asset.immobilizer}
                tooltip={asset.immobilizer ? "Disable" : "Enable"}
                onChange={immobilizerHandler}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item>
        <Grid container display="row" alignItems="center" justifyContent="center">
          <Grid item>
            <CommonButtons
              buttonStyleName="filled"
              key="gpsRefreshCommands"
              label="GPS Refresh"
              onClick={assetRefreshHandler}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(AssetCommands);
