import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import customerService from "../../../services/customer/ws";
import locationService from "../../../services/location";
import UpsertAccount from "./UpsertAccount";
import log from "../../../utils/logger";
import { selectDefaultLocation } from "../../../features/carclub/carclubSlice";

const logger = log("UpsertAccountWrapper");

const UpsertAccountWrapper = (props) => {
  const { createAccount, updateAccount, openAccount, exitUpsert, modal, modals } = props;

  const defaultLocationInStore = useSelector(selectDefaultLocation);

  const [fetchedCustomersList, setFetchedCustomersList] = useState(false);
  const [accountCode, setAccountCode] = useState();
  const [currentAccount, setCurrentAccount] = useState();

  const [placeholder, setPlaceholder] = useState({
    dueDays: 20,
  });
  const [locations, setLocations] = useState([]);
  const [locationsObj, setLocationsObj] = useState();

  const isMounted = useRef(false);
  const [error, setError] = useState();

  const isOnCreateMode = modal === modals.CREATE ? true : false;

  useEffect(() => {
    isMounted.current = true;
    asyncInfoGetter();
    return () => {
      isMounted.current = false;
    };

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isOnCreateMode) {
      setPlaceholder({});
      setOpenAccountToEdit(openAccount);
    }

    //eslint-disable-next-line
  }, [isOnCreateMode, openAccount]);

  useEffect(() => {
    const location = locationsObj && locationsObj[currentAccount?.locationId];
    const locationName = location?.name;
    onChangeHandler("locationName", locationName);
    //eslint-disable-next-line
  }, [currentAccount, locationsObj]);

  const asyncInfoGetter = async () => {
    try {
      const locationsPromise = locationService.listAllLocations();
      const locationsResult = await locationsPromise;
      const mappedLocationResult = Object.values(locationsResult || {}).map(({ code, name }) => ({
        key: code,
        label: name,
      }));
      if (!!isMounted.current) {
        setLocations(mappedLocationResult);
        setLocationsObj(locationsResult);
      }
    } catch (error) {
      logger.warn(`Could not retrive locations.`);
    }
  };

  const setOpenAccountToEdit = (account) => {
    setAccountCode(account.code);
    setCurrentAccount(account);
    onChangeHandler("name", account.name);
    onChangeHandler("ownerCode", account.ownerCode);
    onChangeHandler("ownerName", account.owner);
    onChangeHandler("email", account.email);
    onChangeHandler("phone", account.phone);
    onChangeHandler("enabled", account.enabled);
    onChangeHandler("city", account.city);
    onChangeHandler("street", account.street);
    onChangeHandler("doorNumber", account.number);
    onChangeHandler("zipCode", account.zipCode);
    onChangeHandler("taxNumber", account.taxNumber);
    onChangeHandler("dueDays", account.dueDays);
    onChangeHandler("autoCharge", account.autoCharge);
    onChangeHandler("autoInvoice", account.autoInvoice);
    onChangeHandler("days", account.days);
    onChangeHandler("locationId", account.locationId);
    onChangeHandler("invoiceDay", account.invoiceDay);
  };

  const upsertAccountHandler = (event) => {
    setError();
    event.preventDefault();
    const accountToUpsert = {
      name: placeholder?.name,
      owner: placeholder?.ownerName,
      ownerCode: placeholder?.ownerCode,
      email: placeholder?.email,
      phone: placeholder?.phone,
      enabled: placeholder?.enabled,
      city: placeholder?.city,
      street: placeholder?.street,
      number: placeholder?.doorNumber,
      zipCode: placeholder?.zipCode,
      taxNumber: placeholder?.taxNumber,
      dueDays: placeholder?.dueDays,
      autoCharge: placeholder?.autoCharge,
      autoInvoice: placeholder?.autoInvoice,
      invoiceDay: placeholder?.invoiceDay,
    };

    const checkResult = hasMissingFields(accountToUpsert);
    if (!!checkResult) {
      setError(checkResult);
      return;
    }

    if (isOnCreateMode) {
      createAccount(accountToUpsert);
      exitUpsert();
      return;
    }

    accountToUpsert.code = accountCode;
    updateAccount(accountToUpsert);
    exitUpsert();
  };

  const getLocations = useCallback(
    ({ string: label }) => {
      const filteredLocations = locations.filter(
        (location) => !!(location.label || "").toUpperCase().includes((label || "").toUpperCase())
      );
      return filteredLocations;
    },
    [locations]
  );

  const getCustomers = useCallback(async ({ string: label }) => {
    // protection to allow empty search
    if (!label) {
      label = "";
    }
    const filter = {
      selectBy: "name",
      selectByString: label,
    };
    const customers = await customerService.searchCustomers(filter);

    const filteredCustomers = customers.map((e) => ({
      key: e.code,
      label: e.fullName,
      code: e.code,
      email: e.email,
      phone: e.phoneNumber,
    }));

    return filteredCustomers;
  }, []);

  const onChange = useCallback((data) => setPlaceholder((prev) => ({ ...prev, ...data })), []);

  const onChangeHandler = (fieldString, value) => {
    if (fieldString === "invoiceDay" && (!isNatural(value) || value === "" || value > 31)) {
      if (checkIfEmptyString(value)) {
        onChange({ [fieldString]: value });
      }
      return;
    }
    if (fieldString === "dueDays" && (!isNatural(value) || value === "")) {
      if (checkIfEmptyString(value)) {
        onChange({ [fieldString]: value });
      }
      return;
    }
    onChange({ [fieldString]: value });
  };

  return (
    <UpsertAccount
      {...props}
      createAccount={createAccount}
      error={error}
      exitUpsert={exitUpsert}
      fetchedCustomersList={fetchedCustomersList}
      getCustomers={getCustomers}
      getLocations={getLocations}
      isOnCreateMode={isOnCreateMode}
      onChangeHandler={onChangeHandler}
      openAccount={openAccount}
      placeholder={placeholder}
      setError={setError}
      setFetchedCustomersList={setFetchedCustomersList}
      updateAccount={updateAccount}
      upsertAccountHandler={upsertAccountHandler}
      defaultLocationInStore={defaultLocationInStore}
    />
  );
};

const isNatural = (n) => {
  const p = parseInt(n, 10);
  return p >= 0 && p.toString() === n;
};

const checkIfEmptyString = (string) => {
  if (!string || typeof string !== "string") {
    return true;
  }

  const newString = string.replace(/\s/g, "");
  if (!newString) {
    return true;
  }

  return false;
};

const hasMissingFields = (account) => {
  if (!account.ownerCode) return "An account owner is required, please choose one";
  return false;
};

export default UpsertAccountWrapper;
