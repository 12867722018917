import React from "react";
import DetailsDiv from "../../../../UI/DetailsDiv/DetailsDiv";
import DetailsRow from "../../../../UI/DetailsDiv/DetailsRow/DetailsRow";
import { Typography, withStyles } from "@material-ui/core";
import styles from "./Details.styles";

const Detail = ({ classes, info }) => {
  return (
    <DetailsDiv title="Detail">
      <DetailsRow
        label="Name"
        detail={<Typography className={classes.typography}>{info.smallDescription}</Typography>}
      />
      <DetailsRow
        label="Description"
        detail={<Typography className={classes.typography}>{info.description}</Typography>}
        column
      />
      <DetailsRow label="Date" detail={<Typography className={classes.typography}>{info.startDate}</Typography>} />
    </DetailsDiv>
  );
};

export default withStyles(styles)(Detail);
