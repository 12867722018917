import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const deviceCreateTeltonikaCall = async (
  { deviceListTeltonika },
  dispatch,
  state,
  { serialNumber, imei, model, protocol, debugMode }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "DEVICE/CREATE_TELTONIKA");
  try {
    const result = await wsCall({
      type: "DEVICE",
      subType: "CREATE_TELTONIKA",
      locationId: selectedLocation?.id,
      message: {
        serialNumber,
        imei,
        model,
        protocol,
        debugMode,
      },
    });
    if (result && !result.error) {
      dispatch(setSuccess("Teltonika Device Created successfully"));
      dispatch(deviceListTeltonika({ clear: true }));
    } else {
      rh.error(result?.description || "Error creating device!");
    }
  } catch (_ex) {
    rh.error("Error creating device.");
  }
  rh.close();
};

export default deviceCreateTeltonikaCall;
