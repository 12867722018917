const styles = theme => ({
	// CHARGING MODAL
	chargingModalTitle: {
		fontSize: '16px',
		alignSelf: 'flex-start',
		paddingBottom: 20,
	},

	chargingModalParagraph: {
		paddingBottom: 20,
	},

	btnDiv: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		alignItems: 'center',
	},

	chargingModalButton: {
		paddingBottom: 30,
	},

	chargingModalRoot: {
		position: 'absolute',
		top: 'calc(50vh - 205px)',
		left: 'calc(50vw - 170px)',
		width: 340,
		height: 410,
		backgroundColor: '#fff',
		padding: '25px 30px',
		boxSizing: 'border-box',
		boxShadow: theme.shadows[5],
	},

	chargingModalWorkspace: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},

	labelProps: {
		color: '#47525E',
		zIndex: 999,
	},

	inputPropsMultine: {
		fontSize: 12,
		width: 100,
	},

	multiline: {
		padding: '0 !important',
		margin: '0px 0px 8px 0px',
		height: '75px',
	},

	fieldMultiline: {
		width: '100%',
		fontSize: '11px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
	},

	inputBasePropsMultiline: {
		padding: 3,
	},

});

export default styles;