const styles = () => ({

	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '300px',
		height: 'auto',
		justifyContent: 'space-between',
		padding: '25px',
	},

	valueDiv: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
	},

	buttons: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
	},

	title: {
		fontSize: '22px',
	},

	calendarTitle: {
		fontSize: '14px',
	},

	error: {
		borderRadius: '21px',
		backgroundColor: 'red',
		color: 'white',
		margin: '10px',
	},

	datePickersContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
	},

	pickers: {
		backgroundColor: '#FFFFFF',
		border: '1px solid #DDDDDD',
		borderRadius: '2px',
		boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.12)',
		width: '106px',
		height: '32px',
	},

	pickerLabel: {
		color: '#4C4C4E',
		// FIXME some of these should be given by the Mui.theme
		fontSize: '12px',
		fontWeight: '400',
		lineHeight: '34px',
		width: '32px',
		textAlign: 'left',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},

	input: {
		marginTop: '0 !important',
		padding: '3px !important',
		// FIXME some of these should be given by the Mui.theme
		color: '#89898B',
		fontSize: '12px',
		fontWeight: '400',
		lineHeight: '14px',
		textAlign: 'left',
	},

	inputIcon: {
		padding: '2px',
	},

	labelAndInputCheckbox: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		padding: '2px 0 10px 0',
		alignItems: 'end',
		boxSizing: 'border-box',
		height: 40,
	},

	checkboxLabel: {
		fontSize: '12px',
		height: 35,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'end',
		paddingRight: 8,
	},

	checkbox: {
		padding: '0px',
		height: 25,
		alignItems: 'end',
	},

	inputPropsMultine: {
		fontSize: 12,
		width: 100,
	},

	multiline: {
		padding: 0,
		margin: '0px 0px 8px 0px',
	},

	labelProps: {
		color: '#47525E',
		zIndex: 999,
	},
	
	fieldMultiline: {
		width: '100%',
		height: '100%',
		fontSize: '11px',
		display: 'flex',
		alignSelf: 'center',
		backgroundColor: '#fff',
	},

	subtitle14px: {
		fontSize: '14px',
		padding: '4px 0',
		color: 'rgba(0, 0, 0, 0.54)',
	},

	endAdornment: {
		height: 45,
		fontSize: '12px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		marginLeft: 8,
	},
});

export default styles;