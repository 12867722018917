export const styles = theme => ({

	root: {
	},

	options: {
		color: '#4C4C4E',
		lineHeight: '12px',
		textAlign: 'left',
		// Selects every two elements among any group of siblings.
		'&NthChild(2n)': {
			marginRight: theme.spacing(3),
		},
		'&:hover': {
			cursor: 'pointer',
		},
    
	},
});