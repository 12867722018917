import React from "react";
import * as Yup from "yup";
import { TextField } from "../../../common/forms";

export function formSchema() {
  return Yup.object().shape({
    email: Yup.string().required("Username required"),
    password: Yup.string().required("Password Required"),
  });
}

export function formFields({ disabled }) {
  return [
    {
      size: 12,
      field: <TextField id="email" type="email" label="Login" required disabled={disabled} />,
    },
    {
      size: 12,
      field: <TextField id="password" type="password" label="Password" required disabled={disabled} />,
    },
  ];
}
