import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/AddCircle';
import List from './List/ListWrapper';
import Searchbar from '../../UI/Searchbar/Searchbar';
import withPermission from '../../../hoc/withPermission';
import styles from './Banner.styles';
import BannerDetails from './BannerDetails/BannerDetails';
import UpdateBanner from './UpdateBanner/UpdateBannerWrapper';

const Banner = ({
	banners,
	classes,
	disableButtons,
	error,
	filter,
	isEditing,
	lastPage,
	onDelete,
	onEdit,
	onEnable,
	onSearch,
	onSelect,
	onUpdate,
	selected,
	setDisableButtons,
	setError,
	setFilter,
	setIsEditing,
	defaultLocation,
}) => {
	const bannersList = (
		<List
			banners={banners}
			disableButtons={disableButtons}
			lastPage={lastPage}
			onDelete={onDelete}
			onEdit={onEdit}
			onEnable={onEnable}
			onSearch={onSearch}
			onSelect={onSelect}
			selected={selected}
		/>
	);

	const drawer = (
		<Drawer
			anchor='right'
			onClose={() => !disableButtons && onEdit()}
			open={isEditing}
		>
			<div className={classes.drawer}>
				<UpdateBanner
					banner={selected && banners?.find(({ id }) => id === selected)}
					disableButtons={disableButtons}
					error={error}
					onCancel={onEdit}
					onSave={onUpdate}
					setDisableButtons={setDisableButtons}
					setError={setError}
					setIsEditing={setIsEditing}
				/>
			</div>
		</Drawer>
	);

	const title = (
		<Typography className={classes.title}>
			{defaultLocation?.name} Banners
		</Typography>
	);

	const createButton = (
		<CreateButton
			classes={classes}
			onClick={() => {
				onEdit(true);
			}}
		/>
	);

	const searchbar = (
		<div className={classes.searchbar}>
			{title}
			<Searchbar
				disabled={disableButtons}
				filter={filter}
				label='Designation'
				onSearch={() => onSearch(true)}
				setFilter={setFilter}
			/>
			{createButton}
		</div>
	);

	const details = selected && banners?.find(({ id }) => id === selected) && (
		<BannerDetails
			banner={banners?.find(({ id }) => id === selected)}
		/>
	);

	return (
		<div className={classes.root}>
			<div className={classes.workspace}>
				{drawer}
				<div className={classes.list}>
					{searchbar}
					{bannersList}
				</div>
				<div className={classes.details}>
					{details}
				</div>
			</div>
		</div>
	);
};

const CreateButton = withPermission(
	[{ resource: ['carclub', 'banners', 'create'] }]
)(({
	classes,
	onClick,
}) =>
	(
		<div className={classes.create}>
			<Tooltip title="Add Banner">
				<CreateIcon
					className={classes.createButton}
					onClick={onClick}
				/>
			</Tooltip>
		</div>
	)
);

export default withStyles(styles)(Banner);