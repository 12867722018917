import React, {
	useCallback,
	useEffect,
	useState,
} from 'react';
import bannersService from '../../../services/banners';
import log from '../../../utils/logger';
import Banner from './Banner';
import withPermission from '../../../hoc/withPermission';

const logger = log('Banners');

const BannerWrapper = props => {
	const [banners, setBanners] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [disableButtons, setDisableButtons] = useState(false);
	const [error, setError] = useState();
	const [filter, setFilter] = useState('');
	const [isEditing, setIsEditing] = useState(false);
	const [lastPage, setLastPage] = useState(true);
	const [selected, setSelected] = useState();

	useEffect(() => {
		const onMount = async () => {
			setDisableButtons(true);

			try {
				const { bannerLocs, lastPage } = await bannersService.listBanners();

				setBanners(bannerLocs);
				setLastPage(lastPage);
				setCurrentPage(1);
			} catch (e) {
				logger.warn(e.message || e.description);
			} finally {
				setDisableButtons(false);
			};
		};

		onMount();
	}, []);

	const onSearch = useCallback(async reset => {
		setDisableButtons(true);

		try {
			const { bannerLocs, lastPage } = await bannersService.listBanners(filter, reset ? 1 : currentPage + 1);

			if (reset) {
				setSelected();
				setBanners(bannerLocs);
				setLastPage(lastPage);
				setCurrentPage(1);
				return;
			}
			setBanners(prev => [...prev, ...bannerLocs]);
			setLastPage(lastPage);
			setCurrentPage(prev => prev + 1);
		} catch (e) {
			logger.warn(e.message || e.description);
		} finally {
			setDisableButtons(false);
		};
	}, [currentPage, filter]);

	const onDelete = useCallback(async banner => {
		if (!banner) return;

		setDisableButtons(true);

		try {
			await bannersService.deleteBanner(banner);

			setBanners(prev => [...prev.filter(({ id }) => id !== banner.id)]);
		} catch (e) {
			logger.warn(e.message || e.description);
		} finally {
			setDisableButtons(false);
		};
	}, []);

	const onEnable = useCallback(async banner => {
		if (!banner) return;

		setDisableButtons(true);

		try {
			await bannersService.enableBanner(banner);

			setBanners(prev => [...prev.map(prevBanner => prevBanner.id === banner.id
				? { ...prevBanner, enabled: !prevBanner?.enabled }
				: prevBanner)]);
		} catch (e) {
			logger.warn(e.message || e.description);
		} finally {
			setDisableButtons(false);
		};
	}, []);

	const onUpdate = useCallback(async banner => {
		setDisableButtons(true);

		try {
			if (banner?.id) {
				const updatedBanner = await bannersService.updateBanner(banner);

				setBanners(prev => [...prev.map(prevBanner => prevBanner.id === updatedBanner.id
					? { ...updatedBanner, itemCount: updatedBanner?.items?.length || 0 }
					: prevBanner
				)]);

				setSelected(updatedBanner?.id);
				setIsEditing(false);
				return;
			};

			const newBanner = await bannersService.createBanner(banner);

			setBanners(prev => [...prev, { ...newBanner, itemCount: newBanner?.items?.length || 0 }]);
			setSelected(newBanner?.id);
			setIsEditing(false);
			setError();
		} catch (e) {
			logger.warn(e.message || e.description);
			setError(e.message || e.description);
		} finally {
			setDisableButtons(false);
		};
	}, []);

	const onSelect = useCallback(async id => {
		if (!id || id === selected) return setSelected(id);

		setDisableButtons(true);

		try {
			const selectedBanner = await bannersService.getBanner(id);

			setBanners(prev => [...prev.map(banner => banner.id === selectedBanner.id
				? { ...banner, ...selectedBanner }
				: banner
			)]);

			setSelected(selectedBanner?.id);
		} catch (e) {
			logger.warn(e.message || e.description);
		} finally {
			setDisableButtons(false);
		};
	}, [selected]);

	const onEdit = useCallback(id => {
		if (!id) {
			setIsEditing(false);
			return;
		};

		onSelect(typeof id !== 'boolean' ? id : undefined);
		setIsEditing(true);
	}, [onSelect]);

	return (
		<Banner
			{...props}
			banners={banners}
			disableButtons={disableButtons}
			error={error}
			filter={filter}
			isEditing={isEditing}
			lastPage={lastPage}
			onDelete={onDelete}
			onEdit={onEdit}
			onEnable={onEnable}
			onSearch={onSearch}
			onSelect={onSelect}
			onUpdate={onUpdate}
			selected={selected}
			setDisableButtons={setDisableButtons}
			setError={setError}
			setFilter={setFilter}
			setIsEditing={setIsEditing}
		/>
	);
};

const permissions = [{ resource: ['carclub', 'banners', 'read'] }];

export default withPermission(permissions)(BannerWrapper);