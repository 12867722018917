export const styles = theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexBasis: '45%',
		width: '100%',
		height: '100%',
		minHeight: 250,

	},

	title: {
		boxSizing: 'border-box',
		margin: '0 20px',
		textAlign: 'left',
		fontSize: 14,
		width: '100%',
		borderBottom: '2px solid #009BCC',
	},

	field: {
		paddingBottom: 6, 
	 },

	helperText: {
		textAlign: 'left',
		color: '#C8C8C8',
		fontSize: 11,
	},

	button: {
		padding: 10,
		textAlign: 'center',
	},

	message: {
		backgroundColor: '#0f2c',
		border: `2px solid ${theme.palette.primary}`,
	},

	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		margin: '10px 20px',
	},
});
