import React, { useState } from "react";
import PricingPlanDetailsView from "./PricingPlanDetailsView";

const PricingPlanDetailsViewWrapper = (props) => {
  const { closeHandler, editMode, placeholder, placeholderSetters, selectedPricingPlan, updatePricingPlan } = props;

  const [modal, setModal] = useState(null);
  const [error, setError] = useState(null);
  const [selectedPricingPlanDetail, setSelectedPricingPlanDetail] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const handleBackSaveBtn = async () => {
    setError();

    if (hasUnsavedChanges) {
      const result = placeholder.details && placeholder.details.filter((detail) => !!detail.isDefault).length;

      if (result !== 1) {
        setError("You must have one, and only one, default pricing plan.");
        return;
      }

      await updatePricingPlan(placeholder);

      editMode.setOff();
      setHasUnsavedChanges(false);
      return;
    }

    editMode.setOff();
    setHasUnsavedChanges(false);
    placeholderSetters.resetPlaceholder();
    closeHandler();
  };

  const handleCancelChangesBtn = () => {
    setError();

    const detailsClone =
      !!selectedPricingPlan &&
      !!selectedPricingPlan.details &&
      Array.isArray(selectedPricingPlan.details) &&
      selectedPricingPlan.details.map((detail) => ({ ...detail }));

    detailsClone && placeholderSetters.setDetails(detailsClone);
    setHasUnsavedChanges(false);
  };

  return (
    <PricingPlanDetailsView
      {...props}
      error={error}
      handleBackSaveBtn={handleBackSaveBtn}
      handleCancelChangesBtn={handleCancelChangesBtn}
      hasUnsavedChanges={hasUnsavedChanges}
      modal={modal}
      selectedPricingPlanDetail={selectedPricingPlanDetail}
      setError={setError}
      setHasUnsavedChanges={setHasUnsavedChanges}
      setModal={setModal}
      setSelectedPricingPlanDetail={setSelectedPricingPlanDetail}
    />
  );
};

export default PricingPlanDetailsViewWrapper;
