import { ASSET_SET_LOCATION, ASSET_SET_SELECTED_ASSET_TOKEN, ASSET_UPDATE } from "./actionTypes";
import makeActionCreator from "../../../utils/makeActionCreator";
import assetService from "../../../services/asset";
import log from "../../../utils/logger";

const logger = log("Asset.action");

export const assetSetSelectedAssetToken = makeActionCreator(ASSET_SET_SELECTED_ASSET_TOKEN, "assetToken");

export const assetUpdate = makeActionCreator(ASSET_UPDATE, "asset");

export const assetChangeLocation = makeActionCreator(ASSET_SET_LOCATION, "location");

export const assetGetZones = ({ assetToken, deviceToken, licensePlate }) => {
  return async (dispatch) => {
    if (!assetToken || !deviceToken || !licensePlate) {
      logger.debug(`Asset token, device token and license plate must be provided.`);
    }

    if (!assetToken) {
      return;
    }

    let zones;

    try {
      zones = await assetService.listAssociatedZones({
        assetToken,
        deviceToken,
      });
    } catch (error) {
      logger.info(`Cannot get zones from asset ${assetToken}`);
    }

    dispatch(assetUpdate({ assetToken, deviceToken, licensePlate, zones }));
  };
};
