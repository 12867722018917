import React from "react";
import { DateTimePicker } from "@material-ui/pickers";
import styles from "./ReservationDatePicker.styles";
import classnames from "classnames";
import { withStyles } from "@material-ui/core";
import moment from "moment";

const DatePicker = ({
  classes,
  className,
  endDate,
  endDateHandler,
  endDateLabel,
  hasFuture,
  minDate,
  startDate,
  startDateHandler,
  startDateLabel,
}) => {
  // console.log("DatePicker: %o", { startDate, endDate });
  const datePicker = (date, label, handler) => (
    <DateTimePicker
      ampm={false}
      className={classes.picker}
      clearable
      disableFuture={!hasFuture}
      format="DD/MM/YYYY HH:mm"
      inputVariant="outlined"
      label={label}
      minDate={minDate && new Date(minDate)}
      onChange={(date) => handler(moment.isMoment(date) ? date.format("YYYY-MM-DD HH:mm:ss") : null)}
      value={date}
      InputProps={{ classes: { input: classes.pickerInput } }}
      InputLabelProps={{ classes: { outlined: classes.outlined } }}
    />
  );

  return (
    <div className={classnames(classes.root, className)}>
      {datePicker(startDate, startDateLabel, startDateHandler)}
      {datePicker(endDate, endDateLabel, endDateHandler)}
    </div>
  );
};

export default withStyles(styles)(DatePicker);
