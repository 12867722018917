const styles = (theme) => ({

	root: {
		backgroundColor: 'white',
		bottom: 0,
		boxShadow: '5px 5px 11px gray',
		display: 'flex',
		flexDirection: 'column',
		overflow: 'auto',
		position: 'absolute',
		scrollbarWidth: 'thin',
		top: 0,
		width: 368 + theme.spacing(4),
	},

	virtualList: {
		scrollbarWidth: 'thin', //FIREFOX
		//CHROME
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},

	header: {
		display: 'flex',
		justifyContent: 'space-around',
	},

	addButton: {
		position: 'absolute',
		right: 0,
		top: 0,
	},

	scrollable: {
		overflow: 'scroll',
		scrollbarWidth: 'thin', //FIREFOX
		//CHROME
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
		// paddingBottom: 5,
	},

	listItem: {
		// paddingRight: 0, Removed for the buttons to be even, I think it's ok but will leave here 
		borderTop: '1px solid',
		borderColor: theme.palette.grey['300'],
		paddingBottom: 0,
		paddingTop: 0,
	},
});

export default styles;
