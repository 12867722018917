import {
  CARCLUB_CLEAR_FLOWS,
  CARCLUB_CLEAR_NOTIFICATIONS,
  CARCLUB_REMOVE_DEFAULT_LOCATION,
  CARCLUB_REMOVE_NOTIFICATION,
  CARCLUB_SET_APP,
  CARCLUB_SET_CARCLUBS,
  CARCLUB_SET_FEATURES,
  CARCLUB_SET_FLOWS,
  CARCLUB_SET_LOCALE,
  CARCLUB_SET_LOCATIONS,
  CARCLUB_SET_NOTIFICATIONS,
  CARCLUB_SET_PATTERNS,
  CARCLUB_SET_TIMEZONE,
} from "../../actions/carclub/actionTypes";
import * as mutate from "./mutators";
import { pipe } from "../utils";
import createReducer from "../../utils/createReducer";
import { Map } from "immutable";
import log from "../../../utils/logger";

const logger = log("carclub.reducer");

logger.warn("Carclub definitions are hardcoded for MBG");

const initialState = Map({
  code: "MBG",
  id: "0426c25e-6b63-469e-a530-05dc044387a1",
  //imgSrc: "../../../../assets/icons/logo_mobiag_line.svg",
  imgSrc: "https://www.techstars.com/uploads/Mobiag_logo-e1465317363605.png",

  color: "#0197c6",
  theme: {
    headerColor: "#0197c6",
    buttonsColor: "#0197c6",
  },
  locations: null,
  defaultLocation: Map(),
  patterns: Map({
    currency: Map({
      decimalPlaces: null,
      decimalSymbol: null,
      thousandSymbol: null,
      symbol: null,
    }),
    time: Map({
      hourMinutes: null,
      hourMinutesSeconds: null,
      date: null,
      dateTime: null,
    }),
  }),
  refundFees: Map({
    percentage: 0,
    fixed: 0,
  }),
  locale: null,
  timezone: null,
  wasl: null,
  //FIXME: Remove hardcoded dashboardMetrics
  dashboardMetrics: {
    reservation: {
      RESERVATION_TOTAL: {
        max: 200, // note that, for now, this is a completely arbitrary value
      },
      RESERVATION_SUCCESS: {
        max: 150, // note that, for now, this is a completely arbitrary value
      },
    },
    payment: {
      PAYMENT_CHARGED: {
        max: 200, // note that, for now, this is a completely arbitrary value
      },
      PAYMENT_DUE: {
        max: 1000, // note that, for now, this is a completely arbitrary value
      },
    },
  },
  apps: Map(),
  features: Map(),
  flows: Map(),
  notifications: Map(),
  carclubs: Map(),
  tawtheeq: null,
});

const carclubSet = (state, action) => {
  const {
    alertEndPoint,
    apps,
    code,
    color,
    dashboardEndPoint,
    locale,
    locations,
    patterns,
    refundFees,
    tawtheeq,
    theme,
    timezone,
    wasl,
  } = action.carclub;

  return pipe(
    [
      mutate.setAlertEndPoint(alertEndPoint),
      mutate.setApps(apps),
      mutate.setCode(code),
      mutate.setColor(color),
      mutate.setDashboardEndPoint(dashboardEndPoint),
      mutate.setLocale(locale),
      mutate.setLocations(locations, "id"),
      mutate.setPatterns(patterns),
      mutate.setRefundFees(refundFees),
      mutate.setTawtheeq(tawtheeq),
      mutate.setTheme(theme),
      mutate.setTimezone(timezone),
      mutate.setWasl(wasl),
    ],
    state
  );
};



const carclubSetLocations = (state, action) => {
  return pipe([mutate.setLocations(action.locations, "id")], state);
};

const carclubSetPatterns = (state, action) => {
  return pipe([mutate.setPatterns(action.patterns)], state);
};

const carclubSetLocale = (state, action) => {
  return pipe([mutate.setLocale(action.locale)], state);
};

const carclubSetTimezone = (state, action) => {
  return pipe([mutate.setTimezone(action.timezone)], state);
};

const carclubSetFeatures = (state, action) => {
  return pipe([mutate.setFeatures(action.features, "id")], state);
};

const carclubSetFlows = (state, action) => {
  return pipe([mutate.setFlows(action.flows, "id")], state);
};

const carclubSetNotifications = (state, action) => {
  return pipe([mutate.setNotifications(action.notifications, "id")], state);
};

const carclubClearNotifications = (state) => {
  return state.update("notifications", (map) => map.clear());
};

const carclubRemoveNotification = (state, action) => {
  return state.deleteIn(["notifications", action.id]);
};

const carclubSetCarclubs = (state, action) => {
  return pipe([mutate.setCarclubs(action.carclubs, "id")], state);
};

const carclubClearFlows = (state) => {
  return state.update("flows", (map) => map.clear());
};

const carclubRemoveDefaultLocation = (state) => {
  return pipe([mutate.setDefaultLocation(Map())], state);
};

const carclubReducer = createReducer(initialState, {
  [CARCLUB_CLEAR_FLOWS]: carclubClearFlows,
  [CARCLUB_CLEAR_NOTIFICATIONS]: carclubClearNotifications,
  [CARCLUB_REMOVE_DEFAULT_LOCATION]: carclubRemoveDefaultLocation,
  [CARCLUB_REMOVE_NOTIFICATION]: carclubRemoveNotification,
  [CARCLUB_SET_APP]: carclubSet,
  [CARCLUB_SET_CARCLUBS]: carclubSetCarclubs,
  [CARCLUB_SET_FEATURES]: carclubSetFeatures,
  [CARCLUB_SET_FLOWS]: carclubSetFlows,
  [CARCLUB_SET_LOCALE]: carclubSetLocale,
  [CARCLUB_SET_LOCATIONS]: carclubSetLocations,
  [CARCLUB_SET_NOTIFICATIONS]: carclubSetNotifications,
  [CARCLUB_SET_PATTERNS]: carclubSetPatterns,

  [CARCLUB_SET_TIMEZONE]: carclubSetTimezone,
});

export default carclubReducer;
