import React from "react";
import ResultList from "./ResultList/ResultList";
import ErrorDiv from "../ErrorDiv/ErrorDiv";
import { InputAdornment, InputBase, Select, Typography, withStyles } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import classnames from "classnames";
import styles from "./SearchListExecute.styles";

const SearchListExecute = ({
  classes,
  detailsFields,
  filter,
  onChangeHandler,
  onSearchHandler,
  results,
  selectByParams,
  resultsInPP,
  addOrRemoveHandler,
  errorMessage,
  title,
  searchBarPlaceholder,
  modal,
  newPricingPlanApp,
}) => {
  const titleText = <Typography className={classes.titleText}>{title}</Typography>;

  const errorDiv = !!errorMessage && <ErrorDiv message={errorMessage} />;

  const searchIcon = (
    <SearchIcon
      disabled={filter.isSearching}
      onClick={() => {
        // if (filter.selectByString.trim() === '') {
        // 	onSearchHandler(true);
        // 	return;
        // };
        console.log("search");
        onSearchHandler();
      }}
      className={classnames(classes.icon)}
      size="small"
    />
  );

  const selectByCustomer = !!selectByParams && selectByParams.length > 0 && (
    <Select
      native
      onChange={(e) => onChangeHandler(e.target.value, "selectBy")}
      className={classes.selectEmpty}
      displayEmpty
      value={filter.selectType}
    >
      {selectByParams.map((param) => (
        <option value={param.value} key={param.value}>
          {param.label}
        </option>
      ))}
    </Select>
  );

  const searchBar = (
    <div className={classes.selectByReservationCustomer}>
      {selectByCustomer}
      <InputBase
        placeholder={searchBarPlaceholder}
        value={filter.selectByString}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        onChange={(event) => onChangeHandler(event.target.value, "selectByString")}
        onKeyDown={(event) => {
          if (filter.isSearching) {
            return;
          }
          if (event.keyCode === 13) {
            if (filter.selectByString.trim() === "") {
              onSearchHandler(true);
              return;
            }
            return onSearchHandler();
          }
        }}
        endAdornment={<InputAdornment position="end">{searchIcon}</InputAdornment>}
      />
    </div>
  );

  // RESULT's LIST

  const list = !!results && (
    <ResultList
      results={results}
      resultsInPP={resultsInPP}
      addOrRemoveHandler={addOrRemoveHandler}
      detailsFields={detailsFields}
      modal={modal}
      newPricingPlanApp={newPricingPlanApp}
    />
  );

  const warning = !!results && results.length > 24 && (
    <Typography align="center" className={classes.warning}>
      Please refine your search for more results
    </Typography>
  );

  return (
    <div className={classes.root}>
      {errorDiv}
      {titleText}
      {searchBar}
      {list}
      {warning}
    </div>
  );
};

export default withStyles(styles)(SearchListExecute);
