import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Select, FormControl, InputLabel, MenuItem, Checkbox, Divider } from "@material-ui/core";

import { styles } from "./VehicleSelectField.styles";
import { Field, useForm } from "react-final-form";
import MetaError from "../../../../../common/forms/MetaError";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectDefaultLocation } from "../../../../carclub/carclubSlice";

function VehicleSelectField({ classes, id, label, required, assets, disabled }) {
  const form = useForm();
  const selectedLocation = useSelector(selectDefaultLocation);

  useEffect(() => {
    form.change('assets', []);
    // eslint-disable-next-line
  }, [selectedLocation])

  const onChangeHandler = (input, value) => {
    if (value.includes("ALL")) {
      if (assets?.length < value?.length) {
        input.onChange && input.onChange([]);
      }
      else {
        input.onChange && input.onChange(assets?.map(item => item.assetToken));
      }
    }
    else {
      input.onChange && input.onChange(value);
    }
  }

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => (
          <>
            <FormControl
              fullWidth
              size="small"
            >
              <InputLabel>
                {label}
                {required && <span> *</span>}
              </InputLabel>
              <Select
                id={id}
                value={input.value || []}
                multiple
                onChange={(e) => onChangeHandler(input, e.target.value)}
                className={classes.select}
                fullWidth
                error={Boolean(meta.error && meta.touched)}
                disabled={disabled}
                renderValue={(values) => {
                  if (assets && assets?.length > 0) {
                    if (values?.length === assets?.length) {
                      return "All"
                    }
                    return "Custom selection"
                  }
                  return "No Assets Available";
                }}
              >

                <MenuItem value={"ALL"} name="all" id="all">
                  <Checkbox checked={(input.value || [])?.length === assets?.length} /> All
                </MenuItem>
                <Divider />
                {(assets || []).map((item, index) => (
                  <MenuItem key={"option_" + index} value={item.assetToken}>
                    <Checkbox checked={(input.value || []).includes(item.assetToken)} /> {item.licensePlate}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className={"FormFieldError " + classes.error}>
              <MetaError meta={meta} />
            </div>
          </>
        )}
      </Field>
    </div>
  );
}

export default withStyles(styles)(VehicleSelectField);