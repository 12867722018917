import React from "react";
import { Button } from 'reactstrap';
import { utils } from "../../../../utils";

function CsvExporter(props) {
	let languages = null;
	let i18n = null;
	let filename = "";
	if (props.data && props.data.configuration && props.data.configuration.configs && props.data.configuration.i18n && props.data.configuration.configs.availableLanguages) {
		languages = props.data.configuration.configs.availableLanguages;
		i18n = props.data.configuration.i18n;
		filename = props.data.app_name + "-" + props.data.app_version + "(" + props.data.app_configuration_id + ").csv";
	}

	const handleClick = () => {
		if (i18n && languages && props.fields) {
			let headers = ["key"];
			languages.map((lang) => {
				return headers.push(lang);
			});

			const rows = [
				headers,
			];
			props.fields.tabs.map((tab) => {
				return props.fields.fields[tab.designation].map((block) => {
					return block.fields.map((field) => {
						let row = [field.key];
						languages.map((lang) => {
							if (field.key !== 'terms') {
								let value = i18n[lang][field.key] || "";
								if (value.indexOf(',') > -1) {
									row.push("\"" + value + "\"");
								}
								else {
									row.push(value);
								}
							}
							return lang;
						});
						return rows.push(row);
					});
				});
			});
			utils.exportCsv(filename, rows);
			return;
		}

	};
	return (
		<Button color="secondary" size="sm" onClick={handleClick} active>CSV Export</Button>
	);
}

export default CsvExporter;