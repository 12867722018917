import React from "react";
import SortIcon from "@material-ui/icons/UnfoldMore";
import orderType from "../../../model/Account/orderType";
import * as listItemTypes from "../../UI/List/ListItem/ListItemTypes";
import * as screenSize from "../../../model/ScreenSize/ScreenSize";

const getLabel = (type, label, func) => (
  <span
    style={{
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    }}
    onClick={() => func && func(type)}
  >
    <SortIcon />
    {label}
  </span>
);

const accountFields = (currentScreenSize, sortFunc) => {
  const width = {
    [screenSize.DESKTOP]: 151,
    [screenSize.TABLET]: 118,
    [screenSize.ERROR]: 0,
  }[currentScreenSize];

  const maxWidth = {
    [screenSize.DESKTOP]: 216,
    [screenSize.TABLET]: 186,
    [screenSize.ERROR]: 0,
  }[currentScreenSize];

  const height = {
    [screenSize.DESKTOP]: 35,
    [screenSize.TABLET]: 45,
    [screenSize.ERROR]: 0,
  }[currentScreenSize];

  return [
    {
      key: "icons",
      label: " ",
      order: 99,
      pinned: true,
      width: 100,
      height,
      type: listItemTypes.COMPONENT,
      flexDirection: "row-reverse",
    },
    {
      key: "enabled",
      label: getLabel(orderType.DISABLED, "Enabled", sortFunc),
      order: 6,
      pinned: true,
      width,
      maxWidth: width,
      height,
      type: listItemTypes.COMPONENT,
    },
    {
      key: "code",
      label: "Account No.",
      order: 1,
      pinned: true,
      width,
      maxWidth,
      height,
      type: listItemTypes.TEXT,
    },
    {
      key: "name",
      label: getLabel(
        orderType.ACCOUNTNAME,
        currentScreenSize === screenSize.DESKTOP ? "Account Name" : "Name",
        sortFunc
      ),
      order: 2,
      pinned: true,
      width,
      maxWidth,
      height,
      type: listItemTypes.TEXT,
    },
    {
      key: "owner",
      label: getLabel(
        orderType.OWNERNAME,
        currentScreenSize === screenSize.DESKTOP ? "Account Owner" : "Owner",
        sortFunc
      ),
      order: 3,
      pinned: true,
      width,
      maxWidth,
      height,
      type: listItemTypes.TEXT,
    },
    /* {
			key: 'sixMonthAvg',
			label: '6 Month Avg',
			order: 4,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'dueAmount',
			label: 'Due Amount',
			order: 5,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		}, */
  ];
};

export default accountFields;
