import React from "react";
import List from "../../UI/List/List";
import RateItem from "./RateItem/RateItem";
import { Button, InputBase, InputAdornment, Typography, withStyles } from "@material-ui/core";
import { Search as SearchIcon, Stop as SquareIcon, Close as CrossIcon } from "@material-ui/icons";
import ratesFields from "./ratesFields";
import withPermisssion from "../../../hoc/withPermission";
import { ReactComponent as CreateIcon } from "../../../assets/PromotionApp/icons/add.svg";
import styles from "./RatesList.styles";
import classnames from "classnames";

const RatesList = ({
  classes,
  className,
  currentScreenSize,
  cantEdit,
  dontShowSelectedRate,
  editMode,
  filter,
  filterOnChangeHandler,
  listRates,
  onRemoveHandler,
  onSearchHandler,
  onSelectRateToApply,
  placeholder,
  placeholderSetters,
  rates,
  resetPlaceholderRate,
  selectedRateToApply,
  setFilter,
  fullSizeList,
  createMode,
  setModal,
}) => {
  const { color, rateNumber } = selectedRateToApply || {};

  const titleAndSearch = (
    <div className={classes.header}>
      <div className={classes.titleAndSearch}>
        <Typography className={classes.title}>Rates</Typography>
        <div className={classes.searchBar}>
          <InputBase
            placeholder={"Search by name"}
            value={filter}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            onChange={filterOnChangeHandler}
            onKeyDown={(event) => {
              if (filter.isSearching) {
                return;
              }
              if (event.keyCode === 13) {
                onSearchHandler();
              }
            }}
            endAdornment={
              <>
                {!!filter && (
                  <InputAdornment position="end">
                    <CrossIcon onClick={(event) => setFilter("")} className={classes.crossIcon} size="small" />
                  </InputAdornment>
                )}
              </>
            }
          />
        </div>
        <Button aria-label="Search" onClick={onSearchHandler}>
          <SearchIcon className={classes.searchIcon} />
        </Button>
      </div>
      {createMode && (
        <CreateButton
          onClick={() => {
            resetPlaceholderRate();
            createMode.setOn();
            setModal();
          }}
          className={classes.createIcon}
        />
      )}
    </div>
  );

  const rateRows =
    !!rates &&
    rates.map((rate) => (
      <RateItem
        key={rate.id}
        selected={!!selectedRateToApply && !!rate && rate.rateNumber === selectedRateToApply.rateNumber}
        rate={rate}
        placeholder={placeholder}
        placeholderSetters={placeholderSetters}
        editMode={editMode}
        listRates={listRates}
        cantEdit={cantEdit}
        onSelectRateToApply={onSelectRateToApply}
        onRemove={onRemoveHandler}
        fullSizeList={fullSizeList}
      />
    ));

  const list = (
    <List
      className={classnames(classes.list, className)}
      disableGutters={!fullSizeList}
      headerFields={ratesFields(currentScreenSize, fullSizeList)}
      rowClassName={classes.rows}
    >
      {rateRows}
    </List>
  );

  const squareStyle = { fill: color };

  const rateToApplyDiv = !dontShowSelectedRate && (
    <>
      <SquareIcon style={squareStyle} />
      <Typography>{`Number: ${rateNumber || (rateNumber === 0 ? 0 : "")}`}</Typography>
    </>
  );

  const selectedRateDiv = !cantEdit && !dontShowSelectedRate && (
    <div className={classes.selectedRateDiv}>
      <Typography>Selected rate to apply:</Typography>
      {rateToApplyDiv}
    </div>
  );

  return (
    <div className={classnames(classes.root, className)}>
      {titleAndSearch}
      {list}
      {selectedRateDiv}
    </div>
  );
};

const CreateButton = withPermisssion([{ resource: ["pricingPlansNew", "rates", "create"] }])((props) => (
  <CreateIcon {...props} />
));

export default withStyles(styles)(RatesList);
