import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Grid, Tooltip } from "@material-ui/core";


import { styles } from "./MapButton.styles";

function MapButton({ classes, tooltip, children, onClick }) {
  return (
    <Grid container className={classes.root} justifyContent="center" alignItems="center" onClick={onClick}>
      <Tooltip title={tooltip}>
        {children}
      </Tooltip>
    </Grid>
  );
}

export default withStyles(styles)(MapButton);
