const styles = theme => ({
	root: {
		display: 'flex',
	},

	icon: {
		fill: '#009AC9',
		width: 14,
		height: 14,
	},

	icons: {
		display: 'flex',
		alignItems: 'center',
	},

	checkIcon: {
		color: '#51B383',
	},

	editIcon: {
		color: '#009AC9',
	},
});

export default styles;