import React, { useState, useCallback } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Badge, withStyles } from "@material-ui/core";
import { Chat as ChatIcon } from "@material-ui/icons";
import Chat from "./Chat/ChatWrapper";
import styles from "./ChatApp.styles";

const ChatApp = ({ classes }) => {
  const [isOpen, setIsOpen] = useState(false);
  const newMessages = useSelector((state) => state.getIn(["chat", "newMessages"]), shallowEqual);
  const newPending = useSelector((state) => state.getIn(["chat", "pendingContacts"]), shallowEqual);
  const newGroup = useSelector((state) => state.getIn(["chat", "groupInvites"]), shallowEqual);

  const toggleOpen = useCallback(() => setIsOpen((isOpen) => !isOpen), []);

  return (
    <div className={classes.root}>
      <Badge
        overlap="rectangular"
        color="secondary"
        invisible={!(newMessages?.size || newPending?.size || newGroup?.size)}
        variant="dot"
      >
        <ChatIcon className={classes.icon} onClick={toggleOpen} />
      </Badge>
      <Chat isOpen={isOpen} />
    </div>
  );
};

export default withStyles(styles)(ChatApp);
