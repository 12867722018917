import React from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";

import { styles } from "./AlertsList.styles";
import { SwitchInput } from "../../../../common/inputs";
import InfiniteList from "../../../../common/displays/InfiniteList/InfiniteList";
import AlertItem from "./AlertItem/AlertItem";
import ActionsDropDown from "../../../../common/displays/ActionsDropDown/ActionsDropDown";

import {
  selectAlerts,
  alertList,
  selectIsLoading,
  incrementAlertsFilterPage,
  selectAlertsFilter,
  setAlertsFilterResolved,
  setAlertsFilterLocation,
  alertAssign,
} from "../../alertsSlice";
import { selectDefaultLocation } from "../../../carclub/carclubSlice";

function AlertsList({ classes, onSelectAsset, onSelectReservation, onSelectCustomer }) {
  const selectedLocation = useSelector(selectDefaultLocation);
  const loading = useSelector(selectIsLoading);
  const alerts = useSelector(selectAlerts);
  const dispatch = useDispatch();
  const alertsFilter = useSelector(selectAlertsFilter);

  const loadMoreHandler = () => {
    dispatch(incrementAlertsFilterPage());
    dispatch(alertList());
  };

  const showAllAlertsHandler = (val) => {
    if (val) {
      dispatch(setAlertsFilterResolved(undefined));
      dispatch(alertList({ clear: true }));
    } else {
      dispatch(setAlertsFilterResolved(false));
      dispatch(alertList({ clear: true }));
    }
  };

  const showAllLocationsHandler = (val) => {
    if (val) {
      dispatch(setAlertsFilterLocation(undefined));
      dispatch(alertList({ clear: true }));
    } else {
      dispatch(setAlertsFilterLocation(selectedLocation.id));
      dispatch(alertList({ clear: true }));
    }
  };

  const onStatusChangeHandler = (alertToken, status) => {
    dispatch(alertAssign({ alertToken, status }));
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={1} direction="column">
        <Grid item>
          <Grid container spacing={1} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography className={classes.title}>Alerts</Typography>
            </Grid>
            <Grid item>
              <ActionsDropDown listClassName={classes.actions}>
                <SwitchInput
                  className={classes.button}
                  label="Show all alerts"
                  value={alertsFilter?.resolved === undefined}
                  onChange={showAllAlertsHandler}
                  size="small"
                />
                <SwitchInput
                  className={classes.button}
                  label="Show alerts for all locations"
                  value={alertsFilter?.locationId === undefined}
                  onChange={showAllLocationsHandler}
                  size="small"
                />
              </ActionsDropDown>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <InfiniteList
            style={{ height: "calc(100vh - 100px", paddingTop: 2 }}
            isLoading={loading}
            loadMore={() => loadMoreHandler()}
            data={alerts?.list}
            renderElement={(alert) => {
              return (
                <AlertItem
                  alert={alert}
                  onStatusChange={onStatusChangeHandler}
                  onSelectAsset={onSelectAsset}
                  onSelectReservation={onSelectReservation}
                  selectedLocationId={selectedLocation?.id}
                  onSelectCustomer={onSelectCustomer}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(AlertsList);
