import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import {
	FormControl,
	InputBase,
	InputLabel,
	TextField,
	Typography,
	withStyles,
} from '@material-ui/core';
import { currencyFormatter } from '../../../../services/formatter';
import Button from '../../../UI/Buttons/CommonButtons';
import SearchList from '../../../UI/SearchList/SearchListWrapper';
import styles from './Update.styles';
import classnames from 'classnames';

const Update = ({
	addDriver,
	classes,
	error,
	getDrivers,
	hasRequest,
	onCancel,
	onChange,
	onSave,
	placeholder,
	removeDriver,
}) => {
	const isEditing = !!placeholder?.id;

	const onError = !!error && (
		<Typography className={classes.error} align='center'>
			{typeof error === 'string' ? error : 'Please fill all the fields'}
		</Typography>
	);

	const title = (
		<Typography className={classes.title}>
			{isEditing ? `Editing Bonus` : 'Create Bonus'}
		</Typography>
	);

	const code = (
		<FormControl key='Code_Field'>
			<InputLabel>
				{'Bonus Id'}
			</InputLabel>
			<InputBase
				classes={{
					root: classes.inputRoot,
					input: classes.inputInput,
				}}
				disabled={true}
				value={placeholder?.id || ''}
			/>
		</FormControl>
	);

	const creditAmount = (
		<FormControl key='Amount_Field' className={classes.maxAmount}>
			<InputLabel>
				{'Credit Amount'}
			</InputLabel>
			<InputBase
				id='creditAmountInputId'
				onSelect={() => document.getElementById("creditAmountInputId").select()}
				classes={{
					root: classes.inputRoot,
					input: classnames(classes.inputInput, classes.noArrows,
						!!error && !placeholder.amount && classes.inputError),
				}}
				disabled={isEditing}
				endAdornment={currencyFormatter.symbol()}
				inputProps={{ min: '0.00', step: '0.01', type: 'number' }}
				onChange={event => event?.target?.validity?.valid &&
					onChange({ amount: event.target.value })}
				type="number"
				value={placeholder.amount || 0}
			/>
		</FormControl>
	);

	const driverSearch = !isEditing && (
		<SearchList
			currentFields={placeholder?.customerCodes}
			disabled={hasRequest}
			getList={getDrivers}
			header='Name'
			name='Driver'
			onAdd={addDriver}
			onRemove={removeDriver}
			searchLabel='Search Driver'
			style={{ classNameFiltersRoot: classes.classNameFiltersRoot }}
		/>
	);

	const driver = isEditing && (
		<FormControl key='Driver_Field'>
			<InputLabel>
				{'Driver'}
			</InputLabel>
			<InputBase
				classes={{
					root: classes.inputRoot,
					input: classes.inputInput,
				}}
				value={placeholder?.customerCode || ''}
				disabled={true}
			/>
		</FormControl>
	);

	const expirationDate = (
			<DatePicker
				ampm={false}
				className={classes.pickers}
				disabled={!!hasRequest}
				disablePast
				format="DD/MM/YYYY"
				InputLabelProps={{ classes: { outlined: classes.outlined } }}
				InputProps={{ classes: { input: classes.pickerInput } }}
				inputVariant='outlined'
				key='date_field'
				label='Expiration date'
				onChange={value => onChange({ expirationDate: value })}
				value={placeholder?.expirationDate || null}
			/>
	);

	const description = (
		<TextField
			disabled={isEditing}
			error={!!error && !placeholder.reason && classes.inputError}
			fullWidth
			id='text_Field'
			InputLabelProps={{ classes: { root: classes.labelProps } }}
			InputProps={{ classes: { multiline: classes.greyTextField } }}
			label='Reason'
			margin="normal"
			multiline
			onChange={e => onChange({ reason: e.target.value })}
			minRows="3"
			value={placeholder?.reason || ''}
		/>
	);

	const form = (
		<div className={classes.form}>
			{code}
			{expirationDate}
			{driver}
			{driverSearch}
			{creditAmount}
			{description}
		</div>
	);

	const cancelButton = (
		<Button
			buttonStyleName='void'
			disabled={!!hasRequest}
			key='CancelButton'
			label='Cancel'
			onClick={() => onCancel()}
		/>
	);

	const saveButton = (
		<Button
			buttonStyleName='filled'
			disabled={!!hasRequest}
			key={isEditing ? 'UpdateButton' : 'SaveButton'}
			label={isEditing ? 'Update' : 'Save'}
			onClick={onSave}
		/>
	);

	const buttons = (
		<div className={classes.buttons}>
			{cancelButton}
			{saveButton}
		</div>
	);

	return (
		<div className={classes.root}>
			{title}
			{form}
			{onError}
			{buttons}
		</div>
	);
};

export default withStyles(styles)(Update);