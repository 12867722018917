import React, { useState, useCallback, useEffect, useRef } from "react";
import PricingPlanEditor from "./PricingPlanEditor";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getZones, selectZones } from "../../../features/asset/assetSlice";

const PricingPlanEditorWrapper = ({ endZoneFees, startZoneFees, placeholderSetters, ...props }) => {
  const dispatch = useDispatch();
  const zones = useSelector(selectZones);

  const { setStartZone, setEndZone, ...setters } = placeholderSetters;
  const [selectedZone, setSelectedZone] = useState();
  const listRef = useRef();

  useEffect(() => {
    dispatch(getZones());
    // eslint-disable-next-line
  }, []);

  const getFilteredZones = useCallback(
    ({ string }) => {
      return zones
        .map((zone) => ({ key: zone?.id, label: zone?.description, type: zone?.type }))
        .filter((zone) => zone?.label?.toLowerCase().includes((string + "").toLowerCase()));
    },
    [zones]
  );

  const onAddZone = useCallback(
    (fee, type, startEndEqual) => {
      if (!selectedZone || !(Number(fee) || Number(fee) === 0)) return;

      const setter = type === "start" ? setStartZone : setEndZone;
      const prev = type === "start" ? startZoneFees : endZoneFees;

      setter([
        ...prev?.filter((zone) => selectedZone.key !== zone.zoneId),
        { zoneId: selectedZone?.key, zoneFee: Number(fee), startEndEqual, description: selectedZone?.label },
      ]);
    },
    [selectedZone, startZoneFees, setEndZone, endZoneFees, setStartZone]
  );

  const onRemoveZone = useCallback(
    (toRemove, type) => {
      const setter = type === "start" ? setStartZone : setEndZone;
      const prev = type === "start" ? startZoneFees : endZoneFees;

      setter([...prev?.filter((zone) => zone.zoneId !== toRemove.zoneId)]);
    },
    [endZoneFees, setEndZone, setStartZone, startZoneFees]
  );

  return (
    <PricingPlanEditor
      {...props}
      getZones={getFilteredZones}
      listRef={listRef}
      onAddZone={onAddZone}
      onRemoveZone={onRemoveZone}
      placeholderSetters={setters}
      selectedZone={selectedZone}
      setSelectedZone={setSelectedZone}
      endZoneFees={endZoneFees}
      startZoneFees={startZoneFees}
      zones={zones}
    />
  );
};

export default PricingPlanEditorWrapper;
