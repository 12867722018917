import * as screenSize from '../../../../model/ScreenSize/ScreenSize'; 
import * as listItemTypes from '../../../UI/List/ListItem/ListItemTypes';

const fields = currentScreenSize => {
	const width = {
		[screenSize.DESKTOP]: 111,
		[screenSize.TABLET]: 78,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	const maxWidth = {
		[screenSize.DESKTOP]: 166,
		[screenSize.TABLET]: 136,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	const height = {
		[screenSize.DESKTOP]: 35,
		[screenSize.TABLET]: 45,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	return [
		{
			key: 'editIcon',
			label: '',
			order: 99,
			pinned: true,
			width,
			height,
			type: listItemTypes.COMPONENT,
			flexDirection: 'row-reverse',
		},
		{
			key: 'id',
			label: 'Bonus ID',
			order: 1,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'userAddThis',
			label: 'Created By',
			order: 6,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'amount',
			label: 'Credit Amount',
			order: 3,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'expirationDate',
			label: 'Expiration Date',
			order: 4,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'customerCode',
			label: 'Driver',
			order: 2,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'unusedAmount',
			label: 'Unused Amount',
			order: 5,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'createDate',
			label: 'Create Date',
			order: 7,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
	];
};

export default fields;
