import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import * as chatActions from "../../../../../../store/actions/chat/action";
import List from "./List";

const ListWrapper = ({ newMessages, setSelected, ...props }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const dispatch = useDispatch();

  const toggleExpansion = useCallback(() => setIsExpanded((state) => !state), []);

  const onSelect = useCallback(
    (contact) => {
      if (newMessages?.has(contact?.target) || newMessages?.has(contact?.groupId)) {
        dispatch(chatActions.chatUpdateMessages(contact?.groupId || contact?.target));
      }

      setSelected(contact);
    },
    [newMessages, dispatch, setSelected]
  );

  return (
    <List
      {...props}
      isExpanded={isExpanded}
      newMessages={newMessages}
      onSelect={onSelect}
      toggleExpansion={toggleExpansion}
    />
  );
};

export default ListWrapper;
