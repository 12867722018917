export const styles = theme => ({

	root: {
		display: 'flex',
		paddingLeft: 0,
		paddingRight: 0,
	},

	icons: {
		display: 'flex',
	},

	iconEnabled: {
		color: '#009AC9',
	},
});
