const styles = theme => ({

	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		minWidth: '0',
		justifyContent: 'flex-start',
		backgroundColor: '#FAFAFA',
		boxShadow: '0px 3px 6px #00000029',
	},

	div: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
	},

	list: {
		width: '100%',
		height: '100%',
		paddingLeft: 21,
		paddingRight: 21,
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
	},

	title: {
		margin: '10px 0 0 10px',
		fontSize: '18px',
	},

	header: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		padding: '10px 15px',
		margin: '0 15px',
		borderBottom: '1px solid #888',
	},

	headerText: {
		fontSize: '16px',
	},
});

export default styles;