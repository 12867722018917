import React from "react";
import { IconButton, Modal, Select, TextField, Typography, withStyles } from "@material-ui/core";
import { Close as ExitIcon, Map as MapIcon, FiberManualRecord as BallIcon } from "@material-ui/icons";
import Map from "../Map/MapWrapper";
import List from "../../../UI/SearchList/SearchListWrapper";
import DatePicker from "../../../Reservations/ReservationDatePicker/ReservationDatePicker";
import Button from "../../../UI/Buttons/CommonButtons";
import styles from "./Update.styles";
import classnames from "classnames";

const Update = ({
  agentStatusFilter,
  classes,
  error,
  getOperators,
  getVehicles,
  hasRequest,
  location,
  modal,
  onCancel,
  onChange,
  onGoogleAPILoaded,
  onSave,
  onTypeChange,
  placeholder,
  priorities = [],
  servicePoints = [],
  setAgentStatusFilter,
  placeholderMap,
  mapPlaceholderOnChangeHandler,
  showMap,
  titleRef,
  types = [],
  servicePointMarker,
  resetServicePointMarker,
  route,
  paintExistingRoute,
}) => {
  const { scheduledEndDate, scheduledStartDate } = placeholder;

  console.log("Update times: %o", { scheduledEndDate, scheduledStartDate });

  const badDates = scheduledStartDate && scheduledEndDate && scheduledStartDate > scheduledEndDate;

  const errorBar = "string" === typeof error && (
    <div className={classes.errorBar}>
      <Typography className={classes.errorText} align="center">
        {error}
      </Typography>
    </div>
  );

  const title = (
    <Typography className={classes.title} ref={titleRef}>
      {`${placeholder.code ? "Update" : "New"} Work Order`}
    </Typography>
  );

  const searchVehicleOnMapBtn = (
    <div className={classes.searchMapContainer}>
      <MapIcon
        className={classnames(classes.icon, !location?.id && classes.disabled)}
        disabled={!location?.id}
        key="map_search_asset"
        onClick={() => showMap("MAP")}
      />
    </div>
  );

  const vehicleSearch = (
    <div className={classes.searchVehicleContainer}>
      {searchVehicleOnMapBtn}
      <List
        currentFields={{ key: placeholder.assetToken, label: placeholder.licensePlate }}
        error={!!error && !placeholder.assetToken}
        getList={getVehicles}
        header="License plate"
        name="Vehicle"
        onAdd={(assetToken, licensePlate) => onChange({ assetToken, licensePlate })}
        onRemove={() => onChange({ assetToken: undefined })}
        searchLabel="Search Vehicle"
      />
    </div>
  );

  const statusIcon = <BallIcon style={{ fill: agentStatusFilter === "online" ? "#00B67F" : "#C5C5C5" }} />;

  const operatorFilterBtn = (
    <IconButton
      color="primary"
      aria-label="upload picture"
      component="span"
      onClick={() => {
        setAgentStatusFilter((prev) => (prev === "online" ? false : "online"));
      }}
    >
      {statusIcon}
    </IconButton>
  );

  const operatorSearch = (
    <List
      currentFields={{
        key: placeholder?.assignAgent,
        label: placeholder?.assignAgentName,
        online: placeholder?.status,
        count: placeholder?.worktasksCount,
      }}
      getList={getOperators}
      header={["Name", "Status", "Tasks no."]}
      name="Assignee"
      onAdd={(assignAgent, assignAgentName, status, worktasksCount) => {
        onChange({ assignAgent, assignAgentName, status, worktasksCount });
      }}
      onRemove={() => onChange({ assignAgent: undefined })}
      searchLabel="Search Operator"
      style={{
        classNameHeader: classes.operatorHeaderClassName,
        classNameSearch: classes.operatorSearchClassName,
      }}
      extraFilter={operatorFilterBtn}
    />
  );

  const selectInput = selectInputMapper(classes, onChange, placeholder);
  const selectTypeInput = selectInputMapper(classes, onTypeChange, placeholder);

  const priorityOptions = priorities.map((priority) => (
    <option value={priority} key={priority}>
      {priority[0] + priority.slice(1).toLowerCase()}
    </option>
  ));

  const typeOptions = types.map((type) => (
    <option value={type.type} key={type.code}>
      {type.type}
    </option>
  ));

  const type = selectTypeInput({
    disabled: !!placeholder?.code,
    error: error && !placeholder?.type,
    field: "type",
    label: "Type: ",
    options: typeOptions,
    selectStyle: classes.largerSelect,
  });

  const priority = selectInput({
    field: "priority",
    label: "Priority: ",
    options: priorityOptions,
    error: error && !placeholder?.priority,
  });

  const calendar = (
    <Typography className={classnames(classes.subtitle, badDates && classes.error)}>Schedule</Typography>
  );

  const datePickers = (
    <div className={classes.datePickers}>
      <DatePicker
        className={classes.datePickers}
        endDate={placeholder.scheduledEndDate || null}
        endDateHandler={(dt) => onChange({ scheduledEndDate: dt })}
        endDateLabel="Scheduled end"
        hasFuture={true}
        startDate={placeholder.scheduledStartDate || null}
        startDateHandler={(dt) => onChange({ scheduledStartDate: dt })}
        startDateLabel="Scheduled start"
      />
    </div>
  );

  const currentLocation = (
    <div className={classes.location}>
      <Typography className={classes.subtitle}>Current Location:</Typography>
      <Typography className={classes.subtitle}>{location.name}</Typography>
    </div>
  );

  const latValue =
    (placeholder?.startLatitude &&
      placeholder.startLatitude.toString().substring(0, placeholder.startLatitude.toString().length - 6)) ||
    (servicePointMarker &&
      servicePointMarker.position
        .lat()
        .toString()
        .substring(0, servicePointMarker.position.lat().toString().length - 6));

  const lngValue =
    (placeholder?.startLongitude &&
      placeholder.startLongitude.toString().substring(0, placeholder.startLongitude.toString().length - 6)) ||
    (servicePointMarker &&
      servicePointMarker.position
        .lng()
        .toString()
        .substring(0, servicePointMarker.position.lng().toString().length - 6));

  const selectedCoordinates = !!latValue && !!lngValue && (
    <div className={classes.location}>
      <Typography className={classes.smallText}>{`Lat: ${latValue}`}</Typography>
      <Typography className={classes.smallText}>{`Lng: ${lngValue}`}</Typography>
      <ExitIcon className={classes.exitIcon} onClick={resetServicePointMarker} />
    </div>
  );

  const servicePointOptions = servicePoints.map((servicePoint) => (
    <option value={servicePoint.code} key={servicePoint.code}>
      {servicePoint.name}
    </option>
  ));

  const servicePoint = selectInput({
    afterLabelButton: (
      <MapIcon
        className={classnames(classes.absoluteIcon, !location?.id && classes.disabled)}
        disabled={!location?.id || !!servicePointMarker}
        key="map_search_asset"
        onClick={() => showMap("MAP")}
      />
    ),
    field: "servicePointCode",
    label: "Service Point: ",
    options: servicePointOptions,
    selectStyle: classes.largerSelect,
  });

  const description = <Typography className={classes.subtitle}>Description</Typography>;

  const descriptionField = (
    <TextField
      className={classes.description}
      fullWidth
      multiline
      onChange={(event) => onChange({ description: event.target.value })}
      minRows="3"
      value={placeholder.description}
      variant="filled"
      InputProps={{
        disableUnderline: true,
        style: {
          padding: "10px",
        },
      }}
    />
  );

  const saveButton = <Button disabled={hasRequest} key="save" label="Save" onClick={onSave} />;

  const cancelButton = (
    <Button buttonStyleName="void" disabled={hasRequest} key="cancel" label="Cancel" onClick={onCancel} />
  );

  const mapModal = (
    <Modal onClose={() => showMap()} open={!!modal}>
      <div className={classes.modal}>
        <Map
          onClose={() => showMap()}
          onSelect={(servicePointCode) => {
            onChange({ servicePointCode });
            showMap();
          }}
          mapCenter={location}
          servicePoints={servicePoints}
          onGoogleAPILoaded={onGoogleAPILoaded}
          placeholderMap={placeholderMap}
          mapPlaceholderOnChangeHandler={mapPlaceholderOnChangeHandler}
          route={route}
          paintExistingRoute={paintExistingRoute}
        />
      </div>
    </Modal>
  );

  return (
    <div className={classes.root}>
      {errorBar}
      {mapModal}
      {title}
      {vehicleSearch}
      <div className={classes.selections}>
        {type}
        {priority}
      </div>
      {calendar}
      {datePickers}
      {currentLocation}
      {servicePoint}
      {selectedCoordinates}
      {operatorSearch}
      {description}
      {descriptionField}
      <div className={classes.buttons}>
        {cancelButton}
        {saveButton}
      </div>
    </div>
  );
};

export default withStyles(styles)(Update);

const selectInputMapper =
  (classes, onChange, placeholder) =>
  ({ afterLabelButton, button, disabled, field, label, options, selectStyle, error }) =>
    (
      <div className={classes.inputText}>
        <Typography className={classnames(classes.textInputLabel, error && classes.error)}>{label}</Typography>
        {afterLabelButton}
        <Select
          native
          onChange={(event) => onChange({ [field]: event.target.value })}
          displayEmpty
          className={classnames(classes.select, selectStyle)}
          value={placeholder[field] || ""}
          disabled={disabled}
        >
          <option value="" key="_blank"></option>
          {options}
        </Select>
        {button}
      </div>
    );
