import React from 'react';
import Searchbar from '../../UI/Searchbar/Searchbar';
import UpdateUser from './UpdateUser/UpdateUserWrapper';
import UsersDetails from './UserDetails/UserDetails';
import UsersList from './UsersList/UsersListWrapper';
import UserResetPassword from './ResetPassword/ResetPasswordModal';
import {
	Drawer,
	Tooltip,
	Typography,
	withStyles,
} from '@material-ui/core';
import {
	AddCircle as CreateIcon,
} from '@material-ui/icons';
import withPermission from '../../../hoc/withPermission';
import styles from './Users.styles';

const Users = ({
	classes,
	currentScreenSize,
	disableButtons,
	filter,
	isEditing,
	list,
	onDoubleClick,
	onRemove,
	onResetPassword,
	onSearch,
	onSingleClick,
	onUpdate,
	resetPasswordModal,
	roles,
	selected,
	setFilter,
	setIsEditing,
	setResetPasswordModal,
	setSelected,
}) => {
	const usersList = !!list && !!list.users && (
		<UsersList
			currentScreenSize={currentScreenSize}
			lastPage={list.lastPage}
			onEdit={setIsEditing}
			onRemove={onRemove}
			onResetPassword={setResetPasswordModal}
			onSearch={onSearch}
			onSelect={setSelected}
			onUpdate={onUpdate}
			roles={roles}
			selected={selected}
			users={Object.values(list.users)}
		/>
	);

	const drawer = (
		<Drawer
			anchor='right'
			onClose={() => setIsEditing()}
			open={isEditing}
		>
			<div className={classes.drawer}>
				<UpdateUser
					onCancel={setIsEditing}
					user={list?.users?.[selected]}
					onUpdate={onUpdate}
					roles={roles}
					setSelected={setSelected}
				/>
			</div>
		</Drawer>
	);

	const title = (
		<Typography className={classes.title}>
			Users
		</Typography>
	);

	const createButton = (
		<CreateButton
			classes={classes}
			onClick={() => {
				setSelected();
				setIsEditing(true);
			}}
		/>
	);

	const searchbar = (
		<div className={classes.searchbar}>
			{title}
			<Searchbar
				filter={filter}
				label='Name or email'
				onDoubleClick={onDoubleClick}
				onSearch={() => onSearch(true)}
				onClick={onSingleClick}
				setFilter={setFilter}
			/>
			{createButton}
		</div>
	);

	const details = !!selected && !!list?.users?.[selected] &&
		(
			<UsersDetails
				user={list?.users?.[selected]}
				roles={roles}
			/>
		);

	const modal = (
		<UserResetPassword
			isOpen={resetPasswordModal}
			onCancel={setResetPasswordModal}
			onConfirm={onResetPassword}
			username={(list.users[selected] || {}).name || selected}
			disableButtons={disableButtons}
		/>
	);

	return (
		<div className={classes.root}>
			<div className={classes.workspace}>
				{modal}
				{drawer}
				<div className={classes.list}>
					{searchbar}
					{usersList}
				</div>
				<div className={classes.details}>
					{details}
				</div>
			</div>
		</div>
	);
};

const CreateButton = withPermission(
	[{ resource: ['carclub', 'users', 'create'] }]
)(({
	classes,
	onClick,
}) =>
	(
		<div className={classes.create}>
			<Tooltip title="Add user">
				<CreateIcon
					className={classes.createButton}
					onClick={onClick}
				/>
			</Tooltip>
		</div>
	)
);

export default withStyles(styles)(Users);