import React from 'react';
import Aws from './Aws';

const AwsWrapper = (props) => {
	return (
		<Aws
			{...props}
		/>
	);
};

export default AwsWrapper;