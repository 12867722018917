import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Accordion, AccordionSummary, AccordionDetails, Grid, Button } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";

import AssetSummary from "./AssetSummary/AssetSummary";
import { styles } from "./AssetItem.styles";
import ActivityLog from "./ActivityLog/ActivityLog";
import ToggleAvailabilityButton from "./AssetActions/ToggleAvailabilityButton/ToggleAvailabilityButton";
import CreateReservationButton from "./AssetActions/CreateReservationButton/CreateReservationButton";
import Permission from "../../../../app/components/Permission";
import { selectDefaultLocation } from "../../../carclub/carclubSlice";
import { useDateTime } from "../../../../common/hooks";
import AssetCommands from "./AssetCommands/AssetCommands";
import {
  selectUI,
  setShowAssetCommands,
  setShowDamageReport,
  setShowActivityLog,
  selectSelectedAssetToken,
  setShowAssetDetails,
} from "../../assetSlice";
import { isSharing, isSubscription } from "../../../../common/utils/OTypes";

function AssetItem({ classes, asset, onClick }) {
  const dispatch = useDispatch();
  const uiState = useSelector(selectUI);
  const selectedAssetToken = useSelector(selectSelectedAssetToken);

  const selectedLocation = useSelector(selectDefaultLocation);
  const { toTimeZone } = useDateTime();

  const updateTime = asset.deviceToken && toTimeZone(asset.deviceUpdateDate, "dateTimeComplete");
  const dateLastKnownPosition = asset.deviceToken && toTimeZone(asset.dateLastKnownPosition, "dateTimeComplete");

  const lastUpdate =
    asset?.assetTypeName === "SCOOTER"
      ? asset.dateLastKnownPosition && dateLastKnownPosition
        ? `Last Known Position: ${dateLastKnownPosition}`
        : "Last Known Position: N/A"
      : asset.deviceUpdateDate && updateTime
        ? `Last Info: ${updateTime}`
        : "Last Info: N/A";

  const assetSummary = useMemo(
    () => {
      return <AssetSummary asset={asset} />;
    },
    // eslint-disable-next-line
    [asset]
  );
  const assetDetails = useMemo(() => {
    return uiState.showActivityLog ? (
      <div className={classes.activityLogDetails}>
        <span className={classes.exitCross} onClick={() => dispatch(setShowActivityLog(false))}>
          +
        </span>
        <ActivityLog deviceToken={asset?.deviceToken} />
      </div>
    ) : (
      <>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={4}>
            {asset.make}
            <br />
            {asset.model}
          </Grid>
          <Grid item xs={4}>
            Odometer
            <br />
            {asset.mileage ? asset.mileage + "km" : "-"}
          </Grid>
          <Grid item xs={4}>
            <ToggleAvailabilityButton asset={asset} />
          </Grid>
        </Grid>
        <div className={classes.buttonDiv}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div>{lastUpdate}</div>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    className={classes.button}
                    color="primary"
                    size="small"
                    onClick={() => dispatch(setShowAssetDetails(true))}
                    fullWidth
                  >
                    More Details
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    className={classes.button}
                    color="primary"
                    size="small"
                    onClick={() => dispatch(setShowDamageReport(true))}
                    fullWidth
                  >
                    Damages
                  </Button>
                </Grid>
                <Permission code="reservations.advanced.create">
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      className={classes.button}
                      color="primary"
                      size="small"
                      onClick={() => dispatch(setShowAssetCommands())}
                      fullWidth
                    >
                      Commands
                    </Button>
                  </Grid>
                </Permission>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    className={classes.button}
                    color="primary"
                    size="small"
                    onClick={() => dispatch(setShowActivityLog(true))}
                    fullWidth
                  >
                    Activity Logs
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              {(isSharing(selectedLocation.operationType) || isSubscription(selectedLocation.operationType)) && (
                <Permission code="reservations.advanced.create">
                  <CreateReservationButton asset={asset} />
                </Permission>
              )}
            </Grid>
          </Grid>
          {uiState.showAssetCommands && <AssetCommands asset={asset} />}
        </div>
      </>
    );
    // eslint-disable-next-line
  }, [asset, uiState]);

  return (
    <Accordion className={classes.root} expanded={selectedAssetToken === asset.assetToken} onClick={onClick}>
      <AccordionSummary className={classes.summary} expandIcon={<ExpandMore />}>
        {assetSummary}
      </AccordionSummary>
      <AccordionDetails className={classes.details} onClick={(e) => e.stopPropagation()}>
        {assetDetails}
      </AccordionDetails>
    </Accordion>
  );
}

export default withStyles(styles)(AssetItem);
