export const styles = () => ({
	selectionField: {
		width: "100%",
	},

	textField: {
		width: "100%",
	},
});

export default styles;
