const styles = theme => ({

	root: {
		cursor: 'pointer',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		minHeight: 30,
		padding: '1em 0',
	},

	header: {
		display: 'inline-flex',
		alignItems: 'center',
	},

	icon: {
		marginRight: '24px',
		color: '#888',
	},

	blueIcon: {
		color: '#1AC',
	},

	buttons: {
		display: 'inline-flex',
		justifyContent: 'space-around',
		margin: '1em 0',
	},

	timestamp: {
		textAlign: 'center',
	},
});

export default styles;