const styles = () => ({

	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alingItems: 'center',
		flexWrap: 'no-wrap',
	},

	workspace: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'row',
		alingItems: 'center',
		flexWrap: 'no-wrap',
		position: 'relative',
	},

	searchAsset: {
		position: 'absolute',
		display: 'inline-flex',
		top: '0%',
		right: '0%',
		zIndex: 1,
	},

	mapArea: {
		width: '100%',
		position: 'relative',
	},

	map: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		top: '0px',
		left: '0px',
	},

	createCar: {
		minHeight: '80vh',
		maxHeight: '80vh',
		overflowY: 'visible',
	},

	
});

export default styles;