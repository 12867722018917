import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const carclubEditMessageCarclubCall = async (
  { setMessageSaveSuccess },
  dispatch,
  state,
  { id, bcc, body, subject, type, subType, toSpecificMail, specificMailList }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "CARCLUB/EDIT_MESSAGE_CARCLUB");
  try {
    const result = await wsCall({
      type: "CARCLUB",
      subType: "EDIT_MESSAGE_CARCLUB",
      locationId: selectedLocation?.id,
      message: {
        id,
        bcc,
        body,
        subject,
        type,
        subType,
        toSpecificMail,
        specificMailList,
      },
    });
    if (result && !result.error) {
      dispatch(setSuccess("Notification Updated successfully"));
      dispatch(setMessageSaveSuccess(true));
      setTimeout(() => {
        dispatch(setMessageSaveSuccess(false));
      }, 200);
    } else {
      rh.error(result?.description || "Error updating notification!");
    }
  } catch (_ex) {
    rh.error("Error updating notification!");
  }
  rh.close();
};

export default carclubEditMessageCarclubCall;
