import Extra from '../../../../model/PricingPlanNew/Extra';

//TODO finish this mapper
const extraMapper = {

	fromDTO: ({
		id,
		name,
		description,
		fullDescription,
		createDate,
		value,
		enabled,
	}) => new Extra({
		id,
		name,
		description,
		longDescription: fullDescription,
		createDate,
		value,
		enabled,
	}),

	
	toDTO: ({
		id,
		name,
		description,
		longDescription,
		km,
		createDate,
		value,
		enabled,
	}) => ({
		id,
		name,
		description,
		fullDescription: longDescription,
		km,
		createDate,
		value,
		enabled,
	}),
};

export default extraMapper;

