import React from "react";
import Detail from "./Detail";
import DetailsDiv from "../../../UI/DetailsDiv/DetailsDiv";
import DetailsRow from "../../../UI/DetailsDiv/DetailsRow/DetailsRow";
import { withStyles } from "@material-ui/core";
import styles from "./Details.styles";

const Devices = ({ info }) => {
  return (
    <DetailsDiv title="Devices">
      {!!info.rfidToken && <DetailsRow label="Rfid Token" detail={<Detail detail={info.rfidToken} />} />}
    </DetailsDiv>
  );
};

export default withStyles(styles)(Devices);
