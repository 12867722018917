const styles = (theme) => ({
	root: {
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: 'white',
		borderRadius: '5px',
		boxShadow: theme.shadows[5],
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: '150px',
		margin: 'auto',
		outline: 0,
		padding: '20px',
		position: 'absolute',
		width: '400px',
		justifyContent: 'space-between',
	},

	errorBar: {
		alignSelf: 'center',
		backgroundColor: '#ff0000e5',
		borderRadius: '5px',
		boxShadow: theme.shadows[5],
		maxHeight: '40px',
		minHeight: '20px',
		overflow: 'hidden',
		position: 'fixed',
		width: '350px',
	},

	errorText: {
		color: '#fff',
	},

	title: {
		fontSize: '16px',
		alignSelf: 'center',
	},

	selection: {
		display: 'flex',
		gap: '10px',
		width: '100%',
		alignItems: 'center',
	},

	modal: {
		zIndex: '9 !important',
	},

	select: {
		flex: 1,
	},
});

export default styles;