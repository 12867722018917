import React from 'react';
import {
	withStyles,
} from '@material-ui/core';
import styles from './PulsingDot.styles.js';
import classnames from 'classnames';

const PulsingDot = ({
	classes,
	className,
}) => {


	return (
		<div className={classnames(classes.root, className)} />
	);
};

export default withStyles(styles)(PulsingDot);
