import { wsCall, requestHelper, setSuccess, setError } from "../../../app/coreSlice";

const assetAddParksAndZonesCall = async (
  { setZonesAndParksSuccessTrigger },
  dispatch,
  state,
  { carToken, deviceToken, licensePlate, unwanted, wanted, parkIds }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "ASSET/ADD_PARKS_AND_ZONES");
  try {
    const result = await wsCall({
      type: "ASSET",
      subType: "ADD_PARKS_AND_ZONES",
      locationId: selectedLocation?.id,
      message: {
        carToken,
        deviceToken,
        licensePlate,
        unwanted,
        wanted,
        parkIds,
      },
    });
    if (!result.error) {
      dispatch(setSuccess("Asset zones and parks updated"));
      dispatch(setZonesAndParksSuccessTrigger(carToken));
      setTimeout(() => {
        dispatch(setZonesAndParksSuccessTrigger(null));
      }, 500);
    } else {
      dispatch(setError(result?.description || "Error adding parks and zones."));
    }
  } catch (_ex) {
    rh.error("Error add asset zones/parks.");
  }
  rh.close();
};

export default assetAddParksAndZonesCall;
