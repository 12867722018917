import { wsCall, requestHelper } from "../../../app/coreSlice";

const reservationDashboardGetCall = async ({ setReservationMetrics }, dispatch, state, { startDate, endDate }) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "RESERVATION/DASHBOARD_GET");
  try {
    const result = await wsCall({
      type: "RESERVATION",
      subType: "DASHBOARD_GET",
      locationId: selectedLocation?.id,
      message: {
        startDate,
        endDate,
      },
    });

    const {
      listGraph: list,
      totalReservation: total,
      totalOngoing: ongoing,
      totalCancelled: cancelled,
      totalAgentTrips: agent,
      totalClosed: closed,
    } = result;

    dispatch(setReservationMetrics({ list, total, ongoing, closed, cancelled, agent }));
  } catch (_ex) {
    rh.error("Error getting reservations metrics.");
  }
  rh.close();
};

export default reservationDashboardGetCall;
