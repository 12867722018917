import React, { useState, useCallback, useEffect } from "react";
import { useDateTime } from "../../../common/hooks";
import { guid } from "../../../common/utils";
import tripService from "../../../services/trip";
import TripAnalysis from "./TripAnalysis";

const searchTypes = ["ALARM", "TELEMATIC", "COMMAND", "HEARTBEAT"];

const TripAnalysisWrapper = ({ trip, ...props }) => {
  const [filter, setFilter] = useState({});
  const [data, setData] = useState({});
  const [error, setError] = useState(false);
  const [selected, setSelected] = useState();
  const { toTimeZone } = useDateTime();

  useEffect(() => {
    if (trip) {
      setFilter((state) => ({
        ...state,
        startDate: toTimeZone(trip.startDate).format("YYYY-MM-DD HH:mm:00"),
        endDate: trip.endDate ? toTimeZone(trip.endDate).add(1, "minute").format("YYYY-MM-DD HH:mm:00") : toTimeZone(trip.startDate).add(1, "days").format("YYYY-MM-DD HH:mm:00"),
      }));
    }
    // eslint-disable-next-line
  }, [trip]);

  const onChange = useCallback((field) => setFilter((state) => ({ ...state, ...field })), []);

  const onSearch = async () => {
    if (!filter.type || !filter.startDate || !filter.endDate) {
      return setError("Please, select a type and dates");
    }

    try {
      const newData = await tripService.getTripAnalysis(filter, trip.deviceToken);
      let list = (newData.commsList || newData.heartbeatsList).map((item) => {
        return {
          ...item,
          localId: guid(),
        };
      });

      setData((state) => {
        delete state[filter.type];
        return { ...state, [filter.type]: [...list] };
      });
    } catch (e) {
      setError(e.message || e.description || `Could not get data for ${filter.type}`);
    }
  };

  const onRemove = useCallback(
    (key) =>
      setData((state) => {
        const newState = { ...state };
        delete newState[key];

        if (!!selected?.[key]) {
          setSelected();
        }

        return newState;
      }),
    [selected]
  );

  return (
    <TripAnalysis
      {...props}
      data={data}
      error={error}
      filter={filter}
      onChange={onChange}
      onRemove={onRemove}
      onSearch={onSearch}
      searchTypes={searchTypes}
      selected={selected}
      setSelected={setSelected}
    />
  );
};

export default TripAnalysisWrapper;
