export const styles = () => ({
  root: {
    padding: "10px 5px",
    borderBottom: "1px solid #ddd",
  },
  type: {
    fontWeight: "bold",
    fontSize: 14,
    marginBottom: 4,
  },
  selected: {
    backgroundColor: "#eef6fa",
  },
  checkItem: {
    fill: "#00b67f",
  },
  resolvedText: {
    fontSize: 10,
  },
  description: {
    textAlign: "center",
    padding: "2px",
    width: "100%",
  },
  label: {
    fontWeight: "bold",
    minWidth: 90,
  },
  resolveBtn: {
    textAlign: "center",
    margin: "10px auto",
  },
  pictureBtn: {
    cursor: "pointer",
    height: 24,
    width: 24,
  },
});
