import React, { useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";

import { styles } from "./DamageItem.styles";
import { useDateTime } from "../../../../../common/hooks";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CommonButtons from "../../../../../components/UI/Buttons/CommonButtons";
import ImageModal from "../../../../../common/displays/Modal/ImageModal";
import { PicturePlaceholder } from "./PicturePlaceholder";

function DamageItem({ classes, damage, selected, onSelect, onResolve }) {
  const { toTimeZone } = useDateTime();
  const [picModalOpen, setPicModalOpen] = useState(false);

  const onResolveHandler = () => {
    if (damage?.damageToken) {
      onResolve && onResolve(damage.damageToken);
    }
  }

  return (
    <Grid
      className={classes.root + (selected ? " " + classes.selected : "")}
      container
      direction="column"
      onClick={() => onSelect && onSelect(damage.damageToken || damage.id)}
    >
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography className={classes.type}>{damage.incidentType}</Typography>
          </Grid>
          <Grid item>
            <Typography>{toTimeZone(damage.createDate, "dateTime")}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Grid container wrap="nowrap">
                  <Typography className={classes.label}>Description:</Typography>
                  <Typography>{damage.text || damage.incidentReport}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container wrap="nowrap">
                  <Typography className={classes.label}>Created by:</Typography>
                  <Typography>{damage.createUsername}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container wrap="nowrap">
                  <Typography className={classes.label}>Reservation:</Typography>
                  <Typography>{damage.reference}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {damage.urlImagePublic && (
            <Grid item>
              <div onClick={() => setPicModalOpen(true)}>
                <PicturePlaceholder className={classes.pictureBtn} />
              </div>
              <ImageModal open={picModalOpen} setOpen={setPicModalOpen} url={damage.urlImagePublic} />
            </Grid>
          )}
        </Grid>
      </Grid>
      {selected && !damage.resolvedDate && (
        <Grid item>
          <CommonButtons
            className={classes.resolveBtn}
            label="Resolve"
            onClick={onResolveHandler}
          />
        </Grid>
      )}
      {damage.resolvedDate && (
        <Grid item>
          <Grid container wrap="nowrap" alignItems="center">
            <CheckCircleOutlineIcon className={classes.checkItem} />
            <Typography className={classes.resolvedText}>
              Resolved by {damage.resolvedUsername} at {toTimeZone(damage.resolvedDate, "dateTime")}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default withStyles(styles)(DamageItem);
