import React from "react";
import Modal from "../../UI/Modal/Modal";
import ErrorDiv from "../../ErrorDiv/ErrorDiv";
import CommonButtons from "../../UI/Buttons/CommonButtons";
import { Typography, withStyles } from "@material-ui/core";
import styles from "./CreateLocations.styles";
import withPermission from "../../../hoc/withPermission";
import { TextInput, DecimalInput, TimeZoneInput } from "../../../common/inputs";

const CreateLocations = ({
  classes,
  createOrUpdateLocation,
  error,
  location,
  placeholder,
  polygon,
  setError,
  setPlaceholder,
  stopDrawing,
  setXML,
}) => {
  const content = (
    <div className={classes.content}>
      {!!error && <ErrorDiv className={{ div: classes.errorDiv, text: classes.errorText }} message={error} />}
      <TextInput label="Designation" value={placeholder?.name} onChange={(value) => setPlaceholder({ name: value })} />
      <DecimalInput
        label="Latitude"
        value={placeholder?.latitude}
        onChange={(value) => setPlaceholder({ latitude: value })}
        decimalScale={6}
      />
      <DecimalInput
        label="Longitude"
        value={placeholder?.longitude}
        onChange={(value) => setPlaceholder({ longitude: value })}
        decimalScale={6}
      />
      <TimeZoneInput
        label="TimeZone"
        value={placeholder?.timezone}
        onChange={(value) => setPlaceholder({ timezone: value })}
      />
      <Typography align="center">Tip: Choose a coordinate that represents the centre point of the location.</Typography>
      {location?.code && (
        <ButtonWithPermission>
          <div className={classes.importContainer}>
            <label htmlFor="xml_import" accept=".xml, .kml" className={classes.importButton}>
              <Typography className={classes.attachmentInput}>Upload KML</Typography>
            </label>
            <input
              id="xml_import"
              onChange={(event) => setXML(event.target.files[0])}
              type="file"
              accept=".xml, .kml"
            />
          </div>
        </ButtonWithPermission>
      )}
      <div className={classes.buttons}>
        <CommonButtons key="Cancel" buttonStyleName="void" onClick={stopDrawing} label="Cancel" />
        <CommonButtons
          key="Submit"
          buttonStyleName="filled"
          onClick={() =>
            createOrUpdateLocation(
              polygon,
              placeholder.name,
              placeholder.latitude,
              placeholder.longitude,
              placeholder.timezone,
              setError,
              stopDrawing,
              placeholder.code
            )
          }
          label={placeholder.code ? "Update" : "Create"}
        />
      </div>
    </div>
  );

  const modal = <Modal content={content} className={classes.root} />;

  return modal;
};

const ButtonWithPermission = withPermission([{ resource: ["fleet", "locations", "create"] }])(({ children }) => (
  <>{children}</>
));

export default withStyles(styles)(CreateLocations);
