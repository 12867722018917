export const styles = (theme) => ({
  root: {
    backgroundColor: "white",
    borderRadius: 5,
    boxShadow: theme.shadows[5],
    boxSizing: "border-box",
    outline: 0,
    padding: "15px",
    position: "absolute",
    overflowX: "hidden",
    overflowY: "auto",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "5px",
      boxShadow: "inset 0 0 15px #00000060",
    },
  },
  big: {
    height: "90vh",
    width: "80vw",
    top: "5vh",
    left: "10vw",
  },
  medium: {
    height: "70vh",
    width: "60vw",
    top: "15vh",
    left: "20vw",
    [theme.breakpoints.down("md")]: {
      height: "90vh",
      top: "5vh",
    },
  },
  sm: {
    padding: "20px",
    height: "500px",
    width: "600px",
    top: "calc(50% - 250px)",
    left: "calc(50% - 300px)",
  },
  small: {
    height: "360px",
    width: "300px",
    top: "calc(50% - 180px)",
    left: "calc(50% - 150px)",
  },
});
