import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Tooltip, Switch, Typography, Grid } from "@material-ui/core";

import { styles } from "./Immobilizer.styles";

function Immobilizer({ classes, disabled, tooltip, checked, onChange }) {
  return (
    <Tooltip title={tooltip || ""}>
      <Grid container className={classes.root} direction="row" alignItems="center">
        <Grid item>
          <Typography>Immobilizer:</Typography>
        </Grid>
        <Grid item>
          <Switch disableRipple disabled={disabled} checked={checked} onChange={onChange} />
        </Grid>
      </Grid>
    </Tooltip>
  );
}

export default withStyles(styles)(Immobilizer);
