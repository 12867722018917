const styles = theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: '100%',
	},

	typography: {
		fontSize: '10px',
		lineHeight: '20px',
	},

	details: {
		position: 'relative',
		padding: '0px 5px 5px',
		display: 'inherit',
		height: '100%',
		boxShadow: 'none',
	},

	icons: {
		display: 'flex',
		alignSelf: 'center',
		flexDirection: 'row-reverse',
		flexGrow: 1,
	},

	icon: {
		width: '21px',
		height: '16px',
		margin: '0 5px',
		fill: 'gray',
		alignSelf: 'center',
		cursor: 'pointer',
	},

	selected: {
		fill: '#009AC9',
		cursor: 'default',
	},

	downloadIcon: {
		width: '15px',
		height: '15px',
		alignSelf: 'center',
		cursor: 'pointer',
		fill: '#009AC9',
	},

	buttonDiv: {
		flexGrow: '1',
		flexDirection: 'column-reverse',
		display: 'flex',
		alignItems: 'center',
		paddingBottom: '5%',
	},

	buttons: {
		display: 'flex',
		justifyContent: 'space-around',
		width: '100%',
	},

	attachments: {
		fontWeight: 'bold',
		lineHeight: '20px',
		fontSize: '12px',
		display: 'flex',
	},

	chevron:{
		transition: '500ms',
	},

	open: {
		transform: 'rotate(180deg)',
	},

	files: {
		transition: 'max-height 500ms ease-in',
		maxHeight: 'var(--height)',
		overflowY: 'hidden',
	},

	noDisplay: {
		maxHeight: '0px',
		overflowY: 'hidden',
	},
});

export default styles;