import React from "react";
import { Form as FinalForm } from "react-final-form";
import { useValidationSchema } from "../hooks";
// import { makeValidate } from "mui-rff";

function Form({ schema, render, ...props }) {
  const validate = useValidationSchema(schema);
  // const validate = makeValidate(schema);
  return (
    <FinalForm
      validate={validate}
      {...props}
      render={({ handleSubmit, ...rest }) => (
        <form onSubmit={handleSubmit} noValidate>
          {render && render(rest)}
        </form>
      )}
    />
  );
}

export default Form;
