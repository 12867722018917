import React from 'react';
import {
	withStyles,
} from '@material-ui/core';
import { styles } from '../CommonStyles.styles';

const AppConfigs = ({
	classes,
	editMode,
	placeholder,
	onChangeHandler,
	inputBuilders,
	isDefaultConfigs,
}) => {

	const {
		getLabelAndInput,
	} = inputBuilders || {};

	const appName = getLabelAndInput({editMode: editMode && !!isDefaultConfigs, fieldLabel: 'App name', fieldString: 'appName',
		onChangeHandler, placeholder, classes, rowsNumberString: '1'});

	const appVersions = getLabelAndInput({editMode: editMode && !!isDefaultConfigs, fieldLabel: 'App versions', fieldString: 'appVersions',
		onChangeHandler, placeholder, classes, rowsNumberString: '1'});

	const hostUrl = getLabelAndInput({editMode: editMode && !!isDefaultConfigs, fieldLabel: 'Host Url', fieldString: 'hostUrl',
		onChangeHandler, placeholder, classes, rowsNumberString: '1'});

	const hostKey = getLabelAndInput({editMode: editMode && !!isDefaultConfigs, fieldLabel: 'Host key', fieldString: 'hostKey',
		onChangeHandler, placeholder, classes, rowsNumberString: '1'});

	return (
		<div className={classes.root}>
			<div className={classes.verticalBlockTrips}>
				{appName}
				{appVersions}
				{hostUrl}
				{hostKey}
			</div>
		</div>
	);
};


export default withStyles(styles)(AppConfigs);


