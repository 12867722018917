import createModel from '../createModel';

class Incident {

	constructor({
		administrativeCost,
		attachment,
		carToken,
		closed,
		createDate,
		customerCode,
		description,
		id,
		incidentCode,
		incidentCost,
		incidentType,
		invoiced,
		invoiceId,
		lastModified,
		liabilityPercentage,
		licensePlate,
		paid,
		pdfUrl,
		reservationCode,
	}) {
		createModel.call(this, {
			administrativeCost: !!administrativeCost ? administrativeCost.toString() : null,
			attachment,
			carToken,
			closed,
			createDate,
			customerCode,
			description,
			id,
			incidentCode,
			incidentCost: incidentCost && incidentCost.toString(),
			incidentType,
			invoiced,
			invoiceId,
			lastModified,
			liabilityPercentage: liabilityPercentage && liabilityPercentage.toString(),
			licensePlate,
			paid,
			pdfUrl,
			reservationCode,
		});
	};
};

export default Incident;