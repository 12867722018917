import React from 'react';
import {
	TextField,
	Typography,
	withStyles,
} from '@material-ui/core';
import Button from '../../../../UI/Buttons/CommonButtons';
import SearchList from '../../../../UI/SearchList/SearchListWrapper';
import styles from './AddTask.styles';
import { ReactComponent as PlusSignIcon } from '../../../../../assets/PromotionApp/icons/add.svg';


const AddTask = ({
	classes,
	getTypes,
	listRef,
	onAdd,
	onChange,
	onCreate,
	placeholder = {},
}) => {
	const title = (
		<Typography className={classes.title}>
			Add tasks
		</Typography>
	);

	const taskTypeSearch = (
		<SearchList
			className={classes.searchType}
			currentFields={{ key: placeholder.name, label: placeholder.name }}
			getList={getTypes}
			header='Type'
			name='Type'
			onAdd={name => onAdd(name)}
			onLoad
			onRemove={() => onChange({ name: undefined })}
			addIcon={PlusSignIcon}
			removeIcon={PlusSignIcon}
			ref={listRef}
			searchLabel='Search Type'
		/>
	);

	const customTitle = (
		<Typography className={classes.subtitle}>
			Custom Task
		</Typography>
	);

	const customField = (
		<div className={classes.inputText}>
			<Typography className={classes.textInputLabel}>
				Type
			</Typography>
			<TextField
				onChange={event => onChange({ newType: event.target.value })}
				className={classes.select}
				value={placeholder.newType || ''}
			/>
			<Button
				key='create'
				label='Create'
				onClick={onCreate}
			/>
		</div>
	);

	return (
		<div className={classes.root}>
			{title}
			{taskTypeSearch}
			{customTitle}
			{customField}
		</div>
	);
};

export default withStyles(styles)(AddTask);