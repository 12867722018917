import React from 'react';
import {
	Typography,
	List,
	withStyles,
} from '@material-ui/core';
import styles from './PlaceholderList.styles';
import classnames from 'classnames';

const PlaceholderList = ({
	classes,
	className,
	placeholders,
}) => {

	const styledPlaceholders = placeholders &&
        Array.isArray(placeholders) && placeholders.map( e => (
		<Typography key={e}>
			{e}
		</Typography>
	));

	return (
		<div className={classnames(classes.root, className)}>
			<List>
				{styledPlaceholders}
			</List>
		</div>
	);
};

export default withStyles(styles)(PlaceholderList);