import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { InputAdornment, TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";

import { styles } from "./_base.styles";

function NumberFormatCustom(props) {
  const { inputRef, onChange, decimalScale, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      //onFocus={(e) => e.target && e.target.select && e.target.select()}
      allowNegative={true}
      isNumericString
      decimalScale={decimalScale}
      allowLeadingZeros={false}
    />
  );
}

function Decimal({ classes, label, value, onChange, disabled, decimalScale, endAdornment, required }) {
  return (
    <div className={classes.root}>
      <TextField
        fullWidth
        InputProps={{
          inputComponent: NumberFormatCustom,
          endAdornment: endAdornment ? (
            <InputAdornment className={classes.adornment} position="end">
              {endAdornment}
            </InputAdornment>
          ) : undefined,
        }}
        inputProps={{
          decimalScale: decimalScale,
          className: classes.decimalInput
        }}
        className={disabled ? classes.disabledInput : ""}
        label={label + (required ? " *" : "")}
        onChange={(e) => onChange && onChange(e.target.value)}
        value={value}
        size="small"
        disabled={disabled}
      />
    </div>
  );
}

export default withStyles(styles)(Decimal);
