import { Check } from "@material-ui/icons";

export function listColumns(classes, dt) {
  return [
    {
      field: "customerCode",
      headerName: "Customer No.",
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "fullName",
      headerName: "Name",
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "phoneNumber",
      headerName: "Mobile Number",
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "registrationDate",
      headerName: "Registration",
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return dt.toTimeZone(v.registrationDate, "date");
      },
    },
    {
      field: "available",
      headerName: "Enabled",
      align: "center",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return v.available && <Check className={classes.checkIcon} />;
      },
    },
    {
      field: "isAgent",
      headerName: "Agent",
      align: "center",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return v.isAgent && <Check className={classes.checkIcon} />;
      },
    },
    {
      field: "onboarded",
      headerName: "Onboarded",
      align: "center",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return v.onboarded && <Check className={classes.checkIcon} />;
      },
    },
  ];
}
