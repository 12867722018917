import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { CustomDataGrid } from "../../../../common/displays";

import { styles } from "./CustomersDataGrid.styles";
import { listColumns } from "./CustomersDataGrid.columns";
import CustomersDataGridActions from "./CustomersDataGrid.actions";
import { useDateTime } from "../../../../common/hooks";
import SimpleConfirmationModal from "../../../../common/displays/Modal/SimpleConfirmationModal";
import {
  customerNewList,
  selectCustomers,
  setErrorMovingDriver,
  setSelectedCustomerByCode,
  setShowMoveDriverModal,
} from "../../customerSlice";
import { selectIsLoading } from "../../../../app/coreSlice";
import { selectDefaultLocation } from "../../../carclub/carclubSlice";

function CustomersDataGrid({
  classes,
  onRowSelected,
  onEdit,
  onOnboardChanged,
  onAccountDetails,
  onAgentChanged,
  onAvailableChange,
  onResetPassword,
}) {
  const dispatch = useDispatch();
  const selectedLocation = useSelector(selectDefaultLocation);
  const dt = useDateTime();
  const columns = listColumns(classes, dt);
  const [confirmResetPasswordModal, setConfirmResetPasswordModal] = useState(false);
  const [customer, setCustomer] = useState();
  const customers = useSelector(selectCustomers);
  const isLoading = useSelector(selectIsLoading);

  const onResetPasswordHandler = (customer) => {
    setCustomer(customer);
    setConfirmResetPasswordModal(true);
  };

  const onConfirmResetPasswordHandler = () => {
    setConfirmResetPasswordModal(false);
    onResetPassword && onResetPassword(customer?.code);
  };

  const onMoveDriverHandler = (customer) => {
    setCustomer(customer);
    dispatch(setErrorMovingDriver());
    dispatch(setSelectedCustomerByCode(customer.code));
    dispatch(setShowMoveDriverModal(true));
  };

  return (
    <div className={classes.root}>
      {customers?.list && (
        <CustomDataGrid
          className={classes.datagrid}
          columns={columns}
          rows={customers.list}
          onLoadMore={() => {
            if (!customers.lastPage && !isLoading) {
              dispatch(
                customerNewList({
                  reset: false,
                  filter: { ...customers?.filter, page: (customers?.filter?.page || 0) + 1 },
                })
              );
            }
          }}
          onRowSelected={onRowSelected}
          actionsComponent={
            <CustomersDataGridActions
              classes={classes}
              onEdit={onEdit}
              onOnboardChanged={onOnboardChanged}
              onAccountDetails={onAccountDetails}
              onAgentChanged={onAgentChanged}
              onAvailableChange={onAvailableChange}
              onResetPassword={onResetPasswordHandler}
              selectedLocation={selectedLocation}
              onMoveDriver={onMoveDriverHandler}
            />
          }
        />
      )}
      <SimpleConfirmationModal
        open={confirmResetPasswordModal}
        setOpen={setConfirmResetPasswordModal}
        message={`You are about to reset ${customer?.fullName || customer?.name || customer?.email} password. Confirm?`}
        onConfirm={onConfirmResetPasswordHandler}
      />
    </div>
  );
}

export default withStyles(styles)(CustomersDataGrid);
