// FIXME this whole file needs serious refactoring...
function PricingPlanDetail({
	id,
	description,
	timeStartMin,
	timeEnd,
	distancePrice,
	timePrice,
	distanceOffer,
	timeOffer,
	isDefault,
	maxTimePrice,
}) {
	this.id = id;
	this.description = description;
	this.timeStartMin = timeStartMin;
	this.timeEnd = timeEnd;
	this.distancePrice = distancePrice;
	this.timePrice = timePrice;
	this.distanceOffer = distanceOffer;
	this.timeOffer = timeOffer;
	this.isDefault = isDefault;
	this.maxTimePrice = maxTimePrice;
}

function PricingPlanDetailDTO({
	id,
	description,
	timeStartMin,
	timeEnd,
	distancePrice,
	timePrice,
	distanceOffer,
	timeOffer,
	isDefault,
	maxTimePrice,
}) {
	this.id = id;
	this.description = description;
	this.timeStartMin = timeStartMin;
	this.timeStopMin = timeEnd;
	this.distancePrice = distancePrice;
	this.timePrice = timePrice;
	this.distanceOffer = distanceOffer;
	this.timeOffer = timeOffer;
	this.default = isDefault;
	this.maxTimePrice = maxTimePrice;
}

PricingPlanDetail.prototype.isEqual = function (detail) {


	return this.id === detail.id
		&& this.description === detail.description
		&& this.timeStartMin === detail.timeStartMin
		&& this.timeEnd === detail.timeEnd
		&& this.distancePrice === detail.distancePrice
		&& this.timePrice === detail.timePrice
		&& this.distanceOffer === detail.distanceOffer
		&& this.timeOffer === detail.timeOffer
		&& this.isDefault === detail.isDefault
		&& this.maxTimePrice === detail.maxTimePrice;
};

PricingPlanDetail.prototype.clone = function () {
	const clone = {};

	if (typeof this.id !== 'undefined') {
		clone.id = this.id;
	}
	if (typeof this.description !== 'undefined') {
		clone.description = this.description;
	}
	if (typeof this.timeStartMin !== 'undefined') {
		clone.timeStartMin = this.timeStartMin;
	}
	if (typeof this.timeEnd !== 'undefined') {
		clone.timeEnd = this.timeEnd;
	}
	if (typeof this.distancePrice !== 'undefined') {
		clone.distancePrice = this.distancePrice;
	}
	if (typeof this.timePrice !== 'undefined') {
		clone.timePrice = this.timePrice;
	}
	if (typeof this.distanceOffer !== 'undefined') {
		clone.distanceOffer = this.distanceOffer;
	}
	if (typeof this.timeOffer !== 'undefined') {
		clone.timeOffer = this.timeOffer;
	}
	if (typeof this.isDefault !== 'undefined') {
		clone.isDefault = this.isDefault;
	}
	if (typeof this.maxTimePrice !== 'undefined') {
		clone.maxTimePrice = this.maxTimePrice;
	}
	return new PricingPlanDetail(clone);
};

PricingPlanDetail.isEqual = function (detail1, detail2) {
	return detail1.id === detail2.id
		&& detail1.description === detail2.description
		&& detail1.timeStartMin === detail2.timeStartMin
		&& detail1.timeEnd === detail2.timeEnd
		&& detail1.distancePrice === detail2.distancePrice
		&& detail1.timePrice === detail2.timePrice
		&& detail1.distanceOffer === detail2.distanceOffer
		&& detail1.timeOffer === detail2.timeOffer
		&& detail1.isDefault === detail2.isDefault
		&& detail1.maxTimePrice === detail2.maxTimePrice;
};

export const mapperPricingPlanDetailFlows = ({
	id,
	description,
	timeStartMin,
	timeStopMin,
	distancePrice,
	timePrice,
	distanceOffer,
	timeOffer,
	default: isDefault,
	maxTimePrice,
}) => new PricingPlanDetail({
	id,
	description,
	timeStartMin: timeStartMin,
	timeEnd: timeStopMin,
	distancePrice,
	timePrice,
	distanceOffer,
	timeOffer,
	isDefault: isDefault,
	maxTimePrice,
});

export const mapperPricingPlanDetailToDTO = ({
	id,
	description,
	timeStartMin,
	timeEnd,
	distancePrice,
	timePrice,
	distanceOffer,
	timeOffer,
	isDefault,
	maxTimePrice,
}) => new PricingPlanDetailDTO({
	id,
	description,
	timeStartMin,
	timeEnd,
	distancePrice,
	timePrice,
	distanceOffer,
	timeOffer,
	isDefault,
	maxTimePrice,
});

export default PricingPlanDetail;
