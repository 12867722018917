
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import customerFlowMapper from '../../customer/ws/mappers/customerFlowMapper';
import { publishAndAwait } from '../../../app/coreSlice';

const listAgents = async (filters) => {

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'WORKTASK',
			subType: 'GET_AGENTS',
			message: {
				...filters,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}
	
	const {
		customers,
		lastPage,
	} = processMessage || {};

	const agents = customers.map(agent => customerFlowMapper.fromDTO(agent));

	return {
		agents,
		lastPage,
	};
};

export default listAgents;
