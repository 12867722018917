const styles = theme => ({
	root: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		height: '100%',
		position: 'relative',
		width: '100%',
	},

	title: {
		fontSize: '18px',
		marginTop: '10px',
	},

	header: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'end',
	},

	buttons: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},

	button: {
		paddingLeft: 8,
	},

	searchType: {
		margin: 0,
	},
	
	tasks: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		padding: '0 8px',
	},

	addTasks: {
		boxShadow: '-2px 0 #00000029',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		justifyContent: 'flex-start',
		maxWidth: '390px',
		minWidth: '390px',
		overflowY: 'auto',
		padding: '0 8px',
		width: '100%',
	},

	details: {
		display: 'flex',
		flexDirection: 'row',
		height: '50%',
		width: '100%',
		boxShadow: '0 -2px #00000029',
	},
});

export default styles;