const styles = () => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		height: '100%',
		position: 'relative',
		width: '100%',
	},
});

export default styles;