import React, { useState, useCallback, useEffect } from 'react';
import notificationService from '../../../../../services/notification';
import Configurations from './Configurations';

const ConfigurationsWrapper = ({
	configs,
	saveConfigs,
	...props
}) => {

	const {
		onCancel,
	} = props;

	const [hasRequest, setHasRequest] = useState(true);
	const [error, setError] = useState();
	const [placeholder, setPlaceholder] = useState({});

	useEffect(() => {
		const fetchConfigurations = async () => {
			try {
				const configurations = await notificationService.getConfigurationsSMS();
				setPlaceholder(configurations);
			}
			catch{
				setError('Could not get configurations from server');
			};
			setHasRequest(false);
		};
		fetchConfigurations();
	}, []);

	const onChange = useCallback(({ name, value }) => {
		setError();
		setPlaceholder(prevState => ({ ...prevState, [name]: value }));
	}, []);

	const onSave = async () => {
		setHasRequest(true);
		setError();
		try {
			await notificationService.updateConfigurationsSMS(placeholder);			
			return onCancel();
		}
		catch(error) {
			setHasRequest(false);
			setError(error.message);
		}
	};

	const configurationsWrapper = (
		<Configurations
			{...props}
			errorMessage={error}
			hasRequest={hasRequest}
			onChange={onChange}
			onSave={onSave}
			placeholder={placeholder}
		/>
	);

	return configurationsWrapper;
};

export default ConfigurationsWrapper;