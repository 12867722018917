import { requestHelper, wsCall } from "../../../app/coreSlice";

const userGetLocationsCall = async ({ setUserLocations }, dispatch, state) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "USER/GET_LOCATIONS");
  try {
    const data = await wsCall({
      type: "USER",
      subType: "GET_LOCATIONS",
      locationId: selectedLocation?.id,
    });
    dispatch(setUserLocations((data || [])?.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0))));
  } catch (_ex) {
    rh.error("Something went wrong, failed get user locations");
  }
  rh.close();
};

export default userGetLocationsCall;
