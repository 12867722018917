import React from "react";
import { SvgIcon } from "@material-ui/core";

function ReservationIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 100 100">
      <g transform="translate(50 50) scale(0.69 0.69) rotate(0) translate(-50 -50)">
        <svg viewBox="0 0 232 358" x="0px" y="0px" fillRule="evenodd" clipRule="evenodd">
          <g>
            <path d="M102 314l0 -96 -72 0c-4,0 -8,-2 -10,-6l-18 -29c-3,-3 -3,-7 0,-10l18 -30c2,-3 6,-5 10,-5l72 0 0 -30 -97 0c-4,0 -6,-5 -4,-8l20 -32 -20 -32c-2,-3 0,-8 4,-8l97 0 0 -15c0,-17 27,-17 27,0l0 15 73 0c4,0 7,2 9,5l19 30c2,3 2,7 0,10l-19 29c-2,4 -5,6 -9,6l-73 0 0 30 97 0c5,0 7,5 5,8l-21 32 21 32c2,3 -1,8 -5,8l-97 0 0 96 21 0c10,0 18,8 18,17l0 27 -105 0 0 -27c0,-9 8,-17 18,-17l21 0z"></path>
          </g>
        </svg>
      </g>
    </SvgIcon>
  );
}

export default ReservationIcon;
