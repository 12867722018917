import React, { useState } from "react";
import ListWorkspace from "../../components/WorkOrders/ListWorkspace/ListWorkspaceWrapper";

import { withStyles } from "@material-ui/core";
import styles from "./WorkOrderApp.styles";
import classnames from "classnames";
import useH3Map from "../../hooks/useH3Map/useH3Map";

const WorkOrderApp = ({ classes }) => {
  const [selectedWorkOrder, setSelectedWorkOrder] = useState();

  const h3Map = useH3Map({});

  return (
    <div className={classnames(classes.root)}>
      <div className={classes.workspace}>
        <ListWorkspace
          selectedWorkOrder={selectedWorkOrder}
          setSelectedWorkOrder={setSelectedWorkOrder}
          h3Map={h3Map}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(WorkOrderApp);
