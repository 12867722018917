const styles = theme => ({
	
	root: {
		display: 'flex',
	},
	
	editIcon: {
		color: '#009AC9',
	},

	editIconDisabled: {
		color: '#C5C5C5',
		cursor: 'default',
	},

	checkIcon: {
		color: '#51B383',
	},

	requestIcon: {
		color: '#0089D888',
		cursor: 'default',
	},
});

export default styles;