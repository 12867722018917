export const CREDIT_CARD = "CREDIT_CARD";
export const DEBIT_CARD = "DEBIT_CARD";
export const TOP_UP = "TOP_UP";

export const paymentType = (type) => {
  return (
    {
      CREDIT_CARD: "Credit",
      DEBIT_CARD: "Debit",
      TOP_UP: "Top up",
      NO_PAYMENT: "No payment",
    }[type] || ""
  );
};
