import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const customerUpdateCall = async ({ setCustomerUpdated }, dispatch, state, customer) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "CUSTOMER/UPDATE");
  try {
    const customerUpdated = await wsCall({
      type: "CUSTOMER",
      subType: "UPDATE",
      locationId: selectedLocation?.id,
      message: toCustomerDTO(customer),
    });
    if (!customerUpdated.error) {
      dispatch(setSuccess("Customer updated."));
      dispatch(setCustomerUpdated(fromCustomerDTO(customerUpdated)));
    } else {
      rh.error(customerUpdated?.description || "Error updating customer.");
    }
  } catch (_ex) {
    rh.error("Error updating customer.");
  }
  rh.close();
};

export default customerUpdateCall;

const _getFullName = (fullname, name, surname) => {
  if (!!fullname) {
    return fullname;
  }

  if (!!name && !!surname) {
    return name + " " + surname;
  }

  return name || surname;
};

const fromCustomerDTO = ({
  aceptTermsDate,
  activationDate,
  address,
  addressCity,
  addressCountry,
  addressState,
  addressZipCode,
  available,
  billingAddress,
  billingAddressCity,
  billingAddressCountry,
  billingAddressState,
  billingAddressZipCode,
  birthDate,
  carclubId,
  code,
  createDate,
  customerCode,
  deactivated,
  email,
  emailValidation,
  fatherName,
  fullname,
  grandFatherName,
  id,
  imageUrl,
  isAgent,
  mobileNumber,
  mobile,
  mobileValidation,
  name,
  nationality,
  nif,
  onboarded,
  online,
  phoneNumber,
  policyPrivate,
  socialName,
  surname,
  tawtheeq,
  taxNumber,
  termsVersion,
  worktasksCount,
  role,
  rfidToken,
  affiliateId
}) => {
  return {
    acceptedTerms: aceptTermsDate,
    activationDate: activationDate || undefined,
    address: address || "",
    available: available !== null ? available : undefined,
    billingAddress: billingAddress || "",
    billingCity: billingAddressCity || "",
    billingCountry: billingAddressCountry || "",
    billingState: billingAddressState || "",
    billingZipCode: billingAddressZipCode || undefined,
    birthdate: birthDate || undefined,
    carclubId: carclubId || undefined,
    city: addressCity || "",
    code: code || customerCode || "",
    country: addressCountry || "",
    createDate: createDate || undefined,
    customerCode: code || customerCode || "",
    enabled: !deactivated,
    email: email || "",
    emailVerified: emailValidation !== null ? emailValidation : undefined,
    fullName: fullname || _getFullName(fullname, name, surname) || "",
    grandParentName: grandFatherName || "",
    id: id || undefined,
    imageUrl: imageUrl || undefined,
    isAgent: isAgent !== null ? isAgent : undefined,
    mobileVerified: mobileValidation !== null ? mobileValidation : undefined,
    mobile: mobile || phoneNumber,
    name: name || "",
    nationality: nationality || "",
    nif: nif || undefined,
    onboarded,
    online,
    parentName: fatherName || "",
    phone: phoneNumber || undefined,
    phoneNumber: mobileNumber || phoneNumber || undefined,
    privacyPolicy: policyPrivate !== null ? policyPrivate : undefined,
    registrationDate: createDate || undefined,
    socialName: socialName || "",
    state: addressState || "",
    surname: surname || "",
    tawtheeq,
    taxNumber: taxNumber || undefined,
    termsVersion,
    worktasksCount,
    zipCode: addressZipCode || "",
    role,
    rfidToken,
    affiliateId
  };
};

const toCustomerDTO = ({
  acceptedTerms,
  activationDate,
  address,
  agent,
  available,
  billingAddress,
  billingCity,
  billingCountry,
  billingState,
  billingZipCode,
  birthdate,
  carclubId,
  city,
  code,
  country,
  createDate,
  customerCode,
  email,
  emailVerified,
  fullName,
  id,
  imageUrl,
  mobileVerified,
  mobile,
  name,
  nationality,
  nif,
  onboarded,
  phoneNumber,
  privacyPolicy,
  registrationDate,
  socialName,
  state,
  surname,
  taxNumber,
  termsVersion,
  zipCode,
  rfidToken,
  affiliateId
}) => {
  return {
    aceptTermsDate: acceptedTerms !== null ? acceptedTerms : undefined,
    acceptedTerms: acceptedTerms !== null ? acceptedTerms : undefined,
    activationDate: activationDate || undefined,
    address: address || "",
    addressCity: city || "",
    addressCountry: country || "",
    addressState: state || "",
    addressZipCode: zipCode || "",
    isAgent: agent !== null ? agent : undefined,
    available: available !== null ? available : undefined,
    billingAddress: billingAddress || "",
    billingAddressCity: billingCity || "",
    billingAddressCountry: billingCountry || "",
    billingAddressState: billingState || "",
    billingAddressZipCode: billingZipCode || undefined,
    birthDate: birthdate || undefined,
    carclubId: carclubId || undefined,
    code: code || customerCode || "",
    createDate: createDate || registrationDate || undefined,
    customerCode: customerCode || "",
    email: email || "",
    emailValidation: emailVerified !== null ? emailVerified : undefined,
    fullName: fullName || _getFullName(null, name, surname) || "",
    fullname: fullName || _getFullName(null, name, surname) || "",
    id: id || undefined,
    imageUrl: imageUrl || undefined,
    mobileNumber: mobile || phoneNumber || "",
    mobileValidation: mobileVerified !== null ? mobileVerified : undefined,
    name: name || "",
    nationality: nationality || "",
    nif: nif || "",
    onboarded,
    policyPrivate: privacyPolicy !== null ? privacyPolicy : undefined,
    socialName: socialName || "",
    surname: surname || "",
    taxNumber: taxNumber || undefined,
    termsVersion,
    rfidToken,
    affiliateId
  };
};
