import React from 'react';
import {
	TextField,
	Typography,
	withStyles,
} from '@material-ui/core';
import Map from '../../Map/MapWrapper';
import styles from './Details.styles';
import classnames from 'classnames';

const Details = ({
	additionalInfo,
	classes,
	className,
	images,
	onChangeTaskPlaceholderHandler,
	selectedTask,
	servicePoint,
	task,
}) => {

	const comments = (
		<div className={classes.section}>
			<Typography className={classes.title}>
				Additional Info
			</Typography>
			<TextField
				className={classes.description}
				fullWidth
				multiline
				onInput={event => onChangeTaskPlaceholderHandler(event.target.value)}
				minRows='3'
				value={additionalInfo || ''}
				variant='filled'
				InputProps={{
					disableUnderline: true,
					style: {
						padding: '10px',
					},
				}}
				disabled={!selectedTask && selectedTask !== 0}
			/>
			<Typography className={classes.title}>
				Comments
			</Typography>
			<Typography className={classes.text}>
				{task?.comments}
			</Typography>
		</div>
	);

	const pictures = (images || [
	]).map((picture, index) => (
		<a href={picture} target='_blank' rel='noreferrer noopener' key={index}>
			<img src={picture} className={classes.thumbnail} alt='thumbnail' />
		</a>));

	const thumbnails = (
		<div className={classes.sectionGrid}>
			<Typography className={classes.title}>
				Pictures
			</Typography>
			{pictures}
		</div>
	);

	const mapServicePoint = servicePoint && (
		<Map
			mapOptions={{ gestureHandling: 'none', zoomControl: true, draggableCursor: 'default' }}
			mapCenter={servicePoint}
			servicePoints={servicePoint && [servicePoint]}
			simpleMap
		/>
	);

	const map = (
		<div className={classes.section}>
			<Typography className={classes.title}>
				Map
			</Typography>
			{mapServicePoint}
		</div>
	);

	return (
		<div className={classnames(classes.root, className)}>
			{comments}
			{thumbnails}
			{map}
		</div>
	);
};

export default withStyles(styles)(Details);