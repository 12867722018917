import React from 'react';
import Alert from '../../../ErrorDiv/Alert';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import { styles } from './RatingInfo.styles';
// import withPermission from '../../../../hoc/withPermission';
import classnames from 'classnames';
import { ReactComponent as OneStar } from '../../../../assets/TripApp/common/rating_full.svg';
import { ReactComponent as HalfStar } from '../../../../assets/TripApp/common/rating_half.svg';

const RatingInfo = ({
	classes,
	className,
	ratingInfo,
	errorMessage,
	clearErrorMessage,
	showAlert,
}) => {

	const {
		description,
		rating,
	} = ratingInfo;

	const title = (
		<Typography
			key={'RatingTitle'}
			className={classes.title}
		>
			Rating
		</Typography>
	);

	const starsArray = [];
	const numberOfFullStars = !!rating && Math.floor(rating / 1);
	const hasHalfStar = !!rating && rating % numberOfFullStars !== 0;

	for (let i = 0; i < numberOfFullStars; i++) {
		starsArray.push(<OneStar key={`star-${i + 1}`} className={classes.starIcon} />);
	};

	if (hasHalfStar) {
		starsArray.push(<HalfStar key='half_star' className={classes.starIcon} />);
	};

	const hasRating = starsArray.length > 0;

	const starsContainer = !!starsArray && (
		<div className={classes.raringContainer}>
			{hasRating ? starsArray : 'No Rating'}
		</div>
	);

	const descriptionLabel = hasRating && (
		<Typography
			key={'RatingDescriptionLabel'}
			className={classes.title}
		>
			Description
		</Typography>
	);

	const descriptionText = hasRating && (
		<Typography
			key={'RatingDescription'}
			className={classes.description}
		>
			{description}
		</Typography>
	);

	const errorAlert = showAlert && (
		<Alert
			type='error'
			errorMessage={errorMessage}
			clearErrorMessage={clearErrorMessage}
		/>
	);

	return (
		<div className={classnames(classes.root, className)}>
			{errorAlert}
			{title}
			{starsContainer}
			{descriptionLabel}
			{descriptionText}
		</div>
	);
};

export default withStyles(styles)(RatingInfo);