import React, { useState, useEffect, useRef, useCallback } from 'react';
import carclubService from '../../../services/carclub';
import locationService from '../../../services/location';
import permissionService from '../../../services/permissions';
import withPermission from '../../../hoc/withPermission';
import { useCancellablePromise } from
	'../../../hooks/useCancellablePromise/useCancellablePromise.jsx';
import log from '../../../utils/logger';
import Roles from './Roles';

const logger = log('RolesWrapper');

const RolesWrapper = props => {

	const isMounted = useRef(true);
	const buttonTimeout = useRef(null);
	const [filter, setFilter] = useState('');
	const [filterHandler, setFilterHandler] = useState({ string: '', reset: false });
	const [isEditing, setIsEditing] = useState();
	const [list, setList] = useState({});
	const [selected, setSelected] = useState();
	const [permissions, setPermissions] = useState();

	const [locations, setLocations] = useState({});

	useEffect(() => {
		const getLocations = async () => {
			try {
				const locationsPromise = locationService.listAllLocations();
				const locationsResult = await locationsPromise;
				setLocations(locationsResult);
			}
			catch (error) {
				logger.warn('Could not get locations');
			};
		};
		const getRoles = async () => {
			try {
				const { permissions } = await permissionService.listPermissions("");
				setPermissions(Object.values(permissions || {}));
			}
			catch (error) {
				logger.warn('Could not get permissions');
			};
		};
		getRoles();
		getLocations();
	}, []);

	useEffect(() => {
		const getList = async () => {
			try {
				const [roles, lastPage] = await carclubService.getRoles(filterHandler.string);
				setList(prev => filterHandler.reset
					? { roles, page: 1, lastPage }
					: {
						roles: { ...prev.roles, ...roles },
						page: prev.page + 1,
						lastPage,
					});
			}
			catch (error) {
				logger.warn('Could not get roleslist');
			}
		};

		getList();
	}, [filterHandler]);



	const onSearch = useCallback(reset => {
		setFilterHandler({ reset: !!reset, string: filter });
	}, [filter]);

	//clickHandlers
	const { makeCancellablePromise } = useCancellablePromise();

	useEffect(() => {

		const cleanup = () => {
			clearTimeout(buttonTimeout.current);
			isMounted.current = false;
		};
		return cleanup;
	}, [buttonTimeout]);

	const onSingleClick = () => {

		if (!buttonTimeout.current) {

			const timerId = setTimeout(() => {
				buttonTimeout.current = null;
				makeCancellablePromise(onSearch(true))
					.catch(error => logger.debug(error));
			}, process.env.REACT_APP_SINGLECLICK_TIMEOUT);

			buttonTimeout.current = timerId;
		};
	};

	//this is required for the doubleClick cancellablePromise
	const onReset = useCallback(() => {
		setFilterHandler({ reset: true, string: '' });
		setFilter('');
	}, []);

	const onDoubleClick = () => {

		if (!!buttonTimeout.current) {
			clearTimeout(buttonTimeout.current);
			buttonTimeout.current = null;
		};

		makeCancellablePromise(onReset())
			.catch(error => logger.debug(error));
	};

	//on list Update, update @role
	const onUpdate = useCallback(role => {
		if (!role.id) {
			return;
		};
		setList(prev =>
			({ ...prev, roles: { ...prev.roles, [role.id]: { ...role } } }));
	}, []);

	//on list Remove, remove @role
	const onRemove = useCallback(async () => {
		if (!selected) {
			return;
		};

		try {

			await carclubService.removeRole(selected);

			const roles = { ...list.roles };
			delete roles[selected];
			setList(prev => ({ ...prev, roles }));
		}
		catch (error) {
			logger.warn(`Can't remove role ${selected}`);
		}
	}, [list, selected]);

	return (
		<Roles
			{...props}
			filter={filter}
			isEditing={isEditing}
			list={list}
			locations={locations}
			onDoubleClick={onDoubleClick}
			onRemove={onRemove}
			onSearch={onSearch}
			onSelect={setSelected}
			onSingleClick={onSingleClick}
			onUpdate={onUpdate}
			permissions={permissions}
			selected={selected}
			setFilter={setFilter}
			setIsEditing={setIsEditing}
			setSelected={setSelected}
		/>
	);
};

const permissions = [{ resource: ['carclub', 'roles', 'read'] }];

export default withPermission(permissions)(RolesWrapper);