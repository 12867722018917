import React from 'react';
import List from '../../../../UI/List/List';
import NotificationItem from './NotificationItem/NotificationItem';
import withStyles from '@material-ui/styles/withStyles';
import { styles } from './NotificationList.styles';
import classnames from 'classnames';

const NotificationList = ({
	classes,
	className,
	headerFields,
	notifications = [],
	removeNotification,
	editNotification,
}) => {

	const notificationItems = notifications
		&& notifications.length > 0
		&& notifications
			.map((notification, i) => (
				<NotificationItem
					key={`notification-${notification.type}_${notification.subType}_${i}`}
					{...{ notification }}
					onEdit={ () => editNotification(notification) }
					onRemove={ () => removeNotification(notification.id) }
				/>
			));

	return (
		<List
			className={classnames(classes.root, className)}
			disableGutters
			{...{ headerFields }}
		>
			{notificationItems}
		</List>
	);
};

export default withStyles(styles)(NotificationList);