import { alpha } from '@material-ui/core/styles';

const styles = theme => ({

	root: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: alpha(theme.palette.common.white, 0.70),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.white, 0.85),
		},
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
		border: '1px solid lightgray',
		boxShadow: '1px 1px 5px gray',
		height: '32px',
		minWidth: '32px',
		width: '32px',
	},

	typeRoot: {
		display: 'flex',
		justifyContent: 'center',
		borderRight: '1px inset black',
		maxHeight: 32,
		width: '100px',
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.white, 0.85),
		},
	},

	typeButton: {
		padding: '6px 0',
		fontSize: '9px',
	},
	
	menu: {
		backgroundColor: 'white',
		border: '1px solid lightgray',
		zIndex: 9999,
	},

	filterButton: {
		minWidth: '32px',
		width: '32px',
	},

	fuelFilters: {
		paddingRight: '6px',
		lineHeight: '0px',
	},

	grayBackground: {
		backgroundColor: 'LightGray',
	},
});

export default styles;