import React from 'react';
import Tree from '../Tree/TreeWrapper';
import fieldCreator from './fields';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import withPermission from '../../../../hoc/withPermission';
import styles from './PermissionsCreate.styles';
import classnames from 'classnames';

const PermissionsCreate = ({
	classes,
	className,
	notification,
	onCancel,
	onUpdate,
	placeholder,
	setPlaceholder,
}) => {
	const isUpdating = placeholder.id;

	const titleText = (isUpdating ? 'Edit ' : 'Create ') + 'permission';

	const saveUpdateText = isUpdating ? 'Update' : 'Save';

	const TextButton = TextButtonWithPermission(isUpdating);

	const notificationDiv = (
		<div className={classnames(classes.notification,
			classes[`notification${notification?.type}`],
			!notification && classes.notificationHide)}>
			<Typography className={classes.notificationText}>
				{notification?.message}
			</Typography>
		</div>
	);

	const title = (
		<Typography className={classes.title}>
			{titleText}
		</Typography>
	);

	const saveUpdate = (
		<TextButton
			className={classes.save}
			align='right'
			onClick={onUpdate}
			text={saveUpdateText}
		/>
	);

	const cancel = (
		<TextButton
			className={classes.cancel}
			align='right'
			onClick={onCancel}
			text='Cancel'
		/>
	);

	const buttons = (
		<div className={classes.buttons}>
			{cancel}
			{saveUpdate}
		</div>
	);

	const [
		code,
		designation,
		accessLevel,
		createDate,
		createBy,
		modifiedDate,
		modifiedBy,
	] = fieldCreator({ placeholder, classes, onChange: setPlaceholder });

	const fields = (
		<div className={classes.updateFields}>
			{code}
			{designation}
			{accessLevel}
			<div className={classes.updateFieldsDate}>
				{createDate}
				{createBy}
			</div>
			<div className={classes.updateFieldsDate}>
				{modifiedDate}
				{modifiedBy}
			</div>
		</div>
	);

	const tree = (
		<Tree
			allPermissions={placeholder.permissions || {}}
			permissions={placeholder.permissions || {}}
			allOpen={true}
		/>
	);

	const header = (
		<div className={classes.header}>
			<Typography className={classes.headerText}>
				Active Permissions
			</Typography>
		</div>
	);

	return (
		<div className={classnames(classes.root, className)}>
			{notificationDiv}
			<div className={classes.div}>
				{title}
				{buttons}
			</div>
			{fields}
			{header}
			{tree}
		</div>
	);
};

export default withStyles(styles)(PermissionsCreate);

const TextButtonWithPermission = isUpdating => withPermission(
	[{ resource: ['carclub', 'permissions', isUpdating ? 'edit' : 'create'] }]
)(
	(
		{ className, onClick, text }
	) =>
		(
			<Typography
				className={className}
				align='right'
				onClick={onClick}
			>
				{text}
			</Typography>
		)
);