import React from 'react';
import AccountDetailsItem from './AccountDetailsItem/AccountDetailsItem';
import List from '../../../UI/List/List';
import accountDetailsListConfig from './accountDetailsListConfig';
import accountBonusConfig from './accountBonusDetailsConfig';
import {
	Tooltip,
	withStyles,
} from '@material-ui/core';
import {
	CheckBoxOutlineBlank as UncheckIcon,
	CheckBox as CheckedIcon,
} from '@material-ui/icons';
import styles from './AccountDetailsList.styles';
import classnames from 'classnames';

const AccountDetailsList = ({
	bonusList,
	classes,
	className,
	currentScreenSize,
	getMoreRows,
	mode,
	onSelect,
	onSelectAll,
	onUnselect,
	paymentsArray,
	selected,
}) => {
	const isDetails = 'DETAILS' === mode;

	const allSelected = selected.length === paymentsArray.length;

	const checkIcon = (
		<Tooltip title='Select all' placement='top' >
			<UncheckIcon
				className={classes.iconUnchecked}
				onClick={onSelectAll}
			/>
		</Tooltip>
	);

	const uncheckIcon = (
		<Tooltip title='Unselect all' placement='top' >
			<CheckedIcon
				className={classes.icon}
				onClick={onUnselect}
			/>
		</Tooltip>
	);

	const check = allSelected ? uncheckIcon : checkIcon;

	const payments = !!paymentsArray && paymentsArray
		.sort((a, b) => b.date - a.date)
		.map(payment => (
			<AccountDetailsItem
				detail={payment}
				key={payment.code}
				onSelect={onSelect}
				selected={selected.includes(payment.code)}
			/>
		));

	const bonus = !!bonusList && bonusList
		.sort((a, b) => b.createDate - a.createDate)
		.map(bonus => (
			<AccountDetailsItem
				detail={bonus}
				key={bonus.id}
			/>
		));

	const details = isDetails ? payments : bonus;
	const list = (
		<List
			className={classnames(classes.root, className)}
			getMoreRows={isDetails && getMoreRows}
			headerFields={isDetails
				? accountDetailsListConfig(currentScreenSize, check)
				: accountBonusConfig(currentScreenSize)}
		>
			{details}
		</List>
	);

	return list;
};

export default withStyles(styles)(AccountDetailsList);