import React from 'react';
import {
	InputAdornment,
	InputBase,
	withStyles,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import styles from './AccountFilters.styles';
import classnames from 'classnames';

const label = 'Name, email, phone or code';

const AccountFilters = ({
	classes,
	filter,
	onDoubleClick,
	onSearch,
	onSingleClick,
	setFilter,
}) => {

	const searchIcon = (
		<SearchIcon
			onClick={() => onSingleClick(onSearch)}
			onDoubleClick={() => onDoubleClick(() => {
				setFilter('');
				onSearch(true);
			})}
			className={classnames(classes.searchIcon)}
			size="small"
		/>
	);

	const searchBar = (
		<InputBase
			placeholder={label}
			value={filter}
			classes={{
				root: classes.inputRoot,
				input: classes.inputInput,
			}}
			onChange={event => setFilter(event.target.value)}
			onKeyDown={event => {
				if (event.keyCode === 13){
					onSearch();
				};
			}}
			endAdornment={
				<InputAdornment position="end">
					{searchIcon}
				</InputAdornment>
			}
		/>
	);

	return searchBar;
};

export default withStyles(styles)(AccountFilters);
