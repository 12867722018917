import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { styles } from "./_base.styles";

function AutoCompleteInput({ classes, label, value, onChange, disabled, options, defaultValue }) {
  const allOptions = [{ value: " ", text: "" }, ...options];
  const selectedValue = allOptions.find((item) => item.value === (value || defaultValue || allOptions[0]));

  return (
    <div className={classes.root}>
      <Autocomplete
        className={classes.autocomplete}
        options={allOptions}
        disabled={disabled}
        freeSolo
        getOptionLabel={(option) => {
          // console.log('--- option: %o', option);
          return option?.text;
        }}
        value={selectedValue}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label={label}
            disabled={disabled}
            onFocus={(e) => e.target && e.target.select && e.target.select()}
            InputProps={{
              ...params.InputProps,
              autoComplete: "off", // disable autocomplete and autofill
            }}
          />
        )}
        renderOption={(option) => {
          //console.log('option (render): %o', option);
          return option.text;
          // return option
        }}
        getOptionSelected={(option, value) => {
          return option?.value === value?.value;
        }}
        onChange={(e, v) => onChange && onChange(v?.value)}
      />
    </div>
  );
}

export default withStyles(styles)(AutoCompleteInput);
