import React, { useEffect, useRef, useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { List, ListItem, CircularProgress } from "@material-ui/core";

import { styles } from "./InfiniteList.styles";

function InfiniteList({ classes, data, renderElement, style, loadMore, isLoading, className }) {
  const [obsOn, setObsOn] = useState(false);
  const loader = useRef(null);

  useEffect(() => {
    if (!obsOn && !isLoading) {
      let options = {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      };
      const observer = new IntersectionObserver((entities) => {
        const target = entities[0];
        if (target.isIntersecting) {
          loadMore && loadMore();
        }
      }, options);
      if (loader.current) {
        observer.observe(loader.current);
        setObsOn(true);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <List className={classes.root + (className ? " " + className : "")} style={style}>
      {(data || []).map((row, index) => {
        return <ListItem key={"list_item_" + index}>{renderElement(row)}</ListItem>;
      })}
      <ListItem className={classes.loader}>
        <div ref={loader}>{isLoading && <CircularProgress color="primary" />}</div>
      </ListItem>
    </List>
  );
}

export default withStyles(styles)(InfiniteList);
