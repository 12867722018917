import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid, Button } from "@material-ui/core";

import { styles } from "./BatterySwap.styles";
import { Form, FormFields } from "../../../../common/forms";
import { formFields, formSchema } from "./BatterySwap.schema";
import moment from "moment";
import { assetList_report, reportBatterySwaps, selectNoDataModal, selectReportAssets, selectReportGeneratedModal, setNoDataModal, setReportGeneratedModal } from "../../reportsSlice";
import { selectDefaultLocation } from "../../../carclub/carclubSlice";
import Modal from "../../../../common/displays/Modal/Modal";

function BatterySwap({ classes }) {
  const dispatch = useDispatch();
  const assets = useSelector(selectReportAssets);
  const selectedLocation = useSelector(selectDefaultLocation);
  const noDataModal = useSelector(selectNoDataModal);
  const reportGeneratedModal = useSelector(selectReportGeneratedModal);

  const initialValues = {
    startDate: moment().add('-7', "days").format("YYYY-MM-DDT00:00:00"),
    endDate: moment().format("YYYY-MM-DDT00:00:00"),
  };

  useEffect(() => {
    dispatch(assetList_report());
    // eslint-disable-next-line
  }, [])

  const onSubmitHandler = (values) => {
    console.log('values: %o', values);
    dispatch(reportBatterySwaps(values));
  }

  return (
    <div className={classes.root}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography className={classes.title}>Battery Swaps</Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.description}>All sessions divided according to battery swaps made.</Typography>
        </Grid>
        <Grid item>
          <Form
            schema={formSchema()}
            initialValues={initialValues}
            keepDirtyOnReinitialize
            onSubmit={onSubmitHandler}
            render={({ submitting, values, form }) => {
              return (
                <Grid container spacing={4} direction="column" style={{ maxWidth: 500 }}>
                  <Grid item>
                    <FormFields fields={formFields({ disabled: submitting, values, form, assets: assets.filter(item => item.locationId === selectedLocation?.id) })} />
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={submitting}
                    >
                      Generate Report
                    </Button>
                  </Grid>
                </Grid>
              );
            }}
          />
        </Grid>
      </Grid>
      <Modal open={noDataModal} onClose={() => dispatch(setNoDataModal(false))} sizeClass="sm" style={{ height: 200, maxWidth: 520 }}>
        <div className={classes.modal}>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Typography variant="h5">No data for the selected dates</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" style={{ fontSize: 14 }}>There is no available data for the relevant dates. Set a different time period to try again.</Typography>
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button color="primary" variant="contained" onClick={() => dispatch(setNoDataModal(false))}>
                    Close
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal>
      <Modal open={reportGeneratedModal} onClose={() => dispatch(setReportGeneratedModal(false))} sizeClass="sm" style={{ height: 180, maxWidth: 520 }}>
        <div>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Typography variant="h5">Generating report</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" style={{ fontSize: 14 }}>The report will be sent by email when it will be ready. </Typography>
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button color="primary" variant="contained" onClick={() => dispatch(setReportGeneratedModal(false))}>
                    Close
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}

export default withStyles(styles)(BatterySwap);