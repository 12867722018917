import {
	AUTHORIZATION_CLEAR_ELEMENTS,
	AUTHORIZATION_SET_ELEMENTS,
} from '../../actions/authorization/actionTypes';
import * as mutate from './mutators';
import { pipe } from '../utils';
import createReducer from '../../utils/createReducer';
import { Map } from 'immutable';

const initialState = Map({
	elements: Map(),
});

const authorizationSetElements = (state, action) => {

	return pipe([
		mutate.setElements(action.elements, 'authorizationId'),
	], state);
};

const authorizationClearElements = state => {
	return state.update('elements', map => map.clear());
};

const authorizationReducer = createReducer(initialState, {
	[AUTHORIZATION_SET_ELEMENTS] : authorizationSetElements,
	[AUTHORIZATION_CLEAR_ELEMENTS] : authorizationClearElements,
});

export default authorizationReducer;