import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Modal as MuiModal } from "@material-ui/core";

import { styles } from "./Modal.styles";

function Modal({ classes, children, open, setOpen, sizeClass = "big", style }) {
  return (
    <MuiModal open={open} onClose={() => setOpen(false)}>
      <div className={classes.root + " " + classes[sizeClass] } style={style}>{children}</div>
    </MuiModal>
  );
}

export default withStyles(styles)(Modal);
