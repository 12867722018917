import React from 'react';
import classnames from 'classnames';
import {
	Clear as CrossIcon,
} from '@material-ui/icons';
import {
	Attachement as FilesIcon,
} from '../../../assets/IncidentsApp/all-icons';

const _getName = strArray => {
	return strArray[strArray.length - 1];
};
const attachmentHandler = ({
	classes,
	attachment,
	removeAttachment,
}) => {
	const attachments = attachment?.map((attachment, index) => {

		const isString = typeof attachment === 'string';

		const icon = (
			<FilesIcon
				className={classes.icon}
				onClick={() => window.open(isString ? attachment : URL.createObjectURL(attachment), "_blank")}
			/>
		);

		const name = isString
			? _getName(attachment.split('/'))
			: attachment.name;

		const crossIcon = (
			<CrossIcon
				className={classes.icon}
				onClick={() => removeAttachment(index)}
			/>
		);

		return (
			<div
				key={name + index}
				className={classnames(classes.attachmentItem, index > 0 && classes.attachmentItemBorder)}
			>
				{icon}
				{name}
				{crossIcon}
			</div>
		);
	});

	return (
		<div className={classes.attachmentsDiv}>
			{attachments}
		</div>
	);
};

export default attachmentHandler;