import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_REQUEST_IS_MALFORMED,
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import { dateFormatter } from '../../formatter';
import { publishAndAwait } from '../../../app/coreSlice';

export const createPromoCode = async data => {

	if (typeof data === 'undefined') {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	};

	const {
		name,
		amount,
		description,
		expirationDate,
		numberOfCodes,
	} = data;

	if (!expirationDate || !name || !amount || !description || !numberOfCodes) {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	};

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'BILLING',
			subType: 'CREATE_GENERATED_BONUS',
			message: {
				name,
				amount,
				description,
				expirationDate: dateFormatter.toJSON(expirationDate, 'dateTimeRequest'),
				numberOfCodes,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (error) {
		const {
			description,
			code,
			tag,
			message,
		} = processMessage;

		throw new NGError({
			message: description || message || tag,
			code,
			tag,
		});
	}

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}

	return processMessage;
};

export default createPromoCode;