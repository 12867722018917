import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core";
import Graphs from "./Graphs/GraphsWrapper";
import styles from "./DashboardMetrics.styles";
import { selectDefaultLocation } from "../../../features/carclub/carclubSlice";
import {
  customerDashboardStats,
  dashboardReservationsBilling,
  reservationDashboardGet,
  selectCustomerMetrics,
  selectReservationMetrics,
  selectPaymentMetrics,
} from "../../../features/dashboard/dashboardSlice";

import DashboardMetricsFilter from "./DashboardMetricsFilter/DashboardMetricsFilter";
import { useDateTime } from "../../../common/hooks";

const DashboardMetrics = ({ classes }) => {
  const dispatch = useDispatch();
  const selectedLocation = useSelector(selectDefaultLocation);
  const customerMetrics = useSelector(selectCustomerMetrics);
  const reservationMetrics = useSelector(selectReservationMetrics);
  const paymentMetrics = useSelector(selectPaymentMetrics);
  const { toUtc, now } = useDateTime();

  const [lastLocationLoadedId, setLastLocationLoadedId] = useState();

  useEffect(() => {
    if (selectedLocation && lastLocationLoadedId !== selectedLocation.id) {
      let startDate = toUtc(now().add(-7, "d")).format("YYYY-MM-DD HH:mm:ss");
      let endDate = toUtc(now()).format("YYYY-MM-DD HH:mm:ss");
      dispatch(customerDashboardStats({ startDate, endDate }));
      dispatch(reservationDashboardGet({ startDate, endDate }));
      dispatch(dashboardReservationsBilling({ startDate, endDate }));
      setLastLocationLoadedId(selectedLocation.id);
    }
    // eslint-disable-next-line
  }, [selectedLocation]);

  return (
    <div className={classes.root}>
      <DashboardMetricsFilter />
      <div className={classes.graphs}>
        <Graphs color="#3f3a" metrics={reservationMetrics} title="Trips" />
        <Graphs color="#fc0a" metrics={customerMetrics} title="Customers" />
        <Graphs color="#f55a" metrics={paymentMetrics} symbol title="Payments" />
      </div>
    </div>
  );
};

export default withStyles(styles)(DashboardMetrics);
