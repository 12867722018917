export const styles = (theme) => ({
  root: {
    outline: 0,
    position: "absolute",
    height: "90%",
    left: "10%",
    top: "5%",
    width: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  frame: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "15px",
    boxShadow: theme.shadows[5],
    boxSizing: "border-box",
  },
  img: {
    width: "100%",
    height: "100%",
  },
});
