export const styles = () => ({
  root: {
    width: "100%",
    padding: 20,
  },
  subtitle: {
    width: "80%",
    fontSize: 14,
    textAlign: "center",
    color: "#a1a1a1",
    borderBottom: "1px solid #a1a1a1",
  },
  item: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: 5,
    display: "block",
  },
});
