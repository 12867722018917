const styles = (theme) => ({
	root: {
		backgroundColor: 'white',
		minWidth: 400,
		position: 'absolute',
		top: 0,
		bottom: 0,
		display: 'flex',
		flexDirection: 'column',
		boxShadow: '5px 5px 11px gray',
	},
	
	addButton: {
		position: 'absolute',
		top: 0,
		right: 0,
		paddingRight: 10,
	},

	buttons: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
		alignItems: 'flex-end',
		textAlign: 'right',
	},
	
	saveImport: {
		marginRight: '10px',
	},

	header: {
		display: 'flex',
		justifyContent: 'flex-start',
		marginLeft: '16px',
	},

	type: {
		width: 32,
		paddingRight: 13,
	},

	list: {
		height: '100%',
		overflow: 'auto',
		scrollbarWidth: 'thin', //FIREFOX
		//CHROME
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},

	listItem: {
		paddingBottom: 0,
		paddingTop: 0,
		borderTop: '1px solid',
		borderColor: theme.palette.grey['300'],
	},
});

export default styles;