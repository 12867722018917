export const styles = () => ({
  root: {
    backgroundColor: "#FFFFFF",
    cursor: "pointer",
    padding: 10,
    width: "100%",
    marginBottom: 5,
  },
  diferentLocation: {
    backgroundColor: "#f6f6f6",
    cursor: "default",
  },
  selected: {
    outline: "1px solid #4095c2",
  },
  container: {
    minHeight: 60,
  },
  subcontainer: {
    position: "realtive",
  },
  title: {
    color: "#000000",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "14px",
  },
  message: {
    fontSize: "10px",
    color: "#4C4C4E",
    fontWeight: 400,
  },
  date: {
    position: "absolute",
    right: 20,
    bottom: 5,
    fontSize: "10px",
    color: "#B6B6B6",
    fontWeight: 300,
  },
  actions: {
    position: "absolute",
    right: 12,
    top: 5,
    "& svg": {
      fontSize: 16,
      color: "#B6B6B6",
    },
  },
  actionsList: {
    width: 100,
    "& .MuiListItem-root": {
      padding: 0,
    },
    "& .MuiButton-root": {
      padding: 0,
      textTransform: "none",
      margin: "5px auto",
    },
  },
});
