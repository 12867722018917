import Account from '../../../../model/Account/Account';

export const fromDTO = ({
	accountCode,
	associated,
	autoCharge,
	autoInvoice,
	city,
	disabled,
	dueAmount,
	dueDays,
	email,
	id,
	invoiceDay,
	locationId,
	name,
	number,
	ownerName,
	ownerCode,
	phone,
	sixMonthAvg,
	streetAdress,
	taxNumber,
	zipCode,
}) => new Account({
	associated,
	autoCharge,
	autoInvoice,
	city,
	code: accountCode,
	dueAmount,
	dueDays,
	email,
	enabled: !disabled,
	id,
	invoiceDay,
	locationId,
	name,
	number,
	owner: ownerName,
	ownerCode,
	phone,
	sixMonthAvg,
	street: streetAdress,
	taxNumber,
	zipCode,
});

export const toDTO = ({
	autoCharge,
	autoInvoice,
	city,
	code,
	dueAmount,
	dueDays,
	email,
	enabled,
	invoiceDay,
	locationId,
	name,
	number,
	owner,
	ownerCode,
	ownerEmail,
	phone,
	sixMonthAvg,
	street,
	taxNumber,
	zipCode,
}) => ({
	accountCode: code,
	autoCharge,
	autoInvoice,
	city,
	disabled: !enabled,
	dueAmount,
	dueDays,
	email,
	invoiceDay,
	locationId,
	name,
	number,
	ownerCode,
	ownerEmail,
	ownerName: owner,
	phone,
	sixMonthAvg,
	streetAdress: street,
	taxNumber,
	zipCode,
});

const mapper = {
	fromDTO,
	toDTO,
};

export default mapper;