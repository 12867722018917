
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_PROCESS_MESSAGE_IS_MALFORMED,
	WS_REQUEST_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import zoneMapper from './mappers/zoneFlowMapper';
import { publishAndAwait } from '../../../app/coreSlice';

const listAssociatedZones = async ({ assetToken, deviceToken }) => {

	if(!assetToken || !deviceToken) {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	}

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'ASSET',
			subType: 'ASSOCIATED_ZONES',
			message: {
				carToken: assetToken,
				deviceToken,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
			message,
		} = processMessage;

		throw new NGError({
			message: description || message,
			code,
			tag,
		});
	}
	
	const {
		zones: zoneList,
	} = processMessage || {};

	const zones = zoneList.map(zone => zoneMapper.fromDTO(zone));

	return zones;
};

export default listAssociatedZones;
