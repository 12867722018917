import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid, LinearProgress } from "@material-ui/core";

import { styles } from "./FuelPercentage.styles";

import { ReactComponent as BatteryIcon } from "./icons/battery.svg";
import { ReactComponent as FuelIcon } from "./icons/fuel.svg";

function FuelPercentage({ classes, percentage, fuelType }) {
  return (
    <div className={classes.root}>
      <LinearProgress
        className={
          classes.progress +
          (percentage > 49 ? " " + classes.full : percentage > 24 ? " " + classes.half : " " + classes.empty)
        }
        variant="determinate"
        value={parseFloat(percentage || "0")}
      />
      <Grid
        container
        direction="row"
        className={classes.info}
        justifyContent="space-between"
        wrap="nowrap"
      >
        <Grid item>
          {fuelType === "ELECTRIC" ? <BatteryIcon className={classes.icon} /> : <FuelIcon className={classes.icon} />}
        </Grid>
        <Grid item>
          <Typography>{percentage ? `${parseFloat(percentage).toFixed(0)}%` : "?%"}</Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(FuelPercentage);
