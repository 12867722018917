import { ReactComponent as PromotionsIcon } from '../../assets/PromotionApp/icons/icon-promotions-grey.svg';
import { ReactComponent as BonusIcon } from '../../assets/PromotionApp/icons/icon-individual-grey.svg';
import { ReactComponent as CodeIcon } from '../../assets/PromotionApp/icons/icon-code-grey.svg';
import * as screenNames from '../../model/Promotion/screens';

const style = {
	width: '20px',
	height: '20px',
};

const button = (icon, text, tag, right = false) => {
	return {
		icon,
		text,
		right,
		style,
		tag,
		permissions: [{ resource: ['promotions', tag, 'read']}],
	};
};

const buttons = []
	.concat(button(PromotionsIcon, screenNames.PROMOTIONS, 'promotions'))
	.concat(button(BonusIcon, screenNames.INDIVIDUALBONUS, 'individualBonus'))
	.concat(button(CodeIcon, screenNames.CODEGENERATOR, 'bonusGenerator'));

export default buttons;
