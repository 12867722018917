import React from "react";
import { SvgIcon } from "@material-ui/core";

function DeviceIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 100 100">
      <g transform="translate(50 50) scale(0.69 0.69) rotate(0) translate(-50 -50)">
        <svg
          version="1.1"
          viewBox="0 0 789 575"
          x="0px"
          y="0px"
          fillRule="evenodd"
          clipRule="evenodd"
        >
          <g>
            <path d="M130 203c16,-35 31,-71 51,-111 11,-19 23,-36 45,-38l337 0c22,2 34,19 45,38 20,40 35,76 51,111l-529 0zm158 144l212 0c13,0 23,11 23,24 0,12 -10,23 -23,23l-212 0c-12,0 -23,-11 -23,-23 0,-13 11,-24 23,-24zm-62 -347c-36,0 -58,13 -88,55l-50 113 -76 0c-12,0 -34,62 46,64l-27 65c1,83 1,165 1,247 0,17 14,31 31,31l104 0c17,0 30,-14 30,-31l0 -68 394 0 0 68c0,17 14,31 31,31l104 0c17,0 30,-14 30,-31 0,-82 1,-164 1,-247l-27 -65c81,-2 58,-64 47,-64l-76 0 -50 -113c-30,-42 -52,-55 -88,-55l-337 0zm418 303c-27,0 -50,22 -50,50 0,27 23,50 50,50 28,0 50,-23 50,-50 0,-28 -22,-50 -50,-50zm-499 0c27,0 50,22 50,50 0,27 -23,50 -50,50 -28,0 -50,-23 -50,-50 0,-28 22,-50 50,-50z"></path>
          </g>
        </svg>
      </g>
    </SvgIcon>
  );
}

export default DeviceIcon;
