import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid, Drawer } from "@material-ui/core";

import { styles } from "./DeviceTeltonikaList.styles";
import { useDispatch, useSelector } from "react-redux";
import DevicesDataGrid from "./components/DevicesDataGrid";
import withPermission from "../../../hoc/withPermission";
import { AddCircle } from "@material-ui/icons";

import { deviceListTeltonika, deviceRemoveTeltonika, selectTeltonikaDevices } from "../deviceSlice";
import DeviceTeltonikaForm from "../DeviceTeltonikaForm/DeviceTeltonikaForm";
import SearchBox from "../../../common/inputs/SearchBox";

function DeviceTeltonikaList({ classes }) {
  const teltonikaDevices = useSelector(selectTeltonikaDevices);
  const dispatch = useDispatch();
  const [formDrawerOpen, setFormDrawerOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState();
  const [filteredDevices, setFilteredDevices] = useState();

  useEffect(() => {
    dispatch(deviceListTeltonika({ clear: true }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFilteredDevices(teltonikaDevices?.list);
  }, [teltonikaDevices]);

  const onCreateHandler = () => {
    setSelectedDevice();
    setFormDrawerOpen(true);
  };

  const onEditHandler = (device) => {
    setSelectedDevice(device);
    setFormDrawerOpen(true);
  };

  const onDeleteHandler = (device) => {
    console.log("delete device: %o", device);
    dispatch(deviceRemoveTeltonika(device));
  };

  const onSearchHandler = (qry) => {
    if (qry?.length > 2) {
      setFilteredDevices(
        teltonikaDevices?.list?.filter((item) => {
          console.log(item);
          return item.serialNumber.indexOf(qry) >= 0 || item.imei.indexOf(qry) >= 0;
        })
      );
    } else {
      setFilteredDevices(teltonikaDevices?.list);
    }
  };

  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography className={classes.title}>
            IoT Devices ({teltonikaDevices && teltonikaDevices.totalItems} devices){" "}
          </Typography>
        </Grid>
        <Grid item>
          <SearchBox fast onEnter={onSearchHandler} />
        </Grid>
        <Grid item>
          <Typography>
            <AddButton onClick={onCreateHandler} className={classes.icon} />
          </Typography>
        </Grid>
      </Grid>
      <Drawer anchor="right" onClose={() => setFormDrawerOpen(false)} open={formDrawerOpen}>
        <DeviceTeltonikaForm
          onSave={() => setFormDrawerOpen(false)}
          device={selectedDevice}
          isEdit={Boolean(selectedDevice)}
          onCancel={() => setFormDrawerOpen(false)}
        />
      </Drawer>
      {teltonikaDevices && (
        <DevicesDataGrid
          data={filteredDevices}
          totalRows={teltonikaDevices.totalItems}
          onEdit={onEditHandler}
          onDelete={onDeleteHandler}
        />
      )}
    </div>
  );
}

const AddButton = withPermission([{ resource: ["customers", "edit"] }])(({ onClick, className }) => {
  return <AddCircle onClick={onClick} className={className} elevation={2} />;
});

export default withStyles(styles)(DeviceTeltonikaList);
