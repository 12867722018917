import React from 'react';
import {
	Typography,
	Tooltip,
	withStyles,
} from '@material-ui/core';
import moment from 'moment';
import { LineChart, Line, XAxis, YAxis, Tooltip as ChartToolTip } from 'recharts';
import classnames from 'classnames';
import { currencyFormatter } from '../../../../services/formatter';
import styles from './Graphs.styles';
import { useDateTime } from '../../../../common/hooks';

const tooltipFormatter = symbol => (value, name, props) => {
	const correctValue = symbol ? value + currencyFormatter.symbol() : value;

	return [correctValue, props?.payload?.time || 'Value'];
};

const Graph = ({
	classes,
	color,
	list,
	selected,
	symbol,
	type,
}) => {
	const { toTimeZone } = useDateTime();
	if (!list) {
		return null;
	};

	const value = selected || 'total';

	if (type === 'LINE') {
		const data = list.map(({ showDate, intervalDateString, intervalEndDateString, ...rest }) =>
			({
				name: toTimeZone(intervalDateString).format('MMM Do'),
				time: `${toTimeZone(intervalDateString).format('MMM Do@HH:mm')} 
					- ${toTimeZone(intervalEndDateString).format('MMM Do@HH:mm')}`,
				value: rest[value],
			})
		);

		return (
			<div className={classes.graphRoot}>
				<LineChart
					data={data}
					height={Math.max((window.innerHeight / 3) - 230, 185)}
					margin={{top:10, right: 10, bottom: 0, left: -10}}
					width={Math.max(window.innerWidth - 110, 1000)}
				>
					<XAxis dataKey="name" interval={3}/>
					<YAxis unit={symbol && currencyFormatter.symbol()} />
					<ChartToolTip formatter={tooltipFormatter(symbol)}/>
					<Line type="monotone" dataKey="value" stroke={color} />
				</LineChart>
			</div>
		);

	};

	const highest = Math.max(...list.map(element => element[value])) || 0;
	const half = highest % 2 ? (highest / 2).toFixed(1) : highest / 2;
	const data = [];
	const timeline = [];

	list.forEach((bar, key) => {
		const barHeight = (bar[value] || 0) / highest * 100;

		data.push(
			<Tooltip
				title={`${moment(bar.intervalDateString).format('MMM Do@HH:mm')} 
					- ${moment(bar.intervalEndDateString).format('MMM Do@HH:mm')}
					 : ${bar[value]}${symbol ? currencyFormatter.symbol() : ''}`}
				key={`bar_${key}`}
				placement='top'
			>
				<div
					className={classnames(classes.bar)}
					style={{ '--y': barHeight, '--x': key * 25, '--color': color }}
				>
				</div>
			</Tooltip>
		);

		const time = bar.showDate && (
			<Typography className={classes.timelineText}>
				{moment(bar.intervalDateString).format('MMM Do')}
			</Typography>
		);

		timeline.push(
			<div className={classes.time} key={`info_${key}`}>
				{time}
			</div>
		);
	});

	const max = (
		<Typography className={classes.graphValues}>
			{highest}{symbol && currencyFormatter.symbol()}
		</Typography>
	);

	const mid = (
		<Typography className={classes.graphValues}>
			{half}{symbol && currencyFormatter.symbol()}
		</Typography>
	);

	const min = (
		<Typography className={classes.graphValues}>
			0{symbol && currencyFormatter.symbol()}
		</Typography>
	);


	return (
		<div className={classes.graphRoot}>
			<div className={classes.positions}>
				{max}
				{mid}
				{min}
			</div>
			<div className={classes.graphArea}>
				<div className={classes.graph}>
					{data}
				</div>
				<div className={classes.timeline}>
					{timeline}
				</div>
			</div>
		</div>
	);
};

export default withStyles(styles)(Graph);