import * as listItemTypes from '../../../UI/List/ListItem/ListItemTypes';
import * as screenSize from '../../../../model/ScreenSize/ScreenSize';

const customerFields = (currentScreenSize, icon) => {
	const width = {
		[screenSize.DESKTOP]: 111,
		[screenSize.TABLET]: 78,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	const maxWidth = {
		[screenSize.DESKTOP]: 166,
		[screenSize.TABLET]: 136,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	const height = {
		[screenSize.DESKTOP]: 35,
		[screenSize.TABLET]: 45,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];
	
	return [
		{
			key: 'check',
			label: icon,
			order: 1,
			pinned: true,
			width: 75,
			maxWidth: 75,
			height,
			type: listItemTypes.COMPONENT,
		},
		{
			key: 'date',
			label: 'Date',
			order: 2,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'source',
			label: 'Source',
			order: 3,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'type',
			label: 'Type',
			order: 4,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'amount',
			label: 'Amount',
			order: 5,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'origin',
			label: 'Origin',
			order: 6,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'status',
			label: 'Status',
			order: 7,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
	];
};

export default customerFields;