import {
  CARCLUB_CLEAR_FLOWS,
  CARCLUB_CLEAR_NOTIFICATIONS,
  CARCLUB_REMOVE_DEFAULT_LOCATION,
  CARCLUB_REMOVE_NOTIFICATION,
  CARCLUB_SET_APP,
  CARCLUB_SET_CARCLUBS,
  CARCLUB_SET_FEATURES,
  CARCLUB_SET_FLOWS,
  CARCLUB_SET_LOCALE,
  CARCLUB_SET_LOCATIONS,
  CARCLUB_SET_NOTIFICATIONS,
  CARCLUB_SET_PATTERNS,
  CARCLUB_SET_TIMEZONE,
} from "./actionTypes";
import * as methods from "../../../utils/makeActionCreator";
import Carflow from "../../../model/Carclub/Carflow";
import Feature from "../../../model/Carclub/Feature";
import { dateFormatter } from "../../../services/formatter";
import makeActionCreator from "../../../utils/makeActionCreator";
import NGError from "../../../error/ngError";
import log from "../../../utils/logger";

import carclubService from "../../../services/carclub";
import notificationService from "../../../services/notification";
import { publishAndAwait } from "../../../app/coreSlice";

const logger = log("CarclubActions");

export const carclubClearFlows = () => ({ type: CARCLUB_CLEAR_FLOWS, method: methods.REPLACE });
export const carclubClearNotifications = () => ({ type: CARCLUB_CLEAR_NOTIFICATIONS, method: methods.REPLACE });
export const carclubRemoveDefaultLocation = () => ({ type: CARCLUB_REMOVE_DEFAULT_LOCATION, method: methods.REPLACE });
export const carclubRemoveNotification = (id) => ({ type: CARCLUB_REMOVE_NOTIFICATION, id, method: methods.REPLACE });
export const carclubSet = makeActionCreator(CARCLUB_SET_APP, "carclub");
export const carclubSetCarclubs = makeActionCreator(CARCLUB_SET_CARCLUBS, "carclubs");

export const carclubSetFeatures = makeActionCreator(CARCLUB_SET_FEATURES, "features");
export const carclubSetFlows = makeActionCreator(CARCLUB_SET_FLOWS, "flows");
export const carclubSetLocale = makeActionCreator(CARCLUB_SET_LOCALE, "locale");
export const carclubSetLocations = makeActionCreator(CARCLUB_SET_LOCATIONS, "locations");

export const carclubSetNotifications = makeActionCreator(CARCLUB_SET_NOTIFICATIONS, "notifications");
export const carclubSetPatterns = makeActionCreator(CARCLUB_SET_PATTERNS, "patterns");

export const carclubSetTimezone = makeActionCreator(CARCLUB_SET_TIMEZONE, "timezone");

export const carclubGetDetails = ({ time, currency, ...rest }) => {
  return (dispatch) => {
    dispatch(
      carclubSet({
        ...rest,
        patterns: {
          currency,
          time,
        },
      })
    );
  };
};

export const getFeatures = () => {
  return async (dispatch) => {
    const request = await publishAndAwait({
      destination: "/app/hello",
      body: {
        type: "CARCLUB",
        subType: "GET_FEATURES",
      },
    });

    const { processMessage, error } = request.response;

    if (error) {
      return;
    }

    const features = processMessage.map((feature) => new Feature(feature));

    dispatch(carclubSetFeatures(features));
  };
};

export const getFlows = (carclubId) => {
  return async (dispatch) => {
    dispatch(carclubClearFlows());

    if (!carclubId) {
      return;
    }

    const request = await publishAndAwait({
      destination: "/app/hello",
      body: {
        type: "CARCLUB",
        subType: "GET_CARFLOWS",
        message: {
          carclubId,
        },
      },
    });

    const { processMessage, error } = request.response;

    if (error) {
      return;
    }

    const flows = processMessage.map((flow) => flowMapper(flow));

    dispatch(carclubSetFlows(flows));
  };
};

export const getNotifications = () => {
  return async (dispatch) => {
    try {
      dispatch(carclubClearNotifications());

      const notifications = await notificationService.listNotifications();

      dispatch(carclubSetNotifications(notifications));
    } catch {
      logger.info("Cannot list car club notifications");
    }
  };
};

export const updateNotification = (notification) => {
  return async (dispatch) => {
    try {
      const updatedNotification = await notificationService.updateNotification(notification);
      const array = [];
      array.push(updatedNotification);
      dispatch(carclubSetNotifications(array));
    } catch {
      logger.info("Cannot list car club notifications");
    }
  };
};

export const removeNotification = (id) => {
  return async (dispatch) => {
    try {
      const notificationId = await notificationService.removeNotification(id);
      console.log("result: %o", notificationId);

      if (!notificationId) {
        throw new NGError("Notification id is null");
      }

      dispatch(carclubRemoveNotification(notificationId));
      // dispatch(getNotifications());
    } catch (error) {
      logger.info(`Cannot remove carclub notification ${id}: ${error}`);
    }
  };
};

export const getCarclubs = () => {
  return async (dispatch) => {
    const request = await publishAndAwait({
      destination: "/app/hello",
      body: {
        type: "CARCLUB",
        subType: "GET_ALL",
      },
    });

    const { processMessage, error } = request.response;

    if (error) {
      return;
    }

    const carclubDetails = await carclubService.getDetails();
    let result = processMessage.filter((item) => item.code === carclubDetails.code);
    dispatch(carclubSetCarclubs(result));
  };
};

export const getCarflowFeatures = (data) => {
  return async (dispatch) => {
    const request = await publishAndAwait({
      destination: "/app/hello",
      body: {
        type: "CARCLUB",
        subType: "GET_CARFLOW_FEATURES",
        message: {
          ...data,
        },
      },
    });

    const { processMessage, error } = request.response;

    if (error) {
      return { ...data, error: "Could not get features" };
    }

    const features = processMessage.reduce(featureReducer, {});
    const flow = { ...data, features };

    dispatch(carclubSetFlows([flow]));

    return flow;
  };
};

export const updateCarclubFlows = (data, carclubId) => {
  if (!data) {
    return;
  }

  const subType = !!data.id ? "UPDATE_CARFLOW" : "CREATE_CARFLOW";

  return async (dispatch) => {
    const featuresRequest = await publishAndAwait({
      destination: "/app/hello",
      body: {
        type: "CARCLUB",
        subType,
        message: {
          report: true,
          ...data,
        },
      },
    });

    const { processMessage: featuresPM, error: featuresError } = featuresRequest.response;

    if (featuresError) {
      return { error: featuresError };
    }

    const request = await publishAndAwait({
      destination: "/app/hello",
      body: {
        type: "CARCLUB",
        subType: "GET_CARFLOWS",
        message: {
          carclubId,
        },
      },
    });

    const { processMessage, error } = request.response;

    if (error) {
      return;
    }

    const flows = processMessage.map((flow) => flowMapper(flow));

    dispatch(carclubClearFlows());
    dispatch(carclubSetFlows(flows));

    const features = featuresPM.features.reduce(featureReducer, {});

    const flow = { ...flowMapper(featuresPM.carflow), features };

    dispatch(carclubSetFlows([flow]));

    return flow;
  };
};

// FIXME this mapper should not be here
const flowMapper = ({ createdBy, createDate, description, features, id, report, subtype, type }) =>
  new Carflow({
    createdBy: createdBy || "",
    createDate: dateFormatter.format(createDate, "dateTime"),
    description: description,
    features: features || [],
    id: id,
    report: report,
    subtype: subtype,
    type: type,
  });

const featureReducer = (accumulator, feature, order) => {
  return { ...accumulator, [feature.id]: { ...new Feature(feature), order } };
};
