import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Button } from "@material-ui/core";

import { styles } from "./CloseButton.styles";
import { reservationNewReservationCloseBo, selectActionIsLoading } from "../../../reservationsSlice";
import SimpleConfirmationModal from "../../../../../common/displays/Modal/SimpleConfirmationModal";

function CloseButton({ classes, reservation, actions }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectActionIsLoading);
  const [confirmModal, setConfirmModal] = useState(false);

  const { parkOn, code, carToken } = reservation;

  const onCloseHandler = () => {
    setConfirmModal(false);
    dispatch(reservationNewReservationCloseBo({ reservationCode: code, carToken }, actions));
  };

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => (parkOn ? onCloseHandler() : setConfirmModal(true))}
        disabled={isLoading}
      >
        End Trip
      </Button>
      <SimpleConfirmationModal
        open={confirmModal}
        setOpen={setConfirmModal}
        message={`The vehicle is not parked, are you sure you want to close this trip?`}
        onConfirm={onCloseHandler}
      />
    </div>
  );
}

export default withStyles(styles)(CloseButton);
