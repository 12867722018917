const styles = theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		padding: "15px",
		boxSizing: 'border-box',
		height: '100%',
		width: '400px',
		maxWidth: '400px',
		//width: '100vw', this is used for full screen edit
	},

	form: {
		padding: "10px",
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		margin: '25px 0',
		boxSizing: 'border-box',
		height: '100%',
		overflow: 'auto',
		scrollbarWidth: 'thin',
		'&::-webkit-scrollbar': {
			width: '1px',
		},

		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)', 
		},
	},

	subform: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: '25px',
		boxSizing: 'border-box',
	},

	buttons: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
	},

	resetButton: {
		color: '#e74',
	},

	title: {
		fontSize: '22px',
	},

	subTitle: {
		fontSize: '16px',
	},
});

export default styles;