import deletePark from './deletePark';
import getAssociatedAssets from './getAssociatedAssets';
import listPark from './listPark';
import upsertPark from './upsertPark';

const parkService = {
	deletePark,
	getAssociatedAssets,
	listPark,
	upsertPark,
};

export default parkService;