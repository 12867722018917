import React from 'react';
import List from '../../../UI/List/List';
import PromotionItem from './PromotionItem/PromotionItem';
import {
	withStyles,
} from '@material-ui/core';
import promotionFields from './promotionFields';
import styles from './PromotionList.styles';
import classnames from 'classnames';

const PromotionList = ({
	classes,
	className,
	currentScreenSize,
	getMoreRows,
	onSelect,
	promotionsArray,
	selected,
}) => {

	const promotions = !!promotionsArray
		&& [...new Set(promotionsArray.map(promotion => JSON.stringify(promotion)))]
			.map(promo => {
				const promotion = JSON.parse(promo);

				return (
					<PromotionItem
						key={promotion.id}
						onClick={() => onSelect(promotion)}
						promotion={promotion}
						selected={selected === promotion.id}
					/>
				);
			});

	const list = (
		<List
			className={classnames(classes.root, className)}
			headerFields={promotionFields(currentScreenSize)}
			getMoreRows={getMoreRows}
		>
			{promotions}
		</List>
	);

	return list;
};

export default withStyles(styles)(PromotionList);
