import React from 'react';
import {
	Menu,
	MenuItem,
} from '@material-ui/core';
import {
	AddCircle as AddIcon,
} from '@material-ui/icons';
const addButton = ({classes, setAnchorEl, anchorEl, onCreate}) => (
	<>
		<AddIcon
			id={'chat_add_icon'}
			aria-controls={'chat_create_menu'}
			aria-haspopup='true'
			onClick={e => setAnchorEl(e.target)}
			className={classes.addIcon}
		/>
		<Menu
			id={'chat_create_menu'}
			anchorEl={anchorEl}
			open={!!anchorEl}
			onClose={e => setAnchorEl()}
		>
			<MenuItem
				onClick={() => {
					setAnchorEl();
					onCreate('CREATE');
				}}
			>
				Chat
			</MenuItem>
			<MenuItem
				onClick={() => {
					setAnchorEl();
					onCreate('CREATE_GROUP');
				}}
			>
				Group Chat
			</MenuItem>
		</Menu>
	</>
);

export default addButton;