import React, { useState, useCallback } from 'react';
import {
	Checkbox,
	Divider,
	List,
	ListItem,
	TextField,
	Typography,
	withStyles,
} from '@material-ui/core';
import {
	AddCircle as AddIcon,
	RemoveCircle as RemoveIcon,
	Check as CheckIcon,
} from '@material-ui/icons';
import classnames from 'classnames';
import styles from './PricingPlanEditor.styles';
import { currencyFormatter } from '../../../services/formatter';

const ZonesListWrapper = ({
	addZone,
	...props
}) => {
	const [currentFee, setCurrentFee] = useState('');
	const [isEqual, setIsEqual] = useState(false);

	const onFeeChange = useCallback(e => (Number(e.target.value) || Number(e.target.value) === 0
		|| e.target.value === '-')
		&& setCurrentFee(e.target.value), []);

	const setEqual = useCallback(() => setIsEqual(prev => !prev), []);

	const onAdd = useCallback(() => addZone(currentFee, isEqual), [addZone, currentFee, isEqual]);

	return (
		<ZonesList
			{...props}
			currentFee={currentFee}
			isEqual={isEqual}
			onAdd={onAdd}
			onFeeChange={onFeeChange}
			setEqual={setEqual}
		/>
	);
};

const ZonesList = withStyles(styles)(({
	classes,
	currentFee,
	hasEqual,
	isEqual,
	onAdd,
	onFeeChange,
	onRemove,
	setEqual,
	title,
	viewOnly,
	zonesList,
}) => {
	const equal = hasEqual && !viewOnly && (
		<>
			<Typography className={classes.zonesTitle}>
				Equal
			</Typography>
			<Checkbox
				checked={isEqual || false}
				color='primary'
				onChange={setEqual}
			/>
		</>
	);

	const feeAndEqual = !viewOnly && (
		<div className={classes.feeAndEqual}>
			<TextField
				className={classes.textField}
				onChange={onFeeChange}
				value={currentFee || ''}
				InputProps={{
					endAdornment: currencyFormatter.symbol(),
				}}
			/>
			{equal}
		</div>
	);

	const addButton = !viewOnly && (
		<AddIcon
			className={classes.addButton}
			onClick={onAdd}
		/>
	);

	const header = (
		<div className={classes.header}>
			<Typography className={classes.zonesTitle}>
				{`${title} Fee`}
			</Typography>
			{feeAndEqual}
			{addButton}
		</div>
	);

	const listHeader = (
		<div className={classes.listHeader}>
			{
				['Description', 'Fee', 'Equal', 'button']
					.filter(label => !(viewOnly && label === 'button'))
					.map(label => (
						<Typography
							key={'label_' + label}
							className={classnames(classes.headerLabel,
								label === 'button' && classes.buttonLabel,
								label === 'Equal' && classes.equalHeader,
								label === 'Fee' && classes.fee)}
						>
							{label !== 'button' && !(!hasEqual && label === 'Equal') ? label : ''}
						</Typography>
					))
			}
		</div>
	);

	const listItems = Array.isArray(zonesList) && (
		<List className={classes.listItems}>
			{
				zonesList.map(zone => {
					const parameters = ['description', 'zoneFee'].map(parameter => (
						<Typography
							className={classnames(classes.parameter,
								parameter === 'zoneFee' && classes.fee)}
							key={'parameter_' + parameter + zone?.zoneId}>
							{`${zone?.[parameter]} ${parameter === 'zoneFee'
								? currencyFormatter.symbol()
								: ''}`}
						</Typography>
					));

					const equal = (
						<span className={classes.equalParam}>
							{zone?.equal || zone?.startEndEqual ? <CheckIcon className={classes.checkIcon} /> : ''}
						</span>
					);

					const removeButton = !viewOnly && (
						<RemoveIcon
							className={classes.removeButton}
							onClick={() => onRemove(zone)}
						/>
					);

					return (
						<ListItem
							disableGutters={true}
							className={classes.listItem}
							key={zone?.zoneId}
						>
							<div className={classes.item}>
								{parameters}
								{equal}
								{removeButton}
							</div>
						</ListItem>
					);
				})
			}
		</List>
	);

	return (
		<div className={classes.zonesRoot}>
			{header}
			{listHeader}
			<Divider />
			{listItems}
		</div>
	);
});

export default ZonesListWrapper;