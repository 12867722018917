import React from 'react';
import classnames from 'classnames';
import CrossIcon from '@material-ui/icons/Clear';
import Typography from '@material-ui/core/Typography';

const imageHandler = ({
	classes,
	images,
	removeImage,
}) => {
	const items = images?.map((item, index) => {
		const isString = typeof item.url === 'string';

		const name = (
			<Typography
				onClick={() => window.open(isString
					? item.url
					: URL.createObjectURL(item), "_blank")}
				className={classes.imageText}
			>
				{item.name}
			</Typography>
		);

		const crossIcon = (
			<CrossIcon
				className={classes.icon}
				onClick={() => removeImage(index)}
			/>
		);

		return (
			<div
				key={item?.name + index}
				className={classnames(classes.imageRoot, index > 0 && classes.attachmentItemBorder)}
			>
				<div
					className={classnames(classes.attachmentItem)}
				>
					{name}
					{crossIcon}
				</div>
			</div>
		);
	});

	return (
		<div className={classes.attachmentsDiv}>
			{items}
		</div>
	);
};

export default imageHandler;