import makeActionCreator from '../../../utils/makeActionCreator';
import createReducer from '../../../hooks/createCustomReducer';

const types = {
	SET_CATEGORY : 'SET_CATEGORY',
	SET_DEFAULT_LOCATION : 'SET_DEFAULT_LOCATION',
	SET_CODE : 'SET_CODE',
 	SET_DESCRIPTION : 'SET_DESCRIPTION',
 	SET_ERROR : 'SET_ERROR',
 	SET_ZONE_TYPE : 'SET_ZONE_TYPE',
};

export const setCategory = makeActionCreator(types.SET_CATEGORY, 'category');
export const setDefaultLocation = makeActionCreator(types.SET_DEFAULT_LOCATION, 'defaultLocation');
export const setCode = makeActionCreator(types.SET_CODE, 'code');
export const setDescription = makeActionCreator(types.SET_DESCRIPTION, 'description');
export const setError = makeActionCreator(types.SET_ERROR, 'error');
export const setZoneType = makeActionCreator(types.SET_ZONE_TYPE, 'zoneType');

const actions = dispatch => ({
	setCategory: data => dispatch(setCategory(data)),
	setDefaultLocation: data => dispatch(setDefaultLocation(data)),
	setCode: data => dispatch(setCode(data)),
	setDescription: data => dispatch(setDescription(data)),
	setError: data => dispatch(setError(data)),
	setZoneType: data => dispatch(setZoneType(data)),
});

export const initialState = {
	defaultLocation: '',
	category: '',
	description: '',
	error: null,
	zoneType: '',
};

const setDefaultLocationReducer = (state, action) => ({
	...state,
	defaultLocation: action.defaultLocation,
});

const setCategoryReducer = (state, action) => ({
	...state,
	category: action.category,
});

const setCodeReducer = (state, action) => ({
	...state,
	code: action.code,
});

const setDesciptionReducer = (state, action) => ({
	...state,
	description: action.description,
});

const setErrorReducer = (state, action) => ({
	...state,
	error: action.error,
});

const setZoneTypeReducer = (state, action) => ({
	...state,
	zoneType: action.zoneType,
});

export const reducer = (state, action) => ({
	[types.SET_DEFAULT_LOCATION]: setDefaultLocationReducer,
	[types.SET_CATEGORY]: setCategoryReducer,
	[types.SET_CODE]: setCodeReducer,
	[types.SET_DESCRIPTION]: setDesciptionReducer,
	[types.SET_ERROR]: setErrorReducer,
	[types.SET_ZONE_TYPE]: setZoneTypeReducer,
	undefined: () => state,
}[action.type](state, action));

export default createReducer({ reducer, actions, defaultState: initialState });
