import {
	CAR,
	KICKSCOOTER,
	SCOOTER,
} from '../../../model/Asset/assetTypes.js';

export const createAssetConfigs = [
	{
		label: 'Add a Car',
		onClickParam: CAR,
		tag: CAR,
	},
	{
		label: 'Add a Kickscooter',
		onClickParam: KICKSCOOTER,
		tag: 'KICKSCOOTER',
	},
	{
		label: 'Add a Scooter',
		onClickParam: SCOOTER,
		tag: 'SCOOTER', 
	},
];

export default createAssetConfigs;
