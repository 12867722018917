import React from 'react';
import { publishAndAwait } from '../../../../app/coreSlice';
import ZonesItem from './ZonesItem';

const removeZone = async (code, setZones, setSelectedZoneCode) => {
	// FIXME this request should be in its own WS service
	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'ZONES',
			subType: 'DELETE',
			message: {
				zoneCodes: [code],
			},
		},
	});

	const { processMessage, error } = request && request.response;

	if (error) {
		return;
	};
	setSelectedZoneCode();
	setZones(processMessage, code);
};

const ZonesItemWrapper = props => {
	return (
		<ZonesItem
			{...props}
			removeZone={removeZone}
		/>
	);
};

export default ZonesItemWrapper;
