import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { FormControl, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";

import { styles } from "./_base.styles";

function SelectInput({ classes, id, className, label, value, onChange, disabled, error, options, variant, required }) {
  return (
    <div className={classes.root + " " + className}>
      {variant === "outlined" && (
        <Typography
          component="span"
          variant="caption"
          htmlFor={id}
          color="textPrimary"
          className={disabled ? classes.disabledLabel : ""}
        >
          {label}
          {required && <span> *</span>}
        </Typography>
      )}
      <FormControl
        className={classes.formControl + (disabled ? " " + classes.disabledInput : "")}
        fullWidth
        variant={variant}
        size="small"
      >
        {variant !== "outlined" && (
          <InputLabel>
            {label}
            {required && <span> *</span>}
          </InputLabel>
        )}
        <Select
          id={id}
          value={value}
          onChange={(e) => onChange && onChange(e.target.value)}
          className={classes.select}
          fullWidth
          error={error}
          disabled={disabled}
        >
          {(options || []).map((item, index) => (
            <MenuItem key={"option_" + index} value={item.value}>
              {item.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default withStyles(styles)(SelectInput);
