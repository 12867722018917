import React from "react";
import { useSelector, useDispatch } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";
import Button from "../../../components/UI/Buttons/CommonButtons";

import { styles } from "./CustomerForm.styles";
import { formSchema, formFieldsPersInfo, formFieldsDevices, formFieldsBilling } from "./CustomerForm.schema";
import { FormFields, Form } from "../../../common/forms";

import { customerCreate } from "../customerSlice";
import { selectDefaultLocation } from "../../carclub/carclubSlice";
import { isCommercial, isSubscription } from "../../../common/utils/OTypes";

function CustomerForm({ classes, onCancel, onSave }) {
  const dispatch = useDispatch();
  const selectedLocation = useSelector(selectDefaultLocation);

  const onSubmit = (customer) => {
    dispatch(customerCreate(customer));
    setTimeout(() => {
      onSave && onSave();
    }, 300);
  };

  return (
    <div className={classes.root}>
      <Form
        schema={formSchema()}
        initialValues={{}}
        onSubmit={onSubmit}
        render={({ submitting, form }) => {
          return (
            <>
              <Typography variant="h5" align="left" component="h1">
                Create Customer
              </Typography>
              <br />
              <div className={classes.form}>
                <Typography className={classes.subTitle}>Personal Info</Typography>
                <FormFields fields={formFieldsPersInfo()} />
                {(isCommercial(selectedLocation.operationType) || isSubscription(selectedLocation.operationType)) && (
                  <>
                    <br />
                    <br />
                    <Typography className={classes.subTitle}>Devices</Typography>
                    <FormFields fields={formFieldsDevices()} />
                  </>
                )}
                <br />
                <br />
                <Typography className={classes.subTitle}>Billing</Typography>
                <FormFields fields={formFieldsBilling()} />
              </div>
              <Grid container justifyContent="space-between" className={classes.formButtons}>
                <Button buttonStyleName="void" label="Cancel" onClick={onCancel} />
                <Button buttonStyleName="filled" type="submit" disabled={submitting} label="Create" />
              </Grid>
            </>
          );
        }}
      />
    </div>
  );
}

export default withStyles(styles)(CustomerForm);
