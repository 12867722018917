import { wsCall, requestHelper } from "../../../app/coreSlice";
import { setMapCenter, updateAssetOnList } from "../../asset/assetSlice";

const bigdataDeviceHeartbeats_assetRefreshCall = async (
  { setSelectedAssetHeartbeats },
  dispatch,
  state,
  { assetToken, start, end, deviceToken }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "BIGDATA/DEVICE_HEARTBEATS");
  try {
    const result = await wsCall({
      type: "BIGDATA",
      subType: "DEVICE_HEARTBEATS",
      locationId: selectedLocation?.id,
      message: {
        start,
        end,
        deviceToken,
        offset: 0,
        limit: 10000,
      },
    });
    if (result) {
      const { heartbeatsList } = result;
      //console.log("---------------------------- heartbeatsList: %o", heartbeatsList);
      let orderedHeartBeats = heartbeatsList
        .filter((item) => item?.heartbeat?.position_date)
        .sort((a, b) => {
          return new Date(a.heartbeat.position_date).getTime() > new Date(b.heartbeat.position_date).getTime() ? -1 : 1;
        });

      if (orderedHeartBeats?.length > 0) {
        dispatch(
          updateAssetOnList({
            assetToken,
            latitude: orderedHeartBeats[0].heartbeat.latitude,
            longitude: orderedHeartBeats[0].heartbeat.longitude,
          })
        );
        dispatch(
          setMapCenter({
            lat: orderedHeartBeats[0].heartbeat.latitude,
            lng: orderedHeartBeats[0].heartbeat.longitude,
          })
        );
      }
      dispatch(setSelectedAssetHeartbeats({ list: orderedHeartBeats, assetToken }));
    }
  } catch (_ex) {
    console.log("ex: %o", _ex);
    rh.error("Error getting device heartbeats.");
  }
  rh.close();
};

export default bigdataDeviceHeartbeats_assetRefreshCall;
