import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useSwitch from "../../../hooks/useSwitch";
import locationService from "../../../services/location";
import { createOrUpdateLocation, removeLocation } from "./serviceMapper";
import LocationsWorkspace from "./LocationsWorkspace";
import log from "../../../utils/logger";
import { selectDefaultLocation } from "../../../features/carclub/carclubSlice";

const logger = log("LocationsWorkspace");

const LocationsWorkspaceWrapper = ({ polygons, setShowList, showList, ...props }) => {
  const { clearMapOfPolygons, locationPainter, hideLocation } = props;

  const defaultLocationFromUser = useSelector(selectDefaultLocation);

  const [locations, setLocations] = useState({});
  const [selectedLocationCode, setSelectedLocationCode] = useState();
  const [importLocation, setImportLocation] = useState();
  const [isImporting, setIsImporting] = useState();
  const [areAllLocationsPainted, setAreAllLocationsPainted] = useState(false);

  const hasModal = useSwitch(false);
  const isCreating = useSwitch(false);

  useEffect(() => {
    const getLocations = async () => {
      try {
        setLocations(await locationService.listLocation());
      } catch {
        logger.warn("Could not get locations list");
      }
    };
    getLocations();
  }, []);

  useEffect(() => {
    if (!isCreating.state && polygons["NEW"]) {
      hideLocation("NEW");
    }
  }, [isCreating.state, polygons, hideLocation]);

  useEffect(() => {
    !!locations &&
      !!locations[selectedLocationCode] &&
      !areAllLocationsPainted &&
      locationPainter(locations[selectedLocationCode]);
    // FIXME Make so we do not need to disable it
    // eslint-disable-next-line
  }, [selectedLocationCode, locations, areAllLocationsPainted]);

  useEffect(() => {
    if (isCreating.state && polygons["NEW"] && !isImporting) {
      hasModal.setOn();
    }
  }, [polygons, hasModal, isCreating.state, isImporting]);

  const paintAllLocations = () => {
    try {
      if (!areAllLocationsPainted) {
        const locationsArray = Object.values(locations);
        for (let i = 0; i < locationsArray?.length; i++) {
          const polygonOnClick = () => {
            setSelectedLocationCode(locationsArray[i]?.code);
            const listItem = document.getElementById("location_item_" + locationsArray[i]?.code);
            !!listItem && listItem.scrollIntoView();
          };

          locationPainter(locationsArray[i], false, polygonOnClick);
        }
        setAreAllLocationsPainted(true);
      } else {
        setSelectedLocationCode();
        clearMapOfPolygons();
        setAreAllLocationsPainted(false);
      }
    } catch (error) {
      logger.warn("Error while painting all locations.", error);
    }
  };

  return (
    <LocationsWorkspace
      {...props}
      areAllLocationsPainted={areAllLocationsPainted}
      createOrUpdateLocation={createOrUpdateLocation(setLocations, setSelectedLocationCode)}
      hasModal={hasModal}
      importLocation={importLocation}
      isCreating={isCreating}
      isImporting={isImporting}
      paintAllLocations={paintAllLocations}
      polygon={polygons[selectedLocationCode || "NEW"]}
      removeLocation={removeLocation(setLocations, setSelectedLocationCode, hideLocation)}
      selectedLocation={!!locations && locations[selectedLocationCode]}
      selectedLocationCode={selectedLocationCode}
      setImportLocation={setImportLocation}
      setIsImporting={setIsImporting}
      setSelectedLocationCode={setSelectedLocationCode}
      showLocations={{
        state: showList,
        setOn: () => setShowList(true),
        setOff: () => setShowList(false),
        toggle: () => setShowList(!showList),
      }}
      locations={locations}
      defaultLocationFromUser={defaultLocationFromUser}
    />
  );
};

export default LocationsWorkspaceWrapper;
