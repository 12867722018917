export const styles = theme => ({

	root: {
		padding: 10,
		height: '85%',
	},

	workspace: {

	},

	sepatatedLists: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
  
	separatedTrip: {
		height: 75,
	},

	healthyList: {
		width: '50%',
		flexBasis: '50%',
		height: '100%',
		paddingRight: 8,
	},
  
	warningsList: {
		width: '50%',
		flexBasis: '50%',
		height: '100%',
		paddingRight: 8,
	},

	noTripsMessage: {
		marginLeft: 8,
		marginTop: 8,
	},
});