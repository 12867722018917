const styles = theme => ({

	header: {
		alignItems: 'baseline',
		display: 'flex',
		width: '100%',
	},

	title: {
		fontSize: '20px',
	},

	icons: {
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'flex-end',
	},
	
	root: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: '100%',
	},

});

export default styles;