const styles = () => ({

	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: 'auto',
		justifyContent: 'space-between',
		padding: '25px',
		boxSizing: 'border-box',
	},

	buttons: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
		marginTop: 20,
	},

	title: {
		fontSize: '22px',
	},

	calendarTitle: {
		fontSize: '14px',
	},

	error: {
		borderRadius: '21px',
		backgroundColor: 'red',
		color: 'white',
		margin: '10px',
	},

	datePickersContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
	},

	pickers: {
		backgroundColor: '#FFFFFF',
		border: '1px solid #DDDDDD',
		borderRadius: '2px',
		boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.12)',
		width: '106px',
		height: '32px',
	},

	pickerLabel: {
		color: '#4C4C4E',
		// FIXME some of these should be given by the Mui.theme
		fontSize: '12px',
		fontWeight: '400',
		lineHeight: '34px',
		width: '32px',
		textAlign: 'left',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},

	input: {
		marginTop: '0 !important',
		padding: '3px !important',
		// FIXME some of these should be given by the Mui.theme
		color: '#89898B',
		fontSize: '12px',
		fontWeight: '400',
		lineHeight: '14px',
		textAlign: 'left',
	},

	inputIcon: {
		padding: '2px',
	},

});

export default styles;