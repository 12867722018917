import { wsCall, requestHelper } from "../../../app/coreSlice";

const assetMakeUnavailableCall = async (
  { updateAssetOnList },
  dispatch, state,
  { carToken, maintenance }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "ASSET/MAKE_UNAVAILABLE");
  try {
    const asset = await wsCall({
      type: "ASSET",
      subType: "MAKE_UNAVAILABLE",
      locationId: selectedLocation?.id,
      message: {
        carToken,
        maintenance,
      },
    });
    dispatch(updateAssetOnList({ ...asset, assetToken: asset.carToken }));
  } catch (_ex) {
    rh.error("Error making asset unavailable.");
  }
  rh.close();
};

export default assetMakeUnavailableCall;
