import React from "react";
import List from "../../../UI/List/List";
import { withStyles } from "@material-ui/core";
import Item from "./Item/Item";
import listConfig from "./listConfig";
import { styles } from "./WorkOrderList.styles";
import classnames from "classnames";
import { useSelector } from "react-redux";

const WorkOrderList = ({
  classes,
  className,
  lastPage,
  onComplete,
  onEdit,
  onRemove,
  onSearch,
  onSelect,
  onShowTasks,
  selectedWorkOrder,
  workOrders,
}) => {
  const screenSize = useSelector((state) => state.getIn(["app", "windowWidth"]));

  const list =
    Array.isArray(workOrders) &&
    workOrders.map((workOrder) => (
      <Item
        key={workOrder.code}
        onComplete={onComplete}
        onEdit={onEdit}
        onRemove={onRemove}
        onSelect={onSelect}
        onShowTasks={onShowTasks}
        selected={selectedWorkOrder === workOrder.code}
        workOrder={workOrder}
      />
    ));

  return (
    <List
      className={classnames(classes.root, className)}
      disableGutters={true}
      getMoreRows={!lastPage && onSearch}
      headerFields={listConfig(screenSize)}
    >
      {list}
    </List>
  );
};

export default withStyles(styles)(WorkOrderList);
