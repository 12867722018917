import { wsCall, requestHelper } from "../../../app/coreSlice";

const reservationClosedListCall = async (
  { setClosedReservationsFilter, clearClosedReservations, setClosedReservations },
  dispatch,
  state,
  { filter, clear }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  const { fromDateUtc, toDateUtc, page, states, filterType, search, advanced } = filter;
  // let subType = "RESERVATION_CLOSED_LIST" + (filterType === "TRIP" ? "_NOC" : ""); // remover quando V1 deixar de ser precisa
  let subType = "RESERVATION_CLOSED_LIST";
  let rh = requestHelper(dispatch, "RESERVATION/" + subType);
  try {
    dispatch(setClosedReservationsFilter(filter));
    if (clear) {
      dispatch(clearClosedReservations());
    }

    const searchTrimmed = search?.trim() || undefined;
    const searchFilter =
      filterType === "CUSTOMER"
        ? {
            customerCode: searchTrimmed,
            customerEmail: searchTrimmed,
            customerName: searchTrimmed,
            taxNumber: searchTrimmed,
            phone: searchTrimmed,
          }
        : { reservationCode: searchTrimmed, make: searchTrimmed, model: searchTrimmed, licensePlate: searchTrimmed };
    const result = await wsCall({
      type: "RESERVATION",
      subType,
      locationId: selectedLocation?.id,
      message: {
        fromDate: fromDateUtc,
        toDate: toDateUtc,
        page,
        states: !states || states.length === 0 ? undefined : states,
        advanced,
        ...searchFilter,
      },
    });

    dispatch(setClosedReservations(result));
  } catch (_ex) {
    rh.error("Error getting reservations.");
  }
  rh.close();
};

export default reservationClosedListCall;
