import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeSelectedWorkorder } from '../../../../../store/actions/chat/action';
import CreateChat from './CreateChat';
import chatService from '../../../../../services/chat';
import customerService from '../../../../../services/customer/ws';
import carclubService from '../../../../../services/carclub';
import log from '../../../../../utils/logger';

const logger = log('CreateChatWrapper');

const CreateChatWrapper = ({
	groupId,
	nickname,
	onClose,
	type,
	...props
}) => {
	const [description, setDescription] = useState();
	const [error, setError] = useState(false);
	const [hasRequest, setHasRequest] = useState(false);
	const [name, setName] = useState(getName(nickname)[type]);
	const [placeholder, setPlaceholder] = useState([]);
	const [adminNickname, setNickname] = useState('');

	const errorTimeout = useRef();

	const dispatch = useDispatch();

	const selectedWorkorder = useSelector(state => state.getIn(['chat', 'workorder']));

	//Clear error
	useEffect(() => {
		if (error) {
			errorTimeout.current = setTimeout(() => setError(), 3000);
		}

		return () => clearTimeout(errorTimeout.current);
	}, [error]);

	const getContacts = useCallback(async ({ string: name }) => {
		const { customers } = await customerService.listCustomers({ agent: true, name });
		const { users } = await carclubService.getUsers({ string: name });

		const agents = customers
			.map(agent => ({ key: agent.code, label: agent.fullName, phone: agent.phoneNumber }));
		const operators = Object.values(users)
			.map(operator => ({ key: operator.code, label: operator.name, phone: operator.phone }));

		return [...agents, ...operators];
	}, []);

	const onChange = useCallback(({ key, label, phone }) => setPlaceholder(state => {
		const previousKeys = state.map(contact => contact?.key);

		return previousKeys.includes(key)
			? state.filter(contact => contact?.key !== key)
			: [{ key, label, phone }];
	}), []);

	const onSave = useCallback(async () => {
		setHasRequest(true);

		try {
			const message = getMessage({
				description,
				groupId,
				name,
				nickname: adminNickname,
				placeholder,
				type,
			});

			await chatService.updateChat({ subType: type, message });

			setHasRequest(false);

			onClose(type);
		} catch (e) {
			setHasRequest(false);
			setError(e.message || e.description || 'Could not save');
			logger.warn(e.message || e.description || 'Could not save');
		};
	}, [onClose, type, placeholder, name, groupId, description, adminNickname]);

	useEffect(() => {
		if ('CREATE' !== type || !selectedWorkorder) {
			return;
		};

		const key = selectedWorkorder.assignAgent;
		const label = selectedWorkorder.assignAgentName;

		setPlaceholder([{ key, label }]);
		setName(label);

		dispatch(removeSelectedWorkorder());
	}, [type, selectedWorkorder, dispatch]);

	return (
		<CreateChat
			{...props}
			description={description}
			error={error}
			getContacts={getContacts}
			hasRequest={hasRequest}
			name={name}
			nickname={adminNickname}
			onChange={onChange}
			onClose={onClose}
			onSave={onSave}
			placeholder={placeholder}
			setDescription={setDescription}
			setName={setName}
			setNickname={setNickname}
			type={type}
		/>
	);
};

export default CreateChatWrapper;

const getMessage = ({ type, placeholder, name, groupId, description, nickname }) => ({
	CREATE: { customerCode: placeholder?.[0]?.key, nickname: name },
	CREATE_GROUP: { name, description, nickname },
	GROUP_UPDATE: { customerCode: placeholder?.[0]?.key, groupId, admin: true },
	INVITE_GROUP: { customerCode: placeholder?.[0]?.key, groupId, nickname: name },
})[type];

const getName = name => ({
	GROUP_UPDATE: name,
});