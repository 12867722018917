import React, { Component } from "react";
import DisplayAsset from "../../Assets/Car/ShowAsset/DisplayAssetWrapper";
import AssetVirtualList from "../AssetList/AssetVirtualList";
import DropdownMenu from "../../UI/DropdownMenu/GenericDropdownMenu/GenericDropdownMenu.jsx";
import createAssetConfigs from "./createAssetConfigs.js";
import * as ASSET_CONFIGS from "../assetConfigs.js";
import { isEqual } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./AssetWorkspace.styles";
import AssetFilters from "../../../features/asset/AssetFilters/AssetFilters";
import AssetStateCounter from "../../../features/asset/AssetStateCounter/AssetStateCounter";
import assetDetailsCall from "../../../features/asset/_sliceCalls/assetDetails";
import store from "../../../store";
import AssetList from "../../../features/asset/AssetList/AssetList";

class AssetWorkspace extends Component {
  state = {
  
    filterButton: {
      active: undefined,
      anchor: undefined,
      selected: undefined,
    },
    hasModal: false,
    currentModal: undefined,
    hasSearchFilter: false,
    assetType: undefined,
    isDropdownOpen: false,
    editModeCustomProps: null,
  };

  selectAssetHandler = (assetToken) => {
    const { selectAsset, selectedAssetToken } = this.props;

    if (typeof assetToken === "undefined" || selectedAssetToken === assetToken) {
      this.setState(
        {
          hasSearchFilter: false,
        },
        this.props.centerMap
      );
    }

    selectAsset(assetToken);
  };

  showListHandler = (showList) => {
    const { setShowList } = this.props;

    this.toggleDropdown("true");

    const newShowList = typeof showList === "undefined" ? !this.props.showList : showList;

    if (newShowList) {
      this.props.refreshAssetList();
    }

    setShowList(newShowList);
  };

  openFilterHandler = (event, text) => {
    const { filterButton } = this.state;

    this.setState({
      filterButton: {
        ...filterButton,
        active: text,
        anchor: event.currentTarget,
      },
    });
  };

  closeFilterHandler = () => {
    const { filterButton } = this.state;

    this.setState({
      filterButton: {
        ...filterButton,
        active: null,
        anchor: null,
      },
    });
  };

  searchFilterHandler = (option) => {
    console.log("SEARCH --- option: %o ", option);
    const nextFilter = {
      ...this.props.filter,
      ...option.filter,
    };

    this.props.setFilter(nextFilter);
    this.setState(
      {
        hasSearchFilter: true,
      },
      () => this.props.refreshAssetList(nextFilter, process.env.REACT_APP_SEARCH_TIMEOUT, true)
    );
  };

  modalHandler = (currentModal, data) => {
    this.setState({ currentModal, hasModal: !!currentModal });
  };

  toggleDropdown = (forceClose) => {
    if (forceClose) {
      this.setState({ isDropdownOpen: false });
      return;
    }

    this.setState((state) => ({ isDropdownOpen: !state.isDropdownOpen }));
  };

  assetCreateHandler = (assetType) => {
    this.toggleDropdown();
    this.setState((state) => ({ assetType: assetType }), this.modalHandler("create"));
  };

  focusOnAsset = () => {
    const { showList, setShowList } = this.props;

    if (!showList) {
      setShowList(true);
    }
  };

  getAssetConfigs = (assetType) => {
    return assetType && (ASSET_CONFIGS[assetType] || ASSET_CONFIGS["CAR"]);
  };

  clearEditCustomProps = () => {
    this.setState((state) => ({
      editModeCustomProps: null,
    }));
  };

  componentWillUnmount() {
    clearTimeout(this.selectTimerId);
  }

  assetNoZoneCompare = (prevAssets, nextAssets) => {
    if (prevAssets.length !== nextAssets.length) {
      return 1;
    }

    prevAssets.forEach((prevAsset, index) => {
      delete prevAsset.zones;

      const nextAsset = nextAssets[index];
      delete nextAsset.zones;

      if (!isEqual(prevAsset, nextAsset)) {
        return 1;
      }
    });

    return 0;
  };

  render() {
    const { assets, classes, listAnchor, showList } = this.props;

    const { assetType, currentModal, hasModal, isDropdownOpen } = this.state;

    const modals = {
      create: (
        <DisplayAsset
          assetType={assetType}
          closeHandler={this.modalHandler}
          onCreate={currentModal === "create"}
          exitToShow={async (asset) => {
            if (asset && asset.carToken) {
              setTimeout(async () => {
                let assetDetails = await assetDetailsCall({}, store.dispatch, store.state, {
                  carToken: asset.carToken,
                });

                if (assetDetails.asset.active) {
                  this.selectAssetHandler(asset.carToken);
                } else {
                  this.searchFilterHandler({ filter: { string: asset.licensePlate } });
                }
              }, 2000);
            }
            this.modalHandler();
          }}
          refreshList={() => this.props.refreshAssetList(this.props.filter)}
        />
      ),

      availability: <>to delete</>,
    };

    const modal = hasModal && modals[currentModal];

    return (
      <div>
        <AssetList>
          {modal}
          <div className={classes.searchAsset}>
            <AssetFilters />
          </div>
          <div className={classes.assetLabels}>
            <AssetStateCounter />
          </div>

          {showList && assets && (
            <AssetVirtualList
              createButtonRef={listAnchor}
              onCreateAsset={() => {
                this.toggleDropdown();
              }}
              selectAsset={this.selectAssetHandler}
            />
          )}
          {isDropdownOpen && showList && (
            <DropdownMenu
              anchorEl={listAnchor.current}
              options={createAssetConfigs}
              optionHandler={this.assetCreateHandler}
              closeHandler={this.toggleDropdown}
            />
          )}
        </AssetList>
      </div>
    );
  }
}

export default withStyles(styles)(AssetWorkspace);
