import React, {
	useState,
	useEffect,
} from 'react';
import MaxFlatRatesList from './MaxFlatRatesList';
import log from '../../../../utils/logger';

//eslint-disable-next-line
const logger = log('MaxFlatRatesListWrapper');

const RatesListWrapper = (props) => {

	const {
		placeholderMaxFlatRate: placeholder,
		maxRatesArray,
	} = props;

	const [maxFlatRates, setMaxFlatRates] = useState([]);

	const {
		resetPlaceholder,
		setId,
		setName,
		setMaxPrice,
		setMaxTime,
		addRate,
		removeRate,
	} = placeholder;

	const placeholderSetters = {
		resetPlaceholder,
		setId,
		setName,
		setMaxPrice,
		setMaxTime,
		addRate,
		removeRate,
	};


	useEffect(() => {
   
		resetPlaceholder();
		setMaxFlatRates([]);

		!!maxRatesArray && maxRatesArray.forEach(rate => {
			addRate(rate);
		});

		!!maxRatesArray && setMaxFlatRates(maxRatesArray);

		const cleanup = () => {

		};

		return cleanup;

		//eslint-disable-next-line
    }, [maxRatesArray]);

	const addRateHandler = () => {

		const newMaxFlatRate = { ...placeholder.state };
		const {
			name,
			maxPrice,
			maxTime,
		} = newMaxFlatRate || {};

		if (typeof name === 'undefined'
            || typeof maxPrice === 'undefined'
            || typeof maxTime === 'undefined') {
			return;
		}
		//placeholder
		addRate(newMaxFlatRate);
		//local
		const currArr = !!maxFlatRates ? [...maxFlatRates] : [];
		currArr.push(newMaxFlatRate);
		setMaxFlatRates(currArr);
	};

	const removeRateHandler = (rate) => {

		const result = maxFlatRates.find((element) => {
			return element.name === rate.name;
		});

		const indexToRemove = maxFlatRates.indexOf(result);

		//placeholder
		removeRate(rate);

		//local
		const currArr = maxFlatRates;
		if (indexToRemove > -1) {
			currArr.splice(indexToRemove, 1);
		}
		setMaxFlatRates(currArr);
	};


	return (
		<MaxFlatRatesList
			{...props}
			maxFlatRates={maxFlatRates}
			placeholder={placeholder.state}
			placeholderSetters={placeholderSetters}
			addRateHandler={addRateHandler}
			removeRateHandler={removeRateHandler}
		/>
	);
};

export default RatesListWrapper;