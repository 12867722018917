
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_REQUEST_IS_MALFORMED,
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import pricingPlanMappers from './mappers/pricingPlanMappers';
import { dateFormatter } from '../../../services/formatter';
import { publishAndAwait } from '../../../app/coreSlice';

export const createPricingPlan = async (pricingPlan) => {

	const {
		name,
		startDate,
		endDate,
		startingFee,
		category,
		specific,
		rates,
		maxPrices,
	} = pricingPlan || {};

	if (typeof name === 'undefined'
		|| typeof startDate === 'undefined'
		|| typeof endDate === 'undefined'
		|| typeof startingFee === 'undefined'
		|| typeof category === 'undefined'
		|| typeof specific === 'undefined'
		|| typeof maxPrices === 'undefined'
		|| typeof rates === 'undefined') {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	};

	const dto = pricingPlanMappers.toDTO(pricingPlan);

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'PRICING',
			subType: 'CREATE',
			message: {
				...dto,
				startDate: dateFormatter.toJSON(dto.startDate, 'dateTimeRequest'),
				endDate: dateFormatter.toJSON(dto.endDate, 'dateTimeRequest'),
			},
		},
	});

	const { processMessage, error } = request.response;

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	return processMessage || null;
};

export default createPricingPlan;