import React from 'react';
import DatePicker from '../../Reservations/ReservationDatePicker/ReservationDatePicker.jsx';
import MultiFilterButton from '../../FilterButton/MultiFilterButton';
import { incidentTypeButton } from './incidentTypeButton';
import { incidentStatusButton } from './incidentStatusButton';
import {
	InputBase,
	Select,
	withStyles,
} from '@material-ui/core';
import {
	Search as SearchIcon,
} from '@material-ui/icons';
import { ReactComponent as CreateIcon } from '../../../assets/PromotionApp/icons/add.svg';
import withPermission from '../../../hoc/withPermission';
import styles from './IncidentsFilter.styles';
import classnames from 'classnames';

const label = 'Search...';

const IncidentsFilter = ({
	activeFilter,
	anchorEl,
	classes,
	closeSelectFilter,
	filter,
	onChange,
	onSearch,
	onSingleClick,
	openSelectFilter,
	searchByParams,
	startCreate,
	incidentTypes,
}) => {
	const selectSearch = (
		<Select
			native
			onChange={e => onChange(e.target.value, 'searchType')}
			displayEmpty
			className={classes.selectEmpty}
			value={filter.searchType}
		>
			{searchByParams.map(param => (
				<option value={param.value} key={param.value}>
					{param.label}
				</option>
			))}
		</Select>
	);

	const stringSearch = (
		<InputBase
			placeholder={label}
			value={filter.string}
			classes={{
				root: classnames(classes.inputRoot, classes.string),
				input: classes.inputInput,
			}}
			onChange={event => onChange(event.target.value, 'string')}
			onKeyDown={event => {
				if (event.keyCode === 13) {
					onSearch(true);
				};
			}}
		/>
	);

	const datePickers = (
		<DatePicker
			className={classes.datePickers}
			endDate={filter.endDate}
			endDateHandler={date => onSingleClick(() => onChange(date, 'endDate'))}
			endDateLabel='End Date'
			startDate={filter.startDate}
			startDateHandler={date => onSingleClick(() => onChange(date, 'startDate'))}
			startDateLabel='Start Date'
		/>
	);

	const incidentTypeSearch = (
		<MultiFilterButton
			key={incidentTypeButton(incidentTypes).text}
			button={incidentTypeButton(incidentTypes)}
			open={incidentTypeButton(incidentTypes)?.text === activeFilter}
			anchorEl={anchorEl}
			handleOpen={(event, text) => onSingleClick(() => openSelectFilter(event, text))}
			handleClose={() => onSingleClick(closeSelectFilter)}
			selected={filter.incidentType}
			onSelect={selectedFilter => onChange(selectedFilter.filter, 'incidentType')}
		/>
	);

	const incidentStatusSearch = (
		<MultiFilterButton
			key={incidentStatusButton.text}
			button={incidentStatusButton}
			open={incidentStatusButton.text === activeFilter}
			anchorEl={anchorEl}
			handleOpen={(event, text) => onSingleClick(() => openSelectFilter(event, text))}
			handleClose={() => onSingleClick(closeSelectFilter)}
			selected={filter.status}
			onSelect={selectedFilter => onChange(selectedFilter.filter, 'status')}
		/>
	);

	const searchIcon = (
		<SearchIcon
			onClick={() => onSingleClick(() => onSearch(true))}
			className={classnames(classes.icon)}
		/>
	);

	const createButton = (
		<AddPermission>
			<CreateIcon
				onClick={() => {
					startCreate();
				}}
				className={classes.createIcon}
			/>
		</AddPermission>
	);

	const searchMenu = (
		<div className={classes.root}>
			<div className={classes.filters}>
				{selectSearch}
				{stringSearch}
				{datePickers}
				{incidentTypeSearch}
				{incidentStatusSearch}
				{searchIcon}
			</div>
			<div className={classes.createButtonDiv}>
				{createButton}
			</div>
		</div>
	);

	return searchMenu;
};

const AddPermission = withPermission([
	{ resource: ['incidents', 'create'] },
])(({
	children,
}) =>
	(
		<>
			{children}
		</>
	)
);

export default withStyles(styles)(IncidentsFilter);