// FIXME Use the new AssetType.js
export const getAssetTypeId = assetType => ({
	KICKSCOOTER: '36c2f9d7-15d6-4500-9d67-fff93cb3d73b',
	SCOOTER: 'c95fc7e4-6f5c-4898-88f0-6419edad260a',
	CAR: 'a1a98142-21e0-4b4e-80e7-320e15653284',
	BABY: '22222222-2222-2222-2222-222222222222',
	MAINTENANCE: '22222222-1111-2222-1111-222222222222',
	CRITICALFUEL: '22222222-3333-2222-3333-222222222222',
}[assetType] || undefined);

export const assetWithDoors = [getAssetTypeId('CAR'), getAssetTypeId('BABY'), getAssetTypeId('KICKSCOOTER'), getAssetTypeId('SCOOTER')];
