import createModel from '../createModel';

function AccountInvoice({
	code,
	id,
	itemNumber,
	amount,
	tripsNumber,
	issueDate,
	dueDate,
	status,
	billingItems=[],
} = {}) {
	createModel.call(this, {
		code,
		id,
		itemNumber,
		amount,
		tripsNumber,
		issueDate,
		dueDate,
		status,
		billingItems,
	});
}

export default AccountInvoice;