import * as Yup from "yup";
import { CheckboxField, SelectField, TextField } from "../../../../../common/forms";
import { getLegalDocumentTypeOptions } from "./DocumentForm.options";

export function formSchema() {
  return Yup.object().shape({
    name: Yup.string().required("Name is required"),
    type: Yup.string().required("Type is required"),
    mandatory: Yup.boolean(),
  });
}

export function formFields() {
  return [
    {
      size: 12,
      field: <TextField id="name" label="Name" />,
    },
    {
      size: 12,
      field: <SelectField id="type" label="Type" options={getLegalDocumentTypeOptions()} />,
    },
    {
      size: 12,
      field: <CheckboxField id="mandatory" label="Mandatory" />,
    },
  ];
}
