const screens = {
	GENERAL: 'General',
	LEGAL: 'Legal',
	// FLEET: 'Fleet', to be added in the future
	// HARDWARE: 'Hardware',
	TRIPS: 'Trips',
	// WARNINGS: 'Warnings', to be added in the future
	PAYMENTS: 'Payments',
	REGISTRATION: 'Registration',
	WORK_ORDERS: 'Work Orders',
	APP: 'Mobile App',
	AWS: 'File Storage',
	EXTERNAL_API: "External API"
};

export default screens;