import React, { useState, useCallback, useRef, useEffect } from 'react';
import incidentsService from '../../../services/incidents';
import assetService from '../../../services/asset';
import tripService from '../../../services/trip';
import customerService from '../../../services/customer/ws';
import s3Service from '../../../services/S3/index';
import log from '../../../utils/logger';
import IncidentsUpdate from './IncidentsUpdate';

const logger = log('IncidentUpdaterapper');

const {
	uploadImage,
	saveImage,
} = s3Service;

const initialPlaceholder = {
	description: '',
	incidentType: undefined,
	incidentCost: '',
	liabilityPercentage: '100',
	administrativeCost: '',
	attachment: [],
};

const IncidentsUpdateWrapper = ({
	incident,
	incidentsSetElements,
	onCancel,
	setSelectedIncident,
	...props
}) => {
	const [type, onTypeChange] = useState('Reservation');
	const [placeholder, setPlaceHolder] =
		useState((!!incident && { ...initialPlaceholder, ...incident }) || initialPlaceholder);
	const [hasRequest, setHasRequest] = useState(false);
	const [error, setError] = useState(false);
	const [hasAdministrativeCost, setAdministrativeCost] =
		useState(incident?.incidentCode && !!incident?.administrativeCost);

	const typeRef = useRef();

	useEffect(() => {
		typeRef?.current?.func && typeRef.current.func([]);
	}, [type]);

	const onChange = useCallback(field => {
		!!error && setError(false);

		setPlaceHolder(state => ({ ...state, ...field }));
	}, [error]);

	const removeAttachment = useCallback(index => {
		const attachment = placeholder.attachment;
		attachment.splice(index, 1);

		onChange({ attachment });
	}, [placeholder.attachment, onChange]);

	const imageHandler = useCallback(event => {
		const image = event && event.target && event.target.files && event.target.files[0];

		if (!image) {
			return;
		};

		onChange({ attachment: [...placeholder.attachment, image] });
	}, [placeholder.attachment, onChange]);

	const saveImagesURL = useCallback(async () => {
		setError(false);

		try {
			const attachmentsToSave = await Promise.all(placeholder.attachment
				.filter(attachment => typeof attachment !== 'string')
				.map(async attachment => {
					const image = await uploadImage(attachment);
					return image.keyname;
				})
			);

			const savedUrls = await (attachmentsToSave.length > 0 && saveImage(attachmentsToSave))
				|| [];

			if (savedUrls.error) {
				setError(savedUrls.error);
			}

			const attachment = [
				...placeholder.attachment.filter(attachment => typeof attachment === 'string'),
				...savedUrls];

			return attachment;

		} catch (error) {
			logger.info(error);
		}
	}, [placeholder.attachment]);

	const onSave = useCallback(async () => {
		setError(false);

		const {
			administrativeCost,
			description,
			incidentCost,
			incidentType,
			liabilityPercentage,
			carToken,
			customerCode,
			reservationCode,
		} = placeholder;

		if (!carToken && !customerCode && !reservationCode) {
			return setError('A new incident needs to be associated with an Asset, Customer or Reservation');
		}

		if (
			!incidentType
			|| ('' + incidentCost).trim().length <= 0
			|| ('' + description).trim().length <= 0
			|| ('' + liabilityPercentage).trim().length <= 0
			|| (hasAdministrativeCost && ('' + administrativeCost).trim().length <= 0)) {
			return setError(true);
		};

		setHasRequest(true);

		const attachment = await saveImagesURL();

		try {
			const incident = await incidentsService.updateIncident({
				...placeholder,
				attachment,
				administrativeCost: hasAdministrativeCost ? administrativeCost : null,
			});

			incidentsSetElements([incident]);

			!!setSelectedIncident && setSelectedIncident(incident);
			onCancel();
		} catch (e) {
			setHasRequest(false);
			setError(e.description || e.message
				|| 'Could not make your request at this time, please try again later.');
		}
	}, [hasAdministrativeCost, placeholder, incidentsSetElements, onCancel, saveImagesURL, setSelectedIncident]);

	// eslint-disable-next-line
	const getList = useCallback({
		Reservation: async (filter) => {
			const { trips } = await tripService.listTrips('CLOSED', false, { ...filter });

			const mappedTrips = trips.map(
				({ licensePlate, carToken, customerCode, code }) =>
					({ key: code, label: code, customerCode, licensePlate, carToken }));
			return mappedTrips;
		},
		Vehicle: async ({ string: deviceLicensePlate }) => {
			const assets = await assetService.listAsset(deviceLicensePlate);

			const vehicles = assets.map(vehicle => ({
				key: vehicle.assetToken,
				label: vehicle.licensePlate,
				carName: vehicle.assetName,
			}));

			return vehicles;
		},
		Customer: async ({ string: name }) => {
			const { customers } = await customerService.listCustomers({ name });

			const agents = customers.map(customer => ({
				key: customer.code,
				label: customer.code,
				name: customer.fullName,
				mobile: customer.phoneNumber,
			}));

			return agents;
		},
	}[type], [type]);

	return (
		<IncidentsUpdate
			{...props}
			error={error}
			getList={getList}
			hasAdministrativeCost={hasAdministrativeCost}
			hasRequest={hasRequest}
			imageHandler={imageHandler}
			onCancel={onCancel}
			onChange={onChange}
			onSave={onSave}
			onTypeChange={onTypeChange}
			placeholder={placeholder}
			removeAttachment={removeAttachment}
			setAdministrativeCost={setAdministrativeCost}
			type={type}
			typeRef={typeRef}
		/>
	);
};

export default IncidentsUpdateWrapper;