import React from 'react';
import { Typography } from '@material-ui/core';
import classnames from 'classnames';

const status = (classes, setStatus, selected) =>
	({
		label,
		key,
		subType,
		length,
		color,
		hideLength,
	}) =>
		(
			<Typography
				key={label}
				className={classnames(classes.filterText,
					selected === key && classes.selectedFilterText)}
				onClick={() => setStatus({ key, subType })}
				display='inline'
			>
				{label} {!hideLength && currentOrders(length, color)}
			</Typography>
		);

const currentOrders = (length, color) => <span>( <span style={{ color }}>{length}</span> )</span>;

export default status;