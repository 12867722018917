const styles = theme => ({
	root: {
		backgroundColor: '#fff',
		bottom: '10px',
		position: 'absolute',
		height: '55vh',
		left: '47px',
		width: '30vw',
		cursor: 'default',
		boxShadow: theme.shadows[7],
		display: 'flex',
		borderRadius: '5px',
	},

	hidden: {
		display: 'none',
	},

	left: {
		left: '-30.1vw',
	},
});

export default styles;