export const styles = theme => ({

	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		width: '100%',
		height: '100%',
		minHeight: 250,
		flexBasis: '45%',
	},

	title: {
		boxSizing: 'border-box',
		margin: '0 20px',
		fontSize: 14,
		textAlign: 'left',
		width: '100%',
		borderBottom: '2px solid #009BCC',
	},

	field: {
		paddingBottom: 6, 
	},

	error: {
		border: "2px solid red",
	},

	content: {
		display: 'flex',
		flexDirection: 'column',
		margin: '10px 20px',
		justifyContent: 'space-evenly',   
	},

});