export const WS_REQUEST_IS_MALFORMED = 'WS_REQUEST_IS_MALFORMED'; 	
export const WS_PROCESS_MESSAGE_IS_MALFORMED = 'WS_PROCESS_MESSAGE_IS_MALFORMED'; 	
export const WS_CONNECTION_ERROR = 'WS_CONNECTION_ERROR';
export const WS_AUTHENTICATION_ERROR = 'WS_AUTHENTICATION_ERROR';

const error = type => ({

	[WS_REQUEST_IS_MALFORMED]: {
		description: 'Request is malformed',
		code: '300',
		tag: WS_REQUEST_IS_MALFORMED,
	},

	[WS_PROCESS_MESSAGE_IS_MALFORMED]: {
		description: 'Process message is malformed',
		code: '400',
		tag: WS_PROCESS_MESSAGE_IS_MALFORMED,
	},

	[WS_CONNECTION_ERROR]: {
		description: 'Cannot connect',
		code: '500',
		tag: WS_CONNECTION_ERROR,
	},

	[WS_AUTHENTICATION_ERROR]: {
		description: 'Cannot authenticate',
		code: '401',
		tag: WS_AUTHENTICATION_ERROR,
	},

}[type]);

export default error;


