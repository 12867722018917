
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import rateMapper from './mappers/rateMapper';
import { publishAndAwait } from '../../../app/coreSlice';

export const listRates = async ({filter}) => {


	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'RATE',
			subType: 'LIST',
			message: {
				name: filter,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (error) {
		return;
	}

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}

	const modelArray = !!processMessage && Array.isArray(processMessage.rates)
		&& processMessage.rates.map( rateDTO => rateMapper.fromDTO(rateDTO));

	return modelArray;
};

export default listRates;