import { createSlice } from "@reduxjs/toolkit";
import assetList_reportCall from "./_sliceCalls/assetList_reportCall";
import reportBatterySwapsCall from "./_sliceCalls/reportBatterySwapsCall";

export const reportsSlice = createSlice({
  name: "reports",
  initialState: {
    reportAssets: [],
    noDataModal: false,
    reportGeneratedModal: false,
  },
  reducers: {
    setReportAssets: (state, action) => {
      state.reportAssets = action.payload;
    },
    setNoDataModal: (state, action) => {
      state.noDataModal = action.payload;
    },
    setReportGeneratedModal: (state, action) => {
      state.reportGeneratedModal = action.payload;
    },
  },
});

export const { setNoDataModal, setReportGeneratedModal } = reportsSlice.actions;
export default reportsSlice.reducer;

// SELECTS
export const selectIsLoading = (state) => Boolean(state.getIn(["core"])?.loader?.length > 0);
export const selectReportAssets = (state) => state.getIn(["reports"])?.reportAssets;
export const selectNoDataModal = (state) => state.getIn(["reports"])?.noDataModal;
export const selectReportGeneratedModal = (state) => state.getIn(["reports"])?.reportGeneratedModal;

// CALLS
export const assetList_report = () => (dispatch, getState) => {
  assetList_reportCall(reportsSlice.actions, dispatch, getState());
};

export const reportBatterySwaps = ({ startDate, endDate, assets }) => (dispatch, getState) => {
  reportBatterySwapsCall(reportsSlice.actions, dispatch, getState(), { startDate, endDate, assets });
};

