import makeActionCreator from '../../utils/makeActionCreator';
import createReducer from '../../hooks/createCustomReducer';
import moment from 'moment';

//TODO: Refactor to use other hooks
const types = {
	RESET_PLACEHOLDER: 'RESET_PLACEHOLDER',
	SET_CATEGORY: 'SET_CATEGORY',
	SET_CODE: 'SET_CODE',
	SET_DESCRIPTION: 'SET_DESCRIPTION',
	SET_END_DATE: 'SET_END_DATE',
	SET_END_ZONE: 'SET_END_ZONE',
	SET_MAX_PRICES: 'SET_MAX_PRICES',
	SET_NAME: 'SET_SELECTED_PRICING_PLAN',
	SET_RATES: 'SET_RATES',
	SET_SPECIFIC: 'SET_SPECIFIC',
	SET_START_DATE: 'SET_START_DATE',
	SET_START_ZONE: 'SET_START_ZONE',
	SET_STARTING_FEE: 'SET_STARTING_FEE',
	SET_PLACEHOLDER: 'SET_PLACEHOLDER',
};

export const resetPlaceholder = makeActionCreator(types.RESET_PLACEHOLDER);
export const setCategory = makeActionCreator(types.SET_CATEGORY, 'category');
export const setCode = makeActionCreator(types.SET_CODE, 'id');
export const setDescription = makeActionCreator(types.SET_DESCRIPTION, 'description');
export const setEndDate = makeActionCreator(types.SET_END_DATE, 'endDate');
export const setEndZone = makeActionCreator(types.SET_END_ZONE, 'zones');
export const setMaxPrices = makeActionCreator(types.SET_CALENDAR, 'maxPrices');
export const setName = makeActionCreator(types.SET_NAME, 'name');
export const setRates = makeActionCreator(types.SET_RATES, 'rates');
export const setSpecific = makeActionCreator(types.SET_SPECIFIC, 'specific');
export const setStartDate = makeActionCreator(types.SET_START_DATE, 'startDate');
export const setStartingFee = makeActionCreator(types.SET_STARTING_FEE, 'startingFee');
export const setStartZone = makeActionCreator(types.SET_START_ZONE, 'zones');
export const setPlaceholder = makeActionCreator(types.SET_PLACEHOLDER, 'pp');

const actions = dispatch => ({
	resetPlaceholder: () => dispatch(resetPlaceholder()),
	setCategory: data => dispatch(setCategory(data)),
	setCode: data => dispatch(setCode(data)),
	setDescription: data => dispatch(setDescription(data)),
	setEndDate: data => dispatch(setEndDate(data)),
	setEndZone: data => dispatch(setEndZone(data)),
	setMaxPrices: data => dispatch(setMaxPrices(data)),
	setName: data => dispatch(setName(data)),
	setRates: data => dispatch(setRates(data)),
	setSpecific: data => dispatch(setSpecific(data)),
	setStartDate: data => dispatch(setStartDate(data)),
	setStartingFee: data => dispatch(setStartingFee(data)),
	setStartZone: data => dispatch(setStartZone(data)),
	setPlaceholder: data => dispatch(setPlaceholder(data)),
});

const initialStartDate = moment().subtract(1, 'day')
	.set({ hour: 0, minute: 0, seconds: 0 }).format('YYYY-MM-DD HH:mm:ss');
const initialEndDate = moment()
	.set({ hour: 23, minute: 59, seconds: 59 }).format('YYYY-MM-DD HH:mm:ss');

export const defaultState = {
	id: undefined,
	name: undefined,
	description: undefined,
	startDate: initialStartDate,
	endDate: initialEndDate,
	startingFee: undefined,
	specific: false,
	category: undefined,
	rates: [
		null, null, null, null, null, null, null,
		null, null, null, null, null, null, null,
		null, null, null, null, null, null, null,
		null, null, null, null, null, null, null,
		null, null, null, null, null, null, null,
		null, null, null, null, null, null, null,
		null, null, null, null, null, null, null,
		null, null, null, null, null, null, null,
		null, null, null, null, null, null, null,
		null, null, null, null, null, null, null,
		null, null, null, null, null, null, null,
		null, null, null, null, null, null, null,
		null, null, null, null, null, null, null, 
		null, null, null, null, null, null, null,
		null, null, null, null, null, null, null,
		null, null, null, null, null, null, null,
		null, null, null, null, null, null, null,
		null, null, null, null, null, null, null,
		null, null, null, null, null, null, null,
		null, null, null, null, null, null, null,
		null, null, null, null, null, null, null,
		null, null, null, null, null, null, null,
		null, null, null, null, null, null, null,
		null, null, null, null, null, null, null,
	],
	maxPrices: [],
	startZoneFees: [],
	endZoneFees: [],
};

const setNameReducer = (state, action) => ({
	...state,
	name: action.name,
});

const setCodeReducer = (state, action) => ({
	...state,
	id: action.id,
});

const setDescriptionReducer = (state, action) => ({
	...state,
	description: action.description,
});

const setStartingFeeReducer = (state, action) => {
	if (isNaN(Number(action.startingFee))) {
		return state;
	};

	return {
		...state,
		startingFee: action.startingFee,
	};
};

const setCategoryReducer = (state, action) => {
	if (isNaN(Number(action.category))) {
		return state;
	};

	return {
		...state,
		category: action.category,
	};
};

const setSpecificReducer = (state, action) => ({
	...state,
	specific: action.specific,
});

const setStartDateReducer = (state, action) => ({
	...state,
	startDate: action.startDate,
});

const setEndDateReducer = (state, action) => ({
	...state,
	endDate: action.endDate,
});

const setMaxPricesReducer = (state, action) => ({
	...state,
	maxPrices: action.calendar,
});

const setRatesReducer = (state, action) => ({
	...state,
	rates: action.rates,
});

const setEndZoneReducer = (state, action) => ({
	...state,
	endZoneFees: action.zones,
});

const setStartZoneReducer = (state, action) => ({
	...state,
	startZoneFees: action.zones,
});

const setPlaceholderReducer = (state, action) => {
	const { 
		id,
		name,
		description,
		startDate,
		endDate,
		startingFee,
		specific,
		category,
		startZoneFees,
		endZoneFees,
	 } = action?.pp || {};

	return {
		id,
		name,
		description,
		startDate,
		endDate,
		startingFee,
		specific,
		category,
		startZoneFees: startZoneFees || [],
		endZoneFees: endZoneFees || [],
	}; 
};

const resetPlaceholderReducer = () => defaultState;

export const reducer = (state, action) => ({
	[types.RESET_PLACEHOLDER]: resetPlaceholderReducer,
	[types.SET_CATEGORY]: setCategoryReducer,
	[types.SET_CODE]: setCodeReducer,
	[types.SET_DESCRIPTION]: setDescriptionReducer,
	[types.SET_END_DATE]: setEndDateReducer,
	[types.SET_END_ZONE]: setEndZoneReducer,		
	[types.SET_MAX_PRICES]: setMaxPricesReducer,
	[types.SET_NAME]: setNameReducer,
	[types.SET_RATES]: setRatesReducer,
	[types.SET_SPECIFIC]: setSpecificReducer,
	[types.SET_START_DATE]: setStartDateReducer,
	[types.SET_START_ZONE]: setStartZoneReducer,	
	[types.SET_STARTING_FEE]: setStartingFeeReducer,
	[types.SET_PLACEHOLDER]: setPlaceholderReducer,
}[action.type](state, action));

export default createReducer({ reducer, actions, defaultState });