export const styles = theme => ({
	root: {
		padding: '5px 0 0',
		display: 'flex',
		borderRadius: '2px',
		maxWidth: '300px',
		minWidth: '300px',
	},

	picker: {
		margin: 'auto 5px',
		maxHeight: '50px',
		maxWidth: '170px',
	},

	pickerInput: {
		padding: 8,
	},

	outlined: {
		transform: 'translate(14px, 10px) scale(1)',
	},
});

export default styles;