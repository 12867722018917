const processError = (error) => {
	if (error.response) {
		if (error.response.data) {
			if (error.response.data.message) {
				return error.response.data.message.toString();
			}
      
			return error.response.data.toString();
      
		}
    
		return error.response.toString();
    
	}
	return error.toString();
};

const remoteConfigsReducer = (state = {
	loading: false,
	getConfigsResult: null,
	getConfigsError: null,
	createConfigResult: null,
	createConfigError: null,
}, action) => {
	switch (action.type) {
		case "RC_GETCONFIGS":
			state = {
				...state,
				loading: true,
				getConfigsError: null,
				getConfigsResult: null,
			};
			break;
		case "RC_GETCONFIGS_SUCCESS":
			state = {
				...state,
				loading: false,
				getConfigsResult: action.payload,
			};
			break;
		case "RC_GETCONFIGS_FAILURE":
			state = {
				...state,
				loading: false,
				getConfigsError: processError(action.payload),
			};
			break;
		case "RC_CREATECONFIG":
			state = {
				...state,
				loading: true,
				createConfigError: null,
				createConfigResult: null,
			};
			break;
		case "RC_CREATECONFIG_SUCCESS":
			state = {
				...state,
				loading: false,
				createConfigResult: action.payload,
			};
			break;
		case "RC_CREATECONFIG_FAILURE":
			state = {
				...state,
				loading: false,
				createConfigError: processError(action.payload),
			};
			break;
		default:
			state = {
				...state,
			};
			break;
	}
	return state;
};

export default remoteConfigsReducer;
