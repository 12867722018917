import { wsCall, requestHelper, setError } from "../../../app/coreSlice";

const billingSetCall = async (
  { billingToBill },
  dispatch,
  state,
  { reservationCode, activeTime, idleTime, distanceTravelled, totalCost }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;

  let rh = requestHelper(dispatch, "BILLING/SET");
  try {
    const result = await wsCall({
      type: "BILLING",
      subType: "SET",
      locationId: selectedLocation?.id,
      message: {
        reservationCode,
        activeTime: activeTime ? activeTime.toString() : "0",
        idleTime: idleTime ? idleTime.toString() : "0",
        distanceTravelled: distanceTravelled ? distanceTravelled.toString() : "0",
        totalCost: totalCost ? totalCost.toString() : "0",
      },
    });
    if (result && !result.error) {
      dispatch(billingToBill({ reservationCode }));
    } else {
      setError(result?.description || "Error confirming data for new billing.");
    }
  } catch (_ex) {
    rh.error("Error confirming data for new billing.");
  }
  rh.close();
};

export default billingSetCall;
