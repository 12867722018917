
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import { publishAndAwait } from '../../../app/coreSlice';

const getRoles = async filter => {

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'ROLE',
			subType: 'LIST',
			message: {
				filter,
				limit: 10000
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}

	const roles = processMessage.roles.reduce(listReducer, {});

	return [roles, processMessage.lastPage];
};

export default getRoles;

const listReducer = (acc, role) => ({...acc, [role.id]: role});