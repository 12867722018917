export const getLegalDocumentTypeOptions = (addEmpty) => {
  let options = ["Terms and Conditions", "Privacy Policy", "Consent 1", "Consent 2"].map((item) => {
    return {
      value: item,
      text: item,
    };
  });

  if (addEmpty) {
    options.push({ value: "ALL", text: "All" });
  }
  return options;
};
