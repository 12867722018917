import React, {
	useEffect,
	useState,
} from 'react';
import tripService from '../../../../services/trip/index';
import withPermission from '../../../../hoc/withPermission';
import TimelineInfo from './TimelineInfo';

import log from '../../../../utils/logger';

const logger = log('TimelineInfoWrapper');

const TimelineInfoWrapper = props => {

	const [timelineInfo, setTimelineInfo] = useState({});
	const [errorMessage, setErrorMessage] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [modal, setModal] = useState(false);

	const {
		trip,
		tripType,
	} = props || {};

	useEffect(() => {

		const getTimelineInfo = async (code, tripType) => {

			setErrorMessage(false);

			if (!code) {
				setTimelineInfo({});
				return;
			};
			try {
				const timelineDetails = await tripService.getTimelineDetails(code, tripType);
				setTimelineInfo(timelineDetails || {});
			} catch (error) {
				setTimelineInfo({});
				setErrorMessage(`Unable to retrieve ${trip.code || '(code missing)'} timeline information from server.`);
				setShowAlert(true);
				logger.info(error.message || error);
			};
		};


		getTimelineInfo(trip.code, tripType);

	}, [trip, tripType]);


	return (
		<TimelineInfo
			{...props}
			clearErrorMessage={() => {
				setErrorMessage(false);
				setTimeout(() => {
					setShowAlert(false);
				}, 2000);
			}}
			errorMessage={errorMessage}
			modal={modal}
			setModal={setModal}
			showAlert={showAlert}
			timelineInfo={timelineInfo}
		/>
	);
};

export default withPermission(
	[{ resource: ['reservations', 'immediate', 'read'] }],
)(TimelineInfoWrapper);