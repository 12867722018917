const styles = () => ({
  
	root: {
		zIndex: 0,
		
		width: '40px',
		height: '40px',
	},

	hover: {
		cursor: 'pointer',
	},

	centered: {
		position: 'absolute',
		transform: 'translate(-50%, -50%)',
	},

	pin: {
		borderRadius: '50% 50% 50% 0',
		transform: 'rotateZ(-45deg)',
		boxShadow: '-1px 1px 4px rgba(0, 0, 0, .5)',
		margin: '-2.2em 0 0 -1.3em',
		width: '30px',
		height: '30px',
	},

	unrotate: {
		transform: 'rotateZ(45deg)',
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%',
	},

	icon: {
		color: '#ffffff',
	},
});

export default styles;