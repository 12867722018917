const styles = theme => ({

	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},

	title: {
		fontSize: '16px',
		minWidth: '110px',
		alignSelf: 'center',
	},

	subtitle: {
		fontSize: '12px',
		minWidth: '110px',
	},

	filterText: {
		fontSize: '12px',
		cursor: 'pointer',
		borderBottom: '2px solid rgba(1, 151, 198, 0.30)',
		margin: '0 8px',
		minWidth: '100px',
		textAlign: 'center',
	},
	
	selectedFilterText: {
		borderBottom: '2px solid rgba(1, 151, 198, 0.75)',
		fontWeight: 'bold',
	},

	filterBar: {
		display: 'flex',
		width: '100%',
	},

	filters: {
		display: 'flex',
		minWidth: '70%',
		maxWidth: '100%',
	},

	createButtonDiv: {
		display: 'flex',
		flexGrow: 1,
		flexDirection: 'row-reverse',
		alignItems: 'center',
	},

	createIcon: {
		cursor: 'pointer',
		color: '#009AC9',
		height: '26.5px',
		width: 'auto',
	},

	icon: {
		marginTop: theme.spacing(2),
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1),
		marginLeft: theme.spacing(1),
		cursor: 'pointer',
	},

	datePickers: {
		marginTop: theme.spacing(0.60),
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},

	searchIconShadow: {
		color: '#009AC9',
	},

	inputRoot: {
		color: 'inherit',
		width: '100%',
	},

	inputInput: {
		marginTop: theme.spacing(2),
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1),
		marginLeft: theme.spacing(1),
		transition: theme.transitions.create('width'),
		width: '100%',
		borderBottom: '1px solid gray',
	},

	selectEmpty: {
		marginTop: theme.spacing(2),
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1),
		marginLeft: theme.spacing(1),
		minWidth: '160px',
	},

	string: {
		minWidth: '150px',
	},

	typeFilters: {
		display: 'flex',
		width: '100%',
		padding: '10px 0',
		borderBottom: '1px solid #ccc',
	},
});

export default styles;