export const LOG_TYPES = {
	TELEMATIC: 'TELEMATIC',
	COMMAND: 'COMMAND',
	ALARM: 'ALARM',
	ACTIVATE_DEVICE: 'ACTIVATE_DEVICE',
	ACTIVATE: 'ACTIVATE',
	DEVICE: 'DEVICE',
	DISCONNECTED: 'DISCONNECTED',
	STATUS: 'STATUS',
	BATTERY: 'BATTERY',
	ENGINE: 'ENGINE',
	START: 'START',
	STOP: 'STOP',
	IMMOBILIZER: 'IMMOBILIZER',
	PARK: 'PARK',
	ON: 'ON',
	OFF: 'OFF',
	DOORS: 'DOORS',
	OPEN: 'OPEN',
	CLOSE: 'CLOSE',
	LOCKER: 'LOCKER',
	LOCK: 'LOCK',
	UNLOCK: 'UNLOCK',
};

export const getParsedTypeAndSubType = (type, subType, commType, alarmDescription) => {

	const {
		ALARM,
		ACTIVATE_DEVICE,
		ACTIVATE,
		DEVICE,
		DISCONNECTED,
		STATUS,
		BATTERY,
		ENGINE,
		START,
		IMMOBILIZER,
		PARK,
		ON,
		DOORS,
		OPEN,
		LOCKER,
		UNLOCK,
	} = LOG_TYPES;

	if (commType === ALARM) {
		switch (alarmDescription) {
			case DEVICE + ' ' + DISCONNECTED:
				return 'Disconnected ';
			case STATUS + ' ' + BATTERY:
				return 'Battery <12V';
			default:
				return alarmDescription;
		}
	}

	switch (type) {
		case ACTIVATE_DEVICE:
			return `Device ${subType === ACTIVATE ? 'Activate' : 'Deactivate'}`;
		case ENGINE:
			return subType === START ? 'Engine Starts' : 'Engine Stops';
		case PARK:
			return subType === ON ? 'Start Parking' : 'Stop Parking';
		case IMMOBILIZER:
			return subType === ON ? 'Immobilizer ON' : 'Immobilizer OFF';
		case DOORS:
			return subType === OPEN ? 'Doors Unlocked' : 'Doors Locked';
		case LOCKER:
			return subType === UNLOCK ? 'Doors Unlocked' : 'Doors Locked';
		case STATUS && subType === BATTERY:
			return 'Battery <12V';
		default:
			return `${type} ${subType}`;
	}
};

export const getParsedTypeAndSubTypeV2 = (item) => {
	if (!item?.comms) {
		return "N/A";
	}
	return getParsedTypeAndSubType(item.comms.last_command_type, item.comms.last_command_sub_type, item.comms.commType, item.comms.alarmDescription);
}