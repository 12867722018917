import { wsCall, requestHelper } from "../../../app/coreSlice";

const carInfoCall = async ({ setCarInfo }, dispatch, state) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "CAR/INFO");
  try {
    const result = await wsCall({
      type: "CAR",
      subType: "INFO",
      locationId: selectedLocation?.id,
      message: {},
    });
    if (result) {
      dispatch(setCarInfo(result));
    }
  } catch (_ex) {
    rh.error("Error getting car info.");
  }
  rh.close();
};

export default carInfoCall;
