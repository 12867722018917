import React, { useState } from "react";
import PricingPlanListScreen from "./PricingPlanListScreen";
import pricingPlanServiceV2 from "../../../services/pricingPlanV2/ws";

const PricingPlanListScreenWrapper = (props) => {
  const { showPPCalendar, setShowPPCalendar } = props;

  const [pricingPlanId, setPricingPlanId] = useState();

  const { setModal, listPP } = props;

  const onRemoveHandler = async (id) => {
    try {
      const result = await pricingPlanServiceV2.deletePricingPlan(id);
      if (!!result && !!result.id) {
        setTimeout(() => {
          listPP(1);
        }, 1000);
      }
    } catch (_ex) {}
  };

  const onCustomersOrZonesEditHandler = (event, pricingPlan, modalType) => {
    event.preventDefault();
    event.stopPropagation();

    const id = pricingPlan.id;
    setPricingPlanId(id);
    setModal(modalType);
  };

  return (
    <PricingPlanListScreen
      {...props}
      onCustomersOrZonesEdit={onCustomersOrZonesEditHandler}
      onRemoveHandler={onRemoveHandler}
      selectedPricingPlanId={pricingPlanId}
      setSelectedPricingPlanId={setPricingPlanId}
      setShowPPCalendar={setShowPPCalendar}
      showPPCalendar={showPPCalendar}
    />
  );
};

export default PricingPlanListScreenWrapper;
