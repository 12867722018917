import moment from 'moment-timezone';

class DateFormatter {

	constructor(
		{
			hourMinutes,
			hourMinutesSeconds,
			date,
			dateTime,
			dateTimeRequest,
		} = {
			hourMinutes: 'HH:mm',
			hourMinutesSeconds: 'HH:mm:ss',
			date: 'dd/MM/yyyy',
			dateTime: 'HH:mm dd/MM/yyyy',
			dateTimeRequest: 'yyyy-MM-dd HH:mm:ss',
		},
		timezoneStr,
	) {

		let timezone = timezoneStr
			|| moment.tz.guess()
			|| "Europe/London";

		this.patterns = {
			hourMinutes,
			hourMinutesSeconds,
			date,
			dateTime,
			dateTimeRequest,
		};

		this.getTimezone = () => timezone;
		this.setTimezone = (timezoneStr) => {
			timezone = timezoneStr
				|| "Etc/UTC";
		};
	}

	getLocalTime = () => {

		return moment()
			.tz(this.getTimezone());
	};

	format = (date, format) => {
		if (!date) {
			return '';
		};

		const parsedFormat = formatParser(this.patterns[format] || format);

		return moment.utc(date)
			.tz(this.getTimezone())
			.format(parsedFormat);
	};

	toJSON = (date, format) => {
		if (!date) {
			return '';
		};

		const parsedFormat = formatParser(this.patterns[format] || format);

		return moment(date)
			.tz(this.getTimezone(), true)
			.utc()
			.format(parsedFormat);;
	};

	patternToMoment = (format) => {
		return formatParser(this.patterns[format]);
	};

	getLastUpdate = (time) => {

		if (!time) {
			return 'N/A';
		};

		const difference = moment.utc().diff(time, 'minutes');

		if (difference < 0) {
			return;
		};

		if (difference < 60 && difference >= 0) {
			return `${difference} min ago`;
		};

		if (difference < 1440) { //less than a day
			return 'Today ' + this.format(time, 'HH:mm');
		}

		if (difference < 2880) { //less than two days
			return 'Yesterday ' + this.format(time, 'HH:mm');
		}

		if (difference < 10080) { //less than a week
			return this.format(time, 'EEEE HH:mm');
		}

		return this.format(time, 'HH:mm - DD/MM/YYYY');
	};
}

const formatParser = (format) => {

	const newFormat = format.replace(/y/g, 'Y')
		.replace(/d/g, 'D')
		.replace(/E/g, 'd');

	return newFormat;
};

export default DateFormatter;
