import axios from "axios";

const remoteConfigsApi = {
	post: function (host_url, host_key, method, params, callback) {
		let url = host_url + '/' + method;
		//url = "https://cors-anywhere.herokuapp.com/" + url; //Cors protection
		let options = {
			method: 'post',
			url: url,
			crossDomain: true,
			headers: {
				'Content-type': 'application/json',
				'x-api-key': host_key,
				'Access-Control-Allow-Origin': '*',
			},
		};
		if (params) {
			options.data = params;
		}
		axios(options).then(
			(response) => {
				callback(response);
			}
		).catch(
			(error) => {
				callback(null, error);
			}
		);
	},

	getRemoteConfigsLastVersion: function (host_url, host_key, app_name, app_version, carclub_code, callback) {
		this.post(
			host_url, host_key,
			'getRemoteConfigsLastVersionByCarClubId',
			{
				"app_name": app_name,
				"app_version": app_version,
				"carclub_code": carclub_code,
			},
			callback
		);
	},

	getRemoteConfigs: function (host_url, host_key, app_name, app_version, carclub_code, callback) {
		this.post(
			host_url, host_key,
			'getRemoteConfigsByCarClubId',
			{
				"app_name": app_name,
				"app_version": app_version,
				"carclub_code": carclub_code,
			},
			callback
		);
	},

	addRemoteConfig: function (host_url, host_key, app_name, app_version, carclub_code, app_config, callback) {
		this.post(
			host_url, host_key,
			'addRemoteConfigByCarClubId',
			{
				"app_name": app_name,
				"app_version": app_version,
				"app_config": app_config,
				"carclub_code": carclub_code,
			},
			callback
		);
	},
};

export default remoteConfigsApi;