import React from "react";
import { Modal as MUIModal, InputBase, Typography, withStyles } from "@material-ui/core";
import Button from "../../../../../../../components/UI/Buttons/CommonButtons";
import styles from "./NicknamesModal.styles";

const NicknamesModal = ({ classes, error, members, modal, nicknames, onChange, onClose, onSave, setModal }) => {
  const errorBar = "string" === typeof error && (
    <div className={classes.errorBar}>
      <Typography className={classes.errorText} align="center">
        {error}
      </Typography>
    </div>
  );

  const title = <Typography className={classes.title}>Change a nickname</Typography>;

  const fields = members.map(({ nickname, customerCode }) => (
    <InputBase
      className={classes.text}
      key={customerCode}
      onChange={(event) => onChange({ customerCode, nickname: event.target.value })}
      onKeyDown={(event) => {
        if (event.keyCode === 13) {
          onSave(customerCode);
        }
      }}
      placeholder={nickname || customerCode}
      value={nicknames?.[customerCode] || ""}
    />
  ));

  const nicknamesField = <div className={classes.nicknames}>{fields}</div>;

  const createButton = <Button key="confirm_button" label="Save" onClick={onClose} />;

  return (
    <MUIModal onClose={() => setModal()} open={!!modal}>
      <div className={classes.root}>
        {errorBar}
        {title}
        {nicknamesField}
        {createButton}
      </div>
    </MUIModal>
  );
};

export default withStyles(styles)(NicknamesModal);
