import mutationType from './mutationType';

const createMutator = (type, argName, ...args) => {

	if(!mutationType[type]) {
		throw new Error("Invalid mutation type.");
	}

	const argArray = [argName, ...args];

	return mutationType[type](...argArray);
};

export default createMutator;