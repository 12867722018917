import Image from "../../../../../../assets/AssetApp/createAsset/default.png";

export const styles = () => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 10,
  },

  rootOnEdit: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 10,
  },

  input2: {
    margin: 0,
    "& .MuiInput-root": {
      marginTop: 12,
    },
    "& .MuiInputLabel-shrink": {
      top: "0 !important",
    },
    "& .MuiFormLabel-root": {
      top: -10,
    },
    "& .MuiInput-input": {
      fontSize: 12,
      padding: "1px 0",
    },
  },

  field: {
    marginTop: 3,
  },

  fieldSelect: {
    maxHeight: 34,
    marginTop: 3,
  },

  input: {
    paddingTop: 0,
    marginTop: "2 !important",
    paddingBottom: 2,
  },

  inputLabel: {
    top: -7,
    marginTop: -6,
  },

  inputLabelOnEdit: {
    color: "#b1b1b1",
  },

  shrink: {
    transform: "translate(0, 13.5px) scale(0.75)",
  },

  labelAndInputCheckbox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: 2,
    alignItems: "end",
    paddingBottom: 0,
    boxSizing: "border-box",
    height: 40,
  },

  checkboxLabel: {
    fontSize: "12px",
    height: 35,
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
  },

  imageDiv: {
    width: 144,
    height: 108,
    alignSelf: "center",
  },

  imageButton: {
    opacity: 0,
    cursor: "pointer",
    transform: "translate(2px, -111px)",
    width: 144,
    height: 108,
    alignSelf: "center",
    position: "absolute",
  },

  imageBackground: {
    backgroundImage: `url(${Image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },

  image: {
    objectFit: "contain",
    maxWidth: "100%",
    maxHeight: "100%",
    width: "auto",
    height: "auto",
    pointerEvents: "none",
  },

  textDetailsBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    flexBasis: "30%",
  },

  textDetailsBlockOnEdit: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    flexBasis: "30%",
    alignSelf: "center",
  },

  imageDetailsBlock: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexBasis: "30%",
  },

  imageDetailsBlockOnEdit: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexBasis: "auto",
    alignSelf: "center",
    width: 150,
  },

  button: {
    margin: "0 0 0 4px",
    padding: "10px 0 10px 10px",
    textAlign: "center",
    scale: 0.9,
  },

  marginBabySeat: {
    marginTop: 20,
  },

  //SELECT

  alignSelect: {
    padding: 0,
    margin: 0,
    height: 34,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& .MuiInputLabel-shrink": {
      transform: "translate(0, 13.5px) scale(0.75)",
      transformOrigin: "top left",
    },
  },

  selectLabel: {
    fontSize: "12px",
    height: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },

  selectLabelPadding: {
    padding: "-1px !important",
    paddingBottom: 0,
    paddingTop: 0,
    top: "-7px",
  },

  selectEmpty: {
    marginBottom: 1,
    /*  marginLeft: theme.spacing(2),
		 marginRight: theme.spacing(1),
		 marginTop: theme.spacing(2),
		 // minWidth: '90px',
		 '& .MuiOutlinedInput-notchedOutline': {
			 borderColor: 'red'
		 }, */
    "& .MuiInputLabel-shrink": {
      transform: "translate(0, 13.5px) scale(0.75)",
      transformOrigin: "top left",
    },
  },

  // AttributesSelector

  checkbox: {
    padding: "0px",
    height: 25,
    alignItems: "end",
  },

  checkboxAttLabel: {
    fontSize: "12px",
    height: 23,
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
  },
});
