import React from "react";
import { Typography, withStyles } from "@material-ui/core";
import { Clear as RemoveIcon } from "@material-ui/icons";
import classnames from "classnames";
import styles from "./Lists.styles";
import TripAnalysisDataGrid from "../../../../features/reservation/TripAnalysisDataGrid/TripAnalysisDataGrid";

const Lists = ({ classes, data, setSelected, selected, onRemove }) => {
  const selectedList = selected && Object.keys(selected)?.[0];

  return (
    <div className={classes.root}>
      {data &&
        Object.keys(data).map((key, index) => {
          return (
            <div className={classnames(classes.list, index > 0 && classes.listBorder)} key={key}>
              <div className={classes.titleDiv}>
                <Typography className={classnames(classes.title, classes.sticky)} style={{ "--top": 0 }}>
                  {key}
                </Typography>
                <RemoveIcon className={classes.icon} onClick={() => onRemove(key)} />
              </div>
              <TripAnalysisDataGrid
                data={data?.[key]}
                onRowSelected={(row) =>
                  setSelected({
                    [key]: row?.comms
                      ? { ...row?.comms, create_date: row?.create_date, localId: row?.localId }
                      : { ...row?.heartbeat, create_date: row?.create_date, localId: row?.localId },
                  })
                }
                selectedRowId={selectedList === key ? selected[key]?.localId : null}
                listKey={key}
              />
            </div>
          );
        })}
    </div>
  );
};

export default withStyles(styles)(Lists);
