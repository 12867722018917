import * as listItemTypes from "../../UI/List/ListItem/ListItemTypes";
import * as screenSize from "../../../model/ScreenSize/ScreenSize";

const ratesFields = (currentScreenSize, fullSizeList = false) => {
  const widthSmall = 90;
  const widthBigger = !fullSizeList ? 140 : 250;
  const iconsWidth = !fullSizeList ? 0 : 110;

  const height =
    {
      [screenSize.DESKTOP]: !fullSizeList ? 18 : 35,
      [screenSize.TABLET]: !fullSizeList ? 18 : 45,
      [screenSize.ERROR]: 0,
    }[currentScreenSize] || 18;

  return [
    {
      key: "color",
      label: "",
      order: 1,
      pinned: true,
      width: 20,
      minWidth: 20,
      maxWidth: 20,
      height,
      type: listItemTypes.COMPONENT,
    },
    {
      key: "name",
      label: "Name",
      order: 3,
      minWidth: widthBigger,
      width: widthBigger,
      maxWidth: widthBigger,
      height,
      type: listItemTypes.TEXT,
    },
    {
      key: "rateNumber",
      label: "No.",
      order: 2,
      pinned: true,
      minWidth: 50,
      width: 50,
      maxWidth: 50,
      height,
      type: listItemTypes.TEXT,
    },
    {
      key: "priceInUse",
      label: "In Use",
      order: 4,
      pinned: true,
      minWidth: widthSmall,
      width: widthSmall,
      maxWidth: widthSmall,
      height,
      type: listItemTypes.TEXT,
    },
    {
      key: "priceInStandby",
      label: "Stand By",
      order: 5,
      pinned: true,
      minWidth: widthSmall,
      width: widthSmall,
      maxWidth: widthSmall,
      height,
      type: listItemTypes.TEXT,
    },
    {
      key: "priceKm",
      label: "Km",
      order: 6,
      pinned: true,
      minWidth: widthSmall,
      width: widthSmall,
      maxWidth: widthSmall,
      height,
      type: listItemTypes.TEXT,
    },
    {
      key: "goldPinInUse",
      label: "GP In Use",
      order: 7,
      pinned: false,
      minWidth: widthSmall,
      width: widthSmall,
      maxWidth: widthSmall,
      height,
      type: listItemTypes.TEXT,
    },
    {
      key: "goldPinInStandby",
      label: "GP Stand By",
      order: 8,
      pinned: false,
      minWidth: widthSmall,
      width: widthSmall,
      maxWidth: widthSmall,
      height,
      type: listItemTypes.TEXT,
    },
    {
      key: "editIcons",
      label: !!fullSizeList ? "Actions" : "",
      order: 99,
      pinned: true,
      minWidth: iconsWidth,
      width: iconsWidth,
      maxWidth: iconsWidth,
      height,
      type: listItemTypes.COMPONENT,
      flexDirection: "row-reverse",
    },
  ];
};

export default ratesFields;
