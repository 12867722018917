import {
	ACCOUNT_SET_ELEMENTS,
	ACCOUNT_SET_DETAILS,
	ACCOUNT_CLEAR_ELEMENTS,
	ACCOUNT_SET_LAST_PAGE,
} from './actionTypes';
import makeActionCreator from '../../../utils/makeActionCreator';
import * as methods from '../../../utils/makeActionCreator';
import accountService from '../../../services/account/ws';
import log from '../../../utils/logger';

const logger = log('AccountAction');

export const accountSetElements = makeActionCreator(ACCOUNT_SET_ELEMENTS, 'elements');
export const accountClearElements = () => ({ type: ACCOUNT_CLEAR_ELEMENTS, method: methods.REPLACE });
export const accountSetDetails = makeActionCreator(ACCOUNT_SET_DETAILS, 'account');
export const accountSetLastPage = makeActionCreator(ACCOUNT_SET_LAST_PAGE, 'lastPage');

export const listAccounts = filters => {

	return async dispatch => {

		try {

			const {
				accounts,
				lastPage,
			} = await accountService.listAccounts(filters);

			filters && filters.page === 1
				&& dispatch(accountClearElements());

			dispatch(accountSetLastPage(lastPage));
			dispatch(accountSetElements(accounts));

		} catch (error) {
			logger.info(error);
			return;
		}
	};
};

export const getAccount = account => {

	return async dispatch => {

		try {

			const accountWithDetails = await accountService.getAccount(account);

			dispatch(accountSetDetails(accountWithDetails));

		} catch (error) {
			logger.info(error);
			return;
		}
	};
};

export const removeAccount = (accountId) => {

	return async dispatch => {

		try {

			const account = await accountService.removeAccount(accountId);
			dispatch(accountSetDetails(account));

		} catch (error) {
			logger.info(error);
			return;
		}
	};
};

export const createAccount = (account) => {

	return async dispatch => {

		try {

			const createdAccount = await accountService.createAccount(account);
			dispatch(accountSetDetails(createdAccount));

		} catch (error) {
			logger.info(error);
			return;
		}
	};
};

export const updateAccount = (account) => {

	return async dispatch => {

		try {

			const editedAccount = await accountService.updateAccount(account);
			dispatch(accountSetDetails(editedAccount));

		} catch (error) {
			logger.info(error);
			return;
		}
	};
};

export const enableAccount = account => {

	return async dispatch => {

		try {

			const editedAccount = await accountService.enableAccount(account);
			dispatch(accountSetDetails(editedAccount));

		} catch (error) {
			logger.info(error);
			return;
		}
	};
};

export const getAccountDetails = (code) => {

	return async dispatch => {

		try {

			const account = await accountService.getAccount(code);
			dispatch(accountSetDetails(account));

		} catch (error) {
			logger.info(error);
			return;
		}
	};
};
