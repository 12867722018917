import moment from 'moment';
// import { ceilToMinutes } from "../components/Reservations/EditReservation/calcDuration";

// TODO All these functions should be part of either DateFormatter or DateParser services.

export const dateToString = (date) => {
	const currentDate = date || new Date();
	const currentDateJSON = currentDate.toJSON();
	return currentDateJSON.slice(0, -5).replace("T", " ");
};

export const datePlus = (shift, date) => {

	const { days } = shift;

	const myDate = date ? new Date(date) : new Date();
	myDate.setDate(myDate.getDate() + days);

	return myDate;
};

export const dateFromStrings = (date, dateStr, timeStr) => {

	const newDate = date ? new Date(date) : new Date();

	if(dateStr) {

		const tempDate = new Date(dateStr);
		
		newDate.setFullYear(tempDate.getFullYear());
		newDate.setMonth(tempDate.getMonth());
		newDate.setDate(tempDate.getDate());
	}

	if(!timeStr) {
		return newDate;
	}

	const parts = timeStr.match(/(\d+).(\d+)/);

	const hours = parseInt(parts[1]);
	const minutes = parseInt(parts[2]);

	newDate.setHours(hours);
	newDate.setMinutes(minutes);
	newDate.setSeconds(0);

	return newDate;
};

export const millisecondsToTripDurationFormattedTime = (timeInMillis, 
	ignoreHours=false, ignoreMinutes=false, ignoreSeconds=false) => {
	// This function will display time in 10h30m format omiting hours if hours == 0 (30m)
	if (!timeInMillis) {
		return '-';
	}
	if (timeInMillis < 0) {
		timeInMillis = 0;
	}

	let hoursStr = '';
	let minutesStr = '';
	let secondsStr = '';

	let time = ceilToMinutes(timeInMillis) * 60000;

	const millis = Math.round(time % 1000);
	time = Math.round((time - millis) / 1000);
	const seconds = Math.round(time % 60);
	time = Math.round((time - seconds) / 60);
	const minutes = Math.round(time % 60);
	const hours = Math.round((time - minutes) / 60);

	if (hours !== 0 && !ignoreHours) {
		hoursStr = hours + 'h';
	}

	if (minutes !== 0 && !ignoreMinutes) {
		minutesStr = minutes + 'm';
	}

	if (seconds !== 0 && !ignoreSeconds) {
		secondsStr = seconds + 's';
	}

	return hoursStr + minutesStr + secondsStr;
};


const ceilToMinutes = (millis) => {

	const timeDuration = moment.duration(millis);
	const seconds = timeDuration.seconds();
	const milliseconds = timeDuration.milliseconds();

	let minutes = Math.floor(timeDuration.asMinutes());

	if (seconds > 0 || milliseconds > 0) {
		minutes++;
	}

	return minutes;
};