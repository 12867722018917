import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const billingSimulateAdvancedCall = async (
  { setSelectedTripReviseSimulation },
  dispatch,
  state,
  { reservationCode, activeTime, idleTime, distanceTravelled, carToken }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;

  let rh = requestHelper(dispatch, "BILLING/SIMULATE_ADVANCED");
  try {
    const result = await wsCall({
      type: "BILLING",
      subType: "SIMULATE_ADVANCED",
      locationId: selectedLocation?.id,
      message: {
        reservationCode,
        activeTime,
        idleTime,
        distanceTravelled,
        carToken,
      },
    });
    if (result) {
      if (result) {
        dispatch(setSuccess("Simulation calculated successfuly!"));
        dispatch(setSelectedTripReviseSimulation(result));
      }
    }
  } catch (_ex) {
    rh.error("Error creating simulation.");
  }
  rh.close();
};

export default billingSimulateAdvancedCall;
