export const filterHeartBeats = (heartbeats, threshold = 0.05) => {
  let hbs = [];
  let hbControl;
  if (heartbeats) {
    (heartbeats?.list || [])
      .filter(
        (item) =>
          item.heartbeat?.latitude &&
          item.heartbeat?.longitude &&
          item.heartbeat?.latitude !== 0 &&
          item.heartbeat?.longitude !== 0 &&
          item.heartbeat?.position_date
      )
      .sort((a, b) => (a.heartbeat?.position_date < b.heartbeat?.position_date ? -1 : 1))
      .forEach((element) => {
        if (!hbs.find((hb) => hb.lat === element.heartbeat?.latitude && hb.lng === element.heartbeat?.longitude)) {
          let distance = 1000;
          if (!hbControl) {
            hbControl = { lat: element.heartbeat?.latitude, lng: element.heartbeat?.longitude };
          } else {
            distance = getDistance(hbControl, {
              lat: element.heartbeat?.latitude,
              lng: element.heartbeat?.longitude,
            });
          }
          if (distance > threshold) {
            // 50 meters
            hbControl = { lat: element.heartbeat?.latitude, lng: element.heartbeat?.longitude };
            hbs.push({
              lat: element.heartbeat?.latitude,
              lng: element.heartbeat?.longitude,
              date: element.heartbeat?.position_date,
            });
          }
        }
      });
  }
  return hbs;
};

export const getDistance = (a, b) => {
  if (a.lat === b.lat && a.lng === b.lng) {
    return 0;
  } else {
    let radlat1 = (Math.PI * a.lat) / 180;
    let radlat2 = (Math.PI * b.lat) / 180;
    let theta = a.lng - b.lng;
    let radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515 * 1.609344;
    return dist;
  }
};

export const getCenter = (points) => {
  if (!points || points.length === 0) {
    return null;
  }
  let x = points.map((xy) => xy.lat);
  let y = points.map((xy) => xy.lng);
  let cx = (Math.min(...x) + Math.max(...x)) / 2;
  let cy = (Math.min(...y) + Math.max(...y)) / 2;
  let r = { lat: cx || 0, lng: cy || 0 };
  return r;
};
