export const styles = theme => ({

	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		padding: '6px 0px',
	},
	
	fieldContainer: {
		width: 'inherit',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: '2px 0px',
	},

	textFieldTitle: {
		borderBottom: '2px solid rgba(0, 0, 0, 0.20)',
		fontSize: '15px',
		width: 'inherit',
	},
    
	textField: {
		padding: '0px 2px',
		fontSize: '11px',
	},

	noPadding: {
		padding: 0,
	},
});