import { createSlice } from "@reduxjs/toolkit";
import promotionCancelCall from "./_sliceCalls/promotionCancel";
import promotionCreateCall from "./_sliceCalls/promotionCreate";
import promotionListCall from "./_sliceCalls/promotionList";

export const promotionsSlice = createSlice({
  name: "promotions",
  initialState: {
    promotions: {
      list: [],
      lastPage: false,
      page: 1,
    },
  },
  reducers: {
    setPromotions: (state, action) => {
      let promotionsList = [...state.promotions.list, ...action.payload.promos];
      promotionsList.sort((a, b) => b.startDate - a.startDate);
      state.promotions.list = promotionsList;
      state.promotions.lastPage = action.payload.lastPage;
      state.promotions.page = action.payload.page;
    },
    clearPromotions: (state) => {
      state.promotions.list = [];
      state.promotions.lastPage = false;
      state.promotions.page = 1;
    },
  },
});

export default promotionsSlice.reducer;

export const { setPromotions } = promotionsSlice.actions;

// SELECTS
export const selectPromotions = (state) => state.getIn(["promotions"])?.promotions;

// CALLS
export const promotionList =
  ({ page, clear }) =>
  async (dispatch, getState) => {
    promotionListCall(promotionsSlice.actions, dispatch, getState(), { page, clear });
  };

export const promotionCreate =
  ({
    smallDescription,
    startDate,
    endDate,
    maxUses,
    value,
    percentage,
    description,
    details,
    voucherCode,
    voucherUses,
  }) =>
  async (dispatch, getState) => {
    promotionCreateCall({ promotionList }, dispatch, getState(), {
      smallDescription,
      startDate,
      endDate,
      maxUses,
      value,
      percentage,
      description,
      details,
      voucherCode,
      voucherUses,
    });
  };

export const promotionCancel =
  ({ promotionId }) =>
  async (dispatch, getState) => {
    promotionCancelCall({ promotionList }, dispatch, getState(), {
      promotionId,
    });
  };
