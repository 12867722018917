import React from "react";
import { Typography, withStyles } from "@material-ui/core";
import { styles } from "./AssetHeader.styles";
import { getStatus } from "../../../../../../model/Asset/AssetStatusType";
import { getIconDetails } from "../../../../AssetIcon/assetInfos";
import AssetIcon from "../../../../AssetIcon/AssetIcon";
import classnames from "classnames";

const AssetHeader = ({ classes, noUnderline = false, placeholder, titleText }) => {
  console.log("placeholder: %o", placeholder);
  const title = <Typography className={classes.title}>{titleText || "Asset Information"}</Typography>;

  const status = getStatus(placeholder);
  const iconDetails = getIconDetails(status);

  const headerInfo = placeholder.carToken && (
    <div className={classes.headerInfo}>
      <AssetIcon assetStatus={status} assetTypeName={placeholder?.assetTypeName} />
      <Typography className={classes.status}>
        {iconDetails?.title !== "Undefined" ? iconDetails?.title : "No Status"}
      </Typography>
    </div>
  );

  return (
    <div className={classnames(classes.root, !!noUnderline && classes.noUnderline)}>
      {title}
      {headerInfo}
    </div>
  );
};

export default withStyles(styles)(AssetHeader);
