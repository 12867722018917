import React from 'react';
import ChangePassword from './ChangePassword/ChangePassword';
import ChangePersonalInfo from './ChangePersonalInfo/ChangePersonalInfo';
import Button from '../../UI/Buttons/CommonButtons';
import {
	Typography,
	withStyles,
	Grid,
} from '@material-ui/core';
import { styles } from './UserSettings.styles';
import classnames from 'classnames';

const UserSettings = ({
	classes,
	className,
	placeholder,
	onChangeHandler,
	errorPassword,
	messagePassword,
	onConfirmPassChange,
	onCurrentPassChange,
	onNewPassChange,
	passwordsAreDifferent,
	onSubmitChanges,
	onSubmitPasswordChanges,
	hasNotification,
	isSuccess,
	errorMessage,
}) => {

	const errorBar = (
		<Typography align='center' className={classnames(
			classes.rootBar,
			hasNotification ? classes.showBar : classes.hideBar,
			!!isSuccess ? classes.success : classes.error)}>
			{errorMessage || messagePassword}
		</Typography>
	);

	return (
		<div
			className={classnames(classes.root, className)}
		>
			{errorBar}
			<Typography className={classes.title}>Settings</Typography>
			<div className={classes.horizontalFlex}>
				<ChangePersonalInfo
					onChangeHandler={onChangeHandler}
					placeholder={placeholder}
				/>
				<hr style={{
					height: '100%', borderLeft: '1px solid #E0E0E0',
					borderRight: '1px solid #E0E0E0',
				}} />
				<ChangePassword
					errorPassword={errorPassword}
					messagePassword={messagePassword}
					onConfirmPassChange={onConfirmPassChange}
					onCurrentPassChange={onCurrentPassChange}
					onNewPassChange={onNewPassChange}
					passwordsAreDifferent={passwordsAreDifferent}
				/>
			</div>
			<hr style={{
				width: '100%', borderTop: '1px solid #E0E0E0',
				borderBottom: '1px solid #E0E0E0',
			}} />
			<Grid container justifyContent="space-around">
				<Button
					buttonStyleName='filled'
					className={classes.button}
					disabled={false}
					onClick={onSubmitChanges}
					label='Update Details'
				/>
				<Button
					buttonStyleName='filled'
					className={classes.button}
					disabled={false}
					onClick={onSubmitPasswordChanges}
					label='Update Password'
				/>

			</Grid>
		</div>
	);
};

export default withStyles(styles)(UserSettings);