import React from 'react';
import {
	InputBase,
	Menu,
	MenuItem,
	Tooltip,
	withStyles,
} from '@material-ui/core';
import {
	MoreVert as DotIcon,
	InfoOutlined as MembersIcon,
} from '@material-ui/icons';
import classnames from 'classnames';
import Modal from './NicknamesModal/NicknamesModalWrapper';
import styles from './Header.styles';

const Header = ({
	anchorEl,
	classes,
	edit,
	groupId,
	isAdmin,
	members,
	modal,
	nickname,
	onCreate,
	onSave,
	setAnchorEl,
	setEdit,
	setModal,
	setNickname,
	textRef,
}) => {
	const moreOptions = nickname && !(groupId && !isAdmin) && (
		<>
			<DotIcon
				id={'chat_add_icon'}
				aria-controls={'chat_create_menu'}
				aria-haspopup='true'
				onClick={e => setAnchorEl(e.target)}
				className={classnames(classes.dotIcon, !groupId && classes.noMembers)}
			/>
			<Menu
				id={'chat_create_menu'}
				anchorEl={anchorEl}
				open={!!anchorEl}
				onClose={e => setAnchorEl()}
			>
				{groupId &&
					<MenuItem
						onClick={() => {
							setAnchorEl();
							onCreate('INVITE_GROUP');
						}}
					>
						Add
					</MenuItem>
				}
				{groupId &&
					<MenuItem
						onClick={() => {
							setAnchorEl();
							onCreate('GROUP_UPDATE');
						}}
					>
						Make admin
					</MenuItem>
				}
				<MenuItem
					onClick={groupId
						? () => {
							setModal(true);
							setAnchorEl();
						}
						: setEdit}
				>
					{groupId ? 'Change Nicknames' : 'Change Nickname'}
				</MenuItem>
			</Menu>
		</>
	);

	const groupMembers = groupId && (
		<Tooltip
			title={members.map(({ nickname, customerCode }) => nickname || customerCode).join(', ')}
		>
			<MembersIcon className={classes.membersIcon} />
		</Tooltip>
	);

	const name = (
		<InputBase
			classes={{ disabled: classes.disabled }}
			className={classes.text}
			disabled={!edit}
			inputRef={textRef}
			onChange={event => setNickname(event.target.value)}
			value={nickname || ''}
			onKeyDown={event => {
				if (event.keyCode === 13) {
					onSave();
				};
			}}
		/>
	);

	const nicksModal = modal && (
		<Modal
			onSave={onSave}
			members={members}
			modal={modal}
			setModal={setModal}
		/>
	);

	return (
		<div className={classes.header}>
			{nicksModal}
			{name}
			{groupMembers}
			{moreOptions}
		</div>
	);
};

export default withStyles(styles)(Header);