import React from 'react';
import List from '../../../UI/List/List';
import RolesItem from './RolesItem/RolesItem';
import {
	withStyles,
} from '@material-ui/core';
import rolesFields from './rolesFields';
import styles from './RolesList.styles';
import classnames from 'classnames';

const RolesList = ({
	classes,
	className,
	currentScreenSize,
	getMoreRows,
	onEdit,
	onEnable,
	onSelect,
	permissions,
	roles,
	selected,
}) => {

	const rolesItems = !!roles
		&& roles.length > 0
		&& roles
			.sort((a, b) => {

				if (!a || !b) {
					return 0;
				}

				return ('' + a.name).localeCompare(b.name);
			})
			.map(role => (
				<RolesItem
					key={role.id}
					onEdit={onEdit}
					onEnable={onEnable}
					onSelect={onSelect}
					permissions={permissions}
					selected={selected === role.id}
					role={role}
				/>
			));

	const list = (
		<List
			className={classnames(classes.root, className)}
			getMoreRows={getMoreRows}
			headerFields={rolesFields(currentScreenSize)}
		>
			{rolesItems}
		</List>
	);

	return list;
};

export default withStyles(styles)(RolesList);