const modals = {
	CREATE: 'CREATE',
	EDIT: 'EDIT',
	VIEW: 'VIEW',
	TRIPS: 'TRIPS',
	BILLING: 'BILLING',
	CUSTOMER: 'CUSTOMER',
	MEMBERS: 'MEMBERS',
	CONSUMPTION: 'CONSUMPTION',
};

export default modals;