import { useState } from "react";
import notificationService from "../../../../../services/notification";
import log from "../../../../../utils/logger";
import * as yup from "yup";

const logger = log("CreateOrEditNotificationWrapper");

const CreateOrEditNotificationReducer = () => {
  const [type, setType] = useState();
  const [subType, setSubType] = useState();
  const [toAddress, setToAddress] = useState("");
  const [subject, setSubject] = useState();
  const [toSpecificMail, setToSpecificMail] = useState();
  const [specificMailList, setSpecificMailList] = useState();
  const [notificationMessage, setNotificationMessage] = useState();
  const [template, setTemplate] = useState();

  const [types, setTypes] = useState();
  const [subTypes, setSubTypes] = useState();
  const [placeholders, setPlaceholders] = useState();
  const [errors, setErrors] = useState();

  const setTypeHandler = async (type) => {
    setType(type);

    try {
      const response = await notificationService.getSubTypes(type);
      setSubTypes(response);
    } catch (error) {
      logger.info(error);
    }
  };

  const setSubTypeHandler = async (subType) => {
    setSubType(subType);
    if (subType) {
      try {
        const pendingDescription = notificationService.getNotificationMessage(type, subType);
        const pendingTemplate = notificationService.getTemplate(type, subType);

        const description = await pendingDescription;

        const { body, subject } = await pendingTemplate;

        setNotificationMessage(description);
        setTemplate(body);
        setSubject(subject);
      } catch (error) {
        logger.info(error);
      }
    }
  };

  const createOrUpdateNotification = async (openNotification, exitCreateOrEditModeCB, getNotificationsCB) => {
    // event.preventDefault();
    const encodedTemplate = encodeURIComponent(template);
    let newNotification = {
      type,
      subType,
      subject,
      bcc: toAddress,
      body: encodedTemplate,
      toSpecificMail,
      specificMailList,
    };
    try {
      setErrors();
      let schema = yup.object().shape({
        type: yup.string().required(),
        subType: yup.string().required(),
        subject: yup.string().required(),
        bcc: yup.string().email(),
      });

      schema.validate(newNotification, { abortEarly: false }).catch(function (err) {
        return setErrors(err.errors);
      });

      if (typeof openNotification === "undefined") {
        // onCreate mode
        await notificationService.createNotification(newNotification);
      } else {
        // onEdit mode
        if (typeof openNotification.id !== "undefined") {
          newNotification.id = openNotification.id;
        }
        await notificationService.updateNotification(newNotification);
      }
      exitCreateOrEditModeCB();
      setTimeout(() => {
        getNotificationsCB();
      }, 300);
    } catch (error) {
      logger.info(error);
    }
  };

  const setNotificationToEdit = async (openNotification) => {
    const { type, subType, subject, template, bcc, toSpecificMail, specificMailList } = openNotification;

    const description = await notificationService.getNotificationMessage(type, subType);

    setTypeHandler(type);
    setSubType(subType);
    setSubject(subject);
    setToSpecificMail(toSpecificMail);
    setSpecificMailList(specificMailList);
    setToAddress(bcc);
    setNotificationMessage(description);
    let decodedTemplate;
    try {
      decodedTemplate = decodeURIComponent(template);
    } catch {
      decodedTemplate = "Could not decode template";
    }
    setTemplate(decodedTemplate);
  };

  const reducer = {
    inputFields: {
      type,
      subType,
      toAddress,
      subject,
      notificationMessage,
      template,
      toSpecificMail,
      specificMailList,
    },
    inputOptions: {
      types,
      subTypes,
      placeholders,
    },
    setTypeHandler,
    setSubTypeHandler,
    createOrUpdateNotification,
    setNotificationToEdit,
    setToAddress,
    setTypes,
    setPlaceholders,
    setSubject,
    setTemplate,
    errors,
    setErrors,
    setToSpecificMail,
    setSpecificMailList,
  };

  return reducer;
};

export default CreateOrEditNotificationReducer;
