import createModel from '../createModel';

class ServicePoint {
	constructor({
		carclubCode,
		code,
		createDate,
		createUser,
		lat,
		lng,
		locationCode,
		name,
		updateDate,
		updateUser,
	} = {}) {
		createModel.call(this, {
			carclubCode,
			code,
			createDate,
			createUser,
			lat,
			lng,
			locationCode,
			name,
			updateDate,
			updateUser,
		});
	};
};

export default ServicePoint;