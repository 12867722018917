import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Grid } from "@material-ui/core";

import { styles } from "./InvoiceActions.styles";
import { useSelector } from "react-redux";
import { selectSelectedTrip, selectSelectedTripPaymentDetails } from "../../../reservationsSlice";
import { selectIsCallsLoading } from "../../../../../app/coreSlice";
import InvoiceButton from "./InvoiceButton/InvoiceButton";

function InvoiceActions({ classes }) {
  const loading = useSelector(selectIsCallsLoading(["BILLING/INVOICE_SINGLE_TRIP"]));
  const paymentDetails = useSelector(selectSelectedTripPaymentDetails);
  const reservation = useSelector(selectSelectedTrip);

  return (
    <Grid container className={classes.root} direction="column" spacing={1}>
      {/* <Grid item>
        <Typography className={classes.title}>Actions</Typography>
      </Grid> */}
      <Grid item>
        <Grid container direction="row" justifyContent="space-evenly">
          {(reservation.state === "CLOSED" || reservation.state === "ABSENT") &&
            !reservation?.accountId &&
            !paymentDetails?.billingDetails?.invoices?.length > 0 && (
              <Grid item>
                <InvoiceButton
                  reservationCode={reservation?.code}
                  customerCode={reservation?.customerCode}
                  loading={loading}
                />
              </Grid>
            )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(InvoiceActions);
