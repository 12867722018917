import { fromJS, Set, Map } from 'immutable';

const verifyIfString = (arg) => {
	if (!(typeof arg === 'string' || arg instanceof String)) {
		throw Error("'argName' must be a string.");
	}
};

const arrayOfObjectsToMap = (array, key) => {
	return array.reduce((map, item, index) => {
		map[item[key]] = item;
		return map;
	}, {});
};

const set = (argNames, ...args) => {

	let names;

	if (Array.isArray(argNames)) {
		argNames.forEach(name => verifyIfString(name));
		names = argNames;
	} else {
		verifyIfString(argNames);
		names = [argNames];
	}

	return (arg, key) => {

		if (arg === undefined) {
			return state => state;
		}

		return state => {

			if (Array.isArray(arg)) {
				const obj = arrayOfObjectsToMap(arg, key);
				return state.setIn(names, fromJS(obj));
			}

			return state.setIn(names, fromJS(args[0] || arg));
		};
	};
};

/**
 * Increases the property 'argName' by one. That property must be number.
 */
const increment = (argName) => {
	verifyIfString(argName);
	return () => {
		return state => {
			const prop = state.get(argName);
			if (isNaN(prop)) {
				return state;
			}
			return state.set(argName, prop + 1);
		};
	};
};

/**
 * Decreases the property 'argName' by one. That property must be number.
 */
const decrement = (argName) => {
	verifyIfString(argName);
	return () => {
		return state => {
			const prop = state.get(argName);
			if (isNaN(prop)) {
				return state;
			}
			return state.set(argName, prop - 1);
		};
	};
};

const update = argNames => {

	let path;

	if (Array.isArray(argNames)) {
		argNames.forEach(name => verifyIfString(name));
		path = argNames;
	} else {
		verifyIfString(argNames);
		path = [argNames];
	}

	return (arg, key) => {

		if (arg === undefined) {
			return state => state;
		}

		return state => {

			return state.updateIn(path, map => map?.set([key], fromJS(arg)));
		};
	};
};


const addToSet = argNames => {

	let path;

	if (Array.isArray(argNames)) {
		argNames.forEach(name => verifyIfString(name));
		path = argNames;
	} else {
		verifyIfString(argNames);
		path = [argNames];
	}

	return arg => {

		if (arg === undefined) {
			return state => state;
		}

		return state => {
			const set = state.getIn(path, Set());
			return state.setIn(path, fromJS(set.union([arg])));
		};
	};
};

const removeFromSet = argNames => {

	let path;

	if (Array.isArray(argNames)) {
		argNames.forEach(name => verifyIfString(name));
		path = argNames;
	} else {
		verifyIfString(argNames);
		path = [argNames];
	}

	return arg => {

		if (arg === undefined) {
			return state => state;
		}

		return state => {
			const set = state.getIn(path, Set());
			return state.setIn(path, fromJS(set.subtract([arg])));
		};
	};
};

const remove = argNames => {

	let path;

	if (Array.isArray(argNames)) {
		argNames.forEach(name => verifyIfString(name));
		path = argNames;
	} else {
		verifyIfString(argNames);
		path = [argNames];
	}

	return arg => {

		if (arg === undefined) {
			return state => state;
		}

		return state => {
			const map = state.getIn(path, Map());
			return state.setIn(path, fromJS(map.remove(arg)));
		};
	};
};

const mutationType = {
	set: set,
	increment: increment,
	decrement: decrement,
	update,
	addToSet,
	removeFromSet,
	remove,
	// TODO delete: delete,
	// TODO add: (argName, ...args) => () => state => state.set(argName, state.get(argName) + args[0]),
	// TODO subtract: (argName, ...args) => () => state => state.set(argName, state.get(argName) - args[0]),
	// TODO multiply: (argName, ...args) => () => state => state.set(argName, state.get(argName) * args[0]),
	// TODO divide: (argName, ...args) => () => state => state.set(argName, state.get(argName) / args[0]),
	// TODO update: update,
};

export default mutationType;
