import React from "react";
import { FormControl, InputLabel, Select, Typography, withStyles } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import moment from "moment";
import { DateTimePicker } from "@material-ui/pickers";
import styles from "./Filter.styles";

const Filter = ({ classes, filter, onChange, onSearch, types }) => {
  const title = <Typography className={classes.title}>Trip Analysis</Typography>;

  const datePickers = (
    <div className={classes.datePickers}>
      <DateTimePicker
        ampm={false}
        className={classes.picker}
        clearable
        format="DD/MM/YYYY HH:mm"
        inputVariant="outlined"
        key="datePickerStart"
        label="Start date"
        onChange={(date) => onChange({ startDate: date && moment(date).format("YYYY-MM-DD HH:mm:00") })}
        value={filter.startDate || null}
        InputProps={{ classes: { input: classes.pickerInput } }}
        InputLabelProps={{ classes: { outlined: classes.outlined } }}
      />
      <DateTimePicker
        ampm={false}
        className={classes.picker}
        clearable
        format="DD/MM/YYYY HH:mm"
        inputVariant="outlined"
        key="datePickerEnd"
        label="End date"
        onChange={(date) => onChange({ endDate: date && moment(date).format("YYYY-MM-DD HH:mm:00") })}
        value={filter.endDate || null}
        InputProps={{ classes: { input: classes.pickerInput } }}
        InputLabelProps={{ classes: { outlined: classes.outlined } }}
      />
    </div>
  );

  const options = types.map((type) => (
    <option value={type} key={type} className={classes.option}>
      {type}
    </option>
  ));

  const select = (
    <FormControl key="select" className={classes.select}>
      <InputLabel>Select</InputLabel>
      <Select native onChange={(e) => onChange({ type: e.target.value })} displayEmpty value={filter.type || ""}>
        <option value="" key="_blank"></option>
        {options}
      </Select>
    </FormControl>
  );

  const searchIcon = <SearchIcon className={classes.searchIcon} onClick={onSearch} size="small" />;

  return (
    <div className={classes.root}>
      {title}
      {datePickers}
      {select}
      {searchIcon}
    </div>
  );
};

export default withStyles(styles)(Filter);
