const styles = (theme) => ({
	root: {
		marginLeft: '20px',
		maxWidth: '250px',
	},

	searchIcon: {
		cursor: 'pointer',
	},

	inputRoot: {
		color: 'inherit',
		width: '100%',
	},

	inputInput: {
		paddingTop: theme.spacing(2),
		paddingRight: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		transition: theme.transitions.create('width'),
		width: '100%',
		borderBottom: '1px solid rgba(224, 224, 224, 1)',
	},

	noBorder: {
		borderBottom: 'none',
	},

	error: {
		borderBottom: '1px solid #f01',
	},

	disabled: {
		color: '#8888',
	},
});

export default styles;