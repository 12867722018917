export const styles = () => ({
  root: {
    backgroundColor: "inherit",
    height: "inherit",
    minWidth: "inherit",
    maxWidth: "inherit",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: 8,
  },

  greyBG: {
    backgroundColor: "inherit",
  },

  horizontalMenu: {
    backgroundColor: "inherit",
    width: "100%",
    borderTop: "2px solid rgba(0, 0, 0, 0.20)",
    // paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    minHeight: 48,
    borderRadius: 0,
  },

  horizontalMenuOptions: {
    backgroundColor: "inherit",
    height: "28px",
    minHeight: "28px",
    minWidth: 80,
    width: 95,
    textAlign: "center",
    verticalAlign: "middle",
    cursor: "pointer",
    fontSize: 9,
    borderBottom: "2px solid rgba(1, 151, 198, 0.30)",
  },

  selectedTab: {
    borderBottom: "2px solid rgba(1, 151, 198, 0.75)",
    fontWeight: "bold",
  },

  disabled: {
    color: "#888",
  },

  tabContainer: {
    width: "inherit",
    maxWidth: "inherit",
    display: "flex",
    backgroundColor: "inherit",
    height: 30,
    minHeight: 30,
    flexDirection: "column",
    justifyContent: "flex-start",
    // paddingRight: '17px',
    // border: '1px solid rgba(132, 146, 166, 0.16)',
  },

  tabContent: {
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    scrollbarWidth: "thin", //FIREFOX
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },

  paper: {
    position: "absolute",
    width: "50vw",
    height: "45vh",
    padding: "0 30px 10px",
  },
});
