const styles = (theme) => ({
  root: {},

  crossIcon: {
    cursor: "pointer",
  },

  searchIcon: {
    cursor: "pointer",
  },

  seachCustomers: {
    display: "inline-flex",
  },

  searchIconShadow: {
    color: "#009AC9",
  },

  inputRoot: {
    color: "inherit",
    width: "100%",
  },

  inputInput: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "438px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },

  bookButton: {
    display: "flex",
    alignItems: "flex-end",
    marginRight: "20px",
    zIndex: 2,
  },
  createIcon: {
    cursor: "pointer",
    color: "#009AC9",
    height: "26.5px",
    width: "auto",
    marginRight: 20,
    alignSelf: "flex-end",
  },
});

export default styles;
