import * as Yup from "yup";
import { TextField } from "../../../common/forms";

export function formSchema() {
  return Yup.object().shape({
    name: Yup.string().required("Name is required"),
    surname: Yup.string().required("Surname is required"),
    email: Yup.string().email("Invalid Email").required("Email is required"),
  });
}

export function formFieldsPersInfo() {
  return [
    {
      size: 12,
      field: <TextField id="name" type="text" label="Name" required />,
    },
    {
      size: 12,
      field: <TextField id="surname" type="text" label="Surname" required />,
    },
    {
      size: 12,
      field: <TextField id="phoneNumber" type="text" label="Mobile number" />,
    },
    {
      size: 12,
      field: <TextField id="email" type="email" label="Email" required />,
    },
    {
      size: 12,
      field: <TextField id="address" type="text" label="Address" />,
    },
    {
      size: 12,
      field: <TextField id="city" type="text" label="City" />,
    },
    {
      size: 12,
      field: <TextField id="zipCode" type="text" label="Zip code" />,
    },
    {
      size: 12,
      field: <TextField id="state" type="text" label="State" />,
    },
    {
      size: 12,
      field: <TextField id="country" type="text" label="Country" />,
    },
  ];
}

export function formFieldsDevices() {
  return [
    {
      size: 12,
      field: <TextField id="rfidToken" type="text" label="Rfid Token" />,
    },
  ];
}

export function formFieldsBilling() {
  return [
    {
      size: 12,
      field: <TextField id="taxNumber" type="text" label="Tax number" />,
    },
    {
      size: 12,
      field: <TextField id="billingAddress" type="text" label="Address" />,
    },
    {
      size: 12,
      field: <TextField id="billingCity" type="text" label="City" />,
    },
    {
      size: 12,
      field: <TextField id="billingZipCode" type="text" label="Zip code" />,
    },
    {
      size: 12,
      field: <TextField id="billingState" type="text" label="State" />,
    },
    {
      size: 12,
      field: <TextField id="billingCountry" type="text" label="Country" />,
    },
  ];
}