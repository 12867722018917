import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectDefaultLocation } from "../../../features/carclub/carclubSlice";

import PromotionApp from "./Promotion";
import { promotionList, selectPromotions } from "../../../features/promotions/promotionsSlice";

const PromotionAppWrapper = (props) => {
  const dispatch = useDispatch();
  const promotions = useSelector(selectPromotions);

  const [selectedPromotion, setSelectedPromotion] = useState();

  const selectedLocation = useSelector(selectDefaultLocation);

  // console.log("promotions: %o", promotions);

  useEffect(() => {
    dispatch(promotionList({ page: 1, clear: true }));
    // eslint-disable-next-line
  }, [selectedLocation]);

  const getMoreRows = useCallback(() => {
    dispatch(promotionList({ page: promotions.page + 1, clear: false }));
    // eslint-disable-next-line
  }, [promotions]);

  return (
    <PromotionApp
      {...props}
      getMoreRows={getMoreRows}
      lastPage={promotions.lastPage}
      promotions={promotions.list}
      selectedPromotion={selectedPromotion}
      setSelectedPromotion={setSelectedPromotion}
    />
  );
};

export default PromotionAppWrapper;
