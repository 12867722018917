
import NGError from "../../../error/ngError";
import errorFactory from "../../../error/errorFactory";
import { WS_PROCESS_MESSAGE_IS_MALFORMED } from "../../../error/websocket/errorTypes";
import carclubConfigsMapperNew from "./mappers/carclubConfigsMapperNew";
import { publishAndAwait } from "../../../app/coreSlice";

const updateCarclubConfigs = async (data) => {
  console.log("updateCarclubConfigs ---  got here");

  const dto = carclubConfigsMapperNew.toDTO(data);

  console.log("UPDATE: %o", dto);

  const subType = data?.isDefaultConfigs ? "UPDATE_DEFAULT_CONFIGS" : "UPDATE_CONFIGS";
  const request = await publishAndAwait({
    destination: "/app/hello",
    body: {
      type: "CARCLUB",
      subType,
      message: {
        ...dto,
      },
    },
  });

  const { processMessage, error } = request.response;

  if (!processMessage) {
    throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
  }

  if (error) {
    const { description, code, tag } = processMessage;

    throw new NGError({
      message: description,
      code,
      tag,
    });
  }

  return processMessage || null;
};

export default updateCarclubConfigs;
