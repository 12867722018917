import React, { useState } from "react";
import CustomerDetails from "../../components/NewCustomers/CustomerDetails/CustomerDetailsWrapper";
import CustomerEditCreate from "../../components/NewCustomers/CustomerEditCreate/CustomerEditCreateWrapper";
import CustomerAccountDetails from "../../components/NewCustomers/CustomerAccountDetails/CustomerAccountDetailsWrapper";
import SearchBar from "../../components/NewCustomers/CustomerFilters/CustomerFiltersWrapper";
import { Drawer, Modal, withStyles } from "@material-ui/core";
import styles from "./CustomerApp.styles";
import CustomerForm from "../../features/customer/CustomerForm/CustomerForm";
import ReservationCreateUpdate from "../../features/reservation/ReservationCreateUpdate/ReservationCreateUpdate";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedCustomer, setSelectedCustomerByCode } from "../../features/customer/customerSlice";
import CustomersList from "../../features/customer/CustomersList/CustomersList";

const CustomerApp = ({
  classes,
  closeAccountDetailsScreen,
  closeUpdateScreen,
  currentScreenSize,
  hasCreateEditScreen,
  hasCustomerAccountDetails,
  modal,
  openAccountDetailsScreen,
  openUpdateScreen,
  setModal,
  tawtheeq,
  toggleCustomer,
  updateCustomer,
}) => {
  const dispatch = useDispatch();
  const selectedCustomer = useSelector(selectSelectedCustomer);

  const [createCustomerDrawerOpen, setCreateCustomerDrawerOpen] = useState(false);
  const onAddCustomerHandler = () => {
    setCreateCustomerDrawerOpen(true);
  };

  const onSaveHandler = () => {
    setCreateCustomerDrawerOpen(false);
  };

  const details = selectedCustomer && <CustomerDetails details={selectedCustomer} tawtheeq={tawtheeq} />;

  const accountDetailsModal = selectedCustomer && (
    <Modal onClose={closeAccountDetailsScreen} open={hasCustomerAccountDetails}>
      <div className={classes.modal}>
        <CustomerAccountDetails currentScreenSize={currentScreenSize} customer={selectedCustomer} />
      </div>
    </Modal>
  );

  const reservationModal = !!modal && (
    <ReservationCreateUpdate selectedCustomer={selectedCustomer} onClose={() => setModal(false)} open={!!modal} />
  );

  // Below CustomerForm is using the new version of code to create a new Driver/Customer

  const customerApp = (
    <div className={classes.root}>
      <div className={classes.workspace}>
        {accountDetailsModal}
        {reservationModal}
        <Drawer anchor="right" onClose={closeUpdateScreen} open={!!hasCreateEditScreen}>
          <CustomerEditCreate customer={selectedCustomer} onClose={closeUpdateScreen} updateCustomer={updateCustomer} />
        </Drawer>
        <Drawer anchor="right" onClose={() => setCreateCustomerDrawerOpen(false)} open={createCustomerDrawerOpen}>
          <CustomerForm onCancel={() => setCreateCustomerDrawerOpen(false)} onSave={onSaveHandler} />
        </Drawer>
        <div className={classes.list}>
          <SearchBar
            disabledBook={!selectedCustomer?.available}
            setModal={setModal}
            onAddCustomer={onAddCustomerHandler}
          />
          <CustomersList
            editCustomer={(customerCode) => {
              dispatch(setSelectedCustomerByCode(customerCode));
              console.log("EDIT");
              openUpdateScreen();
            }}
            openAccountDetailsScreen={(customerCode) => {
              dispatch(setSelectedCustomerByCode(customerCode));
              openAccountDetailsScreen();
            }}
            onSelect={(customerCode) => {
              dispatch(setSelectedCustomerByCode(customerCode));
            }}
            toggleCustomer={toggleCustomer}
            updateCustomer={updateCustomer}
          />

        </div>
        <div className={classes.details}>{details}</div>
      </div>
    </div>
  );

  return customerApp;
};

export default withStyles(styles)(CustomerApp);
