import * as listItemTypes from '../UI/List/ListItem/ListItemTypes';

const width = 78;

const maxWidth = 136;

const height = 35;

export const detailsByCustomer = [
	{
		key: 'name',
		label: 'Name',
		order: 2,
		pinned: true,
		width,
		maxWidth,
		height,
		type: listItemTypes.TEXT,
	},
	{
		key: 'email',
		label: 'Email',
		order: 3,
		pinned: true,
		width,
		maxWidth,
		height,
		type: listItemTypes.TEXT,
	},
	{
		key: 'customerCode',
		label: 'Client Code',
		order: 1,
		pinned: true,
		width,
		maxWidth,
		height,
		type: listItemTypes.TEXT,
	},
	{
		key: 'phoneNumber',
		label: 'Phone Number',
		order: 4,
		pinned: true,
		width,
		maxWidth,
		height,
		type: listItemTypes.TEXT,
	},
	{
		key: 'editIcons',
		label: ' ',
		order: 99,
		pinned: true,
		width,
		height,
		type: listItemTypes.COMPONENT,
		flexDirection: 'row-reverse',
	},
];

export const detailsByZone = [
	{
		key: 'code',
		label: 'Code',
		order: 2,
		pinned: true,
		width,
		maxWidth,
		height,
		type: listItemTypes.TEXT,
	},
	{
		key: 'description',
		label: 'Description',
		order: 3,
		pinned: true,
		width,
		maxWidth,
		height,
		type: listItemTypes.TEXT,
	},
	{
		key: 'type',
		label: 'Type',
		order: 1,
		pinned: true,
		width,
		maxWidth,
		height,
		type: listItemTypes.TEXT,
	},
	{
		key: 'editIcons',
		label: ' ',
		order: 99,
		pinned: true,
		width,
		height,
		type: listItemTypes.COMPONENT,
		flexDirection: 'row-reverse',
	},
];