import React from 'react';
import List from '../../../UI/List/List';
import Item from './Item/Item';
import fields from './fields';

const BonusList = ({
	bonuses = [],
	className,
	currentScreenSize,
	getMoreRows,
	onSelect,
	selected,
}) => {
	const list = [...new Set(bonuses.map(bonus => JSON.stringify(bonus)))]
		.map(item => {
			const bonus = JSON.parse(item);

			return (
				<Item
					bonus={bonus}
					key={bonus.id}
					onClick={() => onSelect(bonus)}
					selected={selected === bonus?.id}
				/>
			);
		});

	return (
		<List
			className={className}
			headerFields={fields(currentScreenSize)}
			getMoreRows={getMoreRows}
		>
			{list}
		</List>
	);
};

export default BonusList;
