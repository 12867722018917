import React from 'react';
import GoogleMap from '../../../MainMap/MainMap';
import {
	Room as MarkerIcon,
	Close as ExitIcon,
	DirectionsCar as AssetIcon,
	Visibility as ShowHexagons,
	VisibilityOff as HideHexagons,
	ExpandLess as CollapseIcon,
	ExpandMore as ExpandIcon,
} from '@material-ui/icons';
import {
	Checkbox,
	FormControl,
	Select,
	Typography,
	withStyles,
} from '@material-ui/core';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import styles from './Map.styles';
import Point from './Point';
import classnames from 'classnames';
import moment from 'moment';

const Map = ({
	classes,
	placeholderMap,
	mapPlaceholderOnChangeHandler,
	mapCenter,
	mapOptions = {},
	onGoogleAPILoaded,
	onClose,
	onSelect,
	servicePoints = [],
	simpleMap = false,
	route,
	paintExistingRoute,
}) => {

	const {
		expanded,
		startDate,
		carclubs,
		showHexagons,
		showAssets,
		showDemand,
	} = placeholderMap || {};

	const points = Array.isArray(servicePoints) && servicePoints
		.filter(({ lat, latitude, lng, longitude }) =>
			(lat || latitude) && (lng || longitude))
		.map(point => (
			<Point
				key={`${point.code}_${point.name}`}
				lat={point.lat || point.latitude}
				lng={point.lng || point.longitude}
				onSelect={() => onSelect && onSelect(point.code)}
				tooltip={point.name}
			/>
		));

	const hexagonsIcon = !showHexagons
		? (<HideHexagons
			className={classes.option}
			onClick={() => mapPlaceholderOnChangeHandler('showHexagons', true)}
		/>)
		: (<ShowHexagons
			className={classnames(classes.option, classes.selectedOption)}
			onClick={() => mapPlaceholderOnChangeHandler('showHexagons', false)}
		/>);

	const assetIcon = (
		<AssetIcon
			className={classnames(classes.option, !!showAssets && classes.selectedOption)}
			onClick={() => {
				const current = showAssets;
				mapPlaceholderOnChangeHandler('showAssets', !current);
			}}
		/>
	);

	const markerIcon = (
		<MarkerIcon
			className={classnames(classes.option, !!route && classes.selectedOption)}
			onClick={() => {
				paintExistingRoute();
			}}
		/>
	);

	const filterIcon = expanded
		? (
			<CollapseIcon
				className={classes.option}
			/>
		)
		: (
			<ExpandIcon
				className={classnames(classes.option, expanded && classes.selectedOption)}
			/>
		);

	const filtersExpander = (
		<div
			className={classnames(classes.filterExpander, classes.expanded)}
			onClick={() => {
				const current = expanded;
				mapPlaceholderOnChangeHandler('expanded', !current);
			}}
		>
			<Typography style={{ fontSize: 14 }}>Filters</Typography>
			{filterIcon}
		</div>
	);

	const closeIcon = !simpleMap && (
		<div className={classes.exitDiv}>
			<ExitIcon
				className={classes.exitIcon}
				onClick={onClose}
			/>
			<Typography className={classes.exitText}>Esc</Typography>
		</div>
	);

	const iconProps = classes.inputIcon;
	const inputProps = classes.input;

	const timeFilter = (
			<div className={classes.timeFilter}>
				<div className={classes.timePickerRoot}>
					<span className={classes.pickerLabel}>Time</span>
					<KeyboardDateTimePicker
						className={classes.picker}
						InputAdornmentProps={{ position: 'end' }}
						KeyboardButtonProps={{ classes: { root: iconProps } }}
						InputProps={{ classes: { root: inputProps }, disableUnderline: true }}
						value={startDate}
						onChange={date => {
							const data = moment(date).format('YYYY-MM-DD HH:mm:ss');
							mapPlaceholderOnChangeHandler('startDate', data);
						}}
						minDate={false}
						format="DD/MM/YYYY HH"
						ampm={false}
						disableFuture={false}
						allowKeyboardControl={true}
						clearable={true}
						onDoubleClick={false || (() => undefined)}
						disabled={!showHexagons}
					/>
				</div>
			</div>
	);

	// CARCLUB

	const carclubSelectionDiv = carclubs?.length > 0 && getLabelAndSelect({
		editMode: !!showHexagons === false ? false : !showDemand,
		options: carclubs,
		fieldLabel: 'Company',
		fieldString: 'selectedCarclub',
		onChangeHandler: mapPlaceholderOnChangeHandler,
		placeholder: placeholderMap,
		classes,
	});

	const demandField = getCheckboxAndLabel({
		editMode: !!showHexagons, fieldLabel: 'Demand', fieldString: 'showDemand',
		onChangeHandler: mapPlaceholderOnChangeHandler,
		placeholder: placeholderMap,
		classes,
	});

	const predictionField = getCheckboxAndLabel({
		editMode: !!showHexagons, fieldLabel: 'Prediction', fieldString: 'showPrediction',
		onChangeHandler: mapPlaceholderOnChangeHandler,
		placeholder: placeholderMap,
		classes,
	});

	const expandedOptions = expanded && (
		<div className={classes.expandedRoot}>
			{timeFilter}
			{demandField}
			{predictionField}
			<div className={classes.selectorsRoot}>
				{carclubSelectionDiv}
			</div>
		</div>
	);

	const icons = (
		<div className={classes.iconsRoot}>
			{hexagonsIcon}
			{assetIcon}
			{markerIcon}
		</div>
	);

	const mapInteractiveOptions = !simpleMap && (
		<div className={classnames(classes.mapOptionsRoot)}>
			<div className={classnames(classes.baseOptions, !!expanded && classes.noShadow)}>
				{icons}
				{filtersExpander}
			</div>
			{expandedOptions}
		</div>
	);

	const map = (
		<GoogleMap
			markers={points}
			onAPIloaded={onGoogleAPILoaded}
			pos={mapCenter}
			styles={{
				...mapOptions,
				mapTypeControl: true,
				mapTypeControlOptions: {
					mapTypeIds: ['satellite', 'roadmap'],
				},
			}}
		/>
	);

	return (
		<div className={classes.root}>
			{closeIcon}
			{mapInteractiveOptions}
			{map}
		</div>
	);

};

const getLabelAndSelect = ({
	classes,
	fieldLabel,
	fieldString,
	placeholder,
	onChangeHandler,
	options,
	editMode,
	initialValue = '',
	extraClass,
}) => {

	if (typeof options === 'undefined'
		|| typeof fieldString === 'undefined'
		|| typeof onChangeHandler === 'undefined'
		|| typeof placeholder === 'undefined') {
		return null;
	};

	const labelField = (
		<Typography className={classnames(classes.selectLabel, classes[extraClass])} key={'label_' + fieldLabel}>
			{fieldLabel + ':'}
		</Typography>
	);

	const fieldOptions = options && options
		.map(timeZone => (
			<option value={timeZone} key={timeZone}>
				{timeZone}
			</option>
		));

	const fieldSelect = (
		<FormControl className={classes.alignSelect}>
			<Select
				key={'input_' + fieldLabel}
				className={classes.selectEmpty}
				native
				onChange={(e) => onChangeHandler(fieldString, e.target.value)}
				value={placeholder[fieldString] || initialValue}
				inputProps={{
					id: fieldString,
				}}
				disabled={!editMode}
			>
				<option value='' key='undefined' />
				{fieldOptions}
			</Select>
		</FormControl>
	);

	return (
		<div className={classes.flexHorizontally}>
			{labelField}
			{fieldSelect}
		</div>
	);
};

const getCheckboxAndLabel = ({
	classes,
	editMode,
	fieldLabel,
	fieldString,
	onChangeHandler,
	placeholder,
	rootSize,
	required,
}) => {

	if (typeof fieldString === 'undefined'
		|| typeof onChangeHandler === 'undefined'
		|| typeof placeholder === 'undefined') {
		return null;
	};

	const labelField = (
		<Typography className={classes.checkboxLabel} key={'label_' + fieldLabel}>
			{fieldLabel}
		</Typography>
	);

	const fieldSelect = (
		<Checkbox
			key={'input_' + fieldLabel}
			className={classes.checkbox}
			checked={!!placeholder[fieldString] ? true : false}
			color='primary'
			onChange={() => {
				const current = !!placeholder[fieldString];
				onChangeHandler(fieldString, !current);
			}}
			disableRipple={true}
			disableTouchRipple={true}
			disabled={!editMode}
			required={!!required}
		/>
	);

	return (
		<div className={classnames(classes.labelAndInputCheckbox, classes[rootSize])}>
			{labelField}
			{fieldSelect}
		</div>
	);
};

export default withStyles(styles)(Map);