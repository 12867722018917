import {
	APP_SET_WINDOW_WIDTH,
} from './actionTypes';
import * as screenSize from '../../../model/ScreenSize/ScreenSize';
import makeActionCreator from '../../../utils/makeActionCreator';

const ERROR_WIDTH = screenSize.ERROR;
const MIN_WIDTH = screenSize.TABLET;
const HIGH_WIDTH = screenSize.DESKTOP;

export const appSetWindowWidthAction = makeActionCreator(APP_SET_WINDOW_WIDTH, 'windowWidth');

export const appSetWindowWidth = ( currentWidth, newWidth ) => {

	return dispatch => {

		if ( ( !currentWidth || currentWidth >= MIN_WIDTH ) && newWidth < MIN_WIDTH ){
			dispatch(appSetWindowWidthAction(ERROR_WIDTH));
			return;
		};

		if ( ( !currentWidth || currentWidth < HIGH_WIDTH ) && newWidth >= HIGH_WIDTH ){
			dispatch(appSetWindowWidthAction(HIGH_WIDTH));
			return;
		};

		if ( ( !currentWidth || currentWidth !== MIN_WIDTH ) && newWidth >= MIN_WIDTH && newWidth < HIGH_WIDTH ){
			dispatch(appSetWindowWidthAction(MIN_WIDTH));
			return;
		};
	};
};