import React from 'react';
import List from '../../../../UI/List/List';
import * as listItemTypes from '../../../../UI/List/ListItem/ListItemTypes';
import FeaturesItem from './FeaturesItem/FeaturesItem';
import {
	withStyles,
} from '@material-ui/core';
import styles from './FeaturesList.styles';
import classnames from 'classnames';

const listConfigs = [
	{
		key: 'feature',
		label: '',
		order: 1,
		pinned: true,
		height: 35,
		type: listItemTypes.COMPONENT,
	},
];

const FeaturesList = ({
	classes,
	className,
	array,
	features,
	onSelect,
	selected,
}) => {

	const list = !!array && array
		.sort((a, b) => a.order - b.order)
		.map((feature, index) => (
			<FeaturesItem
				feature={features[feature]}
				onSelect={onSelect}
				selected={!!selected && selected === feature}
				key={feature + '_' + index}
			/>
		));

	return (
		<List
			className={classnames(classes.root, className)}
			headerFields={listConfigs}
			noHeader={true}
		>
			{list}
		</List>
	);
};

export default withStyles(styles)(FeaturesList);