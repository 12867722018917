import React, { useState, useCallback } from 'react';
import ChargeModal from './ChargeModal';

const ChargeModalWrapper = ({
	onSettle,
	...props
}) => {
	const [description, setDescription] = useState();

	const settle = useCallback(() => onSettle(description), [onSettle, description]);

	return (
		<ChargeModal
			{...props}
			description={description}
			onSettle={settle}
			setDescription={setDescription}
		/>
	);
};

export default ChargeModalWrapper;