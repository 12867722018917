import {
  Settings as ConfigsIcon,
  MailOutline as NotificationsIcon,
  Person as UsersIcon,
  VerifiedUser as RolesIcon,
  Security as PermissionsIcon,
  NewReleases as BannersIcon,
  PhonelinkSetup as AppConfigurationsIcon,
  Receipt as InvoiceConfigurationsIcon,
  AccountTreeOutlined as CarflowIcon,
  Router,
} from "@material-ui/icons";

import * as screenNames from "../../model/Carclub/carclubScreens";

const style = {
  width: "20px",
  height: "20px",
};

const button = (icon, text, tag, right = false) => {
  return {
    icon,
    text,
    right,
    style,
    tag,
    permissions: [{ resource: ["carclub", tag, "read"] }],
  };
};

let buttons = []
  .concat(button(ConfigsIcon, screenNames.DEFAULT_CONFIGURATIONS, "defaultConfigurations"))
  .concat(button(ConfigsIcon, screenNames.LOCAL_CONFIGURATIONS, "configurations"))
  .concat(button(NotificationsIcon, screenNames.NOTIFICATIONS, "notifications"))
  .concat(button(BannersIcon, screenNames.BANNERS, "banners"))
  .concat(button(UsersIcon, screenNames.USERS, "users"))
  .concat(button(RolesIcon, screenNames.ROLES, "roles"))
  .concat(button(PermissionsIcon, screenNames.PERMISSIONS, "permissions"))
  .concat(button(AppConfigurationsIcon, screenNames.APP_CONFIGURATIONS, "appConfigurations"))
  .concat(button(InvoiceConfigurationsIcon, screenNames.INVOICE, "invoiceConfigurations"))
  .concat(button(Router, screenNames.TELTONIKA, "teltonika"));

buttons = [...buttons, button(CarflowIcon, screenNames.CARFLOWS, "carflows")];

export default buttons;
