import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useCurrency = () => {
  const currencyPatterns = useSelector((state) => state.getIn(["carclub", "patterns", "currency"]).toJS());
  const [symbol, setSymbol] = useState("€");
  const [decimalScale, setDecimalScale] = useState(2);

  useEffect(() => {
    if (currencyPatterns) {
      setSymbol(currencyPatterns?.symbol || "€");
      setDecimalScale(currencyPatterns?.decimalPlaces || 2);
    }
  }, [currencyPatterns]);

  return {
    symbol,
    decimalScale,
    getPlaceholder: () => {
      let str = "0.";
      for (let i = 0; i < decimalScale; i++) {
        str += "0";
      }
      return str;
    },
    formatCurrency: (value) => {
      if (value) {
        const formatedValue = parseFloat(value).toFixed(decimalScale);
        return `${formatedValue} ${symbol}`;
      } else {
        return `- ${symbol}`;
      }
    },
  };
};

export default useCurrency;
