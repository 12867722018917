
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import carclubConfigsMapperNew from './mappers/carclubConfigsMapperNew';
import { publishAndAwait } from '../../../app/coreSlice';


const getCarclubConfigs = async ({ isDefaultConfigs }) => {

	const subType = isDefaultConfigs
		? 'GET_DEFAULT_CONFIGS'
		: 'GET_CONFIGS';

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'CARCLUB',
			subType,
			message: {},
		},
	});


	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}

	// console.log('processMessage: %o', processMessage);

	const configurations = carclubConfigsMapperNew.fromDTO(processMessage);

	// console.log('configurations: %o', configurations);

	return configurations || null;
};

export default getCarclubConfigs;