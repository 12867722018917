const styles = theme => ({

	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		justifyContent: 'flex-start',
		minWidth: '0',
		width: '100%',
		boxSizing: 'border-box',
	},

	title: {
		fontSize: '20px',
		margin: '10px',
	},

	description: {
		borderBottom: 'none',
	},
	
	inputInput: {
		borderBottom: '1px solid gray',
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(2),
		transition: theme.transitions.create('width'),
		width: '100%',
	},

	inputError: {
		borderBottom: '1px solid red',
	},
	
	inputRoot: {
		color: 'inherit',
		width: '100%',
	},
	
	error: {
		margin: '0 10px',
		color: 'red',
		fontSize: '12px',
	},

	buttons: {
		alignItems: 'flex-end',
		display: 'flex',
		flexGrow: '1',
		justifyContent: 'space-around',
		padding: '5% 0',
	},

	form: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		margin: '10px',
		boxSizing: 'border-box',
		overflow: 'auto',
		overflowX: 'hidden',
		scrollbarWidth: 'thin',
		'&::-webkit-scrollbar': {
			width: '1px',
		},

		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)', 
		},
	},

	icon: {
		alignSelf: 'center',
		cursor: 'pointer',
		fill: '#009AC9',
		height: '15px',
		width: '15px',
	},

	attachmentItem: {
		alignItems: 'center',
		color: '#009AC9',
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
	},

	attachmentItemBorder: {
		borderTop: '1px solid rgba(112, 112, 112, 0.4)',
	},

	attachmentInput: {
		color: '#009AC9',
		cursor: 'pointer',
		margin: '10px 0',
		width: '110px',
		fontSize: '14px',
	},

	imageText: {
		color: '#009AC9',
		cursor: 'pointer',
	},

	imageRoot: {
		display: 'flex',
		flexDirection: 'column',
		padding: '10px 0',
		width: '100%',
	},

	noBorder: {
		borderBottom: 'none',
		padding: 0,
	},

	saveButton: {
		color: '#009AC9',
		cursor: 'pointer',
		fontSize: '11px',
	},

	disabled: {
		color: '#888 !important',
		cursor: 'default !important',
	},

	kmlFile: {
		color: '#009AC9',
		cursor: 'pointer',
		marginTop: '10px',
	},

	datePickers: {
		display: 'grid',
		gap: '10px',
		gridTemplateColumns: 'auto auto',
		margin: '10px 0',
	},

	picker: {
		maxHeight: '50px',
		margin: 'auto 5px',
	},

	pickerInput: {
		padding: 8,
	},

	outlined: {
		transform: 'translate(14px, 10px) scale(1)',
	},
});

export default styles;