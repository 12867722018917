const styles = theme => ({
	root: {
		backgroundColor: 'white',
		borderRadius: '10px',
		bottom: 0,
		boxShadow: theme.shadows[5],
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: '350px',
		left: 0,
		margin: 'auto',
		minHeight: '0',
		outline: 0,
		padding: '20px',
		position: 'absolute',
		right: 0,
		top: 0,
		width: '250px',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	text: {
		width: '100%',
		minHeight: '10px',
		alignItems: 'center',
		display: 'flex',
		padding: '0 10px',
		boxSizing: 'border-box',
	},

	title: {
		fontSize: '18px',
	},

	nicknames: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		margin: '10px',
		overflowY: 'auto',
		scrollbarWidth: 'thin', //FIREFOX
		//CHROME
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},

	errorBar: {
		width: '14vw',
		height: 'auto',
		alignSelf: 'center',
		backgroundColor: '#ff0000e5',
		borderRadius: '5px',
		boxShadow: theme.shadows[5],
		position: 'fixed',
	},

	errorText: {
		color: '#fff',
	},
});

export default styles;