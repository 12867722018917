import React from 'react';
import Modal from '../UI/Modal/Modal';
import UserSettingsWrapper from './UserSettings/UserSettingsWrapper';
import withStyles from '@material-ui/styles/withStyles';
import { styles } from './UserSettingsModal.styles';
import classnames from 'classnames';

const UserSettingsModal = ({
	classes,
	className,
	onClose,
}) => {

	const userModal = <UserSettingsWrapper className={classes.content} onCloseModal={onClose} />;

	return (
		<Modal
			className={classnames(classes.root, className)}
			closeHandler={onClose}
			content={userModal}
			overrideRetardedEscape={true}
		/>
	);
};

export default withStyles(styles)(UserSettingsModal);