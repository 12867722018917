import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid, Switch, FormControl, RadioGroup, FormControlLabel, Radio, Button } from "@material-ui/core";
import CheckedIcon from "@material-ui/icons/CheckBox";
import UncheckedIcon from "@material-ui/icons/CheckBoxOutlineBlank";

import { styles } from "./ToggleAvailabilityButton.styles";
import { getAssetStatus } from "../../../../asset.utils";
import Permission from "../../../../../../app/components/Permission";
import Modal from "../../../../../../common/displays/Modal/Modal";
import { assetMakeAvailable, assetMakeUnavailable } from "../../../../assetSlice";
import { selectIsCallsLoading } from "../../../../../../app/coreSlice";

function ToggleAvailabilityButton({ classes, asset }) {
  const dispatch = useDispatch();
  const status = getAssetStatus(asset || {});
  const [modal, setModal] = useState(false);
  const [reason, setReason] = useState("M");
  const loading = useSelector(selectIsCallsLoading(["ASSET/MAKE_AVAILABLE", "ASSET/MAKE_UNAVAILABLE"]));

  const availabilityHandler = () => {
    asset.operational ? setModal(true) : dispatch(assetMakeAvailable({ carToken: asset.assetToken }));
  };

  const confirmUnavailableHandler = () => {
    setModal(false);
    dispatch(assetMakeUnavailable({ carToken: asset.assetToken, maintenance: reason === "M" }));
  };

  return (status === "AVAILABLE" || status === "UNUSED" || status === "UNAVAILABLE") && asset?.active ? (
    <div className={classes.root}>
      <Permission code="fleet.assets.edit">
        Available
        <br />
        <Switch disableRipple disabled={loading} checked={asset.operational} onChange={availabilityHandler} />
        <Modal open={modal} setOpen={setModal} sizeClass="small">
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            style={{ height: "100%" }}
          >
            <Grid item>
              <Typography align="center">
                <strong>Alert</strong>
              </Typography>
              <br />
              <br />
              <Typography style={{ fontSize: "12px" }}>
                You are about to put the car unavailable. It will still be active, but your customers will no longer be
                able to see it in the mobile app nor book it.
              </Typography>
            </Grid>
            <Grid item>
              <FormControl component="fieldset" className={classes.form}>
                <RadioGroup className={classes.radioGroup} value={reason} onChange={(event, value) => setReason(value)}>
                  {["Maintenance", "Inspection", "Out Of Service"].map(
                    (
                      element // TODO change this to ENUM
                    ) => (
                      <FormControlLabel
                        key={element}
                        value={element.slice(0, 1).toUpperCase()}
                        classes={{ label: classes.font }}
                        control={
                          <Radio
                            icon={<UncheckedIcon />}
                            checkedIcon={<CheckedIcon />}
                            disableRipple
                            classes={{ root: classes.radio, checked: classes.checked }}
                          />
                        }
                        label={element}
                      />
                    )
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <Grid container justifyContent="space-evenly" direction="row">
                <Grid item>
                  <Button className={classes.button} variant="contained" size="small" onClick={() => setModal(false)}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={confirmUnavailableHandler}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      </Permission>
    </div>
  ) : null;
}

export default withStyles(styles)(ToggleAvailabilityButton);
