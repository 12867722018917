import { FilterList as Icon } from "@material-ui/icons";

const button = (icon, text, options, right = false) => {
	return {
		icon,
		text,
		right,
		options,
	};
};

const buttonOption = (text, filter, right = false) => {

	return {
		text,
		right,
		filter,
	};
};

export const incidentStatusButtonOptions = []
	.concat(buttonOption(
		'All Incident Status',
		undefined,
	))
	.concat(buttonOption(
		'Open',
		'open',
	))
	.concat(buttonOption(
		'Closed',
		'closed',
	))
	.concat(buttonOption(
		'Paid',
		'paid',
	));

export const incidentStatusButton = button(Icon, 'All Incident Status', incidentStatusButtonOptions);