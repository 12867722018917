import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import Header from './Header';
import chatService from '../../../../../../services/chat';
import log from '../../../../../../utils/logger';

const logger = log('ChatHeaderWrapper');

const HeaderWrapper = ({
	changeContacts,
	contact,
	...props
}) => {
	const [anchorEl, setAnchorEl] = useState();
	const [edit, setEdit] = useState(false);
	const [members, setMembers] = useState([]);
	const [modal, setModal] = useState(false);
	const [nickname, setNickname] = useState(contact?.nickname || contact?.name);

	const textRef = useRef();

	useEffect(() =>{
		setEdit(false);
	}, [contact]);

	useEffect(() => {
		setNickname(contact?.nickname || contact?.name);
	}, [contact]);

	const getMembers = useCallback(async () => {
		if (!!contact?.groupId) {
			try {
				const membersList = await chatService.getGroupMembers(contact.groupId);

				setMembers(membersList);
			} catch (e) {
				logger.warn(e.description || e.message || 'Could not get group members');
				setMembers([]);
			};
		} else {
			setMembers([]);
		};
	}, [contact]);

	useEffect(() => {
		getMembers();
	}, [getMembers]);

	const onSave = useCallback(async data => {
		const { groupId } = contact;

		const name = data ? data.nickname : nickname;
		const customerCode = data?.customerCode || contact.target;

		if (!name || !name.trim()) {
			return setNickname(contact?.nickname || contact?.name);
		};

		try {
			await chatService.updateNickname({ groupId, nickname: name, customerCode });

			!!groupId && getMembers();
			!groupId && changeContacts(prev => prev.map(contact => contact.target === customerCode
				? { ...contact, nickname }
				: { ...contact })
			);

			setEdit(false);
			
			return false;
		} catch (e) {
			logger.warn(e.message || e.description || 'Could not update nickname');

			setNickname(contact?.nickname || contact?.name);
			setEdit(false);

			return e.message || e.description || 'Could not update nickname';
		};
	}, [nickname, contact, getMembers, changeContacts]);

	const onEdit = useCallback(() => {
		setAnchorEl();
		setEdit(true);

		setTimeout(() => textRef.current.focus(),100);
	}, []);

	const isAdmin = useMemo(() => Array.isArray(members) && members.find(member =>
		 member?.customerCode === contact?.customer)?.admin, [contact, members]);

	return (
		<Header
			{...props}
			anchorEl={anchorEl}
			edit={edit}
			groupId={contact?.groupId}
			isAdmin={isAdmin}
			members={members}
			modal={modal}
			nickname={nickname}
			onSave={onSave}
			setAnchorEl={setAnchorEl}
			setEdit={onEdit}
			setModal={setModal}
			setNickname={setNickname}
			textRef={textRef}
		/>
	);
};

export default HeaderWrapper;