import acceptGroup from './acceptGroup';
import addContact from './addContact';
import getChatHistory from './getChatHistory';
import getContacts from './getContacts';
import getGroupMembers from './getGroupMembers';
import getGroups from './getGroups';
import getPending from './getPending';
import removeContact from './removeContact';
import sendMessage from './sendMessage';
import updateChat from './updateChat';
import updateNickname from './updateNickname';

const chatService = {
	acceptGroup,
	addContact,
	getChatHistory,
	getContacts,
	getGroupMembers,
	getGroups,
	getPending,
	removeContact,
	sendMessage,
	updateChat,
	updateNickname,
};

export default chatService;