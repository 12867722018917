export const styles = () => ({
  root: {
    position: "relative",
  },
  icon: {
    cursor: "pointer"
  },
  list: {
    position: "absolute",
    right: 0,
    zIndex: 200,
    padding: 5,
    minWidth: 100,
  },
});
