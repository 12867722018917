const styles = theme => ({
	root: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		minHeight: '160px',
		maxHeight: '160px',
		width: '100%',
		margin: '15px 0',
	},

	titleDiv: {
		alignItems: 'first baseline',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},

	titleDivOverride: {
		alignItems: 'first baseline',
		display: 'flex',
		flexDirection: 'column',
	},

	selected: {
		backgroundColor: '#6668',
	},

	title: {
		fontSize: '16px',
		width: '100%',
	},

	error: {
		color: '#f01',
	},

	listHeader: {
		marginTop: '10px',
	},

	button: {
		color: '#009AC9',
		cursor: 'pointer',
		width: 18,
		height: 18,
	},

	disabled: {
		color: '#8888',
		cursor: 'default',
	},

	item: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		wordBreak: 'break-word',
	},

	listItem: {
		width: '100%',
		paddingTop: '3px',
		paddingBottom: '3px',
		cursor: 'pointer',
	},

	header: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
	},

	label: {
		flex: 1,
	},

	buttons: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginRight: '15px',
		minWidth: '50px',
	},

	list: {
		height: '100%',
		overflowY: 'auto',
		scrollbarWidth: 'thin', //FIREFOX
		width: '100%',
		//CHROME
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},
});

export default styles;