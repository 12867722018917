import Incident from '../../../../model/Incident/Incident';

export const fromDTO = ({
	administrativeCost,
	attachment,
	carToken,
	closed,
	createDate,
	customerCode,
	description,
	id,
	incidentCode,
	incidentCost,
	incidentType,
	invoiced,
	invoiceId,
	lastModified,
	liabilityPercentage,
	licensePlate,
	paid,
	pdfUrl,
	reservationCode,
}) => new Incident({
	administrativeCost,
	attachment,
	carToken,
	closed,
	createDate,
	customerCode,
	description,
	id,
	incidentCode,
	incidentCost,
	incidentType,
	invoiced,
	invoiceId,
	lastModified,
	liabilityPercentage,
	licensePlate,
	paid,
	pdfUrl,
	reservationCode,
});

export const toDTO = () => alert('Mapper Undefined');

const mapper = {
	fromDTO,
	toDTO,
};

export default mapper;