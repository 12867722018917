
export const styles = theme => ({

	root: {
		width: '100%',
		flexBasis: '60%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
	},

});