import React from 'react';
import {
	withStyles,
} from '@material-ui/core';
import { styles } from './RateDetails.styles';
import classnames from 'classnames';
import SimpleInputMapper from '../../TripApp/TripDetails/Utils/SimpleInputMapper';
import { currencyFormatter } from '../../../services/formatter';


const RateDetails = ({
	classes,
	placeholderRate,
}) => {
	const {
		name,
		rateNumber,
		priceInUse,
		priceInStandby,
		priceKm,
		goldPinInUse,
		goldPinInStandby,
	} = placeholderRate || {};

	const priceInUseWithSymbol = !!priceInUse
		? currencyFormatter.format(priceInUse) + ' ' + currencyFormatter.symbol()
		: null;

	const priceInStandbyWithSymbol = !!priceInStandby
		? currencyFormatter.format(priceInStandby) + ' ' + currencyFormatter.symbol()
		: null;

	const priceKmWithSymbol = !!priceKm
		? currencyFormatter.format(priceKm) + ' ' + currencyFormatter.symbol()
		: null;

	const gpInUseWithSymbol = !!goldPinInUse
		? currencyFormatter.format(goldPinInUse) + ' ' + currencyFormatter.symbol()
		: null;

	const gpStandbyWithSymbol = !!goldPinInStandby
		? currencyFormatter.format(goldPinInStandby) + ' ' + currencyFormatter.symbol()
		: null;

	const rateNumberValue = rateNumber === 0 ? '0' : rateNumber;

	const rateDetailsMapperConfig = {
		'Name:': name || '-',
		'No.:': rateNumberValue ||  '-',
		'In Use:': priceInUseWithSymbol || '-',
		'Stand By:': priceInStandbyWithSymbol || '-',
		'Km:': priceKmWithSymbol || '-',
		'Gold Pin In Use:': gpInUseWithSymbol || '-',
		'Gold Pin Stand By:': gpStandbyWithSymbol || '-',
	};

	const rateDetailsStyled = (
		<SimpleInputMapper
			title='Rate Details'
			titleClassName={classes.textFieldTitle}
			inputmapperconfig={rateDetailsMapperConfig}
		/>
	);

	return (
		<div
			className={classnames(classes.root)}
		>
			<div className={classes.detailsBlock}>
				{rateDetailsStyled}
			</div>
		</div>
	);
};

export default withStyles(styles)(RateDetails);