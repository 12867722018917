export const styles = theme => ({

	root: {
		width: '532px',
		height: '733px',
		padding: '8px',
	},

	flexVertically: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
	},

	dates: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		flexBasis: '60%',
		alignItems: 'center',
	},

	field: {
		width: '50%',
	},

	equalMargin: {
		margin: '8px 0px',
	},

	btns: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
	},

	inputIcon: {
		padding: '2px',
	},

	title: {
		fontSize: '22px',
	},

	amountTextField: {
		padding: '0px 6px',
	},

	codeCheckbox: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
	},

	checkboxTitle: {
		alignSelf: 'center',
	},

	greyTextField: {
		backgroundColor: '#F0F0F0',
	},

	labelProps: {
		zIndex: 999,
	},

	codeField: {
		marginTop: 0,
	},

});
