import React from "react";
import { useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Tooltip } from "@material-ui/core";

import { styles } from "./AlertItemIcon.styles";
import BillingIcon from "./icons/BillingIcon";
import DeviceIcon from "./icons/DeviceIcon";
import ReservationIcon from "./icons/ReservationIcon";
import PersonIcon from "@material-ui/icons/Person";
import { selectCredentials } from "../../../../../../app/websocket/websocketSlice";

function AlertItemIcon({ classes, category, resolved, ongoing, user, priority }) {
  const credentials = useSelector(selectCredentials);
  return (
    <Tooltip title={getTooltip(resolved, ongoing, user, credentials.username)} placement="top">
      <div
        className={
          classes.root +
          (resolved
            ? " " + classes.resolved
            : ongoing
            ? " " + classes.ongoing
            : (priority || "").toUpperCase() === "WARNING"
            ? " " + classes.warning
            : "")
        }
      >
        {getIcon(category)}
      </div>
    </Tooltip>
  );
}

export default withStyles(styles)(AlertItemIcon);

const getTooltip = (resolved, ongoing, user, loggedUser) => {
  if (resolved) {
    return "RESOLVED" + (user === loggedUser ? " (Me)" : " (" + user + ")");
  }
  if (ongoing) {
    return "ONGOING" + (user === loggedUser ? " (Me)" : " (" + user + ")");
  }
  return "PENDING";
};

const getIcon = (category) => {
  switch (category) {
    case "Device":
      return <DeviceIcon />;
    case "Reservation":
      return <ReservationIcon />;
    case "Billing":
      return <BillingIcon />;
    case "Customer":
      return <PersonIcon />;
    default:
      return null;
  }
};
