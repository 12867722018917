export const hasPath = (acc, path) => !!acc && acc[path];

export const changePermissions = permissions => prev => {
	const currentPermissions = { ...prev.permissions };

	if (permissions.reduce(hasPath, currentPermissions)) {
		return {
			...prev,
			permissions: remove(currentPermissions, permissions) || {},
		};
	}

	permissions.reduce(add, currentPermissions);

	return { ...prev, permissions: currentPermissions };
};

const remove = (permissions, path) => {

	const currentPath = path[0];

	if (typeof permissions === 'object' && path.length > 1) {
		remove(permissions[currentPath], path.slice(1));

		Object.keys(permissions[currentPath]).length === 0 &&
			delete permissions[currentPath];
	}

	path.length === 1 && delete permissions[currentPath];

	return permissions;
};

const add = (acc, path, index, array) => {
	if (index === array.length - 1) {
		acc[path] = true;
		return acc;
	}

	if (!acc[path]) {
		acc[path] = {};
	}

	return acc[path];
};

export const addAll = (path, obj) => prev => {

	const permissions = { ...prev.permissions };
	let removeAll = true;

	const add = current => (acc, path) => {

		if (!acc[path]) {
			acc[path] = typeof current[path] === 'boolean' ? true : {};
			removeAll = false;
		};

		if (typeof current[path] === 'object') {
			acc[path] = Object.keys(current[path])
				.reduce(add(current[path]), acc[path] || {});
		}

		return acc;
	};

	const addPath = (acc, path, index, array) => {

		if (!index && !array) {
			acc = Object.keys(obj).reduce(add(obj), acc || {});

			removeAll && Object.keys(acc).forEach(key => delete acc[key]);

			return acc;
		}

		if (index === array.length - 1) {
			acc[path] = Object.keys(obj).reduce(add(obj), acc[path] || {});
			
			removeAll && delete acc[path];

			return acc;
		}

		if (!acc[path]) {
			acc[path] = {};
		}

		return acc[path];
	};

	path ? path.reduce(addPath, permissions) : addPath(permissions);

	path && Object.values(permissions?.[path[0]] || {})?.length === 0 && delete permissions[path[0]];

	return { ...prev, permissions };
};