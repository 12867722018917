import React, { Component, useEffect, useRef, useState } from "react";
import supercluster from "points-cluster";
import DashboardAssetMarker from "./DashboardAssetMarker/DashboardAssetMarker";
import Cluster from "./Cluster/Cluster";
import MainMap from "./MainMap";
import WorkOrderMarker from "./WorkOrderMarker/WorkOrderMarker";
import { useSelector } from "react-redux";
import {
  selectActivityLogs,
  selectHeartbeats,
  selectSelectedAssetHeartbeats,
} from "../../features/bigdata/bigdataSlice";

import { useDateTime } from "../../common/hooks";

import { HeartbeatMarker, EventMarker, LocationMarker, AlertMarker } from "../../common/map";
import { selectUserLocations, userGetLocations, selectLoader } from "../../features/user/userSlice";
import { selectSelectedAlert } from "../../features/alerts/alertsSlice";
import { useDispatch } from "react-redux";
import AssetMarker from "../../common/map/markers/AssetMarker";

let map;
// let directionsRenderer;
let maps;

const MapAux = ({
  pos,
  markers,
  onMapChange,
  zoom,
  styles,
  onAPIloaded,
  onChildClick,
  onChildMouseEnter,
  onChildMouseLeave,
  selectedAsset,
}) => {
  const dispatch = useDispatch();
  const activityLogs = useSelector(selectActivityLogs);
  const heartbeats = useSelector(selectHeartbeats);
  const locations = useSelector(selectUserLocations);
  const loading = useSelector(selectLoader);
  const selectedAlert = useSelector(selectSelectedAlert);
  const [filteredHeartBeats, setFilteredHeartbeats] = useState([]);
  const selectedAssetHeartbeats = useSelector(selectSelectedAssetHeartbeats);

  const lines = useRef();
  const assetLines = useRef();

  const { toTimeZone } = useDateTime();

  const onAPIloadedHandler = (google) => {
    map = google?.map;
    maps = google?.maps;
    onAPIloaded && onAPIloaded(google);
  };

  const updateHeartbeatsRoutes = (hbs) => {
    if (hbs && hbs.length > 1) {
      lines.current = new maps.Polyline({
        path: hbs,
        geodesic: true,
        strokeColor: "#60bdda",
        strokeWeight: 2,
      });
      lines.current.setMap(map);
    } else {
      if (lines.current) {
        lines.current.setMap(null);
      }
    }
  };

  useEffect(() => {
    if (!locations && !loading) {
      dispatch(userGetLocations());
    }
    // eslint-disable-next-line
  }, [locations, loading]);

  useEffect(() => {
    let hbs = [];
    if (heartbeats) {
      (heartbeats?.list || [])
        .filter(
          (item) =>
            item.heartbeat?.latitude &&
            item.heartbeat?.longitude &&
            item.heartbeat?.latitude !== 0 &&
            item.heartbeat?.longitude !== 0
        )
        .forEach((element) => {
          if (!hbs.find((hb) => hb.lat === element.heartbeat?.latitude && hb.lng === element.heartbeat?.longitude)) {
            hbs.push({
              lat: element.heartbeat?.latitude,
              lng: element.heartbeat?.longitude,
              date: element.heartbeat?.position_date,
            });
          }
        });
    }
    hbs = hbs.sort((a, b) => (a.date < b.date ? -1 : 1));
    // console.log("hbs: %o", hbs);
    setFilteredHeartbeats(hbs);
    updateHeartbeatsRoutes(hbs);
    //eslint-disable-next-line
  }, [heartbeats]);

  useEffect(() => {
    // console.log('HERE - selectedAsset?.assetToken: %o', selectedAsset?.assetToken);
    if (assetLines.current) {
      assetLines.current.setMap(null);
    }
    if (
      selectedAssetHeartbeats &&
      selectedAsset?.assetToken &&
      selectedAsset?.assetToken === selectedAssetHeartbeats.assetToken
    ) {
      // console.log("DRAW LINES!!!");
      updateHeartbeatsRoutes();
      let path = (selectedAssetHeartbeats?.list || [])
        .filter(
          (item) =>
            item.heartbeat?.latitude &&
            item.heartbeat?.longitude &&
            item.heartbeat?.latitude !== 0 &&
            item.heartbeat?.longitude !== 0
        )
        .map((element) => {
          return {
            lat: element.heartbeat?.latitude,
            lng: element.heartbeat?.longitude,
          };
        });
      assetLines.current = new maps.Polyline({
        path,
        geodesic: true,
        strokeColor: "#60bdda",
        strokeWeight: 2,
      });
      assetLines.current.setMap(map);
    }
    // else {

    //   console.log("HERE!!!");
    //   console.log("selectedAsset?.assetToken: %o", selectedAsset?.assetToken);
    //   console.log("selectedAssetHeartbeats.assetToken: %o", selectedAssetHeartbeats.assetToken);
    // }
  }, [selectedAssetHeartbeats, selectedAsset]);

  // console.log("activityLogs: %o", activityLogs);
  // console.log("heartbeats: %o", heartbeats);

  let logsMarkers = [];
  let heartbeatMarkers = [];

  if (activityLogs) {
    logsMarkers = (activityLogs || [])
      .filter(
        (item) =>
          item.comms?.latitude && item.comms?.longitude && item.comms?.latitude !== 0 && item.comms?.longitude !== 0
      )
      .map((item, index) => (
        <EventMarker key={"log_" + index} lat={item?.comms?.latitude} lng={item?.comms?.longitude} event={item} />
      ));
  }
  heartbeatMarkers = filteredHeartBeats.map((item, index) => (
    <HeartbeatMarker
      key={"hb_" + index}
      lat={item.lat}
      lng={item.lng}
      tooltip={toTimeZone(item.date, "dateTimeComplete")}
    />
  ));

  const locationMarkers = (locations || []).map((location, index) => (
    <LocationMarker key={"loc_" + index} lat={location.latitude} lng={location.longitude} location={location} />
  ));

  const alertMarker = selectedAlert && selectedAlert?.payload?.latitude && selectedAlert?.payload?.longitude && (
    <AlertMarker
      key="alert"
      lat={selectedAlert?.payload?.latitude}
      lng={selectedAlert?.payload?.longitude}
      alert={selectedAlert}
    />
  );

  const assetHeartbeatsMarkers =
    selectedAssetHeartbeats &&
    selectedAsset?.assetToken &&
    selectedAsset?.assetToken === selectedAssetHeartbeats.assetToken
      ? (selectedAssetHeartbeats?.list || []).map((item, index) => (
          <HeartbeatMarker
            key={"ahb_" + index}
            lat={item.heartbeat?.latitude}
            lng={item.heartbeat?.longitude}
            tooltip={toTimeZone(item.heartbeat?.position_date, "dateTimeComplete")}
          />
        ))
      : [];

  return (
    <MainMap
      pos={pos}
      markers={[
        ...locationMarkers,
        ...assetHeartbeatsMarkers,
        ...markers,
        ...heartbeatMarkers,
        ...logsMarkers,
        alertMarker,
      ]}
      onMapChange={onMapChange}
      zoom={zoom}
      styles={styles}
      onAPIloaded={onAPIloadedHandler}
      onChildClick={onChildClick}
      onChildMouseEnter={onChildMouseEnter}
      onChildMouseLeave={onChildMouseLeave}
    />
  );
};
class MapCore extends Component {
  state = {
    mapOptions: {},
    clusters: [],
  };

  mounted = false;

  getClusters = () => {
    const { markers = [] } = this.props;

    const filteredMarkers = markers.filter((marker) => !!marker.lat && !!marker.lng);

    const clusters = supercluster(filteredMarkers, {
      minZoom: 0,
      maxZoom: 12,
      radius: 30,
    });

    return clusters(this.state.mapOptions);
  };

  createClusters = (props) => {
    this.mounted &&
      this.setState({
        clusters: this.state.mapOptions.bounds
          ? this.getClusters(props).map(({ wx, wy, numPoints, points }) => ({
              lat: wy,
              lng: wx,
              numPoints,
              id: points[0].assetToken,
              points,
            }))
          : [],
      });
  };

  handleMapChange = ({ center, zoom, bounds }) => {
    this.setState(
      {
        mapOptions: {
          center,
          zoom,
          bounds,
        },
      },
      this.createClusters
    );
  };

  clusterOnClick(lat, lng) {
    this.props.centerMapHandler(lat, lng, 13);
  }

  drawZones(asset, zonePainter, hidePolygons) {
    hidePolygons && hidePolygons();

    if (!asset || !asset.zones || !zonePainter) {
      return;
    }

    for (let zone in asset.zones) {
      zonePainter(asset.zones[zone]);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if ((nextProps.selectedAsset || {}).assetToken !== (this.props.selectedAsset || {}).assetToken) {
      this.drawZones(nextProps.selectedAsset, nextProps.zonePainter, nextProps.hidePolygons);
    }

    if (nextProps.markers !== this.props.markers) {
      this.createClustersId = setTimeout(this.createClusters, 0);
    }

    return nextProps !== this.props || nextState !== this.state;
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    if (this.createClustersId) {
      clearTimeout(this.createClustersId);
    }
    this.mounted = false;
  }

  render() {
    const {
      selectedAsset,
      selectAsset,
      onAPIloaded,
      dashboardAssets,
      selectedWorkOrder,
      setSelectedWorkOrder,
      workOrders,
      onChildClick,
      onChildMouseEnter,
      onChildMouseLeave,
      styles,
    } = this.props;

    let selectedMarker = null;

    const markers = this.state.clusters.map((cluster) => {
      if (cluster.numPoints !== 1) {
        return (
          <Cluster
            key={cluster.id}
            lat={cluster.lat}
            lng={cluster.lng}
            numPoints={cluster.numPoints}
            onClick={() => this.clusterOnClick(cluster.lat, cluster.lng)}
          />
        );
      }

      const isSelected = selectedAsset && cluster.points[0].assetToken === selectedAsset.assetToken;
      if (isSelected) {
        selectedMarker = (
          <AssetMarker
            key={cluster.id}
            lat={cluster.lat}
            lng={cluster.lng}
            asset={cluster.points[0]}
            // disabled={selectedAsset && cluster.points[0].assetToken !== selectedAsset.assetToken}
            selected={selectedAsset && cluster.points[0].assetToken === selectedAsset.assetToken}
            selectAsset={selectAsset}
          />
        );
      }
      return isSelected ? null : (
        <AssetMarker
          key={cluster.id}
          lat={cluster.lat}
          lng={cluster.lng}
          asset={cluster.points[0]}
          // disabled={selectedAsset && cluster.points[0].assetToken !== selectedAsset.assetToken}
          selected={selectedAsset && cluster.points[0].assetToken === selectedAsset.assetToken}
          selectAsset={selectAsset}
        />
      );
    });

    const dashboardAssetMarkers =
      (Array.isArray(dashboardAssets) &&
        dashboardAssets
          .filter((asset) => !!asset.latitude && !!asset.longitude)
          .map((asset) => {
            const { assetToken, latitude, longitude } = asset;

            return (
              <DashboardAssetMarker
                {...{ asset }}
                asset={asset}
                selectedAsset={selectedAsset}
                key={`dashboardAsset#${assetToken}`}
                lat={latitude}
                lng={longitude}
              />
            );
          })) ||
      [];
    const workOrderMarkers =
      (Array.isArray(workOrders) &&
        workOrders
          .filter((workOrder) => !!workOrder.lat && !!workOrder.lng)
          .map((workOrder) => {
            const { code, lat, lng } = workOrder;
            return (
              <WorkOrderMarker
                key={`Foo${code}`}
                workOrder={workOrder}
                code={code}
                lat={lat}
                lng={lng}
                selectedWorkOrder={selectedWorkOrder}
                setSelectedWorkOrder={setSelectedWorkOrder}
              />
            );
          })) ||
      [];

    return (
      <MapAux
        pos={this.props.pos}
        markers={[...markers, selectedMarker, ...dashboardAssetMarkers, ...workOrderMarkers]}
        onMapChange={this.handleMapChange}
        zoom={this.props.zoom}
        styles={styles}
        onAPIloaded={onAPIloaded}
        onChildClick={onChildClick}
        onChildMouseEnter={onChildMouseEnter}
        onChildMouseLeave={onChildMouseLeave}
        selectedAsset={selectedAsset}
      />
    );
  }
}

export default MapCore;
