import React from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid, Button, Divider } from "@material-ui/core";

import { styles } from "./Payments.styles";
import { formFields, formSchema, formBillingFields } from "./Payments.schema";

import { Form, FormFields } from "../../../../common/forms";

import {
  selectCarclubConfigs,
  carclubUpdateConfigs,
  selectBillingEngines,
  selectCarclubDefaultConfigs,
  carclubUpdateDefaultConfigs,
} from "../../carclubSlice";
import { useCurrency } from "../../../../common/hooks";

function Payments({ classes, disabled, submitRef, isDefaultConfigs }) {
  const dispatch = useDispatch();
  const defaultConfigs = useSelector(selectCarclubDefaultConfigs);

  const locationConfigs = useSelector(selectCarclubConfigs);
  const configs = isDefaultConfigs ? defaultConfigs : locationConfigs;
  const billingEngines = useSelector(selectBillingEngines);

  const { symbol } = useCurrency();

  const onSubmit = async (values) => {
    let accountId = isDefaultConfigs ? undefined : (values.STRIPE?.accountId || "");
    let CONFIGURATION = { ...configs.CONFIGURATION, ...values.CONFIGURATION };
    let BILLING = { ...configs.BILLING, ...values.BILLING };
    let STRIPE = { ...configs.STRIPE, ...values.STRIPE, accountId };
    let BRAINTREE = { ...configs.BRAINTREE, ...values.BRAINTREE };
    let INVOICE_EXPRESS = { ...configs.INVOICE_EXPRESS, ...values.INVOICE_EXPRESS };
    let nConfigs = { ...configs, CONFIGURATION, BILLING, STRIPE, BRAINTREE, INVOICE_EXPRESS };

    if (isDefaultConfigs) {
      dispatch(carclubUpdateDefaultConfigs(nConfigs));
    } else {
      dispatch(carclubUpdateConfigs(nConfigs));
    }
  };

  let initialValues = configs;

  return (
    <div className={classes.root}>
      <Form
        schema={formSchema()}
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ submitting, values }) => {
          return (
            <>
              <Grid container spacing={3} wrap="nowrap">
                <Grid item xs={6}>
                  <FormFields fields={formFields({ disabled, values, billingEngines, isDefaultConfigs })} />
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={4}>
                  <Typography className={classes.title}>Billing Rules</Typography>
                  <FormFields fields={formBillingFields({ disabled, currencySymbol: symbol, values })} />
                </Grid>
              </Grid>
              <Button
                style={{ display: "none" }}
                ref={submitRef}
                type="submit"
                color="primary"
                variant="contained"
                disabled={submitting}
              >
                Submit
              </Button>
            </>
          );
        }}
      />
    </div>
  );
}

export default withStyles(styles)(Payments);
