export const styles = (theme) => ({
  root: {
    height: "100%",
    padding: "0 10px",
  },
  header: {
    borderBottom: "2px solid " + theme.palette.primary.main,
    padding: "5px 0",
  },
  title: {
    fontSize: 16,
    color: "#a1a1a1",
  },
  footer: {
    borderTop: "2px solid #a1a1a1",
    padding: "20px 0 0 0",
  },
  button: {
    minWidth: 140,
  },
});
