const styles = (theme) => ({

	root: {
		width: "42px",
		height: "42px",
		margin: theme.spacing(1),
	},

	cube: {
		width: "33.33%",
		height: "33.33%",
		backgroundColor: theme.palette.primary.main,
		float: "left",
		animation: "$cubeGridScaleDelay 1.3s infinite ease-in-out", 
	},
	
	"@keyframes cubeGridScaleDelay": {
		"0%, 70%, 100%": {
			transform: "scale3D(1, 1, 1)",
		},
		"35%": {
			transform: "scale3D(0, 0, 1)",
		},
	},

	cube1: {
		animationDelay: "0.2s",
	},
  
	cube2: {
		animationDelay: "0.3s",
	},
  
	cube3: {
		animationDelay: "0.4s",
	},
  
	cube4: {
		animationDelay: "0.1s",
	},

	cube5: {
		animationDelay: "0.2s",
	},

	cube6: {
		animationDelay: "0.3s",
	},

	cube7: {
		animationDelay: "0s",
	},

	cube8: {
		animationDelay: "0.1s",
	},

	cube9: {
		animationDelay: "0.2s",
	},

});

export default styles;