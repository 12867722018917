import * as Yup from "yup";
import { TextField, SwitchField, SelectField } from "../../../../common/forms";

export function formSchema() {
  return Yup.object().shape({
    notification: Yup.object().shape({
      provider: Yup.string().when("active", {
        is: true,
        then: (schema) => schema.required("Provider is required"),
        otherwise: (schema) => schema,
      }),
      url: Yup.string().when("active", {
        is: true,
        then: (schema) => schema.required("Url is required"),
        otherwise: (schema) => schema,
      }),
    }),
  });
}

export function formFieldsNotification(disabled) {
  const providerOptions = ["", "MOD"].map((item) => {
    return {
      value: item,
      text: item,
    };
  });
  return [
    {
      size: 12,
      field: <SwitchField id="notification.active" label="Active" disabled={disabled} />,
    },
    {
      size: 12,
      field: <SelectField id="notification.provider" label="Provider" options={providerOptions} disabled={disabled} />,
    },
    {
      size: 12,
      field: <TextField id="notification.url" type="text" label="Url" disabled={disabled} />,
    },
    {
      size: 12,
      field: <TextField id="notification.apiHeader" type="text" label="Api Header" disabled={disabled} />,
    },
    {
      size: 12,
      field: <TextField id="notification.apiKey" type="text" label="Api Key" disabled={disabled} />,
    },
  ];
}

// active: true
// apiHeader: "X-TS-MOD-API-KEY"
// apiKey: "1ce4ad6c-a0c3-46c9-bffc-8a452c4c3d4d"
// provider: "MOD"
// url: "https://modapi.transsight.com/api/webhook/ev
