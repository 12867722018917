import React from 'react';
import {
	Divider,
	Typography,
	withStyles,
} from '@material-ui/core';
import {
	Check as CheckIcon,
} from '@material-ui/icons';
import { styles } from './WorkOrderDetails.styles';
import classnames from 'classnames';
import DetailDiv from '../../../UI/DetailsDiv/DetailsDiv';
import DetailsRow from '../../../UI/DetailsDiv/DetailsRow/DetailsRow';
import { details, task } from './mappers';

const divider = <Divider key='divider' style={{ margin: '10px 0' }} />;

const getState = task => task.startDate ? 'In process' : 'Pending';

const WorkOrderDetails = ({
	classes,
	servicePoints,
	tasks = [],
	workOrder,
}) => {
	if (!workOrder) {
		return (
			<div className={classes.root}>
				<DetailDiv title='Work Order Details' className={classes}>
					<Typography>Select work order</Typography>
				</DetailDiv>
			</div>
		);
	};

	const createRow = fieldMapper(classes);
	const workOrderDetailsMapper = details({
		...workOrder,
		servicePoint: servicePoints?.find(({ code }) => code === workOrder?.servicePointCode)?.name,
	}).map(createRow);

	const taskDetailsMapper = tasks.map((task, index) => createRow({
		key: `${index}_${task.type}`,
		label: task.type,
		detail: task.endDate
			? <CheckIcon className={classes.checkIcon} />
			: getState(task),
	}));

	const taskExtraDetailsMapper = task(tasks).map(createRow);

	const workOrderDetails = (
		<DetailDiv title='Work Order Details' className={classes}>
			{divider}
			{workOrderDetailsMapper}
		</DetailDiv>
	);

	const taskDetails = (
		<DetailDiv title='Task Details'>
			{divider}
			{taskDetailsMapper}
			{taskExtraDetailsMapper}
		</DetailDiv>
	);


	return (
		<div className={classnames(classes.root)}>
			{workOrderDetails}
			{taskDetails}
		</div>
	);
};

export default withStyles(styles)(WorkOrderDetails);

const fieldMapper = classes => ({ key, label, detail, column }) => !!label
	? (
		<DetailsRow
			className={{ title: classes.label }}
			column={column}
			detail={<Typography className={classes.detail}>{detail}</Typography>}
			key={`detail_${key}_${label}`}
			label={label}
		/>
	)
	: divider;