/**
 * Mutates an ImmutableJS state object. It ensures that only one overal change occurs,
 * independently of the number of mutations performed (see #Immutable.withMutations()).
 * 
 * @param {Array} fns mutators to be applied in sequence
 * @param {object} state immutableJS object
 * 
 * @returns ImmutableJS state object after mutations
 */
export const pipe = (fns, state) => {
	
	const applyFn = (state, fn) => {
		return fn(state);
	};

	return state.withMutations( s => {
		return fns.reduce(applyFn, s);
	});
};

