export const styles = () => ({
  root: {
    width: 600,
    border: "1px solid #ddd",
    borderRadius: 5,
    boxShadow: "1px 1px 5px gray",
    padding: "0 10px",
    "& .FormFieldError": {
      display: "none",
    },
  },
  date: {
    width: 180,
  },
  title: {
		fontSize: '18px',
		marginRight: '10px',
	},
});
