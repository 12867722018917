import store from "../../store";
import DateFormatter from "./dateFormatter";
import CurrencyFormatter from "./currencyFormatter";

/* Date formatter */

export const dateFormatter = new DateFormatter();

const handleDateFormatChange = () => {
  let previousPatterns = dateFormatter.patterns;

  let patterns = {};
  try {
    patterns = store.getState().getIn(["carclub", "patterns", "time"]).toJS();
  } catch {}
  const patternsKeys = Object.keys(patterns);

  if (patternsKeys.find((key) => previousPatterns[key] !== patterns[key])) {
    dateFormatter.patterns = {
      ...dateFormatter.patterns,
      ...patterns,
    };
  }

  let timezone = {};

  try {
    const selectedLocation = store.getState().getIn(["carclub2"])?.defaultLocation;
    timezone = selectedLocation?.timezone || store.getState().getIn(["carclub", "timezone"]);
  } catch {}

  if (dateFormatter.getTimezone() !== timezone) {
    dateFormatter.setTimezone(timezone);
  }
};

store.subscribe(handleDateFormatChange);

/* Currency formatter */

export const currencyFormatter = new CurrencyFormatter();

const handleCurrencyFormatChange = () => {
  let previousPatterns = currencyFormatter.patterns;

  let patterns = {};

  try {
    patterns = store.getState().getIn(["carclub", "patterns", "currency"]).toJS();
  } catch {}
  const patternsKeys = Object.keys(patterns);

  if (patternsKeys.find((key) => previousPatterns[key] !== patterns[key])) {
    currencyFormatter.patterns = {
      ...currencyFormatter.patterns,
      ...patterns,
    };
  }
};

store.subscribe(handleCurrencyFormatChange);
