import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid, Tooltip } from "@material-ui/core";
import { ReactComponent as ParkIcon } from "./icons/park.svg";

import { styles } from "./AssetSummary.styles";
import VehicleIcon from "../../../common/VehicleIcon/VehicleIcon";
import FuelPercentage from "../../../common/FuelPercentage/FuelPercentage";
import DeviceStatusIcon from "../../../AssetList/AssetItem/AssetSummary/DeviceStatusIcon/DeviceStatusIcon";

function AssetSummary({ classes, asset }) {
  return (
    <Grid
      container
      className={classes.root}
      direction="row"
      wrap="nowrap"
      alignItems="center"
      spacing={1}
      justifyContent="space-between"
    >
      <Grid item>
        <Grid container direction="row" wrap="nowrap" alignItems="center" spacing={1}>
          <Grid item>
            <VehicleIcon asset={asset} />
          </Grid>
          {asset.parks && asset.parks.length > 0 && (
            <Grid item>
              <Tooltip title={getParksTooltipList(asset.parks)}>
                <ParkIcon />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Typography>{asset?.licensePlate}</Typography>
      </Grid>
      <Grid item>
        <Grid container direction="row" wrap="nowrap" alignItems="center" spacing={1}>
          <Grid item>
            <FuelPercentage percentage={asset?.fuelLevel || null} fuelType={asset?.fuelType} />
          </Grid>
          {asset.deviceToken && (
            <Grid item>
              <DeviceStatusIcon asset={asset} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(AssetSummary);

const getParksTooltipList = (parks) => {
  let list = [];
  parks.forEach((park, index) => {
    if (park.inUse) {
      list.push(<b key={"park_" + index}>{park.parkName}</b>);
    } else {
      list.push(<span key={"park_" + index}>{park.parkName}</span>);
    }
    if (index !== parks.length) {
      list.push(<br key={"park_br_" + index} />);
    }
  });

  return list;
};
