import React from 'react';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import {
	BarChart as BarIcon,
	ShowChart as LineIcon,
} from '@material-ui/icons';
import Graph from './Graph';
import Cards from './Cards';
import styles from './Graphs.styles';

const Graphs = ({
	classes,
	color,
	metrics,
	selected,
	setSelected,
	setType,
	symbol,
	title,
	type,
}) => {
	if (metrics?.total !== 0 && !metrics?.total) return null;

	const {
		list,
		total,
		...rest
	} = metrics;

	const Icon = 'BAR' === type ? LineIcon : BarIcon;

	const changeChart = (
		<Icon
			className={classes.icon}
			onClick={() => setType('BAR' === type ? 'LINE' : 'BAR')}
			style={{ '--color': color }}
		/>
	);

	const header = (
		<Typography align='left' className={classes.title}>
			{title} {changeChart}
		</Typography>
	);

	const totals = (
		<Cards
			{...rest}
			classes={classes}
			color={color}
			selected={selected}
			setSelected={setSelected}
			symbol={symbol}
			total={total}
		/>
	);

	const graph = (
		<Graph
			classes={classes}
			color={color}
			list={list}
			selected={selected}
			symbol={symbol}
			type={type}
		/>
	);

	return (
		<div className={classes.root}>
			{header}
			{totals}
			{graph}
		</div>
	);
};

export default withStyles(styles)(Graphs);