import React from "react";
import withStyles from "@material-ui/styles/withStyles";

import { styles } from "./AssetProperty.styles";
import { TextInput } from "../../../../../common/inputs";

function AssetProperty({ classes, label, value }) {
  return (
    <div className={classes.root}>
      <TextInput className={classes.input} label={label} value={value || ""} disabled />
    </div>
  );
}

export default withStyles(styles)(AssetProperty);
