import { 
	SET_FILTER,
	SET_HASSEARCH,
	SET_BUTTONTIMEOUT,
} from './actionTypes';
import createReducer from '../../../utils/createComponentReducer';

export const initialState = ({
	filter,
}) => ({
	filter: '',
	hasSearch: false,
	buttonTimeout: false,
});

const setFilter = (state, action) => ({
	...state,
	filter: action.filter,
});

const setHasSearch = (state, action) => ({
	...state,
	hasSearch: action.hasSearch,
});

const setButtonTimeout = (state, action) => ({
	...state,
	buttonTimeout: action.buttonTimeout,
});

export const reducer = createReducer({
	[SET_FILTER]: setFilter,
	[SET_HASSEARCH]: setHasSearch,
	[SET_BUTTONTIMEOUT]: setButtonTimeout,
});