import React, { useEffect } from "react";
import menuButtons from "./menuButtons";
import TripDetails from "../../components/TripApp/TripDetails/TripDetails";
import VerticalMenuBar from "../../components/VerticalMenuBar/VerticalMenuBar";
import LocationModal from "../../components/UI/LocationModal/LocationModalWrapper";
import TripList from "../../components/TripApp/TripList/TripList";
import { ReactComponent as CardModeIcon } from "../../assets/TripApp/common/toggle_card.svg";
import { ReactComponent as ListModeIcon } from "../../assets/TripApp/common/toggle_list.svg";
import { DISPLAY_MODES, TRIP_TYPES } from "./reducer";
import { withStyles, Typography } from "@material-ui/core";
import styles from "./TripApp.styles";
import classnames from "classnames";
import { isEmpty } from "../../utils/check";
import OngoingReservationListFilter from "../../features/reservation/ReservationList/OngoingReservationListFilter/OngoingReservationListFilter";
import { useSelector } from "react-redux";
import {
  closedReservationsDefaultFilter,
  ongoingReservationsDefaultFilter,
  reservationClosedList,
  reservationOngoingList,
  selectClosedReservations,
  selectOngoingReservations,
  setSelectedTrip as setSelectedTripNew,
} from "../../features/reservation/reservationsSlice";
import ClosedReservationListFilter from "../../features/reservation/ReservationList/ClosedReservationListFilter/ClosedReservationListFilter";
import { useDispatch } from "react-redux";
import { selectDefaultLocation } from "../../features/carclub/carclubSlice";
import { ReactComponent as CreateIcon } from "../../assets/PromotionApp/icons/add.svg";
import ReservationCreateUpdate from "../../features/reservation/ReservationCreateUpdate/ReservationCreateUpdate";
import { isSharing, isSubscription } from "../../common/utils/OTypes";

const TripApp = ({
  classes,
  currentFilter,
  displayMode,
  lastPage,
  modal,
  onCardMode,
  removeTrip,
  selectedTrip,
  setDisplayModeCards,
  setDisplayModeList,
  setModal,
  setSelectedTrip,
  setShouldFilterWarnings,
  setTripTypeCurrent,
  setTripTypeHistory,
  shouldFilterWarnings,
  // trips,
  tripType,
  updateHistoryTrip,
  updateTrip,
  closeModalHandler,
  setScheduleTripToUpdate,
  scheduledTripToUpdate,
  closeUpdateModalHandler,
}) => {
  const dispatch = useDispatch();
  const ongoingReservations = useSelector(selectOngoingReservations);
  const closedReservations = useSelector(selectClosedReservations);
  const selectedLocation = useSelector(selectDefaultLocation);

  useEffect(() => {
    dispatch(setSelectedTripNew());
    if (tripType === TRIP_TYPES.HISTORY) {
      dispatch(reservationClosedList({ ...closedReservationsDefaultFilter, page: 1 }, true));
    } else {
      dispatch(reservationOngoingList({ ...ongoingReservationsDefaultFilter, page: 1 }, true));
    }
    // eslint-disable-next-line
  }, [tripType, selectedLocation]);

  const getMoreRowsHandler = () => {
    if (tripType === TRIP_TYPES.HISTORY) {
      if (!closedReservations.lastPage) {
        dispatch(reservationClosedList({ ...closedReservations.filter, page: closedReservations.filter.page + 1 }));
      }
    } else {
      if (!ongoingReservations.lastPage) {
        dispatch(reservationOngoingList({ ...ongoingReservations.filter, page: ongoingReservations.filter.page + 1 }));
      }
    }
  };

  // console.log("TripApp - ongoingReservations: %o | closedReservations: %o", ongoingReservations, closedReservations);
  // Menu bar
  const menuButtonOnClick = {
    "Current Trips": setTripTypeCurrent,
    "History Trips": setTripTypeHistory,
  };

  let menuBtns = menuButtons.map((button) => {
    button["onClick"] = menuButtonOnClick[button.text];
    button.style = tripType === button.tag ? { fill: "#009BCC" } : { fill: "rgba(0, 0, 0, 0.54)" };
    return button;
  });

  if (isSharing(selectedLocation.operationType) || isSubscription(selectedLocation.operationType)) {
    menuBtns.push({
      icon: CreateIcon,
      style: { fill: "#009BCC" },
      text: "Book a car",
      onClick: () => setModal(true),
    });
  }

  const menuBar = <VerticalMenuBar className={classes.menu} buttons={menuBtns} />;

  // Trip Details
  const tripDetails = !isEmpty(selectedTrip) && (
    <div className={classes.tripDetailsArea}>
      <TripDetails
        removeTrip={removeTrip}
        trip={selectedTrip}
        tripType={tripType}
        updateTrip={updateTrip}
        updateHistoryTrip={updateHistoryTrip}
        setModalUpdate={setScheduleTripToUpdate}
      />
    </div>
  );

  const filterTripsButtons = !onCardMode && (
    <div className={classes.tripFilters}>
      <Typography
        className={classnames(classes.filterText, shouldFilterWarnings && classes.selectedFilterText)}
        onClick={() => setShouldFilterWarnings(true)}
        display="inline"
      >
        Warnings
      </Typography>
      <Typography
        className={classnames(classes.filterText, !shouldFilterWarnings && classes.selectedFilterText)}
        onClick={() => setShouldFilterWarnings(false)}
        display="inline"
      >
        All Trips
      </Typography>
    </div>
  );

  const displayOptions = (
    <div className={classnames(classes.displayOptionsRoot, onCardMode && classes.displayOptionsFlexEnd)}>
      {filterTripsButtons}

      <div className={classes.viewFilters}>
        <ListModeIcon
          className={classnames(
            classes.displayModeIcons,
            displayMode === DISPLAY_MODES.SINGLE_LIST_WITH_FILTERS && classes.selectedIcon
          )}
          onClick={setDisplayModeList}
        />
        <CardModeIcon
          className={classnames(
            classes.displayModeIcons,
            displayMode === DISPLAY_MODES.PRE_FILTERED_MULTI_LIST && classes.selectedIcon
          )}
          onClick={setDisplayModeCards}
        />
      </div>
    </div>
  );

  const tripList = (
    <TripList
      currentFilter={currentFilter}
      getMoreRows={getMoreRowsHandler}
      lastPage={lastPage}
      selectedTrip={selectedTrip}
      separateTrips={onCardMode}
      setSelectedTrip={setSelectedTrip}
      shouldFilterWarnings={shouldFilterWarnings}
      trips={tripType === TRIP_TYPES.HISTORY ? closedReservations.list : ongoingReservations.list}
      // trips={trips}
    />
  );
  const reservationModal = !!modal && <ReservationCreateUpdate onClose={closeModalHandler} open={Boolean(modal)} />;

  const updateAdvancedModal = !!scheduledTripToUpdate && (
    <ReservationCreateUpdate
      onClose={closeUpdateModalHandler}
      open={Boolean(scheduledTripToUpdate)}
      scheduledTripToUpdate={scheduledTripToUpdate}
    />
  );

  const locationModal = !selectedLocation?.id && <LocationModal open={true} />;

  return (
    <div className={classes.root}>
      <div className={classes.workspace}>
        {locationModal}
        {reservationModal}
        {updateAdvancedModal}
        {menuBar}
        <div className={classes.reservationArea}>
          {tripType === TRIP_TYPES.HISTORY ? (
            <ClosedReservationListFilter shouldFilterWarnings={shouldFilterWarnings} />
          ) : (
            <OngoingReservationListFilter shouldFilterWarnings={shouldFilterWarnings} />
          )}

          {/* {searchReservation} */}
          <div className={classes.listArea}>
            {displayOptions}
            {tripList}
          </div>
        </div>
        {tripDetails}
      </div>
    </div>
  );
};

export default withStyles(styles)(TripApp);
