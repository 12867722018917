import React, { useCallback, useState, useRef, useEffect } from 'react';
import AddTask from './AddTask';
import workorderService from '../../../../../services/workOrder';
import log from '../../../../../utils/logger';

const logger = log('WorkOrders>Tasks>Add>AddTaskWrapper');

const AddTaskWrapper = ({
	locationId,
	setTasks,
	worktaskId,
	...props
}) => {
	const [placeholder, setPlaceholder] = useState({ worktaskId });
	const [types, setTypes] = useState([]);
	const listRef = useRef();

	useEffect(() => {
		const onMountGetTypes = async () => {
			try {
				const types = await workorderService.getTaskTypes();

				const typesArray = (types || []).map(type => ({ key: type, label: type }));

				setTypes(typesArray);
			} catch (e) {
				logger.warn('Could not get types');
			}
		};

		onMountGetTypes();
	}, []);

	const getTypes = useCallback(async ({string: filter}) => {
		const typesArray = (types || [])
			.filter(type => (type.label || '').toUpperCase().includes((filter || '').toUpperCase()))
			.map(type => type);

		return typesArray;
	}, [types]);

	const onChange = useCallback(
		field => setPlaceholder(prev => ({ ...prev, ...field })),
		[]);

	const onCreate = async () => {
		try {
			const name = await workorderService.createTask({ taskType: placeholder.newType, locationId });

			setPlaceholder(prev => ({ ...prev, name, newType: '' }));
			listRef.current.func(prev => [...prev, { key: name, label: name }]);
		} catch (e) {
			logger.warn('Could not create task');
		}
	};

	const onAdd = useCallback(async (name) => {
		try {
			const tasks = await workorderService
				.addTask({ worktaskId, name });

			setTasks(tasks);
			setPlaceholder({ worktaskId });
		} catch (e) {
			logger.warn('Could not add task');
		}
	}, [setTasks, worktaskId]);

	return (
		<AddTask
			{...props}
			getTypes={getTypes}
			onAdd={onAdd}
			onChange={onChange}
			onCreate={onCreate}
			placeholder={placeholder}
			listRef={listRef}
		/>
	);
};

export default AddTaskWrapper;