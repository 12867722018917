import log from './logger';

const logger = log('createComponentReducer');

const createReducer = (handlers) => {

	return (state, action) => {

		if (!handlers.hasOwnProperty(action.type)) {
			logger.warn(`Action type ${action.type} is not defined`);
			return state;
		}
		
		const handler = handlers[action.type];

		return handler(state, action);
	};
};

export default createReducer;
