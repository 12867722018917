import React from 'react';
import Button from '../../UI/Buttons/CommonButtons';
import {
	Checkbox,
	FormControlLabel,
	TextField,
	Typography,
	withStyles,
} from '@material-ui/core';
import styles from './PricingPlanEditor.styles';

const PricingPlanEditor = ({
	classes,
	disableButtons,
	errorMessage,
	hasError,
	location,
	onCancel,
	onSave,
	placeholder,
	placeholderSetters,
}) => {

	const title = (
		<Typography className={classes.title}>
			{`${placeholder.id ? 'Editing' : 'Creating'} Pricing Plan`}
		</Typography>
	);

	const locationField = (
		<TextField
			value={location}
			id='location'
			label='Current Location'
			disabled
		/>
	);

	const name = (
		<TextField
			defaultValue={placeholder.name}
			error={hasError && placeholder.name.trim().length < 1}
			helperText={hasError && placeholder.name.trim().length < 1 && 'Please fill the name'}
			id='nameEditor'
			label='Name'
			onChange={event => placeholderSetters.setName(event.target.value)}
		/>
	);

	const description = (
		<TextField
			defaultValue={placeholder.description}
			error={hasError && placeholder.description.trim().length < 1}
			helperText={hasError && placeholder.description.trim().length < 1 && 'Please fill the description'}
			id='descriptionEditor'
			label='Description'
			onChange={event => placeholderSetters.setDescription(event.target.value)}
		/>
	);

	const startingFee = (
		<TextField
			id='startingFeeEditor'
			label='Starting Fee'
			onChange={event => placeholderSetters.setStartingFee(event.target.value)}
			value={placeholder.startFee}
		/>
	);

	const category = (
		<TextField
			id='category'
			label='Category'
			onChange={event => placeholderSetters.setCategory(event.target.value)}
			value={placeholder.category}
		/>
	);

	const privateField = (
		<FormControlLabel
			control={
				<Checkbox
					checked={placeholder.specific}
					onChange={event => placeholderSetters.setSpecific(event.target.checked)}
				/>
			}
			label='Private'
			color='primary'
		/>
	);

	const error = !!errorMessage && (
		<Typography align='center' className={classes.error}>
			{errorMessage}
		</Typography>
	);

	const saveButton = (
		<Button
			buttonStyleName='filled'
			disabled={disableButtons}
			key='SaveButton'
			label={placeholder.id ? 'Save' : 'Next'}
			onClick={onSave}
		/>
	);

	const cancelButton = (
		<Button
			buttonStyleName='void'
			disabled={disableButtons}
			key='CancelButton'
			label='Cancel'
			onClick={onCancel}
		/>
	);

	const pricingPlanEditor = (
		<div className={classes.root}>
			{title}
			{locationField}
			{name}
			{description}
			{startingFee}
			{category}
			{privateField}
			<div className={classes.buttons}>
				{cancelButton}
				{saveButton}
			</div>
			{error}
		</div>
	);

	return pricingPlanEditor;
};

export default withStyles(styles)(PricingPlanEditor);