const styles = theme => ({

	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		justifyContent: 'flex-start',
		minWidth: '0',
		width: '100%',
	},

	title: {
		fontSize: '20px',
		margin: '10px 0 0 10px',
	},

	description: {
		borderBottom: 'none',
	},

	inputInput: {
		borderBottom: '1px solid gray',
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(2),
		transition: theme.transitions.create('width'),
		width: '100%',
	},

	inputError: {
		borderBottom: '1px solid red',
	},

	inputRoot: {
		color: 'inherit',
		width: '100%',
	},

	error: {
		margin: '0 10px',
		color: 'red',
		fontSize: '12px',
	},

	buttons: {
		alignItems: 'flex-end',
		display: 'flex',
		flexGrow: '1',
		justifyContent: 'space-around',
		padding: '5% 0',
	},

	select: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
	},

	selectError: {
		'&::before': {
			borderBottom: '1px solid red',
		},
	},

	form: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		margin: '25px',
		boxSizing: 'border-box',
		overflow: 'auto',
		scrollbarWidth: 'thin',
		'&::-webkit-scrollbar': {
			width: '1px',
		},

		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},

	subtitle14px: {
		fontSize: '14px',
		paddingBottom: 4,
	},

	locationList: {
		maxHeight: 300,
		margin: 0,
	},

	locationHeader: {
		flex: 0,
	},

	locationFilterRoot: {
		margin: 0,
		padding: 0,
		fontSize: 11,
	},

	maxAmount: {
		margin: '10px 0',
	},

	noArrows: {
		'-moz-appearance': 'textfield',
		'&::-webkit-outer-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0,
		},
		'&::-webkit-inner-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0,
		},
	},
});

export default styles;