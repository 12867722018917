const styles = theme => ({
	root: {
		position: 'absolute',
		borderRadius: '5px',
		right: '-15vw',
		top: 0,
		boxShadow: theme.shadows[5],
		height: '100%',
		width: '15vw',
		backgroundColor: '#fff',
		display: 'flex',
		flexDirection: 'column',
		boxSizing: 'border-box',
		padding: '10px',
		scrollbarWidth: 'thin', //FIREFOX
		//CHROME
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},

	left: {
		right: 'none',
		left: '-15vw',
	},

	hidden: {
		display: 'none',
	},

	title: {
		fontSize: '18px',
	},

	error: {
		color: '#f01',
	},

	errorBar: {
		width: '14vw',
		height: 'auto',
		alignSelf: 'center',
		backgroundColor: '#ff0000e5',
		borderRadius: '5px',
		boxShadow: theme.shadows[5],
		position: 'fixed',
	},

	errorText: {
		color: '#fff',
	},

	text: {
		width: '100%',
		minHeight: '10px',
		alignItems: 'center',
		display: 'flex',
		padding: '0 10px',
		boxSizing: 'border-box',
	},

	buttons: {
		display: 'flex',
		justifyContent: 'space-around',
		marginTop: 'auto',
	},

	searchList: {
		flex: 1,
		maxHeight: 'none',
	},
});

export default styles;