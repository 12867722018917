import React, {
	useState,
	useEffect,
} from 'react';
import Extras from './Extras';
import log from '../../../utils/logger';

const logger = log('ExtrasWrapper');

const ExtrasWrapper = (props) => {

	const {
		asyncGetExtras,
	} = props;

	const [filter, setFilter] = useState('');

	useEffect(() => {
		!!asyncGetExtras && asyncGetExtras();
		//eslint-disable-next-line
	}, [])

	const searchExtraHandler = async () => {
		try {
			asyncGetExtras(filter);
		} catch (error) {
			logger.info(error.message || error);
		}
	};

	const filterOnChangeHandler = (event) => {
		setFilter(event.target.value);
	};

	return (
		<Extras
			{...props}
			filter={filter}
			setFilter={setFilter}
			filterOnChangeHandler={filterOnChangeHandler}
			onSearchHandler={searchExtraHandler}
			listExtras={asyncGetExtras}
		/>
	);
};


export default ExtrasWrapper;