import React from "react";
import { Typography, TextField, withStyles } from "@material-ui/core";
import { InfoOutlined as InfoIcon } from "@material-ui/icons";
import styles from "./Template.styles";
import classnames from "classnames";

const Template = ({
	classes,
	className,
	notificationMessage,
	template,
	setNotificationMessage,
	setTemplate,
}) => {
	const infoIcon = <InfoIcon className={classnames(classes.iconEnabled)} />;

	const notificationMessageField = (
		<Typography className={classes.message}>
			{infoIcon}
			{notificationMessage}
		</Typography>
	);

	const templateField = (
		/* template && */ <TextField
			className={classnames(classes.templateRoot)}
			InputLabelProps={{ classes: { root: classes.labelProps } }}
			InputProps={{
				classes: { multiline: classes.template, input: classes.templateInput },
			}}
			fullWidth
			margin="normal"
			multiline
			onChange={(e) => setTemplate(e.target.value)}
			value={template || ""}
		/>
	);

	return (
		<div className={classnames(classes.root, className)}>
			{notificationMessageField}
			{templateField}
		</div>
	);
};

export default withStyles(styles)(Template);
