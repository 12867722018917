import React, { useState } from "react";
import { useDispatch } from "react-redux";
import UserMenu from "./UserMenu";
import log from "../../../../../../utils/logger";
import { operatorSetDefaultLoc } from "../../../../../../features/carclub/carclubSlice";

const logger = log("UserMenuWrapper");

const UserMenuWrapper = ({ anchorEl, ...props }) => {
  const [anchorElDefaultLoc, setAnchorElDefaultLoc] = useState();
  const dispatch = useDispatch();
  const { handleClose } = props;
  
  const onSetDefaultLocation = async (location) => {
    try {
      dispatch(operatorSetDefaultLoc(location?.id));
    } catch (error) {
      logger.warn("Unable to set default location", error);
    }
    setAnchorElDefaultLoc();
    handleClose();
  };

  return (
    <UserMenu
      {...props}
      openLocationsModal={(event) => {
        setAnchorElDefaultLoc(event.currentTarget);
      }}
      closeLocationsModal={() => {
        setAnchorElDefaultLoc();
        handleClose();
      }}
      anchorElDefaultLoc={anchorElDefaultLoc}
      onSetDefaultLocation={onSetDefaultLocation}
      anchorEl={anchorEl}
    />
  );
};

export default UserMenuWrapper;
