import React from "react";
import Fields from "./Fields/Fields";
import PlaceholderList from "./PlaceholderList/PlaceholderList";
import Template from "./Template/Template";
import Buttons from "./Buttons/Buttons";
import { withStyles, Grid, Typography } from "@material-ui/core";
import styles from "./CreateOrEditNotification.styles";
import classnames from "classnames";

const CreateOrEditNotification = ({
	classes,
	className,
	fields,
	notificationMessage,
	placeholders,
	template,
	exitCreateOrEditMode,
	createOrUpdateNotificationHandler,
	setNotificationMessage,
	setTemplate,
	editMode,
	errors,
	setErrors,
}) => {
	const onError = !!errors && (
		<div className={classes.errorsRoot}>
			<div className={classes.errors} onClick={() => setErrors()}>
				{errors.map((error, index) => (
					<Typography
						key={"error_" + index + 1}
						className={classes.error}
						align="center"
					>
						{typeof error === "string" ? error : "Please fill all the fields"}
					</Typography>
				))}
			</div>
		</div>
	);

	return (
		<div className={classnames(classes.root, className)}>
			<form onSubmit={createOrUpdateNotificationHandler}>
				<Grid container>
					<Fields editMode={editMode} fields={fields} />
				</Grid>
				<br />
				<br />
				<Grid container>
					<Grid item xs={3}>
						<PlaceholderList placeholders={placeholders} />
					</Grid>
					<Grid item xs={9}>
						<Grid
							container
							direction="column"
							justifyContent="space-evenly"
							alignItems="stretch"
						>
							<Template
								template={template}
								setTemplate={setTemplate}
								notificationMessage={notificationMessage}
								setNotificationMessage={setNotificationMessage}
							/>
							{onError}
							<Buttons closeHandler={exitCreateOrEditMode} />
						</Grid>
					</Grid>
				</Grid>
			</form>
		</div>
	);
};

export default withStyles(styles)(CreateOrEditNotification);
