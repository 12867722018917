import React from 'react';
import {
	withStyles,
} from '@material-ui/core';
import classnames from 'classnames';
import Contacts from './Contacts/ContactsWrapper';
import Messages from './Messages/MessagesWrapper';
import Create from './Create/CreateWrapper';
import styles from './Chat.styles';

const Chat = ({
	changeSide,
	classes,
	contactsList,
	createType,
	isOpen,
	newMessages,
	onCreate,
	onCreateClose,
	selected,
	setContacts,
	setSelected,
}) => {
	const contacts = (
		<Contacts
			changeContacts={setContacts}
			contactsList={contactsList}
			newMessages={newMessages}
			onCreate={onCreate}
			selected={selected}
			setSelected={setSelected}
		/>
	);

	const messages = (
		<Messages
			contact={selected}
			newMessages={newMessages}
			onCreate={onCreate}
			changeContacts={setContacts}
		/>
	);

	const create = !!createType && (
		<Create
			changeSide={changeSide}
			groupId={selected?.groupId}
			nickname={selected?.nickname || selected?.phone || selected?.name}
			onClose={onCreateClose}
			open={!!createType}
			type={createType}
		/>
	);

	return (
		<div
			className={classnames(classes.root, !isOpen && classes.hidden, changeSide && classes.left)}
			onMouseDown={e => e.stopPropagation()}
		>
			{contacts}
			{messages}
			{create}
		</div>
	);
};

export default withStyles(styles)(Chat);