export const getBillingEnginesOptions = (billingEngines) => {
  return [
    { value: "", text: "" },
    ...(billingEngines || []).map((item) => {
      return {
        value: item.code,
        text: item.name,
      };
    }),
  ];
};

export const getBillingGatewaysOptions = (values) => {
  const defaultGws = ["BRAINTREE", "STRIPE", "NONE"];
  const gws = [];
  defaultGws.forEach((item) => {
    if ((Object.keys(values) || []).includes(item)) {
      gws.push({
        value: item,
        text: item,
      });
    }
  });
  return gws;
};

export const getInvoiceGatewaysOptions = () => {
  return ["NONE", "INTERNAL", "INVOICE_EXPRESS"].map((item) => {
    return {
      value: item,
      text: item,
    };
  });
};
