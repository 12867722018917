import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import notificationService from "../../../../../services/notification";
import CreateOrEditNotification from "./CreateOrEditNotification";
import CreateOrEditNotificationReducer from "./CreateOrEditNotificationReducer";

import * as yup from "yup";
import {
	carclubCreateMessageCarclub,
  carclubEditMessageCarclub,
  selectMessageSaveSuccess,
} from "../../../../../features/notification/notificationSlice";

const isOnCreateMode = (openNotification) => typeof openNotification === "undefined";

const CreateOrEditNotificationWrapper = (props) => {
  const dispatch = useDispatch();
  const messageSaveSuccess = useSelector(selectMessageSaveSuccess);

  const { openNotification, exitCreateOrEditMode, getNotifications } = props;

  useEffect(() => {
    if (messageSaveSuccess) {
      exitCreateOrEditMode();
      setTimeout(() => {
        getNotifications();
      }, 300);
    }
    // eslint-disable-next-line
  }, [messageSaveSuccess]);

  // START of Reducer deconstruction

  const {
    // createOrUpdateNotification,
    inputFields,
    inputOptions,
    setNotificationToEdit,
    setPlaceholders,
    setSubTypeHandler,
    setToAddress,
    setTypeHandler,
    setTypes,
    setSubject,
    setNotificationMessage,
    setTemplate,
    errors,
    setErrors,
    setToSpecificMail,
    setSpecificMailList,
  } = CreateOrEditNotificationReducer() || {};

  const { type, subType, toAddress, subject, notificationMessage, template, toSpecificMail, specificMailList } =
    inputFields || {};

  const { types, subTypes, placeholders } = inputOptions || {};

  // END of Reducer deconstruction

  useEffect(() => {
    const getTypesAndPlaceholders = async () => {
      try {
        const { types, placeholders } = await notificationService.getTypesAndPlaceholders();

        setTypes(types);
        setPlaceholders(placeholders);
      } catch (_error) {}
    };
    getTypesAndPlaceholders();

    if (!isOnCreateMode(openNotification)) {
      setNotificationToEdit(openNotification);
    }

    //FIXME Wait for presentation on optimization from ricardo to remove next line
    //eslint-disable-next-line
  }, [
    openNotification,
    /* setTypes,
        setPlaceholders,
        setNotificationToEdit ,*/
  ]);

  const createOrUpdateNotificationHandler = async (event) => {
    event.preventDefault();

    // event.preventDefault();
    const encodedTemplate = encodeURIComponent(template);
    let newNotification = {
      type,
      subType,
      subject,
      bcc: toAddress,
      body: encodedTemplate,
      toSpecificMail,
      specificMailList,
    };
    try {
      setErrors();
      let schema = yup.object().shape({
        type: yup.string().required(),
        subType: yup.string().required(),
        subject: yup.string().required(),
        bcc: yup.string().email(),
      });

      schema.validate(newNotification, { abortEarly: false }).catch(function (err) {
        return setErrors(err.errors);
      });

      if (typeof openNotification === "undefined") {
        // onCreate mode
        // await notificationService.createNotification(newNotification);
				dispatch(carclubCreateMessageCarclub(newNotification));
      } else {
        // onEdit mode
        if (typeof openNotification.id !== "undefined") {
          newNotification.id = openNotification.id;
        }
        //await notificationService.updateNotification(newNotification);
        dispatch(carclubEditMessageCarclub(newNotification));
      }
      // exitCreateOrEditMode();
      // setTimeout(() => {
      //   getNotifications();
      // }, 300);
    } catch (_error) {}
  };

  return (
    <CreateOrEditNotification
      {...props}
      fields={{
        types,
        subTypes,
        type,
        subType,
        toAddress,
        subject,
        toSpecificMail,
        specificMailList,
        setters: {
          setTypeHandler,
          setSubTypeHandler,
          setToAddress,
          setSubject,
          setToSpecificMail,
          setSpecificMailList,
        },
      }}
      placeholders={placeholders}
      notificationMessage={notificationMessage}
      setNotificationMessage={setNotificationMessage}
      template={template}
      setTemplate={setTemplate}
      editMode={Boolean(openNotification)}
      createOrUpdateNotificationHandler={createOrUpdateNotificationHandler}
      errors={errors}
      setErrors={setErrors}
    />
  );
};

export default CreateOrEditNotificationWrapper;
