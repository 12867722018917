import React from "react";
import { withStyles } from "@material-ui/core";
import { styles } from "./AssetInformation.styles";
import AssetHeader from "./AssetHeader/AssetHeader";
import AssetInfoContent from "./AssetInfoContent/AssetInfoContent";
import classnames from "classnames";

const AssetInformation = ({
  classes,
  className,
  generalInfo,
  handlers,
  hasRequest,
  placeholder,
  removeHeader = false,
}) => {
  const { imageHandler, onChangeHandler } = handlers || {};

  const { onEdit, onCreate, generalAssetInformation } = generalInfo || {};

  return (
    <div className={classnames(classes.root, className)}>
      {!removeHeader && <AssetHeader placeholder={placeholder} />}

      <AssetInfoContent
        generalAssetInformation={generalAssetInformation}
        hasRequest={hasRequest}
        imageHandler={imageHandler}
        onChangeHandler={onChangeHandler}
        onCreate={onCreate}
        onEdit={onEdit}
        placeholder={placeholder}
      />
    </div>
  );
};

export default withStyles(styles)(AssetInformation);
