import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const doorsOpenCall = async ({updateAssetOnList}, dispatch, state, { carToken }) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "DOORS/OPEN");
  try {
    const result = await wsCall({
      type: "DOORS",
      subType: "OPEN",
      locationId: selectedLocation?.id,
      message: {
        carToken,
      },
    });
    if (result && !result.error) {
      dispatch(setSuccess("Open doors command sent successfully"));
      dispatch(updateAssetOnList(result));
    } else {
      rh.error(result?.description || "Error opening doors!");
    }
  } catch (_ex) {
    rh.error("Error opening doors!");
  }
  rh.close();
};

export default doorsOpenCall;
