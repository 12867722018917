import React from 'react';
import TripsList from './TripsList/TripsListWrapper';
import TripsFilters from './TripsFilters/TripsFiltersWrapper';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import styles from './TripsModal.styles';

const TripsModal = ({
	classes,
	currentScreenSize,
	filters,
	lastPage,
	setFilters,
	trips,
}) => {

	const title = (
		<Typography className={classes.title}>
			Trips
		</Typography>
	);

	const tripFilters = (
		<TripsFilters
			{...{
				filter: filters.customerCode,
				setFilters,
			}}
		/>
	);

	const list = (
		<TripsList
			{...{
				currentScreenSize,
				lastPage,
				setFilters,
				trips,
			}}
		/>
	);

	const root = (
		<div className={classes.root}>
			<div className={classes.header}>
				{title}
				{tripFilters}
			</div>
			{list}
		</div>
	);

	return root;
};

export default withStyles(styles)(TripsModal);