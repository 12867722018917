import React, { useState, useEffect } from 'react';
import carclubService from '../../../../services/carclub';
import UpdateUser from './UpdateUser';

const check = placeholder => {
	const fieldsArray = placeholder.code
		? ['role']
		: ['login', 'email', 'name', 'role', 'mobile'];

	return fieldsArray
		.filter(field =>
			!placeholder[field] || ('' + placeholder[field]).trim().length <= 0)
		.length > 0;
};

const UpdateUserWrapper = props => {
	const {
		onCancel,
		onUpdate,
		setSelected,
		user,
	} = props;

	const [placeholder, setPlaceholder] = useState(user ? { ...user } : {});
	const [error, setError] = useState();
	const [hasRequest, setHasRequest] = useState();

	useEffect(()=>{
		setPlaceholder(user || {});
	}, [user]);

	const onChange = value => setPlaceholder(prev => ({ ...prev, ...value }));

	const onSave = async () => {

		setError();

		if (check(placeholder)) {
			return setError(true);
		};

		setHasRequest(true);

		try {
			const request = await (placeholder.code
				? carclubService.updateUser(placeholder)
				: carclubService.createUser(placeholder));

			onCancel();
			onUpdate(request);
			setSelected(request.code);
		}
		catch (error) {
			setError(error.message
				|| `Could not ${placeholder.code ? 'update' : 'save'} user`);
		};

		setHasRequest();
	};

	return (
		<UpdateUser
			{...props}
			error={error}
			hasRequest={hasRequest}
			onChange={onChange}
			onSave={onSave}
			placeholder={placeholder}
		/>
	);
};

export default UpdateUserWrapper;