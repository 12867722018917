
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_REQUEST_IS_MALFORMED,
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import { dateFormatter } from '../../formatter/index';
import { publishAndAwait } from '../../../app/coreSlice';

const createBanner = async banner => {
	if ( typeof banner === 'undefined' ) {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	};
	
	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'BANNER',
			subType: 'CREATE_LOCATION',
			message: {
				...banner,
				endDate: dateFormatter.toJSON(banner?.endDate, 'dateTimeRequest') || undefined,
				startDate: dateFormatter.toJSON(banner?.startDate, 'dateTimeRequest') || undefined,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	};

	if (error) {

		const {
			code,
			description,
			message,
			tag,
		} = processMessage;

		throw new NGError({
			message: description || message || tag,
			code,
			tag,
		});
	};

	return processMessage;
};

export default createBanner;