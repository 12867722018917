import NGError from "../../../error/ngError";
import errorFactory from "../../../error/errorFactory";
import { WS_PROCESS_MESSAGE_IS_MALFORMED } from "../../../error/websocket/errorTypes";
import { fromDTO as mapper } from "./mappers/incidentServiceMapper";
import { publishAndAwait, s3GetPublic } from "../../../app/coreSlice";
import { asyncForEach } from "../../../common/utils";

const getIncidents = async (filters) => {
  const request = await publishAndAwait({
    destination: "/app/hello",
    body: {
      type: "RESERVATION",
      subType: "INCIDENT_LIST",
      message: {
        ...filters,
      },
    },
  });

  const { processMessage, error } = request.response;

  // console.log("processMessage: %o", processMessage);

  if (processMessage?.incidentList) {
    let nList = [];
    await asyncForEach(processMessage?.incidentList, async (incident) => {
      let nIncident = { ...incident, attachment: [] };
      if (incident?.attachment?.length > 0) {
        // console.log("..... incident?.attachment: %o", incident?.attachment);
        let attachs = incident.attachment.map((item) => item.split("?")[0]);
        // console.log("attachs: %o", attachs);
        let nAttachs = await s3GetPublic({ s3Link: attachs });
        nIncident.attachment = nAttachs.map((item) => item.value);
      }
      nList.push(nIncident);
    });
    processMessage.incidentList = nList;
  }

  if (!processMessage) {
    throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
  }

  if (error) {
    const { description, code, tag } = processMessage;

    throw new NGError({
      message: description,
      code,
      tag,
    });
  }

  const incidents = await Promise.all(processMessage.incidentList.map((incident) => mapper(incident)));

  return { incidents, lastPage: processMessage.lastPage };
};

export default getIncidents;
