const styles = theme => ({
	root: {
		height: '100%',
		width: '60%',
		display: 'flex',
		boxSizing: 'border-box',
		flexDirection: 'column',
		padding: '8px 10px',
	},

	header: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		minHeight: 0,
	},

	headerText: {
		fontSize: '18px',
	},

	text: {
		borderRadius: '10px',
		border: '1px solid #8885',
		width: '100%',
		minHeight: '10px',
		alignItems: 'center',
		display: 'flex',
		padding: '0 10px',
		boxSizing: 'border-box',
	},

	button: {
		fontSize: '14px',
		cursor: 'pointer',
		color: '#009AC9',
		marginLeft: '5px',
	},

	membersIcon: {
		marginLeft: 'auto',
		cursor: 'pointer',
		color: '#009AC9',
	},

	dotIcon: {
		cursor: 'pointer',
		color: '#009AC9',
	},

	errorBar: {
		width: '14vw',
		height: 'auto',
		alignSelf: 'center',
		backgroundColor: '#ff0000e5',
		borderRadius: '5px',
		boxShadow: theme.shadows[5],
		position: 'fixed',
	},

	errorText: {
		color: '#fff',
	},

	disabled: {
		color: '#8888',
		cursor: 'default',
	},
});

export default styles;