import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Grid, Tooltip } from "@material-ui/core";

import { styles } from "./TripWarningIcons.styles";

import { ReactComponent as AgentIcon } from "../../../assets/TripApp/common/icon_agent.svg";
import { ReactComponent as OperatorIcon } from "../../../assets/TripApp/common/operator.svg";
import { ReactComponent as LateDeliveryIcon } from "../../../assets/TripApp/common/late_delivery.svg";
import { ReactComponent as LongTripIcon } from "../../../assets/TripApp/common/max_time.svg";
import { ReactComponent as PaymentIssueIcon } from "../../../assets/TripApp/common/payment_failed.svg";
import { ReactComponent as ToMigrateIcon } from "../../../assets/TripApp/common/to_migrate.svg";
import { ReactComponent as UnderReviewIcon } from "../../../assets/TripApp/common/under_review.svg";
import { ReactComponent as RfidIcon } from "../../../assets/TripApp/common/icon_rfid.svg";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import RoomIcon from "@material-ui/icons/Room";

const style = { width: "24px", height: "24px" };

function TripWarningIcons({ classes, trip }) {
  let {
    isAgent,
    operator,
    lateDelivery,
    longTrip,
    toMigrate,
    underReview,
    rfidTrip,
    apiTrip,
    state,
    paid,
    groupId,
    goldPin,
  } = trip;

  let paymentIssue = "CLOSED" === state && !paid && !isAgent && !groupId;

  return (
    <Grid container className={classes.root} spacing={1}>
      {isAgent && (
        <Grid item>
          <Tooltip key="Agent" title="Agent" placement="top">
            <AgentIcon style={{ height: "22px", width: "35px" }} />
          </Tooltip>
        </Grid>
      )}
      {operator && (
        <Grid item>
          <Tooltip key="Operator" title="Operator" placement="top">
            <OperatorIcon style={style} />
          </Tooltip>
        </Grid>
      )}
      {lateDelivery && (
        <Grid item>
          <Tooltip key="Late Delivery" title="Late Delivery" placement="top">
            <LateDeliveryIcon style={style} />
          </Tooltip>
        </Grid>
      )}
      {longTrip && (
        <Grid item>
          <Tooltip key="Long Trip" title="Long Trip" placement="top">
            <LongTripIcon style={style} />
          </Tooltip>
        </Grid>
      )}
      {paymentIssue && (
        <Grid item>
          <Tooltip key="Payment Issue" title="Payment Issue" placement="top">
            <PaymentIssueIcon style={style} />
          </Tooltip>
        </Grid>
      )}
      {toMigrate && (
        <Grid item>
          <Tooltip key="To Migrate" title="To Migrate" placement="top">
            <ToMigrateIcon style={style} />
          </Tooltip>
        </Grid>
      )}
      {underReview && (
        <Grid item>
          <Tooltip key="Under Review" title="Under Review" placement="top">
            <UnderReviewIcon style={style} />
          </Tooltip>
        </Grid>
      )}
      {rfidTrip && (
        <Grid item>
          <Tooltip key="Trip with RFID" title="Trip with RFID" placement="top">
            <RfidIcon style={style} />
          </Tooltip>
        </Grid>
      )}
      {apiTrip && (
        <Grid item>
          <Tooltip title="API Trip" placement="top">
            <CloudDownloadIcon style={style} />
          </Tooltip>
        </Grid>
      )}
      {goldPin && (
        <Grid item>
          <Tooltip title="Gold pin" placement="top">
            <RoomIcon style={style} />
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
}

export default withStyles(styles)(TripWarningIcons);
