import React from 'react';
import SortIcon from '@material-ui/icons/UnfoldMore';
import * as listItemTypes from '../../../UI/List/ListItem/ListItemTypes';
import * as screenSize from '../../../../model/ScreenSize/ScreenSize';

const tripFields = (currentScreenSize, onSort) => {
	const width = {
		[screenSize.DESKTOP]: 111,
		[screenSize.TABLET]: 78,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	const maxWidth = {
		[screenSize.DESKTOP]: 166,
		[screenSize.TABLET]: 136,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	const height = {
		[screenSize.DESKTOP]: 35,
		[screenSize.TABLET]: 45,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	const startDate = (
		<span
			style={{
				cursor: 'pointer',
				display: 'flex',
				alignItems: 'center',
			}}
			onClick={onSort}
		>
			<SortIcon />
			{'Start Date'}
		</span>
	);

	return [
		/* {
			key: 'icons',
			label: ' ',
			order: 99,
			pinned: true,
			width,
			height,
			type: listItemTypes.COMPONENT,
			flexDirection: 'row-reverse',
		}, */
		{
			key: 'reservationCode',
			label: 'Reservation No.',
			order: 1,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'customerCode',
			label: 'Customer No.',
			order: 2,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'customerName',
			label: 'Customer Name',
			order: 3,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'startDate',
			label: startDate,
			order: 4,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'endDate',
			label: 'End Date',
			order: 5,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'cost',
			label: 'Cost',
			order: 6,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'state',
			label: 'State',
			order: 7,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
	];
};

export default tripFields;