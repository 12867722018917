import React from 'react';
import List from '../../../UI/List/List';
import Row from './Row/Row';
import {
	withStyles,
} from '@material-ui/core';
import fields from './fields';
import styles from './List.styles';
import classnames from 'classnames';

const BannersList = ({
	banners,
	classes,
	className,
	currentScreenSize,
	disableButtons,
	getMoreRows,
	onDelete,
	onEdit,
	onEnable,
	onSelect,
	selected,
}) => {

	const bannerList = !!banners
		&& Array.isArray(banners)
		&& banners
			.map(banner => (
				<Row
					banner={banner}
					disableButtons={disableButtons}
					key={banner.id}
					onDelete={onDelete}
					onEdit={onEdit}
					onEnable={onEnable}
					onSelect={onSelect}
					selected={selected === banner.id}
				/>
			));

	const list = (
		<List
			className={classnames(classes.root, className)}
			getMoreRows={getMoreRows}
			headerFields={fields(currentScreenSize)}
		>
			{bannerList}
		</List>
	);

	return list;
};

export default withStyles(styles)(BannersList);