import { wsCall, requestHelper } from "../../../app/coreSlice";

const carclubActiveLicenseLocationCall = async ({ setActiveLegalDocuments }, dispatch, state) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "CARCLUB/ACTIVE_LICENSE_LOCATION");
  let result;
  try {
    result = await wsCall({
      type: "CARCLUB",
      subType: "ACTIVE_LICENSE_LOCATION",
      locationId: selectedLocation?.id,
    });

    if (!result.error) {
      dispatch(setActiveLegalDocuments(result?.license));
      // refresh reservations
    } else {
      rh.error(result?.description || "Error loading licenses.");
    }
  } catch (ex) {
    console.error(ex);
    rh.error("Error loading licenses.");
  }
  rh.close();
  return result;
};

export default carclubActiveLicenseLocationCall;
