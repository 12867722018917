import { SHA1, AES, SHA256, enc } from "crypto-js";
const iv = "sFpQIWhcYpDYbtDl";

export const hash = (string) => {
  const hash = SHA1(string).toString();
  return hash;
};

export const createKey = (string) => {
  const hash = SHA256(string).toString().substring(0, 32);
  return hash;
};

export const encrypt = (string, key) => {
  return AES.encrypt(string, key, { iv }).toString();
};

export const decrypt = (string, key) => {
  if (!key || !string) {
    return "";
  }
  return AES.decrypt(string, key, { iv }).toString(enc.Utf8);
};
