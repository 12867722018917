import React from 'react';
import ListItem from '../../../UI/List/ListItem/ListItem';
import {
	Tooltip,
	withStyles,
} from '@material-ui/core';
import withPermission from '../../../../hoc/withPermission';
import {
	Check as TrueIcon,
	Create as EditIcon,
	RemoveCircleOutline as RemoveIcon,
} from '@material-ui/icons';
import { currencyFormatter } from '../../../../services/formatter';
import styles from './PricingPlanDetailItem.styles';

const PricingPlanDetailItem = ({
	classes,
	deleteDetail,
	fields,
	isCreating,
	openEditMode,
	pricingPlanDetail,
	selected,
}) => {

	const valueFormat = value => currencyFormatter.format(value, { decimalPlaces: 4 });

	const timePrice = !!pricingPlanDetail.timePrice
		&& valueFormat(pricingPlanDetail.timePrice) + currencyFormatter.symbol();;

	const maxTimePrice = !!pricingPlanDetail.maxTimePrice
		&& valueFormat(pricingPlanDetail.maxTimePrice) + currencyFormatter.symbol();;

	const distancePrice = !!pricingPlanDetail.distancePrice
		&& valueFormat(pricingPlanDetail.distancePrice) + currencyFormatter.symbol();;

	const isDefault = pricingPlanDetail.isDefault;

	const removeIcon = (
		<Tooltip title='Remove' placement='left'>
			<RemoveIcon
				onClick={() => deleteDetail()}
				className={classes.editIcon}
			/>
		</Tooltip>
	);

	const editIcon = (
		<Tooltip title='Edit' placement='left'>
			<EditIcon
				onClick={() => openEditMode()}
				className={classes.editIcon}
			/>
		</Tooltip>
	);

	const Buttons = ButtonsWithPermission(isCreating);

	const editIcons = (
		<Buttons>
			<div className={classes.icons}>
				{editIcon}
				{removeIcon}
			</div>
		</Buttons>
	);

	const enabled = isDefault
		? <TrueIcon className={classes.checkIcon} />
		: null;

	const item = !!pricingPlanDetail && (
		<ListItem
			selected={selected}
			fields={fields}
			element={{
				...pricingPlanDetail,
				timePrice,
				maxTimePrice,
				distancePrice,
				editIcons,
				enabled,
			}}
		/>
	);
	return item;
};

export default withStyles(styles)(PricingPlanDetailItem);

const ButtonsWithPermission = isCreating => withPermission(
	[{ resource: ['pricingPlans', isCreating? 'create' : 'edit'] }],
)(({ children }) => <> {children} </>);