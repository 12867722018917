import createModel from '../createModel';

/* 
	Backend Definition:
	Rate = {
		id: UUID,
		rateNumber: int,
		name: String,
		priceInUse: double,
		priceInStandby: double,
		priceKm: double,
		color: String (hexadecimal, ex: "#C1C2C3"),
		carclubId: UUID,
	};
*/

class Rate {

	constructor({
		carclubId,
		color,
		goldPinInUse,
		goldPinInStandby,
		id,
		name,
		priceInStandby,
		priceInUse,
		priceKm,
		rateNumber,
	} = {}) {
		createModel.call(this, {
			carclubId,
			color,
			goldPinInUse,
			goldPinInStandby,
			id,
			name,
			priceInStandby,
			priceInUse,
			priceKm,
			rateNumber,
		});
	};

};

export default Rate;