import React from 'react';

// Trip type icons
import { ReactComponent as AdvancedIcon } from '../../../assets/TripApp/common/type_advanced.svg';
import { ReactComponent as ImmediateIcon } from '../../../assets/TripApp/common/type_immediate.svg';
// Current icons
import { ReactComponent as OngoingIcon } from '../../../assets/TripApp/current/trip_ongoing.svg';
import { ReactComponent as ReadyIcon } from '../../../assets/TripApp/current/trip_ready.svg';
import { ReactComponent as ScheduledIcon } from '../../../assets/TripApp/current/trip_scheduled.svg';
// History icons
import { ReactComponent as CancelledIcon } from '../../../assets/TripApp/history/trip_cancel.svg';
import { ReactComponent as ClosedIcon } from '../../../assets/TripApp/history/trip_closed.svg';

export const stateIcons = {
	ONGOING: <OngoingIcon style={{ fill: '#0092e9' }} />,
	STARTED: <OngoingIcon style={{ fill: '#0092e9' }} />,
	READY: <ReadyIcon style={{ fill: '#ffd103' }} />,
	IDLE: <ReadyIcon style={{ fill: '#ffd103' }} />,
	SCHEDULED: <ScheduledIcon style={{ fill: '#4c4c4e' }} />,
	CANCELLED: <CancelledIcon style={{ fill: '#89898b' }} />,
	CLOSED: <ClosedIcon style={{ fill: '#4c4c4e' }} />,
	ABSENT: <CancelledIcon style={{ fill: '#4c4c4e' }} />,
};

export const typeIcons = {
	ADVANCED: AdvancedIcon,
	IMMEDIATE: ImmediateIcon,
};
