import { createSlice } from "@reduxjs/toolkit";
import { mapperPricingPlanFlows } from "../../model/PricingPlan/PricingPlan";
import { mapperPricingPlanDetailToDTO } from "../../model/PricingPlan/PricingPlanDetail";
import princingPlanCreateCall from "./_sliceCalls/princingPlanCreateCall";
import princingPlanDeleteCall from "./_sliceCalls/princingPlanDeleteCall";
import princingPlanListCall from "./_sliceCalls/princingPlanListCall";

export const princingplansSlice = createSlice({
  name: "princingplans",
  initialState: {
    princingPlansList: [],
  },
  reducers: {
    setPrincingPlansList: (state, action) => {
      state.princingPlansList = (action.payload || []).map((pricingPlan) => mapperPricingPlanFlows(pricingPlan)); // mapper to be descontinued
      // state.princingPlansList = action.payload;
    },
    appendPrincingPlan: (state, action) => {
      let currentList = (state.princingPlansList || []).filter((item) => item.id !== action.payload.id);
      state.princingPlansList = [...currentList, mapperPricingPlanFlows(action.payload)]; // mapper to be descontinued
      // state.princingPlansList = [...state.princingPlansList, action.payload];
    },
  },
});

export default princingplansSlice.reducer;

export const { setPrincingPlansList } = princingplansSlice.actions;

// SELECTS
export const selectPrincingPlans = (state) => state.getIn(["princingplans"])?.princingPlansList;

// CALLS
export const princingPlanList = () => async (dispatch, getState) => {
  princingPlanListCall(princingplansSlice.actions, dispatch, getState());
};

export const princingPlanDelete =
  ({ pricingPlanId }) =>
  async (dispatch, getState) => {
    princingPlanDeleteCall(princingplansSlice.actions, dispatch, getState(), { pricingPlanId });
  };

export const princingPlanCreate = (data) => async (dispatch, getState) => {
  // data should go without mapping - this should be simplified
  let formattedData = { ...data };
  const formattedPricingPlanDetails =
    data && data.details.map((detail) => ({ ...detail })).map((detail) => mapperPricingPlanDetailToDTO(detail));
  delete formattedData.details;
  formattedData.pricingDetails = formattedPricingPlanDetails;

  princingPlanCreateCall(princingplansSlice.actions, dispatch, getState(), formattedData);
};
