export const styles = () => ({
  root: {},
  menu: {
    backgroundColor: "white",
    border: "1px solid lightgray",
    zIndex: 9999,
  },
  icon: {
    minWidth: 20,
    paddingRight: 10,
    justifyContent: "center",
  },
  selected: {
    backgroundColor: "#cce9fb",
  },
});
