export const styles = theme => ({

	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
	},

	icon: {
		color: '#009AC9',
		cursor: 'pointer',
	},

	iconDisabled: {
		color: '#C5C5C5',
	},

});

export default styles;