import React from "react";
import { SvgIcon } from "@material-ui/core";

function BillingIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 95 100">
      <symbol viewBox="-27.299 -39.024 54.598 78.047">
        <path
          fill="none"
          d="M11.157,34.02c-1.275-1.85-3.408-3.026-5.733-3.026c-2.13,0-4.118,0.985-5.424,2.607   c-1.306-1.622-3.294-2.607-5.423-2.607c-2.325,0-4.457,1.176-5.734,3.026c-1.275-1.85-3.408-3.026-5.733-3.026   c-1.913,0-3.648,0.776-4.908,2.029v-66.474c1.26,1.253,2.995,2.029,4.908,2.029c2.325,0,4.458-1.176,5.733-3.026   c1.277,1.85,3.409,3.026,5.734,3.026c2.129,0,4.117-0.986,5.423-2.607c1.306,1.622,3.294,2.607,5.424,2.607   c2.325,0,4.458-1.176,5.733-3.026c1.277,1.85,3.409,3.026,5.734,3.026c1.913,0,3.647-0.776,4.907-2.028v66.473   c-1.26-1.253-2.994-2.028-4.907-2.028C14.567,30.995,12.435,32.17,11.157,34.02z"
        ></path>
        <path
          fill="#ffffff"
          stroke="#ffffff"
          strokeWidth="3"
          strokeMiterlimit="10"
          d="M-20.463-37.524   c0.391,1.615,1.839,2.822,3.573,2.822c1.733,0,3.181-1.207,3.572-2.822h4.323c0.391,1.615,1.839,2.822,3.573,2.822   s3.182-1.207,3.573-2.822h3.701c0.391,1.615,1.839,2.822,3.573,2.822s3.182-1.207,3.573-2.822h4.322   c0.391,1.615,1.839,2.822,3.573,2.822s3.182-1.207,3.573-2.822h5.335v75.047h-5.266c-0.219-1.825-1.759-3.25-3.641-3.25   s-3.423,1.425-3.641,3.25H9.065c-0.219-1.825-1.759-3.25-3.641-3.25s-3.423,1.425-3.641,3.25h-3.564   c-0.219-1.825-1.759-3.25-3.641-3.25s-3.423,1.425-3.641,3.25h-4.186c-0.219-1.825-1.758-3.25-3.641-3.25   c-1.883,0-3.423,1.425-3.641,3.25h-5.267v-75.047H-20.463z"
        ></path>
      </symbol>
      <symbol viewBox="-30.282 -48.056 60.563 94.113">
        <path
          fill="#ffffff"
          stroke="#ffffff"
          strokeWidth="6"
          strokeMiterlimit="10"
          d="M16.006,43.056   c-3.233-4.883-8.77-8.108-15.065-8.108s-11.832,3.225-15.065,8.108h-10.584v-88.113h10.584c3.233,4.883,8.77,8.108,15.065,8.108   s11.832-3.224,15.065-8.108H26.59v88.113H16.006z"
        ></path>
        <line
          fill="none"
          stroke="#ffffff"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="7,8"
          x1="27.781"
          y1="-20.755"
          x2="-27.782"
          y2="-20.755"
        ></line>
      </symbol>
      <symbol viewBox="-24.712 -42.834 54.356 85.667">
        <path
          fill="#ffffff"
          stroke="#ffffff"
          strokeWidth="5"
          strokeMiterlimit="10"
          d="M17.181,40.333h-4.475   c-0.466-2.355-2.539-4.131-5.031-4.131c-2.491,0-4.565,1.776-5.03,4.131h-4.883c-0.466-2.355-2.539-4.131-5.031-4.131   c-2.491,0-4.565,1.776-5.03,4.131h-4.883c-0.466-2.355-2.539-4.131-5.03-4.131v-72.023c2.622,0,4.76-1.974,5.069-4.512h4.806   c0.309,2.539,2.447,4.512,5.069,4.512c2.622,0,4.76-1.974,5.069-4.512h4.806c0.309,2.539,2.447,4.512,5.069,4.512   c2.622,0,4.76-1.974,5.069-4.512h4.398c0.309,2.539,2.447,4.512,5.069,4.512v72.023C19.72,36.202,17.647,37.978,17.181,40.333z"
        ></path>
        <line
          fill="none"
          stroke="#ffffff"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeDasharray="7,6"
          x1="-22.644"
          y1="-17.395"
          x2="27.644"
          y2="-17.395"
        ></line>
      </symbol>
      <symbol viewBox="-23.971 -42.094 47.944 84.188">
        <g>
          <path
            fill="#ffffff"
            stroke="#ffffff"
            strokeWidth="3.521"
            strokeMiterlimit="10"
            d="M17.182,40.333h-4.475    c-0.466-2.355-2.539-4.131-5.031-4.131c-2.491,0-4.565,1.776-5.03,4.131h-4.883c-0.466-2.355-2.539-4.131-5.031-4.131    c-2.491,0-4.565,1.776-5.03,4.131h-4.883c-0.466-2.355-2.539-4.131-5.03-4.131v-72.023c2.622,0,4.76-1.974,5.069-4.512h4.806    c0.309,2.539,2.447,4.512,5.069,4.512c2.622,0,4.76-1.974,5.069-4.512h4.806c0.309,2.539,2.447,4.512,5.069,4.512    c2.622,0,4.76-1.974,5.069-4.512h4.398c0.309,2.539,2.447,4.512,5.069,4.512v72.023C19.721,36.202,17.648,37.978,17.182,40.333z"
          ></path>
        </g>
        <polygon
          fill="#ffffff"
          stroke="#ffffff"
          strokeWidth="3.8113"
          strokeMiterlimit="10"
          points="5.14,17.02 11.952,13.853    8.431,7.217 7.524,-0.241 0.124,1.057 -7.248,-0.385 -8.301,7.053 -11.951,13.62 -5.201,16.919 -0.084,22.42  "
        ></polygon>
      </symbol>
      <symbol id="a" viewBox="-21.087 -31.266 42.17 72.87">
        <path
          fill="#ffffff"
          stroke="#ffffff"
          strokeWidth="5"
          strokeMiterlimit="10"
          d="M6.373,39.104h5.21c0-3.87,3.13-7,7-7v-53.87   c-3.87,0-7-3.14-7-7h-5.21"
        ></path>
        <path
          fill="#ffffff"
          stroke="#ffffff"
          strokeWidth="5"
          strokeMiterlimit="10"
          d="M-6.377-28.766h-5.21c0,3.86-3.13,7-7,7v53.87   c3.87,0,7,3.13,7,7h5.21"
        ></path>
        <line
          fill="none"
          stroke="#ffffff"
          strokeWidth="5"
          strokeMiterlimit="10"
          x1="2.373"
          y1="39.104"
          x2="-2.377"
          y2="39.104"
        ></line>
        <line
          fill="none"
          stroke="#ffffff"
          strokeWidth="5"
          strokeMiterlimit="10"
          x1="2.373"
          y1="-28.766"
          x2="-2.377"
          y2="-28.766"
        ></line>
        <g>
          <path
            stroke="#ffffff"
            strokeWidth="0.9"
            strokeMiterlimit="10"
            d="M-9.901-10.118c0-0.994-0.806-1.8-1.8-1.8    s-1.8,0.806-1.8,1.8s0.806,1.8,1.8,1.8S-9.901-9.124-9.901-10.118z"
          ></path>
          <path
            stroke="#ffffff"
            strokeWidth="0.9"
            strokeMiterlimit="10"
            d="M-2.101-10.118c0-0.994-0.806-1.8-1.8-1.8    s-1.8,0.806-1.8,1.8s0.806,1.8,1.8,1.8S-2.101-9.124-2.101-10.118z"
          ></path>
          <path
            stroke="#ffffff"
            strokeWidth="0.9"
            strokeMiterlimit="10"
            d="M5.699-10.118c0-0.994-0.806-1.8-1.8-1.8s-1.8,0.806-1.8,1.8    s0.806,1.8,1.8,1.8S5.699-9.124,5.699-10.118z"
          ></path>
          <path
            stroke="#ffffff"
            strokeWidth="0.9"
            strokeMiterlimit="10"
            d="M13.499-10.118c0-0.994-0.806-1.8-1.8-1.8    s-1.8,0.806-1.8,1.8s0.806,1.8,1.8,1.8S13.499-9.124,13.499-10.118z"
          ></path>
        </g>
      </symbol>
      <symbol viewBox="-21.086 -31.266 42.17 72.87">
        <use
          width="42.17"
          height="72.87"
          x="-21.087"
          y="-31.266"
          transform="matrix(1 0 0 1 6.996482e-004 -2.671033e-004)"
          overflow="visible"
        ></use>
        <line
          fill="none"
          stroke="#ffffff"
          strokeWidth="4"
          strokeMiterlimit="10"
          x1="9.224"
          y1="13.75"
          x2="-9.223"
          y2="13.75"
        ></line>
      </symbol>
      <g display="none">
        <path
          display="inline"
          fill="#ffffff"
          d="M708.792,474.743H-529.006c-2.084,0-3.789-1.705-3.789-3.789V-716.211   c0-2.084,1.705-3.789,3.789-3.789H708.792c2.084,0,3.789,1.705,3.789,3.789V470.954   C712.582,473.038,710.877,474.743,708.792,474.743z"
        ></path>
      </g>
      <g>
        <g>
          <path d="M45.948,92.297h7.52c1.708,0,3.125-1.396,3.125-3.125v-7.228l1.562-0.417C70.903,78.319,75.424,69.55,75.424,63.03    c0-12.082-10.416-17.102-20.623-20.435c-9.582-3.166-12.644-5.083-12.644-7.916c0-2.541,2.937-5.083,9.52-5.083    c7.249,0,11.79,2.062,13.727,2.958c0.417,0.187,0.854,0.292,1.291,0.292c1.208,0,2.312-0.708,2.833-1.792l2.979-6.312    c0.354-0.729,0.396-1.604,0.104-2.375c-0.271-0.792-0.854-1.417-1.604-1.771c-3.583-1.687-7.478-2.771-11.874-3.291l-1.833-0.229    v-6.27c0-1.708-1.396-3.104-3.125-3.104h-7.395c-1.708,0-3.125,1.396-3.125,3.104v6.978l-1.562,0.396    c-10.291,2.604-16.436,9.207-16.436,17.644c0,11.124,11.02,16.081,21.831,19.477c9.978,3.25,11.332,6.062,11.332,8.395    c0,3.604-4.666,6.228-11.082,6.228c-5.458,0-11.332-1.396-15.707-3.729c-0.458-0.25-0.958-0.375-1.479-0.375    c-0.333,0-0.667,0.063-1,0.167c-0.812,0.292-1.479,0.896-1.833,1.687l-2.875,6.395c-0.646,1.479-0.104,3.187,1.312,3.979    c3.645,2.104,9.061,3.666,14.811,4.312l1.854,0.188v6.624C42.823,90.901,44.219,92.297,45.948,92.297z"></path>
        </g>
      </g>
    </SvgIcon>
  );
}

export default BillingIcon;
