import React, { useState, useRef, useCallback } from 'react';
import { useInfinityScroll } from '../../../../hooks/useInfinityScroll/useInfinityScroll';
import PermissionsList from './PermissionsList';

const PermissionsListWrapper = ({
	list,
	lastPage,
	setList,
	...props
}) => {

	const [filter, setFilter] = useState('');

	const ref = useRef(null);

	const onSearch = useCallback(reset => {
		setList(reset, filter);
	}, [filter, setList]);

	const isFetching = useInfinityScroll(!lastPage && onSearch, ref);

	return (
		<PermissionsList
			{...props}
			filter={filter}
			isFetching={isFetching}
			onSearch={onSearch}
			permissions={(list || []).filter(permission => 
				(permission.name + '').toUpperCase().includes(filter.toUpperCase())
				|| (permission.description + '').toUpperCase().includes(filter.toUpperCase()))}
			divRef={ref}
			setFilter={setFilter}
		/>
	);
};

export default PermissionsListWrapper;