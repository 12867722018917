export const styles = () => ({

	root: {
		width: 'inherit',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		padding: '6px 0px',
	},

	title: {
		borderBottom: '2px solid rgba(0, 0, 0, 0.20)',
		fontSize: '12px',
		width: 'inherit',
	},

	description: {
		fontSize: '11px',
		width: 'inherit',
	},

	starsContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},

	starIcon: {
		fill: '#47525e',
	},

});