import { createSlice } from "@reduxjs/toolkit";
import billingGetEnginesCall from "./_sliceCalls/billingGetEngines";
import carclubActiveLicenseLocationCall from "./_sliceCalls/carclubActiveLicenseLocation";
import carclubDetailsCall from "./_sliceCalls/carclubDetails";
import carclubGetConfigsCall from "./_sliceCalls/carclubGetConfigs";
import carclubGetDefaultConfigsCall from "./_sliceCalls/carclubGetDefaultConfigs";
import carclubGetInfoCall from "./_sliceCalls/carclubInfo";
import carclubInsertLegalDocumentCall from "./_sliceCalls/carclubInsertLegalDocument";
import carclubInsertLicenseCall from "./_sliceCalls/carclubInsertLicense";
import carclubSearchLegalDocumentsCall from "./_sliceCalls/carclubSearchLegalDocuments";
import carclubUpdateConfigsCall from "./_sliceCalls/carclubUpdateConfigs";
import carclubUpdateDefaultConfigsCall from "./_sliceCalls/carclubUpdateDefaultConfigs";
import operatorGetDefaultLocCall from "./_sliceCalls/operatorGetDefaultLoc";
import operatorSetDefaultLocCall from "./_sliceCalls/operatorSetDefaultLoc";

export const carclubSlice = createSlice({
  name: "carclub2",
  initialState: {
    defaultConfigs: null,
    configs: null,
    details: null,
    info: null,
    defaultLocation: null,
    billingEngines: null,
    activeLegalDocuments: [],
    legalDocuments: {
      list: [],
      filter: {
        page: 1,
        limit: 10000, // no paging
        type: undefined,
      },
      lastPage: false,
    },
    formSuccess: false
  },
  reducers: {
    setCarclubDefaultConfigs: (state, action) => {
      state.defaultConfigs = action.payload;
    },
    setCarclubConfigs: (state, action) => {
      state.configs = action.payload;
    },
    setCarclubDetails: (state, action) => {
      state.details = action.payload;
    },
    setCarclubInfo: (state, action) => {
      state.info = action.payload;
    },
    setDefaultLocation: (state, action) => {
      state.defaultLocation = action.payload;
    },
    setBillingEngines: (state, action) => {
      state.billingEngines = action.payload;
    },
    setActiveLegalDocuments: (state, action) => {
      state.activeLegalDocuments = action.payload;
    },
    clearLegalDocuments: (state) => {
      state.legalDocuments.list = [];
      state.legalDocuments.lastPage = [];
      state.legalDocuments.filter = { ...state.legalDocuments.filter, page: 1 };
      state.selectedTrip = {}; // reset selected trip on load
    },
    setLegalDocumentsFilter: (state, action) => {
      state.legalDocuments.filter = { ...state.legalDocuments.filter, ...action.payload };
    },
    setLegalDocuments: (state, action) => {
      let nList = [...state.legalDocuments.list, ...action.payload];
      state.legalDocuments.list = nList;
      state.legalDocuments.lastPage = action.payload?.lastPage;
    },
    setFormSuccess: (state, action) => {
      state.formSuccess = action.payload;
    },
  },
});

export const { setCarclubDetails, setCarclubInfo, setDefaultLocation, setFormSuccess } = carclubSlice.actions;

export default carclubSlice.reducer;

// SELECTS
export const selectIsLoading = (state) => Boolean(state.getIn(["core"])?.loader?.length > 0);
export const selectCarclubDetails = (state) => state.getIn(["carclub2"])?.details;
export const selectCarclubInfo = (state) => state.getIn(["carclub2"])?.info;
export const selectDefaultLocation = (state) => state.getIn(["carclub2"])?.defaultLocation;
export const selectCarclubDefaultConfigs = (state) => state.getIn(["carclub2"])?.defaultConfigs;
export const selectCarclubConfigs = (state) => state.getIn(["carclub2"])?.configs;
export const selectBillingEngines = (state) => state.getIn(["carclub2"])?.billingEngines;
export const selectActiveLegalDocuments = (state) => state.getIn(["carclub2"])?.activeLegalDocuments;
export const selectLegalDocumentsFilter = (state) => state.getIn(["carclub2"])?.legalDocuments.filter;
export const selectLegalDocuments = (state) => state.getIn(["carclub2"])?.legalDocuments;
export const selectFormSuccess = (state) => state.getIn(["carclub2"])?.formSuccess;

// CALLS
export const carclubDetails = () => (dispatch, getState) => {
  carclubDetailsCall(carclubSlice.actions, dispatch, getState());
};

export const carclubGetInfo = () => (dispatch, getState) => {
  carclubGetInfoCall(carclubSlice.actions, dispatch, getState());
};

export const operatorGetDefaultLoc = () => (dispatch, getState) => {
  operatorGetDefaultLocCall(carclubSlice.actions, dispatch, getState());
};

export const operatorSetDefaultLoc = (locationId) => (dispatch, getState) => {
  operatorSetDefaultLocCall({ operatorGetDefaultLoc }, dispatch, getState(), locationId);
};

export const carclubGetDefaultConfigs = () => (dispatch, getState) => {
  carclubGetDefaultConfigsCall(carclubSlice.actions, dispatch, getState());
};

export const carclubGetConfigs = () => (dispatch, getState) => {
  carclubGetConfigsCall(carclubSlice.actions, dispatch, getState());
};

export const carclubUpdateConfigs = (configs) => (dispatch, getState) => {
  carclubUpdateConfigsCall({ carclubGetConfigs, carclubGetInfo }, dispatch, getState(), configs);
};

export const carclubUpdateDefaultConfigs = (configs) => (dispatch, getState) => {
  carclubUpdateDefaultConfigsCall({ carclubGetDefaultConfigs, carclubGetInfo }, dispatch, getState(), configs);
};

export const billingGetEngines = () => (dispatch, getState) => {
  billingGetEnginesCall(carclubSlice.actions, dispatch, getState());
};

export const carclubActiveLicenseLocation = () => (dispatch, getState) => {
  carclubActiveLicenseLocationCall(carclubSlice.actions, dispatch, getState());
};

export const carclubSearchLegalDocuments =
  (filter, clear = false) =>
  (dispatch, getState) => {
    carclubSearchLegalDocumentsCall(carclubSlice.actions, dispatch, getState(), { filter, clear });
  };

export const carclubInsertLegalDocument =
  ({ url, mandatory, type, name }) =>
  (dispatch, getState) => {
    carclubInsertLegalDocumentCall(
      { carclubActiveLicenseLocation, carclubSearchLegalDocuments, setFormSuccess },
      dispatch,
      getState(),
      {
        url,
        mandatory,
        type,
        name,
      }
    );
  };

export const carclubInsertLicense =
  ({ idList }) =>
  (dispatch, getState) => {
    carclubInsertLicenseCall({ carclubActiveLicenseLocation, carclubSearchLegalDocuments }, dispatch, getState(), {
      idList,
    });
  };
