import { wsCall, requestHelper } from "../../../app/coreSlice";

const assetList_reportCall = async (
  { setReportAssets },
  dispatch,
  state
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  if (!selectedLocation) {
    return;
  }
  let rh = requestHelper(dispatch, "ASSET/LIST");
  try {
    const result = await wsCall({
      type: "ASSET",
      subType: "LIST",
      locationId: selectedLocation?.id,
      message: {
        activated: true
      },
    });
    if (!result.error) {
      dispatch(
        setReportAssets(result || [])
      );
    }
  } catch (_ex) {
    rh.error("Error getting assets.");
  }
  rh.close();
};

export default assetList_reportCall;
