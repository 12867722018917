import React from 'react';
import Details from './Details/Detail';
import Consumption from './Details/Consumption';
import Pricing from './Details/Pricing';
import Members from './Details/Members';
import Divider from '@material-ui/core/Divider';
import withStyles from '@material-ui/styles/withStyles';
import styles from './AccountDetails.styles';

const AccountDetails = ({
	classes,
	accountDetails,
}) => {

	// Account details
	const accountDetailsDiv = (
		<>
			<Details
				info={accountDetails}
			/>
			<Divider />
		</>
	);

	// CONSUMPTION
	const accountConsumptionDiv = (
		<>
			<Consumption
				details={accountDetails}
			/>
			<Divider />
		</>
	);

	// ACCOUNT PRICING
	const accountPricingDiv = (
		<>
			<Pricing
				details={accountDetails}
			/>
			<Divider />
		</>
	);

	// ACCOUNT MEMBERS
	const accountMembersDiv = (
		<Members
			details={accountDetails}
		/>
	);

	const accountDetailsSidePanel = (
		<div className={classes.root}>
			{accountDetailsDiv}
			{accountConsumptionDiv}
			{accountPricingDiv}
			{accountMembersDiv}
		</div>
	);

	return accountDetailsSidePanel;
};

export default withStyles(styles)(AccountDetails);
