import createLocation from './createLocation';
import listAllLocations from './listAllLocations';
import listLocation from './listLocation';
import removeLocation from './removeLocation';
import updateLocation from './updateLocation';

const locationService = {
	createLocation,
	listAllLocations,
	listLocation,
	removeLocation,
	updateLocation,
};

export default locationService;