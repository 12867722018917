import React, { useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import {
  Typography,
  ClickAwayListener,
  Popper,
  MenuList,
  MenuItem,
  ListItemIcon,
  CircularProgress,
} from "@material-ui/core";
import FuelIcon from "@material-ui/icons/LocalGasStation";
import { styles } from "./FuelLevelFilter.styles";
import MapButton from "../../common/MapButton/MapButton";

function FuelLevelFilter({ classes, filter, isLoading, onSelectFuelLevel }) {
  const [anchorEl, setAnchorEl] = useState(null);

  let selectedIcon;
  if (isLoading) {
    selectedIcon = <CircularProgress size={20} />;
  } else {
    switch (filter?.fuelLevel) {
      case "HIGH":
        selectedIcon = <FuelIcon style={{ color: "#00B67F" }} />;
        break;
      case "MEDIUM":
        selectedIcon = <FuelIcon style={{ color: "#FFD103" }} />;
        break;
      case "LOW":
        selectedIcon = <FuelIcon style={{ color: "#F17618" }} />;
        break;
      case "CRITICAL":
        selectedIcon = <FuelIcon style={{ color: "#FD2E25" }} />;
        break;
      default:
        selectedIcon = <FuelIcon style={{ color: "#4C4C4E" }} />;
        break;
    }
  }

  const onMenuItemClickedHandler = (option) => {
    onSelectFuelLevel && onSelectFuelLevel(option);
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <MapButton tooltip="Filter by Fuel Level" onClick={(e) => setAnchorEl(e.currentTarget)}>
        {selectedIcon}
      </MapButton>
      <Popper className={classes.menu} open={Boolean(anchorEl)} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <MenuList>
            {/* HIGH */}
            <MenuItem onClick={() => onMenuItemClickedHandler("HIGH")}>
              <ListItemIcon className={classes.icon}>
                <FuelIcon style={{ color: "#00B67F" }} />
              </ListItemIcon>
              <Typography>High fuel</Typography>
            </MenuItem>
            {/* MEDIUM */}
            <MenuItem onClick={() => onMenuItemClickedHandler("MEDIUM")}>
              <ListItemIcon className={classes.icon}>
                <FuelIcon style={{ color: "#FFD103" }} />
              </ListItemIcon>
              <Typography>Medium fuel</Typography>
            </MenuItem>
            {/* LOW */}
            <MenuItem onClick={() => onMenuItemClickedHandler("LOW")}>
              <ListItemIcon className={classes.icon}>
                <FuelIcon style={{ color: "#F17618" }} />
              </ListItemIcon>
              <Typography>Low fuel</Typography>
            </MenuItem>
            {/* CRITICAL */}
            <MenuItem onClick={() => onMenuItemClickedHandler("CRITICAL")}>
              <ListItemIcon className={classes.icon}>
                <FuelIcon style={{ color: "#FD2E25" }} />
              </ListItemIcon>
              <Typography>Critical fuel</Typography>
            </MenuItem>
            {/* ALL */}
            <MenuItem onClick={() => onMenuItemClickedHandler(null)}>
              <ListItemIcon className={classes.icon}>
                <FuelIcon style={{ color: "#4C4C4E" }} />
              </ListItemIcon>
              <Typography>All fuel levels</Typography>
            </MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}

export default withStyles(styles)(FuelLevelFilter);
