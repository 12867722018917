import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Grid, IconButton } from "@material-ui/core";
import * as Yup from "yup";

import { styles } from "./ClosedReservationListFilter.styles";
import { Search as SearchIcon } from "@material-ui/icons";
import { Form, SelectField, TextField, DateTimeField } from "../../../../common/forms";
import TripStateFilter from "./TripStateFilter/TripStateFilter";
import { reservationClosedList, selectClosedReservationsFilter, selectIsLoadingTrips } from "../../reservationsSlice";
import { useDateTime } from "../../../../common/hooks";

function ClosedReservationListFilter({ classes, shouldFilterWarnings }) {
  const dispatch = useDispatch();
  const loading = useSelector(selectIsLoadingTrips);
  const filter = useSelector(selectClosedReservationsFilter);
  const [selectedStates, setSelectedStates] = useState([]);
  const { toUtc, now } = useDateTime();

  const typeOptions = [
    { text: "BY TRIP", value: "TRIP" },
    { text: "BY CUSTOMER", value: "CUSTOMER" },
  ];

  useEffect(() => {
    setSelectedStates(filter.states || []);
  }, [filter.states]);

  const formSchema = () => {
    return Yup.object().shape({
      filterType: Yup.string(),
    });
  };

  const onSubmit = (values) => {
    const { fromDate, toDate, search } = values;
    dispatch(
      reservationClosedList(
        {
          ...values,
          fromDateUtc: fromDate ? toUtc(fromDate).format("YYYY-MM-DD HH:mm:00") : undefined,
          toDateUtc: toDate ? toUtc(toDate).format("YYYY-MM-DD HH:mm:ss") : undefined,
          states: selectedStates,
          onlyWarnings: shouldFilterWarnings,
          search: search || "",
        },
        true
      )
    );
  };

  return (
    <div className={classes.root}>
      <Form
        schema={formSchema()}
        initialValues={filter}
        onSubmit={onSubmit}
        render={({ submitting, form, values }) => {
          return (
            <Grid
              container
              spacing={2}
              direction="row"
              wrap="nowrap"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <SelectField id="filterType" options={typeOptions} className={classes.select} />
              </Grid>
              <Grid item>
                <TextField
                  id="search"
                  className={classes.search}
                  placeholder={
                    values.filterType === "TRIP"
                      ? "Insert trip code, make/model, license plate"
                      : "Customer code, name, email or phonenumber"
                  }
                />
              </Grid>
              <Grid item>
                <DateTimeField
                  id="fromDate"
                  className={classes.date}
                  placeholder="Start date"
                  maxDate={now().format("YYYY-MM-DD HH:mm:ss")}
                />
              </Grid>
              <Grid item>
                <DateTimeField
                  id="toDate"
                  className={classes.date}
                  placeholder="End date"
                  maxDate={now().format("YYYY-MM-DD HH:mm:ss")}
                  minDate={values.fromDate}
                />
              </Grid>
              <Grid item>
                <TripStateFilter
                  states={selectedStates}
                  onSelectStatesChange={(options) => setSelectedStates(options)}
                />
              </Grid>
              <Grid item>
                <IconButton type="submit" disabled={Boolean(loading)}>
                  <SearchIcon />
                </IconButton>
              </Grid>
            </Grid>
          );
        }}
      />
    </div>
  );
}

export default withStyles(styles)(ClosedReservationListFilter);
