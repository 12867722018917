import React, { useState, useEffect } from 'react';
import Features from './Features';

const compare = (a, b) => ('' + a.type).localeCompare(b.type);
const listFilter = (element, filter) => 
	element.type.includes(filter.type) 
	&& element.subtype.includes(filter.subtype);

const FeaturesWrapper = ({
	features,
	...props
}) => {

	const [filter, setFilter] = useState({type: '', subtype: ''});
	const [list, setList] = useState([]);

	useEffect(() => {
		setList((features && Object
			.values(features)
			.sort(compare)
			.filter(element => listFilter(element, filter))
		) || []);
	}, [features, filter]);

	const changeFilter = (field, value) => typeof value === 'string' 
		&& setFilter({...filter, [field]: value.trim().toUpperCase()});

	return (
		<Features
			{...props}
			features={list}
			filter={filter}
			setFilter={changeFilter}
		/>
	);
};

export default FeaturesWrapper;