import React from 'react';
import ListItem from '../../../UI/List/ListItem/ListItem';
import {
	Tooltip,
	withStyles,
} from '@material-ui/core';
import {
	Create as EditIcon,
	Check as EnabledIcon,
} from '@material-ui/icons';
import withPermission from '../../../../hoc/withPermission';
import { currencyFormatter } from '../../../../services/formatter';
import styles from './ExtraItem.styles';

const ExtraItem = ({
	classes,
	fields,
	selected,
	extra,
	onRemove,
	onSelect,
	setModal,
	editMode,
}) => {

	const editIcon = (
		<Tooltip title='Edit' placement='left'>
			<EditIcon
				onClick={event => {
					event.stopPropagation();
					onSelect(extra);
					setModal();
					editMode.toggle();
				}}
				className={classes.editIcon}
			/>
		</Tooltip>
	);

	const enabled = extra?.enabled && 
		<EnabledIcon className={classes.checkIcon} />
	;

	const editIcons = (
		<div className={classes.icons}>
			<ButtonsWithPermission>
				{editIcon}
			</ButtonsWithPermission>
		</div>
	);

	const value = !!extra.value
		&& currencyFormatter.format(extra?.value) + ' ' + currencyFormatter.symbol();

	const item = (
		<ListItem
			className={classes.root}
			selected={selected}
			fields={fields}
			onSelect={() => onSelect(extra)}
			element={{
				...extra,
				value,
				enabled,
				editIcons,
			}}
		/>
	);

	return item;
};

export default withStyles(styles)(ExtraItem);

const ButtonsWithPermission = withPermission(
	[{ resource: ['pricingPlansNew', 'extras', 'edit'] }],
)(({ children }) => <> {children} </>);