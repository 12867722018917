import React from 'react';
import {
	Tooltip,
	withStyles,
} from '@material-ui/core';
import {
	RemoveCircleOutline as RemoveIcon,
} from '@material-ui/icons';
import withPermission from '../../../../../../hoc/withPermission';
import ListItem from '../../../../../UI/List/ListItem/ListItem';
import { millisecondsToTripDurationFormattedTime } from '../../../../../../utils/date';
import styles from './Item.styles';

const Item = ({
	classes,
	fields,
	onRemove,
	onSelect,
	selected,
	task = {},
}) => {

	const {
		endTime,
		startTime,
		status,
	} = task;

	const timeSpentInTask = !!endTime && !!startTime
		&& millisecondsToTripDurationFormattedTime(endTime - startTime);

	const color = {
		'Completed': '#00B67F',
		'In Process': '#FFD103',
		'Open': '',
		'Discarded': '#FD2E25',
	}[status];

	const removeButton = onRemove && (
		<ButtonWithPermission>
			<Tooltip title='Remove workorder' placement='left'>
				<RemoveIcon
					className={classes.icon}
					onClick={() => onRemove(task.code)}
				/>
			</Tooltip>
		</ButtonWithPermission>
	);

	const actions = (
		<>
			{removeButton}
		</>
	);

	return (
		<ListItem
			className={classes.row}
			dinamicStyle={{ color }}
			fields={fields}
			onSelect={() => onSelect(task)}
			selected={selected}
			element={{
				...task,
				timeSpentInTask,
				actions,
			}}
		/>
	);
};

export default withStyles(styles)(Item);

const ButtonWithPermission = withPermission(
	[{ resource: ['workOrders', 'edit'] }]
)(({
	children,
}) =>
	(
		<>
			{children}
		</>
	)
);