
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_REQUEST_IS_MALFORMED,
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import mapper from './mappers/taskmapper';
import { publishAndAwait } from '../../../app/coreSlice';

const getTasks = async ({code, endDate}) => {

	if (!code) {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	}

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'WORKTASK',
			subType: 'GET',
			message: {
				id: code,
				completed: !!endDate,
			},
		},
	});
	
	const { processMessage, error } = request.response;
	
	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;


		throw new NGError({
			message: description,
			code,
			tag,
		});
	   };
	
	return (processMessage.tasks || []).map(mapper.fromDTO);
};

export default getTasks;
