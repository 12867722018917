import React from 'react';
import Detail from './Detail';
import DetailsDiv from '../../../UI/DetailsDiv/DetailsDiv';
import DetailsRow from '../../../UI/DetailsDiv/DetailsRow/DetailsRow';
import withStyles from '@material-ui/styles/withStyles';
import styles from './Details.styles';

const Members = ({
	classes,
	info,
}) => {

	const {
		totalMembers,
		memberConsumptionAverage,
		memberNumberVariation,
	} = info || {};

	const totalMembersField = !!totalMembers && (
		<DetailsRow
			label='Total Members'
			detail={
				<Detail detail={totalMembers} />
			}
		/>
	);

	const memberConsumptionAverageField = !!memberConsumptionAverage && (
		<DetailsRow
			label='Member Consumption Average'
			detail={
				<Detail detail={memberConsumptionAverage} />
			}
		/>
	);

	const memberNumberVariationField = !!memberNumberVariation && (
		<DetailsRow
			label='Member Number Average'
			detail={
				<Detail detail={memberNumberVariation} />
			}
		/>
	);

	const members = (
		<DetailsDiv title='Account Members'>
			{totalMembersField}
			{memberConsumptionAverageField}
			{memberNumberVariationField}
		</DetailsDiv>
	);

	return members;
};

export default withStyles(styles)(Members);