export const styles = (theme) => ({
  root: {
    marginBottom: 2,
  },
  disabledLabel: {
    color: "#97A1AF",
  },
  disabledInput: {
    backgroundColor: "#F5F7F9",
    color: "#97A1AF",
  },
  switch: {
    margin: 0,
  },
  checkbox: {
    padding: 0,
    paddingRight: 10,
  },
  select: {
    margin: 0,
  },
  clear: {
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  decimalInput: {
    textAlign: "right",
  },
});
