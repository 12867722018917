import React from "react";
import { Tooltip, Typography, withStyles } from "@material-ui/core";
import { styles } from "./AssetInfoContent.styles";
import Button from "../../../../../UI/Buttons/CommonButtons";
import classnames from "classnames";
import AttributesSelector from "./AttributesSelector/AttributesSelector";
import { getAssetTypeId } from "../../../../../../model/Asset/AssetTypeId";
import { SelectInput, TextInput } from "../../../../../../common/inputs";

const AssetInfoContent = ({
  classes,
  className,
  generalAssetInformation,
  hasRequest,
  imageHandler,
  onChangeHandler,
  onCreate,
  onEdit,
  placeholder,
}) => {
  const { brand, classes: vehicleClasses, fuel, transmission } = generalAssetInformation || {};

  // if (!placeholder.transmission && placeholder?.assetTypeName === "SCOOTER") {
  //   placeholder.transmission = "AUTOMATIC";
  // }

  if (!placeholder.class) {
    let availableClasses = (vehicleClasses || []).filter(
      (vc) => vc.assetTypeId === getAssetTypeId(placeholder?.assetTypeName)
    );
    if (availableClasses.length === 1) {
      placeholder.class = availableClasses[0].name;
    }
  }

  const attributes = generalAssetInformation?.attributes && generalAssetInformation?.attributes.length > 0 && (
    <div>
      <br />
      <br />
      <Typography variant="h6">Attributes</Typography>
      <br />
      <AttributesSelector
        classes={classes}
        allAtributes={(generalAssetInformation?.attributes || []).filter(
          (atr) => atr.assetTypeId === getAssetTypeId(placeholder?.assetTypeName)
        )}
        attributes={placeholder.attributes}
        setAttributes={(attributes) => onChangeHandler("attributes", attributes)}
        disabled={(!onEdit && !onCreate) || hasRequest}
      />
    </div>
  );

  const img = (
    <div className={classnames(classes.imageDiv, !placeholder?.imageUrl && classes.imageBackground)}>
      {!!placeholder?.imageUrl && <img src={placeholder?.imageUrl} alt="Asset" className={classes.image} />}
    </div>
  );

  const updateImage = (
    <Tooltip title={"Click to choose an image."}>
      <div className={classes.imageDiv}>
        {img}
        <input
          id="image"
          hidden
          type="file"
          accept="image/*"
          onChange={imageHandler}
          required={false}
          disabled={false}
        />
        <label htmlFor="image">
          <Button buttonStyleName="filled" className={classes.imageButton} disabled={hasRequest} label="Upload Image" />
        </label>
      </div>
    </Tooltip>
  );

  const imageDiv = onEdit || onCreate ? updateImage : img;

  return (
    <div className={classnames(classes.root, className)}>
      <div className={classnames(classes.textDetailsBlock, classes.textDetailsBlockOnEdit)}>
        <TextInput
          className={classes.input2}
          label="Asset Name"
          onChange={(value) => onChangeHandler("assetName", value)}
          value={placeholder?.assetName || ""}
          required
          disabled={(!onEdit && !onCreate) || hasRequest}
        />
        <TextInput
          className={classes.input2}
          label="License Plate"
          onChange={(value) => onChangeHandler("licensePlate", value)}
          value={placeholder?.licensePlate || ""}
          required
          disabled={(!onEdit && !onCreate) || onEdit || hasRequest}
        />
        <SelectInput
          className={classes.input2}
          label="Make"
          onChange={(value) => onChangeHandler("make", value)}
          value={placeholder?.make || ""}
          required
          disabled={(!onEdit && !onCreate) || hasRequest}
          options={(brand || [])
            .filter((brd) => brd.assetTypeId === getAssetTypeId(placeholder?.assetTypeName))
            .map((item) => {
              return {
                text: item.name,
                value: item.name,
              };
            })}
        />
        <SelectInput
          className={classes.input2}
          label="Model"
          onChange={(value) => onChangeHandler("model", value)}
          value={placeholder?.model || ""}
          required
          disabled={(!onEdit && !onCreate) || hasRequest}
          options={(brand || [])
            .find((brand) => brand?.name === placeholder?.make)
            ?.model.map((item) => {
              return {
                text: item.name,
                value: item.name,
              };
            })}
        />
        <TextInput
          className={classes.input2}
          label="VIN Number"
          onChange={(value) => onChangeHandler("vinNumber", value)}
          value={placeholder?.vinNumber || ""}
          required
          disabled={(!onEdit && !onCreate) || onEdit || hasRequest}
        />
      </div>
      <div className={classnames(classes.textDetailsBlock, classes.textDetailsBlockOnEdit)}>
        {imageDiv}
        <br />
        <SelectInput
          className={classes.input2}
          label="Class"
          onChange={(value) => onChangeHandler("class", value)}
          value={placeholder?.class || ""}
          required
          disabled={(!onEdit && !onCreate) || hasRequest}
          options={(vehicleClasses || [])
            .filter((vc) => vc.assetTypeId === getAssetTypeId(placeholder?.assetTypeName))
            .map((item) => {
              return {
                text: item.name,
                value: item.name,
              };
            })}
        />
        <SelectInput
          className={classes.input2}
          label="Fuel Type"
          onChange={(value) => onChangeHandler("fuelType", value)}
          value={placeholder?.fuelType || ""}
          required
          disabled={(!onEdit && !onCreate) || hasRequest}
          options={(fuel || []).map((item) => {
            return {
              text: item.name,
              value: item.name,
            };
          })}
        />
        <TextInput
          className={classes.input2}
          label="Year"
          onChange={(value) => onChangeHandler("year", value)}
          value={placeholder?.year || ""}
          required
          disabled={(!onEdit && !onCreate) || hasRequest}
        />
        <SelectInput
          className={classes.input2}
          label="Transmission"
          onChange={(value) => onChangeHandler("transmission", value)}
          value={placeholder?.transmission || ""}
          required
          disabled={(!onEdit && !onCreate) || hasRequest}
          options={(transmission || []).map((item) => {
            return {
              text: item.name,
              value: item.name,
            };
          })}
        />
      </div>
      <div className={classnames(classes.imageDetailsBlock, onEdit && classes.imageDetailsBlockOnEdit)}>
        {attributes}
      </div>
    </div>
  );
};

export default withStyles(styles)(AssetInfoContent);
