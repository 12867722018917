import React from 'react';
import {
	Drawer,
	Typography,
	withStyles,
} from '@material-ui/core';
import classnames from 'classnames';
import List from './List/List';
import Details from './Details/Details';
import Update from './Update/UpdateWrapper';
import { ReactComponent as CreateIcon } from '../../../assets/PromotionApp/icons/add.svg';
import Searchbar from '../../UI/Searchbar/Searchbar';
import withPermission from '../../../hoc/withPermission';
import styles from './IndividualBonus.styles';

const IndividualBonus = ({
	addBonus,
	bonuses,
	classes,
	currentScreenSize,
	drawer,
	filter,
	getMoreRows,
	handleNotification,
	hasNotification,
	lastPage,
	onSearch,
	selectedIndividualBonus,
	setDrawer,
	setFilter,
	setSelectedIndividualBonus,
}) => {
	const errorBar = (
		<Typography align='center' className={classnames(
			classes.rootBar,
			hasNotification?.message ? classes.showBar : classes.hideBar,
			hasNotification?.success ? classes.success : classes.error)}>
			{hasNotification?.message}
		</Typography>
	);

	const createIcon = (
		<CreateButton
			onClick={() => {
				setSelectedIndividualBonus();
				setDrawer(true);
			}}
			className={classes.createIcon}
			size="small"
		/>
	);

	const title = (
		<Typography className={classes.title}>
			Individual Bonus
		</Typography>
	);

	const searchbar = (
		<div className={classes.searchbar}>
			<div className={classes.titleAndSearch}>
				{title}
				<Searchbar
					filter={filter}
					className={classes.searchLabel}
					label='Search by client name, mobile, email or bonus id'
					onSearch={onSearch}
					setFilter={setFilter}
				/>
			</div>
			<div className={classes.actions}>
				{createIcon}
			</div>
		</div>
	);

	const list = (
		<List
			currentScreenSize={currentScreenSize}
			getMoreRows={!lastPage && getMoreRows}
			onSelect={setSelectedIndividualBonus}
			bonuses={bonuses}
			selected={selectedIndividualBonus?.id}
		/>
	);

	const details = (
		<Details
			details={selectedIndividualBonus}
			onEdit={() => setDrawer(true)}
		/>
	);

	const update = (
		<Drawer
			anchor='right'
			onClose={() => setDrawer(false)}
			open={drawer}
		>
			<Update
				addBonus={addBonus}
				closeHandler={() => setDrawer(false)}
				handleNotification={handleNotification}
				selected={selectedIndividualBonus}
			/>
		</Drawer>
	);

	return (
		<div className={classes.root}>
			{errorBar}
			{update}
			<div className={classes.workspace}>
				<div className={classes.list}>
					{searchbar}
					{list}
				</div>
				<div className={classes.details}>
					{details}
				</div>
			</div>
		</div>
	);
};

export default withStyles(styles)(IndividualBonus);

const CreateButton = withPermission(
	[{ resource: ['promotions', 'individualBonus', 'create'] }],
)(
	props => <CreateIcon {...props} />
);