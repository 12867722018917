import React from "react";
import Button from "../../UI/Buttons/CommonButtons";
import { Typography, withStyles } from "@material-ui/core";
import styles from "./CustomerEditCreate.styles";
import { TextInput } from "../../../common/inputs";
import { useSelector } from "react-redux";
import { selectCarclubInfo, selectDefaultLocation } from "../../../features/carclub/carclubSlice";
import { isCommercial, isSubscription } from "../../../common/utils/OTypes";

// This form is only for UPDATE Driver/Customer

const CustomerEditCreate = ({
  classes,
  customer,
  customerName,
  isButtonDisabled,
  onCancel,
  onChange,
  onReset,
  onSave,
}) => {
  const selectedLocation = useSelector(selectDefaultLocation);
  const gdpdr = useSelector(selectCarclubInfo)?.secrets?.GDPDR;

  return (
    <div className={classes.root}>
      <Typography key="title" className={classes.title}>
        {customer.customerCode ? `Editing ${customerName}` : "Creating new Customer"}
      </Typography>
      <div className={classes.form}>
        <div className={classes.subform}>
          <Typography className={classes.subTitle}>Personal Info</Typography>
          <TextInput
            label="Name"
            disabled={gdpdr}
            value={customer?.name || ""}
            onChange={(value) => onChange({ name: "name", value })}
          />
          <TextInput
            label="Surname"
            disabled={gdpdr}
            value={customer?.surname || ""}
            onChange={(value) => onChange({ name: "surname", value })}
          />
          <TextInput
            label="Mobile number"
            value={customer?.phoneNumber || ""}
            onChange={(value) => onChange({ name: "phoneNumber", value })}
          />
          <TextInput
            label="Email"
            value={customer?.email || ""}
            onChange={(value) => onChange({ name: "email", value })}
          />
          <TextInput
            label="Address"
            value={customer?.address || ""}
            onChange={(value) => onChange({ name: "address", value })}
          />
          <TextInput
            label="City"
            value={customer?.city || ""}
            onChange={(value) => onChange({ name: "city", value })}
          />
          <TextInput
            label="Zip code"
            value={customer?.zipCode || ""}
            onChange={(value) => onChange({ name: "zipCode", value })}
          />
          <TextInput
            label="State"
            value={customer?.state || ""}
            onChange={(value) => onChange({ name: "state", value })}
          />
          <TextInput
            label="Country"
            value={customer?.country || ""}
            onChange={(value) => onChange({ name: "country", value })}
          />
          <TextInput
            label="AffiliateID"
            value={customer?.affiliateId || ""}
            onChange={(value) => onChange({ name: "affiliateId", value })}
          />
        </div>
        
        {(isCommercial(selectedLocation.operationType) || isSubscription(selectedLocation.operationType)) && (
          <div className={classes.subform}>
            <Typography className={classes.subTitle}>Devices</Typography>
            <TextInput
              label="Rfid Token"
              value={customer?.rfidToken || ""}
              onChange={(value) => onChange({ name: "rfidToken", value })}
            />
          </div>
        )}
        <div className={classes.subform}>
          <Typography className={classes.subTitle}>Billing</Typography>
          <TextInput
            label="Tax number"
            value={customer?.taxNumber || ""}
            onChange={(value) => onChange({ name: "taxNumber", value })}
          />
          <TextInput
            label="Address"
            value={customer?.billingAddress || ""}
            onChange={(value) => onChange({ name: "billingAddress", value })}
          />
          <TextInput
            label="City"
            value={customer?.billingCity || ""}
            onChange={(value) => onChange({ name: "billingCity", value })}
          />
          <TextInput
            label="Zip code"
            value={customer?.billingZipCode || ""}
            onChange={(value) => onChange({ name: "billingZipCode", value })}
          />
          <TextInput
            label="State"
            value={customer?.billingState || ""}
            onChange={(value) => onChange({ name: "billingState", value })}
          />
          <TextInput
            label="Country"
            value={customer?.billingCountry || ""}
            onChange={(value) => onChange({ name: "billingCountry", value })}
          />
        </div>
      </div>
      <div className={classes.buttons}>
        <Button buttonStyleName="void" disabled={Boolean(isButtonDisabled)} label="Cancel" onClick={onCancel} />
        <Button
          buttonStyleName="filled"
          disabled={Boolean(isButtonDisabled)}
          label={customer.customerCode ? "Update" : "Create"}
          onClick={onSave}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(CustomerEditCreate);
