const styles = (theme) => ({
	root: {
		boxSizing: 'border-box',
		height: '100%',
		width: '100%',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
	},

	searchIcon: {
		cursor: 'pointer',
		marginLeft: '18px',
	},

	pickers: {
		maxHeight: '50px',
		maxWidth: '170px',
		margin: 'auto 5px',
	},

	input: {
		padding: '3px !important',
		color: '#89898B',
		maxWidth: '120px',
		minWidth: '120px',
	},

	inputIcon: {
		padding: '2px',
	},

	selectEmpty: {
		marginTop: theme.spacing(2),
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1),
		minWidth: '120px',
		maxWidth: '100%',
	},

	formControl: {
		margin: '0 10px 0 15px',
	},

	pickerInput: {
		padding: 8,
	},

	outlined: {
		transform: 'translate(14px, 10px) scale(1)',
	},
});

export default styles;