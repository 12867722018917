import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const princingPlanDeleteCall = async ({ setPrincingPlansList }, dispatch, state, { pricingPlanId }) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "PRICING_PLAN/DELETE");

  try {
    const result = await wsCall({
      type: "PRICING_PLAN",
      subType: "DELETE",
      locationId: selectedLocation?.id,
      message: {
        pricingPlanId,
      },
    });
    if (result && !result.error) {
      dispatch(setSuccess("Pricing plan deleted successfully"));
      dispatch(setPrincingPlansList(result));
    }
  } catch (_ex) {
    console.error(_ex);
    rh.error("Error deleting princing plan");
  }
  rh.close();
};

export default princingPlanDeleteCall;
