import React from 'react';
import ListItem from '../../../UI/List/ListItem/ListItem';
import {
	Tooltip,
	withStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Create';
import UserIcon from '@material-ui/icons/AccountCircle';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import GraphIcon from '@material-ui/icons/Assessment';
import EnabledIcon from '@material-ui/icons/Check';
import EnableIcon from '@material-ui/icons/RemoveCircleOutline';
import classnames from 'classnames';
import { currencyFormatter } from '../../../../services/formatter';
import modals from '../../../../model/Account/modals';
import withPermission from '../../../../hoc/withPermission';
import styles from './AccountItem.styles';

const onClick = func => e => {
	!!e && e.stopPropagation();
	!!func && func();
};

const AccountItem = ({
	account,
	classes,
	disable,
	enable,
	fields,
	onSelect,
	selected,
	setModal,
}) => {
	const enableIcon = (
		<ButtonWithPermission title={account?.enabled ? 'Disable Account' : 'Enable Account'}>
			<EnableIcon
				className={classnames(classes.icon, !account.enabled && classes.greyIcon)}
				onClick={() => account.enabled ? disable(account.code) : enable(account.code)}
			/>
		</ButtonWithPermission>
	);

	const editIcon = (
		<ButtonWithPermission title='Edit Account'>
			<EditIcon
				className={classes.icon}
				onClick={() => setModal(modals.EDIT)}
			/>
		</ButtonWithPermission>
	);

	const userIcon = (
		<Tooltip title='Account Members' placement='left'>
			<UserIcon
				onClick={onClick(() => {
					onSelect(account.code);
					setModal(modals.MEMBERS);
				})}
				className={classes.icon}
			/>
		</Tooltip>
	);

	const consumptionIcon = (
		<Tooltip title='Consumption' placement='left'>
			<CreditCardIcon
				onClick={onClick(() => {
					onSelect(account.code);
					setModal(modals.CONSUMPTION);
				})}
				className={classes.icon}
			/>
		</Tooltip>
	);

	const tripsIcon = (
		<Tooltip title='Show account Trips' placement='left'>
			<GraphIcon
				onClick={onClick(() => {
					onSelect(account.code);
					setModal(modals.TRIPS);
				})}
				className={classes.icon}
			/>
		</Tooltip>
	);

	const dueAmount = !!account.dueAmount &&
		`${currencyFormatter.format(account.dueAmount)} ${currencyFormatter.symbol()}`;

	const icons = (
		<>
			{editIcon}
			{enableIcon}
			{userIcon}
			{consumptionIcon}
			{tripsIcon}
		</>
	);

	const enabled = !!account.enabled && <EnabledIcon />;

	const item = (
		<ListItem
			selected={selected}
			fields={fields}
			onSelect={() => {
				onSelect(account.code);/* 
				getAccount(account.code); */
			}}
			element={{
				...account,
				dueAmount,
				enabled,
				icons,
			}}
		/>
	);

	return item;
};

const ButtonWithPermission = withPermission(
	[{ resource: ['accounts', 'edit'] }]
)(({
	children,
	title,
}) =>
	(
		<Tooltip title={title} placement='left'>
			{children}
		</Tooltip>
	));

export default withStyles(styles)(AccountItem);