import React from 'react';
import withStyles from '@material-ui/styles/withStyles';
import styles from './NetworkSpinner.styles';
import classnames from 'classnames';

const networkSpinner = (props) => {

	const { classes, className } = props;

	return (
		<div className={classnames(classes.root, className)}>
			<div className={classnames(classes.cube, classes.cube1)}></div>
			<div className={classnames(classes.cube, classes.cube2)}></div>
			<div className={classnames(classes.cube, classes.cube3)}></div>
			<div className={classnames(classes.cube, classes.cube4)}></div>
			<div className={classnames(classes.cube, classes.cube5)}></div>
			<div className={classnames(classes.cube, classes.cube6)}></div>
			<div className={classnames(classes.cube, classes.cube7)}></div>
			<div className={classnames(classes.cube, classes.cube8)}></div>
			<div className={classnames(classes.cube, classes.cube9)}></div>
		</div>
	);
};

export default withStyles(styles)(networkSpinner);