import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/styles/withStyles";

import { styles } from "./AssetsRefreshLoop.styles";
import MapButton from "../../common/MapButton/MapButton";
import LoopIcon from "@material-ui/icons/Loop";
import { useDateTime, usePrevious } from "../../../../common/hooks";
import { useSelector } from "react-redux";
import { selectDefaultLocation } from "../../../carclub/carclubSlice";
import { assetList, selectAssetsFilter, selectSelectedAsset } from "../../assetSlice";
import { alertList, selectAlertsTabOpen } from "../../../alerts/alertsSlice";
import { useDispatch } from "react-redux";
import { getSelectedAssetHeartbeats } from "../../../bigdata/bigdataSlice";

function AssetsRefreshLoop({ classes }) {
  const [timerID, setTimerID] = useState();
  const dispatch = useDispatch();
  const { now, toUtc } = useDateTime();
  const selectedLocation = useSelector(selectDefaultLocation);
  const selectedAsset = useSelector(selectSelectedAsset);
  const prevSelectedAsset = usePrevious(selectedAsset);
  const alertsTabOpened = useSelector(selectAlertsTabOpen);
  const assetsFilter = useSelector(selectAssetsFilter);
  let interval = parseInt(selectedLocation?.periodicRefreshInterval || 1) * 60;

  useEffect(() => {
    return () => {
      if (timerID) {
        clearInterval(timerID);
      }
    };
  }, [timerID]);

  useEffect(() => {
    if (selectedAsset && selectedAsset?.assetToken !== prevSelectedAsset?.assetToken && timerID) {
      console.log("useEffect - timerID: %o || selectedAsset: %o", timerID, selectedAsset);
      clearInterval(timerID);
      setTimerID();
      toggleLoopHandler(selectedAsset, null);
    }
    // eslint-disable-next-line
  }, [selectedAsset]);

  const onRefresh = (refreshStartDate, asset) => {
    console.log("REFRESH - selectedAsset: %o", asset);
    if (alertsTabOpened.current) {
      dispatch(alertList({ clear: true }));
    }
    if (asset && asset?.deviceToken) {
      setTimeout(() => {
        let end = toUtc(now().add(10, "seconds")).format("YYYY-MM-DD HH:mm:ss");
        dispatch(
          getSelectedAssetHeartbeats({
            deviceToken: asset?.deviceToken,
            assetToken: asset?.assetToken,
            start: refreshStartDate,
            end,
          })
        );
      }, 200);
    }
    dispatch(assetList(assetsFilter));
  };

  const toggleLoopHandler = (asset, timerID) => {
    let startDate = now();
    console.log("timerID: %o || asset: %o", timerID, asset);
    if (!timerID) {
      onRefresh && onRefresh(toUtc(startDate).format("YYYY-MM-DD HH:mm:ss"), asset);

      let id = setInterval(() => {
        let tickDiff = now().diff(startDate, "minutes");
        if (tickDiff < 60) {
          onRefresh && onRefresh(toUtc(startDate).format("YYYY-MM-DD HH:mm:ss"), asset);
        } else {
          clearInterval(id);
          setTimerID();
        }
        }, 1000 * (interval < 60 ? 60 : interval));
      // }, 1000 * 10);
      setTimerID(id);
    } else {
      clearInterval(timerID);
      setTimerID();
    }
    // eslint-disable-next-line
  };

  return (
    <div className={classes.root}>
      <MapButton
        tooltip={timerID ? "Turn periodic refresh off" : "Turn periodic refresh on"}
        onClick={() => toggleLoopHandler(selectedAsset, timerID)}
      >
        <LoopIcon style={timerID ? { color: "#009bcc" } : undefined} />
      </MapButton>
    </div>
  );
}

export default withStyles(styles)(AssetsRefreshLoop);
