import React from 'react';
import AccountList from './AccountList';

const AccountListWrapper = props => {

	return (
		<AccountList
			{...props}
		/>
	);
};

export default AccountListWrapper;