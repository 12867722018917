import React from "react";
import AssetInformation from "./AssetInformation/AssetInformation";
import ZonesAndParks from "./ZonesAndParks/ZonesAndParks";
import { Typography, Modal, withStyles } from "@material-ui/core";
import Buttons from "./Buttons/Buttons";
import { styles } from "./DisplayAsset.styles";
import classnames from "classnames";
import DeviceInfo from "../../../../features/asset/AssetDetails/DeviceInfo/DeviceInfo";

const DisplayAsset = ({ classes, className, errorHandling, generalInfo, handlers, hasRequest, placeholder }) => {
  const { errorMessage, isSuccess, hasNotification } = errorHandling || {};

  return (
    <Modal open={true}>
      <div className={classnames(classes.root, className)}>
        <AssetInformation
          generalInfo={generalInfo}
          handlers={handlers}
          hasRequest={hasRequest}
          placeholder={placeholder}
        />
        <Typography
          align="center"
          className={classnames(
            classes.rootBar,
            hasNotification ? classes.showBar : classes.hideBar,
            !!isSuccess ? classes.success : classes.error
          )}
        >
          {errorMessage}
        </Typography>
        <div className={classes.bottomHalf}>
          <ZonesAndParks placeholder={placeholder} handlers={handlers} generalInfo={generalInfo} />
          <hr
            style={{
              height: "135px",
              borderLeft: "1px solid #E0E0E0",
              borderRight: "1px solid #E0E0E0",
              alignSelf: "end",
              margin: "25px",
            }}
          />
          <DeviceInfo
            provider_token={placeholder?.provider}
            serial_number={placeholder?.device?.serial_number}
            externalId={placeholder?.device?.externalId}
          />
        </div>

        <Buttons generalInfo={generalInfo} handlers={handlers} hasRequest={hasRequest} placeholder={placeholder} />
      </div>
    </Modal>
  );
};

export default withStyles(styles)(DisplayAsset);
