import { wsCall, requestHelper } from "../../../app/coreSlice";

const crParkListCall = async ({ setCrParks }, dispatch, state, { filter }) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "PARK/LIST");
  try {
    const result = await wsCall({
      type: "PARK",
      subType: "LIST",
      locationId: selectedLocation?.id,
      message: {
        filter,
      },
    });
    if (result) {
      dispatch(setCrParks(result));
    }
  } catch (_ex) {
    rh.error("Error getting parks.");
  }
  rh.close();
};

export default crParkListCall;
