import React from "react";
import { TableBody, TableRow, TableCell } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';

export default function CustomDataGridBodySkeleton({
  columns,
  rows,
  actionsComponent,
}) {

  const skeletonRows = [];
  for (let i = 0; i < rows; i++) {
    skeletonRows.push(i);
  }
  return (
    <TableBody>
      {skeletonRows.map((row, row_index) => (
        <TableRow key={"tbody_row_" + row_index}>
          {columns.map((col) => (
            <TableCell
              key={"tbody_" + col.field + "_" + row_index}
              align={col.align}
            >
              <Skeleton animation="wave" />
            </TableCell>
          ))}
          {actionsComponent && (
            <TableCell key={"tbody_actions_" + row_index}>
            </TableCell>
          )}
        </TableRow>
      ))}
    </TableBody>
  );
}
