import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const deviceSeatBatteryCall = async ({ updateAssetOnList }, dispatch, state, { carToken }) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "DEVICE/SEAT_BATTERY");
  try {
    const result = await wsCall({
      type: "DEVICE",
      subType: "SEAT_BATTERY",
      locationId: selectedLocation?.id,
      message: {
        carToken,
      },
    });
    if (result && !result.error) {
      dispatch(setSuccess("Seat/Battery unlocked!"));
      dispatch(updateAssetOnList(result));
    } else {
      rh.error(result?.description || "Error unlocking seat/battery!");
    }
  } catch (_ex) {
    rh.error("Error unlocking seat/battery!");
  }
  rh.close();
};

export default deviceSeatBatteryCall;
