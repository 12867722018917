import React from "react";
import DetailsDiv from "../../UI/DetailsDiv/DetailsDiv";
import DetailsRow from "../../UI/DetailsDiv/DetailsRow/DetailsRow";
import Button from "../../UI/Buttons/CommonButtons";
import ConfirmationModal from "../../UI/ConfirmationModal/ConfirmationModal";
import modal from "../../../model/Incident/modal";
import { Download as DownloadIcon } from "../../../assets/IncidentsApp/all-icons";
import { Typography, withStyles } from "@material-ui/core";
import { Done as TrueIcon } from "@material-ui/icons";

import withPermission from "../../../hoc/withPermission";
import styles from "./IncidentsDetails.styles";

const IncidentsDetails = ({ classes, currentModal, detailsToShow, hasModal, hasRequest, incident, setModal }) => {
  const hasPaymentButtons = !!incident.pdfUrl && !incident.paid && !!incident.customerCode;

  const details = (
    <DetailsDiv title="Incident Details">
      {detailsToShow.map((detail) => {
        if (detail.icon && !incident[detail.key]) {
          return null;
        }
        const value = !!incident[detail.key] ? incident[detail.key] : "N/A";
        const symbol = !!incident[detail.key] && !!detail.symbol ? detail.symbol : "";
        const text = !detail.icon && <Typography className={classes.typography}>{value + symbol}</Typography>;
        return (
          <DetailsRow label={detail.label} detail={text || <TrueIcon />} column={!!detail.column} key={detail.key} />
        );
      })}

      <br />
      {incident.attachment?.length > 0 && (
        <div className={classes.files}>
          <Typography className={classes.attachments}>Attachments</Typography>
          {incident.attachment.map((file, index) => {
            if (!file) {
              return null;
            }

            let label = file.split("/");
            if (label.length > 0) {
              label = label[label.length - 1];
            }
            label = label.split("?")[0];

            return (
              <DetailsRow
                label={label}
                detail={<DownloadIcon className={classes.downloadIcon} onClick={() => window.open(file, "_blank")} />}
                key={file + "-" + index}
              />
            );
          })}
          <br />
        </div>
      )}
    </DetailsDiv>
  );

  const closeButton = !incident.closed && (
    <Button
      buttonStyleName="filled"
      disabled={hasRequest}
      key="CloseButton"
      label="Close incident"
      onClick={() => setModal(modal.CLOSE)}
    />
  );

  const chargeButton = hasPaymentButtons && (
    <Button
      buttonStyleName="void"
      disabled={hasRequest}
      key="ChargeButton"
      label="Charge incident"
      onClick={() => setModal(modal.CHARGE)}
    />
  );

  const settleButton = hasPaymentButtons && (
    <Button
      buttonStyleName="filled"
      disabled={hasRequest}
      key="SettleButton"
      label="Settle as paid"
      onClick={() => setModal(modal.SETTLE)}
    />
  );

  const invoiceButton = !incident.pdfUrl && !!incident.closed && !!incident.customerCode && (
    <Button
      buttonStyleName="filled"
      disabled={hasRequest}
      key="invoiceButton"
      label="Generate invoice"
      onClick={() => setModal(modal.INVOICE)}
    />
  );

  const buttons = (
    <div className={classes.buttonDiv}>
      <div className={classes.buttons}>
        <EditPermission>
          {closeButton}
          {invoiceButton}
          {chargeButton}
          {settleButton}
        </EditPermission>
      </div>
    </div>
  );

  const closeIncidentConfirmationModal = hasModal && (
    <ConfirmationModal
      confirmHandler={currentModal.confirmHandler}
      confirmCustomLabel="Confirm"
      cancelHandler={() => setModal()}
      cancelCustomLabel="Cancel"
      title={currentModal.title + incident.incidentCode}
      text={currentModal.text}
    />
  );

  const incidentsDetails = (
    <div className={classes.root}>
      {closeIncidentConfirmationModal}
      {details}
      {buttons}
    </div>
  );

  return incidentsDetails;
};

const EditPermission = withPermission([{ resource: ["incidents", "edit"] }])(({ children }) => <>{children}</>);

export default withStyles(styles)(IncidentsDetails);
