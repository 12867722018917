import * as screenSize from '../../../../model/ScreenSize/ScreenSize'; 
import * as listItemTypes from '../../../UI/List/ListItem/ListItemTypes';

const promotionFields = currentScreenSize => {
	const width = {
		[screenSize.DESKTOP]: 111,
		[screenSize.TABLET]: 78,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	const maxWidth = {
		[screenSize.DESKTOP]: 180,
		[screenSize.TABLET]: 136,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	const height = {
		[screenSize.DESKTOP]: 35,
		[screenSize.TABLET]: 45,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	return [
		{
			key: 'icons',
			label: 'Actions',
			order: 99,
			pinned: true,
			width,
			height,
			type: listItemTypes.COMPONENT,
			flexDirection: 'row-reverse',
		},
		{
			key: 'name',
			label: 'Name',
			order: 1,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'amount',
			label: 'Amount',
			order: 2,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'expirationDate',
			label: 'Expires',
			order: 3,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'numberOfCodes',
			label: 'Total codes',
			order: 5,
			pinned: false,
			width,
			maxWidth: width,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'codesUnused',
			label: 'Available codes',
			order: 5,
			pinned: false,
			width,
			maxWidth: width,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'createUser',
			label: 'Created by:',
			order: 5,
			pinned: false,
			width: maxWidth,
			maxWidth: maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'createDate',
			label: 'Create Date',
			order: 5,
			pinned: false,
			width,
			maxWidth: width,
			height,
			type: listItemTypes.TEXT,
		},
	];
};

export default promotionFields;
