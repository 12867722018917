import React from "react";
import Driver from "../Driver/Driver";
import TripTimesAndDistance from "./TripTimesAndDistance";
import LocationsWithWaypoint from "./LocationsWithWaypoint";
import OtherDetails from "./OtherDetails";
import Alert from "../../../ErrorDiv/Alert";
// import withPermission from '../../../../hoc/withPermission';
import { Typography, withStyles } from "@material-ui/core";
import { styles } from "./TripInfo.styles";
import classnames from "classnames";
import ReservationActions from "../../../../features/reservation/ReservationDetails/ReservationActions/ReservationActions";

const TripInfo = ({
  classes,
  className,
  clearErrorMessage,
  errorMessage,
  removeTrip,
  setError,
  showAlert,
  trip,
  tripInfo,
  tripType,
  updateTrip,
  setModalUpdate,
}) => {
  const {
    activationDate,
    createDate,
    distance,
    distanceTravelled,
    driver,
    endAddress,
    endDate,
    endMileage,
    extendedBy,
    inUseTime,
    latitude,
    longitude,
    numberExtensions,
    parkName,
    realEnd,
    reservationCode,
    reservationState,
    standByTime,
    startAddress,
    startDate,
    startMileage,
    parkStart,
    parkEnd,
    wantedStartDate,
    wantedEndDate,
  } = tripInfo || {};

  const {
    state,
    advanced,
    wantedEndDate: wantedEndDateTrip,
    wantedStartDate: wantedStartDateTrip,
    parkName: parkNameTrip,
  } = trip || {};

  const { groupId } = trip || {};

  const tripTimesAndDistanceBlock = (
    <TripTimesAndDistance
      data={{
        inUseTime,
        standByTime,
        distance,
      }}
    />
  );

  const waypointAndLocationsBlock = (
    <LocationsWithWaypoint
      advanced={advanced}
      data={{
        distanceTravelled: distance || distanceTravelled,
        endAddress,
        endMileage,
        latitude,
        longitude,
        parkName: parkName || parkNameTrip,
        reservationState,
        startAddress,
        startMileage,
      }}
    />
  );

  const otherDetailsBlock = (
    <OtherDetails
      advanced={advanced}
      data={{
        activationDate,
        createDate,
        endDate,
        extendedBy,
        numberExtensions,
        parkEnd,
        parkStart,
        realEnd,
        reservationCode,
        startDate,
        state,
        wantedEndDate: wantedEndDate || wantedEndDateTrip,
        wantedStartDate: wantedStartDate || wantedStartDateTrip,
      }}
    />
  );

  const driverDetailsBlock = <Driver driver={{ ...driver, groupId }} />;

  const errorAlert = showAlert && (
    <Alert type="error" errorMessage={errorMessage} clearErrorMessage={clearErrorMessage} />
  );

  const batteryData = (tripInfo?.batteryData || []).filter((item) => item !== null);
  // console.log("tripInfo: %o, batteryData: %o", tripInfo, batteryData);

  return (
    <div className={classnames(classes.root, className)}>
      {errorAlert}
      {tripTimesAndDistanceBlock}
      {waypointAndLocationsBlock}
      {otherDetailsBlock}

      {driverDetailsBlock}
      {batteryData && batteryData.length > 0 && (
        <>
          <Typography className={classes.title}>Battery Serial Numbers</Typography>
          {batteryData.map((item, index) => (
            <Typography className={classes.batterySerial} key={`batteryData_${index}`}>
              {item}
            </Typography>
          ))}
        </>
      )}
      <ReservationActions
        onUpdateClick={() => setModalUpdate({ ...trip, ...tripInfo })}
        actions={{ update: updateTrip, remove: removeTrip, setError }}
      />
    </div>
  );
};

export default withStyles(styles)(TripInfo);
