export const styles = (theme) => ({

	root: {
		width: '100%',
		height: '100%',
		margin: 0,
		padding: 0,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		backgroundColor: 'inherit',
	},

	flexHorizontal: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
	},

	buttonsContainer: {
		width: '100%',
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		backgroundColor: '#FFFFFF',
	},

	timelineLogsList: {
		backgroundColor: '#F3F3F3',
	},

	timelineLogItem: {

	},

	moreInfo: {
		alignSelf: 'center',
		marginTop: '20px',
	},
});
