import React, { useEffect } from "react";
import CustomerEditCreate from "./CustomerEditCreate";
import useSimpleForm from "../../../hooks/useSimpleForm/useSimpleForm";

import {
  customerUpdate,
  selectCustomerUpdated,
  clearCustomerUpdated,
  selectIsLoading,
} from "../../../features/customer/customerSlice";
import { useDispatch, useSelector } from "react-redux";

const CustomerEditCreateWrapper = ({ customer, onClose, updateCustomer }) => {
  const dispatch = useDispatch();
  const customerUpdated = useSelector(selectCustomerUpdated);
  const loading = useSelector(selectIsLoading);
  const placeholder = useSimpleForm((!!customer && { ...customer }) || {});
  // const [isButtonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    if (customerUpdated) {
      // console.log("UPDATED.................. customerUpdated: %o", customerUpdated);
      updateCustomer(customerUpdated);
      dispatch(clearCustomerUpdated());
      // setButtonDisabled(false);
      onClose();
    }
    // eslint-disable-next-line
  }, [customerUpdated]);

  const onSave = async () => {
    // setButtonDisabled(true);
    // console.log("placeholder: %o", { ...placeholder.state });
    dispatch(customerUpdate({ ...placeholder.state }));
    // await updateCustomer({...placeholder.state});
    // setButtonDisabled(false);
    // onClose();
  };

  return (
    <CustomerEditCreate
      customer={placeholder.state || {}}
      customerName={!!customer.code && customer.fullName}
      onChange={placeholder.setField}
      isButtonDisabled={loading}
      onCancel={onClose}
      onSave={onSave}
      onReset={placeholder.reset}
    />
  );
};

export default CustomerEditCreateWrapper;
