const styles = () =>({	

	root: {
		zIndex: 0,
	},

	hover: {
		cursor: 'pointer',
	},

	centered: {
		position: 'absolute',
		transform: 'translate(-50%, -50%)',
	},

	selected: {
		zIndex: 1,
	/* 	transform: 'scale(1.5)',
		transformOrigin: 'bottom', */
	},

	badge: {
		top: '50%',
		right: '50%',
		backgroundColor: '#4095C2',
		color: 'white',
		fontSize: 14,
	},

});

export default styles;