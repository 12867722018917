import { wsCall, requestHelper, setSuccess, setError } from "../../../app/coreSlice";

const assetAssociateActivateDeviceCall = async (
  { setDeviceAssociatedSuccessTrigger },
  dispatch, state,
  { carToken, licensePlate, provider_token, serial_number, externalId }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "ASSET/ASSOCIATE_ACTIVATE_DEVICE");
  try {
    const result = await wsCall({
      type: "ASSET",
      subType: "ASSOCIATE_ACTIVATE_DEVICE",
      locationId: selectedLocation?.id,
      message: {
        carToken,
        licensePlate,
        provider_token,
        serial_number,
        externalId,
        parkIds: [],
        wanted: [],
        unwanted: [],
      },
    });
    if (!result.error) {
      dispatch(setSuccess("Asset device updated"));
      dispatch(setDeviceAssociatedSuccessTrigger(result));
      setTimeout(() => {
        dispatch(setDeviceAssociatedSuccessTrigger(null));
      }, 500);
    } else {
      console.log("ERROR result: %o", result);
      dispatch(setError(result?.description || "Error associating device."));
    }
  } catch (_ex) {
    rh.error("Error associating device.");
  }
  rh.close();
};

export default assetAssociateActivateDeviceCall;
