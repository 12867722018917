import {
	CUSTOMER_CLEAR_ELEMENTS,
	CUSTOMER_SET_DETAILS,
	CUSTOMER_SET_ELEMENTS,
	CUSTOMER_SET_LAST_PAGE,
	CUSTOMER_SET_PAGE,
} from '../../actions/customer/actionTypes';
import * as mutate from './mutators';
import { pipe } from '../utils';
import createReducer from '../../utils/createReducer';
import { Map, OrderedMap } from 'immutable';

const initialState = Map({
	updateTime: null,
	elements: OrderedMap(),
	lastPage: true,
	page: 1,
});

const customerSetElements = (state, action) => {

	const customers = action.elements.map(customer => !customer.customerCode 
		? {...customer, customerCode: customer.code}
		: {...customer});

	return pipe([
		mutate.setElements(customers, 'customerCode'),
	], state);
};

const customerClearElements = (state, action) => {
	return state.update('elements', map => map.clear());
};

const customerSetDetails = (state, action) => {

	if (!action.customer) {
		return state;
	}

	// DIRTY FIX
	// TODO backend should also send the customerCode
	const code = action.customer.customerCode
		|| state.get('elements').findKey(el => {
			return el.get('name') === action.customer.name;
		});

	//if(!(action.customer && action.customer.customerCode)) {
	//	return state;
	//}

	const customerToDetail = {
		...action.customer,
		customerCode: code || action.customer.code, // DIRTY FIX
	};

	const paymentInfo = customerToDetail.paymentInformation;

	if ( paymentInfo && paymentInfo.last_four_digits ){
		customerToDetail.paymentInformation.lastFourDigits = paymentInfo.last_four_digits;
		delete customerToDetail.paymentInformation.last_four_digits;
	};

	return pipe([
		mutate.setElements([customerToDetail], 'customerCode'),
	], state);
};

const customerSetLastPage = (state, action) => {
	return pipe([
		mutate.setLastPage(action.lastPage),
	], state);
};

const customerSetPage = (state, action) => {
	return pipe([
		mutate.setPage(action.page),
	], state);
};

const customerReducer = createReducer(initialState, {
	[CUSTOMER_CLEAR_ELEMENTS]: customerClearElements,
	[CUSTOMER_SET_DETAILS]: customerSetDetails,
	[CUSTOMER_SET_ELEMENTS]: customerSetElements,
	[CUSTOMER_SET_LAST_PAGE]: customerSetLastPage,
	[CUSTOMER_SET_PAGE]: customerSetPage,
});

export default customerReducer;
