import { createSlice } from "@reduxjs/toolkit";
import { guid } from "../../common/utils";
import { operatorGetDefaultLoc } from "../../features/carclub/carclubSlice";
import { alertSubscriptionHandlers } from "./_sliceCalls/alertSubscriptionHandlers";
import { carclubSubscriptionHandlers } from "./_sliceCalls/carclubSubscriptionHandlers";
import { init, disconnect, subscribe, unsubscribe, call } from "./_sliceCalls/websocket";

export const websocketSlice = createSlice({
  name: "websocket",
  initialState: {
    connected: false,
    connecting: false,
    credentials: {},
    connectError: undefined,
    subscriptions: {
      carclub: false,
      alerts: false,
    },
  },
  reducers: {
    setConnected: (state, action) => {
      state.connected = action.payload;
    },
    setConnecting: (state, action) => {
      state.connecting = action.payload;
    },
    setConnectError: (state, action) => {
      state.connectError = action.payload;
    },
    setCredentials: (state, action) => {
      state.credentials = action.payload;
    },
    setSubscription: (state, action) => {
      state.subscriptions = { ...state.subscriptions, ...action.payload };
    },
  },
});

export const { setConnected, setConnecting, setConnectError, setCredentials, setSubscription } = websocketSlice.actions;
export default websocketSlice.reducer;

// SELECTS
export const selectLogger = (state) => state.getIn(["websocket"])?.logger;
export const selectConnected = (state) => state.getIn(["websocket"])?.connected;
export const selectConnecting = (state) => state.getIn(["websocket"])?.connecting;
export const selectCredentials = (state) => state.getIn(["websocket"])?.credentials;
export const selectConnectError = (state) => state.getIn(["websocket"])?.connectError;
export const selectSubscriptions = (state) => state.getIn(["websocket"])?.subscriptions;

// CALLS

export const initWS = (credentials) => async (dispatch, getState) => {
  const host = process.env.REACT_APP_WS_SERVER + "/websocket";
  dispatch(setCredentials({}));
  dispatch(setConnecting(true));
  dispatch(setConnected(false));

  init(host, credentials, {
    onConnect: async () => {
      let user = await call({ wsid: guid(), type: "USER", subType: "DETAILS" });
      if (!user.isOperator) {
        dispatch(setConnectError({ tag: "NO_PERMISSION" }));
        dispatch(logout());
        return;
      }
      dispatch(operatorGetDefaultLoc());
      dispatch(setCredentials(credentials));
      dispatch(setConnected(true));
      dispatch(setConnecting(false));
      if (credentials?.username) {
        localStorage.setItem("account-credentials", JSON.stringify({ ...credentials, host }));
      }
      let subs = getState().getIn(["websocket"])?.subscriptions;
      if (subs.alerts) {
        dispatch(unsubscribeAlerts());
      }
      dispatch(subscribeAlerts(credentials?.carclub));
      if (subs.carclub) {
        dispatch(unsubscribeCarclub());
      }
      dispatch(subscribeCarclub(credentials?.carclub));
    },
    onConnectError: () => {
      dispatch(setConnected(false));
      dispatch(setConnecting(false));
    },
    onDisconnect: () => {
      dispatch(setConnected(false));
      dispatch(setConnecting(false));
    },
    onWebsocketClose: () => {
      let connected = getState().getIn(["websocket"])?.connected;
      if (connected) {
        dispatch(setConnecting(true));
      }
    },
    onFailedLogin: (msg) => {
      dispatch(setConnectError({ tag: msg }));
      dispatch(setConnecting(false));
      localStorage.removeItem("account-credentials");
    },
  });
};

export const logout = () => async (dispatch) => {
  disconnect();
  dispatch(setConnected(false));
  dispatch(setCredentials({}));
  localStorage.removeItem("account-credentials");
};

export const subscribeAlerts = (carclub) => async (dispatch, getState) => {
  subscribe("alerts", "/queue/carclub/alert" + carclub, (message) => {
    try {
      let body = JSON.parse(message.body);
      alertSubscriptionHandlers(body, dispatch, getState());
    } catch (e) {
      console.error(e);
      return;
    }
  });
  dispatch(setSubscription({ alerts: true }));
};

export const unsubscribeAlerts = () => async (dispatch) => {
  unsubscribe("alerts");
  dispatch(setSubscription({ alerts: false }));
};

export const subscribeCarclub = (carclub) => async (dispatch, getState) => {
  // console.log("subscribeCarclub");
  subscribe("carclub", "/queue/carclub/" + carclub, (message) => {
    try {
      let body = JSON.parse(message.body);
      carclubSubscriptionHandlers(body, dispatch, getState());
    } catch (e) {
      console.error(e);
      return;
    }
  });
  dispatch(setSubscription({ carclub: true }));
};

export const unsubscribeCarclub = () => async (dispatch) => {
  unsubscribe("carclub");
  dispatch(setSubscription({ carclub: false }));
};