import React, { useState, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core";
import styles from "./Timer.styles";
import classnames from "classnames";
import useDateTime from "../../../../../../common/hooks/useDateTime";

const TimerWrapper = ({ classes, className }) => {
  const { now } = useDateTime();
  const [nowTime, setNowTime] = useState(now("dateTime"));

  const timerId = useRef();

  useEffect(() => {
    timerId.current = setInterval(() => {
      setNowTime(now("dateTime"));
    }, 1000);

    return () => {
      clearInterval(timerId.current);
    };
    // eslint-disable-next-line
  }, [timerId]);

  return <div className={classnames(classes.root, className)}>{nowTime}</div>;
};

export default withStyles(styles)(TimerWrapper);
