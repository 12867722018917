import { ERROR_MESSAGE, NOTIFICATION_SET } from "../../actions/notification/actionTypes";
import * as mutate from "./mutators";
import { pipe } from "../utils";
import createReducer from "../../utils/createReducer";
import { Map } from "immutable";

const initialState = Map({
  updateTime: null,
  message: null,
  status: null,
});

const setMessage = (state, action) => {
  return pipe([mutate.setMessage(action.message)], state);
};

const setNotification = (state, action) => {
  console.log("setNotification - action: %o", action);
  return pipe([mutate.setMessage(action.message), mutate.setStatus(action.status)], state);
};

const errorReducer = createReducer(initialState, {
  [ERROR_MESSAGE]: setMessage,
  [NOTIFICATION_SET]: setNotification,
});

export default errorReducer;
