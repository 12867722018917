export const styles = theme => ({

	root: {
		padding: theme.spacing(1),
		width: '100%',
		height: '88%',
		boxSizing: 'border-box',
	},

	row: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},

	headerFilters: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		flexBasis: '50%',
	},

	headerButtons: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		flexBasis: '50%',
	},

	configurationsButton: {
		cursor: 'pointer',
		color: '#009AC9',
	},

	buttons: {
		position: 'absolute',
		top: 10,
		right: 0,
		display: 'flex',
		width: '50px',
		alignSelf: 'flex-end',
		justifyContent: 'space-between',
		marginRight: '20px',
	},

	list: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},

});