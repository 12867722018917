import React from 'react';
import Detail from './Details/Detail';
import Application from './Details/Application';
import {
	Divider,
	withStyles,
} from '@material-ui/core';
import styles from './PromoCodeDetails.styles';
import classnames from 'classnames';

const PromoCodeDetails = ({
	classes,
	className,
	selectedItem,
	searchFromBonus,
}) => {

	const detail = <Detail info={selectedItem} searchFromBonus={searchFromBonus} />;

	const application = <Application info={selectedItem} />;

	return (
		<div className={classnames(classes.root, className)}>
			{detail}
			<Divider />
			{application}
		</div>
	);
};

export default withStyles(styles)(PromoCodeDetails);