import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";

import { styles } from "./VehicleList.styles";
import SearchList from "../../../../components/UI/SearchList/SearchListWrapper";
import { crAssetListNotInReservation, crParkGetAssets, selectCrAssets, setCrAssets } from "../../reservationsSlice";
import { useDateTime } from "../../../../common/hooks";

function VehicleList({
  classes,
  assetToken,
  licensePlate,
  assetName,
  parkId,
  startDate,
  endDate,
  disabled,
  onSelected,
}) {
  const { toUtc } = useDateTime();
  const dispatch = useDispatch();
  const vehicles = useSelector(selectCrAssets);

  useEffect(() => {
    dispatch(setCrAssets([]));
    if (parkId) {
      dispatch(
        crParkGetAssets({
          parkId,
          startDate: startDate ? toUtc(startDate).format("YYYY-MM-DD HH:mm:ss") : undefined,
          endDate: endDate ? toUtc(endDate).format("YYYY-MM-DD HH:mm:ss") : undefined,
          filter: "",
        })
      );
    } else {
      dispatch(crAssetListNotInReservation({}));
    }
    // eslint-disable-next-line
  }, [parkId]);

  return (
    <SearchList
      className={classes.root}
      currentFields={{
        key: assetToken,
        label: licensePlate,
        name: assetName,
      }}
      disabled={disabled}
      data={vehicles.map((asset) => ({
        key: asset.assetToken || asset.carToken,
        label: asset.licensePlate,
        name: asset.assetName,
        object: asset,
      }))}
      onSearch={(search) => {
        if (parkId) {
          dispatch(
            crParkGetAssets({
              parkId,
              startDate: startDate ? toUtc(startDate).format("YYYY-MM-DD HH:mm:ss") : undefined,
              endDate: endDate ? toUtc(endDate).format("YYYY-MM-DD HH:mm:ss") : undefined,
              filter: search,
            })
          );
        } else {
          dispatch(crAssetListNotInReservation({ deviceLicensePlate: search }));
        }
      }}
      header={["License plate", "Name"]}
      name="Vehicle"
      onSelect={({ object }) => {
        onSelected && onSelected(object);
      }}
      searchLabel="Search Vehicle"
    />
  );
}

export default withStyles(styles)(VehicleList);
