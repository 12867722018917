import React from 'react';
import {
	Tooltip,
	withStyles,
} from '@material-ui/core';
import {
	AddCircle as CreateIcon,
} from '@material-ui/icons';
import styles from './NotificationButtons.styles';
import classnames from 'classnames';

const NotificationButtons = ({
	classes,
	className,
	onCreate,
}) => {

	const createButton = (
		<Tooltip
			title='Create'
			placement='left'
		>
			<CreateIcon
				onClick={onCreate}
				className={classnames(classes.icon)}
			/>
		</Tooltip>
	);


	return (
		<div className={classnames(classes.root, className)}>
			{createButton}
		</div>
	);
};

export default withStyles(styles)(NotificationButtons);