import React from 'react';
import PricingPlanListScreen from '../../components/PricingPlan/PricingPlanListScreen/PricingPlanListScreenWrapper';
import PricingPlanDetailsView from '../../components/PricingPlan/PricingPlanDetailsView/PricingPlanDetailsViewWrapper';
import {
	withStyles,
} from '@material-ui/core';
import styles from './PricingPlanApp.styles';

const PricingPlanApp = ({
	classes,
	currentScreenSize,
	editMode,
	placeholder,
	placeholderSetters,
	pricingPlans,
	removePricingPlan,
	selectedPricingPlan,
	setSelectedPricingPlan,
	updatePricingPlan,
	getZones,
	zones,
}) => {

	const pricingPlanList = (
		<PricingPlanListScreen
			currentScreenSize={currentScreenSize}
			editMode={editMode}
			placeholder={placeholder}
			placeholderSetters={placeholderSetters}
			pricingPlanArray={!!pricingPlans && Object.values(pricingPlans)}
			removePricingPlan={removePricingPlan}
			selectedPricingPlan={selectedPricingPlan}
			setSelectedPricingPlan={setSelectedPricingPlan}
			updatePricingPlan={updatePricingPlan}
			getZones={getZones}
			zones={zones}
		/>
	);


	const pricingPlanDetailList = !!pricingPlans && (
		<PricingPlanDetailsView
			currentScreenSize={currentScreenSize}
			closeHandler={() => setSelectedPricingPlan(null)}
			editMode={editMode}
			selectedPricingPlan={selectedPricingPlan && pricingPlans[selectedPricingPlan]}
			placeholder={placeholder}
			placeholderSetters={placeholderSetters}
			updatePricingPlan={updatePricingPlan}
		/>
	);

	const currentScreen = !!selectedPricingPlan ? pricingPlanDetailList : pricingPlanList;

	const pricingPlanApp = (
		<div className={classes.root}>
			{currentScreen}
		</div>
	);

	return pricingPlanApp;
};

export default withStyles(styles)(PricingPlanApp);