
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_REQUEST_IS_MALFORMED,
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import { dateFormatter } from '../../formatter/index';
import { publishAndAwait } from '../../../app/coreSlice';

const getTripAnalysis = async ({type, startDate, endDate}, deviceToken)=> {

	// if(true){
	// 	let foo = [...Array(40).keys()].map((x, index) => ({date: '02/02/2020 20:20', event: `fake${index}`}));
	// 	return foo;
	// };

	if ('undefined' === typeof type) {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	};

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'BIGDATA',
			subType: 'LIST_COMMS',
			message: {
				deviceToken,
				commType: type,
				limit: 500,
				end: dateFormatter.toJSON(endDate, 'dateTimeRequest') || undefined,
				start: dateFormatter.toJSON(startDate, 'dateTimeRequest') || undefined,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}

	return processMessage;
};

export default getTripAnalysis;
