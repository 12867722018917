import React from 'react';
import DetailsDiv from '../../../UI/DetailsDiv/DetailsDiv';
import DetailsRow from '../../../UI/DetailsDiv/DetailsRow/DetailsRow';
import { dateFormatter } from '../../../../services/formatter';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import styles from './BannerDetails.styles';

const dateToDetail = date => date && dateFormatter.format(date, 'dateTime');

//Why is this an array you might ask? To assure order is maintained
const labels = [
	{ key: 'name', label: 'Designation:' },
	{ key: 'description', label: 'Description:' },
	{ key: 'startDate', label: 'Start date:', func: dateToDetail },
	{ key: 'endDate', label: 'End date:', func: dateToDetail },
	{ key: 'createDate', label: 'Created:', func: dateToDetail },
	{ key: 'createUser', label: 'Created by:' },
	{ key: 'updateDate', label: 'Last Modified:', func: dateToDetail },
	{ key: 'updateUser', label: 'Modified by:' },
];

const BannerDetails = ({
	classes,
	banner,
}) => {
	const details = !!banner && labels.map(detail => {
		const detailText = !!detail.func
			? detail.func(banner?.[detail.key])
			: banner?.[detail.key];

		const detailInfo = (
			<Typography className={classes.detail}>
				{detailText}
			</Typography>
		);

		return (
			<DetailsRow
				key={detail.label}
				label={detail.label}
				detail={!!banner?.[detail.key] && detailInfo}
			/>
		);
	});

	const images = banner?.items &&
		banner.items.map(image => (
			<div className={classes.imageDiv} key={image.id}>
				<Typography className={classes.imageName}>
					{image?.name}
				</Typography>
				<img
					alt={image?.name || 'banner'}
					className={classes.image}
					onClick={() => window.open(image?.url, '_blank')}
					src={image?.url}
				/>
			</div>
		));

	return (
		<div className={classes.root}>
			<DetailsDiv title='Banner Details'>
				{details}
				<div className={classes.images}>
					<Typography className={classes.imageText}>
						Images:
					</Typography>
					{images}
				</div>
			</DetailsDiv>
		</div>
	);
};

export default withStyles(styles)(BannerDetails);