import WebSocketService from "./WebSocketService";

let ws;
let subscriptions = {
  user: null,
  alerts: null,
  carclub: null,
};
let requests = {};

export const init = (
  host,
  credentials,
  { onConnect, onConnectError, onDisconnect, onWebsocketClose, onFailedLogin }
) => {
  if (!ws) {
    ws = new WebSocketService();
  }

  const onConnectHandler = (sessionId) => {
    subscriptions.user = ws.subscribe("/secured/user/queue/specific-user-user" + sessionId, (msg) =>
      processMessageHandler(msg)
    );
    onConnect && onConnect();
  };
  ws.init(host, credentials, {
    onConnect: onConnectHandler,
    onConnectError,
    onDisconnect,
    onWebsocketClose,
    onFailedLogin,
  });

  // reset subscriptions and requests
  resetSubscriptions();
  requests = {};
};

export const disconnect = () => {
  ws.disconnect();
  resetSubscriptions();
};

const resetSubscriptions = () => {
  subscriptions.user && subscriptions.user.unsubscribe();
  subscriptions.alerts && subscriptions.alerts.unsubscribe();
  subscriptions.carclub && subscriptions.carclub.unsubscribe();
  subscriptions.user = null;
  subscriptions.alerts = null;
  subscriptions.carclub = null;
};

export const call = ({ wsid, type, subType, message, locationId }) => {
  let body = {
    wsid,
    type,
    subType,
    message,
    locationId,
  };

  ws.publish({
    destination: "/app/hello",
    // body: JSON.stringify(body),
    body: JSON.stringify({ name: JSON.stringify(body) }),
  });
  return new Promise((resolve, reject) => {
    requests[wsid] = {
      resolve,
      reject,
      timeout: setTimeout(() => reject({ tag: "TIMEOUT", flow: type + "/" + subType }), 30000),
    };
  });
};

const processMessageHandler = (message) => {
  let body;
  try {
    body = JSON.parse(message.body);
    let { wsid, reporting, processMessage, error } = body;

    let req = requests[wsid];
    if (req && !reporting) {
      clearTimeout(req.timeout);
      if (error) {
        processMessage.tag = processMessage.tag || "INTERNAL_SERVER_ERROR";
        req.reject(processMessage);
      } else {
        req.resolve(processMessage);
      }
    }
  } catch (e) {
    console.error(e);
    return;
  }
};

export const subscribe = (key, destination, callback) => {
  try {
    subscriptions[key] = ws.subscribe(destination, callback);
  } catch (ex) {
    console.error(ex);
  }
};

export const unsubscribe = (key) => {
  subscriptions[key] && subscriptions[key].unsubscribe();
};
