export const styles = (theme) => ({
  root: {
    margin: theme.spacing(2),
    marginRight: 0,
    padding: "12px 10px",
  },
  drop: {
    top: 50,
    bottom: 0,
    width: 330,
    position: "fixed",
    right: 0,
    zIndex: 101,
    backgroundColor: "#F8F8F8",
    boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.12)",
  },
});
