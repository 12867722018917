import React from "react";
import { Typography, withStyles } from "@material-ui/core";
import NotificationEmail from "./NotificationsEmail/NotificationsEmailWrapper";
import NotificationSMS from "./NotificationsSMS/NotificationsSMSWrapper";
import { styles } from "./Notifications.styles";
import classnames from "classnames";

const Notifications = ({
  classes,
  className,
  screens,
  currentScreen,
  setCurrentScreen,
  editMode, // maybe remove
  setEditMode, // maybe remove
  currentScreenSize,
  getNotifications,
  removeNotification,
  updateNotification,
  notifications,
  defaultLocation,
}) => {
  const contentMap = {
    [screens.EMAIL]: (
      <NotificationEmail
        currentScreenSize={currentScreenSize}
        getNotifications={getNotifications}
        removeNotification={removeNotification}
        updateNotification={updateNotification}
        notifications={notifications}
      />
    ),
    [screens.SMS]: <NotificationSMS currentScreenSize={currentScreenSize} />,
  };

  const content = contentMap[currentScreen];
  return (
    <div className={classnames(classes.root)}>
      <div className={classes.titleBar}>
        <Typography className={classes.title}>{defaultLocation?.name} Notification Center</Typography>
      </div>
      <div className={classes.tabs}>
        {Object.values(screens).map((screen) => {
          return (
            <Typography
              className={classnames(classes.tab, screen === currentScreen && classes.selectedTab)}
              key={screen}
              onClick={() => setCurrentScreen(screen)}
            >
              {screen}
            </Typography>
          );
        })}
      </div>
      {content}
    </div>
  );
};

export default withStyles(styles)(Notifications);
