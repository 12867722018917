import React, { useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Tooltip, Switch, Typography, Grid } from "@material-ui/core";

import { styles } from "./ScooterStartStop.styles";
import SimpleConfirmationModal from "../../../../../../common/displays/Modal/SimpleConfirmationModal";

function ScooterStartStop({ classes, disabled, tooltip, checked, onChange }) {
  const [confirmImo, setConfirmImo] = useState(false);
  return (
    <Tooltip title={tooltip || ""}>
      <Grid container className={classes.root} direction="row" alignItems="center">
        <Grid item>
          <Typography>Stop / Start:</Typography>
        </Grid>
        <Grid item>
          <Switch disableRipple disabled={disabled} checked={checked} onChange={() => setConfirmImo(true)} />
        </Grid>
        <SimpleConfirmationModal
          open={confirmImo}
          setOpen={setConfirmImo}
          message={`Attention, please confirm that the vehicle is parking before sending a Start/Stop command`}
          onConfirm={() => {
            onChange && onChange();
            setConfirmImo(false);
          }}
        />
      </Grid>
    </Tooltip>
  );
}

export default withStyles(styles)(ScooterStartStop);
