export const CARCLUB_CLEAR_FLOWS = 'CARCLUB_CLEAR_FLOWS';
export const CARCLUB_CLEAR_NOTIFICATIONS = 'CARCLUB_CLEAR_NOTIFICATIONS';
export const CARCLUB_REMOVE_DEFAULT_LOCATION = 'CARCLUB_REMOVE_DEFAULT_LOCATION';
export const CARCLUB_REMOVE_NOTIFICATION = 'CARCLUB_REMOVE_NOTIFICATION';
export const CARCLUB_SET_APP = 'CARCLUB_SET_APP';
export const CARCLUB_SET_CARCLUBS = 'CARCLUB_SET_CARCLUBS';
export const CARCLUB_SET_DEFAULT_LOCATION = 'CARCLUB_SET_DEFAULT_LOCATION';
export const CARCLUB_SET_FEATURES = 'CARCLUB_SET_FEATURES';
export const CARCLUB_SET_FLOWS = 'CARCLUB_SET_FLOWS';
export const CARCLUB_SET_LOCALE = 'CARCLUB_SET_LOCALE';
export const CARCLUB_SET_LOCATIONS = 'CARCLUB_SET_LOCATIONS';
export const CARCLUB_SET_NOTIFICATIONS = 'CARCLUB_SET_NOTIFICATIONS';
export const CARCLUB_SET_PATTERNS = 'CARCLUB_SET_PATTERNS';
export const CARCLUB_SET_THEME = 'CARCLUB_SET_THEME';
export const CARCLUB_SET_TIMEZONE = 'CARCLUB_SET_TIMEZONE';