export const styles = () => ({
  root: {
    minWidth: 160,
  },
  header: {
    height: 38,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "2px solid #009ac9",
    alignItems: "center",
    marginBottom: 10,
  },
  title: {
    fontSize: 16,
    color: "#a1a1a1",
    textAlign: "left",
  },
  label: {
    display: "block",
    fontWeight: "bold",
    fontSize: 10,
    color: "#a1a1a1",
  },
  field: {
    display: "block",
    fontSize: 12,
    color: "#a1a1a1",
  },
});
