export const styles = () => ({
  root: {
    "& .CdgRow": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#ddd",
      },
    },
  },
  datagrid: {
    height: "calc(100vh - 137px)",
  },
  id: {
    margin: 0,
  },
  orderBy: {
    margin: 0,
    color: "#aaa",
  },
  checkIcon: {
    color: "#51B383",
  },
  icon: {
    color: "#009AC9",
  },
  iconDisabled: {
    color: "#C5C5C5",
  },
});
