import {
	INCIDENTS_SET_ELEMENTS,
	INCIDENTS_SET_DETAILS,
	INCIDENTS_CLEAR_ELEMENTS,
} from './actionTypes';
import makeActionCreator from '../../../utils/makeActionCreator';
import * as methods from '../../../utils/makeActionCreator';
import incidentsService from '../../../services/incidents';
import log from '../../../utils/logger';

const logger = log('IncidentAction: ');

export const incidentsSetElements = makeActionCreator(INCIDENTS_SET_ELEMENTS, 'elements', 'lastPage', 'page');
export const incidentsSetDetails = makeActionCreator(INCIDENTS_SET_DETAILS, 'details');
export const incidentsClearElements = () => ({ type: INCIDENTS_CLEAR_ELEMENTS, method: methods.REPLACE });

export const getIncidents = (filters, clear) => {

	return async dispatch => {

		!!clear && dispatch(incidentsClearElements());

		try{
		
			const incidents = await incidentsService.getIncidents(filters);

			dispatch(incidentsSetElements(incidents.incidents, incidents.lastPage, filters.page));
		}
		catch(error){

			logger.info(error.message);
		};
	};
};

export const closeIncident = (incident, setRequest) => {
	return async dispatch => {
		try{
			setRequest(true);
		
			const closedIncident = await incidentsService.closeIncident(incident.incidentCode);

			dispatch(incidentsSetElements([closedIncident]));
		}
		catch(error){
			logger.info(error.message);
		}
		finally{
			setRequest(false);
		};
	};
};

export const chargeIncident = (incident, setRequest) => {
	return async dispatch => {
		try{
			setRequest(true);

			const chargeIncident = await incidentsService.chargeIncident(incident.incidentCode);

			dispatch(incidentsSetElements([{...incident, paid: chargeIncident.paid}]));
		}
		catch(error){
			logger.info(error.message);
		}
		finally{
			setRequest(false);
		};
	};
};

export const settleIncident = (incident, setRequest) => {
	return async dispatch => {
		try{
			setRequest(true);
		
			const settleIncident = await incidentsService.settleIncident(incident.incidentCode);

			dispatch(incidentsSetElements([{incidentCode: incident.incidentCode , paid: settleIncident.paid}]));
		}
		catch(error){
			logger.info(error.message);
		}
		finally{
			setRequest(false);
		};
	};
};

export const invoiceIncident = (incident, setRequest) => {
	return async dispatch => {
		try{
			setRequest(true);
		
			const updatedIncident = await incidentsService.invoiceIncident(incident);

			dispatch(incidentsSetElements([{...incident, pdfUrl: updatedIncident.pdfUrl}]));

		}
		catch(error){
			logger.info(error.message);
		}
		finally{
			setRequest(false);
		};
	};
};

export const updateIncident = data => {
	return async dispatch => {
		try{
			const incident = await incidentsService.updateIncident(data);

			dispatch(incidentsSetElements([incident]));

			return incident;
		}
		catch(error){
			return {error: true, description: error.message};
		};
	};
};
