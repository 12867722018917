import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Badge, IconButton, ClickAwayListener } from "@material-ui/core";
import { Notifications } from "@material-ui/icons";

import { styles } from "./AlertsMain.styles";

import { selectAlerts, setSelectedAlert, selectAlertsTabOpen, setAlertsTabOpen } from "../alertsSlice";
import AlertsList from "./AlertsList/AlertsList";
import { setSelectedAssetToken, setShowList } from "../../asset/assetSlice";
import { setSelectedCustomerByCode } from "../../customer/customerSlice";

function AlertsMain({ classes, selectApp }) {
  const dispatch = useDispatch();
  const alerts = useSelector(selectAlerts);
  const open = useSelector(selectAlertsTabOpen);

  const onClickHandler = () => {
    dispatch(setAlertsTabOpen(!open));
  };

  useEffect(() => {
    if (!open) {
      dispatch(setSelectedAlert());
    }
    // eslint-disable-next-line
  }, [open]);

  const onSelectAssetHandler = (assetToken) => {
    console.log("onSelectAssetHandler---- assetToken: %o", assetToken);
    selectApp("fleet");
    // dispatch(assetActions.assetSetShowList(true));

    dispatch(setShowList(true));
    dispatch(setSelectedAssetToken(assetToken));
  };

  const onSelectReservationHandler = (reservationCode) => {
    selectApp("reservations", {
      noAutoSearch: true,
      reservationCode,
    });
  };

  const onSelectCustomerHandler = (customerCode) => {
    selectApp("customers");
    setTimeout(() => {
      dispatch(setSelectedCustomerByCode(customerCode));
    }, 300);
  };

  return (
    <div>
      <ClickAwayListener onClickAway={() => dispatch(setAlertsTabOpen(false))}>
        <div className={classes.root}>
          <IconButton color="inherit" aria-label="Show/hide alerts" onClick={onClickHandler}>
            <Badge overlap="rectangular" badgeContent={alerts?.totalPending} color="error" showZero={false} max={9999}>
              <Notifications />
            </Badge>
          </IconButton>
          {open ? (
            <div className={classes.drop}>
              <AlertsList
                alerts={alerts}
                onSelectAsset={onSelectAssetHandler}
                onSelectReservation={onSelectReservationHandler}
                onSelectCustomer={onSelectCustomerHandler}
              />
            </div>
          ) : null}
        </div>
      </ClickAwayListener>
    </div>
  );
}

export default withStyles(styles)(AlertsMain);
