
export const styles = theme => ({

	root: {
		height: '100%',
		background: '#FFFFFF',
		borderRadius: '2px',
		opacity: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},

	headerDiv: {
		width: '100%',
		height: 'auto',
		display: 'flex',
		flexDirection: 'column',
		padding: 8,
	},

	content: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},

	flexVertically: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		padding: 0,
	},

	flexVerticallyLeft: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		padding: '6px 15px',
		alignItems: 'flex-start',
	},

	flexVerticallyRight: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		padding: '6px 15px',
		alignItems: 'flex-start',
	},

	flexHorizontally: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: 0,
		alignItems: 'center',
	},

	flexStart: {
		justifyContent: 'flex-start',
	},

	accountNameField: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		flexBasis: '50%',
		paddingLeft: 15,
	},

	daysToInvoiceField: {
		paddingLeft: 33,
	},

	labelAndInput: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		padding: 2,
		alignItems: 'initial',
		height: 40,
	},

	labelAndInputCheckbox: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		padding: 2,
		alignItems: 'end',
		paddingBottom: 0,
		boxSizing: 'border-box',
		height: 40,
	},

	taxNumberRoot: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		padding: '2px 2px 2px 6px',
		alignItems: 'center',
		height: 40,
	},

	zipCodeRoot: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		padding: '0px 0px 0px 2px',
		alignItems: 'initial',
		height: 40,
	},

	list: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		height: '30%',
		overflowY: 'auto',
		paddingLeft: 21,
		paddingRight: 21,
		width: '100%',
		maxHeight: '180px',
	},

	subtitle15px: {
		fontSize: '15px',
		paddingBottom: 4,
	},

	subtitle14px: {
		fontSize: '14px',
		paddingBottom: 4,
	},

	subtitle12px: {
		fontSize: '12px',
		paddingBottom: 4,
	},

	locationList: {
		margin: 0,
		height: 175,
		maxHeight: 175,
	},

	locationHeader: {
		flex: 0,
	},

	locationFilterRoot: {
		margin: 0,
		padding: 0,
		fontSize: 11,
	},

	errorText: {
		fontSize: '9px',
	},

	item: {
		cursor: 'pointer',
	},

	icon: {
		cursor: 'pointer',
		marginBottom: theme.spacing(1),
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(3),
	},

	inputRoot: {
		color: 'inherit',
		width: '100%',
	},

	inputInput: {
		borderBottom: '1px solid gray',
		marginBottom: theme.spacing(1),
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(2),
		transition: theme.transitions.create('width'),
		width: '100%',
	},

	equalMargin: {
		margin: '0px 0px 8px 0px',
	},

	inputIcon: {
		padding: '2px',
	},

	title: {
		fontSize: '16px',
		paddingBottom: 4,
	},
	subtitle: {
		fontSize: '14px',
		height: 30,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
	},

	divison: {
		borderTop: '2px solid rgba(192, 192, 192, 0.75)',
		marginTop: 6,
		paddingTop: 4,
	},

	greyTextField: {
		backgroundColor: '#F0F0F0',
	},

	labelProps: {
		color: '#47525E',
		zIndex: 999,
	},

	inputProps: {
		fontSize: 12,
		padding: 0,
	},

	label: {
		minWidth: '100px',
		width: '30%',
		fontSize: '12px',
		height: 35,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
	},

	field: {
		width: '100%',
		fontSize: '11px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
	},

	checkboxLabel: {
		fontSize: '12px',
		height: 35,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'end',
		marginLeft: 12,
	},
	checkbox: {
		padding: '0px',
		height: 25,
		alignItems: 'end',
	},
	tinyLabel: {
		width: 70,
		minWidth: 70,
	},
	smallLabel: {
		width: 80,
		minWidth: 80,
	},

	smallest: {
		width: 80,
	},

	small: {
		width: 120,
	},

	medium: {
		width: 140,
	},

	mediumNoPercent: {
		width: 140,
	},

	big: {
		width: 180,
	},

	invoiceDaysRoot: {
		// height: 0,
	},

	groupListTitle: {
		fontSize: '20px',
		height: 60,
	},

	groupListSearch: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		height: '52px',
		width: '100%',
		paddingBottom: 10,
		color: 'black',
	},

	groupsHeader: {
		width: 'calc(100% - 1px)',
	},
	
	groupFilterRoot: {
		margin: 0,
		padding: 0,
		maxWidth: 330,
	},

	zipCodeInput: {
		width: 110,
	},

});

export default styles;