import React from 'react';
import Buttons from './UpsertButtons/UpsertButtons';
import ErrorDiv from '../../ErrorDiv/ErrorDiv';
import {
	Checkbox,
	TextField,
	Typography,
	withStyles,
} from '@material-ui/core';
import SearchList from '../../UI/SearchList/SearchListWrapper';
import styles from './UpsertAccount.styles';
import classnames from 'classnames';

const UpsertAccount = ({
	classes,
	className,
	upsertAccountHandler,
	exitUpsert,
	error,
	isOnCreateMode,
	editMode = true,
	placeholder,
	onChangeHandler,
	getLocations,
	getCustomers,
	defaultLocationInStore,
}) => {

	const titleText = isOnCreateMode
		? 'Create an Account'
		: 'Update an Account';

	const title = (
		<Typography className={classes.title} align='center' >
			{titleText}
		</Typography >);

	const errorBar = error && (
		<ErrorDiv
			className={classes.errorText}
			message={error}
		/>
	);


	const nameField = getLabelAndInput({
		editMode, fieldLabel: 'Account Name', fieldString: 'name', onChangeHandler: onChangeHandler,
		placeholder, classes, endAdornment: false, initialValue: '', inputSize: 'big', labelSize: 'small',
		required: true,
	});

	const emailField = getLabelAndInput({
		editMode, fieldLabel: 'Email', fieldString: 'email', onChangeHandler,
		placeholder, classes, endAdornment: false, initialValue: '', inputSize: '', labelSize: 'tinyLabel',
		required: true,
	});
	const phoneField = getLabelAndInput({
		editMode, fieldLabel: 'Mobile', fieldString: 'phone', onChangeHandler,
		placeholder, classes, endAdornment: false, initialValue: '', inputSize: '', labelSize: 'tinyLabel',
		required: true,
	});

	const cityField = getLabelAndInput({
		editMode, fieldLabel: 'City', fieldString: 'city', onChangeHandler,
		placeholder, classes, endAdornment: false, initialValue: '', inputSize: '', labelSize: 'tinyLabel',
		required: true,
	});
	const streetField = getLabelAndInput({
		editMode, fieldLabel: 'Street', fieldString: 'street', onChangeHandler,
		placeholder, classes, endAdornment: false, initialValue: '', inputSize: '', labelSize: 'tinyLabel',
		required: true,
	});

	const doorNumberField = getLabelAndInput({
		editMode, fieldLabel: 'Door No.', fieldString: 'doorNumber', onChangeHandler,
		placeholder, classes, endAdornment: false, initialValue: '', inputSize: 'small', labelSize: 'tinyLabel',
		required: true,
	});
	const zipCodeField = getLabelAndInput({
		editMode, fieldLabel: 'Zip Code', fieldString: 'zipCode', onChangeHandler,
		placeholder, classes, endAdornment: false, initialValue: '', inputSize: 'zipCodeInput', labelSize: 'tinyLabel', rootSize: 'zipCodeRoot',
		required: true,
	});

	const taxNumberField = getLabelAndInput({
		editMode, fieldLabel: 'Tax ID', fieldString: 'taxNumber', onChangeHandler,
		placeholder, classes, endAdornment: false, initialValue: '', inputSize: '', labelSize: 'smallLabel',
		required: true,
	});
	const dueDateField = getLabelAndInput({
		editMode, fieldLabel: 'Due Days', fieldString: 'dueDays', onChangeHandler,
		placeholder, classes, endAdornment: false, initialValue: '', inputSize: '', labelSize: 'smallLabel',
		required: true,
	});

	const autoChargeField = getCheckboxAndLabel({
		editMode, fieldLabel: 'Enable Auto Charge', fieldString: 'autoCharge',
		onChangeHandler, placeholder, classes,
	});

	const autoInvoiceField = getCheckboxAndLabel({
		editMode, fieldLabel: 'Enable Auto Invoice', fieldString: 'autoInvoice',
		onChangeHandler, placeholder, classes,
	});

	const invoiceDayField = placeholder?.autoInvoice
		&& getLabelAndInput({
			editMode, fieldLabel: 'Day of the month', fieldString: 'invoiceDay', onChangeHandler, placeholder, classes,
			endAdornment: false, initialValue: '', inputSize: 'smallest', labelSize: 'medium', rootSize: 'invoiceDaysRoot',
			required: true,
		});

	const ownerList = (
		<SearchList
			className={classes.locationList}
			style={{
				classNameTitle: classes.subtitle12px,
				classNameHeader: classes.locationHeader,
				classNameFiltersRoot: classes.locationFilterRoot,
				classNameSearch: classes.groupListSearch,
			}}
			currentFields={{ key: placeholder?.ownerCode, label: placeholder?.ownerName, code: placeholder?.ownerCode }}
			disabled={!editMode}
			getList={getCustomers}
			header={['Name', 'Code']}
			name='Account Owner'
			onAdd={(ownerCode, ownerName, code, email, phone) => {
				onChangeHandler('ownerCode', ownerCode);
				onChangeHandler('ownerName', ownerName);
				!placeholder?.phone && onChangeHandler('phone', phone);
				!placeholder?.email && onChangeHandler('email', email);
			}}
			onLoad
			onRemove={() => {
				onChangeHandler('ownerCode', null);
				onChangeHandler('ownerName', null);
			}}
			searchLabel='Search Owner'
		/>
	);

	const locationList = getLabelAndInput({
		editMode: false, fieldLabel: 'Location', fieldString: 'location', onChangeHandler,
		placeholder, classes, endAdornment: false, initialValue: defaultLocationInStore?.name, inputSize: '', labelSize: 'smallLabel',
		required: true,
	});

	const buttons = (
		<Buttons
			exitUpsert={exitUpsert}
			isOnCreateMode={isOnCreateMode}
		/>
	);

	const headerDiv = (
		<div className={classes.headerDiv}>
			{title}
			{errorBar}
		</div>
	);

	const adressFields = (
		<>
			{streetField}
			<div className={classes.flexHorizontally}>
				{doorNumberField}
				{zipCodeField}
			</div>
			{cityField}
		</>
	);


	const formDiv = (
		<div className={classes.flexVertically}>
			<div className={classes.flexHorizontally}>
				<div className={classes.flexVerticallyLeft}>
					{nameField}
					{ownerList}
					{emailField}
					{phoneField}
				</div>

				<div className={classes.flexVerticallyRight}>
					{adressFields}
					{locationList}
					{taxNumberField}
					{dueDateField}
					{autoChargeField}
					{autoInvoiceField}
					<div className={classes.daysToInvoiceField}>
						{invoiceDayField}
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<div className={classnames(classes.root, className)}>
			<form
				className={classes.content}
				onSubmit={upsertAccountHandler}
			>
				<div className={classnames(classes.flexVertically, classes.flexStart)}>
					{headerDiv}
					{formDiv}
				</div>
				{buttons}
			</form>
		</div>
	);
};



const getLabelAndInput = ({
	classes,
	editMode,
	endAdornment,
	fieldLabel,
	fieldString,
	initialValue = '',
	inputSize,
	labelSize,
	onChangeHandler,
	placeholder,
	rootSize,
	required,
}) => {

	if (typeof fieldLabel === 'undefined'
		|| typeof fieldString === 'undefined'
		|| typeof onChangeHandler === 'undefined'
		|| typeof placeholder === 'undefined') {
		return null;
	};

	const labelField = (
		<Typography
			className={classnames(classes.label, classes[labelSize])}
		>
			{fieldLabel + (required || false ? ' *' : '')}
		</Typography>
	);

	const endAdornmentField = !!endAdornment
		&& (<Typography className={classes.endAdornment} key={'label_' + fieldLabel}>
			{endAdornment}
		</Typography>);

	const inputField = (
		<TextField
			id={fieldLabel}
			key={'input_' + fieldLabel}
			InputLabelProps={{ classes: { root: classes.labelProps, focused: classes.labelProps } }}
			InputProps={{ classes: { root: classes.inputProps, input: classes.inputProps } }}
			className={classnames(classes.field, classes[inputSize])}
			margin="normal"
			onChange={(e) => onChangeHandler(fieldString, e.target.value)}
			error={false}
			value={placeholder[fieldString] || initialValue}
			disabled={!editMode}
			required={!!required}
		/>
	);

	return (
		<div className={classnames(classes.labelAndInput, classes[rootSize])}>
			{labelField}
			{inputField}
			{endAdornmentField}
		</div>
	);
};

///////////////////////////////

const getCheckboxAndLabel = ({
	classes,
	editMode,
	fieldLabel,
	fieldString,
	onChangeHandler,
	placeholder,
	rootSize,
	required,
}) => {

	if (typeof fieldString === 'undefined'
		|| typeof onChangeHandler === 'undefined'
		|| typeof placeholder === 'undefined') {
		return null;
	};

	const labelField = (
		<Typography className={classes.checkboxLabel} key={'label_' + fieldLabel}>
			{fieldLabel}
		</Typography>
	);

	const fieldSelect = (
		<Checkbox
			key={'input_' + fieldLabel}
			className={classes.checkbox}
			checked={!!placeholder[fieldString] ? true : false}
			color='primary'
			onChange={() => {
				const current = !!placeholder[fieldString];
				onChangeHandler(fieldString, !current);
			}}
			disableRipple={true}
			disableTouchRipple={true}
			disabled={!editMode}
			required={!!required}
		/>
	);

	return (
		<div className={classnames(classes.labelAndInputCheckbox, classes[rootSize])}>
			{fieldSelect}
			{labelField}
		</div>
	);
};

export default withStyles(styles)(UpsertAccount);