import React from 'react';
import Tree from '../Tree/TreeWrapper';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import styles from './Permissions.styles';
import classnames from 'classnames';

const Permissions = ({
	allPermissions,
	classes,
	className,
	permissions,
	setPermissions,
}) => {

	const title = (
		<Typography className={classes.title}>
			Available Permissions
		</Typography>
	);

	const header = (
		<div className={classes.header}>
			<Typography className={classes.headerText}>
				Resources
			</Typography>
			<Typography className={classes.headerText}>
				Select
			</Typography>
		</div>
	);

	const list = (
		<Tree
			allPermissions={allPermissions}
			permissions={permissions}
			setPermissions={setPermissions}
		/>
	);

	return (
		<div className={classnames(classes.root, className)}>
			<div className={classes.div}>
				{title}
			</div>
			{header}
			{list}
		</div>
	);
};

export default withStyles(styles)(Permissions);