export const styles = () => ({
  account: {
    fontWeight: "bold",
    fontSize: 12,
  },
  name: {
    fontWeight: "bold",
    fontSize: 12,
  },
  type: {
    fontSize: 10,
  },
  icon: {
    fontSize: 12,
    marginRight: 5,
  },
});
