const styles = theme => ({

	root: {
		backgroundColor: 'inherit',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		justifyContent: 'flex-start',
		maxWidth: '390px',
		minWidth: '390px',
		overflowY: 'auto',
		overflowX: 'hidden',
		padding: 8,
		scrollbarWidth: 'thin', //FIREFOX
		width: '100%',
		//CHROME
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},

	title: {
		fontSize: '18px',
	},

	subtitle: {
		fontSize: '16px',
		marginTop: '15px',
	},

	smallText: {
		fontSize: '14px',
		marginTop: '6px',
	},

	inputClass: {
		marginLeft: '10px',
		maxWidth: '200px',
	},

	searchIcon: {
		cursor: 'pointer',
	},

	inputRoot: {
		color: 'inherit',
		width: '100%',
	},

	select: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
		marginRight: theme.spacing(2),
		minWidth: '100px',
		width: '100%',
	},

	inputText: {
		position: 'relative',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'first baseline',
		flex: 1,
	},

	searchVehicleContainer: {
		position: 'relative',
	},
	

	searchMapContainer: {
		position: 'absolute',
		left: 65,
		top: 28,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	
	disabled: {
		color: '#C5C5C5',
		cursor: 'default',
	},
	
	icon: {
		color: '#009AC9',
		cursor: 'pointer',
	},
	
	absoluteIcon: {
		position: 'absolute',
		left: 116,
		top: 20,
		color: '#009AC9',
		cursor: 'pointer',
	},

	textInputLabel: {
		fontSize: '16px',
		marginRight: '10px',
		width: '100%',
	},

	description: {
		borderBottom: 'none',
	},

	buttons: {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'space-around',
		flex: 1,
		padding: '10px 0',
	},

	datePickers: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		maxWidth: 'none',
	},

	selections: {
		display: 'flex',
		width: '100%',
	},

	largerSelect: {
		minWidth: '125px',
	},

	modal: {
		backgroundColor: 'white',
		boxShadow: theme.shadows[5],
		boxSizing: 'border-box',
		outline: 0,
		height: '100vh',
		width: '100vw',
		left: 0,
		top: 0,
		padding: '10px',
		position: 'absolute',
	},

	error: {
		color: '#f01',
	},

	errorBar: {
		width: '350px',
		height: '20px',
		alignSelf: 'center',
		backgroundColor: '#ff0000e5',
		borderRadius: '5px',
		boxShadow: theme.shadows[5],
		position: 'fixed',
	},

	errorText: {
		color: '#fff',
	},

	onlineBtn: {
		color: '#00B67F',
	},

	offlineBtn: {
		color: '#FD2E25',
	},

	operatorHeaderClassName: {
		width: 'calc(100% - 8px)',
	},

	location: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		gap: '20px',
	},

	exitIcon: {
		color: 'gray',
		cursor: 'pointer',
	},
});

export default styles;