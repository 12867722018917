import User from '../../../../model/Carclub/User.js';

export const fromDTO = ({
	createBy,
	createDate,
	customerCode,
	deactivated,
	email,
	fullName,
	login,
	mobile,
	modifiedBy,
	modifiedDate,
	role,
}) => new User({
	code: customerCode,
	createBy,
	createDate,
	email,
	enabled: typeof deactivated !== 'undefined' ? !deactivated : undefined,
	login,
	mobile,
	modifiedBy,
	modifiedDate,
	name: fullName,
	role,
});

export const toDTO = ({
	code,
	createBy,
	createDate,
	email,
	enabled,
	name,
	login,
	mobile,
	modifiedBy,
	modifiedDate,
	role,
}) => ({
	createBy,
	createDate,
	customerCode: code,
	deactivated: typeof enabled !== 'undefined' ? !enabled : undefined,
	email,
	fullName: name,
	login,
	mobile,
	modifiedBy,
	modifiedDate,
	role,
});

const userFlows = {
	fromDTO,
	toDTO,
};

export default userFlows;