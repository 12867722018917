import createModel from '../createModel';

class Permission {

	constructor({
		accessLevel,
		createBy,
		createDate,
		description,
		id,
		modifiedBy,
		modifiedDate,
		name,
		permissions,
	} = {}) {
		createModel.call(this, {
			accessLevel,
			createBy,
			createDate,
			description,
			id,
			modifiedBy,
			modifiedDate,
			name,
			permissions,
		});
	};
};

export default Permission;