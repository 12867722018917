const styles = () => ({
	
	root: {
		height: '290px',
		width: '300px',
		outline: 'none',
	},

	reducedRootSize: {
		height: '249px',
	},

	errorDiv: {
		position: 'absolute',
		top: '10px',
		left: '10%',
		width: '80%',
		border: "2px solid red",
	},

	errorText: {
		fontSize: '9px',
	},

	content: {
		margin: '35px 10px',
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
		flexDirection: 'column',
	},

	buttons: {
		marginTop: '25px',
		textAlign: 'center',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
	},

	locPicker: {
		cursor: 'pointer',
	},

	locationPickerRoot: {
		zIndex: '99999',
		padding: 0,
	},
	
	importButton: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},

	attachmentInput: {
		color: '#009AC9',
		cursor: 'pointer',
		margin: '10px 0',
		width: '110px',
		fontSize: '14px',
	},

	importContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},

});

export default styles;