import { Checkbox, Typography } from "@material-ui/core";
import { Check as MandatoryIcon } from "@material-ui/icons";

export function listColumns(selectedDocuments, onDocumentCheckedChange, toTimeZone) {
  return [
    {
      field: "id",
      align: "left",
      headerAlign: "left",
      width: 40,
      disableSort: true,
      customValue: (v) => {
        let checked = selectedDocuments ? Boolean(selectedDocuments.find((item) => item.id === v.id)) : false;
        return (
          <Checkbox
            color="primary"
            style={{ padding: "0 0 0 10px" }}
            checked={checked}
            onChange={(e) => onDocumentCheckedChange(e.target.checked, v)}
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "type",
      headerName: "Type",
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "mandatory",
      headerName: "Mandatory",
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        if (v.mandatory) {
          return <MandatoryIcon style={{ fill: "rgb(0, 182, 127)" }} />;
        }
      },
    },
    {
      field: "version",
      headerName: "Version",
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "date",
      headerName: "Date",
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return <Typography>{toTimeZone(v.createDate, "dateTime")}</Typography>;
      },
    },
  ];
}

// createDate: "2022-06-01 12:07:57"
// id: "4e138913-a48b-47d2-8c05-ffe05c16ad3c"
// locationId: "6d4c5bc6-a4aa-419b-95db-f3705c695486"
// mandatory: false
// name: "doc 2"
// type: "Terms and Conditions"
// url: "https://mbg-pub.fra1.digitaloceanspaces.com/BLZ/OTA%20keys%20-%20Public_API_Integration-2.27%20%283%29.pdf"
// version: 1
