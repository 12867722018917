import makeActionCreator from '../../utils/makeActionCreator';
import createReducer from '../createCustomReducer';

export const emptyState = {
};

const types = {
	SET_FIELD: 'SET_FIELD',
	RESET: 'RESET',
};

export const setField = makeActionCreator(types.SET_FIELD, 'field');
export const reset = () => ({ type: types.RESET });

const setFieldReducer = (state, action) => ({
	...state,
	[action.field.name]: action.field.value,
});

const createFormReducer = (defaultState = emptyState) => { 

	const resetReducer = () => defaultState;

	const actions = dispatch => ({
		setField: data => dispatch(setField(data)),
		reset: () => dispatch(reset()),
		initFields: () => defaultState,
	});

	const reducer = (state, action) => ({
		[types.SET_FIELD]: setFieldReducer,
		[types.RESET]: resetReducer,
	}[action.type](state, action));

	return createReducer({ reducer, actions, defaultState })(defaultState);
};

export default createFormReducer;
