export const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    width: "100%",
    cursor: "pointer",
  },
  disabled: {
    cursor: "initial",
    opacity: 0.6,
  },
  selected: {
    backgroundColor: "#e9f6fa",
  },
});
