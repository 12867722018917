import React from 'react';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import styles from './DetailsDiv.styles';
import classnames from 'classnames';

const DetailsDiv = ({
	afterTitle,
	beforeTitle,
	children,
	classes,
	className,
	title,
}) => {

	const titleDiv = (
		<div className={classnames(classes.titleDiv)}>
			{beforeTitle}
			<Typography 
				className={classnames(classes.title, className && className.title)}
			>
				{title}
			</Typography>
			{afterTitle}
		</div>
	);

	const detailsDiv = (
		<div className={classnames(classes.root, className && className.detailList)}>
			{titleDiv}
			{children}
		</div>
	);

	return detailsDiv;
};

export default withStyles(styles)(DetailsDiv);
