import { createSlice } from "@reduxjs/toolkit";
import alertAssignCall from "./_sliceCalls/alertAssign";
import alertListCall from "./_sliceCalls/alertList";

export const alertsSlice = createSlice({
  name: "alerts",
  initialState: {
    alerts: {
      list: [],
      loaded: false,
      totalPending: 0,
    },
    alertsFilter: {
      isLastPage: false,
      locationId: undefined,
      resolved: false,
      page: 1,
    },
    selectedAlert: undefined,
    alertsTabOpened: false,
  },
  reducers: {
    setAlerts: (state, action) => {
      state.alerts.list = action.payload.list;
      state.alerts.totalPending = action.payload.totalPending;
      state.alerts.loaded = true;
      state.alertsFilter.isLastPage = action.payload.isLastPage;
    },
    appendAlerts: (state, action) => {
      state.alerts.list = [...state.alerts.list, ...action.payload.list];
      state.alerts.totalPending = action.payload.totalPending;
      state.alertsFilter.isLastPage = action.payload.isLastPage;
    },
    appendNewAlert: (state, action) => {
      console.log("appendNewAlert - action.payload: %o", action.payload);
      try {
        let alertData = JSON.parse(action.payload?.alertData);
        console.log("appendNewAlert - alertData: %o", alertData);
        console.log("appendNewAlert - state.alertsFilter.locationId: %o", state.alertsFilter.locationId);

        if (state.alertsFilter.locationId === undefined || state.alertsFilter.locationId === alertData?.payload?.locationId) {
          state.alerts.list = [action.payload, ...state.alerts.list];
          state.alerts.totalPending = state.alerts.totalPending + 1;
        }
      } catch (_ex) {}
    },
    setAlertsFilterLocation: (state, action) => {
      console.log("### setAlertsFilterLocation: %o", action);
      state.alertsFilter.locationId = action.payload;
      state.alertsFilter.page = 1;
      state.alertsFilter.isLastPage = false;
    },
    setAlertsFilterResolved: (state, action) => {
      state.alertsFilter.resolved = action.payload;
      state.alertsFilter.page = 1;
      state.alertsFilter.isLastPage = false;
    },
    incrementAlertsFilterPage: (state) => {
      if (!state.alertsFilter?.isLastPage) {
        state.alertsFilter.page++;
      }
    },
    resetFiltersPaging: (state) => {
      state.alertsFilter.page = 1;
      state.alertsFilter.isLastPage = false;
    },
    updateAlertInList: (state, action) => {
      let list = state.alerts.list;
      let pos = list.findIndex((item) => item.alertToken === action.payload.alertToken);
      list[pos].resolved = action.payload.resolved;
      list[pos].user = action.payload.user;
      state.alerts.list = list;
    },
    setSelectedAlert: (state, action) => {
      state.selectedAlert = action.payload;
    },
    clearSelectedAlert: (state) => {
      state.selectedAlert = undefined;
    },
    setAlertsTabOpen: (state, action) => {
      state.alertsTabOpened = action.payload;
    },
  },
});

export default alertsSlice.reducer;

export const {
  setAlerts,
  appendAlerts,
  incrementAlertsFilterPage,
  setAlertsFilterResolved,
  setAlertsFilterLocation,
  resetFiltersPaging,
  updateAlertInList,
  setSelectedAlert,
  clearSelectedAlert,
  setAlertsTabOpen,
  appendNewAlert,
} = alertsSlice.actions;

// SELECTS
export const selectIsLoading = (state) => state.getIn(["core"])?.loader?.length > 0;
export const selectAlerts = (state) => state.getIn(["alerts"])?.alerts;
export const selectAlertsFilter = (state) => state.getIn(["alerts"])?.alertsFilter;
export const selectSelectedAlert = (state) => state.getIn(["alerts"])?.selectedAlert;
export const selectAlertsTabOpen = (state) => state.getIn(["alerts"])?.alertsTabOpened;

// CALLS
export const alertList = (options) => async (dispatch, getState) => {
  alertListCall(alertsSlice.actions, dispatch, getState(), options);
};
export const alertAssign =
  ({ alertToken, status }) =>
  async (dispatch, getState) => {
    alertAssignCall(alertsSlice.actions, dispatch, getState(), { alertToken, status });
  };
