import React from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid, Divider, Button } from "@material-ui/core";

import { styles } from "./General.styles";
import { Form, FormFields } from "../../../../common/forms";
import { compayFormFields, customizationFormFields, formSchema, generalFormFields } from "./General.schema";
import {
  carclubUpdateConfigs,
  carclubUpdateDefaultConfigs,
  selectCarclubConfigs,
  selectCarclubDefaultConfigs,
} from "../../carclubSlice";
import s3Service from "../../../../services/S3";

function General({ classes, disabled, submitRef, isDefaultConfigs }) {
  const dispatch = useDispatch();
  const defaultConfigs = useSelector(selectCarclubDefaultConfigs);
  const locationConfigs = useSelector(selectCarclubConfigs);
  const configs = isDefaultConfigs ? defaultConfigs : locationConfigs;

  const onSubmit = async (values) => {
    const input = document.getElementById("uploaded_logo_file");
    const inputedFile = input?.files?.[0];
    let logoFile = configs.CARCLUB_INFO.customize.logoFile;

    if (inputedFile !== undefined) {
      let s3file = await s3Service.uploadPublicFile(inputedFile);
      console.log("s3file: %o", s3file);
      logoFile = s3file?.imageUrl;
    }

    let infoCompany = { ...configs.CARCLUB_INFO.infoCompany, ...values.CARCLUB_INFO.infoCompany };
    let infoUser = { ...configs.CARCLUB_INFO.infoUser, ...values.CARCLUB_INFO.infoUser };
    let customize = { ...configs.CARCLUB_INFO.customize, ...values.CARCLUB_INFO.customize, logoFile };
    let secrets = { ...configs.CARCLUB_INFO.secrets, ...values.CARCLUB_INFO.secrets };
    let linkApp = { ...configs.CARCLUB_INFO.linkApp, ...values.CARCLUB_INFO.linkApp };
    let CARCLUB_INFO = { ...configs.CARCLUB_INFO, infoCompany, infoUser, customize, secrets, linkApp };
    let CONFIGURATION = { ...configs.CONFIGURATION, ...values.CONFIGURATION };
    let nConfigs = { ...configs, CARCLUB_INFO, CONFIGURATION };

    console.log("nConfigs: %o", nConfigs);

    if (isDefaultConfigs) {
      dispatch(carclubUpdateDefaultConfigs(nConfigs));
    } else {
      dispatch(carclubUpdateConfigs(nConfigs));
    }
  };

  let initialValues = configs;

  return (
    <div className={classes.root}>
      <Form
        schema={formSchema()}
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ submitting, values }) => {
          return (
            <>
              <Grid container spacing={3} wrap="nowrap">
                {isDefaultConfigs && (
                  <Grid item xs={4}>
                    <Typography className={classes.title}>Company Information</Typography>
                    <FormFields fields={compayFormFields({ disabled, classes })} />
                  </Grid>
                )}
                {isDefaultConfigs && <Divider orientation="vertical" flexItem />}
                <Grid item xs={4}>
                  <Typography className={classes.title}>General Configuration</Typography>
                  <FormFields fields={generalFormFields({ disabled, classes, isDefaultConfigs })} />
                </Grid>
                <Divider orientation="vertical" flexItem />
                {isDefaultConfigs && (
                  <Grid item xs={4}>
                    <Typography className={classes.title}>Customization</Typography>
                    <Typography>Logo File</Typography>
                    <input
                      id="uploaded_logo_file"
                      className={classes.fileInput}
                      type="file"
                      accept="image/*"
                      disabled={disabled}
                    />
                    <br />
                    <br />
                    <FormFields fields={customizationFormFields({ disabled, classes })} />
                  </Grid>
                )}
              </Grid>
              <Button
                style={{ display: "none" }}
                ref={submitRef}
                type="submit"
                color="primary"
                variant="contained"
                disabled={submitting}
              >
                Submit
              </Button>
            </>
          );
        }}
      />
    </div>
  );
}

export default withStyles(styles)(General);
