import { Grid } from "@material-ui/core";
import React from "react";
import ConfigInput from "./ConfigInput";

class ConfigsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
      newData: props.newData || {},
    };
  }

  getFieldValue(key) {
    if (this.state.newData && key in this.state.newData) {
      return this.state.newData[key];
    }
    if (this.props.data) {
      return this.props.data[key];
    }
  }

  handleChange(k, v) {
    let change = this.state.newData;
    change[k] = v;
    this.props.onNewData(change);
    if (JSON.stringify(v) === JSON.stringify(this.props.data[k])) {
      delete change[k];
    }
    this.setState({
      newData: change,
    });
  }

  isVersionLowerOrEqual(element_version, app_version) {
    element_version = element_version.split(".").map(function (el) {
      return parseInt(el);
    });
    app_version = app_version.split(".").map(function (el) {
      return parseInt(el);
    });
    for (let i = 0; i < element_version.length; i++) {
      if (element_version[i] < app_version[i] && i + 1 < element_version.length) {
        return true;
      }
      if (element_version[i] <= app_version[i] && i + 1 === element_version.length) {
        return true;
      }
      if (element_version[i] > app_version[i]) {
        return false;
      }
    }
    return false;
  }

  renderFields() {
    if (this.props.fields) {
      return this.props.fields.map((element, index) => {
        if (this.isVersionLowerOrEqual(element.version, this.props.selectedVersion)) {
          return (
            <ConfigInput
              field={element}
              value={this.getFieldValue(element.key)}
              key={element.key}
              onChange={this.handleChange.bind(this)}
            ></ConfigInput>
          );
        }
        return "";
      });
    }
  }

  render() {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <br />
            <table>
              <thead>
                <tr className="text-center">
                  <th>Key</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>{this.renderFields()}</tbody>
            </table>
          </Grid>
          {/* <Col md={{ size: 4 }}>
            <br />
            {
              this.props.changesToUpdate && this.props.showChangesBox ?
                (<Card className="card-json" color="primary">
                  <CardHeader>New Data</CardHeader>
                  <CardFooter>
                    <JsonToHtml object={this.state.newData}></JsonToHtml>
                  </CardFooter>
                </Card>) : ""
            }
            <br />
            <Card className="card-json">
              <CardHeader>Original Data</CardHeader>
              <CardFooter>
                <JsonToHtml object={this.props.data}></JsonToHtml>
              </CardFooter>
            </Card>
          </Col> */}
        </Grid>
      </div>
    );
  }
}

export default ConfigsTab;
