import React from 'react';
import {
	Tooltip,
	withStyles,
} from '@material-ui/core';
import { styles } from './WorkOrderMarker.styles';
import classnames from 'classnames';
import {
	AccountCircle,
} from '@material-ui/icons';

const WorkOrderMarker = ({
	classes,
	workOrder,
	lat,
	lng,
	selectedWorkOrder,
	setSelectedWorkOrder,
}) => {

	const {
		code,
		name,
	} = workOrder || {};

	const isSelected = selectedWorkOrder.code === code;

	return (
		<div
			key={`workOrder#${code}`}
			style={{
				width: 30,
				height: 30,
				borderRadius: 5,
				transform: 'translate(-50%, -50%)',
			}}
			onClick={ () => setSelectedWorkOrder(workOrder)}
		>
			<Tooltip
				title={name}
				placement='top'
			>
				<AccountCircle
					key={`workOrderIcon#${code}`}
					className={classnames(isSelected && classes.selected)}
					style={{
						width: 30,
						height: 30,
						color: 'rgb(1, 151, 198)',
					}} />
			</Tooltip>

		</div>
	);
};

export default withStyles(styles)(WorkOrderMarker);
