import React, { useEffect } from "react";
import Button from "../../UI/Buttons/CommonButtons";
import { Checkbox, FormControlLabel, TextField, Typography, withStyles } from "@material-ui/core";
import styles from "./PricingPlanEditor.styles";
import moment from "moment";
import { DatePicker } from "@material-ui/pickers";
import { CHECK_TYPE } from "../../../containers/PricingPlanAppNew/PricingPlanAppWrapper";
import SearchList from "../../UI/SearchList/SearchListWrapper";
import ZonesList from "./ZonesList";

const PricingPlanEditor = ({
  checkPlaceholderRequiredValues,
  classes,
  createMode,
  defaultLocation,
  disableButtons,
  editMode,
  error,
  errorMessage,
  getZones,
  hasError,
  listRef,
  onAddZone,
  onCancel,
  onRemoveZone,
  onSave,
  placeholder,
  placeholderSetters,
  selectedZone,
  setError,
  setSelectedZone,
  setShowPPCalendar,
  upsertPPHandler,
  startZoneFees,
  endZoneFees,
  zones,
}) => {
  useEffect(() => {
    listRef.current.func(zones.map((zone) => ({ key: zone?.id, label: zone?.description, type: zone?.type })));
    // eslint-disable-next-line
  }, [zones]);

  const title = (
    <Typography className={classes.title}>{`${placeholder.id ? "Editing" : "Creating"} Pricing Plan`}</Typography>
  );

  const defaultLocationField = (
    <TextField defaultValue={defaultLocation?.name} id="defLocEditor" label="Location" disabled={true} />
  );

  const name = (
    <TextField
      value={placeholder.name || ""}
      error={hasError && placeholder.name.trim().length < 1}
      helperText={hasError && placeholder.name.trim().length < 1 && "Please fill the name"}
      id="nameEditor"
      label="Name"
      onChange={(event) => placeholderSetters.setName(event.target.value)}
      disable={(!editMode.state).toString()}
      required={true}
    />
  );

  const description = (
    <TextField
      value={placeholder.description || ""}
      error={hasError && placeholder.description.trim().length < 1}
      helperText={hasError && placeholder.description.trim().length < 1 && "Please fill the description"}
      id="descriptionEditor"
      label="Description"
      onChange={(event) => placeholderSetters.setDescription(event.target.value)}
      disable={(!editMode.state).toString()}
    />
  );

  const startingFee = (
    <TextField
      id="startingFeeEditor"
      label="Starting Fee"
      onChange={(event) => placeholderSetters.setStartingFee(event.target.value)}
      value={placeholder.startingFee || ""}
      disable={(!editMode.state).toString()}
      required={true}
    />
  );

  const calendarTitle = <Typography className={classes.calendarTitle}>Calendar</Typography>;

  const datePickers = (
    <div className={classes.datePickersContainer}>
        <DatePicker
          ampm={false}
          className={classes.pickers}
          format="DD/MM/YYYY"
          inputVariant="outlined"
          key="datePickerStart"
          label="Start date"
          onChange={(date) => placeholderSetters.setStartDate(moment(date).format("YYYY-MM-DD HH:mm:ss"))}
          required={true}
          value={placeholder.startDate}
          InputProps={{ classes: { input: classes.pickerInput } }}
          InputLabelProps={{ classes: { outlined: classes.outlined } }}
        />
        <DatePicker
          ampm={false}
          className={classes.pickers}
          format="DD/MM/YYYY"
          inputVariant="outlined"
          key="datePickerEnd"
          label="End date"
          onChange={(date) => placeholderSetters.setEndDate(moment(date).format("YYYY-MM-DD HH:mm:ss"))}
          required={true}
          value={placeholder.endDate}
          InputProps={{ classes: { input: classes.pickerInput } }}
          InputLabelProps={{ classes: { outlined: classes.outlined } }}
        />
    </div>
  );

  const category = (
    <TextField
      id="category"
      label="Category"
      onChange={(event) => placeholderSetters.setCategory(event.target.value)}
      value={placeholder.category || ""}
      disable={(!editMode.state).toString()}
      required={true}
    />
  );

  const privateField = (
    <FormControlLabel
      control={
        <Checkbox
          checked={placeholder.specific}
          onChange={(event) => placeholderSetters.setSpecific(event.target.checked)}
          disable={(!editMode.state).toString()}
        />
      }
      label="Private"
      color="primary"
    />
  );

  const startZones = (
    <ZonesList
      addZone={(fee) => onAddZone(fee, "start")}
      onRemove={(zone) => onRemoveZone(zone, "start")}
      title="Start Zone"
      zonesList={startZoneFees}
    />
  );

  const endZones = (
    <ZonesList
      addZone={(fee, equal) => onAddZone(fee, "end", equal)}
      onRemove={(zone) => onRemoveZone(zone, "end")}
      title="End Zone"
      zonesList={endZoneFees}
      hasEqual
    />
  );

  const errorField = !!error && (
    <Typography align="center" className={classes.error}>
      {error}
    </Typography>
  );

  const saveButton = (
    <Button
      buttonStyleName="filled"
      disabled={disableButtons}
      key="SaveButton"
      label={placeholder.id ? "Save" : "Next"}
      onClick={() => {
        const checkResult = checkPlaceholderRequiredValues(placeholder, CHECK_TYPE.PP);

        if (!!checkResult) {
          setError(`The "${[checkResult]}" field is required.`);
          return;
        }
        onSave();
        if (!createMode.state) {
          upsertPPHandler();
          return;
        }
        setShowPPCalendar(true);
      }}
    />
  );

  const cancelButton = (
    <Button buttonStyleName="void" disabled={disableButtons} key="CancelButton" label="Cancel" onClick={onCancel} />
  );

  const pricingPlanEditor = (
    <div className={classes.root}>
      {title}
      {defaultLocationField}
      {name}
      {description}
      {calendarTitle}
      {datePickers}
      {startingFee}
      {category}
      {privateField}
      <SearchList
        currentFields={{
          key: selectedZone?.key,
          label: selectedZone?.label,
          type: selectedZone?.type,
        }}
        getList={getZones}
        header={["Description", "Type"]}
        name="Zones"
        onSelect={setSelectedZone}
        ref={listRef}
        searchLabel="description"
      />
      {startZones}
      {endZones}
      <div className={classes.buttons}>
        {cancelButton}
        {saveButton}
      </div>
      {errorField}
    </div>
  );

  return pricingPlanEditor;
};

export default withStyles(styles)(PricingPlanEditor);
