import React from 'react';
import {
	MenuItem,
	TextField,
	withStyles,
} from '@material-ui/core';
import { styles } from './NotificationFilter.styles';
import classnames from 'classnames';

const NotificationFilter = ({
	classes,
	className,
	selectType,
	selectSubType,
	subType,
	subTypes,
	type,
	types,
}) => {

	if (!type || !subType) {
		return null;
	}

	const typeOptions = types.map(option => (
		<MenuItem key={option} value={option}>
			{option}
		</MenuItem>
	));

	const subTypeOptions = subTypes.map(option => (
		<MenuItem key={option} value={option}>
			{option}
		</MenuItem>
	));

	return (
		<div
			className={classnames(classes.root, className)}
		>

			<TextField
				className={classes.textFieldRoot}
				SelectProps={{ classes: { outlined: classes.textFieldSelect } }}
				id="notification-type-filter"
				select
				value={type}
				onChange={event => selectType(event.target.value)}
				variant='outlined'
			>
				{typeOptions}
			</TextField>

			<TextField
				className={classes.textFieldRoot}
				SelectProps={{ classes: { outlined: classes.textFieldSelect } }}
				id="notification-subtype-filter"
				select
				value={subType}
				onChange={event => selectSubType(event.target.value)}
				disabled={type === types[0]}
				variant='outlined'
			>
				{subTypeOptions}
			</TextField>

		</div>
	);
};

export default withStyles(styles)(NotificationFilter);