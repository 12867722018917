import React from "react";
import LocationsList from "../LocationsList/LocationsList";
import importLocations from "../../../services/ImportXML/CoordinatesXML";
import CreateLocations from "../CreateLocations/CreateLocationsWrapper";

const LocationsWorkspace = ({
  centerMap,
  classes,
  areAllLocationsPainted,
  createOrUpdateLocation,
  currentLocation,
  hasModal,
  hideLocation,
  hideLocations,
  importLocation,
  isCreating,
  isDrawing,
  isImporting,
  locationCreator,
  locationPainter,
  locationRedrawer,
  locations,
  paintAllLocations,
  polygon,
  removeLocation,
  selectedLocation,
  selectedLocationCode,
  setImportLocation,
  setIsImporting,
  setLocations,
  setSelectedLocationCode,
  showLocations,
  defaultLocationFromUser,
}) => {
  const selectLocation = (location, editable = false) => {
    if (!areAllLocationsPainted) {
      hideLocations();
    }

    const { code } = location || {};

    setSelectedLocationCode(code);

    if (editable) {
      locationRedrawer(location, true);
      return;
    }

    locationPainter(location, true);
  };

  const cancelDrawing = () => {
    setIsImporting();
    stopDrawing();
  };

  const setXML = (xml) => {
    !areAllLocationsPainted && hideLocations();

    setIsImporting(true);

    setSelectedLocationCode();

    isCreating.setOn();

    showLocations.setOn();

    isDrawing.setOn();

    importLocations(
      xml,
      (location) => {
        setImportLocation(location);
        locationRedrawer(location, true);
      },
      cancelDrawing
    );
  };

  const createLocation = () => {
    if (selectedLocation) {
      setSelectedLocationCode();
    }

    setImportLocation();

    !areAllLocationsPainted && hideLocations();

    isCreating.setOn();
    isDrawing.setOn();

    locationCreator();
  };

  const stopDrawing = stopDrawingSetup(isDrawing.setOff, hasModal.setOff, isCreating.setOff);

  const editLocation = (location) => {
    setSelectedLocationCode(location.code);
    isCreating.setOn();
    hasModal.setOn();
  };

  const redrawLocation = (location) => {
    showLocations.setOn();
    selectLocation(location, true);
    isDrawing.setOn();
  };

  const locationsList = showLocations.state && (
    <LocationsList
      areAllLocationsPainted={areAllLocationsPainted}
      cancelDrawing={cancelDrawing}
      centerMap={centerMap}
      confirmDrawing={() => {
        setIsImporting();
      }}
      createLocation={createLocation}
      currentLocation={currentLocation}
      editLocation={editLocation}
      isCreating={isCreating}
      isDrawing={isDrawing}
      isImporting={isImporting}
      paintAllLocations={paintAllLocations}
      redrawLocation={redrawLocation}
      removeLocation={removeLocation}
      selectedLocationCode={selectedLocationCode}
      selectLocation={(location) => {
        selectLocation(location);
        setIsImporting();
        isCreating.setOff();
      }}
      setLocations={(data, code) => {
        hideLocation(code);
        setLocations(data);
      }}
      setXML={setXML}
      stopRedrawing={() => {
        console.log("got here");
        stopRedrawing(selectedLocation, polygon, stopDrawing, createOrUpdateLocation);
      }}
      locations={Object.values(locations)}
    />
  );

  const createLocationModal = hasModal.state && (
    <CreateLocations
      createOrUpdateLocation={createOrUpdateLocation}
      location={selectedLocation || importLocation}
      polygon={polygon}
      setLocations={setLocations}
      stopDrawing={() => {
        stopDrawing();
        hideLocation("NEW");
      }}
      defaultLocationFromUser={defaultLocationFromUser}
      setXML={setXML}
    />
  );

  return (
    <React.Fragment>
      {createLocationModal}
      {locationsList}
    </React.Fragment>
  );
};

export default LocationsWorkspace;

const stopDrawingSetup = (setIsDrawing, setModal, setIsCreating) => () => {
  setIsDrawing();
  setIsCreating && setIsCreating();
  setModal();
};

const stopRedrawing = (location, polygon, stopDrawing, updateLocation) => {
  const { name, latitude, longitude, timezone, code } = location;

  updateLocation(
    polygon,
    name,
    latitude,
    longitude,
    timezone,
    (error) => {}, //FIXME: setError(error) should do something on error
    stopDrawing,
    code
  );
};
