import React from 'react';
import {
	ListItem,
	ListItemIcon,
	ListItemText,
	Tooltip,
} from '@material-ui/core';

const menuButton = ({
	button,
	showLabel,
}) => {

	return (
		<Tooltip
			title={button.text || ''}
		>
			<ListItem
				button={!button.notButton}
				selected={button.selected}
				disabled={!button.onClick}
				onClick={button.onClick || (() => null)}
			>
				{button.icon && <ListItemIcon>
					<button.icon
						style={{
							color: button.color,
							fill: button.fill,
							...button.style,
						}}
					/>
				</ListItemIcon>}
				{showLabel && button.name && <ListItemText primary={button.text} />}
			</ListItem>
		</Tooltip>
	);
};

export default menuButton;