export const styles = theme => ({

	root: {
	
	},

	subHeaderClassName: {
		height: 30,
	},

});