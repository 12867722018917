import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Grid, Card } from "@material-ui/core";

import { styles } from "./CardIconTab.styles";

function CardIconTab({ classes, icon, children, selected, disabled, onClick }) {
  return (
    <Card
      className={classes.root + (disabled ? " " + classes.disabled : selected ? " " + classes.selected : "")}
      onClick={() => !disabled && onClick()}
    >
      <Grid container spacing={1} direction="row" wrap="nowrap" alignItems="center">
        <Grid item>{icon}</Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </Card>
  );
}

export default withStyles(styles)(CardIconTab);
