import React, { useState } from "react";
import { Fab, Tooltip, withStyles, Modal, Typography, Button } from "@material-ui/core";
import styles from "./CommonButtons.styles";
import classnames from "classnames";

const CommonButtons = ({
  classes,
  className,
  buttonClassName,
  label,
  onClick,
  buttonStyleName = "filled",
  type = "button",
  leftIcon = false,
  rightIcon = false,
  children,
  disabled,
  tooltip,
  confirmMsg,
  ...props
}) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const buttonStyle = buttonStyleName === "filled" ? classes.filledBtn : classes.voidBtn;
  const showLeftIcon = leftIcon && children;
  const showRightIcon = rightIcon && children;

  const openConfirmModal = () => {
    setConfirmModalOpen(true);
  };

  const modal = confirmMsg && (
    <Modal open={confirmModalOpen} onClose={() => setConfirmModalOpen(false)}>
      <div className={classes.modal}>
        <Typography className={classes.modalTitle}>Confirm?</Typography>
        <Typography className={classes.modalText}>{confirmMsg}</Typography>
        <div className={classes.modalButtons}>
          <Button variant="contained" color="primary" onClick={() => setConfirmModalOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onClick();
              setConfirmModalOpen(false);
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );

  const button = (
    <Fab
      classes={{ extended: classnames(buttonStyle, buttonClassName) }}
      onClick={(e) => (confirmMsg ? openConfirmModal() : onClick && onClick(e))}
      variant="extended"
      type={type}
      disabled={!!disabled}
      {...props}
    >
      {showLeftIcon}
      {label || "Label Missing"}
      {showRightIcon}
    </Fab>
  );

  const content = (
    <div className={classnames(classes.root, className)}>
      {button}
      {modal}
    </div>
  );

  const contentWithTooltip = tooltip && <Tooltip title={tooltip}>{content}</Tooltip>;
  return contentWithTooltip || content;
};

export default withStyles(styles)(CommonButtons);
