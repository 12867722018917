import React from "react";
import { TabContent, TabPane, Nav } from "reactstrap";

import ConfigsTab from './ConfigsTab';
import TabLink from '../../../_general/tabs/TabLink';
import ConfigsCustomerTab from './ConfigsCustomerTab';

class ConfigsZone extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: '1',
			newConfigs: props.changes,
		};
		this.toggle = this.toggle.bind(this);
		this.newData = this.newData.bind(this);
	}

	toggle(tab) {
		tab = tab.toString();
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	}

	newData(data) {
		let newConfigs = { ...this.state.newConfigs, ...data };
		this.setState(
			{
				newConfigs: newConfigs,
			}
		);
		this.props.onChange(newConfigs);
	}

	renderTabsLinks() {
		if (this.props.configs && this.props.configs.tabs && this.props.data) {
			return this.props.configs.tabs.map((element, index) => {
				return (<TabLink active={this.state.activeTab === (index + 1).toString()}
					key={element.designation}
					title={element.designation}
					onSelected={() => { this.toggle(index + 1); }}
					tooltip={element.description} ></TabLink>);
			});
		}
	}

	renderConfigsTab(element) {
		return (<ConfigsTab fields={this.props.configs.fields[element.designation]} showChangesBox
			changesToUpdate={this.props.changesToUpdate} selectedVersion={this.props.selectedVersion}
			onNewData={this.newData} newData={this.state.newConfigs}
			data={this.props.data.configuration.configs}></ConfigsTab>);
	}

	renderCustomerTab(element) {
		return (
			<div>
				<ConfigsTab fields={this.props.configs.fields[element.designation]}
					changesToUpdate={this.props.changesToUpdate} selectedVersion={this.props.selectedVersion}
					onNewData={this.newData} newData={this.state.newConfigs}
					data={this.props.data.configuration.configs}></ConfigsTab>
				<ConfigsCustomerTab data={this.props.data.configuration.configs} newData={this.state.newConfigs} onNewData={this.newData}></ConfigsCustomerTab>
			</div>
		);
	}

	renderTabsContent() {
		if (this.props.configs && this.props.configs.tabs && this.props.data) {
			return this.props.configs.tabs.map((element, index) => {
				return (<TabPane key={'pane_' + element.designation} tabId={(index + 1).toString()}>
					{element.designation === "Customer" ? this.renderCustomerTab(element) : this.renderConfigsTab(element)}
				</TabPane>);
			});
		}
	}

	render() {
		return (
			<div>
				<Nav tabs>
					{this.renderTabsLinks()}
				</Nav>
				<TabContent activeTab={this.state.activeTab}>
					{this.renderTabsContent()}
				</TabContent>
			</div>
		);
	}
}

export default ConfigsZone;