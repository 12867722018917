import React from 'react';
import {
	TextField,
	Typography,
	withStyles,
} from '@material-ui/core';
import {
	RemoveCircleOutline as DeleteIcon,
} from '@material-ui/icons';
import { styles } from '../CommonStyles.styles';
import WorkOrderTemplateList from './TemplateList/WorkOrderTemplateList';
import { ReactComponent as PlusSignIcon } from '../../../../assets/PromotionApp/icons/add.svg';
import SearchList from '../../../UI/SearchList/SearchListWrapper';
import TaskSequenceList from './TaskSequenceList/TaskSequenceList';
import classnames from 'classnames';
import CommonButton from '../../../UI/Buttons/CommonButtons';
import ErrorDiv from '../../../ErrorDiv/ErrorDiv';
import ConfirmationModal from '../../../UI/ConfirmationModal/ConfirmationModal';
import { ReactComponent as CreateIcon } from '../../../../assets/PromotionApp/icons/add.svg';
import withPermission from '../../../../hoc/withPermission';

const WorkOrderConfigs = ({
	addTaskToTemplate,
	availableTaskListRef,
	cancelHandler,
	classes,
	clearFilterListener,
	createOnClickHandler,
	deleteTemplateHandler,
	editMode,
	error,
	getAvailableTasks,
	inputBuilders,
	location,
	masterEditMode,
	modal,
	moveTaskHandler,
	onChangeHandler,
	onCreateTask,
	placeholder,
	priorities,
	removeTaskHandler,
	screenSize,
	selectedTemplate,
	setEditMode,
	setModal,
	setSelectedTemplate,
	tasksSequence,
	templates,
	deleteTask,
}) => {

	const createButton = (
		<CreateButton
			className={classnames(classes.createButton)}
			onClick={createOnClickHandler}
		/>
	);

	const errorBar = !!error && (
		<ErrorDiv
			className={{ div: classes.errorDiv, text: classes.errorText }}
			message={error}
		/>
	);

	const {
		getLabelAndInput,
		getLabelAndSelect,
	} = inputBuilders;

	//LEFT BLOCK

	const templatesTitle = (
		<Typography className={classes.subtitle15px}>
			Templates
		</Typography>
	);

	const headerLeftDiv = (
		<div className={classes.horizontalFlex}>
			{templatesTitle}
			{createButton}
		</div>
	);

	const templateList = (
		<WorkOrderTemplateList
			workOrderTemplates={templates}
			selectedTemplate={selectedTemplate}
			setSelectedTemplate={setSelectedTemplate}
			deleteTemplateHandler={() => setModal('ConfirmationModal')}
			masterEditMode={masterEditMode}
			editMode={editMode}
			setEditMode={setEditMode}
			screenSize={screenSize}
			location={location}
		/>
	);

	//MIDDLE BLOCK

	const newOrEditTitle = (
		<Typography
			className={classes.subtitle15px}
		>
			{`${!selectedTemplate ? 'Create' : 'Edit'} template`}
		</Typography>
	);

	const nameInput = getLabelAndInput({editMode, fieldLabel: 'Designation', fieldString: 'type', onChangeHandler,
		placeholder, classes, endAdornment: false, inputSize: 'big', labelSize: 'subtitle14px', id: 'ww-designation-input'});

	const prioritySelectionDiv = getLabelAndSelect({
		editMode, options: priorities, fieldLabel: 'Priority',
		fieldString: 'priority', onChangeHandler, placeholder, classes,
		extraClass: 'subtitle14px',
	});

	const rowBlock1 = (
		<div className={classnames(classes.rowBlock)}>
			<div className={classnames(classes.horizontalFlex, classes.fullWidth)}>
				<div className={classnames(classes.flex50)}>
					{nameInput}
				</div>
				<div className={classnames(classes.flex50)}>
					{prioritySelectionDiv}
				</div>
			</div>
		</div>
	);

	const currentLocation = (
		<div className={classes.location}>
			<Typography className={classes.subtitle}>
				Current Location:
			</Typography>
			<Typography className={classes.subtitle}>
				{location.name}
			</Typography>
		</div>
	);

	const descriptionLabelField = (
		<Typography className={classes.subtitle14px} key={'label_Description'}>
			Description
		</Typography>
	);

	const descriptionInputField = (
		<TextField
			InputLabelProps={{ classes: { root: classes.labelProps, focused: classes.labelProps } }}
			InputProps={{ classes: { root: classes.inputPropsMultiline } }}
			className={classes.fieldMultiline}
			classes={{ root: classes.multiline }}
			disabled={!editMode}
			error={false}
			key={'input_Description'}
			margin="normal"
			multiline
			onChange={(e) => onChangeHandler('description', e.target.value)}
			required={false}
			minRows={3}
			value={placeholder['description'] || ''}
			variant={'outlined'}
		/>
	);

	const descriptionInput = (
		<>
			{descriptionLabelField}
			{descriptionInputField}
		</>
	);

	const rowBlock2 = (
		<div className={classnames(classes.verticalFlex)}>
			{descriptionInput}
		</div>
	);


	const taskSequenceTitle = (
		<Typography className={classnames(classes.subtitle15px, classes.divison)}>
			Task Sequence
		</Typography>
	);

	const taskSequenceList = (
		<TaskSequenceList
			tasksSequence={tasksSequence}
			moveTaskHandler={moveTaskHandler}
			removeTaskHandler={removeTaskHandler}
			editMode={editMode}
			screenSize={screenSize}
		/>
	);

	//RIGHT BLOCK

	const availableTasksTitle = (
		<Typography className={classes.subtitle15px}>
			Available tasks
		</Typography>
	);

	const availableTaskList = (
		<SearchList
			className={classes.availableTaskList}
			style={{
				classNameTitle: classes.subtitle14px,
				classNameFiltersRoot: classes.availableTaskListFilterRoot,
				classNameExtraButton: classes.deleteTaskButton,
			}}
			clearFilterListener={clearFilterListener}
			disabled={!editMode}
			disableScrollToTop
			getList={getAvailableTasks}
			header='Type'
			name='Type'
			onAdd={name => addTaskToTemplate({ name })}
			onExtra={deleteTask}
			onLoad
			addIcon={PlusSignIcon}
			extraIcon={DeleteIcon}
			ref={availableTaskListRef}
			searchLabel='Search tasks'
			disableAllButtons={!editMode}
		/>
	);

	const moreTitle = (
		<Typography className={classnames(classes.subtitle15px, classes.divison)}>
			More</Typography>
	);

	const customTaskCreatorTitle = (
		<Typography className={classes.subtitle14px}>
			Custom Task Creator
		</Typography>
	);

	const taskCreatorContent = getLabelAndInput({editMode, fieldLabel: 'Name', fieldString: 'customTask', onChangeHandler,
		placeholder, classes, endAdornment: false, inputSize: 'medium', labelSize: 'tinyLabel'});

	const confirmationModal = modal === 'ConfirmationModal' && (
		<ConfirmationModal
			text={
				<Typography>
					{`Your are about to delete the Template of type '${selectedTemplate?.type},
					from location '${location.name}`}
				</Typography>
			}
			confirmHandler={() => {
				deleteTemplateHandler(selectedTemplate);
				cancelHandler();
				setModal();
			}}
			confirmCustomLabel='Confirm'
			cancelHandler={() => setModal()}
		/>
	);

	const addButton = (
		<CommonButton
			buttonStyleName='filled'
			// className={classes.button}
			key='add_button'
			label='Add'
			onClick={() => {
				onCreateTask();
			}}
			disabled={!editMode}
		/>
	);

	const customTaskCreator = (
		<>
			<div className={classnames(classes.horizontalFlex, classes.alignItemsBottom)}>
				<div className={classnames(classes.horizontalFlex, classes.flexStart)}>
					{taskCreatorContent}
				</div>
				{addButton}
			</div>
		</>
	);

	const created = (
		<div
			className={classes.timestamp}
		>
			<Typography>
				{`Created by ${placeholder?.createUser || 'N/A'} at ${placeholder?.createDate || 'N/A'}`}
			</Typography>
		</div>
	);

	const lastEdited = (
		<div
			className={classes.timestamp}
		>
			<Typography>
				{`Last edited at ${placeholder?.updateDate || 'N/A'} by user ${placeholder?.updateUser || 'N/A'}`}
			</Typography>
		</div>
	);

	return (
		<div className={classes.workOrdersRoot}>
			{errorBar}
			{confirmationModal}
			<div className={classes.workOrderConfigsLeftBlock}>
				{headerLeftDiv}
				{currentLocation}
				{templateList}
			</div>
			<div className={classes.workOrderConfigsMiddleBlock}>
				{newOrEditTitle}
				{rowBlock1}
				{rowBlock2}
				{taskSequenceTitle}
				{taskSequenceList}
				<div className={classes.verticalFlex}>
					{created}
					{lastEdited}
				</div>
			</div>
			<div className={classes.workOrderConfigsRightBlock}>
				{availableTasksTitle}
				{availableTaskList}
				{moreTitle}
				{customTaskCreatorTitle}
				{customTaskCreator}
			</div>
		</div>
	);
};

const CreateButton = withPermission(
	[{ resource: ['carclub', 'configurations', 'create'] }],
)(props => <CreateIcon {...props} />);

export default withStyles(styles)(WorkOrderConfigs);