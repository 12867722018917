import React, { useState, useCallback } from "react";
import { currencyFormatter } from "../../../services/formatter";
import modal from "../../../model/Incident/modal";
import IncidentsDetails from "./IncidentsDetails";

const detailsToShow = (symbol) => [
  { label: "Reservation Code", key: "reservationCode" },
  { label: "License Plate", key: "licensePlate" },
  { label: "Customer", key: "customerCode" },
  { label: "Description", key: "description", column: true },
  { label: "Cost", key: "incidentCost", symbol },
  { label: "Liability", key: "liabilityPercentage", symbol: "%" },
  { label: "Administrative cost", key: "administrativeCost", symbol },
  { label: "Paid", key: "paid", icon: true },
];

const IncidentsDetailsWrapper = ({ incident, onCharge, onClose, onInvoice, onSettle, ...props }) => {
  const [hasModal, setModal] = useState();
  const [hasRequest, setRequest] = useState(false);

  const currentModal = useCallback(
    (currentModal) => {
      const title = {
        [modal.CLOSE]: "Close incident ",
        [modal.CHARGE]: "Charge incident ",
        [modal.SETTLE]: "Settle as paid incident ",
        [modal.INVOICE]: "Generate invoice for ",
      }[currentModal];

      const text = {
        [modal.CLOSE]: "Are you sure you want to close this incident?",
        [modal.CHARGE]: "This action will charge your customer. Are you sure you want to confirm?",
        [modal.SETTLE]: "This action will settle this incident as paid, but will not charge your customer. Confirm?",
        [modal.INVOICE]: "This action will generate an invoice for the current incident. Confirm? ",
      }[currentModal];

      const handler = {
        [modal.CLOSE]: onClose,
        [modal.CHARGE]: onCharge,
        [modal.SETTLE]: onSettle,
        [modal.INVOICE]: onInvoice,
      }[currentModal];

      return {
        title,
        text,
        confirmHandler: () => {
          handler(incident, setRequest);
          setModal();
        },
      };
    },
    [incident, onClose, onCharge, onSettle, onInvoice]
  );

  return (
    <IncidentsDetails
      {...props}
      currentModal={currentModal(hasModal)}
      detailsToShow={detailsToShow(currencyFormatter.symbol())}
      hasModal={hasModal}
      hasRequest={hasRequest}
      incident={incident}
      setModal={setModal}
    />
  );
};

export default IncidentsDetailsWrapper;
