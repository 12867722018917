const styles = () => ({
  root: {
    width: "100%",
    paddingRight: 0,
    height: 50,
    zIndex: 10,
  },

  grow: {
    flexGrow: 1,
  },

  avatar: {
    backgroundColor: "rgba(0,0,0,0)",
  },

  menuButton: {
    backgroundColor: "inherit",
    minWidth: 110,
    fontSize: 10,
    "-webkit-transition": "all 0.3s",
    "-webkit-transform-origin": "50% 15px",
    minHeight: "45px",
    padding: 0,
    cursor: "pointer",
    color: "white",
  },

  dock: {
    justifyContent: "flex-start",
    overflowX: "hidden",
    overflowY: "hidden",
    display: "flex",
    flexDirection: "row",
  },

  siblingZoom: {
    minWidth: 110,
    fontSize: 10,
    "-webkit-transition": "all 0.3s",
    "-webkit-transform-origin": "50% 15px",
    "-webkit-transform": "scale(1.15)",
    zIndex: 2,
  },

  arrow: {
    width: "20px",
    height: "20px",
    margin: "0px 10px",
    fill: "white",
    cursor: "pointer",
  },

  "@media screen and (max-width: 1250px)": {
    menuButton: {
      minWidth: 105,
      maxWidth: 105,
      fontSize: 10,
    },
  },

  "@media screen and (max-width: 1180px)": {
    menuButton: {
      minWidth: 80,
      maxWidth: 80,
      fontSize: 10,
    },
  },

  logo: {
    height: "100%",
  },

  tabIcons: {
    width: "20px",
    height: "20px",
    margin: "0 !important",
    fill: "white",
  },

  location: {
    display: "flex",
    flexDirection: "column",
    minWidth: "120px",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "inherit",
  },

  locationText: {
    fontSize: "10px",
    color: "white",
  },
});

export default styles;
