import React, { useRef, useCallback } from 'react';
import { useInfinityScroll } from '../../../../hooks/useInfinityScroll/useInfinityScroll';
import List from './List';

const ListWrapper = ({
	filter,
	lastPage,
	onSearch,
	...props
}) => {
	const getMoreRows = useCallback(async () => await onSearch(filter), [onSearch, filter]);

	const listRef = useRef();
	const isFetching = useInfinityScroll(!lastPage && getMoreRows, listRef);

	return (
		<List
			{...props}
			isFetching={isFetching}
			listRef={listRef}
		/>
	);
};

export default ListWrapper;