import React from 'react';
import ListItem from '../../../../../UI/List/ListItem/ListItem';
import {
	Typography,
	Tooltip,
	withStyles,
} from '@material-ui/core';
import {
	Block as BlockIcon,
} from '@material-ui/icons';
import styles from './FeaturesItem.styles';

const FeaturesItem = ({
	feature,
	classes,
	className,
	fields,
	onSelect,
	selected,
}) => {

	if (!feature) {
		return ;
	};
	
	const type = feature.type && (
		<Typography className={classes.type} key={feature.id + '_type'}>
			{feature.type}
		</Typography>
	);

	const subtype = feature.subtype && (
		<Typography className={classes.subtype} key={feature.id + '_subtype'}>
			{feature.subtype}
		</Typography>
	);

	const blocker = feature.blocker
		?	<BlockIcon className={classes.blocker} key={feature.id + '_blocker'}/>
		: (
			<div className={classes.blocker} key={feature.id + '_blocker'}>
			</div>
		);

	const tooltip = !!feature.description ? feature.description : '';

	const currentFeature = (
		<Tooltip placement='bottom' title={tooltip}>
			<div className={classes.root} key={feature.id}>
				{blocker}
				{type}
				{subtype}
			</div>
		</Tooltip>
	);

	const item = (
		<ListItem
			className={className}
			fields={fields}
			onSelect={() => onSelect(feature.id)}
			element={{
				feature: currentFeature,
			}}
			selected={selected}
		/>
	);

	return item;
};

export default withStyles(styles)(FeaturesItem);