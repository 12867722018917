import React from 'react';
import DetailsDiv from '../../../../UI/DetailsDiv/DetailsDiv';
import DetailsRow from '../../../../UI/DetailsDiv/DetailsRow/DetailsRow';
import { currencyFormatter } from '../../../../../services/formatter';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import classnames from 'classnames';
import styles from './Details.styles';
import { dateFormatter } from '../../../../../services/formatter';

const Detail = ({
	classes,
	info,
	searchFromBonus,
}) => {

	const unusedCodesDetail = (
		<DetailsRow
			label='Available codes'
			detail={
				<Typography className={classes.typography}>
					{info.codesUnused}
				</Typography>
			}
		/>
	);

	const creatorDetail = (
		<DetailsRow
			label='Created by'
			detail={
				<Typography className={classes.typography}>
					{info.createUser}
				</Typography>
			}
		/>
	);

	const amountDetail = (
		<DetailsRow
			label='Amount'
			detail={
				<Typography className={classes.typography}>
					{currencyFormatter.format(info.amount)} {currencyFormatter.symbol()}
				</Typography>
			}
		/>
	);

	const descriptionDetail = (
		<DetailsRow
			label='Description'
			detail={
				<Typography className={classes.typography}>
					{info.description}
				</Typography>
			}
		/>
	);

	const createDateDetail = (
		<DetailsRow
			label='Create date'
			detail={
				<Typography className={classes.typography}>
					{dateFormatter.format(info?.createDate, 'date')}
				</Typography>
			}
		/>
	);

	const idDetail = (
		<DetailsRow
			label='ID'
			detail={
				<Typography
					className={classnames(classes.typography, classes.link)}
					onClick={() => info?.id && searchFromBonus(info.id)}
				>
					{info?.id}
				</Typography>
			}
		/>
	);

	return (
		<DetailsDiv title='Details'>
			{amountDetail}
			{unusedCodesDetail}
			{creatorDetail}
			{descriptionDetail}
			{createDateDetail}
			{idDetail}
		</DetailsDiv>
	);

};

export default withStyles(styles)(Detail);