import React from 'react';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import GoBackIcon from '@material-ui/icons/KeyboardBackspace';
import TransactionList from './TransactionList/TransactionList';
import styles from './Transactions.styles';
import classnames from 'classnames';

const Transactions = ({
	classes,
	className,
	transactionsModal,
	billingItemAccount,
	onCloseTransactions,
	currentScreenSize,
	payments,
	getMoreRows,
	onSelect,
	onSelectAll,
	onUnselect,
	selected,
	description,
	setDescription,
	descriptionModal,
	onShowSettleDescription,
	closeSettleDescriptionModal,
}) => {

	const goBackIcon = (
		<GoBackIcon
			className={classes.goBackIcon}
			onClick={onCloseTransactions}
		/>
	);

	const title = (
		<div className={classes.title}>
			<Typography className={classes.mainTitle}>
				Account Billing Item Details
			</Typography>
			<Typography className={classes.subTitle}>
				{billingItemAccount?.code}
			</Typography>
		</div>
	);

	const icons = (
		<div className={classes.icons}>
		</div>
	);

	const header = (
		<div className={classes.header}>
			{title}
			{icons}
		</div>
	);

	const descriptionText = (
		<>
			<Typography className={classes.subTitle}>
				Manual settle description:
			</Typography>
			<Typography className={classes.subTitle2}>
				{description}
			</Typography>
		</>
	);

	const descriptionModalDiv = (
		<Modal
			onClose={() => {
				closeSettleDescriptionModal();
			}}
			open={!!descriptionModal}
		>
			<div className={classes.descriptionModalRoot}>
				<div className={classes.descriptionModalWorkspace}>
					{descriptionText}
				</div>

			</div>
		</Modal>
	);

	const list = payments && (
		<TransactionList
			className={classes.list}
			currentScreenSize={currentScreenSize}
			payments={payments}
			getMoreRows={getMoreRows}
			onSelect={onSelect}
			onSelectAll={onSelectAll}
			onUnselect={onUnselect}
			selected={selected}
			onShowSettleDescription={onShowSettleDescription}
			description={description}
			setDescription={setDescription}
		/>
	);

	return (
		<div
			className={classnames(classes.root, className)}
			tabIndex='0'
			onKeyDown={e => e.key === 'ArrowLeft' && onCloseTransactions()}

		>
			{descriptionModalDiv}
			{goBackIcon}
			{header}
			{list}
		</div >
	);
};

export default withStyles(styles)(Transactions);