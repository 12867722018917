import {
	APP_SET_WINDOW_WIDTH,
} from '../../actions/app/actionTypes';
import * as mutate from './mutators';
import { pipe } from '../utils';
import createReducer from '../../utils/createReducer';
import { Map } from 'immutable';

const initialState = Map({
	windowWidth: null,
});

const appSetWindowWidth = (state, action) => {

	return pipe([
		mutate.setWindowWidth(action.windowWidth),
	], state);
};

const appReducer = createReducer(initialState, {
	[APP_SET_WINDOW_WIDTH] : appSetWindowWidth,
});

export default appReducer;