import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import usePlaceholderPP from "./usePlaceholderPP";
import usePlaceholderRate from "./usePlaceholderRate";
import usePlaceholderMaxFlatRate from "./usePlaceholderMaxFlatRate";
import useSwitch from "../../hooks/useSwitch";
import PricingPlanApp from "./PricingPlanApp";
import pricingPlanServiceV2 from "../../services/pricingPlanV2";
import log from "../../utils/logger";
import { selectDefaultLocation } from "../../features/carclub/carclubSlice";
import { useDispatch } from "react-redux";
import { getZones, selectZones } from "../../features/asset/assetSlice";

const logger = log("PricingPlanV2Wrapper");

const PricingPlanWrapper = (props) => {
  const dispatch = useDispatch();
  const zones = useSelector(selectZones);
  //DEFAULT LOCATION
  const defaultLocation = useSelector(selectDefaultLocation);

  //STATE (Pricings Plans, Rates, Extras)
  const [pricingPlans, setPricingPlans] = useState([]);
  const [showPPCalendar, setShowPPCalendar] = useState(false);
  const [ratesList, setRatesList] = useState([]);
  const [extrasList, setExtrasList] = useState([]);

  //SELECTED ELEMENTS
  const [selectedPP, setSelectedPP] = useState();
  const [selectedPPId, setSelectedPPId] = useState();
  const [selectedRateToApply, setSelectedRateToApply] = useState();

  //ERROR HANDLING
  const [error, setError] = useState();

  //MODES
  const createMode = useSwitch(false);
  const editMode = useSwitch(false);

  //VIEW CONTROLLERS
  const [selectedSubApp, setSelectedSubApp] = useState("pricingPlans");
  const [modal, setModal] = useState();

  //PLACEHOLDERS (Pricing Plans, Rates, Max Flat Rates)
  const placeholderPP = usePlaceholderPP();
  const placeholderRate = usePlaceholderRate();
  const placeholderMaxFlatRate = usePlaceholderMaxFlatRate();
  const [placeholderExtra, setPlaceholderExtra] = useState({});

  //MOUNTED
  const isMounted = useRef(true);
  const groupsListRef = useRef(true);

  //PAGINATION
  const [page, setPage] = useState(1);
  const [lastPageMaster, setLastPageMaster] = useState(true);

  const [lastPageAddFee, setLastPageAddFee] = useState(true);
  const [pageAdditionalFee, setPageAdditionalFee] = useState(1);

  const getMoreRows = async () => {
    if (!lastPageMaster) {
      const { pricings, lastPage } = await pricingPlanServiceV2.listPricingPlans({ page });
      setLastPageMaster(lastPage);
      setPage((prev) => prev + 1);
      setPricingPlans((prev) => [...prev, ...pricings]);
    }
  };

  const getMoreRowsAdditionalFees = useCallback(async () => {
    if (!lastPageAddFee) {
      const { list, lastPage } = await pricingPlanServiceV2.listAdditionalFees({ page: pageAdditionalFee });
      setLastPageAddFee(lastPage);
      setPageAdditionalFee((prev) => prev + 1);
      setExtrasList((prev) => [...prev, ...list]);
    }
  }, [pageAdditionalFee, lastPageAddFee]);

  const asyncGetPricingsOnSubAppChange = useCallback(async () => {
    try {
      const listPricingsPromise = pricingPlanServiceV2.listPricingPlans({ page: 1 });
      const listPricingsResult = await listPricingsPromise;
      const { pricings, lastPage } = listPricingsResult;
      setLastPageMaster(lastPage);
      setPage(2);
      if (isMounted.current) {
        Array.isArray(pricings) && setPricingPlans(pricings);
      }
    } catch (error) {
      logger.info("Unable to get pricing plans");
    }
  }, []);

  useEffect(() => {
    if (selectedSubApp !== "pricingPlans") return;
    asyncGetPricingsOnSubAppChange();
    // eslint-disable-next-line
  }, [selectedSubApp, defaultLocation]);

  const asyncRequest = useCallback(
    async (overridePage) => {
      try {
        const listPricingsPromise = pricingPlanServiceV2.listPricingPlans({ page: overridePage || page });
        const listRatesPromise = pricingPlanServiceV2.listRates({});

        const listPricingsResult = await listPricingsPromise;
        const listRatesResult = await listRatesPromise;

        const { pricings, lastPage } = listPricingsResult;
        setLastPageMaster(lastPage);
        if (!!overridePage) {
          setPage(overridePage + 1);
        } else {
          setPage((prev) => prev + 1);
        }

        if (isMounted.current) {
          Array.isArray(pricings) && setPricingPlans(pricings);
          Array.isArray(listRatesResult) && setRatesList(listRatesResult);
        }
      } catch (error) {
        logger.info(error.message || error);
      }
    },
    [page]
  );

  const asyncGetPPDetails = async (id) => {
    if (!id) {
      return;
    }

    try {
      const { pricing, customerCount, zoneCount, groupCount } = await pricingPlanServiceV2.getPricingPlan(id);

      if (isMounted.current) {
        setSelectedPP({ ...pricing, customerCount, zoneCount, groupCount });
        placeholderPP.setPlaceholder({ ...pricing, customerCount, zoneCount, groupCount });
      }
    } catch (error) {
      logger.info(error.message || error);
    }
  };

  useEffect(() => {
    const cleanup = () => {
      isMounted.current = false;
    };

    return cleanup;
    //eslint-disable-next-line
  }, []);

  const {
    resetPlaceholder,
    setCategory,
    setCode,
    setCumulative,
    setDescription,
    setEndDate,
    setEndZone,
    setMaxPrices,
    setName,
    setRates,
    setSpecific,
    setStartDate,
    setStartingFee,
    setStartZone,
  } = placeholderPP;

  const placeholderPPSetters = {
    resetPlaceholder,
    setCategory,
    setCode,
    setCumulative,
    setDescription,
    setEndDate,
    setEndZone,
    setMaxPrices,
    setName,
    setRates,
    setSpecific,
    setStartDate,
    setStartingFee,
    setStartZone,
  };

  const {
    resetPlaceholder: resetPlaceholderRate,
    setCarclubId,
    setColor,
    setGoldPinInUse,
    setGoldPinInStandby,
    setId,
    setName: setNameRate,
    setPriceInKm,
    setPriceInStandby,
    setPriceInUse,
    setRateNumber,
  } = placeholderRate;

  const placeholderRateSetters = {
    resetPlaceholderRate,
    setCarclubId,
    setColor,
    setGoldPinInUse,
    setGoldPinInStandby,
    setId,
    setNameRate,
    setPriceInKm,
    setPriceInStandby,
    setPriceInUse,
    setRateNumber,
  };

  const placeholderExtraOnChangeHandler = useCallback((key, value) => {
    const regex = typeRestrictionsAdditionalFee[key];
    if (!regex) {
      setPlaceholderExtra((prev) => ({ ...prev, [key]: value }));
      return;
    }
    const passedTest = regex.test(value);
    if (!!passedTest) {
      if (value === ".") {
        setPlaceholderExtra((prev) => ({ ...prev, [key]: "0.0" }));
        return;
      }
      setPlaceholderExtra((prev) => ({ ...prev, [key]: value }));
    }
  }, []);

  const selectPPHandler = async (pricingPlan, overrideUnselect = false) => {
    if (typeof pricingPlan === "undefined" || !pricingPlan) {
      return;
    }
    if (typeof selectedPP === "undefined" || !selectedPP) {
      setSelectedPP(pricingPlan);
      setSelectedPPId(pricingPlan?.id);
      return;
    }
    if (!overrideUnselect && selectedPP.id === pricingPlan.id) {
      setSelectedPP(null);
      return;
    }
    setSelectedPP(pricingPlan);
    setSelectedPPId(pricingPlan?.id);
  };

  const searchPPHandler = async (filter) => {
    try {
      const { pricings } = await pricingPlanServiceV2.listPricingPlans({ filter });
      setPricingPlans(pricings);
      setPage(1);
    } catch (error) {
      logger.info(error.message || error);
    }
  };

  const upsertPPHandler = async (rates) => {
    try {
      setError(false);

      const pricingDetails = {
        rates: rates || placeholderPP.state.rates,
        maxPrices: [...placeholderMaxFlatRate.state.addedRates],
      };

      //Retrieve PP's pricing details if already exists
      if (typeof placeholderPP.state.id !== "undefined" && !rates) {
        const pricingPlanExtraInfo = await pricingPlanServiceV2.getPricingPlan(placeholderPP.state.id);

        const pricingPlan = pricingPlanExtraInfo.pricing;

        pricingDetails.maxPrices = !!pricingPlan && pricingPlan.pricingDetails.maxPrices;
        pricingDetails.rates = !!pricingPlan && pricingPlan.pricingDetails && pricingPlan.pricingDetails.rates;
      }

      const pricingPlanToUpsert = { ...placeholderPP.state, ...pricingDetails };

      const result = await pricingPlanServiceV2.createPricingPlan(pricingPlanToUpsert);

      setSelectedPP({
        ...selectedPP,
        ...pricingPlanToUpsert,
        zoneCount: (pricingPlanToUpsert?.startZonesFee?.length || 0) + (pricingPlanToUpsert?.endZonesFee?.length || 0),
      });
      setShowPPCalendar(false);

      placeholderMaxFlatRate.resetPlaceholder();

      if (result && result.id) {
        setTimeout(asyncGetPricingsOnSubAppChange(), 2000);
      }
    } catch (error) {
      setError(errorCodeMessageMapPricings[error?.code] || error?.message);
      logger.warn(error?.message || "Unable to create or update pricing plan.");
    }
  };

  const upsertRateHandler = async (ev) => {
    try {
      ev.preventDefault();
      setError(false);

      const checkResult = checkPlaceholderRequiredValues({ ...placeholderRate?.state }, CHECK_TYPE.RATE);

      if (!!checkResult) {
        setError(`The "${[checkResult]}" field is required.`);
        return;
      }

      const result = await pricingPlanServiceV2.createRate(placeholderRate.state);
      if (!!result && !!result.id) {
        resetPlaceholderRate();
        editMode.setOff();
        createMode.setOff();
        setTimeout(asyncRequest(), 2000);
      }
    } catch (error) {
      setError(errorCodeMessageMapRates[error?.code] || "Something went wrong while creating/editing rate.");
      logger.warn(error?.message);
    }
  };

  const upsertExtraHandler = async () => {
    setError();
    try {
      const result = await pricingPlanServiceV2.upsertAdditionalFee({ ...placeholderExtra });
      if (!!result && !!result.id) {
        editMode.setOff();
        createMode.setOff();
        setTimeout(asyncGetExtras(), 2000);
      }
    } catch (error) {
      setError(errorCodeMessageMapRates[error?.code] || error?.message);
      logger.warn(error?.message);
    }
  };

  const checkPlaceholderRequiredValues = (placeholder, checkType) => {
    try {
      const checkListArray = REQUIRED_FIELDS[checkType] || [];
      const result = checkListArray.find((e) => {
        if (typeof placeholder[e] === "string" && placeholder[e].split("").length === 0) {
          return true;
        }
        return typeof placeholder[e] === "undefined";
      });

      return !!result && REQUIRED_FIELDS_TEXT[result];
    } catch (error) {
      logger.info(error?.description || error?.message || error);
    }
  };

  const onChangeHandlerGroupPh = useCallback(
    async (action, account) => {
      const { key } = account;

      try {
        if (action === "add") {
          await pricingPlanServiceV2.accountAdd({ pricingId: selectedPPId, accountId: key });
          groupsListRef.current.func((prev) => [
            { ...prev.find((field) => key === field.key), associated: true },
            ...prev.filter((field) => key !== field.key),
          ]);
          return;
        }

        await pricingPlanServiceV2.accountRemove({ pricingId: selectedPPId, accountId: account.key });
        groupsListRef.current.func((prev) => [
          ...prev.filter((field) => key !== field.key),
          { ...prev.find((field) => key === field.key), associated: false },
        ]);
      } catch (error) {
        logger.warn("Unable to add or remove group.");
      }
    },
    [selectedPPId]
  );

  const asyncListAccounts = useCallback(
    async (filter = {}) => {
      try {
        const response = await (!filter?.string
          ? pricingPlanServiceV2.listGroupsForPP({
              pricingPlanId: selectedPPId,
              ...filter,
            })
          : pricingPlanServiceV2.searchPPAccounts({
              pricingPlanId: selectedPPId,
              ...filter,
              filter: filter.string,
            }));

        const fields = [...response?.accounts].map((account) => ({
          key: account?.id,
          label: account?.code,
          name: account?.name,
          associated: account?.associated || !filter?.string,
        }));

        return { fields, lastPage: response.lastPage };
      } catch (error) {
        logger.warn("Unable to retrieve accounts.");
        return [];
      }
    },
    [selectedPPId]
  );

  const asyncGetExtras = useCallback(async (filter) => {
    try {
      const { list, lastPage } = await pricingPlanServiceV2.listAdditionalFees({ filter, page: 1 });
      setPageAdditionalFee(2);
      setLastPageAddFee(lastPage);
      setExtrasList(list);
    } catch (error) {
      logger.warn("Unable to retrieve extras.");
      setExtrasList([]);
    }
  }, []);

  const onSelectExtraHandler = (extra) => {
    try {
      setPlaceholderExtra({});
      Object.keys(extra || {}).forEach((key) => placeholderExtraOnChangeHandler([key], extra[key]));
    } catch (error) {
      logger.warn("Unable to select extra.");
    }
  };

  return (
    <PricingPlanApp
      {...props}
      getZones={() => dispatch(getZones())}
      zones={zones}
      asyncGetExtras={asyncGetExtras}
      asyncGetPPDetails={asyncGetPPDetails}
      asyncListAccounts={asyncListAccounts}
      checkPlaceholderRequiredValues={checkPlaceholderRequiredValues}
      createMode={createMode}
      defaultLocation={defaultLocation}
      editMode={editMode}
      error={error}
      extrasList={extrasList}
      getMoreRows={getMoreRows}
      getMoreRowsAdditionalFees={getMoreRowsAdditionalFees}
      groupsListRef={groupsListRef}
      listPP={asyncRequest}
      modal={modal}
      onChangeHandlerGroupPh={onChangeHandlerGroupPh}
      onSelectExtraHandler={onSelectExtraHandler}
      placeholderExtra={placeholderExtra}
      placeholderExtraOnChangeHandler={placeholderExtraOnChangeHandler}
      placeholderMaxFlatRate={placeholderMaxFlatRate}
      placeholderPP={placeholderPP.state}
      placeholderPPSetters={placeholderPPSetters}
      placeholderRate={placeholderRate.state}
      placeholderRateSetters={placeholderRateSetters}
      pricingPlanArray={pricingPlans}
      ratesList={ratesList}
      resetPlaceholderPP={placeholderPP.resetPlaceholder}
      resetPlaceholderRate={placeholderRate.resetPlaceholder}
      searchPPHandler={searchPPHandler}
      selectedPP={selectedPP}
      selectedRateToApply={selectedRateToApply}
      selectedSubApp={selectedSubApp}
      setError={setError}
      setModal={setModal}
      setPlaceholderExtra={setPlaceholderExtra}
      setRatesList={setRatesList}
      setSelectedPP={selectPPHandler}
      setSelectedPPOverride={setSelectedPP}
      setSelectedRateToApply={setSelectedRateToApply}
      setSelectedSubApp={setSelectedSubApp}
      setShowPPCalendar={setShowPPCalendar}
      showPPCalendar={showPPCalendar}
      upsertExtraHandler={upsertExtraHandler}
      upsertPPHandler={upsertPPHandler}
      upsertRateHandler={upsertRateHandler}
    />
  );
};

const numericFloatRegex = /^([0-9]*[.]{1})?[0-9]*$/;

const typeRestrictionsAdditionalFee = {
  value: numericFloatRegex,
};

export const MODALS = {
  CUSTOMER_EDIT_MODAL: "CUSTOMER_EDIT_MODAL",
  ZONE_EDIT_MODAL: "ZONE_EDIT_MODAL",
};

export const VIEW_MODE = {
  CALENDAR: "CALENDAR",
  RATE_EDITOR: "RATE_EDITOR",
  PP_EDITOR: "PP_EDITOR",
  EXTRA_EDITOR: "EXTRA_EDITOR",
  CONFIRMATION: "CONFIRMATION",
};

export const CHECK_TYPE = {
  PP: "PP",
  RATE: "RATE",
  EXTRA: "EXTRA",
  MAX_FLAT_RATE: "MAX_FLAT_RATE",
};

export const REQUIRED_FIELDS = {
  PP: ["name", "startingFee", "startDate", "endDate", "category"],
  RATE: ["name", "rateNumber", "priceInUse", "priceInStandby", "priceKm", "goldPinInUse", "goldPinInStandby"],
  EXTRA: ["name", "description", "value"],
  MAX_FLAT_RATE: ["name", "maxTime", "maxPrice"],
};

export const REQUIRED_FIELDS_TEXT = {
  name: "Name",
  startingFee: "Starting Fee",
  startDate: "Start Date",
  endDate: "End Date",
  category: "Category",
  rateNumber: "Rate Number",
  priceInUse: "Price In Use",
  priceInStandby: "Price In Standby",
  priceKm: "Price Km",
  maxTime: "Max Time",
  maxPrice: "Max Price",
  description: "Description",
  value: "Value",
};

const errorCodeMessageMapRates = {
  "001": "There are missing parameters.",
  "002": "There was an unexpected error while creating or updating rate.",
  "007": "A rate with that number already exists.",
};

const errorCodeMessageMapPricings = {
  "004": "Invalid rate calendar. All time slots must be occupied.",
  "001": "No parameters.",
  "002": "Invalid parameters.",
  "003": "Insufficient parameters.",
  "000": "Unexpected error while creating or updating pricing.",
};

export default PricingPlanWrapper;
