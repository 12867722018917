import React, {
	useEffect,
	useState,
	useCallback,
} from 'react';
import tripService from '../../../../services/trip/index';
import withPermission from '../../../../hoc/withPermission';
import TripInfo from './TripInfo';

import log from '../../../../utils/logger';

const logger = log('TripInfoWrapper');

const TripInfoWrapper = props => {

	const [tripInfo, setTripInfo] = useState({});
	const [errorMessage, setErrorMessage] = useState(false);
	const [showAlert, setShowAlert] = useState(false);

	const {
		trip,
		tripType,
	} = props || {};

	const setError = useCallback(e => {
		setErrorMessage(e);
		setShowAlert(true);
	}, []);

	useEffect(() => {
		setTripInfo(prev => ({...prev, ...trip}));
	}, [trip]);

	useEffect(() => {
		const getTripInfo = async (code, tripType) => {

			setErrorMessage(false);

			if (typeof code === 'undefined') {
				setTripInfo({});
				return;
			};

			try {
				const tripDetails = await tripService.getTripDetails(code, tripType);
				setTripInfo(tripDetails || {});

			} catch (error) {
				setTripInfo({});
				setErrorMessage(`Unable to retrieve ${trip.code || '(code missing)'} trip information from server.`);
				setShowAlert(true);
				logger.info(error.message || error);
			};
		};

		getTripInfo(trip.code, tripType);

	}, [tripType, trip.code]);


	return (
		<TripInfo
			{...props}
			clearErrorMessage={() => {
				setErrorMessage(false);
				setTimeout(() => {
					setShowAlert(false);
				}, 2000);
			}}
			errorMessage={errorMessage}
			showAlert={showAlert}
			trip={trip}
			setError={setError}
			tripInfo={tripInfo}
		/>
	);
};

export default withPermission(
	[{ resource: ['reservations', 'immediate', 'read'] }],
)(TripInfoWrapper);