import React from 'react';
import Detail from './Detail';
import DetailsDiv from '../../../UI/DetailsDiv/DetailsDiv';
import DetailsRow from '../../../UI/DetailsDiv/DetailsRow/DetailsRow';
import {
	withStyles,
} from '@material-ui/core';
import { dateFormatter } from '../../../../services/formatter';
import styles from './Details.styles';

const PersonalInfo = ({
	classes,
	info,
}) => {

	const personalInfo = (
		<DetailsDiv title='Personal Info'>
			{!!info.name && <DetailsRow
				label='First name'
				detail={
					<Detail detail={info.name} />
				}
			/>}
			{!!info.surname && <DetailsRow
				label='Surname'
				detail={
					<Detail detail={info.surname} />
				}
			/>}
			{!!info.birthdate && <DetailsRow
				label='Birthdate'
				detail={
					<Detail detail={dateFormatter.format(info.birthdate, 'date')} />
				}
			/>}
			{!!info.phoneNumber && <DetailsRow
				label='Mobile number'
				detail={
					<Detail detail={info.phoneNumber} />
				}
			/>}
			{!!info.email && <DetailsRow
				label='Email'
				detail={
					<Detail detail={info.email} />
				}
			/>}
		</DetailsDiv>
	);

	return personalInfo;
};

export default withStyles(styles)(PersonalInfo);