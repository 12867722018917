import { connect } from 'react-redux';
import toJS from '../../hoc/toJS';
import * as customerActions from '../../store/actions/customer/action';
import CustomerApp from './CustomerAppWrapper';

const mapStateToProps = (state) => {
	return {
		tawtheeq: state.getIn(['carclub', 'tawtheeq']),
		currentScreenSize: state.getIn(['app', 'windowWidth']),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleCustomer: data => dispatch(customerActions.toggleCustomer(data)),
		updateCustomer: data => dispatch(customerActions.updateCustomer(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(CustomerApp));
