import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const zonesCreateCall = async (dispatch, state, { code, description, polygon, type }) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;

  let rh = requestHelper(dispatch, "ZONES/CREATE");
  let result;
  try {
    const path = polygon.getPath();
    path.push(polygon.getPath().getAt(0));
    const points = path.getLength();
    let geo_information = [];
    for (let i = 0; i < points; i++) {
      geo_information = [...geo_information, [path.getAt(i).lat(), path.getAt(i).lng()]];
    }

    result = await wsCall({
      type: "ZONES",
      subType: "CREATE",
      locationId: selectedLocation?.id,
      message: {
        zones: [
          {
            code,
            description,
            geo_information,
            type,
          },
        ],
      },
    });

    if (!result.error) {
      dispatch(setSuccess(code ? "Zone updated successfully!" : "Zone created successfully!"));

      // refresh zoness
    } else {
      rh.error(result?.description || "Error saving zones.");
    }
  } catch (_ex) {
    rh.error("Error saving zones.");
  }
  rh.close();
  return result;
};

export default zonesCreateCall;
