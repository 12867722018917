import { mapperPricingPlanDetailFlows } from './PricingPlanDetail';

function PricingPlan({
	description,
	category,
	cumulative,
	id,
	name,
	startFee,
	pricingDetails,
	specific,
}) {
	if (typeof id !== 'undefined') {
		this.id = id;
	};

	if (typeof name !== 'undefined') {
		this.name = name;
	};

	if (typeof category !== 'undefined') {
		this.category = category;
	};

	if (typeof cumulative !== 'undefined') {
		this.cumulative = cumulative;
	};

	if (typeof description !== 'undefined') {
		this.description = description;
	};

	if (typeof startFee !== 'undefined') {
		this.startFee = startFee;
	};

	if (typeof pricingDetails !== 'undefined') {
		this.details = pricingDetails;
	};

	if (typeof specific !== 'undefined') {
		this.specific = specific;
	};
};

PricingPlan.prototype.clone = function () {
	const clone = [];

	if (typeof this.description !== 'undefined') {
		clone.description = this.description;
	}
	if (typeof this.category !== 'undefined') {
		clone.category = this.category;
	}
	if (typeof this.cumulative !== 'undefined') {
		clone.cumulative = this.cumulative;
	}
	if (typeof this.id !== 'undefined') {
		clone.id = this.id;
	}
	if (typeof this.name !== 'undefined') {
		clone.name = this.name;
	}
	if (typeof this.startFee !== 'undefined') {
		clone.startFee = this.startFee;
	}

	if (typeof this.details !== 'undefined') {
		clone.pricingDetails = [];

		Array.isArray(this.details)
			&& this.details.forEach(detail => {
				const miniClone = {...detail};
				clone.pricingDetails.push(miniClone);
			});
	}
	return new PricingPlan(clone);
};

export const mapperPricingPlanFlows = ({
	description,
	details,
	category,
	cumulative,
	id,
	name,
	startFee,
	pricingDetails,
	specific,
}) => new PricingPlan({
	description,
	category,
	cumulative,
	specific,
	id,
	name,
	startFee,
	pricingDetails: !!pricingDetails && pricingDetails.map(detail => mapperPricingPlanDetailFlows(detail)),
});

export default PricingPlan;