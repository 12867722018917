import React from "react";
import { Divider, InputBase, Typography, withStyles } from "@material-ui/core";
import classnames from "classnames";
import Button from "../../../../../components/UI/Buttons/CommonButtons";
import List from "../../../../../components/UI/SearchList/SearchListWrapper";
import styles from "./Create.styles";

const title = {
  CREATE: "Select a nickname",
  CREATE_GROUP: "Select group name",
  GROUP_UPDATE: "Make administrator",
  INVITE_GROUP: "Add to selected group",
};

const isDisabled = {
  GROUP_UPDATE: true,
};

const contactDisabled = {
  CREATE_GROUP: true,
};

const CreateChatModal = ({
  changeSide,
  classes,
  description,
  error,
  getContacts,
  hasRequest,
  name,
  nickname,
  onChange,
  onClose,
  onSave,
  open,
  placeholder,
  setDescription,
  setName,
  setNickname,
  type,
}) => {
  const errorBar = "string" === typeof error && (
    <div className={classes.errorBar}>
      <Typography className={classes.errorText} align="center">
        {error}
      </Typography>
    </div>
  );

  const selectTitle = <Typography className={classes.title}>{title[type]}</Typography>;

  const text = (
    <InputBase
      className={classes.text}
      disabled={isDisabled[type] || false}
      onChange={(event) => setName(event.target.value)}
      placeholder="Choose a name"
      value={name || ""}
    />
  );

  const descriptionField = "CREATE_GROUP" === type && (
    <InputBase
      className={classes.text}
      onChange={(event) => setDescription(event.target.value)}
      placeholder="Add a description"
      value={description || ""}
    />
  );

  const adminNickname = "CREATE_GROUP" === type && (
    <InputBase
      className={classes.text}
      onChange={(event) => setNickname(event.target.value)}
      placeholder="Choose your nickname"
      value={nickname || ""}
    />
  );

  const contactSearch = "CREATE_GROUP" !== type && (
    <List
      className={classes.searchList}
      currentFields={placeholder}
      disabled={hasRequest || contactDisabled[type] || false}
      getList={getContacts}
      header={["Name", "Phone"]}
      name="Contacts"
      onAdd={(key, label, phone) => onChange({ key, label, phone })}
      onRemove={(key) => onChange({ key })}
      searchLabel="Search"
    />
  );

  const createButton = (
    <Button
      disabled={(!placeholder?.[0]?.key && !name) || hasRequest}
      key="confirm_button"
      label="Confirm"
      onClick={onSave}
    />
  );

  const cancelButton = <Button buttonStyleName="void" key="cancel_button" label="Cancel" onClick={() => onClose()} />;

  const buttons = (
    <div className={classes.buttons}>
      {cancelButton}
      {createButton}
    </div>
  );

  return (
    <div className={classnames(classes.root, !open && classes.hidden, changeSide && classes.left)}>
      {errorBar}
      {selectTitle}
      {text}
      {descriptionField}
      {adminNickname}
      <Divider />
      {contactSearch}
      {buttons}
    </div>
  );
};

export default withStyles(styles)(CreateChatModal);
