export const styles = theme => ({
	root: {
		height: 38,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderBottom: '2px solid #009ac9',
		alignItems: 'center',
	},

	title: {
		fontSize: 16,
		textAlign: 'left',
		color: '#a1a1a1',
	},

	headerInfo: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
		width: 250,
	},

	status: {
		marginLeft: 10,
		fontSize: 15,
		textAlign: 'left',
		color: '#a1a1a1',
	},

	noUnderline: {
		border: 'none',
	},
});