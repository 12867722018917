import React from 'react';
import {
	Modal,
	Select,
	Typography,
	withStyles,
} from '@material-ui/core';
import classnames from 'classnames';
import Button from '../Buttons/CommonButtons';
import styles from './LocationModal.styles';

const LocationModal = ({
	classes,
	className,
	error,
	hasRequest,
	locations,
	onChange,
	onSave,
	open = true,
	placeholder,
}) => {
	const errorBar = 'string' === typeof error && (
		<div className={classes.errorBar}>
			<Typography className={classes.errorText} align='center'>
				{error}
			</Typography>
		</div>
	);

	const selectTitle = (
		<Typography className={classes.title}>
			Select default location to continue
		</Typography>
	);

	const options = Array.isArray(locations) && locations.map(({ id, name }) =>
		<option value={id} key={id}>{name}</option>
	);

	const select = (
		<Select
			className={classes.select}
			native
			onChange={e => onChange(e.target.value)}
			value={placeholder}
			variant="outlined"
		>
			<option aria-label="None" value="" />
			{options}
		</Select>
	);

	const saveButton = (
		<Button
			disabled={!placeholder || hasRequest}
			key='confirm_button'
			label='Save'
			onClick={onSave}
		/>
	);

	const selection = (
		<div className={classes.selection}>
			{select}
			{saveButton}
		</div>
	);

	return (
		<Modal
			className={classes.modal}
			open={open}
		>
			<div className={classnames(classes.root, className)}>
				{errorBar}
				{selectTitle}
				{selection}
			</div>
		</Modal>
	);
};

export default withStyles(styles)(LocationModal);