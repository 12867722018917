import React from 'react';
import Button from '../../../UI/Buttons/CommonButtons';
import { ReactComponent as ParkIcon } from '../../../../assets/Zones/parkZone.svg';
import {
	Info,
} from '@material-ui/icons';
import {
	Tooltip,
	Divider,
	Typography,
	withStyles,
} from '@material-ui/core';
import withPermission from '../../../../hoc/withPermission';
import { dateFormatter } from '../../../../services/formatter';
import classnames from 'classnames';
import styles from './ParksItem.styles';

const ParksItem = ({
	associatedAssets,
	classes,
	className,
	deletePark,
	editPark,
	getAssociatedAssets,
	isDrawing,
	isSelected,
	name,
	park,
	redrawPark,
	selectPark,
	setSelectedParkCode,
	stopRedrawing,
}) => {

	const {
		code,
		createDate,
		updateDate,
		updateUser,
	} = park || {};

	const editDate = updateDate || createDate;

	const lastEditDate = editDate
		? dateFormatter.format(editDate, 'dateTime')
		: 'N/A';

	const header = (
		<div className={classes.header} onClick={() => !isSelected && selectPark(park)}>
			<Tooltip title={park.description}>
				<ParkIcon className={classes.icon} />
			</Tooltip>
			<Tooltip title={park.description}>
				<Typography>
					{name}
				</Typography>
			</Tooltip>
			<Typography
				align='right'
				className={classes.assignedAssets}
			>
				{`Assigned Assets: ${park.numberAssets || ''}`}
			</Typography>
			<Info
				className={classes.infoIcon}
				onClick={e => getAssociatedAssets(park.id)}
			/>
		</div>
	);

	const editButton = (
		<ButtonWithPermission
			buttonStyleName='void'
			label='Edit'
			onClick={editPark}
		/>
	);

	const redrawButton = (
		<ButtonWithPermission
			buttonStyleName='void'
			label='Redraw'
			onClick={event => {
				redrawPark();
				event.stopPropagation();
			}}
		/>
	);

	const removeButton = (
		<ButtonWithPermission
			label='Delete'
			onClick={() => {
				deletePark(code, setSelectedParkCode);
			}}
		/>
	);

	const buttons = isSelected && !isDrawing.state && (
		<div className={classes.buttons}>
			{editButton}
			{redrawButton}
			{removeButton}
		</div>
	);

	const cancelButton = (
		<Button
			buttonStyleName='void'
			label='Cancel'
			onClick={isDrawing.setOff}
		/>
	);

	const confirmButton = (
		<Button
			label='Confirm'
			onClick={stopRedrawing}
		/>
	);

	const drawingButtons = isSelected && isDrawing.state && (
		<div className={classes.buttons}>
			{cancelButton}
			{confirmButton}
		</div>
	);

	const lastEdited = isSelected && (
		<div
			className={classes.timestamp}
		>
			<Typography>
				{`Last edited at ${lastEditDate} by user ${updateUser || 'N/A'}`}
			</Typography>
		</div>
	);

	const assets = isSelected && associatedAssets.length > 0 && (
		<React.Fragment>
			<Divider style={{ margin: '10px 0' }} />
			<div className={classes.assetItem}>
				<Typography className={classes.assetItemText}>
					License plate
				</Typography>
				<Typography className={classes.assetItemText}>
					Name
				</Typography>
				<Typography className={classes.status}>
					Status
				</Typography>
			</div>
			<Divider style={{ margin: '10px 0' }} />
			<div className={classes.assetList}>
				{
					associatedAssets.map((asset, index) => (
						<div className={classes.assetItem} key={`${index}_${asset.licensePlate}`}>
							<Typography className={classes.assetItemText}>
								{asset.licensePlate}
							</Typography>
							<Typography className={classes.assetItemText}>
								{asset.assetName}
							</Typography>
							<Typography className={classes.statusText}>
								{getAssetStatus(asset)}
							</Typography>
						</div>
					))
				}
			</div>
		</React.Fragment>
	);

	return (
		<div
			className={classnames(classes.root, className, isSelected && classes.selected)}
		>
			{header}
			{buttons}
			{drawingButtons}
			{lastEdited}
			{assets}
		</div>
	);
};

const permissions = [{ resource: ['fleet', 'parks', 'edit'] }];
const ButtonWithPermission = withPermission(permissions)(
	(props) => <Button {...props} />
);

const getAssetStatus = asset => {
	if (!asset.operational) { return 'Unavailable'; };
	if (asset.inReservation) { return 'Ongoing'; };
	if (asset.assetUnused) { return 'Unused'; };
	return 'Available';
};

export default withStyles(styles)(ParksItem);