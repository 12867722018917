import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import GoogleMap from "google-map-react";

import { styles } from "./MapLite.styles";
import { useSelector } from "react-redux";
import { selectCarclubInfo } from "../../features/carclub/carclubSlice";

function MapLite({ classes, center, markers, zoom, onGoogleApiLoaded }) {
  const carclubInfo = useSelector(selectCarclubInfo);

  return (
    <div className={classes.root}>
      <GoogleMap
        onGoogleApiLoaded={onGoogleApiLoaded}
        bootstrapURLKeys={{ key: carclubInfo?.secrets?.googleMapsAPIKey }}
        defaultCenter={{ lat: 0, lng: 0 }}
        defaultZoom={11}
        center={center}
        zoom={zoom}
        yesIWantToUseGoogleMapApiInternals={true}
      >
        {markers}
      </GoogleMap>
    </div>
  );
}

export default withStyles(styles)(MapLite);
