const roleToString = role => {
	try {
		return role
			.toString()
			.split('_')
			.map(word =>
				word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase())
			.join(' ');
	}
	catch{
		return role.toString();
	};
};

export default roleToString;