import {
	CHAT_NEW_GROUP,
	CHAT_NEW_MESSAGE,
	CHAT_NEW_PENDING,
	CHAT_REMOVE_GROUP_INVITE,
	CHAT_REMOVE_PENDING,
	CHAT_UPDATE_MESSAGES,
	CHAT_REMOVE_WORKORDER,
	CHAT_ADD_WORKORDER,
} from './actionTypes';
import makeActionCreator from '../../../utils/makeActionCreator';
import * as methods from '../../../utils/makeActionCreator';

export const addSelectedWorkorder = makeActionCreator(CHAT_ADD_WORKORDER, 'workorder');
export const chatNewMessage = makeActionCreator(CHAT_NEW_MESSAGE, 'contact');
export const chatUpdateMessages = contact => ({ type: CHAT_UPDATE_MESSAGES, contact, method: methods.REPLACE });
export const newGroupInvite = makeActionCreator(CHAT_NEW_GROUP, 'group');
export const newPendingContact = makeActionCreator(CHAT_NEW_PENDING, 'contact');
export const removeGroupInvite = group => ({ type: CHAT_REMOVE_GROUP_INVITE, group, method: methods.REPLACE });
export const removePendingContact = contact => ({ type: CHAT_REMOVE_PENDING, contact, method: methods.REPLACE });
export const removeSelectedWorkorder = () => ({type: CHAT_REMOVE_WORKORDER, method: methods.REPLACE});