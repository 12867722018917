import React from "react";

class JsonToHtml extends React.Component {
  getJsonToArray(object) {
    let arr = [];
    if (object.constructor === Array) {
      object.forEach(function (item) {
        arr.push({ key: null, value: item, type: typeof item });
      });
    } else {
      Object.keys(object).forEach(function (key) {
        arr.push({ key: key, value: object[key], type: typeof object[key] });
      });
    }
    return arr;
  }

  generateKey(pre) {
    return pre + "_" + new Date().getTime() + "_" + Math.floor(Math.random() * 1000000 + 1);
  }

  renderObj(key, value, type) {
    switch (type) {
      case "string":
        return this.renderRow(key, value);
      case "boolean":
        value = value ? "true" : "false";
        return this.renderRow(key, value);
      case "number":
        return this.renderRow(key, value);
      default:
        if (!value) {
          return this.renderRow(key, "");
        }

        if (key) {
          return (
            <tr key={key}>
              <td>
                <b>{key}</b>
                <br />
                <JsonToHtml object={value}></JsonToHtml>
              </td>
            </tr>
          );
        }

        key = this.generateKey(type);
        return (
          <tr key={key}>
            <td>
              <JsonToHtml object={value}></JsonToHtml>
            </td>
          </tr>
        );
    }
  }

  renderRow(key, value) {
    let aux_key = key;
    if (!aux_key) {
      aux_key = this.generateKey(value);
    }
    return (
      <tr key={aux_key}>
        <td>
          <b>{key}</b> &nbsp; {value}
        </td>
      </tr>
    );
  }

  render() {
    let tableContent = undefined;
    if (this.props.object) {
      tableContent = (
        <tbody>
          {this.getJsonToArray(this.props.object).map((item) => this.renderObj(item.key, item.value, item.type))}
        </tbody>
      );
    }
    return (
      <div>
        <table className="json_to_html_table">{tableContent}</table>
      </div>
    );
  }
}

export default JsonToHtml;
