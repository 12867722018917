import React from 'react';
import {
	InputBase,
	Typography,
	withStyles,
} from '@material-ui/core';
import classnames from 'classnames';
import Conversation from './List/List';
import Header from './Header/HeaderWrapper';
import styles from './Messages.styles';

const Messages = ({
	changeContacts,
	classes,
	contact,
	conversation,
	error,
	message,
	messagesRef,
	onCreate,
	onSend,
	ownCode,
	setMessage,
}) => {
	const canSend = !(contact?.groupId && !contact?.acceptDate);

	const errorBar = 'string' === typeof error && (
		<div className={classes.errorBar}>
			<Typography className={classes.errorText} align='center'>
				{error}
			</Typography>
		</div>
	);

	const header = (
		<Header
			changeContacts={changeContacts}
			contact={contact}
			onCreate={onCreate}
		/>
	);

	const messages = (
		<Conversation
			conversation={(contact?.target || contact?.groupId) && conversation}
			groupId={contact?.groupId}
			messagesRef={messagesRef}
			ownCode={ownCode}
		/>
	);

	const sendButton = (
		<Typography
			align='right'
			className={classnames(classes.button, !canSend && classes.disabled)}
			onClick={canSend ? onSend : undefined}
		>
			Send
		</Typography>
	);

	const text = (
		<InputBase
			className={classes.text}
			onChange={event => setMessage(event.target.value)}
			placeholder='Message'
			value={message || ''}
			onKeyDown={event => {
				if (event.keyCode === 13) {
					canSend && onSend();
				};
			}}
			endAdornment={sendButton}
		/>
	);

	return (
		<div className={classes.root}>
			{errorBar}
			{header}
			{messages}
			{text}
		</div>
	);
};

export default withStyles(styles)(Messages);