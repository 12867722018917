import React from "react";
import { Typography, TextField, Grid } from "@material-ui/core";

export default function InvoiceConfigField({
	classes,
	editMode,
	fieldLabel,
	field,
	value,
	onChangeHandler,
}) {
	const labelField = (
		<Typography key={"ic_label_" + field} className={classes.label}>
			{fieldLabel}
		</Typography>
	);

	const inputField = (
		<TextField
			key={"ic_input_" + field}
			InputLabelProps={{
				classes: { root: classes.labelProps, focused: classes.labelProps },
			}}
			InputProps={{ classes: { root: classes.inputProps } }}
			className={classes.field}
			margin="normal"
			onChange={(e) => onChangeHandler(field, e.target.value)}
			error={false}
			required={false}
			value={value}
			disabled={!editMode}
		/>
	);

	return (
		<Grid container direction="row" alignItems="flex-end">
			<Grid item md={4}>
				{labelField}
			</Grid>
			<Grid item md={8}>
				{inputField}
			</Grid>
		</Grid>
	);
}
