import { wsCall, requestHelper } from "../../../app/coreSlice";

const carclubZonesCall = async ({ setZones }, dispatch, state) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "CARCLUB/ZONES");
  try {
    const result = await wsCall({
      type: "CARCLUB",
      subType: "ZONES",
      locationId: selectedLocation?.id,
      message: {},
    });
    if (result) {
      console.log("carclubZonesCall- result: %o", result);
      dispatch(setZones(result));
    }
  } catch (_ex) {
    console.error(_ex);
    rh.error("Error getting zones.");
  }
  rh.close();
};

export default carclubZonesCall;
