import React, { useState, useEffect } from "react";
import { withStyles, Grid } from "@material-ui/core";
import styles from "./InvoiceConfig.styles";

import invoiceService from "../../../services/invoice";
import log from "../../../utils/logger";

import InvoiceConfigHeader from "./InvoiceConfigHeader/InvoiceConfigHeader";
import InvoiceConfigForm from "./InvoiceConfigForm/InvoiceConfigForm";
import InvoiceConfigPreview from "./InvoiceConfigPreview/InvoiceConfigPreview";

const logger = log("InvoiceConfigurations");

const InvoiceConfig = ({ classes }) => {
	const [editMode, setEditMode] = useState(false);
	const [invoiceConfigurations, setInvoiceConfigurations] = useState({});

	const asyncGetConfigs = async () => {
		try {
			const configs = await invoiceService.getConfigs();
			setInvoiceConfigurations(configs);
		} catch (error) {
			logger.warn(error);
		}
	};

	useEffect(() => {
		asyncGetConfigs();
		//eslint-disable-next-line
	}, []);

	const onEditHandler = () => {
		setEditMode(true);
	};

	const onCancelHandler = () => {
		setEditMode(false);
		asyncGetConfigs();
	};

	const onSaveHandler = async () => {
		try {
			const configs = await invoiceService.updateConfigs(invoiceConfigurations);
			setEditMode(false);
			setInvoiceConfigurations(configs);
			//asyncGetConfigs();
		} catch (error) {
			logger.warn(error);
		}
	};

	return (
		<div className={classes.root}>
			<InvoiceConfigHeader
				classes={classes}
				editMode={editMode}
				onCancel={onCancelHandler}
				onEdit={onEditHandler}
				onSave={onSaveHandler}
			/>
			<Grid container>
				<Grid item xs={6}>
					<InvoiceConfigForm
						classes={classes}
						editMode={editMode}
						invoiceConfigurations={invoiceConfigurations}
						setInvoiceConfigurations={setInvoiceConfigurations}
					/>
				</Grid>
				<Grid item xs={6}>
					<InvoiceConfigPreview
						classes={classes}
						invoiceConfigurations={invoiceConfigurations}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export default withStyles(styles)(InvoiceConfig);
