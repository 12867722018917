export const styles = () => ({

	root: {
		width: '100vw', 
		height: '100vh', 
		position:'absolute', 
		backgroundColor: '#4095C2',
		color: 'White',
		overflow: 'auto',
	},

	smallWindowWidthText: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%',
	},
});

export default styles;