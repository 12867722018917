
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import { publishAndAwait } from '../../../app/coreSlice';


const getDetails = async () => {

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'CARCLUB',
			subType: 'DETAILS',
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			code,
			description,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}

	const carculbDetails = processMessage;

	return carculbDetails;
};

export default getDetails;