import createModel from '../createModel';

function Zone({
	carClubCode,
	category,
	code,
	description,
	id,
	geoInformation,
	geoPolygon,
	parentZoneId,
	type,
	updateUser,
	updateDate,
} = {}) {
	createModel.call(this, {
		carClubCode,
		category,
		code,
		description,
		id,
		geoInformation,
		geoPolygon,
		parentZoneId,
		type,
		updateUser,
		updateDate,
	});
}

export default Zone;
