import React, { useState, useEffect, useCallback } from 'react';
import chatService from '../../../../../services/chat';
import Contacts from './Contacts';
import log from '../../../../../utils/logger';

const logger = log('ContactsWrapper');

const ContactsWrapper = ({
	contactsList,
	changeContacts,
	selected,
	setSelected,
	...props
}) => {
	const [anchorEl, setAnchorEl] = useState();
	const [contacts, setContacts] = useState(contactsList);
	const [filter, setFilter] = useState('');
	const [hasRequest, setHasRequest] = useState();
	const [modal, setModal] = useState();

	useEffect(() => {
		setContacts(contactsList);
	}, [contactsList]);

	const onSearch = useCallback(() => {
		const filteredContacts = contactsList.filter(contact => 
			(contact?.phone || '').toUpperCase().includes(filter.toUpperCase())
			|| (contact?.nickname || '').toUpperCase().includes(filter.toUpperCase())
		);

		setContacts(filteredContacts);
	}, [filter, contactsList]);

	const onGroupAdd = useCallback(async ({inviteCode}) => {
		try{
			await chatService.acceptGroup(inviteCode);

			const groups = await chatService.getGroups();

			changeContacts(contacts => [...contacts.filter(({ groupId }) => !Boolean(groupId)),
				...groups]);

			setSelected({ });
		} catch(e){
			logger.warn(e.message || e.description || 'Could not accept group');
		};
	}, [changeContacts, setSelected]);

	const onAdd = useCallback(async nickname => {
		setHasRequest(true);

		try{
			const {target: customerCode} = selected;

			await chatService.addContact({customerCode, nickname});

			changeContacts(contacts => {
				const updateContacts = [...contacts];
				const index = updateContacts.findIndex(contact => contact.target === customerCode);
				index >= 0 && updateContacts.splice(index, 1, {...updateContacts[index], pending: false, nickname});

				return updateContacts;
			});

			setSelected({ });
			setModal();
		} catch(e){
			logger.warn(e.message || e.description || 'Could not add contact');
		} finally{
			setHasRequest(false);
		};
	}, [changeContacts, selected, setSelected]);

	const onRemove = useCallback(async customerCode => {
		try{
			await chatService.removeContact(customerCode);

			changeContacts(contacts => contacts.filter(contact => contact?.target !== customerCode));

			setSelected({ });
		}catch(e){
			logger.warn(e.message || e.description || 'Could not remove contact');
		}
	}, [changeContacts, setSelected]);

	return (
		<Contacts
			{...props}
			anchorEl={anchorEl}
			contacts={contacts}
			filter={filter}
			hasRequest={hasRequest}
			modal={modal}
			onAdd={onAdd}
			onGroupAdd={onGroupAdd}
			onRemove={onRemove}
			onSearch={onSearch}
			selected={selected}
			setAnchorEl={setAnchorEl}
			setFilter={setFilter}
			setModal={setModal}
			setSelected={setSelected}
		/>
	);
};

export default ContactsWrapper;