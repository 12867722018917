import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";

import { styles } from "./SelectedPark.styles";
import ParkIcon from "./ParkIcon/ParkIcon";
import CardIconTab from "../../../../common/displays/CardIconTab/CardIconTab";

function SelectedPark({ classes, park, onClick, disabled, selected }) {
  console.log();
  return (
    <CardIconTab
      icon={<ParkIcon empty={!Boolean(park?.parkId)} />}
      onClick={onClick}
      disabled={disabled}
      selected={selected}
    >
      {park?.parkId ? (
        <Grid container direction="column">
          <Grid item>
            <Typography className={classes.name}>{park.parkName}</Typography>
          </Grid>
        </Grid>
      ) : (
        <Typography className={classes.type}>Select a park</Typography>
      )}
    </CardIconTab>
  );
}

export default withStyles(styles)(SelectedPark);
