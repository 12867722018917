import * as Yup from "yup";
import moment from "moment";
import { DateField } from "../../../../common/forms";
import VehicleSelectField from "./VehicleSelectField/VehicleSelectField";

export function formSchema() {
  return Yup.object().shape({
    startDate: Yup.date()
      .max(moment().format("YYYY-MM-DDT23:59:59"), "Start date should be before today")
      .required("Start date is required"),
    endDate: Yup.date()
      .max(moment().format("YYYY-MM-DDT23:59:59"), "End date should be before today")
      .test(
        'minEndTest',
        'End date should be after start date',
        (value, context) => Promise.resolve(minEndTest(value, context?.parent))
      )
      .test(
        'maxEndTest',
        'End date should be at most one month after start date',
        (value, context) => Promise.resolve(maxEndTest(value, context?.parent))
      )
      .required("End date is required"),
    assets: Yup.array().min(1, "You need to select at least one asset")
  });
}

const minEndTest = (value, parent) => {
  return moment(value).isSameOrAfter(moment(parent.startDate))
}

const maxEndTest = (value, parent) => {
  return moment(value).isSameOrBefore(moment(parent.startDate).add(1, "month"))
}

export function formFields({ disabled, values, form, assets }) {

  const startDateChangeHandler = (date) => {
    form.change('endDate', moment.min(moment(date).add(1, "month"), moment()).format("YYYY-MM-DDT00:00:00"))
  }

  return [
    {
      size: 12,
      field: <DateField
        id="startDate"
        label="Start Date"
        required
        disabled={disabled}
        maxDate={moment()}
        onChange={startDateChangeHandler}
      />,
    },
    {
      size: 12,
      field: <DateField
        id="endDate"
        label="End Date"
        required
        disabled={disabled}
        minDate={values.startDate}
        maxDate={moment.min(moment(values.startDate).add(1, "month"), moment())}
      />,
    },
    {
      size: 12,
      field: <VehicleSelectField id="assets" label="Assets" required disabled={disabled} assets={assets} />,
    },
  ];
}
