import { alpha } from "@material-ui/core/styles";

export const styles = (theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.7),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.85),
    },
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    border: "1px solid lightgray",
    boxShadow: "1px 1px 5px gray",
    height: 36,
    minWidth: 36,
    width: 36,
    cursor: "pointer",
  },
});
