import { updateAssetOnList } from "../../../features/asset/assetSlice";

export const carclubSubscriptionHandlers = (body, dispatch) => {
  // console.log("carclubSubscriptionHandlers - body: %o", body);
  let msg = body?.processMessage;
  switch (body.type + "/" + body.subType) {
    case "CAR/NOTIFY_AVAILABILITY_ASSET":
      if (msg?.car) {
        dispatch(updateAssetOnList({ ...msg?.car, assetToken: msg?.car?.carToken }));
      }
      break;
    default:
      break;
  }
};
