import React from "react";
import { ReactComponent as PaymentIssueIcon } from "../../../../../../assets/TripApp/common/payment_failed.svg";
import { ReactComponent as CardIcon } from "./cc_icon.svg";
import { Tooltip, withStyles } from "@material-ui/core";
import { styles } from "./PaymentItem.styles";
import classnames from "classnames";
import { useCurrency, useDateTime } from "../../../../../../common/hooks";

// FIXME Typography this component up!

const PaymentItem = ({ classes, className, type, cardNumber, failed, amount, createDate, failMessage }) => {
  const { formatCurrency } = useCurrency();
  const { toTimeZone } = useDateTime();

  const textClass = failed ? classes.errorColor : classes.normalColor;

  const Icon = failed ? PaymentIssueIcon : CardIcon;

  const cardAndNumber = cardNumber && (
    <>
      <span className={classes.icon}>
        {failed ? (
          <Tooltip title={failMessage || "failed"}>
            <Icon style={{ fill: "#f22", width: "20px", height: "20px" }} />
          </Tooltip>
        ) : (
          <Icon style={{ width: "20px", height: "20px" }} />
        )}
      </span>
      <span className={classnames(classes.cardNumber, textClass)}>{"-" + cardNumber}</span>
    </>
  );

  return (
    <div className={classnames(classes.root, className)}>
      <div className={classes.itemContainerLeft}>
        <span className={classnames(classes.payment, textClass)}>{type ? type.toLowerCase().replace(/_/g, ' ') : "N/A"}</span>
        {cardAndNumber}
      </div>

      <div className={classes.itemContainerRight}>
        <span className={classnames(classes.date, textClass)}>{toTimeZone(createDate, "dateTime")}</span>
        <span className={classnames(classes.amount, textClass)}>{(type === "DEPOSIT_REFUND" ? "-" : "") + formatCurrency(amount)}</span>
      </div>
    </div>
  );
};

export default withStyles(styles)(PaymentItem);
