import { connect } from "react-redux";
import {
  princingPlanCreate,
  princingPlanDelete,
  princingPlanList,
  selectPrincingPlans,
} from "../../features/princingplans/princingplansSlice";
import toJS from "../../hoc/toJS";
import PricingPlanAppWrapper from "./PricingPlanAppWrapper";

const mapStateToProps = (state) => {
  return {
    pricingPlans: selectPrincingPlans(state),
    currentScreenSize: state.getIn(["app", "windowWidth"]),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPricingPlans: () => dispatch(princingPlanList()),
    removePricingPlan: (code) => dispatch(princingPlanDelete({ pricingPlanId: code })),
    updatePricingPlan: (data) => dispatch(princingPlanCreate(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(PricingPlanAppWrapper));
