import React from 'react';
import CustomerFilters from './CustomerFilters';

const CustomerFiltersWrapper = props => {

	return (
		<CustomerFilters
			{...props}
		/>
	);
};

export default CustomerFiltersWrapper;
