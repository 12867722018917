import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";

class WebSocketService {
  init = (
    host,
    connectHeaders,
    { onConnect, onConnectError, onDisconnect, onWebsocketClose, onLog, onFailedLogin }
  ) => {
    let socket;

    const client = new Client({
      reconnectDelay: 1000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      splitLargeFrames: true,
      connectHeaders,
      //debug: (str) => console.log(str),
    });
    window.wsClient = client;

    client.webSocketFactory = () => {
      socket = new SockJS(host);
      return socket;
    };

    client.onConnect = (frame) => {
      const sessionId = socket._transport.url.match(/ws.+\/(.+)\/websocket/);
      if (!sessionId || sessionId.length < 2) {
        onConnectError && onConnectError(new Error("Error while connecting to server. Please try again."));
        return;
      }
      onConnect && onConnect(sessionId[1]);
    };

    client.onDisconnect = (frame) => {
      onDisconnect && onDisconnect(frame);
    };

    client.onWebSocketClose = (event) => {
      onWebsocketClose && onWebsocketClose(event);
    };

    client.onStompError = (event) => {
      if (event?.headers?.message === "USER_NOT_FOUND" || event?.headers?.message === "INCORRECT_LOGIN") {
        client.deactivate();
        onFailedLogin && onFailedLogin(event?.headers?.message);
      }
    };
    
    client.activate();

    this.client = client;
    this.onLog = onLog;
  };

  disconnect = () => {
    if (this.client.active) {
      this.client.deactivate();
    }
    return this.client.active;
  };

  publish = ({ destination, body }) => {
    this.client.publish({
      destination,
      body,
    });
  };

  subscribe = (destination, callback) => {
    return this.client.subscribe(destination, callback);
  };
}

export default WebSocketService;
