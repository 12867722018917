import React from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Button } from "@material-ui/core";

import { styles } from "./CancelButton.styles";
import { reservationNewReservationCancelBo, selectActionIsLoading } from "../../../reservationsSlice";

function CancelButton({ classes, reservation, actions }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectActionIsLoading);

  const { code, carToken, state } = reservation;

  const onCancelHandler = () => {
    dispatch(reservationNewReservationCancelBo({ reservationCode: code, carToken }, actions));
  };

  return (
    <div className={classes.root}>
      <Button variant="outlined" color="primary" onClick={onCancelHandler} disabled={isLoading}>
        {state === "SCHEDULED" ? "Cancel Advanced" : "Cancel Trip"}
      </Button>
    </div>
  );
}

export default withStyles(styles)(CancelButton);
