import createModel from "../createModel";

class Trip {
  constructor({
    advanced,
    agent,
    assetName,
    carToken,
    code,
    createDate,
    customerCode,
    deviceToken,
    distance,
    endDate,
    groupId,
    imgUrl,
    isAgent,
    lateDelivery,
    licensePlate,
    locationId,
    longTrip,
    operator,
    paid,
    parkEnd,
    parkOn,
    parkStart,
    paymentIssue,
    reservationCode,
    startDate,
    state,
    toMigrate,
    type,
    underReview,
    wantedEndDate,
    wantedStartDate,
    rfidTrip,
    apiTrip,
    goldPin,
    operationType
  } = {}) {
    createModel.call(this, {
      advanced,
      agent,
      assetName,
      carToken,
      code: code || reservationCode,
      createDate,
      customerCode,
      deviceToken,
      distance,
      endDate,
      groupId,
      imgUrl,
      isAgent,
      lateDelivery,
      licensePlate,
      locationId,
      longTrip,
      operator,
      paid,
      parkEnd,
      parkOn,
      parkStart,
      paymentIssue,
      startDate,
      state,
      toMigrate,
      type,
      underReview,
      wantedEndDate,
      wantedStartDate,
      rfidTrip,
      apiTrip,
      goldPin,
      operationType
    });
    this.possibleProblems = [
      false && lateDelivery, //TODO remove false
      longTrip,
      "CLOSED" === state && !paid && !isAgent && !groupId,
      toMigrate,
      underReview,
    ];
    this.seeIfAttentionIsRequired = () => this.possibleProblems.find(Boolean);
  }
}

export default Trip;
