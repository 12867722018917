import React, { useEffect, useState, useCallback } from "react";
import useSimpleForm from "../../hooks/useSimpleForm/useSimpleForm";
import customerService from "../../services/customer/ws";
import pricingPlanService from "../../services/pricingPlan/index";
import SearchListExecute from "./SearchListExecute";
import { detailsByCustomer, detailsByZone } from "./details";
import { modals } from "../PricingPlan/PricingPlanList/PricingPlanItem/PricingPlanItem";
import log from "../../utils/logger";
import { useMemo } from "react";

import { useSelector } from "react-redux";
import { selectCarclubInfo } from "../../features/carclub/carclubSlice";
import { selectZones } from "../../features/asset/assetSlice";

const logger = log("SearchListExecuteWrapper");

const CUSTOMER = "CUSTOMER";
const ZONE = "ZONE";
const ADD_CUSTOMER = "ADD_CUSTOMER";
const ADD_ZONE = "ADD_ZONE";

const selectByParamsZones = [{ value: "name", label: "Name" }];

const selectByParamsCustomers = [
  { value: "name", label: "Name" },
  { value: "email", label: "Email" },
  { value: "phoneNumber", label: "Phone" },
];

const initialFilter = {
  selectBy: "name",
  selectByString: "",
};

const SearchListExecuteWrapper = (props) => {
  const zones = useSelector(selectZones);

  const GDPDR = useSelector(selectCarclubInfo)?.secrets?.GDPDR;

  const filter = useSimpleForm(initialFilter);
  const [error, setError] = useState(false);
  const [results, setResults] = useState([]);
  const [resultsInPP, setResultsInPP] = useState([]);

  const [allZones, setAllZones] = useState([]);

  const { pricingPlanId, modal, getZones, pricingPlanName } = props;

  const mode = useMemo(() => {
    return modal === modals.CUSTOMER_EDIT_MODAL ? CUSTOMER : ZONE;
  }, [modal]);

  useEffect(() => {
    filter.reset();
    //eslint-disable-next-line
  }, [mode]);

  const title = mode === CUSTOMER ? `Customers from ${pricingPlanName}` : `Zones from ${pricingPlanName}`;

  const searchBarPlaceholder = mode === CUSTOMER ? `Search customers` : `Search zones`;

  let details = mode === CUSTOMER ? detailsByCustomer : detailsByZone;
  let selectByParams = mode === CUSTOMER ? selectByParamsCustomers : selectByParamsZones;

  const getPricingPlanCustomersOrZones = useCallback(
    async (id) => {
      console.log("getPricingPlanCustomersOrZones");
      try {
        let results;

        if (mode === CUSTOMER) {
          results = await pricingPlanService.getCustomersFromPP(id);
          setResultsInPP(results?.resultArray || []);
          setResults(results?.resultArray || []);
          return;
        }

        results = await pricingPlanService.getZonesFromPP(id);

        setResultsInPP(results?.resultArray || []);
        setResults(zones || []);
        zonesHandler(zones);
      } catch (error) {
        logger.info(error);
      }
    },
    [mode, zones]
  );

  useEffect(() => {
    if (mode !== CUSTOMER) {
      getZones();
    }

    getPricingPlanCustomersOrZones(pricingPlanId);
    // eslint-disable-next-line
  }, [pricingPlanId, mode]);

  const onChangeHandler = (option, name) => {
    filter.setField({ name, value: option });
  };

  const onSearchHandler = async (doDefaultSearch) => {
    try {
      if (doDefaultSearch) {
        setResults([]);
        setResultsInPP([]);
        getPricingPlanCustomersOrZones(pricingPlanId);
        return;
      }

      const { state } = filter;

      if (mode === CUSTOMER) {
        const customers = await customerService.searchCustomers({ ...state, GDPDR });
        setResults(customers);
        setResultsInPP(
          resultsInPP.map((detail) => {
            return {
              ...detail,
              hide: false,
            };
          })
        );
        return;
      }

      const string = state.selectByString;
      if (string === "") {
        setResults(allZones);
        return;
      }

      const searchResults = [];

      !!allZones &&
        allZones.forEach((e) => {
          if (e.description && e.description.toUpperCase().includes(string.toUpperCase())) {
            searchResults.push(e);
          }
        });

      setResults(searchResults);
      setResultsInPP(
        resultsInPP.map((detail) => {
          return {
            ...detail,
            hide: false,
          };
        })
      );
    } catch (error) {
      if (error && error.code === "004") {
        setResults([]);
      }
      logger.info(error);
    }
  };

  const addOrRemoveHandler = async (detail, subType) => {
    setError(false);
    let data;

    if (mode === CUSTOMER) {
      data = {
        ...detail,
        pricingPlanId,
        customerCode: detail.code,
        customerId: detail.id,
      };
      addOrRemoveCustomerHandler(data, subType);
      return;
    }

    data = {
      ...detail,
      pricingPlanId,
      zoneId: detail.id,
    };

    addOrRemoveZoneHandler(data, subType);
  };

  const addOrRemoveCustomerHandler = async (data, subType) => {
    try {
      await pricingPlanService.addOrRemoveCustomerToPP(data, subType);

      // Adding a customer
      if (subType === ADD_CUSTOMER) {
        setResultsInPP([...resultsInPP, data]);
        return;
      }

      // Removing a customer
      setResultsInPP([...resultsInPP.filter((detail) => detail.id !== data.id)]);
    } catch (error) {
      logger.info(error);
    }
  };

  const addOrRemoveZoneHandler = async (data, subType) => {
    try {
      const { pricingPlanId, zoneId } = data;

      await pricingPlanService.addOrRemoveZoneToPP({ pricingPlanId, zoneId }, subType);

      // Adding a zone
      if (subType === ADD_ZONE) {
        setResultsInPP([...resultsInPP, data]);
        return;
      }

      // Removing a zone
      setResultsInPP([...resultsInPP.filter((detail) => detail.id !== data.id)]);
    } catch (error) {
      logger.info(error);
    }
  };

  const zonesHandler = (zones) => {
    const wantedZones = zones.wanted;
    const unwantedZones = zones.unwanted;
    const wantedValues = Object.values(wantedZones);

    const unwantedValues = Object.values(unwantedZones);
    const allZones = [];
    allZones.push(...wantedValues);
    allZones.push(...unwantedValues);
    setAllZones(allZones);
  };

  return (
    <SearchListExecute
      {...props}
      title={title}
      searchBarPlaceholder={searchBarPlaceholder}
      onChangeHandler={onChangeHandler}
      onSearchHandler={onSearchHandler}
      filter={filter.state}
      selectByParams={selectByParams}
      results={mode === CUSTOMER ? results : zones}
      resultsInPP={resultsInPP}
      detailsFields={details}
      errorMessage={error}
      addOrRemoveHandler={addOrRemoveHandler}
      modal={modal}
    />
  );
};

export default SearchListExecuteWrapper;
