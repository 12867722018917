const styles = theme => ({
	root: {
		fontSize: '10px',
		lineHeight: '20px',
	},

	checkIcon: {
		color: '#51B383',
	},

	attachments: {
		fontWeight: 'bold',
		lineHeight: '20px',
		fontSize: '10px',
		display: 'flex',
		cursor: 'pointer',
	},

	chevron:{
		transition: '500ms',
	},

	open: {
		transform: 'rotate(180deg)',
	},

	files: {
		transition: 'max-height 500ms ease-in',
		maxHeight: 'var(--height)',
		overflowY: 'hidden',
	},

	noDisplay: {
		maxHeight: '0px',
		overflowY: 'hidden',
	},
	
	document: {
		color: '#08c',
		cursor: 'pointer',
		fontSize: '10px',
		lineHeight: '20px',
	},
});

export default styles;