import React from 'react';
import Typography  from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/AddCircle';
import Filters from './ConsumptionFilter/ConsumptionFilterWrapper';
import Create from './CreateModal/CreateModalWrapper';
import ChargeModal from './ChargeModal/ChargeModalWrapper';
import Transactions from './Transactions/TransactionsWrapper';
import List from './List/ListWrapper';
import styles from './Consumption.styles';
import { currencyFormatter } from '../../../services/formatter';

const Consumption = ({
	accountCode,
	chargingModal,
	classes,
	error,
	filter,
	hasRequest,
	lastPage,
	list,
	modal,
	onCharge,
	onCreate,
	onInvoice,
	onRemove,
	onSearch,
	onSettle,
	selectedBillingItemCode,
	setChargingModal,
	setError,
	setFilter,
	setList,
	setModal,
	total,
	transactionsModal,
	transactionsModalHandler,
}) => {
	const errorBar = 'string' === typeof error && (
		<div className={classes.errorBar}>
			<Typography className={classes.errorText} align='center'>
				{error}
			</Typography>
		</div>
	);

	const title = (
		<div className={classes.titleDiv}>
			<Typography className={classes.title}>
				Consumption
			</Typography>
			<Typography className={classes.subTitle}>
				{accountCode}
			</Typography>
		</div>
	);

	const consumptionFilters = (
		<Filters
			onSearch={onSearch}
			placeholder={filter}
			setPlaceholder={setFilter}
		/>
	);

	const consumptionList = (
		<List
			billingItems={list}
			filter={filter}
			lastPage={lastPage}
			onInvoice={onInvoice}
			onRemove={onRemove}
			onSearch={onSearch}
			onSettle={onSettle}
			setChargingModal={setChargingModal}
			transactionsModalHandler={transactionsModalHandler}
			setError={setError}
			setList={setList}
		/>
	);

	const createModal = (
		<Create
			onClose={() => setModal(false)}
			onCreate={onCreate}
			open={modal}
		/>
	);

	const chargeModal = (
		<ChargeModal
			chargingModal={chargingModal}
			hasRequest={hasRequest}
			onCharge={onCharge}
			onSettle={onSettle}
			setChargingModal={setChargingModal}
		/>
	);

	const billingItemTransactions = !!transactionsModal && (
		<Transactions
			onCloseTransactions={() => transactionsModalHandler({ modal: false, billingItemCode: null })}
			hasRequest={hasRequest}
			selectedBillingItemCode={selectedBillingItemCode}
			billingItemAccount={list.find(e => e.code === selectedBillingItemCode)}
		/>
	);

	const createIcon =
		<CreateIcon onClick={() => setModal(true)} className={classes.createButton} />;

	const mainContent = !transactionsModal && (
		<>
			<div className={classes.header}>
				{title}
				{consumptionFilters}
				{createIcon}
			</div>
			{consumptionList}
		</>

	);

	const totals = (
		<div className={classes.totals}>
			<Typography className={classes.totalsText}>
				Total Cost: {total?.totalCost || 0} {currencyFormatter.symbol()}
			</Typography>
			<Typography className={classes.totalsText}>
				Total Charged: {total?.totalCharged || 0} {currencyFormatter.symbol()}
			</Typography>
		</div>
	);

	return (
		<div className={classes.root}>
			{errorBar}
			{createModal}
			{chargeModal}
			{billingItemTransactions}
			{mainContent}
			{totals}
		</div>
	);
};

export default withStyles(styles)(Consumption);