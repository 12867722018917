import React from 'react';
import Detail from './Detail';
import Application from './Application';
import {
	Divider,
	Typography,
	withStyles,
} from '@material-ui/core';
import Button from '../../../UI/Buttons/CommonButtons';
import styles from './Details.styles';
import classnames from 'classnames';

const Details = ({
	classes,
	className,
	details,
	onEdit,
}) => {
	if (!details) {
		return (
			<div className={classnames(classes.root, className)}>
				<Typography align='center' className={classes.noSelected}>
					Please select a bonus
				</Typography>
			</div>
		);
	}

	const detail = <Detail info={details} />;

	const application = <Application info={details} />;

	const editButton = (
		<Button
			buttonStyleName='filled'
			key='edit_button'
			label='Edit Details'
			onClick={onEdit}
		/>
	);

	return (
		<div className={classnames(classes.root, className)}>
			{detail}
			<Divider />
			{application}
			<div className={classes.buttons}>
				{editButton}
			</div>
		</div>
	);
};

export default withStyles(styles)(Details);