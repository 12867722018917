import React from 'react';
import ListItem from '../../../UI/List/ListItem/ListItem';
import {
	Tooltip,
	withStyles,
} from '@material-ui/core';
import {
	Create as EditIcon,
	PictureAsPdf as InvoiceIcon,
} from '@material-ui/icons';
import { dateFormatter } from '../../../../services/formatter';
import withPermission from '../../../../hoc/withPermission';
import styles from './IncidentsItem.styles';
import classnames from 'classnames';

const IncidentsItem = ({
	classes,
	fields,
	incident,
	onEdit,
	onSelect,
	selected,
}) => {

	const isIncidentClosed = incident.closed;

	const editIcon	= (
		<EditPermission>
			<Tooltip title={'Download invoice'} placement='left'>
				<InvoiceIcon 
					onClick={() => !!incident.pdfUrl && window.open(incident.pdfUrl, '_blank')}
					className={classnames(classes.editIcon,
						!incident.pdfUrl && classes.editIconDisabled)}
					disabled={!incident.pdfUrl}
				/>
			</Tooltip>
			<Tooltip title={isIncidentClosed ? '' : 'Edit'} placement='left'>
				<EditIcon 
					onClick={() => {
						if (!isIncidentClosed){
							onSelect(incident);
							onEdit();
						};
					}}
					className={classnames(classes.editIcon,
						!!isIncidentClosed && classes.editIconDisabled)}
					disabled={!!isIncidentClosed}
				/>
			</Tooltip>
		</EditPermission>
	);

	const createDate = !!incident.createDate
		&& dateFormatter.format(incident.createDate, 'dateTime');

	const lastModified = !!incident.lastModified
		&& dateFormatter.format(incident.lastModified, 'dateTime');

	const state = getStatus(incident);

	const item = (
		<ListItem
			selected={selected}
			fields={fields}
			onSelect={() => onSelect(incident)}
			element={{
				...incident,
				createDate,
				editIcon,
				lastModified,
				state,
			}}
		/>
	);

	return item;
};

const EditPermission = withPermission([
	{ resource: ['incidents', 'edit'] },
])(({
	children,
}) => (
	<>
		{children}
	</>
));

export default withStyles(styles)(IncidentsItem);

const getStatus = incident => {

	if(incident.paid)
		return 'PAID';

	if(incident.closed)
		return 'CLOSED';

	return 'OPEN';
};