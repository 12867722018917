import React from 'react';
import ListItem from '../../../../UI/List/ListItem/ListItem';
import rolesToString from '../../../../../model/Carclub/rolesToString';
import {
	Menu,
	MenuItem,
	Tooltip,
	withStyles,
} from '@material-ui/core';
import {
	Check as TrueIcon,
	Create as UpdateIcon,
	MoreVert as ConfigsItem,
	RemoveCircleOutline as EnableIcon,
	LockOpen as ResetPasswordIcon,
} from '@material-ui/icons';
import { dateFormatter } from '../../../../../services/formatter';
import withPermission from '../../../../../hoc/withPermission';
import styles from './UsersItem.styles';
import classnames from 'classnames';

const UsersItem = ({
	anchorEl,
	classes,
	fields,
	onDelete,
	onEdit,
	onEnable,
	onResetPassword,
	onSelect,
	roles,
	selected,
	setAnchorEl,
	user,
}) => {

	const isEnabled = user.enabled;

	const id = 'menu__' + user.code;
	//Yes, we want undefined, because anchorEl should get an undefiend
	const menuAnchor = anchorEl && anchorEl.id === id ? anchorEl : undefined;

	const enableIcon = (
		<Tooltip title={isEnabled ? 'Disable' : 'Enable'} placement='left'>
			<EnableIcon
				onClick={() => onEnable({ ...user })}
				className={classnames(classes.editIcon, !isEnabled && classes.editIconDisabled)}
			/>
		</Tooltip>
	);

	const customerUpdate = (
		<Tooltip title='Update' placement='left'>
			<UpdateIcon
				onClick={() => {
					onSelect(user.code);
					onEdit(true);
				}}
				className={classnames(classes.editIcon)}
			/>
		</Tooltip>
	);

	const resetPassword = (
		<Tooltip title='Reset Password' placement='left'>
			<ResetPasswordIcon
				onClick={() => {
					onSelect(user.code);
					onResetPassword(true);
				}}
				className={classnames(classes.editIcon)}
			/>
		</Tooltip>
	);

	const moreIcon = (
		<>
			<ConfigsItem
			  id={id}
				aria-controls={'menu_' + user.code}
				aria-haspopup='true'
				onClick={e => {
					setAnchorEl(e.target);
				}}
			/>
			<Menu
				id={'menu_' + user.code}
				anchorEl={menuAnchor}
				open={!!menuAnchor}
				onClose={e => setAnchorEl()}
			>
				<MenuItem
					onClick={() => {
						setAnchorEl();
						onDelete(user.code);
					}}
				>
					Delete
				</MenuItem>
			</Menu>
		</>
	);

	const icons = (
		<ActionButtons>
			{moreIcon}
			{customerUpdate}
			{resetPassword}
			{enableIcon}
		</ActionButtons>
	);

	const enabled = isEnabled && <TrueIcon className={classes.checkIcon} />;

	const createDate = !!user.createDate
		&& dateFormatter.format(user.createDate, 'dateTime');

	const role = roles 
		&& rolesToString((roles.find(role => user.role === role.id) || {}).name || user.role);

	const item = (
		<ListItem
			selected={selected}
			fields={fields}
			onSelect={() => onSelect(user.code)}
			element={{
				...user,
				enabled,
				createDate,
				icons,
				role,
			}}
		/>
	);

	return item;
};

const ActionButtons = withPermission(
	[{ resource: ['carclub', 'users', 'edit'] }]
)(({
	children,
}) => (
	<>
		{children}
	</>
));

export default withStyles(styles)(UsersItem);