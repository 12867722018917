import React, { useState, useCallback, useEffect } from "react";
import ListWorkspace from "./ListWorkspace";
import workorderService from "../../../services/workOrder";
import log from "../../../utils/logger";
import { useSelector } from "react-redux";
import { selectDefaultLocation } from "../../../features/carclub/carclubSlice";

const logger = log("WorkorderListSpaceWrapper");

const ListWorkspaceWrapper = ({ selectedWorkOrder, setSelectedWorkOrder, ...props }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [drawer, setDrawer] = useState();
  const [filter, setFilter] = useState({ subType: "LIST_ACTIVE" });
  const [lastPage, setLastPage] = useState(true);
  const [modal, setModal] = useState();
  const [priorities, setPriorities] = useState([]);
  const [servicePoints, setServicePoints] = useState([]);
  const [status, setStatus] = useState();
  const [tasks, setTasks] = useState([]);
  const [types, setTypes] = useState([]);
  const [workorders, setWorkorders] = useState();
  const selectedLocation = useSelector(selectDefaultLocation);

  useEffect(() => {
    const onMount = async () => {
      try {
        const priorities = await workorderService.getPriorities();
        const servicePoints = await workorderService.listServicePoints();
        const types = await workorderService.getTypes();

        setPriorities(priorities);
        setServicePoints(servicePoints);
        setTypes(types);
      } catch (e) {
        logger.warn("Could not get priorities and types");
      }
    };

    onMount();
  }, [selectedLocation]);

  useEffect(() => {
    //This will also trigger on mount
    const onSubTypeChange = async () => {
      try {
        const { workorders, lastPage } = await workorderService.listWorkorders({ subType: filter.subType });

        setWorkorders(workorders);
        setLastPage(lastPage);
        setCurrentPage(1);
      } catch (e) {
        logger.warn("Could not update workorders on status change");
      }
    };

    onSubTypeChange();
  }, [filter.subType, selectedLocation]);

  useEffect(() => {
    const onWorkorderChange = async () => {
      try {
        const tasks = await workorderService.getTasks((workorders || {})[selectedWorkOrder]);

        setTasks(tasks);
      } catch (e) {
        logger.warn(`Could not get tasks for ${selectedWorkOrder}`);
      }
    };

    (workorders || {})[selectedWorkOrder] && onWorkorderChange();
  }, [selectedWorkOrder, workorders, modal]);

  const onSearch = useCallback(
    async (reset) => {
      reset && setTasks();
      setSelectedWorkOrder();

      try {
        const { workorders, lastPage } = await workorderService.listWorkorders({
          ...filter,
          page: reset ? 1 : currentPage + 1,
        });

        setWorkorders((prev) => (reset ? workorders : { ...prev, ...workorders }));
        setLastPage(lastPage);
        setCurrentPage((prev) => (reset ? 1 : prev + 1));
      } catch (e) {
        logger.warn("Could not get workorders from search");
      }
    },
    [filter, setSelectedWorkOrder, currentPage]
  );

  const onReset = useCallback(() => setFilter((prev) => ({ subType: prev.subType })), []);

  const onStatusChange = useCallback(
    (newStatus) => {
      const { key, subType } = newStatus || {};

      if (subType !== filter.subType) {
        setFilter((prev) => ({ ...prev, subType }));
      }

      setStatus(key);
    },
    [filter.subType]
  );

  const closeDrawer = useCallback(() => setDrawer(), []);

  const updateWorkOrder = useCallback(
    (workorder) => setWorkorders((prev) => ({ ...prev, [workorder.code]: workorder })),
    []
  );

  const removeWorkOrder = useCallback(
    async (workorderCode) =>
      setWorkorders((prev) => {
        const newList = { ...prev };
        delete newList[workorderCode];

        return newList;
      }),
    []
  );

  return (
    <ListWorkspace
      {...props}
      closeDrawer={closeDrawer}
      drawer={drawer}
      filter={filter}
      lastPage={lastPage}
      modal={modal}
      onReset={onReset}
      onSearch={onSearch}
      priorities={priorities}
      removeWorkOrder={removeWorkOrder}
      selectedWorkOrder={selectedWorkOrder}
      servicePoints={servicePoints}
      setDrawer={setDrawer}
      setFilter={setFilter}
      setModal={setModal}
      setSelectedWorkOrder={setSelectedWorkOrder}
      setStatus={onStatusChange}
      setTasks={setTasks}
      status={status}
      tasks={tasks}
      types={types}
      updateWorkOrder={updateWorkOrder}
      workOrders={workorders}
    />
  );
};

export default ListWorkspaceWrapper;
