export const styles = theme => ({

	root: {
		height: '100%',
		width: '100%',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},

	warningsRoot: {
		height: '100%',
		width: '100%',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		flexBasis: '33%',
	},

	/*REGISTRAION START*/

	registrationWithoutReferralBlock: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		flexBasis: '33%',
		padding: 8,
	},

	registrationWithReferralBlock: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		flexBasis: '33%',
		padding: 8,
	},

	registrationWithReferralExistingCustomerBlock: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		flexBasis: '33%',
		padding: 8,
	},


	/*REGISTRAION END*/

	verticalFlex: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
	},

	horizontalFlex: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},

	flexStart: {
		justifyContent: 'flex-start',
	},

	alignItemsBottom: {
		alignItems: 'end',
	},

	verticalBlock: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		flexBasis: '33%',
		padding: 8,
		boxSizing: 'border-box',
	},

	verticalBlockTrips: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		flexBasis: '45%',
		padding: 8,
		boxSizing: 'border-box',
	},

	verticalBlockWarnings: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		flexBasis: '45%',
		padding: 8,
		boxSizing: 'border-box',
	},

	verticalBlockPayments: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		flexBasis: '50%',
		padding: 8,
		boxSizing: 'border-box',
		borderRight: '2px solid rgba(192, 192, 192, 0.75)',
	},

	middleBlock: {
		borderLeft: '2px solid rgba(192, 192, 192, 0.75)',
		borderRight: '2px solid rgba(192, 192, 192, 0.75)',
	},

	flexHorizontally: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		height: 35,
	},

	spaceBetween: {
		justifyContent: 'space-between',
	},

	flex30: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		flexBasis: '40%',
	},

	flex70: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		flexBasis: '60%',
	},

	alignSelect: {
		marginLeft: '30px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},

	alignSelectWarnings: {
		marginLeft: '30px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
	},

	equalMargin: {
		margin: '0px 0px 8px 0px',
	},

	inputIcon: {
		padding: '2px',
	},

	title: {
		fontSize: '16px',
		paddingBottom: 4,
	},

	topPaddedTitle: {
		fontSize: '16px',
		padding: '30px 0 4px 0',
	},

	titleWithDivision: {
		fontSize: '16px',
		paddingBottom: 4,
		borderTop: '2px solid rgba(192, 192, 192, 0.75)',
	},

	subtitle: {
		fontSize: '14px',
		height: 30,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
	},

	divison: {
		borderTop: '2px solid rgba(192, 192, 192, 0.75)',
		marginTop: 6,
		paddingTop: 4,
	},

	selectLabel: {
		fontSize: '12px',
		height: 30,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
	},

	selectLabelPadding: {
		paddingBottom: 4,
	},

	checkboxLabel: {
		fontSize: '12px',
		height: 35,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		marginLeft: 12,
	},

	greyTextField: {
		backgroundColor: '#F0F0F0',
	},

	labelProps: {
		// color: '#009AC9',
		color: '#47525E',
		zIndex: 999,
	},

	inputProps: {
		fontSize: 12,
	},

	inputPropsMultine: {
		fontSize: 12,
		width: 100,
	},

	multiline: {
		padding: 0,
		margin: '0px 0px 8px 0px',
	},

	label: {
		minWidth: '100px',
		width: '30%',
		fontSize: '12px',
		height: 35,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
	},

	endAdornment: {
		height: 35,
		fontSize: '12px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		marginLeft: 8,
	},

	field: {
		width: '70%',
		fontSize: '11px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
	},

	fieldMultiline: {
		width: '95%',
		height: '100%',
		fontSize: '11px',
		display: 'flex',
		alignSelf: 'center',
	},

	descriptionDiv: {
		height: '91%',
	},

	tinyLabel: {
		width: 60,
		minWidth: 60,
	},

	smallest: {
		width: 80,
	},

	small: {
		width: 100,
	},

	medium: {
		width: 140,
	},

	normal: {
		width: 170,
	},

	mediumNoPercent: {
		width: 140,
	},

	big: {
		width: 180,
	},

	subtitle16px: {
		fontSize: '16px',
		paddingBottom: 4,
	},

	subtitle15px: {
		fontSize: '15px',
		paddingBottom: 4,
	},

	subtitle14px: {
		fontSize: '14px',
		paddingBottom: 4,
	},

	checkbox: {
		padding: '0px',
		height: 35,
		alignItems: 'center',
	},

	blueParagraph: {
		marginRight: '20px',
		justifyContent: 'center',
		alignItems: 'center',
	},

	hex: {
		color: '#009AC9',
		marginLeft: '10px',
		justifyContent: 'center',
		alignItems: 'center',
	},

	// WORK ORDERS    	
	workOrdersRoot: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'row',
		height: '85%',
		width: '100%',
	},

	workOrderConfigsLeftBlock: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		flexBasis: '35%',
		padding: '0 8px',
		boxSizing: 'border-box',
	},

	workOrderConfigsMiddleBlock: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		flexBasis: '45%',
		padding: '0 8px',
		boxSizing: 'border-box',
		borderLeft: '2px solid rgba(192, 192, 192, 0.75)',
		borderRight: '2px solid rgba(192, 192, 192, 0.75)',
	},

	workOrderConfigsRightBlock: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		flexBasis: '22%',
		padding: '0 8px',
		boxSizing: 'border-box',
	},

	createButton: {
		cursor: 'pointer',
	},

	availableTaskList: {
		margin: 0,
		minHeight: '60%',
		maxHeight: '60%',
	},

	availableTaskListFilterRoot: {
		margin: 0,
		padding: 0,
		fontSize: 11,
	},

	location: {
		display: 'flex',
		gap: '20px',
	},

	locationList: {
		margin: 0,
	},

	locationHeader: {
		flex: 0,
	},

	locationFilterRoot: {
		margin: 0,
		padding: 0,
		fontSize: 11,
	},

	rowBlock: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},

	rowBlockPadding: {
		paddingTop: 6,
	},

	flex65: {
		flexBasis: '65%',
	},

	flex60: {
		flexBasis: '60%',
	},

	flex50: {
		flexBasis: '50%',
	},

	flex40: {
		flexBasis: '40%',
	},

	flex35: {
		flexBasis: '35%',
	},

	fullWidth: {
		width: '100%',
	},

	workOrderBtn: {
		display: 'flex',
		alignItems: 'end',
	},

	errorDiv: {
		position: 'absolute',
		top: '40px',
		left: '42%',
		width: '50%',
		border: "2px solid red",
		height: '40px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},

	errorText: {
		fontSize: '14px',
	},

	disabledButton: {
		color: '#C5C5C5',
		cursor: 'default',
	},

	timestamp: {
		textAlign: 'center',
	},

	deleteTaskButton: {
		color: '#f50057',
		cursor: 'pointer',
	},

	noMargin: {
		margin: 0,
	},

	uploadDiv: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		padding: '4px 0',
		paddingRight: 6,
		alignItems: 'flex-end',
	},

	fileInput: {
		width: 180,
		padding: '0 6px',
	},

	link: {
		color: '#009BCC',
		cursor: 'pointer',
	},
	
	removeIcon: {
		cursor: 'pointer',
		fill: '#47525E',
		paddingLeft: 6,
	},

	divider: {
		height: "100%",
		borderLeft: '1px solid #E0E0E0',
		borderRight: '1px solid #E0E0E0',
		margin: "0 8px",
	}

});