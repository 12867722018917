import React from "react";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import UpArrowIcon from "@material-ui/icons/ArrowUpwardRounded";
import DownArrowIcon from "@material-ui/icons/ArrowDownwardRounded";
import AlertIcon from "@material-ui/icons/ErrorRounded";
import EngineIcon from "@material-ui/icons/ChildCare";
import DoorsIcon from "@material-ui/icons/SmokingRoomsOutlined";
import { ReactComponent as OperatorIcon } from "../../../../../../../assets/TripApp/common/operator.svg";
import { LOG_TYPES, getParsedTypeAndSubType } from "./utils.js";
import { styles } from "./ActivityLogItem.styles";
import classnames from "classnames";
import { useDateTime } from "../../../../../../../common/hooks";

// TODO: Refactor - we should unburden this component and aggregate this responsability (style, icons, tooltip...) somewhere else
const ActivityLogItem = ({ classes, timelineLogClassName, activityLog, lastLog }) => {
  const { doors, ignition } = activityLog;
  const { toTimeZone } = useDateTime();

  // FIXME snake case should be
  const {
    commType,
    last_command_type: lastCommandType,
    last_command_sub_type: lastCommandSubType,
    create_date: createDate,
    date,
    type,
    subtype,
    user,
    isOperator,
    alarmDescription,
  } = activityLog.comms || activityLog;

  const icon = getElementBehaviourIcon(classes, commType);

  // TODO this should return {type: ..., subType: ...} and used as such
  const typeAndSubTypeString = getParsedTypeAndSubType(
    lastCommandType,
    lastCommandSubType,
    commType,
    alarmDescription
  ).split(" ");

  const parsedType = typeAndSubTypeString[0];
  const parsedSubType = typeAndSubTypeString.slice(1).join(" ");

  return (
    <>
      <ListItem className={classnames(classes.root, timelineLogClassName)} classes={{ gutters: classes.gutters }}>
        {icon && <ListItemAvatar classes={{ root: classes.itemAvatar }}>{icon}</ListItemAvatar>}
        <div className={classes.contentRoot}>
          <div className={classes.contentLeft}>
            {activityLog && (
              <span className={classes.time}>{toTimeZone(createDate || date, "hourMinutesSeconds")}</span>
            )}
            <span className={classes.type}>
              {type} {(lastCommandType || alarmDescription) && parsedType}
            </span>
            <span className={classes.subType}>
              {subtype} {(lastCommandSubType || alarmDescription) && parsedSubType}
            </span>
          </div>

          <div className={classes.statusIcons}>
            {ignition && getEngineStatus()}
            {doors && getDoorStatus()}
            {isOperator && getOperatorStatus(user)}
          </div>
        </div>
      </ListItem>
      {!lastLog && <Divider variant="middle" />}
    </>
  );
};

const getElementBehaviourIcon = (classes, logType) => {
  let icon;
  let fill = { root: classes.greenFill };
  const alertFill = { root: classes.redFill };
  switch (logType) {
    case LOG_TYPES.TELEMATIC:
      icon = <DownArrowIcon classes={fill} />;
      break;
    case LOG_TYPES.COMMAND:
      icon = <UpArrowIcon classes={fill} />;
      break;
    case LOG_TYPES.ALARM:
      icon = <AlertIcon classes={alertFill} />;
      break;
    default:
      icon = <DownArrowIcon classes={fill} />;
      break;
  }

  return icon;
};

const getEngineStatus = () => {
  return (
    <Tooltip title={"Engine running"} placement="top-end">
      <EngineIcon style={{ width: 18 }} />
    </Tooltip>
  );
};

const getDoorStatus = () => {
  return (
    <Tooltip title={"Doors unlocked"} placement="top-end">
      <DoorsIcon style={{ width: 18 }} />
    </Tooltip>
  );
};

const getOperatorStatus = (user) => {
  return (
    <Tooltip title={user} placement="top-end">
      <OperatorIcon style={{ width: 18 }} />
    </Tooltip>
  );
};

export default withStyles(styles)(ActivityLogItem);
