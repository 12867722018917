import React from 'react';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import { styles } from './TripInfo.styles';
import * as ReservationStatus from '../../../../model/Reservation/ReservationStateType';
import { ReactComponent as ThreeDotsIcon } from '../../../../assets/icons/three_dots.svg';
import { ReactComponent as MapPinIcon } from '../../../../assets/icons/map_pin.svg';
import { ReactComponent as MapCrosshairIcon } from '../../../../assets/icons/map_crosshair.svg';
import classnames from 'classnames';

const LocationsWithWaypoint = ({
	advanced,
	classes,
	data,
}) => {

	const {
		distanceTravelled,
		endAddress,
		endMileage,
		latitude,
		longitude,
		parkName,
		reservationState,
		startAddress,
		startMileage,
	} = data || {};

	const endMileageValue = !endMileage
		? startMileage + distanceTravelled
		: endMileage; 

	const endWaypointColor = reservationState === ReservationStatus.CLOSED
		? '#fd2e25'
		: 'black';

	const tripEndIcon = reservationState === ReservationStatus.ONGOING
		? <MapCrosshairIcon fill={'#47525E'} width='24px' height='24px' />
		: <MapPinIcon fill={endWaypointColor} width='24px' height='24px' />;

	const parsedEndLocation = reservationState === ReservationStatus.ONGOING
		? (<a href={`https://www.google.com/maps/search/?api=1&query=${latitude}, ${longitude}`}
			target="_blank"
			rel="noopener noreferrer"
		>
			GPS: {latitude} {longitude}
		</a>)
		: endAddress;

	const waypointIconsLeftDiv = (
		<div className={classes.waypointIconsContainer}>
			<div className={classes.timeAndPlaceIcons}>
				<MapPinIcon fill='#00B67F' width='24px' height='24px' />
			</div>
			<div className={classes.timeAndPlaceIcons}>
				<ThreeDotsIcon fill='#5A6978' width='24px' height='24px' />
			</div>
			<div className={classes.timeAndPlaceIcons}>
				{tripEndIcon}
			</div>
		</div>
	);

	const addressAndOdometerRightDiv = (
		<div className={classes.startAndEndDetailsContainer}>
			<div >
				<Typography className={classnames(classes.timeAndPlaceText, advanced && classes.park)}>{advanced ? parkName && `Park: ${parkName}` : startAddress}</Typography>
				<div className={classnames(classes.timeAndPlaceText, classes.flexHorizontal)}>
					<span className={classnames(classes.alignRight, classes.spanStyle)}>Odometer: {startMileage} km</span>
				</div>
			</div>
			<div>
				{!advanced && <Typography className={classes.timeAndPlaceText}>{parsedEndLocation}</Typography>}
				<div className={classnames(classes.timeAndPlaceText, classes.flexHorizontal)}>
					<span className={classnames(classes.alignRight, classes.spanStyle)}>
						Odometer: {distanceTravelled > 0 ? endMileageValue : startMileage} km
					</span>
				</div>
			</div>
		</div>
	);

	return (
		<div className={classes.timeAndPlaceRoot}>
			<div className={classes.timeAndPlaceContainer}>
				{waypointIconsLeftDiv}
				{addressAndOdometerRightDiv}
			</div>
		</div>
	);
};

export default withStyles(styles)(LocationsWithWaypoint);