import React, { useState, useEffect } from 'react';
import accountService from '../../../../../services/account';
import Item from './Item';
import { useCallback } from 'react';

const ItemWrapper = ({
	billingItem = {},
	setError,
	setList,
	...props
}) => {
	const [sublist, setSublist] = useState();
	const [isExpanded, setIsExpanded] = useState(false);
	const [isSendingMail, setIsSendingMail] = useState(false);
	const [hasModal, setModal] = useState(false);

	useEffect(() => {
		const getDetails = async () => {
			try {
				const newSublist = await accountService.billingDetails(billingItem.code);

				setSublist(newSublist);
			} catch (e) {
				setError(e.message || `Could not get details for ${billingItem.code}`);
			};
		};

		isExpanded && !sublist && getDetails();
	}, [billingItem.code, isExpanded, sublist, setError]);

	const onSendMail = useCallback(async () => {
		try {
			setIsSendingMail(true);

			await accountService.billingSendEmail(billingItem);

			!billingItem?.sendIntent && setList(state => state.map(item => billingItem.code === item.code
				? { ...item, sendIntent: true }
				: { ...item }));
			
			setModal(true);
		} catch (e) {
			setError(e.message || `Could not send email for ${billingItem.code}`);
		} finally {
			setIsSendingMail(false);
		}
	}, [billingItem, setList, setError]);

	return (
		<Item
			{...props}
			billingItem={billingItem}
			hasModal={hasModal}
			isExpanded={isExpanded}
			isSendingMail={isSendingMail}
			onSendMail={onSendMail}
			setIsExpanded={setIsExpanded}
			setModal={setModal}
			sublist={sublist}
		/>
	);
};

export default ItemWrapper;