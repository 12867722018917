import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const assetChangeLocationCall = async (
  { changeAssetLocation, setErrorMovingAsset, setShowMoveAssetModal },
  dispatch,
  state,
  { locationId, carToken }
) => {
  dispatch(setErrorMovingAsset(null));
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "ASSET/CHANGE_LOCATION");
  try {
    const result = await wsCall({
      type: "ASSET",
      subType: "CHANGE_LOCATION",
      locationId: selectedLocation?.id,
      message: {
        locationId,
        carToken,
      },
    });
    if (!result.error) {
      dispatch(setShowMoveAssetModal(false));
      dispatch(setSuccess("Asset moved successfully."));
      dispatch(changeAssetLocation({ assetToken: carToken, locationId }));
    } else {
      if (result?.tag === "ASSET_IN_RESERVATION") {
        dispatch(setErrorMovingAsset("The asset has an active trip. Close the trip to move to another location."));
      } else {
        rh.error(result?.description || "Error moving asset to another location.");
      }
    }
  } catch (_ex) {
    rh.error("Error moving asset to another location.");
  }
  rh.close();
};

export default assetChangeLocationCall;
