import React, { useState, useEffect } from 'react';
import useSwitch from '../../../hooks/useSwitch';
import Carflows from './Carflows';
import withPermission from '../../../hoc/withPermission';

const CarflowsWrapper = ({
	flows,
	getCarclubs,
	getCarflowFeatures,
	getFeatures,
	getFlows,
	...props
}) => {

	useEffect(() => {
		getFeatures();
		getCarclubs();

		return () => getFlows();
	}, [getFeatures, getCarclubs, getFlows]);

	const [selectedFeature, setSelectedFeature] = useState();
	const [selectedFlow, setSelectedFlow] = useState();
	const [carclub, setCarclub] = useState();
	const create = useSwitch(false);

	const onSelectFlow = async data => {
		!!data && getCarflowFeatures(data);
		setSelectedFlow(data);
		create.setOn();
	};

	return (
		<Carflows
			{...props}
			carclub={carclub}
			flows={flows}
			getFlows={getFlows}
			handleCreate={create}
			hasCreate={create.state}
			onSelectFeature={setSelectedFeature}
			onSelectFlow={onSelectFlow}
			selectedFeature={selectedFeature}
			selectedFlow={selectedFlow}
			setCarclub={setCarclub}
		/>
	);
};

const permissions = [{ resource: ['carclub', 'carflows', 'read'] }];

export default withPermission(permissions)(CarflowsWrapper);