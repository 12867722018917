import React from "react";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { Create } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import withPermission from "../../../../hoc/withPermission";

function DevicesDataGridActions({ row, classes, onEdit, onDelete }) {
  const onEditHandler = (e) => {
    e.stopPropagation();
    onEdit && onEdit(row);
  };

  const onDeleteHandler = (e) => {
    e.stopPropagation();
    onDelete && onDelete(row);
  };

  return (
    <Grid container justifyContent="flex-end">
      <Grid item>
        <Tooltip title="Update" placement="left">
          <IconButton onClick={onEditHandler} size="small">
            <Create fontSize="inherit" color="primary" />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title="Delete" placement="left">
          <div>
            <IconButton onClick={onDeleteHandler} size="small" disabled={!row.deviceCanBeRemoved}>
              <DeleteIcon fontSize="inherit" color={row.deviceCanBeRemoved ? "primary" : "disabled"} />
            </IconButton>
          </div>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

const permissions = [{ resource: ["carclub", "teltonika", "edit"] }];

export default withPermission(permissions)(DevicesDataGridActions);
