const styles = () => ({
	root: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		height: '100%',
		width: '100%',
	},

	workspace: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		height: '100%',
		position: 'relative',
		width: '100%',
	},

	screen: {
		width: '100%',
		position: 'relative',
		overflow: 'auto',
		scrollbarWidth: 'thin',
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},
});

export default styles;