import { createSlice } from "@reduxjs/toolkit";
import userDetailsCall from "./_sliceCalls/userDetails";
import userGetLocationsCall from "./_sliceCalls/userGetLocations";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userLocations: null,
    details: null,
  },
  reducers: {
    setUserLocations: (state, action) => {
      state.userLocations = action.payload;
    },
    setUserDetails: (state, action) => {
      state.details = action.payload;
    },
  },
});

export const { setUserLocations } = userSlice.actions;

// SELECTS

export const selectLoader = (state) => Boolean(state.getIn(["core"])?.loader?.length > 0);
export const selectUserLocations = (state) => state.getIn(["user"])?.userLocations;
export const selectUserDetails = (state) => state.getIn(["user"])?.details;

// CALLS

export const userGetLocations = () => (dispatch, getState) => {
  userGetLocationsCall(userSlice.actions, dispatch, getState());
};

export const userDetails = () => async (dispatch, getState) => {
  return await userDetailsCall(userSlice.actions, dispatch, getState());
};

export default userSlice.reducer;
