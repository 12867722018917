import { wsCall, requestHelper } from "../../../app/coreSlice";

const promotionListCall = async ({ setPromotions, clearPromotions }, dispatch, state, { page, clear }) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "PROMOTION/LIST");
  if (clear) {
    dispatch(clearPromotions());
  }
  try {
    const result = await wsCall({
      type: "PROMOTION",
      subType: "LIST",
      locationId: selectedLocation?.id,
      message: {
        page,
      },
    });
    if (result && !result.error) {
      dispatch(setPromotions({ ...result, page }));
    }
  } catch (_ex) {
    console.error(_ex);
    rh.error("Error loading promotions!");
  }
  rh.close();
};

export default promotionListCall;
