import React from "react";
import { Typography } from "@material-ui/core";

import Button from '../../../UI/Buttons/CommonButtons';
import withPermission from '../../../../hoc/withPermission';

export default function InvoiceConfigHeader({
	classes,
	editMode,
	onEdit,
	onCancel,
	onSave,
}) {

	const editButton = !editMode && (
		<ButtonWithPermission>
			<Button
				buttonStyleName='filled'
				className={classes.button}
				key='edit_button'
				label='Edit'
				onClick={onEdit}
			/>
		</ButtonWithPermission>
	);

	const cancelButton = !!editMode && (
		<ButtonWithPermission>
			<Button
				buttonStyleName='void'
				className={classes.button}
				key='cancel_button'
				label='Cancel'
				onClick={onCancel}
			/>
		</ButtonWithPermission>
	);

	const saveButton = !!editMode && (
		<ButtonWithPermission>
			<Button
				buttonStyleName='void'
				className={classes.button}
				key='save_button'
				label='Update'
				onClick={onSave}
			/>
		</ButtonWithPermission>
	);

	return (
		<div className={classes.titleBar}>
			<Typography className={classes.title}>
				Invoice Configurations
			</Typography>
			{editButton}
			{cancelButton}
			{saveButton}
		</div>
	);
}

const ButtonWithPermission = withPermission([
	{ resource: ['carclub', 'invoiceConfigurations', 'edit'] },
])(({
	children,
}) => (
	<>
		{children}
	</>
));