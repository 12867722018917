import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";
import { assetAssetReservationCounts, assetList } from "../../asset/assetSlice";

const reservationNewReservationCreateBoCall = async (
  dispatch,
  state,
  { accountId, carToken, customerCode, parkId, startDate, endDate, additionalFeesList }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "RESERVATION/NEW_RESERVATION_CREATE_BO");
  try {
    const result = await wsCall({
      type: "RESERVATION",
      subType: "NEW_RESERVATION_CREATE_BO",
      locationId: selectedLocation?.id,
      message: {
        accountId,
        carToken,
        customerCode,
        parkId,
        startDate,
        endDate,
        additionalFeesList,
      },
    });

    if (!result.error) {
      dispatch(setSuccess("Reservation created successfully!"));
      setTimeout(() => {
        dispatch(assetAssetReservationCounts());
        dispatch(assetList({}));
      }, 300);
      // refresh reservations
    } else {
      rh.error(result?.description || "Error creating reservation.");
    }
  } catch (_ex) {
    rh.error("Error creating reservation.");
  }
  rh.close();
};

export default reservationNewReservationCreateBoCall;
