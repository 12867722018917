const styles = theme => ({
	root: {
	},

	typography: {
		fontSize: '10px',
		lineHeight: '20px',
	},

	expansionPanel: {
		width: '100%',
		boxShadow: 'none',
		fontSize: '12px',
		textAlign: 'center',
		padding: '0 !important',
		margin: '0 !important',
		minHeight: '0 !important',
		transition: 'none !important',
	},

	details: {
		position: 'relative',
		padding: '0px 5px 5px',
		display: 'inherit',
		height: '100%',
		boxShadow: 'none',
	},

	expansionSummary: {
		padding: '0 !important',
		margin: '0 !important',
		minHeight: '0 !important',
		transition: 'none !important',
	},

	expansionRoot: {
		padding: '0 !important',
		margin: '0 !important',
		minHeight: '0 !important',
		transition: 'none !important',
	},

	expanded: {
		padding: '0 !important',
		margin: '0 !important',
		minHeight: '0 !important',
		transition: 'none !important',
	},
	
});

export default styles;