import { ReactComponent as IncidentIcon } from '../../../assets/IncidentsApp/IncidentIcon.svg';

const button = (icon, text, options, right = false) => {
	return {
		icon,
		text,
		right,
		options,
	};
};

const buttonOption = (text, filter, right = false) => {

	return {
		text,
		right,
		filter,
	};
};

export const incidentTypeButtonOptions = incidentTypes => [
	{text: 'All incident types'},
	...incidentTypes]
	.map(({incidentType, text}) => buttonOption(text || incidentType, incidentType ));

export const incidentTypeButton = incidentTypes => button(IncidentIcon, 'All incident type', incidentTypeButtonOptions(incidentTypes));