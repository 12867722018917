import React from 'react';
import {
	List as MUIList,
	ListItem,
	Typography,
	withStyles,
} from '@material-ui/core';
import {
	ExpandMore as ExpandIcon,
	CheckCircleOutline as AcceptIcon,
	RemoveCircle as RemoveIcon,
} from '@material-ui/icons';
import classnames from 'classnames';
import styles from './List.styles';

const List = ({
	array,
	classes,
	isExpanded,
	newMessages,
	onAdd,
	onRemove,
	onSelect,
	selected,
	title,
	toggleExpansion,
}) => {

	const icon = (
		<ExpandIcon
			className={classnames(classes.icon, isExpanded && classes.iconExpanded)}
			onClick={toggleExpansion}
		/>
	);

	const header = (
		<Typography className={classes.header}>
			{title} {icon}
		</Typography>
	);

	const listItems = Array.isArray(array) && array.map((field, index) => (
		<ListItem
			className={classes.listItem}
			disableGutters
			key={`list_key_${field.target}_${index}`}
			onClick={() => onSelect(field)}
		>
			{
				(newMessages?.has(field.groupId) || newMessages?.has(field.target))
					&& <span className={classes.redDot}></span>
			}
			<Typography
				align='left'
				className={classnames(classes.listItemText,
					selected && (selected === field.target || selected === field.groupId)
					&& classes.selected)}
			>
				{field.nickname || field.phone || field.name}
			</Typography>
			<AcceptIcon
				className={classnames(classes.addIcon, !field?.pending && classes.hidden)}
				onClick={() => field?.pending && onAdd({
					customerCode: field.target,
					inviteCode: field?.inviteCode,
				})}
			/>
			<RemoveIcon
				className={classnames(classes.addIcon, !onRemove && classes.hidden)}
				onClick={() => onRemove(field.target)}
			/>
		</ListItem>
	));

	const list = (
		<MUIList className={classnames(classes.list, !isExpanded && classes.hidden)} disablePadding>
			{listItems}
		</MUIList>
	);

	return (
		<div className={classes.root}>
			{header}
			{list}
		</div>
	);
};

export default withStyles(styles)(List);