import React from 'react';
import List from '../../../../UI/List/List';
import {
	withStyles,
} from '@material-ui/core';
import Item from './Item/Item';
import listConfig from './listConfig';
import styles from './List.styles';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

const TasksLists = ({
	classes,
	className,
	onRemove,
	onTaskSelect,
	selectedTaskIndex,
	tasks,
}) => {
  const screenSize = useSelector((state) => state.getIn(["app", "windowWidth"]));

	const list = Array.isArray(tasks) && tasks
		.sort((a, b) => a.createDate - b.createDate)
		.map((task, index) => {
			return (
				<Item
					key={`${index}_${task.code}`}
					onRemove={onRemove}
					task={task}
					selected={selectedTaskIndex === index}
					onSelect={() =>
						onTaskSelect(index)
					}
				/>
			);
		});

	return (
		<List
			className={classnames(classes.root, className)}
			disableGutters={true}
			headerFields={listConfig(screenSize)}
		>
			{list}
		</List>
	);
};

export default withStyles(styles)(TasksLists);