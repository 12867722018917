import React, { useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid, Divider, Tabs, Tab } from "@material-ui/core";

import { styles } from "./ReportsApp.styles";
import BatterySwap from "./BatterySwap/BatterySwap";

function ReportsApp({ classes }) {
  const [selectedReport, setSelectedReport] = useState(0);

  return (
    <div className={classes.root}>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Typography className={classes.title}>Reports</Typography>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={4} className={classes.content}>
            <Grid item>
              <Tabs
                orientation="vertical"
                value={selectedReport}
                onChange={(e, v) => setSelectedReport(v)}
                className={classes.tabs}
              >
                <Tab label="Battery Swap" />
                {/* <Tab label="Item Two" />
                <Tab label="Item Three" /> */}
              </Tabs>
            </Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            <Grid item>
              {selectedReport === 0 && <BatterySwap />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(ReportsApp);