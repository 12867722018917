import React from 'react';
import SearchBar from '../../../UI/Searchbar/Searchbar';
import {
	withStyles,
} from '@material-ui/core';
import styles from './TripsFilter.styles';

const label = 'Member code';

const TripsFilters = ({
	classes,
	filter,
	onSearch,
	setFilter,
}) => {

	const searchBar = (
		<SearchBar
			{...{
				filter,
				label,
				onSearch,
				setFilter,
			}}
		/>
	);

	const root = (
		<div className={classes.root}>
			{searchBar}
		</div>
	);

	return root;
};

export default withStyles(styles)(TripsFilters);