import React from 'react';
import {
	withStyles,
} from '@material-ui/core';
import styles from './PricingPlanListScreen.styles';
import PricingPlanList from '../PricingPlanList/PricingPlanList';
import ConfirmationModal from '../../UI/ConfirmationModal/ConfirmationModal';
import PricingPlanBuilder from '../PricingPlanBuilder/PricingPlanBuilderWrapper';
import Modal from '../../UI/Modal/Modal';

const PricingPlanListScreen = ({
	classes,
	currentScreenSize,
	asyncGetPPDetails,
	editMode,
	onCustomersOrZonesEdit,
	placeholder,
	placeholderMaxFlatRate,
	placeholderSetters,
	pricingPlanArray,
	selectedPP,
	selectedPricingPlanId,
	setSelectedPricingPlanId,
	selectedRateToApply,
	modal,
	setModal,
	setPricingPlanToChange,
	setSelectedPP,
	setSelectedRateToApply,
	setShowPPCalendar,
	showPPCalendar,
	showPricingTable,
	toggleShowPricingTable,
	updateHandler,
	upsertPPHandler,
	onRemoveHandler,
	createMode,
	ratesList,
	setRatesList,
	getMoreRows,
	error,
}) => {

	const calendarModalContent = (
		<PricingPlanBuilder
			closeHandler={() => {
				placeholderSetters.resetPlaceholder();
				placeholderMaxFlatRate.resetPlaceholder();
				setShowPPCalendar(false);
				setSelectedPP(null);
			}}
			currentScreenSize={currentScreenSize}
			placeholder={placeholder}
			placeholderMaxFlatRate={placeholderMaxFlatRate}
			placeholderSetters={placeholderSetters}
			selectedPP={selectedPP}
			selectedRateToApply={selectedRateToApply}
			setSelectedPP={setSelectedPP}
			setSelectedRateToApply={setSelectedRateToApply}
			setShowPPCalendar={setShowPPCalendar}
			showPricingTable={showPricingTable}
			toggleShowPricingTable={toggleShowPricingTable}
			upsertPPHandler={upsertPPHandler}
			ratesList={ratesList}
			setRatesList={setRatesList}
			error={error}
		/>
	);

	const ppCalendar = showPPCalendar && (
		<Modal
			id='calendarModal'
			className={classes.calendar}
			tabIndex={"0"}
			closeHandler={() => setShowPPCalendar(false)}
			disableCloseIcon={true}
			overrideRetardedEscape={true}
			content={calendarModalContent}
			onKeyDown={e => {
				//remove this or make it work
				if (e.key === 'Escape') {
					setShowPPCalendar(false);
				}
			}}
		/>


	);


	const pricingPlanList = (
		<PricingPlanList
			asyncGetPPDetails={asyncGetPPDetails}
			currentScreenSize={currentScreenSize}
			pricingPlanArray={pricingPlanArray}
			placeholderSetters={placeholderSetters}
			onSelect={setSelectedPP}
			selectedPP={selectedPP}
			onEdit={editMode}
			onChange={setPricingPlanToChange}
			onRemove={onRemoveHandler}
			onUpdate={updateHandler}
			onCustomersOrZonesEdit={onCustomersOrZonesEdit}
			openCalendarHandler={() => {
				setShowPPCalendar(true);
			}}
			closeModals={() => setModal()}
			createMode={createMode}
			setModal={setModal}
			setSelectedPricingPlanId={setSelectedPricingPlanId}
			getMoreRows={getMoreRows}
		/>
	);



	const removePricingPlanConfirmModal = modal === 'ConfirmDeletePP'
		&& (
			<ConfirmationModal
				confirmHandler={() => {
					onRemoveHandler(selectedPricingPlanId);
					setModal();
				}}
				confirmCustomLabel='Confirm'
				cancelHandler={() => setModal()}
				cancelCustomLabel='Cancel'
				title='Remove pricing plan'
				text='Are you sure you want to remove this pricing plan?'
			/>
		);

	const pricingPlanListScreen = (
		<div className={classes.root}>
			{removePricingPlanConfirmModal}
			{ppCalendar}
			<div className={classes.list}>
				{pricingPlanList}
			</div>
		</div>
	);

	return pricingPlanListScreen;
};

export default withStyles(styles)(PricingPlanListScreen);