export const styles = () => ({
  root: {
    "& .CdgRow": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#ddd",
      },
    },
  },
  datagrid: {
    height: "calc(100vh - 140px)",
    "& table": {
      padding: 0,
    },
  },
  icon: {
    color: "#009AC9",
  },
});
