const styles = (theme) => ({

	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
	},

	workspace: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'no-wrap',
		position: 'relative',
	},

	menu: {
	},

	searchReservation: {
		width: '100%',
		display: 'flex',
	},

	searchBar: {
		width: '100%',
		display: 'inline-flex',
		paddingBottom: theme.spacing(1),
	},

	searchDates: {
		display: 'inline-flex',
	},

	pricingPlanArea: {
		width: '100%',
		height: '100%',
		display:'flex',
		flexDirection: 'column',
		overflow: 'auto',
	},

	listArea: {
		height: '100%',
		maxHeight: 'calc(95vh - 80px)',
		padding: '0px 16px',
	},
	
	listAreaRates: {
		height: '100%',
		padding: '0px 16px',
		maxHeight: 'calc(95vh - 18px)',
	},

	sideScreenArea: {
		width: '350px',
		minWidth: '350px',
		height: '100%',
		backgroundColor: '#F3F3F3',
		overflowY: 'auto',
		scrollbarWidth: 'thin', //FIREFOX
		//CHROME
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},

	defaultSideScreen: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},

	actionBar: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	createButton: {
		display: 'flex',
		flexDirection: 'row-reverse',
		width: 'auto',
		alignItems: 'center',
	},
	
	createIcon: {
		padding: '0px 16px 8px 0',
		cursor: 'pointer',
		width: 27,
		height: 27,
	},

	editIcon: {
		fill: 'rgb(0, 155, 204)',
	},

	typoWithIcon: {
		height: 30,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-around',
		width: '70%',
	},

	drawer: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
	},

	groupList: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: '100%',
		padding: '15px',
		margin: 0,
		minHeight: '100%',
	},

	groupListTitle: {
		fontSize: '20px',
		height: 60,
	},

	groupListSearch: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		height: '52px',
		width: '100%',
		paddingBottom: 10,
		color: 'black',
	},

	groupsHeader: {
		width: 'calc(100% - 1px)',
	},

	groupsHeaderItem: {
		boxSizing: 'border-box',
		backgroundColor: '#fff',
		height: 35,
		paddingTop: 8,
		marginTop: 0,
		marginRight: 0,
		paddingLeft: 4,
		color: 'grey',
	},

	groupFilterRoot: {
		margin: 0,
		padding: 0,
		maxWidth: 330,
	},

	groupListItem: {
		height: 35,
		boxSizing: 'border-box',
		paddingLeft: 4,
		borderBottom: '1px solid rgba(224, 224, 224, 1)',
		fontSize: '10px',
	},

});

export default styles;