import { useEffect, useRef } from 'react';

// FIXME do unit tests
// TODO use async await where possible
export function useCancellablePromise() {

	const promises = useRef();

	useEffect(() => {
		promises.current = promises.current || [];

		return function cancel() {

			promises.current.forEach(promise => {
				promise.cancel();
			});
			promises.current = [];
		};
	}, []);

	function makeCancellablePromise(promise) {
		const cancellablePromise = _makeCancellable(promise);
		promises.current.push(cancellablePromise);
		return cancellablePromise.promise;
	}

	return { makeCancellablePromise };
}

export function _makeCancellable(promise) {

	let isCancelled = false;

	const wrappedPromise = new Promise((resolve, reject) => {

		if (typeof promise === 'undefined') {
			return null;
		}

		promise.then(value => {
			isCancelled
				? reject(new Error({ isCancelled }))
				: resolve(value);
		}).catch(error => reject(error));
	});

	return {
		promise: wrappedPromise,
		cancel() {
			isCancelled = true;
		},
	};
}