import * as listItemTypes from '../../../UI/List/ListItem/ListItemTypes';
import * as screenSize from '../../../../model/ScreenSize/ScreenSize';

const usersFields = currentScreenSize => {
	const width = {
		[screenSize.DESKTOP]: 111,
		[screenSize.TABLET]: 78,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	const maxWidth = {
		[screenSize.DESKTOP]: 166,
		[screenSize.TABLET]: 136,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	const height = {
		[screenSize.DESKTOP]: 35,
		[screenSize.TABLET]: 45,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];
	
	return [
		{
			key: 'icons',
			label: ' ',
			order: 99,
			pinned: true,
			width,
			height,
			type: listItemTypes.COMPONENT,
			flexDirection: 'row-reverse',
		},
		{
			key: 'name',
			label: 'Name',
			order: 1,
			pinned: true,
			width,
			maxWidth: width,
			height,
			type: listItemTypes.COMPONENT,
		},
		{
			key: 'email',
			label: 'Email',
			order: 2,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'role',
			label: 'Role',
			order: 3,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'createDate',
			label: 'Created',
			order: 4,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'enabled',
			label: 'Enabled',
			order: 6,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.COMPONENT,
		},
	];
};

export default usersFields;