const styles = theme => ({

	root: {
		height: '100%',
		position: 'relative',
		width: '100%',
	},

	mapOptionsRoot: {
		position: 'absolute',
		top: '14vh',
		left: 9,
		width: 170,
		minHeight: 28,
		height: 'auto',
		padding: 2,
		backgroundColor: 'white',
		boxShadow: theme.shadows[7],
		borderRadius: 5,
		zIndex: 999,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},

	iconsRoot: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},

	expandedRoot: {
		width: 'inherit',
		height: 'inherit',
		backgroundColor: 'white',
		boxShadow: theme.shadows[7],
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		padding: '2px 6px',
		boxSizing: 'border-box',
	},

	baseOptions: {
		width: 'inherit',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	option: {
		color: 'gray',
		cursor: 'pointer',
		padding: 2,
	},

	selectedOption: {
		color: '#009AC9',
		cursor: 'pointer',
	},

	exitDiv: {
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		position: 'absolute',
		top: '1vh',
		right: '1vw',
		zIndex: 999,
		width: 23,
		backgroundColor: 'silver',
		boxShadow: theme.shadows[7],
		borderRadius: 5,
		padding: 2,
	},

	exitIcon: {
		color: 'gray',
		cursor: 'pointer',
	},

	filterExpander: {
		zIndex: 999,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		cursor: 'pointer',
	},

	//TIME PICKERS

	timePickerRoot: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '2px 0 0 0',
	},

	timeFilter: {
		width: '100%',
	},

	picker: {
		backgroundColor: '#FFFFFF',
		border: '1px solid #DDDDDD',
		borderRadius: '2px',
		boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.12)',
		width: 104,
		height: '32px',
	},

	input: {
		marginTop: '0 !important',
		padding: '3px !important',
		// FIXME some of these should be given by the Mui.theme
		color: '#89898B',
		fontSize: '12px',
		fontWeight: '400',
		lineHeight: '14px',
		textAlign: 'left',
	},

	inputIcon: {
		padding: '2px',
	},

	pickerLabel: {
		color: '#4C4C4E',
		// FIXME some of these should be given by the Mui.theme
		fontSize: '12px',
		fontWeight: '400',
		lineHeight: '34px',
		width: '32px',
		textAlign: 'left',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},

	// SELECTORS

	selectorsRoot: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},

	flexHorizontally: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		height: 35,
		width: '100%',
	},

	alignSelect: {
		marginLeft: '20px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},

	selectLabel: {
		fontSize: '12px',
		height: 30,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
	},

	noShadow: {
		boxShadow: 'none',
	},

	labelAndInputCheckbox: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		padding: 2,
		alignItems: 'end',
		paddingBottom: 0,
		boxSizing: 'border-box',
		height: 22,
	},
	checkboxLabel: {
		fontSize: '12px',
		height: 35,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'end',
	},
	checkbox: {
		padding: '0px',
		height: 22,
		alignItems: 'end',
	},
});

export default styles;