import React from 'react';
import Flows from './Flows/FlowsWrapper';
import Create from './Create/CreateWrapper';
import Features from './Features/FeaturesWrapper';
import {
	withStyles,
} from '@material-ui/core';
import styles from './Carflows.styles';
import classnames from 'classnames';

const Carflows = ({
	carclub,
	carclubs,
	classes,
	className,
	features,
	flows,
	getFlows,
	handleCreate,
	hasCreate,
	onSave,
	onSelectFeature,
	onSelectFlow,
	selectedFeature,
	selectedFlow,
	setCarclub,
	updateFlow,
	username,
}) => {

	const carflowsDiv = (
		<Flows
			carclubOptions={carclubs}
			carclubs={carclub}
			flows={flows}
			getFlows={getFlows}
			handleCreate={handleCreate}
			onSelect={onSelectFlow}
			selected={selectedFlow}
			setCarclub={setCarclub}
		/>
	);

	const create = hasCreate && (
		<Create
			carclub={carclub}
			currentFeature={selectedFeature}
			features={features}
			flow={selectedFlow}
			handleCreate={handleCreate}
			onSave={onSave}
			selectedFlow={!!selectedFlow && flows[selectedFlow.id]}
			setFlow={onSelectFlow}
			updateFlow={updateFlow}
			username={username}
		/>
	);

	const createDiv = (
		<div className={classes.create}>
			{create}
		</div>
	);

	const featuresComponent = (
		<Features
			features={features}
			onSelect={onSelectFeature}
			selected={selectedFeature}
		/>
	);

	const carflows = (
		<div className={classnames(classes.root, className)}>
			{carflowsDiv}
			{createDiv}
			{featuresComponent}
		</div>
	);

	return carflows;
};

export default withStyles(styles)(Carflows);