import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const promotionCancelCall = async (
  { promotionList },
  dispatch,
  state,
  { promotionId }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "PROMOTION/CANCEL");
  try {
    const result = await wsCall({
      type: "PROMOTION",
      subType: "CANCEL",
      locationId: selectedLocation?.id,
      message: {
        promotionId
      },
    });
    if (result && !result.error) {
      dispatch(setSuccess("Promotion canceled successfully"));
      dispatch(promotionList({ page: 1, clear: true }));
    } else {
      rh.error(result?.description || "Error cancelling promotion!");
    }
  } catch (_ex) {
    rh.error("Error cancelling promotion!");
  }
  rh.close();
};

export default promotionCancelCall;
