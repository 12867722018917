import React, { Fragment } from 'react';
import {
	Check as CheckIcon,
} from '@material-ui/icons';
import DetailsDiv from '../../../UI/DetailsDiv/DetailsDiv';
import DetailsRow from '../../../UI/DetailsDiv/DetailsRow/DetailsRow';
import {
	withStyles,
} from '@material-ui/core';
import styles from './Details.styles';

const LegalDocuments = ({
	classes,
	info,
}) => {
	const details = Array.isArray(info) && info.map(({ acept, accept, name, mandatory, version, type }, index) => {
		const label = `${name} - V.${version}${mandatory ? ' *' : ''}`;

		const tooltip = `${type}${mandatory ? ' / *mandatory' : ''}`;

		const row = (
			<DetailsRow
				label={label}
				detail={
					acept || accept ? <CheckIcon className={classes.checkIcon} /> : ' '
				}
				tooltip={tooltip}
			/>
		);

		return (
			<Fragment key={`documents_${index}`}>
				{row}
			</Fragment>
		);
	});

	return (
		<DetailsDiv title='Legal Documents'>
			{details}
		</DetailsDiv>
	);
};

export default withStyles(styles)(LegalDocuments);