import React from 'react';
import Notifications
	from '../../components/Carclub/Notifications/NotificationsWrapper';
import Configurations from '../../components/Carclub/Configurations/ConfigurationsWrapper';
import AppConfigurations from '../../components/Carclub/AppConfigurations/AppConfigurationsWrapper';
import InvoiceConfig from '../../components/Carclub/InvoiceConfig/InvoiceConfig';
import Banners from '../../components/Carclub/Banner/BannerWrapper';
import Users from '../../components/Carclub/Users/UsersWrapper';
import Roles from '../../components/Carclub/Roles/RolesWrapper';
import PermissionsWorkspace from '../../components/Carclub/Permissions/PermissionsWorkspaceWrapper';
import VerticalMenuBar from '../../components/VerticalMenuBar/VerticalMenuBar';
import * as screenNames from '../../model/Carclub/carclubScreens';
import { withStyles } from '@material-ui/styles';
import styles from './CarclubApp.styles';
import classnames from 'classnames';
import DeviceTeltonikaList from '../../features/device/DeviceTeltonikaList/DeviceTeltonikaList';
import Carflows from '../../components/Carclub/Carflows/CarflowsWrapper';

const CarclubApp = ({
	classes,
	carclubs,
	className,
	currentScreen,
	currentScreenSize,
	features,
	flows,
	getCarclubs,
	getCarflowFeatures,
	getFeatures,
	getFlows,
	getNotifications,
	notificationList,
	removeNotification,
	updateNotification,
	setCurrentScreen,
	setTheme,
	updateFlow,
	username,
	verticalMenuButtons,
	defaultLocation,
}) => {

	const menuButtonOnClick = {
		[screenNames.DEFAULT_CONFIGURATIONS]: () =>
			setCurrentScreen(screenNames.DEFAULT_CONFIGURATIONS),
		[screenNames.LOCAL_CONFIGURATIONS]: () =>
			setCurrentScreen(screenNames.LOCAL_CONFIGURATIONS),
		[screenNames.NOTIFICATIONS]: () =>
			setCurrentScreen(screenNames.NOTIFICATIONS),
		[screenNames.USERS]: () => setCurrentScreen(screenNames.USERS),
		[screenNames.ROLES]: () => setCurrentScreen(screenNames.ROLES),
		[screenNames.PERMISSIONS]: () => setCurrentScreen(screenNames.PERMISSIONS),
		[screenNames.BANNERS]: () => setCurrentScreen(screenNames.BANNERS),
		[screenNames.APP_CONFIGURATIONS]: () => setCurrentScreen(screenNames.APP_CONFIGURATIONS),
		[screenNames.INVOICE]: () => setCurrentScreen(screenNames.INVOICE),
		[screenNames.TELTONIKA]: () => setCurrentScreen(screenNames.TELTONIKA),
		[screenNames.CARFLOWS]: () => setCurrentScreen(screenNames.CARFLOWS),
	};

	const menuButtons = verticalMenuButtons
		.map((button) => {
			button['onClick'] = menuButtonOnClick[button.text];
			button.color = currentScreen === button.text && 'rgb(64, 149, 194)';
			button.fill = currentScreen === button.text && 'rgb(64, 149, 194)';
			return button;
		});

	const menu = <VerticalMenuBar buttons={menuButtons} />;

	const screens = {
		[screenNames.DEFAULT_CONFIGURATIONS]: <Configurations isDefaultConfigs={true}/>,
		[screenNames.LOCAL_CONFIGURATIONS]: <Configurations defaultLocation={defaultLocation} isDefaultConfigs={false}/>,
		[screenNames.NOTIFICATIONS]: (
			<Notifications
				currentScreenSize={currentScreenSize}
				getNotifications={getNotifications}
				removeNotification={removeNotification}
				updateNotification={updateNotification}
				notifications={notificationList}
				defaultLocation={defaultLocation}
			/>
		),
		[screenNames.USERS]: (
			<Users
				currentScreenSize={currentScreenSize}
			/>
		),
		[screenNames.ROLES]: (
			<Roles
				currentScreenSize={currentScreenSize}
			/>
		),
		[screenNames.PERMISSIONS]: (
			<PermissionsWorkspace
				currentScreenSize={currentScreenSize}
			/>
		),
		[screenNames.BANNERS]: <Banners  defaultLocation={defaultLocation}/>,
		[screenNames.APP_CONFIGURATIONS]: <AppConfigurations/>,
		[screenNames.INVOICE]: <InvoiceConfig/>,
		[screenNames.TELTONIKA]: <DeviceTeltonikaList/>,
		[screenNames.CARFLOWS]: (
			<Carflows
					carclubs={carclubs}
					features={features}
					flows={flows}
					getCarflowFeatures={getCarflowFeatures}
					getCarclubs={getCarclubs}
					getFeatures={getFeatures}
					getFlows={getFlows}
					updateFlow={updateFlow}
					username={username}
			/>
	),
	};

	const screen = screens[currentScreen];


	const carclubApp = (
		<div className={classnames(classes.root, className)}>
			<div className={classes.workspace}>
				{menu}
				<div className={classes.screen}>
					{screen}
				</div>
			</div>
		</div>
	);

	return carclubApp;
};

export default withStyles(styles)(CarclubApp);