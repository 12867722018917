import createModel from "../createModel";

const valuesMap = {
  ON_REGISTRATION: "Sign-Up",
  ON_TRIP: "Trip",
};

const getBoolValue = (value, defaultValue = false) => {
  if (typeof value === "undefined") {
    return defaultValue;
  }
  return value;
};

const getIntegerValue = (value, defaultValue = "0") => {
  if (typeof value === "undefined") {
    return defaultValue;
  }
  return value;
};

const getSelectValue = (value, defaultValue) => {
  if (typeof value === "undefined") {
    return defaultValue;
  }
  return value;
};

class CarclubConfigs {
  constructor({
    customize,
    infoCompany,
    infoUser,
    secrets,
    infoApp,
    awsS3,
    CONFIGURATION,
    DEFAULT_LOCATION,
    paymentGWs,
    invoiceGWs,
    BILLING,
    REGISTRATION,
    // SECRETS,
    TRIP,
    originalConfigs,
    // LOCATIONS,
  } = {}) {
    createModel.call(this, {
      general: {
        ...customize,
        ...infoCompany,
        ...infoUser,
        ...secrets,
        ...infoApp,
        awsS3_provider: awsS3?.provider,
        awsS3_key: awsS3?.key || secrets.S3AccessKey,
        awsS3_secret: awsS3?.secret || secrets.S3AccessSecretKey,
        awsS3_region: awsS3?.region || secrets.region_config,
        awsS3_private_bucket: awsS3?.private_bucket || secrets.bucket,
        awsS3_public_bucket: awsS3?.public_bucket || secrets.bucket,
        awsS3_invoice_bucket: awsS3?.invoice_bucket || secrets.bucket,
        awsS3_endtrip_bucket: awsS3?.endtrip_bucket || secrets.bucket,
        awsS3_app_bucket: awsS3?.app_bucket || secrets.bucket,
        defaultLocation: DEFAULT_LOCATION,
        smsNotification: CONFIGURATION?.smsNotification,
        operationType: getSelectValue(CONFIGURATION?.operationType, "S"),
        damageReportStatus: getBoolValue(CONFIGURATION?.damageReportStatus),
        periodicRefreshInterval: getIntegerValue(CONFIGURATION?.periodicRefreshInterval, "1"),
      },
      fleet: {},
      trips: {
        longTripTime: getIntegerValue(CONFIGURATION?.maxTime),
        expireUnusedTripAfter: getIntegerValue(TRIP?.expireUnusedTripAfter),
        blockNewTripForSameCar: getIntegerValue(TRIP?.blockNewTripForSameCar),
        lateDelivery: getIntegerValue(TRIP?.lateDelivery),
        activateGoldpinAfter: getIntegerValue(TRIP?.activateGoldpinAfter),
        allowDifferentEndPark: getBoolValue(TRIP?.allowDifferentEndPark),
        chargeGracePeriod: getBoolValue(TRIP?.chargeGracePeriod, true),
        timeoutAutomaticTripClosure: getIntegerValue(TRIP?.timeoutAutomaticTripClosure),
        sendNotificationsToClient: getBoolValue(TRIP?.sendNotificationsToClient),
        expireUnusedPlannedTripAfter: getIntegerValue(TRIP?.expireUnusedPlannedTripAfter),
        percFleetPlannedTrip: getIntegerValue(TRIP?.percFleetPlannedTrip),
        maxFutureDaysPlannedTrip: getIntegerValue(TRIP?.maxFutureDaysPlannedTrip),
        thresholdAssignPlannedTrip: getIntegerValue(TRIP?.thresholdAssignPlannedTrip),
        warningBattery: getIntegerValue(TRIP?.warningBattery),
        criticalBattery: getIntegerValue(TRIP?.criticalBattery),
        warningUnused: getIntegerValue(TRIP?.warningUnused),
        criticalUnused: getIntegerValue(TRIP?.criticalUnused),
        warningRental: getIntegerValue(TRIP?.warningRental),
        criticalRental: getIntegerValue(TRIP?.criticalRental),
        warningGeofence: getIntegerValue(TRIP?.warningGeofence),
        assetUnavailableWithCriticalBattery: getBoolValue(TRIP?.assetUnavailableWithCriticalBattery),
        holdAmount: getIntegerValue(TRIP?.holdAmount),
        warningSpeed: getIntegerValue(TRIP?.warningSpeed),
      },
      warnings: {},
      payments: {
        paymentGWs,
        invoiceGWs,
        ...BILLING,
        pricingEngine: CONFIGURATION?.simulator,
      },
      registration: {
        withoutReferralFeeConfig: REGISTRATION?.normal?.feeConfig,
        bonusConditionWithoutReferral: valuesMap[REGISTRATION?.normal?.bonusCondition],
        withoutReferralBonusConfig: REGISTRATION?.normal?.bonusConfig,
        withoutReferralBonusExpire: REGISTRATION?.normal?.bonusExpireDays,

        withReferralFeeConfig: REGISTRATION?.referral?.feeConfig,
        withReferralBonusConfig: REGISTRATION?.referral?.bonusConfig,
        withReferralBonusExpire: REGISTRATION?.referral?.bonusExpireDays,
        withReferralBonusConfigForReferrer: REGISTRATION?.referral?.referrerBonusConfig,
        withReferralBonusExpireForReferrer: REGISTRATION?.referral?.referrerBonusExpireDays,
        bonusCondition: valuesMap[REGISTRATION?.referral?.bonusCondition],
        referrerBonusCondition: valuesMap[REGISTRATION?.referral?.referrerBonusCondition],
      },
      originalConfigs,
      // LOCATIONS,
    });
  }
}

export default CarclubConfigs;
