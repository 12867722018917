import makeActionCreator from '../../utils/makeActionCreator';
import createReducer from '../createCustomReducer';

const orderList = (state, array, {orderFunction, asc}, withSelected) => {

	let list = Array.isArray(array) ? array : Object.values(array) || [];

	if (!orderFunction){

		return {
			list,
			selected: withSelected ? list[0] : state.selected,
		};
	};
	

	if (asc) {
		
		list = list.sort(
			(a, b) => orderFunction(b, a)
		);
		return {
			list,
			selected: withSelected ? list[0] : state.selected,
		};
	};

	list = list.sort(orderFunction);

	return {
		list,
		selected: withSelected ? list[0] : state.selected,
	};
};

export const newState = {
	list: [],
	selected: undefined,
};

const types = {
	SET_LIST: 'SET_LIST',
	SET_LIST_WITH_SELECTED: 'SET_LIST_WITH_SELECTED',
	SET_SELECTED: 'SET_SELECTED',
};

export const setList = makeActionCreator(types.SET_LIST, 'list', 'sort');
export const setListWithSelected = makeActionCreator(types.SET_LIST_WITH_SELECTED, 'list', 'sort');
export const setSelected = makeActionCreator(types.SET_SELECTED, 'selected');

const setSelectedReducer = (action, state) => ({
	...state,
	selected: action.selected,
});

const createListReducer = (dataTypes, defaultState = newState) => { 

	const setListWithSelectedReducer = (action, state) => {

		const sort = action.sort
			&& {orderFunction: dataTypes[action.sort.orderBy], asc: action.sort.asc};

		return orderList(state, action.list, sort, true);
	};

	const setListReducer = (action, state) => {

		const sort = action.sort
			&& {orderFunction: dataTypes[action.sort.orderBy], asc: action.sort.asc};

		return orderList(state, action.list, sort);
	};

	const actions = dispatch => ({
		setList: (list, sort) => dispatch(setList(list, sort)),
		setListWithSelected: (list, sort) => dispatch(setListWithSelected(list, sort)),
		setSelected: selected => dispatch(setSelected(selected)),
	});

	const reducer = (state, action) => ({
		[types.SET_LIST]: setListReducer,
		[types.SET_LIST_WITH_SELECTED]: setListWithSelectedReducer,
		[types.SET_SELECTED]: setSelectedReducer,
	}[action.type](action, state));

	return createReducer({ reducer, actions, defaultState })(defaultState);
};

export default createListReducer;