import React from 'react';
import DetailsItem from '../DetailsItem/DetailsItem';
import List from '../../UI/List/List';
import {
	withStyles,
} from '@material-ui/core';
import styles from './ResultList.styles';

export const ResultListMemo = React.memo(props => ResultList({ ...props }));

const ResultList = ({
	classes,
	results,
	resultsInPP,
	addOrRemoveHandler,
	detailsFields,
	modal,
	newPricingPlanApp,
}) => {

	// RESULT's Items
	const detailsList = !!results && Array.isArray(results)
		&& results.map(detail => {
			const isInPP = !!resultsInPP.find(detailInPP => detailInPP.id === detail.id);
			return (
				<DetailsItem
					key={detail.code || detail.id}
					detail={detail}
					isInPP={isInPP}
					addOrRemoveHandler={addOrRemoveHandler}
					modal={modal}
					newPricingPlanApp={newPricingPlanApp}
				/>
			);
		}).filter(detail => !detail.props.isInPP);

	const detailsInPP = !!resultsInPP && Array.isArray(resultsInPP)
		&& resultsInPP
			.filter(detail => !detail?.hide)
			.map(detail => {
				return (
					<DetailsItem
						key={detail.code || detail.id}
						detail={detail}
						isInPP={true}
						addOrRemoveHandler={addOrRemoveHandler}
						modal={modal}
					/>
				);
			});

	// RESULT's LIST
	const list = !!detailsFields && (
		<List
			className={classes.list}
			headerFields={detailsFields}
		>
			{detailsInPP}
			{detailsList}
		</List>
	);

	return list;
};

export default withStyles(styles)(ResultListMemo);

