import React from 'react';
import Button from '../../../UI/Buttons/CommonButtons';
import SearchList from '../../../UI/SearchList/SearchListWrapper';
import {
	FormControl,
	InputBase,
	InputLabel,
	Select,
	Typography,
	withStyles,
} from '@material-ui/core';
import {
	AddCircle as AddGroupIcon,
	RemoveCircle as RemoveGroupIcon,
} from '@material-ui/icons';
import styles from './UpdateRole.styles';
import classnames from 'classnames';
import { currencyFormatter } from '../../../../services/formatter';

const UpdateRole = ({
	classes,
	error,
	hasRequest,
	onCancel,
	onChange,
	onSave,
	permissions,
	placeholder,
	getLocations,
	locationListRef,
	onChangeHandlerDefaultLoc,
}) => {

	const isEditing = !!placeholder.id;

	const currentPermission = placeholder.permissionId
		|| '';

	const onError = !!error && (
		<Typography className={classes.error} align='center'>
			{typeof error === 'string' ? error : 'Please fill all the fields'}
		</Typography>
	);

	const title = (
		<Typography className={classes.title}>
			{isEditing ? 'Edit Role' : 'Add Role'}
		</Typography>
	);

	const permission = (
		<FormControl key='Permission_Select'>
			<InputLabel>
				Permission
			</InputLabel>
			<Select
				native
				onChange={e => onChange({ permissionId: e.target.value })}
				displayEmpty
				className={classnames(classes.select,
					!!error && !placeholder.permissionId && classes.selectError)}
				value={currentPermission}
			>
				<option value='' key='_blank'></option>
				{!!permissions && permissions.map(permission => (
					<option value={permission.id} key={permission.id}>
						{permission.name}
					</option>
				))}
			</Select>
		</FormControl>
	);

	const name = (
		<FormControl key='Name_Field'>
			<InputLabel>
				{'Designation'}
			</InputLabel>
			<InputBase
				classes={{
					root: classes.inputRoot,
					input: classnames(classes.inputInput,
						!!error && !placeholder.name && classes.inputError),
				}}
				disabled={isEditing}
				value={placeholder.name || ''}
				onChange={event =>
					onChange({ name: event.target.value })}
			/>
		</FormControl>
	);

	const maxAmount = (
		<FormControl key='MaxInput' className={classes.maxAmount}>
			<InputLabel>
				{'Bonus max amount'}
			</InputLabel>
			<InputBase
				classes={{
					root: classes.inputRoot,
					input: classnames(classes.inputInput, classes.noArrows,
						!!error && !placeholder.maxAmount && classes.inputError),
				}}
				value={placeholder.maxAmount || 0}
				onChange={event => event?.target?.validity?.valid &&
					onChange({ maxAmount: event.target.value })}
				endAdornment={currencyFormatter.symbol()}
				type="number"
				inputProps={{ min: '0.00', step: '0.01', type: 'number' }}
			/>
		</FormControl>
	);

	const locationList = (
		<SearchList
			className={classes.locationList}
			style={{
				classNameTitle: classes.subtitle14px,
				classNameHeader: classes.locationHeader,
				classNameFiltersRoot: classes.locationFilterRoot,
			}}
			currentFields={{ key: placeholder?.location, label: placeholder?.locationName }}
			disabled={false}
			getList={getLocations}
			header='Location'
			name='Locations'
			addIcon={AddGroupIcon}
			onAdd={(key, code, rest) => {
				onChangeHandlerDefaultLoc('add', { key, label: code, name: rest });
			}}
			onRemove={(key) => {
				onChangeHandlerDefaultLoc('remove', { key });
			}}
			removeIcon={RemoveGroupIcon}
			ref={locationListRef}
			onLoad
			searchLabel='Search Location'
		/>
	);

	const form = (
		<div className={classes.form}>
			{name}
			{permission}
			{locationList}
			{maxAmount}
		</div>
	);

	const cancelButton = (
		<Button
			buttonStyleName='void'
			disabled={!!hasRequest}
			key='CancelButton'
			label='Cancel'
			onClick={() => onCancel()}
		/>
	);

	const saveButton = (
		<Button
			buttonStyleName='filled'
			disabled={!!hasRequest}
			key={isEditing ? 'UpdateButton' : 'SaveButton'}
			label={isEditing ? 'Update' : 'Save'}
			onClick={onSave}
		/>
	);

	const buttons = (
		<div className={classes.buttons}>
			{cancelButton}
			{saveButton}
		</div>
	);

	return (
		<div className={classes.root}>
			{title}
			{form}
			{onError}
			{buttons}
		</div>
	);
};

export default withStyles(styles)(UpdateRole);