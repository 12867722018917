import { wsCall, requestHelper } from "../../../app/coreSlice";

const carclubGetInfoCall = async ({ setCarclubInfo }, dispatch, state) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "CARCLUB/GET_INFO");
  let result;
  try {
    result = await wsCall({
      type: "CARCLUB",
      subType: "GET_INFO",
      locationId: selectedLocation?.id,
    });
    if (!result.error) {
      dispatch(setCarclubInfo(result));
      // refresh reservations
    } else {
      rh.error(result?.description || "Error loading carclub info.");
    }
  } catch (_ex) {
    rh.error("Error loading carclub info.");
  }
  rh.close();
};

export default carclubGetInfoCall;
