import React, { useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Button, Grid, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { styles } from "./RefundButton.styles";

import Modal from "../../../../../../common/displays/Modal/Modal";
import DecimalInput from "../../../../../../common/inputs/DecimalInput";
import { useCurrency } from "../../../../../../common/hooks";
import { billingRefundAdvanced } from "../../../../reservationsSlice";

function RefundButton({ classes, loading, reservationCode, carToken, depositAmount }) {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const { symbol, decimalScale } = useCurrency();
  const [refundAmount, setRefundAmount] = useState(depositAmount);

  const refundHandler = () => {
    console.log('refund: %o', { reservationCode, carToken, refundAmount });
    dispatch(billingRefundAdvanced({ reservationCode, carToken, refundAmount }))
    // setModal(true);
  };

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        variant="outlined"
        color="primary"
        size="small"
        disabled={loading}
        onClick={() => setModal(true)}
      >
        Refund
      </Button>
      <Modal open={modal} setOpen={setModal} sizeClass="sm"
        style={{
          width: 340, height: 260,
          top: "calc(50% - 130px)",
          left: "calc(50% - 170px)"
        }}
      >
        <div style={{ height: "100%" }}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            style={{ height: "100%" }}
          >
            <Grid item>
              <Typography align="left" variant="h5">
                Enter a sum to issue deposit refund:
              </Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={1} direction="row" wrap="nowrap">
                <Grid item>
                  <DecimalInput label="Deposit" decimalScale={decimalScale} value={depositAmount} disabled endAdornment={symbol} />
                </Grid>
                <Grid item>
                  <DecimalInput label="Refund Sum" decimalScale={decimalScale} value={refundAmount} onChange={(v) => setRefundAmount(v)} endAdornment={symbol} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={1} alignItems="center" justifyContent="space-evenly">
                <Grid item>
                  <Button
                    className={classes.button}
                    variant="outlined"
                    color="primary"
                    size="small"
                    disabled={loading}
                    onClick={() => setModal(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={loading}
                    onClick={refundHandler}
                  >
                    Refund
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}

export default withStyles(styles)(RefundButton);