import createModel from '../createModel';

function Park({
	carClubCode,
	carclubId,
	code,
	countryCode,
	createDate,
	deleted,
	description,
	geoInformation,
	geoLocation,
	geoPolygon,
	id,
	latitude,
	locationImg,
	longitude,
	maxSpots,
	name,
	numberAssets,
	updateDate,
	updateUser,
} = {}) {
	createModel.call(this, {
		carClubCode,
		carclubId,
		code,
		countryCode,
		createDate,
		deleted,
		description,   
		geoInformation,
		geoLocation,
		geoPolygon,
		id,
		latitude,
		locationImg,
		longitude,
		maxSpots,
		name,
		numberAssets,
		updateDate,
		updateUser,
	});
}

export default Park;
