import React, { useState, useEffect, useCallback } from 'react';
import customerService from '../../../services/customer/ws';
import CustomerAccountDetails from './CustomerAccountDetails';
import log from '../../../utils/logger';

const logger = log('CustomerAccountDetailsWrapper');

const CustomerAccountDetailsWrapper = ({
	customer,
	...props
}) => {
	const [mode, setMode] = useState('DETAILS');
	const [bonus, setBonus] = useState({});
	const [selected, setSelected] = useState([]);
	const [payments, setPayments] = useState({});
	const [pageInfo, setPageInfo] = useState({});
	const [balance, setBalance] = useState();

	const getDetails = useCallback(async () => {
		try {
			const {
				balance,
				lastPage,
				page,
				payments,
			} = await customerService.getCustomerAccountDetails({
				customerCode: customer.customerCode,
				page: 1,
			});

			setBalance(balance);
			setPayments(payments);
			setPageInfo({ lastPage, page });
		}
		catch(error){
			logger.warn('Error getting details');
		};
	}, [customer.customerCode]);

	const getBonusList = useCallback(async () => {
		try {
			const bonus = await customerService.getBonusList(customer.customerCode);

			setBonus(bonus);
		} catch(e){
			logger.warn('Error getting bonus list.');
		};
	}, [customer]);

	useEffect(() => {
		getDetails();
		getBonusList();
	}, [getDetails, getBonusList]);

	const onSelect = code => setSelected(selected => selected.includes(code)
		? selected.filter(element => element !== code)
		: [...selected, code]
	);

	const onSelectAll = () => setSelected(Object.keys(payments));
	const onUnselect = () => setSelected([]);

	const getMoreRows = !!pageInfo.lastPage && (async () => {
		try {
			const {
				balance,
				lastPage,
				page,
				payments,
			} = await customerService.getCustomerAccountDetails({
				customerCode: customer.code,
				page: ++pageInfo.page,
			});
			
			setBalance(balance);
			setPayments(prev => ({ ...prev, ...payments }));
			setPageInfo({ lastPage, page });
		}
		catch(error){
			logger.warn('Error getting more rows');
		};
	});

	const customerAccountDetails = (
		<CustomerAccountDetails
			{...props}
			balance={balance}
			bonus={bonus}
			customer={customer}
			getMoreRows={getMoreRows}
			mode={mode}
			onSelect={onSelect}
			onSelectAll={onSelectAll}
			onUnselect={onUnselect}
			payments={payments}
			selected={selected}
			setMode={setMode}
		/>
	);

	return customerAccountDetails;
};

export default CustomerAccountDetailsWrapper;