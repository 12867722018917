import { createSlice } from "@reduxjs/toolkit";
import carclubCreateMessageCarclubCall from "./_sliceCalls/carclubCreateMessageCarclub";
import carclubEditMessageCarclubCall from "./_sliceCalls/carclubEditMessageCarclub";

export const notificationSlice = createSlice({
  name: "notification2",
  initialState: {
    messageSaveSuccess: false,
  },
  reducers: {
    setMessageSaveSuccess: (state, action) => {
      state.messageSaveSuccess = action.payload;
    },
  },
});

// export const {  } = notificationSlice.actions;
export default notificationSlice.reducer;

// SELECTS
export const selectIsLoading = (state) => Boolean(state.getIn(["core"])?.loader?.length > 0);
export const selectMessageSaveSuccess = (state) => state.getIn(["notification2"])?.messageSaveSuccess;

// CALLS
export const carclubEditMessageCarclub = (fields) => (dispatch, getState) => {
  carclubEditMessageCarclubCall(notificationSlice.actions, dispatch, getState(), fields);
};

export const carclubCreateMessageCarclub = (fields) => (dispatch, getState) => {
  carclubCreateMessageCarclubCall(notificationSlice.actions, dispatch, getState(), fields);
};

