
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import customerFlowMapper from './mappers/customerFlowMapper';
import { publishAndAwait } from '../../../app/coreSlice';

const searchCustomers = async ({
	selectBy,
	selectByString,
	GDPDR,
}) => {

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'CUSTOMER',
			subType: !!GDPDR ? 'PICKY_SEARCH_GDPDR' : 'PICKY_SEARCH',
			message: {
				[selectBy]: selectByString,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}

	const customers = processMessage && Array.isArray(processMessage)
		&& processMessage.map(customer => customerFlowMapper.fromDTO(customer));

	return customers;
};

export default searchCustomers;