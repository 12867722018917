import React from "react";
import { Tooltip } from "@material-ui/core";
import { useDateTime } from "../../hooks";

function AlertMarker({ alert }) {
  const { toTimeZone } = useDateTime();

  // console.log('alert: %o', alert);

  const tooltip = alert?.type + " - " + toTimeZone(alert?.createDate, "dateTimeComplete");
  return (
    <Tooltip title={tooltip}>
      <div
        style={{
          backgroundColor: "#fd2e25",
          height: 12,
          width: 12,
          borderRadius: 6,
          marginLeft: -6,
          marginTop: -6,
          cursor: "pointer",
          position: "absolute",
          zIndex: 1,
          color: "white",
          fontSize: 9,
          textAlign: "center",
        }}
      >
        !
      </div>
    </Tooltip>
  );
}

export default AlertMarker;
