const styles = theme => ({

	root: {
		boxShadow: '0px 3px 6px #00000029',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		justifyContent: 'flex-start',
		minWidth: '0',
		width: '100%',
	},

	div: {
		alignItems: 'first baseline',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
	},

	list: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		overflowY: 'auto',
		paddingLeft: 21,
		paddingRight: 21,
		width: '100%',
	},

	title: {
		fontSize: '18px',
		margin: '10px 0 0 10px',
	},

	add: {
		color: '#009AC9',
		cursor: 'pointer',
		fontSize: '13px',
		margin: '0 10px 0 10px',
	},

	selectEmpty: {
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(2),
		maxWidth: '150px',
		minWidth: '130px',
	},

	alignSelect: {
		marginRight: '40px',
	},
});

export default styles;