import React from 'react';
import {
	FormControl,
	InputBase,
	InputLabel,
	Typography,
	withStyles,
} from '@material-ui/core';
import moment from 'moment';
import { DateTimePicker } from '@material-ui/pickers';
import Images from './imageHandler';
import Button from '../../../UI/Buttons/CommonButtons';
import styles from './UpdateBanner.styles';
import classnames from 'classnames';

const labels = [
	{ key: 'name', label: 'Designation', isRequired: true },
	{ key: 'description', label: 'Description' },
];

const UpdateUser = ({
	classes,
	disableButtons,
	error,
	imageHandler,
	inputRef,
	kml,
	newImage,
	newImageName,
	onCancel,
	onChange,
	onNewImage,
	onSave,
	placeholder,
	removeImage,
	setKML,
	setNewImageName,
}) => {
	const isEditing = !!placeholder.id;

	const onError = !!error && (
		<Typography className={classes.error} align='center'>
			{typeof error === 'string' ? error : 'Please fill all the fields'}
		</Typography>
	);

	const title = (
		<Typography className={classes.title}>
			{isEditing ? 'Edit Banner' : 'New Banner'}
		</Typography>
	);

	const input = ({ label, key, isRequired = false }) => {
		return (
			<FormControl key={key} required={isRequired}>
				<InputLabel>
					{label}
				</InputLabel>
				<InputBase
					classes={{
						root: classes.inputRoot,
						input: classnames(classes.inputInput,
							!!error && !placeholder[key] && classes.inputError),
					}}
					value={placeholder[key] || ''}
					onChange={event =>
						onChange({ [key]: event.target.value })}
				/>
			</FormControl>
		);
	};

	const datePickers = (
		<div className={classes.datePickers}>
				<DateTimePicker
					ampm={false}
					className={classes.picker}
					clearable
					format="DD/MM/YYYY HH:mm"
					inputVariant="outlined"
					key='datePickerStart'
					label='Start date'
					onChange={date =>
						onChange({ startDate: date && moment(date).format('YYYY-MM-DD HH:mm:ss') })}
					value={placeholder.startDate || null}
					InputProps={{ classes: {input: classes.pickerInput}}}
					InputLabelProps={{classes: {outlined: classes.outlined}}}
				/>
				<DateTimePicker
					ampm={false}
					className={classes.picker}
					clearable
					format="DD/MM/YYYY HH:mm"
					inputVariant="outlined"
					key='datePickerEnd'
					label='End date'
					onChange={date =>
						onChange({ endDate: date && moment(date).format('YYYY-MM-DD HH:mm:ss') })}
					value={placeholder.endDate || null}
					InputProps={{ classes: {input: classes.pickerInput}}}
					InputLabelProps={{classes: {outlined: classes.outlined}}}
				/>
		</div>
	);

	const fields = labels.map(input);

	const addImages = (
		<div>
			<label htmlFor='addAttachment'>
				<Typography className={classes.attachmentInput}>
					Upload Image
				</Typography>
			</label>
			<input
				accept='image/*' 
				className={classes.input}
				id='addAttachment' 
				onChange={onNewImage} 
				ref={inputRef}
				type='file' 
			/>
			{newImage && <InputBase
				id='save_image_button'
				classes={{
					root: classes.inputRoot,
					input: classnames(classes.inputInput),
				}}
				value={newImageName || ''}
				onChange={event =>
					setNewImageName(event.target.value)}
				endAdornment={
					<Typography
						className={classes.saveButton}
						onClick={imageHandler}
					>
						Save
					</Typography>
				}
			/>}
		</div>
	);

	const images = (
		<Images
			classes={classes}
			images={placeholder?.items}
			removeImage={removeImage}
		/>
	);

	const addKML = (
		<div>
			<label htmlFor='xml_import'>
				<Typography className={classes.attachmentInput}>
					Upload KML
				</Typography>
			</label>
			<input
				id="xml_import"
				onChange={event => setKML(event.target.files[0])}
				type="file"
				accept='.xml, .kml'
			/>
			<Typography
				className={classnames(classes.kmlFile, !kml && classes.disabled)}
				onClick={() => kml && window.open(URL.createObjectURL(kml), "_blank")}
			>
				{kml?.name || 'Select kml'}
			</Typography>
		</div>
	);

	const form = (
		<div className={classes.form}>
			{fields}
			{datePickers}
			{addKML}
			{addImages}
			{images}
		</div>
	);

	const cancelButton = (
		<Button
			buttonStyleName='void'
			disabled={!!disableButtons}
			key='CancelButton'
			label='Cancel'
			onClick={() => onCancel()}
		/>
	);

	const saveButton = (
		<Button
			buttonStyleName='filled'
			disabled={!!disableButtons}
			key={isEditing ? 'UpdateButton' : 'SaveButton'}
			label={isEditing ? 'Update' : 'Save'}
			onClick={onSave}
		/>
	);

	const buttons = (
		<div className={classes.buttons}>
			{cancelButton}
			{saveButton}
		</div>
	);

	return (
		<div className={classes.root}>
			{title}
			{form}
			{onError}
			{buttons}
		</div>
	);
};

export default withStyles(styles)(UpdateUser);