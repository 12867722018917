const styles = () => ({

	root: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		height: '100%',
		width: '100%',
	},

	workspace: {
		alingItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		height: '100%',
		position: 'relative',
		width: '100%',
	},

	searchBar: {
		heigth: 42,
		paddingLeft: '16px',
		width: '100%',
	},

	list: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		paddingLeft: 21,
		paddingRight: 21,
		width: '100%',
	},

	details: {
		background: '#FAFAFA 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 6px #00000029',
		maxWidth: '350px',
		minWidth: '350px',
		overflowY: 'auto',
		padding: '0 21px',
		scrollbarWidth: 'thin',
		'&::-webkit-scrollbar': {
			width: '1px',
		},

		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)', 
		},
	},

	errorBox: {
		position: 'relative',
		margin: '0 20px',
	},

	error: {
		position: 'absolute',
		backgroundColor: 'red',
		color: 'white',
		padding: 5,
		borderRadius: 10,
		left: 0,
		right: 0,
		zIndex: 10,
		top: 50,
		textAlign: 'center',
	},
});

export default styles;