import React from 'react';
import {
	withStyles,
} from '@material-ui/core';
import { styles } from './CalendarCell.styles';
import ListItemCalendar from '../../../UI/List/ListItem/ListItemCalendar';

const CalendarCell = ({
	classes,
	className,
	info,
	fields,
	hourPeriod,
	hourIndex,
	onClickHandler,
	calendarAddHandler,
	calendarSubtractHandler,
	dragStartHandler,
	dragOverHandler,
	dragDropHandler,
	calendarRatesInfo,
}) => {

	return (
		<ListItemCalendar
			fields={fields}
			selected={false}
			onSelect={() => true}
			element={{
				...info,
				hourPeriod,
			}}
			onClick={onClickHandler}
			calendarAddHandler={calendarAddHandler}
			calendarSubtractHandler={calendarSubtractHandler}
			hourPeriod={hourPeriod}
			hourIndex={hourIndex}
			dragStartHandler={dragStartHandler}
			dragOverHandler={dragOverHandler}
			dragDropHandler={dragDropHandler}
			calendarRatesInfo={calendarRatesInfo}
		/>
	);
};

export default withStyles(styles)(CalendarCell);