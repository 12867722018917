import React, { useState } from "react";
import { useCancellablePromise } from "../../../hooks/useCancellablePromise/useCancellablePromise.jsx";

import IncidentsFilter from "./IncidentsFilter";

const searchByParams = [
  { value: "incidentCode", label: "Incident Number" },
  { value: "customerCode", label: "Customer Code" },
  { value: "reservationCode", label: "Reservation Code" },
  { value: "licensePlate", label: "License Plate" },
];

const IncidentsFiltersWrapper = (props) => {
  const [anchorEl, setAnchor] = useState(null);
  const [activeFilter, setActiveFilter] = useState(null);

  const filter = props.filter;

  const onChange = (option, name) => {
    return filter.setField({ name, value: option });
  };

  const openSelectFilter = (currentTarget, filter) => {
    setAnchor(currentTarget);
    setActiveFilter(filter);
  };

  const closeSelectFilter = () => {
    setActiveFilter();
    setAnchor(null);
  };

  const { makeCancellablePromise } = useCancellablePromise();

  const onSingleClick = (cb) => {
    makeCancellablePromise(cb());
  };

  return (
    <IncidentsFilter
      {...props}
      activeFilter={activeFilter}
      anchorEl={anchorEl}
      closeSelectFilter={closeSelectFilter}
      filter={filter.state}
      onChange={onChange}
      onSingleClick={onSingleClick}
      openSelectFilter={openSelectFilter}
      searchByParams={searchByParams}
    />
  );
};

export default IncidentsFiltersWrapper;
