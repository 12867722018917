import { wsCall, requestHelper } from "../../../app/coreSlice";

const parkListCall = async ({ setParks }, dispatch, state) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "PARK/LIST");
  try {
    const result = await wsCall({
      type: "PARK",
      subType: "LIST",
      locationId: selectedLocation?.id,
      message: {},
    });
    if (result) {
      dispatch(setParks(result));
    }
  } catch (_ex) {
    rh.error("Error getting parks.");
  }
  rh.close();
};

export default parkListCall;
