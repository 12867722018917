import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    text: {
      primary: "#47525E",
    },
    primary: {
      main: "#009BCC",
    },
    secondary: {
      main: "#757575",
    },
  },
  typography: {
    fontSize: 12,
    fontWeightRegular: 400,
    fontFamily: '"Montserrat", sans-serif',
    textColor: "#47525E",
  },
  overrides: {
    MuiTypography: {
      root: {
        fontSize: "0.75rem",
        fontWeight: 400,
        fontFamily: '"Montserrat", sans-serif',
        color: "#47525E",
      },

      body1: {
        fontSize: "0.75rem",
        fontWeight: 400,
        fontFamily: '"Montserrat", sans-serif',
        color: "#47525E",
      },
    },
    MuiButton: {
      root: {
        borderRadius: 15,
        padding: "5px 20px",
        fontSize: 9,
      },
      outlinedPrimary: {
        backgroundColor: "#FFF",
      },
    },
    MuiSwitch: {
      root: {
        width: 41,
        height: 15,
        padding: 0,
        margin: 8,
        overflow: "visible",
        marginBottom: 12,
      },
      switchBase: {
        padding: "3px 3px",
        color: "#aaa",
        "&$checked": {
          transform: "translateX(125%)",
          color: "#00b67f !important",
        },
      },
      thumb: {
        width: 12,
        height: 12,
      },
      track: {
        borderRadius: 20,
        border: `2px solid #009BCC`,
        opacity: 1,
        backgroundColor: "#fff",
      },
      colorSecondary: {
        "&$checked": {
          "& + $track": {
            backgroundColor: "#fff",
            opacity: 1,
          },
        },
      },
    },
    MuiTableCell: {
      footer: {
        border: "none !important",
      },
    },
  },
});

export default theme;
