import React from "react";
import ZoneTypes from "../../../../model/Zones/ZoneTypes";
import CommonButtons from "../../../UI/Buttons/CommonButtons";
import { ReactComponent as WantedIcon } from "../../../../assets/Zones/wantedZone.svg";
import { ReactComponent as UnwantedIcon } from "../../../../assets/Zones/unwantedZone.svg";
import { Tooltip, Typography, withStyles } from "@material-ui/core";
import { dateFormatter } from "../../../../services/formatter";
import withPermission from "../../../../hoc/withPermission";
import classnames from "classnames";
import styles from "./ZonesItem.styles";

const ZonesItem = ({
  className,
  classes,
  editZone,
  isDrawing,
  isSelected,
  name,
  redrawZone,
  removeZone,
  selectZone,
  setSelectedZoneCode,
  setZones,
  stopRedrawing,
  type,
  zone,
}) => {
  const { code, createDate, updateDate, updateUser } = zone || {};

  const editDate = updateDate || createDate;

  const lastEditDate = editDate ? dateFormatter.format(editDate, "dateTime") : "N/A";

  const header = (
    <div className={classes.header}>
      <TypeIcon className={classes.icon} type={type} />
      <Typography>{name}</Typography>
    </div>
  );

  const buttons = isSelected && !isDrawing.state && (
    <div className={classes.buttons}>
      <ButtonWithPermission buttonStyleName="void" label="Edit" onClick={editZone} />
      <ButtonWithPermission
        buttonStyleName="void"
        label="Redraw"
        onClick={(event) => {
          redrawZone();
          event.stopPropagation();
        }}
      />
      <ButtonWithPermission label="Delete" onClick={() => removeZone(code, setZones, setSelectedZoneCode)} />
    </div>
  );

  const drawingButtons = isSelected && isDrawing.state && (
    <div className={classes.buttons}>
      <CommonButtons buttonStyleName="void" label="Cancel" onClick={isDrawing.setOff} />
      <CommonButtons label="Confirm" onClick={stopRedrawing} />
    </div>
  );

  const lastEdited = isSelected && (
    <div className={classes.timestamp}>
      <Typography>{`Last edited at ${lastEditDate}`}</Typography>
      <Typography>{` by user ${updateUser || "N/A"}`}</Typography>
    </div>
  );

  return (
    <div
      className={classnames(classes.root, className, isSelected && classes.selected)}
      onClick={() => selectZone(zone)}
    >
      {header}
      {buttons}
      {drawingButtons}
      {lastEdited}
    </div>
  );
};

const TypeIcon = ({ className, type }) => {
  if (!type) {
    return null;
  }

  const Icon = {
    [ZoneTypes.NORMAL]: WantedIcon,
    [ZoneTypes.UNWANTED]: UnwantedIcon,
  }[type];

  return (
    <Tooltip title={type}>
      <Icon className={className} />
    </Tooltip>
  );
};

const ButtonWithPermission = withPermission([{ resource: ["fleet", "zones", "edit"] }])((props) => (
  <CommonButtons {...props} />
));

export default withStyles(styles)(ZonesItem);
