export const styles = theme => ({

	root: {

	},

	textFieldRoot: {
		marginRight: 8,
	},

	textFieldSelect: {
		padding: 5,
	},
});