import React, { cloneElement } from "react";
import { TableBody, TableRow, TableCell } from "@material-ui/core";

export default function CustomDataGridBody({
  classes,
  columns,
  rows,
  keyField,
  actionsComponent,
  onRowSelected,
  innerRow,
  selectedIndex,
}) {
  let k = keyField || "name";
  const onRowSelectedHandler = (row) => {
    // console.log('e: %o', e);
    if (row && onRowSelected) {
      onRowSelected(row);
    }
  };
  return (
    <TableBody>
      {rows.map((row, row_index) => {
        return (
          <React.Fragment key={"frag_" + row[k] + "_" + row_index}>
            <TableRow
              key={row[k] + "_" + row_index}
              className={"CdgRow" + (selectedIndex === row_index ? " CdgRowSelected" : "")}
              onClick={() => onRowSelectedHandler(row)}
            >
              {columns.map((col) => {
                return !col.hidden ? (
                  <TableCell
                    hidden={col.hidden}
                    key={"tbody_" + col.field + "_" + row_index}
                    align={col.align}
                    className={classes.cell}
                  >
                    {col.customValue ? col.customValue(row) : row[col.field]}
                  </TableCell>
                ) : null;
              })}
              {actionsComponent && (
                <TableCell key={"tbody_actions_" + row_index} align="right">
                  {cloneElement(actionsComponent, { row })}
                </TableCell>
              )}
            </TableRow>
            {innerRow && innerRow(row) && (
              <TableRow key={row[k] + "_inner_" + row_index} className="CdgRow">
                <TableCell colSpan={columns.length} style={{ paddingRight: 12, paddingLeft: 12 }}>
                  {innerRow(row, row_index)}
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
        );
      })}
    </TableBody>
  );
}
