import { connect } from "react-redux";
import toJS from "../../hoc/toJS";
import * as carclubActions from "../../store/actions/carclub/action";
import CarclubApp from "./CarclubAppWrapper";

const mapStateToProps = (state) => {
  return {
    carclubs: state.getIn(["carclub", "carclubs"]).toList(),
    currentScreenSize: state.getIn(["app", "windowWidth"]),
    features: state.getIn(["carclub", "features"]),
    flows: state.getIn(["carclub", "flows"]),
    notificationList: state.getIn(["carclub", "notifications"]).toList(),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCarclubs: () => dispatch(carclubActions.getCarclubs()),
    getCarflowFeatures: (data) => dispatch(carclubActions.getCarflowFeatures(data)),
    getFeatures: () => dispatch(carclubActions.getFeatures()),
    getFlows: (carclubId) => dispatch(carclubActions.getFlows(carclubId)),
    getNotifications: () => dispatch(carclubActions.getNotifications()),
    removeNotification: (id) => dispatch(carclubActions.removeNotification(id)),
    updateNotification: (notification) => dispatch(carclubActions.updateNotification(notification)),
    updateFlow: (data) => dispatch(carclubActions.updateCarclubFlows(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(CarclubApp));
