import { connect } from "react-redux";
import { setSelectedAssetToken } from "../../features/asset/assetSlice";
import toJS from "../../hoc/toJS";
import * as assetActions from "../../store/actions/asset/action";

import * as parksActions from "../../store/actions/parks/action";
import AssetApp from "./AssetAppWrapper";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCurrentLocation: (location) => dispatch(assetActions.assetChangeLocation(location)),

    getAssetZones: ({ assetToken, deviceToken, licensePlate }) =>
      dispatch(assetActions.assetGetZones({ assetToken, deviceToken, licensePlate })),

    setSelectedAssetToken: (assetToken) => dispatch(setSelectedAssetToken(assetToken)),

    // setShowList: (showList) => dispatch(assetActions.assetSetShowList(showList)),

    updateAsset: (asset) => dispatch(assetActions.assetUpdate(asset)),

    getParks: () => dispatch(parksActions.requestParks()),

    setParks: (data) => {
      dispatch(parksActions.parksClearElements());
      dispatch(parksActions.parksSetElements(data));
    },

    upsertPark: (data) => dispatch(parksActions.upsertPark(data)),

    deletePark: (code, setSelectedPark) => {
      dispatch(parksActions.deletePark(code, setSelectedPark));
      dispatch(parksActions.requestParks());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(AssetApp));
