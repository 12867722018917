import React from "react";
import { Grid, Tooltip } from "@material-ui/core";
import { Create, PersonAdd, InfoOutlined, AccountCircle, RemoveCircleOutline, LockOpen } from "@material-ui/icons";
import withPermission from "../../../../hoc/withPermission";
import MoveUpIcon from "./icons/MoveUpIcon";
import Permission from "../../../../app/components/Permission";
import { isSharing, isCommercial, isSubscription } from "../../../../common/utils/OTypes";

function CustomersDataGridActions({
  row,
  classes,
  onEdit,
  onOnboardChanged,
  onAccountDetails,
  onAgentChanged,
  onAvailableChange,
  onResetPassword,
  selectedLocation,
  onMoveDriver,
}) {
  const onAccountDetailsHandler = (e) => {
    e.stopPropagation();
    onAccountDetails && onAccountDetails(row.code);
  };
  const onAvailableChangeHandler = (e) => {
    e.stopPropagation();
    onAvailableChange && onAvailableChange(row.code, row.available);
  };
  const onAgentChangedHandler = (e) => {
    e.stopPropagation();
    onAgentChanged && onAgentChanged(row.code, row.isAgent);
  };
  const onOnboardChangedHandler = (e) => {
    e.stopPropagation();
    onOnboardChanged && onOnboardChanged(row.code, row.onboarded);
  };
  const onEditHandler = (e) => {
    e.stopPropagation();
    onEdit && onEdit(row.code);
  };
  const onResetPasswordHandler = (e) => {
    e.stopPropagation();
    onResetPassword && onResetPassword(row);
  };
  const onMoveDriverHandler = (e) => {
    e.stopPropagation();
    onMoveDriver && onMoveDriver(row);
  };

  return (
    <Grid container justifyContent="flex-end" alignItems="center">
      {(isCommercial(selectedLocation?.operationType) || isSubscription(selectedLocation?.operationType)) && (
        <Permission code="customers.create">
          <Grid item>
            <Tooltip title="Move Driver to another location" placement="left">
              <div>
                <MoveUpIcon onClick={onMoveDriverHandler} className={classes.icon} />
              </div>
            </Tooltip>
          </Grid>
        </Permission>
      )}
      {isSharing(selectedLocation?.operationType) && (
        <Grid item>
          <Tooltip title="Show customer account details" placement="left">
            <InfoOutlined onClick={onAccountDetailsHandler} className={classes.icon} />
          </Tooltip>
        </Grid>
      )}
      <Grid item>
        <Tooltip title={row.available ? "Disable" : "Enable"} placement="left">
          <RemoveCircleOutline
            onClick={onAvailableChangeHandler}
            className={row.available ? classes.icon : classes.iconDisabled}
          />
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title={row.isAgent ? "Disable Agent" : "Enable Agent"} placement="left">
          <AccountCircle
            onClick={onAgentChangedHandler}
            className={row.isAgent ? classes.icon : classes.iconDisabled}
          />
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title={row.onboarded ? "Offboard" : "Onboard"} placement="left">
          <PersonAdd
            onClick={onOnboardChangedHandler}
            className={row.onboarded ? classes.icon : classes.iconDisabled}
          />
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title="Reset Password" placement="left">
          <LockOpen onClick={onResetPasswordHandler} className={classes.icon} />
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title="Update" placement="left">
          <Create onClick={onEditHandler} className={classes.icon} />
        </Tooltip>
      </Grid>
    </Grid>
  );
}

const permissions = [{ resource: ["customers", "edit"] }];

export default withPermission(permissions)(CustomersDataGridActions);
