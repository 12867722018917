
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import notificationMapper from './mappers/notificationFlowMapper';
import { publishAndAwait } from '../../../app/coreSlice';

const createNotification = async ({
	body,
	subject,
	bcc,
	type,
	subType,
	toSpecificMail,
	specificMailList,
}) => {

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'CARCLUB',
			subType: 'CREATE_MESSAGE_CARCLUB',
			message: {
				body,
				subject,
				bcc,
				type,
				subType,
				toSpecificMail,
				specificMailList,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}

	const notification = notificationMapper.fromDTO(processMessage);
	return notification;
};

export default createNotification;