
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_REQUEST_IS_MALFORMED,
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import PricingPlan from '../../../model/PricingPlan/PricingPlan';
import { publishAndAwait } from '../../../app/coreSlice';

const getPricingPlans = async (customerCode) => {

	if (typeof customerCode === 'undefined') {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	}

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'CUSTOMER',
			subType: 'PRICING_PLANS',
			message: {
				customerCode,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}
  
	const {
		pricingPlans: pricingPlanList,
	} = processMessage;

	const princinPlans = pricingPlanList.map(pricingPlan =>
		new PricingPlan(pricingPlan)
	);
  
	return princinPlans || [];
};

export default getPricingPlans;
