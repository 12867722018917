import { List } from 'immutable';

export const initialState = List([]);

export const actionTypes = {
	ADD: 'ADD',
	SUBTRACT: 'SUBTRACT',
	SET_CELL: 'SET_CELL',
	SET_CALENDAR: 'SET_CALENDAR',
};

export const actions = {
	add: { type: actionTypes.ADD },
	subtract: { type: actionTypes.SUBTRACT },
};

const parseArrayIntoValidDataObject = (ratesArray) => {
	const arrayToModify = [...ratesArray];
	const dataObject = {};

	for (let indexHour = 0; indexHour < 24; indexHour++) {
		dataObject[indexHour + 1] = {
			monday: arrayToModify[0 + indexHour],
			tuesday: arrayToModify[24 + indexHour],
			wednesday: arrayToModify[48 + indexHour],
			thursday: arrayToModify[72 + indexHour],
			friday: arrayToModify[96 + indexHour],
			saturday: arrayToModify[120 + indexHour],
			sunday: arrayToModify[144 + indexHour],
		};
	};

	return dataObject;
};

const setCalendarHandler = (state, action) => {


	const dataObject = parseArrayIntoValidDataObject(action.calendar);
	let newCalendarMap = List();

	if (typeof dataObject === 'undefined') {
		return state;
	};

	const hoursOfDayName = Object.keys(dataObject);
	const valuesForHourPerDay = Object.values(dataObject);

	hoursOfDayName.forEach((hour, iHour) => {
		const hourObject = valuesForHourPerDay[iHour];
		newCalendarMap = newCalendarMap.set(iHour, { ...hourObject });
	});

	return newCalendarMap;
};

const basicOperationsHandler = (state, action, operation) => {

	if (operation === 'ADD') {
		const index = action.index;
		const valueOfHourObject = state.get(index);

		const nameOfDay = action.dayName;
		const dayCurrentValue = valueOfHourObject[nameOfDay];

		const newValue = (dayCurrentValue + 1) <= 10 ? dayCurrentValue + 1 : dayCurrentValue;
		const newHourObject = {
			...valueOfHourObject,
			[nameOfDay]: newValue,
		};

		return state.set(index, newHourObject);
	}

	if (operation === 'SUBTRACT') {
		const index = action.index;
		const valueOfHourObject = state.get(index);

		const nameOfDay = action.dayName;
		const dayCurrentValue = valueOfHourObject[nameOfDay];

		const newValue = (dayCurrentValue - 1) >= 0 ? dayCurrentValue - 1 : dayCurrentValue;
		const newHourObject = {
			...valueOfHourObject,
			[nameOfDay]: newValue,
		};

		return state.set(index, newHourObject);
	}
};

const setCellHandler = (state, action) => {

	const {
		index,
		dayName,
		value,
	} = action;

	const currentDayObj = state.get(index);
	return state.set(index, {
		...currentDayObj,
		[dayName]: value,
	});

};

export const calendarReducer = (state, action) => {

	switch (action.type) {
		case actionTypes.ADD:
			return basicOperationsHandler(state, action, 'ADD');
		case actionTypes.SUBTRACT:
			return basicOperationsHandler(state, action, 'SUBTRACT');
		case actionTypes.SET_CELL:
			return setCellHandler(state, action);
		case actionTypes.SET_CALENDAR:
			return setCalendarHandler(state, action);
		default:
			return state;
	}
};
