import React from 'react';
import Permissions from './Permissions/Permissions';
import PermissionsList from './PermissionsList/PermissionsListWrapper';
import PermissionsCreate from './PermissionsCreate/PermissionsCreateWrapper';
import {
	withStyles,
} from '@material-ui/core';
import styles from './PermissionsWorkspace.styles';
import classnames from 'classnames';

const PermissionsWorkspace = ({
	addPermission,
	allPermissions,
	classes,
	className,
	createPermission,
	lastPage,
	list,
	onSearch,
	permission,
	selected,
	setPermission,
	setSelected,
}) => {

	const permissionsList = (
		<PermissionsList
			createPermission={createPermission}
			lastPage={lastPage}
			list={list}
			selected={selected}
			setList={onSearch}
			setSelected={setSelected}
		/>
	);

	const create = (
		<PermissionsCreate
			addPermission={addPermission}
			onCancel={createPermission}
			permission={permission}
			setSelected={setSelected}
		/>
	);

	const createDiv = (
		<div className={classes.create}>
			{create}
		</div>
	);

	const permissionsTree = (
		<Permissions
			allPermissions={allPermissions}
			permissions={permission.permissions || {}}
			setPermissions={setPermission}
		/>
	);

	return (
		<div className={classnames(classes.root, className)}>
			{permissionsList}
			{createDiv}
			{permissionsTree}
		</div>
	);
};

export default withStyles(styles)(PermissionsWorkspace);