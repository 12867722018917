import React from 'react';
import TripsList from './TripsList';

const TripsListWrappers = props => {

	const {
		lastPage,
		setFilters,
	} = props;

	const onSort = () => {
		setFilters(prev => ({
			...prev,
			orderStart: !prev.orderStart,
			orderEnd: !prev.orderEnd,
			page: 1,
		}));
	};

	const getMoreRows = !lastPage
		&& (() => setFilters(prev => ({...prev, page: prev.page + 1})));

	const tripsList = (
		<TripsList
			{...{
				...props,
				getMoreRows,
				onSort,
			}}
		/>
	);

	return tripsList;
};

export default TripsListWrappers;