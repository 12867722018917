const styles = theme => ({

	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		justifyContent: 'flex-start',
		minWidth: '0',
		width: '100%',
	},

	div: {
		alignItems: 'first baseline',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
	},

	list: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		overflowY: 'auto',
		paddingLeft: 21,
		paddingRight: 21,
		width: '100%',
	},

	title: {
		fontSize: '18px',
		margin: '10px 0 0 10px',
	},

	save: {
		color: '#009AC9',
		cursor: 'pointer',
		fontSize: '13px',
		margin: '0 10px 0 10px',
	},

	cancel: {
		color: '#707070',
		cursor: 'pointer',
		fontSize: '13px',
		margin: '0 10px 0 10px',
	},

	description: {
		borderBottom: 'none',
	},
	
	inputInput: {
		borderBottom: '1px solid gray',
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(2),
		transition: theme.transitions.create('width'),
		width: '100%',
	},

	inputError: {
		borderBottom: '1px solid red',
	},
	
	inputRoot: {
		color: 'inherit',
		width: '130px',
	},

	error: {
		color: 'red',
		fontSize: '12px',
	},

	buttons: {
		display: 'flex',
	},

	header: {
		display: 'flex',
		flexDirection: 'row',
		padding: '10px 15px',
		margin: '0 15px',
		borderBottom: '1px solid #888',
	},

	headerText: {
		fontSize: '16px',
	},

	updateFields: {
		alignItems: 'first baseline',
		display: 'flex',
		flexFlow: 'row wrap',
		justifyContent: 'space-around',
		width: '100%',
	},

	updateFieldsDate: {
		alignItems: 'first baseline',
		display: 'flex',
		flexFlow: 'row wrap',
		justifyContent: 'space-around',
		width: '100%',
	},

	notification: {
		position: 'absolute',
		top: '10px',
		borderRadius: '10px',
		padding: '0 20px',
		alignSelf: 'center',
		opacity: 1,
		transition: 'opacity 1000ms linear',
	},

	notificationerror: {
		backgroundColor: '#f02c',
	},

	notificationnotification: {
		backgroundColor: 'rgb(0, 182, 127)',
	},

	notificationText: {
		color: '#fff',
		fontSize: '16px',
	},

	notificationHide: {
		opacity: 0,
		transition: 'opacity 1000ms linear',
	},
});

export default styles;