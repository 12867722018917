import React from 'react';
import DetailsDiv from '../../../../UI/DetailsDiv/DetailsDiv';
import DetailsRow from '../../../../UI/DetailsDiv/DetailsRow/DetailsRow';
import { currencyFormatter } from '../../../../../services/formatter';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import styles from './Details.styles';

const Application = ({
	classes,
	info,
}) => {

	const startDetail = info.startDate && (
		<DetailsRow
			label='Start'
			detail={
				<Typography className={classes.typography}>
					{info.startDate}
				</Typography>
			}
		/>
	);

	const endDetail = info.endDate && (
		<DetailsRow
			label='End'
			detail={
				<Typography className={classes.typography}>
					{info.endDate}
				</Typography>
			}
		/>
	);

	const maxUsageDetail = (
		<DetailsRow
			label='Max usage'
			detail={
				<Typography  className={classes.typography}>
					{info.maxUses || 'Unlimited'}
				</Typography>
			}
		/>
	);

	const amountDetail = info.value && (
		<DetailsRow
			label='Amount'
			detail={
				<Typography className={classes.typography}>
					{info.value + currencyFormatter.symbol()}
				</Typography>
			}
		/>
	);

	const discountDetail = info.percentage && (
		<DetailsRow
			label='Discount'
			detail={
				<Typography className={classes.typography}>
					{info.percentage + '%'}
				</Typography>
			}
		/>
	);

	const voucherCodeDetail = info.voucherCode && (
		<DetailsRow
			label='Code'
			detail={
				<Typography className={classes.typography}>
					{info.voucherCode}
				</Typography>
			}
		/>
	);

	const voucherCodeTotalDetail = info.voucherUses && (
		<DetailsRow
			label='Remaining codes'
			detail={
				<Typography className={classes.typography}>
					{info.voucherUses}
				</Typography>
			}
		/>
	);

	return (
		<DetailsDiv title='Application'>
			{startDetail}
			{endDetail}
			{maxUsageDetail}
			{amountDetail}
			{discountDetail}
			{voucherCodeDetail}
			{voucherCodeTotalDetail}
		</DetailsDiv>
	);
};

export default withStyles(styles)(Application);