import React, { useState, useEffect, useCallback, useRef } from 'react';
import carclubService from '../../../../services/carclub';
import locationService from '../../../../services/location';
import UpdateRole from './UpdateRole';
import log from '../../../../utils/logger';

const logger = log('UpdateRoleWrapper');

const check = placeholder => {
	const fieldsArray = ['permissionId', 'name'];

	return fieldsArray
		.filter(field =>
			!placeholder[field] || ('' + placeholder[field]).trim().length <= 0)
		.length > 0;
};

const UpdateRoleWrapper = ({
	onCancel,
	onUpdate,
	setSelected,
	role,
	...props }) => {

	const [placeholder, setPlaceholder] = useState(role ? { ...role } : {});
	const [error, setError] = useState();
	const [hasRequest, setHasRequest] = useState();
	const [locations, setLocations] = useState([]);

	const locationListRef = useRef(true);
	const isMounted = useRef(false);

	const getAssociatedLocations = useCallback(async () => {
		const locationsResult = await locationService.listAllLocations();
		
		const mappedLocationResult = Object.values(locationsResult || {}).map(({ code, name }) => {

			const indexOfLoc = !!role?.locations ? role?.locations.indexOf(code) : -1;

			const associated = indexOfLoc >= 0;

			return { key: code, label: name, associated };
		});
		!!isMounted.current && setLocations(mappedLocationResult);
	}, [role]);

	useEffect(() => {
		const onMount = async () => {
			isMounted.current = true;
			getAssociatedLocations();
		};
		onMount();

		return () => {
			isMounted.current = false;
		};
	}, [getAssociatedLocations]);

	const onChange = value => setPlaceholder(prev => ({ ...prev, ...value }));

	const onSave = async () => {

		setError();

		if (check(placeholder)) {
			return setError(true);
		};

		setHasRequest(true);

		try {
			const mappedLocations = locations
				.filter(loc=> loc.associated)
				.map(loc => loc.key);
			
			const request = await (placeholder.id
				? carclubService.updateRole({
					...placeholder,
					roleId: placeholder.id,
					locations: mappedLocations,
				})
				: carclubService.createRole({
					...placeholder,
					permissionCode: placeholder.permissionId,
					locations: mappedLocations,
				}));

			onCancel();
			onUpdate({ ...request});
			setSelected(request.id);
		}
		catch (error) {
			setError(error.description
				|| `Could not ${placeholder.id ? 'update' : 'save'} role`);
		};

		setHasRequest();
	};


	const getLocations = useCallback(({ string: label }) => {
		const filteredLocations = locations
			.filter(location =>
				!!(location.label || '').toUpperCase().includes((label || '').toUpperCase()))
			.sort((a,b) => a?.label?.localeCompare(b?.label) || 0);

		return filteredLocations;
	}, [locations]);

	const onChangeHandlerDefaultLoc = useCallback(async (action, location) => {
		const {
			key,
		} = location;
		try {

			//TODO: Check this method
			if (action === 'add') {
				setLocations(prev =>
					[{ ...prev.find(field => key === field.key), associated: true },
						...prev.filter(field => key !== field.key)]
						.sort((a,b) => a?.label?.localeCompare(b?.label) || 0));
				return;
			};

			setLocations(prev =>
				[...prev.filter(field => key !== field.key),
					{ ...prev.find(field => key === field.key), associated: false }]
					.sort((a,b) => a?.label?.localeCompare(b?.label) || 0));
		} catch (error) {
			logger.warn('Unable to add or remove loc.');
		}
	}, []);

	return (
		<UpdateRole
			{...props}
			error={error}
			hasRequest={hasRequest}
			onCancel={onCancel}
			onChange={onChange}
			onSave={onSave}
			placeholder={placeholder}
			getLocations={getLocations}
			onChangeHandlerDefaultLoc={onChangeHandlerDefaultLoc}
			locationListRef={locationListRef}
		/>
	);
};

export default UpdateRoleWrapper;