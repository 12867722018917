import React from "react";
import {
	Card, CardHeader, CardBody, Row, Col,
} from "reactstrap";

import CheckBoxConfig from "./CheckBoxConfig";

class SignUpItem extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			cardClass: "remote-config-card",
			disableCb1: false,
			disableCb2: false,
			disableCb3: false,
			disableCb4: false,
			checkedCb1: props.checked[0],
			checkedCb2: props.checked[1],
			checkedCb3: props.checked[2],
			checkedCb4: props.checked[3],
		};
	}

	onChecked1(value) {
		this.setState({
			checkedCb1: value,
		});
		this.triggerOnChange({
			"show_reg": value,
			"mandatory": this.state.checkedCb2,
			"show_account": this.state.checkedCb3,
			"locked": this.state.checkedCb4,
		});
	}

	onChecked2(value) {
		this.setState({
			checkedCb2: value,
		});
		this.triggerOnChange({
			"show_reg": this.state.checkedCb1,
			"mandatory": value,
			"show_account": this.state.checkedCb3,
			"locked": this.state.checkedCb4,
		});
	}

	onChecked3(value) {
		this.setState({
			checkedCb3: value,
		});
		this.triggerOnChange({
			"show_reg": this.state.checkedCb1,
			"mandatory": this.state.checkedCb2,
			"show_account": value,
			"locked": this.state.checkedCb4,
		});
	}

	onChecked4(value) {
		this.setState({
			checkedCb4: value,
		});
		this.triggerOnChange({
			"show_reg": this.state.checkedCb1,
			"mandatory": this.state.checkedCb2,
			"show_account": this.state.checkedCb3,
			"locked": value,
		});
	}

	triggerOnChange(obj) {
		if (this.props.onChange) {
			this.props.onChange(this.props.id, obj);
		}
	}

	render() {
		let cardClass = "remote-config-card";
		if (this.state.checkedCb1 || this.state.checkedCb2 || this.state.checkedCb3 || this.state.checkedCb4) {
			cardClass += " active";
		}
		return (
			<div>
				<Card className={cardClass}>
					<CardHeader>{this.props.title}</CardHeader>
					<CardBody>
						<Row form>
							<Col md={3}>
								<CheckBoxConfig checkState={this.state.checkedCb1} disabled={this.state.disableCb1} label="Show Register" onChecked={this.onChecked1.bind(this)}></CheckBoxConfig>
							</Col>
							<Col md={3}>
								<CheckBoxConfig checkState={this.state.checkedCb2} disabled={this.state.disableCb2} label="Mandatory" onChecked={this.onChecked2.bind(this)}></CheckBoxConfig>
							</Col>
							<Col md={3}>
								<CheckBoxConfig checkState={this.state.checkedCb3} disabled={this.state.disableCb3} label="Show Account" onChecked={this.onChecked3.bind(this)}></CheckBoxConfig>
							</Col>
							<Col md={3}>
								<CheckBoxConfig checkState={this.state.checkedCb4} disabled={this.state.disableCb4} label="Locked" onChecked={this.onChecked4.bind(this)}></CheckBoxConfig>
							</Col>
						</Row>
						{/* <hr/>
            <LanguageInput/> */}
					</CardBody>
				</Card>
			</div>
		);
	}
}

export default SignUpItem;