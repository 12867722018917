
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_REQUEST_IS_MALFORMED,
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import { dateFormatter } from '../../formatter';
import { publishAndAwait } from '../../../app/coreSlice';

export const updateIndividualBonus = async placeholder => {

	if (typeof placeholder === 'undefined') {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	}

	const {
		expirationDate,
	} = placeholder;

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'BONUS',
			subType: 'UPDATE',
			message: {
				...placeholder,
				expirationDate: expirationDate && dateFormatter.toJSON(expirationDate, 'dateTimeRequest'),
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {
		const {
			description,
			code,
			tag,
			message,
		} = processMessage;

		throw new NGError({
			message: description || message || tag,
			code,
			tag,
		});
	}
	
	return [processMessage];
};

export default updateIndividualBonus;