export const ASSET_CLEAR_ELEMENTS = 'ASSET_CLEAR_ELEMENTS';
export const ASSET_CREATE = 'ASSET_CREATE';
export const ASSET_SEARCH = 'ASSET_SEARCH';
export const ASSET_SET_DETAILS = 'ASSET_SET_DETAILS';
export const ASSET_SET_LOCATION = 'ASSET_SET_LOCATION';
export const ASSET_SET_SELECTED_ASSET_TOKEN = 'ASSET_SET_SELECTED_ASSET_TOKEN';
export const ASSET_SHOW_LIST = 'ASSET_SHOW_LIST';
export const ASSET_UPDATE = 'ASSET_UPDATE';

export const FLEET_SEARCH = 'FLEET_SEARCH';
