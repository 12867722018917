const orderType = {
	ACCOUNTNAME: 'name',
	CREATEDDATE: 'created_date',
	EMAIL: 'email',
	NAME: 'name',
	OWNERCODE: 'owner_code',
	OWNERNAME: 'owner_name',
	DISABLED: 'disabled',
};

export default orderType;