
import NGError from "../../../error/ngError";
import errorFactory from "../../../error/errorFactory";
import { WS_PROCESS_MESSAGE_IS_MALFORMED } from "../../../error/websocket/errorTypes";
import mappers from "./mappers/locationMapper";
import { publishAndAwait } from "../../../app/coreSlice";

const listLocation = async () => {
  const request = await publishAndAwait({
    destination: "/app/hello",
    body: {
      type: "LOCATION",
      subType: "LIST",
    },
  });

  const { processMessage, error } = request.response;

  if (!processMessage) {
    throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
  }

  if (error) {
    const { description, code, tag } = processMessage;

    throw new NGError({
      message: description,
      code,
      tag,
    });
  }
  // console.log("LOCATION/LIST processMessage: %o", processMessage);

  const locations = processMessage.reduce(
    (acc, location) => ({
      ...acc,
      [location.id]: mappers.fromDTO(location),
    }),
    {}
  );

  // console.log("LOCATION/LIST: %o", locations);
  return locations;
};

export default listLocation;
