import React from 'react';
import DetailsDiv from '../../../../UI/DetailsDiv/DetailsDiv';
import DetailsRow from '../../../../UI/DetailsDiv/DetailsRow/DetailsRow';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import { dateFormatter } from '../../../../../services/formatter';
import styles from './Details.styles';

const Application = ({
	classes,
	info,
}) => {

	const expirationDateDetail = info.expirationDate && (
		<DetailsRow
			label='Bonus Expiration date'
			detail={
				<Typography className={classes.typography}>
					{dateFormatter.format(info.expirationDate, 'date')}
				</Typography>
			}
		/>
	);

	const numberOfCodesDetails = (
		<DetailsRow
			label='Number of Codes'
			detail={
				<Typography className={classes.typography}>
					{info.numberOfCodes}
				</Typography>
			}
		/>
	);


	return (
		<DetailsDiv title='Application'>
			{expirationDateDetail}
			{numberOfCodesDetails}
		</DetailsDiv>
	);
};

export default withStyles(styles)(Application);