
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_REQUEST_IS_MALFORMED,
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import { publishAndAwait } from '../../../app/coreSlice';

const createAndActivateDevice = async ({
	carToken,
	deviceToken,
	externalId,
	licensePlate,
	provider_token,
	serial_number,
	unwanted,
	wanted,
	park,
}) => {
	console.log('services - createAndActivateDevice');

	if (typeof carToken === 'undefined'
		|| typeof licensePlate === 'undefined'
		|| typeof provider_token === 'undefined'
		|| typeof serial_number === 'undefined'
		|| typeof unwanted === 'undefined'
		|| typeof wanted === 'undefined') {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	}

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'ASSET',
			subType: 'ASSOCIATE_ACTIVATE_DEVICE',
			message: {
				carToken,
				deviceToken,
				externalId,
				licensePlate,
				provider_token,
				serial_number,
				unwanted,
				wanted,
				parkIds: park,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
			message,
		} = processMessage;

		if (tag !== "ALREADY_HAS_DEVICE") {
			throw new NGError({
				message: description || message,
				code,
				tag,
			});
		}
	}

	return processMessage;

};

export default createAndActivateDevice;