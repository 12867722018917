import React, { useState, useEffect } from "react";
import RatesList from "./RatesList";
import log from "../../../utils/logger";
import pricingPlanServiceV2 from "../../../services/pricingPlanV2/ws";
import { useSelector } from "react-redux";
import { selectDefaultLocation } from "../../../features/carclub/carclubSlice";

//eslint-disable-next-line
const logger = log("RatesListWrapper");

const RatesListWrapper = (props) => {
  const { selectedRateToApply, setSelectedRateToApply, asyncGetRates, ratesList, setRatesList, listPP } = props;

  const selectedLocation = useSelector(selectDefaultLocation);

  const [filter, setFilter] = useState("");

  // useEffect(() => {
  //   !!asyncGetRates && asyncGetRates();
  //   !!listPP && listPP();
  //   //eslint-disable-next-line
  // }, []);

  useEffect(() => {
    console.log("got here");
		asyncGetRates && asyncGetRates();
		listPP && listPP();
    //eslint-disable-next-line
  }, [selectedLocation]);

  const onSelectRateToApply = (rate) => {
    if (!rate) {
      return;
    }
    if (!!rate && !!selectedRateToApply && rate.id === selectedRateToApply.id) {
      setSelectedRateToApply();
      return;
    }
    setSelectedRateToApply(rate);
  };

  const onRemoveHandler = async (id) => {
    if (typeof id === "undefined") {
      return;
    }
    try {
      const result = await pricingPlanServiceV2.deleteRate(id);
      if (!!result && !!result.id) {
        setTimeout(listPP(), 2000);
      }
    } catch (error) {
      logger.info(error || error.message);
    }
  };

  const searchRateHandler = async () => {
    try {
      const rates = await pricingPlanServiceV2.listRates({ filter });
      setRatesList(rates);
    } catch (error) {
      logger.info(error.message || error);
    }
  };

  const filterOnChangeHandler = (event) => {
    setFilter(event.target.value);
  };

  return (
    <RatesList
      {...props}
      rates={ratesList}
      filter={filter}
      setFilter={setFilter}
      filterOnChangeHandler={filterOnChangeHandler}
      onSearchHandler={searchRateHandler}
      listRates={asyncGetRates}
      onSelectRateToApply={onSelectRateToApply}
      selectedRateToApply={selectedRateToApply}
      onRemoveHandler={onRemoveHandler}
    />
  );
};

export default RatesListWrapper;
