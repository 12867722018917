import { hash } from '../../../../common/utils';
import createCustomReducer from '../../../../hooks/createCustomReducer';

// TODO make this a general useEncryption(encryptionType) ?

// Actions

const types = {
	set: 'SET',
};

export const setAction = pass => ({ type: types.set, pass });

const actions = dispatch => ({
	set: pass => dispatch(setAction(pass)),
});

// Reducer

export const defaultState = "";

const setReducer = (state, action) => {
	const encryptedPass = hash(action.pass);
	return encryptedPass;
};

export const reducer = (state, action) => ({
	[types.set]: setReducer,
	undefined: () => state,
}[action.type](state, action));

export default createCustomReducer({ reducer, actions, defaultState });