const styles = theme => ({
	root:{
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: '100%',
	},

	titleBar: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'flex-start',
		width: '100%',
	},

	title: {
		fontSize: '18px',
		padding: '8px',
	},

	tabs: {
		display: 'flex',
		padding: '5px 0 5px 3px',
		width: '100%',
		boxSizing: 'border-box',
	},

	tab: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.30)',
		cursor: 'pointer',
		fontSize: '16px',
		margin: '5px',
		minWidth: '115px',
		textAlign: 'center',
	},

	selectedTab: {
		borderBottom: '2px solid rgba(1, 151, 198, 0.75)',
		fontWeight: 'bold',
	},

	button: {
		margin: '10px',
	},

	
	disabled: {
		color: '#C5C5C5',
		cursor: 'default',
	},
});

export default styles;