import React from 'react';
import AccountDetailsItem from './TransactionItem/TransactionItem';
import List from '../../../../UI/List/List';
import accountDetailsListConfig from './transactionListConfig';
import {
	withStyles,
} from '@material-ui/core';
import styles from './TransactionList.styles';
import classnames from 'classnames';

const TransactionList = ({
	classes,
	className,
	currentScreenSize,
	payments = [],
	getMoreRows,
	onShowSettleDescription,
	description,
	setDescription,
}) => {

	const details = !!payments && payments
		.sort((a, b) => b.createDatetime - a.createDatetime)
		.map(payment => (
			<AccountDetailsItem
				detail={payment}
				key={payment?.id}
				onShowSettleDescription={()=>{
					setDescription(payment?.description);
					onShowSettleDescription();
				}}
				hasDescription={!!payment?.description}
			/>
		));

	const list = (
		<List
			className={classnames(classes.root, className)}
			getMoreRows={getMoreRows}
			headerFields={accountDetailsListConfig(currentScreenSize)}
		>
			{details}
		</List>
	);

	return list;
};

export default withStyles(styles)(TransactionList);