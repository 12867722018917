import React, { useContext } from 'react';
import PermissionContext from '../context/PermissionContext';

// FIXME update doc
/**
 * Checks defined permissions given thes resources and passes props or hides the wrapped
 * component according to its permissions.
 * 
 * By default, the wrapped component will not be rendered if no props are passed to it throught
 * the failure permissions props.
 * 
 * @param {[{resource, propSuccess, propFailure}]} permissionArray an array of objects
 *  defining each permission
 * @param resource an array of strings that relates to the permission flag
 * @param propSuccess props object passed to the wrapped component if permission
 *  flag is exists or if it is true
 * @param propFailure: props object passed to the wrapped component if permission
 *  flag does not exist or if it is false. Prop { hide: true } will not render the
 *  component.
 */
const withPermission = (permissionArray = []) => WrappedComponent => props => {

	const permissions = useContext(PermissionContext);

	const permissionProps = permissionArray.reduce((obj, permission) => ({
		...obj,
		...permissionProp(permissions, permission),
	}), {});

	if (permissionProps.hide) {
		return null;
	}

	return (
		<WrappedComponent {...props} {...{ ...permissionProps }} />
	);
};

const permissionProp = (
	permissions,
	{ resource, propSuccess = {}, propFailure = { hide: true } } = {},
) => {

	return validatePermission(permissions, resource)
		? propSuccess
		: propFailure;
};

const validatePermission = (permissions, resource) => {

	if (typeof permissions === 'undefined'
		|| !Array.isArray(resource)
	) {
		return false;
	}

	const permission = permissions[resource[0]];

	if (resource.length > 1) {
		return validatePermission(permission, resource.slice(1));
	}

	return !!permission;
};

export default withPermission;