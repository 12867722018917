
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import { fromDTO as accountMapper } from '../../account/ws/mappers/accountMapper';
import { publishAndAwait } from '../../../app/coreSlice';

export const listPricingPlans = async filter => {

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'PRICING_PLAN',
			subType: 'GET_ACCOUNTS',
			message: {
				...filter,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (error) {
		return;
	}

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}

	const{
		lastPage,
		accounts,
	} = processMessage;

	const accountsArray = (Array.isArray(accounts)
	&& accounts.map(( account ) => accountMapper(account))) || [];

	return { accounts: accountsArray, lastPage };
};

export default listPricingPlans;