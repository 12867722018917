const styles = theme => ({

	root: {
		width: '100%',
		display: 'flex',
		alignContent: 'center',
	},

	type: {
		width: '100%',
		flex: '1',
		marginRight: '10px',
	},

	subtype: {
		width: '100%',
		flex: '2',
	},

	blocker: {
		minWidth: '20px',
		color: 'lightGrey',
	},
});

export default styles;