const styles = () => ({

	root: {
		height: '100%',
		textAlign: 'center',
		padding: 0,
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'hidden',
	},

	header: {
		display: 'flex',
		width: '100%',
		minWidth: 'min-content',
		alignItems: 'center',
		backgroundColor: 'white',
		textAlign: 'left',
		borderBottom: '1px solid rgba(224, 224, 224, 1)',
		padding: 0,
	},

	subheader: {
		marginRight: '8px', //hasScrollbar = true
	},

	field: {
		padding: 5,
		flexGrow: 1,
		display: 'flex',
		alignItems: 'center',
		overflow: 'hidden',
	},

	component: {
		justifyContent: 'center',
	},

	rows: {
		width: '100%',
		minWidth: 'min-content',
		height: '100%',
		boxSizing: 'border-box',
		overflowY: 'auto',
		flexShrink: 1,
		scrollbarWidth: 'thin', //FIREFOX
		//CHROME
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},

	noPaddingHeader: {
		padding: '8px 0px 0px 0px',
		marginRight: 0,
	},

	noPaddingList: {
		padding: '0 6px 0 0',
	},
	
});

export default styles;
