import React, { useState, useEffect, useRef } from 'react';
import { dateFormatter } from '../../../../services/formatter';
import Create from './Create';

const initialPlaceholder = createdBy => ({
	type: '',
	subtype: '',
	description: '',
	createDate: dateFormatter.format(new Date(), 'dateTime'),
	createdBy,
	features: [],
});

const CreateWrapper = ({
	carclub,
	currentFeature,
	handleCreate,
	selectedFlow,
	setFlow,
	updateFlow,
	username,
	...props
}) => {

	const [placeholder, setPlaceholder] = useState(initialPlaceholder(username));
	const [selectedFeature, setSelectedFeature] = useState();
	const [error, setError] = useState(false);

	let self = useRef();

	useEffect(() => {
		self.current = {
			onReset() {
				!!placeholder.id && onCancel();
			},
			onNewSelect(selectedFlow) {
				setPlaceholder({
					...selectedFlow,
					features: Object.values(selectedFlow.features)
						.sort((a, b) => a.order - b.order)
						.map(feature => feature.id),
				});
			},
		};
	});

	const onCancel = () => {
		setPlaceholder(initialPlaceholder(username));
		setFlow(undefined);
		setError(false);
		handleCreate.setOff();
	};

	useEffect(() =>{
		!!selectedFlow && self.current.onNewSelect(selectedFlow);
		!selectedFlow && self.current.onReset();
	}, [selectedFlow]);

	const onChange = (value, field) => {

		setError(false);

		let newValue = value;

		if (field === 'type' || field === 'subtype'){
			newValue = value.trim().toUpperCase();
		};
		
		setPlaceholder({
			...placeholder,
			[field]: newValue,
		});
	};

	const onAdd = () => {

		if (!currentFeature){
			return;
		};

		const features = [...placeholder.features, currentFeature.id];

		onChange(features, 'features');
	};

	const onReOrder = difference => {

		const index = placeholder.features.indexOf(selectedFeature);
		
		if (index < 0
			|| index + difference < 0
			|| index + difference > placeholder.features.length
		){
			return;
		};

		const features = [...placeholder.features];

		features.splice(index, 1);

		if (!!difference){

			features.splice(index + difference, 0, selectedFeature);
		};

		onChange(features, 'features');
	};

	const onSave = async () => {

		if (!placeholder.type
			|| !placeholder.subtype
			|| placeholder.features.length <= 0
		) {
			return setError(true);
		};

		const features = placeholder.features.map(
			(featureId, order) => ({featureId, order}));

		const newFlow = await updateFlow({...placeholder, features}, carclub);

		if(!!newFlow.error){
			setError('Could not complete the request.');
		};

		setFlow(newFlow);
	};

	return (
		<Create
			{...props}
			error={error}
			onAdd={onAdd}
			onCancel={onCancel}
			onChange={onChange}
			onReOrder={onReOrder}
			onUpdate={onSave}
			onSelect={setSelectedFeature}
			placeholder={placeholder}
			selected={selectedFeature}
		/>
	);
};

export default CreateWrapper;