import React from 'react';
import DetailsDiv from '../../../UI/DetailsDiv/DetailsDiv';
import DetailsRow from '../../../UI/DetailsDiv/DetailsRow/DetailsRow';
import { dateFormatter } from '../../../../services/formatter';
import rolesToString from '../../../../model/Carclub/rolesToString';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import {
	Check as CheckIcon,
} from '@material-ui/icons';
import styles from './UserDetails.styles';

const dateToDetail = date => dateFormatter.format(date, 'dateTime');

//Why is this an array you might ask? To assure order is maintained
const labels = [
	{ key: 'login', label: 'Login' },
	{ key: 'code', label: 'User No.' },
	{ key: 'name', label: 'Name' },
	{ key: 'email', label: 'Email' },
	{ key: 'mobile', label: 'Mobile' },
	{
		key: 'role', label: 'Role', func: (roleId, roles) =>
			rolesToString((roles.find(role => roleId === role.id) || {}).name || roleId),
	},
	{ key: 'createDate', label: 'Create date', func: dateToDetail },
	{ key: 'enabled', label: 'Enabled', icon: true },
];

const UserDetails = ({
	classes,
	roles,
	user,
}) => {

	const details = !!user && labels.map(detail => {

		const checkIcon = !!user[detail.key] && (
			<CheckIcon
				className={classes.checkIcon}
			/>
		);

		const detailText = !!detail.func
			? detail.func(user[detail.key], roles)
			: user[detail.key];

		const detailInfo = detail.icon
			? checkIcon
			: (
				<Typography className={classes.detail}>
					{detailText}
				</Typography>
			);

		return (
			<DetailsRow
				key={detail.label}
				label={detail.label}
				detail={!!user[detail.key] && detailInfo}
			/>
		);
	});

	return (
		<div className={classes.root}>
			<DetailsDiv title='Details'>
				{details}
			</DetailsDiv>
		</div>
	);
};

export default withStyles(styles)(UserDetails);