import React, {
	useEffect,
	useState,
} from 'react';
import tripService from '../../../../services/trip/index';
import withPermission from '../../../../hoc/withPermission';
import RatingInfo from './RatingInfo';

import log from '../../../../utils/logger';

const logger = log('RatingInfoWrapper');

const RatingInfoWrapper = props => {

	const [ratingInfo, setRatingInfo] = useState({});
	const [errorMessage, setErrorMessage] = useState(false);
	const [showAlert, setShowAlert] = useState(false);

	const {
		trip,
		tripType,
	} = props || {};

	useEffect(() => {

		const getRatingInfo = async (code) => {

			setErrorMessage(false);

			if (!code) {
				setRatingInfo({});
				return;
			};
			try {
				const ratingDetails = await tripService.getRatingDetails(code, tripType);
				setRatingInfo(ratingDetails || {});
			} catch (error) {
				setRatingInfo({});
				setErrorMessage(`Unable to retrieve ${trip.code || '(code missing)'} rating information from server.`);
				setShowAlert(true);
				logger.info(error.message || error);
			}
		};


		getRatingInfo(trip.code);

	}, [trip, tripType]);


	return (
		<RatingInfo
			{...props}
			ratingInfo={ratingInfo}
			errorMessage={errorMessage}
			clearErrorMessage={() => {
				setErrorMessage(false);
				setTimeout(() => {
					setShowAlert(false);
				}, 2000);
			}}
			showAlert={showAlert}
		/>
	);
};

export default withPermission(
	[{ resource: ['reservations', 'immediate', 'read'] }],
)(RatingInfoWrapper);