import React, { useState, useEffect, useCallback } from 'react';
import promotionService from '../../../services/promotions';
import PromoCodeGen from './PromoCodeGen';
import log from '../../../utils/logger';
import moment from 'moment';

const logger = log('PromoCodeGenWrapper');

const PromoCodeGenWrapper = props => {

	const [generatedCodeList, setGeneratedCodeList] = useState([]);
	const [selectedItem, setSelectedItem] = useState();

	const [filter, setFilter] = useState('');
	const [lastPage, setLastPage] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);

	const [placeholder, setPlaceholder] = useState({ expirationDate: moment(), amount: 0 });
	const [modal, setModal] = useState();

	const [hasNotification, setHasNotification] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [errorMessage, setErrorMessage] = useState();

	//ERROR HANDLING
	const handleNotification = useCallback((errorMsg) => {
		const notificationTimeout = 2500;
		setErrorMessage(errorMsg);
		setHasNotification(true);
		setTimeout(() => {
			setHasNotification(false);
		}, notificationTimeout);
		setTimeout(() => {
			setIsSuccess(false);
		}, notificationTimeout * 2);
	}, []);

	const handleSuccessfulNotification = useCallback((message) => {
		setIsSuccess(true);
		handleNotification(message);
	}, [handleNotification]);

	const asyncGetList = async () => {
		try {
			const {
				list,
				lastPage: updatedLastPage,
			} = await promotionService.listGeneratedCodes({ page: 1 });

			const sortedList = (list || []).sort((a, b) => b.startDate - a.startDate);

			setLastPage(updatedLastPage);
			setCurrentPage(2);
			setGeneratedCodeList(sortedList);
			setSelectedItem(sortedList?.[0]);
		} catch (e) {
			logger.warn('Unable to retrieve promotion bonus codes.');
		};
	};
	useEffect(() => {
		asyncGetList();
	}, []);

	const getMoreRows = useCallback(() => {
		const addRows = async () => {
			try {
				const {
					list,
					lastPage: updatedLastPage,
				} = await promotionService.listGeneratedCodes({ page: currentPage });

				const sortedList = (list || []).sort((a, b) => b.startDate - a.startDate);

				setLastPage(updatedLastPage);
				setCurrentPage(currentPage + 1);
				setGeneratedCodeList(prev => [...prev, ...sortedList]);
			} catch (e) {
				logger.warn('Unable to retrieve more rows, reason: ', e);
			};
		};
		if (!lastPage) {
			addRows();
		}
	}, [currentPage, lastPage]);

	const onSearchHandler = useCallback(async () => {
		try {
			const {
				list,
				lastPage: updatedLastPage,
			} = await promotionService.listGeneratedCodes({ page: 1, filter: {name: filter, id: filter} });
			const sortedList = (list || []).sort((a, b) => b.startDate - a.startDate);

			setSelectedItem();
			setLastPage(updatedLastPage);
			setCurrentPage(2);
			setGeneratedCodeList(sortedList);
		} catch (e) {
			logger.warn('Unable to search promotion codes, reason: ', e);
			handleNotification(`Unable to search promotion codes`);
		};
	}, [filter, handleNotification]);

	const onChangeHandler = useCallback((key, value) => {
		const regex = typeRestrictionsAdditionalFee[key];
		if (!regex) {
			setPlaceholder(prev => ({ ...prev, [key]: value }));
			return;
		}
		if(key==='amount' && (value === '' || value === '.')) {
			setPlaceholder(prev => ({ ...prev, [key]: 0 }));
			return;
		}
		const passedTest = regex.test(value);
		if (!!passedTest) {
			if (value === '.') {
				setPlaceholder(prev => ({ ...prev, [key]: '0.0' }));
				return;
			}
			setPlaceholder(prev => ({ ...prev, [key]: value }));
		}
	}, []);

	const onDownloadCSVHandler = useCallback(async (promoCode) => {
		try {
			const fileName = `csv_file_${promoCode?.name}_${moment().format('x')}.csv`;
			const rows = await promotionService.getCSVFromPromoCodeId({ id: promoCode?.id });

			let csvEncoding = 'data:text/csv;charset=utf-8,Amount,Code,Bonus Expiration Date\n' + (rows || []).map(row => row.join(',')).join('\n');
			const encodedUri = encodeURI(csvEncoding);

			const link = document.createElement('a');
			link.setAttribute('href', encodedUri);
			link.setAttribute('download', fileName);
			document.body.appendChild(link);

			link.click();
			handleSuccessfulNotification(`File ${fileName} downloaded successfully.`);
		} catch (error) {
			logger.warn(`Unable to get/parse data to CSV file. Cause: ${error}`);
			handleNotification(`Unable to get/parse data to CSV file.`);
		}
	}, [handleNotification, handleSuccessfulNotification]);

	const filterOnChangeHandler = useCallback((event) => {
		setFilter(event.target.value);
	}, []);

	const onSelectHandler = useCallback((item) => {
		if (selectedItem?.id === item?.id) return;
		if (typeof item !== 'undefined') setSelectedItem({ ...item });
	}, [selectedItem]);

	const promoCodeBatchOnSaveHandler = useCallback(async (ev) => {
		try {
			ev.preventDefault();
			await promotionService.createPromoCode({ ...placeholder });
			setModal();
			setPlaceholder({ expirationDate: moment(), amount: '0' });
			handleSuccessfulNotification('Promotion code batch created successfully');
			asyncGetList();
		} catch (error) {
			logger.warn(`Unable to ${!placeholder?.id ? 'create' : 'save'} at this time. Reason: ${error}`);
			handleNotification(error?.message || `Unable to create batch of codes.`);
		}
	}, [placeholder, handleSuccessfulNotification, handleNotification]);

	const onDisableHandler = useCallback(async (item) => {
		try {
			const disabledItem = await promotionService.disablePromoCode({ id: item?.id });
			handleSuccessfulNotification('Promotion code batch disabled successfully');
			generatedCodeList.forEach((el, index) => {
				if (el?.id === disabledItem?.id) {
					setGeneratedCodeList(prev => {
						prev[index] = disabledItem;
						return prev;
					});
				}
			});
			setSelectedItem(disabledItem);
		} catch (error) {
			logger.warn(`Unable to disable ${!selectedItem?.name} at this time. Reason: ${error}`);
			handleNotification(`Unable to disable promotion.`);
		}
	}, [handleSuccessfulNotification, generatedCodeList, selectedItem, handleNotification]);

	return (
		<PromoCodeGen
			{...props}
			errorMessage={errorMessage}
			filter={filter}
			filterOnChangeHandler={filterOnChangeHandler}
			generatedCodeList={generatedCodeList}
			getMoreRows={getMoreRows}
			hasNotification={hasNotification}
			isSuccess={isSuccess}
			modal={modal}
			onChangeHandler={onChangeHandler}
			onDisableHandler={onDisableHandler}
			onDownloadCSVHandler={onDownloadCSVHandler}
			onSearchHandler={onSearchHandler}
			onSelectHandler={onSelectHandler}
			placeholder={placeholder}
			promoCodeBatchOnSaveHandler={promoCodeBatchOnSaveHandler}
			selectedItem={selectedItem}
			setFilter={setFilter}
			setModal={setModal}
			setPlaceholder={setPlaceholder}
		/>
	);
};

const numericIntegerRegex = /^[0-9]*$/;
const numericFloatRegex = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/;

const typeRestrictionsAdditionalFee = {
	amount: numericFloatRegex,
	numberOfCodes: numericIntegerRegex,
};

export default PromoCodeGenWrapper;