const styles = () => ({

	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '300px',
		height: 'auto',
		justifyContent: 'space-between',
		padding: '25px',
	},

	buttons: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
	},

	title: {
		fontSize: '22px',
	},

	error: {
		borderRadius: '21px',
		backgroundColor: 'red',
		color: 'white',
		margin: '10px',
	},

});

export default styles;