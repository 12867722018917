import React, { useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Button } from "@material-ui/core";

import { styles } from "./CreateReservationButton.styles";
import ReservationCreateUpdate from "../../../../../reservation/ReservationCreateUpdate/ReservationCreateUpdate";

function CreateReservationButton({ classes, asset }) {
  const [createBookModal, setCreateBookModal] = useState(false);

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        size="small"
        disabled={!asset.operational || (!asset.parks && asset.inUse)}
        onClick={() => setCreateBookModal(true)}
        fullWidth
      >
        Book this car
      </Button>

      <ReservationCreateUpdate selectedAsset={asset} onClose={() => setCreateBookModal(false)} open={createBookModal} />
    </div>
  );
}

export default withStyles(styles)(CreateReservationButton);
