const styles = theme => ({
	root: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},

	button: {
		color: '#009AC9',
		cursor: 'pointer',
		width: 18,
		height: 18,
	},

	disabled: {
		color: '#C5C5C5',
		cursor: 'default',
	},

	actions: {
		flex: 2,
		display: 'flex',
		justifyContent: 'flex-end',
		marginRight: '19px',
	},

	listItem: {
		padding: '2px 0',
		borderBottom: '1px solid #ddd',
	},

	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		padding: '2px 0',
	},

	subHeader: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		margin: '5px 0 2px 20px',
	},

	iconSpace: {
		flex: 1,
		minWidth: '25px',
		maxWidth: '25px',
	},

	flexItem: {
		flex: 1,
		minWidth: '100px',
		maxWidth: '150px',
		width: '100px',
		padding: '3px 0',
	},

	larger: {
		maxWidth: '225px', 
	},

	buttons: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginRight: '15px',
		minWidth: '50px',
	},

	expansionButton: {
		color: '#666',
		cursor: 'pointer',
	},

	bold: {
		fontWeight: 'bold',
	},

	greenIcon: {
		color: '#0b5',
	},

	modal: {
		backgroundColor: 'white',
		borderRadius: '10px',
		bottom: 0,
		boxShadow: theme.shadows[5],
		boxSizing: 'border-box',
		height: '100px',
		left: 0,
		outline: 0,
		padding: '20px',
		position: 'absolute',
		right: 0,
		top: 0,
		width: '250px',
		margin: 'auto',
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'center',
		alignItems: 'center',
	},

	text: {
		fontSize: '14px',
		marginBottom: 'auto',
	},
});

export default styles;