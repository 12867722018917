export const styles = theme => ({

	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},

	itemContainerLeft: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},

	itemContainerRight: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},

	payment: {
		lineHeight: '19px',
		minWidth: '100px',
		textAlign: 'left',
		textTransform: "capitalize"
	},

	normalColor: {
		color: '#47525E',
	},

	amount: {
		lineHeight: '19px',
		width: '60px',
		textAlign: 'right',
	},

	date: {
		lineHeight: '19px',
		width: '119px',
		textAlign: 'left',
	},

	text: {
		lineHeight: '19px',
	},

	icon: {
		marginRight: '3px',
	},

	cardNumber: {
		lineHeight: '19px',
		marginRight: '6px',
	},

	errorColor: {
		color: '#fd2e25',
	},
});