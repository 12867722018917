export const styles = (theme) => ({
	root: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		boxSizing: 'border-box',
		alignItems: 'flex-start',
		marginTop: '10px',
		minHeight: 'auto',
	},

	title: {
		fontSize: '18px',
	},

	cardsRoot: {
		display: 'flex',
		justifyContent: 'flex-start',
		width: '100%',
		margin: '5px 0',
	},

	card: {
		width: '200px',
		marginRight: '20px',
		boxShadow: theme.shadows[2],
		border: '1px outset #3c3',
		display: 'flex',
		justifyContent: 'space-between',
		padding: 5,
		cursor: 'pointer',
		borderRadius: 5,
		borderColor: 'var(--color)',
	},

	text: {
		fontSize: '15px',
		textTransform: 'capitalize',
	},

	value: {
		fontSize: '15px',
	},

	graphRoot: {
		height: '100%',
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-start',
		boxSizing: 'border-box',
	},

	graph: {
		height: '100%',
		width: '100%',
		margin: '10px 0',
		borderBottom: '1px solid rgba(155, 155, 155, 0.7)',
		borderTop: '1px solid rgba(155, 155, 155, 0.7)',
		background: 'linear-gradient(rgba(0,0,0,0) calc(50% - 1px),rgba(155, 155,155,0.7) calc(50%),rgba(0,0,0,0) calc(50% + 1px))',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-end',
		boxSizing: 'border-box',
	},

	positions: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		marginBottom: '15px',
		marginRight: '10px',
		minHeight: '185px',
		minWidth: '50px',
	},

	graphValues: {
		fontSize: '14px',
	},

	bar: {
		width: 50,
		margin: '0 5px',
		height: 'calc(var(--y) * 1%)',
		backgroundColor: 'var(--color)',
		transition: '500ms',
		transform: 'height',
	},

	timeline: {
		height: '20px',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		boxSizing: 'border-box',
		overflow: 'hidden',
	},

	graphArea: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		minHeight: '200px',
	},

	time: {
		width: 50,
		margin: '0 5px',
	},

	timelineText: {
		fontSize: '10px',
		width: '50px',
	},

	bold: {
		fontWeight: 'bold',
	},

	icon: {
		cursor: 'pointer',
		color: 'var(--color)',
	},
});

export default styles;