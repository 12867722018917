import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";

import { styles } from "./SelectedAsset.styles";
import VehicleIcon from "../../../asset/common/VehicleIcon/VehicleIcon";
import CardIconTab from "../../../../common/displays/CardIconTab/CardIconTab";

function SelectedAsset({ classes, asset, onClick, disabled, selected }) {
  return (
    <CardIconTab icon={<VehicleIcon asset={asset} />} onClick={onClick} disabled={disabled} selected={selected}>
      {asset?.assetToken ? (
        <Grid container direction="column">
          <Grid item>
            <Typography className={classes.assetName}>{asset.licensePlate}</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.type}>
              {asset.make} {asset.model}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Typography className={classes.type}>Select a vehicle</Typography>
      )}
    </CardIconTab>
  );
}

export default withStyles(styles)(SelectedAsset);
