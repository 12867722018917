
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_REQUEST_IS_MALFORMED,
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import { publishAndAwait } from '../../../app/coreSlice';

const updateUser = async user => {

	if ( typeof user === 'undefined' ) {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	};
	
	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'CUSTOMER',
			subType: 'EDIT_OPERATOR_ROLE',
			message: {
				customerCode: user.code,
				role: user.role,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	};

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	};

	return {...user, role: processMessage};
};

export default updateUser;
