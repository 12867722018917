import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import WorkOrderConfigs from "./WorkOrderConfigs";
import workOrderService from "../../../../services/workOrder";
import log from "../../../../utils/logger";
import { selectDefaultLocation } from "../../../../features/carclub/carclubSlice";

const logger = log("WorkOrdersConfigsWrapper");

const WorkOrderConfigsWrapper = ({ ...props }) => {
  const { upsertTemplateHandlerCB, clearTemplateHandlerCB, masterEditMode, setMasterEditMode } = props;

  const isMounted = useRef(true);
  const [editMode, setEditMode] = useState(false);

  const carclubId = useSelector((state) => state.getIn(["carclub", "id"]));
  const screenSize = useSelector((state) => state.getIn(["app", "windowWidth"]));
  const location = useSelector(selectDefaultLocation);

  const [templates, setTemplates] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [priorities, setPriorities] = useState([]);

  const [selectedTemplate, setSelectedTemplate] = useState();

  const availableTaskListRef = useRef();

  const [placeholder, setPlaceholder] = useState({});
  const [tasksSequence, setTasksSequence] = useState([]);
  const [error, setError] = useState(false);
  const [modal, setModal] = useState();
  const [clearFilterListener, setClearFilterListener] = useState(false);

  useEffect(() => {
    asyncInfoGetter();
    upsertTemplateHandlerCB.current = upsertTemplateHandler;
    clearTemplateHandlerCB.current = cancelHandler;

    const cleanup = () => {
      isMounted.current = false;
    };

    return cleanup;
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    upsertTemplateHandlerCB.current = upsertTemplateHandler;
    //eslint-disable-next-line
  }, [placeholder, tasksSequence]);

  useEffect(() => {
    setEditMode(masterEditMode);
  }, [masterEditMode]);

  // HANDLERS

  const onChange = useCallback((data) => setPlaceholder((prev) => ({ ...prev, ...data })), []);

  const onChangeHandler = useCallback(
    (fieldString, value) => {
      onChange({ [fieldString]: value });
    },
    [onChange]
  );

  useEffect(() => {
    setPlaceholder({});
    setTasksSequence([]);

    if (!selectedTemplate) {
      return;
    }

    const keys = Object.keys(selectedTemplate);
    keys &&
      keys.forEach((key) => {
        onChangeHandler(key, selectedTemplate[key]);
      });

    const mappedTasks = [...selectedTemplate?.tasks].map((task, i) => ({
      key: task.name + i,
      type: task.name,
      currLength: i,
      ...task,
    }));
    setTasksSequence(mappedTasks || []);
  }, [selectedTemplate, onChangeHandler]);

  const asyncInfoGetter = async () => {
    try {
      const templatesPromise = workOrderService.getTypes();
      const tasksPromise = workOrderService.getTaskTypes();
      const prioritiesPromise = workOrderService.getPriorities();

      const templatesResult = await templatesPromise;
      const tasksResult = await tasksPromise;
      const prioritiesResult = await prioritiesPromise;

      const mappedTemplateResult = (templatesResult || []).map((template) => ({
        ...template,
        priority: mapPriority(template?.priority),
      }));
      const mappedTaskResult = (tasksResult || []).map((task) => ({ key: task, label: task }));
      const mappedPrioritiesResult = [...prioritiesResult].map((e) => mapPriority(e));

      if (!!isMounted.current) {
        setTemplates(mappedTemplateResult);
        setTasks(mappedTaskResult);
        setPriorities(mappedPrioritiesResult);
      }
    } catch (error) {
      logger.warn("Error while retrieving information from server");
    }
  };

  const mapPriority = (priorityToFormat) => {
    try {
      let priority;
      const lettersArray = priorityToFormat?.toLowerCase()?.split("");
      priority = lettersArray[0]?.toUpperCase();
      lettersArray.forEach((letter, i) => {
        if (i === 0) return;
        priority = priority + letter;
      });
      return priority;
    } catch (error) {
      logger.warn("Problem while mapping priority.");
    }
  };

  const onCreateTask = async () => {
    try {
      setError();
      const name = await workOrderService.createTask({ taskType: placeholder.customTask });

      onChangeHandler("customTask", null);

      setTasks((state) => [...state, { key: name, label: name }]);

      availableTaskListRef.current.func((prev) => [...prev, { key: name, label: name }]);
    } catch (e) {
      setError(e.description || e.message || "Could not create task");
      logger.warn("Could not create task");
    }
  };

  const upsertTemplateHandler = async () => {
    try {
      setError();

      const tasks = [...tasksSequence].map((task) => ({ name: task.name }));
      const id = placeholder?.code;
      const designation = placeholder?.type;
      const priority = placeholder?.priority?.toUpperCase();
      delete placeholder?.code;
      delete placeholder?.type;
      delete placeholder?.priority;
      delete placeholder?.createDate;
      delete placeholder?.createUser;
      delete placeholder?.updateDate;
      delete placeholder?.updateUser;

      await workOrderService.upsertTemplate({
        ...placeholder,
        carclubId,
        tasks,
        designation,
        id,
        priority,
      });
      cancelHandler();
      asyncInfoGetter();
    } catch (e) {
      setError("We were unable to create a template, please review the data inserted.");
      logger.warn("Could not create template");
    }
  };

  const deleteTemplateHandler = async (template) => {
    try {
      setError();
      await workOrderService.deleteTemplate(template.code);
      asyncInfoGetter();
    } catch (e) {
      setError("Could not delete template");
      logger.warn("Could not delete template");
    }
  };

  const getAvailableTasks = useCallback(
    async ({ string: filter }) => {
      const typesArray = (tasks || [])
        .filter((task) => (task.label || "").toUpperCase().includes((filter || "").toUpperCase()))
        .map((task) => task);

      return typesArray;
    },
    [tasks]
  );

  const addTaskToTemplate = (task) => {
    const length = tasksSequence.length;
    const taskToAdd = {
      key: task.name + length,
      type: task.name,
      currLength: length,
      ...task,
    };
    setTasksSequence((prev) => [...prev, taskToAdd], []);
  };

  const deleteTask = async (name) => {
    try {
      await workOrderService.deleteTaskType({ name });

      setTasks((state) => [...state.filter((task) => task.key !== name)]);

      availableTaskListRef.current.func((prev) => [...prev.filter((task) => task?.key !== name)]);
    } catch (error) {
      logger.warn("Unable to delete task. Reason: ", error?.message);
    }
  };

  //Will need a ordering prop
  const moveTaskHandler = (task, diff) => {
    const arrayToModify = [...tasksSequence];
    const taskIndex = arrayToModify.indexOf(task);
    const newIndex = taskIndex + diff;

    if (newIndex < 0 || newIndex > arrayToModify.length) return;

    arrayToModify.splice(taskIndex, 1);
    arrayToModify.splice(newIndex, 0, task);
    setTasksSequence(arrayToModify);
  };

  const removeTaskHandler = (task) => {
    const arrayToModify = [...tasksSequence];
    const taskIndex = arrayToModify.indexOf(task);
    arrayToModify.splice(taskIndex, 1);
    setTasksSequence(arrayToModify);
  };

  const cancelHandler = () => {
    setClearFilterListener(true);
    setError();
    setSelectedTemplate();
    setPlaceholder({});
    setTasksSequence([]);
    setTimeout(() => {
      if (!isMounted) return;
      setClearFilterListener(false);
    }, 200);
  };

  const createOnClickHandler = () => {
    setPlaceholder({});
    setMasterEditMode(true);
    setSelectedTemplate();
    setTasksSequence([]);

    try {
      setTimeout(() => {
        document.getElementById("ww-designation-input").focus();
      }, 100);
    } catch (error) {
      logger.warn("Could not focus on create work order");
    }
  };

  return (
    <WorkOrderConfigs
      {...props}
      addTaskToTemplate={addTaskToTemplate}
      availableTaskListRef={availableTaskListRef}
      cancelHandler={cancelHandler}
      clearFilterListener={clearFilterListener}
      createOnClickHandler={createOnClickHandler}
      deleteTemplateHandler={deleteTemplateHandler}
      editMode={editMode}
      error={error}
      getAvailableTasks={getAvailableTasks}
      location={location}
      modal={modal}
      moveTaskHandler={moveTaskHandler}
      onChangeHandler={onChangeHandler}
      onCreateTask={onCreateTask}
      placeholder={placeholder}
      priorities={priorities}
      removeTaskHandler={removeTaskHandler}
      screenSize={screenSize}
      selectedTemplate={selectedTemplate}
      setEditMode={setEditMode}
      setError={setError}
      setModal={setModal}
      setPlaceholder={setPlaceholder}
      setSelectedTemplate={setSelectedTemplate}
      tasks={tasks}
      tasksSequence={tasksSequence}
      templates={templates}
      upsertTemplateHandler={upsertTemplateHandler}
      deleteTask={deleteTask}
    />
  );
};

export default WorkOrderConfigsWrapper;
