import Contact from '../../../../model/Chat/Contact';

const fromDTO = ({
	acceptDate,
	createDate,
	customerCode,
	customer,
	description,
	id,
	inviteCode,
	name,
	nickname,
	pending,
	phone,
	target,
}) => new Contact({
	acceptDate,
	createDate,
	customer: customer || customerCode,
	description,
	groupId: id,
	inviteCode,
	name,
	nickname,
	pending,
	phone,
	target,
});

const toDTO = ({
	acceptDate,
	createDate,
	customer,
	description,
	id,
	inviteCode,
	name,
	nickname,
	pending,
	phone,
	target,
}) => ({
	acceptDate,
	createDate,
	customer,
	description,
	groupId: id,
	inviteCode,
	name,
	nickname,
	pending,
	phone,
	target,
});

const mapper = {
	fromDTO,
	toDTO,
};

export default mapper;