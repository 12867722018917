const styles = (theme) => ({
	root: {
		display: 'flex',
		width: '100%',
	},

	filters: {
		display: 'flex',
		minWidth: '70%',
		maxWidth: '100%',
	},

	createButtonDiv: {
		display: 'flex',
		flexGrow: 1,
		flexDirection: 'row-reverse',
		alignItems: 'center',
		padding: '0 15px',
	},

	createIcon: {
		cursor: 'pointer',
	},

	icon: {
		marginTop: theme.spacing(3),
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1),
		marginLeft: theme.spacing(1),
		cursor: 'pointer',
	},

	datePickers: {
		marginTop: theme.spacing(0.60),
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},

	searchIconShadow: {
		color: '#009AC9',
	},

	inputRoot: {
		color: 'inherit',
		width: '100%',
	},

	inputInput: {
		marginTop: theme.spacing(2),
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1),
		marginLeft: theme.spacing(1),
		transition: theme.transitions.create('width'),
		width: '100%',
		borderBottom: '1px solid gray',
	},

	selectEmpty: {
		marginTop: theme.spacing(2),
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1),
		marginLeft: theme.spacing(1),
		minWidth: '160px',
	},

	string: {
		minWidth: '150px',
	},

});

export default styles;