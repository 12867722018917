import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectCarclubInfo } from '../../../../features/carclub/carclubSlice';
import customerService from '../../../../services/customer/ws';
import promotionsService from '../../../../services/promotions';
import Update from './Update';

const UpdateWrapper = ({
	addBonus,
	closeHandler,
	handleNotification,
	selected,
	...props
}) => {
	const GDPDR = useSelector(selectCarclubInfo)?.secrets?.GDPDR;

	const [placeholder, setPlaceholder] = useState(selected ? { ...selected } : {});
	const [error, setError] = useState();
	const [hasRequest, setHasRequest] = useState(false);

	useEffect(() => {
		setPlaceholder(selected ? { ...selected } : {});
	}, [selected]);

	const onChange = useCallback(field => setPlaceholder(prev => ({ ...prev, ...field })), []);

	const addDriver = useCallback((key, label) =>
		setPlaceholder(prev => (
			{ ...prev, customerCodes: [...prev?.customerCodes || [], { key, label }] })
		), []);

	const removeDriver = useCallback(key =>
		setPlaceholder(prev => (
			{
				...prev,
				customerCodes: (prev?.customerCodes || [])
					.filter(customer => customer.key !== key),
			})
		), []
	);

	const getDrivers = useCallback(async ({ string: name }) => {
		const { customers } = await customerService.listCustomers({ name, GDPDR });

		const agents = customers.map(customer => ({ key: customer.code, label: customer.fullName }));

		return agents;
	}, [GDPDR]);
	
	const onSave = useCallback(async () => {
		if(!placeholder?.reason || !placeholder?.expirationDate
			|| !(placeholder?.customerCodes?.length > 0 || placeholder?.customerCode)
			|| !placeholder?.amount) return setError('Please fill all fields');

		const func = placeholder?.id 
			? promotionsService.updateIndividualBonus
			: promotionsService.createIndividualBonus;

		setHasRequest(true);
		setError();

		try {
			const bonuses = await func({
				...placeholder,
				customerCodes: placeholder?.customerCodes?.map(({key}) => key),
			});

			addBonus(bonuses);
			handleNotification(`Successfully ${placeholder?.id ? 'updated' : 'added'}`, true);
			closeHandler();
		} catch(e){
			setError(e.description || e.message || e);
		} finally{
			setHasRequest(false);
		}
	}, [placeholder, addBonus, closeHandler, handleNotification]);
	
	return (
		<Update
			{...props}
			addDriver={addDriver}
			error={error}
			getDrivers={getDrivers}
			hasRequest={hasRequest}
			onCancel={closeHandler}
			onChange={onChange}
			onSave={onSave}
			placeholder={placeholder}
			removeDriver={removeDriver}
		/>
	);
};

export default UpdateWrapper;