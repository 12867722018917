const styles = theme => ({

	root: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		padding: '15px',
		width: '400px',
	},

	fields: {
		margin: '10px 30px 0 10px',
	},

	inputRoot: {
		color: 'inherit',
		margin: '10px 0',
		'& label.Mui-focused': {
			color: '#009AC9',
		},
	},

	inputInput: {
		borderBottom: '1px solid #009AC9',
		marginBottom: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(2),
		transition: theme.transitions.create('width'),
		width: '100%',
	},

	inputLabel: {
		color: '#009AC9',
	},

	buttons: {
		alignItems: 'flex-end',
		display: 'flex',
		flexGrow: '1',
		justifyContent: 'space-around',
		padding: '5% 0',
	},

	title: {
		fontSize: '20px',
		marginBottom: '30px',
	},
});

export default styles;