import React from 'react';
import {
	Tooltip,
	withStyles,
} from '@material-ui/core';
import {
	Create as EditIcon,
	CheckCircle as StopIcon,
	RemoveCircleOutline as RemoveIcon,
	Assignment as TasksIcon,
} from '@material-ui/icons';
import withPermission from '../../../../../hoc/withPermission';
import ListItem from '../../../../UI/List/ListItem/ListItem';
import Button from '../../../../UI/Buttons/CommonButtons';
import styles from './Item.styles';
import { dateFormatter } from '../../../../../services/formatter';

const Item = ({
	classes,
	fields,
	onComplete,
	onEdit,
	onRemove,
	onSelect,
	onShowTasks,
	selected,
	workOrder = {},
}) => {
	const { status } = workOrder;

	const editButton = 'Completed' !== status && (
		<ButtonWithPermission>
			<Tooltip title='Edit workorder' placement='left'>
				<EditIcon
					className={classes.icon}
					onClick={() => onEdit(workOrder)}
				/>
			</Tooltip>
		</ButtonWithPermission>
	);

	const removeButton = 'Open' === status && (
		<ButtonWithPermission>
			<Tooltip title='Remove workorder' placement='left'>
				<RemoveIcon
					className={classes.icon}
					onClick={() => onRemove(workOrder.code)}
				/>
			</Tooltip>
		</ButtonWithPermission>
	);

	const stopButton = 'In Process' === status && (
		<ButtonWithPermission>
			<Tooltip title='Complete workorder' placement='left'>
				<StopIcon
					className={classes.icon}
					onClick={() => onComplete(workOrder.code)}
				/>
			</Tooltip>
		</ButtonWithPermission>
	);

	const showTasksButton = (
		<Tooltip title='Show workorders' placement='left'>
			<TasksIcon
				className={classes.icon}
				onClick={() => onShowTasks(workOrder)}
			/>
		</Tooltip>
	);

	const actions = (
		<>
			{removeButton}
			{stopButton}
			{showTasksButton}
			{editButton}
		</>
	);

	const assignedTo = workOrder.assignAgentName || (workOrder.status !== 'Completed' && (
		<ButtonWithPermission>
			<Button
				buttonClassName={classes.assignButton}
				key='assign'
				label='Assign'
				onClick={() => onEdit(workOrder)}
			/>
		</ButtonWithPermission>)
	);

	const scheduledStartDate = dateFormatter.format(workOrder.scheduledStartDate, 'dateTime');
	
	const priority = workOrder.priority && 
		workOrder.priority[0].toUpperCase() + workOrder.priority.slice(1).toLowerCase();
	return (
		<ListItem
			fields={fields}
			onSelect={() => onSelect(workOrder)}
			selected={selected}
			className={classes.row}
			element={{
				...workOrder,
				actions,
				assignedTo,
				priority,
				scheduledStartDate,
			}}
		/>
	);
};

export default withStyles(styles)(Item);

const ButtonWithPermission = withPermission(
	[{ resource: ['workOrders', 'edit'] }]
)(({
	children,
}) =>
	(
		<>
			{children}
		</>
	)
);