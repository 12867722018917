import {
  ASSET_UPDATE,
  ASSET_SET_LOCATION,
} from "../../actions/asset/actionTypes";
import * as mutate from "./mutators";
import { pipe } from "../utils";
import createReducer from "../../utils/createReducer";
import { Map } from "immutable";
import log from "../../../utils/logger";

const logger = log("reservation.reducer");

const initialState = Map({
  requestTime: null,
  elements: Map(),
  currentLocation: null,
  selectedAssetToken: null,
  showList: false,
});





const assetUpdate = (state, action) => {
  const { asset } = action;
  if (!asset.assetToken) {
    logger.warn("Asset does not have assetToken");
    // Correct actions that come with carToken instead of assetToken
    // TODO This correction should not exist
    asset.assetToken = asset.carToken;
    delete asset.carToken;
  }

  if (!asset.deviceToken && asset.device_token) {
    asset.deviceToken = asset.device_token;
    delete asset.device_token;
  }

  if (!asset.fuelLevel && asset.fuel_level) {
    asset.fuelLevel = asset.fuel_level;
    delete asset.fuel_level;
  }

  if (Array.isArray(asset.zones)) {
    const zones = {};
    for (let index in asset.zones) {
      const zone = asset.zones[index];
      zones[zone.code] = zone;
    }
    asset.zones = zones;
  }

  if (asset.babySeat) {
    asset.assetTypeName = "BABY";
  }

  /* if(!!Number(asset.fuelLevel) && Number(asset.fuelLevel) < 10){
		asset.assetTypeName = 'CRITICALFUEL';
	}; */

  if (asset.maintenance) {
    asset.assetTypeName = "MAINTENANCE";
  }

  return pipe(
    [
      mutate.setElements([asset], "assetToken"),
      mutate.setSelectedAssetToken(asset.assetToken),
    ],
    state
  );
};

const changeCurrentLocation = (state, action) => {
  return pipe([mutate.setCurrentLocation(action.location)], state);
};

const assetReducer = createReducer(initialState, {
  [ASSET_UPDATE]: assetUpdate,
  [ASSET_SET_LOCATION]: changeCurrentLocation,
});

export default assetReducer;
