import React, {
	useCallback,
	useEffect,
	useState,
} from 'react';
import useSwitch from '../../../../hooks/useSwitch';
import withPermission from '../../../../hoc/withPermission';
import NotificationsSMS from './NotificationsSMS';
import log from '../../../../utils/logger';
import notificationService from '../../../../services/notification';

const logger = log('NotificationSMSWrapper');

const NotificationsSMSWrapper = props => {

	const [type, setType] = useState();
	const [subType, setSubType] = useState();
	const [onCreateOrEdit, setOnCreateOrEdit] = useState(false);
	const [openNotification, setOpenNotification] = useState();
	const openConfigurations = useSwitch(false);

	//eslint-disable-next-line
	const [notifications, setNotifications] = useState([]);

	const asyncGetNotifications = useCallback(async () => {
		try {
			const result = await notificationService.listNotificationsSMS();
			Array.isArray(result) && setNotifications(result);
		} catch (error) {
			logger.warn('Unable to retrieve sms notifications list.');
		}
	}, []);

	useEffect(() => {
		asyncGetNotifications();
	}, [asyncGetNotifications]);

	const onCreateOrEditHandler = useCallback((notification) => {
		setOpenNotification(notification);
		setOnCreateOrEdit(true);
	}, []);

	const exitCreateOrEditModeHandler = useCallback(() => {
		setOpenNotification();
		setOnCreateOrEdit(false);
		asyncGetNotifications();
	}, [asyncGetNotifications]);

	const removeNotification = useCallback(async (id) => {
		try {
			await notificationService.removeNotificationSMS(id);
			setNotifications( prev => [...prev].filter(el => el?.id !== id));
		} catch (error) {
			logger.warn('Unable to remove notification.');
		}
	}, []);


	return (
		<NotificationsSMS
			{...props}
			enterCreateOrEditMode={onCreateOrEditHandler}
			exitCreateOrEditMode={exitCreateOrEditModeHandler}
			notifications={notifications}
			onCreateOrEdit={onCreateOrEdit}
			openConfigurations={openConfigurations}
			openNotification={openNotification}
			removeNotification={removeNotification}
			setSubType={setSubType}
			setType={setType}
			subType={subType}
			type={type}
		/>
	);
};

const permissions = [{ resource: ['carclub', 'notifications', 'read'] }];

export default withPermission(permissions)(NotificationsSMSWrapper);