const styles = theme => ({

	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		justifyContent: 'flex-start',
		minWidth: '0',
		width: '100%',
	},
	
	div: {
		alignItems: 'first baseline',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
	},

	list: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		overflowY: 'auto',
		paddingLeft: 21,
		paddingRight: 21,
		width: '100%',
	},

	title: {
		fontSize: '18px',
		margin: '10px 0 0 10px',
	},

	save: {
		color: '#009AC9',
		cursor: 'pointer',
		fontSize: '13px',
		margin: '0 10px 0 10px',
	},

	cancel: {
		color: '#707070',
		cursor: 'pointer',
		fontSize: '13px',
		margin: '0 10px 0 10px',
	},

	description: {
		borderBottom: 'none',
	},
	
	inputInput: {
		borderBottom: '1px solid gray',
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(2),
		transition: theme.transitions.create('width'),
		width: '100%',
	},

	inputError: {
		borderBottom: '1px solid red',
	},
	
	inputRoot: {
		color: 'inherit',
		width: '130px',
	},

	filters: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: 'auto',
		margin: '0 10px',
		width: '100%',
	},

	subFilters: {
		alignItems: 'first baseline',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
		width: '100%',
	},
	
	manage: {
		display: 'flex',
		width: '100%',
		marginTop: '10px',
	},

	button: {
		color: '#009AC9',
		cursor: 'pointer',
	},

	space: {
		minWidth: '0px',
		width: '10%',
	},

	higherSpace: {
		minWidth: '0px',
		width: '20%',
	},

	error: {
		color: 'red',
		fontSize: '12px',
	},
});

export default styles;