import React, { useState } from "react";
import useSwitch from "../../../hooks/useSwitch";
import PricingPlanListScreen from "./PricingPlanListScreen";
import { useSelector } from "react-redux";
import { selectDefaultLocation } from "../../../features/carclub/carclubSlice";

const PricingPlanListScreenWrapper = (props) => {
  const location = useSelector(selectDefaultLocation);

  const removeHandler = useSwitch(false);
  const updateHandler = useSwitch(false);
  const buttonHandler = useSwitch(false);
  const errorHandler = useSwitch(false);
  const [pricingPlanToChange, setPricingPlanToChange] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState();
  const [showPPCalendar, setShowPPCalendar] = useState(false);

  const [modal, setModal] = useState();
  const [pricingPlanId, setPricingPlanId] = useState();

  const [showPricingTable, setShowPricingTable] = useState(false);

  const { editMode, placeholder, placeholderSetters, setSelectedPricingPlan, updatePricingPlan } = props;

  const onUpdate = async () => {
    setErrorMessage();

    buttonHandler.setOn();

    updatePricingPlan(placeholder);

    buttonHandler.setOff();

    placeholderSetters.resetPlaceholder();
    updateHandler.setOff();
    editMode.setOff();
  };

  const onSave = () => {
    if (placeholder.name.trim().length < 1 || placeholder.description.trim().length < 1) {
      errorHandler.setOn();
      return;
    }

    errorHandler.setOff();

    updateHandler.state ? onUpdate() : setSelectedPricingPlan(pricingPlanToChange || true);
  };

  const onCustomersOrZonesEditHandler = (event, pricingPlan, modalType) => {
    event.preventDefault();
    event.stopPropagation();

    const id = pricingPlan.id;
    setPricingPlanId(id);
    setModal(modalType);
  };

  const setErrorFalse = () => {
    errorHandler.setOff();
    setErrorMessage();
  };

  const toggleShowPricingTable = () => {
    const current = showPricingTable;
    setShowPricingTable(!current);
  };
  return (
    <PricingPlanListScreen
      {...props}
      disableButtons={buttonHandler.state}
      errorMessage={errorMessage}
      hasError={errorHandler.state}
      isRemoving={removeHandler.state}
      location={location?.name || ""}
      modal={modal}
      onCustomersOrZonesEdit={onCustomersOrZonesEditHandler}
      onSave={onSave}
      pricingPlanToChange={pricingPlanToChange}
      removeHandler={(remove) => (remove ? removeHandler.setOn() : removeHandler.setOff())}
      selectedPricingPlanId={pricingPlanId}
      setErrorFalse={setErrorFalse}
      setModal={setModal}
      setPricingPlanToChange={setPricingPlanToChange}
      setShowPPCalendar={setShowPPCalendar}
      showPPCalendar={showPPCalendar}
      showPricingTable={showPricingTable}
      toggleShowPricingTable={toggleShowPricingTable}
      updateHandler={(update) => (update ? updateHandler.setOn() : updateHandler.setOff())}
    />
  );
};

export default PricingPlanListScreenWrapper;
