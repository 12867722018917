import React from 'react';
import ListItem from '../../../UI/List/ListItem/ListItem';
import {
	Tooltip,
	withStyles,
} from '@material-ui/core';
import withPermission from '../../../../hoc/withPermission';
import {
	Check as TrueIcon,
	Create as EditIcon,
	RemoveCircleOutline as RemoveIcon,
	Person as CustomerIcon,
	Map as DashboardIcon,
} from '@material-ui/icons';
import { ReactComponent as CalendarIcon } from '../../../../assets/PricingPlanApp/new/calendar.svg';
import { ReactComponent as GroupIcon } from '../../../../assets/PricingPlanApp/new/people.svg';
import { currencyFormatter } from '../../../../services/formatter';
import styles from './PricingPlanItem.styles';
import { MODALS } from '../../../../containers/PricingPlanAppNew/PricingPlanAppWrapper';

const PricingPlanItem = ({
	asyncGetPPDetails,
	classes,
	closeModals,
	createMode,
	fields,
	onCustomersOrZonesEdit,
	onEdit,
	onSelect,
	openCalendarHandler,
	placeholderSetters,
	pricingPlan,
	selected,
	setModal,
	setSelectedPricingPlanId,
}) => {

	const removeIcon = (
		<Tooltip title='Remove' placement='left'>
			<RemoveIcon
				onClick={event => {
					event.preventDefault();
					event.stopPropagation();
					setSelectedPricingPlanId(pricingPlan.id);
					setModal('ConfirmDeletePP');
				}}
				className={classes.editIcon}
			/>
		</Tooltip>
	);

	const specific = pricingPlan.specific && <TrueIcon className={classes.checkIcon} />;

	const editIcon = (
		<Tooltip title='Edit' placement='left'>
			<EditIcon
				onClick={event => {
					event.stopPropagation();
					setSelectedPricingPlanId(pricingPlan.id);
					placeholderSetters.setCode(pricingPlan.id);
					placeholderSetters.setName(pricingPlan.name);
					placeholderSetters.setDescription(pricingPlan.description);
					placeholderSetters.setStartingFee(pricingPlan.startingFee);
					placeholderSetters.setSpecific(pricingPlan.specific);
					placeholderSetters.setCategory(pricingPlan.category);
					placeholderSetters.setStartDate(pricingPlan.startDate);
					placeholderSetters.setEndDate(pricingPlan.endDate);
					onSelect(pricingPlan, true);
					asyncGetPPDetails(pricingPlan.id);
					setModal();
					onEdit.setOn();
				}}
				className={classes.editIcon}
			/>
		</Tooltip>
	);

	const editCustomersIcon = !!specific && (
		<Tooltip title='Manage Customers' placement='left'>
			<CustomerIcon
				onClick={(e) => {
					e.preventDefault();
					onCustomersOrZonesEdit(e, pricingPlan,
						MODALS.CUSTOMER_EDIT_MODAL);
					onSelect(pricingPlan, true);
					onEdit.setOff();
					createMode.setOff();
				}}
				className={classes.editIcon}
			/>
		</Tooltip>
	);

	const editZonesIcon = !!specific && (
		<Tooltip title='Manage Zones' placement='left'>
			<DashboardIcon
				onClick={(e) => {
					e.preventDefault();
					setSelectedPricingPlanId(pricingPlan.id);
					placeholderSetters.setCode(pricingPlan.id);
					placeholderSetters.setName(pricingPlan.name);
					placeholderSetters.setDescription(pricingPlan.description);
					placeholderSetters.setStartingFee(pricingPlan.startingFee);
					placeholderSetters.setSpecific(pricingPlan.specific);
					placeholderSetters.setCategory(pricingPlan.category);
					placeholderSetters.setStartDate(pricingPlan.startDate);
					placeholderSetters.setEndDate(pricingPlan.endDate);
					onCustomersOrZonesEdit(e, pricingPlan,
						MODALS.ZONE_EDIT_MODAL);
					onSelect(pricingPlan, true);
					asyncGetPPDetails(pricingPlan.id);
					onEdit.setOff();
					createMode.setOff();
				}}
				className={classes.editZonesIcon}
			/>
		</Tooltip>
	);

	const editGroupIcon = !!specific && (
		<Tooltip title='Manage Accounts' placement='left'>
			<GroupIcon
				className={classes.editZonesIcon}
				onClick={(e) => {
					e.preventDefault();
					onCustomersOrZonesEdit(e, pricingPlan,
						MODALS.GROUP_EDIT_MODAL);
					onSelect(pricingPlan, true);
					onEdit.setOff();
					createMode.setOff();
				}}
			/>
		</Tooltip>
	);

	const editCalendarIcon = (
		<Tooltip title='Open Calendar' placement='left'>
			<CalendarIcon
				onClick={(e) => {
					e.stopPropagation();
					setSelectedPricingPlanId(pricingPlan.id);
					placeholderSetters.setCode(pricingPlan.id);
					placeholderSetters.setName(pricingPlan.name);
					placeholderSetters.setDescription(pricingPlan.description);
					placeholderSetters.setStartingFee(pricingPlan.startingFee);
					placeholderSetters.setSpecific(pricingPlan.specific);
					placeholderSetters.setCategory(pricingPlan.category);
					placeholderSetters.setStartDate(pricingPlan.startDate);
					placeholderSetters.setEndDate(pricingPlan.endDate);
					onSelect(pricingPlan, true);
					openCalendarHandler();
				}}
				className={classes.editZonesIcon}
			/>
		</Tooltip>
	);

	const editIcons = (
		<div className={classes.icons}>
			{editGroupIcon}
			{editCustomersIcon}
			{editZonesIcon}
			{editCalendarIcon}
			<ButtonsWithPermission>
				{editIcon}
				{removeIcon}
			</ButtonsWithPermission>
		</div>
	);

	const startingFee = !!pricingPlan.startingFee
		&& currencyFormatter.format(pricingPlan.startingFee) + ' ' + currencyFormatter.symbol();

	const item = (
		<ListItem
			selected={selected}
			fields={fields}
			onSelect={(event) => {
				event.preventDefault();
				closeModals();
				setSelectedPricingPlanId(pricingPlan.id);
				placeholderSetters.setCode(pricingPlan.id);
				placeholderSetters.setName(pricingPlan.name);
				placeholderSetters.setDescription(pricingPlan.description);
				placeholderSetters.setStartingFee(pricingPlan.startingFee);
				placeholderSetters.setSpecific(pricingPlan.specific);
				placeholderSetters.setCategory(pricingPlan.category);
				placeholderSetters.setStartDate(pricingPlan.startDate);
				placeholderSetters.setEndDate(pricingPlan.endDate);
				onEdit.setOff();
				createMode.setOff();
				if (!!selected) {
					onSelect(pricingPlan);
					return;
				}
				asyncGetPPDetails(pricingPlan.id);
			}}
			element={{
				...pricingPlan,
				editIcons,
				startingFee,
				specific,
			}}
		/>
	);

	return item;
};

export default withStyles(styles)(PricingPlanItem);

const ButtonsWithPermission = withPermission(
	[{ resource: ['pricingPlansNew', 'pricingPlans', 'edit'] }],
)(({ children }) => <> {children} </>);