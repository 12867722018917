import {
	// Timeline as OperationsIcon,
	// History as FleetIcon,
	Dashboard as DashboardIcon,
} from '@material-ui/icons';
import { tabs } from '../../model/Dashboard/Tabs';

const buttonStyle = {
	background: 'white',
	borderRadius: '20px',
	padding: '5px',
	width: '25px',
	height: '25px',
	boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.161)',
	cursor: 'pointer',
};

const button = (icon, id, tab, right=false) => {
	return {
		icon,
		id,
		tab,
		style: buttonStyle,
		notButton: true,
		right,
	};
};

const menuButtons = []
	.concat(button(DashboardIcon, 'Dashboard', tabs.DASHBOARD))
;

export default menuButtons;