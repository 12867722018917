import { wsCall, requestHelper } from "../../../app/coreSlice";

const crAssetListNotInReservationCall = async ({ setCrAssets }, dispatch, state, { deviceLicensePlate }) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "ASSET/LIST_NOT_IN_RESERVATION");
  try {
    const result = await wsCall({
      type: "ASSET",
      subType: "LIST_NOT_IN_RESERVATION",
      locationId: selectedLocation?.id,
      message: {
        deviceLicensePlate
      },
    });
    if (result) {
      dispatch(setCrAssets(result));
    }
  } catch (_ex) {
    rh.error("Error getting assets.");
  }
  rh.close();
};

export default crAssetListNotInReservationCall;
