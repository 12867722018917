export const UNDEFINED = 'UNDEFINED';
export const DEACTIVATED = 'DEACTIVATED';
export const UNAVAILABLE = 'UNAVAILABLE';
export const AVAILABLE = 'AVAILABLE';
export const UNUSED = 'UNUSED';
export const IN_USE = 'IN_USE';

export const getStatus = ({ operational, inUse, assetUnused, deviceToken }) => {
	if (!deviceToken) {
		return DEACTIVATED;
	};

	if (inUse) {
		return IN_USE;
	};

	if (operational && assetUnused) {
		return UNUSED;
	};

	if (operational) {
		return AVAILABLE;
	};

	if (operational !== undefined) {
		return UNAVAILABLE;
	};

	return UNDEFINED;
};
