import getPermission from './getPermission';
import getTemplate from './getTemplate';
import listPermissions from './listPermissions';
import updatePermission from './updatePermission';

const carclubService = {
	getPermission,
	getTemplate,
	listPermissions,
	updatePermission,
};

export default carclubService;