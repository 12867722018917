import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Field } from "react-final-form";
import MetaError from "../MetaError";
import { styles } from "./_base.styles";
import { TextInput } from "../../inputs";

function TextField({
  classes,
  className,
  id,
  type,
  label,
  disabled,
  required,
  multiline,
  rows,
  placeholder,
  uppercase,
}) {
  const onChangeHandler = (v, input) => {
    input.onChange && input.onChange(uppercase ? v.toUpperCase() : v);
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => (
          <>
            <TextInput
              className={classes.input + (className ? " " + className : "")}
              id={id}
              label={label}
              type={type || "text"}
              value={input.value || ""}
              onChange={(value) => onChangeHandler(value, input)}
              disabled={disabled}
              required={required}
              error={Boolean(meta.error && meta.touched)}
              // variant={"outlined"}
              multiline={multiline}
              rows={rows}
              placeholder={placeholder}
            />
            <div className={"FormFieldError " + classes.error}>
              <MetaError meta={meta} />
            </div>
          </>
        )}
      </Field>
    </div>
  );
}

export default withStyles(styles)(TextField);
