import Image from "../../../../../assets/AssetApp/damageReportImages/damageReport.png";
import ImageScooter from "./scooter.svg";

const styles = (theme) => ({
  root: {
    width: "270px",
    maxWidth: "270px",
    height: "540px",
    maxHeight: "540px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    margin: "0 auto",
    padding: 0,
    backgroundSize: "cover",
    [theme.breakpoints.down("md")]: {
      width: "220px",
      maxWidth: "220px",
      height: "440px",
      maxHeight: "440px",
    },
  },

  car: {
    backgroundImage: `url(${Image})`,
  },

  scooter: {
    backgroundImage: `url(${ImageScooter})`,
  },

  cell: {
    width: "5.88%",
    height: "2.94%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  damage: {
    width: "80%",
    height: "80%",
    backgroundColor: "red",
    borderRadius: "50%",
    cursor: "pointer",
  },

  selectedDamage: {
    backgroundColor: "yellow",
    zIndex: 5,
  },
});

export default styles;
