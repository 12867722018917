import React from 'react';
import DetailsDiv from '../../../UI/DetailsDiv/DetailsDiv';
import DetailsRow from '../../../UI/DetailsDiv/DetailsRow/DetailsRow';
import { dateFormatter, currencyFormatter } from '../../../../services/formatter';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import styles from './Details.styles';

const Application = ({
	classes,
	info,
}) => {
	const endDate = (
		<DetailsRow
			label='End Date'
			detail={
				<Typography className={classes.typography}>
					{dateFormatter.format(info?.expirationDate, 'date')}
				</Typography>
			}
		/>
	);

	const amount = (
		<DetailsRow
			label='Amount'
			detail={
				<Typography className={classes.typography}>
					{`${info?.amount || '-'} ${currencyFormatter.symbol()}`}
				</Typography>
			}
		/>
	);

	return (
		<DetailsDiv title='Application'>
			{endDate}
			{amount}
		</DetailsDiv>
	);
};

export default withStyles(styles)(Application);