import React, { useState, useCallback, useEffect } from 'react';
import permissionService from '../../../services/permissions';
import PermissionsWorkspace from './PermissionsWorkspace';
import log from '../../../utils/logger';

const logger = log('PermissionsWorkspaceWrapper');

const PermissionsWorkspaceWrapper = props => {

	const [selected, setSelected] = useState();
	const [permission, setPermission] = useState({});
	const [template, setTemplate] = useState({});
	const [list, setList] = useState({});

	useEffect(() => {
		const getPermissions = async () => {
			try {
				const {
					permissions,
					lastPage,
				} = await permissionService.listPermissions();
				setList({lastPage, permissions});
			}
			catch (error){
				logger.warn('Error getting permissions list');
			}
		};

		getPermissions();
	}, []);

	useEffect(() => {
		const getTemplate = async () => {
			try{
				const template = await permissionService.getTemplate();
				
				setTemplate(template);
			}
			catch(error){
				setTemplate({});
			};
		};

		getTemplate();
	}, []);

	useEffect(() => {
		const getPermissionDetails = async () => {
			try{
				const permission = await permissionService.getPermission(selected);
				
				setPermission(permission);
			}
			catch(error){
				setPermission({});
			};
		};

		getPermissionDetails();
	}, [selected]);

	const createPermission = useCallback(() =>{
		setPermission({});
		setSelected();
	}, []);

	const addPermission = useCallback(newPermission => {
		setList(prev => ({
			...prev,
			permissions: {
				...prev.permissions, 
				[newPermission.id]: newPermission,
			},
		}));
	}, [setList]);

	const onSearch = useCallback(async (reset, filter)=>{
		try {
			const {
				permissions,
				lastPage,
			} = await permissionService.listPermissions(filter);
			setList(reset 
				? {lastPage, permissions}
				: prev => ({
					lastPage,
					permissions: {...prev.permissions, ...permissions},
				})
			);
		}
		catch (error){
			logger.warn('Error updating permissions list');
		}
	}, []);

	return (
		<PermissionsWorkspace
			{...props}
			addPermission={addPermission}
			allPermissions={template}
			createPermission={createPermission}
			list={Object.values(list.permissions || {})}
			lastPage={list.lastPage}
			permission={permission}
			selected={selected} 
			setPermission={setPermission}
			setSelected={setSelected}
			onSearch={onSearch}
		/>
	);
};

export default PermissionsWorkspaceWrapper;