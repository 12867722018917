
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_REQUEST_IS_MALFORMED,
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import { publishAndAwait } from '../../../app/coreSlice';

const createAsset = async ({
	assetName,
	assetType,
	assetTypeName,
	babySeat,
	class: carClass,
	fuelType,
	imageUrl,
	licensePlate,
	make,
	model,
	transmission,
	vinNumber,
	year,
	attributes,
}) => {

	if (typeof assetType === 'undefined'
		|| typeof carClass === 'undefined'
		|| typeof fuelType === 'undefined'
		|| typeof imageUrl === 'undefined'
		|| typeof licensePlate === 'undefined'
		|| typeof make === 'undefined'
		|| typeof transmission === 'undefined'
		|| typeof vinNumber === 'undefined'
		|| typeof year === 'undefined') {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	}

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'ASSET',
			subType: 'CREATE',
			message: {
				assetName,
				assetType,
				assetTypeName,
				babySeat,
				carClass,
				fuel: fuelType,
				imageUrl,
				licensePlate,
				make,
				model,
				transmission,
				vinNumber,
				year,
				attributes,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
			message,
		} = processMessage;

		throw new NGError({
			message: description || message,
			code,
			tag,
		});
	}

	return processMessage;

};

export default createAsset;