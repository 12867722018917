import Permission from '../../../../model/Permission/Permission';

export const fromDTO = ({
	accessLevel,
	createDate,
	createUser,
	description,
	id,
	name,
	permissions,
	updateDate,
	updateUser,
}) => new Permission({
	accessLevel,
	createBy: createUser,
	createDate,
	description,
	id,
	modifiedBy: updateUser,
	modifiedDate: updateDate,
	name,
	permissions,
});

export const toDTO = ({
	accessLevel,
	description,
	id,
	permissions,
	name,
}) => ({
	accessLevel,
	description,
	id,
	name,
	permissions,
});

const mapper = { fromDTO, toDTO };

export default mapper;