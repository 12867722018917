import React, { useState, useEffect, useCallback, useRef } from "react";
import s3Service from "../../../../services/S3/index";
import UpdateBanner from "./UpdateBanner";
import log from "../../../../utils/logger";
import importCoordinatesXML from "../../../../services/ImportXML/CoordinatesXML";

const logger = log("IncidentUpdaterapper");

const { uploadImage, saveImage } = s3Service;

const UpdateBannerWrapper = ({ banner, onSave, setDisableButtons, setError, ...props }) => {
  const [kml, changeKml] = useState();
  const [newImage, setNewImage] = useState();
  const [newImageName, setNewImageName] = useState();
  const [placeholder, setPlaceholder] = useState(banner ? { ...banner } : {});

  const inputRef = useRef();

  const onNewImage = useCallback((e) => setNewImage(e?.target?.files?.[0]), []);

  useEffect(() => {
    newImage && document.getElementById("save_image_button").focus({ preventScroll: false });
  }, [newImage]);

  const imageHandler = useCallback(() => {
    setPlaceholder((prev) => {
      const newFile = new File([newImage], newImageName || newImage?.name, {
        type: newImage?.type,
      });

      const items = [...(prev?.items || []), newFile];

      setNewImage();
      setNewImageName();
      inputRef.current.value = "";
      return { ...prev, items };
    });
  }, [newImage, newImageName]);

  useEffect(() => {
    setPlaceholder(banner || {});
  }, [banner]);

  const onChange = useCallback(
    (value) => {
      setError(false);
      setPlaceholder((prev) => ({ ...prev, ...value }));
    },
    [setError]
  );

  const removeImage = useCallback((index) => {
    setPlaceholder((prev) => ({ ...prev, items: prev.items.filter((item, curr) => curr !== index) }));
  }, []);

  const saveImagesURL = useCallback(async () => {
    setError(false);

    if (
      !placeholder?.items ||
      placeholder.items.length < 1 ||
      (!placeholder.id && (!placeholder.geoInformation || !placeholder.geoLocation))
    )
      return setError("Please be sure to add at least 1 image and a file");

    setDisableButtons(true);

    try {
      const imagesToSave = await Promise.all(
        (placeholder?.items || [])
          .filter((item) => typeof item.url !== "string")
          .map(async (item) => {
            const image = await uploadImage(item);
            return { name: item.name, url: image.keyname };
          })
      );

      const savedUrls = (await (imagesToSave.length > 0 && saveImage(imagesToSave.map((image) => image.url)))) || [];

      if (savedUrls.error) {
        setError(savedUrls.error);
      }

      const items = [
        ...(placeholder?.items || []).filter((item) => typeof item?.url === "string"),
        ...savedUrls.map((url, index) => ({ url, name: imagesToSave?.[index]?.name })),
      ];

      onSave({ ...placeholder, items });
    } catch (error) {
      logger.info(error);
      setError(error.message || error.description);
      setDisableButtons(false);
    }
  }, [placeholder, setError, onSave, setDisableButtons]);

  const setKML = useCallback(
    (file) => {
      if (!file) return;

      importCoordinatesXML(
        file,
        ({ geoInformation, geoLocation }) => setPlaceholder((prev) => ({ ...prev, geoInformation, geoLocation })),
        setError
      );
      changeKml(file);
    },
    [setError]
  );

  return (
    <UpdateBanner
      {...props}
      imageHandler={imageHandler}
      inputRef={inputRef}
      kml={kml}
      newImage={newImage}
      newImageName={newImageName}
      onChange={onChange}
      onNewImage={onNewImage}
      onSave={saveImagesURL}
      placeholder={placeholder}
      removeImage={removeImage}
      setKML={setKML}
      setNewImageName={setNewImageName}
    />
  );
};

export default UpdateBannerWrapper;
