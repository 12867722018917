import React from 'react';
import * as listItemTypes from './ListItemTypes';
import {
	ListItem as MUIListItem,
	withStyles,
} from '@material-ui/core';
import styles from './ListItem.styles';
import classnames from 'classnames';

const paddingCorrection = 10;

const ListItem = ({
	classes,
	className,
	element,
	fields,
	onSelect,
	selected,
	dinamicStyle={},
}) => {

	const row = fields.map(field => {

		const style = {
			minWidth: field.width ? field.width - paddingCorrection : undefined,
			width: field.width ? field.width - paddingCorrection : undefined,
			maxWidth: field.maxWidth ? field.maxWidth - paddingCorrection : undefined,
			height: field.height ? field.height - paddingCorrection : undefined,
			flexDirection: field.flexDirection,
			padding: field.padding ? field.padding : undefined,
			color: dinamicStyle.color ? dinamicStyle.color : undefined,
		};

		return {
			[listItemTypes.TEXT]: (
				<span key={field.key} style={style} className={classes.field}>
					{element[field.key]}
				</span>
			),

			[listItemTypes.COMPONENT]: (
				<div
					key={field.key}
					style={style}
					className={classnames(classes.field, !field.flexDirection && classes.comp)}
				>
					{element[field.key]}
				</div>
			),

		}[field.type];
	});

	const content = (
		<div className={classes.root}>
			{row}
		</div>
	);

	const listItem = (
		<MUIListItem
			dense
			onClick={onSelect ? onSelect : () => null}
			className={classnames(classes.row, selected && classes.selected, className)}
		>
			{content}
		</MUIListItem>
	);

	return listItem;
};

export default withStyles(styles)(ListItem);
