export const styles = () => ({
  root: {},
  button: {
    fontSize: 9,
    minWidth: 90,
    minHeight: 25,
  },
  prevValue: {
    textAlign: "center",
    width: 190,
    "& p": {
      color: "#C5C5C5",
    },
  },
});
