const styles = theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		minHeight: '0',
		boxSizing: 'border-box',
	},

	list: {
		display: 'flex',
		flexDirection: 'column',
		transition: '500ms',
		backgroundColor: '#eee',
	},

	listItemText: {
		fontSize: '14px',
	},

	selected: {
		fontWeight: 'bold',
	},

	listItem: {
		borderBottom: '1px solid #8885',
		boxSizing: 'border-box',
		display: 'flex',
		padding: '5px 12.5px 5px 15px',
		width: '100%',
		cursor: 'pointer',
	},

	header: {
		borderBottom: '1px solid #8885',
		boxSizing: 'border-box',
		display: 'flex',
		fontSize: '16px',
		justifyContent: 'space-between',
		padding: '10px',
		width: '100%',
	},

	addIcon: {
		cursor: 'pointer',
		color: '#009AC9',
		height: '22.5px',
		width: 'auto',
		marginLeft: 'auto',
	},

	icon: {
		cursor: 'pointer',
		color: '#009AC9',
		height: '26.5px',
		width: 'auto',
		transition: '500ms',
	},

	iconExpanded: {
		transform: 'rotate(180deg)',
	},

	hidden: {
		display: 'none',
	},

	redDot: {
		width: '10px',
		height: '10px',
		backgroundColor: '#08e',
		borderRadius: '50%',
		display: 'inline-block',
		marginRight: '5px',
	},
});

export default styles;