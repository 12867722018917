const reader = new FileReader();

const parser = new DOMParser();

const importCoordinatesXML = (xml, setFunction, onError) => {
  reader.onload = (e) => {
    const readXml = e.target.result;

    let doc = parser.parseFromString(readXml, "text/xml");

    try {
      let name = doc.getElementsByTagName("name");

      if (!!name) {
        name = name[0];
        name = name && name.childNodes;
        name = name && name[0].nodeValue;
      }

      const coordinates = doc
        .getElementsByTagName("coordinates")[0]
        .childNodes[0].nodeValue.replace(/[0,]+\n/g, ",")
        .trim()
        .split(",")
        .map((coord) => coord.trim());

      let geoLocation = [];
      for (let i = 0; i < coordinates.length; i++) {
        i % 2 !== 0 && geoLocation.push([coordinates[i], coordinates[i - 1]]);
      }

      const latitude = coordinates[1];
      const longitude = coordinates[0];

      geoLocation = "[" + geoLocation.join(" ").replace(/\s/g, "],[") + "]";
      const geoInformation = "[" + geoLocation + "]";

      setFunction({ geoInformation, geoLocation, name, description: name, latitude, longitude });
    } catch {
      alert("Could not import from this file");
      "function" === typeof onError && onError();
    }
  };

  reader.readAsText(xml);
};

export default importCoordinatesXML;
