
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import { fromDTO } from './mappers/mapper';
import { publishAndAwait } from '../../../app/coreSlice';

const listPermissions = async filter => {

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'ROLE',
			subType: 'LIST_PERMISSION',
			message: {
				filter,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}
	
	const {
		lastPage,
		permissions: permissionsList,
	} = processMessage;

	const permissions = Object.values(permissionsList || processMessage).reduce(mapPermissions, {});

	return { permissions, lastPage };
};

export default listPermissions;

const mapPermissions = (acc, permission) => 
	({...acc, [permission.id]: fromDTO(permission)});