
import errorFactory from '../../../error/errorFactory';
import {
	WS_REQUEST_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import log from '../../../utils/logger';
import { publishAndAwait } from '../../../app/coreSlice';

const logger = log('saveImage#S3Service');

const saveImage = async (keyname, bucketKey) => {

	if (typeof keyname === 'undefined'
		|| typeof bucketKey === 'undefined') {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	};

	const isArray = Array.isArray(keyname);

	const s3PublicLink = isArray
		? keyname.map(keyname => ({ keyname, bucket: bucketKey }))
		: [{ keyname, bucket: bucketKey }];

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'S3',
			subType: 'SAVE_LINK',
			message: {
				s3PublicLink,
				s3PrivateLink: [],
			},
		},
	});

	const { processMessage, error } = request.response;

	if (error || !processMessage) {
		logger.warn(`Error saving image: ${error}`);
		return { error: processMessage ? processMessage.description : 'Error saving image' };
	};

	return isArray
		? processMessage.s3PublicLink.map(obj => obj.savedUrl)
		: processMessage.s3PublicLink[0].savedUrl;
};

export default saveImage;


