export const styles = theme => ({

	root: {
		backgroundColor: 'inherit',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		padding: 8,
		boxSizing: 'border-box',
	},

	detail: {
		fontSize: '10px',
		lineHeight: '20px',
	},

	title: {
		margin: '5px 0 0',
	},

	label: {
		fontWeight: 'normal',
	},

	checkIcon: {
		color: '#51B383',
	},
});