import React, { useState, useEffect, useRef } from "react";
import permissionService from "../../../../services/permissions";
import PermissionsCreate from "./PermissionsCreate";
import log from "../../../../utils/logger";
import { useCallback } from "react";

const logger = log("PermissionsCreateWrapper");

const PermissionsCreateWrapper = ({ addPermission, permission, setSelected, ...props }) => {
  const [placeholder, setPlaceholder] = useState({});
  const [hasRequest, setHasRequest] = useState(false);
  const [notification, setNotification] = useState();
  const timerId = useRef();

  useEffect(() => {
    return () => timerId?.current && clearTimeout(timerId?.current);
  }, []);

  const notificationHandling = useCallback((message, type) => {
    setNotification({ message, type });
    timerId.current = setTimeout(() => setNotification(), 2000);
  }, []);

  useEffect(() => {
    if (permission.id !== placeholder.id || Object.keys(permission).length === 0) {
      //...spread will make a shallow copy, this will make a deep copy
      setPlaceholder(JSON.parse(JSON.stringify(permission)) || {});
    }
  }, [permission, placeholder.id]);

  useEffect(() => {
    setPlaceholder((prev) => ({
      ...prev,
      //...spread will make a shallow copy, this will make a deep copy
      permissions: JSON.parse(JSON.stringify(permission.permissions || {})),
    }));
  }, [permission]);

  const onChange = (field) => setPlaceholder((prev) => ({ ...prev, ...field }));

  const onUpdate = useCallback(async () => {
    setHasRequest(true);

    let accessLevel = parseInt(placeholder?.accessLevel);
    if (accessLevel < 10 || accessLevel > 100) {
      notificationHandling("Invalid Access Level, should be an integer between 10 and 100", "error");
      setHasRequest(false);
      return;
    }

    try {
      const newPermission = await permissionService.updatePermission(placeholder);

      addPermission(newPermission);
      notificationHandling(placeholder.id ? "Successfully Updated" : "Successfully Saved", "notification");
      setPlaceholder(newPermission);
      setSelected(newPermission.id);
    } catch (error) {
      logger.warn("Could not save new permission\n[error]:", error.description);
      notificationHandling(error.description || error.message || "Could not save permission", "error");
    }
    setHasRequest(false);
  }, [addPermission, setSelected, placeholder, notificationHandling]);

  return (
    <PermissionsCreate
      {...props}
      hasRequest={hasRequest}
      notification={notification}
      onUpdate={onUpdate}
      placeholder={placeholder}
      setPlaceholder={onChange}
    />
  );
};

export default PermissionsCreateWrapper;
