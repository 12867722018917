export const styles = () => ({
  root: {
    position: "relative",
    height: 32,
    width: 32,
  },
  mapMarker: {
    marginLeft: -16,
    marginTop: -34,
  },
  mapPin: {
    position: "absolute",
    height: 30,
    width: 30,
    margin: "1px",
    borderRadius: "50% 50% 50% 0",
    transform: "rotateZ(-45deg)",
  },
  icon: {
    position: "absolute",
    height: 32,
    width: 32,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon_DEACTIVATED: {
    backgroundColor: "#000000",
  },
  icon_IN_USE: {
    backgroundColor: "#0092E9",
  },
  icon_UNUSED: {
    backgroundColor: "#DAA520",
  },
  icon_AVAILABLE: {
    backgroundColor: "#00B67F",
  },
  icon_UNAVAILABLE: {
    backgroundColor: "#FD2E25",
  },
  icon_UNDEFINED: {
    backgroundColor: "#89898B",
    color: "#FFFFFF",
  },
});
