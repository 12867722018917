import React from 'react';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import classnames from 'classnames';
import { currencyFormatter } from '../../../../services/formatter';
import styles from './Graphs.styles';

const Cards = ({
	classes,
	color,
	selected,
	setSelected,
	symbol,
	total,
	...rest
}) => {
	if (total !== 0 && !total) {
		return null;
	};

	const totalCard = (
		<div
			className={classnames(classes.card)}
			onClick={() => setSelected()}
			style={{ '--color': color }}
		>
			<Typography className={classes.text}>
				Total
			</Typography>
			<Typography
				className={classnames(classes.value, undefined === selected && classes.bold)}
			>
				{total} {symbol && currencyFormatter.symbol()}
			</Typography>
		</div>
	);

	const cards = Object.keys(rest).map(key => {
		const name = (
			<Typography className={classes.text}>
				{key.replace(/([A-Z])/g, ' $1')}
			</Typography>
		);

		const value = (
			<Typography
				className={classnames(classes.value, key === selected && classes.bold)}
			>
				{rest[key]} {symbol && currencyFormatter.symbol()}
			</Typography>
		);

		return (
			<div
				className={classnames(classes.card)}
				key={`card_${key}`}
				onClick={() => setSelected(key)}
				style={{ '--color': color }}
			>
				{name}
				{value}
			</div>
		);
	});

	return (
		<div className={classes.cardsRoot}>

			{totalCard}
			{cards}
		</div>
	);
};

export default withStyles(styles)(Cards);