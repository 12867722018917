import React, {
	useEffect,
	useState,
} from 'react';
import AccountDetails from './AccountDetails';
import log from '../../../utils/logger';

const logger = log('AccountDetailsWrapper');

const AccountDetailsWrapper = props => {

	const {
		account,
		getAccount,
	} = props || {};

	const [accountDetails, setAccountDetails] = useState();

	//eslint-disable-next-line
	const getAccountAsyncWrapper = async (account) => {

		try {
			
			const requestedDetails = await getAccount(account);
			return requestedDetails;

		} catch (error) {
			logger.info(error);
		}
	};

	useEffect(() => {

		setAccountDetails(account);
	}, [account, getAccount]);

	return (
		<AccountDetails
			{...props}
			accountDetails={accountDetails}
		/>
	);
};

export default AccountDetailsWrapper;
