import { wsCall, requestHelper } from "../../../app/coreSlice";

const reservationPaymentDetailsCall = async (
  { setSelectedTripPaymentDetails },
  dispatch,
  state,
  { reservationCode }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;

  let rh = requestHelper(dispatch, "RESERVATION/PAYMENT_DETAILS");
  try {
    dispatch(setSelectedTripPaymentDetails({}));

    const result = await wsCall({
      type: "RESERVATION",
      subType: "PAYMENT_DETAILS",
      locationId: selectedLocation?.id,
      message: {
        reservationCode,
      },
    });

    dispatch(setSelectedTripPaymentDetails(result));
  } catch (_ex) {
    rh.error("Error getting reservation payment details.");
  }
  rh.close();
};

export default reservationPaymentDetailsCall;
