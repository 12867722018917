const styles = () => ({
	detail: {
		fontSize: '10px',
		lineHeight: '20px',
	},

	checkIcon: {
		color: '#51B383',
	},

	imageDiv: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},

	imageName: {
		margin: '10px',
		color: '#009AC9',
	},

	image: {
		maxWidth: '250px',
		maxHeight: '200px',
		cursor: 'pointer',
	},

	images: {
		maxWidth: '100%',
		maxHeight: '100%',
		display: 'flex',
		flexDirection: 'column',
		boxSizing: 'border-box',
	},

	imageText: {
		fontWeight: 'bold',
	},
});

export default styles;