export const styles = theme => ({

	root: {
		zIndex: '100',
	},

	list: {
		minWidth: 172,
		paddingBottom: 0,
	},

	locationRoot: {
		zIndex: '100',
		padding: 0,
	},

	locationList: {
		minWidth: 140,
		paddingBottom: 0,
	},

	menuItem: {
		width: '100%',
		justifyContent: 'center',
	},

	locMenuItem: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		aligntItems: 'center',
	},

	expandIcon: {
		alignSelf: 'center',
		position: 'absolute',
	},

});