
export const fromDTO = ({
  changeUsername,
  createUsername,
  description,
  subject,
  changeDate,
  
  subType,
  id,
  type,
  body,
  toAddress,
  createDate,
  bcc,
  ...rest
}) =>
  ({
    createDate,
    createdBy: createUsername,
    id,
    subject,
    toAddress,
    description, //FIXME Wait for BE to implement this
    template: body,
    // info, // FIXME This will probably need mapping
    lastModified: changeDate,
    // lastSent, // FIXME This will probably need mapping
    modifiedBy: changeUsername,
    subType,
    // totalSent, // FIXME This will probably need mapping
    type,
    bcc,
    ...rest,
  });

export const toDTO = () => alert("Mapper undefined");

const mapper = {
  fromDTO,
  toDTO,
};

export default mapper;
