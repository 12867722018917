export const REPLACE = 'REPLACE';

const makeActionCreator = (type, ...argNames) => {

	return (...args) => {
  
		const action = { type };
  
		argNames.forEach((arg, index) => {
			return action[argNames[index]] = args[index];
		});
  
		return action;
	};
};

export default makeActionCreator;