export const styles = () => ({
  root: {
    "& .CdgRow": {
      "&:hover": {
        backgroundColor: "#eee",
      },
    },
  },
  datagrid: {
    height: "auto",
    "& table": {
      padding: 0,
    },
  },
  icon: {
    cursor: "pointer",
    color: "#009AC9",
  },
});
