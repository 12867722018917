const styles = (theme) => ({
	root: {
	},

	crossIcon: {
		cursor: 'pointer',
	},

	searchIcon: {
		cursor: 'pointer',
	},

	searchIconShadow: {
		color: '#009AC9',
	},

	inputRoot: {
		color: 'inherit',
		width: '100%',
	},

	inputInput: {
		paddingTop: theme.spacing(2),
		paddingRight: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		transition: theme.transitions.create('width'),
		width: '100%',
		borderBottom: '1px solid rgba(224, 224, 224, 1)',
	},
	
});

export default styles;