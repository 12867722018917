import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Button, Grid, Modal } from "@material-ui/core";

import { styles } from "./SimpleConfirmationModal.styles";

function SimpleConfirmationModal({ classes, open, setOpen, message, onConfirm }) {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className={classes.root}>
        <Grid container direction="column" spacing={2}>
          <Grid item>{message}</Grid>
          <Grid item>
            <Grid container justifyContent="space-around">
              <Grid item>
                <Button variant="contained" color="primary" onClick={onConfirm}>
                  Confirm
                </Button>
              </Grid>
              <Grid item>
                <Button color="primary" variant="outlined" onClick={() => setOpen(false)}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}

export default withStyles(styles)(SimpleConfirmationModal);
