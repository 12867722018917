
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import { dateFormatter } from '../../formatter/index';
import { publishAndAwait } from '../../../app/coreSlice';

const getAssociatedAssets = async (parkId, filter, start, end, available) => {
	const endDate = end && dateFormatter.toJSON(end, 'dateTimeRequest');
	const startDate = start && dateFormatter.toJSON(start, 'dateTimeRequest');

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'PARK',
			subType: 'GET_ASSETS',
			message: {
				parkId,
				filter,
				endDate,
				startDate,
				available,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	};

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	};

	return processMessage;
};

export default getAssociatedAssets;