import React, { useCallback, useState, useEffect, useRef } from "react";
import Update from "./Update";
import { FiberManualRecord as BallIcon } from "@material-ui/icons";
import { useSelector } from "react-redux";
import assetService from "../../../../services/asset";
import workorderService from "../../../../services/workOrder";
import log from "../../../../utils/logger";
import { selectDefaultLocation } from "../../../../features/carclub/carclubSlice";
import { useDateTime } from "../../../../common/hooks";

const logger = log("WorkOrders>Update");

const UpdateWrapper = ({ onCancel, setSelectedWorkOrder, types, updateWorkOrder, workOrder, h3Map, ...props }) => {
  const {
    placeholderMap,
    mapPlaceholderOnChangeHandler,
    setReturnedAPI,
    servicePointMarker,
    resetServicePointMarker,
    externalOnChange,
    setExternalModal,
    exitMap,
    selectedAsset,
    route,
    paintExistingRoute,
  } = h3Map || {};
  const { toUtc } = useDateTime();

  const [placeholder, setPlaceholder] = useState(workOrder);
  const [modal, setModal] = useState();
  const [error, setError] = useState();
  const [hasRequest, setHasRequest] = useState(false);
  const [agentStatusFilter, setAgentStatusFilter] = useState();

  const servicePointCode = placeholder?.servicePointCode;

  const location = useSelector(selectDefaultLocation);

  const titleRef = useRef();

  useEffect(() => {
    return () => {
      resetServicePointMarker();
      selectedAsset.current = null;
    };
    //eslint-disable-next-line
  }, []);

  const onChange = useCallback(
    (field) =>
      setPlaceholder((prev) => {
        return { ...prev, ...field };
      }),
    []
  );

  useEffect(() => {
    exitMap.current = setModal;
  }, [exitMap]);

  useEffect(() => {
    if (typeof servicePointCode !== "undefined") {
      resetServicePointMarker();
    }
  }, [servicePointCode, resetServicePointMarker]);

  useEffect(() => {
    if (typeof servicePointMarker !== "undefined") {
      if (!!servicePointMarker?.position) {
        setPlaceholder((prev) => {
          const template = types.find((template) => template.type === prev.type);
          return {
            ...prev,
            servicePointCode: undefined,
            startLatitude: servicePointMarker.position.lat(),
            startLongitude: servicePointMarker.position.lng(),
            tasks: template?.tasks || [],
          };
        });
      }
    }
  }, [servicePointMarker, types]);

  useEffect(() => {
    externalOnChange.current = onChange;
  }, [onChange, externalOnChange]);

  useEffect(() => {
    setExternalModal(modal);
  }, [modal, setExternalModal]);

  /**
   * This is used to be sure @tasks are also part of @placeholder
   */
  useEffect(() => {
    const valueToSet = { ...placeholder };
    if (selectedAsset?.current?.assetToken) {
      valueToSet.assetToken = selectedAsset?.current?.assetToken;
    }
    if (selectedAsset?.current?.licensePlate) {
      valueToSet.licensePlate = selectedAsset?.current?.licensePlate;
    }
    setPlaceholder((prev) => {
      const template = types.find((template) => template.type === prev.type);
      return { ...prev, ...valueToSet, tasks: template?.tasks || [] };
    });
    // eslint-disable-next-line
  }, [selectedAsset, types]);

  const onTypeChange = useCallback(
    (field) => {
      if (!field.type) {
        return setPlaceholder((prev) => ({ ...prev, type: undefined, tasks: undefined }));
      }

      const template = types.find((template) => template.type === field.type);
      const workorder = template ? { ...template } : {};
      delete workorder.code;
      delete workorder.carclubCode;
      delete workorder.locationCode;

      setPlaceholder((prev) => ({
        ...prev,
        ...workorder,
      }));
    },
    [types]
  );

  const getOperators = useCallback(
    async ({ string: name }) => {
      const onlineIcon = <BallIcon style={{ fill: "#00B67F" }} />;

      const offlineIcon = <BallIcon style={{ fill: "#FD2E25" }} />;

      const online = !!agentStatusFilter ? true : undefined;
      const { agents } = await workorderService.listAgents({ agent: true, name, online });

      const mappedAgents = agents.map((agent) => {
        return {
          key: agent?.code,
          label: agent?.fullName,
          online: agent?.online ? onlineIcon : offlineIcon,
          count: agent?.worktasksCount,
        };
      });

      return mappedAgents;
    },
    [agentStatusFilter]
  );

  const getVehicles = useCallback(async ({ string: deviceLicensePlate }) => {
    const assets = await assetService.listAsset(deviceLicensePlate || "");
    const vehicles = assets.map((vehicle) => ({ key: vehicle.assetToken, label: vehicle.licensePlate }));
    return vehicles;
  }, []);

  const onSave = async () => {
    setError();

    const { assetToken, priority, scheduledEndDate, scheduledStartDate, type } = placeholder;

    const badDates = scheduledStartDate && scheduledEndDate && scheduledStartDate > scheduledEndDate;

    if (!assetToken || !priority || badDates || !type /** || !location.id */) {
      titleRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      return setError(badDates ? "Check start and end dates" : true);
    }

    setHasRequest(true);

    try {
      const newWorkorder = await workorderService.updateWorkorder({
        ...placeholder,
        scheduledStartDate: scheduledStartDate ? toUtc(scheduledStartDate).format("YYYY-MM-DD HH:mm:ss") : undefined,
        scheduledEndDate: scheduledEndDate ? toUtc(scheduledEndDate).format("YYYY-MM-DD HH:mm:ss") : undefined,
      });

      updateWorkOrder(newWorkorder);
      setSelectedWorkOrder(newWorkorder.code);
      onCancel();
    } catch (e) {
      console.error(e);
      logger.warn("Could not save workorder");
      setError("Could not save workorder");
    } finally {
      setHasRequest(false);
    }
  };

  const onAPIloadedHandler = ({ map, maps }) => {
    setReturnedAPI({
      map,
      maps,
    });
  };

  return (
    <Update
      {...props}
      agentStatusFilter={agentStatusFilter}
      error={error}
      getOperators={getOperators}
      getVehicles={getVehicles}
      hasRequest={hasRequest}
      location={location}
      modal={modal}
      onCancel={onCancel}
      onChange={onChange}
      onGoogleAPILoaded={onAPIloadedHandler}
      onSave={onSave}
      onTypeChange={onTypeChange}
      placeholder={placeholder}
      setAgentStatusFilter={setAgentStatusFilter}
      showMap={setModal}
      titleRef={titleRef}
      types={types}
      placeholderMap={placeholderMap}
      mapPlaceholderOnChangeHandler={mapPlaceholderOnChangeHandler}
      servicePointMarker={servicePointMarker}
      resetServicePointMarker={resetServicePointMarker}
      route={route}
      paintExistingRoute={paintExistingRoute}
    />
  );
};

export default UpdateWrapper;
