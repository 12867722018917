import { createSlice } from "@reduxjs/toolkit";
import deviceCreateTeltonikaCall from "./_sliceCalls/deviceCreateTeltonika";
import deviceListTeltonikaCall from "./_sliceCalls/deviceListTeltonika";
import deviceSeatBatteryCall from "./_sliceCalls/deviceSeatBattery";
import deviceUpdateTeltonikaCall from "./_sliceCalls/deviceUpdateTeltonika";
import { updateAssetOnList } from "../asset/assetSlice";
import deviceRemoveTeltonikaCall from "./_sliceCalls/deviceRemoveTeltonika";

export const deviceSlice = createSlice({
  name: "device",
  initialState: {
    teltonikaDevices: {
      totalItems: 0,
      list: [],
      loaded: false,
    },
    deviceTeltonikaDetails: undefined,
  },
  reducers: {
    setTeltonikaDevices: (state, action) => {
      state.teltonikaDevices.list = action.payload.list;
      state.teltonikaDevices.totalItems = action.payload.totalItems;
      state.teltonikaDevices.loaded = true;
    },
    clearTeltonikaDevices: (state) => {
      state.teltonikaDevices.list = [];
      state.teltonikaDevices.totalItems = 0;
    },
    setTeltonikaListDetails: (state, action) => {
      state.deviceTeltonikaDetails = action.payload;
    },
  },
});

export default deviceSlice.reducer;

export const { setTeltonikaDevices, clearTeltonikaDevices, setTeltonikaListDetails } = deviceSlice.actions;

// SELECTS
export const selectIsLoading = (state) => Boolean(state.getIn(["core"])?.loader?.length > 0);
export const selectTeltonikaDevices = (state) => state.getIn(["device"])?.teltonikaDevices;
export const selectDeviceSeatBatteryLoading = (state) =>
  Boolean(state.getIn(["core"])?.loader.indexOf("DEVICE/SEAT_BATTERY") > -1);

// CALLS
export const deviceListTeltonika =
  ({ clear, offset, limit }) =>
  async (dispatch, getState) => {
    deviceListTeltonikaCall(deviceSlice.actions, dispatch, getState(), { clear, offset, limit });
  };

export const deviceCreateTeltonika =
  ({ serialNumber, imei, model, protocol, debugMode }) =>
  async (dispatch, getState) => {
    deviceCreateTeltonikaCall({ deviceListTeltonika }, dispatch, getState(), {
      serialNumber,
      imei,
      model,
      protocol,
      debugMode,
    });
  };

export const deviceUpdateTeltonika =
  ({ serialNumber, imei, model, protocol, debugMode, date, ip, carclubCode }) =>
  async (dispatch, getState) => {
    deviceUpdateTeltonikaCall({ deviceListTeltonika }, dispatch, getState(), {
      serialNumber,
      imei,
      model,
      protocol,
      debugMode,
      date,
      ip,
      carclubCode,
    });
  };

export const deviceSeatBattery =
  ({ carToken }) =>
  async (dispatch, getState) => {
    deviceSeatBatteryCall({ updateAssetOnList }, dispatch, getState(), { carToken });
  };

export const deviceRemoveTeltonika =
  ({ serialNumber, imei }) =>
  async (dispatch, getState) => {
    deviceRemoveTeltonikaCall({ deviceListTeltonika }, dispatch, getState(), {
      serialNumber,
      imei,
    });
  };
