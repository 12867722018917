import { wsCall, requestHelper } from "../../../app/coreSlice";

const customerDashboardStatsCall = async ({ setCustomerMetrics }, dispatch, state, { startDate, endDate }) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "CUSTOMER/DASHBOARD_STATS");
  try {
    const result = await wsCall({
      type: "CUSTOMER",
      subType: "DASHBOARD_STATS",
      locationId: selectedLocation?.id,
      message: {
        startDate,
        endDate,
      },
    });

    const { active, newCustomers, suspended, total } = result;
    dispatch(setCustomerMetrics({ active, newCustomers, suspended, total, list: result.listGraph }));
  } catch (_ex) {
    rh.error("Error getting customer metrics.");
  }
  rh.close();
};

export default customerDashboardStatsCall;
