
export const styles = theme => ({

	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},

	buttons: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},

	button: {
		padding: 10,
		margin: '0 0 0 30px',
		textAlign: 'center',
	},

});