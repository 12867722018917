import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { CustomDataGrid } from "../../../../common/displays";
import DevicesDataGridActions from "./DevicesDataGrid.actions";

import { styles } from "./DevicesDataGrid.styles";
import { listColumns } from "./DevicesDataGrid.columns";
import { useDateTime } from "../../../../common/hooks";

function DevicesDataGrid({
  classes,
  data,
  // filter,
  totalRows,
  // onFilterChange,
  onLoadMore,
  onEdit,
  onDelete,
}) {
  const dt = useDateTime();
  const columns = listColumns(classes, dt);

  // const onFilterHandler = (nFilter) => {
  //   nFilter = {
  //     ...filter,
  //     ...nFilter,
  //     offset: 0,
  //   };
  //   onFilterChange &&
  //     onFilterChange({
  //       filter: nFilter,
  //       reset: true,
  //     });
  // };

  // const onLoadMoreHandler = () => {
  //   if (data.length < totalRows) {
  //     let nFilter = {
  //       ...filter,
  //       offset: data.length,
  //     };
  //     onFilterChange &&
  //       onFilterChange({
  //         filter: nFilter,
  //         reset: false,
  //       });
  //   }
  // };

  return (
    <div className={classes.root}>
      {data && (
        <CustomDataGrid
          className={classes.datagrid}
          columns={columns}
          rows={data}
          // onFilter={onFilterHandler}
          onLoadMore={onLoadMore}
          total={totalRows}
          actionsComponent={<DevicesDataGridActions classes={classes} onEdit={onEdit} onDelete={onDelete} />}
        />
      )}
    </div>
  );
}

export default withStyles(styles)(DevicesDataGrid);
