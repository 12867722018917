import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AssetWorkspace from "./AssetWorkspace";

import withPermission from "../../../hoc/withPermission";
import { selectAlertsTabOpen } from "../../../features/alerts/alertsSlice";
import { useRef } from "react";

import {
  assetList,
  selectAssetsFilter,
  selectSelectedAsset,
  selectUI,
  setShowAssetEdit,
} from "../../../features/asset/assetSlice";
import { selectDefaultLocation } from "../../../features/carclub/carclubSlice";
import DisplayAssetWrapper from "../Car/ShowAsset/DisplayAssetWrapper";

const AssetWorkspaceWrapper = ({ ...props }) => {
  const dispatch = useDispatch();
  const uiState = useSelector(selectUI);
  const selectedAsset = useSelector(selectSelectedAsset);

  const filter = useSelector(selectAssetsFilter);

  const [modal, setModal] = useState();
  const selectedLocation = useSelector(selectDefaultLocation);

  // FIXME Make AssetWorkspace a functional component!!!
  const listAnchor = React.useRef();

  const refreshAssetList = (filter) => {
    dispatch(assetList(filter));
  };

  const alertsTabOpened = useRef();
  alertsTabOpened.current = useSelector(selectAlertsTabOpen);

  return (
    <>
      <AssetWorkspace
        listAnchor={listAnchor}
        {...props}
        modal={modal}
        setModal={setModal}
        selectedLocation={selectedLocation}
        refreshAssetList={refreshAssetList}
        filter={filter}
      />
      {uiState.showAssetEdit && (
        <DisplayAssetWrapper
          selectedAsset={selectedAsset}
          closeHandler={() => dispatch(setShowAssetEdit(false))}
          open
          refreshList={() => refreshAssetList(filter)}
          exitToShow={() => {
            dispatch(setShowAssetEdit(false));
          }}
        />
      )}
    </>
  );
};

const permissions = [{ resource: ["fleet", "assets", "read"] }];

export default withPermission(permissions)(AssetWorkspaceWrapper);
