import React from 'react';
import AppConfigs from './AppConfigs';

const AppConfigsWrapper = (props) => {
	return (
		<AppConfigs
			{...props}
		/>
	);
};

export default AppConfigsWrapper;