
export const styles = theme => ({

	root: {
		height: '100%',
		width: '769px',
		background: '#FFFFFF',
		borderRadius: '2px',
		opacity: 1,
		overflow: 'hidden',
		padding: '10px',
	},

	content: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
	},

	left: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		flexBasis: '40%',
		padding: '8px 4px 8px 8px',
	},

	right: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		flexBasis: '60%',
		padding: '8px 8px 8px 4px',
	},

	errorsRoot: {
		position: 'relative',
	},

	errors: {
		position: 'absolute',
		background: 'red',
		padding: 5,
		borderRadius: 10,
		bottom: 0,
		right: 0,
		left: 0,
	},

	error: {
		color: 'white',
	},

});

export default styles;