const styles = theme => ({
	root: {
		alingItems: 'center',
		boxSizing: 'border-box',
		display: 'flex',
		flexWrap: 'nowrap',
		height: '100%',
		position: 'relative',
		width: '100%',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		overflowY: 'auto',
		scrollbarWidth: 'thin', //FIREFOX
		//CHROME
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},

	title: {
		fontSize: '18px',
		marginTop: '10px',
	},

	searchType: {
		margin: 0,
		minHeight: '46%',
		maxHeight: '46%',
	},

	description: {
		borderBottom: 'none',
	},

	subtitle: {
		fontSize: '16px',
		marginTop: '15px',
		paddingTop: '10px',
		boxShadow: '0 -2px #00000029',
	},

	buttons: {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'space-around',
		flex: 1,
		padding: '10px 0',
	},

	select: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
		marginRight: theme.spacing(2),
		minWidth: '100px',
		width: '100%',
	},

	inputText: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'first baseline',
	},

	textInputLabel: {
		fontSize: '16px',
		marginRight: '10px',
	},
});

export default styles;