import React from "react";
import { Tooltip } from "@material-ui/core";
import { ReactComponent as MapPinIcon } from "../../../assets/icons/map_pin.svg";

function LocationMarker({ location }) {
  return (
    <div
      style={{
        width: 30,
        marginLeft: -15,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        height: 90,
        marginTop: -90,
        flexDirection: "column",
      }}
    >
      <Tooltip title={location?.name}>
        {location?.locationImg ? (
          <img alt="location" src={location?.locationImg} style={{ minWidth: 20, maxWidth: 30 }} />
        ) : (
          <MapPinIcon style={{ minWidth: 20, maxWidth: 30, height: "auto" }} />
        )}
      </Tooltip>
    </div>
  );
}

export default LocationMarker;
