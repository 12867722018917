
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_REQUEST_IS_MALFORMED,
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import mapper from './mappers/servicePointMapper';
import { publishAndAwait } from '../../../app/coreSlice';

const getServicePoint = async servicePointId => {

	if (typeof servicePointId === 'undefined') {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	}

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'CARCLUB',
			subType: 'GET_SERVICE_POINT',
			message:{
				servicePointId,
			},
		},
	});
	
	const { processMessage, error } = request.response;
	
	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;


		throw new NGError({
			message: description,
			code,
			tag,
		});
	   };
	   
	return mapper.fromDTO(processMessage);
};

export default getServicePoint;
