import { Typography } from "@material-ui/core";

export function listColumns(classes, dt, listKey) {
  return [
    {
      field: "date",
      headerName: "Date",
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return dt.toTimeZone(listKey !== "HEARTBEAT" ? v.create_date : v?.heartbeat?.position_date, "dateTimeComplete");
      },
    },
    listKey !== "HEARTBEAT" && {
      field: "event",
      headerName: "Event",
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return (
          <Typography className={classes.text}>
            {v?.comms?.alarmDescription || v?.comms?.last_command_type + " - " + v?.comms?.last_command_sub_type}
          </Typography>
        );
      },
    },
  ];
}
