import React from "react";
// import { useNavigate, useParams } from "react-router-dom";
import withStyles from "@material-ui/styles/withStyles";
import { Tab, Tooltip } from "@material-ui/core";

import { styles } from "./AppButton.styles";

function AppButton({ classes, app, selectApp, selectedApp, useLabel, menuButtonClasses }) {
  // const navigate = useNavigate();
  // const { carclubCode } = useParams();
  // console.log("app: %o", app);

  const tabClickHandler = () => {
    // if (app.tag === "fleet") {
    //   navigate(`/${carclubCode}/fleet`);
    // } else {
    // }
    selectApp(app.tag);
  };

  return (
    <Tooltip title={!useLabel ? app.name : ""} placement="bottom">
      <Tab
        id={app.id}
        icon={<app.icon className={classes.tabIcons} />}
        label={useLabel && app.name}
        selected={app.tag === selectedApp}
        onClick={tabClickHandler}
        className={menuButtonClasses.root}
        wrapped={true}
      />
    </Tooltip>
  );
}

export default withStyles(styles)(AppButton);
