import React from "react";
import PricingPlanListScreen from "../../components/PricingPlanNew/PricingPlanListScreen/PricingPlanListScreenWrapper";
import { withStyles, Typography } from "@material-ui/core";
import styles from "./PricingPlanApp.styles";
import PricingPlanFilter from "../../components/PricingPlanNew/PricingPlanSearch/PricingPlanFilter";
import VerticalMenuBar from "../../components/VerticalMenuBar/VerticalMenuBar";
import menuButtons from "./menuButtons";
import RatesList from "../../components/PricingPlanNew/PricingPlanRatesList/RatesListWrapper";
import Extras from "../../components/PricingPlanNew/ExrasList/ExtrasWrapper";
import PricingPlanDetails from "../../components/PricingPlanNew/PricingPlanDetails/PricingPlanDetails";
import RateDetails from "../../components/PricingPlanNew/RateDetails/RateDetails";
import AdditionalFeeDetails from "../../components/PricingPlanNew/AdditionalFeeDetails/AdditionalFeeDetails";
import PricingPlanEditor from "../../components/PricingPlanNew/PricingPlanEditor/PricingPlanEditorWrapper";
import ExtraEditor from "../../components/PricingPlanNew/ExtraEditor/ExtraEditor";
import RateEditor from "../../components/PricingPlanNew/PricingPlanRatesList/RateEditor/RateEditor";
import withPermisssion from "../../hoc/withPermission";
import { ReactComponent as CreateIcon } from "../../assets/PromotionApp/icons/add.svg";
import { Create as EditIcon } from "@material-ui/icons";

const PricingPlanApp = ({
  asyncGetPPDetails,
  asyncListAccounts,
  checkPlaceholderRequiredValues,
  classes,
  createMode,
  currentScreenSize,
  editMode,
  error,
  getMoreRows,
  getZones,
  groupsListRef,
  listPP,
  modal,
  onChangeHandlerGroupPh,
  placeholderMaxFlatRate,
  placeholderMaxFlatRateSetters,
  placeholderPP,
  placeholderPPSetters,
  placeholderRate,
  placeholderRateSetters,
  pricingPlanArray,
  ratesList,
  removePricingPlan,
  resetPlaceholderPP,
  resetPlaceholderRate,
  searchPPHandler,
  selectedPP,
  selectedRateToApply,
  selectedSubApp,
  setError,
  setModal,
  setRatesList,
  setSelectedPP,
  setSelectedPPOverride,
  setSelectedRateToApply,
  setSelectedSubApp,
  setShowPPCalendar,
  showPPCalendar,
  updatePricingPlan,
  upsertPPHandler,
  upsertRateHandler,
  zones,
  defaultLocation,
  extrasList,
  placeholderExtra,
  setPlaceholderExtra,
  upsertExtraHandler,
  placeholderExtraOnChangeHandler,
  asyncGetExtras,
  onSelectExtraHandler,
  getMoreRowsAdditionalFees,
}) => {
  const menuButtonOnClick = {
    "Pricing Plans": () => {
      setSelectedSubApp("pricingPlans");
      setPlaceholderExtra({});
      placeholderRateSetters.resetPlaceholderRate();
    },
    Rates: () => {
      editMode.setOff();
      createMode.setOff();
      setSelectedPPOverride(null);
      setModal();
      setSelectedSubApp("rates");
      setPlaceholderExtra({});
    },
    "Additional Fees": () => {
      editMode.setOff();
      createMode.setOff();
      setSelectedPPOverride(null);
      setModal();
      setSelectedSubApp("extras");
      placeholderRateSetters.resetPlaceholderRate();
    },
  };

  const menuBtns = menuButtons.map((button) => {
    button["onClick"] = menuButtonOnClick[button.text];
    button.style = selectedSubApp === button.tag ? { fill: "#009BCC" } : { fill: "rgba(0, 0, 0, 0.54)" };
    return button;
  });

  const menuBar = <VerticalMenuBar className={classes.menu} buttons={menuBtns} />;

  const searchPP = <PricingPlanFilter getPPList={searchPPHandler} />;

  const createButtonPP = (
    <CreateButtonPP
      className={classes.createIcon}
      onClick={() => {
        createMode.setOn();
        setSelectedPPOverride(null);
        placeholderPPSetters.resetPlaceholder();
        resetPlaceholderPP();
        setModal();
      }}
    />
  );

  const createButtonRate = (
    <CreateButtonRate
      className={classes.createIcon}
      onClick={() => {
        createMode.setOn();
        resetPlaceholderRate();
        setSelectedPPOverride(null);
        placeholderPPSetters.resetPlaceholder();
        setModal();
      }}
    />
  );

  const createButtonExtra = (
    <CreateButtonExtra
      className={classes.createIcon}
      onClick={() => {
        createMode.setOn();
        setSelectedPPOverride(null);
        placeholderPPSetters.resetPlaceholder();
        setModal();
      }}
    />
  );

  const actionBar = (
    <div className={classes.actionBar}>
      {searchPP}
      {selectedSubApp === "pricingPlans" && createButtonPP}
      {selectedSubApp === "rates" && createButtonRate}
      {selectedSubApp === "extras" && createButtonExtra}
    </div>
  );

  const screenTitle = {
    pricingPlans: "pricing plan",
    rates: "rate",
    extras: "additional fee",
  }[selectedSubApp];

  const defaultSideScreen = !createMode.state &&
    !editMode.state &&
    !modal &&
    !selectedPP &&
    !placeholderExtra?.id &&
    !placeholderRate?.id && (
      <div className={classes.defaultSideScreen}>
        <div className={classes.typoWithIcon}>
          <Typography display="inline">Click</Typography>
          {selectedSubApp === "pricingPlans" && createButtonPP}
          {selectedSubApp === "rates" && createButtonRate}
          {selectedSubApp === "extras" && createButtonExtra}
          <Typography display="inline">to create a new {screenTitle}.</Typography>
        </div>
        <Typography>To view an existing {screenTitle} select it from the list</Typography>
        <div className={classes.typoWithIcon}>
          <Typography display="inline">Click</Typography>
          <EditIcon className={classes.editIcon} />
          <Typography display="inline">to edit an existing {screenTitle}.</Typography>
        </div>
      </div>
    );

  const pricingPlanDetailsScreen = !!selectedPP && !createMode.state && !editMode.state && !modal && (
    <PricingPlanDetails pricingPlan={selectedPP} placeholderMaxFlatRate={placeholderMaxFlatRate} />
  );

  const rateDetailsScreen = !!placeholderRate?.id && !createMode.state && !editMode.state && !modal && (
    <RateDetails placeholderRate={placeholderRate} />
  );

  const additionalFeeDetailsScreen = !!placeholderExtra?.id && !createMode.state && !editMode.state && !modal && (
    <AdditionalFeeDetails placeholderExtra={placeholderExtra} />
  );

  const editScreenPP = (createMode.state || editMode.state) && selectedSubApp === "pricingPlans" && (
    <PricingPlanEditor
      onCancel={() => {
        createMode.setOff();
        editMode.setOff();
        placeholderPPSetters.resetPlaceholder();
      }}
      onSave={() => {
        createMode.setOff();
        editMode.setOff();
      }}
      placeholder={placeholderPP}
      placeholderSetters={placeholderPPSetters}
      createMode={createMode}
      upsertPPHandler={upsertPPHandler}
      editMode={editMode}
      setShowPPCalendar={setShowPPCalendar}
      error={error}
      setError={setError}
      checkPlaceholderRequiredValues={checkPlaceholderRequiredValues}
      defaultLocation={defaultLocation}
      startZoneFees={placeholderPP?.startZoneFees}
      endZoneFees={placeholderPP?.endZoneFees}
    />
  );

  const editScreenRate = (createMode.state || editMode.state) && selectedSubApp === "rates" && (
    <RateEditor
      onCancel={() => {
        createMode.setOff();
        editMode.setOff();
      }}
      onSave={upsertRateHandler}
      placeholder={placeholderRate}
      placeholderSetters={placeholderRateSetters}
      editMode={editMode}
      checkPlaceholderRequiredValues={checkPlaceholderRequiredValues}
      error={error}
      setError={setError}
      defaultLocation={defaultLocation}
    />
  );

  const editScreenExtra = (createMode.state || editMode.state) && selectedSubApp === "extras" && (
    <ExtraEditor
      onCancel={() => {
        createMode.setOff();
        editMode.setOff();
        setPlaceholderExtra({});
      }}
      onSave={upsertExtraHandler}
      placeholder={placeholderExtra}
      setPlaceholderExtra={setPlaceholderExtra}
      editMode={editMode}
      checkPlaceholderRequiredValues={checkPlaceholderRequiredValues}
      error={error}
      setError={setError}
      defaultLocation={defaultLocation}
      onChangeHandler={placeholderExtraOnChangeHandler}
    />
  );

  const sideScreen = (
    <div className={classes.sideScreenArea}>
      {defaultSideScreen}
      {pricingPlanDetailsScreen}
      {editScreenPP}
      {rateDetailsScreen}
      {editScreenRate}
      {additionalFeeDetailsScreen}
      {editScreenExtra}
    </div>
  );

  const pricingPlanList = (
    <PricingPlanListScreen
      asyncGetPPDetails={asyncGetPPDetails}
      createMode={createMode}
      currentScreenSize={currentScreenSize}
      editMode={editMode}
      error={error}
      getMoreRows={getMoreRows}
      getZones={getZones}
      listPP={listPP}
      modal={modal}
      placeholder={placeholderPP}
      placeholderMaxFlatRate={placeholderMaxFlatRate}
      placeholderMaxFlatRateSetters={placeholderMaxFlatRateSetters}
      placeholderSetters={placeholderPPSetters}
      pricingPlanArray={pricingPlanArray}
      ratesList={ratesList}
      resetPlaceholderPP={resetPlaceholderPP}
      removePricingPlan={removePricingPlan}
      selectedPP={selectedPP}
      selectedRateToApply={selectedRateToApply}
      setModal={setModal}
      setRatesList={setRatesList}
      setSelectedPP={setSelectedPP}
      setSelectedRateToApply={setSelectedRateToApply}
      setShowPPCalendar={setShowPPCalendar}
      showPPCalendar={showPPCalendar}
      updatePricingPlan={updatePricingPlan}
      upsertPPHandler={upsertPPHandler}
      zones={zones}
    />
  );

  const pricingPlanScreen = (
    <>
      <div className={classes.pricingPlanArea}>
        {actionBar}
        <div className={classes.listArea}>{pricingPlanList}</div>
      </div>
      {sideScreen}
    </>
  );

  const ratesScreen = (
    <>
      <div className={classes.pricingPlanArea}>
        <div className={classes.listAreaRates}>
          <RatesList
            cantEdit={!editMode.state}
            currentScreenSize={currentScreenSize}
            dontShowSelectedRate={true}
            editMode={editMode}
            createMode={createMode}
            fullSizeList={true}
            listPP={listPP}
            placeholder={placeholderRate}
            placeholderSetters={placeholderRateSetters}
            ratesList={ratesList}
            selectedRateToApply={selectedRateToApply}
            setRatesList={setRatesList}
            setSelectedRateToApply={setSelectedRateToApply}
            setModal={setModal}
            resetPlaceholderRate={resetPlaceholderRate}
          />
        </div>
      </div>
      {sideScreen}
    </>
  );

  const extrasScreen = (
    <>
      <div className={classes.pricingPlanArea}>
        <div className={classes.listArea}>
          <Extras
            currentScreenSize={currentScreenSize}
            placeholder={placeholderExtra}
            editMode={editMode}
            cantEdit={!editMode.state}
            extrasList={extrasList}
            asyncGetExtras={asyncGetExtras}
            createMode={createMode}
            setModal={setModal}
            onSelectExtraHandler={onSelectExtraHandler}
            getMoreRowsAdditionalFees={getMoreRowsAdditionalFees}
            setPlaceholderExtra={setPlaceholderExtra}
          />
        </div>
      </div>
      {sideScreen}
    </>
  );

  const selectedScreenContent = {
    pricingPlans: pricingPlanScreen,
    rates: ratesScreen,
    extras: extrasScreen,
  }[selectedSubApp] || <p>something went wrong</p>;

  const pricingPlanApp = (
    <div className={classes.root}>
      <div className={classes.workspace}>
        {menuBar}
        {selectedScreenContent}
      </div>
    </div>
  );

  return pricingPlanApp;
};

const CreateButtonPP = withPermisssion([{ resource: ["pricingPlansNew", "pricingPlans", "create"] }])((props) => (
  <CreateIcon {...props} />
));

const CreateButtonRate = withPermisssion([{ resource: ["pricingPlansNew", "rates", "create"] }])((props) => (
  <CreateIcon {...props} />
));

const CreateButtonExtra = withPermisssion([{ resource: ["pricingPlansNew", "extras", "create"] }])((props) => (
  <CreateIcon {...props} />
));

export default withStyles(styles)(PricingPlanApp);
