import accountAdd from "./accountAdd";
import accountRemove from "./accountRemove";
import addCustomerToPP from "./addCustomerToPP";
import createPricingPlan from "./createPricingPlan";
import createRate from "./createRate";
import deletePricingPlan from "./deletePricingPlan";
import deleteRate from "./deleteRate";
import getCustomersFromPP from "./getCustomersFromPP";
import getPricingPlan from "./getPricingPlan";
import getZonesFromPP from "./getZonesFromPP";
import listGroupsForPP from "./listGroupsForPP";
import listPricingPlans from "./listPricingPlans";
import listRates from "./listRates";
import removeCustomerFromPP from "./removeCustomerFromPP";
import searchPPAccounts from "./searchPPAccounts";
import listAdditionalFees from "./listAdditionalFees";
import upsertAdditionalFee from "./upsertAdditionalFee";

const pricingPlanService = {
  accountAdd,
  accountRemove,
  addCustomerToPP,
  createPricingPlan,
  createRate,
  deletePricingPlan,
  deleteRate,
  getCustomersFromPP,
  getPricingPlan,
  getZonesFromPP,
  listGroupsForPP,
  listPricingPlans,
  listRates,
  removeCustomerFromPP,
  searchPPAccounts,
  listAdditionalFees,
  upsertAdditionalFee,
};

export default pricingPlanService;
