
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_PROCESS_MESSAGE_IS_MALFORMED,
	WS_REQUEST_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import { publishAndAwait } from '../../../app/coreSlice';

const updateNotificationSMS = async ({
	id,
	type,
	subType,
	message,
}) => {

	if ( !id || !message) {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	}

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'CARCLUB',
			subType: 'EDIT_SMS_MESSAGE_CARCLUB',
			message: {
				id,
				type,
				subType,
				body: message,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}

	return processMessage;
};

export default updateNotificationSMS;