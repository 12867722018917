import React, { useCallback, useState, useMemo, useRef, useEffect } from 'react';
import useDoubleClickHandler from '../../../../hooks/useDoubleClickHandler/useDoubleClickHandler';
import multiFilterButtons from './multiFilterButtons';
import Filters from './Filters';

const FiltersWrapper = ({
	onReset,
	onSearch,
	priorities,
	selectedStatus,
	setFilter,
	types,
	workOrders,
	...props
}) => {
	const isMounted = useRef(false);
	const [anchorEl, setAnchorEl] = useState();
	const [openFilter, setOpenFilter] = useState();

	const [workOrdersBackupArray, setWorkOrdersBackupArray] = useState([]);
	const [blockBackup, setBlockBackup] = useState(false);

	useEffect(() => {
		if(selectedStatus === 'Completed' ) {
			setBlockBackup(true);
			return;
		};
		if(!!blockBackup) {
			setBlockBackup(false);
			return;
		};
		setWorkOrdersBackupArray([...workOrders]);
		//eslint-disable-next-line
	}, [workOrders, selectedStatus]);

	useEffect(() => {
		isMounted.current = true;

		return () => {
			isMounted.current = false;
		};
	}, []);

	const [singleClick, doubleClick] = useDoubleClickHandler(() => onSearch(true), onReset);

	const onChange = useCallback(field => setFilter(
		prev => ({ ...prev, ...field })
	), [setFilter]);

	const openSelectFilter = useCallback((currentTarget, filter) => {
		setAnchorEl(currentTarget);
		setOpenFilter(filter);
	}, []);

	const closeSelect = useCallback(() => {
		setOpenFilter();
		setAnchorEl();
	}, []);

	const typeButton = useMemo(() => multiFilterButtons.typeButtons(types && types.map(type => type.type)), [types]);

	const priorityButton = useMemo(() => multiFilterButtons.priorityButtons(priorities), [priorities]);

	return (
		<Filters
			{...props}
			anchorEl={anchorEl}
			workOrdersBackupArray={workOrdersBackupArray}
			closeSelectFilter={closeSelect}
			doubleClick={doubleClick}
			onChange={onChange}
			onSearch={onSearch}
			openFilter={openFilter}
			openSelectFilter={openSelectFilter}
			priorityButton={priorityButton}
			selectedStatus={selectedStatus}
			singleClick={singleClick}
			typeButton={typeButton}
		/>
	);
};

export default FiltersWrapper;