import React, { useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import {
  Typography,
  ClickAwayListener,
  Popper,
  MenuList,
  MenuItem,
  ListItemIcon,
  Tooltip,
  IconButton,
  Badge,
} from "@material-ui/core";
import AllIcon from "@material-ui/icons/InfoOutlined";

import { styles } from "./TripStateFilter.styles";
import reservationStatusIcon from "../../../../../assets/ReservationApp/icons/ReservationStatusIcon";

function TripStateFilter({ classes, onSelectStatesChange, states }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const onMenuItemClickedHandler = (option) => {
    let exists = states.find((item) => item === option);
    let nOptions;
    if (exists) {
      nOptions = states.filter((item) => item !== option);
    } else {
      nOptions = [...states, option];
    }
    onSelectStatesChange && onSelectStatesChange(nOptions);
  };
  const ClosedIcon = reservationStatusIcon["CLOSED"];
  const CancelledIcon = reservationStatusIcon["CANCELLED"];

  return (
    <div className={classes.root}>
      <Tooltip title="Filter by State">
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
          <Badge color="primary" overlap="rectangular" invisible={!states || states.length === 0} variant="dot">
            <AllIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popper className={classes.menu} open={Boolean(anchorEl)} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <MenuList>
            {/* CLOSED */}
            <MenuItem
              onClick={() => onMenuItemClickedHandler("CLOSED")}
              className={states.find((item) => item === "CLOSED") && classes.selected}
            >
              <ListItemIcon className={classes.icon}>
                <ClosedIcon style={{ color: "#4c4c4e" }} />
              </ListItemIcon>
              <Typography>Closed</Typography>
            </MenuItem>
            {/* CANCELLED */}
            <MenuItem
              onClick={() => onMenuItemClickedHandler("CANCELLED")}
              className={states.find((item) => item === "CANCELLED") && classes.selected}
            >
              <ListItemIcon className={classes.icon}>
                <CancelledIcon style={{ color: "#89898b" }} />
              </ListItemIcon>
              <Typography>Cancelled</Typography>
            </MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}

export default withStyles(styles)(TripStateFilter);
