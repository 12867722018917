import React from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Button } from "@material-ui/core";

import { styles } from "./ParkOnButton.styles";
import { reservationNewReservationParkOnBo, selectActionIsLoading } from "../../../reservationsSlice";

function ParkOnButton({ classes, reservation, actions }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectActionIsLoading);

  const { code, carToken } = reservation;

  const onParkOnHandler = () => {
    dispatch(reservationNewReservationParkOnBo({ reservationCode: code, carToken }, actions));
  };

  return (
    <div className={classes.root}>
      <Button variant="contained" color="primary" onClick={onParkOnHandler} disabled={isLoading}>
        ParkOn
      </Button>
    </div>
  );
}

export default withStyles(styles)(ParkOnButton);
