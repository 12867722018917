import errorFactory from "../../../error/errorFactory";
import { WS_REQUEST_IS_MALFORMED } from "../../../error/websocket/errorTypes";
import log from "../../../utils/logger";

const logger = log("uploadImage#S3Service");

const uploadImage = (img, bucket, key, Bucket, aclPublic) => {
  if (typeof img === "undefined" || typeof bucket === "undefined") {
    throw errorFactory(WS_REQUEST_IS_MALFORMED);
  }

  let ACL = aclPublic ? "public-read" : "private";
  const params = {
    ACL,
    Key: `${key}/${img.name}`,
    ContentType: img.type,
    Body: img,
    Bucket,
  };

  return new Promise((resolve, reject) => {
    bucket.upload(params, (error, data) => {
      if (error || !data) {
        logger.warn(`Error uploading image: ${error}`);
        resolve({ error: error || true });

        return;
      }

      if (data) {
        logger.debug(`Uploaded image: ${data.Location}`);
        resolve({ imageUrl: data.Location, keyname: params.Key });
      }
    });
  });
};

export default uploadImage;
