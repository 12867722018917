import React from 'react';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import { styles } from '../CommonStyles.styles';
import { currencyFormatter } from '../../../../services/formatter';
import classnames from 'classnames';

const Registration = ({
	classes,
	editMode,
	placeholder,
	onChangeHandler,
	inputBuilders,
}) => {

	const {
		getLabelAndInput,
		getLabelAndSelect,
	} = inputBuilders || {};

	const withoutReferralTitle = 
		<Typography className={classes.title}>Registration without referral code</Typography>
	;

	const newRegWithoutReferralFee = getLabelAndInput({
		editMode, fieldLabel: 'New Customer Fee', fieldString: 'withoutReferralFeeConfig',
		onChangeHandler, placeholder, classes, endAdornment: currencyFormatter.symbol(), inputSize: 'smallest', labelSize: 'normal',
	});

	const creditCategoryOptions = ['Sign-Up', 'Trip'];

	const creditCategoryWithoutReferral = getLabelAndSelect({
		editMode,
		options: creditCategoryOptions, fieldLabel: 'Credit Category', fieldString: 'bonusConditionWithoutReferral',
		onChangeHandler, placeholder, classes, extraClass: 'normal', noMargin: true, noEmptyOption: false,
	});

	const creditCategoryWithReferral = getLabelAndSelect({
		editMode,
		options: creditCategoryOptions, fieldLabel: 'Credit Category', fieldString: 'bonusCondition',
		onChangeHandler, placeholder, classes, extraClass: 'normal', noMargin: true, noEmptyOption: false,
	});

	const appliedCreditCondition = getLabelAndSelect({
		editMode,
		options: creditCategoryOptions, fieldLabel: 'Applied Credit Condition', fieldString: 'referrerBonusCondition',
		onChangeHandler, placeholder, classes, extraClass: 'normal', noMargin: true, noEmptyOption: false,
	});

	const newRegWithoutReferralBonus = getLabelAndInput({
		editMode, fieldLabel: 'Credit Amount', fieldString: 'withoutReferralBonusConfig',
		onChangeHandler, placeholder, classes, endAdornment: currencyFormatter.symbol(), inputSize: 'smallest',
		labelSize: 'normal',
	});

	const newRegWithoutReferralBonusExpire = getLabelAndInput({
		editMode, fieldLabel: 'Credit Duration', fieldString: 'withoutReferralBonusExpire',
		onChangeHandler, placeholder, classes, endAdornment: 'days', inputSize: 'smallest',  labelSize: 'normal',
	});

	const withReferralTitle = 
		<Typography className={classes.title}>Registration with referral code</Typography>
	;

	const newRegWithReferralFee = getLabelAndInput({
		editMode, fieldLabel: 'New Customer Fee', fieldString: 'withReferralFeeConfig', onChangeHandler,
		placeholder, classes, endAdornment: currencyFormatter.symbol(), inputSize: 'smallest',  labelSize: 'normal',
	});

	const newRegWithReferralBonus = getLabelAndInput({
		editMode, fieldLabel: 'Credit Amount', fieldString: 'withReferralBonusConfig', onChangeHandler,
		placeholder, classes, endAdornment: currencyFormatter.symbol(), inputSize: 'smallest',  labelSize: 'normal',
	});

	const newRegWithReferralBonusExpire = getLabelAndInput({
		editMode, fieldLabel: 'Credit Duration', fieldString: 'withReferralBonusExpire', onChangeHandler,
		placeholder, classes, endAdornment: 'days', inputSize: 'smallest',  labelSize: 'normal',
	});

	const existingCustomerTitle = 
		<Typography className={classes.title}>Existing customer (Referrer)</Typography>
	;

	const newRegWithReferralBonusForReferrer = getLabelAndInput({
		editMode, fieldLabel: 'Credit Amount', fieldString: 'withReferralBonusConfigForReferrer', onChangeHandler,
		placeholder, classes, endAdornment: currencyFormatter.symbol(), inputSize: 'smallest', labelSize: 'normal',
	});

	const newRegWithReferralBonusExpireForReferrer = getLabelAndInput({
		editMode, fieldLabel: 'Credit Duration', fieldString: 'withReferralBonusExpireForReferrer', onChangeHandler,
		placeholder, classes, endAdornment: 'days', inputSize: 'smallest',  labelSize: 'normal',
	});

	const verticalDivider = (
		<hr style={{
			height: '100%', borderLeft: '1px solid #E0E0E0',
			borderRight: '1px solid #E0E0E0', alignSelf: 'end',
			margin: '0 10px',
		}} />
	);

	return (

		<div className={classnames(classes.root, classes.registrationRoot)}>
			<div className={classes.registrationWithoutReferralBlock}>
				{withoutReferralTitle}
				{newRegWithoutReferralFee}
				{creditCategoryWithoutReferral}
				{newRegWithoutReferralBonus}
				{newRegWithoutReferralBonusExpire}
			</div>
			{verticalDivider}
			<div className={classes.registrationWithReferralBlock}>
				{withReferralTitle}
				{newRegWithReferralFee}
				{creditCategoryWithReferral}
				{newRegWithReferralBonus}
				{newRegWithReferralBonusExpire}
			</div>
			{verticalDivider}
			<div className={classes.registrationWithReferralExistingCustomerBlock}>
				{existingCustomerTitle}
				{newRegWithReferralBonusForReferrer}
				{appliedCreditCondition}
				{newRegWithReferralBonusExpireForReferrer}
			</div>

		</div>
	);
};

export default withStyles(styles)(Registration);