import createModel from '../createModel';

class Contact {
	constructor({
		acceptDate,
		createDate,
		customer,
		description,
		groupId,
		inviteCode,
		name,
		nickname,
		pending,
		phone,
		target,
	} = {}) {
		createModel.call(this, {
			acceptDate,
			createDate,
			customer,
			description,
			groupId,
			inviteCode,
			name,
			nickname,
			pending,
			phone,
			target,
		});
	};
};

export default Contact;