import React from 'react';
import List from '../../UI/List/List';
import ExtraItem from './ExtraItem/ExtraItem';
import {
	Button,
	InputBase,
	InputAdornment,
	Typography,
	withStyles,
} from '@material-ui/core';
import {
	Search as SearchIcon,
	Close as CrossIcon,
} from '@material-ui/icons';
import withPermisssion from '../../../hoc/withPermission';
import { ReactComponent as CreateIcon } from '../../../assets/PromotionApp/icons/add.svg';
import extrasFields from './extrasFields';
import styles from './Extras.styles';
import classnames from 'classnames';

const Extras = ({
	classes,
	className,
	currentScreenSize,
	filter,
	setFilter,
	filterOnChangeHandler,
	onSearchHandler,
	listExtras,
	placeholder,
	editMode,
	cantEdit,
	extrasList,
	onSelectExtraHandler,
	onRemoveHandler,
	createMode,
	setModal,
	getMoreRowsAdditionalFees,
	setPlaceholderExtra,
}) => {


	const title = (
		<Typography className={classes.title}>
			Additional Fees
		</Typography>
	);

	const icon = (
		<CrossIcon
			onClick={(event) => setFilter('')}
			className={classes.crossIcon}
			size="small"
		/>
	);

	const searchBar = (
		<div className={classes.searchBar}>
			<InputBase
				placeholder={'Search by name'}
				value={filter}
				classes={{
					root: classes.inputRoot,
					input: classes.inputInput,
				}}
				onChange={filterOnChangeHandler}
				onKeyDown={event => {
					if (filter.isSearching) {
						return;
					};
					if (event.keyCode === 13) {
						onSearchHandler();
					};
				}}
				endAdornment={
					<>
						{!!filter &&
							<InputAdornment position="end">
								{icon}
							</InputAdornment>}
					</>
				}
			/>
		</div>
	);

	const searchButton = (
		<Button
			aria-label="Search"
			onClick={onSearchHandler}
		>
			<SearchIcon className={classes.searchIcon} />
		</Button>
	);

	const createButton = (
		<CreateButton
			onClick={() => {
				setPlaceholderExtra({});
				createMode.setOn();
				setModal();
			}}
			className={classes.createIcon}
		/>
	);

	const titleAndSearch = (
		<div className={classes.header}>
			<div className={classes.titleAndSearch}>
				{title}
				{searchBar}
				{searchButton}
			</div>
			{createButton}
		</div>
	);

	const extraRows = !!extrasList && extrasList.map(extra => (
		<ExtraItem
			cantEdit={cantEdit}
			editMode={editMode}
			extra={extra}
			key={extra.id}
			listExtras={listExtras}
			onRemove={onRemoveHandler}
			onSelect={onSelectExtraHandler}
			placeholder={placeholder}
			setModal={setModal}
		/>
	));

	const list = (
		<List
			className={classnames(classes.list)}
			headerFields={extrasFields(currentScreenSize)}
			getMoreRows={getMoreRowsAdditionalFees}
		>
			{extraRows}
		</List>
	);

	return (
		<div className={classnames(classes.root, className)}>
			{titleAndSearch}
			{list}
		</div>
	);
};

const CreateButton = withPermisssion(
	[{ resource: ['pricingPlansNew', 'extras', 'create'] }],
)(props => <CreateIcon {...props} />);

export default withStyles(styles)(Extras);