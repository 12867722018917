import { wsCall, requestHelper } from "../../../app/coreSlice";

const paymentGetPaymentInfoCall = async ({ setCustomerPaymentInfo }, dispatch, state, { customerCode }) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "PAYMENT/GET_PAYMENT_INFO");
  let result = {};
  dispatch(setCustomerPaymentInfo({}));
  try {
    result = await wsCall({
      type: "PAYMENT",
      subType: "GET_PAYMENT_INFO",
      locationId: selectedLocation?.id,
      message: {
        customerCode,
      },
    });
    if (result) {
      if (!result?.error) {
        dispatch(
          setCustomerPaymentInfo({
            ...result,
            paymentMethod: result.paymentMethod && JSON.parse(result.paymentMethod),
          })
        );
      } else {
        rh.error(result?.description || "Error getting customer payment info.");
      }
    }
  } catch (ex) {
    console.error(ex);
    rh.error("Error getting customer payment info.");
  }
  rh.close();
  return result?.error ? {} : result;
};

export default paymentGetPaymentInfoCall;
