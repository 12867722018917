const barWidth = 260; // tODO put this in theme

export const styles = () => ({

	root: {
		backgroundColor: 'white',
		width: barWidth,
		borderRight: '1px solid',
	},

	collapsed: {
		minWidth: 60,
		maxWidth: 60,
	},
});