import React from "react";
import ListItem from "../../UI/List/ListItem/ListItem";
import withPermisssion from "../../../hoc/withPermission";
import { Tooltip, withStyles } from "@material-ui/core";
import { AddCircle as AddIcon, RemoveCircle as RemoveIcon } from "@material-ui/icons";
import { modals } from "../../PricingPlan/PricingPlanList/PricingPlanItem/PricingPlanItem";
import styles from "./DetailsItem.styles";
import classnames from "classnames";

const ADD_CUSTOMER = "ADD_CUSTOMER";
const REMOVE_CUSTOMER = "REMOVE_CUSTOMER";
const ADD_ZONE = "ADD_ZONE";
const REMOVE_ZONE = "REMOVE_ZONE";

const DetailsItem = ({
  classes,
  detail,
  selected,
  fields,
  onSelect,
  isInPP,
  addOrRemoveHandler,
  modal,
  newPricingPlanApp,
}) => {
  const addSubType = modal === modals.CUSTOMER_EDIT_MODAL ? ADD_CUSTOMER : ADD_ZONE;

  const removeSubType = modal === modals.CUSTOMER_EDIT_MODAL ? REMOVE_CUSTOMER : REMOVE_ZONE;

  const actionTitle = modal === modals.CUSTOMER_EDIT_MODAL ? "Customer" : "Zone";

  const resource = !!newPricingPlanApp ? ["pricingPlansNew", "edit"] : ["pricingPlans", "edit"];

  const RemoveButton = withPermisssion([
    {
      resource,
      propFailure: { unauthorized: true },
    },
  ])(({ classes, onClick, title, unauthorized }) => (
    <Tooltip title={title} placement="left">
      <RemoveIcon
        onClick={() => !unauthorized && onClick()}
        className={classnames(classes.editIcon, unauthorized && classes.disabled)}
      />
    </Tooltip>
  ));

  const AddButton = withPermisssion([{ resource }])(({ classes, onClick, title }) => (
    <Tooltip title={title} placement="left">
      <AddIcon onClick={onClick} className={classes.editIcon} />
    </Tooltip>
  ));

  const addCustomerIcon = !isInPP && (
    <AddButton title={`Add ${actionTitle}`} onClick={() => addOrRemoveHandler(detail, addSubType)} classes={classes} />
  );

  const removeCustomerIcon = isInPP && (
    <RemoveButton
      title={`Remove ${actionTitle}`}
      onClick={() => addOrRemoveHandler(detail, removeSubType)}
      classes={classes}
    />
  );

  const editIcons = (
    <div className={classes.icons}>
      {addCustomerIcon}
      {removeCustomerIcon}
    </div>
  );

  const item = (
    <ListItem
      selected={selected}
      fields={fields}
      element={{
        ...detail,
        editIcons,
      }}
    />
  );

  return item;
};

export default withStyles(styles)(DetailsItem);
