import store from "../../../store"; // Use store.dispatch() to dispatch imported actions

import * as assetActions from "../../../store/actions/asset/action";
import * as chatActions from "../../../store/actions/chat/action";

import { alertList, resetFiltersPaging, appendNewAlert } from "../../../features/alerts/alertsSlice";

import { updateAssetOnList } from "../../../features/asset/assetSlice";

const defaultHandlers = {
  ALERT: {
    DETAILS: (message) => {
      console.log("ALERT/DETAILS");
      // store.dispatch(alertActions.alertSetDetails(message));
    },
    CREATE: (message) => {
      store.dispatch(appendNewAlert({ ...message, resolved: false }));
    },
    ASSIGN: (message) => {
      console.log("ALERT/ASSIGN message: %o", message);
      // store.dispatch(alertActions.alertSetElements(message));
      store.dispatch(resetFiltersPaging());
      store.dispatch(alertList({ clear: true }));
    },
  },

  DOORS: {
    CLOSE: (message) => {
      store.dispatch(assetActions.assetUpdate(message));
      store.dispatch(updateAssetOnList(message));
    },
    OPEN: (message) => {
      store.dispatch(assetActions.assetUpdate(message));
      store.dispatch(updateAssetOnList(message));
    },
  },

  IMO: {
    OFF: (message) => {
      store.dispatch(assetActions.assetUpdate(message));
      store.dispatch(updateAssetOnList(message));
    },
    ON: (message) => {
      store.dispatch(assetActions.assetUpdate(message));
      store.dispatch(updateAssetOnList(message));
    },
  },
  SEND: {
    MESSAGE: (message) =>
      store.dispatch(chatActions.chatNewMessage({ ...message, code: message.groupId || message.customerCode })),
  },
  MESSAGE: {
    ALERT: ({ phone, customerCode, target, nickname }) =>
      store.dispatch(chatActions.newPendingContact({ phone, customer: customerCode, target, nickname })),
    INVITE_GROUP_ALERT: ({ groupName, inviteCode, groupId }) =>
      store.dispatch(chatActions.newPendingContact({ groupId, inviteCode, name: groupName, target: groupId })),
  },
};

export default defaultHandlers;
