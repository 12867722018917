import AccountInvoice from '../../../../model/Account/AccountInvoice';

const STATUS = {
	OPEN: 'OPEN',
	PAID: 'PAID',
};

const {
	OPEN,
	PAID,
} = STATUS;

export const fromDTO = ({
	code,
	invoiceId,
	itemNumber,
	amount,
	tripsNumber,
	issueDate,
	dueDate,
	isPaid,
	billingItems,
}) => new AccountInvoice({
	code,
	id: invoiceId,
	itemNumber,
	amount,
	tripsNumber,
	issueDate,
	dueDate,
	status: isPaid === true ? PAID : OPEN,
	billingItems,
});

export const toDTO = ({
	code,
	itemNumber,
	amount,
	tripsNumber,
	issueDate,
	dueDate,
	status,
	billingItems,
}) => ({
	code,
	itemNumber,
	amount,
	tripsNumber,
	issueDate,
	dueDate,
	status,
	billingItems,
});

const mapper = {
	fromDTO,
	toDTO,
};

export default mapper;