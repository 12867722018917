export const fromDTO = ({
	phone,
	name,
	customerCode,
	email,
}) => ({
	code: customerCode,
	name,
	email,
	phoneNumber: phone,
});

export const toDTO = ({
	phoneNumber,
	name,
	code,
	email,
}) => ({
	customerCode: code,
	name,
	email,
	phone: phoneNumber,
});

const membersMapper = { fromDTO, toDTO };

export default membersMapper;