export const styles = theme => ({
  root: {
  },
  input: {
    margin: 0,
    "& .MuiInput-root": {
      marginTop: 12,
    },
    "& .MuiInputLabel-shrink": {
      top: "0 !important",
    },
    "& .MuiFormLabel-root": {
      top: -10,
    },
    "& .MuiInput-input": {
      fontSize: 12,
      padding: "1px 0",
    },
  },
});