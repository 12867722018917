export const styles = (theme) => ({
  root: {
  },
  searchBox: {
    height: 36,
    margin: 8,
    boxShadow: theme?.shadows[5],
    maxWidth: 175,
    backgroundColor: "#ffffffb3",
    borderRadius: 5,
  },
});
