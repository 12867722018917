const styles = () => ({
  root: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: "15px",
    width: "400px",
  },
  form: {
    padding: "10px",
  },
  buttons: {
    alignItems: "flex-end",
    display: "flex",
    flexGrow: "1",
    justifyContent: "space-around",
    padding: "5% 0",
  },
  title: {
    fontSize: "20px",
    marginBottom: "30px",
  },
});

export default styles;
