import React, { useCallback } from 'react';
import useDoubleClickHandler from '../../../../hooks/useDoubleClickHandler/useDoubleClickHandler';
import ConsumptionFilter from './ConsumptionFilter';

const ConsumptionFilterWrapper = ({
	onSearch,
	placeholder,
	setPlaceholder,
	...props
}) => {
	const reset = useCallback(() => setPlaceholder(), [setPlaceholder]);

	const onChange = useCallback(
		field => setPlaceholder(prev => ({ ...prev, ...field })), [setPlaceholder]);

	const search = () => onSearch({
		...placeholder,
		paid: placeholder?.paid && placeholder.paid === 'true',
		page: 1,
	});

	const [onSingleClick, onReset] = useDoubleClickHandler(search, reset);

	return (
		<ConsumptionFilter
			{...props}
			onChange={onChange}
			onReset={onReset}
			onSearch={search}
			onSingleClick={onSingleClick}
			placeholder={placeholder}
		/>
	);
};

export default ConsumptionFilterWrapper;