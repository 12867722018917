import NGError from "../../../error/ngError";
import errorFactory from "../../../error/errorFactory";
import { WS_PROCESS_MESSAGE_IS_MALFORMED } from "../../../error/websocket/errorTypes";
import { fromDTO as mapper } from "./mappers/accountMapper";
import orderType from "../../../model/Account/orderType";
import { publishAndAwait } from "../../../app/coreSlice";

const type = orderType.OWNERCODE;

const listAccounts = async (filters = { type }) => {
  let accountName;
  let accountCode;
  let email;
  let phone;

  if (!!filters.string && !!filters.string.trim()) {
    email = accountCode = accountName = phone = filters.string;
  }

  delete filters.string;

  const request = await publishAndAwait({
    destination: "/app/hello",
    body: {
      type: "ACCOUNTS",
      subType: "ACCOUNT_LIST",
      message: {
        ...filters,
        email,
        accountCode,
        accountName,
        phone,
      },
    },
  });

  const { processMessage, error } = request.response;

  console.log("processMessage: %o", processMessage);

  if (!processMessage) {
    throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
  }

  if (error) {
    const { description, code, tag } = processMessage;

    throw new NGError({
      message: description,
      code,
      tag,
    });
  }
  return { ...processMessage, accounts: (processMessage.accounts || []).map((account) => mapper(account)) };
};

export default listAccounts;
