import React, { useState, useCallback } from 'react';
import NicknamesModal from './NicknamesModal';

const NicknamesWrapper = ({
	onSave,
	setModal,
	...props
}) => {
	const [nicknames, setNicknames] = useState({});
	const [error, setError] = useState(false);

	const onChange = useCallback(({customerCode, nickname}) => {
		setNicknames(state => ({...state, [customerCode]: nickname}));
	}, []);
	
	const onNicknameSave = useCallback(async customerCode => {
		setError(false);

		const error = await onSave({customerCode, nickname: nicknames?.[customerCode]});

		setError(error);

		if(!error){
			setNicknames(state => {
				const newState = {...state};
				delete newState[customerCode];

				return newState;
			});
		};

		return !!error;
	}, [nicknames, onSave]);

	const onClose = useCallback(async () => {
		setError(false);
		for (let nickname in nicknames) {
			const error = await onNicknameSave(nickname);

			if(error){ return; };
		};

		setModal(false);
	}, [setModal, onNicknameSave, nicknames]);

	return (
		<NicknamesModal
			{...props}
			error={error}
			nicknames={nicknames}
			onChange={onChange}
			onClose={onClose}
			onSave={onNicknameSave}
			setModal={setModal}
		/>
	);
};

export default NicknamesWrapper;