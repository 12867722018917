import createModel from '../createModel';

/* 
    Backend Definition:
    Extra = {
        id: UUID,
        name: String,
        description: String,
        longDescription: String,
        createDate: DateTime,
        value: UUID,
        enabled: boolean,
    };
*/

class Extra {

	constructor({
		id,
		name,
		description,
		longDescription,
		createDate,
		value,
		enabled,
	} = {}) {
		createModel.call(this, {
			id,
			name,
			description,
			longDescription,
			createDate,
			value,
			enabled,
		});
	};

};

export default Extra;