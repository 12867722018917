import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useSwitch from "../../../hooks/useSwitch";
import ZoneWorkspace from "./ZoneWorkspace";
import carclubService from "../../../services/carclub";
import log from "../../../utils/logger";
import { selectDefaultLocation } from "../../../features/carclub/carclubSlice";
import { getZones, selectZones } from "../../../features/asset/assetSlice";

const logger = log("ZonesWorkspaceWrapper");

const ZoneWorkspaceWrapper = ({ polygons, setShowList, showList, ...props }) => {
  const dispatch = useDispatch();
  const selectedLocation = useSelector(selectDefaultLocation);
  const zones = useSelector(selectZones);

  const { clearMapOfPolygons, zonePainter, hideZone } = props;

  const [selectedZoneCode, setSelectedZoneCode] = useState();
  const [selectedZone, setSelectedZone] = useState();
  const [isImporting, setIsImporting] = useState();
  const [areAllZonesPainted, setAreAllZonesPainted] = useState(false);

  const hasModal = useSwitch(false);
  const isCreating = useSwitch(false);

  const [locations, setLocations] = useState([]);

  //onMount
  useEffect(() => {
    dispatch(getZones());
    // eslint-disable-next-line
  }, [selectedLocation]);

  const getLocations = useCallback(async () => {
    try {
      const locationsPromise = carclubService.getUserLocations();
      const locationsResult = await locationsPromise;
      Array.isArray(locationsResult) && setLocations(locationsResult);
    } catch (e) {
      logger.warn("Unable to get locations");
    }
  }, []);

  useEffect(() => {
    if (!showList) {
      return;
    }

    getLocations();
  }, [getLocations, showList]);

  useEffect(() => {
    setSelectedZone(zones.find((zone) => zone.code === selectedZoneCode));
  }, [selectedZoneCode, zones]);

  useEffect(() => {
    !isImporting && selectedZone && zonePainter(selectedZone);
    //eslint-disable-next-line
  }, [selectedZone]);

  useEffect(() => {
    if (isCreating.state && polygons["NEW"] && !isImporting) {
      hasModal.setOn();
    }
  }, [polygons, hasModal, isCreating.state, isImporting]);

  useEffect(() => {
    if (!isCreating.state && polygons["NEW"]) {
      hideZone("NEW");
    }
  }, [isCreating.state, polygons, hideZone]);

  const paintAllZones = () => {
    try {
      if (!areAllZonesPainted) {
        for (let i = 0; i < zones?.length; i++) {
          const polygonOnClick = () => {
            setSelectedZoneCode(zones[i]?.code);
            const listItem = document.getElementById("zone_item_" + zones[i]?.code);
            !!listItem && listItem.scrollIntoView();
          };
          if (zones?.[i]?.type === "UNWANTED" || zones?.[i]?.type === "NORMAL")
            zonePainter(zones[i], false, polygonOnClick, true);
        }
        setAreAllZonesPainted(true);
      } else {
        setSelectedZoneCode();
        clearMapOfPolygons();
        setAreAllZonesPainted(false);
      }
    } catch (error) {
      logger.warn("Error while painting all zones.", error);
    }
  };

  return (
    <ZoneWorkspace
      areAllZonesPainted={areAllZonesPainted}
      defaultLocationFromUser={selectedLocation}
      hasModal={hasModal}
      isCreating={isCreating}
      isImporting={isImporting}
      locations={locations}
      paintAllZones={paintAllZones}
      polygon={polygons[selectedZoneCode || "NEW"]}
      selectedZone={selectedZone}
      selectedZoneCode={selectedZoneCode}
      setSelectedZoneCode={setSelectedZoneCode}
      setIsImporting={setIsImporting}
      setSelectedZone={setSelectedZone}
      showZones={{
        state: showList,
        setOn: () => setShowList(true),
        setOff: () => setShowList(false),
        toggle: () => setShowList(!showList),
      }}
      zones={zones}
      setZones={() => dispatch(getZones())}
      {...props}
    />
  );
};

export default ZoneWorkspaceWrapper;
