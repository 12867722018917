import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Divider, Grid } from "@material-ui/core";

import { styles } from "./PaymentTab.styles";
import {
  reservationPaymentDetails,
  selectSelectedTrip,
  selectSelectedTripPaymentDetails,
} from "../../reservationsSlice";
import PaymentList from "./PaymentList/PaymentList";
import PaymentActions from "./PaymentActions/PaymentActions";
import LabelValueInfo from "../../../../common/displays/LabelValueInfo/LabelValueInfo";
import InvoiceDetails from "./InvoiceDetails/InvoiceDetails";
import InvoiceActions from "./InvoiceActions/InvoiceActions";

function PaymentTab({ classes }) {
  const dispatch = useDispatch();
  const reservation = useSelector(selectSelectedTrip);
  const paymentDetails = useSelector(selectSelectedTripPaymentDetails);

  const { billingDetails } = paymentDetails;
  const { billingItem, payments } = billingDetails || {};
  const { paymentMethod } = billingItem || {};

  let sortedPayments = [];
  if (payments && payments.length > 0) {
    sortedPayments = [...(payments || [])];
    sortedPayments.sort((a, b) => new Date(a.createDate) - new Date(b.createDate));
    sortedPayments = sortedPayments.filter(
      (payment, index) => payment.type !== "AUTHORIZATION" || index === payments.length - 1
    ); // only shows authorization if is last payment
  }
  const [lastPayment] = (sortedPayments || []).slice(-1);

  // console.log("######  ------------- paymentDetails: %o | reservation: %o", paymentDetails, reservation);

  useEffect(() => {
    if (reservation?.code) {
      dispatch(reservationPaymentDetails({ reservationCode: reservation?.code }));
    }
    // eslint-disable-next-line
  }, []);

  return paymentDetails && Object.keys(paymentDetails).length > 0 ? (
    <div className={classes.root}>
      <Grid container direction="column" spacing={1}>
        {paymentMethod && (
          <Grid item>
            <LabelValueInfo
              label="Source"
              value={
                {
                  CREDIT_CARD: "Credit Card",
                  DEBIT_CARD: "Debit Card",
                  TOP_UP: "Top Up",
                }[paymentMethod]
              }
            />
          </Grid>
        )}
        <Grid item>
          <PaymentList payments={sortedPayments} lastPayment={lastPayment} />
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <InvoiceDetails sortedPayments={sortedPayments} />
        </Grid>
        <Grid item>
          <InvoiceActions />
        </Grid>
        <Grid item>
          <PaymentActions />
        </Grid>
      </Grid>
      <br/>
      <br/>
    </div>
  ) : null;
}

export default withStyles(styles)(PaymentTab);
