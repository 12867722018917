import React from "react";
import { useDispatch } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";
import Button from "../../../components/UI/Buttons/CommonButtons";

import { styles } from "./PromotionForm.styles";
import { formSchema, formFields } from "./PromotionForm.schema";
import { FormFields, Form } from "../../../common/forms";

import { promotionCreate } from "../promotionsSlice";
import { useDateTime } from "../../../common/hooks";

function PromotionForm({ classes, onCancel, onSave }) {
  const dispatch = useDispatch();
  const { now, toUtc } = useDateTime();

  const onSubmit = (values) => {
    // console.log("values: %o", values);
    let startDate = toUtc(values?.startDate).set({ hour: 0, minute: 0, seconds: 0 }).format("YYYY-MM-DD HH:mm:ss");
    let endDate = toUtc(values?.endDate).set({ hour: 23, minute: 59, seconds: 59 }).format("YYYY-MM-DD HH:mm:ss");
    dispatch(promotionCreate({ ...values, startDate, endDate }));
    onSave && onSave();
  };

  return (
    <div className={classes.root}>
      <Form
        schema={formSchema()}
        keepDirtyOnReinitialize
        initialValues={{ startDate: now(), endDate: now(), type: "AMOUNT" }}
        onSubmit={onSubmit}
        render={({ submitting, form, values }) => {
          return (
            <>
              <Typography variant="h5" align="left" component="h1">
                Add new promotion
              </Typography>
              <br />
              <div className={classes.form}>
                <FormFields fields={formFields(values)} />
              </div>
              <Grid container justifyContent="space-between" className={classes.formButtons}>
                <Button buttonStyleName="void" label="Cancel" onClick={onCancel} />
                <Button buttonStyleName="filled" type="submit" disabled={submitting} label="Create" />
              </Grid>
            </>
          );
        }}
      />
    </div>
  );
}

export default withStyles(styles)(PromotionForm);
