import React from 'react';
import AppConfigurations from './AppConfigurations';

const AppConfigurationsWrapper = props => {

	return (
		<AppConfigurations
			{...props}
		/>
	);
};



export default AppConfigurationsWrapper;