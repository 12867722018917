export const styles = theme => ({

	root: {
		padding: '8px 16px',
		height: '100%',
		boxSizing: 'border-box',
	},

	textFieldTitle: {
		borderBottom: 'none',
		color: 'rgb(0, 155, 204)',
		fontSize: '18px',
	},

	detailsBlock: {
	},

	checkIcon: {
		color: '#51B383',
	},

});