const styles = theme => ({
	root: {
		display: 'flex',
	},

	icon: {
		color: '#009AC9',
	},

	disabledIcon: {
		color: '#C5C5C5',
		cursor: 'default',
	},

	redIcon: {
		color: '#510000',
	},

	greyIcon: {
		color: '#8888',
	},
});

export default styles;