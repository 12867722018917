import React from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";

import { styles } from "./AssetList.styles";
import Modal from "../../../common/displays/Modal/Modal";
import AssetReportDamagesMain from "../../damagereport/AssetReportDamagesMain/AssetReportDamagesMain";
import { selectUI, setShowDamageReport, setShowAssetDetails, setShowMoveAssetModal } from "../assetSlice";
import AssetDetails from "../AssetDetails/AssetDetails";
import MoveAssetForm from "../MoveAssetForm/MoveAssetForm";

function AssetList({ classes, children }) {
  const dispatch = useDispatch();
  const uiState = useSelector(selectUI);

  return (
    <div className={classes.root}>
      {children}
      <Modal
        sizeClass="medium"
        open={uiState.showDamageReport}
        setOpen={(value) => dispatch(setShowDamageReport(value))}
        style={{ height: "90vh", top: "5vh" }}
      >
        <AssetReportDamagesMain modalOpen={uiState.showDamageReport} />
      </Modal>
      <Modal
        sizeClass="medium"
        open={uiState.showAssetDetails}
        setOpen={(value) => dispatch(setShowAssetDetails(value))}
      >
        <AssetDetails />
      </Modal>
      <Modal
        style={{ height: 310, width: 360 }}
        sizeClass="small"
        open={uiState.showMoveAssetModal}
        setOpen={(value) => dispatch(setShowMoveAssetModal(value))}
      >
        <MoveAssetForm />
      </Modal>
    </div>
  );
}

export default withStyles(styles)(AssetList);
