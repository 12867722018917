import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";
import { assetAssetReservationCounts, assetList } from "../../asset/assetSlice";

const reservationNewReservationParkoffBoCall = async (
  { setSelectedTrip },
  dispatch,
  state,
  { reservationCode, carToken },
  oldActions
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  const selectedTrip = state?.getIn(["reservations"])?.selectedTrip;
  let rh = requestHelper(dispatch, "RESERVATION/NEW_RESERVATION_PARKOFF_BO");
  try {
    const result = await wsCall({
      type: "RESERVATION",
      subType: "NEW_RESERVATION_PARKOFF_BO",
      locationId: selectedLocation?.id,
      message: {
        reservationCode,
        carToken,
      },
    });

    if (!result.error) {
      dispatch(setSuccess("Reservation unparked successfully!"));
      let nReservation = { ...selectedTrip, ...(result || {}) };
      dispatch(setSelectedTrip(nReservation));
      oldActions.update(result);
      setTimeout(() => {
        // this should be update using notifications
        dispatch(assetList({}));
        dispatch(assetAssetReservationCounts());
      }, 300);
      // refresh reservations
    } else {
      rh.error(result?.description || "Error unparking reservation.");
    }
  } catch (_ex) {
    rh.error("Error unparking reservation.");
  }
  rh.close();
};

export default reservationNewReservationParkoffBoCall;
