import React from "react";
import ZonesList from "../ZonesList/ZonesList";
import importZones from "../../../services/ImportXML/CoordinatesXML";
import CreateZoneWrapper from "../CreateZone/CreateZoneWrapper";

const stopDrawingSetup = (setIsDrawing, setModal, setIsCreating) => () => {
  setIsDrawing();
  setIsCreating && setIsCreating();
  setModal();
};

const ZonesWorkspace = ({
  classes,
  areAllZonesPainted,
  hasModal,
  hideZone,
  hideZones,
  isCreating,
  isDrawing,
  isImporting,
  locations,
  paintAllZones,
  polygon,
  selectedZone,
  setIsImporting,
  setSelectedZone,
  setSelectedZoneCode,
  setZones,
  showZones,
  zoneCreator,
  zonePainter,
  zoneRedrawer,
  zones,
  defaultLocationFromUser,
}) => {
  const selectZone = (zone, editable = false) => {
    if (!areAllZonesPainted) {
      hideZones();
    }

    const { code } = zone || {};

    setSelectedZoneCode(code);

    if (editable) {
      zoneRedrawer(zone, true);
      return;
    }
    zonePainter(zone, true);
  };

  const cancelDrawing = () => {
    setIsImporting();
    stopDrawing();
  };

  const setXML = (xml) => {
    !areAllZonesPainted && hideZones();

    setIsImporting(true);

    setSelectedZoneCode();

    isCreating.setOn();

    showZones.setOn();

    isDrawing.setOn();

    importZones(
      xml,
      (zone) => {
        setSelectedZone(zone);
        zoneRedrawer(zone, true);
      },
      cancelDrawing
    );
  };

  const createZone = () => {
    if (selectedZone) {
      setSelectedZoneCode();
    }

    !areAllZonesPainted && hideZones();

    isCreating.setOn();
    isDrawing.setOn();

    zoneCreator();
  };

  const stopDrawing = stopDrawingSetup(isDrawing.setOff, hasModal.setOff, isCreating.setOff);

  const editZone = (zone) => {
    setSelectedZoneCode(zone.code);
    isCreating.setOn();
    hasModal.setOn();
  };

  const redrawZone = (zone) => {
    //showZones.setOn();
    selectZone(zone, true);
    isDrawing.setOn();
  };

  const zonesList = showZones.state && (
    <ZonesList
      areAllZonesPainted={areAllZonesPainted}
      cancelDrawing={cancelDrawing}
      confirmDrawing={() => {
        setIsImporting();
      }}
      createZone={createZone}
      editZone={editZone}
      isCreating={isCreating}
      isDrawing={isDrawing}
      isImporting={isImporting}
      paintAllZones={paintAllZones}
      redrawZone={redrawZone}
      selectedZone={selectedZone}
			polygon={polygon}
      setSelectedZoneCode={setSelectedZoneCode}
      selectZone={(zone) => {
        selectZone(zone);
        setIsImporting();
        isCreating.setOff();
      }}
      setXML={setXML}
      setZones={(data, code) => {
        hideZone(code);
        setZones(data);
      }}
      showZones={showZones}
			stopDrawing={stopDrawing}
      zones={zones}
    />
  );

  const createZoneModal = hasModal.state && (
    <CreateZoneWrapper
      zone={selectedZone}
      polygon={polygon}
      locations={locations}
      setZones={setZones}
      stopDrawing={() => {
        stopDrawing();
        hideZone("NEW");
      }}
      defaultLocationFromUser={defaultLocationFromUser}
      setXML={setXML}
    />
  );

  return (
    <React.Fragment>
      {createZoneModal}
      {zonesList}
    </React.Fragment>
  );
};

export default ZonesWorkspace;
