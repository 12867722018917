const styles = (theme) => ({

	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		overflowY: 'hidden',
	},

	workspace: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'no-wrap',
		position: 'relative',
	},

	menu: {
	},

	searchReservation: {
		width: '100%',
		display: 'flex',
	},

	searchBar: {
		width: '100%',
		display: 'inline-flex',
		paddingBottom: theme.spacing(1),
	},

	searchDates: {
		display: 'inline-flex',
	},

	reservationArea: {
		flexBasis: '75%',
		height: '100%',
		flexDirection: 'column',
	},

	listArea: {
		width: 'inherit',
		height: 'inherit',
		flexDirection: 'row',
	},

	tripDetailsArea: {
		flexBasis: '25%',
		height: 'inherit',
		maxWidth: 'inherit',
		backgroundColor: '#F3F3F3',
	},

	displayOptionsRoot: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '0px 10px',
	},

	displayOptionsFlexEnd: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
		padding: '0px 10px',
	},

	tripFilters: {
		diplsay: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
	},

	filterText: {
		textAlign: 'center',
		verticalAlign: 'middle',
		cursor: 'pointer',
		borderBottom: '2px solid rgba(1, 151, 198, 0.30)',
		paddingRight: 6,
		marginRight: 6,
	},


	selectedFilterText: {
		borderBottom: '2px solid rgba(1, 151, 198, 0.75)',
		fontWeight: 'bold',
	},

	viewFilters: {

	},

	displayModeIcons: {
		fill: 'rgba(0, 0, 0, 0.54)',
		cursor: 'pointer',
	},

	tripTypeIcons: {
		fill: 'rgba(0, 0, 0, 0.54)',
	},

	selectedIcon: {
		fill: '#009BCC',
	},

});

export default styles;
