const styles = (theme) => ({

	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		flexWrap: 'nowrap',
	},

	workspace: {
		width: '100%',
		height: 'calc(100vh - 60px)',
		display: 'flex',
		flexDirection: 'row',
		alingItems: 'center',
		flexWrap: 'nowrap',
		position: 'relative',
	},

	actionBar: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	title: {
		fontSize: '16px',
		marginRight: '10px',
	},

	list: {
		width: '100%',
		height: '100%',
		paddingLeft: 21,
		paddingRight: 21,
		display: 'flex',
		flexDirection: 'column',
	},

	details: {
		maxWidth: '284px',
		minWidth: '284px',
		paddingRight: 21,
		overflowY: 'auto',
	},

	leftSide: {
		paddingLeft: '16px',
		width: 388,
		heigth: 42,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		flexBasis: '50%',
		alignItems: 'first baseline',

	},

	rightSide: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		flexBasis: '50%',
	},

	upsertModal: {
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		margin: 'auto',
		backgroundColor: 'white',
		borderRadius: '10px',
		height: 500,
		outline: 0,
		padding: '10px 10px 20px 10px',
		position: 'absolute',
		width: 750,
	},

	drawer: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		maxWidth: '625px',
		minWidth: '625px',
		padding: '15px',
	},
	
	modal: {
		backgroundColor: 'white',
		borderRadius: '10px',
		boxShadow: theme.shadows[5],
		boxSizing: 'border-box',
		height: '90%',
		left: '10%',
		outline: 0,
		padding: '20px',
		position: 'absolute',
		top: '5%',
		width: '80%',
	},
	
});

export default styles;