import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
// import { DatePicker } from '@material-ui/pickers/DatePicker';
import SearchIcon from '@material-ui/icons/Search';
import styles from './ConsumptionFilter.styles';
import classnames from 'classnames';
// import moment from 'moment';

const ConsumptionFilter = ({
	classes,
	className,
	placeholder,
	onChange,
	onSearch,
	onSingleClick,
	onReset,
}) => {

	// const datePicker = datePickerCreate(classes, placeholder, onChange);
	// const startDate = datePicker({ label: 'Start Date', field: 'startDate' });
	// const endDate = datePicker({ label: 'End Date', field: 'endDate' });

	// const dateFilters = (
	// 	<>
	// 		{startDate}
	// 		{endDate}
	// 	</>
	// );

	const paidSelect = (
		<FormControl className={classes.formControl}>
			{!placeholder?.paid &&
				<InputLabel htmlFor="select">
					Select
			  </InputLabel>}
			<Select
				className={classes.selectEmpty}
				displayEmpty
				inputProps={{
					id: 'select',
				}}
				native
				onChange={e => onChange({ paid: e.target.value })}
				value={placeholder?.paid || ''}
			>
				<option value='' key='undefined' />
				<option value='true' key='paid'>Paid</option>
				<option value='false' key='open'>Open</option>
			</Select>
		</FormControl>
	);

	const searchIcon = (
		<SearchIcon
			className={classnames(classes.searchIcon)}
			onClick={onSingleClick}
			onDoubleClick={onReset}
			size="small"
		/>
	);

	return (
		<div className={classnames(classes.root, className)}>
			{/* {dateFilters} */}
			{paidSelect}
			{searchIcon}
		</div>
	);
};

export default withStyles(styles)(ConsumptionFilter);

// const datePickerCreate = (classes, placeholder, onChange) => ({ field, label }) => (
// 	<DatePicker
// 		ampm={false}
// 		className={classes.pickers}
// 		clearable
// 		format="DD/MM/YYYY"
// 		inputVariant="outlined"
// 		key={label}
// 		label={label}
// 		onChange={date => onChange({ [field]: date && moment(date).format('YYYY-MM-DD') })}
// 		value={placeholder?.[field] || null}
// 		InputProps={{ classes: { input: classes.pickerInput } }}
// 		InputLabelProps={{ classes: { outlined: classes.outlined } }}
// 	/>
// );