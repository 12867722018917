import { useState } from 'react';
import useSwitch from '../../../../hooks/useSwitch';
import customerService from '../../../../services/customer/ws';

const useChangePassword = () => {

	const [message, setMessage] = useState(null);
	const error = useSwitch(false);

	const changePassword = async (oldPass, newPass) => {
		try {
			await customerService.changePassword(oldPass, newPass);
			setMessage("Password changed successfully");
			error.setOff();
			setTimeout(setMessage, 5000);
			return { error: false, message: "Password changed successfully"};
		} catch {
			setMessage("Unable to change password");
			error.setOn();
			setTimeout(setMessage, 5000);
			return { error: true, message: "Unable to change password"};
		};
	};

	return { changePassword, message, error };
};


export default useChangePassword;