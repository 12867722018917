class NGError extends Error {
	
	constructor({ message, code, tag } = {}) {
		super(message);
		this.code = code;
		this.tag = tag;
	}
}

export default NGError;
