import React from 'react';
import List from '../../UI/List/List';
import PricingPlanItem from './PricingPlanItem/PricingPlanItem';
import {
	withStyles,
} from '@material-ui/core';
import pricingPlanFields from './pricingPlanFields';
import styles from './PricingPlanList.styles';
import classnames from 'classnames';

const PricingPlanList = ({
	asyncGetPPDetails,
	classes,
	className,
	closeModals,
	createMode,
	currentScreenSize,
	getMoreRows,
	onChange,
	onCustomersOrZonesEdit,
	onEdit,
	onRemove,
	onSelect,
	onUpdate,
	openCalendarHandler,
	placeholderSetters,
	pricingPlanArray,
	selectedPP,
	setModal,
	setSelectedPricingPlanId,
}) => {

	const selectedPPId = selectedPP && selectedPP.id;

	const pricingPlans = !!pricingPlanArray && pricingPlanArray.map(pricingPlan => (
		<PricingPlanItem
			asyncGetPPDetails={asyncGetPPDetails}
			closeModals={closeModals}
			createMode={createMode}
			key={pricingPlan.id}
			onChange={onChange}
			onCustomersOrZonesEdit={onCustomersOrZonesEdit}
			onEdit={onEdit}
			onRemove={onRemove}
			onSelect={onSelect}
			onUpdate={onUpdate}
			openCalendarHandler={openCalendarHandler}
			placeholderSetters={placeholderSetters}
			pricingPlan={pricingPlan}
			selected={selectedPPId === pricingPlan.id}
			setModal={setModal}
			setSelectedPricingPlanId={setSelectedPricingPlanId}
		/>
	));
	
	const list = (
		<List
			className={classnames(classes.root, className)}
			headerFields={pricingPlanFields(currentScreenSize)}
			getMoreRows={getMoreRows}
		>
			{pricingPlans}
		</List>
	);

	return list;
};

export default withStyles(styles)(PricingPlanList);