import React, { useEffect, useState } from "react";
import useSwitch from "../../../../hooks/useSwitch";
import withPermission from "../../../../hoc/withPermission";
import NotificationsEmail from "./NotificationsEmail";
import { useSelector } from "react-redux";
import { selectDefaultLocation } from "../../../../features/carclub/carclubSlice";

const NotificationsEmailWrapper = (props) => {
  const selectedLocation = useSelector(selectDefaultLocation);

  const [type, setType] = useState();
  const [subType, setSubType] = useState();
  const [onCreateOrEdit, setOnCreateOrEdit] = useState(false);
  const [openNotification, setOpenNotification] = useState();
  const openConfigurations = useSwitch(false);

  const { getNotifications } = props;

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line
  }, [selectedLocation]);

  const onCreateOrEditHandler = (notification) => {
    console.log("notification: %o", notification);
    setOpenNotification(notification);
    setOnCreateOrEdit(true);
  };

  const exitCreateOrEditModeHandler = () => {
    setOpenNotification();
    setOnCreateOrEdit(false);
  };

  return (
    <NotificationsEmail
      {...props}
      {...{
        type,
        setType,
        setSubType,
        subType,
      }}
      openConfigurations={openConfigurations}
      onCreateOrEdit={onCreateOrEdit}
      openNotification={openNotification}
      enterCreateOrEditMode={onCreateOrEditHandler}
      exitCreateOrEditMode={exitCreateOrEditModeHandler}
    />
  );
};

const permissions = [{ resource: ["carclub", "notifications", "read"] }];

export default withPermission(permissions)(NotificationsEmailWrapper);
