import React from 'react';
import List from '../../../UI/List/List';
import PromoCodeItem from './PromoCodeItem/PromoCodeItem';
import {
	withStyles,
} from '@material-ui/core';
import promoCodeFields from './promoCodeFields';
import styles from './PromoCodeList.styles';
import classnames from 'classnames';

const PromotionList = ({
	classes,
	className,
	currentScreenSize,
	getMoreRows,
	onSelectHandler,
	generatedCodeList,
	selectedItem,
	onDisableHandler,
	onDownloadCSVHandler,
}) => {

	const promotions = !!generatedCodeList
		&& [...new Set(generatedCodeList.map(element => JSON.stringify(element)))]
			.map(element => {
				const promoCodeItem = JSON.parse(element);

				return (
					<PromoCodeItem
						key={promoCodeItem.id}
						promoCodeItem={promoCodeItem}
						onDisableHandler={onDisableHandler}
						selected={selectedItem?.id === promoCodeItem?.id}
						onSelectHandler={onSelectHandler}
						onClick={() => onSelectHandler(promoCodeItem)}
						onDownloadCSVHandler={onDownloadCSVHandler}
					/>
				);
			});

	const list = (
		<List
			className={classnames(classes.root, className)}
			headerFields={promoCodeFields(currentScreenSize)}
			getMoreRows={getMoreRows}
		>
			{promotions}
		</List>
	);

	return list;
};

export default withStyles(styles)(PromotionList);
