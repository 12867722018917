import React from 'react';
import {
	withStyles,
} from '@material-ui/core';
import { styles } from './TripInfo.styles';
import carActiveIcon from '../../../../assets/icons/start.svg';
import carPausedIcon from '../../../../assets/icons/Stop.svg';
import distanceIcon from '../../../../assets/icons/distance_40px.svg';
import {
	millisecondsToTripDurationFormattedTime as millisToFormattedTime,
} from '../../../../utils/date';

const TripTimesAndDistance = ({
	classes,
	data,
}) => {

	const {
		inUseTime,
		standByTime,
		distance,
	} = data || {};

	const parsedActiveTime = millisToFormattedTime(inUseTime, false, false, true)
		|| '-';

	const parsedIdleTime = millisToFormattedTime(standByTime, false, false, true)
		|| '-';

	const parsedDistance = !!distance
		? distance + ' km'
		: '-';

	const activeTimeContainer = (
		<div className={classes.icons}>
			<div>
				<img
					style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
					src={carActiveIcon}
					height='38px'
					alt="active"
				/>
			</div>
			<div className={classes.centerTextFlex}>{parsedActiveTime}</div>
		</div>
	);

	const idleTimeContainer = (
		<div className={classes.icons}>
			<div>
				<img
					style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
					src={carPausedIcon}
					height='38px'
					alt="paused"
				/>
			</div>
			<div className={classes.centerTextFlex}>{parsedIdleTime}</div>
		</div>
	);

	const distanceTravelledContainer = (
		<div className={classes.icons}>
			<div>
				<img
					style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
					src={distanceIcon}
					height='38px'
					alt="distance"
				/>
			</div>
			<div className={classes.centerTextFlex}>{parsedDistance}</div>
		</div>
	);

	return (
		<div className={classes.tripData}>
			{activeTimeContainer}
			{idleTimeContainer}
			{distanceTravelledContainer}
		</div>
	);
};

export default withStyles(styles)(TripTimesAndDistance);