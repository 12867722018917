import React from 'react';
import List from '../../../../UI/List/List';
import {
	withStyles,
} from '@material-ui/core';
import Item from './Item/Item';
import listConfig from './listConfig';
import { styles } from './TaskSequenceList.styles';
import classnames from 'classnames';

const TaskSequenceList = ({
	classes,
	className,
	screenSize,
	moveTaskHandler,
	removeTaskHandler,
	tasksSequence,
	editMode,
}) => {

	const listItems = Array.isArray(tasksSequence) && tasksSequence.map(task => {

		return (
			<Item
				key={task.key}
				onMoveTask={moveTaskHandler}
				onRemove={removeTaskHandler}
				task={task}
				editMode={editMode}
			/>
		);
	});

	return (
		<List
			className={classnames(classes.root, className)}
			disableGutters={true}
			overridePadding
			headerFields={listConfig(screenSize)}
			subHeaderClassName={classes.subHeaderClassName}
		>
			{listItems}
		</List>
	);
};

export default withStyles(styles)(TaskSequenceList);