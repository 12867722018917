
import getRatingDetails from './getRatingDetails';
import getTimelineDetails from './getTimelineDetails';
import getTripAnalysis from './getTripAnalysis';
import getTripDetails from './getTripDetails';

const tripService = {
	getTripAnalysis,
	getRatingDetails,
	getTimelineDetails,
	getTripDetails,
};

export default tripService;

