import React from "react";
import { useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Grid, Typography } from "@material-ui/core";

import { styles } from "./Navigation.styles";
import PrimaryBar from "./PrimaryBar/PrimaryBar";

import ChatApp from "../../../../features/chat/ChatApp/ChatApp";
import Modal from "../../../../common/displays/Modal/Modal";
import NetworkSpinner from "../../../../components/UI/Spinner/NetworkSpinner/NetworkSpinner";
import { selectConnected, selectConnecting } from "../../../websocket/websocketSlice";

function Navigation({ classes, children, filteredApps, selectedApp, selectAppHandler }) {
  const connected = useSelector(selectConnected);
  const connecting = useSelector(selectConnecting);

  // console.log("Navigation ::: filteredApps: %o --- connecting: %o", filteredApps, connecting);
  return (
    <div className={classes.root}>
      <PrimaryBar apps={filteredApps} selectedApp={selectedApp} selectApp={selectAppHandler} />
      {connected && <ChatApp />}
      {children}
      {connecting && (
        <Modal open={true} sizeClass="small">
          <Grid
            container
            direction="column"
            spacing={5}
            alignItems="center"
            justifyContent="center"
            style={{ height: "100%" }}
          >
            <Grid item>
              <NetworkSpinner />
            </Grid>
            <Grid item>
              <Typography component="h5" variant="h5">
                Reconnecting...
              </Typography>
            </Grid>
          </Grid>
        </Modal>
      )}
    </div>
  );
}

export default withStyles(styles)(Navigation);
