export const styles = theme => ({

	root: {
		width: 'calc(100% - 14px)',
		height: 'calc(100vh - 165px)',
		border: '1px solid #707070',
		opacity: 0.48,
		overflowX: 'hidden',
		overflowY: 'scroll',
	},

});

export default styles;