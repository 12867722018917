import Rate from '../../../../model/PricingPlanNew/Rate';

//TODO finish this mapper
const rateMapper = {

	fromDTO: ({
		carclubId,
		color,
		goldPinInUse,
		goldPinInStandby,
		id,
		name,
		priceInStandby,
		priceInUse,
		priceKm,
		rateNumber,
	}) => new Rate({
		carclubId,
		color,
		goldPinInUse,
		goldPinInStandby,
		id,
		name,
		priceInStandby,
		priceInUse,
		priceKm,
		rateNumber,
	}),


	toDTO: ({
		carclubId,
		color,
		goldPinInUse,
		goldPinInStandby,
		id,
		name,
		priceInStandby,
		priceInUse,
		priceKm,
		rateNumber,
	}) => new Rate({
		carclubId,
		color,
		goldPinInUse,
		goldPinInStandby,
		id,
		name,
		priceInStandby,
		priceInUse,
		priceKm,
		rateNumber,
	}),
};

export default rateMapper;

