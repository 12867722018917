import React, { useCallback, useState } from 'react';
import carclubService from '../../../../services/carclub';
import UsersList from './UsersList';
import log from '../../../../utils/logger';

const logger = log('[Carclub]UsersListWrapper');

const UsersListWrapper = props => {

	const {
		lastPage,
		onRemove,
		onSearch,
		onUpdate,
	} = props || {};

	const getMoreRows = !lastPage && (() => onSearch());

	const [anchorEl, setAnchorEl] = useState();

	const anchorElHandler = useCallback(anchor => {
		setAnchorEl(anchor);
	}, []);

	const onEnable = useCallback(async user => {
		if(!user){
			return;
		};

		try{
			const updatedUser = await carclubService.enableUser(user);

			onUpdate(updatedUser);
		}
		catch(error){
			logger.warn(`Could not ${user.enabled ? 'disable' : 'enable'} ${user.code}`);
		};
	}, [onUpdate]);

	const onDelete = useCallback(async code => {
		try{
			const request = await carclubService.removeUser(code);

			!request.error && onRemove(code);
		}
		catch(error){
			logger.warn(`Could not remove ${code}`);
		};
	}, [onRemove]);

	return (
		<UsersList
			{ ...props }
			{...{
				anchorEl,
				getMoreRows,
				onDelete,
				onEnable,
				setAnchorEl: anchorElHandler,
			}}
		/>
	);
};

export default UsersListWrapper;