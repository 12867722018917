import { wsCall, requestHelper } from "../../../app/coreSlice";

const billingGetEnginesCall = async ({ setBillingEngines }, dispatch, state) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "BILLING/GET_ENGINES");
  let result;
  try {
    result = await wsCall({
      type: "BILLING",
      subType: "GET_ENGINES",
      locationId: selectedLocation?.id,
    });

    if (!result.error) {
      dispatch(setBillingEngines(result));
      // refresh reservations
    } else {
      rh.error(result?.description || "Error loading billing engines.");
    }
  } catch (_ex) {
    rh.error("Error loading billing engines.");
  }
  rh.close();
  return result;
};

export default billingGetEnginesCall;
