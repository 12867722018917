import associateAccountMember from './associateAccountMember';
import billingCharge from './billingCharge';
import billingCreate from './billingCreate';
import billingDelete from './billingDelete';
import billingDetails from './billingDetails';
import billingInvoice from './billingInvoice';
import billingList from './billingList';
import billingSendEmail from './billingSendEmail';
import billingSettle from './billingSettle';
import createAccount from './createAccount';
import enableAccount from './enableAccount';
import getAccount from './getAccount';
import getAccountMembers from './getAccountMembers';
import getAccountReservations from './getAccountReservations';
import getAccountsFromCustomer from './getAccountsFromCustomer';
import listAccounts from './listAccounts';
import removeAccount from './removeAccount';
import removeAccountMember from './removeAccountMember';
import setInvoiceAsPaid from './setInvoiceAsPaid';
import updateAccount from './updateAccount';
import getAccountBillingItems from './getAccountBillingItems';

const accountService = {
	associateAccountMember,
	billingCharge,
	billingCreate,
	billingDelete,
	billingDetails,
	billingInvoice,
	billingList,
	billingSendEmail,
	billingSettle,
	createAccount,
	enableAccount,
	getAccount,
	getAccountMembers,
	getAccountReservations,
	getAccountsFromCustomer,
	listAccounts,
	removeAccount,
	removeAccountMember,
	setInvoiceAsPaid,
	updateAccount,
	getAccountBillingItems,
};

export default accountService;