import React from "react";
import AssetInformation from "../AssetInformation/AssetInformation";
// import ZonesAndParks from "../ZonesAndParks/ZonesAndParks";
import { Typography, Modal, withStyles } from "@material-ui/core";
import Buttons from "../Buttons/Buttons";
import { styles } from "./UpsertAsset.styles";
import classnames from "classnames";
import AssetHeader from "../AssetInformation/AssetHeader/AssetHeader";
import { CONFIRMATION_SCREENS } from "../utils";
import DeviceForm from "../../../../../features/asset/DeviceForm/DeviceForm";
import ZonesParksForm from "../../../../../features/asset/ZonesParksForm/ZonesParksForm";

export const screens = {
  ASSET_INFO: "Asset Information",
  OBS_DEVICE: "OBS Device",
  ZONES_AND_PARKS: "Zones and Parks",
};

const UpsertAsset = ({
  classes,
  className,
  errorHandling,
  generalInfo,
  handlers,
  hasRequest,
  placeholder,
  onDeviceAssociate,
  onZonesAdded,
}) => {
  const { setCurrentTab, setConfirmationScreen, closeHandler, closeHandlerCreate } = handlers || {};

  const { onCreate, currentTab, confirmationScreen } = generalInfo || {};

  const { errorMessage, isSuccess, hasNotification } = errorHandling || {};

  const errorBar = (
    <Typography
      align="center"
      className={classnames(
        classes.rootBar,
        hasNotification ? classes.showBar : classes.hideBar,
        !!isSuccess ? classes.success : classes.error
      )}
    >
      {errorMessage}
    </Typography>
  );

  const header = (
    <AssetHeader
      noUnderline={true}
      titleText={
        onCreate ? "Creating asset" : `Editing asset: ${placeholder?.licensePlate || " missing license plate."}`
      }
      placeholder={placeholder}
      handlers={handlers}
      generalInfo={generalInfo}
    />
  );

  const steps = {
    [screens.ASSET_INFO]: (
      <AssetInformation
        className={classes.assetInformation}
        generalInfo={generalInfo}
        handlers={handlers}
        hasRequest={hasRequest}
        placeholder={placeholder}
        removeHeader={true}
      />
    ),
    [screens.ZONES_AND_PARKS]: (
      <ZonesParksForm
        carToken={placeholder?.carToken}
        licensePlate={placeholder?.licensePlate}
        deviceToken={placeholder?.deviceToken}
        wanted={placeholder?.zones?.wanted.map((item) => item.id)}
        unwanted={placeholder?.zones?.unwanted.map((item) => item.id)}
        parkIds={placeholder?.zones?.park.map((item) => item.id)}
        onAdded={onZonesAdded}
      />
    ),
    [screens.OBS_DEVICE]: (
      <DeviceForm
        disabled={Boolean(placeholder?.providerToken)}
        carToken={placeholder?.carToken}
        licensePlate={placeholder?.licensePlate}
        provider_token={placeholder?.providerToken}
        serial_number={placeholder?.device?.serial_number}
        externalId={placeholder?.device?.externalId}
        onRemove={() => setConfirmationScreen(CONFIRMATION_SCREENS.REMOVE_DEVICE)}
        onAssociate={onDeviceAssociate}
      />
    ),
  };

  const confimationScreens = {
    [CONFIRMATION_SCREENS.ACTIVATE_DEVICE_OR_CREATE_ONLY]: (
      <div className={classes.confirmationModal}>
        <Typography>If you confirm, the vehicle will be ready to be sold to your customer.</Typography>
        <Typography>
          If you skip the activation, the asset will be created,{" "}
          <strong>but you'll need to add a device and activate it to start the commercial deployment.</strong>
        </Typography>
      </div>
    ),
    [CONFIRMATION_SCREENS.REMOVE_DEVICE]: (
      <div className={classes.confirmationModal}>
        <Typography>
          You are about to remove the device from this asset, this will deactivate your asset. In order to make it
          available, it must have a device. Are you sure you want to proceed?
        </Typography>
      </div>
    ),
    [CONFIRMATION_SCREENS.CONFIRM_EXIT]: (
      <div className={classes.confirmationModal}>
        <Typography>If you cancel the operation, all changes will be lost.</Typography>
      </div>
    ),
  };

  const confirmationScreenContent = !!confirmationScreen && confimationScreens[confirmationScreen];

  const content = !confirmationScreen && steps[currentTab];

  const modalCloseHandler = () => {
    closeHandler();
    closeHandlerCreate();
  };

  return (
    <Modal open={true} onClose={modalCloseHandler}>
      <div className={classnames(classes.root, className)}>
        <div className={classes.workspace}>
          {header}
          {errorBar}
          <div className={classes.tabs}>
            {Object.values(screens).map((screen) => {
              let disabled = false;

              if (!placeholder?.carToken) {
                disabled = true;
              }
              if (!placeholder?.providerToken && screen === screens.ZONES_AND_PARKS) {
                // console.log("placeholder: %o", placeholder);
                disabled = true;
              }

              return (
                <Typography
                  className={classnames(
                    classes.tab,
                    screen === currentTab && classes.selectedTab,
                    disabled && classes.disabled
                  )}
                  key={screen}
                  onClick={() => !disabled && setCurrentTab(screen)}
                >
                  {screen}
                </Typography>
              );
            })}
          </div>
          <div className={classes.content}>{content || confirmationScreenContent}</div>
        </div>
        {(confirmationScreen || currentTab === screens.ASSET_INFO) && (
          <Buttons generalInfo={generalInfo} handlers={handlers} hasRequest={hasRequest} placeholder={placeholder} />
        )}
      </div>
    </Modal>
  );
};

export default withStyles(styles)(UpsertAsset);
