import React from 'react';
import DetailsDiv from '../../../UI/DetailsDiv/DetailsDiv';
import DetailsRow from '../../../UI/DetailsDiv/DetailsRow/DetailsRow';
import { dateFormatter, currencyFormatter } from '../../../../services/formatter';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import {
	Check as CheckIcon,
} from '@material-ui/icons';
import styles from './RolesDetails.styles';

//Why is this an array you might ask? To assure order is maintained
const labels = [
	{ key: 'name', label: 'Designation' },
	{ key: 'permissionCode', label: 'Permission' },
	{ key: 'maxAmount', label: 'Max Amount', currency: true},
	{ key: 'createDate', label: 'Created', date: true },
	{ key: 'createUser', label: 'Created by' },
	{ key: 'updateDate', label: 'Last modified', date: true },
	{ key: 'updateUser', label: 'Modified by' },
	{ key: 'enabled', label: 'Enabled', icon: true },
];

const RolesDetails = ({
	classes,
	role,
	locationsObj,
}) => {

	const details = !!role && labels.map(detail => {

		const checkIcon = !!role[detail.key] && (
			<CheckIcon
				className={classes.checkIcon}
			/>
		);

		const detailText = detail.date
			? dateFormatter.format(role[detail.key], 'dateTime')
			: `${role[detail.key]}${detail.currency ? currencyFormatter.symbol() : ''}`;

		const detailInfo = detail.icon
			? checkIcon
			: (
				<Typography className={classes.detail}>
					{detailText}
				</Typography>
			);

		return (
			<DetailsRow
				key={detail.label}
				label={detail.label}
				detail={!!role[detail.key] && detailInfo}
			/>
		);
	});

	const locationList = Array.isArray(role?.locations) && role.locations.map((loc, i) => {
		const detail = (
			<Typography className={classes.detail}>
				{locationsObj[loc]?.name}
			</Typography>
		);

		return (
			<DetailsRow
				key={loc}
				label={i === 0 ? 'Locations' : ''}
				detail={detail || ''}
			/>
		);
	});

	return (
		<div className={classes.root}>
			<DetailsDiv title='Role Details'>
				{details}
				{locationList}
			</DetailsDiv>
		</div>
	);
};

export default withStyles(styles)(RolesDetails);