import React from 'react';
import Button from '../../UI/Buttons/CommonButtons';
import {
	Checkbox,
	TextField,
	Typography,
	withStyles,
} from '@material-ui/core';
import { currencyFormatter } from '../../../services/formatter';
import styles from './ExtraEditor.styles';
import { CHECK_TYPE } from '../../../containers/PricingPlanAppNew/PricingPlanAppWrapper';
import classnames from 'classnames';

const ExtraEditor = ({
	classes,
	checkPlaceholderRequiredValues,
	defaultLocation,
	disableButtons,
	editMode,
	error,
	onCancel,
	onChangeHandler,
	onSave,
	placeholder,
	setError,
}) => {

	const title = (
		<Typography className={classes.title}>
			{`${placeholder?.id ? 'Editing' : 'Creating'} Additional Fee`}
		</Typography>
	);


	const defaultLocationField = (
		<TextField
			defaultValue={defaultLocation?.name}
			id='defLocEditor'
			label='Location'
			disabled={true}
		/>
	);

	const name = (
		<TextField
			value={placeholder?.name || ''}
			id='nameEditor'
			label='Name'
			onChange={event => onChangeHandler('name', event.target.value)}
			disable={(!editMode.state).toString()}
			required={true}
		/>
	);

	const description = (
		<TextField
			id='descriptionExtraEditor'
			value={placeholder?.description || ''}
			label='Description'
			onChange={event => onChangeHandler('description', event.target.value)}
			disable={(!editMode.state).toString()}
			required={true}
		/>
	);

	const longDescriptionLabel = (
		<Typography className={classes.subtitle14px} key={'label_long_description'}>
			Long description
		</Typography>
	);

	const longDescriptionInput = (
		<TextField
			InputLabelProps={{ classes: { root: classes.labelProps, focused: classes.labelProps } }}
			InputProps={{ classes: { root: classes.inputPropsMultiline } }}
			className={classes.fieldMultiline}
			classes={{ root: classes.multiline }}
			disabled={!editMode}
			error={false}
			key={'input_long_description'}
			margin="normal"
			multiline
			onChange={(e) => onChangeHandler('longDescription', e.target.value)}
			required={false}
			minRows={3}
			value={placeholder?.longDescription || ''}
			variant={'outlined'}
		/>
	);

	const longDescriptionDiv = (
		<>
			{longDescriptionLabel}
			{longDescriptionInput}
		</>
	);

	const value = (
		<div className={classes.valueDiv}>
			<TextField
				id='valueExtraEditor'
				label='Value'
				value={placeholder?.value || ''}
				onChange={event => onChangeHandler('value', event.target.value)}
				disable={(!editMode.state).toString()}
				required={true}
			/>
			<Typography
				className={classes.endAdornment}
				position="end">
				{currencyFormatter.symbol()}
			</Typography>
		</div>
	);

	const enabledLabel = (
		<Typography className={classes.checkboxLabel} key={'label_enabled'}>
			Enabled
		</Typography>
	);

	const enabledInput = (
		<Checkbox
			key={'input_enbled'}
			className={classes.checkbox}
			checked={!!placeholder?.enabled ? true : false}
			color='primary'
			onChange={() => {
				const current = !!placeholder?.enabled;
				onChangeHandler('enabled', !current);
			}}
			disableRipple={true}
			disableTouchRipple={true}
			disabled={!editMode}
		/>
	);

	const enabledDiv = (
		<div className={classnames(classes.labelAndInputCheckbox)}>
			{enabledLabel}
			{enabledInput}
		</div>
	);

	const errorField = !!error && (
		<Typography align='center' className={classes.error}>
			{error}
		</Typography>
	);

	const saveButton = (
		<Button
			buttonStyleName='filled'
			disabled={disableButtons}
			key='SaveButton'
			label={placeholder?.id ? 'Save' : 'Create'}
			onClick={() => {
				const checkResult = checkPlaceholderRequiredValues(placeholder, CHECK_TYPE.EXTRA);

				if (!!checkResult) {
					setError(`The "${[checkResult]}" field is required.`);
					return;
				}
				onSave();
			}}
		/>
	);

	const cancelButton = (
		<Button
			buttonStyleName='void'
			disabled={disableButtons}
			key='CancelButton'
			label='Cancel'
			onClick={onCancel}
		/>
	);

	const pricingPlanEditor = (
		<div className={classes.root}>
			{title}
			{defaultLocationField}
			{name}
			{description}
			{longDescriptionDiv}
			{value}
			{enabledDiv}
			<div className={classes.buttons}>
				{cancelButton}
				{saveButton}
			</div>
			{errorField}
		</div>
	);

	return pricingPlanEditor;
};

export default withStyles(styles)(ExtraEditor);