import React, { useContext } from "react";
import PermissionContext from "../../context/PermissionContext";

function Permission({ children, code }) {
  const permissions = useContext(PermissionContext);
  let permission = extractPermissionValue(permissions || {}, code);
  if (permission) {
    return <>{children}</>;
  }
  return null;
}

function extractPermissionValue(userPermissions, code) {
  try {
    return code.split(".").reduce((o, i) => {
      return o[i] || false;
    }, userPermissions);
  } catch (ex) {
    return false;
  }
}

export default Permission;
