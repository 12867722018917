const styles = theme => ({

	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		minHeight: 30,
		padding: '1em 0',
	},

	header: {
		display: 'inline-flex',
		width: '100%',
		cursor: 'pointer',
		alignItems: 'center',
	},

	icon: {
		marginRight: '24px',
	},

	buttons: {
		display: 'inline-flex',
		justifyContent: 'space-around',
		margin: '1em 0',
	},

	timestamp: {
		textAlign: 'center',
	},

	assetList: {
		display: 'flex',
		flexDirection: 'column',
		maxHeight: '100px',
		overflowY: 'auto',
		scrollbarWidth: 'thin', //FIREFOX
		//CHROME
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},

	assetItem: {
		display: 'flex',
		width: 'auto',
		alignContent: 'center',
	},

	assignedAssets: {
		flex: 1,
	},

	infoIcon: {
		color: '#009BCC',
		margin: '0 5px',
	},

	assetItemText: {
		flex: 1,
	},

	status: {
		maxWidth: '75px',
		marginRight: '8px',
		flex: 1,
	},

	statusText: {
		maxWidth: '75px',
		flex: 1,
	},
});

export default styles;