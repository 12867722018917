import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid, Tabs, Tab } from "@material-ui/core";

import { styles } from "./AssetReportDamagesMain.styles";

import {
  selectDamageReports,
  selectIsLoading,
  damageReportList,
  damageReportGetResolved,
  setSelectedDamage,
  selectSelectedDamage,
  damageReportResolve,
} from "../damagereportSlice";
import InfiniteList from "../../../common/displays/InfiniteList/InfiniteList";
import DamageItem from "./components/DamageItem/DamageItem";
import DamagesGrid from "./components/DamagesGrid/DamagesGrid";
import { selectSelectedAsset } from "../../asset/assetSlice";

function AssetReportDamagesMain({ classes, modalOpen }) {
  const dispatch = useDispatch();
  const loading = useSelector(selectIsLoading);
  const damageReports = useSelector(selectDamageReports);
  const selectedDamageObj = useSelector(selectSelectedDamage);
  const asset = useSelector(selectSelectedAsset);
  const [tab, setTab] = useState(1);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (asset?.assetToken && !loaded && !loading && modalOpen) {
      dispatch(damageReportList({ assetToken: asset?.assetToken }));
      dispatch(damageReportGetResolved({ assetToken: asset?.assetToken }));
      setLoaded(true);
    }
    // eslint-disable-next-line
  }, [loading, modalOpen]);

  useEffect(() => {
    if (!loading && (damageReports?.list || []).length > 0) {
      setTab(0);
    }
    // eslint-disable-next-line
  }, [loading, damageReports?.list]);

  useEffect(() => {
    if (modalOpen) {
      setTab(1);
    }
    // eslint-disable-next-line
  }, [modalOpen]);

  const onResolveHandler = (damageToken) => {
    dispatch(damageReportResolve({ assetToken: asset?.assetToken, damageToken }));
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{asset?.licensePlate} Reported Damages</Typography>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={6}>
          <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)} className={classes.tabs}>
            <Tab
              label={
                damageReports?.list?.length > 0 ? "Reported (" + damageReports?.list?.length + ")" : "Reported (0)"
              }
              style={damageReports?.list?.length > 0 ? { fontSize: 14 } : null}
            />
            <Tab label="Resolved" />
          </Tabs>
          <div hidden={tab !== 0}>
            <InfiniteList
              className={classes.list}
              isLoading={loading}
              data={damageReports?.list}
              renderElement={(damage) => {
                return (
                  <DamageItem
                    damage={damage}
                    selected={damageReports?.selectedDamage === damage?.damageToken}
                    onSelect={(damageId) => dispatch(setSelectedDamage(damageId))}
                    onResolve={onResolveHandler}
                  />
                );
              }}
            />
          </div>
          <div hidden={tab !== 1}>
            <InfiniteList
              className={classes.list}
              isLoading={loading}
              data={damageReports?.listSolved}
              renderElement={(damage) => {
                return (
                  <DamageItem
                    damage={damage}
                    selected={damageReports?.selectedDamage === damage?.id}
                    onSelect={(damageToken) => dispatch(setSelectedDamage(damageToken))}
                  />
                );
              }}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.grid}>
            <DamagesGrid
              damageReports={damageReports?.list}
              asset={asset}
              onSelect={(damageId) => {
                dispatch(setSelectedDamage(damageId));
                setTab(0);
              }}
              //getDetails={(report) => setSelectedDamageReport(report)}
              selected={selectedDamageObj}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(AssetReportDamagesMain);
