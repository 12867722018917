import { wsCall, requestHelper } from "../../../app/coreSlice";

const crCustomerListCall = async ({ setCrCustomers }, dispatch, state, { name }) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;

  let rh = requestHelper(dispatch, "CUSTOMER/NEW_LIST");
  try {
    const result = await wsCall({
      type: "CUSTOMER",
      subType: "NEW_LIST",
      locationId: selectedLocation?.id,
      message: {
        name,
        available: true,
        onboarded: true,
        limit: 10000,
      },
    });
    if (result) {
      dispatch(setCrCustomers(result?.customers || []));
    }
  } catch (_ex) {
    rh.error("Error getting drivers.");
  }
  rh.close();
};

export default crCustomerListCall;
