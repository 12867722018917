import React, { useState, useEffect, useCallback } from 'react';
import accountService from '../../../services/account/ws';
import customerService from '../../../services/customer/ws';
import modals from '../../../model/Account/modals';
import Members from './Members';
import log from '../../../utils/logger';

const logger = log('MembersModalWrapper');

const filterMembers = filter => member => !!member && (
	(!member.name || member.name.includes(filter))
	|| (!member.email || member.email.includes(filter))
	|| (!member.phone || member.phone.includes(filter))
);

const MembersWrapper = ({
	account,
	setCustomerCode,
	setModal,
	...props
}) => {

	const [selected, setSelected] = useState();
	const [accountMembers, setAccountMembers] = useState({});
	const [drivers, setDrivers] = useState([]);
	const [filter, setFilter] = useState('');
	const [selectBy, setSelectBy] = useState('name');

	useEffect(() => {
		const getMembers = async () => {
			try {
				setAccountMembers(await accountService.getAccountMembers(account));
			}
			catch (error) {
				logger.warn(`Error getting members for account ${account}`);
			};
		};

		getMembers();
	}, [account]);

	const addMember = useCallback(async member => {
		try {
			setAccountMembers(await accountService.associateAccountMember(account, member));
		}
		catch (error) {
			logger.warn(`Error adding the following member ${member.code} to ${account}`);
		}
	}, [account]);

	const removeMember = useCallback(async memberCode => {
		try {
			setAccountMembers(await accountService.removeAccountMember(account, memberCode));

			setSelected();
		}
		catch (error) {
			logger.warn(`Error removing the following member ${memberCode} from ${account}`);
		}
	}, [account]);

	const getAllDrivers = useCallback(async () => {
		// if (!filter.trim()) {
		// 	return;
		// };

		setDrivers([]);

		try {
			setDrivers(await customerService.searchCustomers({ selectBy, selectByString: filter }));

		}
		catch (error) {
			logger.warn(`Error getting drivers with filter ${filter}`);
		};
	}, [filter, selectBy]);

	const showCustomerTrips = useCallback(code => {
		setModal(modals.TRIPS);
		setCustomerCode(code);
	}, [setCustomerCode, setModal]);

	const customerAccountDetails = (
		<Members
			{...{
				...props,
				addMember,
				filter,
				getAllDrivers,
				removeMember,
				selected,
				setFilter,
				showCustomerTrips,
			}}
			drivers={drivers.filter(driver =>
				!Object.keys(accountMembers).includes(driver.code))}
			members={Object.values(accountMembers).filter(filterMembers(filter))}
			onSelect={setSelected}
			selectBy={selectBy}
			setSelectBy={setSelectBy}
		/>
	);

	return customerAccountDetails;
};

export default MembersWrapper;