import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import styles from "./CustomerFilters.styles";
import Button from "../../UI/Buttons/CommonButtons";
import withPermission from "../../../hoc/withPermission";
import { useSelector } from "react-redux";
import { selectDefaultLocation } from "../../../features/carclub/carclubSlice";
import CustomerListFilter from "../../../features/customer/CustomersList/CustomerListFilter/CustomerListFilter";
import Permission from "../../../app/components/Permission";
import { isSharing } from "../../../common/utils/OTypes";

const CustomerFilters = ({ classes, disabledBook, setModal, onAddCustomer }) => {
  const selectedLocation = useSelector(selectDefaultLocation);

  return (
    <div className={classes.root}>
      <Grid container spacing={1} alignItems="center" justifyContent="space-between">
        <Grid item>
          <CustomerListFilter />
        </Grid>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              {isSharing(selectedLocation.operationType) && (
                <Button
                  className={classes.bookButton}
                  disabled={disabledBook}
                  key="book_button"
                  label="Book a car"
                  onClick={() => setModal(true)}
                />
              )}
            </Grid>
            <Permission code="customers.create">
              <Grid item>
                <AddButton onClick={onAddCustomer} className={classes.createIcon} />
              </Grid>
            </Permission>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(CustomerFilters);

const AddButton = withPermission([{ resource: ["customers", "edit"] }])(({ onClick, className }) => {
  return <AddCircle onClick={onClick} className={className} elevation={2} />;
});
