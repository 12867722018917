import React, { useState, useCallback } from 'react';
import Tree from './Tree';

const TreeWrapper = props => {

	const [closed, setClosed] = useState([]);

	const onToggle = useCallback(key => {
		setClosed(prev => prev.includes(key)
			? prev.filter(permission => permission !== key)
			: [...prev, key]);
	}, [setClosed]);

	return <Tree
		{...props}
		onToggle={onToggle}
		closed={closed}
	/>;
};

export default TreeWrapper;