import React from 'react';
import {
	Modal,
	TextField,
	Typography,
	withStyles,
} from '@material-ui/core';
import Button from '../../../UI/Buttons/CommonButtons';
import styles from './ChargeModal.styles';

const Consumption = ({
	chargingModal,
	classes,
	description,
	hasRequest,
	onSettle,
	onCharge,
	setChargingModal,
	setDescription,
}) => {
	const chargeButton = (
		<Button
			className={classes.chargingModalButton}
			disabled={hasRequest}
			key='charge_button'
			label='Charge'
			onClick={onCharge}
		/>
	);

	const setAsPaidButton = (
		<Button
			disabled={hasRequest}
			key='create_button'
			label='Set as Paid'
			onClick={onSettle}
		/>
	);
	const chargingModalTitle = (
		<Typography className={classes.chargingModalTitle} >
			Payment Actions
		</Typography >
	);

	const chargeBlock = (
		<div className={classes.chargeBlock}>
			<Typography className={classes.chargingModalParagraph} >
				Select the desired action to perform.
			</Typography >
			<Typography className={classes.chargingModalParagraph} >
				Charge will make a payment attempt directly to the owner's account.
			</Typography >
			<div className={classes.btnDiv}>
				{chargeButton}
			</div>
		</div>
	);


	const setAsPaidBlock = (
		<div className={classes.setAsPaidBlock}>
			<Typography className={classes.chargingModalParagraph} >
				Charge will make a payment attempt directly to the owner's account.
			</Typography >
			<TextField
				InputLabelProps={{ classes: { root: classes.labelProps, focused: classes.labelProps } }}
				InputProps={{ classes: { root: classes.inputPropsMultiline, multiline: classes.inputBasePropsMultiline } }}
				className={classes.fieldMultiline}
				classes={{ root: classes.multiline }}
				error={false}
				key={'set_as_paid_description'}
				margin="normal"
				multiline
				onChange={e => setDescription(e.target.value)}
				required={false}
				rows={3}
				value={description || ''}
				variant={'outlined'}
			/>
			<div className={classes.btnDiv}>
				{setAsPaidButton}
			</div>
		</div>
	);

	return (
		<Modal
			onClose={() => {
				setChargingModal();
			}}
			open={!!chargingModal}
		>
			<div className={classes.chargingModalRoot}>
				<div className={classes.chargingModalWorkspace}>
					{chargingModalTitle}
					{chargeBlock}
					{setAsPaidBlock}
				</div>

			</div>
		</Modal>
	);
};

export default withStyles(styles)(Consumption);