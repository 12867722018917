import React from 'react';
import {
	withStyles,
} from '@material-ui/core';
import { styles } from './PricingPlanBuilder.styles';
import classnames from 'classnames';
import PricingPlanCalendar from '../PricingPlanCalendar/PricingPlanCalendar';
import RatesList from '../PricingPlanRatesList/RatesListWrapper';
import MaxFlatRatesList from '../PricingPlanRatesList/MaxFlatRatesList/MaxFlatRatesListWrapper';

const PricingPlanBuilder = ({
	classes,
	className,
	currentScreenSize,
	asyncGetRates,
	blockSelection,
	calendarAddHandler,
	calendarState,
	calendarStyle,
	calendarSubtractHandler,
	closeHandler,
	dragDropHandler,
	dragOverHandler,
	dragStartHandler,
	info,
	maxRatesArray,
	placeholderMaxFlatRate,
	rates,
	saveHandler,
	selectedPP,
	selectedRateToApply,
	setBlockSelection,
	setCalendar,
	setRates,
	setSelectedRateToApply,
	showPricingTable,
	toggleShowPricingTable,
	ratesList,
	setRatesList,
	error,
}) => {



	const calendar = (
		<PricingPlanCalendar
			blockSelection={blockSelection}
			calendarAddHandler={calendarAddHandler}
			calendarState={calendarState}
			calendarStyle={calendarStyle}
			calendarSubtractHandler={calendarSubtractHandler}
			closeHandler={closeHandler}
			currentScreenSize={currentScreenSize}
			dragDropHandler={dragDropHandler}
			dragOverHandler={dragOverHandler}
			dragStartHandler={dragStartHandler}
			info={info}
			rates={ratesList}
			saveHandler={saveHandler}
			selectedPP={selectedPP}
			setBlockSelection={setBlockSelection}
			setCalendar={setCalendar}
			showPricingTable={showPricingTable}
			toggleShowPricingTable={toggleShowPricingTable}
			error={error}
		/>
	);


	const ratesSideDiv = (
		<div className={classes.ratesSideDiv}>
			<RatesList
				className={classes.ratesList}
				currentScreenSize={currentScreenSize}
				selectedRateToApply={selectedRateToApply}
				setSelectedRateToApply={setSelectedRateToApply}
				rates={rates}
				setRates={setRates}
				asyncGetRates={asyncGetRates}
				ratesList={ratesList}
				setRatesList={setRatesList}
				fullSizeList={false}
			/>
			<MaxFlatRatesList
				className={classes.maxRatesList}
				currentScreenSize={currentScreenSize}
				placeholderMaxFlatRate={placeholderMaxFlatRate}
				maxRatesArray={maxRatesArray}
			/>
		</div>
	);


	return (
		<div className={classnames(classes.root, className)}>
			<div className={classes.workspace}>
				<div className={classes.flexVertically}>
					{calendar}
				</div>
				{ratesSideDiv}
			</div>
		</div>
	);
};

export default withStyles(styles)(PricingPlanBuilder);