import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Button } from "@material-ui/core";

import { styles } from "./InvoiceButton.styles";
import { useDispatch } from "react-redux";
import { billingInvoiceSingleTrip } from "../../../../reservationsSlice";

function InvoiceButton({ classes, reservationCode, customerCode, loading }) {
  const dispatch = useDispatch();

  const invoiceHandler = () => {
    dispatch(
      billingInvoiceSingleTrip({
        reservationCode,
        customerCode,
      })
    );
  };

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        size="small"
        disabled={loading}
        onClick={invoiceHandler}
      >
        Invoice
      </Button>
    </div>
  );
}

export default withStyles(styles)(InvoiceButton);
