import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";

import { styles } from "./CarclubConfigurations.styles";
import {
  billingGetEngines,
  carclubGetConfigs,
  carclubGetDefaultConfigs,
  selectDefaultLocation,
  selectIsLoading,
} from "../carclubSlice";

function CarclubConfigurations({ classes, isDefaultConfigs }) {
  const dispatch = useDispatch();
  const loading = useSelector(selectIsLoading);
  const selectedLocation = useSelector(selectDefaultLocation);

  const [billingEnginesLoaded, setBillingEnginesLoaded] = useState(false);
  const [configsLoaded, setConfigsLoaded] = useState();

  useEffect(() => {
    if (!loading) {
      if (isDefaultConfigs) {
        if (configsLoaded !== "CARCLUB") {
          dispatch(carclubGetDefaultConfigs());
          setConfigsLoaded("CARCLUB");
        }
      } else {
        if (configsLoaded !== selectedLocation) {
          dispatch(carclubGetConfigs());
          setConfigsLoaded(selectedLocation);
        }
      }
      if (!billingEnginesLoaded) {
        dispatch(billingGetEngines());
        setBillingEnginesLoaded(true);
      }
    }
    // eslint-disable-next-line
  }, [loading, selectedLocation, isDefaultConfigs]);

  return <div className={classes.root}></div>;
}

export default withStyles(styles)(CarclubConfigurations);
