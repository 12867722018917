import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Grid } from "@material-ui/core";

import { styles } from "./LabelValueInfo.styles";

function LabelValueInfo({ classes, label, value }) {
  return (
    <Grid className={classes.root} container direction="row" wrap="nowrap" spacing={1} justifyContent="space-between">
      <Grid item>{label}</Grid>
      <Grid item>{value}</Grid>
    </Grid>
  );
}

export default withStyles(styles)(LabelValueInfo);
