import createIndividualBonus from './createIndividualBonus';

import listIndividualBonus from './listIndividualBonus';
// PROMO CODE GEN
import createPromoCode from './createPromoCode';
import listGeneratedCodes from './listGeneratedCodes';
import disablePromoCode from './disablePromoCode';
import getCSVFromPromoCodeId from './getCSVFromPromoCodeId';
//INDIVIDUAL BONUS
import updateIndividualBonus from './updateIndividualBonus';

const promotionService = {
	createIndividualBonus,
	listIndividualBonus,
	createPromoCode,
	updateIndividualBonus,
	listGeneratedCodes,
	disablePromoCode,
	getCSVFromPromoCodeId,
};

export default promotionService;