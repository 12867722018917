import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";

import { styles } from "./_base.styles";
import VehicleIcon from "../../../features/asset/common/VehicleIcon/VehicleIcon";
import FuelPercentage from "../../../features/asset/common/FuelPercentage/FuelPercentage";
import { useDispatch } from "react-redux";
import { setSelectedAssetToken } from "../../../features/asset/assetSlice";

function AssetMarker({ classes, asset, selected, selectAsset }) {
  const dispatch = useDispatch();
  // console.log("asset: %o", asset);
  // console.log("selected: %o", selected);
  return (
    <div
      onClick={() => {
        if (!selected) {
          selectAsset(asset.assetToken);
          dispatch(setSelectedAssetToken(asset.assetToken));
        }
      }}
    >
      <VehicleIcon
        asset={asset}
        mapPin
        richTooltip
        tooltip={
          asset && (
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography variant="h5">{asset.licensePlate}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  <b>Odometer: </b> {!!asset.mileage ? asset.mileage + "km" : "-"}
                </Typography>
              </Grid>
              <Grid item>
                <FuelPercentage percentage={asset?.fuelLevel || null} fuelType={asset?.fuelType} />
              </Grid>
              {asset?.inUse && (
                <Grid item>
                  <Typography variant="body1">
                    <b>Current Driver</b>
                    <br />
                    {asset.customerName?.indexOf(asset.rfidTagInUse) > 0
                      ? asset.rfidTagInUse
                      : `${asset.customerName} (${asset.customerCode})`}
                  </Typography>
                </Grid>
              )}
            </Grid>
          )
        }
      />
    </div>
  );
}

export default withStyles(styles)(AssetMarker);
