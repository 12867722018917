const styles = () => ({

	root: {
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100vw',
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},

	paper : {
		position: 'absolute',
		width: '50vw',
		height: '80vh',
		padding: '40px 30px 10px',
	},

	closeButton: {
		margin: 'auto',
		top: 0,
		right: 0,
		position: 'absolute',
	},
});

export default styles;