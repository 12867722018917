import { Typography } from "@material-ui/core";
import * as Yup from "yup";
import { CheckListField } from "../../../common/forms";
import { ReactComponent as ParkIcon } from "../../../assets/Zones/parkZone.svg";
import { ReactComponent as WantedIcon } from "../../../assets/Zones/wantedZone.svg";
import { ReactComponent as UnwantedIcon } from "../../../assets/Zones/unwantedZone.svg";

export function formSchema() {
  return Yup.object().shape({});
}

export function formFields(classes, disabled, wantedZones, unwantedZones, parks, form) {
  const wantedZonesOptions = (wantedZones || []).map((item) => {
    return {
      value: item?.id,
      component: (
        <div className={classes.item}>
          <div className={classes.icon}>
            <WantedIcon />
          </div>
          <span>{item?.description}</span>
        </div>
      ),
    };
  });

  const unwantedZonesOptions = (unwantedZones || []).map((item) => {
    return {
      value: item?.id,
      component: (
        <div className={classes.item}>
          <div className={classes.icon}>
            <UnwantedIcon />
          </div>
          <span>{item?.description}</span>
        </div>
      ),
    };
  });

  const parksOptions = (parks || []).map((item) => {
    return {
      value: item?.id,
      component: (
        <div className={classes.item}>
          <div className={classes.icon}>
            <ParkIcon />
          </div>
          <span>{item?.description}</span>
        </div>
      ),
    };
  });
  return [
    {
      size: 4,
      field: (
        <CheckListField
          id="wanted"
          label={<Typography className={classes.subtitle}>Wanted</Typography>}
          options={wantedZonesOptions}
          disabled={disabled}
          onAddElement={() => {
            form.change("parkIds", []);
          }}
        />
      ),
    },
    {
      size: 4,
      field: (
        <CheckListField
          id="unwanted"
          label={<Typography className={classes.subtitle}>Unwanted</Typography>}
          options={unwantedZonesOptions}
          disabled={disabled}
          onAddElement={() => {
            form.change("parkIds", []);
          }}
        />
      ),
    },
    {
      size: 4,
      field: (
        <CheckListField
          id="parkIds"
          label={<Typography className={classes.subtitle}>Parks</Typography>}
          options={parksOptions}
          disabled={disabled}
          onAddElement={() => {
            form.change("wanted", []);
            form.change("unwanted", []);
          }}
        />
      ),
    },
  ];
}
