import React from 'react';
import {
	getIcon,
	getIconDetails,
} from './assetInfos';
import {
	Tooltip,
	withStyles,
} from '@material-ui/core';
import { styles } from './AssetIcon.styles';

const AssetIcon = ({
	classes,
	assetStatus,
	assetTypeName,
	tooltip,
}) => {
	
	const Icon = getIcon(assetTypeName);
	const iconDetails = getIconDetails(assetStatus);

	const tooltipText = tooltip
		? iconDetails.title
		: "";

	return (
		<Tooltip
			className={classes.root}
			title={tooltipText}
			placement='left'
		>
			<div
				className={classes.icon}
				style={{backgroundColor: iconDetails.color}}
			>
				<Icon style={{color: '#fff'}} />
			</div>
		</Tooltip>
	);
};

export default withStyles(styles)(AssetIcon);
