import React from 'react';
import ListItem from '../../../../UI/List/ListItem/ListItem';
import {
	Typography,
	Tooltip,
	withStyles,
} from '@material-ui/core';
import {
	Block as BlockIcon,
} from '@material-ui/icons';
import styles from './CarflowsItem.styles';

const CarflowsItem = ({
	carflow,
	classes,
	fields,
	onSelect,
	selected,
}) => {

	const type = carflow.type && (
		<Typography className={classes.type} key={carflow.id + '_type'}>
			{carflow.type}
		</Typography>
	);

	const subtype = carflow.subtype && (
		<Typography className={classes.subtype} key={carflow.id + '_subtype'}>
			{carflow.subtype}
		</Typography>
	);

	const blocker = carflow.blocker
		?	<BlockIcon className={classes.blocker} key={carflow.id + '_blocker'}/>
		: (
			<div className={classes.blocker} key={carflow.id + '_blocker'}>
			</div>
		);

	const tooltip = !!carflow.description ? carflow.description : '';

	const currentCarflow = (
		<Tooltip placement='bottom' title={tooltip}>
			<div className={classes.root} key={carflow.id}>
				{blocker}
				{type}
				{subtype}
			</div>
		</Tooltip>
	);

	const item = (
		<ListItem
			fields={fields}
			onSelect={() => onSelect({...carflow})}
			element={{
				carflow: currentCarflow,
			}}
			selected={selected}
		/>
	);

	return item;
};

export default withStyles(styles)(CarflowsItem);