export const styles = theme => ({

	root: {
	},

	icon: {
		borderRadius: '50%',
		padding: '4px 5px',
	},
});
