
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import mapper from './mappers/parkMapper';
import { publishAndAwait } from '../../../app/coreSlice';

const upsertPark = async (data) => {


	const {
		code,
		name,
		description,
		geoLocation,
		latitude,
		longitude,
		maxSpots,
	} = data || {};

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'PARK',
			subType: 'CREATE_UPDATE',
			message: {
				parkCode: code,
				name,
				description,
				geoLocation,
				latitude,
				longitude,
				maxedSpots: maxSpots,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	};

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	};

	const park = processMessage && mapper.fromDTO({
		...processMessage,
		geoInformation: `[${processMessage.geoLocation}]`,
	});


	return park;
};

export default upsertPark;