import React from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Paper, Typography, Grid, Button } from "@material-ui/core";

import { styles } from "./AlertItem.styles";
import { useDateTime } from "../../../../../common/hooks";
import AlertItemIcon from "./AlertItemIcon/AlertItemIcon";
import ActionsDropDown from "../../../../../common/displays/ActionsDropDown/ActionsDropDown";

import { setSelectedAlert, selectSelectedAlert } from "../../../alertsSlice";

function AlertItem({
  classes,
  alert,
  onStatusChange,
  onSelectAsset,
  onSelectReservation,
  selectedLocationId,
  onSelectCustomer,
}) {
  const dispatch = useDispatch();
  const { toTimeZone } = useDateTime();
  const selectedAlert = useSelector(selectSelectedAlert);

  const alertState = alert.resolved ? "RESOLVED" : alert.user ? "ONGOING" : "PENDING";

  let alertData = undefined;
  try {
    alertData = JSON.parse(alert?.alertData);
  } catch (ex) {}

  if (!alertData) {
    console.log("ALERT WITHOUT DATA --- alert: %o", alert);
    return null;
  }

  // console.log("alert: %o", alert);
  // console.log("alertData: %o", alertData);

  const onClickHandler = () => {
    console.log("CLICK -> alertData?.category: %o", alertData?.category);
    const { assetToken, reservationCode, locationId, customerCode } = alertData?.payload;
    if (selectedLocationId !== locationId) {
      return;
    }
    dispatch(setSelectedAlert({ ...alert, ...alertData }));

    if (alertData?.category === "Device" || alertData?.category === "Reservation") {
      switch (alertData.type) {
        case "Driver Mismatch":
        case "Double Booking":
        case "Unauthorized use attempt":
          if (reservationCode) {
            onSelectReservation(reservationCode);
            return;
          }
          break;
        default:
          if (assetToken) {
            onSelectAsset(assetToken);
            return;
          }
      }
    }

    if (alertData?.category === "Billing" && reservationCode) {
      onSelectReservation(reservationCode);
      return;
    }

    if (alertData?.category === "Customer" && customerCode) {
      onSelectCustomer(customerCode);
      return;
    }
  };

  let message = alertData?.payload?.messageDescription;
  if (!message) {
    switch (alertData.type) {
      case "Unusual behavior":
        message = `Asset ${alertData?.payload?.licensePlate} ignition is on without an active reservation`;
        break;
      case "Device disconnected":
        message = `${alertData?.payload?.licensePlate} device is disconnected`;
        break;
      case "Unwanted zone":
        message = `${alertData?.payload?.licensePlate} ended in unwanted zone ${alertData?.payload?.zoneToken}`;
        break;
      case "Forbidden zone":
        message = `${alertData?.payload?.licensePlate} ended in forbbiden zone ${alertData?.payload?.zoneToken}`;
        break;
      case "Authorization failed":
        message = `Authorization failed while ${alertData?.payload?.licensePlate}'s trip is ongoing`;
        break;
      default:
        message = "(no message)";
        break;
    }
  }

  return (
    <Paper
      className={
        classes.root +
        (selectedLocationId !== alertData?.payload.locationId ? " " + classes.diferentLocation : "") +
        (selectedAlert?.alertToken === alert?.alertToken ? " " + classes.selected : "")
      }
      elevation={3}
      onClick={onClickHandler}
    >
      <Grid className={classes.container} container direction="row" wrap="nowrap" alignItems="center" spacing={1}>
        <Grid item>
          <AlertItemIcon
            category={alertData?.category}
            resolved={Boolean(alert?.resolved)}
            ongoing={Boolean(alert?.user)}
            user={alert?.user}
            priority={alertData?.priority}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <Grid container direction="column" className={classes.subcontainer} spacing={1}>
            <Grid item>
              <Typography align="left" className={classes.title}>
                {alertData.type}
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="left" className={classes.message}>
                {message}
              </Typography>
            </Grid>
            <ActionsDropDown className={classes.actions} listClassName={classes.actionsList} itemClickClose>
              {alertState !== "RESOLVED" && (
                <Button size="small" onClick={() => onStatusChange(alert.alertToken, "RESOLVED")}>
                  Mark as resolved
                </Button>
              )}
              {alertState !== "PENDING" && (
                <Button size="small" onClick={() => onStatusChange(alert.alertToken, "PENDING")}>
                  Mark as pending
                </Button>
              )}
              {alertState !== "ONGOING" && (
                <Button size="small" onClick={() => onStatusChange(alert.alertToken, "ONGOING")}>
                  Mark as ongoing
                </Button>
              )}
            </ActionsDropDown>
            <Typography align="right" className={classes.date}>
              {toTimeZone(alert?.createDate, "dateTime")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(styles)(AlertItem);
