import React from 'react';
import List from '../../UI/List/List';
import IncidentsItem from './IncidentsItem/IncidentsItem';
import {
	withStyles,
} from '@material-ui/core';
import styles from './IncidentsList.styles';
import classnames from 'classnames';

const IncidentsList = ({
	classes,
	className,
	getIncidents,
	incidentsArray,
	incidentsFields,
	onEdit,
	onSelect,
	selected,
}) => {

	const incidents = !!incidentsArray && incidentsArray.map(incident => (
		<IncidentsItem
			incident={incident}
			key={incident.incidentCode}
			onEdit={onEdit}
			onSelect={onSelect}
			selected={!!selected && selected.incidentCode === incident.incidentCode}
		/>
	));

	const list = (
		<List
			className={classnames(classes.root, className)}
			headerFields={incidentsFields}
			getMoreRows={getIncidents}
		>
			{incidents}
		</List>
	);

	return list;
};

export default withStyles(styles)(IncidentsList);