import React from 'react';
import {
	Modal as MUIModal,
	InputBase,
	Typography,
	withStyles,
} from '@material-ui/core';
import Button from '../../../../../../components/UI/Buttons/CommonButtons';
import styles from './Modal.styles';

const Modal = ({
	classes,
	setModal,
	nickname,
	setNickname,
	modal,
	hasRequest,
	onSave,
}) => {
	const title = (
		<Typography className={classes.title}>
			Choose a nickname
		</Typography>
	);

	const nicknameField = (
		<InputBase
			className={classes.text}
			onChange={event => setNickname(event.target.value)}
			placeholder='nickname'
			value={nickname || ''}
		/>
	);

	const createButton = (
		<Button
			disabled={!nickname || hasRequest}
			key='confirm_button'
			label='Confirm'
			onClick={() => onSave(nickname)}
		/>
	);

	return (
		<MUIModal
			onClose={() => setModal()}
			open={!!modal}
		>
			<div className={classes.root}>
				{title}
				{nicknameField}
				{createButton}
			</div>
		</MUIModal>
	);
};

export default withStyles(styles)(Modal);