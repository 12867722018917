import React, { useState } from "react";
import * as screenNames from "../../model/Carclub/carclubScreens";
import CarclubApp from "./CarclubApp";
import verticalMenuButtons from "./verticalMenuButtons";
import withPermission from "../../hoc/withPermission";
import { useSelector } from "react-redux";
import { selectDefaultLocation } from "../../features/carclub/carclubSlice";
import { selectUserDetails } from "../../features/user/userSlice";

const CarclubAppWrapper = (props) => {
  const defaultLocation = useSelector(selectDefaultLocation);
  const username = useSelector(selectUserDetails)?.userName;

  const [currentScreen, setCurrentScreen] = useState(screenNames.LOCAL_CONFIGURATIONS);

  return (
    <CarclubApp
      {...props}
			defaultLocation={defaultLocation}
      username={username}
      currentScreen={currentScreen}
      setCurrentScreen={setCurrentScreen}
      verticalMenuButtons={verticalMenuButtons}
    />
  );
};

const permissions = [{ resource: ["carclub"] }];

export default withPermission(permissions)(CarclubAppWrapper);
