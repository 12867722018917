import React from "react";
import Select from "../../../../../UI/Select/Select";
import { TextField, withStyles, Grid, Checkbox, Typography } from "@material-ui/core";
import styles from "./Fields.styles";

const Fields = ({ classes, className, fields, editMode }) => {
  const { types, subTypes, type, subType, subject, toAddress, setters, toSpecificMail, specificMailList } =
    fields || {};

  const { setTypeHandler, setSubTypeHandler, setSubject, setToAddress, setToSpecificMail, setSpecificMailList } =
    setters;

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Select
          name="Type"
          className={classes.selectionField}
          label="Type"
          array={types || []}
          onChange={setTypeHandler}
          value={type || ""}
          disabled={editMode}
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          name="Sub-Type"
          className={classes.selectionField}
          label="Sub-Type"
          array={subTypes || []}
          onChange={setSubTypeHandler}
          value={subType || ""}
          disabled={editMode || !subTypes}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          className={classes.textField}
          value={toAddress || ""}
          error={false}
          label="Bcc"
          onChange={(event) => setToAddress(event.target.value)}
        />
      </Grid>
      <Grid item xs={9}>
        <TextField
          className={classes.textField}
          value={subject || ""}
          error={false}
          label="Subject"
          onChange={(event) => setSubject(event.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <Grid container wrap="nowrap" spacing={1} alignItems="center">
          <Grid item>
            <Checkbox checked={Boolean(toSpecificMail)} onChange={() => setToSpecificMail(!toSpecificMail)} />
          </Grid>
          <Grid item>
            <Typography>Send email only to specific address</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        {Boolean(toSpecificMail) && (
          <TextField
            className={classes.textField}
            value={specificMailList || ""}
            error={false}
            label="Specific Emails"
            onChange={(event) => setSpecificMailList(event.target.value)}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Fields);
