import Task from '../../../../model/WorkOrder/Task';
import { getStatus } from '../../../../model/WorkOrder/workOrderStatus';
import moment from 'moment';

const fromDTO = ({
	comments,
	description,
	endLatitude,
	endLongitude,
	endTime,
	id,
	imageUrls,
	name,
	startLatitude,
	startLongitude,
	startTime,
	status,
	taskType,
	assignAgent,
	assignAgentName,
}) => new Task({
	code: id,
	comments,
	description,
	discarded: status === 'DISCARD' ? true : false,
	driver: assignAgent,
	driverName: assignAgentName,
	endDate: endTime,
	endLatitude,
	endLongitude,
	endTime,
	pictures: imageUrls,
	startDate: startTime,
	startLatitude,
	startLongitude,
	startTime,
	status: getStatus({ startDate: startTime, endDate: endTime, discarded: status === 'DISCARD' ? true : false}),
	type: taskType || name,
});

const toDTO = ({
	code,
	comments,
	description,
	driver,
	driverName,
	endDate,
	endLatitude,
	endLongitude,
	name,
	pictures,
	startDate,
	startLatitude,
	startLongitude,
	status,
	type,
}) => ({
	assignAgent: driver,
	assignAgentName: driverName,
	comments,
	description,
	endLatitude,
	endLongitude,
	endTime: endDate && moment(endDate).format(),
	id: code,
	imageUrls: pictures,
	name: type || name,
	startLatitude,
	startLongitude,
	startTime: startDate && moment(startDate).format(),
	status: status,
	taskType: type,
});

const taskMapper = { fromDTO, toDTO };

export default taskMapper;