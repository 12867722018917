export const styles = () => ({
  root: {
    width: "100%",
    minHeight: "21px",
  },

  contentRoot: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },

  contentLeft: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
  },

  gutters: {
    padding: "2px",
  },

  statusIcons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  time: {
    color: "#4C4C4E",
    fontSize: "11px",
    fontWeight: "400",
    minWidth: 55,
    textAlign: "left",
    lineHeight: "21px",
  },

  type: {
    color: "#4C4C4E",
    fontSize: "11px",
    fontWeight: "400",
    lineHeight: "21px",
    minWidth: 90,
    textAlign: "left",
    paddingRight: 4,
  },

  subType: {
    color: "#89898B",
    fontSize: "11px",
    fontWeight: "700",
    lineHeight: "21px",
    textAlign: "left",
    paddingRight: 4,
  },

  itemAvatar: {
    paddingLeft: 5,
    paddingRight: 5,
    minWidth: "21px",
  },

  redFill: {
    fill: "#FD2E25",
  },

  greenFill: {
    fill: "#00B67F",
  },

  text: {
    lineHeight: "21px",
  },
});
