import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPublicLinks } from "../../../services/S3";
import CustomerDetails from "./CustomerDetails";
import customerService from "../../../services/customer/ws";
import { customerPrincingPlans, selectSelectedCustomerPrincingPlans } from "../../../features/customer/customerSlice";

const CustomerDetailsWrapper = (props) => {
  const code = useMemo(() => props.details?.code, [props.details]);
  const dispatch = useDispatch();
  const pricingPlans = useSelector(selectSelectedCustomerPrincingPlans);

  const [documents, setDocuments] = useState();
  const [legalDocuments, setLegalDocuments] = useState();
  const [showFiles, setShowFiles] = useState([]);

  useEffect(() => {
    const getAttachments = async () => {
      try {
        const newDocuments = await customerService.getAttachments(code);

        setDocuments(newDocuments);
      } catch (e) {
        //TODO
        setDocuments();
      }
    };

    const getLegalDocuments = async () => {
      try {
        const newLegalDocuments = await customerService.getLegalDocuments(code);

        setLegalDocuments(newLegalDocuments);
      } catch (e) {
        //TODO
        setLegalDocuments();
      }
    };

    getAttachments();
    getLegalDocuments();
    setShowFiles([]);

    if (code) {
      dispatch(customerPrincingPlans({ customerCode: code }));
    }
    // eslint-disable-next-line
  }, [code]);

  const openLink = useCallback(async (link) => {
    try {
      const url = await getPublicLinks(link);

      window.open(url[0], "_blank");
    } catch (e) {
      //TODO
    }
  }, []);

  const setOpen = useCallback(
    (type) =>
      setShowFiles((state) => (state.includes(type) ? [...state.filter((curr) => type !== curr)] : [...state, type])),
    []
  );

  return (
    <CustomerDetails
      {...props}
      documents={documents}
      legalDocuments={legalDocuments}
      openLink={openLink}
      setOpen={setOpen}
      showFiles={showFiles}
      pricingPlans={pricingPlans}
    />
  );
};

export default CustomerDetailsWrapper;
