import locationService from "../../../services/location";

export const createOrUpdateLocation =
  (setLocations) => async (polygon, name, latitude, longitude, timezone, setError, stopDrawing, code) => {
    console.log(
      "polygon: %o, name: %o, latitude: %o, longitude: %o, timezone: %o, stopDrawing: %o, code: %o",
      polygon,
      name,
      latitude,
      longitude,
      timezone,
      stopDrawing,
      code
    );
    try {
      if (
        !Number.isFinite(parseFloat(latitude)) ||
        !Number.isFinite(parseFloat(longitude)) ||
        !name ||
        name.trim().getLength === 0
      ) {
        setError("Please make sure you have a name, latitude and longitude.");
        return;
      }

      const path = polygon.getPath();
      path.push(polygon.getPath().getAt(0));
      const points = path.getLength();
      let geoLocation = [];

      for (let i = 0; i < points; i++) {
        geoLocation = [...geoLocation, [path.getAt(i).lat(), path.getAt(i).lng()]];
      }

      geoLocation = geoLocation.join(" ").replace(/\s/g, "],[");
      geoLocation = `[${geoLocation}]`;

      const location = await (code
        ? locationService.updateLocation({ id: code, latitude, longitude, name, geoLocation, timezone })
        : locationService.createLocation({ latitude, longitude, geoLocation, name, timezone }));

      setLocations((prev) => ({
        ...prev,
        [location.code]: { ...location, geoInformation: "[" + geoLocation + "]" },
      }));
    } catch (error) {
      setError(`Could not ${code ? "update" : "create"} location`);
    }

    stopDrawing();
  };

export const removeLocation =
  (setLocations, setSelected, hideLocation, setError = () => {}) =>
  async (code) => {
    try {
      await locationService.removeLocation(code);

      setLocations((prev) => {
        const locations = { ...prev };
        delete locations[code];

        return locations;
      });
      hideLocation && hideLocation(code);
      setSelected && setSelected();
    } catch (error) {
      setError && setError(`Could not delete ${code} location`);
    }
  };
