import * as Yup from "yup";
import { DateField, TextField, NumberField, SwitchField, SelectField } from "../../../common/forms";

export function formSchema() {
  return Yup.object().shape({
    smallDescription: Yup.string().required("Name is required"),
    startDate: Yup.string().required("Start Date is required"),
    endDate: Yup.string().required("End Date is required"),
    maxUses: Yup.string().required("Max Uses is required"),
    // value: Yup.string().required("Amount is required"),
    // percentage: Yup.string().required("Discount is required"),
    value: Yup.string().when("type", {
      is: "AMOUNT",
      then: (schema) => schema.required("Amount is required"),
      otherwise: (schema) => schema,
    }),
    percentage: Yup.string().when("type", {
      is: "PERCENTAGE",
      then: (schema) => schema.required("Discount is required"),
      otherwise: (schema) => schema,
    }),
    description: Yup.string().required("Description is required"),
    details: Yup.string().required("Details is required"),
  });
}

export function formFields(values) {
  const typeOptions = [
    {
      value: "AMOUNT",
      text: "Amount",
    },
    {
      value: "PERCENTAGE",
      text: "Percentage",
    },
  ];
  return [
    {
      size: 12,
      field: <TextField id="smallDescription" type="text" label="Name" required />,
    },
    {
      size: 6,
      field: <DateField id="startDate" label="Start date" required />,
    },
    {
      size: 6,
      field: <DateField id="endDate" label="End date" required />,
    },
    {
      size: 4,
      field: <NumberField id="maxUses" label="Max usage" required decimalScale={0} />,
    },
    {
      size: 4,
      field: (
        <div style={{ marginTop: 3 }}>
          <SelectField id="type" label="Type" required options={typeOptions} />
        </div>
      ),
    },
    {
      size: 4,
      field: <NumberField id="value" label="Amount" required decimalScale={0} endAdornment="€" />,
      hidden: values.type !== "AMOUNT",
    },
    {
      size: 4,
      field: <NumberField id="percentage" label="Discount" required decimalScale={0} endAdornment="%" />,
      hidden: values.type !== "PERCENTAGE",
    },
    {
      size: 12,
      field: <TextField id="description" type="text" label="Description" required multiline rows={2} />,
    },
    {
      size: 12,
      field: <TextField id="details" type="text" label="Details" required multiline rows={5} />,
    },
    {
      size: 12,
      field: <SwitchField id="voucher" type="text" label="Promotion Code" />,
    },
    {
      size: 6,
      field: <TextField id="voucherCode" type="text" label="Code" required uppercase />,
      hidden: !values?.voucher,
    },
    {
      size: 6,
      field: <NumberField id="voucherUses" label="Usage quantity" required />,
      hidden: !values?.voucher,
    },
  ];
}

// smallDescription: "promotion1"
// startDate: "2022-07-30 23:00:00"
// endDate: "2022-08-31 22:59:00"
// maxUses: "55"
// value: "20"
// percentage: "5"

// description: "promotion description"
// details: "promotion details"

// voucherCode: "PR01"
// voucherUses: "5"

// cumulative: false
