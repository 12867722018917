import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import SearchList from "./SearchList";
import log from "../../../utils/logger";
import { useInfinityScroll } from "../../../hooks/useInfinityScroll/useInfinityScroll";

const logger = log("SearchList");

const SearchListWrapper = ({ getList, onLoad, data, onSearch, ...props }, ref) => {
  const { clearFilterListener } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [fields, setFields] = useState([]);
  const [lastPage, setLastPage] = useState(true);
  const [searchText, setSearchText] = useState();
  const listRef = useRef();

  useEffect(() => {
    if (!!clearFilterListener) setSearchText();
  }, [clearFilterListener]);

  useEffect(() => {
    const showListOnMount = async () => {
      try {
        const result = await getList({});

        setFields(Array.isArray(result) ? result : result.fields || []);
        typeof result?.lastPage === "boolean" && setLastPage(result.lastPage);
        setCurrentPage(1);
      } catch (e) {
        logger.warn(`onMount: ${e.description || e.message}`);
      }
    };
    onLoad && getList && showListOnMount();
  }, [onLoad, getList]);

  const onSearchCallback = useCallback(
    async (newRows) => {
      onSearch && onSearch(searchText);
      if (getList) {
        setLastPage(true);
        try {
          const result = await getList({
            string: searchText,
            page: newRows ? currentPage + 1 : 1,
          });

          setFields((prev) =>
            newRows ? [...prev, ...(result.fields || result || [])] : result?.fields || result || []
          );

          typeof result?.lastPage === "boolean" && setLastPage(result.lastPage);
          setCurrentPage((prev) => (newRows ? prev + 1 : 1));
        } catch (e) {
          setLastPage(false);
          logger.warn(`onSearch: ${e.description}`);
        }
      }
    },
    // eslint-disable-next-line
    [searchText, getList, currentPage]
  );

  const getMoreRows = useCallback(async () => {
    onSearchCallback(true);
  }, [onSearchCallback]);

  useInfinityScroll(!lastPage && getMoreRows, listRef);

  useImperativeHandle(ref, () => ({
    func: setFields,
  }));

  return (
    <SearchList
      {...props}
      fields={data || fields}
      listRef={listRef}
      onSearch={onSearchCallback}
      searchText={searchText}
      setSearchText={setSearchText}
    />
  );
};

export default forwardRef(SearchListWrapper);
