import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid, Divider } from "@material-ui/core";

import { styles } from "./SelectedDriver.styles";
import DriverIcon from "./DriverIcon/DriverIcon";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import CardIconTab from "../../../../common/displays/CardIconTab/CardIconTab";

function SelectedDriver({ classes, customer, account, onClick, disabled, selected }) {
  return (
    <CardIconTab
      icon={<DriverIcon empty={!Boolean(customer?.customerCode)} account={Boolean(account?.id)} />}
      onClick={onClick}
      disabled={disabled}
      selected={selected}
    >
      {customer?.customerCode ? (
        <Grid container direction="column">
          {account?.id && (
            <Grid item>
              <Typography className={classes.account}>{account.name}</Typography>
              <Divider />
            </Grid>
          )}
          <Grid item>
            <Typography className={classes.name}>{customer.fullName}</Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" wrap="nowrap">
              <Grid item>
                <EmailIcon className={classes.icon} />
              </Grid>
              <Grid item>
                <Typography className={classes.type}>{customer.email}</Typography>
              </Grid>
            </Grid>
          </Grid>
          {customer.phoneNumber && (
            <Grid item>
              <Grid container direction="row" wrap="nowrap">
                <Grid item>
                  <PhoneIcon className={classes.icon} />
                </Grid>
                <Grid item>
                  <Typography className={classes.type}>{customer.phoneNumber}</Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : (
        <Typography className={classes.type}>Select a driver</Typography>
      )}
    </CardIconTab>
  );
}

export default withStyles(styles)(SelectedDriver);
