import React from 'react';
import List from '../../UI/List/List';
import PricingPlanDetailItem from './PricingPlanDetailItem/PricingPlanDetailItem';
import CreatePricingPlanDetail from './CreatePricingPlanDetail/CreatePricingPlanDetailWrapper';
import ErrorDiv from '../../ErrorDiv/ErrorDiv';
import {
	Button,
	Drawer,
	Typography,
	withStyles,
} from '@material-ui/core';
import {
	Backspace as BackSaveIcon,
} from '@material-ui/icons';
import pricingPlanDetailFields from './pricingPlanDetailFields';
import withPermisssion from '../../../hoc/withPermission';
import { ReactComponent as CreateIcon } from '../../../assets/PromotionApp/icons/add.svg';
import styles from './PricingPlanDetailsView.styles';
import classnames from 'classnames';

const MODAL = {
	CREATE: 'CREATE',
	EDIT: 'EDIT',
};

const PricingPlanDetailsView = ({
	classes,
	className,
	closeHandler,
	currentScreenSize,
	error,
	handleBackSaveBtn,
	handleCancelChangesBtn,
	hasUnsavedChanges,
	modal,
	placeholder,
	placeholderSetters,
	selectedPricingPlan,
	selectedPricingPlanDetail,
	setHasUnsavedChanges,
	setModal,
	setSelectedPricingPlanDetail,
}) => {

	const {
		CREATE,
		EDIT,
	} = MODAL;

	const backOrSaveButton = (
		<Button
			classes={{ outlined: classes.backOrSaveBtn }}
			variant="outlined"
			color="default"
			onClick={handleBackSaveBtn}
		>
			<BackSaveIcon className={classes.backOrSaveIcon} />
			{hasUnsavedChanges ? 'Save changes' : 'Back'}
		</Button>
	);

	const cancelChangesButton = hasUnsavedChanges && (
		<Button
			classes={{ outlined: classes.backOrSaveBtn }}
			variant="outlined"
			color="default"
			onClick={() => {
				handleCancelChangesBtn();
			}}
		>
			{'Cancel'}
		</Button>
	);

	const CreateButton = CreateButtonWithPermission(!!selectedPricingPlan);
	
	const createIcon = (
		<CreateButton
			onClick={() => {
				setModal(CREATE);
				setSelectedPricingPlanDetail();
			}}
			className={classes.createIcon}
			size="small"
		/>
	);

	const errorBar = error && (
		<ErrorDiv
			className={{ div: classes.errorText }}
			message={error}
		/>
	);

	const actionDiv = (
		<div className={classes.actionDiv}>
			<div className={classes.leftSideActions}>
				{backOrSaveButton}
				{cancelChangesButton}
				<Typography >
					{selectedPricingPlan ? selectedPricingPlan.name : placeholder.name}
					<span className={classes.titleSuffix}>{' details'}</span>
				</Typography>
				{errorBar}
			</div>

			<div className={classes.rightSideActions}>
				{createIcon}
			</div>
		</div>
	);

	const pricingPlanDetails = placeholder
		&& placeholder.details
		&& placeholder.details.map((pricingPlanDetail, index) => (
			<PricingPlanDetailItem
				key={pricingPlanDetail.id || index + '-key'}
				pricingPlanDetail={pricingPlanDetail}
				openEditMode={() => {
					setSelectedPricingPlanDetail({...pricingPlanDetail, index});
					setModal(EDIT);
				}}
				deleteDetail={() => {
					setHasUnsavedChanges(true);
					placeholderSetters.removeDetail(index);
				}}
				isCreating={!selectedPricingPlan}
			/>
		));

	const createModal = (
		<Drawer
			anchor='right'
			onClose={() => setModal(null)}
			open={modal === CREATE || modal === EDIT}
		>
			<CreatePricingPlanDetail
				closeHandler={() => setModal(null)}
				isEditing={modal === EDIT}
				onBack={closeHandler}
				placeholder={placeholder}
				placeholderSetters={placeholderSetters}
				selectedPricingPlanDetail={selectedPricingPlanDetail}
				setHasUnsavedChanges={setHasUnsavedChanges}
			/>
		</Drawer>
	);

	const list = (
		<List
			className={classnames(classes.root, className)}
			headerFields={pricingPlanDetailFields(currentScreenSize)}
		>
			{pricingPlanDetails}
		</List>
	);

	return (
		<div className={classes.workspace}>
			{createModal}
			{actionDiv}
			<div className={classes.list}>
				{list}
			</div>
		</div>

	);
};

export default withStyles(styles)(PricingPlanDetailsView);

const CreateButtonWithPermission = isEditing => withPermisssion(
	[{ resource: ['pricingPlans', isEditing ? 'edit' : 'create']}],
)( props => <CreateIcon {...props} />);