const styles = theme => ({
	root: {
		width: '100%',
		height: '100%',
		overflowY: 'auto',
		scrollbarWidth: 'thin', //FIREFOX
		//CHROME
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},

	list: {
		listStyleType: 'none',
		margin: '15px',
		marginRight: 0,
	},

	listLine: {
		borderLeft: '1px dotted black',
	},

	mainList: {
		listStyleType: 'none',
		padding: '0 30px',
	},

	icon: {
		color: '#888',
	},

	iconCursor: {
		cursor: 'pointer',
	},

	listTitle: {
		display: 'flex',
		justifyContent: 'space-between',
	},

	all: {
		cursor: 'pointer',
		fontSize: '16px',
	},

	allIcon: {
		margin: '0 10px',
	},

	checkbox: {
		padding: '0px',
	},
});

export default styles;