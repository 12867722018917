import React from 'react';
import ListItem from '../../../../UI/List/ListItem/ListItem';
import {
	Tooltip,
	withStyles,
} from '@material-ui/core';
import styles from './MaxFlatRateItem.styles';
import {
	RemoveCircleOutline as RemoveIcon,
} from '@material-ui/icons';
import { currencyFormatter } from '../../../../../services/formatter';

export const modals = {
	CUSTOMER_EDIT_MODAL: 'CUSTOMER_EDIT_MODAL',
	ZONE_EDIT_MODAL: 'ZONE_EDIT_MODAL',
};

const MaxFlatRateItem = ({
	classes,
	fields,
	selected,
	rate,
	removeRate,
	viewOnly,
}) => {


	const removeIcon = (
		<Tooltip title='Add' placement='left'>
			<RemoveIcon
				onClick={() => removeRate(rate)}
				className={classes.icon}
			/>
		</Tooltip>
	);
	const editIcons = !viewOnly && (
		<div className={classes.icons}>
			{removeIcon}
		</div>
	);

	
	const maxPrice = !!rate.maxPrice
		&& currencyFormatter.format(rate.maxPrice) + ' ' + currencyFormatter.symbol();
	
	const maxTime = !!rate.maxTime
		&& rate.maxTime + ' min';

	const item = (
		<ListItem
			className={classes.root}
			selected={selected}
			fields={fields}
			onSelect={() => {

			}}
			element={{
				...rate,
				editIcons,
				maxTime,
				maxPrice,
			}}
		/>
	);

	return item;
};

export default withStyles(styles)(MaxFlatRateItem);