import { useEffect, useState } from 'react';

export const useFieldSorter = (array, elementId) => {

	const [windowSize, setWindowSize] = useState(window.innerWidth);

	const getDivSize = () => {
		elementId.current && setWindowSize(elementId.current.clientWidth);
	};

	window.addEventListener('resize', getDivSize);

	useEffect(() => {
		return () => window.removeEventListener('resize', getDivSize);
	});

	array.sort((current, next) => {
		
		if( current.pinned && !next.pinned ){
			return -1;
		};

		if( !current.pinned && next.pinned ){
			return 1;
		};
		
		return current.order - next.order;
	});

	const count = array.reduce((count, current) => {
		
		count.width += current.width;

		if (count.width > windowSize && !current.pinned){
			return count;
		};
		
		count.columns += 1;
		
		return count;
	}, {columns: 0, width: 0});

	const fieldsToShow = array.slice(0, count.columns);

	fieldsToShow.sort((current, next) => current.order - next.order);

	return fieldsToShow;
};