import React from "react";

import InvoiceConfigField from "./InvoiceConfigField/InvoiceConfigField";
import InvoiceConfigImageUpload from "./InvoiceConfigImageUpload/InvoiceConfigImageUpload";

import s3service from "../../../../services/S3";

export default function InvoiceConfigForm({
	classes,
	editMode,
	invoiceConfigurations,
	setInvoiceConfigurations,
}) {
	const onChangeHandler = (field, value) => {
		invoiceConfigurations[field] = value;
		let obj = {};
		switch (field) {
			case "vatPercent":
				obj[field] = parseInt(value) || 0;
				break;
			default:
				obj[field] = value;
		}
		setInvoiceConfigurations({ ...invoiceConfigurations, ...obj });
	};

	const onImageUploadHandler = async (file) => {
		const savedImage = await s3service.uploadImage(file);
		if (savedImage.error) {
			return;
		}
		const imageRequest = await s3service.saveImage(savedImage.keyname);

		if (imageRequest.error) {
			return;
		}
		let obj = {
			carclubInvoiceLogoUrl: imageRequest,
		};
		setInvoiceConfigurations({ ...invoiceConfigurations, ...obj });
	};

	const textInput = (label, field) => {
		return (
			invoiceConfigurations && (
				<InvoiceConfigField
					classes={classes}
					editMode={editMode}
					fieldLabel={label}
					field={field}
					value={invoiceConfigurations[field] || ""}
					onChangeHandler={onChangeHandler}
				/>
			)
		);
	};

	const imageUpload = (label, field) => {
		return (
			invoiceConfigurations && (
				<InvoiceConfigImageUpload
					classes={classes}
					editMode={editMode}
					fieldLabel={label}
					field={field}
					value={invoiceConfigurations[field] || ""}
					onChangeHandler={onChangeHandler}
					onImageUploadHandler={onImageUploadHandler}
				/>
			)
		);
	};

	return (
		<div>
			{imageUpload("Logo image", "carclubInvoiceLogoUrl")}
			{/* {textInput("Logo image", "carclubInvoiceLogoUrl")} */}
			{textInput("Invoice title", "header")}
			{textInput("Invoice number title", "invoiceNumberTitle")}
			<br />
			{textInput("Company extra information", "extraInfo")}
			{textInput("Vat designation", "vatTitle")}
			{textInput("To designation", "toTitle")}
			<br />
			{textInput("Invoice Date", "dateTitle")}
			{textInput("Payment terms title", "paymentTermsTitle")}
			{textInput("Payment terms Paid", "paymentTermsPaid")}
			{textInput("Payment terms Issued", "paymentTermsIssued")}
			{textInput("dueDateTitle", "dueDateTitle")}
			{textInput("balanceTitle", "balanceTitle")}
			<br />
			{textInput("Table header - Item", "itemHeader")}
			{textInput("Table header - Quantity", "quantityHeader")}
			{textInput("Table header - Unit Cost", "unitCostHeader")}
			{textInput("Table header - Amount", "amountHeader")}
			<br />
			{textInput("Reservation Title", "reservationTitle")}
			{textInput("Start Date Title", "startDateTitle")}
			{textInput("End Date Title", "endDateTitle")}
			{textInput("Time In Use Title", "timeInUseTitle")}
			{textInput("Time Stand By Title", "timeStandByTitle")}
			{textInput("Distance Title", "distanceTitle")}
			{textInput("Total Cost Title", "totalCostTitle")}
			{textInput("Included Vat Title", "includedVatTitle")}
			{textInput("Incident Title", "incidentTitle")}
			{textInput("Description Title", "descriptionTitle")}
			<br />
			{textInput("Total Title", "totalTitle")}
			{textInput("Amount Paid Title", "amountPaidTitle")}
			<br />
			{textInput("Notes Title", "notesTitle")}
			{textInput("Charged Title", "chargedTitle")}
			<br />
			{textInput("Currency", "currency")}
			{textInput("Vat Percent", "vatPercent")}
			<br />
			<br />
			<br />
			<br />
			<br />
		</div>
	);
}
