import React from "react";
import { Divider, List as MUIList, withStyles, Typography } from "@material-ui/core";

import Item from "./Item/ItemWrapper";
import styles from "./List.styles";

const headers = ["Item No.", "Amount", "No. Trips", "Start Date", "End Date", "Issue Date", "Due Date", "Payment Date"];

const List = ({
  billingItems,
  classes,
  listRef,
  onInvoice,
  onRemove,
  onSettle,
  setChargingModal,
  transactionsModalHandler,
  setError,
  setList,
}) => {
  const headerField = headerFieldCreate(classes);

  const headerFields = headers.map(headerField);

  const header = (
    <div className={classes.header}>
      <div className={classes.iconSpace} />
      {headerFields}
      <Typography className={classes.actions} aligh="right">
        Actions
      </Typography>
    </div>
  );

  console.log("billingItems: %o", billingItems);

  const listItems = (billingItems || []).map((billingItem) => (
    <Item
      billingItem={billingItem}
      key={billingItem.code}
      onInvoice={onInvoice}
      onRemove={onRemove}
      onSettle={onSettle}
      openChargingModal={setChargingModal}
      setError={setError}
      setList={setList}
      onOpenTransactions={() => transactionsModalHandler({ modal: true, billingItemCode: billingItem?.code })}
    />
  ));

  const list = (
    <MUIList className={classes.list} ref={listRef} disablePadding>
      {listItems}
    </MUIList>
  );

  return (
    <div className={classes.root}>
      {header}
      <Divider />
      {list}
    </div>
  );
};

export default withStyles(styles)(List);

const headerFieldCreate = (classes) => (field, index) =>
  (
    <Typography className={classes.headerField} key={`${field}_${index}`}>
      {field}
    </Typography>
  );
