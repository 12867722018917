const styles = () => ({
	root: {
		boxSizing: "border-box",
		display: "flex",
		flexDirection: "column",
		height: "100%",
		width: "100%",
		padding: "0 10px",
	},

	hidden: {
		display: "none",
	},

	titleBar: {
		alignItems: "center",
		display: "flex",
		justifyContent: "flex-start",
		width: "100%",
	},

	title: {
		fontSize: "18px",
		padding: "8px",
	},

	button: {
		margin: "10px",
	},

	disabled: {
		color: "#C5C5C5",
		cursor: "default",
	},

	labelProps: {
		// color: '#009AC9',
		color: "#47525E",
		zIndex: 999,
	},

	inputProps: {
		fontSize: 12,
	},

	label: {
		fontSize: "12px",
		marginBottom: "12px",
	},

	field: {
		fontSize: "11px",
		marginTop: 0,
		width: "100%",
	},

	fieldImageUpload: {
		fontSize: "11px",
		marginTop: 0,
		width: "100%",
		marginBottom: 10,
	},

	preview: {
		borderRadius: "0",
		margin: "0 40px",
		padding: "10px",
		fontSize: "10px",
		position: "fixed",
		right: 0,
		width: "calc(50vw - 130px)",
	},

	previewPic: {
		maxHeight: "100px",
	},

	previewTopLeftInfo: {
		marginLeft: "10px",
	},

	previewHeader: {
		fontSize: "18px",
	},

	previewDateTitle: {
		textAlign: "right",
		padding: "5px 0px",
	},

	previewDateTitleValue: {
		textAlign: "right",
		padding: "5px 10px",
		fontWeight: "bold",
	},

	previewBalanceTitle: {
		backgroundColor: "#EEE",
		padding: "5px 0px",
		textAlign: "right",
		fontWeight: "bold",
		fontSize: "11px",
	},

	previewBalanceTitleValue: {
		backgroundColor: "#EEE",
		padding: "5px 10px",
		textAlign: "right",
		fontWeight: "bold",
		fontSize: "11px",
	},

	previewTableHeader: {
		backgroundColor: "#3A3A3A",
		color: "#FFF",
		padding: "5px 10px",
		borderRadius: "5px",
	},

	previewTableItem: {
		padding: "5px 10px",
	},

	alignRight: {
		textAlign: "right",
	},

	alignRightGrey: {
		textAlign: "right",
		color: "#aaa",
	},

	grey: {
		color: "#aaa",
	},
});

export default styles;
