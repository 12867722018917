export const styles = theme => ({
  root: {
  },
  listItemRoot: {
    padding: 0,
  },
  accountSubListItem: {
    paddingLeft: 20,
    height: 18,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  accountFieldContainer: {
    maxWidth: 200,
    overflow: "auto",
    scrollbarWidth: "thin",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  accountIcon: {
    fill: "#009AC9",
    width: 18,
    height: 18,
    paddingRight: 6,
  },
  subListItemTypo: {
    fontSize: "10px",
  },
  checkButtons: {
    color: "#009AC9",
    cursor: "pointer",
    width: 18,
    height: 18,
    paddingRight: 15,
  },
});