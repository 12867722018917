import React, { useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid, Button } from "@material-ui/core";

import { styles } from "./DocumentForm.styles";
import { formFields, formSchema } from "./DocumentForm.schema";
import { Form, FormFields } from "../../../../../common/forms";
import { useDispatch } from "react-redux";
import s3Service from "../../../../../services/S3";
import { carclubInsertLegalDocument } from "../../../carclubSlice";

function DocumentForm({ classes }) {
  const dispatch = useDispatch();
  const [fileError, setFileError] = useState(false);

  const onSubmit = async (values) => {
    setFileError(false);
    const input = document.getElementById("uploaded_legal_document");
    const inputedFile = input?.files?.[0];

    if (typeof inputedFile === "undefined") {
      setFileError(true);
      return;
    }
    let s3File = await s3Service.uploadPublicFile(inputedFile);
    console.log("values: %o", { ...values, url: s3File.imageUrl });
    dispatch(carclubInsertLegalDocument({ ...values, url: s3File.imageUrl }));
  };

  return (
    <div className={classes.root}>
      <Form
        schema={formSchema()}
        initialValues={{}}
        onSubmit={onSubmit}
        keepDirtyOnReinitialize
        render={({ submitting, values }) => {
          return (
            <>
              <Grid container spacing={1} wrap="nowrap" direction="column">
                <Grid item>
                  <Typography variant="h5">Create New Document</Typography>
                </Grid>
                <Grid item>
                  <FormFields fields={formFields()} />
                </Grid>
                <Grid item>
                  <br />
                  <input
                    id="uploaded_legal_document"
                    className={classes.fileInput}
                    type="file"
                    accept=".pdf, .doc, .html"
                  />
                  <br />
                </Grid>
                <Grid item>{fileError && <Typography color="error">You must upload a file!</Typography>}</Grid>
                <Grid item>
                  <br />
                  <br />
                  <Button type="submit" fullWidth color="primary" variant="contained" disabled={submitting}>
                    Submit
                  </Button>
                </Grid>
                <Grid item>
                  <br />
                  <br />
                  <Typography>
                    Note: creating a document with the same name and type will addup to the version number
                  </Typography>
                </Grid>
              </Grid>
            </>
          );
        }}
      />
    </div>
  );
}

export default withStyles(styles)(DocumentForm);
