import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Accordion, AccordionDetails, AccordionSummary, Grid, List, ListItem, withStyles } from "@material-ui/core";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import { styles } from "./AssetListAccordion.styles";

import AssetItem from "../AssetItem/AssetItem";
import { selectAssets, selectSelectedAsset, selectSelectedAssetToken, setSelectedAssetToken } from "../../assetSlice";
import { operatorSetDefaultLoc, selectDefaultLocation } from "../../../carclub/carclubSlice";
import { selectUserLocations } from "../../../user/userSlice";
import { useEffect } from "react";
// import { useDispatch } from "react-redux";

function AssetListAccordion({ classes, onAssetClick }) {
  const dispatch = useDispatch();
  const assets = useSelector(selectAssets) || [];
  const selectedAssetToken = useSelector(selectSelectedAssetToken);
  const selectedAsset = useSelector(selectSelectedAsset);
  const selectedLocation = useSelector(selectDefaultLocation);
  const locations = useSelector(selectUserLocations) || [];

  // console.log("selectedLocation: %o - locations: %o", selectedLocation, locations);
  // console.log("assets: %o", assets);

  const onLocationChangeHandler = (locationId) => {
    dispatch(setSelectedAssetToken());
    dispatch(operatorSetDefaultLoc(locationId));
  };

  // Scroll to location accordion when selected location changes
  useEffect(() => {
    if (selectedLocation) {
      setTimeout(() => {
        document.getElementById("location-" + selectedLocation?.id)?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 500);
    }
    // eslint-disable-next-line
  }, [selectedLocation]);

  useEffect(() => {
    if (selectedAsset) {
      console.log("selectedAsset: %o", selectedAsset);
      if (selectedAsset?.locationId !== selectedLocation?.id) {
        dispatch(operatorSetDefaultLoc(selectedAsset?.locationId));
      }
      setTimeout(() => {
        document.getElementById("asset-" + selectedAsset?.assetToken)?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 500);
    }
    // eslint-disable-next-line
  }, [selectedAsset]);

  return (
    <div className={classes.root}>
      {locations.map((location) => {
        let locationAssets = assets.filter((el) => el.locationId === location.id);
        return (
          <Accordion
            square
            className={classes.accordion}
            key={location.id}
            expanded={selectedLocation?.id === location.id}
            onChange={() => onLocationChangeHandler(location.id)}
          >
            <AccordionSummary
              id={`location-${location.id}`}
              expandIcon={
                selectedLocation?.id === location.id ? (
                  <RemoveIcon fontSize="small" style={{ display: "block" }} />
                ) : (
                  <AddIcon fontSize="small" style={{ display: "block" }} />
                )
              }
            >
              <Grid container spacing={2} wrap="nowrap" alignItems="center">
                <Grid item>
                  <LocationCityIcon fontSize="small" style={{ display: "block" }} />
                </Grid>
                <Grid item>{`${location.name} (${locationAssets.length})`}</Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              {locationAssets.length === 0 ? (
                <Grid
                  container
                  spacing={2}
                  wrap="nowrap"
                  alignItems="center"
                  justifyContent="center"
                  className={classes.noAssets}
                >
                  <Grid item>
                    <DriveEtaIcon fontSize="small" style={{ display: "block" }} />
                  </Grid>
                  <Grid item>No assets</Grid>
                </Grid>
              ) : (
                <List disablePadding>
                  {(locationAssets || []).map((asset) => {
                    return (
                      <ListItem
                        disableGutters
                        className={classes.listItem}
                        key={asset.assetToken}
                        id={`asset-${asset.assetToken}`}
                      >
                        <AssetItem
                          asset={asset}
                          onClick={() => {
                            onAssetClick &&
                              onAssetClick({
                                isSelected: selectedAssetToken === asset.assetToken,
                                assetToken: asset.assetToken,
                              });
                          }}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}

export default withStyles(styles)(AssetListAccordion);
