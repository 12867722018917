import React from 'react';
import {
	Drawer,
	Tooltip,
	Typography,
	withStyles,
} from '@material-ui/core';
import styles from './Roles.styles.js';
import RolesList from './RolesList/RolesListWrapper';
import UpdateRole from './UpdateRole/UpdateRoleWrapper';
import RolesDetails from './RolesDetails/RolesDetails';
import Searchbar from '../../UI/Searchbar/Searchbar';
import {
	AddCircle as CreateIcon,
	RemoveCircle as DeleteIcon,
} from '@material-ui/icons';
import withPermission from '../../../hoc/withPermission';
import classnames from 'classnames';

const Roles = ({
	classes,
	currentScreenSize,
	filter,
	isEditing,
	list,
	locations,
	onDoubleClick,
	onRemove,
	onSearch,
	onSelect,
	onSingleClick,
	onUpdate,
	permissions,
	selected,
	setFilter,
	setIsEditing,
	setSelected,
}) => {

	const selectedRole = !!list && !!list.roles && list.roles[selected];

	const rolesList = !!list && (
		<RolesList
			currentScreenSize={currentScreenSize}
			lastPage={list.lastPage}
			onEdit={setIsEditing}
			onRemove={onRemove}
			onSearch={onSearch}
			onSelect={onSelect}
			onUpdate={onUpdate}
			permissions={permissions}
			roles={Object.values(list.roles || {})}
			selected={selected}
		/>
	);

	const title = (
		<Typography className={classes.title}>
			Roles
		</Typography>
	);

	const details = !!selected && (
		<RolesDetails
			role={selectedRole}
			locationsObj={locations}
		/>
	);

	const createButton = (
		<CreateButton
			classes={classes}
			onClick={() => {
				onSelect();
				setIsEditing(true);
			}}
		/>
	);

	const deleteButton = (
		<DeleteButton
			classes={classes}
			onClick={onRemove}
			disabled={!list || !list.roles || !list.roles[selected]}
		/>
	);

	const drawer = (
		<Drawer
			anchor='right'
			onClose={() => setIsEditing()}
			open={isEditing}
		>
			<div className={classes.drawer}>
				<UpdateRole
					onCancel={setIsEditing}
					onUpdate={onUpdate}
					permissions={permissions}
					role={selectedRole}
					setSelected={setSelected}
				/>
			</div>
		</Drawer>
	);

	const searchbar = (
		<div className={classes.searchbar}>
			{title}
			<Searchbar
				filter={filter}
				label='Search by designation'
				onDoubleClick={onDoubleClick}
				onSearch={() => onSearch(true)}
				onClick={onSingleClick}
				setFilter={setFilter}
			/>
			<div className={classes.create}>
				{createButton}
				{deleteButton}
			</div>
		</div>
	);

	return (
		<div className={classes.root}>
			<div className={classes.workspace}>
				{drawer}
				<div className={classes.list}>
					{searchbar}
					{rolesList}
				</div>
				<div className={classes.details}>
					{details}
				</div>
			</div>
		</div>
	);
};

const CreateButton = withPermission(
	[{ resource: ['carclub', 'roles', 'create'] }]
)(({
	classes,
	onClick,
}) =>
	(
		<Tooltip title="Add role">
			<CreateIcon
				className={classes.createButton}
				onClick={onClick}
			/>
		</Tooltip>
	)
);

const DeleteButton = withPermission(
	[{ resource: ['carclub', 'roles', 'edit'] }]
)(({
	classes,
	onClick,
	disabled,
}) =>
	(
		<Tooltip title={disabled ? '' : "Remove role"}>
			<DeleteIcon
				className={classnames(classes.createButton,
					disabled && classes.disabledButton)}
				onClick={disabled ? undefined : onClick}
			/>
		</Tooltip>
	)
);

export default withStyles(styles)(Roles);