import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const billingReviseCall = async (
  { updateSelectedTripBillingItem, updateSelectedTrip, updateSingleClosedReservation },
  dispatch,
  state,
  { reservationCode }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;

  let rh = requestHelper(dispatch, "BILLING/REVISE");
  try {
    const result = await wsCall({
      type: "BILLING",
      subType: "REVISE",
      locationId: selectedLocation?.id,
      message: {
        reservationCode,
      },
    });
    if (result) {
      if (result?.billing) {
        dispatch(setSuccess("This trip is set to be revised!"));
        dispatch(updateSelectedTripBillingItem(result.billing));
        dispatch(updateSelectedTrip({ paid: result.paid }));
        dispatch(updateSingleClosedReservation({ onHold: result?.billing.onHold, code: result.reservationCode }));
      }
    }
  } catch (_ex) {
    rh.error("Error on setting to revise.");
  }
  rh.close();
};

export default billingReviseCall;
