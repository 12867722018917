
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import notificationMapper from './mappers/notificationFlowMapper';
import { publishAndAwait } from '../../../app/coreSlice';

const listNotifications = async (filters) => {

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'CARCLUB',
			subType: 'MESSAGE_CARCLUB_LIST',
			message: {
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}
  console.log('MESSAGE_CARCLUB_LIST - processMessage: %o', processMessage);
	const notifications = processMessage.map(notification => notificationMapper.fromDTO(notification));
	return notifications;
};

export default listNotifications;