const styles = theme => ({

	root: {
		boxShadow: '0px 3px 6px #00000029',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		justifyContent: 'flex-start',
		minWidth: '0',
		width: '100%',
	},

	div: {
		alignItems: 'first baseline',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
	},

	headerDiv: {
		borderBottom: '1px solid #888',
	},

	list: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		overflowY: 'auto',
		width: '100%',
		padding: '0 15px',
		boxSizing: 'border-box',
	},

	title: {
		fontSize: '18px',
		margin: '10px 0 0 10px',
	},

	listItem: {
		width: 'auto',
		cursor: 'pointer',
		borderBottom: '1px solid #888',
		padding: '15px',
		'&:hover': {
			backgroundColor: '#eee',
		},
	},

	selected: {
		backgroundColor: '#eee',
	},
});

export default styles;