import { wsCall, requestHelper } from "../../../app/coreSlice";

const reportBatterySwapsCall = async (
  { setReportGeneratedModal, setNoDataModal },
  dispatch,
  state,
  { startDate, endDate, assets }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  if (!selectedLocation) {
    return;
  }

  let rh = requestHelper(dispatch, "REPORT/BATTERY_SWAPS");
  try {
    const result = await wsCall({
      type: "REPORT",
      subType: "BATTERY_SWAPS",
      locationId: selectedLocation?.id,
      message: {
        startDate, endDate, assets
      },
    });
    console.log('result: %o', result);
    if (result.noData) {
      dispatch(setNoDataModal(true));
    }
    if (result.generating) {
      dispatch(setReportGeneratedModal(true));
    }
  } catch (_ex) {
    rh.error("Error creating report");
  }
  rh.close();
};

export default reportBatterySwapsCall;
