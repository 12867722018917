
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_REQUEST_IS_MALFORMED,
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import extraMapper from './mappers/extraMapper';
import { publishAndAwait } from '../../../app/coreSlice';

export const upsertAdditionalFee = async ( extra ) => {

	const {
		name,
		description,
		value,
	} = extra || {};

	if (typeof name === 'undefined'
	|| typeof description === 'undefined'
	|| typeof value === 'undefined') {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	};

	const dto = extraMapper.toDTO(extra);

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'BILLING',
			subType: 'CREATE_UPDATE_ADDITIONAL_FEE',
			message: {
				...dto,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (error) {
		return;
	}

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}

	return extraMapper.fromDTO(processMessage) || null;
};

export default upsertAdditionalFee;