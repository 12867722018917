import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const customerUpdate_liteCall = async ({ customerNewList }, dispatch, state, customer) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "CUSTOMER/UPDATE");
  try {
    const customerUpdated = await wsCall({
      type: "CUSTOMER",
      subType: "UPDATE",
      locationId: selectedLocation?.id,
      message: customer,
    });
    if (!customerUpdated.error) {
      dispatch(setSuccess("Customer updated."));
      setTimeout(() => {
        dispatch(customerNewList({ reset: true }));
      }, 200);
    } else {
      rh.error(customerUpdated?.description || "Error updating customer.");
    }
  } catch (_ex) {
    rh.error("Error updating customer.");
  }
  rh.close();
};

export default customerUpdate_liteCall;
