import createModel from '../createModel';

function Account({
	accountNumber,
	activeNumber,
	associated,
	autoCharge,
	autoInvoice,
	city,
	code,
	createDate,
	createdBy,
	dueAmount,
	dueDays,
	email,
	enabled,
	id,
	invoiceDay,
	locationId,
	name,
	number,
	owner,
	ownerCode,
	phone,
	pricingName,
	sixMonthAvg,
	street,
	taxNumber,
	zipCode,
} = {}) {
	createModel.call(this, {
		accountNumber,
		activeNumber,
		associated,
		autoCharge,
		autoInvoice,
		city,
		code,
		createDate,
		createdBy,
		dueAmount,
		dueDays,
		email,
		enabled,
		id,
		invoiceDay,
		locationId,
		name,
		number,
		owner,
		ownerCode,
		phone,
		pricingName,
		sixMonthAvg,
		street,
		taxNumber,
		zipCode,
	});
}

export default Account;