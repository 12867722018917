export const styles = () => ({
  root: {},
  icon: {
    color: "white",
    borderRadius: "100%",
    padding: 1,
    display: "block",
  },
  text: {
    color: "#888",
    fontSize: 15,
  },
});
