import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import CustomersDataGrid from "./CustomersDataGrid/CustomersDataGrid";

import { styles } from "./CustomersList.styles";

import { customerUpdateLite, customerNewPasswordOperator, selectUI, setShowMoveDriverModal } from "../customerSlice";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../common/displays/Modal/Modal";
import MoveDriverForm from "./MoveDriverForm/MoveDriverForm";

function CustomersList({ classes, editCustomer, onSelect, openAccountDetailsScreen, toggleCustomer, updateCustomer }) {
  const dispatch = useDispatch();
  const uiState = useSelector(selectUI);

  const onAgentChangedHandler = (code, isAgent) => {
    //console.log("code: %o, isAgent: %o", code, isAgent);
    dispatch(
      customerUpdateLite({
        isAgent: !isAgent,
        customerCode: code,
        updateAgent: true,
      })
    );
    updateCustomer({
      isAgent: !isAgent,
      customerCode: code,
      updateAgent: true,
    });
  };

  const onResetPasswordHandler = (customerCode) => {
    dispatch(customerNewPasswordOperator({ customerCode }));
  };

  return (
    <div className={classes.root}>
      <CustomersDataGrid
        onRowSelected={(row) => onSelect(row.code)}
        onAccountDetails={(code) => {
          openAccountDetailsScreen(code);
        }}
        onAvailableChange={(code, available) =>
          toggleCustomer({
            available: !available,
            customerCode: code,
          })
        }
        onAgentChanged={onAgentChangedHandler}
        onOnboardChanged={(code, onboarded) =>
          toggleCustomer({
            onboarded: !onboarded,
            customerCode: code,
          })
        }
        onEdit={editCustomer}
        onResetPassword={onResetPasswordHandler}
      />
      <Modal
        style={{ height: 310, width: 360 }}
        sizeClass="small"
        open={uiState.showMoveDriverModal}
        setOpen={(value) => dispatch(setShowMoveDriverModal(value))}
      >
        <MoveDriverForm />
      </Modal>
    </div>
  );
}

export default withStyles(styles)(CustomersList);
