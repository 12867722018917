import React from "react";
import { Badge, Button, ClickAwayListener, MenuItem, MenuList, Popper, Tooltip, withStyles } from "@material-ui/core";
import styles from "./FilterButton.styles";
import classnames from "classnames";

const MultiFilterButton = ({
  classes,
  className,
  classNameMainBtn,
  button,
  open,
  anchorEl,
  handleOpen,
  handleClose,
  selected,
  doubleClickHandler,
  onSelect,
}) => {
  const badgeVisibility = !!selected && selected.length > 0;

  const title = [];

  const options = button.options.map((option) => {
    const isSelected = selected ? selected.includes(option && option.filter) : false;

    if (isSelected) {
      title.push(option.text);
    }

    const btn = !!option.icon && (
      <span className={classes.fuelFilters}>
        <option.icon style={{ color: option.color }} />
      </span>
    );

    return (
      <MenuItem
        key={option.text}
        onClick={() => onSelect(option)}
        className={classnames(isSelected && classes.grayBackground)}
      >
        {btn}
        {option.text}
      </MenuItem>
    );
  });

  const id = open ? "simple-popper" : null;

  return (
    <div className={classnames(classes.root, className)}>
      <Tooltip title={(badgeVisibility && title.join(", ")) || button.text}>
        <Button
          className={classnames(classes.filterButton, classNameMainBtn)}
          aria-describedby={id}
          onClick={(event) => (open ? handleClose() : handleOpen(event.currentTarget, button.text))}
          onDoubleClick={doubleClickHandler || undefined}
        >
          <Badge color="primary" overlap="rectangular" invisible={!badgeVisibility} variant="dot">
            <button.icon />
          </Badge>
        </Button>
      </Tooltip>
      <Popper className={classes.menu} id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClose}>
          <MenuList>{options}</MenuList>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default withStyles(styles)(MultiFilterButton);
