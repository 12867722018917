import createCustomReducer from "../createCustomReducer";

// Actions

const types = {
	setOn: 'ON',
	setOff: 'OFF',
	toggle: 'TOGGLE',
};

export const setOnAction = () => ({ type: types.setOn });
export const setOffAction = () => ({ type: types.setOff });
export const toggleAction = () => ({ type: types.toggle });

const actions = dispatch => ({
	setOn: () => dispatch(setOnAction()),
	setOff: () => dispatch(setOffAction()),
	toggle: () => dispatch(toggleAction()),
});

// Reducer

export const defaultState = false;

const setOnReducer = (state, action) => true;
const setOffReducer = (state, action) => false;
const toggleReducer = (state, action) => !state;

export const reducer = (state, action) => ({
	[types.setOn]: setOnReducer,
	[types.setOff]: setOffReducer,
	[types.toggle]: toggleReducer,
	undefined: () => state,
}[action.type](state, action));

export default createCustomReducer({ reducer, actions, defaultState });