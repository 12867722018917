const styles = theme => ({
	root: {
		width: '100%',
		height: '100%',
		marginTop: '20px',
		boxSizing: 'border-box',
		display: 'flex',
		justifyContent: 'flex-start',
		overflowX: 'auto',
		scrollbarWidth: 'thin', //FIREFOX

		'&::-webkit-scrollbar': {
			width: '5px',
			borderRadius: '21px',
		},

		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},

	list: {
		height: '100%',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		overflowY: 'auto',
		scrollbarWidth: 'thin', //FIREFOX

		'&::-webkit-scrollbar': {
			width: '5px',
			borderRadius: '21px',
		},

		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},

		padding: '0 10px',
		flex: 1,
	},

	title: {
		fontSize: '18px',
		textTransform: 'capitalize',
	},

	titleDiv: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	header: {
		fontWeight: 'bold',
		fontSize: '14px',
		flex: 1,
		padding: 5,
	},

	text: {
		fontSize: '14px',
		flex: 1,
		padding: 5,
	},

	row: {
		display: 'flex',
		justifyContent: 'flex-start',
		borderBottom: '1px solid #8885',
		alignItems: 'center',
		padding: 5,
		cursor: 'pointer',
	},

	listBorder: {
		borderLeft: '1px solid #8885',
		marginLeft: '5px',
	},

	sticky: {
		position: 'sticky',
		top: 'var(--top)',
		backgroundColor: '#fff',
	},

	icon: {
		color: '#888c',
		cursor: 'pointer',
	},

	selected: {
		backgroundColor: '#8885',
	},
});

export default styles;