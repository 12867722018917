import React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { TableCell, Typography } from "@material-ui/core";

export default function CustomDataGridHeaderItem({
  className,
  label,
  align,
  sort,
  onSort,
  hidden,
  width,
  headerComponent,
}) {
  const justifyContent =
    align === "left" ? "flex-start" : align === "right" ? "flex-end" : "center";

  return (
    <TableCell
      onClick={onSort}
      hidden={hidden}
      className={className}
      style={width && width > 0 ? { width } : {}}
    >
      <div style={{ display: "flex", justifyContent: justifyContent }}>
        <Typography variant="h6" component="label">
          {label}
        </Typography>
        {headerComponent}
        {sort === "DESC" && <ArrowDropDownIcon />}
        {sort === "ASC" && <ArrowDropUpIcon />}
      </div>
    </TableCell>
  );
}
