import AWS from "aws-sdk";
import log from "../../../utils/logger";
import uploadImage from "./uploadImage";
import saveImage from "./saveImage";
import { decrypt, hash } from "../../../common/utils";

class S3Service {
	constructor(callbacks = {}) {
		this.logger = log("S3Service");
		this.client = this.init;
		this.uploadPublicFile = (image) =>
			uploadImage(image, this.bucket, this.key, this.publicBucket, true);
		this.uploadImage = (image) =>
			uploadImage(image, this.bucket, this.key, this.bucketKey);
		this.saveImage = (keyname) => saveImage(keyname, this.bucketKey);
	}

	init = (secrets) => {
		const { app_bucket, public_bucket } = secrets || {};
		this.bucketKey = app_bucket;
		this.publicBucket = public_bucket;

		let credentials = {
			accessKeyId: secrets.key,
			secretAccessKey: secrets.secret,
		};

		const secretHash = window.sessionStorage.getItem(
			hash("secretHash")
		);

		if (!secrets) {
			try {
				Object.keys(credentials).forEach((e) => {
					const secretSauce = sessionStorage.getItem(
						hash(credentials[e])
					);
					const secret = decrypt(secretSauce, secretHash);
					credentials[e] = secret;
				});
			} catch (error) {
				this.logger.info(error);
			}
		}

		AWS.config.update(credentials);
		AWS.config.region = secrets.region;

		const s3init = {
			Bucket: secrets.app_bucket,
		};
		if (secrets.provider === "do") {
			s3init.endpoint = new AWS.Endpoint(
				secrets.region + ".digitaloceanspaces.com"
			);
		}
		const bucket = new AWS.S3(s3init);
		this.bucket = bucket;

		this.key = secrets.code;
	};
}

export default S3Service;
