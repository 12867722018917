import React from "react";
import { useSelector } from "react-redux";
import PaymentItem from "./PaymentItem/PaymentItem";
import * as CardType from "../../../../../model/Billing/CardType";
import {
  AUTHORIZATION,
  CAPTURE,
  PURCHASE,
  CHARGE_BALANCE,
  TOPUP_ACCOUNT,
  REFUND,
} from "../../../../../model/Billing/PaymentType";
import { withStyles, List, Grid } from "@material-ui/core";
import { styles } from "./PaymentList.styles";

import { selectIsCallsLoading } from "../../../../../app/coreSlice";
import { selectSelectedTrip, selectSelectedTripPaymentDetails } from "../../../reservationsSlice";
import ChargeButton from "./ChargeButton/ChargeButton";

const PAYMENT_STATUSES = {
  AUTHORIZATION: "AUTHORIZATION",
  CAPTURE: "CAPTURE",
  FAILED: "FAILED",
  NOT_REFUNDED: "NOT_REFUNDED",
  PENDING: "PENDING",
  PURCHASE: "PURCHASE",
  REFUNDED: "REFUNDED",
  SETTLED: "SETTLED",
};

const parsePaymentItemType = (payment) =>
  ({
    [AUTHORIZATION]: "Authorize",
    [CAPTURE]: "Capture",
    [PURCHASE]: "Purchase",
    [CHARGE_BALANCE]: "Balance",
    [TOPUP_ACCOUNT]: "Purchase",
  }[payment.type]);

const correctBalance = (payments) => {
  // this is not a pure function

  const balanceIndex = payments.findIndex((payment) => payment.type === CHARGE_BALANCE);

  if (balanceIndex < 0) {
    return;
  }

  const correctedPaymentBalance = payments
    .filter(
      (payment) =>
        !_isFailedPayment(payment) &&
        (payment.type === PAYMENT_STATUSES.PURCHASE || payment.type === PAYMENT_STATUSES.CAPTURE)
    )
    .reduce((sum, payment) => sum + payment.amount, 0);

  payments[balanceIndex].amount = payments[balanceIndex].amount - correctedPaymentBalance;

  if (payments[balanceIndex].amount === 0) {
    payments.splice(balanceIndex, 1);
  }
};

const _isFailedPayment = (payment) => {
  const { AUTHORIZATION, CAPTURED, FAILED, NOT_REFUNDED, PURCHASE, REFUNDED, SETTLED } = PAYMENT_STATUSES;

  const { type, status, failed } = payment;

  switch (type) {
    case AUTHORIZATION:
      if (status === FAILED || failed === true) {
        return true;
      }
      break;
    case CAPTURE:
      if (status === FAILED) {
        return true;
      }
      if (status === CAPTURED) {
        return false;
      }
      break;
    case PURCHASE:
      if (status === FAILED) {
        return true;
      }
      if (status === SETTLED) {
        return false;
      }
      break;
    case CHARGE_BALANCE:
      if (status === SETTLED) {
        return false;
      }
      break;
    case TOPUP_ACCOUNT:
      if (status === FAILED) {
        return true;
      }
      if (status === CAPTURE) {
        return false;
      }
      break;
    case REFUND:
      if (status === NOT_REFUNDED) {
        return true;
      }
      if (status === REFUNDED) {
        return false;
      }
      break;
    default:
      break;
  }
};

const PaymentList = ({ classes, payments, lastPayment }) => {
  const loading = useSelector(selectIsCallsLoading(["BILLING/CHARGE_ADVANCED"]));
  const paymentDetails = useSelector(selectSelectedTripPaymentDetails);
  const reservation = useSelector(selectSelectedTrip);

  const { chargedAmount, feeAmount, preAmount, paymentMethod, depositAmount, depositRefundAmount } = paymentDetails?.billingDetails?.billingItem || {};
  const { authorization } = paymentDetails?.billingDetails || {};
  const amountToCharge = (chargedAmount ? preAmount - chargedAmount : preAmount) + (feeAmount || 0) + depositAmount - depositRefundAmount;

  // We need to remove the Top up amount from the balance payment
  if (payments.length > 0 && reservation.state === "CLOSED" && paymentMethod === CardType.DEBIT_CARD) {
    correctBalance(payments);
  }

  const paymentItems =
    payments &&
    payments.map((payment) => {
      const type = payment && parsePaymentItemType(payment);

      if (payment.type === TOPUP_ACCOUNT && payment.status === PAYMENT_STATUSES.PENDING) return null;

      return (
        (type !== CHARGE_BALANCE || reservation.state === "CLOSED") && (
          <PaymentItem
            key={payment.paymentId}
            type={type || (payment && payment.type)}
            failed={_isFailedPayment(payment) && payment.type !== CHARGE_BALANCE}
            amount={payment.amount}
            cardNumber={payment.cardNumber}
            createDate={payment.createDate}
            failMessage={payment.failMessage}
          />
        )
      );
    });

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <List className={classes.root}>
          {paymentItems}
          {
            //FIXME: authorizationItem is not being used, it's comment on father
            authorization && (paymentMethod === CardType.CREDIT_CARD || !!authorization.creditCardNumber) && (
              <PaymentItem
                key="authorizationItem"
                type="Authorization"
                failed={_isFailedPayment(authorization)}
                amount={authorization.amount}
                cardNumber={authorization.creditCardNumber}
                createDate={authorization.createDate}
              />
            )
          }
        </List>
      </Grid>
      <Grid item style={{ textAlign: "center" }}>
        {reservation.state === "CLOSED" &&
          !reservation.groupId &&
          !paymentDetails?.billingDetails?.billingItem?.paid &&
          amountToCharge > 0 && (
            <ChargeButton
              loading={loading}
              reservationCode={reservation?.code}
              carToken={reservation?.carToken}
              amount={amountToCharge}
              creditCardLastDigits={lastPayment?.cardNumber}
            />
          )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(PaymentList);
