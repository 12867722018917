import React, { useEffect, useState, useCallback } from "react";
import { dateFormatter } from "../../services/formatter";
import useSimpleForm from "../../hooks/useSimpleForm/useSimpleForm";
import useList from "../../hooks/useList/useList";
import IncidentsApp from "./IncidentsApp";
import incidentsService from "../../services/incidents";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectDefaultLocation } from "../../features/carclub/carclubSlice";
import * as incidentsActions from '../../store/actions/incidents/action';

const dataTypes = {
  createDate: (a, b) => new Date(b.createDate) - new Date(a.createDate),
  lastModified: (a, b) => new Date(b.lastModified) - new Date(a.lastModified),
  incidentCode: (a, b) =>
    parseInt(b.incidentCode.replace(/[^0-9]/g, "")) - parseInt(a.incidentCode.replace(/[^0-9]/g, "")),
};

const dataOrderTypeTranslate = {
  createDate: "create_date",
  lastModified: "last_modified",
  incidentCode: "incident_code",
};

const initialFilter = {
  searchType: "incidentCode",
  string: "",
  incidentType: undefined,
  startDate: null,
  endDate: null,
  status: undefined,
};

const IncidentsAppWrapper = (props) => {
  const dispatch = useDispatch();
  const selectedLocation = useSelector(selectDefaultLocation);

  const getIncidents = (filters, clear) => dispatch(incidentsActions.getIncidents(filters, clear));

  const [sorting, setSorting] = useState({ orderBy: "createDate", asc: false });
  const [currentDrawer, setCurrentDrawer] = useState(undefined);
  const filter = useSimpleForm(initialFilter);
  const list = useList(dataTypes);
  const [incidentTypes, setIncidentTypes] = useState([]);

  const [error, setError] = useState(null);

  useEffect(() => {
    const getIncidentTypes = async () => {
      try {
        const newIncidentTypes = await incidentsService.getIncidentTypes();

        setIncidentTypes(newIncidentTypes);
      } catch (e) {
        //TODO
      }
    };

    getIncidentTypes();
  }, []);

  const { incidents, page } = props;

  const { setSelected } = list;

  const selectedIncident = list.state.selected && list.state.selected.incidentCode;

  //eslint-disable-next-line
  const sortList = useCallback(list.setList, []);

  useEffect(() => {
    getIncidents({ limit: 25, page: 1 }, true);
    //eslint-disable-next-line
  }, [selectedLocation]);

  useEffect(() => {
    sortList(incidents, sorting);
  }, [incidents, sorting, sortList]);

  useEffect(() => {
    if (!!list.state.list[0] && (!selectedIncident || !incidents[selectedIncident])) {
      list.setListWithSelected(incidents, sorting);
    }
  }, [selectedIncident, incidents, list, sorting]);

  const closeDrawer = () => setCurrentDrawer(undefined);
  const startCreate = () => setCurrentDrawer("create");
  const startEdit = () => setCurrentDrawer("update");

  const onSearch = (clean) => {
    const { endDate, incidentType, searchType, startDate, string } = filter.state;

    const status = computeStatus(filter.state.status);

    return getIncidents(
      {
        [searchType]: string.trim().length > 0 ? string : undefined,
        startDate: startDate !== null ? dateFormatter.format(startDate, "dateTimeRequest") : undefined,
        endDate: endDate !== null ? dateFormatter.format(endDate, "dateTimeRequest") : undefined,
        incidentType,
        asc: sorting.asc,
        orderBy: dataOrderTypeTranslate[sorting.orderBy],
        page: clean ? 1 : page + 1,
        limit: 25,
        ...status,
      },
      clean
    );
  };

  const onReset = () => {
    filter.reset();

    return getIncidents(
      {
        asc: sorting.asc,
        orderBy: dataOrderTypeTranslate[sorting.orderBy],
        page: 1,
        limit: 25,
      },
      true
    );
  };

  const onCharge = async (incident) => {
    try {
      await incidentsService.chargeIncident(incident.incidentCode);
      onSearch(true);
    } catch (ex) {
      setError(ex.message);
    }
  };

  return (
    <IncidentsApp
      {...props}
      closeDrawer={closeDrawer}
      currentDrawer={currentDrawer}
      filter={filter}
      incidents={list.state.list}
      onReset={onReset}
      onSearch={onSearch}
      onSort={(orderBy) =>
        orderBy === sorting.orderBy && !sorting.asc
          ? setSorting({ orderBy, asc: true })
          : setSorting({ orderBy, asc: false })
      }
      incidentTypes={incidentTypes}
      selectedIncident={!!selectedIncident && incidents[selectedIncident]}
      setCurrentDrawer={setCurrentDrawer}
      setSelectedIncident={setSelected}
      startCreate={startCreate}
      startEdit={startEdit}
      onCharge={onCharge}
      error={error}
      setError={setError}
    />
  );
};

export default IncidentsAppWrapper;

const computeStatus = (status) => {
  if (!status) return {};

  return {
    open: {
      closed: false,
      paid: false,
    },
    closed: {
      closed: true,
      paid: false,
    },
    paid: {
      paid: true,
    },
  }[status];
};
