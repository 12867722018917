import React from 'react';
import * as listItemTypes from './ListItemTypes';
import {
	ListItem as MUIListItem,
	withStyles,
} from '@material-ui/core';
import styles from './ListItem.styles';
import classnames from 'classnames';

const paddingCorrection = 10;

export const ListItemCalendarMemo = React.memo(
	props => ListItemCalendar({ ...props }));

const ListItemCalendar = ({
	classes,
	element,
	fields,
	onSelect,
	selected,
	calendarAddHandler,
	calendarSubtractHandler,
	hourPeriod,
	hourIndex,
	dragStartHandler,
	dragOverHandler,
	dragDropHandler,
	calendarRatesInfo,
}) => {
	let lastRow = element[fields[0].key] === '24';

	const row = fields.map(field => {

		let value = element[field.key];
		const isLabel = field.key === 'hourPeriod';

		if (isLabel) {
			value = parseLabelText(value);
			value = value.split(' ');
		};

		// parse BG color
		const numberBGcolor = !!calendarRatesInfo && calendarRatesInfo[value];

		const style = {
			minWidth: field.width ? field.width - paddingCorrection : undefined,
			width: field.width ? field.width - paddingCorrection : undefined,
			height: 18,//field.height ? field.height - paddingCorrection : undefined,
			flexDirection: field.flexDirection,
			justifyContent: isLabel ? 'space-evenly' : 'center',
			backgroundColor: isLabel ? '#F3F3F3' : numberBGcolor,
			cursor: 'pointer',
			'&:hover': {
				backgroundColor: 'rgb(100, 70, 70)',

			},
			boxSizing: 'border-box',
			borderRight: '1px solid rgba(0, 0, 0, 0.5)',
			borderError: '1px solid rgba(0, 0, 0, 0.5)',
		};

		return {
			[listItemTypes.TEXT]: (
				<span
					key={field.key}
					id={hourIndex + '_' + field.key}
					name={'calendar-cell'}
					style={style}
					className={classnames(classes.field)}
					onClick={(e) => {
						e.preventDefault();
						calendarAddHandler(hourIndex, field);
					}}
					onContextMenu={(e) => {
						e.preventDefault();
						calendarSubtractHandler(hourIndex, field);
					}}
					onDragStart={(e) => dragStartHandler(e, element[field.key])}
					onDragOver={(e) => dragOverHandler(e, hourIndex, field)}
					onDrop={(e) => dragDropHandler(e)}
					draggable={true}
				>
					{value}
				</span>
			),
			[listItemTypes.SPECIAL_TEXT]: (
				<div
					key={field.key}
					id={hourIndex + '_' + field.key}
					name={'calendar-cell'}
					style={style}
					className={classes.field}
					onClick={(e) => {
						e.preventDefault();
						calendarAddHandler(hourIndex, field);
					}}
					onContextMenu={(e) => {
						e.preventDefault();
						calendarSubtractHandler(hourIndex, field);
					}}
					onDragStart={(e) => dragStartHandler(e, element[field.key])}
					onDragOver={(e) => dragOverHandler(e, hourIndex, field)}
					onDrop={(e) => dragDropHandler(e)}
					draggable={true}
				>
					<span key={field.key + '1'}>
						{!!value && value[0]}
					</span>
					<span key={field.key + '2'}>
						{!!value && value[1]}
					</span>
					<span key={field.key + '3'}>
						{!!value && value[2]}
					</span>

				</div>
			),

			[listItemTypes.COMPONENT]: (
				<div key={field.key} style={style} className={classes.field}>
					{element[field.key]}
				</div>
			),

		}[field.type];
	});

	const content = (
		<div className={classes.calendarItemRoot}>
			{row}
		</div>
	);

	const listItem = (
		<MUIListItem
			dense
			onClick={onSelect ? onSelect : () => null}
			className={classnames(classes.calendarRow, selected && classes.selected,
				lastRow && classes.lastRow)}
		>
			{content}
		</MUIListItem>
	);

	return listItem;
};

const parseLabelText = (value) => {
	return labelMap[value];
};

const labelMap = {
	'1': '00:00 > 00:59',
	'2': '01:00 > 01:59',
	'3': '02:00 > 02:59',
	'4': '03:00 > 03:59',
	'5': '04:00 > 04:59',
	'6': '05:00 > 05:59',
	'7': '06:00 > 06:59',
	'8': '07:00 > 07:59',
	'9': '08:00 > 08:59',
	'10': '09:00 > 09:59',
	'11': '10:00 > 10:59',
	'12': '11:00 > 11:59',
	'13': '12:00 > 12:59',
	'14': '13:00 > 13:59',
	'15': '14:00 > 14:59',
	'16': '15:00 > 15:59',
	'17': '16:00 > 16:59',
	'18': '17:00 > 17:59',
	'19': '18:00 > 18:59',
	'20': '19:00 > 19:59',
	'21': '20:00 > 20:59',
	'22': '21:00 > 21:59',
	'23': '22:00 > 22:59',
	'24': '23:00 > 23:59',
};

export default withStyles(styles)(ListItemCalendarMemo);
