import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const billingChargeAdvancedCall = async (
  {
    setSelectedTripPaymentDetailsBillingPaid,
    updateSelectedTrip,
    updateSingleClosedReservation,
    reservationPaymentDetails,
  },
  dispatch,
  state,
  { reservationCode, carToken }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;

  let rh = requestHelper(dispatch, "BILLING/CHARGE_ADVANCED");
  try {
    const result = await wsCall({
      type: "BILLING",
      subType: "CHARGE_ADVANCED",
      locationId: selectedLocation?.id,
      message: {
        reservationCode,
        carToken,
      },
    });
    if (result) {
      console.log("result: %o", result);
      dispatch(setSuccess("Trip billing charged successfully!"));
      dispatch(setSelectedTripPaymentDetailsBillingPaid());
      dispatch(updateSelectedTrip({ paid: result.paid }));
      dispatch(updateSingleClosedReservation({ paid: result.paid, code: result.reservationCode }));
      dispatch(reservationPaymentDetails({ reservationCode }));
    }
  } catch (_ex) {
    rh.error("Error creating invoice.");
  }
  rh.close();
};

export default billingChargeAdvancedCall;
