import React from "react";
import { useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";

import { styles } from "./LegalDocumentsDataGrid.styles";
import { listColumns } from "./LegalDocumentsDataGrid.columns";
import LegalDocumentsDataGridActions from "./LegalDocumentsDataGrid.actions";

import { CustomDataGrid } from "../../../../../common/displays";
import { selectActiveLegalDocuments, selectLegalDocuments } from "../../../carclubSlice";
import { useEffect } from "react";
import { useDateTime } from "../../../../../common/hooks";

function LegalDocumentsDataGrid({ classes, setEnableApply, selectedDocuments, setSelectedDocuments }) {
  const data = useSelector(selectLegalDocuments);
  const activeDocuments = useSelector(selectActiveLegalDocuments);
  const { toTimeZone } = useDateTime();

  useEffect(() => {
    if (activeDocuments) {
      setSelectedDocuments(activeDocuments);
    }
    // eslint-disable-next-line
  }, [activeDocuments]);

  useEffect(() => {
    if (activeDocuments?.length !== selectedDocuments?.length) {
      setEnableApply(true);
    } else {
      let selectedIds = selectedDocuments.map((item) => item.id);
      let activeIds = activeDocuments.map((item) => item.id);
      setEnableApply(JSON.stringify(selectedIds) !== JSON.stringify(activeIds));
    }
    // eslint-disable-next-line
  }, [selectedDocuments]);

  const onDocumentCheckedChange = (checked, doc) => {
    if (checked) {
      let prevSelectedDocuments = selectedDocuments.filter((item) => item.name !== doc.name || item.type !== doc.type);
      setSelectedDocuments([...prevSelectedDocuments, doc]);
    } else {
      setSelectedDocuments([...selectedDocuments.filter((item) => item.id !== doc.id)]);
    }
  };

  const columns = listColumns(selectedDocuments, onDocumentCheckedChange, toTimeZone);

  return (
    <div className={classes.root}>
      {data && (
        <CustomDataGrid
          className={classes.datagrid}
          columns={columns}
          rows={data?.list}
          total={data?.lastPage ? data?.list.length + 1 : data?.list.length}
          actionsComponent={<LegalDocumentsDataGridActions classes={classes} />}
        />
      )}
    </div>
  );
}

export default withStyles(styles)(LegalDocumentsDataGrid);
