import React, { useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Button, Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";

import carActiveLogo from "../../../../../../assets/icons//car-play_40px.svg";
import distanceLogo from "../../../../../../assets/icons/distance_40px.svg";
import carPausedLogo from "../../../../../../assets/icons/car-pause_40px.svg";

import { styles } from "./EditTripDataButton.styles";
import Modal from "../../../../../../common/displays/Modal/Modal";
import { useDispatch } from "react-redux";
import { billingSet, billingSimulateAdvanced, selectSelectedTripReviseSimulation } from "../../../../reservationsSlice";
import DecimalInput from "../../../../../../common/inputs/DecimalInput";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useCurrency } from "../../../../../../common/hooks";

function EditTripDataButton({ classes, loading, reservationCode, carToken, billingItemCosts, preAmount }) {
  const [modal, setModal] = useState(false);
  const [priceMode, setPriceMode] = useState("data"); // data | price
  const dispatch = useDispatch();
  const { symbol, formatCurrency } = useCurrency();

  const [inUseHours, setInUseHours] = useState(0);
  const [inUseMinutes, setInUseMinutes] = useState(0);
  const [standbyHours, setStandbyHours] = useState(0);
  const [standbyMinutes, setStandbyMinutes] = useState(0);
  const [distance, setDistance] = useState(0);
  const [cost, setCost] = useState(0);
  const simulation = useSelector(selectSelectedTripReviseSimulation);

  useEffect(() => {
    // console.log("billingItemCosts: %o", billingItemCosts);
    let { inUseTime, standByTime, distance } = billingItemCosts || {};
    let inUseSeconds = Math.ceil(inUseTime / 1000);
    let inUseHours = Math.floor(inUseSeconds / 60 / 60);
    let inUseMinutes = Math.ceil((inUseSeconds - inUseHours * 60 * 60) / 60);
    setInUseHours(inUseHours);
    setInUseMinutes(inUseMinutes);
    let standBySeconds = Math.ceil(standByTime / 1000);
    let standByHours = Math.floor(standBySeconds / 60 / 60);
    let standByMinutes = Math.ceil((standBySeconds - standByHours * 60 * 60) / 60);
    setStandbyHours(standByHours);
    setStandbyMinutes(standByMinutes);
    setDistance(distance);
    setCost(preAmount);
    // eslint-disable-next-line
  }, [billingItemCosts]);

  useEffect(() => {
    if (simulation) {
      setCost(simulation?.totalCost);
    }
    // eslint-disable-next-line
  }, [simulation]);

  const validSimulation = Boolean(
    simulation &&
      (inUseHours * 60 + inUseMinutes) * 60000 === simulation.activeTime &&
      (standbyHours * 60 + standbyMinutes) * 60000 === simulation.idleTime &&
      distance === simulation.distanceTravelled
  );

  // console.log("simulation: %o, validSimulation: %o, standbyMinutes: %o", simulation, validSimulation, standbyMinutes);

  const inuseHourHandler = (value) => {
    setInUseHours(parseInt(value));
  };

  const inuseMinutesHandler = (value) => {
    let nValue = parseInt(value);
    if (nValue >= 60) {
      let hours = Math.floor(nValue / 60);
      setInUseHours(hours + inUseHours);
      setInUseMinutes(nValue - hours * 60);
    } else {
      setInUseMinutes(nValue);
    }
  };

  const standByHoursHandler = (value) => {
    setStandbyHours(parseInt(value));
  };

  const standByMinutesHandler = (value) => {
    let nValue = parseInt(value);
    if (nValue >= 60) {
      let hours = Math.floor(nValue / 60);
      setStandbyHours(hours + standbyHours);
      setStandbyMinutes(nValue - hours * 60);
    } else {
      setStandbyMinutes(nValue);
    }
  };

  const distanceHandler = (value) => {
    setDistance(parseInt(value));
  };

  const calculateCostHandler = () => {
    dispatch(
      billingSimulateAdvanced({
        reservationCode,
        carToken,
        activeTime: (inUseHours * 60 + inUseMinutes) * 60000,
        distanceTravelled: distance,
        idleTime: (standbyHours * 60 + standbyMinutes) * 60000,
      })
    );
  };

  const confirmCostHandler = () => {
    dispatch(
      billingSet({
        reservationCode,
        activeTime: (inUseHours * 60 + inUseMinutes) * 60000,
        distanceTravelled: distance,
        idleTime: (standbyHours * 60 + standbyMinutes) * 60000,
        totalCost: cost,
      })
    );
  };

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        variant="outlined"
        color="primary"
        size="small"
        disabled={loading}
        onClick={() => setModal(true)}
      >
        Edit Trip Data
      </Button>
      <Modal open={modal} setOpen={setModal} sizeClass="sm">
        <div style={{ height: "100%" }}>
          <Grid
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="stretch"
            style={{ height: "100%" }}
          >
            <Grid item>
              <Typography align="center" variant="h5">
                Edit Trip Data
              </Typography>
            </Grid>
            <Grid item style={{ alignSelf: "flex-start" }}>
              <FormControlLabel
                control={
                  <Checkbox checked={priceMode === "data"} onChange={() => setPriceMode("data")} color="primary" />
                }
                label="Edit the data of the trip"
              />
              <Typography>
                Please, edit the in use time, distance and stand by time for this trip. By doing so, you'll generate a
                new price that will appear below.
              </Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox checked={priceMode === "price"} onChange={() => setPriceMode("price")} color="primary" />
                }
                label="Edit the total price of the trip"
              />
              <Typography>
                Please, edit the total price of the trip. After you confirm, an invoice will be generated on this new
                cost and with the data of the trip appearing above.
              </Typography>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={8} wrap="nowrap">
                <Grid item>
                  <Typography>Time in use</Typography>
                  <Grid container direction="row" spacing={1} wrap="nowrap">
                    <Grid item>
                      <img className={classes.image} src={carActiveLogo} height="30px" alt="in use" />
                    </Grid>
                    <Grid item>
                      <DecimalInput
                        value={inUseHours}
                        endAdornment="h"
                        label=""
                        onChange={inuseHourHandler}
                        decimalScale={0}
                        disabled={Boolean(priceMode === "price")}
                      />
                    </Grid>
                    <Grid item>
                      <DecimalInput
                        value={inUseMinutes}
                        endAdornment="m"
                        label=""
                        onChange={inuseMinutesHandler}
                        decimalScale={0}
                        disabled={Boolean(priceMode === "price")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography>Distance</Typography>
                  <Grid container direction="row" spacing={1} wrap="nowrap">
                    <Grid item>
                      <img className={classes.image} src={distanceLogo} height="30px" alt="distance" />
                    </Grid>
                    <Grid item>
                      <DecimalInput
                        value={distance}
                        endAdornment="km"
                        label=""
                        onChange={distanceHandler}
                        disabled={Boolean(priceMode === "price")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography>Time in standby</Typography>
                  <Grid container direction="row" spacing={1} wrap="nowrap">
                    <Grid item>
                      <img className={classes.image} src={carPausedLogo} height="30px" alt="in standby" />
                    </Grid>
                    <Grid item>
                      <DecimalInput
                        value={standbyHours}
                        endAdornment="h"
                        label=""
                        onChange={standByHoursHandler}
                        decimalScale={0}
                        disabled={Boolean(priceMode === "price")}
                      />
                    </Grid>
                    <Grid item>
                      <DecimalInput
                        value={standbyMinutes}
                        endAdornment="m"
                        label=""
                        onChange={standByMinutesHandler}
                        decimalScale={0}
                        disabled={Boolean(priceMode === "price")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="space-evenly">
                <Grid item>
                  <DecimalInput
                    value={cost}
                    endAdornment={symbol}
                    label="Total Cost"
                    decimalScale={2}
                    disabled={Boolean(loading || priceMode === "data")}
                    onChange={(val) => setCost(parseFloat(val))}
                  />
                </Grid>
                <Grid item>
                  <div className={classes.prevValue}>
                    <Typography>Previous Value:</Typography>
                    <Typography>{formatCurrency(preAmount)}</Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction="row" wrap="nowrap" justifyContent="space-evenly">
                <Grid item>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={Boolean(loading || priceMode === "price" || validSimulation)}
                    onClick={calculateCostHandler}
                  >
                    Calculate Cost
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={Boolean(loading || (priceMode === "data" && !validSimulation))}
                    onClick={confirmCostHandler}
                  >
                    Confirm trip data
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}

export default withStyles(styles)(EditTripDataButton);
