const styles = () => ({

	root: {
		padding: '5px 0 10px',
	},

	title: {
		color: 'rgba(64,149,194,1)',
		fontSize: '16px',
		margin: '5px 0 5px',
	},

	titleDiv: {
		display: 'flex',
		width: '100%',
	},
});

export default styles;