import { wsCall, requestHelper } from "../../../app/coreSlice";

const assetMakeAvailableCall = async ({ updateAssetOnList }, dispatch, state, { carToken }) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "ASSET/MAKE_AVAILABLE");
  try {
    const asset = await wsCall({
      type: "ASSET",
      subType: "MAKE_AVAILABLE",
      locationId: selectedLocation?.id,
      message: {
        carToken,
      },
    });
    dispatch(updateAssetOnList({ ...asset, assetToken: asset.carToken }));
  } catch (_ex) {
    rh.error("Error making asset available.");
  }
  rh.close();
};

export default assetMakeAvailableCall;
