import {
  createStore,
  applyMiddleware,
  // compose,
} from "redux";
import thunk from "redux-thunk";
import { combineReducers } from "redux-immutable";
import { Map, Record } from "immutable";

import coreReducer from "../app/coreSlice";
import websocketReducer from "../app/websocket/websocketSlice";

import { RESET_ROOT } from "./actions/root/actionTypes";
import accountReducer from "./reducers/account/reducer";

import assetReducer from "./reducers/asset/reducer";
import authorizationReducer from "./reducers/authorization/reducer";

import carclubReducer from "./reducers/carclub/reducer";
import carclubReducer2 from "../features/carclub/carclubSlice";
import chatReducer from "./reducers/chat/reducer";
import customerReducer from "./reducers/customer/reducer";
import incidentsReducer from "./reducers/incidents/reducer";
import invoiceReducer from "./reducers/invoice/reducer";
import notificationReducer from "./reducers/notification/reducer";
import notificationReducer2 from "../features/notification/notificationSlice";
import parksReducer from "./reducers/parks/reducer";

import storeAppReducer from "./reducers/app/reducer";

import customerReducer2 from "../features/customer/customerSlice";
import deviceReducer from "../features/device/deviceSlice";
import userReducer from "../features/user/userSlice";
import bigdataReducer from "../features/bigdata/bigdataSlice";
import alertsReducer from "../features/alerts/alertsSlice";
import assetsReducer from "../features/asset/assetSlice";
import damagereportReducer from "../features/damagereport/damagereportSlice";
import reservationsReducer from "../features/reservation/reservationsSlice";
import promotionsReducer from "../features/promotions/promotionsSlice";
import dashboardReducer from "../features/dashboard/dashboardSlice";
import princingplansReducer from "../features/princingplans/princingplansSlice"
import reportsReducer from "../features/reports/reportsSlice";

import remoteConfigsReducer from "../appRemoteConfigurations/Store/Reducers/remoteConfigsReducer";

const initialState = Map();

const stateRecord = Record({
  core: null,
  websocket: null,
  account: null,
  // alert: null,
  app: null,
  asset: null,
  // auth: null,
  authorization: null,
  carclub: null,
  carclub2: null,
  chatReducer: null,
  customer: null,
  customer2: null,
  damageReport: null,
  incidents: null,
  invoice: null,
  notification: null,
  notification2: null,
  parks: null,
  trip: null,
  // websocket: null,
  device: null,
  user: null,
  bigdata: null,
  alerts: null,
  assets: null,
  damagereport: null,
  reservations: null,
  promotions: null,
  dashboard: null,
  princingplans: null,
  reports: null,
});

const appReducer = combineReducers(
  {
    core: coreReducer,
    websocket: websocketReducer,
    account: accountReducer,
    // alert: alertReducer,
    app: storeAppReducer,
    asset: assetReducer,
    // auth: authReducer,
    authorization: authorizationReducer,
    carclub: carclubReducer,
    carclub2: carclubReducer2,
    chat: chatReducer,
    customer: customerReducer,
    customer2: customerReducer2,
    incidents: incidentsReducer,
    invoice: invoiceReducer,
    notification: notificationReducer,
    notification2: notificationReducer2,
    parks: parksReducer,
    remoteConfigsReducer: remoteConfigsReducer,
    device: deviceReducer,
    user: userReducer,
    bigdata: bigdataReducer,
    alerts: alertsReducer,
    assets: assetsReducer,
    damagereport: damagereportReducer,
    reservations: reservationsReducer,
    promotions: promotionsReducer,
    dashboard: dashboardReducer,
    princingplans: princingplansReducer,
    reports: reportsReducer,
  },
  stateRecord
);

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === RESET_ROOT) {
    state = initialState;
  }

  return appReducer(state, action);
};

// To enable redux devtool on browser
// const composeEnhancers = process.env.NODE_ENV !== 'production'
// 	? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
// 	: compose;

const store = createStore(
  rootReducer,
  initialState,
  // composeEnhancers(applyMiddleware(thunk/* , taskMiddleware */))
  applyMiddleware(thunk /* , taskMiddleware */)
);

export default store;
