import React from 'react';
import ListItem from '../../../../../UI/List/ListItem/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import styles from './TransactionItem.styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { currencyFormatter, dateFormatter } from '../../../../../../services/formatter';

const TransactionItem = ({
	classes,
	detail,
	fields,
	onShowSettleDescription,
	hasDescription,
}) => {

	const amount = detail.amount
		&& `${currencyFormatter.format(detail.amount)} ${currencyFormatter.symbol()}`;

	const date = detail.createDatetime
		&& dateFormatter.format(detail.createDatetime, 'dateTime');

	const actions = hasDescription && (
		<div className={classes.actions}>
			<Tooltip title='Settle desciption' placement='left'>
				<InfoIcon
					className={classes.icon}
					onClick={onShowSettleDescription}
				/>
			</Tooltip>

		</div>
	);

	const item = (
		<ListItem
			fields={fields}
			element={{
				...detail,
				amount,
				date,
				actions,
			}}
		/>
	);

	return item;
};

export default withStyles(styles)(TransactionItem);