export const styles = (theme) => ({
  root: {
    height: "100vh",
    position: "relative",
    backgroundColor: "white",
  },

  workspace: {
    width: "100%",
    display: "inline-flex",
    flexWrap: "nowrap",
    paddingTop: 50,
    height: "calc(100vh - 50px)",
  },

  app: {
    width: "100%",
  },

  notificationInfo: {
    backgroundColor: theme.palette.primary.main,
  },

  notificationError: {
    backgroundColor: theme.palette.error.main,
  },

  alertApp: {
    position: "fixed",
    right: 0,
    zIndex: 101,
    boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.12)",
    // overflowY: 'auto',
    // overflowX: 'hidden',
  },

  smallWindowWidth: {
    width: "100vw",
    height: "100vh",
    position: "absolute",
    backgroundColor: "#4095C2",
    color: "White",
    overflow: "auto",
  },

  smallWindowWidthText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
});
