import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";
import { assetAssetReservationCounts, assetList } from "../../asset/assetSlice";

const reservationActivateSubscriptionBoCall = async (
  { setSelectedTrip, updateSingleOngoingReservation },
  dispatch,
  state,
  { reservationCode, customerCode, carToken, deviceToken },
  oldActions
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  const selectedTrip = state?.getIn(["reservations"])?.selectedTrip;
  let rh = requestHelper(dispatch, "RESERVATION/ACTIVATE_SUBSCRIPTION_BO");
  try {
    const result = await wsCall({
      type: "RESERVATION",
      subType: "ACTIVATE_SUBSCRIPTION_BO",
      locationId: selectedLocation?.id,
      message: {
        reservationCode,
        customerToken: customerCode,
        customerCode,
        carToken,
        deviceToken,
      },
    });

    if (!result.error) {
      dispatch(setSuccess("Reservation activated successfully!"));
      let nReservation = { ...selectedTrip, ...(result?.reservation || {}) };
      dispatch(setSelectedTrip(nReservation));
      oldActions.update(result?.reservation);
      dispatch(updateSingleOngoingReservation(nReservation));
      setTimeout(() => {
        // this should be update using notifications
        dispatch(assetList({}));
        dispatch(assetAssetReservationCounts());
      }, 300);
      // refresh reservations
    } else {
      rh.error(result?.description || "Error activating reservation.");
    }
  } catch (_ex) {
    rh.error("Error creating reservation.");
  }
  rh.close();
};

export default reservationActivateSubscriptionBoCall;
