import React, { useState, useEffect, useCallback } from "react";
import UserSettings from "./UserSettings";
import usePasswordReducer from "./ChangePassword/usePasswordReducer";
import useChangePassword from "./ChangePassword/useChangePassword";
import customerService from "../../../services/customer/ws";
import log from "../../../utils/logger";

const logger = log("ChangePersonalInfoWrapper");

//Code is not editable but is required to update the user
const editableFields = ["email", "mobile", "fullName", "code"];
const userFieldsToCheck = ["email", "mobile", "fullName"];

const initialState = {
	focusedConfig: null,
	name: null,
	email: null,
};

const UserSettingsWrapper = (props) => {
	const { onCloseModal } = props;

	const [placeholder, setPlaceholder] = useState(initialState);
	const [hasNotification, setHasNotification] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [errorMessage, setErrorMessage] = useState();

	const currentPass = usePasswordReducer();
	const confirmPass = usePasswordReducer();
	const newPass = usePasswordReducer();

	const { changePassword, message, error: errorPassword } = useChangePassword();
	const passwordsAreDifferent = newPass.state !== confirmPass.state;

	const handleNotification = useCallback(
		(errorMsg, closeAfter) => {
			const notificationTimeout = 2500;
			setErrorMessage(errorMsg);
			setHasNotification(true);
			setTimeout(() => {
				setHasNotification(false);
				if (!!closeAfter) {
					onCloseModal();
				}
			}, notificationTimeout);
			setTimeout(() => {
				setIsSuccess(false);
			}, notificationTimeout * 2);
		},
		[onCloseModal]
	);

	useEffect(() => {
		if (errorPassword === true) {
			handleNotification(message);
		}
		if (errorPassword === false) {
			handleNotification(message);
			setHasNotification(true);
		}
	}, [errorPassword, message, handleNotification]);

	const { code, email, mobile, fullName } = placeholder;

	useEffect(() => {
		const asyncGet = async () => {
			try {
				const result = await customerService.getUser();
				editableFields.forEach((el) => {
					onChangeHandler(el, result[el]);
				});
			} catch (error) {
				logger.warn("Error while getting user info.");
			}
		};
		asyncGet();
	}, []);

	const checkIfUserWantsToChangePassword = () => {
		try {
			const a = currentPass?.state.split("").length;
			const b = confirmPass?.state.split("").length;
			const c = newPass?.state.split("").length;
			return a > 0 && b > 0 && c > 0;
		} catch (error) {
			logger.warn("Error checking if user want to change password");
		}
	};

	const checkPersonalInfoForMissingFields = () => {
		const missingField = userFieldsToCheck.find((field) => {
			if (
				typeof placeholder?.[field] === "undefined" ||
				placeholder?.[field].split("").length < 1
			) {
				return true;
			}
			return false;
		});

		if (!!missingField) {
			return true;
		}
		return false;
	};

	const onChangeHandler = (key, value) => {
		setPlaceholder((prev) => ({ ...prev, [key]: value }));
	};

	const onSubmitPasswordHandler = async () => {
		//PASSWORD
		if (checkIfUserWantsToChangePassword()) {
			if (newPass?.state !== confirmPass?.state) {
				setIsSuccess(false);
				handleNotification("New passwords must patch");
				return;
			}

			const { error, message } = await changePassword(
				currentPass.state,
				newPass.state
			);

			//ERROR
			if (!!error) {
				setIsSuccess(false);
				handleNotification(message);
				return;
			}
			//SUCCESS
			setIsSuccess(true);
			handleNotification(message);
			currentPass.set("");
			confirmPass.set("");
			newPass.set("");
		}
	};

	const onSubmitHandler = async () => {
		try {
			setIsSuccess();

			//PERSONAL DETAILS
			if (checkPersonalInfoForMissingFields()) {
				handleNotification("Missing required fields.");
				return;
			}

			const result = await customerService.updateCustomer({
				customerCode: code,
				email,
				phoneNumber: mobile,
				fullName,
			});
			editableFields.forEach((el) => {
				onChangeHandler(el, result[el]);
			});
			setIsSuccess(true);
			handleNotification("Successfully edited!", true);
		} catch (error) {
			logger.warn("Error while updating user info.");
			handleNotification("Error while updating user info.");
		}
	};

	return (
		<UserSettings
			placeholder={placeholder}
			onChangeHandler={onChangeHandler}
			errorPassword={errorPassword}
			messagePassword={message}
			onConfirmPassChange={confirmPass.set}
			onCurrentPassChange={currentPass.set}
			onNewPassChange={newPass.set}
			passwordsAreDifferent={passwordsAreDifferent}
			onSubmitChanges={onSubmitHandler}
			onSubmitPasswordChanges={onSubmitPasswordHandler}
			hasNotification={hasNotification}
			errorMessage={errorMessage}
			isSuccess={isSuccess}
		/>
	);
};

export default UserSettingsWrapper;
