const styles = (theme) => ({

	root: {
		height: '100%',
	},

	header: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingBottom: theme.spacing(1),
	},

	list: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},

	details: {
		minWidth: '284px',
		maxWidth: '284px',
		paddingRight: 21,
		overflowY: 'auto',
	},

	titleAndSearch: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	selectedRateDiv: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		borderBottom: '2px solid rgba(0, 0, 0, 0.20)',
		marginBottom: '8px',
		paddingBottom: '8px',
	},

	title: {
		fontSize: '22px',
		color: '#47525E',
		marginRight: 16,
	},

	searchBar: {
		width: '200px',
		display: 'inline-flex',
		paddingBottom: 4,
	},

	searchIcon: {
		margin: '10px 0',
	},

	createIcon: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		width: 26,
		height: 26,
	},

	crossIcon: {
		cursor: 'pointer',
	},

	inputRoot: {
		color: 'inherit',
		width: '100%',
	},

	inputInput: {
		paddingTop: theme.spacing(1),
		paddingRight: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		transition: theme.transitions.create('width'),
		width: '100%',
		borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
	},
});

export default styles;