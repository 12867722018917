import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Field } from "react-final-form";
import MetaError from "../MetaError";
import { styles } from "./_base.styles";
import { CheckboxInput } from "../../inputs";

function CheckboxField({ classes, id, label, disabled, required }) {
  const onChangeHandler = (v, input) => {
    input.onChange && input.onChange(v);
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => (
          <>
            <CheckboxInput
              label={label}
              value={Boolean(input.value)}
              className={classes.input}
              onChange={(value) => onChangeHandler(value, input)}
              disabled={disabled}
              required={required}
              error={Boolean(meta.error && meta.touched)}
            />
            {meta.error && meta.touched && (
              <div className={"FormFieldError " + classes.error}>
                <MetaError meta={meta} />
              </div>
            )}
          </>
        )}
      </Field>
    </div>
  );
}

export default withStyles(styles)(CheckboxField);
