const styles = theme => ({
	root: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		maxWidth: '550px',
		minWidth: '550px',
		padding: '15px',
		overflow: 'auto',
		scrollbarWidth: 'thin',
		'&::-webkit-scrollbar': {
			width: '1px',
		},

		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)', 
		},
	},

	title: {
		fontSize: '20px',
	},

	inputRoot: {
		color: 'inherit',
		width: '100%',
	},

	formroot: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: '100%',
	},

	inputDiv: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		margin: '10px 0',
		width: '100%',
	},

	description: {
		borderBottom: 'none',
	},

	inputRootform: {
		color: 'inherit',
		width: '100px',
	},

	inputText: {
		borderBottom: '1px solid gray',
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(2),
		transition: theme.transitions.create('width'),
		width: '100%',
	},

	inputInput: {
		borderBottom: '1px solid gray',
		padding: 0,
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		transition: theme.transitions.create('width'),
		width: '100%',
	},

	inputError: {
		borderBottom: '1px solid red',
	},

	selectEmpty: {
		marginBottom: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(2),
		maxWidth: '150px',
		minWidth: '120px',
	},

	buttons: {
		alignItems: 'flex-end',
		display: 'flex',
		flexGrow: '1',
		justifyContent: 'space-around',
		padding: '5% 0',
	},

	icon: {
		alignSelf: 'center',
		cursor: 'pointer',
		fill: '#009AC9',
		height: '15px',
		width: '15px',
	},

	attachmentItem: {
		alignItems: 'center',
		color: '#009AC9',
		display: 'flex',
		justifyContent: 'space-between',
		padding: '10px 0',
		width: '100%',
	},

	attachmentItemBorder: {
		borderTop: '1px solid rgba(112, 112, 112, 0.4)',
	},

	attachmentInput: {
		color: '#009AC9',
		cursor: 'pointer',
		margin: '10px 0',
		width: '110px',
	},

	attachmentsDiv: {
		overflow: 'auto',
		minHeight: '90px',
		scrollbarWidth: 'thin',
		'&::-webkit-scrollbar': {
			width: '1px',
		},

		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)', 
		},
	},

	input: {
		display: 'none',
	},

	error: {
		fontSize: '14px',
		color: 'red',
	},

	confirmationField: {
		flex: 1,
	},

	confirmationLabel: {
		fontSize: '12px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '15px',
	},

	confirmationTitle: {
		fontSize: '16px',
	},

	header: {
		boxSizing: 'border-box',
		display: 'flex',
		gap: '10%',
		justifyContent: 'space-between',
		margin: '10px',
	},

	capitalize: {
		textTransform: 'capitalize',
	},
});

export default styles;