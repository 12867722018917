import { createSlice } from "@reduxjs/toolkit";
import { selectIsCallsLoading } from "../../app/coreSlice";
import { fromDTO } from "../../services/trip/ws/mappers/tripMapper";
import billingChargeAdvancedCall from "./_sliceCalls/billingChargeAdvanced";
import billingInvoiceSingleTripCall from "./_sliceCalls/billingInvoiceSingleTrip";
import billingReviseCall from "./_sliceCalls/billingRevise";
import billingSetCall from "./_sliceCalls/billingSet";
import billingSettleCall from "./_sliceCalls/billingSettle";
import billingSimulateAdvancedCall from "./_sliceCalls/billingSimulateAdvanced";
import billingToBillCall from "./_sliceCalls/billingToBill";
import crAccountsRetrieveAccountsUserCall from "./_sliceCalls/crAccountsRetrieveAccountsUser";
import crAssetListNotInReservationCall from "./_sliceCalls/crAssetListNotInReservation";
import crCustomerListCall from "./_sliceCalls/crCustomerList";
import crParkGetAssetsCall from "./_sliceCalls/crParkGetAssets";
import crParkListCall from "./_sliceCalls/crParkList";
import reservationActivateAdvancedBoCall from "./_sliceCalls/reservationActivateAdvancedBo";
import reservationClosedListCall from "./_sliceCalls/reservationClosedList";
import reservationNewReservationCancelBoCall from "./_sliceCalls/reservationNewReservationCancelBo";
import reservationNewReservationCloseBoCall from "./_sliceCalls/reservationNewReservationCloseBo";
import reservationNewReservationCreateBoCall from "./_sliceCalls/reservationNewReservationCreateBo";
import reservationNewReservationParkoffBoCall from "./_sliceCalls/reservationNewReservationParkoffBo";
import reservationNewReservationParkonBoCall from "./_sliceCalls/reservationNewReservationParkonBo";
import reservationNewReservationUpdateCall from "./_sliceCalls/reservationNewReservationUpdate";
import reservationOngoingListCall from "./_sliceCalls/reservationOngoingList";
import reservationPaymentDetailsCall from "./_sliceCalls/reservationPaymentDetails";
import reservationRequestReservationExtensionCall from "./_sliceCalls/reservationRequestReservationExtension";
import reservationActivateSubscriptionBoCall from "./_sliceCalls/reservationActivateSubscriptionBoCall";
import billingRefundAdvancedCall from "./_sliceCalls/billingRefundAdvancedCall";

export const ongoingReservationsDefaultFilter = {
  fromDate: undefined,
  toDate: undefined,
  page: 1,
  states: undefined,
  filterType: "TRIP", // TRIP, CUSTOMER
  search: "",
  onlyWarnings: false,
};

export const closedReservationsDefaultFilter = {
  fromDate: undefined,
  toDate: undefined,
  page: 1,
  states: undefined,
  filterType: "TRIP", // TRIP, CUSTOMER
  search: "",
  onlyWarnings: false,
  advanced: undefined,
};

export const reservationsSlice = createSlice({
  name: "reservations",
  initialState: {
    selectedTrip: {},
    selectedTripPaymentDetails: {},
    selectedTripReviseSimulation: null,
    ongoingReservations: {
      list: [],
      filter: {
        ...ongoingReservationsDefaultFilter,
      },
      lastPage: false,
    },
    closedReservations: {
      list: [],
      filter: {
        ...closedReservationsDefaultFilter,
      },
      lastPage: false,
    },
    crParks: [],
    crAssets: [],
    crCustomerAccounts: [],
    crCustomers: [],
  },
  reducers: {
    setSelectedTrip: (state, action) => {
      state.selectedTrip = action.payload;
    },
    setOngoingReservationsFilter: (state, action) => {
      state.ongoingReservations.filter = {
        page: state.ongoingReservations.filter?.page,
        filterType: state.ongoingReservations.filter?.filterType,
        ...action.payload,
      };
    },
    clearOngoingReservations: (state) => {
      state.ongoingReservations.list = [];
      state.ongoingReservations.lastPage = [];
      state.ongoingReservations.filter = { ...state.ongoingReservations.filter, page: 1 };
      state.selectedTrip = {}; // reset selected trip on load
    },
    setOngoingReservations: (state, action) => {
      // to remove dependency from this mapping, and use original object
      let nList = [
        ...state.ongoingReservations.list,
        ...(action.payload?.resultList || []).map((trip) => fromDTO(trip)),
      ];
      nList.sort((a, b) => ("" + b.code).localeCompare(a.code));
      state.ongoingReservations.list = nList;
      state.ongoingReservations.lastPage = action.payload?.lastPage;
    },
    updateSingleClosedReservation: (state, action) => {
      let nReservations = [];
      state.closedReservations.list.forEach((item) => {
        if (item.code === action.payload?.code) {
          nReservations.push({
            ...item,
            ...action.payload,
          });
        } else {
          nReservations.push(item);
        }
      });
      state.closedReservations.list = nReservations;
    },
    updateSingleOngoingReservation: (state, action) => {
      console.log("§§§ updateSingleOngoingReservation - action.payload: %o", action.payload);
      let nReservations = [];
      let found = false;
      state.ongoingReservations.list.forEach((item) => {
        if (item.code === action.payload?.code) {
          found = true;
          nReservations.push({
            ...item,
            ...action.payload,
          });
        } else {
          nReservations.push(item);
        }
      });
      if (found) {
        state.ongoingReservations.list = nReservations;
      } else {
        state.ongoingReservations.list = [fromDTO(action.payload), ...nReservations];
      }
    },
    removeSingleOngoingReservation: (state, action) => {
      let nReservations = [];
      state.ongoingReservations.list.forEach((item) => {
        if (item.code !== action.payload?.code) {
          nReservations.push(item);
        }
      });
      state.ongoingReservations.list = nReservations;
      if (state.selectedTrip?.code === action.payload?.code) {
        state.selectedTrip = {};
      }
    },
    setClosedReservationsFilter: (state, action) => {
      state.closedReservations.filter = {
        page: state.closedReservations.filter?.page,
        filterType: state.closedReservations.filter?.filterType,
        ...action.payload,
      };
    },
    clearClosedReservations: (state) => {
      state.closedReservations.list = [];
      state.closedReservations.lastPage = [];
      state.closedReservations.filter = { ...state.closedReservations.filter, page: 1 };
      state.selectedTrip = {}; // reset selected trip on load
    },
    setClosedReservations: (state, action) => {
      // to remove dependency from this mapping, and use original object
      let nList = [
        ...state.closedReservations.list,
        ...(action.payload?.resultList || []).map((trip) => fromDTO(trip)),
      ];
      nList.sort((a, b) => ("" + b.code).localeCompare(a.code));
      state.closedReservations.list = nList;
      state.closedReservations.lastPage = action.payload?.lastPage;
    },
    setCrParks: (state, action) => {
      state.crParks = action.payload;
    },
    setCrAssets: (state, action) => {
      state.crAssets = action.payload;
    },
    setCrCustomerAccounts: (state, action) => {
      state.crCustomerAccounts = action.payload;
    },
    setCrCustomers: (state, action) => {
      state.crCustomers = action.payload;
    },
    setSelectedTripPaymentDetails: (state, action) => {
      state.selectedTripPaymentDetails = action.payload;
    },
    setSelectedTripPaymentDetailsInvoice: (state, action) => {
      if (state.selectedTripPaymentDetails?.billingDetails) {
        state.selectedTripPaymentDetails.billingDetails.invoices = [action.payload.invoice];
      }
    },
    setSelectedTripPaymentDetailsBillingPaid: (state) => {
      if (state.selectedTripPaymentDetails?.billingDetails?.billingItem) {
        state.selectedTripPaymentDetails.billingDetails.billingItem.paid = true;
      }
    },
    updateSelectedTripBillingItem: (state, action) => {
      if (state.selectedTripPaymentDetails?.billingDetails?.billingItem) {
        state.selectedTripPaymentDetails.billingDetails.billingItem = {
          ...state.selectedTripPaymentDetails.billingDetails.billingItem,
          ...action.payload,
        };
      }
    },
    updateSelectedTrip: (state, action) => {
      state.selectedTrip = { ...state.selectedTrip, ...action.payload };
    },
    setSelectedTripReviseSimulation: (state, action) => {
      state.selectedTripReviseSimulation = action.payload;
    },
  },
});

export const {
  setSelectedTrip,
  setOngoingReservationsFilter,
  setOngoingReservations,
  clearOngoingReservations,
  updateSingleOngoingReservation,
  setClosedReservationsFilter,
  clearClosedReservations,
  setClosedReservations,
  setCrCustomerAccounts,
  setSelectedTripPaymentDetails,
  setCrAssets,
  removeSingleOngoingReservation,
} = reservationsSlice.actions;

export default reservationsSlice.reducer;

// SELECTS

export const selectIsLoadingTrips = selectIsCallsLoading([
  "RESERVATION/RESERVATION_ONGOING_LIST",
  "RESERVATION/RESERVATION_ONGOING_LIST_NOC",
  "RESERVATION/RESERVATION_CLOSED_LIST",
  "RESERVATION/RESERVATION_CLOSED_LIST_NOC",
]);
export const selectSelectedTrip = (state) => state.getIn(["reservations"])?.selectedTrip;
export const selectSelectedTripPaymentDetails = (state) => state.getIn(["reservations"])?.selectedTripPaymentDetails;
export const selectSelectedTripReviseSimulation = (state) =>
  state.getIn(["reservations"])?.selectedTripReviseSimulation;
export const selectActionIsLoading = selectIsCallsLoading([
  "RESERVATION/ACTIVATE_ADVANCE_BO",
  "RESERVATION/ACTIVATE_SUBSCRIPTION_BO",
  "RESERVATION/NEW_RESERVATION_CANCEL_BO",
  "RESERVATION/NEW_RESERVATION_CLOSE_BO",
  "RESERVATION/NEW_RESERVATION_PARKOFF_BO",
  "RESERVATION/NEW_RESERVATION_PARKON_BO",
  "RESERVATION/REQUEST_RESERVATION_EXTENSION",
]);
export const selectOngoingReservationsFilter = (state) => state.getIn(["reservations"])?.ongoingReservations.filter;
export const selectOngoingReservations = (state) => state.getIn(["reservations"])?.ongoingReservations;
export const selectClosedReservationsFilter = (state) => state.getIn(["reservations"])?.closedReservations.filter;
export const selectClosedReservations = (state) => state.getIn(["reservations"])?.closedReservations;

export const selectCrParks = (state) => state.getIn(["reservations"])?.crParks;
export const selectCrAssets = (state) => state.getIn(["reservations"])?.crAssets;
export const selectCrCustomers = (state) => state.getIn(["reservations"])?.crCustomers;
export const selectCrCustomerAccounts = (state) => state.getIn(["reservations"])?.crCustomerAccounts;
export const selectCreateLoading = selectIsCallsLoading([
  "RESERVATION/NEW_RESERVATION_CREATE_BO",
  "RESERVATION/NEW_RESERVATION_UPDATE",
]);

// CALLS

export const reservationNewReservationCreateBo =
  ({ accountId, carToken, customerCode, parkId, startDate, endDate, additionalFeesList }) =>
    (dispatch, getState) => {
      reservationNewReservationCreateBoCall(dispatch, getState(), {
        accountId,
        carToken,
        customerCode,
        parkId,
        startDate,
        endDate,
        additionalFeesList,
      });
    };

export const reservationNewReservationUpdate =
  ({ reservationCode, carToken, parkId, startDate, endDate }) =>
    (dispatch, getState) => {
      reservationNewReservationUpdateCall(dispatch, getState(), {
        reservationCode,
        carToken,
        parkId,
        startDate,
        endDate,
      });
    };

export const reservationNewReservationCancelBo =
  ({ reservationCode, carToken }, oldActions) =>
    (dispatch, getState) => {
      reservationNewReservationCancelBoCall(
        { ...reservationsSlice.actions, reservationOngoingList },
        dispatch,
        getState(),
        { reservationCode, carToken },
        oldActions
      );
    };

export const reservationActivateAdvancedBo =
  ({ reservationCode, customerCode, carToken, deviceToken }, oldActions) =>
    (dispatch, getState) => {
      reservationActivateAdvancedBoCall(
        reservationsSlice.actions,
        dispatch,
        getState(),
        { reservationCode, customerCode, carToken, deviceToken },
        oldActions
      );
    };

export const reservationActivateSubscriptionBo =
  ({ reservationCode, customerCode, carToken, deviceToken }, oldActions) =>
    (dispatch, getState) => {
      reservationActivateSubscriptionBoCall(
        reservationsSlice.actions,
        dispatch,
        getState(),
        { reservationCode, customerCode, carToken, deviceToken },
        oldActions
      );
    };

export const reservationNewReservationCloseBo =
  ({ reservationCode, carToken }, oldActions) =>
    (dispatch, getState) => {
      reservationNewReservationCloseBoCall(
        { ...reservationsSlice.actions, reservationOngoingList },
        dispatch,
        getState(),
        { reservationCode, carToken },
        oldActions
      );
    };

export const reservationNewReservationParkOnBo =
  ({ reservationCode, carToken }, oldActions) =>
    (dispatch, getState) => {
      reservationNewReservationParkonBoCall(
        reservationsSlice.actions,
        dispatch,
        getState(),
        { reservationCode, carToken },
        oldActions
      );
    };

export const reservationNewReservationParkoffBo =
  ({ reservationCode, carToken }, oldActions) =>
    (dispatch, getState) => {
      reservationNewReservationParkoffBoCall(
        reservationsSlice.actions,
        dispatch,
        getState(),
        { reservationCode, carToken },
        oldActions
      );
    };

export const reservationRequestReservationExtension =
  ({ reservationCode }, oldActions) =>
    (dispatch, getState) => {
      reservationRequestReservationExtensionCall(
        reservationsSlice.actions,
        dispatch,
        getState(),
        { reservationCode },
        oldActions
      );
    };

export const dashboardFeedReservation = (reservation) => (dispatch, getState) => {
  const selectedTrip = getState().getIn(["reservations"])?.selectedTrip;
  if (selectedTrip?.code === reservation.reservationCode) {
    dispatch(setSelectedTrip({ ...selectedTrip, ...reservation }));
  }
};

export const reservationOngoingList =
  (filter, clear = false) =>
    (dispatch, getState) => {
      reservationOngoingListCall(reservationsSlice.actions, dispatch, getState(), { filter, clear });
    };

export const reservationClosedList =
  (filter, clear = false) =>
    (dispatch, getState) => {
      reservationClosedListCall(reservationsSlice.actions, dispatch, getState(), { filter, clear });
    };

/// create reservation

export const crParkList = (filter) => (dispatch, getState) => {
  crParkListCall(reservationsSlice.actions, dispatch, getState(), { filter });
};

export const crParkGetAssets =
  ({ parkId, startDate, endDate, filter }) =>
    (dispatch, getState) => {
      crParkGetAssetsCall(reservationsSlice.actions, dispatch, getState(), {
        parkId,
        filter,
        startDate,
        endDate,
      });
    };

export const crAssetListNotInReservation =
  ({ deviceLicensePlate }) =>
    (dispatch, getState) => {
      crAssetListNotInReservationCall(reservationsSlice.actions, dispatch, getState(), {
        deviceLicensePlate,
      });
    };

export const crCustomerList = (name) => (dispatch, getState) => {
  crCustomerListCall(reservationsSlice.actions, dispatch, getState(), {
    name,
  });
};

export const crAccountsRetrieveAccountsUser =
  ({ customerCode }) =>
    (dispatch, getState) => {
      crAccountsRetrieveAccountsUserCall(reservationsSlice.actions, dispatch, getState(), {
        customerCode,
      });
    };

export const reservationPaymentDetails =
  ({ reservationCode }) =>
    (dispatch, getState) => {
      reservationPaymentDetailsCall(reservationsSlice.actions, dispatch, getState(), {
        reservationCode,
      });
    };

export const billingInvoiceSingleTrip =
  ({ reservationCode, customerCode }) =>
    (dispatch, getState) => {
      billingInvoiceSingleTripCall(reservationsSlice.actions, dispatch, getState(), {
        reservationCode,
        customerCode,
      });
    };

export const billingSettle =
  ({ reservationCode }) =>
    (dispatch, getState) => {
      billingSettleCall(reservationsSlice.actions, dispatch, getState(), {
        reservationCode,
      });
    };

export const billingRevise =
  ({ reservationCode }) =>
    (dispatch, getState) => {
      billingReviseCall(reservationsSlice.actions, dispatch, getState(), {
        reservationCode,
      });
    };

export const billingChargeAdvanced =
  ({ reservationCode, carToken }) =>
    (dispatch, getState) => {
      billingChargeAdvancedCall({ ...reservationsSlice.actions, reservationPaymentDetails }, dispatch, getState(), {
        reservationCode,
        carToken,
      });
    };

export const billingRefundAdvanced =
  ({ reservationCode, carToken, refundAmount }) =>
    (dispatch, getState) => {
      billingRefundAdvancedCall({ ...reservationsSlice.actions, reservationPaymentDetails }, dispatch, getState(), {
        reservationCode,
        carToken,
        refundAmount
      });
    };

export const billingSimulateAdvanced =
  ({ reservationCode, activeTime, idleTime, distanceTravelled, carToken }) =>
    (dispatch, getState) => {
      billingSimulateAdvancedCall(reservationsSlice.actions, dispatch, getState(), {
        reservationCode,
        activeTime,
        idleTime,
        distanceTravelled,
        carToken,
      });
    };

export const billingSet =
  ({ reservationCode, activeTime, idleTime, distanceTravelled, totalCost }) =>
    (dispatch, getState) => {
      billingSetCall({ billingToBill }, dispatch, getState(), {
        reservationCode,
        activeTime,
        idleTime,
        distanceTravelled,
        totalCost,
      });
    };

export const billingToBill =
  ({ reservationCode }) =>
    (dispatch, getState) => {
      billingToBillCall({ ...reservationsSlice.actions, reservationPaymentDetails }, dispatch, getState(), {
        reservationCode,
      });
    };
