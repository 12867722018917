const styles = theme => ({
	root: {
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: 'white',
		borderRadius: '5px',
		boxShadow: theme.shadows[5],
		boxSizing: 'border-box',
		display: 'flex',
		minHeight: '500px',
		height: '90%',
		margin: 'auto',
		outline: 0,
		position: 'absolute',
		minWidth: '1000px',
		width: '90%',
	},

	data: {
		width: '100%',
		height: '100%',
		padding: '20px',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
	},
});

export default styles;