import React, { useState } from "react";
import PromotionList from "./PromotionList/PromotionList";
import PromotionDetails from "./PromotionDetails/PromotionDetails";
import { dateFormatter } from "../../../services/formatter";
import { Drawer, Modal, withStyles, Typography } from "@material-ui/core";
import { ReactComponent as CreateIcon } from "../../../assets/PromotionApp/icons/add.svg";
import withPermission from "../../../hoc/withPermission";
import styles from "./Promotion.styles";
import PromotionForm from "../../../features/promotions/PromotionForm/PromotionForm";

const PromotionApp = ({
  classes,
  currentScreenSize,
  getMoreRows,
  lastPage,
  promotions,
  request,
  selectedPromotion,
  setSelectedPromotion,
}) => {
  const [modal, setModal] = useState(false);
  const title = <Typography className={classes.title}>Promotions</Typography>;

  const createIcon = <CreateButton onClick={() => setModal(true)} className={classes.createIcon} size="small" />;

  const searchbar = (
    <div className={classes.searchbar}>
      <div className={classes.titleAndSearch}>{title}</div>
      <div className={classes.actions}>{createIcon}</div>
    </div>
  );

  const list = !!promotions && (
    <PromotionList
      currentScreenSize={currentScreenSize}
      getMoreRows={!lastPage && getMoreRows}
      onSelect={setSelectedPromotion}
      promotionsArray={promotions}
      selected={selectedPromotion?.id}
    />
  );

  const details = !!selectedPromotion && (
    <PromotionDetails
      details={{
        ...selectedPromotion,
        startDate: dateFormatter.format(selectedPromotion.startDate, "date"),
        endDate: dateFormatter.format(selectedPromotion.endDate, "date"),
      }}
    />
  );

  const requestModal = !!request && (
    <Modal open={!!request}>
      <div className={classes.requestModal}>
        <Typography className={classes.requestText} align="center">
          {`Disabling promotion ${request}.`}
        </Typography>
        <Typography className={classes.requestText}>This operation might take a few seconds</Typography>
        <Typography className={classes.requestText}>Please wait</Typography>
      </div>
    </Modal>
  );

  const promotionApp = (
    <div className={classes.root}>
      <Drawer anchor="right" onClose={() => setModal(false)} open={modal}>
        <PromotionForm onCancel={() => setModal(false)} onSave={() => setModal(false)} />
      </Drawer>
      {requestModal}
      <div className={classes.workspace}>
        <div className={classes.list}>
          {searchbar}
          {list}
        </div>
        <div className={classes.details}>{details}</div>
      </div>
    </div>
  );

  return promotionApp;
};

export default withStyles(styles)(PromotionApp);

const CreateButton = withPermission([{ resource: ["promotions", "promotions", "create"] }])((props) => (
  <CreateIcon {...props} />
));
