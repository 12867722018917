const styles = theme => ({
	root: {
		background: '#FAFAFA 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 6px #00000029',
		maxWidth: '320px',
		minWidth: '320px',
		overflowY: 'auto',
		padding: '20px',
		borderTopRightRadius: '5px',
		borderBottomRightRadius: '5px',
		scrollbarWidth: 'thin',
		'&::-webkit-scrollbar': {
			width: '1px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)', 
		},
		display: 'flex',
		flexDirection: 'column',
	},

	title: {
		fontSize: '20px',
	},

	label: {
		fontSize: 11,
		fontWeight: "bold",
		flex: 1,
	},

	value: {
		fontSize: 12,
		flex:1,
	},

	row: {
		display: 'flex',
		justifyContent: 'flex-start',
		width: '100%',
	},
});

export default styles;