export const styles = () => ({
	root: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		height: "calc(100vh - 264px)",
	},

	message: {
		fontSize: "14px",
	},

	templateRoot: {
		height: "calc(100vh - 314px)",
	},

	template: {
		width: "auto",
		height: "calc(100vh - 314px)",
		background: "#EEEEEE 0% 0% no-repeat padding-box",
		borderRadius: "2px",
		opacity: 1,
	},

	templateInput: {
		height: "100% !important",
		overflowY: "scroll !important",
	},

	labelProps: {
		zIndex: 999,
		color: "blue",
	},

	iconEnabled: {
		color: "#009AC9",
		marginBottom: "-5px",
	},
});

export default styles;
