export const READY = 'READY';
export const ONGOING = 'ONGOING';
export const CLOSED = 'CLOSED';
export const CANCELLED = 'CANCELLED';
export const SCHEDULED = 'SCHEDULED';
export const ABSENT = 'ABSENT';

export const isValidReservationState = (state) => {
	return [READY, ONGOING, CLOSED, CANCELLED, SCHEDULED, ABSENT].includes(state);
};

export const getReservationStatus = reservation => {
	if (!reservation) {
		return undefined;
	};
	
	if (!reservation.state) {
		return 'undefined';
	};

	if (reservation.state === 'IDLE') {
		return READY;
	};

	if (reservation.state === 'STARTED') {
		return ONGOING;
	};

	if (reservation.state === 'OVERLAPPED'){
		return CANCELLED;
	};

	if (reservation.state === 'SCHEDULED'){
		return SCHEDULED;
	};

	if (reservation.state === 'ABSENT'){
		return ABSENT;
	};

	return reservation.state;
};