import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useSwitch from "../../hooks/useSwitch";
import CustomerApp from "./CustomerApp";
import { selectDefaultLocation } from "../../features/carclub/carclubSlice";
import {
  customerNewList,
  selectCustomers,
  selectSelectedCustomer,
  setSelectedCustomerByCode,
} from "../../features/customer/customerSlice";

const CustomerAppWrapper = (props) => {
  const dispatch = useDispatch();
  const selectedLocation = useSelector(selectDefaultLocation);
  const [modal, setModal] = useState();
  const selectedCustomer = useSelector(selectSelectedCustomer);
  const customers = useSelector(selectCustomers);

  useEffect(() => {
    if (!selectedCustomer && customers.list?.length > 0) {
      dispatch(setSelectedCustomerByCode(customers.list[0].code));
    }
    //eslint-disable-next-line
  }, [customers.list]);

  useEffect(() => {
    if (selectedLocation) {
      dispatch(customerNewList({ reset: true }));
    }
    // eslint-disable-next-line
  }, [selectedLocation]);

  const hasCreateEditScreen = useSwitch(false);
  const hasCustomerAccountDetails = useSwitch(false);

  return (
    <CustomerApp
      {...props}
      closeAccountDetailsScreen={hasCustomerAccountDetails.setOff}
      closeUpdateScreen={hasCreateEditScreen.setOff}
      hasCreateEditScreen={hasCreateEditScreen.state}
      hasCustomerAccountDetails={hasCustomerAccountDetails.state}
      modal={modal}
      openAccountDetailsScreen={hasCustomerAccountDetails.setOn}
      openUpdateScreen={hasCreateEditScreen.setOn}
      selectedCustomer={selectedCustomer}
      setModal={setModal}
    />
  );
};

export default CustomerAppWrapper;
