import React from "react";
import { typeIcons, stateIcons } from "./icons.js";
import { dateFormatter } from "../../../services/formatter";
import { TRIP_STATE, TRIP_TYPE } from "../../../containers/TripApp/reducer";
import { Tooltip, Typography, withStyles } from "@material-ui/core";
import { NotInterested as NotFoundIcon } from "@material-ui/icons";
import { styles } from "./TripCard.styles";
import classnames from "classnames";
import TripWarningIcons from "../../../features/reservation/TripWarningIcons/TripWarningIcons.js";

const TripCard = ({ classes, className, trip, selectedTrip, cardMode, onClick }) => {
  const { advanced, assetName, createDate, code, distance, endDate, licensePlate, startDate, state, type } = trip || {};
  //TODO Had function to d
  const isSelected = selectedTrip.code === code;

  const parsedState = parseState(state);

  const needsAttention = trip.seeIfAttentionIsRequired();

  const assetInfoData = [code, licensePlate, assetName];

  const iconNotFound = (
    <Tooltip title="Unable to parse icon" placement="left">
      <NotFoundIcon />
    </Tooltip>
  );

  let typeToParse = type;

  if (typeof type === "undefined") {
    typeToParse = typeof advanced === "undefined" || !advanced ? TRIP_TYPE.IMMEDIATE : TRIP_TYPE.ADVANCED;
  }

  const TypeIcon = typeIcons[typeToParse];
  const stateIcon = stateIcons[parsedState];

  const typeIconTooltipped = (
    <Tooltip title={typeToParse} placement="left">
      <TypeIcon className={classes.greyIcon} />
    </Tooltip>
  );

  const stateIconTooltipped = (
    <Tooltip title={parsedState} placement="left">
      {stateIcon}
    </Tooltip>
  );

  const stateTypeIcons = (
    <div className={classes.stateTypeDivision}>
      {typeof TypeIcon !== "undefined" ? typeIconTooltipped : iconNotFound}
      {typeof stateIcon !== "undefined" ? stateIconTooltipped : iconNotFound}
    </div>
  );

  const listModeAssetInfo = !cardMode && (
    <>
      <div className={!cardMode && classes.horizontalSpreadFlex}>
        <Typography key={trip.code + trip.licensePlate + code} className={classes.textField}>
          {code}
        </Typography>
        <Typography key={trip.code + trip.licensePlate + licensePlate} className={classes.textField}>
          {licensePlate}
        </Typography>
      </div>
      <div className={!cardMode && classes.horizontalSpreadFlex}>
        <Typography key={trip.code + trip.licensePlate + assetName} className={classes.textField}>
          {assetName}
        </Typography>
      </div>
    </>
  );

  const cardModeAssetInfo = cardMode && assetInfoData.map(textFieldMapper({ classes, trip, cardMode }));
  const assetInfo = (
    <div className={classnames(classes.assetDivison, classes.verticalFlex)}>
      {cardModeAssetInfo}
      {listModeAssetInfo}
    </div>
  );

  const initialDate = parsedState === TRIP_STATE.CANCELLED ? createDate : startDate;

  const parsedStart = dateFormatter.format(initialDate, "dateTime");

  const parsedEnd = dateFormatter.format(endDate, "dateTime");

  const distanceInfo = "undefined" !== typeof distance && distance >= 0 && "CLOSED" === state && (
    <div className={classnames(cardMode && classes.horizontalFlex)}>
      <Typography className={classes.textField} display="inline">
        {"Distance: "}
      </Typography>
      <Typography className={classes.textField} display="inline">
        {distance}
      </Typography>
    </div>
  );

  const endInfo = parsedEnd && (
    <div className={classnames(cardMode && classes.horizontalFlex)}>
      <Typography className={classes.textFieldSmall} display="inline">
        {"End Date: "}
      </Typography>
      <Typography className={classes.textField} display="inline">
        {parsedEnd}
      </Typography>
    </div>
  );

  return (
    <div
      className={classnames(
        classes.root,
        className,
        needsAttention && classes.attentionRequired,
        isSelected && classes.selected
      )}
      onClick={onClick}
    >
      {stateTypeIcons}
      {assetInfo}
      <div className={classnames(classes.tripDivison, cardMode && classes.verticalFlex)}>
        <div className={classnames(cardMode && classes.horizontalFlex)}>
          <Typography className={classes.textFieldSmall} display="inline">
            {`${parsedState === TRIP_STATE.CANCELLED ? "Create date: " : "Start date: "}`}
          </Typography>
          <Typography className={classes.textField} display="inline">
            {parsedStart}
          </Typography>
        </div>
        {endInfo}
        {distanceInfo}
      </div>
      <div className={classnames(classes.iconDivison, cardMode && classes.iconDivisonVertical)}>
        <TripWarningIcons trip={trip} />
      </div>
    </div>
  );
};

const textFieldMapper =
  ({ classes, trip, cardMode }) =>
  (field) => {
    return (
      <Typography key={trip.code + trip.licensePlate + field} className={classes.textField} display="inline">
        {field}
      </Typography>
    );
  };

export const parseState = (state) => {
  return stateMap[state];
};

const stateMap = {
  ONGOING: "ONGOING",
  STARTED: "ONGOING",
  READY: "READY",
  IDLE: "READY",
  SCHEDULED: "SCHEDULED",
  CANCELLED: "CANCELLED",
  CLOSED: "CLOSED",
  ABSENT: "ABSENT",
};

export default withStyles(styles)(TripCard);
