
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_REQUEST_IS_MALFORMED,
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import mapper from './mappers/customerAccountDetails';
import { publishAndAwait } from '../../../app/coreSlice';

const getCustomerAccountDetails = async filters => {

	if (typeof filters === 'undefined') {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	};

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'CUSTOMER',
			subType: 'ACCOUNT_LIST',
			message: {
				...filters,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	};

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	};

	if (!processMessage.payments) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	};

	const payments = processMessage.payments
		.reduce((obj, payment) => ({ ...obj, [payment.id]: mapper(payment) }), {});

	return { ...processMessage, payments, page: processMessage.page || filters.page };

};

export default getCustomerAccountDetails;