export const LOCATION = 'LOCATION';
export const NORMAL = 'NORMAL';
export const PARK = 'PARK';
export const UNWANTED = 'UNWANTED';

const geofences = {
	LOCATION,
	NORMAL,
	PARK,
	UNWANTED,
};

export default geofences;