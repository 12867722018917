import React from "react";
import { Button } from 'reactstrap';
import { utils } from "../../../../utils";

function getColumnsData(rawLines) {
	let lines = [];
	for (let i = 1; i < rawLines.length; i++) {
		let data = [];
		if (rawLines[i].indexOf('"') > -1) {
			data = rawLines[i].split(',"');
		}
		else {
			data = rawLines[i].split(',');
		}
		for (let j = 0; j < data.length; j++) {
			data[j] = data[j].split('"').join(""); //replace " for nothing
		}
		lines.push(data);
	}
	return lines;
}

function CsvImporter(props) {

	let languages = null;
	let i18n = null;
	if (props.data && props.data.configuration && props.data.configuration.configs && props.data.configuration.i18n && props.data.configuration.configs.availableLanguages) {
		languages = props.data.configuration.configs.availableLanguages;
		i18n = props.data.configuration.i18n;
	}
	const onChangeFile = (event) => {
		event.stopPropagation();
		event.preventDefault();
		let file = event.target.files[0];
		if (file) {
			utils.getFileAsText(file, (result, error) => {
				if (!error) {
					let json = {};
					let allTextLines = result.split(/\r\n|\n/);
					let headers = allTextLines[0].split(',');
					let lines = getColumnsData(allTextLines);
					for (let i = 1; i < headers.length; i++) {
						let lang = headers[i];
						if (languages.indexOf(lang) === -1) {
							i++;
						}
						else {
							json[lang] = {};
							for (let j = 1; j < lines.length; j++) {
								let key = lines[j][0];
								let value = lines[j][i];
								if (value === "") {
									value = undefined;
								}
								if (i18n[lang][key] === "") {
									i18n[lang][key] = undefined;
								}
								if (i18n[lang][key] !== value) {
									json[lang][key] = value;
								}
							}
						}
					}
					props.onChange(json);
				}
			});
		}
	};
	let upload;
	return (
		<div>
			<Button color="secondary" size="sm" onClick={() => upload.click()} active>CSV Import</Button>
			<input
				id="myInput"
				type="file"
				ref={ref => upload = ref}
				style={{ display: "none" }}
				onChange={onChangeFile}
			/>
		</div>
	);
}

export default CsvImporter;