export const styles = () => ({
  root: {
    padding: 10,
    color: "#47525E",
  },
  title: {
    fontSize: 20,
    color: "#4095c2",
  },
  button: {
    marginBottom: 0,
  },
  actions: {
    width: 240,
  },
});
