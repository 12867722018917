import React from 'react';
import MembersList from './MembersList/MembersList';
import Searchbar from '../../UI/Searchbar/Searchbar';
import {
	FormControl,
	InputLabel,
	Select,
	Typography,
	withStyles,
} from '@material-ui/core';
import styles from './Members.styles';
import classnames from 'classnames';

const label = 'Search';

const selectByArray = [
	{ value: 'name', label: 'Name' },
	{ value: 'email', label: 'Email' },
	{ value: 'phoneNumber', label: 'Phone' },
];

const Members = ({
	addMember,
	className,
	classes,
	currentScreenSize,
	drivers,
	filter,
	getAllDrivers,
	members,
	onSelect,
	removeMember,
	setSelectBy,
	selected,
	selectBy,
	setFilter,
	showCustomerTrips,
}) => {

	const selectBySelect = (
		<FormControl>
			<InputLabel htmlFor="selectByMember">
				Search by
	  </InputLabel>
			<Select
				native
				onChange={e => setSelectBy(e.target.value)}
				className={classes.selectEmpty}
				value={selectBy}
				inputProps={{
					id: 'selectByMember',
				}}
			>
				{selectByArray.map(param => (
					<option value={param.value} key={param.value}>
						{param.label}
					</option>
				))}
			</Select>
		</FormControl>
	);

	const title = (
		<div className={classes.title}>
			<Typography className={classes.mainTitle}>
				Members
			</Typography>
			{selectBySelect}
			<Searchbar {...{ filter, label, onSearch: getAllDrivers, setFilter}} />
		</div>
	);

	const list = !!members && (
		<MembersList
			className={classes.list}
			{...{
				addMember,
				currentScreenSize,
				drivers,
				members,
				onSelect,
				removeMember,
				selected,
				showCustomerTrips,
			}}
		/>
	);

	const root = (
		<div className={classnames(classes.root, className)}>
			{title}
			{list}
		</div>
	);

	return root;
};

export default withStyles(styles)(Members);