import React from "react";
import { Tooltip } from "@material-ui/core";

function HeartbeatMarker({ tooltip }) {
  return (
    <Tooltip title={tooltip}>
      <div
        style={{
          backgroundColor: "#009bcc ",
          height: 6,
          width: 6,
          borderRadius: 3,
          marginLeft: -3,
          marginTop: -3,
          cursor: "pointer",
        }}
      ></div>
    </Tooltip>
  );
}

export default HeartbeatMarker;
