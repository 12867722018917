import React, { useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import {
  Typography,
  ClickAwayListener,
  Popper,
  MenuList,
  MenuItem,
  ListItemIcon,
  CircularProgress,
} from "@material-ui/core";
import { ReactComponent as AssetIcon } from "../../../../assets/AssetApp/icons/wheelicon_black.svg";
import { ReactComponent as CarIcon } from "../../../../assets/AssetApp/icons/caricon_black.svg";
import { ReactComponent as ScooterIcon } from "../../../../assets/AssetApp/icons/scootericon_black.svg";

import { styles } from "./AssetTypeFilter.styles";
import MapButton from "../../common/MapButton/MapButton";

// CAR - 'a1a98142-21e0-4b4e-80e7-320e15653284'
// KICKSCOOTER - '36c2f9d7-15d6-4500-9d67-fff93cb3d73b'
// SCOOTER: 'c95fc7e4-6f5c-4898-88f0-6419edad260a',

function AssetTypeFilter({ classes, filter, isLoading, onSelectType }) {
  const [anchorEl, setAnchorEl] = useState(null);

  let selectedIcon;
  if (isLoading) {
    selectedIcon = <CircularProgress size={20} />;
  } else {
    selectedIcon =
      filter?.assetType === "a1a98142-21e0-4b4e-80e7-320e15653284" ? (
        <CarIcon />
      ) : filter?.assetType === "36c2f9d7-15d6-4500-9d67-fff93cb3d73b" ? (
        <ScooterIcon />
      ) : filter?.assetType === "c95fc7e4-6f5c-4898-88f0-6419edad260a" ? (
        <ScooterIcon />
      ) : (
        <AssetIcon />
      );
  }

  const onMenuItemClickedHandler = (option) => {
    onSelectType && onSelectType(option);
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <MapButton tooltip="Filter by Asset Type" onClick={(e) => setAnchorEl(e.currentTarget)}>
        {selectedIcon}
      </MapButton>
      <Popper className={classes.menu} open={Boolean(anchorEl)} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <MenuList>
            {/* KICKSCOOTER */}
            {/* <MenuItem onClick={() => onSelectType("36c2f9d7-15d6-4500-9d67-fff93cb3d73b")}>
              <ListItemIcon className={classes.icon}>
                <ScooterIcon />
              </ListItemIcon>
              <Typography>Kick Scooter</Typography>
            </MenuItem> */}
            {/* SCOOTER */}
            <MenuItem onClick={() => onMenuItemClickedHandler("c95fc7e4-6f5c-4898-88f0-6419edad260a")}>
              <ListItemIcon className={classes.icon}>
                <ScooterIcon />
              </ListItemIcon>
              <Typography>Scooter</Typography>
            </MenuItem>
            {/* CAR */}
            <MenuItem onClick={() => onMenuItemClickedHandler("a1a98142-21e0-4b4e-80e7-320e15653284")}>
              <ListItemIcon className={classes.icon}>
                <CarIcon />
              </ListItemIcon>
              <Typography>Car</Typography>
            </MenuItem>
            {/* ALL  */}
            <MenuItem onClick={() => onMenuItemClickedHandler(null)}>
              <ListItemIcon className={classes.icon}>
                <AssetIcon />
              </ListItemIcon>
              <Typography>All</Typography>
            </MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}

export default withStyles(styles)(AssetTypeFilter);
