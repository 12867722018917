import React, { useState, useEffect, useCallback, useRef } from "react";
import accountService from "../../../services/account";
import Consumption from "./Consumption";
import log from "../../../utils/logger";

const logger = log("Accounts>Consumption");

const ConsumptionWrapper = ({ accountCode, ...props }) => {
  const [error, setError] = useState();
  const [filter, setFilter] = useState({});
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false);

  const [billingItemCode, setBillingItemCode] = useState();
  const [chargingModal, setChargingModal] = useState(false);
  const [transactionsModal, setTransactionsModal] = useState(false);
  const [hasRequest, setHasRequest] = useState(false);
  const [total, setTotal] = useState({});

  const [page, setPage] = useState({ lastPage: true, page: 1 });

  const errorTimeout = useRef();

  useEffect(() => {
    const onMount = async () => {
      try {
        const { billingItemsAccountList, lastPage, totalCost, totalCharged } = await accountService.billingList({
          accountCode,
        });

        setList(billingItemsAccountList);
        setPage({ lastPage, page: 2 });
        setTotal({ totalCost, totalCharged });
      } catch (e) {
        logger.warn(`Could not get billing items for ${accountCode}`);
        setError(e.message || e.description || `Could not get billing items for ${accountCode}`);
      }
    };

    onMount();
  }, [accountCode]);

  //Clear error
  useEffect(() => {
    if (error) {
      errorTimeout.current = setTimeout(() => setError(), 5000);
    }

    return () => clearTimeout(errorTimeout.current);
  }, [error]);

  const onSearch = useCallback(
    async (filters = {}) => {
      try {
        const { billingItemsAccountList, lastPage, totalCost, totalCharged } = await accountService.billingList({
          ...filters,
          accountCode,
          page: filters.page || page.page,
        });

        setList((prev) => (filters.page ? billingItemsAccountList : [...prev, ...billingItemsAccountList]));

        setPage((prev) => ({ lastPage, page: filters.page + 1 || prev.page + 1 }));
        setTotal({ totalCost, totalCharged });
      } catch (e) {
        logger.warn(`Could not search for ${accountCode}`);
        setError(e.message || e.description || `Could not search for ${accountCode}`);
        setTotal({});
      }
    },
    [accountCode, page.page]
  );

  const onCreate = useCallback(
    async (dates) => {
      try {
        console.log("onCreate");
        const newBillingItem = await accountService.billingCreate({ ...dates, accountCode });
        console.log("newBillingItem: %o", newBillingItem);
        if (newBillingItem) {
          setList((prev) => [...prev, newBillingItem]);
        } else {
          setError(`Could not create billing item for ${accountCode}`);
        }
      } catch (e) {
        logger.warn(`Could not create billing item for ${accountCode}`);
        setError(e.message || e.description || `Could not create billing item for ${accountCode}`);
      }
    },
    [accountCode]
  );

  const onSettle = useCallback(
    async (description) => {
      try {
        setError();

        if (!description || checkIfEmptyString(description)) {
          setError("Description is required when settling as paid");
          setHasRequest(false);
          return;
        }

        setHasRequest(true);

        await accountService.billingSettle({ accountCode, billingItemAccountCode: billingItemCode, description });

        setList((prev) => [
          ...prev.filter((billingItem) => billingItem.code !== billingItemCode),
          { ...prev.find((billingItem) => billingItem.code === billingItemCode), paid: true },
        ]);
      } catch (e) {
        logger.warn(`Could not settle billing item ${billingItemCode}`);
        setError(e.message || e.description || `Could not settle billing item ${billingItemCode}`);
      } finally {
        setHasRequest(false);
        setChargingModal(false);
      }
    },
    [billingItemCode, accountCode]
  );

  const onCharge = useCallback(async () => {
    try {
      setError();
      setHasRequest(true);

      await accountService.billingCharge({ billingItemAccountCode: billingItemCode, accountCode });

      setList((prev) => [
        ...prev.filter((billingItem) => billingItem.code !== billingItemCode),
        { ...prev.find((billingItem) => billingItem.code === billingItemCode), paid: true },
      ]);
    } catch (e) {
      logger.warn(`Could not charge billing item ${billingItemCode}`);
      setError(e.message || e.description || `Could not charge billing item ${billingItemCode}`);
    } finally {
      setHasRequest(false);
      setChargingModal(false);
    }
  }, [billingItemCode, accountCode]);

  const onRemove = useCallback(async (code) => {
    try {
      await accountService.billingDelete(code);

      setList((prev) => prev.filter((billingItem) => billingItem.code !== code));
    } catch (e) {
      logger.warn(`Could not remove billing item ${code}`);
      setError(e.message || e.description || `Could not remove billing item ${code}`);
    }
  }, []);

  const onInvoice = useCallback(
    async (billingItemAccountCode) => {
      try {
        const response = await accountService.billingInvoice({ billingItemAccountCode, accountCode });
        // console.log("req: %o , response: %o", { billingItemAccountCode, accountCode }, response);

        setList((prev) => [
          ...prev.filter((billingItem) => billingItem.code !== billingItemAccountCode),
          {
            ...prev.find((billingItem) => billingItem.code === billingItemAccountCode),
            invoiced: true,
            pdfUrl: response?.invoice?.pdfUrl,
          },
        ]);
      } catch (e) {
        logger.warn(`Could not generate invoice for ${billingItemAccountCode}`);
        setError(e.message || e.description || `Could not generate invoice for ${billingItemAccountCode}`);
      }
    },
    [accountCode]
  );

  const setChargingModalHandler = (billingItemCode) => {
    setChargingModal((prev) => !prev);
    setBillingItemCode(billingItemCode);
  };

  const transactionsModalHandler = ({ modal, billingItemCode }) => {
    setTransactionsModal(!!modal);
    setBillingItemCode(billingItemCode);
  };

  return (
    <Consumption
      {...props}
      accountCode={accountCode}
      chargingModal={chargingModal}
      error={error}
      filter={filter}
      hasRequest={hasRequest}
      lastPage={page.lastPage}
      list={list}
      modal={modal}
      onCharge={onCharge}
      onCreate={onCreate}
      onInvoice={onInvoice}
      onRemove={onRemove}
      onSearch={onSearch}
      onSettle={onSettle}
      selectedBillingItemCode={billingItemCode}
      setChargingModal={setChargingModalHandler}
      setError={setError}
      setFilter={setFilter}
      setList={setList}
      setModal={setModal}
      total={total}
      transactionsModal={transactionsModal}
      transactionsModalHandler={transactionsModalHandler}
    />
  );
};

const checkIfEmptyString = (string) => {
  if (!string || typeof string !== "string") {
    return true;
  }

  const newString = string.replace(/\s/g, "");
  if (!newString) {
    return true;
  }

  return false;
};

export default ConsumptionWrapper;
