import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const doorsCloseCall = async ({ updateAssetOnList }, dispatch, state, { carToken }) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "DOORS/CLOSE");
  try {
    const result = await wsCall({
      type: "DOORS",
      subType: "CLOSE",
      locationId: selectedLocation?.id,
      message: {
        carToken,
      },
    });
    if (result && !result.error) {
      dispatch(setSuccess("Close doors command sent successfully"));
      dispatch(updateAssetOnList(result));
    } else {
      rh.error(result?.description || "Error closing doors!");
    }
  } catch (_ex) {
    rh.error("Error closing doors!");
  }
  rh.close();
};

export default doorsCloseCall;
