import React from 'react';
import ReservationStatusIcon
	from '../../../../assets/ReservationApp/icons/ReservationStatusIcon';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import classnames from 'classnames';
import styles from './Header.styles.js';
import { parseState } from '../../TripCard/TripCard';

const Header = ({
	classes,
	className,
	trip,
}) => {

	const {
		state,
		code,
		imgUrl,
	} = trip || {};

	const parsedState = parseState(state);

	const StatusIcon = ReservationStatusIcon[parsedState];

	const assetImage = (
		<div className={classnames(
			classes.image,
			!imgUrl && classes.imageBackground,
		)}>
			{!!imgUrl && <img src={imgUrl} alt='Asset' className={classes.image} />}
		</div>
	);

	return (
		<div className={classnames(classes.root, className)}>

			<div className={classes.assetStatusContainer}>
				<div className={classes.flexColumn}>
					<div className={classes.flexHorizontal}>
						{!!StatusIcon && <StatusIcon
							className={classes.statusIcon}
						/>}
						<Typography key={parsedState} className={classes.text} variant='body1'>
							{parsedState}
						</Typography>
					</div>
				</div>
			</div>

			<div className={classes.assetInfoContainer}>
				<Typography className={classes.reservationCode}>
					{code}
				</Typography>
			</div>

			<div className={classes.assetImgContainer}>
				{assetImage}
			</div>
		</div >
	);
};

export default withStyles(styles)(Header);
