import React from "react";
import { useDispatch } from "react-redux";
import { Check as TrueIcon, RemoveCircleOutline as EditIcon } from "@material-ui/icons";
import { Tooltip, withStyles } from "@material-ui/core";

import styles from "./PromotionItem.styles";
import ListItem from "../../../../UI/List/ListItem/ListItem";
import { dateFormatter } from "../../../../../services/formatter";
import withPermission from "../../../../../hoc/withPermission";
import { useDateTime } from "../../../../../common/hooks";
import { promotionCancel } from "../../../../../features/promotions/promotionsSlice";

const PromotionItem = ({ classes, promotion, fields, onClick, selected }) => {
  const { isPast } = useDateTime();
  const dispatch = useDispatch();
  const isEnabled = !isPast(promotion?.endDate);

  const cancelHandler = (values) => {
    dispatch(promotionCancel({ promotionId: values?.id }));
  };

  const Button = ButtonWithPermission(isEnabled);

  const editIcon = <Button classes={classes} onClick={() => cancelHandler(promotion)} />;

  const enabled = isEnabled ? <TrueIcon className={classes.checkIcon} /> : null;

  const item = !!promotion && (
    <ListItem
      selected={selected}
      fields={fields}
      onSelect={onClick}
      element={{
        ...promotion,
        startDate: dateFormatter.format(promotion.startDate, "date"),
        endDate: dateFormatter.format(promotion.endDate, "date"),
        editIcon,
        enabled,
      }}
    />
  );
  return item;
};

export default withStyles(styles)(PromotionItem);

const ButtonWithPermission = (isEnabled) =>
  withPermission([{ resource: ["promotions", "promotions", "edit"] }])(({ classes, onClick }) =>
    isEnabled ? (
      <Tooltip title="Disable" placement="left">
        <EditIcon className={classes.editIcon} onClick={onClick} />
      </Tooltip>
    ) : (
      <EditIcon className={classes.editIconDisabled} />
    )
  );
