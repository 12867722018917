import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableContainer, Button, CircularProgress, TableFooter, TableRow, TableCell } from "@material-ui/core";
import CustomDataGridHeader from "./CustomDataGridHeader";
import CustomDataGridBody from "./CustomDataGridBody";
import CustomDataGridFooter from "./CustomDataGridFooter";
import CustomDataGridBodySkeleton from "./CustomDataGridBodySkeleton";

const useStyles = makeStyles({
  root: {
    height: 300,
    overflowY: "auto",
    margin: "10px 0",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "21px",
      boxShadow: "inset 0 0 21px rgba(0,0,0,.3)",
    },
  },
  table: {
    padding: "0",
    minWidth: 400,
    position: "relative",
    borderCollapse: "separate",
    "& .MuiTableCell-sizeSmall": {
      padding: "5px",
    },
  },
  headerCell: {
    backgroundColor: "#FFF",
    position: "sticky",
    top: 0,
    "& label": {
      fontSize: "12px",
      color: "#47525E",
      fontWeight: "bold",
    },
  },
  cell: {
    fontSize: "10px",
    color: "#47525E",
  },
});

export default function CustomDataGrid({
  className,
  localFilters,
  columns,
  rows,
  actionsComponent,
  loading,
  onFilter,
  onLoadMore,
  onRowSelected,
  total,
  footer,
  innerRow,
  rowKeyField,
  hasHeader,
  selectedIndex,
}) {
  const classes = useStyles();
  const [filteredRows, setFilteredRows] = useState(rows);
  const [filterActive, setFilterActive] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const [filter, setFilter] = useState(null);

  const onSortHandler = (field) => {
    //console.log("field: %o -- filter: %o", field, filter);
    const nFilter = {
      ...filter,
      sortField: field,
      sortType:
        filter?.sortField === field
          ? filter?.sortType === "DESC"
            ? "ASC"
            : filter?.sortType === "ASC"
            ? "DESC"
            : null
          : "DESC",
    };

    setFilter(nFilter);
    if (localFilters) {
      let fRows = [...rows];
      fRows.sort((a, b) => {
        if (nFilter.sortType === "DESC" && a[nFilter.sortField] < b[nFilter.sortField]) {
          return -1;
        } else if (nFilter.sortType === "ASC" && a[nFilter.sortField] > b[nFilter.sortField]) {
          return -1;
        }
        return 1;
      });
      setFilteredRows(fRows);
    } else {
      setFilterActive(true);
      onFilter && onFilter(nFilter);
    }
  };

  const loader = useRef(null);
  const loadMoreBtn = useRef();
  const [obsOn, setObsOn] = useState(false);

  useEffect(() => {
    if (rows && rows.length > 0 && !obsOn) {
      let options = {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      };
      // initialize IntersectionObserver
      // and attaching to Load More div
      const observer = new IntersectionObserver((entities) => {
        const target = entities[0];
        // console.log("OBS - target.isIntersecting: %s", target.isIntersecting);
        if (target.isIntersecting) {
          //onLoadMore && onLoadMore();
          setTimeout(() => {
            loadMoreBtn?.current && loadMoreBtn.current.click();
          }, 300);
          // console.log("YES");
        }
      }, options);
      if (loader.current) {
        observer.observe(loader.current);
        setObsOn(true);
      }
    }
    // eslint-disable-next-line
  }, [rows]);

  useEffect(() => {
    if (localFilters) {
      if (filteredRows) {
        setFilteredRows(rows);
      }
    } else {
      if (rows && rows.length > 0) {
        if (filteredRows) {
          setFilteredRows(rows);
        }
        // load more if window has size
        // let g = document.getElementById("gridRoot");
        // console.log(
        //   "body: %s\ninner: %s\ngrid-offsetHeight: %s\ngrid-clientHeight: %s\ngrid-scrollHeight: %s",
        //   document.body.offsetHeight,
        //   window.innerHeight,
        //   g.offsetHeight,
        //   g.clientHeight,
        //   g.scrollHeight
        // );
        // //if (document.body.offsetHeight < document.body.offsetHeight) {
        // setTimeout(() => {
        //   if (g.offsetHeight < window.innerHeight && rows.length > 0) {
        //     onLoadMore && onLoadMore();
        //   }
        // }, 300);
      }
    }
    // eslint-disable-next-line
  }, [rows, total, filterActive]);

  return (
    <div>
      <TableContainer className={classes.root + " " + className}>
        <Table id="gridRoot" className={classes.table} size="small">
          {hasHeader !== false && (
            <CustomDataGridHeader
              columns={columns}
              filter={filter}
              onSort={onSortHandler}
              actionsComponent={actionsComponent}
              classes={classes}
            />
          )}
          <CustomDataGridBody
            keyField={rowKeyField}
            columns={columns}
            classes={classes}
            rows={localFilters ? filteredRows : rows}
            actionsComponent={actionsComponent}
            onRowSelected={onRowSelected}
            innerRow={innerRow}
            selectedIndex={selectedIndex}
          />
          {loading && <CustomDataGridBodySkeleton columns={columns} rows="20" actionsComponent={actionsComponent} />}
          {footer && <CustomDataGridFooter colspan={columns.length}>{footer}</CustomDataGridFooter>}
          <TableFooter>
            <TableRow>
              <TableCell colSpan={columns.length + (actionsComponent ? 1 : 0)} style={{ height: 30 }}>
                <div className="loading" ref={loader}>
                  {isFetching && <CircularProgress size={20} style={{ display: "block", margin: "0 auto" }} />}
                  <Button
                    style={{ display: "none" }}
                    variant="contained"
                    onClick={async () => {
                      if (!filterActive && onLoadMore) {
                        setIsFetching(true);
                        await onLoadMore();
                        setIsFetching(false);
                      }
                      setFilterActive(false);
                    }}
                    ref={loadMoreBtn}
                  >
                    Load More
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}
