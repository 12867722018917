import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Tooltip } from "@material-ui/core";
import WifiOnIcon from "@material-ui/icons/Wifi";
import WifiOffIcon from "@material-ui/icons/WifiOff";

import { styles } from "./DeviceStatusIcon.styles";
import { useDateTime } from "../../../../../../common/hooks";

function DeviceStatusIcon({ classes, asset }) {
  const dt = useDateTime();

  return (
    <div className={classes.root}>
      {asset.deviceUpdateDate && dt.getMinutesAgo(asset.deviceUpdateDate) < 15 ? (
        <Tooltip title={dt.toTimeZone(asset.deviceUpdateDate, "dateTimeComplete")} placement="bottom">
          <WifiOnIcon />
        </Tooltip>
      ) : (
        <Tooltip title={dt.toTimeZone(asset.deviceUpdateDate, "dateTimeComplete")} placement="bottom">
          <WifiOffIcon />
        </Tooltip>
      )}
    </div>
  );
}

export default withStyles(styles)(DeviceStatusIcon);
