import React from "react";
import { Divider, Typography, withStyles } from "@material-ui/core";
import styles from "./Details.styles";

const getKeyValue = (classes, key, value) => {
  return (
    <div className={classes.row} key={key}>
      <Typography className={classes.label}>{key}</Typography>
      <Typography className={classes.value}>{value.toString()}</Typography>
    </div>
  );
};

const Details = ({ classes, selected }) => {
  const title = <Typography className={classes.title}>Details</Typography>;

  const divider = <Divider key="divider" style={{ margin: "10px 0" }} />;

  const selectedKey = !!selected && Object.keys(selected)?.[0];
  const selectedObj = !!selectedKey && selected[selectedKey];

  console.log('selectedObj: %o', selectedObj);

  const details =
    !!selectedObj &&
    Object.keys(selectedObj).map((key) => {
      if (typeof selectedObj[key] !== "object") {
        return getKeyValue(classes, key, selectedObj[key]);
      } else {
        return Object.keys(selectedObj[key]).map((key2) => {
          return getKeyValue(classes, key + "." + key2, selectedObj[key][key2]);
        });
      }
    });

  return (
    <div className={classes.root}>
      {title}
      {divider}
      {details}
    </div>
  );
};

export default withStyles(styles)(Details);
