import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";
import { assetAssetReservationCounts, assetList } from "../../asset/assetSlice";

const reservationNewReservationCancelBoCall = async (
  { setSelectedTrip, reservationOngoingList },
  dispatch,
  state,
  { reservationCode, carToken },
  oldActions
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "RESERVATION/NEW_RESERVATION_CANCEL_BO");
  try {
    const result = await wsCall({
      type: "RESERVATION",
      subType: "NEW_RESERVATION_CANCEL_BO",
      locationId: selectedLocation?.id,
      message: {
        reservationCode,
        carToken,
      },
    });

    if (!result.error) {
      dispatch(setSuccess("Reservation canceled successfully!"));
      dispatch(setSelectedTrip());
      oldActions.remove(result);
      setTimeout(() => {
        // this should be update using notifications
        dispatch(assetList({}));
        dispatch(assetAssetReservationCounts());
        dispatch(reservationOngoingList(null, true));
      }, 300);
      // refresh reservations
    } else {
      rh.error(result?.description || "Error canceling reservation.");
    }
  } catch (_ex) {
    rh.error("Error canceling reservation.");
  }
  rh.close();
};

export default reservationNewReservationCancelBoCall;
