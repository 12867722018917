import createNotification from './createNotification';
import getConfigurations from './getConfigurations';
import getNotificationMessage from './getNotificationMessage';
import getSubTypes from './getSubTypes';
import getTemplate from './getTemplate';
import getTypesAndPlaceholders from './getTypesAndPlaceholders';
import listNotifications from './listNotifications';
import updateConfigurations from './updateConfigurations';
import updateNotification from './updateNotification';
import removeNotification from './removeNotification';
//SMS
import createNotificationSMS from './createNotificationSMS';
import updateConfigurationsSMS from './updateConfigurationsSMS';
import getConfigurationsSMS from './getConfigurationsSMS';
import listNotificationsSMS from './listNotificationsSMS';
import updateNotificationSMS from './updateNotificationSMS';
import removeNotificationSMS from './removeNotificationSMS';
import getTypesAndPlaceholdersSMS from './getTypesAndPlaceholdersSMS';
import getSubTypesSMS from './getSubTypesSMS';
import getTemplateSMS from './getTemplateSMS';


const notificationService = {
	createNotification,
	getConfigurations,
	getNotificationMessage,
	getSubTypes,
	getTemplate,
	getTypesAndPlaceholders,
	listNotifications,
	updateConfigurations,
	updateNotification,
	removeNotification,
	createNotificationSMS,
	updateConfigurationsSMS,
	getConfigurationsSMS,
	listNotificationsSMS,
	updateNotificationSMS,
	removeNotificationSMS,
	getTypesAndPlaceholdersSMS,
	getSubTypesSMS,
	getTemplateSMS,
};

export default notificationService;