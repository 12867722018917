import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const promotionCreateCall = async (
  { promotionList },
  dispatch,
  state,
  { smallDescription, startDate, endDate, maxUses, value, percentage, description, details, voucherCode, voucherUses }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "PROMOTION/CREATE");
  try {
    const result = await wsCall({
      type: "PROMOTION",
      subType: "CREATE",
      locationId: selectedLocation?.id,
      message: {
        smallDescription,
        startDate,
        endDate,
        maxUses,
        value,
        percentage,
        description,
        details,
        voucherCode,
        voucherUses,
        cumulative: false,
      },
    });
    if (result && !result.error) {
      dispatch(setSuccess("Promotion created successfully"));
      dispatch(promotionList({ page: 1, clear: true }));
    } else {
      rh.error(result?.description || "Error creating promotion!");
    }
  } catch (_ex) {
    rh.error("Error creating promotion!");
  }
  rh.close();
};

export default promotionCreateCall;
