import React from 'react';
import ListItem from '../../../../UI/List/ListItem/ListItem';
import { dateFormatter, currencyFormatter } from '../../../../../services/formatter';
import {
	withStyles,
} from '@material-ui/core';
import styles from './TripsItem.styles';

const TripsItem = ({
	classes,
	fields,
	trip,
}) => {

	const endDate = !!trip.endDate && dateFormatter.format(trip.endDate, 'dateTime');

	const startDate = !!trip.startDate && dateFormatter.format(trip.startDate, 'dateTime');
	
	const cost = !!trip.cost &&
	`${currencyFormatter.format(trip.cost)} ${currencyFormatter.symbol()}`;

	const item = (
		<ListItem
			className={classes.item}
			element={{
				...trip,
				cost,
				endDate,
				startDate,
			}}
			fields={fields}
		/>
	);

	return item;
};

export default withStyles(styles)(TripsItem);