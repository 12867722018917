import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const imoOffCall = async ({ updateAssetOnList }, dispatch, state, { carToken }) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "IMO/OFF");
  try {
    const result = await wsCall({
      type: "IMO",
      subType: "OFF",
      locationId: selectedLocation?.id,
      message: {
        carToken,
      },
    });
    if (result && !result.error) {
      dispatch(setSuccess("Disable immobiliser command sent successfully"));
      dispatch(updateAssetOnList(result));
    } else {
      rh.error(result?.description || "Error disabling immobiliser!");
    }
  } catch (_ex) {
    rh.error("Error disabling immobiliser!");
  }
  rh.close();
};

export default imoOffCall;
