import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid } from "@material-ui/core";
import { ReactComponent as ParkIcon } from "../../../../assets/Zones/parkZone.svg";
import { ReactComponent as WantedIcon } from "../../../../assets/Zones/wantedZone.svg";
import { ReactComponent as UnwantedIcon } from "../../../../assets/Zones/unwantedZone.svg";

import { styles } from "./AssetZonesAndParks.styles";

function AssetZonesAndParks({ classes, asset }) {
  // console.log("AssetZonesAndParks --- asset: %o", asset);
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.title}>Zones and Parks</Typography>
      </div>
      <Grid container direction="column" spacing={1}>
        {(asset?.assetZones || []).map((zone) => (
          <Grid item key={zone.id}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                {zone?.type === "NORMAL" ? (
                  <WantedIcon style={{ display: "block" }} />
                ) : (
                  <UnwantedIcon style={{ display: "block" }} />
                )}
              </Grid>
              <Grid item>
                <Typography>{zone.description}</Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
        {(asset?.parks || []).map((park) => (
          <Grid item key={park.id || park.parkId}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <ParkIcon style={{ display: "block" }} />
              </Grid>
              <Grid item>
                <Typography>{park.name}</Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default withStyles(styles)(AssetZonesAndParks);
