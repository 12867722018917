import { wsCall, requestHelper } from "../../../app/coreSlice";

const alertListCall = async (
  { setAlerts, appendAlerts },
  dispatch,
  state,
  options = { clear: false }
) => {
  const alertsFilter = state.getIn(["alerts"])?.alertsFilter;
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  if (alertsFilter?.isLastPage && !options.clear) {
    return;
  }
  let result;
  let rh = requestHelper(dispatch, "ALERT/LIST");
  try {
    result = await wsCall({
      type: "ALERT",
      subType: "LIST",
      locationId: selectedLocation?.id,
      message: {
        page: options.clear ? 1 : alertsFilter?.page || 1,
        locationId: alertsFilter?.locationId,
        resolved: alertsFilter?.resolved,
        limit: 30,
      },
    });
    if (alertsFilter?.page === 1 || options.clear) {
      dispatch(
        setAlerts({
          list: result?.alerts,
          totalPending: result?.total,
          isLastPage: result?.lastPage,
        })
      );
    } else {
      dispatch(
        appendAlerts({
          list: result?.alerts,
          totalPending: result?.total,
          isLastPage: result?.lastPage,
        })
      );
    }
  } catch (_ex) {
    rh.error("Error loading alerts.");
  }
  rh.close();
  return result;
};

export default alertListCall;
