import React from "react";
import Detail from "./Detail";
import DetailsDiv from "../../../UI/DetailsDiv/DetailsDiv";
import DetailsRow from "../../../UI/DetailsDiv/DetailsRow/DetailsRow";
import { withStyles } from "@material-ui/core";
import { paymentType } from "../../../../model/Payment/PaymentType";
import styles from "./Details.styles";
import { useSelector } from "react-redux";
import { selectSelectedCustomerPaymentInfo } from "../../../../features/customer/customerSlice";

const Payment = () => {
  const paymentInfo = useSelector(selectSelectedCustomerPaymentInfo);

  return (paymentInfo?.paymentMethod?.payment_method && paymentInfo?.paymentMethod?.payment_method !== "NO_PAYMENT") ||
    (paymentInfo?.paymentMethod?.credit_card_type && paymentInfo?.paymentMethod?.last_four_digits) ? (
    <div>
      <DetailsDiv title="Payment">
        {paymentInfo?.paymentMethod?.payment_method && (
          <DetailsRow
            label="Payment type"
            detail={<Detail detail={paymentType(paymentInfo?.paymentMethod?.payment_method)} />}
          />
        )}
        {paymentInfo?.paymentMethod?.card_data?.credit_card_type &&
          paymentInfo?.paymentMethod?.card_data?.last_four_digits && (
            <DetailsRow
              label="Card details"
              detail={
                <Detail
                  detail={
                    paymentInfo?.paymentMethod?.card_data?.credit_card_type +
                    " - " +
                    paymentInfo?.paymentMethod?.card_data?.last_four_digits
                  }
                />
              }
            />
          )}
      </DetailsDiv>
    </div>
  ) : null;
};

export default withStyles(styles)(Payment);
