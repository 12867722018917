const styles = (theme) => ({
	root: {
		width: '100%',
		display: 'flex',
	},

	searchIcon: {
		/* cursor: 'pointer', */
	},

	inputRoot: {
		color: 'inherit',
		width: '100%',
		borderBottom: '1px solid rgba(224, 224, 224, 1)',
	},

	inputInput: {
		paddingTop: theme.spacing(2),
		paddingRight: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		transition: theme.transitions.create('width'),
		width: '100%',
	},

	type: {
		flex: 1,
		margin: '5px',
	},

	subtype: {
		flex: 2,
		margin: '5px',
	},
});

export default styles;