import React from 'react';
import CarflowsList from '../CarflowsList/CarflowsList';
import Filters from '../TypeSubtypeFilter/TypeSubtypeFilter';
import {
	FormControl,
	InputLabel,
	Select,
	Typography,
	withStyles,
} from '@material-ui/core';
import withPermission from '../../../../hoc/withPermission';
import styles from './Flows.styles';
import classnames from 'classnames';

const Flows = ({
	carclub,
	carclubOptions,
	classes,
	className,
	filter,
	flows,
	handleCreate,
	onSelect,
	selected,
	setCarclub,
	setFilter,
}) => {

	const title = (
		<Typography className={classes.title}>
			Carflows
		</Typography>
	);

	const carclubSelectOptions = carclubOptions && carclubOptions
		.map(param => (
			<option value={param.id} key={param.id}>
				{param.name}
			</option>
		));

	const selectCarclub = (
		<FormControl className={classes.alignSelect}>
			<InputLabel htmlFor="selectIncidentType">
				Choose Carclub
	 		</InputLabel>
			<Select
				className={classes.selectEmpty}
				native
				onChange={e => setCarclub(e.target.value)}
				value={carclub}
				inputProps={{
					id: 'selectIncidentType',
				}}
			>
				<option value='' key='undefined' />
				{carclubSelectOptions}
			</Select>
		</FormControl>
	);

	const addCarflow = (
		<AddWithPermission
			className={classes.add}
			onClick={() => {
				onSelect(undefined);
				handleCreate.setOn();
			}} />
	);

	const filters = (
		<Filters
			filter={filter}
			setFilter={setFilter}
		/>
	);

	const list = !!flows && (
		<CarflowsList
			array={flows}
			onSelect={onSelect}
			selected={selected}
		/>
	);

	const flowsDiv = (
		<div className={classnames(classes.root, className)}>
			<div className={classes.div}>
				{title}
				{selectCarclub}
				{addCarflow}
			</div>
			<div className={classes.div}>
				{filters}
			</div>
			{list}
		</div>
	);

	return flowsDiv;
};

export default withStyles(styles)(Flows);

const AddWithPermission = withPermission(
	[{ resource: ['carclub', 'carflows', 'create'] }]
)((
	{ className, onClick }
) => (
	<Typography
		className={className}
		align='right'
		onClick={onClick}
	>
			Add carflow
	</Typography>
));