
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import pricingPlanMappers from './mappers/pricingPlanMappers';
import { publishAndAwait } from '../../../app/coreSlice';

export const listPricingPlans = async ({ filter, page }) => {

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'PRICING',
			subType: 'LIST',
			message: {
				name: (!!filter && filter.string) || undefined,
				page,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (error) {
		return;
	}

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
			message,
		} = processMessage;

		throw new NGError({
			message: description || message,
			code,
			tag,
		});
	};

	const {
		pricings,
		lastPage,
	} = processMessage || {};

	const modelArray = !!pricings && Array.isArray(pricings)
		&& pricings.map(pricingPlanDTO => pricingPlanMappers.fromDTO(pricingPlanDTO));

	return { pricings: modelArray, lastPage };
};

export default listPricingPlans;