import React, { useEffect, useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid, Button } from "@material-ui/core";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { styles } from "./MoveAssetForm.styles";
import { useSelector } from "react-redux";
import {
  assetChangeLocation,
  selectErrorMovingAsset,
  selectSelectedAsset,
  setErrorMovingAsset,
  setShowAssetDetails,
} from "../assetSlice";
import { SelectInput } from "../../../common/inputs";
import { selectUserLocations } from "../../user/userSlice";
import { useDispatch } from "react-redux";

function MoveAssetForm({ classes }) {
  const dispatch = useDispatch();
  const selectedAsset = useSelector(selectSelectedAsset);
  const locations = useSelector(selectUserLocations) || [];
  const [newLocation, setNewLocation] = useState();
  const errorMovingAsset = useSelector(selectErrorMovingAsset);

  useEffect(() => {
    if (selectedAsset.inUse) {
      dispatch(setErrorMovingAsset("The asset has an active trip. Close the trip to move to another location."));
    } else {
      dispatch(setErrorMovingAsset());
    }
    // eslint-disable-next-line
  }, []);

  const onUpdateHandler = () => {
    dispatch(assetChangeLocation({ carToken: selectedAsset?.assetToken, locationId: newLocation }));
  };

  return (
    <Grid container className={classes.root} direction="column" spacing={2} justifyContent="space-between">
      <Grid item>
        <Typography className={classes.title}>
          {`Move Asset to another location: ${selectedAsset?.licensePlate}`}
        </Typography>
      </Grid>
      <Grid item>
        <SelectInput
          label="Location"
          value={newLocation || selectedAsset?.locationId}
          options={locations.map((item) => ({
            text: item.name,
            value: item.id,
          }))}
          onChange={(locationId) => setNewLocation(locationId)}
        />
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={1} alignItems="center" wrap="nowrap">
          <Grid item>
            {errorMovingAsset ? (
              <InfoOutlinedIcon fontSize="small" color="error" style={{ display: "block" }} />
            ) : (
              <ReportProblemOutlinedIcon fontSize="small" style={{ display: "block" }} />
            )}
          </Grid>
          <Grid item>
            <Typography color={errorMovingAsset ? "error" : "inherit"}>
              {errorMovingAsset ||
                "Make sure to update the asset's configuration and to assign it to the relevant zone."}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="row" justifyContent="center" spacing={4}>
          <Grid item>
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => {
                dispatch(setShowAssetDetails(true));
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              size="small"
              disabled={Boolean(!newLocation || newLocation === selectedAsset?.locationId || errorMovingAsset)}
              onClick={onUpdateHandler}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(MoveAssetForm);
