const styles = () => ({
	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		flexWrap: 'nowrap',
		overflow: 'auto',
		scrollbarWidth: 'thin', //FIREFOX
		//CHROME
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},

	actionDiv: {
		height: 40,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},

	workspace: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'row',
		alingItems: 'center',
		flexWrap: 'nowrap',
		position: 'relative',
	},


	list: {
		width: '100%',
		height: '100%',
		paddingLeft: 21,
		paddingRight: 21,
		display: 'flex',
		flexDirection: 'column',
	},

	details: {
		minWidth: '284px',
		maxWidth: '284px',
		paddingRight: 21,
		overflowY: 'auto',
		scrollbarWidth: 'thin', //FIREFOX
		//CHROME
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},

	icon: {
		verticalAlign: 'middle',
	},

	title: {
		width: 220,
		fontSize: '22px',
		color: '#47525E',
		marginRight: 20,
	},

	createIcon: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		width: 26,
		height: 26,
	},

	searchbar: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		marginTop: 6,
	},

	titleAndSearch: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},

	actions: {
		display: 'flex',
		alignItems: 'center',
	},

	searchLabel: {
		minWidth: '400px',
	},

	//ERROR
	rootBar: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		margin: 'auto',
		textAlign: 'center',
		lineHeight: 'inherit',
		marginTop: 10,
		minHeight: 18,
		width: '300px',
		maxWidth: '300px',
		fontSize: '14px',
		borderRadius: 10,
		color: 'white',
		wordBreak: 'break-word',
	},

	showBar: {
		opacity: 1,
		transition: 'opacity 1000ms linear',
	},

	hideBar: {
		opacity: 0,
		transition: 'opacity 2500ms linear',
	},

	success: {
		backgroundColor: 'rgb(0, 182, 127)',
	},

	error: {
		backgroundColor: 'rgb(253, 46, 37)',
	},
});

export default styles;