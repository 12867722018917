
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_REQUEST_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import { publishAndAwait } from '../../../app/coreSlice';

const billingSendEmail = async ({code, accountCode, pdfUrl}) => {
	if (typeof code === 'undefined' || typeof accountCode === 'undefined') {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	};

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'BILLING',
			subType: 'SEND_INVOICE_USER',
			message: {
				code,
				accountCode,
				pdfUrl,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	};


	return true;
};

export default billingSendEmail;