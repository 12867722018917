const styles = () => ({

	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		alingItems: 'center',
		flexWrap: 'nowrap',
		position: 'relative',
	},

	searchBar: {
		paddingLeft: '16px',
		width: 388,
		heigth: 42,
	},

	list: {
		width: '100%',
		height: '100%',
		paddingLeft: 21,
		paddingRight: 21,
		display: 'flex',
		flexDirection: 'column',
	},

	details: {
		minWidth: '284px',
		maxWidth: '284px',
		paddingRight: 21,
		overflowY: 'auto',
	},
});

export default styles;