import createModel from '../createModel';

class WorkOrder {
	constructor({
		assetToken,
		assignAgent,
		assignAgentName,
		code,
		createDate,
		createUser,
		description,
		endDate,
		externalId,
		licensePlate,
		location,
		priority,
		startLatitude,
		startLongitude,
		scheduledEndDate,
		scheduledStartDate,
		servicePointCode,
		startDate,
		status,
		tasks,
		type,
	} = {}) {
		createModel.call(this, {
			assetToken,
			assignAgent,
			assignAgentName,
			code,
			createDate,
			createUser,
			description,
			endDate,
			externalId,
			licensePlate,
			location,
			priority,
			startLatitude,
			startLongitude,
			scheduledEndDate,
			scheduledStartDate,
			servicePointCode,
			startDate,
			status,
			tasks,
			type,
		});
	};
};

export default WorkOrder;