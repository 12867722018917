import {
	ACCOUNT_SET_ELEMENTS,
	ACCOUNT_SET_DETAILS,
	ACCOUNT_CLEAR_ELEMENTS,
	ACCOUNT_SET_LAST_PAGE,
} from '../../actions/account/actionTypes';
import * as mutate from './mutators';
import { pipe } from '../utils';
import createReducer from '../../utils/createReducer';
import { Map, OrderedMap } from 'immutable';

const initialState = Map({
	updateTime: null,
	elements: OrderedMap(),
	lastPage: true,
	page: 1,
});

const accountSetElements = (state, action) => {

	const accounts = action.elements;
	let newMap = OrderedMap();
	Array.isArray(accounts) && accounts.forEach( (account, i) => {
		newMap = newMap.set(account?.code, account);
	});
	return state.update('elements', map => {
		return map.merge(newMap);
	});

};

const accountClearElements = (state, action) => {
	return state.update('elements', map => map.clear());
};

const accountSetDetails = (state, action) => {
	if (!action.account) {
		return state;
	}

	return pipe([
		mutate.setElements([action.account], 'code'),
	], state);
};

const accountSetLastPage = (state, action) => {
	return pipe([
		mutate.setLastPage(action.lastPage),
	], state);
};

const accountReducer = createReducer(initialState, {
	[ACCOUNT_CLEAR_ELEMENTS]: accountClearElements,
	[ACCOUNT_SET_DETAILS]: accountSetDetails,
	[ACCOUNT_SET_ELEMENTS]: accountSetElements,
	[ACCOUNT_SET_LAST_PAGE]: accountSetLastPage,
});

export default accountReducer;
