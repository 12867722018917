export const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },

  title: {
    borderBottom: "2px solid rgba(0, 0, 0, 0.20)",
    fontSize: "15px",
    width: "inherit",
  },

	batterySerial: {
		fontSize: 11,
	},

  errorContainer: {
    position: "absolute",
    top: "5vh",
    marginLeft: "auto",
    marginRight: "auto",
    left: "10vw",
    width: "60vw",
    backgroundColor: "rgb(250, 179, 174)",
    textColot: "white",
    border: "2px solid rgb(244, 67, 54)",
    padding: theme.spacing(1),
    borderRadius: 3,
    height: 40,
    maxHeight: 40,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  errorTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "left",
  },

  errorText: {
    textColor: "rgb(250, 179, 174)",
    color: "white",
  },

  errorIcon: {
    fill: "rgb(244, 67, 54)",
    marginRight: 8,
  },

  closeIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    margin: 2,
    fill: "rgb(40, 40, 40)",
    cursor: "pointer",
  },

  timeAndPlaceRoot: {
    width: "100%",
    alignItems: "center",
    textAlign: "center",
  },

  timeAndPlaceContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    textAlign: "center",
  },

  waypointIconsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  startAndEndDetailsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexBasis: "60%",
  },

  arrowIcon: {
    verticalAlign: "middle",
    lineHeight: "40px",
  },

  tripData: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: "77px",
    textAlign: "center",
  },

  icons: {
    marginTop: "10px",
    flexBasis: "25%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  timeAndPlaceIcons: {
    margin: "5px",
    width: "30px",
    height: "30px",
  },

  timeAndPlaceText: {
    textAlign: "left",
    lineHeight: "15px",
    margin: "6px",
  },

  alignLeft: {
    textAlign: "left",
  },

  alignRight: {
    textAlign: "right",
  },

  noBorderIcon: {
    border: "none",
  },

  centerTextFlex: {
    flexBasis: "33%",
    color: "#89898B",
    textAlign: "center",
    verticalAlign: "bottom",
    fontWeight: "400",
  },

  costsContainer: {
    display: "flex",
    flexDirection: "row",
  },

  tripDataTitles: {
    display: "flex",
    justifyContent: "space-around",
  },

  flexHorizontal: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  spanStyle: {
    fontSize: 11,
    fontWeightRegular: 400,
    fontFamily: '"Montserrat", sans-serif',
    color: "#47525E",
  },

  otherInfo: {},

  fieldContainer: {
    width: "inherit",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "6px 0px",
  },

  textField: {
    padding: "0px 2px",
    fontSize: "11px",
  },

  textFieldTitle: {
    borderBottom: "2px solid rgba(0, 0, 0, 0.20)",
    fontSize: "11px",
  },

  park: {
    marginBottom: "10px",
  },
});
