import React from 'react';
import {
	Typography,
	TextField,
	withStyles,
} from '@material-ui/core';
import { styles } from './ChangePersonalInfo.styles';

const ChangePersonalInfo = ({
	classes,
	hasError,
	errorMessage,
	placeholder,
	onChangeHandler,
}) => {

	const title = (
		<Typography className={classes.title}>
            User Details
		</Typography>
	);

	const fullNameInput = (
		<TextField
			className={classes.field}
			label="Full Name"
			onChange={event => onChangeHandler('fullName', event.target.value)}
			value={placeholder?.fullName || ''}
			required
			type="fullName"
		/>);

	const emailInput = (
		<TextField
			className={classes.field}
			label="Email"
			onChange={event => onChangeHandler('email', event.target.value)}
			value={placeholder?.email || ''}
			required
			type="email"
		/>);

	const mobileInput = (
		<TextField
			className={classes.field}
			label="Mobile"
			onChange={event => onChangeHandler('mobile', event.target.value)}
			value={placeholder?.mobile || ''}
			required
			type="text"
		/>);

	return (
		<div className={classes.root}>
			{title}
			<div className={classes.content}>
				{fullNameInput}
				{emailInput}
				{mobileInput}
			</div>
		</div>
	);
};

export default withStyles(styles)(ChangePersonalInfo);