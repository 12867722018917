import * as listItemTypes from '../../../UI/List/ListItem/ListItemTypes';
import * as screenSize from '../../../../model/ScreenSize/ScreenSize';

const usersFields = currentScreenSize => {
	const width = {
		[screenSize.DESKTOP]: 111,
		[screenSize.TABLET]: 78,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	const maxWidth = {
		[screenSize.DESKTOP]: 166,
		[screenSize.TABLET]: 136,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	const height = {
		[screenSize.DESKTOP]: 35,
		[screenSize.TABLET]: 45,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];
	
	return [
		{
			key: 'icons',
			label: 'Actions',
			order: 99,
			pinned: true,
			width,
			height,
			type: listItemTypes.COMPONENT,
			flexDirection: 'row-reverse',
		},
		{
			key: 'name',
			label: 'Designation',
			order: 1,
			pinned: true,
			width,
			maxWidth: width,
			height,
			type: listItemTypes.COMPONENT,
		},
		{
			key: 'itemCount',
			label: 'No. Images',
			order: 2,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.COMPONENT,
		},
		{
			key: 'createDate',
			label: 'Create date',
			order: 3,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'createUser',
			label: 'Created by',
			order: 4,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'startDate',
			label: 'Start',
			order: 5,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'endDate',
			label: 'End',
			order: 6,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'enabled',
			label: 'Enabled',
			order: 7,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.COMPONENT,
		},
	];
};

export default usersFields;