import React, { useState, useCallback } from 'react';
import UpsertPark from './UpsertPark';

const UpsertParkWrapper = ({
	park = {},
	upsertPark,
	...otherProps
}) => {

	const [placeholder, setPlaceholder] = useState({ ...park });
	const [error, setError] = useState();

	const onChange = useCallback(field =>
		setPlaceholder(prev => ({ ...prev, ...field })), []);

	return (
		<UpsertPark
			error={error}
			setError={setError}
			placeholder={placeholder}
			setPlaceholder={onChange}
			park={park}
			upsertParkHandler={upsertParkHandler}
			upsertPark={upsertPark}
			{...otherProps}
		/>
	);
};

const upsertParkHandler = async (
	polygon,
	code,
	name,
	description,
	latitude,
	longitude,
	maxSpots,
	setError,
	stopDrawing,
	upsertPark,
	park,
) => {

	let requiredFieldsMsg = '';

	if (!name || name.trim().getLength === 0) {
		requiredFieldsMsg += 'name';
	}

	if (!description || description.trim().getLength === 0) {
		if (requiredFieldsMsg.length > 0) {
			requiredFieldsMsg += ', ';
		}
		requiredFieldsMsg += 'description';
	}

	if (!latitude) {
		if (requiredFieldsMsg.length > 0) {
			requiredFieldsMsg += ', ';
		}
		requiredFieldsMsg += 'latitude';
	}

	if (!longitude) {
		if (requiredFieldsMsg.length > 0) {
			requiredFieldsMsg += ', ';
		}
		requiredFieldsMsg += 'longitude';
	}

	if (requiredFieldsMsg.length > 0) {
		setError('Please make sure you have the fields: ' + requiredFieldsMsg);
		return;
	}

	let geo_information = [];

	if (!code) {
		const path = polygon.getPath();
		const points = path.getLength();

		for (let i = 0; i < points; i++) {
			geo_information = [...geo_information, [
				path.getAt(i).lat(),
				path.getAt(i).lng(),
			]];
		};

		geo_information = geo_information.join(' ').replace(/\s/g, '],[');
		geo_information = `[${geo_information}]`;
	}

	if (geo_information.length === 0) {
		geo_information = park.geoLocation;
	}


	const data = {
		code,
		name,
		description,
		latitude,
		longitude,
		maxSpots,
		geoLocation: geo_information,
	};

	await upsertPark(data);
	stopDrawing();
};

export default UpsertParkWrapper;
