export const styles = theme => ({

	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		height: '100%',
		width: '100%',
	},

	horizontalFlex: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: '100%',
		width: '100%',
	},

	title: {
		fontSize: 16,
		boxSizing: 'border-box',
		textAlign: 'left',
		width: '100%',
		paddingBottom: 10, 
	},

	button: {
		padding: 10,
		textAlign: 'center',
	},

	rootBar: {
		position: 'absolute',
		top: 0,
		left: 'calc(50% - 150px)',
		textAlign: 'center',
		lineHeight: 'inherit',
		marginTop: 10,
		height: 18,
		width: '300px',
		fontSize: '14px',
		borderRadius: 10,
		color: 'white',
	},

	showBar: {
		opacity: 1,
		transition: 'all 1000ms linear',
	},

	hideBar: {
		opacity: 0,
		transition: 'all 2500ms linear',
	},

	success: {
		backgroundColor: 'rgb(0, 182, 127)',
	},

	error: {
		backgroundColor: 'rgb(253, 46, 37)',
	},


});
