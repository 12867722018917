import React from 'react';
import ListItem from '../../../../UI/List/ListItem/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import styles from './MembersItem.styles';
import AddIcon from '@material-ui/icons/AddCircle';
import ShowTripsIcon from '@material-ui/icons/Assessment';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import withPermission from '../../../../../hoc/withPermission';
import classnames from 'classnames';

const MembersItem = ({
	addMember,
	classes,
	fields,
	isMember,
	member,
	onSelect,
	removeMember,
	selected,
	showCustomerTrips,
}) => {

	const tripsIcon = isMember && (
		<Tooltip title='Show member trips' placement='left'>
			<ShowTripsIcon
				onClick={() => showCustomerTrips(member.code)}
				className={classes.icon}
			/>
		</Tooltip>
	);

	const addIcon = !isMember && (
		<ButtonWithPermission title='Add member'>
			<AddIcon
				onClick={() => addMember([member])}
				className={classes.icon}
			/>
		</ButtonWithPermission>
	);

	const removeIcon = isMember && (
		<RemoveMemberButton
			classes={classes}
			className={classes.icon}
			onClick={() => removeMember(member.code)}
			title='Remove member'
		/>
	);

	const icons = (
		<>
			{addIcon}
			{removeIcon}
			{tripsIcon}
		</>
	);

	const item = (
		<ListItem
			fields={fields}
			onSelect={() => onSelect(member)}
			selected={selected}
			element={{
				...member,
				icons,
			}}
		/>
	);

	return item;
};

const ButtonWithPermission = withPermission(
	[{ resource: ['accounts', 'edit'] }]
)(({
	children,
	title,
}) =>
	(
		<Tooltip title={title} placement='left'>
			{children}
		</Tooltip>
	));

const RemoveMemberButton = withPermission([
	{
		resource: ['accounts', 'edit'],
		propFailure: { unauthorized: true },
	},
])(({
	classes,
	className,
	onClick,
	title,
	unauthorized,
}) =>
	(
		<Tooltip title={`${title}${unauthorized ? ' (Unauthorized)' : ''}`} placement='left'>
			<RemoveIcon
				onClick={() => !unauthorized && onClick()}
				className={classnames(className, unauthorized && classes.iconDisabled)}
			/>
		</Tooltip>
	));


export default withStyles(styles)(MembersItem);