import { isImmutable } from 'immutable';
import * as methods from '../../utils/makeActionCreator';

/**
 * Creates a reducer that enforces the use of immutable objects, given by the ImmutableJS lib.
 * This happens uppon reducer's creation and its every called action, including immutability
 * check on the state before the action takes place and. It performs a deep merge (see
 * #immutable/Map.mergeDeep()).
 * 
 * @param {object} initialState immutable object
 * @param {object} handlers object containg one or more '[ACTION_TYPE]: actionFunction'.
 *                          Each 'actionFunction' must return a Redux action'
 *                          Note: 'actionFunction' is not called here.
 * @returns reducer using ImmutableJS objects
 */
const createReducer = (initialState, handlers) => {

	const verifyImmutability = (object, objectString) => {
		if (!isImmutable(object)) {
			throw new Error(objectString + " is not immutable. Use the Immutable.js library.");
		}
	};

	verifyImmutability(initialState, "Initial state");

	return (state = initialState, action) => {

		if (!handlers.hasOwnProperty(action.type)) {
			return state;
		}
		
		verifyImmutability(state, "State");

		const changingState = handlers[action.type](state, action);
		verifyImmutability(changingState, "Reduced state");

		if(action.method === methods.REPLACE) {
			return changingState;
		}

		return state.mergeDeep(changingState);
	};
};

export default createReducer;