const ALL = { label: 'All Orders', subType: 'LIST_ACTIVE' };
const COMPLETED = { label: 'Completed', key: 'Completed', subType: 'LIST_CLOSED' };
const INPROCESS = { label: 'In Process', key: 'In Process', color: '#fc0', subType: 'LIST_ACTIVE' };
const OPEN = { label: 'Open', key: 'Open', color: '#f00', subType: 'LIST_ACTIVE' };
const DISCARDED = { label: 'Discarded', key: 'Discarded', color: '#f00', subType: 'LIST_ACTIVE' };

export const getStatus = workOrder => {
	if (Boolean(workOrder?.discarded)){
		return DISCARDED.key;
	};
	if (Boolean(workOrder.endDate)){
		return COMPLETED.key;
	};

	if (Boolean(workOrder.startDate)){
		return INPROCESS.key;
	};

	return OPEN.key;
};

const status = {
	ALL,
	COMPLETED,
	INPROCESS,
	OPEN,
};

export default status;