import React, {
	useState,
	useCallback,
	useEffect,
} from 'react';
import notificationService from '../../../../../services/notification';
import CreateOrEditNotification from './CreateOrEditNotification';
import log from '../../../../../utils/logger';

const logger = log('CreateOrEditNotificationWrapper');

const CreateOrEditNotificationWrapper = props => {

	const {
		exitCreateOrEditMode,
		openNotification,
	} = props;

	const [placeholder, setPlaceholder] = useState({});

	const [types, setTypes] = useState([]);
	const [subTypes, setSubTypes] = useState([]);
	const [placeholders, setPlaceholders] = useState([]);

	const [type, setType] = useState('');
	const [subType, setSubType] = useState('');

	const [hasNotification, setHasNotification] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [errorMessage, setErrorMessage] = useState();
	const [hasRequest, setHasRequest] = useState(false);

	const messageLength = (placeholder?.message || '').split('')?.length;

	//ERROR HANDLING
	const handleNotification = useCallback((errorMsg) => {
		const notificationTimeout = 2500;
		setErrorMessage(errorMsg);
		setHasNotification(true);
		setTimeout(() => {
			setHasNotification(false);
		}, notificationTimeout);
		setTimeout(() => {
			setIsSuccess(false);
		}, notificationTimeout * 2);
	}, []);

	const resetErrorHandler = useCallback(() => {
		setErrorMessage();
		setHasNotification(false);
		setIsSuccess(false);
	}, []);

	const setTypeHandler = useCallback(async (data) => {
		setType(data);
		try {
			const response = await notificationService.getSubTypesSMS(data);
			setSubTypes(response);
		} catch (error) {
			logger.info(error);
		}
	}, []);

	const setSubTypeHandler = useCallback(async (data) => {
		setSubType(data);
		try {
			const {
				body,
			} = await notificationService.getTemplateSMS(type, data);
			setPlaceholder(prev => ({ ...prev, message: body }));
		} catch (error) {
			logger.info(error);
		}
	}, [type]);

	const getTypesAndPlaceholders = useCallback(async () => {
		try {
			const {
				types,
				placeholders,
			} = await notificationService.getTypesAndPlaceholdersSMS();

			setTypes(types);
			setPlaceholders(placeholders);
		} catch (error) {
			logger.info(error);
		}
	}, []);

	const parseSelectedNotification = useCallback( async () => {
		if (!openNotification) {
			setType('');
			setSubType('');
			setPlaceholder({});
			return;
		};
		const { body, type, subType } = openNotification;
		await getTypesAndPlaceholders();
		await setTypeHandler(type);
		setSubType(subType);
		setPlaceholder({ ...openNotification, message: body });
	}, [openNotification, setTypeHandler, getTypesAndPlaceholders]);

	useEffect(()=> {
		parseSelectedNotification();
	}, [parseSelectedNotification]);

	useEffect(() => {
		getTypesAndPlaceholders();
	}, [getTypesAndPlaceholders]);

	const onChangeHandler = useCallback((key, value) => {
		setPlaceholder(prev => ({ ...prev, [key]: value }));
	}, []);

	const checkTypeAndSubtype = useCallback(() => {
		if (!type || type.split('')?.length < 0) {
			handleNotification('Please choose a specific type.');
			return false;
		};
		if (!subType || subType.split('')?.length < 0) {
			handleNotification('Please choose a specific subtype.');
			return false;
		};

		return true;
	}, [handleNotification, type, subType]);

	const createOrUpdateNotificationHandler = useCallback(async (event) => {
		event.preventDefault();
		try {
			setHasRequest(true);
			resetErrorHandler();
			if (messageLength > 160) {
				handleNotification('Message is limited to 160 characters.');
				return;
			};
			const canProceed = checkTypeAndSubtype();
			if (!canProceed) {
				return;
			};

			!placeholder?.id
				? await notificationService.createNotificationSMS({ ...placeholder, type, subType })
				: await notificationService.updateNotificationSMS({ ...placeholder, type, subType });

			exitCreateOrEditMode();
		} catch (error) {
			logger.warn(`Could not ${!placeholder?.id ? 'create' : 'edit'} sms notification. Error: ${error}`);
			if (error?.code === '004') {
				handleNotification('Notification with type-subtype pair already exists.');
				setHasRequest(false);
				return;
			}
			handleNotification('Unexpected error while trying to process request.');
			setHasRequest(false);
		}
	}, [
		placeholder, handleNotification, checkTypeAndSubtype,
		exitCreateOrEditMode, messageLength, resetErrorHandler, type, subType,
	]);

	const errorHandling = {
		errorMessage,
		isSuccess,
		hasNotification,
	};

	let placeholdersValue = '';

	(placeholders || []).forEach(el => {
		placeholdersValue += `${el}\n`;
	});

	return (
		<CreateOrEditNotification
			{...props}
			createOrUpdateNotificationHandler={createOrUpdateNotificationHandler}
			errorHandling={errorHandling}
			hasRequest={hasRequest}
			messageLength={messageLength}
			onChangeHandler={onChangeHandler}
			placeholder={placeholder}
			placeholdersValue={placeholdersValue}
			types={types}
			subTypes={subTypes}
			type={type}
			subType={subType}
			setTypeHandler={setTypeHandler}
			setSubTypeHandler={setSubTypeHandler}
		/>
	);
};

export default CreateOrEditNotificationWrapper;