import createModel from '../createModel';
import { dateFormatter } from '../../services/formatter';

class Template {
	constructor({
		carclubCode,
		code,
		description,
		locationCode,
		type,
		priority,
		tasks,
		createDate,
		createUser,
		updateDate,
		updateUser,
	} = {}) {
		createModel.call(this, {
			carclubCode,
			code,
			description,
			locationCode,
			type,
			priority,
			tasks: tasks || [],
			createDate: dateFormatter.format(createDate, 'dateTime'),
			createUser: createUser,
			updateDate: dateFormatter.format(updateDate, 'dateTime'),
			updateUser: updateUser,
		});
	};
};

export default Template;