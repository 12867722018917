import { wsCall, requestHelper } from "../../../app/coreSlice";

const carclubSearchLegalDocumentsCall = async ({ clearLegalDocuments, setLegalDocumentsFilter, setLegalDocuments }, dispatch, state, { filter, clear }) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  const { page, type, limit } = filter;
  let rh = requestHelper(dispatch, "CARCLUB/SEARCH_LEGAL_DOCUMENTS");
  let result;
  try {
    dispatch(setLegalDocumentsFilter(filter));
    if (clear) {
      dispatch(clearLegalDocuments());
    }
    result = await wsCall({
      type: "CARCLUB",
      subType: "SEARCH_LEGAL_DOCUMENTS",
      locationId: selectedLocation?.id,
      message: { page, type, limit },
    });

    if (!result.error) {
      dispatch(setLegalDocuments(result?.legalDocuments));
      // refresh reservations
    } else {
      rh.error(result?.description || "Error loading legal documents.");
    }
  } catch (ex) {
    console.error(ex);
    rh.error("Error loading legal documents.");
  }
  rh.close();
  return result;
};

export default carclubSearchLegalDocumentsCall;
