export const styles = () => ({
  root: {},
  image: {
    maxWidth: 140,
    maxHeight: 120,
    display: "block",
    margin: "0 auto",
  },
  noimage: {
    width: "100%",
    height: 100,
    backgroundColor: "#ccc",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
