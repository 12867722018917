import NGError from "../../../error/ngError";
import errorFactory from "../../../error/errorFactory";
import { WS_REQUEST_IS_MALFORMED, WS_PROCESS_MESSAGE_IS_MALFORMED } from "../../../error/websocket/errorTypes";
import mapper from "./mappers/workordersList";
import { publishAndAwait } from "../../../app/coreSlice";

const updateWorkOrder = async (workorder) => {
  console.log("updateWorkOrder: %o", workorder);

  if (typeof workorder === "undefined") {
    throw errorFactory(WS_REQUEST_IS_MALFORMED);
  }

  const request = await publishAndAwait({
    destination: "/app/hello",
    body: {
      type: "WORKTASK",
      subType: "CREATE",
      message: {
        ...mapper.toDTO(workorder),
      },
    },
  });

  const { processMessage, error } = request.response;

  if (!processMessage) {
    throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
  }

  if (error) {
    const { description, code, tag } = processMessage;

    throw new NGError({
      message: description,
      code,
      tag,
    });
  }

  return mapper.fromDTO(processMessage);
};

export default updateWorkOrder;
