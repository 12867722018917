import React from 'react';
import Filter from '../../../UI/Searchbar/Searchbar';
import Button from '../../../UI/Buttons/CommonButtons';
import {
	ListItem,
	ListSubheader,
	Tooltip,
	Typography,
	withStyles,
} from '@material-ui/core';
import withPermission from '../../../../hoc/withPermission';
import styles from './PermissionsList.styles';
import classnames from 'classnames';

const PermissionsList = ({
	classes,
	className,
	createPermission,
	filter,
	onSearch,
	permissions,
	divRef,
	selected,
	setFilter,
	setSelected,
}) => {

	const title = (
		<Typography className={classes.title}>
			Permissions
		</Typography>
	);

	const header = (
		<Typography className={classes.header}>
			Name
		</Typography>
	);

	const buttons = (
		<div className={classes.buttons}>
			<AddWithPermission
				onClick={createPermission}
			/>
		</div>
	);

	const listHeader = (
		<ListSubheader component='div'>
			<div className={classnames(classes.div, classes.headerDiv)}>
				{header}
				{buttons}
			</div>
		</ListSubheader>
	);

	const searchbar = (
		<Filter
			filter={filter}
			label='Name or designation'
			onSearch={() => onSearch(true)}
			setFilter={setFilter}
		/>
	);

	const listItems = permissions.map(permission => (
		<Tooltip
			key={'permission_' + permission.id}
			title={permission.description || ''}
			placement='top-start'
		>
			<ListItem

				className={classnames(classes.listItem,
					selected === permission.id && classes.selected)}
				onClick={() => setSelected(permission.id)}
			>
				<Typography>
					{permission.name || ''}
				</Typography>
			</ListItem>
		</Tooltip>
	));

	const list = (
		<div className={classes.list} ref={divRef}>
			{listItems}
		</div>
	);

	const flowsDiv = (
		<div className={classnames(classes.root, className)}>
			<div className={classes.div}>
				{title}
				{searchbar}
			</div>
			{listHeader}
			{list}
		</div>
	);

	return flowsDiv;
};

export default withStyles(styles)(PermissionsList);

const AddWithPermission = withPermission(
	[{ resource: ['carclub', 'permissions', 'create'] }]
)((
	{ onClick }
) =>
	(
		<Button
			buttonStyleName='filled'
			key='CreatePermission'
			label='New'
			onClick={onClick}
		/>
	)
);