import { createSlice } from "@reduxjs/toolkit";
import customerDashboardStatsCall from "./_sliceCalls/customerDashboardStats";
import dashboardReservationsBillingCall from "./_sliceCalls/dashboardReservationsBilling";
import reservationDashboardGetCall from "./_sliceCalls/reservationDashboardGet";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    filter: {
      customerMetrics: null,
      reservationMetrics: null,
      paymentMetrics: null,
    },
  },
  reducers: {
    setCustomerMetrics: (state, action) => {
      state.customerMetrics = action.payload;
    },
    setReservationMetrics: (state, action) => {
      state.reservationMetrics = action.payload;
    },
    setPaymentMetrics: (state, action) => {
      state.paymentMetrics = action.payload;
    },
  },
});

export const { setCustomerMetrics, setReservationMetrics, setPaymentMetrics } = dashboardSlice.actions;

export default dashboardSlice.reducer;

// SELECTS

export const selectCustomerMetrics = (state) => state.getIn(["dashboard"])?.customerMetrics;
export const selectReservationMetrics = (state) => state.getIn(["dashboard"])?.reservationMetrics;
export const selectPaymentMetrics = (state) => state.getIn(["dashboard"])?.paymentMetrics;

// CALLS

export const customerDashboardStats =
  ({ startDate, endDate }) =>
  (dispatch, getState) => {
    customerDashboardStatsCall(dashboardSlice.actions, dispatch, getState(), { startDate, endDate });
  };

export const reservationDashboardGet =
  ({ startDate, endDate }) =>
  (dispatch, getState) => {
    reservationDashboardGetCall(dashboardSlice.actions, dispatch, getState(), { startDate, endDate });
  };

export const dashboardReservationsBilling =
  ({ startDate, endDate }) =>
  (dispatch, getState) => {
    dashboardReservationsBillingCall(dashboardSlice.actions, dispatch, getState(), { startDate, endDate });
  };
