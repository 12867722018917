import React, {
	memo,
} from 'react';
import DashboardAssetIcon from './DashboardAssetIcon';
// import ComponentWithPophover from '../../UI/Pophover/Pophover' I forgot to add this to git @ home, fack
import {
	getFleetStatus,
} from '../../../model/Asset/FleetStatusType';
import {
	Popover,
	Typography,
	withStyles,
} from '@material-ui/core';
import styles from './DashboardAssetMarker.styles';
import classNames from 'classnames';

const DashboardAssetMarker = ({
	classes,
	asset,
	disabled,
	selectedAsset,
	lat,
	lng,
}) => {

	const [anchorEl, setAnchorEl] = React.useState(null);

	if (!lat || !lng) {
		return null;
	}

	const status = getFleetStatus(asset);

	const isSelected = typeof selectedAsset !== 'undefined'
		&& selectedAsset === asset.assetToken;

	const handlePopoverOpen = event => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<div
			className={classNames(
				classes.root,
				classes.centered,
				classes.hover,
				selectedAsset && classes.selected,
				disabled && classes.disabled,
			)}
			aria-owns={open ? 'mouse-over-popover' : undefined}
			aria-haspopup="true"
			onMouseEnter={handlePopoverOpen}
			onMouseLeave={handlePopoverClose}
		>
			<DashboardAssetIcon
				assetStatus={status}
				assetTypeName={asset.assetTypeName}
				tooltip={asset.code}
				isSelected={isSelected}
				hasSeriousProblem={false} //TODO:  This needs to be changed, obviously
			/>
			<Popover
				id="mouse-over-popover"
				style={{ pointerEvents: 'none'}}
				className={classes.popover}
				classes={{
					paper: classes.paper,
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: -30,
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'center',
				}}
				
			>
				<Typography>{`${status || `Unable to parse status`}: `}</Typography>
				<Typography>{`Plate: ${asset && asset.licensePlate}`}</Typography>
			</Popover>
		</div>
	);
};

// FIXME consider not defining the function
const DashboardAssetMarkerMemo = memo(
	DashboardAssetMarker,
	(prevProps, nextProps) => {

		const {
			lat: prevAssetLat,
			lng: prevAssetLng,
		} = prevProps.asset || {};

		const {
			lat: nextAssetLat,
			lng: nextAssetLng,
		} = nextProps.asset || {};

		return prevAssetLat === nextAssetLat
			&& prevAssetLng === nextAssetLng
			&& prevProps.selectedAsset === nextProps.selectedAsset;
	},
);

export default withStyles(styles)(DashboardAssetMarkerMemo);
