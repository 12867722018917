import React from 'react';
import Modal from '../../UI/Modal/Modal';
import ErrorDiv from '../../ErrorDiv/ErrorDiv';
import CommonButtons from '../../UI/Buttons/CommonButtons';
import {
	TextField,
	withStyles,
	Typography,
} from '@material-ui/core';
import styles from './UpsertPark.styles';

const UpsertPark = ({
	classes,
	error,
	park,
	placeholder,
	polygon,
	setError,
	setPlaceholder,
	stopDrawing,
	upsertPark,
	upsertParkHandler,
	defaultLocationFromUser,
}) => {

	const {
		code,
		name,
		description,
		latitude,
		longitude,
		maxSpots,
	} = placeholder || {};
	
	const title = (
		<Typography>
			{code ? `Update Park ${name}` : `Create Park`}
		</Typography>
	);

	const field = createField(setPlaceholder);

	const defaultLocationField = (
		<TextField
			defaultValue={defaultLocationFromUser?.name}
			id='defLocEditor'
			label='Location'
			disabled={true}
		/>
	);

	const descriptionField = field('Description', description, 'description', true);
	const latitudeField = field('Latitude', latitude, 'latitude', true);
	const longitudeField = field('Longitude', longitude, 'longitude', true);	
	const maxSpotsField = field('Max Spots', maxSpots, 'maxSpots', false);
	const nameField = field('Name', name, 'name', true);

	const errorDiv = !!error && (
		<ErrorDiv
			className={{ div: classes.errorDiv, text: classes.errorText }}
			message={error}
		/>
	);

	const cancelButton = <CommonButtons
		key='Cancel'
		buttonStyleName='void'
		onClick={() => {
			stopDrawing();
		}}
		label='Cancel'
	/>;

	const confirmButton = <CommonButtons
		key='Submit'
		buttonStyleName='filled'
		onClick={() => upsertParkHandler(
			polygon,
			code,
			name,
			description,
			latitude,
			longitude,
			maxSpots,
			error => setError(error),
			stopDrawing,
			upsertPark,
			park,
		)}
		label={code ? 'Update' : 'Create'}
	/>;

	const content = (
		<div className={classes.content}>
			{errorDiv}
			{title}
			{defaultLocationField}
			{nameField}
			{descriptionField}
			{latitudeField}
			{longitudeField}
			{maxSpotsField}
			<div className={classes.buttons}>
				{cancelButton}
				{confirmButton}
			</div>
		</div>
	);

	const modal = (
		<Modal
			content={content}
			className={classes.root}
		/>
	);

	return modal;
};

export default withStyles(styles)(UpsertPark);

const createField = func => (label, value, key, required) => (
	<TextField
		key={key}
		label={label}
		required={!!required}
		value={value || ''}
		onChange={event => func({[key]: event.target.value})}
		margin='normal'
	/>
);