export const styles = () => ({
  root: {
    width: 500,
    margin: 10,
    border: "1px solid #ddd",
    borderRadius: 5,
    boxShadow: "1px 1px 5px gray",
    padding: "0 10px",
    "& .FormFieldError": {
      display: "none",
    },
  },
  select: {
    width: 110,
    "& .MuiSelect-root": {
      fontSize: 11,
    },
    "& .MuiInputBase-root": {
      marginTop: 0,
    },
    "& .MuiFormLabel-root": {
      display: "none",
    },
  },
  search: {
    width: 350,
  },
  date: {
    width: 180,
  },
});
