import React from "react";
import DetailsDiv from "../../../UI/DetailsDiv/DetailsDiv";
import DetailsRow from "../../../UI/DetailsDiv/DetailsRow/DetailsRow";
import { Divider, withStyles } from "@material-ui/core";
import styles from "./Details.styles";
import classnames from "classnames";

const PricingPlans = ({ classes, className, divider, pricingPlans }) => {

  if (!pricingPlans || pricingPlans.length === 0) {
    return null;
  }

  const pricingPlanItems = pricingPlans.map((pricingPlan) => (
    <DetailsRow label={pricingPlan.name} key={`pricingPlan-${pricingPlan.id}`} />
  ));

  return (
    <DetailsDiv className={classnames(classes.root, className)} title="Pricing Plans">
      {pricingPlanItems}
      {divider && <Divider />}
    </DetailsDiv>
  );
};

export default withStyles(styles)(PricingPlans);
