import NGError from "../../../error/ngError";
import errorFactory from "../../../error/errorFactory";
import { WS_PROCESS_MESSAGE_IS_MALFORMED } from "../../../error/websocket/errorTypes";
import { publishAndAwait } from "../../../app/coreSlice";

const listAsset = async (deviceLicensePlate) => {

  const request = await publishAndAwait({
    destination: "/app/hello",
    body: {
      type: "ASSET",
      subType: "LIST",
      message: {
        deviceLicensePlate,
      },
    },
  });

  const { processMessage, error } = request.response;

  if (!processMessage) {
    throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
  }

  if (error) {
    const { description, code, tag, message } = processMessage;

    throw new NGError({
      message: description || message,
      code,
      tag,
    });
  }

  return processMessage;
};

export default listAsset;
