import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const damageReportResolveCall = async (
  { damageReportList, damageReportGetResolved },
  dispatch, state,
  { assetToken, damageToken }
) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "DAMAGE_REPORT/RESOLVE");
  try {
    const result = await wsCall({
      type: "DAMAGE_REPORT",
      subType: "RESOLVE",
      locationId: selectedLocation?.id,
      message: {
        assetToken,
        damageToken,
      },
    });
    if (!result.error) {
      dispatch(setSuccess("Damage resolved!"));
      dispatch(damageReportList({ assetToken }));
      dispatch(damageReportGetResolved({ assetToken }));
    } else {
      rh.error("Something went wrong, failed to resolve damage");
    }
  } catch (_ex) {
    rh.error("Something went wrong, failed to resolve damage.");
  }
  rh.close();
};

export default damageReportResolveCall;
