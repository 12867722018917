const styles = () => ({

	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexWrap: 'nowrap',
		backgroundColor: '#E5E5E5',
	},

	workspace: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'row',
		alingItems: 'center',
		flexWrap: 'no-wrap',
		position: 'relative',
	},

	menu: {
		position: 'absolute',
		zIndex: 999,
		backgroundColor: 'rgba(0,0,0,0.0)',
		border: 'none',
	},

});

export default styles;