import React from 'react';
import Buttons from './Buttons/Buttons';
import {
	MenuItem,
	TextField,
	Typography,
	withStyles,
} from '@material-ui/core';
import styles from './CreateOrEditNotification.styles';
import classnames from 'classnames';

const CreateOrEditNotification = ({
	classes,
	className,
	createOrUpdateNotificationHandler,
	errorHandling,
	exitCreateOrEditMode,
	messageLength,
	onChangeHandler,
	placeholder,
	placeholdersValue,
	types,
	subTypes,
	type,
	subType,
	setTypeHandler,
	setSubTypeHandler,
}) => {

	const {
		id,
		message,
	} = placeholder || {};

	const {
		errorMessage,
		isSuccess,
		hasNotification,
	} = errorHandling || {};

	const errorBar = (
		<Typography align='center' className={classnames(
			classes.rootBar,
			hasNotification ? classes.showBar : classes.hideBar,
			!!isSuccess ? classes.success : classes.error)}>
			{errorMessage}
		</Typography>
	);

	const title = (
		<Typography className={classes.title}>
			{`${!id ? 'Creating' : 'Editing'} SMS Template`}
		</Typography>
	);


	const typeOptions = types.map(option => (
		<MenuItem key={option} value={option}>
			{option}
		</MenuItem>
	));

	const subTypeOptions = subTypes.map(option => (
		<MenuItem key={option} value={option}>
			{option}
		</MenuItem>
	));

	const typeAndSubtype = (
		<div
			className={classnames(classes.typeAndSubtypeContainer)}
		>
			<Typography className={classes.typeAndSubtypeLabel}>
				Type:
			</Typography>
			<TextField
				className={classes.textFieldRoot}
				SelectProps={{ classes: { outlined: classes.textFieldSelect } }}
				id="notification-type-filter"
				select
				value={type || ''}
				onChange={event => setTypeHandler(event.target.value)}
				variant='outlined'
			>
				{typeOptions}
			</TextField>
			<Typography className={classes.typeAndSubtypeLabel}>
				Subtype:
			</Typography>
			<TextField
				className={classes.textFieldRoot}
				SelectProps={{ classes: { outlined: classes.textFieldSelect } }}
				id="notification-subtype-filter"
				select
				value={subType || ''}
				onChange={event => setSubTypeHandler(event.target.value)}
				variant='outlined'
			>
				{subTypeOptions}
			</TextField>

		</div>
	);

	const messageLabelField = (
		<Typography className={classes.subtitle14px} key={'label_message'}>
			Message
		</Typography>
	);

	const messageInputField = (
		<TextField
			InputLabelProps={{ classes: { root: classes.labelProps, focused: classes.labelProps } }}
			InputProps={{ classes: { root: classes.inputPropsMultiline } }}
			className={classes.fieldMultiline}
			classes={{ root: classes.multiline }}
			error={false}
			key={'input_message'}
			margin="normal"
			multiline
			onChange={(e) => onChangeHandler('message', e?.target?.value)}
			required={true}
			rows={5}
			value={message || ''}
			variant={'outlined'}
		/>
	);

	const messageLengthCount = (
		<Typography className={classnames(classes.messageLength, messageLength > 160 && classes.messageTooBig)}>
			{`${messageLength} / 160`}
		</Typography>
	);

	const messageInput = (
		<>
			{messageLabelField}
			{messageInputField}
			{messageLengthCount}
		</>
	);

	const placeholdersLabelField = (
		<Typography className={classes.subtitle14px} key={'label_placeholders'}>
			Placeholders
		</Typography>
	);

	const placeholdersInputField = (
		<TextField
			InputLabelProps={{ classes: { root: classes.labelProps, focused: classes.labelProps } }}
			InputProps={{ classes: { root: classes.inputPropsMultiline } }}
			className={classes.fieldMultiline}
			classes={{ root: classes.multiline }}
			error={false}
			key={'input_placeholders'}
			margin="normal"
			multiline
			onChange={(e) => onChangeHandler('placeholders', e?.target?.value)}
			required={false}
			minRows={5}
			value={placeholdersValue || ''}
			variant={'outlined'}
		/>
	);

	const placeholdersInput = (
		<>
			{placeholdersLabelField}
			{placeholdersInputField}
		</>
	);

	return (
		<div className={classnames(classes.root, className)}>

			<form
				className={classes.contentContainer}
				onSubmit={createOrUpdateNotificationHandler}
			>
				<div className={classes.content}>
					{errorBar}
					{title}
					{typeAndSubtype}
					{messageInput}
					{placeholdersInput}
				</div>
				<Buttons
					closeHandler={exitCreateOrEditMode}
				/>
			</form>
		</div>
	);
};

export default withStyles(styles)(CreateOrEditNotification);