import ValidatorBuilder from './validatorBuilder';

const validatorFactory = function () {

	const types = {
    
		VIN: builder => builder
			.length({ min: 17, max: 17 })
			.letterAndNumbersOnly()
			.build(),
	};

	this.create = (type) => {
		const builder = new ValidatorBuilder();
		const validatorType = types[type];
		return validatorType(builder);
	};
};

export default validatorFactory;