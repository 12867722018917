import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Grid, IconButton } from "@material-ui/core";
import * as Yup from "yup";

import { styles } from "./CustomerListFilter.styles";
import { Search as SearchIcon } from "@material-ui/icons";
import { Form, TextField } from "../../../../common/forms";
import CustomerStateFilter from "./CustomerStateFilter/CustomerStateFilter";
import { customerNewList, selectCustomers } from "../../customerSlice";

function CustomerListFilter({ classes }) {
  const dispatch = useDispatch();
  const loading = false; //useSelector(selectIsLoadingTrips);
  const filter = useSelector(selectCustomers)?.filter;
  const [selectedStates, setSelectedStates] = useState([]);

  useEffect(() => {
    setSelectedStates(filter.states || []);
  }, [filter.states]);

  const onSubmit = (values) => {
    const { search } = values;
    let nFilter = {
      onboarded: selectedStates.find((item) => item === "ONBOARDING") ? false : undefined,
      agent: selectedStates.find((item) => item === "AGENT") ? true : undefined,
      available: selectedStates.find((item) => item === "DISABLED") ? false : undefined,
      customerCode: search || undefined,
      email: search || undefined,
      mobileNumber: search || undefined,
      name: search || undefined,
      nif: search || undefined,
      search,
    };
    console.log("nFilter: %o", nFilter);
    dispatch(
      customerNewList({
        filter: nFilter,
        reset: true,
      })
    );
  };

  return (
    <div className={classes.root}>
      <Form
        schema={Yup.object().shape({
          search: Yup.string(),
        })}
        initialValues={filter}
        onSubmit={onSubmit}
        render={() => {
          return (
            <Grid
              container
              spacing={2}
              direction="row"
              wrap="nowrap"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <TextField id="search" className={classes.search} placeholder="Name, email, phone or tax number" />
              </Grid>

              <Grid item>
                <CustomerStateFilter
                  states={selectedStates}
                  onSelectStatesChange={(options) => setSelectedStates(options)}
                />
              </Grid>
              <Grid item>
                <IconButton type="submit" disabled={Boolean(loading)}>
                  <SearchIcon />
                </IconButton>
              </Grid>
            </Grid>
          );
        }}
      />
    </div>
  );
}

export default withStyles(styles)(CustomerListFilter);
