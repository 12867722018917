import React from "react";
import withStyles from "@material-ui/styles/withStyles";

import { styles } from "./_base.styles";
import AutoCompleteInput from "./AutoCompleteInput";
import { useDateTime } from "../hooks";

function TimeZoneInput({classes, ...props }) {
  const { getAllTimeZones, getCurrentTimeZone } = useDateTime();
  const options = (getAllTimeZones() || []).map((item) => {
    return {
      value: item.tz,
      text: item.designation,
    };
  });
  return (
    <div className={classes.root}>
      <AutoCompleteInput {...props} options={options} defaultValue={getCurrentTimeZone()} />
    </div>
  );
}

export default withStyles(styles)(TimeZoneInput);
