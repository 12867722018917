import * as screenSize from '../../../model/ScreenSize/ScreenSize'; 
import * as listItemTypes from '../../UI/List/ListItem/ListItemTypes';

const pricingPlanDetail = currentScreenSize => {
	const width = {
		[screenSize.DESKTOP]: 111,
		[screenSize.TABLET]: 78,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	const maxWidth = {
		[screenSize.DESKTOP]: 166,
		[screenSize.TABLET]: 136,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	const height = {
		[screenSize.DESKTOP]: 35,
		[screenSize.TABLET]: 45,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	return [
		{
			key: 'editIcons',
			label: '',
			order: 99,
			pinned: true,
			width,
			height,
			type: listItemTypes.COMPONENT,
			flexDirection: 'row-reverse',
		},
		{
			key: 'description',
			label: 'Description',
			order: 1,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'timeStartMin',
			label: 'Start',
			order: 2,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'timeEnd',
			label: 'End',
			order: 3,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'distancePrice',
			label: 'Distance Price',
			order: 4,
			pinned: false, //TODO Change to true
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
			flexDirection: 'row-reverse',
		},
		{
			key: 'timePrice',
			label: 'Time Price',
			order: 5,
			pinned: false, //TODO Change to true
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
			flexDirection: 'row-reverse',
		},
		{
			key: 'distanceOffer',
			label: 'Distance Offer',
			order: 7,
			pinned: false, //TODO Change to true
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'timeOffer',
			label: 'Time Offer',
			order: 8,
			pinned: false, //TODO Change to true
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'maxTimePrice',
			label: 'Max. Time Price',
			order: 6,
			pinned: false, //TODO Change to true
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
			flexDirection: 'row-reverse',
		},
		{
			key: 'enabled',
			label: 'Default',
			order: 9,
			pinned: false, //TODO Change to true
			width,
			maxWidth,
			height,
			type: listItemTypes.COMPONENT,
		},
	];
};

export default pricingPlanDetail;
