import { useState, useCallback, useEffect, useRef } from "react";
import assetService from "../../services/asset";
import moment from "moment";
import log from "../../utils/logger";

const logger = log("H3Map");

const initialState = {
  expanded: false,
  startDate: moment(),
  carclubs: ["emov", "car2go", "wible", "zity"],
  selectedCarclub: "zity",
  showHexagons: false,
  showAssets: true,
  showDemand: false,
  showPrediction: false,
};

const oldSP = [];

//ASSET ICONS
const urlDefault = "https://mbg-public.s3.eu-west-1.amazonaws.com/MBG/1594976000104car-map-36px.svg";
const urlCriticalFuel = "https://mbg-public.s3.eu-west-1.amazonaws.com/MBG/critical-fuel.svg";
const urlMaintenance = "https://mbg-public.s3.eu-west-1.amazonaws.com/MBG/mainteance-car.svg";

const useH3Map = ({ noAssets = false, noServicePoint = false }) => {
  const [returnedAPI, setReturnedAPI] = useState(null);
  const [directionsService, setDirectionsService] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);

  const [placeholderMap, setPlaceholderMap] = useState(initialState);

  const { startDate, showHexagons, showAssets, showDemand, showPrediction } = placeholderMap;

  const [servicePointMarker, setServicePointMarker] = useState();
  const [route, setRoute] = useState();

  const [mappedHexagons, setMappedHexagons] = useState([]);

  const [mappedDemand, setMappedDemand] = useState([]);
  const [mappedAssets, setMappedAssets] = useState([]);

  //eslint-disable-next-line
  const isMounted = useRef(false);
  const mapWasClosed = useRef(false);
  const currentlyOnMap = useRef(false);
  const routeIsDrawn = useRef(false);

  const selectedAsset = useRef();
  const externalOnChange = useRef();
  const exitMap = useRef();

  const [externalModal, setExternalModal] = useState();

  const selectedCarclubVal = placeholderMap?.selectedCarclub;

  const clearCarclubHexagonsFromMap = useCallback((hexagons) => {
    hexagons.forEach((hexagon) => !!hexagon?.setMap && hexagon.setMap(null));
  }, []);

  const clearDemandFromMap = useCallback((hexagons) => {
    hexagons.forEach((hexagon) => {
      if (!!hexagon?.setMap) {
        hexagon.setMap(null);
      }
      if (!!hexagon?.infoWindow) {
        hexagon.infoWindow.setMap(null);
        hexagon.infoWindow = null;
      }
    });
  }, []);

  //MAP PH ON CHANGE
  const mapPlaceholderOnChangeHandler = useCallback(
    (propertyString, data) => {
      if (propertyString === "selectedCarclub" && data !== selectedCarclubVal) {
        clearDemandFromMap(mappedDemand);
      }
      if (propertyString === "startDate" && data !== startDate) {
        clearDemandFromMap(mappedDemand);
      }
      if (propertyString === "showDemand" && data === true && !!showPrediction) {
        setPlaceholderMap((prev) => ({ ...prev, showPrediction: false, [propertyString]: data }));
        return;
      }
      if (propertyString === "showPrediction" && data === true && !!showDemand) {
        setPlaceholderMap((prev) => ({ ...prev, showDemand: false, [propertyString]: data }));
        return;
      }
      setPlaceholderMap((prev) => ({ ...prev, [propertyString]: data }));
    },
    [clearDemandFromMap, selectedCarclubVal, startDate, showDemand, showPrediction, mappedDemand]
  );

  useEffect(() => {
    try {
      if (!!returnedAPI && !directionsService && !directionsRenderer) {
        const googleDirectionsService = new returnedAPI.maps.DirectionsService();
        const googleDirectionsRenderer = new returnedAPI.maps.DirectionsRenderer();
        googleDirectionsRenderer.setMap(returnedAPI?.map);
        setDirectionsService(googleDirectionsService);
        setDirectionsRenderer(googleDirectionsRenderer);
      }
    } catch (error) {
      logger.warn("Error while trying to create google directions service and/or renderer.", error);
    }
  }, [returnedAPI, directionsService, directionsRenderer]);

  //MAP SETTERS FOR PREDICTION, DEMAND AND ASSETS
  const setMapForAllHexagons = useCallback(
    (map) => {
      mappedHexagons.forEach((hexagon) => {
        if (!!hexagon?.setMap) {
          hexagon.setMap(map);
        }
      });
    },
    [mappedHexagons]
  );

  const setMapForAllDemand = useCallback(
    (map) => {
      mappedDemand.forEach((hexagon) => {
        if (!!hexagon?.setMap) {
          hexagon.setMap(map);
        }
      });
    },
    [mappedDemand]
  );

  const setMapForAllAssets = useCallback(
    (map) => {
      !noAssets &&
        mappedAssets.forEach((asset) => {
          if (!!asset?.setMap) {
            asset.setMap(map);
          }
        });
    },
    [mappedAssets, noAssets]
  );

  //MAKES AND PAINTS ASSETS ON MAP
  const paintAssetsOnMap = useCallback(async () => {
    const assets = await assetService.listAvailableCoordinates();

    assets.forEach((asset) => {
      let url;
      url = urlDefault;
      if (!!asset?.criticalFuel) {
        url = urlCriticalFuel;
      }
      if (!!asset?.maintenance) {
        url = urlMaintenance;
      }
      const marker = new returnedAPI.maps.Marker({
        position: new returnedAPI.maps.LatLng(asset.lat, asset.lng),
        icon: url,
        title: asset.licensePlate,
        licensePlate: asset.licensePlate,
        assetToken: asset.assetToken,
        color: "#009BCC",
      });
      returnedAPI.maps.event.addListener(marker, "click", function (e) {
        externalOnChange.current({ assetToken: marker.assetToken, licensePlate: marker.licensePlate });
        selectedAsset.current = {
          assetToken: marker.assetToken,
          licensePlate: marker.licensePlate,
          location: marker.position,
        };
        mapPlaceholderOnChangeHandler("selectedAsset", {
          assetToken: marker.assetToken,
          licensePlate: marker.licensePlate,
        });
        exitMap.current();
      });
      setMappedAssets((prev) => [...prev, marker]);
      marker.setMap(returnedAPI.map);
    });
  }, [returnedAPI, mapPlaceholderOnChangeHandler]);

  function TrafficControl(controlDiv, map, trafficLayer) {
    // Set CSS for the control border.
    let controlUI = document.createElement("div");
    controlUI.style.backgroundColor = "#fff";
    controlUI.style.border = "2px solid #fff";
    controlUI.style.borderRadius = "3px";
    controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlUI.style.cursor = "pointer";
    controlUI.style.position = "absolute";
    controlUI.style.left = 65;
    controlUI.style.top = 28;
    controlUI.style.marginLeft = "9px";
    controlUI.style.textAlign = "center";
    controlUI.title = "Click to show/hide traffic";
    controlUI.style.height = "30px";
    controlUI.style.width = "65px";
    controlUI.style.display = "flex";
    controlUI.style.justifyContent = "center";
    controlUI.style.alignItems = "center";
    controlDiv.appendChild(controlUI);

    // Set CSS for the control interior.
    let controlText = document.createElement("div");
    controlText.style.color = "rgb(25,25,25)";
    controlText.style.fontFamily = "Roboto,Arial,sans-serif";
    controlText.style.fontWeight = "normal";
    controlText.style.fontSize = "16px";
    controlText.innerHTML = "Traffic";
    controlText.id = "traffic_control_button";
    controlUI.appendChild(controlText);

    // Setup the click event listeners: simply set the map to Chicago.
    controlUI.addEventListener("click", function () {
      if (trafficLayer.getMap() === null) {
        trafficLayer.setMap(map);
        document.getElementById("traffic_control_button").style.fontWeight = "500";
        return;
      }

      trafficLayer.setMap(null);
      document.getElementById("traffic_control_button").style.fontWeight = "normal";
    });
  }

  //H3 ONMOUNT && GET OPTIONS, PAINT H3 DATA (PREDICTION AND DEMAND) AND ASSETS
  useEffect(() => {
    if (!returnedAPI) return;

    try {
      if (!noServicePoint) {
        const trafficLayer = new returnedAPI.maps.TrafficLayer();

        const trafficControlDiv = document.createElement("div");
        new TrafficControl(trafficControlDiv, returnedAPI.map, trafficLayer);

        trafficControlDiv.index = 1;
        returnedAPI.map.controls[5].push(trafficControlDiv);
      }

      if (!noAssets) {
        paintAssetsOnMap();
      }
    } catch (error) {
      logger.warn("Could not parse geo data", error);
    }
    //I know ricardo, ill fix
    //eslint-disable-next-line
  }, [returnedAPI, noAssets, showHexagons]);

  //PAINT OR ERASE HEXAGONS ON CHANGE SHOW_HEXAGONS
  useEffect(() => {
    const map = showHexagons && showPrediction ? returnedAPI?.map : null;
    setMapForAllHexagons(map);
  }, [selectedCarclubVal, showHexagons, setMapForAllHexagons, returnedAPI, mappedHexagons, showPrediction]);

  //PAINT OR ERASE DEMAND ON CHANGE SHOW_DEMAND
  useEffect(() => {
    if (!returnedAPI) return;
    try {
      const map = showHexagons && showDemand ? returnedAPI?.map : null;
      setMapForAllDemand(map);
    } catch (error) {
      logger.warn("Could not parse geo data", error);
    }
    //eslint-disable-next-line
  }, [showDemand, showHexagons]);

  //PAINT OR ERASE MAPPED ASSETS ON CHANGE SHOW_ASSETS
  useEffect(() => {
    const map = showAssets ? returnedAPI?.map : null;
    setMapForAllAssets(map);
  }, [mappedAssets, showAssets, setMapForAllAssets, returnedAPI]);

  //ROUTE
  const paintRoute = useCallback(
    (endLocation) => {
      if (!selectedAsset.current || !directionsService || !directionsRenderer) {
        return;
      }
      try {
        const start = selectedAsset?.current?.location;
        const end = endLocation;
        const request = {
          origin: start,
          destination: end,
          travelMode: "DRIVING",
        };
        directionsRenderer.setMap(returnedAPI?.map);

        directionsService.route(request, function (result, status) {
          if (status === "OK") {
            directionsRenderer.setMap(returnedAPI?.map);
            setRoute(result);
            directionsRenderer.setDirections(result);
          }
        });
      } catch (error) {
        logger.info(error);
      }
    },
    [directionsService, directionsRenderer, selectedAsset, returnedAPI]
  );

  const paintExistingRoute = useCallback(() => {
    if (!!route && !!servicePointMarker && !routeIsDrawn.current) {
      try {
        servicePointMarker.setMap(returnedAPI?.map);
        paintRoute(servicePointMarker?.position);
        routeIsDrawn.current = true;
      } catch (error) {
        logger.info(error);
      }
    }
  }, [route, servicePointMarker, paintRoute, returnedAPI, routeIsDrawn]);

  //RELOAD MAP ON EXIT && RETURN
  useEffect(() => {
    if (!!currentlyOnMap.current && externalModal !== "MAP") {
      currentlyOnMap.current = false;
      mapWasClosed.current = true;
      routeIsDrawn.current = false;

      clearCarclubHexagonsFromMap(mappedHexagons);
      clearDemandFromMap(mappedDemand);
      setMappedHexagons([]);
      setMappedDemand([]);
      setPlaceholderMap(initialState);
    }
    if (!currentlyOnMap.current && externalModal === "MAP") {
      currentlyOnMap.current = true;
    }
    if (!!mapWasClosed.current && externalModal === "MAP") {
      mapWasClosed.current = false;
      currentlyOnMap.current = true;

      if (!noAssets) {
        paintAssetsOnMap();
      }

      servicePointMarker && servicePointMarker.setMap(returnedAPI?.map);
    }
  }, [
    externalModal,
    returnedAPI,
    mappedDemand,
    paintAssetsOnMap,
    selectedCarclubVal,
    servicePointMarker,
    noAssets,
    mappedHexagons,
    clearCarclubHexagonsFromMap,
    clearDemandFromMap,
    directionsRenderer,
    directionsService,
    route,
    paintExistingRoute,
    showHexagons,
  ]);

  //TO PUT SERVICE POINT ON MAP
  useEffect(() => {
    if (typeof returnedAPI?.maps === "undefined" || !!noServicePoint) {
      return;
    }
    returnedAPI.maps.event.addListener(returnedAPI.map, "click", function (event) {
      servicePointMarker && servicePointMarker.setMap(null);
      placeMarker(event.latLng);
    });

    function placeMarker(location) {
      try {
        const marker = new returnedAPI.maps.Marker({
          position: location,
          map: returnedAPI.map,
          animation: returnedAPI.maps.Animation.DROP,
          icon: "https://mbg-public.s3.eu-west-1.amazonaws.com/MBG/1594976086497service-point-2-36px.svg",
        });
        oldSP.push(marker);
        setServicePointMarker(marker);
        paintRoute(location);
      } catch (error) {
        logger.warn(error);
      }
    }
    //eslint-disable-next-line
  }, [returnedAPI, /* servicePointMarker, */ noServicePoint, directionsService, directionsRenderer, paintRoute]);

  useEffect(() => {
    oldSP.forEach((servicePoint, i) => {
      if (i === oldSP.length - 1) {
        return;
      }
      servicePoint.setMap && servicePoint.setMap(null);
    });
  }, [servicePointMarker]);

  return {
    showHexagons,
    showAssets,
    showDemand,
    showPrediction,
    placeholderMap,
    mapPlaceholderOnChangeHandler,
    setReturnedAPI,
    servicePointMarker,
    resetServicePointMarker: () => setServicePointMarker(),
    externalOnChange,
    externalModal,
    setExternalModal,
    exitMap,
    selectedAsset,
    route,
    paintExistingRoute,
  };
};


export default useH3Map;
