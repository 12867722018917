import * as screenSize from '../../../../model/ScreenSize/ScreenSize'; 
import * as listItemTypes from '../../../UI/List/ListItem/ListItemTypes';

const promotionFields = currentScreenSize => {
	const width = {
		[screenSize.DESKTOP]: 111,
		[screenSize.TABLET]: 78,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	const maxWidth = {
		[screenSize.DESKTOP]: 166,
		[screenSize.TABLET]: 136,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	const height = {
		[screenSize.DESKTOP]: 35,
		[screenSize.TABLET]: 45,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	return [
		{
			key: 'editIcon',
			label: '',
			order: 99,
			pinned: true,
			width,
			height,
			type: listItemTypes.COMPONENT,
			flexDirection: 'row-reverse',
		},
		{
			key: 'smallDescription',
			label: 'Promotion',
			order: 1,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'startDate',
			label: 'Start Date',
			order: 2,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'endDate',
			label: 'End Date',
			order: 3,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
		{
			key: 'enabled',
			label: 'Enabled',
			order: 5,
			pinned: false, //TODO Change to true
			width,
			maxWidth: width,
			height,
			type: listItemTypes.COMPONENT,
		},
	];
};

export default promotionFields;
