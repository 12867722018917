
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import mapper from './mappers/contactMapper';
import { publishAndAwait } from '../../../app/coreSlice';

const getGroups = async () => {

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'CHAT',
			subType: 'GET_USER_GROUPS',
		},
	});


	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}

	const contacts = processMessage.map(group => ({...mapper.fromDTO(group), pending: !group.acceptDate}));

	return contacts;
};

export default getGroups;