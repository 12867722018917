import { useEffect, useState } from 'react';

export const useInfinityScroll = (callback, elementId) => {

	const [isFetching, setIsFetching] = useState(false);
	const [heightCheck, setHeightCheck] = useState(0);

	//FIXME: improve this method dependencies? D:
	useEffect(() => {
		const fillPage = async () => {
			const ref = elementId.current;

			if (ref && !!callback && ref.hasChildNodes()) {
				setHeightCheck(ref.scrollHeight);

				if (ref.scrollHeight - ref.offsetHeight <= 0 
					&& heightCheck !== ref.scrollHeight) {
					await callback();

				};
			};
		};

		fillPage();
	}, [elementId, callback, heightCheck]);

	useEffect(() => {

		const handleScroll = async () => {
			const scrolled = elementId.current.offsetHeight + elementId.current.scrollTop;
			const divSize = elementId.current.scrollHeight;

			if (scrolled <= (divSize - 100)
				|| isFetching || !callback) {
				return;
			}
			
			setIsFetching(true);

			await callback();

			setIsFetching(false);
		};

		const currentDiv = elementId.current;

		currentDiv.addEventListener('scroll', handleScroll);

		return () =>
			currentDiv.removeEventListener('scroll', handleScroll);
	}, [elementId, callback, isFetching]);

	return isFetching;
};