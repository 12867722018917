const styles = (theme) => ({

	root: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		borderBottom: '1px solid rgba(224, 224, 224, 1)',
		color: '#47525E',
	},
	calendarItemRoot: {
		display: 'flex',
		width: '100%',
		height: 18,
		alignItems: 'center',
		color: '#47525E',
	},
	selected: {
		backgroundColor: theme.palette.grey[200],
		backgroundClip: 'content-box',
	},

	row: {
		cursor: 'pointer',
		padding: '0 16px',
		'&:hover': {
			backgroundColor: theme.palette.grey[200],
			backgroundClip: 'content-box',
		},
	},

	calendarRow: {
		cursor: 'pointer',
		padding: '0px !important',
		'&:hover': {
			backgroundColor: theme.palette.grey[200],
			backgroundClip: 'content-box',
		},
		borderTop: '1px solid rgba(0, 0, 0, 0.5)',
		borderLeft: '1px solid rgba(0, 0, 0, 0.5)',
		borderRight: '1px solid rgba(0, 0, 0, 0.5)',
	},

	lastRow: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.5)',
	},

	field: {
		flexGrow: 1,
		fontSize: '10px',
		display: 'flex',
		alignItems: 'center',
		overflow: 'hidden',
		padding: 5,
	},

	labelField: {
		flexGrow: 1,
		fontSize: '10px',
		display: 'flex',
		alignItems: 'center',
		overflow: 'hidden',
		justifyContent: 'center',
		padding: 10,
	},

	comp: {
		justifyContent: 'center',
	},
});

export default styles;
