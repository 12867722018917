import React, { useState, useCallback, useEffect, useRef } from 'react';
import workorderService from '../../../../services/workOrder';
import { getPublicLinks } from '../../../../services/S3';
import Tasks from './Tasks';
import log from '../../../../utils/logger';

const logger = log('Workorder>TaskWrapper');

const TasksWrapper = ({
	setTasks,
	tasks,
	workorder,
	...props
}) => {
	const [selectedTaskIndex, setSelectedTaskIndex] = useState();
	const [selectedTaskImages, setSelectedTaskImages] = useState([]);
	const [servicePoint, setServicePoint] = useState();

	const isMounted = useRef(false);
	const [hasChanges, setHasChanges] = useState(false);
	const [additionalInfo, setAdditionalInfo] = useState();
	const [taskListPlaceholder, setTaskListPlaceholder] = useState([]);

	useEffect(() => {
		isMounted.current = true;

		//Cleanup
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		const onMount = async () => {
			try {
				if (!!workorder?.servicePointCode) {
					const servicePoint = await workorderService.getServicePoint(workorder.servicePointCode);
					setServicePoint(servicePoint);
				}
				if (!!workorder?.startLatitude && !!workorder?.startLongitude) {
					setServicePoint({ lat: workorder?.startLatitude, lng: workorder?.startLongitude });
				}
			} catch (e) {
				logger.warn('Could not get service points');
			};
		};

		onMount();
	}, [workorder]);

	useEffect(() => {
		if (!!isMounted.current && Array.isArray(tasks)) {
			setTaskListPlaceholder([...tasks].map(e => ({ type: e.name, ...e })));
		};
	}, [tasks]);

	const onChangeTaskPlaceholderHandler = useCallback(description => {

		setAdditionalInfo(description);
		if (!hasChanges) setHasChanges(true);
		const arrayToModify = [...taskListPlaceholder];

		const task = { ...taskListPlaceholder?.[selectedTaskIndex], description };
		arrayToModify.splice(selectedTaskIndex, 1, task);

		setTaskListPlaceholder(arrayToModify);
	}, [hasChanges, selectedTaskIndex, taskListPlaceholder]);

	const onTaskSelect = useCallback(async index => {
		!taskListPlaceholder?.[index]?.pictures?.length > 0 && setSelectedTaskImages([]);

		if (taskListPlaceholder?.[index]?.pictures?.length > 0) {
			try {
				const pictures = await getPublicLinks(taskListPlaceholder?.[index]?.pictures || []);

				setSelectedTaskImages(pictures);
			} catch (e) {
				setSelectedTaskImages([]);
				logger.warn('Could not get pictures');
			};
		};

		setSelectedTaskIndex(index);
		setAdditionalInfo(taskListPlaceholder?.[index]?.description);
	}, [taskListPlaceholder]);

	const updateWorkOrder = useCallback(async () => {
		try {
			setHasChanges(false);

			const updatedWorkOrder =
				await workorderService.updateWorkorder({ ...workorder, tasks: [...taskListPlaceholder] });

			isMounted.current && setTasks(updatedWorkOrder?.tasks || []);
			isMounted.current && onTaskSelect();
		} catch (error) {
			logger.warn('Could not update work order.');
		}

	}, [setTasks, taskListPlaceholder, workorder, onTaskSelect]);

	const cancelEditHandler = () => {
		setHasChanges(false);
		Array.isArray(tasks) && setTaskListPlaceholder([...tasks]);
	};

	const onRemove = useCallback(async taskId => {
		try {

			await updateWorkOrder();
			await workorderService.removeTask({ taskId, worktaskId: workorder.code });

			setTasks(prev => [...prev.filter(({ code }) => code !== taskId)]);
		} catch (e) {
			logger.warn('Could not remove task');
		}
	}, [setTasks, workorder.code, updateWorkOrder]);

	return (
		<Tasks
			{...props}
			additionalInfo={additionalInfo}
			cancelEditHandler={cancelEditHandler}
			hasChanges={hasChanges}
			onChangeTaskPlaceholderHandler={onChangeTaskPlaceholderHandler}
			onRemove={onRemove}
			onTaskSelect={onTaskSelect}
			selectedTaskImages={selectedTaskImages}
			selectedTaskIndex={selectedTaskIndex}
			servicePoint={servicePoint}
			setTasks={setTasks}
			tasks={taskListPlaceholder}
			updateWorkOrder={updateWorkOrder}
			workorder={workorder}
			workorderService={workorder}
		/>
	);
};

export default TasksWrapper;