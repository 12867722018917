
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import customerFlowMapper from './mappers/customerFlowMapper';
import { publishAndAwait } from '../../../app/coreSlice';

const listCustomers = async (filters) => {

	const {
		GDPDR,
		...rest
	} = filters || {};

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'CUSTOMER',
			subType: 'NEW_LIST',
			message: {
				...rest,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}
	
	const {
		customers: customerList,
		lastPage,
	} = processMessage || {};

	const customers = customerList.map(customer => customerFlowMapper.fromDTO(customer));

	return {
		customers,
		lastPage,
	};
};

export default listCustomers;
