
import NGError from "../../../error/ngError";
import errorFactory from "../../../error/errorFactory";
import { WS_PROCESS_MESSAGE_IS_MALFORMED } from "../../../error/websocket/errorTypes";
import { publishAndAwait } from "../../../app/coreSlice";

const updateConfigs = async (configs) => {

	const request = await publishAndAwait({
		destination: "/app/hello",
		body: {
			type: "INVOICE",
			subType: "UPDATE_CONFIGS",
			message: configs,
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {
		const { description, code, tag } = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}
	return processMessage;
};

export default updateConfigs;
