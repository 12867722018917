const styles = theme => ({

	title: {
		display: 'flex',
		flexDirection: 'column',
	},

	selectText: {
		fontSize: '20px',
		borderBottom: '1px solid #8888',
		cursor: 'pointer',
		marginRight: '10px',
	},

	selectTitle: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		gap: '10px',
	},

	selectedText: {
		fontWeight: 'bold',
		borderBottom: '1px solid #08e',
	},

	subTitle: {
		fontSize: '14px',
		marginLeft: '15px',
	},

	icons: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
	},

	balance: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
	},

	balanceValue: {
		fontSize: '16px',
	},

	colorRed: {
		color: 'red',
	},

	header: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
	},

	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: '100%',
	},

	totalAmount: {
		display: 'flex',
		flexDirection: 'row-reverse',
		fontSize: '16px',
	},

	downloadIcon: {
		color: '#009AC9',
		cursor: 'pointer',
	},

});

export default styles;