import { wsCall, requestHelper } from "../../../app/coreSlice";

const princingPlanListCall = async ({ setPrincingPlansList }, dispatch, state) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "PRICING_PLAN/LIST");

  try {
    const result = await wsCall({
      type: "PRICING_PLAN",
      subType: "LIST",
      locationId: selectedLocation?.id,
      message: {},
    });
    if (result && !result.error) {
      dispatch(setPrincingPlansList(result));
    }
  } catch (_ex) {
    console.error(_ex);
    rh.error("Error loading princing plans!");
  }
  rh.close();
};

export default princingPlanListCall;
