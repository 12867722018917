export const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  title: {
		fontSize: '18px',
		padding: '8px 0',
	},
  divider: {
		height: "100%",
		borderLeft: '1px solid #E0E0E0',
		borderRight: '1px solid #E0E0E0',
		margin: "0 8px",
	}
});
