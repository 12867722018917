import React, { useState, useRef, useEffect } from 'react';
import { useCancellablePromise } from '../../../hooks/useCancellablePromise/useCancellablePromise.jsx';
import log from '../../../utils/logger.js';
import AccountFilters from './AccountFilters';

const logger = log('AccountFiltersWrapper');

const AccountFiltersWrapper = props => {

	const {
		filter,
		setFilter,
	} = props;

	const [string, setString] = useState('');

	const onSearch = reset => {

		reset
			? filter.default()
			: setFilter(prev => ({...prev, string, page: 1}));	
	};

	const isMounted = useRef(false);
	const [buttonTimeout, setButtonTimeout] = useState(null);

	const { makeCancellablePromise } = useCancellablePromise();

	useEffect(() => {

		const cleanup = () => {
			clearTimeout(buttonTimeout);
			isMounted.current = false;
		};
		return cleanup;
	}, [buttonTimeout]);

	const onSingleClick = (cb, timeout) => {

		if (!buttonTimeout) {

			const timerId = setTimeout(() => {
				setButtonTimeout(false);
				makeCancellablePromise(cb())
					.catch(error => logger.debug(error));
			}, timeout || process.env.REACT_APP_SINGLECLICK_TIMEOUT);

			setButtonTimeout(timerId);
		};
	};

	const onDoubleClick = cb => {

		if (!!buttonTimeout) {
			clearTimeout(buttonTimeout);
			setButtonTimeout(false);
		};

		makeCancellablePromise(cb())
			.catch(error => logger.debug(error));
	};

	return (
		<AccountFilters
			{...props}
			filter={string}
			onDoubleClick={onDoubleClick}
			onSearch={onSearch}
			onSingleClick={onSingleClick}
			setFilter={setString}
		/>
	);
};

export default AccountFiltersWrapper;
