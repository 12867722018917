import React from 'react';
import IncidentsList from './IncidentsList';
import incidentsFields from './incidentsFields';
import {
	UnfoldMore as SortIcon,
} from '@material-ui/icons';

const findCreateDate = element => element.key === 'createDate';
const findLastModified = element => element.key === 'lastModified';
const findIncidentCode = element => element.key === 'incidentCode';

const label = {
	cursor: 'pointer',
	display: 'flex',
	alignItems: 'center',
};

const IncidentsListWrapper = props => {

	const {
		currentScreenSize,
		lastPage,
		onSearch,
		onSort,
	} = props;

	const headerFields = incidentsFields(currentScreenSize);

	const createDateIndex = headerFields.findIndex(findCreateDate);
	const lastModifiedIndex = headerFields.findIndex(findLastModified);
	const incidentCodeIndex = headerFields.findIndex(findIncidentCode);

	const _getLabel = (field, index) => (
		<span
			style={label}
			onClick={() => {
				onSort(field);
				!lastPage && onSearch(true);
			}}
		>
			<SortIcon />
			{headerFields[index].label}
		</span>
	);
	
	headerFields[createDateIndex].label = _getLabel('createDate', createDateIndex);
	headerFields[lastModifiedIndex].label = _getLabel('lastModified', lastModifiedIndex);
	headerFields[incidentCodeIndex].label = _getLabel('incidentCode', incidentCodeIndex);

	const getIncidents = !props.lastPage && (() => props.onSearch());

	const incidentsList = (
		<IncidentsList
			{...props}
			getIncidents={getIncidents}
			incidentsFields={headerFields}
		/>
	);

	return incidentsList;
};

export default IncidentsListWrapper;