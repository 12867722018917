import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Grid, Typography } from "@material-ui/core";

import { styles } from "./PaymentActions.styles";
import { useSelector } from "react-redux";
import { selectIsCallsLoading } from "../../../../../app/coreSlice";
import { selectSelectedTrip, selectSelectedTripPaymentDetails } from "../../../reservationsSlice";
import SettleButton from "./SettleButton/SettleButton";
import ReviseButton from "./ReviseButton/ReviseButton";
import EditTripDataButton from "./EditTripDataButton/EditTripDataButton";
import ConfirmBillingButton from "./ConfirmBillingButton/ConfirmBillingButton";
import { useDateTime } from "../../../../../common/hooks";
import RefundButton from "./RefundButton/RefundButton";

function PaymentActions({ classes }) {
  const { getDaysAgo } = useDateTime();
  const loading = useSelector(
    selectIsCallsLoading(["BILLING/TO_BILL", "BILLING/SIMULATE_ADVANCED", "BILLING/SET", "BILLING/REVISE", "BILLING/REFUND_ADVANCED"])
  );
  const paymentDetails = useSelector(selectSelectedTripPaymentDetails);
  const reservation = useSelector(selectSelectedTrip);

  const { paid, onHold, preAmount, depositAmount, depositRefundAmount } = paymentDetails?.billingDetails?.billingItem || {};

  return (
    <Grid container className={classes.root} direction="column" spacing={1}>
      <Grid item>
        <Typography className={classes.title}>Actions</Typography>
      </Grid>
      <Grid item>
        <Grid container direction="row" justifyContent="space-evenly">
          {/* SETTLE */}
          {(reservation.state === "CLOSED" || reservation.state === "ABSENT") && !paid && !onHold && (
            <Grid item>
              <SettleButton loading={loading} reservationCode={reservation?.code} />
            </Grid>
          )}
          {/* REFUND */}
          {(reservation.state === "CLOSED" || reservation.state === "ABSENT") &&
             depositRefundAmount === 0 &&  (
              <Grid item>
                <RefundButton loading={loading} reservationCode={reservation?.code} depositAmount={depositAmount} carToken={reservation?.carToken} />
              </Grid>
            )}
          {/* REVISE */}
          {(reservation.state === "CLOSED" || reservation.state === "ABSENT") &&
            !onHold &&
            getDaysAgo(reservation?.endDate) < 60 && (
              <Grid item>
                <ReviseButton loading={loading} reservationCode={reservation?.code} />
              </Grid>
            )}
          {/* EDIT */}
          {(reservation.state === "CLOSED" || reservation.state === "ABSENT") && onHold && (
            <Grid item>
              <EditTripDataButton
                loading={loading}
                reservationCode={reservation?.code}
                carToken={reservation?.carToken}
                billingItemCosts={paymentDetails?.billingItemCosts}
                preAmount={preAmount}
              />
            </Grid>
          )}
          {/* CONFIRM */}
          {(reservation.state === "CLOSED" || reservation.state === "ABSENT") && onHold && (
            <Grid item>
              <ConfirmBillingButton loading={loading} reservationCode={reservation?.code} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(PaymentActions);
