import React from 'react';
import {
	Checkbox,
	InputAdornment,
	TextField,
	Typography,
	withStyles,
} from '@material-ui/core';
import { styles } from './CreatePricingPlanDetail.styles';
import ErrorDiv from '../../../ErrorDiv/ErrorDiv';
import CommonButtons from '../../../UI/Buttons/CommonButtons';
import { currencyFormatter } from '../../../../services/formatter';

const changeNumber = func => event => {
	const number = event.target.value;

	return !!Number(number) || Number(number) === 0
		? func.value.set(number)
		: undefined;
};

const startingValue = '0.0000';

const CreatePricingPlanDetail = ({
	classes,
	closeHandler,
	createPricingPlanDetailHandler,
	error,
	isEditing,
	onBack,
	placeholder,
	validatedInputs,
}) => {

	const {
		description,
		distanceOffer,
		distancePrice,
		isDefault,
		maxTimePrice,
		timeEnd,
		timeOffer,
		timePrice,
		timeStartMin,
	} = validatedInputs || {};

	const errorBar = error && (
		<ErrorDiv
			className={classes.errorText}
			message={error}
		/>
	);

	const title = (
		<Typography className={classes.title}>
			{isEditing ? 'Editing ' : 'Creating '}{'pricing plan detail'}
		</Typography>
	);

	const descriptionField = (
		<TextField
			InputLabelProps={{ classes: { root: classes.labelProps } }}
			InputProps={{ classes: { multiline: classes.greyTextField } }}
			error={!!description.value.state.error}
			fullWidth
			label={description.label}
			margin="normal"
			multiline
			onChange={(e) => description.value.set(e.target.value)}
			required={description.required}
			minRows="3"
			value={description.value.state.value || ''}
		/>
	);

	const timeStartMinField = (
		<TextField
			className={classes.field}
			classes={ { root: classes.equalMargin } }
			error={!!timeStartMin.value.state.error}
			label={timeStartMin.label}
			margin="normal"
			onChange={changeNumber(timeStartMin)}
			required={timeStartMin.required}
			type='number'
			value={timeStartMin.value.state.value}
		/>
	);

	const timeEndField = (
		<TextField
			className={classes.field}
			classes={ { root: classes.equalMargin } }
			error={!!timeEnd.value.state.error}
			label={timeEnd.label}
			margin="normal"
			onChange={changeNumber(timeEnd)}
			required={timeEnd.required}
			type='number'
			value={timeEnd.value.state.value}
		/>
	);

	const distancePriceField = (
		<TextField
			InputProps={{
				endAdornment: <InputAdornment position="end">{currencyFormatter.symbol()}</InputAdornment>,
			}}
			inputProps={{step: 0.0001}}
			className={classes.field}
			classes={ { root: classes.equalMargin } }
			error={!!distancePrice.value.state.error}
			label={distancePrice.label}
			margin="normal"
			onChange={changeNumber(distancePrice)}
			required={distancePrice.required}
			value={distancePrice.value.state.value || startingValue}
		/>
	);

	const timePriceField = (
		<TextField
			InputProps={{
				endAdornment: <InputAdornment position="end">{currencyFormatter.symbol()}</InputAdornment>,
			}}
			inputProps={{step: 0.0001}}
			className={classes.field}
			classes={ { root: classes.equalMargin } }
			error={!!timePrice.value.state.error}
			label={timePrice.label}
			margin="normal"
			onChange={changeNumber(timePrice)}
			required={timePrice.required}
			value={timePrice.value.state.value || startingValue}
		/>
	);

	const distanceOfferField = (
		<TextField
			InputProps={{
				endAdornment: <InputAdornment position="end">Km</InputAdornment>,
			}}
			className={classes.field}
			classes={ { root: classes.equalMargin } }
			error={!!distanceOffer.value.state.error}
			label={distanceOffer.label}
			margin="normal"
			onChange={changeNumber(distanceOffer)}
			required={distanceOffer.required}
			type='number'
			value={distanceOffer.value.state.value}
		/>
	);

	const timeOfferField = (
		<TextField
			InputProps={{
				endAdornment: <InputAdornment position="end">min</InputAdornment>,
			}}
			className={classes.field}
			classes={ { root: classes.equalMargin } }
			error={!!timeOffer.value.state.error}
			label={timeOffer.label}
			margin="normal"
			onChange={changeNumber(timeOffer)}
			required={timeOffer.required}
			type='number'
			value={timeOffer.value.state.value}
		/>
	);

	const maxTimePriceField = (
		<TextField
			InputProps={{
				endAdornment: <InputAdornment position="end">{currencyFormatter.symbol()}</InputAdornment>,
			}}
			inputProps={{step: 0.0001}}
			className={classes.field}
			classes={ { root: classes.equalMargin } }
			error={!!maxTimePrice.value.state.error}
			label={maxTimePrice.label}
			margin="normal"
			onChange={changeNumber(maxTimePrice)}
			required={maxTimePrice.required}
			value={maxTimePrice.value.state.value || startingValue}
		/>
	);

	const isDefaultCheckbox = (
		<div className={classes.codeCheckbox}>
			<Typography className={classes.checkboxTitle}>Default</Typography>
			<Checkbox
				checked={isDefault.value.state.value}
				color="primary"
				error={isDefault.value.state.error ? 1 : 0}
				onClick={() => {
					const current = isDefault.value.state.value;
					isDefault.value.set(!current);
				}}
			/>
		</div>
	);

	const cancelBtn = (
		<CommonButtons
			buttonStyleName='void'
			key='Cancel'
			label={!placeholder.id && placeholder.details.length < 1 ? 'Back' : 'Cancel'}
			onClick={!placeholder.id && placeholder.details.length < 1 ? onBack : closeHandler}
		/>
	);

	const createBtn = (
		<CommonButtons
			buttonStyleName='filled'
			key='CreatePromo'
			label={isEditing ? 'Save' : 'Add'}
			type='submit'
		/>
	);

	return (
		<div className={classes.root}>
			{errorBar}
			{title}
			<form onSubmit={(event) => createPricingPlanDetailHandler(event)}>
				<div className={classes.flexVertically}>
					{descriptionField}
					{timeStartMinField}
					{timeEndField}
					{distancePriceField}
					{timePriceField}
					{maxTimePriceField}
					{distanceOfferField}
					{timeOfferField}
					{isDefaultCheckbox}
				</div>
				<div className={classes.btns}>
					{cancelBtn}
					{createBtn}
				</div>
			</form>
		</div>
	);
};

export default withStyles(styles)(CreatePricingPlanDetail);
