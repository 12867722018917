import React, { Fragment, useState } from "react";
import Alert from "../../../ErrorDiv/Alert";
import { Grid, Typography, withStyles } from "@material-ui/core";
import { styles } from "./TimelineInfo.styles";
import ActivityLogList from "../../../../features/asset/AssetList/AssetItem/ActivityLog/ActivityLogList/ActivityLogList";
import Button from "../../../UI/Buttons/CommonButtons";
// import withPermission from '../../../../hoc/withPermission';
import classnames from "classnames";
import moment from "moment";
import TripAnalysis from "../../TripAnalysis/TripAnalysisWrapper";
import Modal from "../../../../common/displays/Modal/Modal";
import TripActivityMap from "../../../../features/bigdata/TripActivityMap/TripActivityMap";

const TimelineInfo = ({
  classes,
  className,
  trip,
  timelineInfo,
  errorMessage,
  clearErrorMessage,
  showAlert,
  modal,
  setModal,
}) => {
  const [modalMap, setModalMap] = useState(false);
  const logs = Array.isArray(timelineInfo) && timelineInfo;

  const sortedLogsByDayArray = logs && logs.length > 0 && sortActivityLogsByDay(logs);

  const getAndStyleLogs = () => {
    return sortedLogsByDayArray.map((logs) => {
      return timeSection(logs[0].date, logs, classes);
    });
  };
  const logLists = logs && logs.length > 0 && getAndStyleLogs();

  const errorAlert = showAlert && (
    <Alert type="error" errorMessage={errorMessage} clearErrorMessage={clearErrorMessage} />
  );

  const analysisModal = modal && <TripAnalysis open={!!modal} trip={trip} onClose={() => setModal(false)} />;

  return (
    <div className={classnames(classes.root, className)}>
      {analysisModal}
      {errorAlert}
      {logLists}

      <Grid container spacing={2} justifyContent="center" direction="row">
        <Grid item>
          <Button className={classes.moreInfo} label="Map" onClick={() => setModalMap(true)} />
          <Modal open={modalMap} setOpen={setModalMap}>
            <TripActivityMap startDate={trip.startDate} endDate={trip.endDate} deviceToken={trip.deviceToken} />
          </Modal>
        </Grid>
        <Grid item>
          <Button className={classes.moreInfo} key="more_info" label="More Info" onClick={() => setModal(true)} />
        </Grid>
      </Grid>
    </div>
  );
};
// TODO dateFormatter.format(foo, 'dateTime');

const sortActivityLogsByDay = (timelineLogs) => {
  let sortedLogs = [...(timelineLogs || [])];
  sortedLogs.sort((a, b) => new Date(b.date) - new Date(a.date));
  let sortedLogsByDay = [];
  // FIXME: this should use the dateFormatter service
  const mostRecentDay = moment(sortedLogs[0].date).format("DD-MM-YYYY");
  let currentDay = mostRecentDay;
  let dayArray = [];

  sortedLogs.forEach((log, index) => {
    // FIXME: this should use the dateFormatter service
    if (currentDay !== moment(log.date).format("DD-MM-YYYY")) {
      sortedLogsByDay.push(dayArray);
      dayArray = [];
      // FIXME: this should use the dateFormatter service
      currentDay = moment(log.date).format("DD-MM-YYYY");
    }
    if (index === sortedLogs.length - 1) {
      sortedLogsByDay.push(dayArray);
    }
    dayArray.push(log);
  });

  return sortedLogsByDay;
};

const timeSection = (title, timelineLogs, classes) => {
  // FIXME: this should use the dateFormatter service
  const parsedTitle = moment(title).format("DD-MM-YYYY");
  return (
    <Fragment key={"frag-" + title}>
      <Typography key={"text-" + parsedTitle} className={classes.timeTitle} variant="body1">
        {parsedTitle}
      </Typography>
      <ActivityLogList
        key={"list-" + parsedTitle}
        timelineLogs={timelineLogs}
        className={classes.timelineLogsList}
        timelineLogClassName={classes.timelineLogItem}
        disablePadding={true}
      />
    </Fragment>
  );
};

export default withStyles(styles)(TimelineInfo);
