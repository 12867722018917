
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_REQUEST_IS_MALFORMED,
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import { TRIP_TYPES } from '../../../containers/TripApp/reducer';
import { publishAndAwait } from '../../../app/coreSlice';


const getTripDetails = async (code, tripType) => {

	if (typeof code === 'undefined'
	|| typeof tripType === 'undefined') {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	}

	let subType = tripType === TRIP_TYPES.CURRENT
		? 'ONGOING_DETAILS'
		: 'CLOSED_DETAILS';

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'RESERVATION',
			subType,
			message: {
				reservationCode: code,
			},
		},
	});
	
	const { processMessage, error } = request.response;
	
	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		if (code === "001") {
			return null;
		}

		throw new NGError({
			message: description,
			code,
			tag,
		});
	}

	return processMessage;
};

export default getTripDetails;
