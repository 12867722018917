import React, { useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import {
  Typography,
  ClickAwayListener,
  Popper,
  MenuList,
  MenuItem,
  ListItemIcon,
  Tooltip,
  IconButton,
  Badge,
} from "@material-ui/core";
import AllIcon from "@material-ui/icons/InfoOutlined";

import { styles } from "./TripStateFilter.styles";
import reservationStatusIcon from "../../../../../assets/ReservationApp/icons/ReservationStatusIcon";

function TripStateFilter({ classes, onSelectStatesChange, states }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const onMenuItemClickedHandler = (option) => {
    let exists = states.find((item) => item === option);
    let nOptions;
    if (exists) {
      nOptions = states.filter((item) => item !== option);
    } else {
      nOptions = [...states, option];
    }
    onSelectStatesChange && onSelectStatesChange(nOptions);
  };
  const ReadyIcon = reservationStatusIcon["READY"];
  const OngoingIcon = reservationStatusIcon["ONGOING"];
  const ScheduledIcon = reservationStatusIcon["SCHEDULED"];

  return (
    <div className={classes.root}>
      <Tooltip title="Filter by State">
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
          <Badge color="primary" overlap="rectangular" invisible={!states || states.length === 0} variant="dot">
            <AllIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popper className={classes.menu} open={Boolean(anchorEl)} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <MenuList>
            {/* READY */}
            <MenuItem
              onClick={() => onMenuItemClickedHandler("READY")}
              className={states.find((item) => item === "READY") && classes.selected}
            >
              <ListItemIcon className={classes.icon}>
                <ReadyIcon style={{ color: "#00b67f" }} />
              </ListItemIcon>
              <Typography>Ready</Typography>
            </MenuItem>
            {/* ONGOING */}
            <MenuItem
              onClick={() => onMenuItemClickedHandler("ONGOING")}
              className={states.find((item) => item === "ONGOING") && classes.selected}
            >
              <ListItemIcon className={classes.icon}>
                <OngoingIcon style={{ color: "#0092e9" }} />
              </ListItemIcon>
              <Typography>Ongoing</Typography>
            </MenuItem>
            {/* SCHEDULED */}
            <MenuItem
              onClick={() => onMenuItemClickedHandler("SCHEDULED")}
              className={states.find((item) => item === "SCHEDULED") && classes.selected}
            >
              <ListItemIcon className={classes.icon}>
                <ScheduledIcon style={{ color: "#333" }} />
              </ListItemIcon>
              <Typography>Scheduled</Typography>
            </MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}

export default withStyles(styles)(TripStateFilter);
