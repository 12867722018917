import React from 'react';
import List from '../../../UI/List/List';
import * as listItemTypes from '../../../UI/List/ListItem/ListItemTypes';
import CarflowItem from './CarflowsItem/CarflowsItem';
import {
	withStyles,
} from '@material-ui/core';
import styles from './CarflowsList.styles';
import classnames from 'classnames';

const listConfigs = [
	{
		key: 'carflow',
		label: '',
		order: 1,
		pinned: true,
		height: 35,
		type: listItemTypes.COMPONENT,
	},
];

const CarflowsList = ({
	classes,
	className,
	array,
	onSelect,
	selected,
}) => {

	const carflows = !!array && array.map(carflow => (
		<CarflowItem
			carflow={carflow}
			onSelect={onSelect}
			selected={!!selected && selected.id === carflow.id}
			key={carflow.id}
		/>
	));

	return (
		<List
			className={classnames(classes.root, className)}
			headerFields={listConfigs}
			noHeader={true}
		>
			{carflows}
		</List>
	);
};

export default withStyles(styles)(CarflowsList);