import React from 'react';
import Button from '../../../UI/Buttons/CommonButtons';
import rolesToString from '../../../../model/Carclub/rolesToString';
import {
	FormControl,
	InputBase,
	InputLabel,
	Select,
	Typography,
	withStyles,
} from '@material-ui/core';
import styles from './UpdateUser.styles';
import classnames from 'classnames';

const labels = [
	{ key: 'login', label: 'Login', isRequired: true },
	{ key: 'email', label: 'Email', isRequired: true },
	{ key: 'mobile', label: 'Mobile', isRequired: true },
	{ key: 'name', label: 'Full Name', isRequired: true },
];

const UpdateUser = ({
	classes,
	error,
	hasRequest,
	onCancel,
	onChange,
	onSave,
	placeholder,
	roles,
}) => {

	const isEditing = !!placeholder.code;

	const currentRole = (!!roles
		&& (roles.find(role => role.name === placeholder.role
			|| role.id === placeholder.role) || {}).id) || '';

	const onError = !!error && (
		<Typography className={classes.error} align='center'>
			{typeof error === 'string' ? error : 'Please fill all the fields'}
		</Typography>
	);

	const title = (
		<Typography className={classes.title}>
			{isEditing ? 'Edit User' : 'Add User'}
		</Typography>
	);

	const input = ({ label, key, isRequired = false }) => {
		return (
			<FormControl key={key} required={isRequired}>
				<InputLabel>
					{label}
				</InputLabel>
				<InputBase
					classes={{
						root: classes.inputRoot,
						input: classnames(classes.inputInput,
							!!error && !placeholder[key] && classes.inputError),
					}}
					disabled={isEditing}
					value={placeholder[key] || ''}
					onChange={event =>
						onChange({ [key]: event.target.value })}
				/>
			</FormControl>
		);
	};

	const role = (
		<FormControl key='role'>
			<InputLabel>
				Role
			</InputLabel>
			<Select
				native
				onChange={e => onChange({ role: e.target.value })}
				displayEmpty
				className={classnames(classes.select,
					!!error && !placeholder.role && classes.selectError)}
				value={currentRole}
			>
				<option value='' key='_blank'></option>
				{!!roles && roles.map(role => (
					<option value={role.id} key={role.id}>
						{rolesToString(role.name)}
					</option>
				))}
			</Select>
		</FormControl>
	);

	const fields = labels.map(input);

	const form = (
		<div className={classes.form}>
			{fields}
			{role}
		</div>
	);

	const cancelButton = (
		<Button
			buttonStyleName='void'
			disabled={!!hasRequest}
			key='CancelButton'
			label='Cancel'
			onClick={() => onCancel()}
		/>
	);

	const saveButton = (
		<Button
			buttonStyleName='filled'
			disabled={!!hasRequest}
			key={isEditing ? 'UpdateButton' : 'SaveButton'}
			label={isEditing ? 'Update' : 'Save'}
			onClick={onSave}
		/>
	);

	const buttons = (
		<div className={classes.buttons}>
			{cancelButton}
			{saveButton}
		</div>
	);

	return (
		<div className={classes.root}>
			{title}
			{form}
			{onError}
			{buttons}
		</div>
	);
};

export default withStyles(styles)(UpdateUser);