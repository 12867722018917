import React from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Button } from "@material-ui/core";

import { styles } from "./ActivateButton.styles";
import { reservationActivateAdvancedBo, reservationActivateSubscriptionBo, selectActionIsLoading } from "../../../reservationsSlice";
import { isSubscription } from "../../../../../common/utils/OTypes";

function ActivateButton({ classes, reservation, actions }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectActionIsLoading);
  console.log('reservation: %o', reservation);

  const { code, carToken, customerCode, deviceToken, operationType } = reservation;

  const onActivateHandler = () => {
    // TODO
    if (isSubscription(operationType)) {
      dispatch(
        reservationActivateSubscriptionBo(
          { reservationCode: code, carToken, customerCode, deviceToken },
          actions
        )
      );
    }
    else {
      dispatch(
        reservationActivateAdvancedBo(
          { reservationCode: code, carToken, customerCode, deviceToken },
          actions
        )
      );
    }
  };

  return (
    <div className={classes.root}>
      <Button variant="contained" color="primary" onClick={onActivateHandler} disabled={isLoading}>
        Activate Trip
      </Button>
    </div>
  );
}

export default withStyles(styles)(ActivateButton);
