const styles = theme => ({
	
	icon: {
		color: '#009AC9',
	},

	iconUnchecked: {
		color: '#707070',
	},
});

export default styles;