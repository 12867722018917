export const styles = theme => ({

	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		width: 750,
		height: 560,
		position: 'absolute',
		top: 'calc(50vh - 280px)',
		left: 'calc(50vw - 375px)',
		backgroundColor: '#fff',
		padding: '8px 26px',
		borderRadius: 8,
		boxSizing: 'border-box',
	},

	bottomHalf: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		flexBasis: '40%',
		height: '100%',
	},


	rootBar: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		margin: 'auto',
		textAlign: 'center',
		lineHeight: 'inherit',
		marginTop: 10,
		minHeight: 18,
		width: '300px',
		maxWidth: '300px',
		fontSize: '14px',
		borderRadius: 10,
		color: 'white',
		wordBreak: 'break-word',
	},

	showBar: {
		opacity: 1,
		transition: 'opacity 1000ms linear',
	},

	hideBar: {
		opacity: 0,
		transition: 'opacity 2500ms linear',
	},

	success: {
		backgroundColor: 'rgb(0, 182, 127)',
	},

	error: {
		backgroundColor: 'rgb(253, 46, 37)',
	},

});