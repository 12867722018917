const createModel = function(obj) {
	
	const keys = Object.keys(obj);

	keys.forEach(key => {
		
		if (typeof obj[key] === 'undefined'
			|| typeof key === 'undefined'
		) {
			return;
		}
	
		this[key] = obj[key];
	});
};

export default createModel;