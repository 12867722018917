import createBanner from './createBanner';
import deleteBanner from './deleteBanner';
import enableBanner from './enableBanner';
import getBanner from './getBanner';
import listBanners from './listBanners';
import updateBanner from './updateBanner';

const index ={
	createBanner,
	deleteBanner,
	enableBanner,
	getBanner,
	listBanners,
	updateBanner,
};

export default index;