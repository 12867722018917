export const styles = theme => ({

	root: {
		padding: '8px 16px',
		height: '100%',
		boxSizing: 'border-box',
	},

	textFieldTitle: {
		borderBottom: 'none',
		color: 'rgb(0, 155, 204)',
		fontSize: '18px',
	},

	detailsBlock: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.20)',
	},

	maxRatesBlock: {
		borderBottom: '1px solid rgba(0, 0, 0, 0.20)',
		padding: '6px 0',
	},

	zonesBlock: {
		display: 'flex',
		flexDirection: 'column',
	},

	title: {
		fontSize: '18px',
		color: 'rgb(0, 155, 204)',
		maxHeight: 40,
		marginTop: '10px',
	},
});