export const styles = theme => ({

	root: {
		overflow: 'auto',
		overflowX: 'hidden', 
	},

	timeTitle: {
		color: '#C5C5C5',
		fontSize: '10px',
		fontWeight: '500',
		lineHeight: '12px',
		width: '70px',
		textAlign: 'left',
	},

	header: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		width: '100%',
		marginBottom: '10px',
	},

	datePickersContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
	},

	pickers: {
		backgroundColor: '#FFFFFF',
		border: '1px solid #DDDDDD',
		borderRadius: '2px',
		boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.12)',
		width: 'calc(100% - 3px)',
		height: '32px',
	},

	pickerLabel: {
		color: '#4C4C4E',
		// FIXME some of these should be given by the Mui.theme
		fontSize: '12px',
		fontWeight: '400',
		lineHeight: '34px',
		width: '32px',
		textAlign: 'left',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},

	input: {
		marginTop: '0 !important',
		padding: '3px !important',
		// FIXME some of these should be given by the Mui.theme
		color: '#89898B',
		fontSize: '12px',
		fontWeight: '400',
		lineHeight: '14px',
		textAlign: 'left',
	},

	horizontalFlex: {
		height: 32,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},

	search: {
		backgroundColor: '#FFFFFF',
		border: '1px solid #DDDDDD',
		borderRadius: '7px',
		boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.12)',
		width: '336px',
		height: '32px',
		paddingTop: '0px',
		marginTop: '8px',
		alignSelf: 'center',
	},

	searchProps: {
		// FIXME some of these should be given by the Mui.theme
		color: '#89898B',
		fontSize: '12px',
		lineHeight: '32px',
		textAlign: 'left',
		padding: '0 0 0 6px',
	},

	searchIcon: {
		width: '32px',
		height: '32px',
		fill: '#C5C5C5',
		'&:hover': {
			cursor: 'pointer',
		},
	},

	noMargin: {
		marginTop: 0,
		color: '#89898B',
		fontSize: '12px',
		fontWeight: '400',
		lineHeight: '14px',
		textAlign: 'left',
	},

	inputIcon: {
		padding: '2px',
	},

	moreLogsText: {
		color: '#0092E9',
		'&:hover': {
			cursor: 'pointer',
		},
	},

	noMoreLogsText: {
		color: theme.palette.grey['500'],
	},

	list: {
		height: '100%',
		// FIXME this allows to see the hidden part of the list
		// The list should have the proper size in the first place
		paddingBottom: 10,
	},
});
