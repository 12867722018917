export const styles = (theme) => ({
  root: {
    height: "100%",
    padding: theme.spacing(1),
  },
  title: {
    marginTop: theme.spacing(2),
    fontSize: 18,
    color: "#A1A1A1",
  },
  button: {
    minWidth: 100,
  },
});
