
import NGError from "../../../error/ngError";
import errorFactory from "../../../error/errorFactory";
import { WS_REQUEST_IS_MALFORMED, WS_PROCESS_MESSAGE_IS_MALFORMED } from "../../../error/websocket/errorTypes";
import Zone from "../../../model/Zones/Zone";
import { publishAndAwait } from "../../../app/coreSlice";

export const getZonesFromPP = async (id) => {
  console.log("getZonesFromPP2");

  if (typeof id === "undefined") {
    throw errorFactory(WS_REQUEST_IS_MALFORMED);
  }

  if (!id) {
    throw errorFactory(WS_REQUEST_IS_MALFORMED);
  }

  const request = await publishAndAwait({
    destination: "/app/hello",
    body: {
      type: "PRICING_PLAN",
      subType: "GET_ZONES",
      message: {
        pricingPlanId: id,
      },
    },
  });
  const { processMessage, error } = request.response;

  if (error) {
    return;
  }

  if (!processMessage) {
    throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
  }

  if (error) {
    const { description, code, tag } = processMessage;

    throw new NGError({
      message: description,
      code,
      tag,
    });
  }

  const mappedZones = processMessage && Array.isArray(processMessage) && processMessage.map((zone) => new Zone(zone));

  return { resultArray: mappedZones } || null;
};

export default getZonesFromPP;
