import React from 'react';
import {
	Typography,
	TextField,
	withStyles,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { styles } from './CreatePromoCode.styles';
import CommonButtons from '../../../UI/Buttons/CommonButtons';
import { currencyFormatter } from '../../../../services/formatter';

const CreatePromoCode = ({
	classes,
	closeHandler,
	onSaveHandler,
	placeholder,
	onChange,
	errorMessage,
}) => {

	const errorText = !!errorMessage && (
		<Typography className={classes.error} align='center'>
			{errorMessage}
		</Typography>
	);

	const title = (
		<Typography className={classes.title}>
			Generate batch of promotion codes
		</Typography>
	);

	const name = (
		<TextField
			className={classes.field}
			value={placeholder?.name || ''}
			id='nameEditor'
			label='Name'
			onChange={event => onChange('name', event.target.value)}
			required={true}
		/>
	);

	const expirationDateField = (
		<DatePicker
			ampm={false}
			className={classes.pickers}
			format="DD/MM/YYYY"
			inputVariant='outlined'
			key={'date-key'}
			label='Expiration date'
			onChange={(value) => onChange('expirationDate', value)}
			required={true}
			value={placeholder?.expirationDate || ''}
			InputProps={{ classes: { input: classes.pickerInput } }}
			InputLabelProps={{ classes: { outlined: classes.outlined } }}
		/>
	);

	const codesNumber = (
		<div className={classes.valueDiv}>
			<TextField
				className={classes.field}
				id='codesNumberId'
				label='Number of codes'
				value={placeholder?.numberOfCodes || ''}
				onChange={event => onChange('numberOfCodes', event.target.value)}
				required={true}
			/>
		</div>
	);

	const value = (
		<div className={classes.valueDiv}>
			<TextField
				id='valueInputId'
				onClick={() => document.getElementById("valueInputId").select()}
				label='Amount'
				value={placeholder?.amount || 0}
				onChange={event => onChange('amount', event.target.value)}
				required={true}
			/>
			<Typography
				className={classes.endAdornment}
				position="end">
				{currencyFormatter.symbol()}
			</Typography>
		</div>
	);

	const descriptionLabel = (
		<Typography className={classes.subtitle14px} key={'label_description'}>
			Description
		</Typography>
	);

	const descriptionInput = (
		<TextField
			InputLabelProps={{ classes: { root: classes.labelProps, focused: classes.labelProps } }}
			InputProps={{ classes: { root: classes.inputPropsMultiline } }}
			className={classes.fieldMultiline}
			classes={{ root: classes.multiline }}
			error={false}
			key={'input_description'}
			margin="normal"
			multiline
			onChange={(e) => onChange('description', e.target.value)}
			required={true}
			minRows={3}
			value={placeholder?.description || ''}
			variant={'outlined'}
		/>
	);

	const descriptionDiv = (
		<>
			{descriptionLabel}
			{descriptionInput}
		</>
	);

	const cancelBtn = (
		<CommonButtons
			buttonStyleName='void'
			key='Cancel'
			label='Cancel'
			onClick={() => closeHandler()}
		/>
	);

	const createBtn = (
		<CommonButtons
			buttonStyleName='filled'
			key='CreatePromo'
			label='Create'
			type='submit'
		/>
	);

	return (
		<div className={classes.root}>
			<form className={classes.form} onSubmit={onSaveHandler}>
				<div className={classes.content}>
					{title}
					<div className={classes.row}>
						{name}
						{expirationDateField}
					</div>
					<div className={classes.row}>
						{value}
						{codesNumber}
					</div>
					{descriptionDiv}
				</div>
				<div className={classes.bottom}>
					{errorText}
					<div className={classes.btns}>
						{cancelBtn}
						{createBtn}
					</div>
				</div>
			</form>
		</div>
	);
};

export default withStyles(styles)(CreatePromoCode);
