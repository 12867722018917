export const styles = theme => ({
	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		backgroundColor: 'inherit',
		padding: 8,
		boxSizing: 'border-box',
		minWidth: '735px',
	},

	buttonsContainer: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		bottom: '5%',
	},

	btn: {
		paddingLeft: 6,
	},

	selectionDiv: {
		border: '1px dotted #000',
		position: 'absolute',
		zIndex: 9999,
	},

	subList: {
		width: '100%',
		paddingBottom: 8,
	},

	title: {
		fontSize: '22px',
		height: 40,
		maxHeight: 40,
	},

	header: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},

	listHeader: {
		paddingLeft: '0 !important',
		paddingRight: '6px !important',
	},

});