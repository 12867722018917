import React, { useState, useEffect, useCallback, Fragment } from "react";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import ActivityLogList from "./ActivityLogList/ActivityLogList";
import { styles } from "./ActivityLog.styles";

import {
  bigdataListComms,
  selectActivityLogs,
  selectActivityLogsIsLastPage,
  bigdataHeartbeats,
  selectIsLoading,
} from "../../../../bigdata/bigdataSlice";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { useDateTime } from "../../../../../common/hooks";

const ActivityLog = ({ classes, deviceToken }) => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const logs = useSelector(selectActivityLogs);
  // console.log('logs: %o', logs);
  const isLastPage = useSelector(selectActivityLogsIsLastPage);
  const isLoading = useSelector(selectIsLoading);

  const { now, toUtc, toTimeZone } = useDateTime();

  // SEARCH PARAMS
  // FIXME: this should use our own date service
  const [startDate, setStartDate] = useState(now().subtract(3, "hours").format("YYYY-MM-DD HH:mm:ss"));
  // FIXME: this should use our own date service
  const [endDate, setEndDate] = useState(now().format("YYYY-MM-DD HH:mm:ss"));
  const [selectedOption, setSelectedOption] = useState(OPTIONS.ALL);
  const [maxEndDate, setMaxEndDate] = useState(now().format("YYYY-MM-DD HH:mm:ss"));

  useEffect(() => {
    // console.log("startDate: %o, endDate: %o", startDate, endDate);
    let diff = moment(endDate).diff(moment(startDate), "minute");
    // console.log("diff: %o", diff);
    if (diff > 180 || diff < 0) {
      setEndDate(moment(startDate).add(3, "hour").format("YYYY-MM-DD HH:mm:ss"));
    }
    let maxEnd = moment(startDate).add(24, "hour");
    if (maxEnd > now()) {
      setMaxEndDate(now().format("YYYY-MM-DD HH:mm:ss"));
    } else {
      setMaxEndDate(maxEnd.format("YYYY-MM-DD HH:mm:ss"));
    }
    //eslint-disable-next-line
  }, [startDate]);

  const searchLogsHandler = useCallback(
    async (event) => {
      if (event.keyCode === 13 || event === "search-icon") {
        dispatch(
          bigdataListComms({
            clear: true,
            start: toUtc(startDate).format("YYYY-MM-DD HH:mm:ss"),
            end: toUtc(endDate).format("YYYY-MM-DD HH:mm:ss"),
            deviceToken,
            page: 1,
            commType: selectedOption !== OPTIONS.ALL ? selectedOption : undefined,
          })
        );
        dispatch(
          bigdataHeartbeats({
            clear: true,
            start: toUtc(startDate).format("YYYY-MM-DD HH:mm:ss"),
            end: toUtc(endDate).format("YYYY-MM-DD HH:mm:ss"),
            deviceToken,
            offset: 0,
            limit: 10000,
          })
        );

        setPage(1);
      }
    },
    //eslint-disable-next-line
    [startDate, endDate, deviceToken, page, selectedOption]
  );

  useEffect(() => {
    searchLogsHandler("search-icon");
    //eslint-disable-next-line
  }, []);

  const loadItems = async () => {
    let currentPage = page;
    currentPage++;
    setPage(currentPage);
    dispatch(
      bigdataListComms({
        clear: false,
        start: toUtc(startDate).format("YYYY-MM-DD HH:mm:ss"),
        end: toUtc(endDate).format("YYYY-MM-DD HH:mm:ss"),
        deviceToken,
        page: currentPage,
        commType: selectedOption !== OPTIONS.ALL ? selectedOption : undefined,
      })
    );
  };

  // DATE PICKER STYLES
  const iconProps = classes.inputIcon;
  const inputProps = classes.input;

  const sortedLogsByDayArray = logs && logs.length > 0 && sortActivityLogsByDay(logs);

  const getAndStyleLogs = () => {
    return sortedLogsByDayArray.map((logs) => {
      return (
        <Fragment key={"frag-" + logs[0].comms.create_date}>
          <Typography
            key={"text-" + toTimeZone(logs[0].comms.create_date, "date")}
            className={classes.timeTitle}
            variant="body1"
          >
            {toTimeZone(logs[0].comms.create_date, "date")}
          </Typography>
          <ActivityLogList key={"list-" + toTimeZone(logs[0].comms.create_date, "date")} activityLogs={logs} />
        </Fragment>
      );
    });
  };
  const logLists = logs && logs.length > 0 && getAndStyleLogs();

  let moreLogsBtn =
    logLists &&
    (!isLastPage ? (
      <p className={classes.moreLogsText} onClick={loadItems}>
        More Logs
      </p>
    ) : (
      <p className={classes.noMoreLogsText}>No More Logs</p>
    ));

  const startDateChangeHandler = (date) => {
    let mDate = moment(date);
    if (mDate.isValid()) {
      setStartDate(mDate.format("YYYY-MM-DD HH:mm:ss"));
    }
  };

  const endDateChangeHandler = (date) => {
    let mDate = moment(date);
    if (mDate.isValid()) {
      setEndDate(mDate.format("YYYY-MM-DD HH:mm:ss"));
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.datePickersContainer}>
          <Grid container direction="row" justifyContent="space-evenly" spacing={1}>
            <Grid item xs={6}>
              <Grid container direction="column">
                <span className={classes.pickerLabel}>Start</span>
                <KeyboardDateTimePicker
                  key="datePickerStart"
                  className={classes.pickers}
                  autoOk
                  ampm={false}
                  disableToolbar={true}
                  variant="inline"
                  format="DD/MM/YYYY HH:mm"
                  value={startDate}
                  inputVariant="standard"
                  InputAdornmentProps={{ position: "end" }}
                  KeyboardButtonProps={{ classes: { root: iconProps } }}
                  InputProps={{ classes: { root: inputProps }, disableUnderline: true }}
                  onChange={startDateChangeHandler}
                  onKeyDown={(e) => searchLogsHandler(e)}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="column">
                <span className={classes.pickerLabel}>End</span>
                <KeyboardDateTimePicker
                  key="datePickerEnd"
                  className={classes.pickers}
                  autoOk
                  ampm={false}
                  disableToolbar={true}
                  variant="inline"
                  format="DD/MM/YYYY HH:mm"
                  value={endDate}
                  minDate={startDate}
                  maxDate={maxEndDate}
                  inputVariant="standard"
                  InputAdornmentProps={{ position: "end" }}
                  KeyboardButtonProps={{ classes: { root: iconProps } }}
                  InputProps={{ classes: { root: inputProps }, disableUnderline: true }}
                  onChange={endDateChangeHandler}
                  onKeyDown={(e) => searchLogsHandler(e)}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.horizontalFlex}>
                <TextField
                  id="selectComms"
                  select
                  className={classes.search}
                  value={selectedOption}
                  InputProps={{
                    classes: { input: classes.searchProps },
                    disableUnderline: true,
                  }}
                  placeholder="Search an activity"
                  type="search"
                  margin="normal"
                  onChange={(e) => setSelectedOption(e.target.value)}
                >
                  {Object.values(OPTIONS).map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>

                <div>
                  <Button
                    classes={{ root: classes.searchIcon }}
                    aria-label="Search"
                    onClick={() => searchLogsHandler("search-icon")}
                    disabled={Boolean(isLoading)}
                  >
                    <SearchIcon />
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={classes.list}>
        {logLists}
        {moreLogsBtn}
      </div>
    </div>
  );
};

const OPTIONS = {
  ALL: "ALL",
  TELEMATIC: "TELEMATIC",
  COMMAND: "COMMAND",
  ALARM: "ALARM",
};

const sortActivityLogsByDay = (activityLogsOrin) => {
  let activityLogs = [...activityLogsOrin];
  // TODO Use const whenever possible. The arrays have functionst to empty them. Refactor it.
  const sortedLogs = activityLogs.sort((a, b) => new Date(b.comms.create_date) - new Date(a.comms.create_date));
  let sortedLogsByDay = [];
  // FIXME: this should use the dateFormatter service
  const mostRecentDay = moment(sortedLogs[0].comms.create_date).format("DD-MM-YYYY");
  let currentDay = mostRecentDay;
  let dayArray = [];

  sortedLogs.forEach((log, index) => {
    // FIXME: this should use the dateFormatter service
    if (currentDay !== moment(log.comms.create_date).format("DD-MM-YYYY")) {
      sortedLogsByDay.push(dayArray);
      dayArray = [];
      // FIXME: this should use the dateFormatter service
      currentDay = moment(log.comms.create_date).format("DD-MM-YYYY");
    }
    if (index === sortedLogs.length - 1) {
      sortedLogsByDay.push(dayArray);
    }
    dayArray.push(log);
  });

  return sortedLogsByDay;
};

export default withStyles(styles)(ActivityLog);
