const styles = theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: '40%',
		borderRight: '1px solid #8885',
		minHeight: '0',
		boxSizing: 'border-box',
	},

	searchText: {
		maxWidth: '70%',
	},

	addIcon: {
		cursor: 'pointer',
		color: '#009AC9',
		height: '26.5px',
		width: 'auto',
	},

	searchBar: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-around',
		padding: '0 0 5px',
		borderBottom: '1px solid #8885',
	},

	list: {
		height: '100%',
		width: '100%',
		overflowY: 'auto',
		scrollbarWidth: 'thin', //FIREFOX
		//CHROME
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},
});

export default styles;