import * as listItemTypes from '../../UI/List/ListItem/ListItemTypes';
import * as screenSize from '../../../model/ScreenSize/ScreenSize';

const calendarFields = (currentScreenSize) => {

	const numberWidth = 80;

	const height = {
		[screenSize.DESKTOP]: 15,
		[screenSize.TABLET]: 15,
		[screenSize.ERROR]: 0,
	}[currentScreenSize] || 15;

	return [
		{
			key: 'hourPeriod',
			label: '',
			order: 1,
			pinned: true,
			width: 50,
			minWidth: 50,
			// maxWidth: numberWidth,
			height,
			type: listItemTypes.SPECIAL_TEXT,
		},
		{
			key: 'monday',
			label: 'Monday',
			order: 2,
			pinned: true,
			width: numberWidth,
			// maxWidth: numberWidth,
			height,
			type: listItemTypes.TEXT,
			padding: 10,
			justifyContent: 'center',
			fontSize: 16,
		},
		{
			key: 'tuesday',
			label: 'Tuesday',
			order: 3,
			pinned: true,
			width: numberWidth,
			height,
			type: listItemTypes.TEXT,
			padding: 10,
			justifyContent: 'center',
			fontSize: 16,
		},
		{
			key: 'wednesday',
			label: 'Wednesday',
			order: 4,
			pinned: true,
			width: numberWidth,
			height,
			type: listItemTypes.TEXT,
			padding: 10,
			justifyContent: 'center',
			fontSize: 16,
		},
		{
			key: 'thursday',
			label: 'Thursday',
			order: 5,
			pinned: true,
			width: numberWidth,
			height,
			type: listItemTypes.TEXT,
			padding: 10,
			justifyContent: 'center',
			fontSize: 16,
		},
		{
			key: 'friday',
			label: 'Friday',
			order: 6,
			pinned: true,
			width: numberWidth,
			height,
			type: listItemTypes.TEXT,
			padding: 10,
			justifyContent: 'center',
			fontSize: 16,
		},
		{
			key: 'saturday',
			label: 'Saturday',
			order: 7,
			pinned: true,
			width: numberWidth,
			height,
			type: listItemTypes.TEXT,
			padding: 10,
			justifyContent: 'center',
			fontSize: 16,
		},
		{
			key: 'sunday',
			label: 'Sunday',
			order: 8,
			pinned: true,
			width: numberWidth,
			height,
			type: listItemTypes.TEXT,
			padding: 10,
			justifyContent: 'center',
			fontSize: 16,
		},
	];
};

export default calendarFields;