import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid, Checkbox } from "@material-ui/core";

import { styles } from "./AdvancedDatesInput.styles";
import DateTimeInput from "../../../../common/inputs/DateTimeInput";
import { useDateTime } from "../../../../common/hooks";

function AdvancedDatesInput({ classes, startDate, endDate, onChange, lock, onLock, ongoing }) {
  let { now } = useDateTime();

  let defaultStartDate = now().add(1, "hours");
  let defaultEndDate = now().add(2, "hours");
  return (
    <Grid container direction="row" className={classes.root} spacing={1}>
      <Grid item>
        <DateTimeInput
          label="Start Date"
          value={startDate || defaultStartDate}
          minDate={ongoing ? null : now()}
          onChange={(startDate) => onChange({ startDate })}
          disabled={lock || ongoing}
        />
      </Grid>
      <Grid item>
        <DateTimeInput
          label="End Date"
          value={endDate || defaultEndDate}
          minDate={startDate || defaultStartDate}
          onChange={(endDate) => onChange({ endDate })}
          disabled={lock}
        />
      </Grid>
      <Grid item>
        <Grid container direction="row" alignItems="center" onClick={onLock}>
          <Grid item>
            <Checkbox checked={Boolean(lock)} color="primary" />
          </Grid>
          <Grid item>
            <Typography>Lock Dates</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(AdvancedDatesInput);
