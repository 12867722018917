export const styles = () => ({
  root: {
    width: "100%",
    boxShadow: "none",
    fontSize: "12px",
    textAlign: "center",
  },
  summary: {
    padding: "0 20px 0 20px",
  },
  details: {
    display: "block",
    padding: 0,
    marginBottom: 10,
  },
  activityLogDetails: {
    position: "relative",
    padding: "0px 5px 5px",
    display: "inherit",
    height: "100%",
  },
  exitCross: {
    position: "absolute",
    top: -5,
    right: 5,
    display: "inline-block",
    transform: "rotate(45deg)",
    fontSize: 20,
    color: "black",
    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonDiv: {
    margin: "0 20px",
  },
  button: {
    fontSize: 9,
  },
});
