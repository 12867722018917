import Zone from '../../../../model/Zones/Zone';

export const fromDTO = ({
	geoPolygn,
	...props
}) => new Zone({
	geoPolygon: geoPolygn,
	...props,
});

const customerFlowMapper = {

	fromDTO,
	toDTO: zone => new Zone(zone),
};

export default customerFlowMapper;
