import React from 'react';
import {
	withStyles,
	Badge,
} from '@material-ui/core';
import { 
	LiveHelp as AssetIcon,
} from '@material-ui/icons';
import styles from './Cluster.styles';
import classNames from 'classnames';

const Cluster = (props) => {

	const { classes, $hover, numPoints, onClick } = props;

	return (
		<div
			className={
				classNames(
					classes.root,
					classes.centered,
					$hover && classes.hover,
				)
			}
			onClick={onClick}
		>
			<Badge badgeContent={numPoints} classes={{badge : classes.badge}}>
				<AssetIcon style={{color: '#4095C2', fontSize: 30}}/>
			</Badge>
		</div>
	);
};

export default withStyles(styles)(Cluster);