import React from 'react';
import { getIconDetails } from './DashboardAssetIconType';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PulsingDot from '../../UI/Animations/PulsingDot';
import {
	withStyles,
} from '@material-ui/core';
import styles from './DashboardAssetIcon.styles';

const DashboardAssetIcon = ({
	classes,
	assetStatus,
	assetTypeName,
	tooltip,
	isSelected,
	hasSeriousProblem,
}) => {

	const iconDetails = getIconDetails(assetStatus);



	const {
		color,
		radius,
	} = iconDetails;

	const choosenStyle = isSelected
		? classes.selected
		: classes.icon;

	const translate = isSelected
		? classes.translate
		: null;

	const iconStyle = {
		color: color,
		width: radius,
		height: radius,
	};

	const icon = hasSeriousProblem
		? <PulsingDot
			overrideStyle={iconStyle}
		/>
		: <FiberManualRecordIcon
			className={translate}
			style={iconStyle}
		/>;

	return (
		<div
			className={choosenStyle}
		>
			{icon}
		</div>
	);
};

export default withStyles(styles)(DashboardAssetIcon);
