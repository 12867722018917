import React from 'react';
import {
	withStyles,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@material-ui/core';
import CommonButtons from '../Buttons/CommonButtons';
import styles from './ConfirmationModal.styles';

const ConfirmationModal = (props) => {

	const {
		classes,
		confirmHandler,
		confirmCustomLabel,
		cancelHandler,
		cancelCustomLabel,
		thirdOptionHandler,
		thirdOptionCustomLabel,
		title,
		text,
	} = props;

	const cancelButton = (
		<CommonButtons
			key='cancel'
			buttonStyleName='void'
			onClick={cancelHandler}
			label={cancelCustomLabel || 'Cancel'}
		/>);

	const thirdOptionButton = !!thirdOptionHandler && !!thirdOptionCustomLabel
		&& (
			<CommonButtons
				key='third-option'
				buttonClassName={classes.buttonMinWidth}
				buttonStyleName='void'
				onClick={thirdOptionHandler}
				label={thirdOptionCustomLabel}
			/>);

	const confirmButton = (
		<CommonButtons
			key='confirm'
			buttonClassName={classes.buttonMinWidth}
			buttonStyleName='filled'
			onClick={confirmHandler}
			label={confirmCustomLabel || 'Confirm'}
		/>);

	return (<Dialog
		open={true}
		maxWidth='sm'
	>
		<DialogTitle>
			{title || 'Alert'}
		</DialogTitle>
		<DialogContent classes={{ root: classes.root }}>
			{text}
		</DialogContent>
		<DialogActions classes={{ root: classes.flexHorizontal }}>
			{cancelButton}
			{thirdOptionButton}
			{confirmButton}
		</DialogActions>
	</Dialog>);
};

export default withStyles(styles)(ConfirmationModal);