import WorkOrder from '../../../../model/WorkOrder/WorkOrder';
import { getStatus } from '../../../../model/WorkOrder/workOrderStatus';
import taskMapper from './taskmapper';

const fromDTO = ({
	assetToken,
	assignAgent,
	assignAgentName,
	createDate,
	createUser,
	description,
	designation,
	endDate,
	externalId,
	id,
	licensePlate,
	locationId,
	priority,
	servicePointId,
	scheduledEndDate,
	scheduledStartDate,
	startDate,
	startLatitude,
	startLongitude,
	tasks,
}) => new WorkOrder({
	assetToken,
	assignAgent,
	assignAgentName,
	code: id,
	createDate,
	createUser,
	description,
	endDate,
	externalId,
	licensePlate,
	location: locationId,
	priority,
	scheduledEndDate: scheduledEndDate && new Date(scheduledEndDate),
	scheduledStartDate: scheduledStartDate && new Date(scheduledStartDate),
	servicePointCode: servicePointId,
	startDate,
	startLatitude,
	startLongitude,
	status: getStatus({ startDate, endDate }),
	tasks: (tasks || []).map(taskMapper.fromDTO),
	type: designation,
});

const toDTO = ({
	assetToken,
	assignAgent,
	code,
	description,
	licensePlate,
	location,
	priority,
	scheduledEndDate,
	scheduledStartDate,
	servicePointCode,
	startLatitude,
	startLongitude,
	tasks,
	type,
}) => ({
	assetToken,
	assignAgent,
	description,
	designation: type,
	id: code,
	licensePlate,
	locationId: location,
	priority,
	scheduledEndDate,
	scheduledStartDate,
	servicePointId: servicePointCode,
	startLatitude,
	startLongitude,
	tasks: (tasks || []).map(taskMapper.toDTO),
});

const workordersList = { fromDTO, toDTO };

export default workordersList;