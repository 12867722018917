const styles = theme => ({
	root: {
		display: 'flex',
		width: '100%',
		minHeight: '0',
		alignItems: 'center',
	},

	searchIcon: {
		cursor: 'pointer',
		marginLeft: '10px',
	},

	select: {
		margin: '0 10px',
	},

	picker: {
		maxHeight: '50px',
		margin: 'auto 10px',
	},

	title: {
		fontSize: 20,
		marginRight: '10px',
	},

	option: {
		textTransform: 'capitalize',
	},

	pickerInput: {
		padding: 8,
	},

	outlined: {
		transform: 'translate(14px, 10px) scale(1)',
	},
});

export default styles;