const logger = (caller) => {
  return {
    warn: (message) => console.warn(`[${caller}] ${message}`),
    info: (message) => console.info(`[${caller}] ${message}`),
    debug: (message) => console.debug(`[${caller}] ${message}`),
    silly: (message) => console.debug(`[${caller}] ${message}`),
  };
};

export default logger;
