
import { SvgIcon } from "@material-ui/core";

export function CloseIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
    </SvgIcon>
  );
}

export function InventoryIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M4 2H20C21 2 22 2.9 22 4V7.01C22 7.73 21.57 8.35 21 8.7V20C21 21.1 19.9 22 19 22H5C4.1 22 3 21.1 3 20V8.7C2.43 8.35 2 7.73 2 7.01V4C2 2.9 3 2 4 2ZM5 20H19V9H5V20ZM4 7H20V4H4V7ZM9 12H15V14H9V12Z" />
    </SvgIcon>
  );
}

export function ClearIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M14.59 8L12 10.59L9.41 8L8 9.41L10.59 12L8 14.59L9.41 16L12 13.41L14.59 16L16 14.59L13.41 12L16 9.41L14.59 8ZM12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" />
    </SvgIcon>
  );
}

export function PicturePlaceholder(props) {
  return (
    <SvgIcon {...props} viewBox="100 100 300 300">
      <rect
        fill="none"
        height="190.3"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        width="199.5"
        x="156.3"
        y="160.9"
      />
      <polyline
        fill="none"
        points="   156.3,315.7 219.7,256 275.7,315.7 304.7,285.8 355.7,351.1  "
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
      />
      <circle
        cx="303.9"
        cy="222.6"
        fill="none"
        r="26.2"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
      />
    </SvgIcon>
  );
}

export function SearchIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z" />
    </SvgIcon>
  );
}

export function ArrowBottomRightIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M17.6569 6.34315H15.6628L15.6557 14.2415L7.05025 5.63604L5.63604 7.05026L14.2415 15.6557L6.34315 15.6628V17.6569H17.6569V6.34315Z" />
    </SvgIcon>
  );
}
export function ArrowTopRightIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M6.34313 6.34315V8.33719L14.2415 8.34426L5.63603 16.9497L7.05024 18.364L15.6557 9.75847L15.6628 17.6569H17.6568V6.34315H6.34313Z" />
    </SvgIcon>
  );
}
export function InfoIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M9 6C9 5.44772 9.44771 5 10 5C10.5523 5 11 5.44772 11 6C11 6.55228 10.5523 7 10 7C9.44771 7 9 6.55228 9 6ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z" />
    </SvgIcon>
  );
}

export function HomeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12 5.69L17 10.19V18H15V12H9V18H7V10.19L12 5.69ZM12 3L2 12H5V20H11V14H13V20H19V12H22L12 3Z" />
    </SvgIcon>
  );
}

export function OrdersIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M16 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM9 1.75C9.41 1.75 9.75 2.09 9.75 2.5C9.75 2.91 9.41 3.25 9 3.25C8.59 3.25 8.25 2.91 8.25 2.5C8.25 2.09 8.59 1.75 9 1.75ZM16 18H2V4H16V18Z" />
    </SvgIcon>
  );
}

export function PeopleIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M9 13.75C6.66 13.75 2 14.92 2 17.25V19H16V17.25C16 14.92 11.34 13.75 9 13.75ZM4.34 17C5.18 16.42 7.21 15.75 9 15.75C10.79 15.75 12.82 16.42 13.66 17H4.34ZM9 12C10.93 12 12.5 10.43 12.5 8.5C12.5 6.57 10.93 5 9 5C7.07 5 5.5 6.57 5.5 8.5C5.5 10.43 7.07 12 9 12ZM9 7C9.83 7 10.5 7.67 10.5 8.5C10.5 9.33 9.83 10 9 10C8.17 10 7.5 9.33 7.5 8.5C7.5 7.67 8.17 7 9 7ZM16.04 13.81C17.2 14.65 18 15.77 18 17.25V19H22V17.25C22 15.23 18.5 14.08 16.04 13.81ZM15 12C16.93 12 18.5 10.43 18.5 8.5C18.5 6.57 16.93 5 15 5C14.46 5 13.96 5.13 13.5 5.35C14.13 6.24 14.5 7.33 14.5 8.5C14.5 9.67 14.13 10.76 13.5 11.65C13.96 11.87 14.46 12 15 12Z" />
    </SvgIcon>
  );
}

export function WaitingIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      <circle cx="20" cy="20" r="20" fill="#F2D4D2" />
      <path
        d="M20.5 23.5V24.5H19.5V23.5H20.5ZM20.5 15.5V20.5H19.5V15.5H20.5ZM10.5 20C10.5 14.7555 14.7468 10.5 19.99 10.5C25.2442 10.5 29.5 14.7565 29.5 20C29.5 25.2435 25.2442 29.5 19.99 29.5C14.7468 29.5 10.5 25.2445 10.5 20ZM11.5 20C11.5 24.6961 15.3039 28.5 20 28.5C24.6961 28.5 28.5 24.6961 28.5 20C28.5 15.3039 24.6961 11.5 20 11.5C15.3039 11.5 11.5 15.3039 11.5 20Z"
        fill="#97A1AF"
        stroke="#BD271E"
      />
    </SvgIcon>
  );
}

export function InTransitIcon(props) {
  return (
    <SvgIcon {...props} width="100%" height="100%" viewBox="0 0 40 40">
      <circle cx="20" cy="20" r="20" fill="#CCF3F6" />
      <path
        d="M27 12H22.82C22.4 10.84 21.3 10 20 10C18.7 10 17.6 10.84 17.18 12H13C11.9 12 11 12.9 11 14V28C11 29.1 11.9 30 13 30H27C28.1 30 29 29.1 29 28V14C29 12.9 28.1 12 27 12ZM20 11.75C20.41 11.75 20.75 12.09 20.75 12.5C20.75 12.91 20.41 13.25 20 13.25C19.59 13.25 19.25 12.91 19.25 12.5C19.25 12.09 19.59 11.75 20 11.75ZM27 28H13V14H27V28Z"
        fill="#97A1AF"
      />
    </SvgIcon>
  );
}

export function CompletedIcon(props) {
  return (
    <SvgIcon {...props} width="100%" height="100%" viewBox="0 0 40 40">
      <circle cx="20" cy="20" r="20" fill="#CCF2F0" />
      <path
        d="M26.2222 12H13.7778C12.8 12 12 12.8 12 13.7778V26.2222C12 27.2 12.8 28 13.7778 28H26.2222C27.2 28 28 27.2 28 26.2222V13.7778C28 12.8 27.2 12 26.2222 12ZM26.2222 26.2222H13.7778V13.7778H26.2222V26.2222ZM25.3244 17.3333L24.0711 16.0711L18.2133 21.9289L15.92 19.6444L14.6578 20.8978L18.2133 24.4444L25.3244 17.3333Z"
        fill="#00BFB3"
      />
    </SvgIcon>
  );
}

export function DropDownIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M7 10L12 15L17 10H7Z" />
    </SvgIcon>
  );
}

export function ImagePlaceholder(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#FBFCFD" stroke="inherit" />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
        <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="inherit" />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29 17C31.2091 17 33 15.2091 33 13C33 10.7909 31.2091 9 29 9C26.7909 9 25 10.7909 25 13C25 15.2091 26.7909 17 29 17ZM4 29.5V37C4 38.6569 5.34315 40 7 40H34.5L15.8453 21.3453C15.0917 20.5917 13.8795 20.5614 13.0892 21.2764L4 29.5ZM37.5 32.5V38.7929C37.5 39.2383 36.9614 39.4614 36.6464 39.1464L23 25.5L26.1358 23.4095C26.929 22.8807 27.9852 22.9852 28.6594 23.6594L37.5 32.5Z"
          stroke="none"
          fill="inherit"
        />
      </g>
    </SvgIcon>
  );
}

export function MenuIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M3 12H21" />
      <path d="M3 6.00003H21" />
      <path d="M3 18H21" />
    </SvgIcon>
  );
}

export function FileUploadIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20ZM8 15.01L9.41 16.42L11 14.84V19H13V14.84L14.59 16.43L16 15.01L12.01 11L8 15.01Z"
        fill="#97A1AF"
      />
    </SvgIcon>
  );
}
