import React from 'react';
import {
	Tooltip,
	withStyles,
} from '@material-ui/core';
import {
	RemoveCircleOutline as RemoveIcon,
} from '@material-ui/icons';
import withPermission from '../../../../../../hoc/withPermission';
import ListItem from '../../../../../UI/List/ListItem/ListItem';
import styles from './Item.styles';
import classnames from 'classnames';

const Item = ({
	classes,
	editMode,
	fields,
	masterEditMode,
	onRemove,
	onSelect,
	selected,
	setEditMode,
	setSelectedTemplate,
	workOrderTemplate = {},
}) => {

	const removeButton = (
		<ButtonWithPermission>
			<Tooltip title='Remove template' placement='left'>
				<RemoveIcon
					className={classnames(classes.removeIcon, !editMode && classes.disabledIcon)}
					onClick={(event) => {
						event.stopPropagation();
						if (!editMode) return;
						setSelectedTemplate(workOrderTemplate);
						onRemove('ConfirmationModal');
					}}
				/>
			</Tooltip>
		</ButtonWithPermission>
	);

	const actions = (
		<>
			{removeButton}
		</>
	);


	const priority = workOrderTemplate.priority &&
		workOrderTemplate.priority[0].toUpperCase() + workOrderTemplate.priority.slice(1).toLowerCase();

	return (
		<ListItem
			fields={fields}
			onSelect={() => onSelect(workOrderTemplate.code)}
			selected={selected}
			className={classes.row}
			element={{
				...workOrderTemplate,
				actions,
				priority,
			}}
		/>
	);
};

export default withStyles(styles)(Item);

const ButtonWithPermission = withPermission(
	[{ resource: ['workOrders', 'edit'] }]
)(({
	children,
}) =>
	(
		<>
			{children}
		</>
	)
);