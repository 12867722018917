import React from "react";
import { useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid, Checkbox } from "@material-ui/core";

import { styles } from "./Attributes.styles";
import { selectCarInfo } from "../../../assetSlice";

function Attributes({ classes, asset }) {
  const carInfo = useSelector(selectCarInfo);
  let assetTypeAttributes = (carInfo?.attributes || []).filter((item) => item.assetTypeId === asset.assetType);

  return (
    <div className={classes.root}>
      <Typography variant="h6">Attributes</Typography>
      <br />
      <br />
      <Grid container direction="column">
        {assetTypeAttributes.map((attribute) => (
          <Grid item key={attribute.id}>
            <Grid container wrap="nowrap" alignItems="center">
              <Grid item>
                <Checkbox
                  checked={asset?.attributes.find((item) => {
                    return item === attribute.id;
                  })}
                  disabled
                />
              </Grid>
              <Grid item>
                <Typography>{attribute.name}</Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default withStyles(styles)(Attributes);
