const styles = theme => ({
	
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: '100%',
	},

	goBackIcon: {
		position: 'absolute',
		top: 20,
		right: 30,
		color: '#009AC9',
		cursor: 'pointer',
		scale: 2.7,
	},

	title: {
		display: 'flex',
		flexDirection: 'column',
	},

	mainTitle: {
		fontSize: '20px',
	},

	subTitle: {
		fontSize: '15px',
		marginLeft: '15px',
	},

	subTitle2: {
		fontSize: '14px',
	},

	icons: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
	},

	balance: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
	},

	balanceValue: {
		fontSize: '16px',
	},

	colorRed: {
		color: 'red',
	},

	header: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
	},

	totalAmount: {
		display: 'flex',
		flexDirection: 'row-reverse',
		fontSize: '16px',
	},

	downloadIcon: {
		color: '#009AC9',
		cursor: 'pointer',
	},

	descriptionModalRoot: {
		position: 'absolute',
		top: 'calc(50vh - 205px)',
		left: 'calc(50vw - 170px)',
		width: 340,
		height: 'auto',
		maxHeight: 410,
		backgroundColor: '#fff',
		padding: '25px 30px',
		boxSizing: 'border-box',
		boxShadow: theme.shadows[5],
		borderRadius: 8,
	},

	descriptionModalWorkspace: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},

});

export default styles;