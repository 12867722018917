import React from 'react';
import CarflowsList from '../CarflowsList/CarflowsList';
import Filters from '../TypeSubtypeFilter/TypeSubtypeFilter';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import styles from './Features.styles';
import classnames from 'classnames';

const Features = ({
	classes,
	className,
	features,
	filter,
	onSelect,
	selected,
	setFilter,
}) => {

	const title = (
		<Typography className={classes.title}>
			Features
		</Typography>
	);

	const filters = (
		<Filters
			filter={filter}
			setFilter={setFilter}
		/>
	);

	const list = !!features && (
		<CarflowsList
			array={features}
			onSelect={onSelect}
			selected={selected}
		/>
	);

	const featuresDiv = (
		<div className={classnames(classes.root, className)}>
			<div className={classes.div}>
				{title}
			</div>
			<div className={classes.div}>
				{filters}
			</div>
			{list}
		</div>
	);

	return featuresDiv;
};

export default withStyles(styles)(Features);