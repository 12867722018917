import React from 'react';
import List from '../../../../UI/List/List';
import {
	withStyles,
} from '@material-ui/core';
import Item from './Item/Item';
import listConfig from './listConfig';
import { styles } from './WorkOrderList.styles';
import classnames from 'classnames';

const WorkOrderList = ({
	classes,
	className,
	lastPage,
	onSearch,
	screenSize,
	selectedTemplate,
	setSelectedTemplate,
	workOrderTemplates,
	deleteTemplateHandler,
	editMode,
	setEditMode,
	masterEditMode,
}) => {

	const listItems = Array.isArray(workOrderTemplates) && workOrderTemplates.map(workOrderTemplate => (
		<Item
			key={workOrderTemplate.code}
			onSelect={() => {
				if (typeof selectedTemplate === 'undefined') {
					setSelectedTemplate(workOrderTemplate);
					return;
				}
				const value = selectedTemplate?.code !== workOrderTemplate?.code
					? workOrderTemplate
					: null;
				setSelectedTemplate(value);
			}}
			setSelectedTemplate={setSelectedTemplate}
			selected={selectedTemplate?.code === workOrderTemplate?.code}
			workOrderTemplate={workOrderTemplate}
			onRemove={deleteTemplateHandler}
			masterEditMode={masterEditMode}
			editMode={editMode}
			setEditMode={setEditMode}
		/>
	));

	return (
		<List
			className={classnames(classes.root, className)}
			disableGutters={true}
			overridePadding={true}
			getMoreRows={!lastPage && onSearch}
			headerFields={listConfig(screenSize)}
			subHeaderClassName={classes.subHeaderClassName}
		>
			{listItems}
		</List>
	);
};

export default withStyles(styles)(WorkOrderList);