import React from 'react';
import Detail from './Detail';
import DetailsDiv from '../../../UI/DetailsDiv/DetailsDiv';
import DetailsRow from '../../../UI/DetailsDiv/DetailsRow/DetailsRow';
import withStyles from '@material-ui/styles/withStyles';
import styles from './Details.styles';

const Pricing = ({
	classes,
	info,
}) => {

	const {
		pricingName,
		activeNumber,
	} = info || {};

	const pricingNameField = !!pricingName && (
		<DetailsRow
			label='Pricing Name'
			detail={
				<Detail detail={pricingName} />
			}
		/>
	);

	const activeNumberField = !!activeNumber && (
		<DetailsRow
			label='Number of Active Pricings'
			detail={
				<Detail detail={activeNumber} />
			}
		/>
	);

	const pricing = (
		<DetailsDiv title='Account Pricing'>
			{pricingNameField}
			{activeNumberField}
		</DetailsDiv>
	);

	return pricing;
};

export default withStyles(styles)(Pricing);