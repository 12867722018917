const styles = () => ({

	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		flexWrap: 'nowrap',
	},

	workspace: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
		position: 'relative',
	},

	list: {
		width: '100%',
		height: '100%',
		paddingLeft: 21,
		paddingRight: 21,
		display: 'flex',
		flexDirection: 'column',
	},

	details: {
		maxWidth: '284px',
		minWidth: '284px',
		paddingRight: 21,
		overflowY: 'auto',
	},

	drawer: {
		height: '100%',
		maxWidth: '284px',
		minWidth: '284px',
		paddingRight: 21,
	},

	title: {
		fontSize: '20px',
		marginRight: '20px',
	},

	searchbar: {
		display: 'flex',
		alignItems: 'baseline',
		width: '100%',
	},

	create: {
		flexDirection: 'row-reverse',
		display: 'flex',
		flexGrow: 1,
		marginRight: '20px',
	},

	createButton: {
		color: '#009AC9',
		cursor: 'pointer',
	},

	disabledButton: {
		color: '#888',
		cursor: 'default',
	},
});

export default styles;