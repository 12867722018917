import React, { useEffect, useState } from "react";
import usePlaceholder from "./usePlaceholder";
import useSwitch from "../../hooks/useSwitch";
import PricingPlanApp from "./PricingPlanApp";
import { useSelector } from "react-redux";
import { selectDefaultLocation } from "../../features/carclub/carclubSlice";
import { useDispatch } from "react-redux";
import { getZones, selectZones } from "../../features/asset/assetSlice";

const PricingPlanWrapper = (props) => {
  const dispatch = useDispatch();
  const zones = useSelector(selectZones);
  const { getPricingPlans } = props;
  const selectedLocation = useSelector(selectDefaultLocation);

  useEffect(() => {
    getPricingPlans();
    // eslint-disable-next-line
  }, [selectedLocation]);

  const [selectedPricingPlan, setSelectedPricingPlan] = useState();
  const placeholder = usePlaceholder();
  const editMode = useSwitch(false);

  const {
    addDetail,
    editDetail,
    removeDetail,
    resetPlaceholder,
    setCategory,
    setCode,
    setCumulative,
    setDescription,
    setDetails,
    setName,
    setStartingFee,
    setSpecific,
  } = placeholder;

  return (
    <PricingPlanApp
      {...props}
      getZones={() => dispatch(getZones())}
      zones={zones}
      editMode={editMode}
      placeholder={placeholder.state}
      selectedPricingPlan={selectedPricingPlan}
      setSelectedPricingPlan={setSelectedPricingPlan}
      placeholderSetters={{
        addDetail,
        editDetail,
        removeDetail,
        resetPlaceholder,
        setCategory,
        setCode,
        setCumulative,
        setDescription,
        setDetails,
        setName,
        setStartingFee,
        setSpecific,
      }}
    />
  );
};

export default PricingPlanWrapper;
