import React from 'react';
import CommonButtons from '../../../../../UI/Buttons/CommonButtons';
import {
	withStyles,
} from '@material-ui/core';
import styles from './Buttons.styles';
import classnames from 'classnames';

const Buttons = ({
	classes,
	className,
	closeHandler,
}) => {

	const cancelButton = (
		<CommonButtons
			buttonStyleName='void'
			key='Cancel'
			label='Cancel'
			onClick={() => closeHandler()}
		/>
	);

	const submitButton = (
        	<CommonButtons
			buttonStyleName='filled'
			key='Save'
			label='Save'
			type='submit'
		/>
	);


	return (
		<div className={ classnames( classes.root, className ) }>
			{cancelButton}
			{submitButton}
		</div>
	);
};

export default withStyles(styles)(Buttons);