export const CAR = {

	header: [
		{ label: 'Name', key: 'assetName' },
		{ label: 'License Plate', key: 'licensePlate' },
		{ label: 'Brand', key: 'make' },
		{ label: 'Model', key: 'model' },
	],

	details: [
		{ label: 'Vin', key: 'vinNumber' },
		{ label: 'Class', key: 'carClass' },
		{ label: 'Fuel', key: 'fuel' },
		{ label: 'Year', key: 'year' },
		{ label: 'Transmission', key: 'transmission' },
		{ label: 'Baby seat', key: 'babySeat', select: true},
	],

	device: [
		{ label: 'Provider', key: 'providerToken' },
		{ label: 'OBS number', key: 'serialNumber' },
	],

	required: [
		'vinNumber',
		'vehicleSequenceNumber',
		'carClass',
		'fuel',
		'make',
		'model',
		'licensePlate',
		'providerToken',
		'serialNumber',
		'operationalMode',
		'transmission',
	],

	blocked: [
		'vehicleSequenceNumber',
		'vehicleSequenceReference',
		'licensePlate',
	],

	waslDetails: [
		{ label: 'Registration No', key: 'vehicleSequenceNumber' },
	],

	staticDetails: [
		{ label: 'WASL', key: 'vehicleSequenceReference' },
	],

	nav: {
		steps: ['Vehicle details', 'Zones / Park', 'Associate device'],
		buttons: [['Cancel', 'Create Vehicle'], ['Do it later', 'Associate device'], ['Zones', 'Activate']],
	},
};

// TODO review this configs when tasks are done.
export const SCOOTER = {

	header: [
		{ label: 'License Plate', key: 'licensePlate' },
		{ label: 'Brand', key: 'make' },
		{ label: 'Model', key: 'model' },
	],

	details: [
		{ label: 'Fuel', key: 'fuel' },
		{ label: 'Pin', key: 'pin' },
	],

	device: [
		{ label: 'Provider', key: 'providerToken' },
		{ label: 'OBS number', key: 'serialNumber' },
	],

	required: [
		'licensePlate',
		'make',
		'model',
		'fuel',
		'pin',
	],

	blocked: [
		'licensePlate',
	],

	nav: {
		steps: ['Vehicle details', 'Zones / Park', 'Associate device'],
		buttons: [['Cancel', 'Create Vehicle'], ['Do it later', 'Associate device'], ['Zones', 'Activate']],
	},

};