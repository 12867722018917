import React, { useState } from 'react';
import Graphs from './Graphs';

const GraphsWrapper = ({
	...props
}) => {
	const [selected, setSelected] = useState();
	const [type, setType] = useState('LINE');

	return (
		<Graphs
			{...props}
			selected={selected}
			setSelected={setSelected}
			setType={setType}
			type={type}
		/>
	);
};

export default GraphsWrapper;