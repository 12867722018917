import React from 'react';
import NotificationList from './NotificationList';
import notificationFields from './notificationFields';

const NotificationListWrapper = props => {

	const {
		currentScreenSize,
		filters,
		notifications,
	} = props;

	const headerFields = notificationFields(currentScreenSize);

	const notificationsFiltered = filterNotifications(notifications, filters);

	return (
		<NotificationList
			{...props}
			{...{headerFields}}
			notifications={notificationsFiltered}
		/>
	);
};

const filterNotifications = (notifications, filters) => {
	
	const {
		type,
		subType,
	} = filters || {};

	const validType = !!type
		&& !!notifications.find(notification => notification.type === type);

	const validSubType = !! subType
		&& !!notifications.find(notification => notification.subType === subType);

	return notifications
		&& notifications.length > 0
		&& notifications
			.filter(notification =>
				(!validType || notification.type === type)
				&& (!validSubType || notification.subType === subType)
			);
};

export default NotificationListWrapper;
