import React from 'react';
import Button from '../../../UI/Buttons/CommonButtons';
import {
	TextField,
	Typography,
	withStyles,
} from '@material-ui/core';
import styles from './RateEditor.styles';


const RateEditor = ({
	classes,
	disableButtons,
	hasError,
	onCancel,
	onSave,
	placeholder,
	placeholderSetters,
	editMode,
	error,
	defaultLocation,
}) => {

	const {
		color,
		goldPinInUse,
		goldPinInStandby,
		id,
		name,
		priceInStandby,
		priceInUse,
		priceKm,
		rateNumber,
	} = placeholder || {};


	const title = (
		<Typography className={classes.title}>
			{`${id ? 'Editing' : 'Creating'} Rate`}
		</Typography>
	);

	const nameField = (
		<TextField
			value={name || ''}
			error={hasError && placeholder.name.trim().length < 1}
			helperText={hasError && placeholder.name.trim().length < 1 && 'Please fill the name'}
			id='nameEditor'
			label='Name'
			onChange={event => placeholderSetters.setNameRate(event.target.value)}
			disable={(!editMode.state).toString()}
			required={true}
		/>
	);

	const defaultLocationField = (
		<TextField
			value={defaultLocation?.name || ''}
			id='defLocEditor'
			label='Location'
			disabled={true}
		/>
	);

	const rateNumberField = (
		<TextField
			value={rateNumber || ''}
			error={hasError && placeholder.rateNumber.trim().length < 1}
			helperText={hasError && placeholder.rateNumber.trim().length < 1 && 'Please fill the rate number'}
			id='rateNumberEditor'
			label='Rate number'
			onChange={event => placeholderSetters.setRateNumber(event.target.value)}
			disable={(!editMode.state).toString()}
			required={true}
		/>
	);

	const priceInUseField = (
		<TextField
			id='descriptionEditorID'
			onClick={() => document.getElementById("descriptionEditorID").select()}
			label='Price in use'
			value={priceInUse || 0}
			onChange={event => placeholderSetters.setPriceInUse(event.target.value)}
			disable={(!editMode.state).toString()}
			required={true}
			error={hasError && placeholder.priceInUse.trim().length < 1}
			helperText={hasError && placeholder.priceInUse.trim().length < 1 && 'Please fill the priceInUse'}
		/>
	);

	const priceInStandbyField = (
		<TextField
			id='startingFeeEditorID'
			onClick={() => document.getElementById("startingFeeEditorID").select()}
			label='Price in standby'
			value={priceInStandby || 0}
			onChange={event => placeholderSetters.setPriceInStandby(event.target.value)}
			disable={(!editMode).toString()}
			required={true}
		/>
	);

	const priceKmField = (
		<TextField
			id='priceKmID'
			onClick={() => document.getElementById("priceKmID").select()}
			label='Price by Km'
			value={priceKm || 0}
			onChange={event => placeholderSetters.setPriceInKm(event.target.value)}
			disable={(!editMode).toString()}
			required={true}
		/>
	);

	const priceGPUse = (
		<TextField
			id='priceGPUseID'
			onClick={() => document.getElementById("priceGPUseID").select()}
			label='Gold Pin in use price'
			value={goldPinInUse || 0}
			onChange={event => placeholderSetters.setGoldPinInUse(event.target.value)}
			disable={(!editMode).toString()}
			required={true}
		/>
	);

	const priceGPStandby = (
		<TextField
			id='priceGPStandbyID'
			onClick={() => document.getElementById("priceGPStandbyID").select()}
			label='Gold Pin Standby price'
			value={goldPinInStandby || 0}
			onChange={event => placeholderSetters.setGoldPinInStandby(event.target.value)}
			disable={(!editMode).toString()}
			required={true}
		/>
	);

	const colorField = (
		<TextField
			id='color'
			label='Color'
			onChange={event => placeholderSetters.setColor(event.target.value)}
			value={color || ''}
			placeholder='#B2F6CC, #ff00ff, red, lightgreen...'
			disable={(!editMode).toString()}
		/>
	);

	const errorField = !!error && (
		<Typography align='center' className={classes.error}>
			{error}
		</Typography>
	);

	const saveButton = (
		<Button
			buttonStyleName='filled'
			disabled={disableButtons}
			key='SaveButton'
			label={'Save'}
			type='submit'
		/>
	);

	const cancelButton = (
		<Button
			buttonStyleName='void'
			disabled={disableButtons}
			key='CancelButton'
			label='Cancel'
			onClick={onCancel}
		/>
	);

	const rateEditor = (
		<div className={classes.root}>
			<form
				onSubmit={onSave}
			>
				{title}
				{defaultLocationField}
				{nameField}
				{rateNumberField}
				{priceInUseField}
				{priceInStandbyField}
				{priceGPUse}
				{priceGPStandby}
				{priceKmField}
				{colorField}
				<div className={classes.buttons}>
					{cancelButton}
					{saveButton}
				</div>
			</form>
			{errorField}
		</div>
	);

	return rateEditor;
};

export default withStyles(styles)(RateEditor);