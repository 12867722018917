import React from 'react';
import ListItem from '../../../../UI/List/ListItem/ListItem';
import {
	Tooltip,
	withStyles,
} from '@material-ui/core';
import {
	GetApp as DownloadIcon,
	RemoveCircle as RemoveIcon,
} from '@material-ui/icons';
import styles from './PromoCodeItem.styles';
import { dateFormatter } from '../../../../../services/formatter';
import { currencyFormatter } from '../../../../../services/formatter';
import withPermission from '../../../../../hoc/withPermission';

const PromoCodeItem = ({
	classes,
	promoCodeItem,
	fields,
	onSelectHandler,
	selected,
	onDisableHandler,
	onDownloadCSVHandler,
}) => {
	const isEnabled = !promoCodeItem?.disabled;

	const DownloadCSVButton = DownloadCSVButtonWithPermission(isEnabled);
	const DisableButton = DisableButtonWithPermission(isEnabled);

	const downloadCSVIcon = (
		<DownloadCSVButton
			classes={classes}
			onClick={() => onDownloadCSVHandler(promoCodeItem)}

		/>
	);

	const disableIcon = (
		<DisableButton
			classes={classes}
			onClick={() => onDisableHandler(promoCodeItem)}
		/>
	);

	const icons = (
		<>
			{disableIcon}
			{downloadCSVIcon}
		</>
	);

	const amount = !!promoCodeItem.amount &&
		`${currencyFormatter.format(promoCodeItem.amount)} ${currencyFormatter.symbol()}`;


	const item = !!promoCodeItem && (
		<ListItem
			selected={selected}
			fields={fields}
			onSelect={() => onSelectHandler(promoCodeItem)}
			element={{
				...promoCodeItem,
				expirationDate: dateFormatter.format(promoCodeItem.expirationDate, 'date'),
				amount,
				icons,
			}}
		/>
	);
	return item;
};

export default withStyles(styles)(PromoCodeItem);

const DownloadCSVButtonWithPermission = isEnabled => withPermission(
	[{ resource: ['promotions', 'bonusGenerator', 'edit'] }],
)(
	({
		classes,
		onClick,
	}) => isEnabled
		? (
			<Tooltip title='Download CSV' placement='left'>
				<DownloadIcon
					className={classes.editIcon}
					onClick={onClick}
				/>
			</Tooltip>
		)
		: (
			<DownloadIcon
				className={classes.editIconDisabled}
			/>
		)
);

const DisableButtonWithPermission = isEnabled => withPermission(
	[{ resource: ['promotions', 'bonusGenerator', 'edit'] }],
)(
	({
		classes,
		onClick,
	}) => isEnabled
		? (
			<Tooltip title='Disable' placement='left'>
				<RemoveIcon
					className={classes.editIcon}
					onClick={onClick}
				/>
			</Tooltip>
		)
		: (
			<RemoveIcon
				className={classes.editIconDisabled}
			/>
		)
);

