export const styles = () => ({
  root: {
    textAlign: "left",
  },
  appversion: {
    position: "fixed",
    bottom: -1,
    right: -1,
    backgroundColor: "white",
    padding: "0px 5px",
    borderTopLeftRadius: 5,
    fontSize: 8,
    color: "#bbb",
    border: "1px solid #eee",
  },
});
