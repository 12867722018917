export const styles = (theme) => ({
  root: {
    position: "relative",
    height: 32,
    width: 32,
  },
  icon: {
    position: "absolute",
    height: 32,
    width: 32,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    color: "#FFF",
  },
  empty: {
    backgroundColor: "#89898B",
  },
});