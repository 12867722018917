import React from 'react';
import {
	withStyles,
	Typography,
} from '@material-ui/core';
import styles from './ErrorDiv.styles';
import classnames from 'classnames';

const ErrorDiv = ({
	classes,
	message,
	className,
}) => {

	const showError = (
		// FIXME This should simply merge className, not use specific objects inside what is a string,
		// according to the Material UI framework.
		<div className={classnames( classes.root, className && className.div )}>
			<Typography align='center' className={classnames( classes.text, className && className.text )}>
				{message}
			</Typography>
		</div>
	);

	return showError;
};

export default withStyles(styles)(ErrorDiv);