import React, { useMemo, useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid, Modal, Tabs, Tab, Divider, Button } from "@material-ui/core";

import { styles } from "./ReservationCreateUpdate.styles";
import AdvancedDatesInput from "./AdvancedDatesInput/AdvancedDatesInput";
import { useEffect } from "react";
import { useDateTime } from "../../../common/hooks";
import SelectedDriver from "./SelectedDriver/SelectedDriver";
import SelectedAsset from "./SelectedAsset/SelectedAsset";
import SelectedPark from "./SelectedPark/SelectedPark";
import ParkList from "./ParkList/ParkList";
import VehicleList from "./VehicleList/VehicleList";
import { useDispatch } from "react-redux";
import {
  reservationNewReservationCreateBo,
  reservationNewReservationUpdate,
  selectCreateLoading,
} from "../reservationsSlice";
import { useSelector } from "react-redux";
import CustomerList from "./CustomerList/CustomerList";

const reservationTypes = ["IMMEDIATE", "ADVANCED"];

function ReservationCreateUpdate({ classes, selectedAsset, onClose, open, scheduledTripToUpdate, selectedCustomer }) {
  let { now, toTimeZone, toUtc } = useDateTime();
  const dispatch = useDispatch();
  const loading = useSelector(selectCreateLoading);

  const ongoingTrip = useMemo(() => {
    return scheduledTripToUpdate && ["STARTED", "IDLE"].some(a => scheduledTripToUpdate.state === a)
  }, [scheduledTripToUpdate]);

  const defaultTabIndex = (selectedAsset?.parks && selectedAsset?.parks.length) > 0 || scheduledTripToUpdate ? 1 : 0;
  const assetDefaultPark =
    selectedAsset?.parks && selectedAsset?.parks.length > 0
      ? selectedAsset.parks.find((item) => item.inUse) || selectedAsset.parks[0]
      : {};

  const [type, setType] = useState(reservationTypes[defaultTabIndex]);
  const [tab, setTab] = useState(defaultTabIndex);
  const [selectedInputTab, setSelectedInputTab] = useState(
    type === "ADVANCED" ? "PARK" : selectedAsset ? "CUSTOMER" : "VEHICLE"
  );

  const [reservationDates, setReservationDates] = useState({
    lock: false,
    startDate: now().add(1, "hours"),
    endDate: now().add(2, "hours"),
  });
  const [reservationSelectedPark, setReservationSelectedPark] = useState(assetDefaultPark);
  const [reservationSelectedAsset, setReservationSelectedAsset] = useState(selectedAsset || {});
  const [reservationSelectedCustomer, setReservationSelectedCustomer] = useState(selectedCustomer || {});
  const [reservationSelectedAccount, setReservationSelectedAccount] = useState({});

  useEffect(() => {
    if (scheduledTripToUpdate) {
      setReservationDates({
        startDate: toTimeZone(scheduledTripToUpdate?.wantedStartDate),
        endDate: toTimeZone(scheduledTripToUpdate?.wantedEndDate),
      });
      setReservationSelectedPark({
        parkName: scheduledTripToUpdate.parkName,
        parkId: scheduledTripToUpdate.parkId,
      });
      setReservationSelectedAsset({
        ...scheduledTripToUpdate,
        assetToken: scheduledTripToUpdate.carToken,
        inUse: true,
      });
      setReservationSelectedCustomer({
        customerCode: scheduledTripToUpdate.customerCode,
        fullName: scheduledTripToUpdate.driver?.name,
        phoneNumber: scheduledTripToUpdate.driver?.phone,
        email: scheduledTripToUpdate.driver?.email,
      });
      setReservationSelectedAccount({
        id: scheduledTripToUpdate.driver?.account,
        name: scheduledTripToUpdate.driver?.accountName,
      });
    }
    // eslint-disable-next-line
  }, [scheduledTripToUpdate]);

  const tabChangeHandler = (e, tabIndex) => {
    setTab(tabIndex);
    setType(reservationTypes[tabIndex]);
    setReservationSelectedAsset({});
    if (tabIndex === 0) {
      setSelectedInputTab("VEHICLE");
      setReservationSelectedPark({});
    } else {
      setSelectedInputTab("PARK");
    }
  };

  const advancedDatesChange = (obj) => {
    console.log('advancedDatesChange')
    setReservationDates((prev) => ({ ...prev, ...obj }));
  };

  const onInputTabChangeHandler = (inputTab) => {
    setSelectedInputTab(inputTab);
  };

  const onParkSelectedHandler = (park) => {
    if (!park || reservationSelectedPark.parkId === park.id) {
      setReservationSelectedPark({});
      setReservationSelectedAsset({});
    } else {
      setReservationSelectedPark({
        parkId: park.id,
        parkName: park.name,
      });
    }
  };

  const onAssetSelectedHandler = (asset) => {
    console.log("onAssetSelectedHandler - asset: %o", asset);
    if (!asset || reservationSelectedPark.assetToken === (asset.carToken || asset.assetToken)) {
      setReservationSelectedAsset(
        scheduledTripToUpdate
          ? {
            ...scheduledTripToUpdate,
            assetToken: scheduledTripToUpdate.carToken,
            inUse: true,
          }
          : {}
      );
    } else {
      setReservationSelectedAsset({
        ...asset,
        assetToken: asset.carToken || asset.assetToken,
      });
    }
  };

  const onCustomerSelectedHandler = (customer) => {
    console.log("customer: %o", customer);
    if (!customer || reservationSelectedCustomer.customerCode === customer.code) {
      setReservationSelectedCustomer({});
    } else {
      setReservationSelectedCustomer({
        ...customer,
        customerCode: customer.code,
        fullName: customer.fullname,
        phoneNumber: customer.mobileNumber,
      });
    }
    setReservationSelectedAccount({});
  };

  const onAccountSelectedHandler = (account) => {
    console.log("account: %o", account);
    if (!account || reservationSelectedAccount.id === account.id) {
      setReservationSelectedAccount({});
    } else {
      setReservationSelectedAccount({
        ...account,
      });
    }
  };

  const onCreateHandler = () => {
    console.log("CREATE");
    const { endDate, startDate } = reservationDates;

    dispatch(
      reservationNewReservationCreateBo({
        accountId: reservationSelectedAccount.id,
        carToken: reservationSelectedAsset.assetToken,
        customerCode: reservationSelectedCustomer.customerCode,
        parkId: reservationSelectedPark.parkId,
        startDate: type === "ADVANCED" && startDate ? toUtc(startDate).format("YYYY-MM-DD HH:mm:ss") : undefined,
        endDate: type === "ADVANCED" && endDate ? toUtc(endDate).format("YYYY-MM-DD HH:mm:ss") : undefined,
      })
    );
    setTimeout(() => {
      onClose && onClose();
    }, 1000);
  };

  const onUpdateHandler = () => {
    console.log("UPDATE");
    const { endDate, startDate } = reservationDates;
    dispatch(
      reservationNewReservationUpdate({
        reservationCode: scheduledTripToUpdate.reservationCode,
        carToken: reservationSelectedAsset.assetToken,
        parkId: reservationSelectedPark.parkId,
        startDate: type === "ADVANCED" && startDate ? toUtc(startDate).format("YYYY-MM-DD HH:mm:ss") : undefined,
        endDate: type === "ADVANCED" && endDate ? toUtc(endDate).format("YYYY-MM-DD HH:mm:ss") : undefined,
      })
    );
    setTimeout(() => {
      onClose && onClose();
    }, 1000);
  };

  const disabledButtons =
    (type === "ADVANCED" &&
      (!reservationDates.lock ||
        !reservationDates.startDate ||
        !reservationDates.endDate ||
        reservationDates.endDate < reservationDates.startDate)) ||
    !reservationSelectedAsset?.assetToken ||
    !reservationSelectedCustomer?.customerCode;

  return (
    <Modal onClose={onClose} open={open}>
      <div className={classes.root}>
        <Grid container direction="column" style={{ height: "100%" }} spacing={1} justifyContent="space-between">
          <Grid item style={{ height: "calc(100% - 45px)" }}>
            <Grid container direction="column" spacing={1} style={{ height: "100%" }}>
              <Grid item>
                <Typography className={classes.title}>
                  {scheduledTripToUpdate ? "Update Reservation" : "Create new Reservation"}
                </Typography>
              </Grid>
              {!scheduledTripToUpdate && (
                <Grid item>
                  <Tabs
                    value={tab}
                    indicatorColor="primary"
                    style={{ height: 40, minHeight: 40 }}
                    textColor="primary"
                    onChange={tabChangeHandler}
                  >
                    <Tab label="Immediate" />
                    <Tab label="Advanced" />
                  </Tabs>
                </Grid>
              )}
              {type === "ADVANCED" && (
                <Grid item>
                  <AdvancedDatesInput
                    onChange={advancedDatesChange}
                    onLock={() => setReservationDates((prev) => ({ ...prev, lock: !prev.lock }))}
                    {...reservationDates}
                    ongoing={ongoingTrip}
                  />
                </Grid>
              )}
              <Grid item style={{ flexGrow: 1 }}>
                <Grid container direction="row" spacing={2} wrap="nowrap" style={{ height: "100%" }}>
                  <Grid item style={{ width: 260 }}>
                    <Grid container spacing={1} direction="column">
                      {type === "ADVANCED" && (
                        <Grid item container>
                          <SelectedPark
                            park={reservationSelectedPark}
                            onClick={() => onInputTabChangeHandler("PARK")}
                            disabled={("ADVANCED" === type && !reservationDates.lock) || ongoingTrip}
                            selected={selectedInputTab === "PARK"}
                          />
                        </Grid>
                      )}
                      <Grid item container>
                        <SelectedAsset
                          asset={reservationSelectedAsset}
                          onClick={() => onInputTabChangeHandler("VEHICLE")}
                          disabled={("ADVANCED" === type && (!reservationDates.lock || !reservationSelectedPark.parkId)) || ongoingTrip}
                          selected={selectedInputTab === "VEHICLE"}
                        />
                      </Grid>
                      <Grid item container>
                        <SelectedDriver
                          customer={reservationSelectedCustomer}
                          account={reservationSelectedAccount}
                          onClick={() => onInputTabChangeHandler("CUSTOMER")}
                          disabled={("ADVANCED" === type && (!reservationDates.lock || scheduledTripToUpdate)) || ongoingTrip}
                          selected={selectedInputTab === "CUSTOMER"}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item style={{ width: "calc(100% - 260px)" }}>
                    {selectedInputTab === "PARK" && (
                      <ParkList
                        onSelected={onParkSelectedHandler}
                        parkId={reservationSelectedPark.parkId}
                        parkName={reservationSelectedPark.parkName}
                        disabled={!reservationDates.lock || ongoingTrip}
                      />
                    )}
                    {selectedInputTab === "VEHICLE" && (
                      <VehicleList
                        assetToken={
                          reservationSelectedAsset?.assetToken
                        }
                        licensePlate={
                          reservationSelectedAsset?.licensePlate
                        }
                        assetName={
                          scheduledTripToUpdate ? scheduledTripToUpdate.assetName : reservationSelectedAsset?.assetName
                        }
                        parkId={reservationSelectedPark.parkId}
                        startDate={reservationDates.startDate}
                        endDate={reservationDates.endDate}
                        disabled={(type === "ADVANCED" && !reservationDates.lock) || ongoingTrip}
                        onSelected={onAssetSelectedHandler}
                      />
                    )}
                    {selectedInputTab === "CUSTOMER" && (
                      <CustomerList
                        customerCode={reservationSelectedCustomer.customerCode}
                        customerName={reservationSelectedCustomer.fullName}
                        customerPhone={reservationSelectedCustomer.phoneNumber}
                        disabled={(type === "ADVANCED" && !reservationDates.lock) || ongoingTrip}
                        onSelected={onCustomerSelectedHandler}
                        accountId={reservationSelectedAccount.id}
                        onAccountSelected={onAccountSelectedHandler}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column" justifyContent="center" spacing={1}>
              <Grid item>
                <Divider />
              </Grid>
              <Grid item style={{ textAlign: "center" }}>
                {scheduledTripToUpdate ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onUpdateHandler}
                    disabled={loading || disabledButtons}
                  >
                    Update Reservation
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onCreateHandler}
                    disabled={loading || disabledButtons}
                  >
                    Confirm Reservation
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
}

export default withStyles(styles)(ReservationCreateUpdate);
