import React from "react";
import withStyles from "@material-ui/styles/withStyles";
import LocalParkingIcon from "@material-ui/icons/LocalParking";

import { styles } from "./ParkIcon.styles";

function ParkIcon({ classes, empty }) {
  return (
    <div className={classes.root}>
      <div className={classes.icon + (empty ? " " + classes.empty : "")}>
        <LocalParkingIcon />
      </div>
    </div>
  );
}

export default withStyles(styles)(ParkIcon);
