import React from 'react';
import List from '../../UI/List/List';
import PricingPlanItem from './PricingPlanItem/PricingPlanItem';
import {
	withStyles,
} from '@material-ui/core';
import pricingPlanFields from './pricingPlanFields';
import styles from './PricingPlanList.styles';
import classnames from 'classnames';

const PricingPlanList = ({
	classes,
	className,
	currentScreenSize,
	onChange,
	onEdit,
	onRemove,
	onSelect,
	onUpdate,
	placeholderSetters,
	pricingPlanArray,
	onCustomersOrZonesEdit,
}) => {

	const pricingPlans = !!pricingPlanArray && pricingPlanArray.map(pricingPlan => (
		<PricingPlanItem
			key={pricingPlan.id}
			onEdit={onEdit}
			onChange={onChange}
			onRemove={onRemove}
			onSelect={onSelect}
			onUpdate={onUpdate}
			onCustomersOrZonesEdit={onCustomersOrZonesEdit}
			pricingPlan={pricingPlan}
			placeholderSetters={placeholderSetters}
		/>
	));
	
	const list = (
		<List
			className={classnames(classes.root, className)}
			headerFields={pricingPlanFields(currentScreenSize)}
		>
			{pricingPlans}
		</List>
	);

	return list;
};

export default withStyles(styles)(PricingPlanList);