const styles = theme => ({
	root: {
		backgroundColor: 'inherit',
		height: '50%',
		minWidth: 'inherit',
		maxWidth: 'inherit',
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
	},

});

export default styles;