import Park from '../../../../model/Parks/Park';
//TODO decide if line below is needed
// import geofenceTypes from '../../../../model/Geofence/geofenceTypes';

const fromDTO = ({
	carClubCode,
	carclubId,
	countryCode,
	createDate,
	delete: deleted,
	description,
	geoInformation,
	geoLocation,
	geoPolygn,
	id,
	latitude,
	locationImg,
	longitude,
	maxedSpots,
	name,
	numberAssets,
	parkCode,
	updateDate,
	updateUser,
}) => new Park({
	carClubCode,
	carclubId,
	code: parkCode,
	countryCode,
	createDate,
	deleted,
	description,
	geoInformation,
	geoLocation,
	id,
	latitude,
	locationImg,
	longitude,
	maxSpots: maxedSpots,
	name,
	numberAssets,
	updateDate,
	updateUser,
});

const toDTO = ({
	carClubCode,
	carclubId,
	countryCode,
	createDate,
	geoLocation,
	geoPolygon,
	id,
	latitude,
	locationImg,
	longitude,
	name,
	maxSpots,
	deleted,
	updateDate,
	updateUser,
}) => ({
	carClubCode,
	carclubId,
	countryCode,
	createDate,
	geoLocation,
	geoPolygon,
	id,
	latitude,
	locationImg,
	longitude,
	name,
	maxSpots,
	'delete': deleted,
	updateDate,
	updateUser,
});

const mapper = { fromDTO, toDTO };

export default mapper;