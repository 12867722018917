import React from 'react';
import AccountButtons
	from '../../components/Account/AccountButtons/AccountButtons';
import AccountList
	from '../../components/Account/AccountList/AccountListWrapper';
import AccountDetails
	from '../../components/Account/AccountDetails/AccountDetailsWrapper';
import SearchBar
	from '../../components/Account/AccountFilters/AccountFiltersWrapper';
import UpsertAccountWrapper from '../../components/Account/UpsertAccount/UpsertAccountWrapper';
import Members from '../../components/Account/Members/MembersWrapper';
import Consumption from '../../components/Account/Consumption/ConsumptionWrapper';
import TripsModal from '../../components/Account/TripsModal/TripsModalWrapper';
import {
	Drawer,
	Modal,
	Typography,
	withStyles,
} from '@material-ui/core';
import styles from './AccountApp.styles';

const AccountApp = ({
	accounts,
	classes,
	closeModal,
	createAccount,
	currentScreenSize,
	customerCode,
	enableAccount,
	filter,
	getAccount,
	getMoreRows,
	lastPage,
	listAccounts,
	modal,
	modals,
	onSelect,
	onSort,
	onUpsert,
	page,
	removeAccount,
	selected,
	setCustomerCode,
	setFilter,
	setModal,
	updateAccount,
}) => {
	const buttons = (
		<AccountButtons
			disabled={!selected}
			onCreate={() => setModal(modals.CREATE)}
		/>
	);

	const searchBar = (
		<div className={classes.actionBar}>
			<div className={classes.leftSide}>
				<Typography className={classes.title}>
					Accounts
				</Typography>
				<SearchBar
					{...{ filter, setFilter }}
				/>
			</div>
			<div className={classes.rightSide}>
				{buttons}
			</div>
		</div>
	);

	const list = (
		<AccountList
			accounts={accounts}
			className={classes.customerList}
			currentScreenSize={currentScreenSize}
			enableAccount={enableAccount}
			getAccount={getAccount}
			getMoreRows={getMoreRows}
			lastPage={lastPage}
			onSelect={onSelect}
			onSort={onSort}
			page={page}
			removeAccount={removeAccount}
			selected={selected}
			setFilter={setFilter}
			setModal={setModal}
		/>
	);

	// TODO: When we have Details
	// eslint-disable-next-line
	const details = !!accounts && !!selected && (
		<AccountDetails
			details={accounts[selected]}
			getAccount={getAccount}
		/>
	);

	const accountMembers = !!accounts && !!selected && (
		<Drawer
			anchor='right'
			onClose={closeModal}
			open={modal === modals.MEMBERS}
		>
			<div className={classes.drawer}>
				<Members
					account={selected}
					currentScreenSize={currentScreenSize}
					setCustomerCode={setCustomerCode}
					setModal={setModal}
				/>
			</div>
		</Drawer>
	);

	const upsertAccountContent = (
		<div className={classes.upsertModal}>
			<UpsertAccountWrapper
				createAccount={createAccount}
				updateAccount={updateAccount}
				openAccount={accounts[selected]}
				exitUpsert={closeModal}
				modal={modal}
				modals={modals}
				listAccounts={listAccounts}
				refreshList={() => setFilter(prev => ({ ...prev, page: 1 }))}
			/>
		</div>
	);

	const consumptionModal = (
		<div className={classes.modal}>
			<Consumption
				accountCode={selected}
			/>
		</div>
	);

	const tripsModal = !!selected && (
		<div className={classes.modal}>
			<TripsModal
				accountCode={selected}
				customerCode={customerCode}
				currentScreenSize={currentScreenSize}
				setCustomerCode={setCustomerCode}
			/>
		</div>
	);

	const modalMapper = {
		[modals.CREATE]: upsertAccountContent,
		[modals.EDIT]: upsertAccountContent,
		[modals.TRIPS]: tripsModal,
		[modals.CONSUMPTION]: consumptionModal,
	};

	const currentModal = [
		modals.CREATE,
		modals.EDIT,
		modals.TRIPS,
		modals.CONSUMPTION,
	].includes(modal) &&
		(
			<Modal
				onClose={closeModal}
				open={true}
			>
				{modalMapper[modal]}
			</Modal>
		);

	const accountApp = (
		<div className={classes.root}>
			<div className={classes.workspace}>
				{currentModal}
				{accountMembers}
				<div className={classes.list}>
					{searchBar}
					{list}
				</div>
			</div>
		</div>
	);

	return accountApp;
};

export default withStyles(styles)(AccountApp);