import React,
{
	useEffect,
	useReducer,
	useRef,
} from 'react';
import { useCancellablePromise } from '../../../hooks/useCancellablePromise/useCancellablePromise.jsx';
import {
	Search as SearchIcon,
	Close as CrossIcon,
} from '@material-ui/icons';
import {
	InputAdornment,
	InputBase,
	Button,
	withStyles,
	Typography,
} from '@material-ui/core';
import * as actions from './actions';
import { initialState, reducer } from './reducer';
import log from '../../../utils/logger.js';
import styles from './PricingPlanFilter.styles';

const logger = log('PricingPlanFilter');

const initialFilter = {
	string: null,
};

const PricingPlanFilter = ({
	classes,
	getPPList,
}) => {

	const [state, dispatch] = useReducer(reducer, initialState({
		filter: { ...initialFilter },
	}));

	const {
		filter,
		hasSearch,
		buttonTimeout,
	} = state;

	const isMounted = useRef(true);



	useEffect(() => {

		const cleanup = () => {
			clearTimeout(buttonTimeout);
			isMounted.current = false;
		};
		return cleanup;
		// eslint-disable-next-line
	}, []);

	const { makeCancellablePromise } = useCancellablePromise();

	//Search Reservation

	const searchReservationsHandler = async (
		page = 0
	) => {
		dispatch(actions.setHasSearch(true));

		await getPPList({ ...filter, page });

		return new Promise((resolve, reject) => {
			isMounted.current
				? resolve(dispatch(actions.setHasSearch(false)))
				: reject(new Error(
					`Cancelling async function on unmounted component on searching`
				));
		}).catch(error => logger.debug(error));
	};

	// Handle Click and DoubleClick

	const _onSingleClick = (cb, timeout) => {

		if (!buttonTimeout) {

			const timerId = setTimeout(() => {
				dispatch(actions.setButtonTimeout(false));
				makeCancellablePromise(cb())
					.catch(error => logger.debug(error));
			}, timeout || process.env.REACT_APP_SINGLECLICK_TIMEOUT);

			dispatch(actions.setButtonTimeout(timerId));
		};
	};

	const _onDoubleClick = cb => {

		if (!!buttonTimeout) {
			clearTimeout(buttonTimeout);
			dispatch(actions.setButtonTimeout(false));
		};

		makeCancellablePromise(cb())
			.catch(error => logger.debug(error));
	};

	//Clearn filters

	const _cleanSearch = currentFilter => {

		if (!currentFilter) {
			dispatch(actions.setFilter({ ...initialFilter }));
			return;
		};

		const filterProp = (filter => {
			switch (filter) {
				case 'fromDate': return null;
				case 'toDate': return null;
				case 'string': return '';
				default: return [];
			}
		})(currentFilter);

		dispatch(actions.setFilter({
			...filter,
			[currentFilter]: filterProp,
		})
		);
	};

	const stringFilterHandler = (option) => {
		dispatch(actions.setFilter({ ...filter, ...option.filter }));

	};

	const title = (
		<Typography className={classes.title}>
			Pricing Plans
		</Typography>
	);

	const icon = (
		<CrossIcon
			onClick={(event) => stringFilterHandler({
				filter:
					{ string: '' },
			})}
			className={classes.crossIcon}
			size="small"
		/>
	);


	const searchBar = (
		<div className={classes.searchBar}>
			<InputBase
				placeholder={'Search pricing plans'}
				value={filter.string || ''}
				classes={{	
					root: classes.inputRoot,
					input: classes.inputInput,
				}}
				onChange={(event) => stringFilterHandler({
					filter:
						{ string: event.target.value },
				})}
				onKeyDown={event => {
					if (event.keyCode === 13) {
						_onSingleClick(searchReservationsHandler);
						return;
					};
				}}
				endAdornment={!!filter.string &&
					<InputAdornment position="end">
						{icon}
					</InputAdornment>
				}
			/>
		</div>
	);

	const searchButton = (
		<Button
			aria-label="Search"
			onClick={(event) => _onSingleClick(searchReservationsHandler)}
			onDoubleClick={(event) => _onDoubleClick(_cleanSearch)}
			disabled={hasSearch}
		>
			<SearchIcon className={classes.searchIcon} />
		</Button>
	);

	const searchReservation = (
		<div className={classes.searchPP}>
			<div className={classes.titleAndSearch}>
				{title}
				{searchBar}
				{searchButton}
			</div>
			<div className={classes.searchDates}>
			</div>
		</div>
	);

	return searchReservation;

};

export default withStyles(styles)(PricingPlanFilter);