export const styles = theme => ({
  root: {
    top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		margin: 'auto',
		position: 'absolute',
		height: '500px',
		width: '700px',
		padding: '20px',
		backgroundColor: 'white',
		borderRadius: '10px',
		boxShadow: theme.shadows[5],
  },
	title: {
		fontSize: 16,
		color: '#a1a1a1',
	},
});