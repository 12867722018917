import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const customerCreateCall = async ({ customerNewList }, dispatch, state, customer) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "CUSTOMER/CREATE");
  let filter = state.getIn(["customer2"])?.customers.filter;
  try {
    let result = await wsCall({
      type: "CUSTOMER",
      subType: "CREATE",
      locationId: selectedLocation?.id,
      message: {
        ...customer,
        acceptedTerms: customer.aceptTermsDate,
      },
    });
    if (!result.error) {
      dispatch(setSuccess("Customer created."));
      dispatch(customerNewList({ reset: true, filter }));
    } else {
      rh.error(result?.description || "Error creating customer.");
    }
  } catch (_ex) {
    rh.error("Error creating customer.");
  }
  rh.close();
};

export default customerCreateCall;
