import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Button } from "@material-ui/core";

import { styles } from "./ExtendButton.styles";
import {
  reservationRequestReservationExtension,
  selectActionIsLoading,
} from "../../../reservationsSlice";
import SimpleConfirmationModal from "../../../../../common/displays/Modal/SimpleConfirmationModal";

function ExtendButton({ classes, reservation, actions }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectActionIsLoading);
  const [confirmModal, setConfirmModal] = useState(false);

  const { code, carToken } = reservation;

  const onExtendHandler = () => {
    setConfirmModal(false);
    dispatch(reservationRequestReservationExtension({ reservationCode: code, carToken }, actions));
  };

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setConfirmModal(true)}
        disabled={isLoading}
      >
        Extend Trip
      </Button>
      <SimpleConfirmationModal
        open={confirmModal}
        setOpen={setConfirmModal}
        message={`Are you sure you want to extend the reservation one hour?`}
        onConfirm={onExtendHandler}
      />
    </div>
  );
}

export default withStyles(styles)(ExtendButton);
