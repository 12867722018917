class ValidatorBuilder {

	constructor() {
		this._validators = [];
	}

    build = () => obj => this._validators.reduce((isValid, validator) => {
    	if (!isValid) {
    		return false;
    	}
    	return validator(obj);
    }, true);


    length = ({ min, max }) => {
        
    	this._validators.push(num => {

    		if (!num.length || isNaN(num.length)) {
    			return false;
    		}

    		if (!!min && num.length < min) {
    			return false;
    		}

    		if (!!max && num.length > max) {
    			return false;
    		}

    		return true;
    	});
			
    	return this;
    }
		
		letterAndNumbersOnly = () => {

			this._validators.push(num => {
				const result = num.match("^[A-z0-9]+$");
				return result === null ? false : true;
			});

			return this;
		}
}

export default ValidatorBuilder;


