import React from "react";
import Billing from "./Details/Billing";
import Others from "./Details/Others";
import Payment from "./Details/Payment";
import PersonalInfo from "./Details/PersonalInfo";
import PricingPlans from "./Details/PricingPlans";
import Documents from "./Details/Documents";
import LegalDocuments from "./Details/LegalDocuments";
import { Divider, withStyles } from "@material-ui/core";
import styles from "./CustomerDetails.styles";
import Devices from "./Details/Devices";
import { useSelector } from "react-redux";
import { selectDefaultLocation } from "../../../features/carclub/carclubSlice";
import { useEffect } from "react";
import { paymentGetPaymentInfo, selectSelectedCustomer } from "../../../features/customer/customerSlice";
import { useDispatch } from "react-redux";
import { isSharing, isCommercial, isSubscription } from "../../../common/utils/OTypes";

const CustomerDetails = ({
  classes,
  details,
  documents,
  openLink,
  pricingPlans,
  setOpen,
  showFiles,
  legalDocuments,
}) => {
  const dispatch = useDispatch();
  const selectedLocation = useSelector(selectDefaultLocation);
  const selectedCustomer = useSelector(selectSelectedCustomer);

  useEffect(() => {
    if (selectedCustomer && selectedLocation) {
      if (isSharing(selectedLocation.operationType) || isSubscription(selectedLocation.operationType)) {
        dispatch(paymentGetPaymentInfo(selectedCustomer.code));
      }
    }
    // eslint-disable-next-line
  }, [selectedCustomer, selectedLocation]);

  if (!details) {
    return <div></div>;
  }

  const hasPersonalInfo =
    !!details.name || !!details.surname || !!details.birthdate || !!details.phoneNumber || !!details.email;

  const hasBilling =
    !!details.taxNumber || !!details.address || !!details.city || !!details.zipCode || !!details.country;

  const customerDetails = (
    <div className={classes.root}>
      {hasPersonalInfo && (
        <>
          <PersonalInfo info={details} />
          <Divider />
        </>
      )}
      {hasBilling && (
        <>
          <Billing info={details} />
          <Divider />
        </>
      )}

      <Payment />

      <PricingPlans pricingPlans={pricingPlans} divider />
      {documents && (
        <>
          <Documents info={documents} openLink={openLink} setOpen={setOpen} showFiles={showFiles} />
          <Divider />
        </>
      )}
      {legalDocuments && (
        <>
          <LegalDocuments info={legalDocuments} />
          <Divider />
        </>
      )}
      <Others info={details} />
      {(isCommercial(selectedLocation.operationType) || isSubscription(selectedLocation.operationType)) && details.rfidToken && (
        <>
          <Divider />
          <Devices info={details} />
        </>
      )}
    </div>
  );

  return customerDetails;
};

export default withStyles(styles)(CustomerDetails);
