import React from "react";
import { withStyles } from "@material-ui/core";
import { styles } from "./TripInfo.styles";
import SimpleInputMapper from "../Utils/SimpleInputMapper";
import { useDateTime } from "../../../../common/hooks";

const OtherDetails = ({ advanced, classes, data }) => {
  const dt = useDateTime();
  const {
    activationDate,
    createDate,
    endDate,
    extendedBy,
    numberExtensions,
    /* parkEnd,
		parkStart, */
    realEnd,
    startDate,
    state,
    wantedEndDate,
    wantedStartDate,
  } = data || {};

  const parsedCreateDate = createDate ? dt.toTimeZone(createDate, "dateTime") : "-";

  const parsedStartDate = startDate ? dt.toTimeZone(startDate, "dateTime") : "-";

  const parsedWantedStartDate = startDate ? dt.toTimeZone(wantedStartDate, "dateTime") : "-";

  const parsedActivationDate = wantedStartDate && dt.toTimeZone(activationDate, "dateTime");

  const parsedEndDate = endDate ? dt.toTimeZone(endDate, "dateTime") : "-";

  const parsedWantedEndDate = endDate ? dt.toTimeZone(wantedEndDate, "dateTime") : "-";

  const parsedRealEnd = realEnd ? dt.toTimeZone(realEnd, "dateTime") : "-";

  const mapperConfig =
    (!!data && {
      "Create Date:": !advanced && (parsedCreateDate || "-"),
      "Start Date:": !advanced && (parsedStartDate || "-"),
      "Scheduled Start:": !!advanced && (parsedWantedStartDate || "-"),
      "Activation:":
        (("ONGOING" === state || "CLOSED" === state || "STARTED" === state) && parsedActivationDate) ||
        (advanced ? "-" : null),
      "End Date:": !advanced && (parsedEndDate || "-"),
      "Scheduled End": !!advanced && (parsedWantedEndDate || "-"),
      "End Trip": !!advanced && (parsedRealEnd || "-"),
      "No. of Extensions:": numberExtensions || null,
      "Extended by:": extendedBy || null,
    }) ||
    {};

  return (
    <div className={classes.otherInfo}>
      <SimpleInputMapper title="Driver" noTitle={true} inputmapperconfig={mapperConfig} />
    </div>
  );
};

export default withStyles(styles)(OtherDetails);
