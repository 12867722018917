
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import mapper from './mappers/locationMapper';
import { publishAndAwait } from '../../../app/coreSlice';

const listAllLocations = async () => {
	
	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'LOCATION',
			subType: 'LIST_ALL',
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	};

	if (error) {

		const {
			description,
			code,
			tag,
		} = processMessage;

		throw new NGError({
			message: description,
			code,
			tag,
		});
	};

	const locations = processMessage.reduce(reducer, {});

	console.log('LIST_ALL: %o', locations);

	return locations;
};

export default listAllLocations;

const reducer = (acc, location) => ({
	...acc,
	[location.id]: mapper.fromDTO({
		...location,
		geoInformation: `[${location.geoLocation}]`,
	}),
});