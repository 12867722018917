export const styles = theme => ({

	root: {
		height: '100%',
		width: '470',
		padding: '16px',
	},

	form: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},

	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
	},

	dates: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		flexBasis: '60%',
		alignItems: 'center',
	},

	bottom: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
	},

	btns: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
	},

	inputIcon: {
		padding: '2px',
	},

	input: {
		marginTop: '0 !important',
		padding: '3px !important',
		color: '#89898B',
		fontSize: '12px',
		fontWeight: '400',
		lineHeight: '14px',
		textAlign: 'left',
	},

	pickers: {
		maxWidth: '180px',
		maxHeight: '50px',
	},

	title: {
		fontSize: '22px',
	},

	equalMargin: {
		margin: '8px 0px',
	},

	amountTextField: {
		padding: '0px 6px',
	},

	codeCheckbox: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
	},

	checkboxTitle: {
		alignSelf: 'center',
	},

	greyTextField: {
		backgroundColor: '#F0F0F0',
	},

	codeField: {
		marginTop: 0,
	},

	pickerInput: {
		padding: 8,
	},

	outlined: {
		transform: 'translate(14px, 10px) scale(1)',
	},
	inputPropsMultine: {
		fontSize: 12,
		width: 100,
	},

	multiline: {
		padding: 0,
		margin: '0px 0px 8px 0px',
	},

	labelProps: {
		color: '#47525E',
		zIndex: 999,
	},

	fieldMultiline: {
		width: '100%',
		height: '100%',
		fontSize: '11px',
		display: 'flex',
		alignSelf: 'center',
		backgroundColor: '#fff',
	},

	endAdornment: {
		height: 45,
		fontSize: '12px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		marginLeft: 2,
	},

	valueDiv: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		width: 180,
	},

	row: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingBottom: 16,
		alignItems: 'baseline',
	},

	field: {
		width: 180,
	},
	
	noArrows: {
		'-moz-appearance': 'textfield',
		'&::-webkit-outer-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0,
		},
		'&::-webkit-inner-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0,
		},
	},

	error: {
		margin: '16px 10px',
		color: 'red',
		fontSize: '12px',
	},
});
