
import NGError from '../../../error/ngError';
import errorFactory from '../../../error/errorFactory';
import {
	WS_REQUEST_IS_MALFORMED,
	WS_PROCESS_MESSAGE_IS_MALFORMED,
} from '../../../error/websocket/errorTypes';
import { publishAndAwait } from '../../../app/coreSlice';

const updateAssetInfoOnly = async ({
	carToken,
	imageUrl,
	licensePlate,
	wanted,
	unwanted,
	serialNumber,
	providerToken,
	parkId,
	assetName,
	make,
	model,
	carClass,
	fuel,
	year,
	transmission,
	babySeat,
	deviceToken,
	attributes,
}) => {

	if (typeof carToken === 'undefined'
		|| 'undefined' === imageUrl
		|| 'undefined' === licensePlate
		|| 'undefined' === make
		|| 'undefined' === model
		|| 'undefined' === carClass
		|| 'undefined' === year
		|| 'undefined' === transmission
	) {
		throw errorFactory(WS_REQUEST_IS_MALFORMED);
	}

	const request = await publishAndAwait({
		destination: '/app/hello',
		body: {
			type: 'ASSET',
			subType: 'UPDATE_NEW',
			message: {
				carToken,
				imageUrl,
				licensePlate,
				wanted,
				unwanted,
				serialNumber,
				providerToken,
				parkId,
				assetName,
				make,
				model,
				carClass,
				fuel,
				year,
				transmission,
				babySeat,
				deviceToken,
				attributes,
			},
		},
	});

	const { processMessage, error } = request.response;

	if (!processMessage) {
		throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
	}

	if (error) {

		const {
			description,
			code,
			tag,
			message,
		} = processMessage;

		throw new NGError({
			message: description || message,
			code,
			tag,
		});
	}

	return processMessage;

};

export default updateAssetInfoOnly;