import chargeIncident from './chargeIncident';
import closeIncident from './closeIncident';
import getIncidents from './getIncidents';
import getIncidentTypes from './getIncidentTypes';
import invoiceIncident from './invoiceIncident';
import settleIncident from './settleIncident';
import updateIncident from './updateIncident';

const incidentsService = {
	chargeIncident,
	closeIncident,
	getIncidents,
	getIncidentTypes,
	invoiceIncident,
	settleIncident,
	updateIncident,
};

export default incidentsService;