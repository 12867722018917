export const styles = (theme) => ({
  root: {
    background: "#0197c6",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
    height: "100vh",
    width: "100vw",
  },
  content: {
    width: 400,
    display: "block",
    marginTop: 70,
  },
  paper: {
    padding: theme.spacing(3),
  },
  logo: {
    width: "100%",
    margin: "30px auto",
  },
  button: {
    "& button": {
      width: "100%",
    },
  },
});
