import React from 'react';
import {
	withStyles,
} from '@material-ui/core';
import { styles } from './AdditionalFeeDetails.styles';
import {
	Check as EnabledIcon,
} from '@material-ui/icons';
import classnames from 'classnames';
import SimpleInputMapper from '../../TripApp/TripDetails/Utils/SimpleInputMapper';
import { currencyFormatter } from '../../../services/formatter';


const AdditionalFeeDetails = ({
	classes,
	placeholderExtra,
}) => {
	const {
		name,
		description,
		longDescription,
		createDate,
		value,
		enabled,
	} = placeholderExtra || {};

	const enabledIcon = !!enabled && <EnabledIcon className={classes.checkIcon} />;

	const valueWithSymbol = !!value
		? currencyFormatter.format(value) + ' ' + currencyFormatter.symbol()
		: null;

	const additionalFeeDetailsMapperConfig = {
		'Name:': name || '-',
		'Description:': description || '-',
		'Long Description:': longDescription || '-',
		'Create date:': createDate || '-',
		'Value:': valueWithSymbol || '-',
		'Enabled:': enabledIcon || '-',
	};

	const additionalFeeDetailsStyled = (
		<SimpleInputMapper
			title='Additional Fee Details'
			titleClassName={classes.textFieldTitle}
			inputmapperconfig={additionalFeeDetailsMapperConfig}
		/>
	);

	return (
		<div
			className={classnames(classes.root)}
		>
			<div className={classes.detailsBlock}>
				{additionalFeeDetailsStyled}
			</div>
		</div>
	);
};

export default withStyles(styles)(AdditionalFeeDetails);