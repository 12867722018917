const style = (theme) => ({
  root: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    backgroundColor: "#08C",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: theme.shadows[7],
    padding: 8,
    position: "absolute",
    bottom: 7,
    left: 7,
    zIndex: 10,
  },

  icon: {
    height: "25px",
    width: "25px",
    color: "#fff",
  },

  main: {
    backgroundColor: "#fff",
    bottom: "7px",
    position: "absolute",
    height: "55vh",
    left: "47px",
    width: "25vw",
    cursor: "default",
    boxShadow: theme.shadows[7],
  },
});

export default style;
