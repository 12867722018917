import React, { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import ErrorScreen from "../../components/ErrorScreen/ErrorScreen";
import { IconButton, Snackbar, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { styles } from "./Layout.styles";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { selectCarclubDetails, selectDefaultLocation } from "../../features/carclub/carclubSlice";
import PermissionContext from "../../context/PermissionContext";
import * as notificationActions from "../../store/actions/notification/action";
import * as appActions from "../../store/actions/app/action";
import { assetList } from "../../features/asset/assetSlice";
import { selectConnected, selectCredentials } from "../../app/websocket/websocketSlice";

const notificationStatus = (classes) => ({
  INFO: classes.notificationInfo,
  ERROR: classes.notificationError,
});

const Layout = ({ classes, appProps, selectedApp, filteredApps }) => {
  const dispatch = useDispatch();
  const location = useSelector(selectDefaultLocation);
  const { carclubCode } = useParams();

  const connected = useSelector(selectConnected);
  const credentials = useSelector(selectCredentials);

  const appWindowWidth = useSelector((state) => state.getIn(["app", "windowWidth"]));
  const notification = useSelector((state) => state.getIn(["notification"]).toJS());
  const carclubDetails = useSelector(selectCarclubDetails);

  const onResize = () => {
    onWindowWidthChange(appWindowWidth, window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();
    return () => {
      window.removeEventListener("resize", onResize);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // console.log("change app: %o", selectedApp);
    if (selectedApp === "fleet" && connected) {
      dispatch(assetList({}));
    }
    // eslint-disable-next-line
  }, [selectedApp, connected]);

  const setNotificationHandler = (message = null, status = null) => {
    dispatch(notificationActions.setNotification(message, status));
  };

  // const wsActivate = (token) => dispatch(activate(token));
  const onWindowWidthChange = (currentWidth, newWidth) =>
    dispatch(appActions.appSetWindowWidth(currentWidth, newWidth));

  // console.log("carclubDetails: %o", carclubDetails);

  if (!credentials.username) {
    console.log("redirect to login - no credentials");
    return (
      <Navigate
        to={{
          pathname: carclubCode ? `/auth/${carclubCode.toLowerCase()}` : "/",
          state: { from: location },
        }}
      />
    );
  }

  if (appWindowWidth === 1) {
    return <ErrorScreen />;
  }

  const app =
    filteredApps.find((app) => {
      return selectedApp && app.tag === selectedApp;
    }) || filteredApps[0];

  const notificationBar = (
    <Snackbar
      className={classnames(classes.notification)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={!!notification.status}
      autoHideDuration={6000}
      onClose={() => setNotificationHandler(null, null)}
      ContentProps={{
        "aria-describedby": "message-id",
        className: notificationStatus(classes)[notification.status],
      }}
      //
      message={<span id="message-id">{notification.message}</span>}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={() => setNotificationHandler(null, null)}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  );

  return (
    <PermissionContext.Provider value={carclubDetails?.apps}>
      <div className={classes.root}>

        <div className={classes.workspace}>
          <div className={classes.app}>
            {app && (
              <app.component
                appProps={appProps}
                handleNotification={() => {} /** BUG check this one: setNotificationHandlerMessage */}
                tag={app.tag}
              />
            )}
          </div>
        </div>
        {notificationBar}
      </div>
    </PermissionContext.Provider>
  );
};

export default withStyles(styles)(Layout);
