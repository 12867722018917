const styles = (theme) => ({

	root: {
		maxwidth: 'max-content',
		width: '100%',
		maxHeight: 250,
		display: 'flex',
		flexDirection: 'column',
	},

	btn: {
		width: '56px',
	},

	list: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
	},

	details: {
		minWidth: '284px',
		maxWidth: '284px',
		paddingRight: 21,
		overflowY: 'auto',
	},

	titleAndInputs: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		flexBasis: '50%',
	},

	title: {
		fontSize: '18px',
		height: 40,
		maxHeight: 40,
	},

	searchBar: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	icon: {
		cursor: 'pointer',
		marginBottom: theme.spacing(1),
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(3),
		color: 'grey',
	},

	inputRoot: {
		color: 'inherit',
		width: '100%',
	},

	inputInput: {
		borderBottom: '1px solid gray',
		marginBottom: theme.spacing(1),
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(2),
		transition: theme.transitions.create('width'),
		width: '100%',
		color: 'grey',
		fontSize: 12,
	},
});

export default styles;