const styles = () => ({
	root: {
		paddingBottom: '30px',
	},

	flexHorizontal: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
	},

	buttonMinWidth: {
		minWidth: '138px !important',
	},
	
});

export default styles;