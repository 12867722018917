import {
	INCIDENTS_CLEAR_ELEMENTS,
	INCIDENTS_SET_DETAILS,
	INCIDENTS_SET_ELEMENTS,
} from '../../actions/incidents/actionTypes';
import * as mutate from './mutators';
import { pipe } from '../utils';
import createReducer from '../../utils/createReducer';
import { Map } from 'immutable';

const initialState = Map({
	elements: Map(),
	page: 0,
	lastPage: false,
});

const incidentsSetElements = (state, action) => {

	const mutators = [
		mutate.setElements(action.elements, 'incidentCode'),
	];

	'boolean' === typeof action.lastPage && mutators.push(
		mutate.setLastPage(action.lastPage));
	(action.page === 0 || !!action.page) && mutators.push(
		mutate.setPage(action.page));

	return pipe(mutators, state);
};

const incidentsClearElements = state => {
	return state.update('elements', map => map.clear());
};

const incidentsReducer = createReducer(initialState, {
	[INCIDENTS_SET_DETAILS]: undefined,
	[INCIDENTS_SET_ELEMENTS]: incidentsSetElements,
	[INCIDENTS_CLEAR_ELEMENTS]: incidentsClearElements,
});

export default incidentsReducer;