import React from 'react';
import ListItem from '../../../../UI/List/ListItem/ListItem';
import {
	withStyles,
} from '@material-ui/core';
import styles from './Item.styles';
import { dateFormatter, currencyFormatter } from '../../../../../services/formatter';

const Item = ({
	classes,
	bonus,
	fields,
	onClick,
	selected,
}) => {
	const amount = `${bonus?.amount || '-'} ${currencyFormatter.symbol()}`;
	
	const currentAmount = (bonus?.amount || 0) - (bonus?.amountUsed || 0);
	const unusedAmount = `${currentAmount} ${currencyFormatter.symbol()}`;

	const item = !!bonus && (
		<ListItem
			selected={selected}
			fields={fields}
			onSelect={onClick}
			element={{
				...bonus,
				expirationDate: dateFormatter.format(bonus.expirationDate, 'date'),
				createDate: dateFormatter.format(bonus.createDate, 'date'),
				unusedAmount,
				amount,
			}}
		/>
	);
	return item;
};

export default withStyles(styles)(Item);