import React from 'react';
import {
	Typography,
	withStyles,
} from '@material-ui/core';
import withPermission from '../../../../hoc/withPermission';
import TaskList from './TaskList/List';
import AddTask from './Add/AddTaskWrapper';
import Details from './Details/Details';
import Button from '../../../UI/Buttons/CommonButtons';
import styles from './Tasks.styles';

const Tasks = ({
	additionalInfo,
	cancelEditHandler,
	classes,
	hasChanges,
	onChangeTaskPlaceholderHandler,
	onRemove,
	onTaskSelect,
	selectedTaskImages,
	selectedTaskIndex,
	servicePoint,
	setTasks,
	tasks,
	updateWorkOrder,
	workorder,
}) => {
	const title = (
		<Typography className={classes.title}>
			Work Order Tasks
		</Typography>
	);

	const saveButton = !!hasChanges && (
		<ButtonWithPermission>
			<Button
				buttonStyleName='filled'
				className={classes.button}
				key='save_button'
				label='Save'
				onClick={() => {
					updateWorkOrder();
				}}
			/>
		</ButtonWithPermission>
	);

	const cancelButton = !!hasChanges && (
		<ButtonWithPermission>
			<Button
				buttonStyleName='void'
				className={classes.button}
				key='cancel_button'
				label='Cancel'
				onClick={() => {
					cancelEditHandler();
				}}
			/>
		</ButtonWithPermission>
	);

	const header = (
		<div className={classes.header}>
			{title}
			<div className={classes.buttons}>
				{cancelButton}
				{saveButton}
			</div>
		</div>
	);

	const taskList = (
		<TaskList
			onRemove={!workorder.endDate && onRemove}
			onTaskSelect={onTaskSelect}
			selectedTaskIndex={selectedTaskIndex}
			tasks={tasks}
		/>
	);

	const add = (
		<AddTask
			setTasks={setTasks}
			worktaskId={workorder.code}
			locationId={workorder.location}
		/>
	);

	const addWithPermission = !workorder.endDate && (
		<ButtonWithPermission>
			<div className={classes.addTasks}>
				{add}
			</div>
		</ButtonWithPermission>
	);

	const details = (
		<Details
			additionalInfo={additionalInfo}
			images={selectedTaskImages}
			onChangeTaskPlaceholderHandler={onChangeTaskPlaceholderHandler}
			selectedTask={selectedTaskIndex}
			servicePoint={servicePoint}
			task={tasks[selectedTaskIndex]}
		/>
	);

	return (
		<div className={classes.root}>
			<div className={classes.tasks}>
				{header}
				{taskList}
				<div className={classes.details}>
					{details}
				</div>
			</div>
			{addWithPermission}
		</div>
	);
};

export default withStyles(styles)(Tasks);

const ButtonWithPermission = withPermission(
	[{ resource: ['workOrders', 'edit'] }]
)(({
	children,
}) =>
	(
		<>
			{children}
		</>
	)
);