import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";

import { styles } from "./ParkList.styles";
import SearchList from "../../../../components/UI/SearchList/SearchListWrapper";
import { crParkList, selectCrParks } from "../../reservationsSlice";

function ParkList({ classes, parkId, parkName, disabled, onSelected }) {
  const dispatch = useDispatch();
  const parks = useSelector(selectCrParks);

  useEffect(() => {
    dispatch(crParkList(""));
    // eslint-disable-next-line
  }, []);

  return (
    <SearchList
      className={classes.root}
      currentFields={{
        key: parkId,
        label: parkName,
      }}
      disabled={disabled}
      data={parks.map((park) => ({ key: park.id, label: park.name, object: park }))}
      onSearch={(search) => {
        dispatch(crParkList(search));
      }}
      header={"Name"}
      name="Park"
      onSelect={({ object }) => {
        !disabled && onSelected && onSelected(object);
      }}
      searchLabel="Search Park"
    />
  );
}

export default withStyles(styles)(ParkList);
