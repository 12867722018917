import Image from '../../../../assets/AssetApp/createAsset/default.png';

const styles = () => ({

	root: {
		fontSize: '12px',
		width: '376px',
		minHeight: '70px',
		display: 'flex',
		justifyContent: 'space-between',
	},

	assetStatusContainer: {
		flexBasis: '33%',
		textAlign: 'center',
		alignSelf: 'center',
		verticalAlign: 'middle',
		height: '75%',
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
	},

	statusIcon: {
		maxHeight: '20px',
		textAlign: 'center',
		verticalAlign: 'middle',
	},


	assetInfoContainer: {
		flexBasis: '33%',
		textAlign: 'center',
		lineHeight: '14px',
		alignSelf: 'center',
		borderRight: '2px solid rgba(0, 0, 0, 0.20)',
		borderLeft: '2px solid rgba(0, 0, 0, 0.20)',
		height: '75%',
		marginLeft: '6px',
		marginRight: '6px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
	},

	assetImgContainer: {
		flexBasis: '33%',
		textAlign: 'center',
		verticalAlign: 'middle',
		// TODO temporary for case with no asset image
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-around',
	},

	image: {
		width: 80,
		height: 60,
	},

	imageBackground: {
		backgroundImage: `url(${Image})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	
	dividerStyles: {
		height: '87px',
		alignSelf: 'center',
		backgroundColor: '#969FAA',
		margin: '0 4px',
	},

	text: {
		textAlign: 'center',
		// lineHeight: '15px',
		margin: '6px',
	},

	reservationCode: {
		fontWeight: 700,
		margin: '6px',
	},

	noMargin: {
		margin: '0px',
	},

	flexColumn: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
	},

	flexHorizontal: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		alignItems: 'center',
	},



	isAgent: {
		borderRadius: '15px',
		fontSize: '10px',
		border: '1px solid #a1a1a2',
		width: '41px',
		height: '16px',
		textAlign: 'center',
		color: '#a1a1a2',
		marginLeft: '6px',
		padding: '1px 2px',
	},

});

export default styles;