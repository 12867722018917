import React from 'react';
import {
	Checkbox,
	FormControl,
	InputLabel,
	Select,
	TextField,
	Typography,
	withStyles,
} from '@material-ui/core';
import SearchList from '../../UI/SearchList/SearchListWrapper';
import {
	Button,
	createConfirmationField,
	currentFields,
	divider,
	typeHeader,
	typeOnAdd,
	Input,
	fitsPercentage,
} from './incidentsFunctions';
import Attachment from './attachmentHandler';
import { incidentTypeButtonOptions } from '../IncidentsFilter/incidentTypeButton';
import { currencyFormatter } from '../../../services/formatter';
import styles from './IncidentsUpdate.styles';

const IncidentsUpdate = ({
	classes,
	error,
	getList,
	hasAdministrativeCost,
	hasRequest,
	imageHandler,
	incidentTypes,
	onCancel,
	onChange,
	onSave,
	onTypeChange,
	placeholder,
	removeAttachment,
	setAdministrativeCost,
	type,
	typeRef,
}) => {
	const title = (
		<Typography className={classes.title}>
			{placeholder?.incidentCode ? `Editing ${placeholder.incidentCode}` : 'Creating new incident'}
		</Typography>
	);

	const confirmationField = createConfirmationField({
		classes,
		placeholder,
		onChange: !placeholder?.incidentCode && onChange,
		hasRequest,
	});

	const header = (
		<div className={classes.header}>
			{[
				{ label: 'Reservation', field: 'reservationCode', fieldName: 'reservationCode' },
				{ label: 'Vehicle', field: 'carToken', fieldName: 'licensePlate' },
				{ label: 'Customer', field: 'customerCode', fieldName: 'customerCode' },
			].map(confirmationField)}
		</div>
	);

	const typeSearchOptions = ['Reservation', 'Vehicle', 'Customer'].map(param => (
		<option value={param} key={param} className={classes.capitalize}>
			{param}
		</option>
	));

	const searchType = (
		<Select
			className={classes.selectEmpty}
			native
			onChange={e => onTypeChange(e.target.value)}
			value={type}
		>
			{typeSearchOptions}
		</Select>
	);

	const listType = !placeholder.incidentCode && (
		<SearchList
			currentFields={currentFields(placeholder)[type]}
			disabled={hasRequest}
			getList={getList}
			header={typeHeader[type]}
			name={searchType}
			onAdd={typeOnAdd(onChange)[type]}
			onRemove={() => onChange({
				reservationCode: undefined,
				customerName: undefined,
				licensePlate: undefined,
				customerCode: undefined,
				carToken: undefined,
				mobile: undefined,
			})}
			ref={typeRef}
			searchLabel='Search...'
		/>
	);

	const selectIncidentType = (
		<FormControl>
			<InputLabel htmlFor="selectIncidentType">
				Incident Type
			</InputLabel>
			<Select
				className={classes.selectEmpty}
				error={error && !placeholder?.incidentType}
				native
				onChange={e => onChange({ incidentType: e.target.value })}
				value={placeholder?.incidentType}
				inputProps={{
					id: 'selectIncidentType',
				}}
			>
				<option value='' key='undefined' />
				{incidentTypeButtonOptions(incidentTypes)
					.filter(param => !!param.filter)
					.map(param => (
						<option value={param.filter} key={param.filter}>
							{param.text}
						</option>
					))}
			</Select>
		</FormControl>
	);

	const description = (
		<TextField
			className={classes.description}
			error={error && (placeholder?.description || '').trim().length <= 0}
			fullWidth
			multiline
			onChange={event => onChange({ description: event.target.value })}
			placeholder='Description'
			minRows='7'
			value={placeholder?.description}
			variant='filled'
		/>
	);

	const numberOnChange = (value, field) => (!value || !!Number(value) || value === '0')
		&& onChange({ [field]: value });

	const input = Input(classes);

	const costInput = input({
		endAdornment: currencyFormatter.symbol(),
		error: error && (placeholder?.incidentCost || '').trim().length <= 0,
		onChange: event => numberOnChange(event.target.value, 'incidentCost'),
		title: 'Cost',
		value: placeholder?.incidentCost,
	});

	const liabilityInput = input({
		endAdornment: '%',
		error: error && (placeholder?.liabilityPercentage || '').trim().length <= 0,
		onChange: event => numberOnChange(
			fitsPercentage(event.target.value) ? event.target.value : 'Doesn\'t fit',
			'liabilityPercentage'),
		title: 'Liability',
		value: placeholder?.liabilityPercentage,
	});

	const administrativeCostInput = input({
		disabled: !hasAdministrativeCost,
		endAdornment: currencyFormatter.symbol(),
		error: error && hasAdministrativeCost
			&& placeholder?.administrativeCost.trim().length <= 0,
		onChange: event => numberOnChange(event.target.value, 'administrativeCost'),
		startAdornment: (
			<Checkbox
				checked={!!hasAdministrativeCost}
				className={classes.administrativeCostCheck}
				color='primary'
				onChange={() => setAdministrativeCost(!hasAdministrativeCost)}
			/>
		),
		title: 'Administrative Cost',
		value: placeholder?.administrativeCost,
	});

	const addAttachementInput = (
		<div>
			<label htmlFor='addAttachment'>
				<Typography className={classes.attachmentInput}>
					Add Attachement
				</Typography>
			</label>
			<input type='file' id='addAttachment' onChange={imageHandler} className={classes.input} />
		</div>
	);

	const attachments = (
		<Attachment
			classes={classes}
			attachment={placeholder?.attachment}
			removeAttachment={removeAttachment}
		/>
	);

	const errorInfo = 'string' === typeof error && (
		<Typography className={classes.error} align='center' >
			{error}
		</Typography>
	);

	const cancelButton =
		<Button buttonStyleName='void' disabled={hasRequest} label='Cancel' onClick={onCancel} />;

	const saveButton = (
		<Button
			buttonStyleName='filled'
			disabled={hasRequest}
			label={placeholder?.incidentCode ? 'Update' : 'Save'}
			onClick={onSave}
		/>
	);

	return (
		<div className={classes.root}>
			{title}
			{divider}
			{header}
			{divider}
			{listType}
			{selectIncidentType}
			{description}
			{costInput}
			{liabilityInput}
			{administrativeCostInput}
			{addAttachementInput}
			{attachments}
			{errorInfo}
			<div className={classes.buttons}>
				{cancelButton}
				{saveButton}
			</div>
		</div>
	);
};

export default withStyles(styles)(IncidentsUpdate);