import React from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Grid } from "@material-ui/core";

import { styles } from "./AssetFilters.styles";
import SearchBox from "../../../common/inputs/SearchBox";
import AssetTypeFilter from "./AssetTypeFilter/AssetTypeFilter";
import FuelLevelFilter from "./FuelLevelFilter/FuelLevelFilter";
import AssetStateFilter from "./AssetStateFilter/AssetStateFilter";
import AssetsRefreshLoop from "./AssetsRefreshLoop/AssetsRefreshLoop";
import { assetList, selectAssetsFilter, selectAssetListIsLoading } from "../assetSlice";

function AssetFilters({ classes }) {
  const dispatch = useDispatch();
  const filter = useSelector(selectAssetsFilter);
  const isLoading = useSelector(selectAssetListIsLoading);

  const onFilterChange = (key, value) => {
    dispatch(assetList({ ...filter, [key]: value }));
  };

  return (
    <Grid className={classes.root} direction="row" container>
      <AssetsRefreshLoop/>
      <AssetStateFilter filter={filter} isLoading={isLoading} onSelectState={(v) => onFilterChange("assetState", v)} />
      <FuelLevelFilter
        filter={filter}
        isLoading={isLoading}
        onSelectFuelLevel={(v) => onFilterChange("fuelLevel", v)}
      />
      <AssetTypeFilter filter={filter} isLoading={isLoading} onSelectType={(v) => onFilterChange("assetType", v)} />
      <SearchBox
        variant="outlined"
        className={classes.searchBox}
        disabled={isLoading}
        onEnter={(qry) => onFilterChange("deviceLicensePlate", qry)}
      />
    </Grid>
  );
}

export default withStyles(styles)(AssetFilters);
