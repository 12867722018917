import React from "react";
import VerticalMenuBar from "../../components/VerticalMenuBar/VerticalMenuBar";
import menuButtons from "./menuButtons";
import { withStyles } from "@material-ui/core";
import styles from "./DashboardApp.styles";
import DashboardMetrics from "../../features/dashboard/DashboardMetrics/DashboardMetrics";

const DashboardApp = ({
  classes,
}) => {

  const dashboardApp = (
    <div className={classes.root}>
      <div className={classes.workspace}>
        <VerticalMenuBar
          className={{ root: classes.menu }}
          buttons={menuButtons.map((button) => {
            // button["onClick"] = menuButtonOnClick[button.id];
            button.color = "rgb(64, 149, 194)";
            return button;
          })}
        />
        <DashboardMetrics />
      </div>
    </div>
  );

  return dashboardApp;
};

export default withStyles(styles)(DashboardApp);
