const styles = theme => ({
	root: {
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		width: '100%',
		margin: '15px 0 0',
		minHeight: 0,
	},

	list: {
		height: '100%',
		overflowY: 'auto',
		scrollbarWidth: 'thin', //FIREFOX
		width: '100%',
		//CHROME
		'&::-webkit-scrollbar': {
			width: '5px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},

	headerField: {
		flex: 1,
		minWidth: '100px',
		maxWidth: '150px',
		width: '100px',
	},

	header: {
		display: 'flex',
		width: '100%',
	},

	iconSpace: {
		flex: 1,
		minWidth: '19px',
		maxWidth: '19px',
	},
	
	actions: {
		flex: 2,
		display: 'flex',
		justifyContent: 'flex-end',
		marginRight: '19px',
	},
});

export default styles;