import React from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@material-ui/styles/withStyles";
import { Typography, Grid, Divider, Button } from "@material-ui/core";

import { styles } from "./Trips.styles";
import { Form, FormFields } from "../../../../common/forms";
import { formSchema, genericFormFields, plannedTripsFormFields, warningsFormFields } from "./Trips.schema";
import {
  carclubUpdateConfigs,
  carclubUpdateDefaultConfigs,
  selectCarclubConfigs,
  selectCarclubDefaultConfigs,
} from "../../carclubSlice";

function Trips({ classes, disabled, submitRef, isDefaultConfigs }) {
  const dispatch = useDispatch();
  const defaultConfigs = useSelector(selectCarclubDefaultConfigs);
  const locationConfigs = useSelector(selectCarclubConfigs);
  const configs = isDefaultConfigs ? defaultConfigs : locationConfigs;

  const onSubmit = async (values) => {
    let CONFIGURATION = { ...configs.CONFIGURATION, ...values.CONFIGURATION };
    let TRIP = { ...configs.TRIP, ...values.TRIP };
    let nConfigs = { ...configs, CONFIGURATION, TRIP };

    console.log("nConfigs: %o", nConfigs);

    if (isDefaultConfigs) {
      dispatch(carclubUpdateDefaultConfigs(nConfigs));
    } else {
      dispatch(carclubUpdateConfigs(nConfigs));
    }
  };

  let initialValues = configs;

  return (
    <div className={classes.root}>
      <Form
        schema={formSchema()}
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ submitting, values }) => {
          return (
            <>
              <Grid container spacing={3} wrap="nowrap">
                <Grid item xs={4}>
                  <Typography className={classes.title}>Generic</Typography>
                  <FormFields fields={genericFormFields({ disabled, classes })} />
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={4}>
                  <Typography className={classes.title}>Planned Trips</Typography>
                  <FormFields fields={plannedTripsFormFields({ disabled, classes })} />
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={4}>
                  <Typography className={classes.title}>Warnings</Typography>
                  <FormFields fields={warningsFormFields({ disabled, classes })} />
                </Grid>
              </Grid>
              <Button
                style={{ display: "none" }}
                ref={submitRef}
                type="submit"
                color="primary"
                variant="contained"
                disabled={submitting}
              >
                Submit
              </Button>
            </>
          );
        }}
      />
    </div>
  );
}

export default withStyles(styles)(Trips);
