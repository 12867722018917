import { createSlice } from "@reduxjs/toolkit";
import bigdataDeviceHeartbeatsCall from "./_sliceCalls/bigdataDeviceHeartbeats";
import bigdataDeviceHeartbeats_assetRefreshCall from "./_sliceCalls/bigdataDeviceHeartbeats_assetRefresh";
import bigdataListCommsCall from "./_sliceCalls/bigdataListComms";

export const bigdataSlice = createSlice({
  name: "bigdata",
  initialState: {
    activityLogs: [],
    activityLogsIsLastPage: false,
    heartbeats: {
      totalItems: 0,
      list: [],
    },
    selectedAssetHeartbeats: {
      assetToken: null,
      list: [],
    },
  },
  reducers: {
    appendActivityLogs: (state, action) => {
      state.activityLogs = [...(state.activityLogs || []), ...(action.payload || [])];
    },
    clearActivityLogs: (state) => {
      state.activityLogs = [];
    },
    setActivityLogsIsLastPage: (state, action) => {
      state.activityLogsIsLastPage = action.payload;
    },
    setHeartbeats: (state, action) => {
      state.heartbeats.list = action.payload.list;
      state.heartbeats.totalItems = action.payload.totalItems;
    },
    clearHeartbeats: (state) => {
      state.heartbeats.list = [];
      state.heartbeats.totalItems = 0;
    },
    setSelectedAssetHeartbeats: (state, action) => {
      console.log("-------- setSelectedAssetHeartbeats --------- %o", action.payload.list);
      state.selectedAssetHeartbeats.list = action.payload.list;
      state.selectedAssetHeartbeats.assetToken = action.payload.assetToken;
    },
  },
});

export const {
  setLoader,
  appendActivityLogs,
  clearActivityLogs,
  setActivityLogsIsLastPage,
  setHeartbeats,
  clearHeartbeats,
  setSelectedAssetHeartbeats,
} = bigdataSlice.actions;

export default bigdataSlice.reducer;

// SELECTS
export const selectIsLoading = (state) => state.getIn(["core"])?.loader?.length > 0;
export const selectActivityLogs = (state) => state.getIn(["bigdata"])?.activityLogs;
export const selectActivityLogsIsLastPage = (state) => state.getIn(["bigdata"])?.activityLogsIsLastPage;
export const selectHeartbeats = (state) => state.getIn(["bigdata"])?.heartbeats;
export const selectSelectedAssetHeartbeats = (state) => state.getIn(["bigdata"])?.selectedAssetHeartbeats;

// CALLS

export const getSelectedAssetHeartbeats =
  ({ assetToken, start, end, deviceToken }) =>
  async (dispatch, getState) => {
    bigdataDeviceHeartbeats_assetRefreshCall(bigdataSlice.actions, dispatch, getState(), {
      assetToken,
      start,
      end,
      deviceToken,
    });
  };

export const bigdataHeartbeats =
  ({ clear, start, end, deviceToken, offset, limit }) =>
  async (dispatch, getState) => {
    bigdataDeviceHeartbeatsCall(bigdataSlice.actions, dispatch, getState(), {
      clear,
      start,
      end,
      deviceToken,
      offset,
      limit,
    });
  };

export const bigdataListComms =
  ({ clear, start, end, deviceToken, page, commType, limit }) =>
  async (dispatch, getState) => {
    bigdataListCommsCall(bigdataSlice.actions, dispatch, getState(), {
      clear,
      start,
      end,
      deviceToken,
      page,
      commType,
      limit,
    });
  };
