import { wsCall, requestHelper, setSuccess } from "../../../app/coreSlice";

const carclubUpdateConfigsCall = async ({ carclubGetConfigs, carclubGetInfo }, dispatch, state, configs) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "CARCLUB/UPDATE_CONFIGS");
  let result;
  try {
    result = await wsCall({
      type: "CARCLUB",
      subType: "UPDATE_CONFIGS",
      locationId: selectedLocation?.id,
      message: configs,
    });

    if (!result.error) {
      dispatch(setSuccess("Configurations updated."));
      setTimeout(() => {
        // refresh reservations
        dispatch(carclubGetConfigs());
        dispatch(carclubGetInfo());
      }, 100);
    } else {
      rh.error(result?.description || "Error updating configuration.");
    }
  } catch (_ex) {
    rh.error("Error updating configuration.");
  }
  rh.close();
  return result;
};

export default carclubUpdateConfigsCall;
