const styles = theme => ({
	root: {
		fontSize: '10px',
		lineHeight: '20px',
	},

	checkIcon: {
		color: '#51B383',
	},
	
});

export default styles;