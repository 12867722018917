import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, FormGroup, Input, Label, Row, Alert } from "reactstrap";
import { Grid, ButtonGroup, Button, CircularProgress } from "@material-ui/core";

import { getConfigs, addRemoteConfig } from "./Actions/remoteConfigsActions";
import JsonToHtml from "./Components/JsonToHtml";
import ConfigsZone from "./Components/remoteConfig/dashboard/configsZone/ConfigsZone";
import I18nZone from "./Components/remoteConfig/dashboard/i18nZone/I18nZone";
import UpdateModal from "./Components/remoteConfig/dashboard/update/updateModal";
import AppIcon from "./Components/remoteConfig/dashboard/appSelector/AppIcon";

import carclubService from "../services/carclub/index";
import log from "../utils/logger";

import "./index.css";
import "./custom-bootstrap.css";
import Permission from "../app/components/Permission";

const i18n = require("./data/i18n.json");
const configs = require("./data/configs.json");

const logger = log("RemoteConfigContainer");

class RemoteConfigContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedApp: undefined,
      selectedVersion: "",
      rSelected: 0,
      configChanges: {},
      i18nChanges: {},
      changesToUpdate: false,
    };
    this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
    window.rc = this;
    this.updateModalRef = React.createRef();
  }

  componentDidMount() {
    this.onRefreshLocalApp();
  }

  componentDidUpdate() {
    this.refreshNewConfigs();
    this.refreshNewI18n();
  }

  async onRefreshLocalApp() {
    try {
      const configs = await carclubService.getCarclubConfigs({ isDefaultConfigs: true });
      if (configs.originalConfigs.CARCLUB_INFO.infoApp) {
        let versions = [];
        if (configs.originalConfigs.CARCLUB_INFO.infoApp.appVersions) {
          versions = configs.originalConfigs.CARCLUB_INFO.infoApp.appVersions.split(",");
          versions = versions.sort((a, b) => {
            return b - a;
          });
        }
        this.setState({
          selectedApp: {
            app_name: configs.originalConfigs.CARCLUB_INFO.infoApp.appName,
            versions: versions,
          },
          rSelected: 1,
          host_url: configs.originalConfigs.CARCLUB_INFO.infoApp.hostUrl,
          host_key: configs.originalConfigs.CARCLUB_INFO.infoApp.hostKey,
        });
      }
    } catch (error) {
      logger.warn(error);
    }
  }

  onRefreshApp() {
    this.setState({
      configChanges: {},
      i18nChanges: {},
      changesToUpdate: false,
    });
    let version = this.state.selectedVersion;
    if (!version || this.state.selectedApp.versions.indexOf(version) < 0) {
      version = this.state.selectedApp.versions[0];
      this.setState({
        selectedVersion: version,
      });
    }
    this.props.getConfigs(
      this.state.host_url,
      this.state.host_key,
      this.state.selectedApp.app_name,
      version,
      this.props.carclubCode.toUpperCase()
    );
  }

  onChangeVersion(key) {
    this.setState({
      selectedVersion: key.target.value,
      configChanges: {},
      i18nChanges: {},
      changesToUpdate: false,
    });
  }

  onChangeConfigs(data) {
    this.setState({
      configChanges: data,
      changesToUpdate: JSON.stringify(data) !== "{}" || JSON.stringify(this.state.i18nChanges) !== "{}",
    });
  }

  onChangeI18n(data) {
    this.setState({
      i18nChanges: data,
      changesToUpdate: JSON.stringify(this.state.configChanges) !== "{}" || JSON.stringify(data) !== "{}",
    });
  }

  onRadioBtnClick(rSelected) {
    this.setState({ rSelected });
  }

  updateConfigs() {
    this.updateModalRef.current.open();
  }

  onUpdateConfirm() {
    let configuration = this.props.getConfigsResult.configuration;
    configuration.configs = { ...configuration.configs, ...this.state.configChanges };
    for (let l in this.state.i18nChanges) {
      if (!configuration.i18n) {
        configuration.i18n = {};
      }
      configuration.i18n[l] = { ...configuration.i18n[l], ...this.state.i18nChanges[l] };
    }
    this.props.addRemoteConfig(
      this.state.host_url,
      this.state.host_key,
      this.state.selectedApp.app_name,
      this.state.selectedVersion,
      this.props.carclubCode.toUpperCase(),
      configuration
    );
  }

  refreshNewConfigs() {
    let configChanges = this.state.configChanges;
    let update = false;
    for (let k in configChanges) {
      if (k === "showRegFields" || k === "mandatoryRegFields" || k === "showAccountFields" || k === "lockRegFields") {
        break;
      }
      if (
        this.props.getConfigsResult &&
        this.props.getConfigsResult.configuration &&
        configChanges[k] === this.props.getConfigsResult.configuration.configs[k]
      ) {
        delete configChanges[k];
        update = true;
      }
    }
    if (update) {
      this.setState({
        configChanges: configChanges,
        changesToUpdate: JSON.stringify(configChanges) !== "{}" || JSON.stringify(this.state.i18nChanges) !== "{}",
      });
    }
  }

  refreshNewI18n() {
    let i18nChanges = this.state.i18nChanges;
    let update = false;
    for (let l in i18nChanges) {
      for (let k in i18nChanges[l]) {
        if (
          this.props.getConfigsResult &&
          this.props.getConfigsResult.configuration &&
          this.props.getConfigsResult.configuration &&
          this.props.getConfigsResult.configuration.i18n &&
          this.props.getConfigsResult.configuration.i18n[l] &&
          i18nChanges[l][k] === this.props.getConfigsResult.configuration.i18n[l][k]
        ) {
          delete i18nChanges[l][k];
          update = true;
        }
      }
    }
    if (update) {
      this.setState({
        i18nChanges: i18nChanges,
        changesToUpdate: JSON.stringify(i18nChanges) !== "{}" || JSON.stringify(this.state.configChanges) !== "{}",
      });
    }
  }

  renderLoadedStatus() {
    if (this.props.loading) {
      return (
        <div className="load-status">
          <CircularProgress />
        </div>
      );
    }

    if (this.props.getConfigsResult && this.props.getConfigsResult.app_configuration_id) {
      return (
        <div className="load-status">
          <Alert color="success">Config Loaded ({this.props.getConfigsResult.app_configuration_id})</Alert>
        </div>
      );
    }
    if (this.props.getConfigsError) {
      return (
        <div className="load-status">
          <Alert color="danger">{this.props.getConfigsError}</Alert>
        </div>
      );
    }

    return "";
  }

  renderUpdateButton() {
    let color = "primary";
    let disabled = false;
    let text = "Update";
    if (!this.state.changesToUpdate) {
      color = "secondary";
      disabled = true;
      text = "No changes";
    }
    if (this.props.loading) {
      disabled = true;
    }
    return (
      <Permission code="carclub.appConfigurations.edit">
        <Button
          className="m-auto d-block"
          color={color}
          disabled={disabled}
          size="small"
          onClick={this.updateConfigs.bind(this)}
        >
          {text}
        </Button>
      </Permission>
    );
  }

  renderHeader() {
    let outHtml = "";
    if (this.state.selectedApp && this.state.selectedApp.app_name && this.state.host_url && this.state.host_key) {
      outHtml = (
        <div>
          <Grid container>
            <Grid xs={12}>
              <FormGroup row>
                <Label for="version" sm={12} size="sm">
                  <AppIcon app_name={this.state.selectedApp.app_name} onClick={this.onRefreshApp.bind(this)}></AppIcon>
                </Label>
                <Col sm={12}>
                  <Input
                    type="select"
                    name="version"
                    id="version"
                    bsSize="sm"
                    onChange={this.onChangeVersion.bind(this)}
                  >
                    {this.state.selectedApp.versions.map((version) => {
                      return (
                        <option key={version} value={version}>
                          {version}
                        </option>
                      );
                    })}
                  </Input>
                </Col>
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Button variant="outlined" fullWidth color="primary" onClick={this.onRefreshApp.bind(this)}>
                refresh
              </Button>
              <br />
              <br />
              {this.renderLoadedStatus()}
              <br />
              <br />
            </Grid>
          </Grid>
        </div>
      );
    } else {
      outHtml = (
        <div>
          App configuration not available
          <br />
          <Button variant="outlined" color="primary" onClick={this.onRefreshLocalApp.bind(this)}>
            refresh
          </Button>
          <hr />
        </div>
      );
    }
    return outHtml;
  }

  renderBody() {
    if (this.state.selectedApp && this.state.selectedApp.app_name) {
      switch (this.state.rSelected) {
        case 1:
          return (
            <ConfigsZone
              configs={configs}
              data={this.props.getConfigsResult}
              selectedVersion={this.state.selectedVersion}
              changesToUpdate={this.state.changesToUpdate}
              changes={this.state.configChanges}
              onChange={this.onChangeConfigs.bind(this)}
            ></ConfigsZone>
          );
        case 2:
          return (
            <I18nZone
              i18n={i18n}
              data={this.props.getConfigsResult}
              selectedVersion={this.state.selectedVersion}
              changes={this.state.i18nChanges}
              onChange={this.onChangeI18n.bind(this)}
            ></I18nZone>
          );
        case 3:
          return (
            <div>
              <br />
              Result:
              <JsonToHtml object={this.props.getConfigsResult} />
              <br />
              New config changes:
              <JsonToHtml object={this.state.configChanges} />
              <br />
              New i18n changes:
              <JsonToHtml object={this.state.i18nChanges} />
            </div>
          );

        default:
          return <div>[body]</div>;
      }
    }
  }

  renderUpdateModal() {
    if (this.state.selectedApp && this.state.selectedApp.app_name) {
      return (
        <UpdateModal
          ref={this.updateModalRef}
          app={this.state.selectedApp}
          version={this.state.selectedVersion}
          onConfirm={this.onUpdateConfirm.bind(this)}
          configChanges={this.state.configChanges}
          i18nChanges={this.state.i18nChanges}
        ></UpdateModal>
      );
    }
  }

  renderMenu() {
    return (
      <Row>
        <Col>
          <ButtonGroup>
            <Button
              variant="contained"
              color={this.state.rSelected === 1 ? "primary" : "secondary"}
              size="small"
              onClick={() => this.onRadioBtnClick(1)}
            >
              Configs
            </Button>
            <Button
              variant="contained"
              color={this.state.rSelected === 2 ? "primary" : "secondary"}
              size="small"
              onClick={() => this.onRadioBtnClick(2)}
            >
              I18N
            </Button>
            <Button
              variant="contained"
              color={this.state.rSelected === 3 ? "primary" : "secondary"}
              size="small"
              onClick={() => this.onRadioBtnClick(3)}
            >
              original (raw)
            </Button>
          </ButtonGroup>
          <div className="float-right">{this.renderUpdateButton()}</div>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <div className="rconfigs">
        <Grid container spacing={2}>
          <Grid item xs={2}>
            {this.renderHeader()}
          </Grid>
          <Grid item xs={10}>
            {this.renderMenu()}
            <br />
            {this.renderBody()}
          </Grid>
        </Grid>

        {this.renderUpdateModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.getIn(["remoteConfigsReducer", "loading"]), //state.remoteConfigsReducer.loading,  ,
    getConfigsResult: state.getIn(["remoteConfigsReducer", "getConfigsResult"]), //state.remoteConfigsReducer.getConfigsResult,
    getConfigsError: state.getIn(["remoteConfigsReducer", "getConfigsError"]), //state.remoteConfigsReducer.getConfigsError,
    createConfigResult: state.getIn(["remoteConfigsReducer", "createConfigResult"]), //state.remoteConfigsReducer.createConfigResult,
    createConfigError: state.getIn(["remoteConfigsReducer", "createConfigError"]), //state.remoteConfigsReducer.createConfigError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getConfigs: (host_url, host_key, app_name, app_version, carclub_code) => {
      getConfigs(host_url, host_key, app_name, app_version, carclub_code, dispatch);
    },
    addRemoteConfig: (host_url, host_key, app_name, app_version, carclub_code, app_config) => {
      addRemoteConfig(host_url, host_key, app_name, app_version, carclub_code, app_config, dispatch);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoteConfigContainer);
