import React from "react";
import useCreateZoneReducer from "./useCreateZoneReducer";
import CreateZone from "./CreateZone";

const CreateZoneWrapper = ({ zone, ...otherProps }) => {
  console.log("zone: %o", zone);
  const { category, code, description, type, updateUser, updateDate } = zone || {};

  const createZone = useCreateZoneReducer({
    code,
    category: category ? category : "",
    description: description ? description : "",
    error: null,
    zoneType: type ? type : "",
    updateUser: updateUser ? updateUser : "",
    updateDate: updateDate ? updateDate : "",
  });

  return <CreateZone createZone={createZone} {...otherProps} />;
};

export default CreateZoneWrapper;
