import React from 'react';
import {
	FormControl,
	MenuItem,
	InputLabel,
	Select as MUISelect,
} from '@material-ui/core';

const Select = ({
	name,
	value,
	array,
	onChange,
	className,
	disabled,
}) => {

	let menuItems = null;

	if ( !!array && Array.isArray(array) ) {
		menuItems = array.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>);
	}

	return (
		<FormControl className={className} disabled={disabled}>
			<InputLabel>{name}</InputLabel>
			<MUISelect
				key={name}
				className={className}
				value={value}
				onChange={event => onChange(event.target.value)}
				inputProps={{
					value,
				}}
			>
				<MenuItem value='' />
				{menuItems}
			</MUISelect>
		</FormControl>
	);
};

export default Select;