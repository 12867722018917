import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "../../components/Home/Home";
import withStyles from "@material-ui/styles/withStyles";
import { styles } from "./App.styles";
import Snacks from "./Snacks/Snacks";
import MainRouting from "./MainRouting/MainRouting";
import LoginForm from "../../features/authentication/LoginForm/LoginForm";

const App = ({ classes }) => {

  return (
    <BrowserRouter className={classes.root}>
      <>
        <Routes>
          <Route path="/auth/:carclubCode" element={<LoginForm />} />
          <Route path="/" element={<Home />} />
          <Route path="/auth" element={<Home />} />
          <Route path="/:carclubCode/*" element={<MainRouting />} />
        </Routes>
        <Snacks />
        <div className={classes.appversion}>v{process.env.REACT_APP_VERSION}</div>
      </>
    </BrowserRouter>
  );
};

export default withStyles(styles)(App);
