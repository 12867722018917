import React, { useRef } from 'react';
import TYPES from './ListItem/ListItemTypes';
import { useFieldSorter } from '../../../hooks/useFieldSorter/useFieldSorter';
import { useInfinityScroll } from '../../../hooks/useInfinityScroll/useInfinityScroll';
import {
	CircularProgress as LoadingSpinner,
	List as MUIList,
	ListSubheader,
	withStyles,
} from '@material-ui/core';
import styles from './List.styles';
import classnames from 'classnames';

const paddingCorrection = 10;

const List = ({
	children,
	classes,
	className,
	disableGutters,
	getMoreRows,
	headerClassName,
	headerFields = [],
	isCalendar,
	noHeader,
	overridePadding,
	rowClassName,
	subHeaderClassName,
}) => {

	const divRef = useRef(null);
	const fields = useFieldSorter(headerFields, divRef);
	const isFetching = useInfinityScroll(getMoreRows, divRef);

	const hasScroll = divRef.current
		&& divRef.current.scrollHeight - divRef.current.offsetHeight > 0;

	const header = !noHeader && (
		<ListSubheader
			className={classnames(hasScroll && classes.subheader)}
			disableGutters={!!disableGutters}
		>
			<div className={classnames(classes.header, subHeaderClassName)} >
				{fields.map(field => {
					return (
						<span
							key={field.key}
							style={{
								minWidth: field.minWidth ? field.minWidth - paddingCorrection : undefined,
								width: field.width ? field.width - paddingCorrection : undefined,
								maxWidth: field.maxWidth ? field.maxWidth - paddingCorrection : undefined,
								height: field.height ? field.height - paddingCorrection : undefined,
								flexDirection: field.flexDirection,
								boxSizing: !!isCalendar ? 'border-box' : undefined,
								alignItem: !!isCalendar ? 'center' : undefined,
								padding: field.padding ? field.padding : undefined,
								justifyContent: field.justifyContent ? field.justifyContent : undefined,
								fontSize: field.fontSize ? field.fontSize : undefined,
							}}
							className={classnames(classes.field,
								!field.flexDirection && field.type === TYPES.COMPONENT && classes.component)}>
							{field.label}
						</span>
					);
				})}
			</div>
		</ListSubheader>
	);

	//This is used to pass the updated fields
	const childrenWithProps = !!children && React.Children.map(children, child => {
		return React.cloneElement(child, { fields });
	});

	const headerDiv = !noHeader && (
		<MUIList className={classnames(classes.row, headerClassName)} classes={{ padding: classes.noPaddingHeader }}>
			{header}
		</MUIList>
	);

	const noPadding = overridePadding
		&& { padding: classes.noPaddingList };

	return (
		<div className={classnames(classes.root, className)} >
			{headerDiv}
			<MUIList ref={divRef} className={classnames(classes.rows, rowClassName)} classes={noPadding}>
				{childrenWithProps}
				{isFetching && <LoadingSpinner />}
			</MUIList>
		</div>
	);
};

export default withStyles(styles)(List);