import {
  CUSTOMER_CLEAR_ELEMENTS,
  CUSTOMER_CREATE,
  CUSTOMER_SET_ELEMENTS,
  CUSTOMER_SET_DETAILS,
  CUSTOMER_SET_LAST_PAGE,
  CUSTOMER_SET_PAGE,
} from "./actionTypes";
import makeActionCreator from "../../../utils/makeActionCreator";
import * as methods from "../../../utils/makeActionCreator";
import customerService from "../../../services/customer/ws";
import log from "../../../utils/logger";
import { customerNewList } from "../../../features/customer/customerSlice";

const logger = log("Customer.action");

export const customerClearElements = () => ({ type: CUSTOMER_CLEAR_ELEMENTS, method: methods.REPLACE });
export const customerSetElements = makeActionCreator(CUSTOMER_SET_ELEMENTS, "elements");
export const customerSetDetails = makeActionCreator(CUSTOMER_SET_DETAILS, "customer");
export const customerSetLastPage = makeActionCreator(CUSTOMER_SET_LAST_PAGE, "lastPage");
export const customerSetPage = makeActionCreator(CUSTOMER_SET_PAGE, "page");

export const customerCreate = makeActionCreator(CUSTOMER_CREATE);


export const updateCustomer = (data) => {
  return async (dispatch) => {
    try {
      const customer = data;
      dispatch(customerSetDetails(customer));
    } catch (error) {
      logger.info("Cannot update customer.");
      logger.debug(error);
    }
  };
};


export const toggleCustomer = (data) => {
  return async (dispatch) => {
    try {
      const customer = await customerService.toggleCustomer(data);
      dispatch(customerSetDetails(customer));
      setTimeout(() => {
        dispatch(customerNewList({ reset: true }));
      }, 200);
    } catch (error) {
      logger.info(`Cannot ${data.available ? "enable" : "disable"} customer ${data.customerCode}`);
      logger.debug(error);
    }
  };
};
