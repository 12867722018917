import { connect } from 'react-redux';
import toJS from '../../hoc/toJS';
import * as incidentsActions from '../../store/actions/incidents/action';
import IncidentsApp from './IncidentsAppWrapper';

const mapStateToProps = (state) => {
	return {
		incidents: state.getIn(['incidents', 'elements']),
		lastPage: state.getIn(['incidents', 'lastPage']),
		page: state.getIn(['incidents', 'page']),
		currentScreenSize: state.getIn(['app', 'windowWidth']),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		chargeIncident: (incident, setRequest) => dispatch(incidentsActions.chargeIncident(incident, setRequest)),
		closeIncident: (incident, setRequest) => dispatch(incidentsActions.closeIncident(incident, setRequest)),
		incidentsSetElements: incident => dispatch(incidentsActions.incidentsSetElements(incident)),
		invoiceIncident: (incident, setRequest) => dispatch(incidentsActions.invoiceIncident(incident, setRequest)),
		settleIncident: (incident, setRequest) => dispatch(incidentsActions.settleIncident(incident, setRequest)),
		updateIncident: data => dispatch(incidentsActions.updateIncident(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(IncidentsApp));