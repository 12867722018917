import React, { useState } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Button, Grid, Typography } from "@material-ui/core";

import { styles } from "./SettleButton.styles";
import Modal from "../../../../../../common/displays/Modal/Modal";
import { useDispatch } from "react-redux";
import { billingSettle } from "../../../../reservationsSlice";

function SettleButton({ classes, loading, reservationCode }) {
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();

  const settleHandler = () => {
    dispatch(billingSettle({ reservationCode }));
    setModal(false);
  };

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        variant="outlined"
        color="primary"
        size="small"
        disabled={loading}
        onClick={() => setModal(true)}
      >
        Settle
      </Button>
      <Modal open={modal} setOpen={setModal} sizeClass="small">
        <div style={{ height: "100%" }}>
          <Grid
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Grid item>
              <Typography align="center" variant="h5">
                Settle trip as paid
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="center">
                If you confirm, the billing item of this trip will be marked as paid.
              </Typography>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                size="small"
                disabled={loading}
                onClick={settleHandler}
              >
                Settle
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}

export default withStyles(styles)(SettleButton);
