import React from 'react';
import {
	withStyles,
} from '@material-ui/core';
import CommonButtons from '../../../UI/Buttons/CommonButtons';
import styles from '../../AccountButtons/AccountButtons.styles';
import classnames from 'classnames';

const UpsertButtons = ({
	classes,
	className,
	exitUpsert,
	isOnCreateMode,
}) => {

	const confirmBtnTitle = isOnCreateMode
		? 'Create'
		: 'Save';

	const cancelButton = (<CommonButtons
		key='cancel'
		label='Cancel'
		buttonStyleName='void'
		onClick={exitUpsert}
	/>);

	const confirmButton = (<CommonButtons
		key='confirm'
		label={confirmBtnTitle}
		type='submit'
	/>);

	return (
		<div className={classnames(classes.root, className)}>
			{cancelButton}
			{confirmButton}
		</div>
	);
};

export default withStyles(styles)(UpsertButtons);