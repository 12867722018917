import * as Yup from "yup";
import { TextField, SelectField } from "../../../common/forms";

export function formSchema() {
  return Yup.object().shape({
    provider_token: Yup.string().required("Provider is required"),
    serial_number: Yup.string().required("Serial Number is required"), 
  });
}

export function formFields(disabled, providers, values) {
  // console.log("values: %o", values);
  // console.log("disabled: %o", disabled);
  const providerOptions = (providers || []).map((item) => {
    return {
      value: item?.provider_token,
      text: item?.name,
    };
  });
  return [
    {
      size: 4,
      field: (
        <SelectField
          id="provider_token"
          type="text"
          label="Provider"
          required
          disabled={disabled}
          options={providerOptions}
        />
      ),
    },
    {
      size: 4,
      hidden: values.provider_token === "PRIMECLOUD",
    },
    {
      size: 4,
      field: <TextField id="serial_number" type="text" label={values.provider_token === "PRIMECLOUD" ? "Device Name" : "Device Serial Number"} required disabled={disabled} />,
    },
    {
      size: 4,
      field: <TextField id="externalId" type="text" label="Device IMEI" disabled={disabled} />,
      hidden: values.provider_token !== "PRIMECLOUD",
    },
  ];
}
