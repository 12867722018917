import React from "react";
import List from "./List/WorkOrderListWrapper";
import Details from "./Details/WorkOrderDetails";
import Filters from "./Filters/FiltersWrapper";
import Update from "./UpdateDrawer/UpdateWrapper";
import Tasks from "./Tasks/TasksWrapper";
import { Drawer, Modal, withStyles } from "@material-ui/core";
import styles from "./ListWorkspace.styles";
import classnames from "classnames";
import { useState } from "react";
import { useEffect } from "react";
import { useDateTime } from "../../../common/hooks";
import moment from "moment";

const ListWorkspace = ({
  classes,
  className,
  closeDrawer,
  drawer,
  filter,
  lastPage,
  modal,
  onReset,
  onSearch,
  priorities,
  removeWorkOrder,
  selectedWorkOrder,
  servicePoints,
  setDrawer,
  setFilter,
  setModal,
  setSelectedWorkOrder,
  setStatus,
  setTasks,
  status,
  tasks,
  types,
  updateWorkOrder,
  workOrders = {},
  h3Map,
}) => {
  const { toTimeZone } = useDateTime();
  const workOrdersArray = Object.values(workOrders || {});
  const [workOrder, setWorkOrder] = useState({});

  useEffect(() => {
    if (selectedWorkOrder && workOrders && Object.keys(workOrders)?.length > 0 && workOrders?.[selectedWorkOrder]) {
      let nWorkOrder = { ...workOrders?.[selectedWorkOrder], tasks: tasks || [] };
      nWorkOrder.scheduledStartDate = nWorkOrder?.scheduledStartDate
        ? toTimeZone(moment(nWorkOrder.scheduledStartDate).format("YYYY-MM-DD HH:mm:ss")).format("YYYY-MM-DD HH:mm:ss")
        : null;
      nWorkOrder.scheduledEndDate = nWorkOrder?.scheduledEndDate
        ? toTimeZone(moment(nWorkOrder.scheduledEndDate).format("YYYY-MM-DD HH:mm:ss")).format("YYYY-MM-DD HH:mm:ss")
        : null;
      setWorkOrder(nWorkOrder);
    } else {
      setWorkOrder({});
    }
    // eslint-disable-next-line
  }, [workOrders, selectedWorkOrder, tasks]);

  return (
    <div className={classnames(classes.root, className)}>
      {selectedWorkOrder && (
        <Modal onClose={() => setModal()} open={!!modal}>
          <div className={classes.modal}>
            <Tasks setTasks={setTasks} tasks={tasks} workorder={workOrder} />
          </div>
        </Modal>
      )}
      <Drawer anchor="right" open={drawer}>
        <Update
          onCancel={closeDrawer}
          priorities={priorities}
          servicePoints={servicePoints}
          setSelectedWorkOrder={setSelectedWorkOrder}
          types={types}
          updateWorkOrder={updateWorkOrder}
          workOrder={workOrder}
          h3Map={h3Map}
        />
      </Drawer>
      <div className={classes.list}>
        <Filters
          filter={filter}
          onReset={onReset}
          onSearch={onSearch}
          priorities={priorities}
          selectedStatus={status}
          setFilter={setFilter}
          setStatus={setStatus}
          startCreate={() => {
            setSelectedWorkOrder();
            setDrawer(true);
          }}
          types={types}
          workOrders={workOrdersArray}
        />
        <List
          lastPage={lastPage}
          onSearch={onSearch}
          removeWorkOrder={removeWorkOrder}
          selectedWorkOrder={selectedWorkOrder}
          setDrawer={setDrawer}
          setModal={setModal}
          setSelectedWorkOrder={setSelectedWorkOrder}
          updateWorkOrder={updateWorkOrder}
          workOrders={workOrdersArray.filter((order) => !status || order.status === status)}
        />
      </div>
      <div className={classes.details}>
        <Details servicePoints={servicePoints} tasks={tasks} workOrder={workOrder} />
      </div>
    </div>
  );
};

export default withStyles(styles)(ListWorkspace);
