const styles = () => ({
	map: {
		width: '100%',
		height: '600px',
		border: 'solid 1px black',
		display: 'flex',
		flexDirection: 'row',
		alingItems: 'center',
		flexWrap: 'no-wrap',
	},

});

export default styles;