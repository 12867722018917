import React from 'react';
import {
	useParams,
} from 'react-router-dom';
import {
	withStyles,
} from '@material-ui/core';
import styles from './AppConfigurations.styles';
import RemoteConfigContainer from '../../../appRemoteConfigurations/dashboard';

const AppConfigurations = ({
	classes,
}) => {
	const { carclubCode } = useParams();
	return (
		<div className={classes.root}>
			<RemoteConfigContainer carclubCode={carclubCode}></RemoteConfigContainer>
		</div>
	);
};


export default withStyles(styles)(AppConfigurations);