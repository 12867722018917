export const styles = () => ({
	root: {
		height: '100%',
		minHeight: '0px',
		width: '100%',
		textAlign: 'left',
		display: 'flex',
		flexDirection: 'column',
		marginLeft: 60,
		padding: 20,
		boxSizing: 'border-box',
		overflowY: 'auto',
		scrollbarWidth: 'thin', //FIREFOX

		'&::-webkit-scrollbar': {
			width: '5px',
			borderRadius: '21px',
		},

		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
		backgroundColor: '#fff',
	},

	graphs: {
		height: '100%',
		minHeight: '0px',
		width: '100%',
		textAlign: 'left',
		display: 'flex',
		flexDirection: 'column',
		boxSizing: 'border-box',
		overflowY: 'auto',
		scrollbarWidth: 'thin', //FIREFOX

		'&::-webkit-scrollbar': {
			width: '5px',
			borderRadius: '21px',
		},

		'&::-webkit-scrollbar-thumb': {
			borderRadius: '21px',
			boxShadow: 'inset 0 0 21px rgba(0,0,0,.3)',
		},
	},
});

export default styles;