import React from 'react';
import {
	Checkbox,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
	withStyles,
} from '@material-ui/core';
import { ZONE_TYPES_MAP } from '../utils';
import { ReactComponent as ParkIcon } from '../../../../../assets/Zones/parkZone.svg';
import { ReactComponent as WantedIcon } from '../../../../../assets/Zones/wantedZone.svg';
import { ReactComponent as UnwantedIcon } from '../../../../../assets/Zones/unwantedZone.svg';
import { styles } from './ZonesAndParks.styles';
import classnames from 'classnames';

const ZonesAndParks = ({
	classes,
	className,
	generalInfo,
	handlers,
	hasRequest,
	placeholder,
	removeHeader = false,
}) => {
	const {
		onChangeHandlerZones,
	} = handlers || {};

	const {
		onEdit,
		onCreate,
		zonesAndParks,
	} = generalInfo || {};

	const title = (
		<Typography
			className={classes.title}
		>
			Zones and Parks
		</Typography>
	);

	const header = !removeHeader && (
		<div className={classes.header}>
			{title}
		</div>
	);

	const wantedZoneList = (placeholder?.zones?.wanted?.length > 0
		|| placeholder?.zones?.unwanted?.length > 0 || onEdit || onCreate)
		&& (
			<List
				key='wantedZoneList'
				className={classnames(classes.list, (onEdit || onCreate) && classes.listOnEdit)}
			>
				{!(onEdit || onCreate) && placeholder?.zones?.wanted.map((zone, index) => item({
					classes, placeholder, onChangeHandlerZones, hasRequest, index,
					onEdit: onEdit || onCreate, Icon: WantedIcon, zone, checked: true,
				}))}
				{(onEdit || onCreate) && zonesAndParks?.wanted.map((zone, index) => item({
					classes, placeholder, onChangeHandlerZones, hasRequest, index,
					onEdit: onEdit || onCreate, Icon: WantedIcon, zone, onCreate,
				}))}
			</List>
		);

	const unwantedZoneList = (placeholder?.zones?.wanted?.length > 0
		|| placeholder?.zones?.unwanted?.length > 0 || onEdit || onCreate)
		&& (
			<List
				key='unwantedZoneList'
				className={classnames(classes.list, (onEdit || onCreate) && classes.listOnEdit)}
			>
				{!(onEdit || onCreate) && placeholder?.zones?.unwanted.map((zone, index) => item({
					classes, placeholder, onChangeHandlerZones, hasRequest, index,
					onEdit: onEdit || onCreate, Icon: UnwantedIcon, zone, checked: true,
				}))}
				{(onEdit || onCreate) && zonesAndParks?.unwanted.map((zone, index) => item({
					classes, placeholder, onChangeHandlerZones, hasRequest, index,
					onEdit: onEdit || onCreate, Icon: UnwantedIcon, zone, onCreate,
				}))}
			</List>
		);

	const parkList = (placeholder?.zones?.park?.length > 0 || onEdit || onCreate)
		&& (
			<List
				key='ParkList'
				className={classnames(classes.list, classes.parkList, (onEdit || onCreate) && classes.listOnEdit)}
			>
				{!(onEdit || onCreate) && placeholder?.zones?.park.map((zone, index) => item({
					classes, placeholder, onChangeHandlerZones, hasRequest,
					onEdit: onEdit || onCreate, Icon: ParkIcon, zone, checked: true, index,
					isPark: true,
				}))}
				{(onEdit || onCreate) && zonesAndParks?.park.map((zone, index) => item({
					classes, placeholder, onChangeHandlerZones, hasRequest,
					onEdit: onEdit || onCreate, Icon: ParkIcon, zone, onCreate, index,
					isPark: true,
				}))}
			</List>
		);

	const verticalDivider = (
		<hr style={{
			height: '135px', borderLeft: '1px solid #E0E0E0',
			borderRight: '1px solid #E0E0E0', alignSelf: 'end',
			margin: '25px',
		}} />
	);

	const content = !onEdit && !onCreate
		? (
			<div className={classes.workspace}>
				{wantedZoneList}
				{unwantedZoneList}
				{parkList}
			</div>
		)
		: (
			<div className={classes.workspace}>
				<div className={classes.onEditList}>
					<Typography className={classes.subtitle}>Wanted</Typography>
					{wantedZoneList}
				</div>
				{verticalDivider}
				<div className={classes.onEditList}>
					<Typography className={classes.subtitle}>Unwanted</Typography>
					{unwantedZoneList}
				</div>
				{verticalDivider}
				<div className={classes.onEditList}>
					<Typography className={classes.subtitle}>Parks</Typography>
					{parkList}
				</div>
			</div>
		);

	return (
		<div className={classnames(classes.root, className)}>
			{header}
			{content}
		</div>
	);
};

const item = ({
	checked,
	classes,
	hasRequest,
	Icon,
	index,
	onChangeHandlerZones,
	onCreate,
	onEdit,
	placeholder,
	zone,
	isPark,
}) => {
	const {
		id,
		code,
		description,
		name,
		type,
	} = zone || {};

	const zoneType = ZONE_TYPES_MAP[type];
	const isSelected = (placeholder?.zones?.[zoneType] || []).find(el => el?.id === zone?.id);

	return (
		<ListItem
			key={id || index}
			className={classes.listItem}
			classes={{ button: classes.listItem }}
			role={undefined} dense button
			onClick={() => {
				onChangeHandlerZones(zone, zoneType);
			}}
			disabled={!onEdit && !onCreate}
		>
			<ListItemIcon className={classnames(classes.listItemIcon, (onEdit || onCreate) && classes.listItemIconOnEdit)}>
				{(onEdit || onCreate) && <Checkbox
					edge="start"
					checked={!!isSelected || !!checked}
					tabIndex={-1}
					disableRipple
					inputProps={{ 'aria-labelledby': code }}
					disabled={hasRequest}
				/>}
				<Icon />
			</ListItemIcon>
			<ListItemText id={id} primary={!isPark ? description : name} />
		</ListItem>
	);
};

export default withStyles(styles)(ZonesAndParks);