export const ASSET_CREATION_STEPS = {
	CREATE_ASSET: 'CREATE_ASSET',
	ADD_ZONES_OR_PARKS: 'ADD_ZONES_OR_PARKS',
	CREATE_DEVICE: 'CREATE_DEVICE',
};

export const CONFIRMATION_SCREENS = {
	ACTIVATE_DEVICE_OR_CREATE_ONLY: 'ACTIVATE_DEVICE_OR_CREATE_ONLY',
	REMOVE_DEVICE: 'REMOVE_DEVICE',
	CONFIRM_EXIT: 'CONFIRM_EXIT',
};

export const ZONE_TYPES_MAP = {
	NORMAL: 'wanted',
	UNWANTED: 'unwanted',
	PARKING: 'park',
};

export const fieldsToCheck = {
	CREATE_ASSET: ['licensePlate', 'make', 'model', 'vinNumber', 
		'class', 'fuelType', 'year', 'transmission', 'imageUrl'],
	ADD_ZONES_OR_PARKS: [],
	CREATE_DEVICE: [],
	update: ['licensePlate', 'make', 'model', 'vinNumber', 'provider',
		'class', 'fuelType', 'year', 'transmission', 'imageUrl', 'deviceNumber'],
};

export const mapAndFilterZonesAndParks = asset => {
	const { zones, parks } = asset || {};
	
	const mappedZoneData = Array.isArray(zones)
		? zones.map(zone => {
			return {
				type: zone?.type,
				category: zone?.category,
				description: zone?.description,
				code: zone?.code,
				id: zone?.id,
			};
		})
		: [];

	const mappedParkData = Array.isArray(parks)
		? parks.map(park => {
			return {
				type: 'PARKING',
				category: park?.category,
				description: park?.description,
				code: park?.code,
				id: park?.id,
				name: park?.name,
			};
		})
		: [];
	return {
		wanted: mappedZoneData.filter(zone => zone?.type === 'NORMAL'),
		unwanted: mappedZoneData.filter(zone => zone?.type === 'UNWANTED'),
		park: mappedParkData,
	};
};

export const fieldsToCheckLabel = {
	class: 'Class',
	deviceNumber: 'Serial Number',
	fuelType: 'Fuel Type',
	imageUrl: 'Image',
	licensePlate: 'License Plate',
	make: 'Make',
	model: 'Model',
	provider: 'Provider',
	transmission: 'Transmission',
	vinNumber: 'Vin Number',
	year: 'Year',
};