import React from 'react';
import {
	List,
	withStyles,
} from '@material-ui/core';
import MenuButton from './MenuButton/MenuButtonWrapper';
import { styles } from './VerticalMenuBar.styles';
import classNames from 'classnames';

const verticalMenuBar = ({
	classes,
	className,
	buttons,
	showLabels,
}) => {

	const buttonList = buttons.map((button, index) => {
		return (
			<MenuButton
				key={index}
				button={button}
				showLabel={showLabels}
			/>
		);
	});

	return (
		<List className={classNames(classes.root,
			!showLabels && classes.collapsed,
			className && className.root,
		)}>
			{buttonList}
		</List>
	);
};

export default withStyles(styles)(verticalMenuBar);