import React from "react";

class AppIcon extends React.Component {
	handleOnClick() {
		this.props.onClick();
	}

	render() {
		return (
			<div className="app-icon" onClick={this.handleOnClick.bind(this)}>
				<span>{this.props.app_name}</span>
			</div>
		);
	}
}

export default AppIcon;