import { wsCall, requestHelper } from "../../../app/coreSlice";

const crAccountsRetrieveAccountsUserCall = async ({ setCrCustomerAccounts }, dispatch, state, { customerCode }) => {
  const selectedLocation = state?.getIn(["carclub2"])?.defaultLocation;
  let rh = requestHelper(dispatch, "ACCOUNTS/RETRIEVE_ACCOUNTS_USER");
  try {
    const result = await wsCall({
      type: "ACCOUNTS",
      subType: "RETRIEVE_ACCOUNTS_USER",
      locationId: selectedLocation?.id,
      message: {
        customerCode
      },
    });
    if (result) {
      dispatch(setCrCustomerAccounts(result));
    }
  } catch (_ex) {
    rh.error("Error getting accounts.");
  }
  rh.close();
};

export default crAccountsRetrieveAccountsUserCall;
