const configs = [
	{
		key: 'user',
		label: 'User',
	},
	{
		key: 'password',
		label: 'Password',
	},
	{
		key: 'urlProvider',
		label: 'URL Provider',
	},
	{
		key: 'sender',
		label: 'Sender',
	},
];

export default configs;