import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import accountService from '../../../../services/account';
import Transactions from './Transactions';
import log from '../../../../utils/logger';

const logger = log('TransactionsWrapper');

const TransactionsWrapper = ({
	onSettle,
	...props
}) => {
	const {
		selectedBillingItemCode,
	} = props;

	const currentScreenSize = useSelector(state => state.getIn(['app', 'windowWidth']));

	const [paymentsArray, setPaymentsArray] = useState([]);
	const [lastPage, setLastPage] = useState(true);
	const [page, setPage] = useState(1);

	const [description, setDescription] = useState();
	const [descriptionModal, setDescriptionModal] = useState(false);

	const asyncGetAccountBillingItems = useCallback(async () => {
		try {
			const {
				payments,
				lastPage,
			} = await accountService.getAccountBillingItems({ bia: selectedBillingItemCode, page });

			Array.isArray(payments) && setPaymentsArray(prev => [...prev, ...payments]);
			setLastPage(lastPage);
		} catch (error) {
			logger.warn('Unable to retrieve account billing items.');
		}
	}, [selectedBillingItemCode, page]);

	useEffect(() => {
		asyncGetAccountBillingItems();
	}, [asyncGetAccountBillingItems]);

	const getMoreRows = useCallback(() => {
		if (!lastPage) {
			setPage(prev => prev + 1);
		}
	}, [lastPage]);

	return (
		<Transactions
			{...props}
			currentScreenSize={currentScreenSize}
			description={description}
			setDescription={setDescription}
			payments={paymentsArray}
			getMoreRows={getMoreRows}
			descriptionModal={descriptionModal}
			onShowSettleDescription={() => setDescriptionModal(true)}
			closeSettleDescriptionModal={() => setDescriptionModal(false)}
		/>
	);
};

export default TransactionsWrapper;