import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addSelectedWorkorder } from '../../../../store/actions/chat/action';
import WorkOrderList from './WorkOrderList';
import workOrderService from '../../../../services/workOrder';
import log from '../../../../utils/logger';

const logger = log('WorkOrderListWrapper');

const WorkOrderListWrapper = ({
	removeWorkOrder,
	setDrawer,
	setModal,
	setSelectedWorkOrder,
	updateWorkOrder,
	...props
}) => {

	const dispatch = useDispatch();

	const onEdit = useCallback(workOrder => {
		setSelectedWorkOrder(workOrder);
		setDrawer(true);
	}, [setDrawer, setSelectedWorkOrder]);

	const onShowTasks = useCallback(workOrder => {
		setSelectedWorkOrder(workOrder);
		setModal('TASKS');
	}, [setModal, setSelectedWorkOrder]);

	const onRemove = useCallback(async workorderCode => {
		try{
			const remove = await workOrderService.removeWorkorder(workorderCode);

			!remove.error && removeWorkOrder(workorderCode);
		} catch(e){
			logger.warn(`Could not remove ${workorderCode}`);
		};
	}, [removeWorkOrder]);

	const onComplete = useCallback(async workorderCode => {
		try{
			const workorder = await workOrderService.closeWorkorder(workorderCode);

			removeWorkOrder(workorder?.code);
		} catch(e){
			logger.warn(`Could not remove ${workorderCode}`);
		};
	}, [removeWorkOrder]);

	const onSelect = useCallback(workorder => {
		setSelectedWorkOrder(workorder.code);

		workorder?.assignAgent && dispatch(addSelectedWorkorder(workorder));
	}, [setSelectedWorkOrder, dispatch]);
	
	return (
		<WorkOrderList
			{...props}
			onComplete={onComplete}
			onEdit={onEdit}
			onRemove={onRemove}
			onSelect={onSelect}
			onShowTasks={onShowTasks}
		/>
	);
};

export default WorkOrderListWrapper;