import React, { useState, useEffect, useCallback, useRef } from "react";
import { InputLabel, Checkbox, Typography, TextField, FormControl, Select } from "@material-ui/core";
import screens from "../../../model/Carclub/configurationsScreens";
import Configurations from "./Configurations";
import carclubService from "../../../services/carclub/index";

import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { operatorGetDefaultLoc, selectDefaultLocation } from "../../../features/carclub/carclubSlice";

const ConfigurationsWrapper = (props) => {
  const dispatch = useDispatch();
  const { isDefaultConfigs } = props;

  const [screen, setScreen] = useState(screens.GENERAL);
  const [editMode, setEditMode] = useState(false);
  //eslint-disable-next-line
  const [error, setError] = useState();

  const [placeholder, setPlaceholder] = useState({});
  //eslint-disable-next-line
  const [retrievedConfigs, setRetrievedConfigs] = useState({});
  //eslint-disable-next-line
  const [logoFileLink, setLogoFileLink] = useState(undefined);
  //eslint-disable-next-line
  const [termsAndConditions, setTermsAndConditions] = useState(undefined);

  //WORK ORDER CB's

  const upsertTemplateHandlerCB = useRef();
  const clearTemplateHandlerCB = useRef();

  const asyncGetConfigs = async () => {
    try {
      //add logo file and terms&&conditions
      const configs = await carclubService.getCarclubConfigs({ isDefaultConfigs });

      configs && setRetrievedConfigs(configs?.originalConfigs);
      parseResultToPlaceholder({ ...configs });
    } catch (error) {
      console.error(error);
    }
  };

  const selectedLocation = useSelector(selectDefaultLocation);

  useEffect(() => {
    setPlaceholder({});
    setRetrievedConfigs({});
    asyncGetConfigs();
    setScreen(screens.GENERAL);
    setEditMode(false);
    setLogoFileLink();
    setTermsAndConditions();
    //eslint-disable-next-line
  }, [isDefaultConfigs, selectedLocation]);

  const parseResultToPlaceholder = (result) => {
    // console.log('parseResultToPlaceholder --> result: %o', result);
    const { general, fleet, trips, warnings, payments, registration } = result || {};

    const spreadedConfigs = {
      ...general,
      ...fleet,
      ...trips,
      ...warnings,
      ...payments,
      ...registration,
      invoiceGW:
        payments.invoiceGWs && payments.invoiceGWs.INVOICE_EXPRESS && payments.invoiceGWs.INVOICE_EXPRESS.apiCode
          ? "INVOICE_EXPRESS"
          : "",
    };

    bulkChangeHandler(spreadedConfigs);

    // let locationName;
    // const locations = originalConfigs.LOCATIONS;

    // locationName =
    //   Array.isArray(locations) &&
    //   locations.find((location) => {
    //     return location.id === general.defaultLocation;
    //   });

    // locationName = locationName?.name;

    // onChangeHandler("defaultLocation", locationName);
  };

  const updateConfigsHandler = async () => {
    try {
      // const defaultLocationsArray = retrievedConfigs && retrievedConfigs.LOCATIONS;
      // const defaultLocation =
      //   Array.isArray(defaultLocationsArray) &&
      //   defaultLocationsArray.find((location) => {
      //     return location.name === placeholder.defaultLocation;
      //   });

      await carclubService.updateCarclubConfigs({
        ...placeholder,
        logoFileLink,
        termsAndConditions,
        // defaultLocation: defaultLocation?.id,
        originalConfigs: retrievedConfigs,
        isDefaultConfigs,
      });
      setPlaceholder({});
      setEditMode(false);
      asyncGetConfigs();

      dispatch(operatorGetDefaultLoc());
    } catch (error) {
      console.error(error);
      setEditMode(false);
    }
  };

  const onChange = useCallback((data) => setPlaceholder((prev) => ({ ...prev, ...data })), []);

  const bulkChangeHandler = (data) => {
    let dataProcessed = {};
    for (let key of Object.keys(data)) {
      let value = data[key];
      if (value === "true") value = true;
      if (value === "false") value = false;
      const regex = typeRestrictions[key];
      if (regex) {
        const passedTest = regex.test(value);
        if (!!passedTest) {
          if (value === ".") {
            dataProcessed[key] = "0.0";
          } else {
            dataProcessed[key] = value;
          }
        }
      } else {
        dataProcessed[key] = value;
      }
    }
    onChange(dataProcessed);
  };

  const onChangeHandler = (fieldString, value) => {
    const regex = typeRestrictions[fieldString];
    if (!regex) {
      onChange({ [fieldString]: value });
      return;
    }
    const passedTest = regex.test(value);
    if (!!passedTest) {
      if (value === ".") {
        onChange({ [fieldString]: "0.0" });
        return;
      }
      onChange({ [fieldString]: value });
    }
  };

  const inputBuilders = {
    getLabelAndInput,
    getSubtitleAndInput,
    getLabelAndSelect,
    getCheckboxAndLabel,
  };

  return (
    <Configurations
      {...props}
      currentConfiguration={screen}
      onChangeHandler={onChangeHandler}
      placeholder={placeholder}
      setCurrentConfiguration={setScreen}
      editMode={editMode}
      setEditMode={setEditMode}
      inputBuilders={inputBuilders}
      updateConfigs={updateConfigsHandler}
      refreshConfigs={asyncGetConfigs}
      upsertTemplateHandlerCB={upsertTemplateHandlerCB}
      clearTemplateHandlerCB={clearTemplateHandlerCB}
    />
  );
};

const numericFloatRegex = /^([0-9]*[.]{1})?[0-9]*$/;
const numericIntegerRegex = /^[0-9]*$/;

const typeRestrictions = {
  withReferralFeeConfig: numericFloatRegex,
  withReferralBonusConfig: numericFloatRegex,
  withReferralBonusExpire: numericIntegerRegex,
  withReferralBonusConfigForReferrer: numericFloatRegex,
  withReferralBonusExpireForReferrer: numericIntegerRegex,
  withoutReferralFeeConfig: numericFloatRegex,
  withoutReferralBonusConfig: numericFloatRegex,
  withoutReferralBonusExpire: numericIntegerRegex,
};

const getLabelAndInput = ({
  editMode,
  fieldLabel,
  fieldString,
  onChangeHandler,
  placeholder,
  classes,
  endAdornment,
  initialValue = "",
  inputSize,
  labelSize,
  id,
}) => {
  if (
    typeof fieldLabel === "undefined" ||
    typeof fieldString === "undefined" ||
    typeof onChangeHandler === "undefined" ||
    typeof placeholder === "undefined"
  ) {
    return null;
  }

  const labelField = (
    <Typography key={"label_" + fieldLabel + fieldString} className={classnames(classes.label, classes[labelSize])}>
      {fieldLabel + ":"}
    </Typography>
  );

  const endAdornmentField = !!endAdornment && (
    <Typography className={classes.endAdornment} key={"adornnment_" + fieldLabel + fieldString}>
      {endAdornment}
    </Typography>
  );

  const inputField = (
    <TextField
      id={id}
      key={"input_" + fieldLabel + fieldString}
      InputLabelProps={{ classes: { root: classes.labelProps, focused: classes.labelProps } }}
      InputProps={{ classes: { root: classes.inputProps } }}
      className={classnames(classes.field, classes[inputSize])}
      margin="normal"
      onChange={(e) => onChangeHandler(fieldString, e.target.value)}
      error={false}
      required={false}
      value={placeholder[fieldString] || initialValue}
      disabled={!editMode}
    />
  );

  return (
    <div className={classes.flexHorizontally} key={"div_" + fieldLabel + fieldString}>
      {labelField}
      {inputField}
      {endAdornmentField}
    </div>
  );
};

const getSubtitleAndInput = ({
  editMode,
  fieldLabel,
  fieldString,
  onChangeHandler,
  placeholder,
  classes,
  rowsNumberString = "1",
  variant = "standard",
  extraClass,
  endAdornment,
}) => {
  if (
    typeof fieldLabel === "undefined" ||
    typeof fieldString === "undefined" ||
    typeof onChangeHandler === "undefined" ||
    typeof placeholder === "undefined"
  ) {
    return null;
  }

  const endAdornmentField = !!endAdornment && (
    <Typography className={classes.endAdornment} key={"adornnment_" + fieldLabel + fieldString}>
      {endAdornment}
    </Typography>
  );

  const labelField = (
    <Typography className={classnames(classes.subtitle, classes[extraClass])} key={"label_" + fieldLabel}>
      {fieldLabel}
    </Typography>
  );

  const inputField = (
    <TextField
      InputLabelProps={{ classes: { root: classes.labelProps, focused: classes.labelProps } }}
      InputProps={{ classes: { root: classes.inputProps } }}
      className={classes.field}
      classes={{ root: classes.equalMargin }}
      disabled={!editMode}
      error={false}
      key={"input_" + fieldLabel}
      margin="normal"
      multiline
      onChange={(e) => onChangeHandler(fieldString, e.target.value)}
      required={false}
      minRows={rowsNumberString}
      value={placeholder[fieldString] || ""}
      variant={variant}
    />
  );

  return (
    <>
      {labelField}
      <div className={classnames(classes.horizontalFlex, classes.flexStart)}>
        {inputField}
        {endAdornmentField}
      </div>
    </>
  );
};

const getLabelAndSelect = ({
  editMode,
  options,
  fieldLabel,
  noLabel = false,
  fieldString,
  onChangeHandler,
  placeholder,
  classes,
  initialValue = "",
  extraClass,
  label,
  alternativeDefaultValue,
  noMargin = false,
  noEmptyOption = false,
}) => {
  if (
    typeof options === "undefined" ||
    typeof fieldString === "undefined" ||
    typeof onChangeHandler === "undefined" ||
    typeof placeholder === "undefined"
  ) {
    return null;
  }

  const labelField = !noLabel && (
    <Typography className={classnames(classes.selectLabel, classes[extraClass])} key={"label_" + fieldLabel}>
      {fieldLabel + ":"}
    </Typography>
  );

  const emptyOption = !noEmptyOption && (
    <option value={alternativeDefaultValue || ""} key="undefined">
      {alternativeDefaultValue || ""}
    </option>
  );

  const fieldOptions =
    options &&
    options.map((option) => (
      <option value={option} key={option}>
        {option}
      </option>
    ));

  const fieldSelect = (
    <FormControl className={classnames(classes.alignSelect, noMargin && classes.noMargin)}>
      <InputLabel id={"select_" + label} className={classes.selectLabelPadding}>
        {label}
      </InputLabel>
      <Select
        id={"select_" + label}
        key={"input_" + fieldLabel}
        className={classes.selectEmpty}
        classes={{ select: classes.selectLabelPadding }}
        native
        label={label}
        onChange={(e) => onChangeHandler(fieldString, e.target.value)}
        value={placeholder[fieldString] || initialValue}
        inputProps={{
          id: fieldString,
        }}
        disabled={!editMode}
      >
        {emptyOption}
        {fieldOptions}
      </Select>
    </FormControl>
  );

  return (
    <div className={classes.flexHorizontally}>
      {labelField}
      {fieldSelect}
    </div>
  );
};

const getCheckboxAndLabel = ({ editMode, fieldLabel, fieldString, onChangeHandler, placeholder, classes }) => {
  if (
    typeof fieldString === "undefined" ||
    typeof onChangeHandler === "undefined" ||
    typeof placeholder === "undefined"
  ) {
    return null;
  }

  const labelField = (
    <Typography className={classes.checkboxLabel} key={"label_" + fieldLabel}>
      {fieldLabel}
    </Typography>
  );

  const fieldSelect = (
    <Checkbox
      key={"input_" + fieldLabel}
      className={classes.checkbox}
      checked={!!placeholder?.[fieldString]}
      color="primary"
      onChange={() => {
        const current = placeholder[fieldString];
        onChangeHandler(fieldString, !current);
      }}
      disableRipple={true}
      disableTouchRipple={true}
      disabled={!editMode}
    />
  );

  return (
    <div className={classes.flexHorizontally}>
      {fieldSelect}
      {labelField}
    </div>
  );
};

export default ConfigurationsWrapper;
