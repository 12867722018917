
import NGError from "../../../error/ngError";
import errorFactory from "../../../error/errorFactory";
import { WS_REQUEST_IS_MALFORMED, WS_PROCESS_MESSAGE_IS_MALFORMED } from "../../../error/websocket/errorTypes";
import mapper from "./mappers/workordersList";
import { publishAndAwait } from "../../../app/coreSlice";

const listWorkorders = async ({ subType, ...message }) => {
  if (!subType) {
    throw errorFactory(WS_REQUEST_IS_MALFORMED);
  }

  if (message) {
    message.assignAgent = message.licensePlate = message.filter;

    delete message.filter;
  }

  const request = await publishAndAwait({
    destination: "/app/hello",
    body: {
      type: "WORKTASK",
      subType,
      message,
    },
  });

  const { processMessage, error } = request.response;

  if (!processMessage) {
    throw errorFactory(WS_PROCESS_MESSAGE_IS_MALFORMED);
  }

  if (error) {
    const { description, code, tag } = processMessage;

    throw new NGError({
      message: description,
      code,
      tag,
    });
  }

  const workorders = processMessage.worktasks.reduce(reducer, {});

  const lastPage = processMessage.lastPage;

  return { workorders, lastPage };
};

export default listWorkorders;

const reducer = (acc, workorder) => ({ ...acc, [workorder.id]: mapper.fromDTO(workorder) });
