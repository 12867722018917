import * as listItemTypes from '../../../../UI/List/ListItem/ListItemTypes';
import * as screenSize from '../../../../../model/ScreenSize/ScreenSize';

const taskSequenceFields = currentScreenSize => {
	const width = {
		[screenSize.DESKTOP]: 111,
		[screenSize.TABLET]: 78,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	const maxWidth = {
		[screenSize.DESKTOP]: 166,
		[screenSize.TABLET]: 136,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	const height = {
		[screenSize.DESKTOP]: 35,
		[screenSize.TABLET]: 45,
		[screenSize.ERROR]: 0,
	}[currentScreenSize];

	return [
		{
			key: 'actions',
			label: 'Actions',
			order: 99,
			pinned: true,
			width: 100,
			height,
			type: listItemTypes.COMPONENT,
			flexDirection: 'row-reverse',
		},
		{
			key: 'type',
			label: 'Type',
			order: 1,
			pinned: true,
			width,
			maxWidth,
			height,
			type: listItemTypes.TEXT,
		},
	];
};

export default taskSequenceFields;